import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';



import "../../../assets/scss/datatables.scss"

// Table data
const products = [

  { "id": 1, "name":  <div className="d-flex align-items-center">
  <div className="flex-shrink-0">
    <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/>
  </div>
  <div className="flex-grow-1 ms-1">
    <h6 className="mb-0 fw-bold">Khusbu Patel</h6>
    <p className="mb-0"><a href="" className="table-link-a">abc@gmail.com</a></p>
  </div>
</div>, "position": "SBI", "office": "Sola Road", "age": "361008", "salary": "0889652482",action: (
    <>
     <div className="d-flex gap-3"><a className="text-success" href="/EditClient"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
    </>
  ), },

  { "id": 2, "name": <div className="d-flex align-items-center">
  <div className="flex-shrink-0">
    <img src="/static/media/avatar-4.b23e41d9.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/>
  </div>
  <div className="flex-grow-1 ms-1">
    <h6 className="mb-0 fw-bold">Pratiksha</h6>
    <p className="mb-0"><a href="" className="table-link-a">abc@gmail.com</a></p>
  </div>
</div>, "position": "BOB", "office": "parasnagar", "age": "362002", "startdate": "abc@gmail.com", "salary": "9865234586",action: (
    <>
     <div className="d-flex gap-3"><a className="text-success" href="/EditClient"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
    </>
  ), },

  { "id": 3, "name":  <div className="d-flex align-items-center">
  <div className="flex-shrink-0">
    <img src="/static/media/avatar-4.b23e41d9.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/>
  </div>
  <div className="flex-grow-1 ms-1">
    <h6 className="mb-0 fw-bold">Pooja Patel</h6>
    <p className="mb-0"><a href="" className="table-link-a">abc@gmail.com</a></p>
  </div>
</div>, "position": "RBL", "office": "Vejalpur", "age": "369882", "startdate": "xyz@gmail.com", "salary": "8563214589",
  action: (
    <>
     <div className="d-flex gap-3"><a className="text-success" href="/EditClient"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
    </>
  ), },

  { "id": 4, "name": <div className="d-flex align-items-center">
  <div className="flex-shrink-0">
    <img src="/static/media/avatar-7.5ba5195e.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/>
  </div>
  <div className="flex-grow-1 ms-1">
    <h6 className="mb-0 fw-bold">Utsav patel</h6>
    <p className="mb-0"><a href="" className="table-link-a">abc@gmail.com</a></p>
  </div>
</div>, "position": "SBI", "office": "Iscon Cross Road", "age": "895236", "startdate": "abc@gmail.com", "salary": "$132,000",action: (
    <>
     <div className="d-flex gap-3"><a className="text-success" href="/EditClient"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
    </>
  ), },
  { "id": 5, "name": <div className="d-flex align-items-center">
  <div className="flex-shrink-0">
    <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/>
  </div>
  <div className="flex-grow-1 ms-1">
    <h6 className="mb-0 fw-bold">Rajal Patel</h6>
    <p className="mb-0"><a href="" className="table-link-a">abc@gmail.com</a></p>
  </div>
</div>, "position": "RBL", "office": "Vejalpur", "age": "369882", "startdate": "xyz@gmail.com", "salary": "8563214589",
  action: (
    <>
     <div className="d-flex gap-3"><a className="text-success" href="/EditClient"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
    </>
  ), },
  { "id": 6, "name":  <div className="d-flex align-items-center">
  <div className="flex-shrink-0">
    <img src="/static/media/avatar-7.5ba5195e.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/>
  </div>
  <div className="flex-grow-1 ms-1">
    <h6 className="mb-0 fw-bold">Sonu Singh</h6>
    <p className="mb-0"><a href="" className="table-link-a">abc@gmail.com</a></p>
  </div>
</div>, "position": "RBL", "office": "Vejalpur", "age": "369882", "startdate": "xyz@gmail.com", "salary": "8563214589",
  action: (
    <>
     <div className="d-flex gap-3"><a className="text-success" href="/EditClient"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
    </>
  ), },
  { "id": 7, "name":  <div className="d-flex align-items-center">
  <div className="flex-shrink-0">
    <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/>
  </div>
  <div className="flex-grow-1 ms-1">
    <h6 className="mb-0 fw-bold">Nancy Patel</h6>
    <p className="mb-0"><a href="" className="table-link-a">abc@gmail.com</a></p>
  </div>
</div>, "position": "RBL", "office": "Vejalpur", "age": "369882", "startdate": "xyz@gmail.com", "salary": "8563214589",
  action: (
    <>
     <div className="d-flex gap-3"><a className="text-success" href="/EditClient"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
    </>
  ), },
  { "id": 8, "name":  <div className="d-flex align-items-center">
  <div className="flex-shrink-0">
    <img src="/static/media/avatar-4.b23e41d9.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/>
  </div>
  <div className="flex-grow-1 ms-1">
    <h6 className="mb-0 fw-bold">Shilpa Singh</h6>
    <p className="mb-0"><a href="" className="table-link-a">abc@gmail.com</a></p>
  </div>
</div>, "position": "RBL", "office": "Vejalpur", "age": "369882", "startdate": "xyz@gmail.com", "salary": "8563214589",
  action: (
    <>
     <div className="d-flex gap-3"><a className="text-success" href="/EditClient"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
    </>
  ), },
  { "id": 9, "name":  <div className="d-flex align-items-center">
  <div className="flex-shrink-0">
    <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/>
  </div>
  <div className="flex-grow-1 ms-1">
    <h6 className="mb-0 fw-bold">Yogi Patel</h6>
    <p className="mb-0"><a href="" className="table-link-a">abc@gmail.com</a></p>
  </div>
</div>, "position": "RBL", "office": "Vejalpur", "age": "369882", "startdate": "xyz@gmail.com", "salary": "8563214589",
  action: (
    <>
     <div className="d-flex gap-3"><a className="text-success" href="/EditClient"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
    </>
  ), },
  { "id": 10, "name":  <div className="d-flex align-items-center">
  <div className="flex-shrink-0">
    <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/>
  </div>
  <div className="flex-grow-1 ms-1">
    <h6 className="mb-0 fw-bold">Hiren Suthar</h6>
    <p className="mb-0"><a href="" className="table-link-a">abc@gmail.com</a></p>
  </div>
</div>, "position": "RBL", "office": "Vejalpur", "age": "369882", "startdate": "xyz@gmail.com", "salary": "8563214589",
  action: (
    <>
     <div className="d-flex gap-3"><a className="text-success" href="/EditClient"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
    </>
  ), },
  { "id": 11, "name":  <div className="d-flex align-items-center">
  <div className="flex-shrink-0">
    <img src="/static/media/avatar-7.5ba5195e.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/>
  </div>
  <div className="flex-grow-1 ms-1">
    <h6 className="mb-0 fw-bold">Hirva Suthar</h6>
    <p className="mb-0"><a href="" className="table-link-a">abc@gmail.com</a></p>
  </div>
</div>, "position": "RBL", "office": "Vejalpur", "age": "369882", "startdate": "xyz@gmail.com", "salary": "8563214589",
  action: (
    <>
     <div className="d-flex gap-3"><a className="text-success" href="/EditClient"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
    </>
  ), },
  { "id": 12, "name": <div className="d-flex align-items-center">
  <div className="flex-shrink-0">
    <img src="/static/media/avatar-4.b23e41d9.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/>
  </div>
  <div className="flex-grow-1 ms-1">
    <h6 className="mb-0 fw-bold">Komal Patel</h6>
    <p className="mb-0"><a href="" className="table-link-a">abc@gmail.com</a></p>
  </div>
</div>, "position": "RBL", "office": "Vejalpur", "age": "369882", "startdate": "xyz@gmail.com", "salary": "8563214589",
  action: (
    <>
     <div className="d-flex gap-3"><a className="text-success" href="/EditClient"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
    </>
  ), },
  { "id": 13, "name": <div className="d-flex align-items-center">
  <div className="flex-shrink-0">
    <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/>
  </div>
  <div className="flex-grow-1 ms-1">
    <h6 className="mb-0 fw-bold">Hiren Suthar</h6>
    <p className="mb-0"><a href="" className="table-link-a">abc@gmail.com</a></p>
  </div>
</div>, "position": "RBL", "office": "Vejalpur", "age": "369882", "startdate": "xyz@gmail.com", "salary": "8563214589",
  action: (
    <>
     <div className="d-flex gap-3"><a className="text-success" href="/EditClient"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
    </>
  ), },


];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    const columns = [{
      dataField: 'id',
      text: 'Id',
      sort: true,
    }, {
      dataField: 'name',
      text: 'Full Name',
      sort: true
    }, {
      dataField: 'position',
      text: 'Bank Name',
      sort: true
    }, {
      dataField: 'office',
      text: 'Branch Name',
      sort: true
    }, {
      dataField: 'age',
      text: 'Pincode',
      sort: true
    }, {
      dataField: 'salary',
      text: 'Phone',
      sort: true
    },
    {
      dataField: 'action',
      text: 'Action',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

 

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
          <title>Client List | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
              <Col className="col-lg-8 col-md-6 col-6">
                <div className="breadcrum ps-0">
                <CardTitle className="h4">Client List</CardTitle>
                  <a href="#" className="me-2">
                    Home
                  </a>{" "}
                  <span>/</span>
                  <span className="ms-2">Client List</span>
                </div>
              </Col>
              <Col className="col-lg-4 co-md-6 col-6">
                <a
                  className="btn btn-primary btn-sm float-end font-14 margin-20-back"
                  href="#"
                >
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
                 {/* <a href="#" className="btn btn-primary waves-effect btn-sm float-end font-14 btn-label waves-light me-3"><i className="mdi mdi-arrow-left label-icon"></i>Back</a> */}
               
                 {/* <a href="/maps-google" className="btn btn-green waves-effect btn-sm float-end font-14 btn-label waves-light me-3"><i className="bx bx-plus label-icon"></i>ADD</a> */}
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

<Row className="mb-2">
                                <Col className="col-lg-4 col-md-4 col-6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-lg-8 col-md-8 col-6">
                                <a
                  className="btn btn-green btn-sm float-end font-14"
                  href="/EditClient"
                >
                  <i className="mdi mdi-plus align-middle me-1"></i>Add Lead
                </a>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                     
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light table-thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DatatableTables