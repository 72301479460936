import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import axios from 'axios'
import { Col, Row, CardTitle, Card, CardBody, Input, Label } from "reactstrap";

const UiRating = () => {
  const [CustomerCode, setCustomerCode] = useState('');
  const [FullName, setFullName] = useState("");
  const [PMobileNumber, setPMobileNumber] = useState("");
  const [PEmailAddress, setPEmailAddress] = useState("");
  const [CustomerProfileImage, setCustomerProfileImage] = useState("");
  const [CustomerType, setCustomerType] = useState("");
  const [RegisterDate, setRegisterDate] = useState("");
  const [RegisterTime, setRegisterTime] = useState("");
  const [ClientActivitiesStatus, setClientActivitiesStatus] = useState('Pending');



  const [ServicePackageActivities, setServicePackageActivities] = useState([]);
  const [ServicePackageActivitiesDetail, setServicePackageActivitiesDetail] = useState([]);
  const [ServicePackageActivitiesDetailRowCount, setServicePackageActivitiesDetailRowCount] = useState([]);
  const [ServicePackageActivitiesDetailRow, setServicePackageActivitiesDetailRow] = useState([]);

  const [ClientFollowUpList, setClientFollowUpList] = useState([]);

  const [MasterList, setMasterList] = useState([]);

  const [EditActivitiesId, setEditActivitiesId] = useState(0);
  const [EditServicePackageId, setEditServicePackageId] = useState(0);
  const [EditMasterRowId, setEditMasterRowId] = useState(0);
  const [EditTableRowId, setEditTableRowId] = useState(0);
  const [documentFilepath, setDocumentFilePath] = useState('');

  const [StatusMasterRowId, setStatusMasterRowId] = useState(0);



  useEffect(() => {
    let CustomerId = localStorage.getItem("CustomerIndividualId");

    let LeadId = localStorage.getItem("CustomerIndividualLeadId");
    if (CustomerId != null && LeadId != null) {
      if (CustomerId != "0") {
        getMasterList();
        GetClientFollowupList();
        getCustomerDetailById(CustomerId);
        getServicePackageActivitiesDetail(LeadId);
        getServicePackageActivities(LeadId);
        getServicePackageActivitiesDetailRowCount(LeadId);
        getServicePackageActivitiesDetailRow(LeadId);
      }
      else {
        history.push("/ConvertedInq");
      }
    }
    else {
      history.push("/ConvertedInq");
    }
  }, []);

  const getMasterList = async () => {
    var AUrl = global.APIURL + '/MasterSelectAll';
    axios.get(AUrl)
      .then(MSres => {
        let MasterList = MSres.data.map(VMaster => {
          return VMaster;
        });
        setMasterList(MasterList);
      });
  };

  const getDocumentpath = async (e) => {
    var details = e.target.files[0];
    var details1 = "ServiceActivitiesDocument";
    var filename = details.name;
    const formData = new FormData();
    formData.append('Document', details);
    formData.append('FolderName', details1);
    var AUrl = global.APIURL + '/uploadavatar/';
    axios.post(AUrl, formData, {
      headers: { "Content-Type": details.type },
    })
      .then((res) => {
        setDocumentFilePath(res.data.IName);
      });
  }


  const getCustomerDetailById = async (CId) => {
    var AUrl = global.APIURL + '/WebCustomerEdit/' + CId;
    axios.get(AUrl)
      .then(res => {
        setCustomerCode(res.data[0].CustomerCode);
        setFullName(res.data[0].FullName);
        setPMobileNumber(res.data[0].MobileNumber);
        setPEmailAddress(res.data[0].EmailAddress);
        setCustomerType(res.data[0].CustomerType);
        setCustomerProfileImage(res.data[0].CustomerProfileImage)
        setRegisterDate(res.data[0].RegisterDate)
        setRegisterDate(res.data[0].RegisterDate)
        setRegisterTime(res.data[0].RegisterTime);
        setClientActivitiesStatus(res.data[0].ActivitiesStatus);
        document.getElementById('txtClientActivitiesStatus').value = res.data[0].ActivitiesStatus;
      })
  }

  const getServicePackageActivities = async LeadId => {
    let VServicePackageId = localStorage.getItem("IndividualServicePackageId");
    const RequestArr = {
      LeadId: LeadId,
      ServicePackageId: VServicePackageId
    };
    var AUrl = global.APIURL + '/CustomerServicePackageActivities';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        let ServicePackageActivitiesList = CSPres.data.map(VServicePackageActivities => {
          return VServicePackageActivities;
        });
        setServicePackageActivities(ServicePackageActivitiesList);
      });
  };

  const getServicePackageActivitiesDetail = async LeadId => {
    let VServicePackageId = localStorage.getItem("IndividualServicePackageId");
    const RequestArr = {
      LeadId: LeadId,
      ServicePackageId: VServicePackageId
    };
    var AUrl = global.APIURL + '/CustomerServicePackageActivitiesDetail';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        let ServicePackageActivitiesDetailList = CSPres.data.map(VServicePackageActivitiesDetail => {
          return VServicePackageActivitiesDetail;
        });
        setServicePackageActivitiesDetail(ServicePackageActivitiesDetailList);
      });
  };

  const getServicePackageActivitiesDetailRowCount = async LeadId => {
    let VServicePackageId = localStorage.getItem("IndividualServicePackageId");
    const RequestArr = {
      LeadId: LeadId,
      ServicePackageId: VServicePackageId
    };
    var AUrl = global.APIURL + '/CustomerActivitiesDetailGroupByList';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        let sra = CSPres.data.map(Vsra => {
          return Vsra;
        });
        setServicePackageActivitiesDetailRowCount(sra);
      });
  };

  const getServicePackageActivitiesDetailRow = async LeadId => {
    let VServicePackageId = localStorage.getItem("IndividualServicePackageId");
    const RequestArr = {
      LeadId: LeadId,
      ServicePackageId: VServicePackageId
    };
    var AUrl = global.APIURL + '/CustomerActivitiesDetailList';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        let sra = CSPres.data.map(Vsra => {
          return Vsra;
        });
        setServicePackageActivitiesDetailRow(sra);
      });
  };

  const onClickActivitiesAction = async (ActivitiesId, ButtonControlId) => {
    const AddUpdatebtn = document.getElementById(ButtonControlId);
    if (AddUpdatebtn.textContent == 'Add') {
      var VActivitiesId = ActivitiesId == null ? '0' : ActivitiesId == '' ? '0' : ActivitiesId;
      var SerPackActivitiesDetailFilter = ServicePackageActivitiesDetail.filter(x => x.ActivitiesId == VActivitiesId);
      var SerPackActRowFilter = ServicePackageActivitiesDetailRowCount.filter(x => x.ActivitiesId == VActivitiesId);
      const TabelRowArr = {};
      const RowCount = SerPackActRowFilter.length + 1;
      var SRowCount = RowCount.toString();
      const FCListRowCountArr = {
        ActivitiesId: ActivitiesId,
        RowId: RowCount,
      };
      setServicePackageActivitiesDetailRowCount([...ServicePackageActivitiesDetailRowCount, FCListRowCountArr]);

      for (let j = 0; j < SerPackActivitiesDetailFilter.length; j++) {
        var VFormControlValue = '0';
        var VFormControlText = '';
        var VDocFileName = "";
        var VActionId = SerPackActivitiesDetailFilter[j].ActionId;
        var VFormControlId = SerPackActivitiesDetailFilter[j].FormControlId;
        var VServicePackageId = SerPackActivitiesDetailFilter[j].ServicePackageId == null ? '0' : SerPackActivitiesDetailFilter[j].ServicePackageId == '' ? '0' : SerPackActivitiesDetailFilter[j].ServicePackageId;
        var VActivitiesTitle = SerPackActivitiesDetailFilter[j].ActivitiesTitle == null ? '' : SerPackActivitiesDetailFilter[j].ActivitiesTitle;
        var VActionTitle = SerPackActivitiesDetailFilter[j].ActionTitle == null ? '' : SerPackActivitiesDetailFilter[j].ActionTitle;

        var VFormControlId = SerPackActivitiesDetailFilter[j].FormControlId == null ? '' : SerPackActivitiesDetailFilter[j].FormControlId;
        var VLabelControlId = SerPackActivitiesDetailFilter[j].LabelControlId == null ? '' : SerPackActivitiesDetailFilter[j].LabelControlId;

        var VLeadId = SerPackActivitiesDetailFilter[j].LeadId == null ? '0' : SerPackActivitiesDetailFilter[j].LeadId == '' ? '0' : SerPackActivitiesDetailFilter[j].LeadId;
        var VCustomerId = SerPackActivitiesDetailFilter[j].CustomerId == null ? '0' : SerPackActivitiesDetailFilter[j].CustomerId == '' ? '0' : SerPackActivitiesDetailFilter[j].CustomerId;

        var VDropdownPlaceholder = SerPackActivitiesDetailFilter[j].DropdownPlaceholder == null ? '' : SerPackActivitiesDetailFilter[j].DropdownPlaceholder;
        if (VActionId == 'Text') {
          VFormControlText = document.getElementById(VFormControlId).value;
          document.getElementById(VFormControlId).value = '';
        }
        else if (VActionId == 'Number') {
          VFormControlText = document.getElementById(VFormControlId).value;
          document.getElementById(VFormControlId).value = '';
        }
        else if (VActionId == 'Date') {
          VFormControlText = document.getElementById(VFormControlId).value;
          document.getElementById(VFormControlId).value = '';
        }
        else if (VActionId == 'TextArea') {
          VFormControlText = document.getElementById(VFormControlId).value;
          document.getElementById(VFormControlId).value = '';
        }
        else if (VActionId == 'Dropdown') {
          var VDropdownControlIndex = document.getElementById(VFormControlId).selectedIndex;
          var VDropdownControl = document.getElementById(VFormControlId).options;
          var VFormControlValue = VDropdownControl[VDropdownControlIndex].value;
          var VFormControlText = VDropdownControl[VDropdownControlIndex].text;
          document.getElementById(VFormControlId).value = '0';
        }
        else if (VActionId == 'File Upload') {
          VFormControlText = documentFilepath;
          var filearray = VFormControlText.toString().split('/');
          VDocFileName = filearray.length > 4 ? filearray[5] : '';

        }
        else if (VActionId == 'Checkbox') {
          VFormControlText = document.getElementById(VFormControlId).checked;
          if (VFormControlText == true) { VFormControlText = "Yes" } else { VFormControlText = "No" }
          document.getElementById(VFormControlId).checked = '';
          // document.getElementById(VLabelControlId).value = VFormControlText==true?'Yes':'No';

        }
        const TRowArr = {
          TableRowId: 0,
          EntryType: 'N',
          ActivitiesId: ActivitiesId,
          ServicePackageId: VServicePackageId,
          ActivitiesTitle: VActivitiesTitle,
          ActionTitle: VActionTitle,
          FormControlId: VFormControlId,
          LeadId: VLeadId,
          CustomerId: VCustomerId,
          RowId: (j + 1),
          MasterRowId: RowCount,
          LabelControlId: SerPackActivitiesDetailFilter[j].LabelControlId + SRowCount,
          LabelControlValue: VFormControlValue,
          LabelControlTitle: VFormControlText == VDropdownPlaceholder ? '' : VFormControlText,
          HiddenControlId: SerPackActivitiesDetailFilter[j].HiddenControlId + SRowCount,
          ActionId: VActionId,
          UploadDocumentFileName: VDocFileName
        };
        ServicePackageActivitiesDetailRow.push(TRowArr);
      }
      setServicePackageActivitiesDetailRow(ServicePackageActivitiesDetailRow);

      var VServicePackageId = SerPackActivitiesDetailFilter.length == 0 ? '0' : SerPackActivitiesDetailFilter[0].ServicePackageId == null ? '0' : SerPackActivitiesDetailFilter[0].ServicePackageId == '' ? '0' : SerPackActivitiesDetailFilter[0].ServicePackageId;
      GetActionStatusCodeLast(VServicePackageId);
    }
    else if (AddUpdatebtn.textContent == 'Update') {
      const AddUpdatebtn = document.getElementById(ButtonControlId);
      AddUpdatebtn.textContent = 'Add';
      for (let v = 0; v < ServicePackageActivitiesDetailRow.length; v++) {
        var VActivitiesId = ServicePackageActivitiesDetailRow[v].ActivitiesId;
        var VServicePackageId = ServicePackageActivitiesDetailRow[v].ServicePackageId;
        var VMasterRowId = ServicePackageActivitiesDetailRow[v].MasterRowId;
        var VMasterRowId = ServicePackageActivitiesDetailRow[v].MasterRowId;
        var VFormControlId = ServicePackageActivitiesDetailRow[v].FormControlId;
        var VLabelControlId = ServicePackageActivitiesDetailRow[v].LabelControlId;
        var VHiddenControlId = ServicePackageActivitiesDetailRow[v].HiddenControlId;
        var VActionId = ServicePackageActivitiesDetailRow[v].ActionId;
        var VFormControlValue = '0';
        var VFormControlText = '';
        var VDocFileName = ServicePackageActivitiesDetailRow[v].UploadDocumentFileName;
        var VDropdownPlaceholder = ServicePackageActivitiesDetailRow[v].LabelControlTitle == null ? '' : ServicePackageActivitiesDetailRow[v].LabelControlTitle;
        if (VActivitiesId == EditActivitiesId && VServicePackageId == EditServicePackageId && VMasterRowId == EditMasterRowId) {
          if (VActionId == 'Text') {
            VFormControlText = document.getElementById(VFormControlId).value;
            document.getElementById(VFormControlId).value = '';
            ServicePackageActivitiesDetailRow[v]['LabelControlValue'] = 0;
            ServicePackageActivitiesDetailRow[v]['LabelControlTitle'] = VFormControlText;
            document.getElementById(VLabelControlId).value = VFormControlText;
          }
          else if (VActionId == 'Number') {
            VFormControlText = document.getElementById(VFormControlId).value;
            document.getElementById(VFormControlId).value = '';
            ServicePackageActivitiesDetailRow[v]['LabelControlValue'] = 0;
            ServicePackageActivitiesDetailRow[v]['LabelControlTitle'] = VFormControlText;
            document.getElementById(VLabelControlId).value = VFormControlText;
          }
          else if (VActionId == 'Date') {
            VFormControlText = document.getElementById(VFormControlId).value;
            document.getElementById(VFormControlId).value = '';
            ServicePackageActivitiesDetailRow[v]['LabelControlValue'] = 0;
            ServicePackageActivitiesDetailRow[v]['LabelControlTitle'] = VFormControlText;
            document.getElementById(VLabelControlId).value = VFormControlText;
          }
          else if (VActionId == 'TextArea') {
            VFormControlText = document.getElementById(VFormControlId).value;
            document.getElementById(VFormControlId).value = '';
            ServicePackageActivitiesDetailRow[v]['LabelControlValue'] = 0;
            ServicePackageActivitiesDetailRow[v]['LabelControlTitle'] = VFormControlText;
            document.getElementById(VLabelControlId).value = VFormControlText;
          }
          else if (VActionId == 'Dropdown') {
            var VDropdownControlIndex = document.getElementById(VFormControlId).selectedIndex;
            var VDropdownControl = document.getElementById(VFormControlId).options;
            var VFormControlValue = VDropdownControl[VDropdownControlIndex].value;
            var VFormControlText = VDropdownControl[VDropdownControlIndex].text;
            document.getElementById(VFormControlId).value = '0';
            ServicePackageActivitiesDetailRow[v]['LabelControlValue'] = VFormControlValue;
            ServicePackageActivitiesDetailRow[v]['LabelControlTitle'] = VFormControlValue == '0' ? '' : VFormControlText;
            document.getElementById(VLabelControlId).value = VFormControlValue == '0' ? '' : VFormControlText,
              document.getElementById(VHiddenControlId).value = VFormControlValue;
          }
          else if (VActionId == "File Upload") {


            if (documentFilepath == "") {
              VDocFileName = ServicePackageActivitiesDetailRow[v].UploadDocumentFileName;
              VFormControlText = VDocFileName;

            } else {
              VFormControlText = documentFilepath;
              var filearray = VFormControlText.toString().split('/');
              VDocFileName = filearray[5];
            }

            document.getElementById(VFormControlId).value = '';
            ServicePackageActivitiesDetailRow[v]['LabelControlValue'] = 0;
            ServicePackageActivitiesDetailRow[v]['LabelControlTitle'] = VFormControlText;
            ServicePackageActivitiesDetailRow[v]['UploadDocumentFileName'] = VDocFileName;
          }
          else if (VActionId == 'Checkbox') {
            VFormControlText = document.getElementById(VFormControlId).checked;
            document.getElementById(VFormControlId).checked = '';
            ServicePackageActivitiesDetailRow[v]['LabelControlValue'] = VFormControlText == true ? 'Yes' : 'No';
            ServicePackageActivitiesDetailRow[v]['LabelControlTitle'] = VFormControlText == true ? 'Yes' : 'No';
            document.getElementById(VLabelControlId).value = VFormControlText == true ? 'Yes' : 'No';

          }
        }
      }
      setServicePackageActivitiesDetailRow(ServicePackageActivitiesDetailRow);

      var VServicePackageId = ServicePackageActivitiesDetailRow.length == 0 ? '0' : ServicePackageActivitiesDetailRow[0].ServicePackageId == null ? '0' : ServicePackageActivitiesDetailRow[0].ServicePackageId == '' ? '0' : ServicePackageActivitiesDetailRow[0].ServicePackageId;
      GetActionStatusCodeLast(VServicePackageId);
    }


  };


  const SaveActivitiesAction = async (ActivitiesId) => {

    var VActivitiesId = ActivitiesId == null ? '0' : ActivitiesId == '' ? '0' : ActivitiesId;
    var SerPackActRowFilter = ServicePackageActivitiesDetailRow.filter(x => x.ActivitiesId == VActivitiesId);

    for (let s = 0; s < SerPackActRowFilter.length; s++) {
      var AcTId = SerPackActRowFilter[s].ActionId;
      const ActivitiesActionArr = {
        CustomerActivitiesId: SerPackActRowFilter[s].EntryType == 'O' ? SerPackActRowFilter[s].TableRowId : '0',
        LeadId: SerPackActRowFilter[s].LeadId,
        CustomerId: SerPackActRowFilter[s].CustomerId,
        ServicePackageId: SerPackActRowFilter[s].ServicePackageId,
        ActivitiesTitle: SerPackActRowFilter[s].ActivitiesTitle,
        ActivitiesId: SerPackActRowFilter[s].ActivitiesId,
        ActionTitle: SerPackActRowFilter[s].ActionTitle,
        Action: SerPackActRowFilter[s].ActionId,
        ActionText: SerPackActRowFilter[s].LabelControlTitle,
        ActionValue: AcTId == 'Checkbox' ? '0' : SerPackActRowFilter[s].LabelControlValue,
        FormControlId: SerPackActRowFilter[s].FormControlId,
        LabelControlId: SerPackActRowFilter[s].LabelControlId,
        HiddenControlId: SerPackActRowFilter[s].HiddenControlId,
        MasterRowId: SerPackActRowFilter[s].MasterRowId,
        CreateUser: 1,
        UploadDocumentFileName: SerPackActRowFilter[s].UploadDocumentFileName
      };
      axios.post(global.APIURL + "/CustomerActivitiesDetailSave", ActivitiesActionArr).then(res => { if (res.data.length > 0) { } })
    }
    setStatusMasterRowId(0);
    alert("Activities action save successfully");
    let VLeadId = localStorage.getItem("CustomerIndividualLeadId");
    getServicePackageActivitiesDetailRowCount(VLeadId);
    getServicePackageActivitiesDetailRow(VLeadId);
    let CustomerId = localStorage.getItem("CustomerIndividualId");
    getCustomerDetailById(CustomerId);

  }

  const EditActivitiesAction = async (ActivitiesId, MasterRowId, ButtonControlId) => {
    const AddUpdatebtn = document.getElementById(ButtonControlId);
    AddUpdatebtn.textContent = 'Update';
    var VActivitiesId = ActivitiesId == null ? '0' : ActivitiesId == '' ? '0' : ActivitiesId;
    var VMasterRowId = MasterRowId == null ? '0' : MasterRowId == '' ? '0' : MasterRowId;
    var SerPackActRowFilter = ServicePackageActivitiesDetailRow.filter(x => x.ActivitiesId == VActivitiesId && x.MasterRowId == VMasterRowId);
    setStatusMasterRowId(MasterRowId);
    if (SerPackActRowFilter.length > 0) {
      var VServicePackageId = SerPackActRowFilter[0].ServicePackageId == null ? '0' : SerPackActRowFilter[0].ServicePackageId == '' ? '0' : SerPackActRowFilter[0].ServicePackageId;
      var VTableRowId = SerPackActRowFilter[0].TableRowId == null ? '0' : SerPackActRowFilter[0].TableRowId == '' ? '0' : SerPackActRowFilter[0].TableRowId;
      setEditActivitiesId(VActivitiesId);
      setEditServicePackageId(VServicePackageId);
      setEditMasterRowId(VMasterRowId);
      setEditTableRowId(VTableRowId);
      for (let e = 0; e < SerPackActRowFilter.length; e++) {
        var VFormControlId = SerPackActRowFilter[e].FormControlId;
        var VActionId = SerPackActRowFilter[e].ActionId
        if (VActionId == 'Text') {
          var VLabelControlTitle = SerPackActRowFilter[e].LabelControlTitle;
          document.getElementById(VFormControlId).value = VLabelControlTitle;
        }
        else if (VActionId == 'Number') {
          var VLabelControlTitle = SerPackActRowFilter[e].LabelControlTitle;
          document.getElementById(VFormControlId).value = VLabelControlTitle;
        }
        else if (VActionId == 'Date') {
          var VLabelControlTitle = SerPackActRowFilter[e].LabelControlTitle;
          document.getElementById(VFormControlId).value = VLabelControlTitle;
        }
        else if (VActionId == 'TextArea') {
          var VLabelControlTitle = SerPackActRowFilter[e].LabelControlTitle;
          document.getElementById(VFormControlId).value = VLabelControlTitle;
        }
        else if (VActionId == 'Dropdown') {
          var VLabelControlValue = SerPackActRowFilter[e].LabelControlValue;
          document.getElementById(VFormControlId).value = VLabelControlValue;

        }
        else if (VActionId == 'File Upload') {

          var VLabelControlTitle = SerPackActRowFilter[e].UploadDocumentFileName;
          document.getElementById(VFormControlId).src = VLabelControlTitle;
        }
        else if (VActionId == 'Checkbox') {
          var VLabelControlTitle = SerPackActRowFilter[e].LabelControlTitle;
          if (VLabelControlTitle == "Yes") {
            document.getElementById(VFormControlId).checked = true;
          } else {
            document.getElementById(VFormControlId).checked = false;
          }
        }
      }
    }
  }

  const DeleteActivitiesAction = async (ActivitiesId, MasterRowId, ActivitiesStatusControlId) => {

    var VActivitiesId = ActivitiesId == null ? '0' : ActivitiesId == '' ? '0' : ActivitiesId;
    var VMasterRowId = MasterRowId == null ? '0' : MasterRowId == '' ? '0' : MasterRowId;
    var SerPackActRowFilter = ServicePackageActivitiesDetailRow.filter(x => x.ActivitiesId == VActivitiesId && x.MasterRowId == VMasterRowId);
    var VMasterRowId = MasterRowId == null ? '0' : MasterRowId == '' ? '0' : MasterRowId;
    let VLeadId = localStorage.getItem("CustomerIndividualLeadId");
    if (SerPackActRowFilter.length > 0) {
      for (let sp = 0; sp < SerPackActRowFilter.length; sp++) {
        var VTableRowId = parseInt(SerPackActRowFilter[sp].TableRowId == null ? '0' : SerPackActRowFilter[sp].TableRowId == '' ? '0' : SerPackActRowFilter[sp].TableRowId);

        var VServicePackageId = SerPackActRowFilter.length == 0 ? '0' : SerPackActRowFilter[0].ServicePackageId == null ? '0' : SerPackActRowFilter[0].ServicePackageId == '' ? '0' : SerPackActRowFilter[0].ServicePackageId;
        if (VTableRowId > 0) {
          const ActivitiesActionDeleteArr = {
            CustomerActivitiesId: VTableRowId,
            LeadId: VLeadId,
            ServicePackageId: VServicePackageId,
            ActivitiesId: VActivitiesId,
            MasterRowId: VMasterRowId,
            UpdateUser: 1,
          };
          axios.post(global.APIURL + "/CustomerActivitiesDetailDelete", ActivitiesActionDeleteArr).then(res => { if (res.data.length > 0) { } })

          GetActionStatusCodeLast(VServicePackageId);
        }
        else {
          const ActivitiesActionDeleteArr = {
            CustomerActivitiesId: VTableRowId,
            LeadId: VLeadId,
            ServicePackageId: VServicePackageId,
            ActivitiesId: VActivitiesId,
            MasterRowId: VMasterRowId,
            UpdateUser: 1,
          };
          axios.post(global.APIURL + "/CustomerActivitiesDetailLogDelete", ActivitiesActionDeleteArr).then(res => { if (res.data.length > 0) { } })

          GetActionStatusCodeLast(VServicePackageId);
        }
      }
    }

    for (let sad = 0; sad < ServicePackageActivitiesDetailRowCount.length; sad++) {
      var VAId = ServicePackageActivitiesDetailRowCount[sad].ActivitiesId;
      var VMId = ServicePackageActivitiesDetailRowCount[sad].RowId;
      if (VAId == VActivitiesId && VMId == VMasterRowId) {
        var SPARowCountFilter = ServicePackageActivitiesDetailRowCount.filter((_, index) => index !== sad);
        setServicePackageActivitiesDetailRowCount(SPARowCountFilter);
      }
    }

    for (let sad = 0; sad < ServicePackageActivitiesDetailRow.length; sad++) {
      var VAId = ServicePackageActivitiesDetailRow[sad].ActivitiesId;
      var VMId = ServicePackageActivitiesDetailRow[sad].MasterRowId;
      if (VAId == VActivitiesId && VMId == VMasterRowId) {
        var SPARowFilter = ServicePackageActivitiesDetailRow.filter((_, index) => index !== sad);
        setServicePackageActivitiesDetailRow(SPARowFilter);
      }
    }
    document.getElementById(ActivitiesStatusControlId).value = 'Pending';
    GetActActionStatusCode(VServicePackageId, VActivitiesId, ActivitiesStatusControlId)

    alert("Activities action delete successfully");
  }

  const GetActionStatusCode = async (ServicePackageId, ActivitiesId, ActionId, ActionTitle, FormControlId, ActivitiesStatusControlId) => {
    var VServicePackageId = ServicePackageId == null ? '0' : ServicePackageId == '' ? '0' : ServicePackageId;
    var VActivitiesId = ActivitiesId == null ? '0' : ActivitiesId == '' ? '0' : ActivitiesId;
    var VActionTitle = ActionTitle == null ? '' : ActionTitle == '' ? '' : ActionTitle;
    var VFormControlId = FormControlId == null ? '' : FormControlId == '' ? '' : FormControlId;
    var VActionId = ActionId;
    var VFormControlText = '';
    var VFormControlValue = 0;
    var VDocFileName = "";

    let VCustomerId = localStorage.getItem("CustomerIndividualId");
    let VLeadId = localStorage.getItem("CustomerIndividualLeadId");
    var SARowFilter = ServicePackageActivitiesDetailRowCount.filter(x => x.ActivitiesId == VActivitiesId);
    var SARowCount = StatusMasterRowId == 0 ? SARowFilter.length + 1 : StatusMasterRowId;
    if (VActionId == 'Text') {
      VFormControlText = document.getElementById(VFormControlId).value;
    }
    else if (VActionId == 'Number') {
      VFormControlText = document.getElementById(VFormControlId).value;
    }
    else if (VActionId == 'Date') {
      VFormControlText = document.getElementById(VFormControlId).value;

    }
    else if (VActionId == 'TextArea') {
      VFormControlText = document.getElementById(VFormControlId).value;
    }
    else if (VActionId == 'Dropdown') {
      var VDropdownControlIndex = document.getElementById(VFormControlId).selectedIndex;
      var VDropdownControl = document.getElementById(VFormControlId).options;
      VFormControlValue = VDropdownControl[VDropdownControlIndex].value;
      VFormControlText = VDropdownControl[VDropdownControlIndex].text;
    }
    else if (VActionId == 'File Upload') {
      VFormControlText = documentFilepath;
      var filearray = VFormControlText.split('/');
      VDocFileName = filearray[5];
    }
    else if (VActionId == 'Checkbox') {
      VFormControlText = document.getElementById(VFormControlId).checked;
    }

    const RequestArr = {
      ActivitiesActionLogId: 0,
      LeadId: VLeadId,
      CustomerId: VCustomerId,
      ServicePackageId: VServicePackageId,
      ActivitiesId: VActivitiesId,
      ActionTitle: VActionTitle,
      ActionText: VFormControlText,
      ActionValue: VFormControlValue,
      ActivitiesActionRowId: SARowCount,
      CreateUser: 1,

    };

    var AUrl = global.APIURL + '/CustomerActivitiesActionLogSave';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        let SPAA = CSPres.data.map(VSPAADetail => {
          return VSPAADetail;
        });

        document.getElementById(ActivitiesStatusControlId).value = SPAA[0].StatusCode;
        GetActionStatusCodeLast(VServicePackageId);
      });
  }

  const GetActionStatusCodeLast = async (ServicePackageId) => {
    var VServicePackageId = ServicePackageId == null ? '0' : ServicePackageId == '' ? '0' : ServicePackageId;
    let VLeadId = localStorage.getItem("CustomerIndividualLeadId");
    const RequestArr = {
      LeadId: VLeadId,
      ServicePackageId: VServicePackageId,
      ActivitiesId: 0
    };
    var AUrl = global.APIURL + '/CustomerActivitiesActionActionStatus';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        let SPAA = CSPres.data.map(VSPAADetail => {
          return VSPAADetail;
        });
        var VStatusCode = SPAA.length == 0 ? 'Pending' : SPAA[0].StatusCode == null ? 'Pending' : SPAA[0].StatusCode;
        if (VStatusCode == 'Pending') {

        }
        document.getElementById('txtClientActivitiesStatus').value = VStatusCode;
      });
  }

  const GetActActionStatusCode = async (ServicePackageId, ActivitiesId, ActivitiesStatusControlId) => {
    var VServicePackageId = ServicePackageId == null ? '0' : ServicePackageId == '' ? '0' : ServicePackageId;
    var VActivitiesId = ActivitiesId == null ? '0' : ActivitiesId == '' ? '0' : ActivitiesId;
    let VLeadId = localStorage.getItem("CustomerIndividualLeadId");
    const RequestArr = {
      LeadId: VLeadId,
      ServicePackageId: VServicePackageId,
      ActivitiesId: VActivitiesId
    };
    var AUrl = global.APIURL + '/CustomerServiceActivitiesActionActionStatus';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        let SPAA = CSPres.data.map(VSPAADetail => {
          return VSPAADetail;
        });

        if (SPAA.length > 0) {
          if (SPAA[0].StatusCode != null) {
            if (SPAA[0].StatusCode != '') {
              document.getElementById(ActivitiesStatusControlId).value = SPAA[0].StatusCode;
            }
            else {
              document.getElementById(ActivitiesStatusControlId).value = 'Pending';
            }
          }
          else {
            document.getElementById(ActivitiesStatusControlId).value = 'Pending';
          }
        }
        else {
          document.getElementById(ActivitiesStatusControlId).value = 'Pending';
        }
      });
  }

  const ClientFollowupSave = async (e) => {
    e.preventDefault();
    let VLeadId = localStorage.getItem("CustomerIndividualLeadId");
    const ClientFollowupArr = {
      LeadId: VLeadId,
      Action: document.getElementById("txtAction").value,
      NextFollowupDate: document.getElementById("txtNextFollowupDate").value,
      NextFollowupTime: document.getElementById("txtNextFollowupTime").value,
      NextFollowupMode: document.getElementById("ddlFollowupMode").value == '0' ? '' : document.getElementById("ddlFollowupMode").value,
      NotesforNextFollowup: document.getElementById("txtNotesforNextFollowup").value,
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    }
    axios.post(global.APIURL + '/ClientFollowupSave', ClientFollowupArr)
      .then(res => {
        alert(res.data.StatusMessage);
        GetClientFollowupList();
        document.getElementById("txtAction").value = '';
        document.getElementById("txtNextFollowupDate").value = '';
        document.getElementById("txtNextFollowupTime").value = '';
        document.getElementById("ddlFollowupMode").value = '0';
        document.getElementById("txtNotesforNextFollowup").value = '';
      });
  }

  const GetClientFollowupList = async () => {
    let VLeadId = localStorage.getItem("CustomerIndividualLeadId");
    const ClientFollowupArr = {
      LeadId: VLeadId
    }
    var AUrl = global.APIURL + '/ClientFollowupAll';
    axios.post(AUrl, ClientFollowupArr)
      .then(CSPres => {
        let SPAA = CSPres.data.map(VSPAADetail => {
          return VSPAADetail;
        });
        setClientFollowUpList(SPAA);
      });
  }

  const ClientFollowupRemarkClose = async (e) => {
    e.preventDefault();
    document.getElementById("txtFollowupRemarks").value = '';
  }

  const ClientFollowupRemarkSave = async (e) => {
    e.preventDefault();
    const ClientFollowupRemarkUpdateArr = {
      FollowUpId: document.getElementById("hdfClientFollowupId").value,
      Remarks: document.getElementById("txtFollowupRemarks").value,
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    }
    axios.post(global.APIURL + '/ClientFollowupRemarksUpdate', ClientFollowupRemarkUpdateArr)
      .then(res => {
        alert(res.data.StatusMessage);
        GetClientFollowupList();
        document.getElementById("txtFollowupRemarks").value = '';
        document.getElementById("hdfClientFollowupId").value = '0';
      });
  }

  const setClientFollowupId = async FId => {
    document.getElementById("hdfClientFollowupId").value = FId;
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Client Service Activities | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">


          <Row className="mb-3">
            <Col className="col-lg-9 col-md-6 col-12">
              <div className="breadcrum ps-0">
                <CardTitle>Client Service Activities</CardTitle>
                <a href="/ClientLead" className="me-2">
                  Manage CLient
                </a>{" "}
                <span>/</span>
                <span className="ms-2">Client Service Activities</span>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-6 col-12 mt-3 mt-md-0">
              <p className="mb-2 fontWeight500"> Client Activities Status</p>
              <Input type="text" readOnly className="" id="txtClientActivitiesStatus" defaultValue={ClientActivitiesStatus} />
            </Col>
          </Row>

          <div className="modal fade bd-example-modal-sm" id="ClientFollowupRemark" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <div>
                    <h5 className="modal-title" id="exampleModalLabel">Follow-up Remarks</h5>
                  </div>
                  <div>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ClientFollowupRemarkClose}></button>
                  </div>
                </div>
                <div className="modal-body">
                  <Row>

                    <Col lg={12} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Remarks</label>
                        <textarea rows={4} type="text" className="form-control" id="txtFollowupRemarks" placeholder="Enter Follow-up Remarks" />
                      </div>
                    </Col>
                    <Col lg={12} className="mt-2">
                      <button className="btn btn_success btn-sm" id="btnsave" onClick={ClientFollowupRemarkSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                      <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={ClientFollowupRemarkClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                      <Input type="hidden" className="form-control" id="hdfClientFollowupId" value="0" />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div className="ind-card" id="ind-card">
                <Row>
                  <Col className="col-lg-12 col-12">
                    <Card>
                      <CardBody>
                        <Row className="align-items-center">
                          <Col className="col-md-8 col-lg-10 col-12">
                            <Col className="col-md-2 col-lg-2 col-12 text-center mb-3  d-block d-md-none">
                              <div className="">
                                <img
                                  className="rounded-circle clientactimg"
                                  src={CustomerProfileImage}
                                ></img>
                              </div>
                            </Col>
                            <Row>
                              <Col className="col-12  d-md-block text-center text-md-start">
                                <h5 className="fw-bolder mb-2">{FullName} <span className="text-primary">({CustomerCode})</span></h5>
                                <hr />
                              </Col>
                            </Row>
                            <Row className="mt-2 mb-2">
                              <Col className="col-md-6 col-lg-3 col-12 mb-md-3 mb-3 mb-lg-0">
                                <p className="mb-2 fontWeight500"> Mobile Number </p>
                                <Input
                                  type="text"
                                  className=""
                                  readOnly
                                  id="txtMobileNumber"
                                  defaultValue={PMobileNumber}
                                  placeholder="Enter Mobile Number"
                                />
                              </Col>
                              <Col className="col-md-6 col-lg-3 col-12 mb-md-3 mb-3 mb-lg-0">
                                <p className="mb-2 fontWeight500"> Email Address </p>
                                <Input
                                  type="text"
                                  className=""
                                  readOnly
                                  id="txtEmailAddress"
                                  defaultValue={PEmailAddress}
                                  placeholder="Enter Email Address"
                                />
                              </Col>
                              <Col className="col-md-6 col-lg-2 col-12 mb-md-3 mb-3 mb-lg-0">
                                <p className="mb-2 fontWeight500"> Type </p>
                                <Input
                                  type="text"
                                  readOnly
                                  className=""
                                  id="txtType"
                                  defaultValue={CustomerType}
                                  placeholder="Enter Type"
                                />
                              </Col>
                              <Col className="col-md-6 col-lg-2 col-12 mb-md-3 mb-3 mb-lg-0">
                                <p className="mb-2 fontWeight500"> Register Date </p>
                                <Input
                                  type="text"
                                  readOnly
                                  className=""
                                  id="txtType"
                                  defaultValue={RegisterDate}
                                  placeholder="Enter Type"
                                />
                              </Col>
                              <Col className="col-md-6 col-lg-2 col-12 mb-md-3 mb-3 mb-lg-0">
                                <p className="mb-2 fontWeight500"> Register Time </p>
                                <Input
                                  type="text"
                                  readOnly
                                  className=""
                                  id="txtType"
                                  defaultValue={RegisterTime}
                                  placeholder="Enter Type"
                                />
                              </Col>

                            </Row>
                          </Col>
                          <Col className="col-md-4 col-lg-2 col-12 float-end d-none d-md-block">
                            <div className="">
                              <img
                                className="rounded-circle float-end clientactimg"
                                src={CustomerProfileImage}
                              ></img>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-lg-12 col-12">
                    <Card>
                      <CardBody>
                        <div className="accordion">
                          <div className="accordion-item">
                            <h2 className="accordion-header" >
                              <button className="accordion-button" type="button">Follow ups{" "}</button>
                            </h2>
                            <div className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <Row>
                                  <div className="col-lg-3">
                                    <div className="mb-3">
                                      <label htmlFor="exampleInputEmail1" className="form-label">Action</label>
                                      <input type="text" className="form-control" placeholder="Enter Action" id="txtAction" aria-describedby="emailHelp" />
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="mb-3">
                                      <label htmlFor="exampleInputEmail1" className="form-label">Next Follow up Date</label>
                                      <input type="date" className="form-control" placeholder="Enter Next Follow up Date" id="txtNextFollowupDate" aria-describedby="emailHelp" />
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="mb-3">
                                      <label htmlFor="exampleInputEmail1" className="form-label">Next Follow up Time</label>
                                      <input type="time" className="form-control" placeholder="Enter Next Follow up Time" id="txtNextFollowupTime" aria-describedby="emailHelp" />
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="mb-3">
                                      <label htmlFor="exampleInputEmail1" className="form-label">Next Follow up Mode</label>
                                      <select id="ddlFollowupMode" className="form-select" aria-label="Default select example">
                                        <option value="0" selected>Select Mode</option>
                                        <option value="Call">Call</option>
                                        <option value="Email">Email</option>
                                        <option value="Meeting">Meeting</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="mb-3">
                                      <label htmlFor="exampleInputEmail1" className="form-label">Notes for Next Follow-up</label>
                                      <textarea type="" rows="2" className="form-control" placeholder="Enter Notes for Next Follow-up" id="txtNotesforNextFollowup" aria-describedby="emailHelp" />
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <a className="btn btn_success btn-sm font-14 me-2" id="lnkFollowupSave" onClick={ClientFollowupSave}>Save</a>
                                    <a className="btn btn_danger btn-sm  font-14" href="#">Cancel</a>
                                  </div>
                                  <div className="col-lg-12 mt-3">
                                    <div className="table-responsive">
                                      <table className="table table-bordered align-middle whitenowrapedit">
                                        <thead>
                                          <tr>
                                            <th className="textaligncenter">#</th>
                                            <th className="textaligncenter">Action</th>
                                            <th className="textaligncenter">Date</th>
                                            <th className="textaligncenter">Time</th>
                                            <th className="textaligncenter">Mode</th>
                                            <th className="textaligncenter">Note</th>
                                            <th className="textaligncenter">Remarks</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {ClientFollowUpList.map(item => (
                                            <tr key={item.FollowUpId}>
                                              <td scope="row" className="text-center">{item.RowNo}</td>
                                              <td className="text-center">{item.Action}</td>
                                              <td className="text-center">{item.SNextFollowupDate}</td>
                                              <td className="text-center">{item.NextFollowupTime}</td>
                                              <td className="text-center">{item.NextFollowupMode}</td>
                                              <td className="text-center">{item.NotesforNextFollowup}</td>
                                              <td className="text-center">
                                                {item.FollowpStatus == 'P' ? <a className="btn btn-outline-success btn-sm" data-bs-toggle="modal" data-bs-target="#ClientFollowupRemark" onClick={e => setClientFollowupId(item.FollowUpId)}>Update Remarks</a> : item.Remarks}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-lg-12">
                    <Card>
                      <CardBody>
                        <div className="accordion" id="accordionExample">
                          {ServicePackageActivities.map(item => (

                            <div className="accordion-item" key={item.RowId}>
                              <h2 className="accordion-header" id={item.AccordionId1}>
                                <button className={item.RowId == 1 ? 'accordion-button' : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target={item.collapse2} aria-expanded={item.RowId == 1 ? 'true' : 'false'} aria-controls={item.collapse1}>
                                  {item.ActivitiesTitle}{" "}
                                  <input type="text" id={item.ActivitiesStatusControlId} defaultValue={item.ActivitiesStatusCode} className="text-danger activity-status fw-bold textborderhide" aria-describedby="emailHelp" />
                                  <input type="hidden" id={item.AddRowCountControlId} defaultValue={0} className="form-control" aria-describedby="emailHelp" />
                                </button>
                              </h2>
                              <div id={item.collapse1} className={item.RowId == 1 ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'} aria-labelledby={item.AccordionId1} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <Row>
                                    {ServicePackageActivitiesDetail.filter(x => parseInt(x.ActivitiesId) == parseInt(item.ActivitiesId)).map(ADitem => (
                                      <div className="col-md-6 col-lg-3 col-12" key={ADitem.RowId}>
                                        <form>
                                          <div className="mb-3">
                                            <label htmlFor={ADitem.FormControlId} className="form-label">{ADitem.ActionTitle}</label>
                                            {ADitem.ActionId == 'Date'
                                              ?
                                              <input type="date" id={ADitem.FormControlId} className="form-control" aria-describedby="emailHelp" onChange={e => GetActionStatusCode(item.ServicePackageId, item.ActivitiesId, ADitem.ActionId, ADitem.ActionTitle, ADitem.FormControlId, item.ActivitiesStatusControlId)} />
                                              :
                                              ADitem.ActionId == 'Text'
                                                ?
                                                <input type="text" className="form-control" placeholder={ADitem.FormControlPlaceholder} id={ADitem.FormControlId} aria-describedby="emailHelp" onChange={e => GetActionStatusCode(item.ServicePackageId, item.ActivitiesId, ADitem.ActionId, ADitem.ActionTitle, ADitem.FormControlId, item.ActivitiesStatusControlId)} />
                                                :
                                                ADitem.ActionId == 'TextArea'
                                                  ?
                                                  <textarea type="" rows="1" className="form-control" placeholder={ADitem.FormControlPlaceholder} id={ADitem.FormControlId} aria-describedby="emailHelp" onChange={e => GetActionStatusCode(item.ServicePackageId, item.ActivitiesId, ADitem.ActionId, ADitem.ActionTitle, ADitem.FormControlId, item.ActivitiesStatusControlId)} />
                                                  :
                                                  ADitem.ActionId == 'Number'
                                                    ?
                                                    <input type="number" className="form-control" placeholder={ADitem.FormControlPlaceholder} id={ADitem.FormControlId} aria-describedby="emailHelp" onChange={e => GetActionStatusCode(item.ServicePackageId, item.ActivitiesId, ADitem.ActionId, ADitem.ActionTitle, ADitem.FormControlId, item.ActivitiesStatusControlId)} />
                                                    :
                                                    ADitem.ActionId == 'Dropdown'
                                                      ?
                                                      <select id={ADitem.FormControlId} className="form-select" onChange={e => GetActionStatusCode(item.ServicePackageId, item.ActivitiesId, ADitem.ActionId, ADitem.ActionTitle, ADitem.FormControlId, item.ActivitiesStatusControlId)}>
                                                        <option value={"0"}>{ADitem.DropdownPlaceholder}</option>
                                                        {MasterList.filter(x => x.RecordType == ADitem.PageId).map(msitem => (
                                                          <option key={msitem.Id} value={msitem.Id}>{msitem.Title}</option>
                                                        ))}
                                                      </select>
                                                      :

                                                      ADitem.ActionId == 'File Upload'
                                                        ?

                                                        <input type="file" className="form-control" placeholder={ADitem.FormControlPlaceholder} id={ADitem.FormControlId} aria-describedby="emailHelp" onChange={getDocumentpath} />

                                                        :
                                                        ADitem.ActionId == 'Checkbox'
                                                          ?

                                                          <div>  <input type="checkbox" placeholder={ADitem.FormControlPlaceholder} id={ADitem.FormControlId} aria-describedby="emailHelp" onChange={e => GetActionStatusCode(item.ServicePackageId, item.ActivitiesId, ADitem.ActionId, ADitem.ActionTitle, ADitem.FormControlId, item.ActivitiesStatusControlId)} />
                                                            <label>  Done</label></div>
                                                          :


                                                          ''
                                            }
                                          </div>
                                        </form>
                                      </div>
                                    ))}

                                  </Row>
                                  <Row>
                                    <div className="col-lg-4 co-md-6 col-12 mb-3">
                                      <button className="btn btn_success btn-sm font-14 me-2" id={item.ButtonControlId} onClick={e => onClickActivitiesAction(item.ActivitiesId, item.ButtonControlId)}>Add</button>
                                      <button className="btn btn_danger btn-sm  font-14">Cancel</button>
                                    </div>
                                  </Row>
                                  <Row>
                                    <Col className="col-lg-12">
                                      <hr />
                                      <CardTitle>Action Log</CardTitle>
                                      <hr />
                                    </Col>
                                    <div className="col-lg-12 mt-3">
                                      <div className="table-responsive">
                                        <table className="table table-bordered align-middle whitenowrapedit">
                                          <thead>

                                            <tr>
                                              <th className="textaligncenter">#</th>
                                              {ServicePackageActivitiesDetail.filter(x => parseInt(x.ActivitiesId) == parseInt(item.ActivitiesId)).map(ADitem => (
                                                <th scope="col" key={ADitem.RowId}>{ADitem.ActionTitle}</th>
                                              ))}
                                              <th className="textaligncenter">Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>

                                            {ServicePackageActivitiesDetailRowCount.filter(x => parseInt(x.ActivitiesId) == parseInt(item.ActivitiesId)).map(rcitem => (
                                              <tr key={rcitem.RowId}>
                                                <td className="textaligncenter">{rcitem.RowId}</td>
                                                {

                                                  ServicePackageActivitiesDetailRow.filter(x => parseInt(x.ActivitiesId) == parseInt(item.ActivitiesId) && parseInt(x.MasterRowId) == parseInt(rcitem.RowId)).map(ritem => (


                                                    <td scope="col" key={ritem.MasterRowId}   >
                                                      {
                                                        ritem.ActionId == 'File Upload' ?
                                                          <a href={ritem.LabelControlTitle} target="_blank" rel="noopener noreferrer">{ritem.UploadDocumentFileName}</a>
                                                          :
                                                          <input type="text" id={ritem.LabelControlId} defaultValue={ritem.LabelControlTitle} readOnly className="form-control textborderhide" />
                                                      }

                                                      <input type="hidden" id={ritem.HiddenControlId} defaultValue={ritem.LabelControlTitle} className="form-control" />
                                                    </td>


                                                  ))}
                                                <td className="textaligncenter">
                                                  <button className="text-primary deletebuttonclass" onClick={e => EditActivitiesAction(item.ActivitiesId, rcitem.RowId, item.ButtonControlId)}><i className="mdi mdi-pencil font-size-18"></i></button>
                                                  <button className="text-danger deletebuttonclass" onClick={e => DeleteActivitiesAction(item.ActivitiesId, rcitem.RowId, item.ActivitiesStatusControlId)}><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></button>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </Row>
                                  <Row>
                                    <div className="col-lg-4 co-md-6 col-12 mt-3">
                                      <button className="btn btn_success btn-sm font-14 me-2" onClick={e => SaveActivitiesAction(item.ActivitiesId)}>Save</button>
                                      <button className="btn btn_danger btn-sm  font-14">Cancel</button>
                                    </div>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}
export default UiRating;
