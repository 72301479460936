import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';

const AddCity = () => {
  const history = useHistory();
  const [CountryId, setCountryId] = useState('');
  const [CountryTitle, setCountryTitle] = useState('');
  const [StateId, setStateId] = useState('');
  const [StateTitle, setStateTitle] = useState('');
  const [DistrictId, setDistrictId] = useState('');
  const [DistrictTitle, setDistrictTitle] = useState('');
  const [TehsilId, setTehsilId] = useState('');
  const [TehsilTitle, setTehsilTitle] = useState('');

  const saveCity = async (e) => {
    e.preventDefault();
    let CityTitle = event.target["txtCity"].value;
    if (CityTitle != '') {
      const CityArr = {
        CountryId: CountryId,
        StateId: StateId,
        DistrictId: DistrictId,
        TehsilId: TehsilId,
        CityId: '0',
        Title: CityTitle,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      }

      axios.post(global.APIURL + '/City', CityArr)
        .then(res => {
          alert(res.data.StatusMessage);
          history.push("/City");
        });
    }
  }

  useEffect(() => {
    let VCountryId = localStorage.getItem("CountryId");
    let VCountryTitle = localStorage.getItem("CountryTitle");
    let VStateStateId = localStorage.getItem("DistrictStateId");
    let VStateTitle = localStorage.getItem("DistrictStateTitle");
    let VDistrictId = localStorage.getItem("TehsilDistrictId");
    let VDistrictTitle = localStorage.getItem("TehsilDistrictTitle");
    let VTehsilId = localStorage.getItem("CityTehsilId");
    let VTehsilTitle = localStorage.getItem("CityTehsilTitle");
    setCountryId(VCountryId);
    setCountryTitle(VCountryTitle);
    setStateId(VStateStateId);
    setStateTitle(VStateTitle);
    setDistrictId(VDistrictId);
    setDistrictTitle(VDistrictTitle);
    setTehsilId(VTehsilId);
    setTehsilTitle(VTehsilTitle);
  });

  return (
    <div className="page-content">

      <MetaTags>
        <title>Add City | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Add City</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <a href="/Country" className="ms-2 me-2">{CountryTitle}</a>
              <span>/</span>
              <a href="/State" className="ms-2 me-2">{StateTitle}</a>
              <span>/</span>
              <a href="/District" className="ms-2 me-2">{DistrictTitle}</a>
              <span>/</span>
              <a href="/Tehsil" className="ms-2 me-2">{TehsilTitle}</a>
              <span>/</span>
              <a href="/City" className="me-2 ms-2">City List</a>
              <span>/</span>
              <span className="ms-2">Add City</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-6">
            <a className="btn btn-primary btn-sm float-end font-14" href="/City">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <form onSubmit={saveCity}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">City</label>
                        <Input type="text" className="form-control" id="txtCity" placeholder="Enter City" />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/City">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>

    </div>
  )
}

export default AddCity;