import React, { Component, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input, Label } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import PlacholderImg from "assets/images/favicon.png";

const AddChannelSource = () => {
  const history = useHistory();
  const [StateId, setStateId] = useState("");
  const [ChannelId, setChanneId] = useState("");
  const [ChannelTypeId, setChannelTypeId] = useState("");
  const [ChannelMasterId, setChannelMasterId] = useState(0);
  const [CityId, setCityId] = useState("");
  const [TehsilId, setTehsilId] = useState("");
  const [Pincode, setPincode] = useState("");
  const [DistrictId, setDistrictId] = useState("");
  const [BranchContactNumber, setContactNo] = useState("");
  const [BranchEmailAddress, setEmailAddress] = useState("");
  const [Branchlocation, SetBranchLocation] = useState("");
  const [BranchAddress, setBranchAddress] = useState("");
  const [IFSCCode, SetIFSCCode] = useState("");
  const [BranchCode, SetBranchCode] = useState("");
  const [Branchname, setbranchname] = useState("");
  const [CountryList, setCountryList] = useState([]);
  const [ChannelMasterList, setChannelMasterList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [ChannelTypeList, setChannelTypeList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [ChannelLogo, setChannelLogo] = useState(PlacholderImg);

  const [AddTehsilRights, setAddTehsilRights] = useState('D');
  const [AddCityRights, setAddCityRights] = useState('D');

  useEffect(() => {
    getMenuHideShow();
    getCountryList();
    getChannelTypeList();
  }, [])

  const getMenuHideShow = async () => {
    const f = {
      MenuId: "37",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {
      
        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add Tehsil') {
            setAddTehsilRights(MS);
          }
          else if (MA == 'Add City') {
            setAddCityRights(MS);
          }
        }
      });
  };


  const getChannelTypeList = async () => {
    fetch(global.APIURL + "/ChannalTypeList")
      .then((res) => res.json())
      .then((json) => {
        setChannelTypeList(json)
      })
  }

  const getChannelMasterList = async (CTId) => {
    var AUrl = global.APIURL + '/ChannelMasterActiveList/' + CTId;
    fetch(AUrl)
      .then((res) => res.json())
      .then((json) => {
        setChannelMasterList(json)
      })
  }

  const getCountryList = async () => {
    fetch(global.APIURL + "/CountryActiveList")
      .then((res) => res.json())
      .then((json) => {
        setCountryList(json)
      })
  }

  const getStateList = async (CId) => {
    var AUrl = global.APIURL + '/ActiveState/' + CId;
    axios.get(AUrl)
      .then(Sres => {
        let StateL = Sres.data.map((kcstate) => {
          return kcstate
        })
        setStateList(StateL);
      })
  }

  const getDistrictList = async (SId) => {
    var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
    axios.get(AUrl)
      .then(Cires => {
        let DistrictL = Cires.data.map((kcDistrict) => {
          return kcDistrict
        })
        setDistrictList(DistrictL);
      })
  }

  const getTehsilList = async DId => {
    var AUrl = global.APIURL + '/ActiveTehsil/' + DId;
    axios.get(AUrl)
      .then(Dres => {
        let TehsilL = Dres.data.map(kctehsil => {
          return kctehsil;
        });
        setTehsilList(TehsilL);
      });
  };

  const getCityList = async (TId) => {
    var AUrl = global.APIURL + '/ActiveCity/' + TId;
    axios.get(AUrl)
      .then(Cires => {
        let CityL = Cires.data.map((kccity) => {
          return kccity
        })
        setCityList(CityL);
      })
  }

  const getChannelMasterDetail = async (CMId) => {
    var AUrl = global.APIURL + '/ChannelMasterEdit/' + CMId;
    axios.get(AUrl)
      .then(res => {
        setChannelLogo(res.data[0].ChannelLogo);
      })
  }

  const UserDetailSave = async (e) => {
    e.preventDefault();

    const UserDetailArr = {
      action: "Add",
      ChannelId: "0",
      IsExistingBranch: "No",
      ChannelImage: ChannelLogo,
      ChannelTypeId: ChannelTypeId,
      ChannelMasterId: ChannelMasterId,
      IsNewChannel: "Yes",
      ExistingChannelId: "0",
      ExistingBranchId: "0",
      ChannelName: ChannelId,
      BranchName: Branchname,
      BranchCode: BranchCode,
      BranchIFSCCode: IFSCCode,
      BranchStateID: StateId,
      BranchCityID: CityId,
      BranchTehsilId: TehsilId,
      BranchPincode: Pincode,
      BranchContactNumber: BranchContactNumber,
      BranchEmailAddress: BranchEmailAddress,
      BranchAddress: BranchAddress,
      BranchLocation: Branchlocation,
      BranchDistrictId: DistrictId,
      Flag: "A",
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    }

  
    axios.post(global.APIURL + '/ChannelAddEditWeb', UserDetailArr)
      .then(res => {
        if (res.data.length > 0) {
          alert(res.data);
          history.push("/ChannelSource");
        }
      });

  }

  function ChannelLogofetchPost(e) {
    var details = e.target.files[0];
    let arr = details.name.toString().split(".");
    var FileEx = arr[1];
    var isUpload = 0;
    if (FileEx == 'jpg') {
      isUpload++;
    }
    else if (FileEx == 'JPG') {
      isUpload++;
    }
    else if (FileEx == 'jpeg') {
      isUpload++;
    }
    else if (FileEx == 'JPEG') {
      isUpload++;
    }
    else if (FileEx == 'png') {
      isUpload++;
    }
    else if (FileEx == 'PNG') {
      isUpload++;
    }
    else if (FileEx == 'gif') {
      isUpload++;
    }
    else if (FileEx == 'GIF') {
      isUpload++;
    }
    if (parseInt(isUpload) > 0) {
      var details1 = "ChannelSource";
      const formData = new FormData();
      formData.append('Document', details);
      formData.append('FolderName', details1);
      var AUrl = global.APIURL + '/uploadavatar/';
      axios.post(AUrl, formData,
        {
          headers: { "Content-Type": details.type },
        })
        .then((res) => {
          setChannelLogo(res.data.IName);
          document.getElementById("IGProfileImage").src = res.data.IName;
        });
    }
    else {
      document.getElementById("fuChannelLogo").value = '';
      alert("Please upload file .jpg or .jpeg or .png or .gif image!");
    }
  }

  const TehsilDetailSave = async (e) => {
    e.preventDefault();
    var VTehsilTitle = document.getElementById("txtTehsil").value;
    if (VTehsilTitle != '') {
      const TehsilArr = {
        TehsilId: '0',
        CountryId: document.getElementById("ddlCountry").value,
        StateId: document.getElementById("ddlState").value,
        DistrictId: document.getElementById("ddlDistrict").value,
        Title: VTehsilTitle,
        CreateUser: "1"
      }

      axios.post(global.APIURL + '/Tehsil', TehsilArr)
        .then(res => {
          alert(res.data);
          var DistrictId = document.getElementById("ddlDistrict").value;
          getTehsilList(DistrictId);
        });
    }
    document.getElementById("txtTehsil").value = '';
  }

  const TehsilClose = async (e) => {
    e.preventDefault();
    document.getElementById("txtPerTehsil").value = '';
  }

  const CityDetailSave = async (e) => {
    e.preventDefault();
    var VCityTitle = document.getElementById("txtCity").value;
    if (VCityTitle != '') {
      const CityArr = {
        CountryId: document.getElementById("ddlCountry").value,
        StateId: document.getElementById("ddlState").value,
        DistrictId: document.getElementById("ddlDistrict").value,
        TehsilId: document.getElementById("ddlTehsil").value,
        CityId: '0',
        Title: VCityTitle,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      }
      axios.post(global.APIURL + '/City', CityArr)
        .then(res => {
          alert(res.data.StatusMessage);
          var VTehsilId = document.getElementById("ddlTehsil").value;
          getCityList(VTehsilId)
        });
    }
    document.getElementById("txtCity").value = '';
  }

  const CityClose = async (e) => {
    e.preventDefault();
    document.getElementById("txtCity").value = '';
  }

  return (
    <div className="page-content">

      <MetaTags>
        <title>Add Channel Source | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">
        <div className="modal fade bd-example-modal-sm" id="AddTehsil" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add Tehsil</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={TehsilClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>

                  <Col lg={12} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Tehsil</label>
                      <Input type="text" className="form-control" id="txtTehsil" placeholder="Enter Tehsil" />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={TehsilDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={TehsilClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-sm" id="AddCity" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add City</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={CityClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>

                  <Col lg={12} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">City</label>
                      <Input type="text" className="form-control" id="txtCity" placeholder="Enter City" />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={CityDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={CityClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-12 mb-2">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Add Channel Source</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <a href="/ChannelSource" className="ms-2 me-2">Channel Source</a>
              <span>/</span>
              <span className="ms-2">Add Channel Source</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-12">
            <a className="btn btn-primary btn-sm float-end font-14" href="/ChannelSource">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <form onSubmit={UserDetailSave}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <Row>

                    <Col lg={3} className="mt-2">
                      <div className="mb-3 labeledit text-center">
                        <div>
                          <label htmlFor="uploadimage" className="form-label">
                            Upload Image
                          </label>
                        </div>
                        <img id="IGProfileImage" src={ChannelLogo} className="channellogoimage"></img>
                        <input type="file" id="fuChannelLogo" className="form-control form-control mt-3" onChange={ChannelLogofetchPost} />
                      </div>
                    </Col>
                    <Col lg={9} className="mt-2">
                    </Col>
                    <Col lg={4} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Channel Type</label>
                        <select className="form-select" Id="ddlChanneltype" name="ddlChanneltype" onChange={(e) => { setChannelTypeId(e.target.value); getChannelMasterList(e.target.value) }}>
                          <option value={"0"}>Select Channel Type</option>
                          {ChannelTypeList.map((ChannelType) => (
                            <option key={ChannelType.ChannelTypeId} value={ChannelType.ChannelTypeId}> {ChannelType.ChannelTypeName} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={4} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Channel Master</label>
                        <select className="form-select" Id="ddlChannelMaster" name="ddlChannelMaster" onChange={(e) => { getChannelMasterDetail(e.target.value); setChannelMasterId(e.target.value) }}>
                          <option value={"0"}>Select Channel Master</option>
                          {ChannelMasterList.map((ChannelMaster) => (
                            <option key={ChannelMaster.ChannelMasterId} value={ChannelMaster.ChannelMasterId}> {ChannelMaster.Title} </option>
                          ))}
                        </select>
                      </div>
                    </Col>

                    <Col lg={4} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Channel Source</label>
                        <Input type="text" className="form-control" id="txtChannelName" placeholder="Enter Channel Source" onChange={(e) => setChanneId(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={4} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Branch Name</label>
                        <Input type="text" className="form-control" id="txtBranchName" placeholder="Enter BranchName" onChange={(e) => setbranchname(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={4} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label"> Branch Code</label>
                        <Input type="text" className="form-control" id="txtBranchCode" placeholder="Enter BranchCode" onChange={(e) => SetBranchCode(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={4} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label"> IFSC Code</label>
                        <Input type="text" className="form-control" id="txtIFSC" placeholder="Enter IFSC" onChange={(e) => SetIFSCCode(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={4} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label"> Branch Contact No</label>
                        <Input type="text" className="form-control" id="txtContactNo" placeholder="Enter ContactNo" onChange={(e) => setContactNo(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={4} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label"> Branch Email Address</label>
                        <Input type="text" className="form-control" id="txtEmail" placeholder="Enter Email" onChange={(e) => setEmailAddress(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={4} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label"> Branch Location</label>
                        <Input type="text" className="form-control" id="txtBranchLocation" placeholder="Enter Branch Location" onChange={(e) => SetBranchLocation(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-2">
                        <label className="form-label">Address</label>
                        <Input type="textarea" className="form-control" id="txtAddress" placeholder="Enter Address" onChange={(e) => setBranchAddress(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={2} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Country</label>
                        <select className="form-select" Id="ddlCountry" name="ddlState" onChange={(e) => getStateList(e.target.value)}>
                          <option value={"0"}>Select Country</option>
                          {CountryList.map((country) => (
                            <option key={country.CountryId} value={country.CountryId}> {country.Title} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={2} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">State</label>
                        <select className="form-select" Id="ddlState" name="ddlState" onChange={(e) => { getDistrictList(e.target.value); setStateId(e.target.value) }}>
                          <option value={"0"}>Select State</option>
                          {StateList.map((state) => (
                            <option key={state.StateId} value={state.StateId}> {state.Title} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={2} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">District</label>
                        <select className="form-select" Id="ddlDistrict" name="ddlDistrict" onChange={e => { getTehsilList(e.target.value); setDistrictId(e.target.value) }}>
                          <option value={"0"}>Select District</option>
                          {DistrictList.map((district) => (
                            <option key={district.DistrictId} value={district.DistrictId} selected={DistrictId === district.DistrictId}> {district.Title} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={2} className="mt-2">
                      <div className="mb-2">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label htmlFor="formFileSm" className="form-label">Tehsil</label>
                          </div>
                          <div>
                            {AddTehsilRights == "A" ? DistrictId > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddTehsil">+ Add New</a></span> : '' : ''}
                          </div>
                        </div>
                        <select className="form-select" Id="ddlTehsil" name="ddlTehsil" onChange={(e) => { getCityList(e.target.value); setTehsilId(e.target.value) }}>
                          <option value={"0"}>Select Tehsil</option>
                          {TehsilList.map((Tehsil) => (
                            <option key={Tehsil.TehsilId} value={Tehsil.TehsilId}> {Tehsil.Title} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={2} className="mt-2">
                      <div className="mb-2">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label htmlFor="formFileSm" className="form-label">City/Village/Town</label>
                          </div>
                          <div>
                            {AddCityRights == "A" ? TehsilId > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddCity">+ Add New</a></span> : '' : ''}
                          </div>
                        </div>
                        <select className="form-select" Id="ddlCity" name="ddlCity" onChange={(e) => setCityId(e.target.value)}>
                          <option value={"0"}>Select City/Village/Town</option>
                          {CityList.map((city) => (
                            <option key={city.CityId} value={city.CityId}> {city.Title} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={2} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Pincode</label>
                        <Input type="text" className="form-control" id="txtPincode" placeholder="Enter Pincode" onChange={(e) => setPincode(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={12} className="mt-2">
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/ChannelSource">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>

    </div>
  )
}

export default AddChannelSource;