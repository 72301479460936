import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Input, Form, Button } from "reactstrap";
import { useHistory } from 'react-router-dom';
import { Search } from "react-bootstrap-table2-toolkit";
import "../../../assets/scss/datatables.scss";
import axios from "axios";
import PlacholderImgC from "assets/images/favicon.png";
import { Link } from "react-router-dom";


const { SearchBar } = Search;

const bureauhelplink = () => {
  const history = useHistory();

  const [HelpLinkList, setHelpLinkList] = useState([]);
  const [BureauDataList, setBureauDataList] = useState([]);
  const [BureauHelpLinkId, setBureauHelpLinkId] = useState(0);
  const [BureauHelpLinkType, setBureauHelpLinkType] = useState('');
  const [BureauHelpLink,setBureauHelpLink]=useState("");
  const [BureauHelpLinkFileName,setBureauHelpLinkFileName]=useState("");

  useEffect(() => {
    getBureauDataList();
  }, []);

  const getBureauDataList = async () => {
    var ACUrl = global.APIURL + '/BureauCategory';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setBureauDataList(CityL);
      });
  };

  // const getbureausubcategoryDataList = async (VBId) => {
  //     var ACUrl = global.APIURL + '/BureauSubCategory';
  //     var subcatgory = {
  //         BId: VBId
  //     }

  //     axios.post(ACUrl, subcatgory)
  //         .then(Cires => {
  //             let CityL = Cires.data.map(kccity => {
  //                 return kccity;
  //             });
  //             setBureauDatacategoryList(CityL);
  //         });
  // };

  const GetBureauhelpLinkByBureau = async () => {
    var AUrl = global.APIURL + '/BureauHelpLinkListByBureau';
    var VBureauId = document.getElementById('ddlBureau').value;
    var ca = {
      BureauId: parseInt(VBureauId == null ? '0' : VBureauId == '' ? '0' : VBureauId)
    }
    axios.post(AUrl, ca)
      .then(LCdres => {
        console.log("res--",LCdres);
        let BureauhelpLinkList = LCdres.data.map((VBureauhelpLink) => {
          return VBureauhelpLink
        })
        setHelpLinkList(BureauhelpLinkList);
      });
  }

  const EditBureauhelpLinkDetail = async (e, VBureauHelpLinkId) => {
    var AUrl = global.APIURL + '/BureauHelpLinkDetail';
    var ca = {
      BureauHelpLinkId: parseInt(VBureauHelpLinkId == null ? '0' : VBureauHelpLinkId == '' ? '0' : VBureauHelpLinkId)
    }

    axios.post(AUrl, ca)
      .then(res => {
        console.log("edit-res",res)
        if (res.data.length > 0) {
          setBureauHelpLinkId(res.data[0].BureauHelpLinkId);
          document.getElementById('txtTitle').value = res.data[0].BureauHelpLinkTitle;
          setBureauHelpLinkType(res.data[0].BureauHelpLinkType);
          setBureauHelpLink(res.data[0].BureauHelpLink);
          setBureauHelpLinkFileName(res.data[0].BureauHelpLinkFileName);
        }
        else {
          setBureauHelpLinkId(0);
          document.getElementById('txtTitle').value = '';
          document.getElementById('txtLink').value = '';
        }
      })
  }

  const BureauHelpLinkHandleRemoveRow = (e, BureauHelpLinkId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const VBureauHelpLinkId = parseInt(BureauHelpLinkId == null ? '0' : BureauHelpLinkId == '' ? '0' : BureauHelpLinkId);
      if (VBureauHelpLinkId > 0) {
        const BureauHelpLinkArr =
        {
          BureauHelpLinkId: VBureauHelpLinkId,
          CreateUser: localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")
        }
        axios.post(global.APIURL + '/BureauHelpLinkDelete', BureauHelpLinkArr).then(resem => {
          alert('Delete successfully.');
          GetBureauhelpLinkByBureau();
        });
      }
    }
  };

  const SaveBureauHelpLinkDeails = async () => {
    var VHelpLinkTitle = document.getElementById('txtTitle').value;
    var VBureauId = document.getElementById('ddlBureau').value;
    var IBureauId = parseInt(VBureauId == null ? '0' : VBureauId == '' ? '0' : VBureauId);

    let IsDataInsert = 1;

    if (IBureauId == 0) {
      alert("Please select bureau.");
      IsDataInsert = 0;
      return;
    }
    if (VHelpLinkTitle == "") {
      alert("Enter Help Link Title.");
      IsDataInsert = 0;
      return;
    }
    if (BureauHelpLink == "") {
      alert("Enter Help Link.");
      IsDataInsert = 0;
      return;
    }

    if (IsDataInsert == 1) {
      const HelpLinkItemArr = {
        BureauHelpLinkId: 0,
        BureauId: parseInt(VBureauId == null ? '0' : VBureauId == '' ? '0' : VBureauId),
        BureauHelpLinkTitle: VHelpLinkTitle,
        BureauHelpLinkType:BureauHelpLinkType,
        BureauHelpLink: BureauHelpLink,
        CreateUser: localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId"),
      };

      console.log("HelpLinkItemArr",HelpLinkItemArr);

      axios.post(global.APIURL + '/BureauHelpLinkInsert', HelpLinkItemArr).then((res) => {
        alert("Save successfully.");        
        document.getElementById('txtTitle').value = '';
        if(BureauHelpLinkType=="Link"){
          document.getElementById('txtLink').value = '';          
        }
        else{
          setBureauHelpLink("");
          setBureauHelpLinkFileName("");
        }     
        setBureauHelpLinkType('');
        GetBureauhelpLinkByBureau();
      });
    }
  };

  const UpdateBureauHelpLinkDeails = async () => {
    var VHelpLinkTitle = document.getElementById('txtTitle').value;
   // var VHelpLink = document.getElementById('txtLink').value;
    var VBureauId = document.getElementById('ddlBureau').value;
    var IBureauId = parseInt(VBureauId == null ? '0' : VBureauId == '' ? '0' : VBureauId);

    let IsDataInsert = 1;

    if (IBureauId == 0) {
      alert("Please select bureau.");
      IsDataInsert = 0;
      return;
    }
    if (VHelpLinkTitle == "") {
      alert("Enter Help Link Title.");
      IsDataInsert = 0;
      return;
    }
    if (BureauHelpLink == "") {
      alert("Enter Help Link.");
      IsDataInsert = 0;
      return;
    }

    if (IsDataInsert == 1) {
      const HelpLinkItemArr = {
        BureauHelpLinkId: BureauHelpLinkId,
        BureauId: parseInt(VBureauId == null ? '0' : VBureauId == '' ? '0' : VBureauId),
        BureauHelpLinkTitle: VHelpLinkTitle,
        BureauHelpLinkType:BureauHelpLinkType,
        BureauHelpLink: BureauHelpLink,
        CreateUser: localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId"),
      };

      axios.post(global.APIURL + '/BureauHelpLinkUpdate', HelpLinkItemArr).then((res) => {
        alert("Update successfully.");
        setBureauHelpLinkId(0);
        document.getElementById('txtTitle').value = '';
        if(BureauHelpLinkType=="Link"){
          document.getElementById('txtLink').value = '';
        }
        else{
          setBureauHelpLink("");
          setBureauHelpLinkFileName("");
        }
        setBureauHelpLinkType('');
        GetBureauhelpLinkByBureau();
      });
    }
  };
  const ClearBureauHelpLinkDeails = async () => {
    document.getElementById('txtTitle').value = '';
    document.getElementById('txtLink').value = '';
    setBureauHelpLinkId(0);
  };

  function onChangeLinkTypeValue(event) {

    setBureauHelpLinkType(event.target.value);
  }

  function fetchPost(e){
    var details = e.target.files[0];
    var details1 = "Bureau";
  
    const formData = new FormData();
   
    formData.append('Document', details);
    formData.append('FolderName', details1);

    axios.post(global.APIURL +'/uploadavatar',formData,{
      
         headers: { "Content-Type": details.type},
    
    }) .then((res) => {
        
         setBureauHelpLink(res.data.IName);
         setBureauHelpLinkFileName(res.data.FileName);
        }); 
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">

          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Bureau Help Link</CardTitle>
                <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <span className="ms-2">Bureau Help Link</span>
              </div>
            </Col>
          </Row>

          <div className="ind-card" id="ind-card">
            <Card>
              <CardBody>
                <Row>
                  <div className="col-lg-3 col-md-6">
                    <div className="mb-3">
                      <label htmlFor="Bureau" className="form-label">
                        Bureau
                      </label>
                      <select className="form-select" Id="ddlBureau" name="ddlBureau" onChange={e => GetBureauhelpLinkByBureau()}>
                        <option value={"0"}>Select Bureau</option>
                        {BureauDataList.map(Bureau => (
                          <option key={Bureau.Id} value={Bureau.Id}>
                            {" "}
                            {Bureau.value}{" "}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </Row>
                <Col lg="12">
                  <div data-repeater-list="group-a" className="mb-3">
                    <Row data-repeater-item className="align-items-end">
                      <Col lg="4" className="">
                        <Label>
                          Title
                        </Label>
                        <Input type="text" id="txtTitle" className="form-control" placeholder="Enter Bureau Help Link Title" />
                      </Col>
                      <Col className="col-lg-1">
                        <div className="form-check bureau-help-link">
                          <input type="radio" id="1" name="radio1" className="form-check-input" value="Link" onChange={onChangeLinkTypeValue} checked={BureauHelpLinkType==="Link"}/>
                          <Label className="form-check-label" htmlFor="radio2" >Link</Label>
                        </div>
                      </Col>
                      <Col className="col-lg-2">
                        <div className="form-check">
                          <input type="radio" id="1" name="radio1" className="form-check-input" value="File Upload" onChange={onChangeLinkTypeValue} checked={BureauHelpLinkType==="File Upload"} />
                          <Label className="form-check-label" htmlFor="radio2" >File Upload</Label>
                        </div>
                      </Col>
                      <Col lg="5">
                      </Col>
                      {BureauHelpLinkType == "Link" ?
                        <Col lg="6" className="mt-3">
                          <Label >
                            Link
                          </Label>                                            
                          <Input type="text" id="txtLink" className="form-control" placeholder="Enter Bureau Help Link" defaultValue={BureauHelpLink} onChange={e=>setBureauHelpLink(e.target.value)} />
                        </Col> : ''}

                        {BureauHelpLinkType == "File Upload" ?
                        <Col lg="6" className="mt-3">
                          <Label >
                            File Upload
                          </Label>
                          <span className="float-end text-primary font-12" id="spandoctitle">
                          <a href={BureauHelpLink} target="_blank" rel="noopener noreferrer" >{BureauHelpLinkFileName}</a></span> 
                          <Input type="file" id="fuFileUpload" className="form-control" onChange={fetchPost}/>
                        </Col> : ''}
                      <Col lg="1" className="mt-3">
                      </Col>
                    
                    </Row>

                    <Row data-repeater-item className="align-items-end">
                      <Col lg="4" className="mt-4">
                      {BureauHelpLinkId > 0 ?
                          <a className="btn btn_success me-2" onClick={() => UpdateBureauHelpLinkDeails()}> Update </a>
                          :
                          <a className="btn btn_success me-2" onClick={() => SaveBureauHelpLinkDeails()}> Save </a>
                        }
                        <a className="btn btn_danger me-2" onClick={() => ClearBureauHelpLinkDeails()}> Cancel </a> 
                                {/* <Button onClick={HelpLinkhandleAddRow} color="primary" className="mb-1"><i className="fas fa-plus me-2"></i>Add</Button> */}
                         </Col>
                      </Row>
                  </div>
                  <hr className="mb-3" />
                  <table style={{ width: "100%" }} className="table table-bordered table-striped" id="as-react-datatable">
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th>Bureau Help Link Title</th>
                        <th className="text-center">Bureau Help Link Type</th>
                        <th>Bureau Help Link</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {HelpLinkList.map((item, idx) => (
                        <tr id={"addr" + idx} key={idx}>
                          <td className="text-center">{idx + 1}</td>
                          <td>{item.BureauHelpLinkTitle}</td>
                          <td className="text-center">{item.BureauHelpLinkType}</td>
                          <td>
                            
                          
                            {item.BureauHelpLinkType==="File Upload"?
                                <a href={item.BureauHelpLink} target="_blank" rel="noopener noreferrer">{item.BureauHelpLinkFileName}</a>: 
                                 <a href={item.BureauHelpLink} target="_blank" rel="noopener noreferrer">{item.BureauHelpLink}</a>
                          }
                            </td>
                          <td className="text-center">
                            <a onClick={e => EditBureauhelpLinkDetail(e, item.BureauHelpLinkId)} color="danger" className="me-3 text-primary">
                              <i className="mdi mdi-pencil font-size-18"></i>
                            </a>
                            <a onClick={e => BureauHelpLinkHandleRemoveRow(e, item.BureauHelpLinkId)} color="danger" className="text-danger">
                              <i className="fas fa-trash-alt me-2"></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </Col>




              </CardBody>
            </Card>

          </div>

        </div>
      </div>
    </React.Fragment >
  );
};

export default bureauhelplink;

