import React, { Component} from "react"
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
 
const AddDocumentType = () => { 
    const history = useHistory();
    const DocumentTypeSave = async (e) => {
        e.preventDefault();
        let VDocumentType=event.target["txtDocumentType"].value; 
        let VDescription=event.target["txtDocumentDescription"].value;
        if(VDocumentType!='')
        {
            const DocumentType = {
              DocumentId:'0',
              DocumentType: VDocumentType,
              Description: VDescription,
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
                    
            }

            axios.post(global.APIURL+'/DocumentType', DocumentType)
            .then(res => 
            {
                    alert(res.data.StatusMessage);
                    history.push("/DocumentType");
            });
        }
    }
    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Add Document Type | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-12">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Add Document Type</CardTitle>
                 <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/DocumentType" className="ms-2 me-2">Document Type</a>
                <span>/</span>
                <span className="ms-2">Add Document Type</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-12">
              <a className="btn btn-primary btn-sm float-end font-14" href="/DocumentType">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={DocumentTypeSave}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Document Type</label>
                        <Input type="text" className="form-control" id="txtDocumentType" placeholder="Enter Document Type"/>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-2">
                        <label className="form-label">Document Description</label>
                        <Input type="textarea" className="form-control" id="txtDocumentDescription" placeholder="Enter Document Description"/>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/AddDocumentType">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
       
      </div>
    )
}

export default AddDocumentType;