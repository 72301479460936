import React, { useState, useEffect }  from "react"
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
 
const AddDocument = () => { 
    const history = useHistory();
    const [DocumentTypeList, setDocumentTypeList] = useState([]);
    const [DocumentTypeId, setDocumentTypeId] = useState("0");

    useEffect(() => {
        getDocumentTypeList();
     
      });

      const getDocumentTypeList = async () => {
        axios.get(global.APIURL+'/ActiveDocumentType')
          .then(DTres => {
            let DTList = DTres.data.map(documenytype => {
              return documenytype;
            });
            setDocumentTypeList(DTList);
          });      
      };
    
  const [posts, setPosts] = useState('');
  const [de, setde] = useState("");
    const DocumentTypeSave = async (e) => {
        e.preventDefault();
        let VDocumentType=event.target["txtDocumentTitle"].value; 
        let VDescription=event.target["txtDocumentDescription"].value;
        if(VDocumentType!='')
        {
            const DocumentType = {
                DocumentId:'0',
                DocumentTypeId: DocumentTypeId,
                Title: VDocumentType,
                Description :VDescription,
                Url:posts,
                Flag : "A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
                      
            }

            axios.post(global.APIURL+'/Document', DocumentType)
            .then(res => 
            {
                    alert(res.data.StatusMessage);
                    history.push("/Document");
            });
        }
    }

    function fetchPost(e){
        var details = e.target.files[0];
        var details1 = "Document";
        setde(details);
        const formData = new FormData();
       
        formData.append('Document', details);
        formData.append('FolderName', details1);
    
        axios.post('http://localhost:8282/uploadavatar',formData,{
          
             headers: { "Content-Type": details.type},
        
        }) .then((res) => {
            
              setPosts(res.data.IName);
           
    
            });
      
      }
    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Add Document  | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-12">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Add Document </CardTitle>
                 <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/Document" className="ms-2 me-2">Document</a>
                <span>/</span>
                <span className="ms-2">Add Document</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-12">
              <a className="btn btn-primary btn-sm float-end font-14" href="/Document">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={DocumentTypeSave}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
                  <Row>
                  <Col lg={4}>
                          <div className="mb-3">
                                <label className="form-label">Document Type</label>
                                  <select id="ddlDocumentType" className="form-select" onChange={e => setDocumentTypeId(e.target.value)}>
                                    <option value={"0"}>Select Documet type</option>
                                    {DocumentTypeList.map(doctype => (
                                      <option key={doctype.DocumentId} value={doctype.DocumentId}>{doctype.DocumentType}</option>
                                    ))}
                                  </select>
                                </div>
                          </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Title</label>
                        <Input type="text" className="form-control" id="txtDocumentTitle" placeholder="Enter Document Type"/>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-2">
                        <label className="form-label">Document Description</label>
                        <Input type="textarea" className="form-control" id="txtDocumentDescription" placeholder="Enter Document Description"/>
                      </div>
                    </Col>
                    <Col lg={4} className="mt-2">
                  <div className="mb-3 labeledit">
                    <label htmlFor="uploadimage" className="form-label">
                    Document
                    </label>
                  
                       <input type="file" className="form-control form-control" name="file" onChange={fetchPost}/>
                  </div>
                </Col>
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/AddDocument">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
       
      </div>
    )
}

export default AddDocument;