import React,{ useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory,useParams  } from 'react-router-dom';

 
const EditBureauSubcategory = () => { 
      
      const [BsubId, setBsubId] = useState('');
      const [BId, setBId] = useState('');
      const [Title, setTitle] = useState('');
      const [Description, setDescription] = useState('');
      const [bureauList, setbureauList] = useState([]);
      const [bureauId, setbureauId] = useState("0");
      const history = useHistory();
      let { id } = useParams();

     

      const BureaucategoryUpdate  = async (e) => {
        e.preventDefault();
        let VBsub=BsubId; 
        let VTitle=event.target["txtTitle"].value; 
        let VDescription=event.target["txtDescription"].value;
        
            const Bureaucategory = {
                BsubId:VBsub,
                BId : bureauId,
                Title: VTitle,
                Description: VDescription,
                Flag : "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                   
            }
           

            axios.post(global.APIURL+'/bureauSubcategoryUpdate', Bureaucategory)
            .then(res => 
            {
                    alert("Update Successfully");
                    history.push("/BureauSubcategoryList");
            });
        
    }

    useEffect(() => {
      
        if(id!=null)
        {
          if(id!="0")
          {
            let newid = id.split(':'); 
            getDocumentTypeById(newid[1]);
            getbureauList();
          }
          else{
            history.push("/BureauSubcategoryList");
          }
        }
        else{
          history.push("/BureauSubcategoryList");
        }
    }, []);
 

    const getbureauList = async () => {
        axios.get(global.APIURL+'/BureauCategory')
          .then(DTres => {
            let DTList = DTres.data.map(documenytype => {
              return documenytype;
            });
            setbureauList(DTList);
          });      
      };

    const getDocumentTypeById = async (DId) => {
      var AUrl=global.APIURL+'/bureauSubcategoryEdit/'+DId;
        axios.get(AUrl)  
        .then(res => {  
         
          setbureauId(res.data[0].BId);
          setBsubId(res.data[0].BsubId);
          setTitle(res.data[0].Title);
          setDescription(res.data[0].Description);
          
        })
    }

    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Edit Bureau Sub Category | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Edit Bureau Sub Category</CardTitle>
                 <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/BureauSubcategoryList"><span className="ms-2 me-2">Bureau  Sub Category</span></a>
                <span>/</span>
                <span className="ms-2">Edit Bureau  Sub Category</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/BureauSubcategoryList">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={BureaucategoryUpdate}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                  <Row>
                  <Col lg={4}>
                          <div className="mb-3">
                                <label className="form-label">Bureau</label>
                                  <select id="ddlbureau" className="form-select" onChange={e => setbureauId(e.target.value)}>
                                    <option value={"0"}>Select Bureau</option>
                                    {bureauList.map(b => (
                                      <option key={b.Id} value={b.Id} selected={bureauId === b.Id}>{b.value}</option>
                                    ))}
                                  </select>
                                </div>
                          </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Title</label>
                        <Input type="text" className="form-control" id="txtTitle" defaultValue={Title} placeholder="Enter Title"/>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-2">
                        <label className="form-label">Description</label>
                        <Input type="textarea" className="form-control" id="txtDescription" defaultValue={Description} placeholder="Enter Description"/>
                      </div>
                    </Col>
                    <Col lg={12}>    
                        <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                        <a className="btn btn_danger btn-sm ms-2" href="/bureaucategoryList">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
      </div>
    )
}

export default EditBureauSubcategory;