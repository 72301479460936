import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';

import LinkApp from 'App';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';



const OnSort = () => {


  let columns = [
    {
      key: "SR",
      text: "#",
      sortable: true
    },



    {
      key: "DetailImage",
      text: "Image",
      width: "250px",
      cell: (record, index) => {
        return (
          <Fragment>
            <img src={record.DetailImage} style={{ height: "60px", width: "60px" }}></img>
          </Fragment>
        );
      },
      sortable: true
    },


    {
      d: "action",
      text: "Description",
      width: "250px",
      cell: (record, index) => {
        let Description = record["DetailDescription"];
        return (
          <Fragment>
            <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal1" className="btn btn-sm btn-primary" onClick={getDesc.bind(this, record)}>View Description</button>

          </Fragment>
        );
      }
    },

    {
      d: "action",
      text: "Title",
      cell: (record, index) => {
        let DetailTitle = record["DetailTitle"];
        return (
          <Fragment>

            {DetailTitle}
            {/* <div dangerouslySetInnerHTML={{ __html: DetailTitle }} /> */}
          </Fragment>
        );
      },
      sortable: true
    },

    {
      d: "action",
      text: "Action",
      cell: (record, index) => {


        return (
          <Fragment>
            {RightsUpdate == "A" ? <a className="me-3 text-primary" onClick={(editRecord.bind(this, record))}><i className="mdi mdi-pencil font-size-18"></i></a> : ''}
            {RightsDelete == "A" ? <a className={IconrenderSwitchColor(record)} onClick={deleteRecord.bind(this, record)}><i className={IconrenderSwitch(record)}></i></a> : ''}


          </Fragment>
        );
      }
    }
  ];
  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",

  }
  const [records, setRecords] = useState([])
  const [CategoryFlag, setuserFlag] = useState("");
  const [RightsAdd, setRightsAdd] = useState("D");
  const [RightsUpdate, setRightsUpdate] = useState("D");
  const [RightsDelete, setRightsDelete] = useState("D");
  const [Description, setDescription] = useState("");


  let history = useHistory();

  function IconrenderSwitch(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'mdi mdi-close font-size-18';
      default:
        return 'mdi mdi-check-all font-size-18';
    }
  }

  function IconrenderSwitchColor(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }

  useEffect(() => {

    const f = {
      MenuId: "22",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
      //UserTypeId: "1"
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {

        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add') {
            setRightsAdd(MS);
          }
          else if (MA == 'Update') {
            setRightsUpdate(MS);
          }
          else if (MA == 'Delete') {
            setRightsDelete(MS);
          }
        }
      });

    let Wid = localStorage.getItem("WebServiceId") == null ? "0" : localStorage.getItem("WebServiceId") == "" ? "0" : localStorage.getItem("WebServiceId");

    const AListData = {
      "WebServiceId": Wid,
      "Flag": "A"
    }

    axios.post(global.APIURL + "/WebServicesDetailList", AListData).then((res) => {

      if (res.data.length > 0) {
        setRecords(res.data);
      }

    });


  }, []);

  const getDesc = (record) => {
    setDescription(record["DetailDescription"]);
  }

  function handleChange(event) {
    const VFlag = event.target.value
    let Wid = localStorage.getItem("WebServiceId") == null ? "0" : localStorage.getItem("WebServiceId") == "" ? "0" : localStorage.getItem("WebServiceId");

    const AListData = {
      "WebServiceId": Wid,
      "Flag": VFlag
    }

    axios.post(global.APIURL + "/WebServicesDetailList", AListData).then((res) => {
      setRecords(res.data);
    });
  }
  const editRecord = (record) => {
    let id = record["Id"]
    let path = `/WebServicesDetails/EditWebServicesDetails/:${id}`;
    history.push(path);
  }

  const deleteRecord = (record) => {

    let VId = record["Id"];
    let CreateUser = localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    let UTFlag = record["Flag"];

    const DeleteData = {
      "Id": VId,
      "CreateUser": CreateUser
    }
    let AlertMessgae = UTFlag == "A" ? "Are you sure you want to inactive this record?" : "Are you sure you want to active this record?";

    if (confirm(AlertMessgae) == true) {

      axios.post(global.APIURL + '/WebServicesDetailStausUpdate', DeleteData)
        .then(res => {
          if (UTFlag == "A") {
            alert("Record Inactive Successfully");
            const AListData = {
              "Flag": "A"
            }
            axios.post(global.APIURL + "/WebServicesDetailList", AListData).then((res) => {
              setRecords(res.data);
              window.location.reload()
            });
          }
          else if (UTFlag == "D") {
            alert("Record Active Successfully");
            const AListData = {
              "Flag": "A"
            }
            axios.post(global.APIURL + "/WebServicesDetailList", AListData).then((res) => {
              setRecords(res.data);
              window.location.reload()
            });
          }
        })
    }
  }

  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }

  const exportPDF = () => {

    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Web Services Detail List Report";
    const headers = [["#", "Title", "Description"]];

    const data = records.map((item, index) => [index + 1, item.DetailTitle, item.DetailDescription]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("WebServicesDetailReport.pdf")
  }


  return (
    <div className="page-content">
      <MetaTags>
        <title>Web Services Detail| KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Description</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <form>
                <div className="modal-body">

                  <div dangerouslySetInnerHTML={{ __html: Description }} />



                </div>

              </form>



            </div>
          </div>
        </div>

        <Row className="mb-3">
          <Col className="col-lg-6 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Web Services Detail</CardTitle>
              <a href="/GeneralDashboard" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <span className="ms-2">Web Services Detail</span>
            </div>
          </Col>
          <Col className="col-lg-6 co-md-6 col-6">
            <div className="d-flex align-items-center justify-content-end ">
              <div className="me-2">
                <select id="ddlActive" className="form-select selectbtnedit" onChange={handleChange}>

                  <option value={"A"} selected={CategoryFlag === "A"}>Active Records</option>
                  <option value={"D"}>In-Active Records</option>
                  <option value={"ALL"}>ALL Records</option>

                </select>
              </div>
              <div>
                <a className="btn btn-primary btn-sm float-end font-14" href="/WebServices">
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
                {RightsAdd == "A" ? <a className="btn btn-primary btn-sm float-end font-14 me-2" href="/WebServicesDetails/AddWebServicesDetails">
                  <i className="mdi mdi-plus align-middle me-1"></i>Add New
                </a> : ''}

                <ReactHtmlTableToExcel
                  className="btn btn-primary btn-sm float-end font-14 me-2"
                  table="emp"
                  filename="WebServicesDetailsReport"
                  sheet="Sheet"
                  buttonText="Export Excel" />
                <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
              </div>
            </div>
          </Col>

        </Row>

        <Row>
          <div>
            <table id="emp" className="table" hidden="hidden">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Description</th>

                </tr>
              </thead>
              <tbody>              {
                records.map((item, index) => {
                  return <tr key={index}>
                    <td> {index + 1} </td>
                    <td>{item.DetailTitle}</td>
                    {/* <td><div dangerouslySetInnerHTML={{ __html: item.DetailTitle }} /></td>  */}
                    <td><div dangerouslySetInnerHTML={{ __html: item.DetailDescription }} /></td>

                  </tr>
                })
              }
              </tbody>
            </table>
          </div>
        </Row>
        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default OnSort;