import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle} from "reactstrap"
import { connect } from "react-redux"


import { getChartsData } from "../../../store/actions"

import { withTranslation } from "react-i18next"

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
        {
          title: "Revenue",
          iconClass: "bx-archive-in",
          description: "$35, 723",
        },
        {
          title: "Average Price",
          iconClass: "bx-purchase-tag-alt",
          description: "$16.2",
        },
      ],
      email: [
        { title: "Week", linkto: "#", isActive: false },
        { title: "Month", linkto: "#", isActive: false },
        { title: "Year", linkto: "#", isActive: true },
      ],
      modal: false,
      subscribemodal: false,
      chartSeries: [],
      periodType: "yearly"
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }

  componentDidMount() {
    const { onGetChartsData } = this.props;
    setTimeout(() => this.setState({ subscribemodal: true }), 2000);
    onGetChartsData("yearly");
  }


  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ ...this.state, chartSeries: this.props.chartsData })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Retail Dashboard | KC Admin Group</title> 
          </MetaTags>
          <Container fluid>
          <div className="modal fade" id="attendanceadd" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Take Selfie to Check-in</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <Row>
        <div className="col-lg-3">
          <div className="attendance-box-retail">
            <div className="plus-box"><i className="bx bx-plus h1 mb-0"></i></div>
          </div>
        </div>
        <div className="col-lg-6">
            <p className="mb-1"><i className="bx bxs-map align-middle me-1"></i>87828-9935 Margaret Tunnel 
                Francescohaven 
            </p>
          
        </div>
      </Row>
      </div>
      <div className="modal-footer">
        {/* <button type="button" className="btn btn_success">Save</button>
        <button type="button" className="btn btn_danger" data-bs-dismiss="modal">Close</button> */}
          <a href="#"><span className="badge checkin badge-soft-success">Check In</span></a>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="breakstart" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Break Start</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <Row>
        <div className="col-lg-3">
          <div className="attendance-box-retail">
            <div className="plus-box"><i className="bx bx-plus h1 mb-0"></i></div>
          </div>
        </div>
        <div className="col-lg-6">
            <p className="mb-1"><i className="bx bxs-map align-middle me-1"></i>87828-9935 Margaret Tunnel 
                Francescohaven 
            </p>
          
        </div>
      </Row>
      </div>
      <div className="modal-footer">
        {/* <button type="button" className="btn btn_success">Save</button>
        <button type="button" className="btn btn_danger" data-bs-dismiss="modal">Close</button> */}
          <a href="#"><span className="badge checkin badge-soft-success">Break Start</span></a>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="breakend" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Break End</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <Row>
        <div className="col-lg-3">
          <div className="attendance-box-retail">
            <div className="plus-box"><i className="bx bx-plus h1 mb-0"></i></div>
          </div>
        </div>
        <div className="col-lg-6">
            <p className="mb-1"><i className="bx bxs-map align-middle me-1"></i>87828-9935 Margaret Tunnel 
                Francescohaven 
            </p>
          
        </div>
      </Row>
      </div>
      <div className="modal-footer">
        {/* <button type="button" className="btn btn_success">Save</button>
        <button type="button" className="btn btn_danger" data-bs-dismiss="modal">Close</button> */}
          <a href="#"><span className="badge checkin badge-soft-success">Break End</span></a>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="checkout" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Checkout</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <Row>
        <div className="col-lg-3">
          <div className="attendance-box-retail">
            <div className="plus-box"><i className="bx bx-plus h1 mb-0"></i></div>
          </div>
        </div>
        <div className="col-lg-6">
            <p className="mb-1"><i className="bx bxs-map align-middle me-1"></i>87828-9935 Margaret Tunnel 
                Francescohaven 
            </p>
          
        </div>
      </Row>
      </div>
      <div className="modal-footer">
        {/* <button type="button" className="btn btn_success">Save</button>
        <button type="button" className="btn btn_danger" data-bs-dismiss="modal">Close</button> */}
          <a href="#"><span className="badge checkin badge-soft-success">Checkout</span></a>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="bdywish" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Send Message</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <Row>
       <div className="col-lg-12">
       <div className="">
          <div className="form-check mb-3"><input className="form-check-input" type="checkbox" id="defaultCheck1" value=""/><label className="form-check-label" htmlFor="defaultCheck1">Wishing you the most exciting and fun filled birthday ever.</label></div>
        </div>
        <div className="">
          <div className="form-check mb-3"><input className="form-check-input" type="checkbox" id="defaultCheck1" value=""/><label className="form-check-label" htmlFor="defaultCheck1">Happy Birthday Dear</label></div>
        </div>
        <div className="">
          <div className="form-check mb-3"><input className="form-check-input" type="checkbox" id="defaultCheck1" value=""/><label className="form-check-label" htmlFor="defaultCheck1">On your birthday, I wish you to have a hundred of birthdays more</label></div>
        </div>
        <div className="">
          <div className="form-check mb-3"><input className="form-check-input" type="checkbox" id="defaultCheck1" value=""/><label className="form-check-label" htmlFor="defaultCheck1">Custom Message</label>
          <textarea className="form-control mt-2" placeholder="Type Something" id="exampleFormControlTextarea1" rows="3"></textarea></div>
        </div>
       </div>
      </Row>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary">Send on SMS</button>
        <button type="button" className="btn btn_success" data-bs-dismiss="modal">Send on Whatsapp</button>
         
      </div>
    </div>
  </div>
</div>
            {/* Render Breadcrumb */}
            <CardTitle className="mb-3">Retail Dashboard</CardTitle>
           <Card>
             <CardBody>
             <Row>
               <CardTitle className="mb-3">Summary</CardTitle>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="job-box">
                <Card className="summary-bg">
                  <CardBody>
                  <div className="mini-stats-wid">
                      <div className="">
                          <div className="media">
                              <div className="media-body">
                                  <h5 className="text-muted font-weight-medium">Total Leads</h5>
                                  <h1 className="mb-0">1000</h1>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title-1">
                                      <i className="mdi mdi-account-search font-size-24"></i>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                  </CardBody>
                </Card>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="job-box">
                <Card className="summary-bg">
                  <CardBody>
                  <div className="mini-stats-wid">
                      <div className="">
                          <div className="media">
                              <div className="media-body">
                                  <h5 className="text-muted font-weight-medium">Total Clients</h5>
                                  <h1 className="mb-0">12,500</h1>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title-1">
                                      <i className="mdi mdi-handshake font-size-24"></i>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                  </CardBody>
                </Card>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="job-box">
                <Card className="summary-bg">
                  <CardBody>
                  <div className="mini-stats-wid">
                      <div className="">
                          <div className="media">
                              <div className="media-body">
                                  <h5 className="text-muted font-weight-medium">Pending Follow-ups</h5>
                                  <h1 className="mb-0">100</h1>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title-1">
                                      <i className="mdi mdi-file-document-outline font-size-24"></i>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                  </CardBody>
                </Card>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="job-box">
                <Card className="summary-bg">
                  <CardBody>
                  <div className="mini-stats-wid">
                      <div className="">
                          <div className="media">
                              <div className="media-body">
                                  <h5 className="text-muted font-weight-medium">Today&apos;s Follow-ups</h5>
                                  <h1 className="mb-0">100</h1>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title-1">
                                      <i className="fas fa-check font-size-24"></i>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                  </CardBody>
                </Card>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="job-box">
                <Card className="summary-bg">
                  <CardBody>
                  <div className="mini-stats-wid">
                      <div className="">
                          <div className="media">
                              <div className="media-body">
                                  <h5 className="text-muted font-weight-medium">Today&apos;s Pending</h5>
                                  <h1 className="mb-0">100</h1>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title-1">
                                      <i className="fas fa-clock font-size-24"></i>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                  </CardBody>
                </Card>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="job-box">
                <Card className="summary-bg">
                  <CardBody>
                  <div className="mini-stats-wid">
                      <div className="">
                          <div className="media">
                              <div className="media-body">
                                  <h5 className="text-muted font-weight-medium">Pending Tasks</h5>
                                  <h1 className="mb-0">100</h1>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title-1">
                                      <i className="mdi mdi-file-document-multiple font-size-24"></i>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                  </CardBody>
                </Card>
              </div>
            </div>
            </Row>
             </CardBody>
           </Card>
           <Card>
            <CardBody>
            <Row>
              <div className="col-lg-12">
                <div className="todo-main-box d-flex align-items-center justify-content-between mb-3">
                    <div className="">
                    <h5 className="font-weight-medium">Attendance</h5>
                    </div>
                    <div className="todo-btn">
                      {/* <a href="/kc-todo" className="btn btn-success btn-sm me-3">Add New Task</a> */}
                      <a href="/ListofAttendance" className="btn btn-primary btn-sm">View All</a>
                    </div>
                </div>
               
              </div>
              <Row>
                <div className="col-lg-3">
                  <a href="" data-bs-toggle="modal" data-bs-target="#attendanceadd">
                  <div className="attendance-box-retail">
                    <div className="plus-box"><i className="bx bx-plus h1 mb-0"></i></div>
                    <p className="font-size-16 mt-2">Check-in</p>
                  </div>
                  </a>
                </div>
                <div className="col-lg-3">
                 <a href="" data-bs-toggle="modal" data-bs-target="#breakstart">
                 <div className="attendance-box-retail">
                    <div className="plus-box"><i className="bx bx-plus h1 mb-0"></i></div>
                    <p className="font-size-16 mt-2">Break start</p>
                  </div>
                 </a>
                </div>
                <div className="col-lg-3">
                 <a href="" data-bs-toggle="modal" data-bs-target="#breakend">
                 <div className="attendance-box-retail">
                    <div className="plus-box"><i className="bx bx-plus h1 mb-0"></i></div>
                    <p className="font-size-16 mt-2">Break end</p>
                  </div>
                 </a>
                </div>
                <div className="col-lg-3">
                 <a href="" data-bs-toggle="modal" data-bs-target="#checkout">
                 <div className="attendance-box-retail">
                    <div className="plus-box"><i className="bx bx-plus h1 mb-0"></i></div>
                    <p className="font-size-16 mt-2">Check out</p>
                  </div>
                 </a>
                </div>
              </Row>
              <Row>
              <div className="col-lg-6 mt-3">
                <div className="leave-sub-box">
                                <div className="box-2-text"><div className="text-box">
                                  <div className="h5 mb-0">19-08-2021 </div>
                                </div>
                                <div className="btn-box">
                                <a href="/" className=""><i className="bx bxs-map align-middle me-1"></i>21504 Abigale Trail Jacobibury </a>
                                
                                </div></div>
                            <div className="box-2"></div>
                            <table className="table table-borderless">
  <thead>
    <tr className="">
      <th scope="col">Check-in</th>
      <th scope="col">Break Start</th>
      <th scope="col">Break End</th>
      <th scope="col">Check out</th>
    </tr>
  </thead>
  <tbody>
    <tr className="">
      <th scope="row" className="text-success">09:11 AM</th>
      <td className="text-blue">01:00 PM</td>
      <td className="text-warning">1:30 PM</td>
      <td className="text-danger">08:00 PM</td>
    </tr>
  </tbody>
</table>
                              </div>
                             
                              
                </div>
                <div className="col-lg-6 mt-3">
                <div className="leave-sub-box">
                                <div className="box-2-text"><div className="text-box">
                                  <div className="h5 mb-0">19-08-2021 </div>
                                </div>
                                <div className="btn-box">
                                <a href="/" className=""><i className="bx bxs-map align-middle me-1"></i>21504 Abigale Trail Jacobibury </a>
                                
                                </div></div>
                            <div className="box-2"></div>
                            <table className="table table-borderless">
  <thead>
    <tr className="">
      <th scope="col">Check-in</th>
      <th scope="col">Break Start</th>
      <th scope="col">Break End</th>
      <th scope="col">Check out</th>
    </tr>
  </thead>
  <tbody>
  <tr className="">
      <th scope="row" className="text-success">09:11 AM</th>
      <td className="text-blue">01:00 PM</td>
      <td className="text-warning">1:30 PM</td>
      <td className="text-danger">08:00 PM</td>
    </tr>
  </tbody>
</table>
                              </div>
                             
                              
                </div>
                <div className="col-lg-6 mt-3">
                <div className="leave-sub-box">
                                <div className="box-2-text"><div className="text-box">
                                  <div className="h5 mb-0">19-08-2021 </div>
                                </div>
                                <div className="btn-box">
                                <a href="/" className=""><i className="bx bxs-map align-middle me-1"></i>21504 Abigale Trail Jacobibury </a>
                                
                                </div></div>
                            <div className="box-2"></div>
                            <table className="table table-borderless">
  <thead>
    <tr className="">
      <th scope="col">Check-in</th>
      <th scope="col">Break Start</th>
      <th scope="col">Break End</th>
      <th scope="col">Check out</th>
    </tr>
  </thead>
  <tbody>
  <tr className="">
      <th scope="row" className="text-success">09:11 AM</th>
      <td className="text-blue">01:00 PM</td>
      <td className="text-warning">1:30 PM</td>
      <td className="text-danger">08:00 PM</td>
    </tr>
  </tbody>
</table>
                              </div>
                             
                              
                </div>
                <div className="col-lg-6 mt-3">
                <div className="leave-sub-box">
                                <div className="box-2-text"><div className="text-box">
                                  <div className="h5 mb-0">19-08-2021 </div>
                                </div>
                                <div className="btn-box">
                                <a href="/" className=""><i className="bx bxs-map align-middle me-1"></i>21504 Abigale Trail Jacobibury </a>
                                
                                </div></div>
                            <div className="box-2"></div>
                            <table className="table table-borderless">
  <thead>
    <tr className="">
      <th scope="col">Check-in</th>
      <th scope="col">Break Start</th>
      <th scope="col">Break End</th>
      <th scope="col">Check out</th>
    </tr>
  </thead>
  <tbody>
  <tr className="">
      <th scope="row" className="text-success">09:11 AM</th>
      <td className="text-blue">01:00 PM</td>
      <td className="text-warning">1:30 PM</td>
      <td className="text-danger">08:00 PM</td>
    </tr>
  </tbody>
</table>
                              </div>
                             
                              
                </div>
              
              </Row>
              
         
            </Row>

            </CardBody>
            </Card>

            <Row>
              <Col xl="4">
              <Card>
                  <CardBody>
                  <div className="mini-stats-wid">
                    <div className="col-lg-12">
                <div className="todo-main-box d-flex align-items-center justify-content-between mb-3">
                    <div className="">
                    <h5 className="font-weight-medium">Today&apos;s Follow-ups</h5>
                    </div>
                    <div className="todo-btn">
                      {/* <a href="/kc-todo" className="btn btn-success btn-sm me-3">Add New Task</a> */}
                      <a href="/kc-todo1" className="btn btn-primary btn-sm">View All</a>
                    </div>
                </div>
               
              </div>
                    <div className="leave-sub-box mb-3">
                                        <div className="leave-sub-box-1">
                                          <div className="name-box">
                                              <div className="h6 font-16">Hirva Patel</div>
                                              <p className="mb-2">+91 4589635896</p>
                                              {/* <p className="mb-0"><i className="mdi mdi-calendar-month align-middle me-2"></i>01/06/2020</p> */}
                                          </div>
                                          {/* <button className="position-relative btn btn-primary">CL
                                              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success font-size-12"> 1 <span className="visually-hidden">unread messages</span> </span>
                                          </button> */}
                                          <div className="">
                                          <img src="/static/media/avatar-4.b23e41d9.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle"/>
                                          </div>
                                          
                                        </div>
                                      <div className="mt-2">
                                      <a href="" className="me-3"><span className="badge bg-primary font-size-11">On Call : 9:41 PM</span></a>
                                      <a href="" className="me-3"><span className="badge bg-success font-size-11">Completed</span></a>
                                      {/* <a href="" className=""><span className="badge bg-danger font-size-11">Reject</span></a> */}
                                      </div>
                                      <div className="box-2"></div>
                                    <div className="h6 mt-3 mb-0"><span>Remarks :</span> Nesciunt rerum provident consequatur</div>
                                    </div>
                                   
                  </div>
                  <div className="mini-stats-wid">
                    <div className="leave-sub-box mb-3">
                                        <div className="leave-sub-box-1">
                                          <div className="name-box">
                                              <div className="h6 font-16">Utsav Shah</div>
                                              <p className="mb-2">At Office</p>
                                              <p className="mb-0"><i className="mdi mdi-map-marker align-middle me-1"></i>Herminia South  </p>
                                          </div>
                                          <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle"/>
                                          
                                        </div>
                                      <div className="mt-2">
                                      <a href="" className="me-3"><span className="badge bg-primary font-size-11">Meeting : 10:00 Am</span></a>
                                      <a href="" className="me-3"><span className="badge bg-yellow font-size-11">Complete</span></a>
                                      {/* <a href="" className=""><span className="badge bg-danger font-size-11">Reject</span></a> */}
                                      </div>
                                      <div className="box-2"></div>
                                    <div className="h6 mt-3 mb-0"><span>Remarks :</span> Nesciunt rerum provident consequatur</div>
                                    </div>
                                    
                                    
                  </div>
                  <div className="mini-stats-wid">
                    <div className="leave-sub-box mb-3">
                                        <div className="leave-sub-box-1">
                                          <div className="name-box">
                                              <div className="h6 font-16">Kevin Patel</div>
                                              <p className="mb-2">kaer39@gmail.com</p>
                                              {/* <p className="mb-0"><i className="mdi mdi-map-marker align-middle me-1"></i>Herminia South  </p> */}
                                          </div>
                                          <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle"/>
                                          
                                        </div>
                                      <div className="mt-2">
                                      <a href="" className="me-3"><span className="badge bg-primary font-size-11">Email : 10:00 Am</span></a>
                                      <a href="" className="me-3"><span className="badge bg-yellow font-size-11">Complete</span></a>
                                      {/* <a href="" className=""><span className="badge bg-danger font-size-11">Reject</span></a> */}
                                      </div>
                                      <div className="box-2"></div>
                                    <div className="h6 mt-3 mb-0"><span>Remarks :</span> Nesciunt rerum provident consequatur</div>
                                    </div>
                                    
                                    
                  </div>
                  
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
              <Card>
                  <CardBody>
                  <div className="col-lg-12">
                <div className="todo-main-box d-flex align-items-center justify-content-between mb-3">
                    <div className="">
                    <h5 className="font-weight-medium">Clients</h5>
                    </div>
                    <div className="todo-btn">
                      {/* <a href="/kc-todo" className="btn btn-success btn-sm me-3">Add New Task</a> */}
                      <a href="/kc-todo1" className="btn btn-primary btn-sm">View All</a>
                    </div>
                </div>
               
              </div>
                  <div className="leave-sub-box mb-3">
                                        <div className="leave-sub-box-1">
                                          <div className="name-box">
                                              <div className="h6 font-16">Pooja Gupta</div>
                                              <p className="mb-2">Reilly Y. , HDFC, Gurukul , Ahmedabad</p>
                                              <div className="d-flex gap-3">
                            <div className="bg_blue p-1 px-2 rounded-3"> India </div>
                            <div className="bg_blue p-1 px-2 rounded-3"> Gujarat </div>
                            <div className="bg_blue p-1  px-2 rounded-3"> 380001 </div>
                        </div>
                                              {/* <p className="mb-0"><i className="mdi mdi-map-marker align-middle me-1"></i>Herminia South  </p> */}
                                          </div>
                                          <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle"/>
                                          
                                        </div>
                                      <div className="box-2"></div>
                                    <div className="mt-2 d-flex align-items-center justify-content-between">
                                        <a href="" className="text-success"><i className="fas fa-phone-alt me-1"></i>+91 4589236941</a>
                                        <a href="" className="text-blue"><i className="fas fa-envelope me-1"></i>Ella91@gmail.com</a>
                                    </div>
                    </div>     
                    <div className="leave-sub-box mb-3">
                                        <div className="leave-sub-box-1">
                                          <div className="name-box">
                                              <div className="h6 font-16">Pooja Gupta</div>
                                              <p className="mb-2">Reilly Y. , HDFC, Gurukul , Ahmedabad</p>
                                              <div className="d-flex gap-3">
                            <div className="bg_blue p-1 px-2 rounded-3"> India </div>
                            <div className="bg_blue p-1 px-2 rounded-3"> Gujarat </div>
                            <div className="bg_blue p-1  px-2 rounded-3"> 380001 </div>
                        </div>
                                              {/* <p className="mb-0"><i className="mdi mdi-map-marker align-middle me-1"></i>Herminia South  </p> */}
                                          </div>
                                          <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle"/>
                                          
                                        </div>
                                      <div className="box-2"></div>
                                    <div className="mt-2 d-flex align-items-center justify-content-between">
                                        <a href="" className="text-success"><i className="fas fa-phone-alt me-1"></i>+91 4589236941</a>
                                        <a href="" className="text-blue"><i className="fas fa-envelope me-1"></i>Ella91@gmail.com</a>
                                    </div>
                    </div>     
                    <div className="leave-sub-box mb-3">
                                        <div className="leave-sub-box-1">
                                          <div className="name-box">
                                              <div className="h6 font-16">Pooja Gupta</div>
                                              <p className="mb-2">Reilly Y. , HDFC, Gurukul , Ahmedabad</p>
                                              <div className="d-flex gap-3">
                            <div className="bg_blue p-1 px-2 rounded-3"> India </div>
                            <div className="bg_blue p-1 px-2 rounded-3"> Gujarat </div>
                            <div className="bg_blue p-1  px-2 rounded-3"> 380001 </div>
                        </div>
                                              {/* <p className="mb-0"><i className="mdi mdi-map-marker align-middle me-1"></i>Herminia South  </p> */}
                                          </div>
                                          <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle"/>
                                          
                                        </div>
                                      <div className="box-2"></div>
                                    <div className="mt-2 d-flex align-items-center justify-content-between">
                                        <a href="" className="text-success"><i className="fas fa-phone-alt me-1"></i>+91 4589236941</a>
                                        <a href="" className="text-blue"><i className="fas fa-envelope me-1"></i>Ella91@gmail.com</a>
                                    </div>
                    </div>                
                  </CardBody>
                  
                </Card>
              </Col>
              <Col xl="4">
              <Card>
                  <CardBody>
                  <div className="col-lg-12">
                <div className="todo-main-box d-flex align-items-center justify-content-between mb-3">
                    <div className="">
                    <h5 className="font-weight-medium">To Do&apos;s</h5>
                    </div>
                    <div className="todo-btn">
                      {/* <a href="/kc-todo" className="btn btn-success btn-sm me-3">Add New Task</a> */}
                      <a href="/ListofTodo" className="btn btn-primary btn-sm">View All</a>
                    </div>
                </div>
               
              </div>
                  <div className="leave-sub-box mb-3">
                                        <div className="leave-sub-box-1">
                                        <Row className="mb-3" data-bs-toggle="modal" data-bs-target="#checklist">
                            <Col className="col-1 text-center" >
                                <input
                                    type="checkbox"
                                    className="form-check-input input-mini "
                                    id="closeButton"
                                    value="checked" checked
                                />
                            </Col>
                            <Col className="col-11">
                           <a className="table-link-a">
                           Perspiciatis dolores et aperiam et et.
                           </a>
                            </Col>  
                            </Row> 
                                          
                                        </div>
                                      <div className="box-2 mt-0"></div>
                                    <div className="mt-2 d-flex align-items-center justify-content-between">
                                       <div className="">
                                       <a href="" className="text-orange">View Details</a>
                                       </div>
                                      <div className="">
                                      <a href="" className="text-success"><i className="far fa-calendar-alt me-1"></i>16-08-2021</a>
                                      </div>
                                       {/* <div className="text-end">
                                       <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                                       </div> */}
                                    </div>
                    </div>     
                    <div className="leave-sub-box mb-3">
                                        <div className="leave-sub-box-1">
                                        <Row className="mb-3" data-bs-toggle="modal" data-bs-target="#checklist">
                            <Col className="col-1 text-center" >
                                <input
                                    type="checkbox"
                                    className="form-check-input input-mini "
                                    id="closeButton"
                                    value="checked" checked
                                />
                            </Col>
                            <Col className="col-11">
                           <a className="table-link-a">
                           Perspiciatis dolores et aperiam et et.
                           </a>
                            </Col>  
                            </Row> 
                                          
                                        </div>
                                      <div className="box-2 mt-0"></div>
                                    <div className="mt-2 d-flex align-items-center justify-content-between">
                                       <div className="">
                                       <a href="" className="text-orange">View Details</a>
                                       </div>
                                      <div className="">
                                      <a href="" className="text-success"><i className="far fa-calendar-alt me-1"></i>16-08-2021</a>
                                      </div>
                                       {/* <div className="text-end">
                                       <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                                       </div> */}
                                    </div>
                    </div>  
                    <div className="leave-sub-box mb-3">
                                        <div className="leave-sub-box-1">
                                        <Row className="mb-3" data-bs-toggle="modal" data-bs-target="#checklist">
                            <Col className="col-1 text-center" >
                                <input
                                    type="checkbox"
                                    className="form-check-input input-mini "
                                    id="closeButton"
                                    value="checked" checked
                                />
                            </Col>
                            <Col className="col-11">
                           <a className="table-link-a">
                           Perspiciatis dolores et aperiam et et.
                           </a>
                            </Col>  
                            </Row> 
                                          
                                        </div>
                                      <div className="box-2 mt-0"></div>
                                    <div className="mt-2 d-flex align-items-center justify-content-between">
                                       <div className="">
                                       <a href="" className="text-orange">View Details</a>
                                       </div>
                                      <div className="">
                                      <a href="" className="text-success"><i className="far fa-calendar-alt me-1"></i>16-08-2021</a>
                                      </div>
                                       {/* <div className="text-end">
                                       <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                                       </div> */}
                                    </div>
                    </div>  
                    <div className="leave-sub-box mb-3">
                                        <div className="leave-sub-box-1">
                                        <Row className="mb-3" data-bs-toggle="modal" data-bs-target="#checklist">
                            <Col className="col-1 text-center" >
                                <input
                                    type="checkbox"
                                    className="form-check-input input-mini "
                                    id="closeButton"
                                    value="checked" checked
                                />
                            </Col>
                            <Col className="col-11">
                           <a className="table-link-a">
                           Perspiciatis dolores et aperiam et et.
                           </a>
                            </Col>  
                            </Row> 
                                          
                                        </div>
                                      <div className="box-2 mt-0"></div>
                                    <div className="mt-2 d-flex align-items-center justify-content-between">
                                       <div className="">
                                       <a href="" className="text-orange">View Details</a>
                                       </div>
                                      <div className="">
                                      <a href="" className="text-success"><i className="far fa-calendar-alt me-1"></i>16-08-2021</a>
                                      </div>
                                       {/* <div className="text-end">
                                       <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                                       </div> */}
                                    </div>
                    </div>  
                    <div className="leave-sub-box mb-3">
                                        <div className="leave-sub-box-1">
                                        <Row className="mb-3" data-bs-toggle="modal" data-bs-target="#checklist">
                            <Col className="col-1 text-center" >
                                <input
                                    type="checkbox"
                                    className="form-check-input input-mini "
                                    id="closeButton"
                                    value="checked" checked
                                />
                            </Col>
                            <Col className="col-11">
                           <a className="table-link-a">
                           Perspiciatis dolores et aperiam et et.
                           </a>
                            </Col>  
                            </Row> 
                                          
                                        </div>
                                      <div className="box-2 mt-0"></div>
                                    <div className="mt-2 d-flex align-items-center justify-content-between">
                                       <div className="">
                                       <a href="" className="text-orange">View Details</a>
                                       </div>
                                      <div className="">
                                      <a href="" className="text-success"><i className="far fa-calendar-alt me-1"></i>16-08-2021</a>
                                      </div>
                                       {/* <div className="text-end">
                                       <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                                       </div> */}
                                    </div>
                    </div>  
                  
                  </CardBody>
                  
                </Card>
              </Col>
            </Row>


            <Row>
              <Col xl="6">
              <Card>
                  <CardBody>
                  <div className="mini-stats-wid">
                    <div className="col-lg-12">
                <div className="todo-main-box d-flex align-items-center justify-content-between mb-3">
                    <div className="">
                    <h5 className="font-weight-medium">Birthdays</h5>
                    </div>
                    {/* <div className="todo-btn">
                      <a href="/" className="btn btn-primary btn-sm">View All</a>
                    </div> */}
                </div>
               
              </div>
                 
                                   
                  </div>
                  <nav>
  <div className="nav nav-tabs" id="nav-tab" role="tablist">
    <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Clients/Leads</button>
    <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Colleagues</button>
    
  </div>
</nav>
<div className="tab-content" id="nav-tabContent">
    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
        <div className="leave-sub-box mb-3 mt-3">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-3">
                    <div className="h6 font-16">
                        Kartik Singh<span className="text-blue float-end"><i className="far fa-calendar-alt me-2"></i>16-09-1990</span>
                    </div>
                    <a href="tel:+4733378901" className="btn btn-success btn-sm me-3">Call</a>
                    <a href="" data-bs-toggle="modal" data-bs-target="#bdywish" className="btn btn-warning btn-sm">Send Message</a>
                </div>
            </div>
        </div>
        <div className="leave-sub-box mb-3 mt-3">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-3">
                    <div className="h6 font-16">
                        Smit Jain<span className="text-blue float-end"><i className="far fa-calendar-alt me-2"></i>16-09-1990</span>
                    </div>
                     <a href="tel:+4733378901" className="btn btn-success btn-sm me-3">Call</a>
                    <a href="" data-bs-toggle="modal" data-bs-target="#bdywish" className="btn btn-warning btn-sm">Send Message</a>
                </div>
            </div>
        </div>
        <div className="leave-sub-box mb-3 mt-3">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-3">
                    <div className="h6 font-16">
                        Kevin Patel<span className="text-blue float-end"><i className="far fa-calendar-alt me-2"></i>16-09-1990</span>
                    </div>
                     <a href="tel:+4733378901" className="btn btn-success btn-sm me-3">Call</a>
                    <a href="" data-bs-toggle="modal" data-bs-target="#bdywish" className="btn btn-warning btn-sm">Send Message</a>
                </div>
            </div>
        </div>
        <div className="leave-sub-box mb-3 mt-3">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-3">
                    <div className="h6 font-16">
                        Kevin Patel<span className="text-blue float-end"><i className="far fa-calendar-alt me-2"></i>16-09-1990</span>
                    </div>
                     <a href="tel:+4733378901" className="btn btn-success btn-sm me-3">Call</a>
                    <a href="" data-bs-toggle="modal" data-bs-target="#bdywish" className="btn btn-warning btn-sm">Send Message</a>
                </div>
            </div>
        </div>
    </div>
    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
    <div className="leave-sub-box mb-3 mt-3">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-3">
                    <div className="h6 font-16">
                        Kevin Patel<span className="text-blue float-end"><i className="far fa-calendar-alt me-2"></i>16-09-1990</span>
                    </div>
                     <a href="tel:+4733378901" className="btn btn-success btn-sm me-3">Call</a>
                    <a href="" data-bs-toggle="modal" data-bs-target="#bdywish" className="btn btn-warning btn-sm">Send Message</a>
                </div>
            </div>
        </div>
        <div className="leave-sub-box mb-3 mt-3">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-3">
                    <div className="h6 font-16">
                        Kevin Patel<span className="text-blue float-end"><i className="far fa-calendar-alt me-2"></i>16-09-1990</span>
                    </div>
                     <a href="tel:+4733378901" className="btn btn-success btn-sm me-3">Call</a>
                    <a href="" data-bs-toggle="modal" data-bs-target="#bdywish" className="btn btn-warning btn-sm">Send Message</a>
                </div>
            </div>
        </div>
        <div className="leave-sub-box mb-3 mt-3">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-3">
                    <div className="h6 font-16">
                        Kevin Patel<span className="text-blue float-end"><i className="far fa-calendar-alt me-2"></i>16-09-1990</span>
                    </div>
                     <a href="tel:+4733378901" className="btn btn-success btn-sm me-3">Call</a>
                    <a href="" data-bs-toggle="modal" data-bs-target="#bdywish" className="btn btn-warning btn-sm">Send Message</a>
                </div>
            </div>
        </div>
        <div className="leave-sub-box mb-3 mt-3">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-3">
                    <div className="h6 font-16">
                        Kevin Patel<span className="text-blue float-end"><i className="far fa-calendar-alt me-2"></i>16-09-1990</span>
                    </div>
                     <a href="tel:+4733378901" className="btn btn-success btn-sm me-3">Call</a>
                    <a href="" data-bs-toggle="modal" data-bs-target="#bdywish" className="btn btn-warning btn-sm">Send Message</a>
                </div>
            </div>
        </div>
      </div>
</div>

                  
                  </CardBody>
                </Card>
              </Col>
              <Col xl="6">
              <Card>
                  <CardBody>
                  <div className="mini-stats-wid">
                    <div className="col-lg-12">
                <div className="todo-main-box d-flex align-items-center justify-content-between mb-3">
                    <div className="">
                    <h5 className="font-weight-medium">Anniversary</h5>
                    </div>
                    {/* <div className="todo-btn">
                      <a href="/kc-todo1" className="btn btn-primary btn-sm">View All</a>
                    </div> */}
                </div>
               
              </div>
                 
                                   
                  </div>
                  <nav>
  <div className="nav nav-tabs" id="nav-tab" role="tablist">
    <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home1" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Clients/Leads</button>
    <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile1" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Colleagues</button>
    
  </div>
</nav>
<div className="tab-content" id="nav-tabContent">
    <div className="tab-pane fade show active" id="nav-home1" role="tabpanel" aria-labelledby="nav-home-tab">
        <div className="leave-sub-box mb-3 mt-3">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-3">
                    <div className="h6 font-16">
                        Kevin Patel<span className="text-blue float-end"><i className="far fa-calendar-alt me-2"></i>16-09-1990</span>
                    </div>
                     <a href="tel:+4733378901" className="btn btn-success btn-sm me-3">Call</a>
                    <a href="" data-bs-toggle="modal" data-bs-target="#bdywish" className="btn btn-warning btn-sm">Send Message</a>
                </div>
            </div>
        </div>
        <div className="leave-sub-box mb-3 mt-3">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-3">
                    <div className="h6 font-16">
                        Kevin Patel<span className="text-blue float-end"><i className="far fa-calendar-alt me-2"></i>16-09-1990</span>
                    </div>
                     <a href="tel:+4733378901" className="btn btn-success btn-sm me-3">Call</a>
                    <a href="" data-bs-toggle="modal" data-bs-target="#bdywish" className="btn btn-warning btn-sm">Send Message</a>
                </div>
            </div>
        </div>
        <div className="leave-sub-box mb-3 mt-3">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-3">
                    <div className="h6 font-16">
                        Kevin Patel<span className="text-blue float-end"><i className="far fa-calendar-alt me-2"></i>16-09-1990</span>
                    </div>
                     <a href="tel:+4733378901" className="btn btn-success btn-sm me-3">Call</a>
                    <a href="" data-bs-toggle="modal" data-bs-target="#bdywish" className="btn btn-warning btn-sm">Send Message</a>
                </div>
            </div>
        </div>
        <div className="leave-sub-box mb-3 mt-3">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-3">
                    <div className="h6 font-16">
                        Kevin Patel<span className="text-blue float-end"><i className="far fa-calendar-alt me-2"></i>16-09-1990</span>
                    </div>
                     <a href="tel:+4733378901" className="btn btn-success btn-sm me-3">Call</a>
                    <a href="" data-bs-toggle="modal" data-bs-target="#bdywish" className="btn btn-warning btn-sm">Send Message</a>
                </div>
            </div>
        </div>
    </div>
    <div className="tab-pane fade" id="nav-profile1" role="tabpanel" aria-labelledby="nav-profile-tab">
    <div className="leave-sub-box mb-3 mt-3">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-3">
                    <div className="h6 font-16">
                        Kevin Patel<span className="text-blue float-end"><i className="far fa-calendar-alt me-2"></i>16-09-1990</span>
                    </div>
                     <a href="tel:+4733378901" className="btn btn-success btn-sm me-3">Call</a>
                    <a href="" data-bs-toggle="modal" data-bs-target="#bdywish" className="btn btn-warning btn-sm">Send Message</a>
                </div>
            </div>
        </div>
        <div className="leave-sub-box mb-3 mt-3">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-3">
                    <div className="h6 font-16">
                        Kevin Patel<span className="text-blue float-end"><i className="far fa-calendar-alt me-2"></i>16-09-1990</span>
                    </div>
                     <a href="tel:+4733378901" className="btn btn-success btn-sm me-3">Call</a>
                    <a href="" data-bs-toggle="modal" data-bs-target="#bdywish" className="btn btn-warning btn-sm">Send Message</a>
                </div>
            </div>
        </div>
        <div className="leave-sub-box mb-3 mt-3">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-3">
                    <div className="h6 font-16">
                        Kevin Patel<span className="text-blue float-end"><i className="far fa-calendar-alt me-2"></i>16-09-1990</span>
                    </div>
                     <a href="tel:+4733378901" className="btn btn-success btn-sm me-3">Call</a>
                    <a href="" data-bs-toggle="modal" data-bs-target="#bdywish" className="btn btn-warning btn-sm">Send Message</a>
                </div>
            </div>
        </div>
        <div className="leave-sub-box mb-3 mt-3">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                    <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                </div>
                <div className="flex-grow-1 ms-3">
                    <div className="h6 font-16">
                        Kevin Patel<span className="text-blue float-end"><i className="far fa-calendar-alt me-2"></i>16-09-1990</span>
                    </div>
                     <a href="tel:+4733378901" className="btn btn-success btn-sm me-3">Call</a>
                    <a href="" data-bs-toggle="modal" data-bs-target="#bdywish" className="btn btn-warning btn-sm">Send Message</a>
                </div>
            </div>
        </div>
      </div>
</div>

                  
                  </CardBody>
                </Card>
              </Col>
             
            </Row>
          </Container>
        </div>
       
      </React.Fragment>
    )
  }
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func
}

const mapStateToProps = ({ Dashboard }) => ({
  chartsData: Dashboard.chartsData,
})

const mapDispatchToProps = dispatch => ({
  onGetChartsData: (periodType) => dispatch(getChartsData(periodType)),
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(withTranslation()(Dashboard))
