import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';

import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';

import LinkApp from 'App';

const OnSort = () => {
  let columns = [
    {
      key: "Srno",
      text: "#",
      sortable: true
    },
    // {
    //   key: "CreateDate",
    //   text: "Date Created",
    //   sortable: true
    // },
    {
      key: "ServiceTitle",
      text: "Service Title",
      sortable: true
    },
    {
      key: "Monthlyrate",
      text: "Monthly Rate",
      sortable: true
    },
    {
      key: "QuaterlyRate",
      text: "Quaterly Rate",
      sortable: true
    },
    {
      key: "Halfyearly",
      text: "Half yearly",
      sortable: true
    },
    {
      key: "yearlyrate",
      text: "yearly rate",
      sortable: true
    },
    {
      d: "Status",
      text: "Status",
      cell: (record, index) => {
        let LeadStatus = record["LeadStatus"];
        let LeadStatusClass = record["LeadStatusClass"];
        return (
          <Fragment>
            <span className={LeadStatusClass}>{LeadStatus}</span>
          </Fragment>
        );
      }
    },
    {
      
      text: "Manage Bureau Step",
      cell: (record, index) => {
        return (
          <Fragment>
            <a className="btn btn-primary btn-sm float-end font-14" onClick={(AddActivitiesRecord.bind(this, record))}> Manage Bureau Step</a>
          </Fragment>
        );
      }
    },
    {
      d: "action",
      text: "Action",
      cell: (record, index) => {


        return (
          <Fragment>
            <a className="me-3 text-primary" onClick={(editRecord.bind(this, record))}><i className="mdi mdi-pencil font-size-18"></i></a>
          </Fragment>
        );
      }
    }
  ];




  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
    // button: {
    //     excel: true,
    //     print: true,
    //     csv: true
    // }
  }
  const [records, setRecords] = useState([])
  const [CreditStatusFlag, setCreditStatusFlag] = useState("");
  const [RightsAdd, setRightsAdd] = useState("D");
  const [RightsUpdate, setRightsUpdate] = useState("D");
  const [RightsDelete, setRightsDelete] = useState("D");
  const [BureauDataList, setBureauDataList] = useState([]);


  let history = useHistory();



  useEffect(() => {
    const f = {
      MenuId: "54",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
      //UserTypeId: "1"
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {

        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add') {
            setRightsAdd(MS);
          }
          else if (MA == 'Update') {
            setRightsUpdate(MS);
          }
          else if (MA == 'Delete') {
            setRightsDelete(MS);
          }
        }
      });
    fetch(global.APIURL + "/CorporateserviceplanList")
      .then((res) => res.json())
      .then((json) => {
        setRecords(json)
      })


  }, []);


  const editRecord = (record) => {
    let id = record["CorporateserviceId"]
    let path = `CorporateServiceplan/EditCorporateServiceplan/:${id}`;
    history.push(path);
  }

  const AddActivitiesRecord = (record) => { 
    $('#MPAddActivities').modal('show');
    let CorporateserviceId = record["CorporateserviceId"]
    localStorage.setItem("ActivitiesCorporateServiceId",CorporateserviceId);
    getBureauDataList(CorporateserviceId);
    // 
    // localStorage.setItem("ActivitiesCorporateServiceId",CorporateserviceId);
    // let path = `CorporateServiceActivities`;
    // history.push(path);

  }

  const AddActivitiesBureauRecord = (BureauId) => {
    let BId = parseInt(BureauId == null ? "0" : BureauId == "" ? "0" : BureauId)
    localStorage.setItem("CorporateServiceBureauId", BId);
    $('#MPAddActivities').modal('hide');
    window.location.href = "/CorporateServiceActivitiesList";
    // let path = `CorporateServiceActivitiesList`;
    // history.push(path);
  }

  const getBureauDataList = async (CorporateserviceId) => {
    let VActivitiesCorporateServiceId = parseInt(CorporateserviceId == null ? "0" : CorporateserviceId == "" ? "0" : CorporateserviceId);
    var ItemArr = {
      CorporateServiceId: VActivitiesCorporateServiceId
    }
    var ACUrl = global.APIURL + '/CorporateServiceBureauCategory';

    axios.post(ACUrl, ItemArr)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setBureauDataList(CityL);
      });
  };

  const exportPDF = () => {

    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Corporate Service Plan List Report";
    const headers = [["#", "CreateDate", "Service Title", "Monthly Rate", "Quaterly Rate", "Half Yearly Rate", "Yearly Rate"]];

    const data = records.map((item, index) => [index + 1, item.CreateDate, item.ServiceTitle, item.Monthlyrate, item.QuaterlyRate, item.Halfyearly, item.yearlyrate]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("CorporateServicePlanListReport.pdf")
  }

  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Corporate Service Plan</title>
      </MetaTags>

      <div className="container-fluid">
        <Row className="mb-3">
          <Col className="col-lg-6 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Corporate Service Plan</CardTitle>
              <a href="/GeneralDashboard" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <span className="ms-2">Corporate Service Plan</span>
            </div>
          </Col>
          <Col className="col-lg-6 co-md-6 col-6">
            <div className="d-flex align-items-center justify-content-end ">

              <div>
                <a className="btn btn-primary btn-sm float-end font-14" href="/GeneralDashboard">
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
                <a className="btn btn-primary btn-sm float-end font-14 me-2" href="/CorporateServiceplan">
                  <i className="mdi mdi-plus align-middle me-1"></i>Add New
                </a>
                <ReactHtmlTableToExcel
                  className="btn btn-primary btn-sm float-end font-14 me-2"
                  table="emp"
                  filename="CorporateServicePlanListReport"
                  sheet="Sheet"
                  buttonText="Export Excel" />
                <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
              </div>
            </div>
          </Col>
        </Row>
        <div className="modal fade" id="MPAddActivities" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
              <h5 className="modal-title" style={{width:'100%',textAlign: 'center'}} id="exampleModalLabel">Bureau Step List</h5>
                {/* <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> */}
              </div>
              <div className="modal-body">
                <Row className="justifycontentbutton">
                  {BureauDataList.map((item, index) => (
                    
                    // <div className="col-lg-4 mb-3" >
                      <button type="button" key={index} style={{width:'auto'}} value="Click Me!" className="btn btn_success btn-sm me-2" onClick={() => AddActivitiesBureauRecord(item.BId)}>
                        Add {item.Title} Steps
                      </button>
                    // </div>
                  ))}

                </Row>
              </div>
            </div>
          </div>
        </div>
        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
        <table id="emp" className="table" hidden="hidden">
          <thead>
            <tr>
              <th>#</th>
              <th>Create Date</th>
              <th>Service Title</th>
              <th>Monthly Rate</th>
              <th>Quaterly Rate</th>
              <th>Half Yearly Rate</th>
              <th>Yearly Rate</th>
            </tr>
          </thead>
          <tbody>
            {
              records.map((item, index) => {
                return <tr key={index}>
                  <td> {index + 1} </td>
                  <td>{item.CreateDate}</td>
                  <td>{item.ServiceTitle}</td>
                  <td>{item.Monthlyrate}</td>
                  <td>{item.QuaterlyRate}</td>
                  <td>{item.Halfyearly}</td>
                  <td>{item.yearlyrate}</td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OnSort;