import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';

import LinkApp from 'App';



const OnSort = () => {

  const styles = {
    table_filter: {
      display: "none !important"
    }
  }
  let columns = [
    {
      text: "SR",
      cell: (record, index) => {
        let RowIndex = index + 1;
        return (
          <Fragment>
            {RowIndex}
          </Fragment>
        );
      }
    },
    {
      text: "Logo",
      cell: (record, index) => {
        let VChannelPartnerLogo = record["ChannelPartnerLogo"];
        return (
          <Fragment>
            <img src={VChannelPartnerLogo} alt="" className="img-thumbnail img-leads channelpartnerlogo" />
          </Fragment>
        );
      }
    },
    {
      text: "Partner Name ",
      cell: (record, index) => {
        let SubChannelName = record["Name"];
        let ChannelPartnerCode = record["ChannelPartnerCode"];
        let Designation = record["Designation"];
        return (
          <Fragment>
            <p className="extramarginbottomremove">{SubChannelName}</p>
            <p className="extramarginbottomremove">{ChannelPartnerCode}</p>
            <p className="extramarginbottomremove"><b>{Designation}</b></p>
          </Fragment>

        );
      }
    },
    {
      text: "Channel Name ",
      cell: (record, index) => {
        let ChannelName = record["ChannelName"];
        return (
          <Fragment>
            {ChannelName}
          </Fragment>
        );
      }
    },
    {
      text: "Mobile Number",
      cell: (record, index) => {
        let VMobileNumber = record["MobileNumber"];
        return (
          <Fragment>
            {VMobileNumber}
          </Fragment>
        );
      }
    },
    {
      text: "Email Address",
      cell: (record, index) => {
        let VEmailAddress = record["Email"];
        return (
          <Fragment>
            {VEmailAddress}
          </Fragment>
        );
      }
    },
    {
      text: "Department",
      cell: (record, index) => {
        let VDepartment = record["Department"];
        return (
          <Fragment>
            {VDepartment}
          </Fragment>
        );
      }
    },
    {
      text: "Address",
      cell: (record, index) => {
        let VCurrentAddress = record["CurrentAddress"];
        return (
          <Fragment>
            {VCurrentAddress}
          </Fragment>
        );
      }
    },
    {
      text: "District & City & Pincode",
      cell: (record, index) => {
        let VDistrictName = record["DistrictName"];
        let VCity = record["City"];
        let VPincode = record["Pincode"];
        return (
          <Fragment>
            <p className="mb-1">{VDistrictName}</p>
            <p className="mb-1">{VCity}</p>
            <p className="mb-1">{VPincode}</p>
          </Fragment>
        );
      }
    },
    {
      text: "Created Date",
      cell: (record, index) => {
        let VCreateDate = record["CreateDate"];
        return (
          <Fragment>
            {VCreateDate}
          </Fragment>
        );
      }
    },
    {
      text: "Action",
      cell: (record, index) => {


        return (
          <Fragment>
            {RightsUpdate == "A" ? <a className="me-3 text-primary" onClick={(editRecord.bind(this, record))}><i className="mdi mdi-pencil font-size-18"></i></a> : ''}
            {RightsDelete == "A" ? <a className={IconrenderSwitchColor(record)} onClick={deleteRecord.bind(this, record)}><i className={IconrenderSwitch(record)}></i></a> : ''}
            <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModalHistoryByChannelSub" style={{ marginLeft: "16px" }} className="btn btn-sm btn-primary" onClick={getUserHistoryByChannelSub.bind(this, record)}>History</button>

          </Fragment>
        );
      }
    }
  ];
  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents"
  }
  const [records, setRecords] = useState([])
  const [chan, setchan] = useState("");
  const [RightsAdd, setRightsAdd] = useState("D");
  const [RightsUpdate, setRightsUpdate] = useState("D");
  const [RightsDelete, setRightsDelete] = useState("D");
  const [UserVisitHistoryByChannelSub, setUserVisitHistoryByChannelSub] = useState([]);
  const [UserVisitImagesByChannelSub, setUserVisitImagesByChannelSub] = useState([]);


  let history = useHistory();

  function IconrenderSwitch(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'mdi mdi-close font-size-18';
      default:
        return 'mdi mdi-check-all font-size-18';
    }
  }

  function IconrenderSwitchColor(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }

  useEffect(() => {
    const f = {
      MenuId: "38",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
      //UserTypeId: "1"
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {

        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add') {
            setRightsAdd(MS);
          }
          else if (MA == 'Update') {
            setRightsUpdate(MS);
          }
          else if (MA == 'Delete') {
            setRightsDelete(MS);
          }
        }
      });
    gethannelSubSourceList();
  }, []);

  const gethannelSubSourceList = async () => {
    var QSearch = document.getElementById("txtQuickSearch").value;
    var StausFlag = document.getElementById("ddlRecordStatus").value;
    var FromDate = document.getElementById("txtStartDate").value;
    var ToDate = document.getElementById("txtEndDate").value;

    const ChannelSubSourceListFilterItem = {
      Flag: StausFlag,
      QuickSearch: QSearch,
      FromDate: FromDate,
      ToDate: ToDate,
    }

    axios.post(global.APIURL + "/ChannelSubSourceListWebFilter", ChannelSubSourceListFilterItem)
      .then(CLres => {
        let VWebLeadList = CLres.data.map(vcl => {
          return vcl;
        });
        setRecords(VWebLeadList);
      });
  };

  const editRecord = (record) => {
    let id = record["ChannelPartnerId"]
    let path = `/ChannelSubSource/EditChannelSubSource/:${id}`;
    history.push(path);
  }

  const deleteRecord = (record) => {
    let UTId = record["ChannelPartnerId"];
    let UTFlag = record["Flag"];
    let de = UTFlag == "A" ? "D" : UTFlag == "D" ? "A" : UTFlag;
    const UserTypeflag = {
      ChannelPartnerId: UTId,
      Flag: de
    }

    let AlertMessgae = UTFlag == "A" ? "Are you sure you want to inactive this record?" : "Are you sure you want to active this record?";
    if (confirm(AlertMessgae) == true) {
      axios.post(global.APIURL + '/ChannelSubSourceDelete', UserTypeflag)
        .then(res => {
          if (UTFlag == "A") {
            alert("Record Inactive Successfully");
          }
          else if (UTFlag == "D") {
            alert("Record Active Successfully");
          }
          gethannelSubSourceList();
        })
    }
  }


  const exportPDF = () => {

    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Channel Sub Source List Report";
    const headers = [["#", "Name", "ChannelPartnerCode", "ChannelName","Designation", "MobileNumber", "Email", "Department", "Address", "DistrictName","City","Pincode", "Created Date"]];

    const data = records.map((item, index) => [index + 1, item.Name, item.ChannelPartnerCode, item.ChannelName,item.Designation, item.MobileNumber, item.Email, item.Department, item.CurrentAddress, item.DistrictName,item.City,item.Pincode, item.CreateDate]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("ChannelSubSourceListReport.pdf")
  }


  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }

  const getUserHistoryByChannelSub = async (record) => {
    event.preventDefault();
    let ChannelPartnerId = record["ChannelPartnerId"];

    const ChannelSubUserdata = {
      ChannelSubSourceId: ChannelPartnerId
    }


    axios.post(global.APIURL + '/UserVisitHistoryByChannelPartner', ChannelSubUserdata)
      .then(res => {
        setUserVisitHistoryByChannelSub(res.data);
      });
  }

  const getUserVisitImagesByChannelSub = async (UserVisitId) => {
    const VisitImagedata = {
      UserVisitLogId: UserVisitId
    }
    axios.post(global.APIURL + '/UserVisitHistoryImages', VisitImagedata)
      .then(res => {
        setUserVisitImagesByChannelSub(res.data);
      });

  }

  const btnFilterClieck = async (e) => {
    gethannelSubSourceList();
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Channel Sub Source | KC Admin Group</title>
      </MetaTags>

      <div className="modal fade" id="exampleModalHistoryByChannelSub" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">User Visit History By Channel Sub Source</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form>
              <div className="modal-body">
                <div className="table-responsive text-center" id="style-4">
                  <table className="table table-sm table-bordered  ">
                    <thead>
                      <tr>
                        <th>ChannelName.</th>
                        <th>User Name</th>
                        <th>Visit Agenda</th>
                        <th>Visit Date</th>
                        <th>Visit From Time</th>
                        <th>Visit Person Contact</th>
                        <th>Visit Person Email</th>
                        <th>Visit Person Name</th>
                        <th>Visit Place Location</th>
                        <th>Visit Remark</th>
                        <th>Visit To Time</th>
                        <th>Visit Type</th>
                        <th>View Images</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {UserVisitHistoryByChannelSub.map(item => (
                        <tr key={item.UserVisitLogId}>
                          <td></td>
                          <td>{item.ChannelName}</td>
                          <td>{item.UserName}</td>
                          <td>{item.VisitAgenda}</td>
                          <td>{item.VisitDate}</td>
                          <td>{item.VisitFromTime}</td>
                          <td>{item.VisitPersonContact}</td>
                          <td>{item.VisitPersonEmail}</td>
                          <td>{item.VisitPersonName}</td>
                          <td>{item.VisitPlaceLocation}</td>
                          <td>{item.VisitRemark}</td>
                          <td>{item.VisitToTime}</td>
                          <td>{item.VisitType}</td>
                          <td><button type="button" data-bs-toggle="modal" data-bs-target="#exampleModalImagesByChannelSub" style={{ marginLeft: "16px" }} className="btn btn-sm btn-primary" onClick={getUserVisitImagesByChannelSub.bind(this, item.UserVisitLogId)}>View Images</button></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="modal fade visitImage" id="exampleModalImagesByChannelSub" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">View Images</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form>
              <div className="modal-body">
                <Row>
                  {UserVisitImagesByChannelSub.map(item => (<Col lg={6} key={item.UserVisitLogId}>
                    <div className="mb-2 vewsourcecode">
                      <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                      <a href={item.VisitPhotos1} target="_blank" rel="noreferrer" className="fadocumenttitleiconcolor" >{item.VisitPhotos1FileName}</a>
                    </div>
                  </Col>
                  ))}
                  {UserVisitImagesByChannelSub.map(item => (<Col lg={6} key={item.UserVisitLogId}>
                    <div className="mb-2 vewsourcecode">
                      <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                      <a href={item.VisitPhotos2} target="_blank" rel="noreferrer" className="fadocumenttitleiconcolor" >{item.VisitPhotos2FileName}</a>
                    </div>
                  </Col>
                  ))}
                  {UserVisitImagesByChannelSub.map(item => (<Col lg={6} key={item.UserVisitLogId}>
                    <div className="mb-2 vewsourcecode">
                      <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                      <a href={item.VisitPhotos3} target="_blank" rel="noreferrer" className="fadocumenttitleiconcolor" >{item.VisitPhotos3FileName}</a>
                    </div>
                  </Col>
                  ))}
                  {UserVisitImagesByChannelSub.map(item => (<Col lg={6} key={item.UserVisitLogId}>
                    <div className="mb-2 vewsourcecode">
                      <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                      <a href={item.VisitPhotos4} target="_blank" rel="noreferrer" className="fadocumenttitleiconcolor" >{item.VisitPhotos4FileName}</a>
                    </div>
                  </Col>
                  ))}
                  {UserVisitImagesByChannelSub.map(item => (<Col lg={6} key={item.UserVisitLogId}>
                    <div className="mb-2 vewsourcecode">
                      <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                      <a href={item.VisitPhotos5} target="_blank" rel="noreferrer" className="fadocumenttitleiconcolor" >{item.VisitPhotos5FileName}</a>
                    </div>
                  </Col>
                  ))}
                </Row>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <Row className="mb-3">
          <Col className="col-lg-6 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Channel Sub Source</CardTitle>
              <a href="/GeneralDashboard" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <span className="ms-2">Channel Sub Source</span>
            </div>
          </Col>
          <Col className="col-lg-6 co-md-6 col-6">
            <div className="d-flex align-items-center justify-content-end ">
              <div>
                <a className="btn btn-primary btn-sm float-end font-14" href="/GeneralDashboard">
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
                {RightsAdd == "A" ? <a className="btn btn-primary btn-sm float-end font-14 me-2" href="/ChannelSubSource/AddChannelSubSource">
                  <i className="mdi mdi-plus align-middle me-1"></i>Add New
                </a> : ''}
                <ReactHtmlTableToExcel
                  className="btn btn-primary btn-sm float-end font-14 me-2"
                  table="emp"
                  filename="ChannelSubsourceListReport"
                  sheet="Sheet"
                  buttonText="Export Excel" />
                <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="col-12">
            <Row>
              <Col xl="12">
                <div className="card card-body">
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label className="form-label"> Quick search</label>
                      <form className="app-search app-searchnew p-0 d-none d-lg-block">
                        <div className="position-relative"><input type="text" className="form-control" placeholder="Search..." id="txtQuickSearch" /><span className="bx bx-search-alt"></span>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="" className="form-label">Record Status</label>
                      <select id="ddlRecordStatus" className="form-select">
                        <option value={"A"} selected>Active Records</option>
                        <option value={"D"}>In-Active Records</option>
                        <option value={"ALL"}>ALL Records</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Start Date </label>
                        <input type="date" className="form-control" id="txtStartDate" placeholder="name@example.com" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label">End Date </label>
                        <input type="date" className="form-control" id="txtEndDate" placeholder="name@example.com" />
                      </div>
                    </div>

                    <div className="col-md-12 mt-3 mb-2">
                      <button type="button" className="btn btn-sm btn-success font-14" onClick={btnFilterClieck}>Go</button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <div>
            <table id="emp" className="table" hidden="hidden"  >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>ChannelPartnerCode</th>
                  <th>ChannelName</th>
                  <th>Designation</th>
                  <th>MobileNumber</th>
                  <th>Email</th>
                  <th>Department</th>
                  <th>Address</th>
                  <th>District</th>
                  <th>City</th>
                  <th>Pincode</th>
                  <th>Created Date</th>
                </tr>
              </thead>
              <tbody>              {
                records.map((item, index) => {
                  return <tr key={index}>
                    <td> {index + 1} </td>
                    <td>{item.Name}</td>
                    <td>{item.ChannelPartnerCode}</td>
                    <td>{item.ChannelName}</td>
                    <td>{item.Designation}</td>
                    <td>{item.MobileNumber}</td>
                    <td>{item.Email}</td>
                    <td>{item.Department}</td>
                    <td>{item.CurrentAddress}</td>
                    <td>{item.DistrictName}</td>
                    <td>{item.City}</td>
                    <td>{item.Pincode}</td>
                    <td>{item.CreateDate}</td>
                  </tr>
                })
              }
              </tbody>
            </table>
          </div>
        </Row>
        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12" className="datatablesearchhide">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} style={styles.table_filter}/>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default OnSort;