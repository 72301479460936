import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import LinkApp from 'App';


const OnSort = () => {
  let columns = [
    {
      key: "SR",
      text: "#",
     
      sortable: true
    },
    // {
    //   key: "Name",
    //   text: "Name",
    //   sortable: true
    // },
    // {
    //   key: "Email",
    //   text: "Email",
    //   sortable: true
    // },
    // {
    //   key: "Mobilenumber",
    //   text: "Mobile No.",
    //   sortable: true
    // },
    {
      key: "ChannelTypeName",
      text: "Network Type",
      sortable: true,
      width:150,
    },
    {
      key: "CompanyName",
      text: "Network Source",
      sortable: true,
      width:150,
    },

    {
      key: "Branchcode",
      text: "Branchcode",
      sortable: true,
      width:30,
    },
    {
      key: "CorporateEmail",
      text: "Email",
      sortable: true
    },
    {
      key: "Address",
      text: "Address",
      sortable: true
    },

    {
      d: "Status",
      text: "Status",
      width:100,
      cell: (record, index) => {
        let LeadStatus = record["LeadStatus"];
        let LeadStatusClass = record["LeadStatusClass"];
        return (
          <Fragment>
            {record["Status"] === "N" ? <a className="btn btn-primary btn-sm float-end font-14 me-2 sbtn" onClick={(getFollowupId.bind(this, record))} data-bs-toggle="modal" data-bs-target="#AddFollowUp"><i className="mdi mdi-plus align-middle me-1"></i> Add Follow Up</a>
              : <span className={LeadStatusClass}>{LeadStatus}</span>}
          </Fragment>
        );
      }
    },
    {
      d: "action",
      text: "Action",
      cell: (record, index) => {


        return (
          <Fragment>
            {RightsUpdate == "A" ? <a className="me-3 text-primary" onClick={(editRecord.bind(this, record))}><i className="mdi mdi-pencil font-size-18"></i></a> : ''}

          </Fragment>
        );
      }
    }
  ];

  const RedirectAddNewFollowup = (record) => {
    let VFcorLeadId = record["CorporateleadId"]
    let VcorFollowUpId = record["CorporateleadfollowupId"]
    localStorage.setItem('CorporateleadId', VFcorLeadId);
    localStorage.setItem('CorporateleadfollowupId', VcorFollowUpId);
    window.location.href = "/EditCorLeadFollowups";
  }

  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
  }

  const [records, setRecords] = useState([])
  const [CreditStatusFlag, setCreditStatusFlag] = useState("");
  const [RightsAdd, setRightsAdd] = useState("D");
  const [RightsUpdate, setRightsUpdate] = useState("D");
  const [RightsDelete, setRightsDelete] = useState("D");
  const [FollowUpRecord, setFollowUpRecord] = useState([]);
  const [RightsAdd1, setRightsAdd1] = useState("D");
  const [RightsUpdate1, setRightsUpdate1] = useState("D");
  const [RightsDelete1, setRightsDelete1] = useState("D");
  const [NextFollowupDate, setNextFollowupDate] = useState('');
  const [NextFollowupTime, setNextFollowupTime] = useState('');
  const [NextFollowupMode, setNextFollowupMode] = useState('');
  const [NextFollowupNote, setNextFollowupNote] = useState('');
  const [FollowupId, setFollowupId] = useState(0);

  let history = useHistory();


  useEffect(() => {
    const f = {
      MenuId: "48",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {
        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add') {
            setRightsAdd(MS);
          }
          else if (MA == 'Update') {
            setRightsUpdate(MS);
          }
          else if (MA == 'Delete') {
            setRightsDelete(MS);
          }
        }
      });

    fetch(global.APIURL + "/corporateLeadList/A")
      .then((res) => res.json())
      .then((json) => {
        setRecords(json)
      })
  }, []);


  function handleChange(event) {

    if (event.target.value == "A") {
      fetch(global.APIURL + "/corporateLeadList/A")
        .then((req) => req.json())
        .then((json) => {
          setRecords(json)
        })
    }
    else if (event.target.value == "ALL") {
      fetch(global.APIURL + "/corporateLeadList/ALL")
        .then((req) => req.json())
        .then((json) => {
          setRecords(json)
        })
    }
    else {
      fetch(global.APIURL + "/corporateLeadList/D")
        .then((req) => req.json())
        .then((json) => {
          setRecords(json)
        })
    }
  }
  const editRecord = (record) => {
    let id = record["CorporateleadId"]
    let path = `CorLeads/Editcorleads/:${id}`;
    history.push(path);
  }

  const deleteRecord = (record) => {
    let UTId = record["CorporateleadId"];
    let UTFlag = record["Flag"];
    let de = UTFlag == "A" ? "D" : UTFlag == "D" ? "A" : UTFlag;
    const Statusflag = {
      Id: UTId,
      Flag: de
    }
    let AlertMessgae = UTFlag == "A" ? "Are you sure you want to inactive this record?" : "Are you sure you want to active this record?";
    if (confirm(AlertMessgae) == true) {
      axios.post(global.APIURL + '/corporateLeadListdelete', Statusflag)
        .then(res => {
          if (UTFlag == "A") {
            alert("Record Inactive Successfully");
            fetch(global.APIURL + "/corporateLeadList/A")
              .then((res) => res.json())
              .then((json) => {
                setRecords(json)
              })
          }
          else if (UTFlag == "D") {
            alert("Record Active Successfully");
            fetch(global.APIURL + "/corporateLeadList/A")
              .then((res) => res.json())
              .then((json) => {
                setRecords(json)
                window.location.reload()
              })
          }
        })
    }
  }

  const exportPDF = () => {

    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Corporate Lead List Report";
    const headers = [["#", "Network Type", "Network Source", "Branch Code", "Email", "Address", "Status"]];

    const data = records.map((item, index) => [index + 1, item.ChannelTypeName, item.CompanyName, item.Branchcode, item.CorporateEmail, item.Address, item.LeadStatus]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("CorporateLeadListReport.pdf")
  }

  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }
  const LeadFollowUpSave = async () => {

    let VFollowupLeadId = FollowupId

    const LeadFollowUpArr =
    {
      CorporateleadId: VFollowupLeadId,
      ScheduledDate: NextFollowupDate,
      ScheduledTime: NextFollowupTime,
      Mode: NextFollowupMode,
      Agenda: NextFollowupNote,
      Remark: '',
      Status: "P",
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    }
    axios.post(global.APIURL + "/corLeadFollowUpAdd", LeadFollowUpArr).then(res => {
      if (res.data.length > 0) {
        alert(res.data[0].RecordStatus);
        $('#AddFollowUp').modal('hide');
        history.push("/CorLeadsFollowUps");

      }
    });

  }

  const getFollowupId = (record) => {
    let id = record["CorporateleadId"];
    setFollowupId(id);


  }

  const FollowDetailClose = async (e) => {
    e.preventDefault();
    document.getElementById("txtNextFollowupNote").value = "";
    document.getElementById("txtNextFollowupDate").value = "";
    document.getElementById("txtNextFollowupTime").value = "";
    document.getElementById("txtNextFollowMode").value = "";
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Corporate Lead | KC Admin Group</title>
      </MetaTags>

      <div className="modal fade bd-example-modal-lg" id="AddFollowUp" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div>
                <h5 className="modal-title" id="exampleModalLabel">Add Lead Follow Up</h5>
              </div>
              <div>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
              </div>
            </div>
            <div className="modal-body">
              <Row className="">
                <Card>
                  <CardBody>
                    <div className="mb-2">
                      <CardTitle>Set Lead Followup</CardTitle>
                      <hr />
                    </div>
                    <Row>

                      <div className="col-md-12">

                        <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                            <Row>
                              <div className="col-md-4 mb-2 mt-2">
                                <label className=""> Next Follow up Date </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder=""
                                  id="txtNextFollowupDate"
                                  onChange={e => setNextFollowupDate(e.target.value)}
                                  min={new Date().toISOString().split('T')[0]} 
                                />
                              </div>

                              <div className="col-md-4 mb-2 mt-2">
                                <label className=""> Next Follow up Time </label>
                                <input
                                  type="time"
                                  className="form-control"
                                  placeholder=""
                                  id="txtNextFollowupTime"
                                  onChange={e => setNextFollowupTime(e.target.value)}
                                />
                              </div>
                              <div className="col-md-4 mb-2 mt-2">
                                <label className=""> Next Follow up Mode </label>
                                <select className="form-select" aria-label="Default select example" id="txtNextFollowMode" onChange={e => setNextFollowupMode(e.target.value)}>
                                  <option selected>Select Mode</option>
                                  <option value="Call">Call</option>
                                  <option value="Email">Email</option>
                                  <option value="Meeting">Meeting</option>
                                </select>
                              </div>
                            </Row>
                            <Row>
                              <div className="mb-3 mt-2">
                                <label className="">
                                  {" "}
                                  Notes for Next Follow-up{" "}
                                </label>
                                <textarea
                                  className="form-control"
                                  placeholder="Enter Notes for Next Follow-up"
                                  id="txtNextFollowupNote"
                                  onChange={e => setNextFollowupNote(e.target.value)}
                                >
                                  {" "}
                                </textarea>
                              </div>
                            </Row>
                            <Row>
                              <div className="">
                                <a href="#" onClick={LeadFollowUpSave} className="btn btn_success me-2">
                                  {" "}
                                  Submit{" "}
                                </a>
                                <a href="#" onClick={FollowDetailClose} className="btn btn_danger me-2">
                                  {" "}
                                  Cancel{" "}
                                </a>
                              </div>
                            </Row>
                          </div>

                        </div>

                      </div>
                    </Row>

                  </CardBody>
                </Card>



              </Row>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <Row className="mb-3">
          <Col className="col-lg-6 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Corporate Lead</CardTitle>
              <a href="/GeneralDashboard" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <span className="ms-2">Corporate Lead</span>
            </div>
          </Col>
          <Col className="col-lg-6 co-md-6 col-6">
            <div className="d-flex align-items-center justify-content-end ">
              <div className="me-2">
                <select id="ddlActive" className="form-select selectbtnedit" onChange={handleChange}>
                  <option value={"A"} selected={CreditStatusFlag === "A"}>Active Records</option>
                  <option value={"D"}>In-Active Records</option>
                  <option value={"ALL"}>ALL Records</option>
                </select>
              </div>
              <div>
                {/* <a className="btn btn-primary btn-sm float-end font-14" href="/GeneralDashboard">
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a> */}
                {RightsAdd == "A" ? <a className="btn btn-primary btn-sm float-end font-14 me-2" href="/CorLeads/Addcorleads">
                  <i className="mdi mdi-plus align-middle me-1"></i>Add New
                </a> : ''}

                <ReactHtmlTableToExcel
                  className="btn btn-primary btn-sm float-end font-14 me-2"
                  table="emp"
                  filename="CorporateLeadListReport"
                  sheet="Sheet"
                  buttonText="Export Excel" />
                <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
              </div>
            </div>
          </Col>
        </Row>
        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
        <table id="emp" className="table" hidden="hidden">
          <thead>
            <tr>
              <th>#</th>
              <th>Network Type</th>
              <th>Network Source</th>
              <th>Branch Code</th>
              <th>Email</th>
              <th>Address</th>
              <th>Status</th>

            </tr>
          </thead>
          <tbody>
            {
              records.map((item, index) => {
                return <tr key={index}>
                  <td> {index + 1} </td>
                  <td>{item.ChannelTypeName}</td>
                  <td>{item.CompanyName}</td>
                  <td>{item.Branchcode}</td>
                  <td>{item.CorporateEmail}</td>
                  <td>{item.Address}</td>
                  <td>{item.LeadStatus}</td>

                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OnSort;