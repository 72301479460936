import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';

import LinkApp from 'App';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';


const OnSort = () => {
  let columns = [
    {
      key: "SR",
      text: "#",
      sortable: true
    },
    {
      key: "Title",
      text: "Title",
      sortable: true
    },
    {
      key: "SettingType",
      text: "Setting Type",
      sortable: true
    },
    {
      key: "SettingUserName",
      text: "Setting User",
      sortable: true
    },
    {
      d: "action",
      text: "Action",
      cell: (record, index) => {


        return (
          <Fragment>
            <a className="me-3 text-primary" onClick={(editRecord.bind(this, record))}><i className="mdi mdi-pencil font-size-18"></i></a>
          </Fragment>
        );
      }
    }
  ];
  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
    // button: {
    //     excel: true,
    //     print: true,
    //     csv: true
    // }
  }
  const [records, setRecords] = useState([])
  const [CategoryFlag, setuserFlag] = useState("");
  const [RightsAdd, setRightsAdd] = useState("D");
  const [RightsUpdate, setRightsUpdate] = useState("D");
  const [RightsDelete, setRightsDelete] = useState("D");


  let history = useHistory();

  function IconrenderSwitch(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'mdi mdi-close font-size-18';
      default:
        return 'mdi mdi-check-all font-size-18';
    }
  }

  function IconrenderSwitchColor(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }

  useEffect(() => {
    // const f = {
    //   MenuId: "22",
    //   UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
    //   //UserTypeId: "1"
    // }

    // axios.post(global.APIURL + '/HideShowWeb', f)
    //   .then(res => {

    //     for (let con = 0; con < res.data.length; con++) {
    //       const hidef = res.data[con];
    //       var MA = hidef.MenuAction;
    //       var MS = hidef.Status;
    //       if (MA == 'Add') {
    //         setRightsAdd(MS);
    //       }
    //       else if (MA == 'Update') {
    //         setRightsUpdate(MS);
    //       }
    //       else if (MA == 'Delete') {
    //         setRightsDelete(MS);
    //       }
    //     }
    //   });


    fetch(global.APIURL + "/DashboardSettingsList")
      .then((res) => res.json())
      .then((json) => {
        setRecords(json)
      })
  }, []);

  // function handleChange(event) {

  //   if (event.target.value == "A") {
  //     fetch(global.APIURL + "/Category/A")
  //       .then((req) => req.json())
  //       .then((json) => {
  //         setRecords(json)
  //       })
  //   }
  //   else if (event.target.value == "ALL") {
  //     fetch(global.APIURL + "/Category/ALL")
  //       .then((req) => req.json())
  //       .then((json) => {
  //         setRecords(json)
  //       })
  //   }
  //   else {
  //     fetch(global.APIURL + "/Category/D")
  //       .then((req) => req.json())
  //       .then((json) => {
  //         setRecords(json)
  //       })
  //   }


  // }

  const editRecord = (record) => {
    let id = record["Id"]
    localStorage.setItem('EditDashboardSettingId', id);
    window.location.href = "/EditDashboardSetting";
  }

  // const deleteRecord = (record) => {
  //   let UTId = record["Id"];
  //   let UTFlag = record["Flag"];
  //   let de = UTFlag == "A" ? "D" : UTFlag == "D" ? "A" : UTFlag;
  //   const Categoryflag = {
  //     Id: UTId,
  //     Flag: de
  //   }
  //   let AlertMessgae = UTFlag == "A" ? "Are you sure you want to inactive this record?" : "Are you sure you want to active this record?";
  //   if (confirm(AlertMessgae) == true) {
  //     axios.post(global.APIURL + '/CategoryDelete', Categoryflag)
  //       .then(res => {
  //         if (UTFlag == "A") {
  //           alert("Record Inactive Successfully");
  //           fetch(global.APIURL + "/Category/A")
  //             .then((res) => res.json())
  //             .then((json) => {
  //               setRecords(json)
  //             })
  //         }
  //         else if (UTFlag == "D") {
  //           alert("Record Active Successfully");
  //           fetch(global.APIURL + "/Category/A")
  //             .then((res) => res.json())
  //             .then((json) => {
  //               setRecords(json)
  //               window.location.reload()
  //             })
  //         }
  //       })
  //   }
  // }

  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }



  return (
    <div className="page-content">
      <MetaTags>
        <title>Dashboard Settings | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">
        <Row className="mb-3">
          <Col className="col-lg-6 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Dashboard Settings</CardTitle>
              <a href="/GeneralDashboard" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <span className="ms-2">Dashboard Settings</span>
            </div>
          </Col>
          <Col className="col-lg-6 co-md-6 col-6">
            <div className="d-flex align-items-center justify-content-end ">
              <div>
                <a className="btn btn-primary btn-sm float-end font-14" href="/GeneralDashboard">
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
                <a className="btn btn-primary btn-sm float-end font-14 me-2" href="AddDashboardSetting">
                  <i className="mdi mdi-plus align-middle me-1"></i>Add New
                </a>
              </div>
            </div>
          </Col>
        </Row>
        
        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default OnSort;