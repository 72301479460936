import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Input, Form, Button } from "reactstrap";
import { useHistory, useParams } from 'react-router-dom';
import { Search } from "react-bootstrap-table2-toolkit";
import "../../../assets/scss/datatables.scss";
import axios from "axios";
import PlacholderImgC from "assets/images/favicon.png";
import { Link } from "react-router-dom";


const EditCorporateServiceplan = () => {
    const history = useHistory();
    const [GSTList, SetGSTList] = useState([]);
    const [ServiceTitle, setServicetitle] = useState("");
    const [Monthlyrate, setmonthlyrate] = useState("");
    const [QuaterlyRate, setquaterlyrate] = useState("");
    const [Halfyearly, sethalfyearlyrate] = useState("");
    const [yearlyrate, setyearlyrate] = useState("");
    const [GSTId, setGstId] = useState("");
    const [GST, setGstName] = useState("");
    const [BureauDataList, setBureauDataList] = useState([]);
    const [CorporateserviceId, setCorporateserviceId] = useState("");
    const [IsActive, SetActive] = useState();
    const [BId, setBId] = useState(0);
    const [BList, setBList] = useState([]);
    let { id } = useParams();


    useEffect(() => {
        if (id != null) {
            if (id != "0") {
                let newid = id.split(':');
                getserviceDetailById(newid[1]);
                getGSTList();
            } else {
                history.push("CorporateserviceplanList");
            }
        } else {
            history.push("CorporateserviceplanList");
        }
    }, []);

    const getGSTList = async () => {
        var ACUrl = global.APIURL + '/GSTList';
        axios.get(ACUrl)
            .then(Cires => {
                let CityL = Cires.data.map(kccity => {
                    return kccity;
                });
                SetGSTList(CityL);
            });
    };

    const getserviceDetailById = async sId => {

        var AUrl = global.APIURL + '/CorporateserviceplanEdit/' + sId;
        axios.get(AUrl)
            .then(res => {
                setGstId(res.data.Result[0].list[0].GSTId);
                setServicetitle(res.data.Result[0].list[0].ServiceTitle);
                setmonthlyrate(res.data.Result[0].list[0].Monthlyrate);
                setquaterlyrate(res.data.Result[0].list[0].QuaterlyRate);
                sethalfyearlyrate(res.data.Result[0].list[0].Halfyearly);
                setyearlyrate(res.data.Result[0].list[0].yearlyrate);
                SetActive(res.data.Result[0].list[0].Status == "Yes" ? true : false);
                setBId(res.data.Result[0].list[0].BId);
                setBureauDataList(res.data.Result[0].blist);
                setCorporateserviceId(res.data.Result[0].list[0].CorporateserviceId)
            });
    };


    const UpdateServiceDeails = async () => {
        // var VIsActive = document.getElementById("flexCheckDefault").checked;
        let BureauIds = '';
        for (let bl = 0; bl < BureauDataList.length; bl++) {
            const BureauDataRow = BureauDataList[bl];
            let VFormControlId = '#' + BureauDataRow.FormControlId;
            let VBureauId = BureauDataRow.Id;
            if ($(VFormControlId).prop('checked') == true) {
                if (BureauIds == '') {
                    BureauIds = VBureauId;
                }
                else {
                    BureauIds = BureauIds + ',' + VBureauId;
                }
            }
        }
        const UserDetailArr = {
            CorporateserviceId: CorporateserviceId,
            ServiceTitle: ServiceTitle,
            Monthlyrate: Monthlyrate,
            QuaterlyRate: QuaterlyRate,
            Halfyearly: Halfyearly,
            yearlyrate: yearlyrate,
            GSTId: GSTId,
            GST: GST,
            BId: BureauIds,
            Flag: "A",
            IsActive: IsActive == true ? "Yes" : "No",
            CreateUser: localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId")
        }
        axios.post(global.APIURL + '/CorporateserviceplanUpdate', UserDetailArr)
            .then(res => {
                alert("Update Successfull");
                history.push("/CorporateserviceplanList");
            });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">

                    <Row className="mb-3">
                        <Col className="col-lg-8 col-md-6 col-6">
                            <div className="breadcrum ps-0">
                                <CardTitle className="h4">Edit Corporate Service Plan</CardTitle>
                                <a href="/GeneralDashboard" className="me-2">Home</a>
                                <span>/</span>
                                <a href="/CorporateserviceplanList"><span className="ms-2 me-2">Corporate Service Plan</span></a>
                                <span>/</span>
                                <span className="ms-2">Edit Corporate Service Plan</span>
                            </div>
                        </Col>
                        <Col className="col-lg-4 co-md-6 col-6">
                            <a className="btn btn-primary btn-sm float-end font-14" href="/CorporateserviceplanList">
                                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                            </a>
                        </Col>
                    </Row>

                    <div className="ind-card" id="ind-card">
                        <Card>
                            <CardBody>

                                <form>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-2">
                                                <label className="form-label">Service Title</label>
                                                <Input type="text" className="form-control" id="txtServiceTitle" placeholder="Enter Service Title" defaultValue={ServiceTitle} onChange={(e) => setServicetitle(e.target.value)} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col lg={3}>
                                            <div className="mb-2">
                                                <label className="form-label">Monthly Rate</label>
                                                <Input type="number" className="form-control" id="txtMonthlyRate" placeholder="Enter Monthly Rate" defaultValue={Monthlyrate} onChange={(e) => setmonthlyrate(e.target.value)} />
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="mb-2">
                                                <label className="form-label">Quaterly Rate</label>
                                                <Input type="number" className="form-control" id="txtQuaterlyRate" placeholder="Enter Quaterly Rate" defaultValue={QuaterlyRate} onChange={(e) => setquaterlyrate(e.target.value)} />
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="mb-2">
                                                <label className="form-label">Half yearly Rate</label>
                                                <Input type="number" className="form-control" id="txtHalfyearlyRate" placeholder="Enter Half yearly Rate" defaultValue={Halfyearly} onChange={(e) => sethalfyearlyrate(e.target.value)} />
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="mb-2">
                                                <label className="form-label">yearly Rate</label>
                                                <Input type="number" className="form-control" id="txtyearlyRate" placeholder="Enter yearly Rate" defaultValue={yearlyrate} onChange={(e) => setyearlyrate(e.target.value)} />
                                            </div>
                                        </Col>
                                        <Col className="col-lg-12">
                                            <Row>
                                                <div className="col-lg-3 col-md-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="GST" className="form-label">
                                                            GST
                                                        </label>
                                                        <select className="form-select" Id="ddlGST" name="ddlGST" onChange={e => { setGstId(e.target.value), setGstName(e.target.name) }}>
                                                            <option value={"0"}>Select GST</option>
                                                            {GSTList.map(g => (
                                                                <option key={g.Id} value={g.Id} name={g.value} selected={GSTId === g.Id}>
                                                                    {" "}
                                                                    {g.value}{" "}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-9 ">

                                                    <div className="checkList">
                                                        <div>
                                                            <label className="form-label">Bureau</label>
                                                        </div>
                                                        {BureauDataList.map((item, index) => (
                                                            item.IsSelect == 1 ?
                                                                <div key={index} className="form-check form-check-inline">

                                                                    <div >
                                                                        <input className="form-check-input" value={item.Id} type="checkbox" Id={item.FormControlId} defaultChecked={1} />
                                                                        <span htmlFor={item.FormControlId}>{item.value}</span>
                                                                    </div>

                                                                </div> : <div key={index} className="form-check form-check-inline">

                                                                    <div >
                                                                        <input className="form-check-input" value={item.Id} type="checkbox" Id={item.FormControlId} defaultChecked={0} />
                                                                        <span htmlFor={item.FormControlId}>{item.value}</span>
                                                                    </div>

                                                                </div>

                                                        ))}

                                                        {/* {BureauDataList.map((item, index) => (
                                                            <div key={index} className="form-check form-check-inline">

                                                                <div >
                                                                    <input className="form-check-input" value={item.Id} type="checkbox" onChange={handleCheck} defaultChecked={item.Id==BId} />
                                                                    <span className={isChecked(item.Id)}>{item.value}</span>
                                                                </div>

                                                            </div>
                                                        ))} */}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 ">

                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" onChange={e => SetActive(e.target.checked)} defaultChecked={IsActive} />
                                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                                            Active
                                                        </label>
                                                    </div>


                                                </div>

                                            </Row>
                                        </Col>

                                    </Row>
                                </form>


                                <div className="co-md-12 mt-3"><a href="#" className="btn btn_success me-2" onClick={() => UpdateServiceDeails()}> Save </a>
                                    <a href="#" className="btn btn_danger me-2"> Cancel </a></div>


                            </CardBody>
                        </Card>

                    </div>

                </div>
            </div>
        </React.Fragment >
    );
};

export default EditCorporateServiceplan;
