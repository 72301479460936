import React, { StrictMode, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input, Label } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import { parseInt } from "lodash";
import { event } from "jquery";
import $ from "jquery";
import ReactApexChart from "react-apexcharts";
import DashboardLoad from "../DashboardSettings/DashboardLoad";

import totalleads from "assets/images/dashboardimg/totalleads.png";
import totalindivisuallead from "assets/images/dashboardimg/totalindivisuallead.png";
import totalfirm from "assets/images/dashboardimg/totalfirm.png";
import totalclient from "assets/images/dashboardimg/totalclient.png";
import leadmain from "assets/images/dashboardimg/leadmain.png";
import convertedlead from "assets/images/dashboardimg/convertedlead.png";
import registeredlead from "assets/images/dashboardimg/registeredlead.png";
import closedlead from "assets/images/dashboardimg/closedlead.png";
import clientlead from "assets/images/dashboardimg/clientlead.png";


const AddDashboardSetting = () => {

  const [TotalLead, setTotalLead] = useState("0");
  const [TotalIndividualLead, setTotalIndividualLead] = useState("0");
  const [TotalFirmCompanyLead, setTotalFirmCompanyLead] = useState("0");
  const [TotalEnrollLead, setTotalEnrollLead] = useState("0");
  const [TotalIndividualLeadEnroll, setTotalIndividualLeadEnroll] = useState("0");
  const [TotalFirmCompanyLeadEnroll, setTotalFirmCompanyLeadEnroll] = useState("0");
  const [TotalConvertLead, setTotalConvertLead] = useState("0");
  const [TotalConvertIndividualLead, setTotalConvertIndividualLead] = useState("0");
  const [TotalConvertFirmCompanyLead, setTotalConvertFirmCompanyLead] = useState("0");
  const [TotalRegisterLead, setTotalRegisterLead] = useState("0");
  const [TotalRegisterIndividualLead, setTotalRegisterIndividualLead] = useState("0");
  const [TotalRegisterFirmCompanyLead, setTotalRegisterFirmCompanyLead] = useState("0");
  const [TotalCloseLead, setTotalCloseLead] = useState("0");
  const [TotalCloseIndividualLead, setTotalCloseIndividualLead] = useState("0");
  const [TotalCloseFirmCompanyLead, setTotalCloseFirmCompanyLead] = useState("0");
  const [TotalClient, setTotalClient] = useState("0");
  const [TotalIndividualClient, setTotalIndividualClient] = useState("0");
  const [TotalFirmCompanyClient, setTotalFirmCompanyClient] = useState("0");

  const [LeadList, setLeadList] = useState([]);
  const [IndividualLeadList, setIndividualLeadList] = useState([]);
  const [FirmCompanyLeadList, setFirmCompanyLeadList] = useState([]);

  const [EnrolledList, setEnrolledList] = useState([]);
  const [IndividualEnrolledList, setIndividualEnrolledList] = useState([]);
  const [FirmCompanyEnrolledList, setFirmCompanyEnrolledList] = useState([]);

  const [ConvertedList, setConvertedList] = useState([]);
  const [IndividualConvertedList, setIndividualConvertedList] = useState([]);
  const [FirmCompanyConvertedList, setFirmCompanyConvertedList] = useState([]);

  const [RegisteredList, setRegisteredList] = useState([]);
  const [IndividualRegisteredList, setIndividualRegisteredList] = useState([]);
  const [FirmCompanyRegisteredList, setFirmCompanyRegisteredList] = useState([]);

  const [ClosedList, setClosedList] = useState([]);
  const [IndividualClosedList, setIndividualClosedList] = useState([]);
  const [FirmCompanyClosedList, setFirmCompanyClosedList] = useState([]);

  const [ClientList, setClientList] = useState([]);
  const [IndividualClientList, setIndividualClientList] = useState([]);
  const [FirmCompanyClientList, setFirmCompanyClientList] = useState([]);

  const [CountersLayout, setCountersLayout] = useState('col-3');
  const [ChartsLayout, setChartsLayout] = useState('col-6');
  const [ListLayout, setListLayout] = useState('col-6');

  const [DisplayTotalLead, setDisplayTotalLead] = useState(0);
  const [DisplayTotalIndividualLead, setDisplayTotalIndividualLead] = useState(0);
  const [DisplayTotalFirmCompanyLead, setDisplayTotalFirmCompanyLead] = useState(0);
  const [DisplayTotalEnrollLead, setDisplayTotalEnrollLead] = useState(0);
  const [DisplayTotalIndividualLeadEnroll, setDisplayTotalIndividualLeadEnroll] = useState(0);
  const [DisplayTotalFirmCompanyLeadEnroll, setDisplayTotalFirmCompanyLeadEnroll] = useState(0);
  const [DisplayTotalConvertLead, setDisplayTotalConvertLead] = useState(0);
  const [DisplayTotalConvertIndividualLead, setDisplayTotalConvertIndividualLead] = useState(0);
  const [DisplayTotalConvertFirmCompanyLead, setDisplayTotalConvertFirmCompanyLead] = useState(0);
  const [DisplayTotalRegisterLead, setDisplayTotalRegisterLead] = useState(0);
  const [DisplayTotalRegisterIndividualLead, setDisplayTotalRegisterIndividualLead] = useState(0);
  const [DisplayTotalRegisterFirmCompanyLead, setDisplayTotalRegisterFirmCompanyLead] = useState(0);
  const [DisplayTotalCloseLead, setDisplayTotalCloseLead] = useState(0);
  const [DisplayTotalCloseIndividualLead, setDisplayTotalCloseIndividualLead] = useState(0);
  const [DisplayTotalCloseFirmCompanyLead, setDisplayTotalCloseFirmCompanyLead] = useState(0);
  const [DisplayTotalClient, setDisplayTotalClient] = useState(0);
  const [DisplayTotalIndividualClient, setDisplayTotalIndividualClient] = useState(0);
  const [DisplayTotalFirmCompanyClient, setDisplayTotalFirmCompanyClient] = useState(0);

  const [BarChart, setBarChart] = useState(0);
  const [PieChart, setPieChart] = useState(0);
  const [LineChart, setLineChart] = useState(0);
  const [AreaChart, setAreaChart] = useState(0);
  const [StackedAreaChart, setStackedAreaChart] = useState(0);

  const [DisplayLeadList, setDisplayLeadList] = useState(0);
  const [DisplayIndividualLeadList, setDisplayIndividualLeadList] = useState(0);
  const [DisplayFirmCompanyLeadList, setDisplayFirmCompanyLeadList] = useState(0);
  const [DisplayEnrollLeadList, setDisplayEnrollLeadList] = useState(0);
  const [DisplayIndividualEnrollLeadList, setDisplayIndividualEnrollLeadList] = useState(0);
  const [DisplayFirmCompanyEnrollLeadList, setDisplayFirmCompanyEnrollLeadList] = useState(0);
  const [DisplayConvertLeadList, setDisplayConvertLeadList] = useState(0);
  const [DisplayConvertIndividualLeadList, setDisplayConvertIndividualLeadList] = useState(0);
  const [DisplayConvertFirmCompanyLead, setDisplayConvertFirmCompanyLead] = useState(0);
  const [DisplayRegisterLeadList, setDisplayRegisterLeadList] = useState(0);
  const [DisplayRegisterIndividualLeadList, setDisplayRegisterIndividualLeadList] = useState(0);
  const [DisplayRegisterFirmCompanyLeadList, setDisplayRegisterFirmCompanyLeadList] = useState(0);
  const [DisplayCloseLeadList, setDisplayCloseLeadList] = useState(0);
  const [DisplayCloseIndividualLeadList, setDisplayCloseIndividualLeadList] = useState(0);
  const [DisplayCloseFirmCompanyLeadList, setDisplayCloseFirmCompanyLeadList] = useState(0);
  const [DisplayClientList, setDisplayClientList] = useState(0);
  const [DisplayIndividualClientList, setDisplayIndividualClientList] = useState(0);
  const [DisplayFirmCompanyClientList, setDisplayFirmCompanyClientList] = useState(0);

  const [UserList, setUserList] = useState([]);

  const [SelectUserId, setSelectUserId] = useState('0');
  const [SelectSettingType, setSelectSettingType] = useState('Public');

  const [DisplayLayoutTypeCount, setDisplayLayoutTypeCount] = useState('col-md-3');
  const [DisplayLayoutTypeCount1, setDisplayLayoutTypeCount1] = useState('3');
  const [DisplayLayoutTypeChart, setDisplayLayoutTypeChart] = useState('6');
  const [DisplayLayoutTypeList, setDisplayLayoutTypeList] = useState('6');

  const chartData = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "45%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["transparent"],
      },

      colors: ["#34c38f", "#556ee6", "#f46a6a"],
      xaxis: {
        categories: [
          "Lead",
          "Enroll",
          "Convert",
          "Register",
          "Close",
          "Client"
        ],
      },
      yaxis: {
        title: {
          text: "No. Of Inquiry",
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          },
        },
      },
    }, series: [
      {
        name: "Individual",
        data: [TotalIndividualLead, TotalIndividualLeadEnroll,
          TotalConvertIndividualLead, TotalRegisterIndividualLead,
          TotalCloseIndividualLead, TotalIndividualClient],
      },
      {
        name: "Total",
        data: [TotalLead, TotalEnrollLead, TotalConvertLead,
          TotalRegisterLead, TotalCloseLead, TotalClient],
      },
      {
        name: "Firm/Company",
        data: [TotalFirmCompanyLead, TotalFirmCompanyLeadEnroll,
          TotalConvertFirmCompanyLead,
          TotalRegisterFirmCompanyLead, TotalCloseFirmCompanyLead, TotalFirmCompanyClient],
      }
    ]
  };

  const pieChart = {
    series: [TotalLead, TotalEnrollLead, TotalConvertLead,
      TotalRegisterLead, TotalCloseLead, TotalClient],
    options: {
      labels: ["Lead",
        "Enroll",
        "Convert",
        "Register",
        "Close",
        "Client"],
      colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c", "#556ee6"],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: false,
        fontSize: "14px",
        offsetX: 0,
        offsetY: -10,
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 240,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  }

  const lineChart = {
    series: [
      {
        name: "Individual",
        data: [TotalIndividualLead, TotalIndividualLeadEnroll,
          TotalConvertIndividualLead, TotalRegisterIndividualLead,
          TotalCloseIndividualLead, TotalIndividualClient],
      },
      {
        name: "Total",
        data: [TotalLead, TotalEnrollLead, TotalConvertLead,
          TotalRegisterLead, TotalCloseLead, TotalClient],
      },
      {
        name: "Firm/Company",
        data: [TotalFirmCompanyLead, TotalFirmCompanyLeadEnroll,
          TotalConvertFirmCompanyLead,
          TotalRegisterFirmCompanyLead, TotalCloseFirmCompanyLead, TotalFirmCompanyClient],
      },
    ],
    options: {
      chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
      colors: ["#556ee6", "#f46a6a", "#34c38f"],
      dataLabels: { enabled: !1 },
      stroke: { width: [3, 4, 3], curve: "straight", dashArray: [0, 8, 5] },

      markers: { size: 0, hover: { sizeOffset: 6 } },
      xaxis: {
        categories: [
          "Lead",
          "Enroll",
          "Convert",
          "Register",
          "Close",
          "Client"
        ],
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (e) {
                return e + " (mins)"
              },
            },
          },
          {
            title: {
              formatter: function (e) {
                return e + " per session"
              },
            },
          },
          {
            title: {
              formatter: function (e) {
                return e
              },
            },
          },
        ],
      },
      grid: { borderColor: "#f1f1f1" },
    },

  };

  const areaChart = {
    series: [
      {
        name: "Individual",
        data: [TotalIndividualLead, TotalIndividualLeadEnroll,
          TotalConvertIndividualLead, TotalRegisterIndividualLead,
          TotalCloseIndividualLead, TotalIndividualClient],
      },
      {
        name: "Total",
        data: [TotalLead, TotalEnrollLead, TotalConvertLead,
          TotalRegisterLead, TotalCloseLead, TotalClient],
      },
      {
        name: "Firm/Company",
        data: [TotalFirmCompanyLead, TotalFirmCompanyLeadEnroll,
          TotalConvertFirmCompanyLead,
          TotalRegisterFirmCompanyLead, TotalCloseFirmCompanyLead, TotalFirmCompanyClient],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },

      colors: ["#556ee6", "#34c38f", "#f46a6a"],
      xaxis: {
        categories: [
          "Lead",
          "Enroll",
          "Convert",
          "Register",
          "Close",
          "Client"
        ],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    }
  };

  const stackedBarChart = {
    options: {
      colors: ["#3c4ccf", "#f0f1f4"],
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: "40%",
        },
      },
      grid: {
        borderColor: "#f8f8fa",
        row: {
          colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },

      xaxis: {
        categories: [
          "Lead",
          "Enroll",
          "Convert",
          "Register",
          "Close",
          "Client"
        ],
        labels: {
          formatter: function (val) {
            return val
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          },
        },
      },
      fill: {
        opacity: 1,
      },

      legend: {
        show: false,
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
    series: [
      {
        name: "Individual",
        data: [TotalIndividualLead, TotalIndividualLeadEnroll,
          TotalConvertIndividualLead, TotalRegisterIndividualLead,
          TotalCloseIndividualLead, TotalIndividualClient],
      },
      {
        name: "Total",
        data: [TotalLead, TotalEnrollLead, TotalConvertLead,
          TotalRegisterLead, TotalCloseLead, TotalClient],
      },
      {
        name: "Firm/Company",
        data: [TotalFirmCompanyLead, TotalFirmCompanyLeadEnroll,
          TotalConvertFirmCompanyLead,
          TotalRegisterFirmCompanyLead, TotalCloseFirmCompanyLead, TotalFirmCompanyClient],
      },
    ],

  };


  useEffect(() => {
    getDashboardCount();

    getLeadList();
    getIndividualLeadList();
    getFirmCompanyLeadList();

    getEnrolledList();
    getIndividualEnrolledList();
    getFirmCompanyEnrolledList();

    getConvertedList();
    getIndividualConvertedList();
    getFirmCompanyConvertedList();

    getRegisteredList();
    getIndividualRegisteredList();
    getFirmCompanyRegisteredList();

    getClosedList();
    getIndividualClosedList();
    getFirmCompanyClosedList();

    getClientList();
    getIndividualClientList();
    getFirmCompanyClientList();

    getUserList();
  }, []);

  const getUserList = async () => {
    axios.get(global.APIURL + '/ActiveUserList')
      .then(Tres => {
        let ActiveUser = Tres.data.map(kctag => {
          return kctag;
        });
        setUserList(ActiveUser);
      });
  };

  const getDashboardCount = async () => {
    const LeadListFilterArr = { RecordType: 'ALL', SpMode: 'DashboardCountSelectAll' }
    var AUrl = global.APIURL + '/DashboardCount/';
    axios.post(AUrl, LeadListFilterArr)
      .then(res => {
        setTotalLead(res.data[0].TotalLead);
        setTotalIndividualLead(res.data[0].TotalIndividualLead);
        setTotalFirmCompanyLead(res.data[0].TotalFirmCompanyLead);
        setTotalEnrollLead(res.data[0].TotalEnrollLead);
        setTotalIndividualLeadEnroll(res.data[0].TotalIndividualLeadEnroll);
        setTotalFirmCompanyLeadEnroll(res.data[0].TotalFirmCompanyLeadEnroll);
        setTotalConvertLead(res.data[0].TotalConvertLead);
        setTotalConvertIndividualLead(res.data[0].TotalConvertIndividualLead);
        setTotalConvertFirmCompanyLead(res.data[0].TotalConvertFirmCompanyLead);
        setTotalRegisterLead(res.data[0].TotalRegisterLead);
        setTotalRegisterIndividualLead(res.data[0].TotalRegisterIndividualLead);
        setTotalRegisterFirmCompanyLead(res.data[0].TotalRegisterFirmCompanyLead);
        setTotalCloseLead(res.data[0].TotalCloseLead);
        setTotalCloseIndividualLead(res.data[0].TotalCloseIndividualLead);
        setTotalCloseFirmCompanyLead(res.data[0].TotalCloseFirmCompanyLead);
        setTotalClient(res.data[0].TotalClient);
        setTotalIndividualClient(res.data[0].TotalIndividualClient);
        setTotalFirmCompanyClient(res.data[0].TotalFirmCompanyClient);
      })

  }

  const getLeadList = async () => {
    const LeadListFilterArr = { LeadType: 'ALL', RecordType: 'ALL', SpMode: 'DashboardLeadListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setLeadList(VLeadList);
      });
  }

  const getIndividualLeadList = async () => {
    const LeadListFilterArr = { LeadType: 'Individual', RecordType: 'ALL', SpMode: 'DashboardLeadListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setIndividualLeadList(VLeadList);
      });
  }

  const getFirmCompanyLeadList = async () => {
    const LeadListFilterArr = { LeadType: 'Firm/Company', RecordType: 'ALL', SpMode: 'DashboardLeadListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setFirmCompanyLeadList(VLeadList);
      });
  }

  const getEnrolledList = async () => {
    const LeadListFilterArr = { LeadType: 'ALL', RecordType: 'ALL', SpMode: 'DashboardEnrolledListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setEnrolledList(VLeadList);
      });
  }

  const getIndividualEnrolledList = async () => {
    const LeadListFilterArr = { LeadType: 'Individual', RecordType: 'ALL', SpMode: 'DashboardEnrolledListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setIndividualEnrolledList(VLeadList);
      });
  }

  const getFirmCompanyEnrolledList = async () => {
    const LeadListFilterArr = { LeadType: 'Firm/Company', RecordType: 'ALL', SpMode: 'DashboardEnrolledListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setFirmCompanyEnrolledList(VLeadList);
      });
  }

  const getConvertedList = async () => {
    const LeadListFilterArr = { LeadType: 'ALL', RecordType: 'ALL', SpMode: 'DashboardConvertedListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setConvertedList(VLeadList);
      });
  }

  const getIndividualConvertedList = async () => {
    const LeadListFilterArr = { LeadType: 'Individual', RecordType: 'ALL', SpMode: 'DashboardConvertedListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setIndividualConvertedList(VLeadList);
      });
  }

  const getFirmCompanyConvertedList = async () => {
    const LeadListFilterArr = { LeadType: 'Firm/Company', RecordType: 'ALL', SpMode: 'DashboardConvertedListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setFirmCompanyConvertedList(VLeadList);
      });
  }

  const getRegisteredList = async () => {
    const LeadListFilterArr = { LeadType: 'ALL', RecordType: 'ALL', SpMode: 'DashboardRegisteredListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setRegisteredList(VLeadList);
      });
  }

  const getIndividualRegisteredList = async () => {
    const LeadListFilterArr = { LeadType: 'Individual', RecordType: 'ALL', SpMode: 'DashboardRegisteredListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setIndividualRegisteredList(VLeadList);
      });
  }

  const getFirmCompanyRegisteredList = async () => {
    const LeadListFilterArr = { LeadType: 'Firm/Company', RecordType: 'ALL', SpMode: 'DashboardRegisteredListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setFirmCompanyRegisteredList(VLeadList);
      });
  }

  const getClosedList = async () => {
    const LeadListFilterArr = { LeadType: 'ALL', RecordType: 'ALL', SpMode: 'DashboardClosedListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setClosedList(VLeadList);
      });
  }

  const getIndividualClosedList = async () => {
    const LeadListFilterArr = { LeadType: 'Individual', RecordType: 'ALL', SpMode: 'DashboardClosedListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setIndividualClosedList(VLeadList);
      });
  }

  const getFirmCompanyClosedList = async () => {
    const LeadListFilterArr = { LeadType: 'Firm/Company', RecordType: 'ALL', SpMode: 'DashboardClosedListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setFirmCompanyClosedList(VLeadList);
      });
  }

  const getClientList = async () => {
    const LeadListFilterArr = { LeadType: 'ALL', RecordType: 'ALL', SpMode: 'DashboardClientListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setClientList(VLeadList);
      });
  }

  const getIndividualClientList = async () => {
    const LeadListFilterArr = { LeadType: 'Individual', RecordType: 'ALL', SpMode: 'DashboardClientListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setIndividualClientList(VLeadList);
      });
  }

  const getFirmCompanyClientList = async () => {
    const LeadListFilterArr = { LeadType: 'Firm/Company', RecordType: 'ALL', SpMode: 'DashboardClientListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setFirmCompanyClientList(VLeadList);
      });
  }

  const handleChart = (event, chartvalues, chartType, chartname) => {
    const checked = event.target.checked;
    const id = event.target.id;
    if (chartType == 'bar' && chartname == 'Bar Chart') {
      if (checked) {
        setBarChart(1);
      } else if (!checked) {
        setBarChart(0);
      }
    }
    else if (chartType == 'pie' && chartname == 'Pie Chart') {
      if (checked) {
        setPieChart(1);
      } else if (!checked) {
        setPieChart(0);
      }
    }
    else if (chartType == 'line' && chartname == 'Line Chart') {
      if (checked) {
        setLineChart(1);
      } else if (!checked) {
        setLineChart(0);
      }
    }
    else if (chartType == 'area' && chartname == 'Area Chart') {
      if (checked) {
        setAreaChart(1);
      } else if (!checked) {
        setAreaChart(0);
      }
    }
    else if (chartType == 'bar' && chartname == 'Stacked Area Chart') {
      if (checked) {
        setStackedAreaChart(1);
      } else if (!checked) {
        setStackedAreaChart(0);
      }
    }
  }

  const handleLeadList = async (event, VLeadType) => {
    const checked = event.target.checked;
    const id = event.target.id;
    if (VLeadType == 'ALL') {
      if (checked) {
        setDisplayLeadList(1);
      } else if (!checked) {
        setDisplayLeadList(0);
      }
    }
    else if (VLeadType == 'Individual') {
      if (checked) {
        setDisplayIndividualLeadList(1);
      } else if (!checked) {
        setDisplayIndividualLeadList(0);
      }
    }
    else if (VLeadType == 'Firm/Company') {
      if (checked) {
        setDisplayFirmCompanyLeadList(1);
      } else if (!checked) {
        setDisplayFirmCompanyLeadList(0);
      }
    }
  }

  const handleEnrollList = async (event, VLeadType) => {
    const checked = event.target.checked;
    const id = event.target.id;
    if (VLeadType == "ALL") {
      if (checked) {
        setDisplayEnrollLeadList(1);
      }
      else {
        setDisplayEnrollLeadList(0);
      }
    }
    else if (VLeadType == "Individual") {
      if (checked) {
        setDisplayIndividualEnrollLeadList(1);
      }
      else {
        setDisplayIndividualEnrollLeadList(0);
      }
    }
    else if (VLeadType == "Firm/Company") {
      if (checked) {
        setDisplayFirmCompanyEnrollLeadList(1);
      }
      else {
        setDisplayFirmCompanyEnrollLeadList(0);
      }
    }
  }

  const handleConvertList = async (event, VLeadType) => {
    const checked = event.target.checked;
    const id = event.target.id;
    if (VLeadType == "ALL") {
      if (checked) {
        setDisplayConvertLeadList(1);
      }
      else {
        setDisplayConvertLeadList(0);
      }
    }
    else if (VLeadType == "Individual") {
      if (checked) {
        setDisplayConvertIndividualLeadList(1);
      }
      else {
        setDisplayConvertIndividualLeadList(0);
      }
    }
    else if (VLeadType == "Firm/Company") {
      if (checked) {
        setDisplayConvertFirmCompanyLead(1);
      }
      else {
        setDisplayConvertFirmCompanyLead(0);
      }
    }
  }

  const handleRegisterList = async (event, VLeadType) => {
    const checked = event.target.checked;
    const id = event.target.id;
    if (VLeadType == "ALL") {
      if (checked) {
        setDisplayRegisterLeadList(1);
      }
      else {
        setDisplayRegisterLeadList(0);
      }
    }
    else if (VLeadType == "Individual") {
      if (checked) {
        setDisplayRegisterIndividualLeadList(1);
      }
      else {
        setDisplayRegisterIndividualLeadList(0);
      }
    }
    else if (VLeadType == "Firm/Company") {
      if (checked) {
        setDisplayRegisterFirmCompanyLeadList(1);
      }
      else {
        setDisplayRegisterFirmCompanyLeadList(0);
      }
    }
  }

  const handleClosedList = async (event, VLeadType) => {
    const checked = event.target.checked;
    const id = event.target.id;
    if (VLeadType == "ALL") {
      if (checked) {
        setDisplayCloseLeadList(1);
      }
      else {
        setDisplayCloseLeadList(0);
      }
    }
    else if (VLeadType == "Individual") {
      if (checked) {
        setDisplayCloseIndividualLeadList(1);
      }
      else {
        setDisplayCloseIndividualLeadList(0);
      }
    }
    else if (VLeadType == "Firm/Company") {
      if (checked) {
        setDisplayCloseFirmCompanyLeadList(1);
      }
      else {
        setDisplayCloseFirmCompanyLeadList(0);
      }
    }
  }

  const handleClientList = async (event, VLeadType) => {
    const checked = event.target.checked;
    if (VLeadType == "ALL") {
      if (checked) {
        setDisplayClientList(1);
      }
      else {
        setDisplayClientList(0);
      }
    }
    else if (VLeadType == "Individual") {
      if (checked) {
        setDisplayIndividualClientList(1);
      }
      else {
        setDisplayIndividualClientList(0);
      }
    }
    else if (VLeadType == "Firm/Company") {
      if (checked) {
        setDisplayFirmCompanyClientList(1);
      }
      else {
        setDisplayFirmCompanyClientList(0);
      }
    }
  }


  const handleLeadCount = async (event, VLeadType) => {
    const checked = event.target.checked;
    if (VLeadType == "ALL") {
      if (checked) {
        setDisplayTotalLead(1);
      }
      else {
        setDisplayTotalLead(0);
      }
    }
    else if (VLeadType == "Individual") {
      if (checked) {
        setDisplayTotalIndividualLead(1);
      }
      else {
        setDisplayTotalIndividualLead(0);
      }
    }
    else if (VLeadType == "Firm/Company") {
      if (checked) {
        setDisplayTotalFirmCompanyLead(1);
      }
      else {
        setDisplayTotalFirmCompanyLead(0);
      }
    }
  }

  const handleEnrollCount = async (event, VLeadType) => {
    const checked = event.target.checked;
    if (VLeadType == "ALL") {
      if (checked) {
        setDisplayTotalEnrollLead(1);
      }
      else {
        setDisplayTotalEnrollLead(0);
      }
    }
    else if (VLeadType == "Individual") {
      if (checked) {
        setDisplayTotalIndividualLeadEnroll(1);
      }
      else {
        setDisplayTotalIndividualLeadEnroll(0);
      }
    }
    else if (VLeadType == "Firm/Company") {
      if (checked) {
        setDisplayTotalFirmCompanyLeadEnroll(1);
      }
      else {
        setDisplayTotalFirmCompanyLeadEnroll(0);
      }
    }
  }

  const handleConvertedCount = async (event, VLeadType) => {
    const checked = event.target.checked;
    if (VLeadType == "ALL") {
      if (checked) {
        setDisplayTotalConvertLead(1);
      }
      else {
        setDisplayTotalConvertLead(0);
      }
    }
    else if (VLeadType == "Individual") {
      if (checked) {
        setDisplayTotalConvertIndividualLead(1);
      }
      else {
        setDisplayTotalConvertIndividualLead(0);
      }
    }
    else if (VLeadType == "Firm/Company") {
      if (checked) {
        setDisplayTotalConvertFirmCompanyLead(1);
      }
      else {
        setDisplayTotalConvertFirmCompanyLead(0);
      }
    }
  }

  const handleRegisteredCount = async (event, VLeadType) => {
    const checked = event.target.checked;
    if (VLeadType == "ALL") {
      if (checked) {
        setDisplayTotalRegisterLead(1);
      }
      else {
        setDisplayTotalRegisterLead(0);
      }
    }
    else if (VLeadType == "Individual") {
      if (checked) {
        setDisplayTotalRegisterIndividualLead(1);
      }
      else {
        setDisplayTotalRegisterIndividualLead(0);
      }
    }
    else if (VLeadType == "Firm/Company") {
      if (checked) {
        setDisplayTotalRegisterFirmCompanyLead(1);
      }
      else {
        setDisplayTotalRegisterFirmCompanyLead(0);
      }
    }
  }

  const handleClosedCount = async (event, VLeadType) => {
    const checked = event.target.checked;
    if (VLeadType == "ALL") {
      if (checked) {
        setDisplayTotalCloseLead(1);
      }
      else {
        setDisplayTotalCloseLead(0);
      }
    }
    else if (VLeadType == "Individual") {
      if (checked) {
        setDisplayTotalCloseIndividualLead(1);
      }
      else {
        setDisplayTotalCloseIndividualLead(0);
      }
    }
    else if (VLeadType == "Firm/Company") {
      if (checked) {
        setDisplayTotalCloseFirmCompanyLead(1);
      }
      else {
        setDisplayTotalCloseFirmCompanyLead(0);
      }
    }
  }

  const handleClientCount = async (event, VLeadType) => {
    const checked = event.target.checked;
    if (VLeadType == "ALL") {
      if (checked) {
        setDisplayTotalClient(1);
      }
      else {
        setDisplayTotalClient(0);
      }
    }
    else if (VLeadType == "Individual") {
      if (checked) {
        setDisplayTotalIndividualClient(1);
      }
      else {
        setDisplayTotalIndividualClient(0);
      }
    }
    else if (VLeadType == "Firm/Company") {
      if (checked) {
        setDisplayTotalFirmCompanyClient(1);
      }
      else {
        setDisplayTotalFirmCompanyClient(0);
      }
    }
  }

  const SaveDashboardSettings = async () => {

    var CreateUser = localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId");
    var VDashboardSettingsWidgetArr = [];
    var VDashboardSettingsUserArr = [];

    var VCkTLead = document.getElementById('CkTLead').checked;
    var VCkTILead = document.getElementById('CkTILead').checked;
    var VCkTFLead = document.getElementById('CkTFLead').checked;

    var VTEnroll = document.getElementById('CkTEnroll').checked;
    var VTIEnroll = document.getElementById('CkTIEnroll').checked;
    var VTFEnroll = document.getElementById('CkTFEnroll').checked;

    var VTConvert = document.getElementById('CkTConvert').checked;
    var VTIConvert = document.getElementById('CkTIConvert').checked;
    var VTFConvert = document.getElementById('CkTFConvert').checked;

    var VTRegister = document.getElementById('CkTRegister').checked;
    var VTIRegister = document.getElementById('CkTIRegister').checked;
    var VTFRegister = document.getElementById('CkTFRegister').checked;

    var VTClosed = document.getElementById('CkTClosed').checked;
    var VTIClosed = document.getElementById('CkTIClosed').checked;
    var VTFClosed = document.getElementById('CkTFClosed').checked;

    var VTClient = document.getElementById('CkTClient').checked;
    var VTIClient = document.getElementById('CkTIClient').checked;
    var VTFClient = document.getElementById('CkTFClient').checked;

    var VbarCheckbox = document.getElementById('barCheckbox').checked;
    var VradialCheckbox = document.getElementById('radialCheckbox').checked;
    var VlineCheckbox = document.getElementById('lineCheckbox').checked;
    var VareaCheckbox2 = document.getElementById('areaCheckbox2').checked;
    var VstackedCheckbox2 = document.getElementById('stackedCheckbox2').checked;

    var VLeadallCheckbox = document.getElementById('LeadallCheckbox').checked;
    var VLeadIndCheckbox = false;//document.getElementById('LeadIndCheckbox').checked;
    var VLeadFirmCheckbox = false;//document.getElementById('LeadFirmCheckbox').checked;

    var VEnrollAllCheckbox = document.getElementById('EnrollAllCheckbox').checked;
    var VEnrollIndCheckbox = false;//document.getElementById('EnrollIndCheckbox').checked;
    var VEnrollFirmCheckbox = false;//document.getElementById('EnrollFirmCheckbox').checked;

    var VConvertallCheckbox = document.getElementById('ConvertallCheckbox').checked;
    var VConvertIndCheckbox = false;//document.getElementById('ConvertIndCheckbox').checked;
    var VConvertFirmCheckbox = false;//document.getElementById('ConvertFirmCheckbox').checked;

    var VRegisterallCheckbox2 = document.getElementById('RegisterallCheckbox2').checked;
    var VRegisterIndCheckbox2 = false;//document.getElementById('RegisterIndCheckbox2').checked;
    var VRegisterFirmCheckbox2 = false;//document.getElementById('RegisterFirmCheckbox2').checked;

    var VClosedallCheckbox = document.getElementById('ClosedallCheckbox').checked;
    var VClosedIndividualCheckbox = false;//document.getElementById('ClosedIndividualCheckbox').checked;
    var VClosedFirmCompanyCheckbox = false;//document.getElementById('ClosedFirmCompanyCheckbox').checked;

    var VClientallCheckbox = document.getElementById('ClientallCheckbox').checked;
    var VClientindCheckbox = false;//document.getElementById('ClientindCheckbox').checked;
    var VClientFirmCheckbox = false;//document.getElementById('ClientFirmCheckbox').checked;
    var VSettingTypeId = 0;
    if (SelectSettingType == 'Private') {
      var VSTId = document.getElementById('ddlUser').value;
      VSettingTypeId = parseInt(VSTId == null ? '0' : VSTId == '' ? '0' : VSTId == 'Select User' ? '0' : VSTId);
    }
    else {
      VSettingTypeId = 0;
    }

    if (VCkTLead == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Lead', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VCkTILead == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Individual Lead', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VCkTFLead == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Firm/Company Lead', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VTEnroll == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Enrolled Lead', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VTIEnroll == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Individual Enrolled Lead', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VTFEnroll == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Firm/Company Enrolled Lead', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VTConvert == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Converted Lead', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VTIConvert == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Individual Converted Lead', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VTFConvert == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Firm/Company Converted Lead', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VTRegister == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Registered Lead', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VTIRegister == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Individual Registered Lead', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VTFRegister == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Firm/Company Registered Lead', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VTClosed == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Closed Lead', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VTIClosed == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Individual Closed Lead', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VTFClosed == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Firm/Company Closed Lead', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VTClient == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Client', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VTIClient == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Individual Client', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VTFClient == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: CountersLayout, WidgetType: 'Count', WidgetValue: 'Total Firm/Company Client', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }

    if (VbarCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ChartsLayout, WidgetType: 'Chart', WidgetValue: 'Bar Chart', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VradialCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ChartsLayout, WidgetType: 'Chart', WidgetValue: 'Pie Chart', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VlineCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ChartsLayout, WidgetType: 'Chart', WidgetValue: 'Line Chart', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VareaCheckbox2 == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ChartsLayout, WidgetType: 'Chart', WidgetValue: 'Area Chart', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VstackedCheckbox2 == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ChartsLayout, WidgetType: 'Chart', WidgetValue: 'Stacked Area Chart', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }

    if (VLeadallCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Lead List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VLeadIndCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Lead Individual List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VLeadFirmCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Lead Firm/Company List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VEnrollAllCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Enrolled List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VEnrollIndCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Enrolled Individual List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VEnrollFirmCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Enrolled Firm/Company List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VConvertallCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Converted List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VConvertIndCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Converted Individual List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VConvertFirmCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Converted Firm/Company List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VRegisterallCheckbox2 == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Registered List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VRegisterIndCheckbox2 == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Registered Individual List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VRegisterFirmCheckbox2 == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Registered Firm/Company List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VClosedallCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Closed List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VClosedIndividualCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Closed Individual List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VClosedFirmCompanyCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Closed Firm/Company List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VClientallCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Client List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VClientindCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Client Individual List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }
    if (VClientFirmCheckbox == true) {
      var dswarr = { Id: '0', DashboardSettingId: '0', LayoutType: ListLayout, WidgetType: 'List', WidgetValue: 'Client Firm/Company List', CreateUser: CreateUser };
      VDashboardSettingsWidgetArr.push(dswarr);
    }

    if (VSettingTypeId > 0) {
      var dsuarr = { Id: '0', DashboardSettingId: '0', UserId: VSettingTypeId, CreateUser: CreateUser };
      VDashboardSettingsUserArr.push(dsuarr);
    }

    if (VDashboardSettingsWidgetArr.length > 0) {
      const UserSettingArr = {
        Id: '0',
        SettingType: VSettingTypeId == 0 ? 'Public' : 'Private',
        SettingTypeId: VSettingTypeId,
        CreateUser: CreateUser,
        DashboardSettingsWidget: VDashboardSettingsWidgetArr,
        DashboardSettingsUser: VDashboardSettingsUserArr
      };


      axios.post(global.APIURL + '/DashboardSettingsSave', UserSettingArr)
        .then(res => {

          var SAlert = res.data.StatusMessage;
          alert(SAlert);
          if (SAlert == 'Insert Completed') {
            window.location.href = "/DashboardSettings";
          }
          else if (SAlert == 'Update Completed') {
            window.location.href = "/DashboardSettings";
          }
        });
    }
  };

  return (

    <div className="page-content">
      <MetaTags>
        <title>Add Dashboard Settings | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">
        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Add Dashboard Settings</CardTitle>
              <a href="/GeneralDashboard" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <a href="/DashboardSettings" className="me-2 ms-2">
                Dashboard Settings
              </a>{" "}
              <span>/</span>
              <span className="ms-2">Add Dashboard Settings</span>
            </div>
          </Col>

          <Col className="col-lg-4 co-md-6 col-12 text-end">
            <a className="btn btn-secondary btn-sm font-14 me-2" data-bs-toggle="modal" data-bs-target="#myModal2" >
              <i className="fas fa-sliders-h align-middle me-1"></i>Customize
            </a>
            <a type="button" value="Dashboard" className="btn btn_success btn-sm font-14 me-2" onClick={() => SaveDashboardSettings()}>
              Save
            </a>
            <a className="btn btn-primary btn-sm float-end font-14" href="/DashboardSettings">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <div className="modal right fade fadenew" id="myModal2" tabIndex="-1" aria-labelledby="exampleModal" aria-hidden="true">
          <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Add Dashboard Settings</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <h6 className="card-title">Layout</h6>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-check">
                      <input type="radio" id="rbPublic" name="rbSettingType" className="form-check-input" value="Public" defaultChecked onChange={e => setSelectSettingType(e.target.value)} />
                      <Label className="form-check-label" htmlFor="rbPublic" >Public</Label>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-check">
                      <input type="radio" id="rbPrivate" name="rbSettingType" className="form-check-input" value="Private" onChange={e => setSelectSettingType(e.target.value)} />
                      <Label className="form-check-label" htmlFor="rbPrivate" >Private</Label>
                    </div>
                  </div>
                  {SelectSettingType == 'Private' ? <div className="col-md-12 mb-3">
                    <label className="form-label">User</label>
                    <select className="form-select" name="ddlUser" Id="ddlUser" onChange={e => setSelectUserId(e.target.value)}>
                      <option value={"0"}>Select User</option>
                      {UserList.map(tag => (
                        <option key={tag.UserId} value={tag.UserId}>
                          {tag.Name}
                        </option>
                      ))}
                    </select>
                  </div> : ''}
                  <div className="col-md-12 mt-2 mb-2">
                    <h6 className="card-title">Counters Layout</h6>
                  </div>
                  <div className="col-md-6">
                    <button className="btn btn-primary w-100" onClick={(e) => { setDisplayLayoutTypeCount('col-4'); setDisplayLayoutTypeCount1('4') }} value="layoutThree"> <i className="fas fa-th pe-2"></i> Layout 3</button>
                  </div>
                  <div className="col-md-6">
                    <button className="btn btn-primary w-100" onClick={(e) => { setDisplayLayoutTypeCount('col-3'); setDisplayLayoutTypeCount1('3') }} value="layoutFour"> <i className="fas fa-th-list pe-2"></i> Layout 4</button>
                  </div>
                  <div className="col-md-12 mt-2 mb-2">
                    <h6 className="card-title">Counters</h6>
                  </div>
                  <div className="col-md-12">
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTLead" name="T-Lead" key="TLeadkey" value="TLead" onChange={event => handleLeadCount(event, "ALL")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox1">Total Lead</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTILead" name="TI-Lead" key="TILeadKey" value="TILead" onChange={event => handleLeadCount(event, "Individual")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox1">Total Individual Lead</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTFLead" value="TFLead" name="TF-Lead" key="TFLeadkey" onChange={event => handleLeadCount(event, "Firm/Company")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox1">Total Firm/Company</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTEnroll" value="TEnroll" name="T-Enroll" key="TEnrollkey" onChange={event => handleEnrollCount(event, "ALL")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox1">Total Enrolled Lead</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTIEnroll" value="TIEnroll" name="TI-Enroll" key="TIEnrollkey" onChange={event => handleEnrollCount(event, "Individual")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Total Individual Enrolled Lead</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTFEnroll" value="TFEnroll" name="TF-Enroll" key="TFEnrollkey" onChange={event => handleEnrollCount(event, "Firm/Company")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Total Firm/Company Enrolled Lead</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTConvert" value="TConvert" name="T-Convert" key="TConvertkey" onChange={event => handleConvertedCount(event, "ALL")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Total Converted Lead</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTIConvert" value="TIConvert" name="TI-Convert" key="TIConvertkey" onChange={event => handleConvertedCount(event, "Individual")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Total Converted Individual Lead</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTFConvert" value="TFConvert" name="TF-Convert" key="TFConvertkey" onChange={event => handleConvertedCount(event, "Firm/Company")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Total Converted Firm/Company Lead</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTRegister" value="TRegister" name="T-Register" key="TRegisterkey" onChange={event => handleRegisteredCount(event, "ALL")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Total Registered Lead</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTIRegister" value="TIRegister" name="TI-Register" key="TIRegisterkey" onChange={event => handleRegisteredCount(event, "Individual")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Total Registered Individual Lead</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTFRegister" value="TFRegister" name="TF-Register" key="TFRegisterkey" onChange={event => handleRegisteredCount(event, "Firm/Company")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Total Registered Firm/Company</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTClosed" value="TClosed" name="T-Closed" key="TClosedkey" onChange={event => handleClosedCount(event, "ALL")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Total Closed Lead</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTIClosed" value="TIClosed" name="TI-Closed" key="TIClosedkey" onChange={event => handleClosedCount(event, "Individual")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Total Closed Individual Lead</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTFClosed" value="TFClosed" name="TF-Closed" key="TFClosedkey" onChange={event => handleClosedCount(event, "Firm/Company")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Total Closed Firm/Company Lead</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTClient" value="TClient" name="T-Client" key="TClientkey" onChange={event => handleClientCount(event, "ALL")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Total Client</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTIClient" value="TIClient" name="TI-Client" key="TIClientkey" onChange={event => handleClientCount(event, "Individual")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Total Individual Client</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="CkTFClient" value="TFClient" name="TF-Client" key="TFClientkey" onChange={event => handleClientCount(event, "Firm/Company")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Total Firm/Company Client</label>
                    </div>
                  </div>
                  <div className="col-md-12 mt-2 mb-2">
                    <h6 className="card-title">Charts Layout</h6>
                  </div>
                  <div className="col-md-6">
                    <button className="btn btn-primary w-100" onClick={(e) => setDisplayLayoutTypeChart('12')} value="layoutThree"> <i className="fas fa-th pe-2"></i> Layout 1</button>
                  </div>
                  <div className="col-md-6">
                    <button className="btn btn-primary w-100" onClick={(e) => setDisplayLayoutTypeChart('6')} value="layoutFour"> <i className="fas fa-th-list pe-2"></i> Layout 2</button>
                  </div>
                  <div className="col-md-12 mt-2 mb-2">
                    <h6 className="card-title">Charts</h6>
                  </div>
                  <div className="col-md-12">
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="barCheckbox" value="option1" onChange={event => handleChart(event, chartData, "bar", "Bar Chart")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox01">Bar</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="radialCheckbox" value="option2" onChange={event => handleChart(event, pieChart, "pie", "Pie Chart")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Pie Chart</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="lineCheckbox" value="option3" onChange={event => handleChart(event, lineChart, "line", "Line Chart")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Line</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="areaCheckbox2" value="option4" onChange={event => handleChart(event, areaChart, "area", "Area Chart")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Area</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="stackedCheckbox2" value="option5" onChange={event => handleChart(event, stackedBarChart, "bar", "Stacked Area Chart")} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Stacked Area</label>
                    </div>
                  </div>
                  <div className="col-md-12 mt-2 mb-2">
                    <h6 className="card-title">Lists Layout</h6>
                  </div>
                  <div className="col-md-6">
                    <button className="btn btn-primary w-100" onClick={(e) => setDisplayLayoutTypeList('12')} value="layoutThree"> <i className="fas fa-th pe-2"></i> Layout 1</button>
                  </div>
                  <div className="col-md-6">
                    <button className="btn btn-primary w-100" onClick={(e) => setDisplayLayoutTypeList('6')} value="layoutFour"> <i className="fas fa-th-list pe-2"></i> Layout 2</button>
                  </div>
                  <div className="col-md-12 mt-2 mb-2">
                    <h6 className="card-title">Lists</h6>
                  </div>
                  <div className="col-md-12">
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="LeadallCheckbox" value="option1" onChange={event => handleLeadList(event, 'ALL')} />
                      <label className="form-check-label" htmlFor="inlineCheckbox">Lead List</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="EnrollAllCheckbox" value="option4" onChange={event => handleEnrollList(event, 'ALL')} />
                      <label className="form-check-label" htmlFor="inlineCheckbox">Enrolled List</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="ConvertallCheckbox" value="option7" onChange={event => handleConvertList(event, 'ALL')} />
                      <label className="form-check-label" htmlFor="inlineCheckbox">Converted List</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="RegisterallCheckbox2" onChange={event => handleRegisterList(event, 'ALL')} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Registered List</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="ClosedallCheckbox" onChange={event => handleClosedList(event, 'ALL')} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Closed List</label>
                    </div>
                    <div className="form-check  mb-0">
                      <input className="form-check-input" type="checkbox" id="ClientallCheckbox" onChange={event => handleClientList(event, 'ALL')} />
                      <label className="form-check-label" htmlFor="inlineCheckbox2">Client List</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Row>
          {DisplayTotalLead > 0 ?
            <div className={DisplayLayoutTypeCount}>
              <a href='AllInq'>
                <div className="card">
                  <div className="p-3">
                    <div className="d-flex justify-content-between">
                      <div className="contentmaindashboard">
                        <h2>
                          {TotalLead}
                        </h2>
                        <p>
                          Total Leads
                        </p>
                      </div>
                      <div className="imgboxdashboardmain">
                        <img src={totalleads}></img>
                      </div>
                    </div>

                  </div>

                </div>
              </a>
            </div> : ''}
          {DisplayTotalIndividualLead > 0 ?
            <div className={DisplayLayoutTypeCount}>
              <a href='AllInq'>
                <div className="card">
                  <div className="p-3">
                    <div className="d-flex justify-content-between">
                      <div className="contentmaindashboard">
                        <h2>
                          {TotalIndividualLead}
                        </h2>
                        <p>
                          Total Individual Lead
                        </p>
                      </div>
                      <div className="imgboxdashboardmain">
                        <img src={totalindivisuallead}></img>
                      </div>
                    </div>

                  </div>

                </div>
              </a>

            </div> : ''}
          {DisplayTotalFirmCompanyLead > 0 ?
            <div className={DisplayLayoutTypeCount}>
              <a href='AllInq'>
                <div className="card">
                  <div className="p-3">
                    <div className="d-flex justify-content-between">
                      <div className="contentmaindashboard">
                        <h2>
                          {TotalFirmCompanyLead}
                        </h2>
                        <p>
                          Total Firm/Company Lead
                        </p>
                      </div>
                      <div className="imgboxdashboardmain">
                        <img src={totalfirm}></img>
                      </div>
                    </div>

                  </div>

                </div>
              </a>


            </div> : ''}

          {DisplayTotalClient > 0 ?
            <div className={DisplayLayoutTypeCount}>
              <a href='ClientLead'>
                <div className="card">
                  <div className="p-3">
                    <div className="d-flex justify-content-between">
                      <div className="contentmaindashboard">
                        <h2>
                          {TotalClient}
                        </h2>
                        <p> Total Client
                        </p>
                      </div>
                      <div className="imgboxdashboardmain">
                        <img src={totalclient}></img>
                      </div>
                    </div>

                  </div>

                </div>
              </a>


            </div> : ''}
        </Row>
        <Row>
          <div className="col-md-12">
            <div className="card">
              <div className="p-4">
                <div className="row">
                  {DisplayTotalEnrollLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                    <a href='LeadList'>
                      <Row>
                        <Col md="12" className="mb-2">
                          <div className="mini-stats-wid mini-stats-widnew">
                            <div>
                              <div className="countimgmain">
                                <img src={leadmain}></img>
                              </div>
                            </div>
                            <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                              <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="d-flex align-items-center">
                                  <span className="mb-0 ndedit">{TotalEnrollLead}</span>
                                  <p className="ndtitaleedit">

                                    Total Enrolled Lead
                                  </p>

                                </div>
                                <div>
                                  <i className="fas fa-chevron-right"></i>
                                </div>

                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </a>
                  </Col> : ''}

                  {DisplayTotalIndividualLeadEnroll > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                    <a href='LeadList'>
                      <Row>
                        <Col md="12" className="mb-2">
                          <div className="mini-stats-wid mini-stats-widnew">
                            <div>
                              <div className="countimgmain">
                                <img src={leadmain}></img>
                              </div>
                            </div>
                            <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                              <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="d-flex align-items-center">
                                  <span className="mb-0 ndedit">{TotalIndividualLeadEnroll}</span>
                                  <p className="ndtitaleedit">

                                    Total Individual Enrolled Lead
                                  </p>

                                </div>
                                <div>
                                  <i className="fas fa-chevron-right"></i>
                                </div>

                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </a>
                  </Col> : ''}

                  {DisplayTotalFirmCompanyLeadEnroll > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                    <a href='LeadList'>
                      <Row>
                        <Col md="12" className="mb-2">
                          <div className="mini-stats-wid mini-stats-widnew">
                            <div>
                              <div className="countimgmain">
                                <img src={leadmain}></img>
                              </div>
                            </div>
                            <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                              <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="d-flex align-items-center">
                                  <span className="mb-0 ndedit">{TotalFirmCompanyLeadEnroll}</span>
                                  <p className="ndtitaleedit">

                                    Total Firm/Company Enrolled Lead
                                  </p>

                                </div>
                                <div>
                                  <i className="fas fa-chevron-right"></i>
                                </div>

                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </a>
                  </Col> : ''}

                  {DisplayTotalConvertLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                    <a href='ConvertedInq'>
                      <Row>
                        <Col md="12" className="mb-2">
                          <div className="mini-stats-wid mini-stats-widnew">
                            <div>
                              <div className="countimgmain">
                                <img src={convertedlead}></img>
                              </div>
                            </div>
                            <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                              <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="d-flex align-items-center">
                                  <span className="mb-0 ndedit">{TotalConvertLead}</span>
                                  <p className="ndtitaleedit">

                                    Total Converted Lead
                                  </p>

                                </div>
                                <div>
                                  <i className="fas fa-chevron-right"></i>
                                </div>

                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </a>
                  </Col> : ''}

                  {DisplayTotalConvertIndividualLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                    <a href='ConvertedInq'>
                      <Row>
                        <Col md="12" className="mb-2">
                          <div className="mini-stats-wid mini-stats-widnew">
                            <div>
                              <div className="countimgmain">
                                <img src={convertedlead}></img>
                              </div>
                            </div>
                            <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                              <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="d-flex align-items-center">
                                  <span className="mb-0 ndedit">{TotalConvertIndividualLead}</span>
                                  <p className="ndtitaleedit">

                                    Total Converted Individual Lead
                                  </p>

                                </div>
                                <div>
                                  <i className="fas fa-chevron-right"></i>
                                </div>

                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </a>
                  </Col> : ''}

                  {DisplayTotalConvertFirmCompanyLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                    <a href='ConvertedInq'>
                      <Row>
                        <Col md="12" className="mb-2">
                          <div className="mini-stats-wid mini-stats-widnew">
                            <div>
                              <div className="countimgmain">
                                <img src={convertedlead}></img>
                              </div>
                            </div>
                            <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                              <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="d-flex align-items-center">
                                  <span className="mb-0 ndedit">{TotalConvertFirmCompanyLead}</span>
                                  <p className="ndtitaleedit">

                                    Total Converted Firm/Company Lead
                                  </p>

                                </div>
                                <div>
                                  <i className="fas fa-chevron-right"></i>
                                </div>

                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </a>
                  </Col> : ''}

                  {DisplayTotalRegisterLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                    <a href='RegisteredList'>
                      <Row>
                        <Col md="12" className="mb-2">
                          <div className="mini-stats-wid mini-stats-widnew">
                            <div>
                              <div className="countimgmain">
                                <img src={registeredlead}></img>
                              </div>
                            </div>
                            <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                              <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="d-flex align-items-center">
                                  <span className="mb-0 ndedit">{TotalRegisterLead}</span>
                                  <p className="ndtitaleedit">

                                    Total Registered Lead
                                  </p>

                                </div>
                                <div>
                                  <i className="fas fa-chevron-right"></i>
                                </div>

                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </a>
                  </Col> : ''}

                  {DisplayTotalRegisterIndividualLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                    <a href='RegisteredList'>
                      <Row>
                        <Col md="12" className="mb-2">
                          <div className="mini-stats-wid mini-stats-widnew">
                            <div>
                              <div className="countimgmain">
                                <img src={registeredlead}></img>
                              </div>
                            </div>
                            <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                              <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="d-flex align-items-center">
                                  <span className="mb-0 ndedit">{TotalRegisterIndividualLead}</span>
                                  <p className="ndtitaleedit">

                                    Total Registered Individual Lead
                                  </p>

                                </div>
                                <div>
                                  <i className="fas fa-chevron-right"></i>
                                </div>

                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </a>
                  </Col> : ''}

                  {DisplayTotalRegisterFirmCompanyLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                    <a href='RegisteredList'>
                      <Row>
                        <Col md="12" className="mb-2">
                          <div className="mini-stats-wid mini-stats-widnew">
                            <div>
                              <div className="countimgmain">
                                <img src={registeredlead}></img>
                              </div>
                            </div>
                            <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                              <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="d-flex align-items-center">
                                  <span className="mb-0 ndedit">{TotalRegisterFirmCompanyLead}</span>
                                  <p className="ndtitaleedit">

                                    Total Registered Firm/Company Lead
                                  </p>

                                </div>
                                <div>
                                  <i className="fas fa-chevron-right"></i>
                                </div>

                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </a>
                  </Col> : ''}

                  {DisplayTotalCloseLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                    <a href='ClosedList'>
                      <Row>
                        <Col md="12" className="mb-2">
                          <div className="mini-stats-wid mini-stats-widnew">
                            <div>
                              <div className="countimgmain">
                                <img src={closedlead}></img>
                              </div>
                            </div>
                            <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                              <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="d-flex align-items-center">
                                  <span className="mb-0 ndedit">{TotalCloseLead}</span>
                                  <p className="ndtitaleedit">

                                    Total Closed Lead
                                  </p>

                                </div>
                                <div>
                                  <i className="fas fa-chevron-right"></i>
                                </div>

                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </a>
                  </Col> : ''}

                  {DisplayTotalCloseIndividualLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                    <a href='ClosedList'>
                      <Row>
                        <Col md="12" className="mb-2">
                          <div className="mini-stats-wid mini-stats-widnew">
                            <div>
                              <div className="countimgmain">
                                <img src={closedlead}></img>
                              </div>
                            </div>
                            <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                              <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="d-flex align-items-center">
                                  <span className="mb-0 ndedit">{TotalCloseIndividualLead}</span>
                                  <p className="ndtitaleedit">

                                    Total Closed Individual Lead
                                  </p>

                                </div>
                                <div>
                                  <i className="fas fa-chevron-right"></i>
                                </div>

                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </a>
                  </Col> : ''}

                  {DisplayTotalCloseFirmCompanyLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                    <a href='ClosedList'>
                      <Row>
                        <Col md="12" className="mb-2">
                          <div className="mini-stats-wid mini-stats-widnew">
                            <div>
                              <div className="countimgmain">
                                <img src={closedlead}></img>
                              </div>
                            </div>
                            <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                              <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="d-flex align-items-center">
                                  <span className="mb-0 ndedit">{TotalCloseFirmCompanyLead}</span>
                                  <p className="ndtitaleedit">

                                    Total Closed Firm/Company Lead
                                  </p>

                                </div>
                                <div>
                                  <i className="fas fa-chevron-right"></i>
                                </div>

                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </a>
                  </Col> : ''}

                  {DisplayTotalClient > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                    <a href='ClientLead'>
                      <Row>
                        <Col md="12" className="mb-2">
                          <div className="mini-stats-wid mini-stats-widnew">
                            <div>
                              <div className="countimgmain">
                                <img src={clientlead}></img>
                              </div>
                            </div>
                            <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                              <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="d-flex align-items-center">
                                  <span className="mb-0 ndedit">{TotalClient}</span>
                                  <p className="ndtitaleedit">

                                    Total Client
                                  </p>

                                </div>
                                <div>
                                  <i className="fas fa-chevron-right"></i>
                                </div>

                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </a>
                  </Col> : ''}

                  {DisplayTotalIndividualClient > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                    <a href='ClientLead'>
                      <Row>
                        <Col md="12" className="mb-2">
                          <div className="mini-stats-wid mini-stats-widnew">
                            <div>
                              <div className="countimgmain">
                                <img src={clientlead}></img>
                              </div>
                            </div>
                            <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                              <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="d-flex align-items-center">
                                  <span className="mb-0 ndedit">{TotalIndividualClient}</span>
                                  <p className="ndtitaleedit">

                                    Total Individual Client
                                  </p>

                                </div>
                                <div>
                                  <i className="fas fa-chevron-right"></i>
                                </div>

                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </a>
                  </Col> : ''}

                  {DisplayTotalFirmCompanyClient > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                    <a href='ClientLead'>
                      <Row>
                        <Col md="12" className="mb-2">
                          <div className="mini-stats-wid mini-stats-widnew">
                            <div>
                              <div className="countimgmain">
                                <img src={clientlead}></img>
                              </div>
                            </div>
                            <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                              <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="d-flex align-items-center">
                                  <span className="mb-0 ndedit">{TotalFirmCompanyClient}</span>
                                  <p className="ndtitaleedit">
                                    Total Firm/Company Client
                                  </p>
                                </div>
                                <div>
                                  <i className="fas fa-chevron-right"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </a>
                  </Col> : ''}
                </div>

              </div>

            </div>

          </div>

          {BarChart > 0 ? <Col xl={DisplayLayoutTypeChart}>
            <Card className="heighfixdashbiardchart">
              <CardBody>
                <Row className="pt-4">
                  <div className="card-body">
                    <p className="text-muted fw-medium">
                      Bar Chart
                    </p>
                    <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={300} className="apex-charts" />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col> : ''}

          {PieChart > 0 ? <Col xl={DisplayLayoutTypeChart}>
            <Card className="heighfixdashbiardchart">
              <CardBody>
                <Row className="pt-4">
                  <div className="card-body">
                    <p className="text-muted fw-medium">
                      Pie Chart
                    </p>
                    <ReactApexChart options={pieChart.options} series={pieChart.series} type="pie" height={300} className="apex-charts" />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col> : ''}

          {LineChart > 0 ? <Col xl={DisplayLayoutTypeChart}>
            <Card className="heighfixdashbiardchart">
              <CardBody>
                <Row className="pt-4">
                  <div className="card-body">
                    <p className="text-muted fw-medium">
                      Line Chart
                    </p>
                    <ReactApexChart options={lineChart.options} series={lineChart.series} type="line" height={300} className="apex-charts" />
                  </div>
                </Row>
              </CardBody>
            </Card >
          </Col > : ''}

          {AreaChart > 0 ? <Col xl={DisplayLayoutTypeChart}>
            <Card className="heighfixdashbiardchart">
              <CardBody>
                <Row className="pt-4">
                  <div className="card-body">
                    <p className="text-muted fw-medium">
                      Area Chart
                    </p>
                    <ReactApexChart options={areaChart.options} series={areaChart.series} type="area" height={300} className="apex-charts" />
                  </div>
                </Row>
              </CardBody>
            </Card >
          </Col > : ''}

          {StackedAreaChart > 0 ? <Col xl={DisplayLayoutTypeChart}>
            <Card className="heighfixdashbiardchart">
              <CardBody>
                <Row className="pt-4">
                  <div className="card-body">
                    <p className="text-muted fw-medium">
                      Stacked Area Chart
                    </p>
                    <ReactApexChart options={stackedBarChart.options} series={stackedBarChart.series} type="bar" height={300} className="apex-charts" />
                  </div>
                </Row>
              </CardBody>
            </Card >
          </Col > : ''}

          {DisplayLeadList > 0 ? <Col xl={DisplayLayoutTypeList}>
            <Row>
              <Col md="12" className="mb-2">
                <Card className="mini-stats-wid heighfixdashbiard">
                  <CardBody>
                    <Row className="justify-content-between">
                      <Col className="col-lg-4 mb-0">
                        <CardTitle className="mt-2">Lead List</CardTitle>
                      </Col>

                      <Col className="col-lg-4 mb-0">
                        <CardTitle className="mt-2"><a href='/AllInq' className="viewlistbutton">View More</a></CardTitle>
                      </Col>

                    </Row>
                    <Col className="col-lg-12 mb-2">
                      <div >
                        <table className="table">
                          <thead className="table-light">
                            <tr>
                              <th scope="col" className='text-center'>#</th>
                              <th scope="col" className='text-center'>Date</th>
                              <th scope="col">Name</th>
                              <th scope="col" className='text-center'>Type</th>
                              <th scope="col">Contact & Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {LeadList.map((item, idx) => (
                              <tr key={idx}>
                                <td className='text-center'>{item.SR}</td>
                                <td className='text-center'>{item.CreateDate}</td>
                                <td>{item.FullName}<br />{item.LeadCode}</td>
                                <td className='text-center'>{item.LeadType}</td>
                                <td>{item.MobileNo}<br />{item.EmailAddress}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col> : ''}

          {DisplayEnrollLeadList > 0 ? <Col xl={DisplayLayoutTypeList}>
            <Row>
              <Col md="12" className="mb-2">
                <Card className="mini-stats-wid heighfixdashbiard">
                  <CardBody>
                    <Row className="justify-content-between">
                      <Col className="col-lg-4 mb-0">
                        <CardTitle className="mt-2">Enrolled List</CardTitle>
                      </Col>

                      <Col className="col-lg-4 mb-0">
                        <CardTitle className="mt-2"><a href='/LeadList' className="viewlistbutton">View More</a></CardTitle>
                      </Col>

                    </Row>
                    <Col className="col-lg-12 mb-2">
                      <div >
                        <table className="table">
                          <thead className="table-light">
                            <tr>
                              <th scope="col" className='text-center'>#</th>
                              <th scope="col" className='text-center'>Date</th>
                              <th scope="col">Name</th>
                              <th scope="col" className='text-center'>Type</th>
                              <th scope="col">Contact & Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {EnrolledList.map((item, idx) => (
                              <tr key={idx}>
                                <td className='text-center'>{item.SR}</td>
                                <td className='text-center'>{item.CreateDate}</td>
                                <td>{item.FullName}<br />{item.LeadCode}</td>
                                <td className='text-center'>{item.LeadType}</td>
                                <td>{item.MobileNo} <br />{item.EmailAddress}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col> : ''}

          {DisplayConvertLeadList > 0 ? <Col xl={DisplayLayoutTypeList}>
            <Row>
              <Col md="12" className="mb-2">
                <Card className="mini-stats-wid heighfixdashbiard">
                  <CardBody>
                    <Row className="justify-content-between">
                      <Col className="col-lg-4 mb-0">
                        <CardTitle className="mt-2">Converted List</CardTitle>
                      </Col>

                      <Col className="col-lg-4 mb-0">
                        <CardTitle className="mt-2"><a href='/ConvertedInq' className="viewlistbutton">View More</a></CardTitle>
                      </Col>

                    </Row>
                    <Col className="col-lg-12 mb-2">
                      <div >
                        <table className="table">
                          <thead className="table-light">
                            <tr>
                              <th scope="col" className='text-center'>#</th>
                              <th scope="col" className='text-center'>Date</th>
                              <th scope="col">Name</th>
                              <th scope="col" className='text-center'>Type</th>
                              <th scope="col">Contact & Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ConvertedList.map((item, idx) => (
                              <tr key={idx}>
                                <td className='text-center'>{item.SR}</td>
                                <td className='text-center'>{item.ConvertDate}</td>
                                <td>{item.FullName}<br />{item.LeadCode}</td>
                                <td className='text-center'>{item.LeadType}</td>
                                <td>{item.MobileNo}<br />{item.EmailAddress}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col> : ''}

          {DisplayRegisterLeadList > 0 ? <Col xl={DisplayLayoutTypeList}>
            <Row>
              <Col md="12" className="mb-2">
                <Card className="mini-stats-wid heighfixdashbiard">
                  <CardBody>
                    <Row className="justify-content-between">
                      <Col className="col-lg-4 mb-0">
                        <CardTitle className="mt-2">Registered List</CardTitle>
                      </Col>

                      <Col className="col-lg-4 mb-0">
                        <CardTitle className="mt-2"><a href='/RegisteredList' className="viewlistbutton">View More</a></CardTitle>
                      </Col>

                    </Row>
                    <Col className="col-lg-12 mb-2">
                      <div >
                        <table className="table">
                          <thead className="table-light">
                            <tr>
                              <th scope="col" className='text-center'>#</th>
                              <th scope="col" className='text-center'>Date</th>
                              <th scope="col">Name</th>
                              <th scope="col" className='text-center'>Type</th>
                              <th scope="col">Contact & Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {RegisteredList.map((item, idx) => (
                              <tr key={idx}>
                                <td className='text-center'>{item.SR}</td>
                                <td className='text-center'>{item.RegisterDate}</td>
                                <td>{item.FullName}<br />{item.LeadCode}</td>
                                <td className='text-center'>{item.LeadType}</td>
                                <td>{item.MobileNo}<br />{item.EmailAddress}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col> : ''}

          {DisplayCloseLeadList > 0 ? <Col xl={DisplayLayoutTypeList}>
            <Row>
              <Col md="12" className="mb-2">
                <Card className="mini-stats-wid heighfixdashbiard">
                  <CardBody>
                    <Row className="justify-content-between">
                      <Col className="col-lg-4 mb-0">
                        <CardTitle className="mt-2">Closed List</CardTitle>
                      </Col>

                      <Col className="col-lg-4 mb-0">
                        <CardTitle className="mt-2"><a href='/ClosedList' className="viewlistbutton">View More</a></CardTitle>
                      </Col>

                    </Row>
                    <Col className="col-lg-12 mb-2">
                      <div >
                        <table className="table">
                          <thead className="table-light">
                            <tr>
                              <th scope="col" className='text-center'>#</th>
                              <th scope="col" className='text-center'>Date</th>
                              <th scope="col">Name</th>
                              <th scope="col" className='text-center'>Type</th>
                              <th scope="col">Contact & Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ClosedList.map((item, idx) => (
                              <tr key={idx}>
                                <td className='text-center'>{item.SR}</td>
                                <td className='text-center'>{item.CloseDate}</td>
                                <td>{item.FullName}<br />{item.LeadCode}</td>
                                <td className='text-center'>{item.LeadType}</td>
                                <td>{item.MobileNo}<br />{item.EmailAddress}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col> : ''}

          {DisplayClientList > 0 ? <Col xl={DisplayLayoutTypeList}>
            <Row>
              <Col md="12" className="mb-2">
                <Card className="mini-stats-wid heighfixdashbiard">
                  <CardBody>
                    <Row className="justify-content-between">
                      <Col className="col-lg-4 mb-0">
                        <CardTitle className="mt-2">Client List</CardTitle>
                      </Col>

                      <Col className="col-lg-4 mb-0">
                        <CardTitle className="mt-2"><a href='/ClientLead' className="viewlistbutton">View More</a></CardTitle>
                      </Col>

                    </Row>
                    <Col className="col-lg-12 mb-2">
                      <div >
                        <table className="table">
                          <thead className="table-light">
                            <tr>
                              <th scope="col" className='text-center'>#</th>
                              <th scope="col" className='text-center'>Date</th>
                              <th scope="col">Name</th>
                              <th scope="col" className='text-center'>Client Type</th>
                              <th scope="col">Contact & Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ClientList.map((item, idx) => (
                              <tr key={idx}>
                                <td className='text-center'>{idx + 1}</td>
                                <td className='text-center'>{item.CreateDate}</td>
                                <td>{item.FullName}<br />{item.CustomerCode}</td>
                                <td className='text-center'>{item.CustomerType}</td>
                                <td>{item.MobileNo}<br />{item.EmailAddress}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col> : ''}
        </Row>
      </div>

    </div >
  )
}

export default AddDashboardSetting;