import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import axios from "axios";
import {
  Button,

  Col,
  Form,

  Input,
  Label,
  Row,
} from "reactstrap";

const FormRepeater = () => {

  const [MobileNo, setMobileNo] = useState("")

  const changeMobileNo = (event) => {
    setMobileNo(event.target.value)
  }

  const transferValue = (event) => {
    event.preventDefault();

    var data = {

      "LeadId": "1",
      "MobileNo": MobileNo,
      "IsActiveWhatsApp": "Yes",
      "CreateUser": "1"
    }

    axios.post(global.APIURL + "/WebLeadContactInformationAdd", data)
      .then((res) => { })
      .catch((err) => console.log(err));
    clearState();
  };

  const clearState = () => {
    setMobileNo('')

  };


  return (
    <React.Fragment>
      <div className="page-content mt-0 pt-0 pb-0">
        <MetaTags>
          <title>Add New Inquiry | KC Admin Group</title>
        </MetaTags>


        <div>


          <table style={{ width: "100%" }}>
            <tbody>
              <tr id="addr01" key="">
                <td>
                  <Form
                    className="repeater"
                    encType="multipart/form-data"
                  >
                    <div data-repeater-list="group-a">
                      <Row data-repeater-item>
                        <Col lg="10" className="mb-3">
                          <Label htmlFor="mobileno">Mobile No.</Label>
                          <Input
                            type="text"
                            id="mobileno"
                            name="MobileNo"
                            value={MobileNo}
                            onChange={changeMobileNo}
                            className="form-control"
                            placeholder="Enter Number"
                          />
                        </Col>


                        <Col
                          lg="2"
                          className="align-self-center"
                        >
                          <div className="d-grid mt-2">
                            <Button

                              color="danger"
                              style={{ width: "56%" }}
                              className="btn-sm"
                            >

                              <i className="fas fa-trash-alt"></i>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </td>
              </tr>


            </tbody>
          </table>
          <Button onClick={transferValue} color="primary" className="mt-3 mt-lg-0 btn-sm"><i className="fas fa-plus me-2"></i>
            Add
          </Button>

        </div>
      </div>
    </React.Fragment>
  );




}

export default FormRepeater;
