import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import jsPDF from "jspdf";
import 'jspdf-autotable';

const OnSort = () => {
    const [PaymentModeType, setPaymentModeType] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let columns = [
        {
            d: "#",
            text: "#",
            cell: (record, index) => {
                let RowIndex = index + 1;
                return (
                    <Fragment>
                        {RowIndex}
                    </Fragment>
                );
            }
        },
        {
            key: "NetworkType",
            text: "Network Type",
            sortable: true
        },
        {
            key: "NetworkSource",
            text: "Network Source",
            sortable: true
        },
        {
            key: "EmailId",
            text: "Email",
            sortable: true
        },
        // {
        //     key: "Address",
        //     text: "Address",
        //     sortable: true
        // },
        {
            key: "ServicesPackageType",
            text: "Pay Type",
            sortable: true
        },
        {
            key: "DueDate",
            text: "Due Date",
            sortable: true
        },
        {
            key: "Amount",
            text: "Amount",
            sortable: true
        },
        {
            key: "GstAmount",
            text: "Gst Amount",
            sortable: true
        },
        {
            key: "TotalAmount",
            text: "Total Amount",
            sortable: true
        },
        {
            d: "action",
            text: "Action",
            cell: (record, index) => {

                let RowId = record["Id"] == null ? '0' : record["Id"] == '' ? '0' : record["Id"];
                return (
                    <Fragment>
                        <button type="button" style={{ marginRight: "16px" }} onClick={(CorporatePaymentDueDetail.bind(this, record))} className="btn btn-sm btn-primary">Pay</button>
                    </Fragment>
                );
            }
        }
    ];
    let config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        show_filter: true,
        show_pagination: true,
        filename: "LeadList",
        no_data_text: 'No data available!',
        button: {
            excel: true,
            print: true,
            csv: true
        }
    }
    const [records, setRecords] = useState([])
    const [NetworkTypeList, setNetworkTypeList] = useState([]);
    const [DropdownChannelList, setDropdownChannelList] = useState([]);
    const [ServicesPackageType, setServicesPackageType] = useState('');
    const [PaymentDueId, setPaymentDueId] = useState(0);
    const [NetworkType, setNetworkType] = useState('');
    const [NetworkSource, setNetworkSource] = useState('');
    const [ServicesPackage, setServicesPackage] = useState('');
    const [PlanAmount, setPlanAmount] = useState(0);
    const [GstPer, setGstPer] = useState(0);
    const [PlanGstAmount, setPlanGstAmount] = useState(0);
    const [Amount, setAmount] = useState(0);
    const [GstAmount, setGstAmount] = useState(0);
    const [TotalAmount, setTotalAmount] = useState(0);
    const [CompanyList, setCompanyList] = useState([]);
    const [PaymentModeList, setPaymentModeList] = useState([]);
    const [CompanyId, setCompanyId] = useState(0);
    const [PaymentModeId, setPaymentModeId] = useState(0);
    const [PaymentModeTitle, setPaymentModeTitle] = useState('');

    const [BankName, setBankName] = useState('');
    const [BankBranch, setBankBranch] = useState('');
    const [ChequeDDNo, setChequeDDNo] = useState('');
    const [ChequeDDDate, setChequeDDDate] = useState('');
    const [TransactionDate, setTransactionDate] = useState('');



    let history = useHistory();

    useEffect(() => {
        const TableFilter = document.getElementsByClassName('table_filter');
        for (const tf of TableFilter) {
            tf.style.display = 'none';
        }
        getNetWorkList();
        getWebLeadList();
        getCompanyList();
        getPaymentModeList();
    }, []);

    const getCompanyList = async () => {
        var AUrl = global.APIURL + '/CompanyActive';
        axios.get(AUrl).then(SPres => {
            let CompanyL = SPres.data.map((Company) => {
                return Company
            })
            setCompanyList(CompanyL);
        })
    }

    const getPaymentModeList = async () => {
        var AUrl = global.APIURL + '/PaymentModeActive';
        axios.get(AUrl).then(Pres => {
            let PaymentModeL = Pres.data.map((PaymentMode) => {
                return PaymentMode
            })
            setPaymentModeList(PaymentModeL);
        })
    }

    const CorporatePaymentDueDetail = (record) => {
        let id = record["Id"]
        const CorporatePaymentDueDetailFilter = {
            Id: id
        };
        axios.post(global.APIURL + "/CorporatePaymentDueDetail", CorporatePaymentDueDetailFilter)
            .then(PaymentDueres => {
                $('#exampleModalPay').modal('show');
                var VId = parseInt(PaymentDueres.data[0].Id == null ? '0' : PaymentDueres.data[0].Id == '' ? "0" : PaymentDueres.data[0].Id);
                var VNetworkType = PaymentDueres.data[0].ChannelTypeName == null ? '' : PaymentDueres.data[0].ChannelTypeName;
                var VNetworkSource = PaymentDueres.data[0].ChannelMaster == null ? '' : PaymentDueres.data[0].ChannelMaster;
                var VServiceTitle = PaymentDueres.data[0].ServiceTitle == null ? '' : PaymentDueres.data[0].ServiceTitle;
                var VServicesPackageType = PaymentDueres.data[0].ServicesPackageType == null ? '' : PaymentDueres.data[0].ServicesPackageType;
                var VPlanAmount = parseFloat(PaymentDueres.data[0].PlanAmount == null ? '0' : PaymentDueres.data[0].PlanAmount == '' ? "0" : PaymentDueres.data[0].PlanAmount);
                var VGstPer = parseFloat(PaymentDueres.data[0].GstPer == null ? '0' : PaymentDueres.data[0].GstPer == '' ? "0" : PaymentDueres.data[0].GstPer);
                var VPlanGstAmount = parseFloat(PaymentDueres.data[0].PlanGstAmount == null ? '0' : PaymentDueres.data[0].PlanGstAmount == '' ? "0" : PaymentDueres.data[0].PlanGstAmount);
                var VAmount = parseFloat(PaymentDueres.data[0].Amount == null ? '0' : PaymentDueres.data[0].Amount == '' ? "0" : PaymentDueres.data[0].Amount);
                var VGstAmount = parseFloat(PaymentDueres.data[0].GstAmount == null ? '0' : PaymentDueres.data[0].GstAmount == '' ? "0" : PaymentDueres.data[0].GstAmount);
                var VTotalAmount = parseFloat(PaymentDueres.data[0].TotalAmount == null ? '0' : PaymentDueres.data[0].TotalAmount == '' ? "0" : PaymentDueres.data[0].TotalAmount);
                setPaymentDueId(VId);
                setNetworkType(VNetworkType);
                setNetworkSource(VNetworkSource);
                setServicesPackage(VServiceTitle);
                setServicesPackageType(VServicesPackageType);
                setPlanAmount(VPlanAmount);
                setGstPer(VGstPer);
                setPlanGstAmount(VPlanGstAmount);
                setAmount(VAmount);
                setGstAmount(VGstAmount);
                setTotalAmount(VTotalAmount);
            });
    }

    const getNetWorkList = async () => {
        var ACUrl = global.APIURL + '/ChannalTypeList/';
        axios.get(ACUrl)
            .then(Cires => {
                let CityL = Cires.data.map(kccity => {
                    return kccity;
                });
                setNetworkTypeList(CityL);
            });
    };

    const getDropdownChannelMasterList = async () => {
        var VNetworkTypeId = document.getElementById("ddlNetworkType").value;
        if (parseInt(VNetworkTypeId) > 0) {
            const ChannelDropdownFilter = {
                ChannelTypeId: VNetworkTypeId,
                ChannelMasterId: 0,
                CityId: 0,
                SpMode: 'ChannelMasterSelectAll',
            };
            axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
                .then(CLres => {
                    let VChannelMasterList = CLres.data.map(vcl => {
                        return vcl;
                    });
                    setDropdownChannelList(VChannelMasterList);
                });
        }
    };

    const saveLead = async (e) => {
        getWebLeadList();
    }

    const getWebLeadList = async () => {
        var QSearch = document.getElementById("txtQuickSearch").value;
        var NetworkTypeId = document.getElementById("ddlNetworkType").value;
        var NetworkSourceId = document.getElementById("ddlCompany").value;
        var StartDate = document.getElementById("txtStartDate").value;
        var EndDate = document.getElementById("txtEndDate").value;
        var ServicesPackageType = document.getElementById("ddlPaymentType").value;


        const ServicesDueItem = {
            ServicesPackageType: ServicesPackageType,
            QuickSearch: QSearch,
            FromDate: StartDate,
            ToDate: EndDate,
            NetworkTypeId: NetworkTypeId,
            NetworkSourceId: NetworkSourceId
        }
        axios.post(global.APIURL + "/CorporatePaymentDueList", ServicesDueItem)
            .then(CLres => {
                let VWebLeadList = CLres.data.map(vcl => {
                    return vcl;
                });
                setRecords(VWebLeadList);

            });
    };

    const onSort = (column, records, sortOrder) => {
        return orderBy(records, [column], [sortOrder]);
    }

    const PaymentModeOnChange = e => {
        var VPaymentMode = e.nativeEvent.target.selectedIndex;
        var PaymentModeId = e.nativeEvent.target[VPaymentMode].value;
        var PaymentModeText = e.nativeEvent.target[VPaymentMode].text;
        setPaymentModeId(PaymentModeId);
        setPaymentModeTitle(PaymentModeText);
        if (PaymentModeText == 'Cash') {
            setPaymentModeType(0);
        }
        else if (PaymentModeText == 'Cheque') {
            setPaymentModeType(1);
        }
        else if (PaymentModeText == 'Credit Card / Debit Card') {
            setPaymentModeType(1);
        }
        else if (PaymentModeText == 'NEFT') {
            setPaymentModeType(1);
        }
        else if (PaymentModeText == 'Paytm') {
            setPaymentModeType(1);
        }
        else if (PaymentModeText == 'E-Wallets except Paytm') {
            setPaymentModeType(1);
        }
        else if (PaymentModeText == 'Scholarship') {
            setPaymentModeType(1);
        }
        else if (PaymentModeText == 'Free') {
            setPaymentModeType(0);
        }
        else if (PaymentModeText == 'CASH & CARD') {
            setPaymentModeType(1);
        }
        else if (PaymentModeText == 'TT Remittance') {
            setPaymentModeType(1);
        }
        else if (PaymentModeText == 'Cash & Cheque') {
            setPaymentModeType(1);
        }
        else {
            setPaymentModeType(0);
        }
    };

    const ServicePackageDuePay = async () => {
        let IsValid = 1;
        if (CompanyId == 0) {
            IsValid = 0;
            alert("Please select company.");
        }
        else if (PaymentModeId == 0) {
            IsValid = 0;
            alert("Please select payment mode.");
        }
        else if (PaymentModeTitle == 'Cheque') {
            if (BankName == '') {
                IsValid = 0;
                alert("Please input back name.");
            }
            else if (BankBranch == '') {
                IsValid = 0;
                alert("Please input back branch name.");
            }
            else if (ChequeDDNo == '') {
                IsValid = 0;
                alert("Please input Cheque/DD number.");
            }
            else if (ChequeDDDate == '') {
                IsValid = 0;
                alert("Please input Cheque/DD date.");
            }
        }
        else if (PaymentModeTitle == 'Credit Card / Debit Card') {
            if (BankName == '') {
                IsValid = 0;
                alert("Please input back name.");
            }
            else if (BankBranch == '') {
                IsValid = 0;
                alert("Please input back branch name.");
            }
            else if (ChequeDDNo == '') {
                IsValid = 0;
                alert("Please input Cheque/DD number.");
            }
            else if (ChequeDDDate == '') {
                IsValid = 0;
                alert("Please input Cheque/DD date.");
            }
        }
        else if (PaymentModeTitle == 'NEFT') {
            if (BankName == '') {
                IsValid = 0;
                alert("Please input back name.");
            }
            else if (BankBranch == '') {
                IsValid = 0;
                alert("Please input back branch name.");
            }
            else if (ChequeDDNo == '') {
                IsValid = 0;
                alert("Please input Cheque/DD number.");
            }
            else if (ChequeDDDate == '') {
                IsValid = 0;
                alert("Please input Cheque/DD date.");
            }
        }
        else if (PaymentModeTitle == 'Paytm') {
            if (BankName == '') {
                IsValid = 0;
                alert("Please input back name.");
            }
            else if (BankBranch == '') {
                IsValid = 0;
                alert("Please input back branch name.");
            }
            else if (ChequeDDNo == '') {
                IsValid = 0;
                alert("Please input Cheque/DD number.");
            }
            else if (ChequeDDDate == '') {
                IsValid = 0;
                alert("Please input Cheque/DD date.");
            }
        }
        else if (PaymentModeTitle == 'E-Wallets except Paytm') {
            if (BankName == '') {
                IsValid = 0;
                alert("Please input back name.");
            }
            else if (BankBranch == '') {
                IsValid = 0;
                alert("Please input back branch name.");
            }
            else if (ChequeDDNo == '') {
                IsValid = 0;
                alert("Please input Cheque/DD number.");
            }
            else if (ChequeDDDate == '') {
                IsValid = 0;
                alert("Please input Cheque/DD date.");
            }
        }
        else if (PaymentModeTitle == 'Scholarship') {
            if (BankName == '') {
                IsValid = 0;
                alert("Please input back name.");
            }
            else if (BankBranch == '') {
                IsValid = 0;
                alert("Please input back branch name.");
            }
            else if (ChequeDDNo == '') {
                IsValid = 0;
                alert("Please input Cheque/DD number.");
            }
            else if (ChequeDDDate == '') {
                IsValid = 0;
                alert("Please input Cheque/DD date.");
            }
        }
        else if (PaymentModeTitle == 'Free') {
            if (BankName == '') {
                IsValid = 0;
                alert("Please input back name.");
            }
            else if (BankBranch == '') {
                IsValid = 0;
                alert("Please input back branch name.");
            }
            else if (ChequeDDNo == '') {
                IsValid = 0;
                alert("Please input Cheque/DD number.");
            }
            else if (ChequeDDDate == '') {
                IsValid = 0;
                alert("Please input Cheque/DD date.");
            }
        }
        else if (PaymentModeTitle == 'CASH & CARD') {
            if (BankName == '') {
                IsValid = 0;
                alert("Please input back name.");
            }
            else if (BankBranch == '') {
                IsValid = 0;
                alert("Please input back branch name.");
            }
            else if (ChequeDDNo == '') {
                IsValid = 0;
                alert("Please input Cheque/DD number.");
            }
            else if (ChequeDDDate == '') {
                IsValid = 0;
                alert("Please input Cheque/DD date.");
            }
        }
        else if (PaymentModeTitle == 'TT Remittance') {
            if (BankName == '') {
                IsValid = 0;
                alert("Please input back name.");
            }
            else if (BankBranch == '') {
                IsValid = 0;
                alert("Please input back branch name.");
            }
            else if (ChequeDDNo == '') {
                IsValid = 0;
                alert("Please input Cheque/DD number.");
            }
            else if (ChequeDDDate == '') {
                IsValid = 0;
                alert("Please input Cheque/DD date.");
            }
        }
        else if (PaymentModeTitle == 'Cash & Cheque') {
            if (BankName == '') {
                IsValid = 0;
                alert("Please input back name.");
            }
            else if (BankBranch == '') {
                IsValid = 0;
                alert("Please input back branch name.");
            }
            else if (ChequeDDNo == '') {
                IsValid = 0;
                alert("Please input Cheque/DD number.");
            }
            else if (ChequeDDDate == '') {
                IsValid = 0;
                alert("Please input Cheque/DD date.");
            }
        }
        else if (TransactionDate == '') {
            IsValid = 0;
            alert("Please select transaction date.");
        }
        if (IsValid == 1) {
            const DuePayArr = {
                Id: PaymentDueId,
                Amount: Amount,
                GstAmount: GstAmount,
                TotalAmount: TotalAmount,
                CompanyId: CompanyId,
                PaymentModeId: PaymentModeId,
                PaymentMode: PaymentModeTitle,
                BankName: BankName,
                Branch: BankBranch,
                ChequeDDNo: ChequeDDNo,
                ChequeDDDate: ChequeDDDate,
                TransactionDate: TransactionDate,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
            }
            axios.post(global.APIURL + '/CorporatePaymentDuePay', DuePayArr)
                .then(resdue => {
                    alert(resdue.data[0].RecordStatus);
                    $('#exampleModalPay').modal('hide');
                    getWebLeadList();
                });
        }
    };

    const exportPDF=()=>{

        const unit = "pt";
        const size = "A4"; 
        const orientation = "portrait"; 
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);

        // var today = new Date();
        // var curr_date = today.getDate();
        // var curr_month = today.getMonth();
        // var curr_year = today.getFullYear();
        // var m_names = new Array("January", "February", "March", 
        //                         "April", "May", "June", "July",
        //                         "August", "September", 
        //                         "October", "November", "December");
    
        // today = m_names[curr_month] + " " + curr_date + ", " + curr_year;
        // var newdat = today;
        // doc.text(400, 60, newdat);
    
        const title ="Corporate Payment Due List Report";
        const headers = [["#","Network Type","Network Source","Email","Pay Type","Due Date","Amount","GST Amount","Total Amount"]];
    
        const data = records.map((item,index)=> [index+1,item.NetworkType,item.NetworkSource,item.EmailId,item.ServicesPackageType,item.DueDate,item.Amount,item.GstAmount,item.TotalAmount]);
    
        let content = {
          startY: 50,
          head: headers,
          body: data
        };
    
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("CorporatePaymentDueList.pdf")
      }

    return (
        <div className="page-content">
            <MetaTags>
                <title>Corporate Payment Due List | KC Admin Group</title>
            </MetaTags>
            <div className="modal fade" id="exampleModalPay" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Payment Details </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form >
                            <div className="modal-body">
                                <Row>
                                    <Col className="col-12">
                                        <div>
                                            <div>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="mb-2">
                                                            <label className="form-label">Network Type</label>
                                                            <input type="text" className="form-control" id="txtSerPack" value={NetworkType} readOnly />
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-2">
                                                            <label className="form-label">Network Source</label>
                                                            <input type="text" className="form-control" id="txtSerPackType" value={NetworkSource} readOnly />
                                                        </div>
                                                    </Col>
                                                    <Col lg={8}>
                                                        <div className="mb-2">
                                                            <label className="form-label">Service Package</label>
                                                            <input type="text" className="form-control" id="txtSerPack" value={ServicesPackage} readOnly />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-2">
                                                            <label className="form-label">Service Package Type</label>
                                                            <input type="text" className="form-control" id="txtSerPackType" value={ServicesPackageType} readOnly />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-2">
                                                            <label className="form-label">Package Amount</label>
                                                            <input type="text" className="form-control" id="txtPackageAmount" value={PlanAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })} readOnly />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-2">
                                                            <label className="form-label">Gst Percentage</label>
                                                            <input type="text" className="form-control" id="txtPackageAmount" value={GstPer.toLocaleString(undefined, { maximumFractionDigits: 2 })} readOnly />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-2">
                                                            <label className="form-label">Package GST Amount</label>
                                                            <input type="text" className="form-control" id="txtPackageGSTAmount" value={PlanGstAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })} readOnly />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-2">
                                                            <label className="form-label">Pay Amount </label>
                                                            <input type="text" className="form-control" id="txtTotalAccruedAmount" value={Amount.toLocaleString(undefined, { maximumFractionDigits: 2 })} readOnly />
                                                        </div>
                                                    </Col>

                                                    <Col lg={4}>
                                                        <div className="mb-2">
                                                            <label className="form-label">GST Amount</label>
                                                            <input type="text" className="form-control" id="txtAccruedDue" value={GstAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })} readOnly />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-2">
                                                            <label className="form-label">Total Pay Amount </label>
                                                            <input type="text" className="form-control" id="txtPaidAmount" value={TotalAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })} readOnly />
                                                        </div>
                                                    </Col>

                                                    <Col lg={4}>
                                                        <p className="mb-2 fontWeight500"> Company </p>
                                                        <select id="ddlPayCompany" className="form-select" aria-label="Default select example" onChange={e => setCompanyId(e.target.value)}>
                                                            <option key="0" value="0">Select Company</option>
                                                            {CompanyList.map((item) => (
                                                                <option key={item.CompanyId} value={item.CompanyId}>{item.CompanyName}</option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <p className="mb-2 fontWeight500"> Payment Mode </p>
                                                        <select id="ddlPaymentMode" className="form-select" aria-label="Default select example" onChange={PaymentModeOnChange}>
                                                            <option key="0" value="0">Select Payment Mode</option>
                                                            {PaymentModeList.map((item) => (
                                                                <option key={item.PaymentModeId} value={item.PaymentModeId}>{item.PaymenMode}</option>
                                                            ))}
                                                        </select>
                                                    </Col>

                                                    {PaymentModeType > 0 ?
                                                        <Col lg={4}>
                                                            <p className="mb-2 fontWeight500"> Bank Name </p>
                                                            <input type="text" className="form-control" id="txtBankName" placeholder="Enter Bank Name" onChange={e => setBankName(e.target.value)} />
                                                        </Col>
                                                        : ''}
                                                    {PaymentModeType > 0 ? <Col lg={4} className="mt-2">
                                                        <p className="mb-2 fontWeight500"> Branch</p>
                                                        <input type="text" className="form-control" id="txtBranch" placeholder="Enter Branch" onChange={e => setBankBranch(e.target.value)} />
                                                    </Col> : ''}
                                                    {PaymentModeType > 0 ? <Col lg={4} className="mt-2">
                                                        <p className="mb-2 fontWeight500"> Cheque/DD No</p>
                                                        <input type="text" className="form-control" id="txtChequeDDNo" placeholder="Enter Cheque/DD No" onChange={e => setChequeDDNo(e.target.value)} />
                                                    </Col> : ''}
                                                    {PaymentModeType > 0 ? <Col lg={4} className="mt-2">
                                                        <p className="mb-2 fontWeight500">Cheque/DD Date</p>
                                                        <input type="date" className="form-control" id="txtChequeDDDate" placeholder="Enter Cheque/DD Date" onChange={e => setChequeDDDate(e.target.value)} />
                                                    </Col> : ''}
                                                    <Col lg={4} className={PaymentModeType > 0 ? 'mt-2' : ''}>
                                                        <p className="mb-2 fontWeight500" >Transaction Date</p>
                                                        <input type="date" className="form-control" id="txtTransactionDate" placeholder="" onChange={e => setTransactionDate(e.target.value)} />
                                                    </Col>

                                                </Row>

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <div className="text-end w-100">
                                    <button type="button" className="btn btn_success " id="btnsave" onClick={() => ServicePackageDuePay()}>Pay</button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="exampleModalViewPay" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">View Payment</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form>
                            <div className="modal-body">


                                <div className="table-responsive text-center" id="style-4">
                                    <table className="table table-sm table-bordered  ">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>ReceiptNo.</th>
                                                <th>Services</th>
                                                <th>Recived Amount</th>
                                                <th>GST Amount</th>
                                                <th>Total Amount</th>

                                                <th>Payment Mode</th>
                                                <th>Transaction Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {ServicePackagePayment.map(item => (
                                                <tr key={item.RowId}>
                                                    <td>{item.RowId}</td>
                                                    <td>{item.ReceiptCode}</td>
                                                    <td>{item.ServicePackage}</td>
                                                    <td>{item.RecivedAmount}</td>
                                                    <td>{item.RecivedGSTAmount}</td>
                                                    <td>{item.TotalAmount}</td>

                                                    <td>{item.PaymentMode}</td>
                                                    <td>{item.TransactionDate}</td>
                                                </tr>
                                            ))} */}
                                        </tbody>
                                    </table>
                                </div>



                            </div>
                            <div className="modal-footer justify-content-center">
                                <div className="text-end w-100">
                                    <button className="btn btn_success" id="btnsave" >Add</button>
                                </div>
                            </div>
                        </form>



                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <Row className="mb-3">
                    <Col className="col-lg-8 col-md-6 col-12 mb-2">
                        <div className="breadcrum ps-0">
                            <CardTitle className="h4">Corporate Payment Due List</CardTitle>
                            <a href="/" className="me-2">
                                Home
                            </a>{" "}
                            <span>/</span>
                            <span className="ms-2">Corporate Payment Due</span>
                        </div>
                    </Col>
                    <Col className="col-lg-4 co-md-6 col-12 text-end">
                        <a className="btn btn-primary btn-sm font-14 me-2 " data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                            <i className="fas fa-filter"></i>
                        </a>

                        {/* <a className="btn btn-primary btn-sm float-end font-14 me-2" href="/AddNewInquiry">
                            <i className="mdi mdi-plus align-middle me-1"></i>Add New
                        </a>*/}
                        <ReactHTMLTableToExcel
                            className="btn btn-primary btn-sm float-end font-14 me-2"
                            table="emp"
                            filename="CorporatePaymentDueReport"
                            sheet="Sheet"
                            buttonText="Export Excel" 
                            style={ { whiteSpace:'unset'}} 
                            />
                        <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button> 

                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">

                        <Row>
                            <Col xl="12">
                                <div className="collapse" id="collapseExample">
                                    <div className="card card-body">
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label className="form-label"> Quick search</label>
                                                <form className="app-search app-searchnew p-0 d-none d-lg-block">
                                                    <div className="position-relative"><input type="text" className="form-control" placeholder="Search..." id="txtQuickSearch" /><span className="bx bx-search-alt"></span>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="" className="form-label">Network Type </label>
                                                <select className="form-select" Id="ddlNetworkType" name="ddlNetworkType" onChange={e => getDropdownChannelMasterList()}>
                                                    <option value={"0"}>Select Network Type</option>
                                                    {NetworkTypeList.map(state => (
                                                        <option key={state.ChannelTypeId} value={state.ChannelTypeId}>
                                                            {" "}
                                                            {state.ChannelTypeName}{" "}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="" className="form-label">Network Source </label>
                                                <select className="form-select" Id="ddlCompany" name="ddlCompany">
                                                    <option value={"0"}>Select Source</option>
                                                    {DropdownChannelList.map(item => (
                                                        <option key={item.ChannelMasterId} value={item.ChannelMasterId}>
                                                            {item.Title}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <label htmlFor="" className="form-label">Type </label>
                                                <select className="form-select" aria-label="Default select example" id="ddlPaymentType">
                                                    <option value=''>All</option>
                                                    <option value="Monthly">Monthly</option>
                                                    <option value="Quaterly">Quaterly</option>
                                                    <option value="Half Yearly">Half Yearly</option>
                                                    <option value="Yearly">Yearly</option>
                                                </select>
                                            </div>
                                            <div className="col-md-3 mt-3">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Start Date </label>
                                                    <input type="date" className="form-control" id="txtStartDate" />
                                                </div>
                                            </div>
                                            <div className="col-md-3 mt-3">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">End Date </label>
                                                    <input type="date" className="form-control" id="txtEndDate" />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-3 mb-2">
                                                <button type="button" className="btn btn-sm btn-success font-14" onClick={saveLead}>Go</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <form >
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className="col-lg-12">
                                            <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </form>

                <table id="emp" className="table"  hidden="hidden">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Network Type</th>
                  <th>Network Source</th>                
                  <th>Email</th>
                  <th>Pay Type</th>
                  <th>Due Date</th>
                  <th>Amount</th>
                  <th>Gst Amount</th>
                  <th>Total Amount</th>
                
                                </tr>
              </thead>
              <tbody>  
                        {
                records.map((item, index) => {
                  return <tr key={index}>
                    <td> {index + 1} </td>
                    <td>{item.NetworkType}</td>
                    <td>{item.NetworkSource}</td>                  
                    <td>{item.EmailId}</td>
                    <td>{item.ServicesPackageType}</td>
                    <td>{item.DueDate}</td>
                    <td>{item.Amount}</td>
                    <td>{item.GstAmount}</td>
                    <td>{item.TotalAmount}</td>
                                      
                                </tr>
                })
              }
              </tbody>
            </table>
            </div>
        </div>
    );
}

export default OnSort;