import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import img2 from "assets/images/PAN.png";
import axios from "axios";
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody, Input, Label, Button } from "reactstrap";
import PlacholderImgC from "assets/images/favicon.png";
import { ResetTvRounded } from "@mui/icons-material";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { getSliderUtilityClass } from "@mui/base";

const EditRegisterClient = () => {
  const history = useHistory();

  const [CorporateLeadId, setCorporateLeadId] = useState(0);
  const [StateList, setStateList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);
  const [CompanyDistrictId, setCompanyDistrictId] = useState("");
  const [CompanyTehsilId, setCompanyTehsilId] = useState("");
  const [LeadPartnerList, setLeadPartnerList] = useState([]);
  const [AddTehsil, setAddTehsil] = useState('D');
  const [AddCity, setAddCity] = useState('D');
  const [CompanyCityId, setCompanyCityId] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Branchcode, setBranchcode] = useState("");
  const [ComapanyList, setCompanyList] = useState([]);
  const [CompanyId, setCompanyId] = useState("");
  const [NetworkTypeList, setNetworkTypeList] = useState([]);
  const [NetworkType, setNetworkType] = useState([]);
  const [NetworkTypeId, setNetworkTypeId] = useState("");
  const [DistrictName, setDistrictName] = useState([]);
  const [TehsilName, setTehsilName] = useState([]);
  const [CityName, setCityName] = useState([]);
  const [LeadContactList, setLeadContactList] = useState([]);
  const [Address, setAddress] = useState("");
  const [LeadFollowupList, setLeadFollowupList] = useState([]);
  const [ChannelTypeId, setChannelTypeId] = useState("");
  const [StateId, setStateId] = useState("");
  const [StateName, setStateName] = useState([]);
  const [EditcorLeadId, setcorEditLeadId] = useState("");

  const [MEmailId, setmEmailId] = useState("");
  const [ConvertDate, setConvertDate] = useState('');
  const [ConvertRemark, setConvertRemark] = useState('');
  const [ServiceId, setServiceId] = useState(0);
  const [CloseDate, setCloseDate] = useState('');
  const [CloseRemark, setCloseRemark] = useState('');
  const [MeetNote, setMeetNote] = useState([]);
  const [MeetSummary, setMeetSummary] = useState([]);
  const [MeetDate, setMeetDate] = useState([]);
  const [MeetTime, setMeetTime] = useState([]);
  const [NextMeetDate, setNextMeetDate] = useState([]);
  const [MeetPointList, setMeetPointList] = useState([]);
  const [MeetAgendaList, setMeetAgendaList] = useState([]);
  const [AttachmentList, setAttachmentList] = useState([]);
  const [AttachmentFilePath, setAttachmentFilePath] = useState([]);
  const [AttachmentFile, setAttachmentFile] = useState([]);
  const [MeetDetails, setMeetDetails] = useState([]);
  const [DisplayAttachFile, setDisplayAttachFile] = useState([]);
  const [DropdownChannelList, setDropdownChannelList] = useState([]);
  const [CorporateList, setCorporateList] = useState([]);
  const [MeetCorporateId, setMeetCorporateId] = useState(0);
  const [CorporateId, setCorporateId] = useState(0);
  const [MeetingId, setMeetingId] = useState(0);
  const [CorporateLeadContactList, setCorporateLeadContactList] = useState([]);

  const [FinanceInstName, setFinanceInstName] = useState([]);
  const [MemberCode, setMemberCode] = useState([]);
  const [CMail, setCMail] = useState([]);
  const [CPassword, setCPassword] = useState([]);
  const [CAddress, setCAddress] = useState([]);
  const [DistrictId, setDistrictId] = useState([]);
  const [CityId, setCityId] = useState([]);
  const [PAN, setPAN] = useState([]);
  const [TAN, setTAN] = useState([]);
  const [GSTProvId, setGSTProvId] = useState([]);
  const [GstARN, setGstARN] = useState([]);
  const [TehsilId, setTehsilId] = useState([]);
  const [BureauId, setBureauId] = useState(0);
  const [AlternateInfo, setAlternateInfo] = useState([]);
  const [BillingInfo, setBillingInfo] = useState([]);
  const [ContactInfo, setContactInfo] = useState([]);
  const [ManageInfo, setManageInfo] = useState([]);
  const [MeetPoint, setMeetPoint] = useState([]);
  const [ClientList, setClientList] = useState([]);
  const [pointde, setpoint] = useState([]);
  const [agendade, setagenda] = useState([]);
  const [Idset, setId] = useState([]);
  const [attach, setattachment] = useState([]);
  const [mid, setmid] = useState([]);
  //let CoLeadId = localStorage.getItem("CorporateleadId");

  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [CorporateServicesPackageList, setCorporateServicesPackageList] = useState([])
  const [CorporateServicesPackageBureauList, setCorporateServicesPackageBureauList] = useState([])
  const [CorporateServicesPackageBureauStepList, setCorporateServicesPackageBureauStepList] = useState([])
  const [CorporateServicesPackageBureauDueList, setCorporateServicesPackageBureauDueList] = useState([])

  let { id } = useParams();
  let MIdstorage = localStorage.getItem("MId");

  const [MonthlyRate, setMonthlyRate] = useState(0);
  const [QuaterlyRate, setQuaterlyRate] = useState(0);
  const [HalfYearlyRate, setHalfYearlyRate] = useState(0);
  const [YearlyRate, setYearlyRate] = useState(0);

  const [MonthlyGSTRate, setMonthlyGSTRate] = useState(0);
  const [QuaterlyGSTRate, setQuaterlyGSTRate] = useState(0);
  const [HalfYearlyGSTRate, setHalfYearlyGSTRate] = useState(0);
  const [YearlyGSTRate, setYearlyGSTRate] = useState(0);

  const [MonthlyTotalRate, setMonthlyTotalRate] = useState(0);
  const [QuaterlyTotalRate, setQuaterlyTotalRate] = useState(0);
  const [HalfYearlyTotalRate, setHalfYearlyTotalRate] = useState(0);
  const [YearlyTotalRate, setYearlyTotalRate] = useState(0);

  const [GstPercentage, setGstPercentage] = useState(0);
  const [NoofDue, setNoofDue] = useState(0);

  const [PlanAmount, setPlanAmount] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [TotalGstAmount, setTotalGstAmount] = useState(0);
  const [ServicesPackageType, setServicesPackageType] = useState('');
  const [ServiceList, setServiceList] = useState([]);
  const [NetworkSource, setNetworkSource] = useState([]);
  const [CorSerPackageList, setCorSerPackageList] = useState([]);
  const [CorporateServicesPackageBureau, setCorporateServicesPackageBureau] = useState([]);
  const [CorporateServicesPackageBureauFiter, setCorporateServicesPackageBureauFiter] = useState([]);
  const [BureauCategoryList, setBureauCategoryList] = useState([]);

  const [NodelofficerName, setNodelofficerName] = useState([]);
  const [CDesignation, setCDesignation] = useState([]);
  const [CMobileNo, setCMobileNo] = useState([]);
  const [CEmailId, setCEmailId] = useState([]);
  const [CLandLine, setCLandLine] = useState([]);

  const [AlternatecontactName, setAlternatecontactName] = useState([]);
  const [ADesignation, setADesignation] = useState([]);
  const [AMobileNo, setAMobileNo] = useState([]);
  const [AEmailId, setAEmailId] = useState([]);
  const [ALandLine, setALandLine] = useState([]);

  const [BillingName, setBillingName] = useState([]);
  const [BDesignation, setBDesignation] = useState([]);
  const [BMobileNo, setBMobileNo] = useState([]);
  const [BEmailId, setBEmailId] = useState([]);
  const [BLandLine, setBLandLine] = useState([]);

  const [MPincode, setMPincode] = useState([]);
  const [cminfoId, setcminfoId] = useState(0);
  const [BureauName, setBureauName] = useState([]);

  useEffect(() => {
    let CorporateLeadId = localStorage.getItem("EditCorporateRegisterClientId");
    if (CorporateLeadId != null) {
      if (CorporateLeadId != "0") {
        getStateList("1");
        getLeadContactList(CorporateLeadId);
        getLeadDetailById(CorporateLeadId);
        getCompanyList();
        getNetWorkList();
        getLeadFollowupList(CorporateLeadId);
        getMenuHideShow();
        getMeetDetailList(CorporateLeadId);
        setId(CorporateLeadId);
        getDropdownChannelMasterList();
        getCorporateList();
        document.getElementById("CorporateListdiv").style.display = 'none';
        getservicelist();
        getServiceDetails(CorporateLeadId);
        getCorporateServicesPackageList();
        getBureauCategoryList();
        // getManageInfoBureauDetails(1);
      } else {
        history.push("CorporateLeads");
      }
    } else {
      history.push("CorporateLeads");
    }
  }, []);



  const getservicelist = async () => {
    var ACUrl = global.APIURL + '/CorporateServiceList';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setServiceList(CityL);

      });
  };
  const getCompanyCityList = async TId => {
    var ACUrl = global.APIURL + '/ActiveCity/' + TId;
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCityList(CityL);
      });
  };


  const getCompanyDistrictList = async SId => {
    var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
    axios.get(AUrl)
      .then(Dres => {
        let DistrictL = Dres.data.map(kcdistrict => {
          return kcdistrict;
        });
        setDistrictList(DistrictL);
      });

  };

  const getCorporateServicesPackageList = async () => {
    let CorporateLeadId = localStorage.getItem("EditCorporateRegisterClientId");
    const CorporateData = {
      Id: CorporateLeadId,
    }
    axios.post(global.APIURL + '/CorporateServicesPackageBureau', CorporateData)
      .then(res => {

        setCorporateServicesPackageList(res.data.ServicesPackage);
        setCorporateServicesPackageBureau(res.data.ServicesPackageBureau);
        let SArray = [];

        for (let i = 0; i < res.data.ServicesPackage.length; i++) {

          let VCorporateServicesPackageList = res.data.ServicesPackage[i];

          let VSerPackageId = VCorporateServicesPackageList.ServicesPackageId;

          let SerBureauList = [];

          SerBureauList = res.data.ServicesPackageBureau.filter(x => x.ServiceId == VSerPackageId);

          let BArray = [];

          for (let j = 0; j < SerBureauList.length; j++) {
            const SItem = {
              VBId: SerBureauList[j].BId,
              VServiceId: SerBureauList[j].ServiceId,
              VTitle: SerBureauList[j].Title
            }
            BArray.push(SItem);

          }

          const SItem2 = {
            VId: VCorporateServicesPackageList.Id,
            CorporateleadId: VCorporateServicesPackageList.CorporateleadId,
            ServicesPackageId: VCorporateServicesPackageList.ServicesPackageId,
            ServicesPackageType: VCorporateServicesPackageList.ServicesPackageType,
            PlanAmount: VCorporateServicesPackageList.PlanAmount,
            TotalAmount: VCorporateServicesPackageList.TotalAmount,
            TotalRecivedAmount: VCorporateServicesPackageList.TotalRecivedAmount,
            TotalDueAmount: VCorporateServicesPackageList.TotalDueAmount,
            PaymentStaus: VCorporateServicesPackageList.PaymentStaus,
            PaymentType: VCorporateServicesPackageList.PaymentType,
            ServiceTitle: VCorporateServicesPackageList.ServiceTitle,
            BArray: BArray
          }
          SArray.push(SItem2);
          setCorSerPackageList(SArray);
        }
      });
  }

  const getCorporateList = async () => {
    fetch(global.APIURL + "/MeetingCorporateClient")
      .then((res) => res.json())
      .then((json) => {
        setCorporateList(json)
      })
  }

  const getCorporateContactPersonList = async CompanyId => {
    const LeadInquiry = {
      CompanyId: CompanyId
    };
    setCorporateId(CompanyId);
    axios.post(global.APIURL + "/MeetingCorporateClientContact", LeadInquiry)
      .then(CorporateClientres => {
        let CorporateClientContact = CorporateClientres.data.map(contact => {
          return contact;
        });
        setCorporateContactPersonList(CorporateClientContact);

      });
  };

  const getDropdownChannelMasterList = async () => {
    var VNetworkTypeId = document.getElementById("ddlNetworkType").value;
    if (parseInt(VNetworkTypeId) > 0) {
      const ChannelDropdownFilter = {
        ChannelTypeId: VNetworkTypeId,
        ChannelMasterId: 0,
        CityId: 0,
        SpMode: 'ChannelMasterSelectAll',
      };
      axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
        .then(CLres => {
          let VChannelMasterList = CLres.data.map(vcl => {
            return vcl;
          });
          setDropdownChannelList(VChannelMasterList);
        });
    }
  };

  const getEditDropdownChannelMasterList = async (NetworkTypeId) => {
    var VNetworkTypeId = NetworkTypeId
    if (parseInt(VNetworkTypeId) > 0) {
      const ChannelDropdownFilter = {
        ChannelTypeId: VNetworkTypeId,
        ChannelMasterId: 0,
        CityId: 0,
        SpMode: 'ChannelMasterSelectAll',
      };
      axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
        .then(CLres => {
          let VChannelMasterList = CLres.data.map(vcl => {
            return vcl;
          });
          setDropdownChannelList(VChannelMasterList);
        });
    }
  };

  const getMenuHideShow = async () => {
    const f = {
      MenuId: "48",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {
        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add Tehsil') {
            setAddTehsil(MS);
          }
          else if (MA == 'Add City') {
            setAddCity(MS);
          }
        }
      });
  };

  const getCompanyList = async () => {
    var ACUrl = global.APIURL + '/CompanyActive/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCompanyList(CityL);
      });
  };
  const getNetWorkList = async () => {
    var ACUrl = global.APIURL + '/ChannalTypeList/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setNetworkTypeList(CityL);
      });
  };

  const getStateList = async CId => {
    var AUrl = global.APIURL + '/ActiveState/' + CId;
    axios.get(AUrl)
      .then(Sres => {
        let StateL = Sres.data.map(kcstate => {
          return kcstate;
        });
        setStateList(StateL);
      });
  };

  const getDistrictList = async SId => {
    setStateId(SId);
    var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
    axios.get(AUrl)
      .then(Dres => {
        let DistrictL = Dres.data.map(kcdistrict => {
          return kcdistrict;
        });
        setDistrictList(DistrictL);
      });
  };

  const getCompanyTehsilList = async DId => {
    var AUrl = global.APIURL + '/ActiveTehsil/' + DId;
    axios.get(AUrl)
      .then(Dres => {
        let TehsilL = Dres.data.map(kctehsil => {
          return kctehsil;
        });
        setTehsilList(TehsilL);
      });
  };

  const getMeetDetailList = async (CID) => {
    const MData = {
      "CorporateleadId": CID
    }

    axios.post(global.APIURL + '/WebCorporateLeadMomEdit', MData)
      .then(res => {
        if (res.data != '') {

          setMeetDetails(res.data);
          setmid(res.data[0].MId);
        }

      });

  }

  const MeetPointhandleAddRow = e => {
    var LRNo = MeetPointList.length == 0 ? 1 : (MeetPointList[MeetPointList.length - 1].RowId + 1);

    var VMeetPoint = document.getElementById('txtMeetingPoint').value;
    if (VMeetPoint == "") {
      alert("Enter Meeting Points");
      return;
    }

    const Mitem = {
      MeetPointId: 0,
      RowId: LRNo,
      Point: VMeetPoint,
      MeetPointTextBoxControlId: 'txtMeetingPoint' + LRNo,

    };
    setMeetPointList([...MeetPointList, Mitem]);
    document.getElementById('txtMeetingPoint').value = '';

  };

  const MeetPointhandleRemoveRow = (e, RowId, MeetPointId) => {
    let AlertMessgae = "Are you sure you want to delete this re cord?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowId;
      setMeetPointList(MeetPointList.filter(item => item.RowId !== RId));

      var VMeetPointId = parseInt(MeetPointId == null ? '0' : MeetPointId == '' ? '0' : MeetPointId);
      if (VMeetPointId > 0) {
        const MPItemArr = { Id: VMeetPointId }
        axios.post(global.APIURL + '/WebMOMPointDelete', MPItemArr).then(resem => { });
      }
    }
  };

  const MeetAgendahandleAddRow = e => {
    var LRNo = MeetAgendaList.length == 0 ? 1 : (MeetAgendaList[MeetAgendaList.length - 1].RowId + 1);

    var VAgenda = document.getElementById('txtMeetingAgenda').value;
    if (VAgenda == "") {
      alert("Enter Meeting Agenda");
      return;
    }

    const Aitem = {
      MeetAgendaId: 0,
      RowId: LRNo,
      Agenda: VAgenda,
      MeetAgendaTextBoxControlId: VAgenda,

    };
    setMeetAgendaList([...MeetAgendaList, Aitem]);
    document.getElementById('txtMeetingAgenda').value = '';

  };

  const MeetAgendahandleRemoveRow = (e, RowId, MeetAgendaId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowId;
      setMeetAgendaList(MeetAgendaList.filter(item => item.RowId !== RId));

      var VMeetAgendaId = parseInt(MeetAgendaId == null ? '0' : MeetAgendaId == '' ? '0' : MeetAgendaId);
      if (VMeetAgendaId > 0) {
        const MAItemArr = { Id: VMeetAgendaId }
        axios.post(global.APIURL + '/WebMOMagendaDelete', MAItemArr).then(resem => { });
      }
    }
  };

  const AttachmenthandleAddRow = e => {
    var LRNo = AttachmentList.length == 0 ? 1 : (AttachmentList[AttachmentList.length - 1].RowId + 1);
    var VAttachFile = AttachmentFile;
    var VAttachFilePath = AttachmentFilePath;
    if (VAttachFile == "") {
      alert("Add Attachment");
      return;
    }
    const ALitem = {
      AttachId: 0,
      RowId: LRNo,
      AttachFile: VAttachFile,
      AttachFilePath: VAttachFilePath,
      UploadFileTextBoxControlId: VAttachFilePath

    };
    setAttachmentList([...AttachmentList, ALitem]);
    setAttachmentFile([]);
    setAttachmentFilePath([]);
    document.getElementById("AttachfileId").value = "";


  };

  const AttachmenthandleRemoveRow = (e, RowId, AttachId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowId;
      setAttachmentList(AttachmentList.filter(item => item.RowId !== RId));

      var VAttachId = parseInt(AttachId == null ? '0' : AttachId == '' ? '0' : AttachId);
      if (VAttachId > 0) {
        const AItemArr = { Id: VAttachId }
        axios.post(global.APIURL + '/WebMOMAttachDelete', AItemArr).then(resem => { });
      }
    }
  };

  function AttachmentfetchPost(e) {
    var details = e.target.files[0];
    var details1 = "MeetAttachment";
    const formData = new FormData();
    formData.append('Document', details);
    formData.append('FolderName', details1);
    var AUrl = global.APIURL + '/uploadavatar/';
    axios.post(AUrl, formData,
      {
        headers: { "Content-Type": details.type },
      })
      .then((res) => {

        setAttachmentFile(res.data.FileName);
        setAttachmentFilePath(res.data.IName);

        if (e.target.files[0].name != null) {
          if (e.target.files[0].name != '') {
            setDisplayAttachFile(1);
          }
          else {
            setDisplayAttachFile(0);
          }
        }
      });
  }
  const getLeadDetailById = async LId => {
    var AUrl = global.APIURL + '/CorporateleadEdit/' + LId;
    axios.get(AUrl)
      .then(res => {
        if (res.data.length > 0) {
          setCorporateLeadId(parseInt(res.data[0].CorporateleadId == null ? '0' : res.data[0].CorporateleadId == '' ? '0' : res.data[0].CorporateleadId));
          setCompanyId(res.data[0].CompanyId);
          setNetworkTypeId(res.data[0].ChannelTypeId);
          setAddress(res.data[0].Address);
          setStateId(res.data[0].StateId);
          getDistrictList(res.data[0].StateId);
          setCompanyDistrictId(res.data[0].DistrictId);
          getCompanyTehsilList(res.data[0].DistrictId);
          setCompanyCityId(res.data[0].CityId);
          setCompanyTehsilId(res.data[0].TehsilId);
          getCityList(res.data[0].TehsilId)
          setPincode(res.data[0].Pincode);
          setBranchcode(res.data[0].Branchcode);
          setcorEditLeadId(res.data[0].CorporateleadId);
          setmEmailId(res.data[0].EmailId);
          getEditDropdownChannelMasterList(res.data[0].ChannelTypeId);

          const CorporateData = {
            Id: parseInt(res.data[0].CorporateleadId == null ? '0' : res.data[0].CorporateleadId == '' ? '0' : res.data[0].CorporateleadId),
          }
          axios.post(global.APIURL + '/CorporateServicesPackage', CorporateData)
            .then(res => {
              setCorporateServicesPackageList(res.data);

            });
        }
      });
  };

  const getServiceDetails = async (VId) => {
    const SData = {
      "Id": VId
    }
    axios.post(global.APIURL + '/CorporateleadclientDetails ', SData)
      .then(res => {
        setClientList(res.data.Result[0].ClientDetails);
        setNetworkSource(res.data.Result[0].ClientDetails[0].CompanyName);
        setNetworkType(res.data.Result[0].ClientDetails[0].ChannelTypeName);
        setAddress(res.data.Result[0].ClientDetails[0].Address);
        setStateName(res.data.Result[0].ClientDetails[0].StateName);
        setDistrictName(res.data.Result[0].ClientDetails[0].districtName);
        setTehsilName(res.data.Result[0].ClientDetails[0].TehsilName);
        setCityName(res.data.Result[0].ClientDetails[0].CityName);
        setPincode(res.data.Result[0].ClientDetails[0].Pincode);
        setBranchcode(res.data.Result[0].ClientDetails[0].Branchcode);
        setmEmailId(res.data.Result[0].ClientDetails[0].EmailId);
        setLeadContactList(res.data.Result[0].de);
      });
  }

  const getLeadContactList = async (LeadId) => {
    var AUrl = global.APIURL + '/CorporateleadContactDetailsEdit/' + LeadId;
    axios.get(AUrl)
      .then(LCdres => {
        setCorporateLeadContactList(LCdres.data);
      });
  }


  const saveMeetDetails = async () => {

    let VMeetingPoint = document.getElementById("txtMeetingPoint").value;
    let VMeetingAgenda = document.getElementById("txtMeetingAgenda").value;
    let VAttachFile = AttachmentFile;
    let VAttachFilePath = AttachmentFilePath;

    if (MeetPointList.length == 0 && MeetAgendaList.length == 0 && AttachmentList.length == 0) {

      let IsDataInsert = 1;
      if (MeetNote == "") { IsDataInsert = 0; }
      else if (MeetSummary == "") { IsDataInsert = 0; }
      else if (MeetDate == "") { IsDataInsert = 0; }
      else if (MeetTime == "") { IsDataInsert = 0; }
      else if (NextMeetDate == "") { IsDataInsert = 0; }
      else if (VMeetingPoint == "") { IsDataInsert = 0; }
      else if (VMeetingAgenda == "") { IsDataInsert = 0; }
      else if (VAttachFile == "") { IsDataInsert = 0 }

      if (IsDataInsert == 1) {
        const MeetData = {
          Meetingheadnote: MeetNote,
          Meetingsummary: MeetSummary,
          MeetingDate: MeetDate,
          Meetingtime: MeetTime,
          Nextmeetingdate: NextMeetDate,
          "Flag": "A",
          CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
          CorporateleadId: Idset
        }
        axios.post(global.APIURL + '/WebCorporateLeadManageofmeetingAdd', MeetData).then(res => {

          var VMId = res.data[0].MId;

          for (let ccp = 0; ccp < CorporateLeadContactList.length; ccp++) {


            const CorporateContactPersonDetail = CorporateLeadContactList[ccp];
            var VChkId = CorporateContactPersonDetail.ChkId;
            var VCorporateleadId = CorporateContactPersonDetail.CorporateleadId;
            var VCorporateleadContactId = CorporateContactPersonDetail.CorporateleadContactId;
            var VIsChk = document.getElementById(VChkId).checked;
            if (VIsChk == true) {


              const MeetItemArr = {
                Id: 0,
                MId: VMId,
                CorporateId: VCorporateleadId == null ? '0' : VCorporateleadId == '' ? '0' : VCorporateleadId,
                ContactId: VCorporateleadContactId == null ? '0' : VCorporateleadContactId == '' ? '0' : VCorporateleadContactId,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              };

              axios.post(global.APIURL + '/MeetingContactInformationSave', MeetItemArr).then(res => {
              });
            }
          }

          if (VMeetingPoint != '') {
            const MeetPointItemArr = {
              MId: VMId,
              Point: VMeetingPoint,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: Idset
            };

            axios.post(global.APIURL + '/WebCorporateLeadMompointAdd', MeetPointItemArr).then(res => { });
          }

          if (VMeetingAgenda != '') {
            const MeetAgItemArr = {
              MId: VMId,
              Agenda: VMeetingAgenda,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: Idset
            };

            axios.post(global.APIURL + '/WebCorporateLeadMomagendaAdd', MeetAgItemArr).then(res => { });
          }

          if (VAttachFile != '') {
            const ALItemArr = {
              MId: VMId,
              Attachment: VAttachFilePath,
              mediafileName: VAttachFile,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: Idset
            };

            axios.post(global.APIURL + '/WebCorporateLeadMomAttachmentAdd', ALItemArr).then(res => { });
          }

          alert("Insert Successfully");
          window.setTimeout(() => {
            history.push("/MOMList");
          }, 1000);

        });
      }
    }
    else {
      let IsDataInsert = 1;
      if (MeetNote == "") { IsDataInsert = 0; alert("Enter Meeting Note") }
      else if (MeetSummary == "") { IsDataInsert = 0; alert("Enter Meeting Summary") }
      else if (MeetDate == "") { IsDataInsert = 0; alert("Enter Meeting Meeting Date") }
      else if (MeetTime == "") { IsDataInsert = 0; alert("Enter Meeting Time") }
      else if (NextMeetDate == "") { IsDataInsert = 0; alert("Enter Next Meeting Date") }
      else if (VMeetingPoint == "") { IsDataInsert = 0; alert("Enter Meeting Point") }
      else if (VMeetingAgenda == "") { IsDataInsert = 0; alert("Enter Meeting Agenda") }
      else if (VAttachFile == "") { IsDataInsert = 0; alert("Select Attachment") }
      if (IsDataInsert == 1) {

        const MeetData = {
          Meetingheadnote: MeetNote,
          Meetingsummary: MeetSummary,
          MeetingDate: MeetDate,
          Meetingtime: MeetTime,
          Nextmeetingdate: NextMeetDate,
          "Flag": "A",
          CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
          CorporateleadId: Idset
        }

        axios.post(global.APIURL + '/WebCorporateLeadManageofmeetingAdd', MeetData).then(res => {
          var VMId = res.data[0].MId;
          for (let ccp = 0; ccp < CorporateLeadContactList.length; ccp++) {

            const CorporateContactPersonDetail = CorporateLeadContactList[ccp];
            var VChkId = CorporateContactPersonDetail.ChkId;
            var VCorporateleadId = CorporateContactPersonDetail.CorporateleadId;
            var VCorporateleadContactId = CorporateContactPersonDetail.CorporateleadContactId;
            var VIsChk = document.getElementById(VChkId).checked;

            if (VIsChk == true) {

              const MeetItemArr = {
                Id: 0,
                MId: VMId,
                CorporateId: VCorporateleadId == null ? '0' : VCorporateleadId == '' ? '0' : VCorporateleadId,
                ContactId: VCorporateleadContactId == null ? '0' : VCorporateleadContactId == '' ? '0' : VCorporateleadContactId,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              };

              axios.post(global.APIURL + '/MeetingContactInformationSave', MeetItemArr).then(res => { });
            }
          }

          if (VMeetingPoint != '') {
            const MeetItemArr = {
              MId: VMId,
              Point: VMeetingPoint,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: Idset
            };
            axios.post(global.APIURL + '/WebCorporateLeadMompointAdd', MeetItemArr).then(res => { });
          }

          if (VMeetingAgenda != '') {
            const MeetAgItemArr = {
              MId: VMId,
              Agenda: VMeetingAgenda,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: Idset
            };
            axios.post(global.APIURL + '/WebCorporateLeadMomagendaAdd', MeetAgItemArr).then(res => { });
          }

          if (VAttachFile != '') {
            const ALItemArr = {
              MId: VMId,
              Attachment: VAttachFilePath,
              mediafileName: VAttachFile,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: Idset
            };

            axios.post(global.APIURL + '/WebCorporateLeadMomAttachmentAdd', ALItemArr).then(res => { });
          }

          for (let mpl = 0; mpl < MeetPointList.length; mpl++) {
            const MeetPointDetail = MeetPointList[mpl];

            var VMeetPointTextBoxControlId = MeetPointDetail.MeetPointTextBoxControlId;
            var VMeetPoint = document.getElementById(VMeetPointTextBoxControlId).value == null ? '' : document.getElementById(VMeetPointTextBoxControlId).value;

            if (VMeetPoint != '') {
              const MeetItemArr = {
                MId: VMId,
                Point: VMeetPoint,
                "Flag": "A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                CorporateleadId: Idset
              };

              axios.post(global.APIURL + '/WebCorporateLeadMompointAdd', MeetItemArr).then(res => { });
            }
          }

          for (let mal = 0; mal < MeetAgendaList.length; mal++) {
            const MeetAgendaDetail = MeetAgendaList[mal];

            var VMeetAgendaTextBoxControlId = MeetAgendaDetail.MeetAgendaTextBoxControlId;
            var VMeetAgenda = document.getElementById(VMeetAgendaTextBoxControlId).value == null ? '' : document.getElementById(VMeetAgendaTextBoxControlId).value;

            if (VMeetAgenda != '') {
              const MeetAgItemArr = {
                MId: VMId,
                Agenda: VMeetAgenda,
                "Flag": "A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                CorporateleadId: Idset
              };
              axios.post(global.APIURL + '/WebCorporateLeadMomagendaAdd', MeetAgItemArr).then(res => { });
            }
          }

          for (let al = 0; al < AttachmentList.length; al++) {
            const AttachmentListDetail = AttachmentList[al];

            var VAttachment = AttachmentListDetail.AttachFilePath;
            var VmediafileName = AttachmentListDetail.AttachFile;

            if (VAttachment != '') {
              const ALItemArr = {
                MId: VMId,
                Attachment: VAttachment,
                mediafileName: VmediafileName,
                "Flag": "A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                CorporateleadId: Idset
              };

              axios.post(global.APIURL + '/WebCorporateLeadMomAttachmentAdd', ALItemArr).then(res => { });
            }
          }

        });
        alert("Insert Successfully");
        window.setTimeout(() => {
          history.push("/MOMList");
        }, 2000);

      }
    }
  }

  const getCityList = async TId => {
    var AUrlC = global.APIURL + '/ActiveCity/' + TId;
    axios.get(AUrlC)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCityList(CityL);
      });
  };

  const getLeadFollowupList = async (LId) => {
    var AUrl = global.APIURL + '/WebcorFollowupListByLeadId/' + LId;
    fetch(AUrl)
      .then((res) => res.json())
      .then((json) => {
        setLeadFollowupList(json)
      })
  }

  const inputHandler = e => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };

  const getrateandgst = async ServiceId => {

    var AUrl = global.APIURL + '/CorporateServiceAllList';
    const rate = {
      Id: ServiceId,
    };

    axios.post(AUrl, rate)
      .then(res => {

        setGstPercentage(parseFloat(res.data[0].percentage == null ? '0' : res.data[0].percentage == '' ? '0' : res.data[0].percentage));

        setMonthlyRate(parseFloat(res.data[0].Monthlyrate == null ? '0' : res.data[0].Monthlyrate == '' ? '0' : res.data[0].Monthlyrate));
        setMonthlyGSTRate(parseFloat(res.data[0].MonthlyGSTRate == null ? '0' : res.data[0].MonthlyGSTRate == '' ? '0' : res.data[0].MonthlyGSTRate));
        setMonthlyTotalRate(parseFloat(res.data[0].MonthlyTotal == null ? '0' : res.data[0].MonthlyTotal == '' ? '0' : res.data[0].MonthlyTotal));

        setQuaterlyRate(parseFloat(res.data[0].QuaterlyRate == null ? '0' : res.data[0].QuaterlyRate == '' ? '0' : res.data[0].QuaterlyRate));
        setQuaterlyGSTRate(parseFloat(res.data[0].QuaterlyGSTRate == null ? '0' : res.data[0].QuaterlyGSTRate == '' ? '0' : res.data[0].QuaterlyGSTRate));
        setQuaterlyTotalRate(parseFloat(res.data[0].QuaterlyTotal == null ? '0' : res.data[0].QuaterlyTotal == '' ? '0' : res.data[0].QuaterlyTotal));

        setHalfYearlyRate(parseFloat(res.data[0].Halfyearly == null ? '0' : res.data[0].Halfyearly == '' ? '0' : res.data[0].Halfyearly));
        setHalfYearlyGSTRate(parseFloat(res.data[0].HalfyearlyGSTRate == null ? '0' : res.data[0].HalfyearlyGSTRate == '' ? '0' : res.data[0].HalfyearlyGSTRate));
        setHalfYearlyTotalRate(parseFloat(res.data[0].HalfYearlyTotal == null ? '0' : res.data[0].HalfYearlyTotal == '' ? '0' : res.data[0].HalfYearlyTotal));

        setYearlyRate(parseFloat(res.data[0].yearlyrate == null ? '0' : res.data[0].yearlyrate == '' ? '0' : res.data[0].yearlyrate));
        setYearlyGSTRate(parseFloat(res.data[0].YearlyGSTRate == null ? '0' : res.data[0].YearlyGSTRate == '' ? '0' : res.data[0].YearlyGSTRate));
        setYearlyTotalRate(parseFloat(res.data[0].YearlyTotal == null ? '0' : res.data[0].YearlyTotal == '' ? '0' : res.data[0].YearlyTotal));
      });

  }
  const SelectServicesPackageType = async PackageType => {
    var VGstPer = parseFloat(GstPercentage == null ? '0' : GstPercentage.toString() == '' ? '0' : GstPercentage.toString());
    var VNoOfDue = parseFloat(NoofDue == null ? '0' : NoofDue.toString() == '' ? '0' : NoofDue.toString());
    if (PackageType == 'Monthly') {
      var VTotalAmount = VNoOfDue == 0 ? MonthlyRate : MonthlyRate * VNoOfDue;
      var VTotalGstAmount = ((VTotalAmount * VGstPer) / 100);
      setPlanAmount(MonthlyRate);
      setTotalAmount(VTotalAmount);
      setTotalGstAmount(VTotalGstAmount);
    }
    else if (PackageType == 'Quaterly') {
      var VTotalAmount = VNoOfDue == 0 ? QuaterlyRate : QuaterlyRate * VNoOfDue;
      var VTotalGstAmount = ((VTotalAmount * VGstPer) / 100);
      setPlanAmount(QuaterlyRate);
      setTotalAmount(VTotalAmount);
      setTotalGstAmount(VTotalGstAmount);
    }
    else if (PackageType == 'Half Yearly') {
      var VTotalAmount = VNoOfDue == 0 ? HalfYearlyRate : HalfYearlyRate * VNoOfDue;
      var VTotalGstAmount = ((VTotalAmount * VGstPer) / 100);
      setPlanAmount(HalfYearlyRate);
      setTotalAmount(VTotalAmount);
      setTotalGstAmount(VTotalGstAmount);
    }
    else if (PackageType == 'Yearly') {
      var VTotalAmount = VNoOfDue == 0 ? YearlyRate : YearlyRate * VNoOfDue;
      var VTotalGstAmount = ((VTotalAmount * VGstPer) / 100);
      setPlanAmount(YearlyRate);
      setTotalAmount(VTotalAmount);
      setTotalGstAmount(VTotalGstAmount);
    }
  }

  const onChangeNoofDue = async (SNoofDue) => {
    var VGstPer = parseFloat(GstPercentage == null ? '0' : GstPercentage.toString() == '' ? '0' : GstPercentage.toString());
    var VNoOfDue = parseFloat(SNoofDue == null ? '0' : SNoofDue.toString() == '' ? '0' : SNoofDue.toString());
    if (ServicesPackageType == 'Monthly') {
      var VTotalAmount = VNoOfDue == 0 ? MonthlyRate : MonthlyRate * VNoOfDue;
      var VTotalGstAmount = ((VTotalAmount * VGstPer) / 100);
      setPlanAmount(MonthlyRate);
      setTotalAmount(VTotalAmount);
      setTotalGstAmount(VTotalGstAmount);
    }
    else if (ServicesPackageType == 'Quaterly') {
      var VTotalAmount = VNoOfDue == 0 ? QuaterlyRate : QuaterlyRate * VNoOfDue;
      var VTotalGstAmount = ((VTotalAmount * VGstPer) / 100);
      setPlanAmount(QuaterlyRate);
      setTotalAmount(VTotalAmount);
      setTotalGstAmount(VTotalGstAmount);
    }
    else if (ServicesPackageType == 'Half Yearly') {
      var VTotalAmount = VNoOfDue == 0 ? HalfYearlyRate : HalfYearlyRate * VNoOfDue;
      var VTotalGstAmount = ((VTotalAmount * VGstPer) / 100);
      setPlanAmount(HalfYearlyRate);
      setTotalAmount(VTotalAmount);
      setTotalGstAmount(VTotalGstAmount);
    }
    else if (ServicesPackageType == 'Yearly') {
      var VTotalAmount = VNoOfDue == 0 ? YearlyRate : YearlyRate * VNoOfDue;
      var VTotalGstAmount = ((VTotalAmount * VGstPer) / 100);
      setPlanAmount(YearlyRate);
      setTotalAmount(VTotalAmount);
      setTotalGstAmount(VTotalGstAmount);
    }
  }

  const RegisterClientDeails = async () => {
    let x = parseInt(NoofDue == null ? '0' : NoofDue == '' ? '0' : NoofDue)
    if (x <= 0) {
      alert("Enter No of Due");
      return;
    }
    const UserDetailArr = {
      CorporateleadId: parseInt(CorporateLeadId == null ? '0' : CorporateLeadId == '' ? '0' : CorporateLeadId),
      ServicePackageId: parseInt(ServiceId == null ? '0' : ServiceId == '' ? '0' : ServiceId),
      ServicesPackageType: ServicesPackageType,
      ServicesPackageNoOfDue: NoofDue,
      GstPer: GstPercentage,
      PlanAmount: PlanAmount,
      TotalAmount: TotalAmount,
      RecivedAmount: 0,
      PaymentStaus: 'Due',
      PaymentType: ServicesPackageType,
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    }

    axios.post(global.APIURL + '/CorporateLeadServicePackageRegisterMore', UserDetailArr)
      .then(res => {
        var VMasterId = parseInt(res.data[0].MasterId == null ? '0' : res.data[0].MasterId == '' ? '0' : res.data[0].MasterId)
        if (VMasterId > 0) {
          for (let pd = 0; pd < NoofDue; pd++) {
            let VDueDay = 0;
            let DDay = pd + 1;
            if (ServicesPackageType == 'Monthly') {
              VDueDay = DDay;
            }
            else if (ServicesPackageType == 'Quaterly') {
              VDueDay = DDay * 3;
            }
            else if (ServicesPackageType == 'Half Yearly') {
              VDueDay = DDay * 6;
            }
            else if (ServicesPackageType == 'Yearly') {
              VDueDay = DDay * 12;
            }

            const DueDetailArr = {
              MasterId: VMasterId,
              CorporateleadId: parseInt(CorporateLeadId == null ? '0' : CorporateLeadId == '' ? '0' : CorporateLeadId),
              ServicePackageId: parseInt(ServiceId == null ? '0' : ServiceId == '' ? '0' : ServiceId),
              ServicesPackageType: ServicesPackageType,
              NoOfDue: NoofDue,
              DueDay: VDueDay,
              GstPercentage: GstPercentage,
              Amount: PlanAmount,
              GstAmount: ((PlanAmount * GstPercentage) / 100),
              TotalAmount: (PlanAmount + ((PlanAmount * GstPercentage) / 100)),
              PaymentStaus: 'D',
              PaymentType: ServicesPackageType,
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
            }

            axios.post(global.APIURL + '/CorporateLeadServicePackagePaymentDue', DueDetailArr)
              .then(resdue => {
                if (pd == NoofDue - 1) {
                  alert(res.data[0].RecordStatus);
                  getCorporateServicesPackageList();
                }
              });
          }
        }
        else {
          alert(res.data[0].RecordStatus);
        }
      });
  };

  const Redirection = (CorLeadId) => {

    localStorage.setItem("ServCorLeadId", CorLeadId);
  }

  const ManageServiceActivitiesBureauRecord = (SerPackageId, CorleadId) => {
    //  localStorage.setItem("CorporateServiceId", parseInt(SerPackageId == null ? '0' : SerPackageId == '' ? '0' : SerPackageId))
    const UserDetailArr = {
      CorporateLeadId: parseInt(CorleadId == null ? '0' : CorleadId == '' ? '0' : CorleadId),
      ServiceId: parseInt(SerPackageId == null ? '0' : SerPackageId == '' ? '0' : SerPackageId),
    }
    var AUrl = global.APIURL + '/CorporateServiceActivitiesBureau';
    axios.post(AUrl, UserDetailArr)
      .then(res => {
        setCorporateServicesPackageBureauList(res.data);
      });
  }

  const ManageRedirection = (item1, item2) => {

    localStorage.setItem("CorporateServiceBureauId", parseInt(item2.VBId == null ? '0' : item2.VBId == '' ? '0' : item2.VBId));
    localStorage.setItem("CorporateServiceId", parseInt(item1.ServicesPackageId == null ? '0' : item1.ServicesPackageId == '' ? '0' : item1.ServicesPackageId));
    localStorage.setItem("CorporateServicePackageId", parseInt(item1.VId == null ? '0' : item1.VId == '' ? '0' : item1.VId));

    window.open('/ServiceMonthlyDues ', '_blank');

  }

  const ManageServiceActivitiesBureauDueRecord = (item) => {
    let VCorporateLeadId = parseInt(localStorage.getItem("EditCorporateRegisterClientId") == null ? "0" : localStorage.getItem("EditCorporateRegisterClientId") == "" ? "0" : localStorage.getItem("EditCorporateRegisterClientId"));
    let VCorporateServiceId = parseInt(localStorage.getItem("CorporateServiceId") == null ? "0" : localStorage.getItem("CorporateServiceId") == "" ? "0" : localStorage.getItem("CorporateServiceId"));
    let VCorporateServiceBureauId = parseInt(localStorage.getItem("CorporateServiceBureauId") == null ? "0" : localStorage.getItem("CorporateServiceBureauId") == "" ? "0" : localStorage.getItem("CorporateServiceId"));
    //  localStorage.setItem("CorporateServiceBureauStepId", parseInt(item.Id == null ? '0' : item.Id == '' ? '0' : item.Id))

    const UserDetailArr = {
      CorporateLeadId: VCorporateLeadId,
      ServiceId: VCorporateServiceId,
      BureauId: VCorporateServiceBureauId,
      BureauStepId: parseInt(item.Id == null ? '0' : item.Id == '' ? '0' : item.Id),
    }
    var AUrl = global.APIURL + '/CorporateServiceActivitiesBureauDue';
    axios.post(AUrl, UserDetailArr)
      .then(res => {
        setCorporateServicesPackageBureauDueList(res.data);
      });
  }

  const ManageServiceActivitiesBureauDueActionRecord = (item) => {
    localStorage.setItem("CorporateServiceBureauDueId", parseInt(item.Id == null ? '0' : item.Id == '' ? '0' : item.Id));


  }

  const saveManageInfo = () => {

    let VContactName = document.getElementById("txtContactName").value;
    let VContactDesignation = document.getElementById("txtContactDesignation").value;
    let VContactMobile = document.getElementById("txtContactMobile").value;
    let VContactLandline = document.getElementById("txtContactLandline").value;
    let VContactEmail = document.getElementById("txtContactEmail").value;

    let VAlterContactName = document.getElementById("txtAlterContactName").value;
    let VAlterContactDesignation = document.getElementById("txtAlterContactDesignation").value;
    let VAlterContactMobile = document.getElementById("txtAlterContactMobile").value;
    let VAlterContactLandline = document.getElementById("txtAlterContactLandline").value;
    let VAlterContactEmail = document.getElementById("txtAlterContactEmail").value;

    let VBillContactName = document.getElementById("txtBillContactName").value;
    let VBillContactDesignation = document.getElementById("txtBillContactDesignation").value;
    let VBillContactMobile = document.getElementById("txtBillContactMobile").value;
    let VBillContactLandline = document.getElementById("txtBillContactLandline").value;
    let VBillContactEmail = document.getElementById("txtBillContactEmail").value;

    const ContItemArray = [];
    const AlterContItemArray = [];
    const BillContItemArray = [];


    let IsDataInsert = 1;
    if (VContactName == "") { IsDataInsert = 0; return; }
    if (VContactDesignation == "") { IsDataInsert = 0; return; }
    if (VContactMobile == "") { IsDataInsert = 0; return; }
    if (VContactLandline == "") { IsDataInsert = 0; return; }
    if (VContactEmail == "") { IsDataInsert = 0; return; }

    if (IsDataInsert == 1) {

      const CArray = {
        NodelofficerName: VContactName,
        Designation: VContactDesignation,
        MobileNo: VContactMobile,
        LandLine: VContactLandline,
        EmailId: VContactEmail,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")

      };
      ContItemArray.push(CArray);

    }


    if (VAlterContactName == "") { IsDataInsert = 0; return; }
    if (VAlterContactDesignation == "") { IsDataInsert = 0; return; }
    if (VAlterContactMobile == "") { IsDataInsert = 0; return; }
    if (VAlterContactLandline == "") { IsDataInsert = 0; return; }
    if (VAlterContactEmail == "") { IsDataInsert = 0; return; }

    if (IsDataInsert == 1) {
      const CAlterArray = {
        AlternatecontactName: VAlterContactName,
        Designation: VAlterContactDesignation,
        MobileNo: VAlterContactMobile,
        LandLine: VAlterContactLandline,
        EmailId: VAlterContactEmail,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      };
      AlterContItemArray.push(CAlterArray);
    }


    if (VBillContactName == "") { IsDataInsert = 0; return; }
    if (VBillContactDesignation == "") { IsDataInsert = 0; return; }
    if (VBillContactMobile == "") { IsDataInsert = 0; return; }
    if (VBillContactLandline == "") { IsDataInsert = 0; return; }
    if (VBillContactEmail == "") { IsDataInsert = 0; return; }
    if (IsDataInsert == 1) {
      const BillContactArray = {
        CBilling: VBillContactName,
        Designation: VBillContactDesignation,
        MobileNo: VBillContactMobile,
        LandLine: VBillContactLandline,
        EmailId: VBillContactEmail,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      };
      BillContItemArray.push(BillContactArray);
    }

    const CData = {
      cminfoId: 0,
      BureauId: BureauId,
      Nameofthefinancialinstitution: FinanceInstName,
      MemberCode: MemberCode,
      communicationEmail: CMail,
      communicationPassword: CPassword,
      communicationAddress: CAddress,
      stateId: StateId,
      cityId: CityId,
      DistrictId: DistrictId,
      TehsilId: TehsilId,
      pincode: MPincode,
      PAN: PAN,
      TAN: TAN,
      GSTProvisionalId: GSTProvId,
      GSTARN: GstARN,
      Flag: "A",
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
      Nodelofficer: ContItemArray,
      ManageInfocontact: AlterContItemArray,
      ManageInfocontactpersonbilling: BillContItemArray
    }
    console.log("CData", CData);

    axios.post(global.APIURL + '/CorporateLeadManageInfoInsert ', CData)
      .then(res => {
        console.log("res", res);
        alert(res.data.StatusMessage);
        getManageInfoBureauDetails(BureauId);

      });


  }

  const UpdateManageInfo = () => {

    let VContactName = document.getElementById("txtContactName").value;
    let VContactDesignation = document.getElementById("txtContactDesignation").value;
    let VContactMobile = document.getElementById("txtContactMobile").value;
    let VContactLandline = document.getElementById("txtContactLandline").value;
    let VContactEmail = document.getElementById("txtContactEmail").value;

    let VAlterContactName = document.getElementById("txtAlterContactName").value;
    let VAlterContactDesignation = document.getElementById("txtAlterContactDesignation").value;
    let VAlterContactMobile = document.getElementById("txtAlterContactMobile").value;
    let VAlterContactLandline = document.getElementById("txtAlterContactLandline").value;
    let VAlterContactEmail = document.getElementById("txtAlterContactEmail").value;


    let VBillContactName = document.getElementById("txtBillContactName").value;
    let VBillContactDesignation = document.getElementById("txtBillContactDesignation").value;
    let VBillContactMobile = document.getElementById("txtBillContactMobile").value;
    let VBillContactLandline = document.getElementById("txtBillContactLandline").value;
    let VBillContactEmail = document.getElementById("txtBillContactEmail").value;

    const ContItemArray = [];
    const AlterContItemArray = [];
    const BillContItemArray = [];


    let IsDataInsert = 1;
    if (VContactName == "") { IsDataInsert = 0; return; }
    if (VContactDesignation == "") { IsDataInsert = 0; return; }
    if (VContactMobile == "") { IsDataInsert = 0; return; }
    if (VContactLandline == "") { IsDataInsert = 0; return; }
    if (VContactEmail == "") { IsDataInsert = 0; return; }

    if (IsDataInsert == 1) {

      const CArray = {
        NodelofficerName: VContactName,
        Designation: VContactDesignation,
        MobileNo: VContactMobile,
        LandLine: VContactLandline,
        EmailId: VContactEmail,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")

      };
      ContItemArray.push(CArray);

    }


    if (VAlterContactName == "") { IsDataInsert = 0; return; }
    if (VAlterContactDesignation == "") { IsDataInsert = 0; return; }
    if (VAlterContactMobile == "") { IsDataInsert = 0; return; }
    if (VAlterContactLandline == "") { IsDataInsert = 0; return; }
    if (VAlterContactEmail == "") { IsDataInsert = 0; return; }

    if (IsDataInsert == 1) {
      const CAlterArray = {
        AlternatecontactName: VAlterContactName,
        Designation: VAlterContactDesignation,
        MobileNo: VAlterContactMobile,
        LandLine: VAlterContactLandline,
        EmailId: VAlterContactEmail,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      };
      AlterContItemArray.push(CAlterArray);
    }


    if (VBillContactName == "") { IsDataInsert = 0; return; }
    if (VBillContactDesignation == "") { IsDataInsert = 0; return; }
    if (VBillContactMobile == "") { IsDataInsert = 0; return; }
    if (VBillContactLandline == "") { IsDataInsert = 0; return; }
    if (VBillContactEmail == "") { IsDataInsert = 0; return; }
    if (IsDataInsert == 1) {
      const BillContactArray = {
        CBilling: VBillContactName,
        Designation: VBillContactDesignation,
        MobileNo: VBillContactMobile,
        LandLine: VBillContactLandline,
        EmailId: VBillContactEmail,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      };
      BillContItemArray.push(BillContactArray);
    }
    const MData = {

      cminfoId: cminfoId,
      BureauId: BureauId,
      Nameofthefinancialinstitution: FinanceInstName,
      MemberCode: MemberCode,
      communicationEmail: CMail,
      communicationPassword: CPassword,
      communicationAddress: CAddress,
      stateId: StateId,
      cityId: CityId,
      DistrictId: DistrictId,
      TehsilId: TehsilId,
      pincode: MPincode,
      PAN: PAN,
      TAN: TAN,
      GSTProvisionalId: GSTProvId,
      GSTARN: GstARN,
      Flag: "A",
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
      Nodelofficer: ContItemArray,
      ManageInfocontact: AlterContItemArray,
      ManageInfocontactpersonbilling: BillContItemArray

    }
    console.log("MData", MData);
    axios.post(global.APIURL + '/CorporateLeadManageInfoUpdate ', MData)
      .then(res => {
        console.log("update-res", res);
        alert(res.data.StatusMessage);
        getManageInfoBureauDetails(BureauId);


      });


  }

  const getBureauCategoryList = async () => {

    axios.get(global.APIURL + '/bureaucategoryActiveList ')
      .then(res => {
        console.log("res-B", res);
        setBureauCategoryList(res.data);

      });

  }

  const getManageInfoBureauDetails = async (VBId) => {

    console.log("VBId", VBId);

    const BItem = {
      BureauId: VBId
    }
    console.log("BItem-B", BItem);

    axios.post(global.APIURL + '/CorporateLeadManageInfoDetailByBureau', BItem)
      .then(res => {
        console.log("res-B", res);

        setManageInfo(res.data.ManageInformation);
        setContactInfo(res.data.ContactInformation);
        setAlternateInfo(res.data.AlternateContactInformation);
        setBillingInfo(res.data.BillingContactInformation);


        if (res.data.AlternateContactInformation.length > 0) {
          console.log("setAlternatecontactName", AlternatecontactName);
          setAlternatecontactName(res.data.AlternateContactInformation[0].AlternatecontactName);
          setADesignation(res.data.AlternateContactInformation[0].Designation);
          setAMobileNo(res.data.AlternateContactInformation[0].MobileNo);
          setAEmailId(res.data.AlternateContactInformation[0].EmailId);
          setALandLine(res.data.AlternateContactInformation[0].LandLine);
        }
        if (res.data.BillingContactInformation.length > 0) {

          setBillingName(res.data.BillingContactInformation[0].CBilling);
          setBDesignation(res.data.BillingContactInformation[0].Designation);
          setBMobileNo(res.data.BillingContactInformation[0].MobileNo);
          setBEmailId(res.data.BillingContactInformation[0].EmailId);
          setBLandLine(res.data.BillingContactInformation[0].LandLine);

        }
        if (res.data.ContactInformation.length > 0) {
          setNodelofficerName(res.data.ContactInformation[0].NodelofficerName);
          setCDesignation(res.data.ContactInformation[0].Designation);
          setCMobileNo(res.data.ContactInformation[0].MobileNo);
          setCEmailId(res.data.ContactInformation[0].EmailId);
          setCLandLine(res.data.ContactInformation[0].LandLine);

        }
        if (res.data.ManageInformation.length > 0) {
          setcminfoId(res.data.ManageInformation[0].cminfoId);
          setBureauId(res.data.ManageInformation[0].BureauId);
          setFinanceInstName(res.data.ManageInformation[0].Nameofthefinancialinstitution);
          setMemberCode(res.data.ManageInformation[0].MemberCode);
          setCMail(res.data.ManageInformation[0].communicationEmail);
          setCPassword(res.data.ManageInformation[0].communicationPassword);
          setCAddress(res.data.ManageInformation[0].communicationAddress);
          setMPincode(res.data.ManageInformation[0].pincode);
          setPAN(res.data.ManageInformation[0].PAN);
          setTAN(res.data.ManageInformation[0].TAN);
          setGSTProvId(res.data.ManageInformation[0].GSTProvisionalId);
          setGstARN(res.data.ManageInformation[0].GSTARN);
          setStateId(res.data.ManageInformation[0].stateId);
          getDistrictList(res.data.ManageInformation[0].stateId);
          getCompanyTehsilList(res.data.ManageInformation[0].DistrictId);
          setCityId(res.data.ManageInformation[0].cityId);
          getCityList(res.data.ManageInformation[0].TehsilId)
          setDistrictId(res.data.ManageInformation[0].DistrictId)
          setTehsilId(res.data.ManageInformation[0].TehsilId);
          setBureauName(res.data.ManageInformation[0].BureauTitle);




        }
      });

  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Corporate Leads | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">


          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle>Register Corporate Client Detail</CardTitle>
                <a href="/" className="me-2">Home</a>{" "}
                <span>/</span>
                <a href="/RegisterClientList" className="me-2 ms-2">Register Corporate Client</a>{" "}
                <span>/</span>
                <span className="ms-2">Register Corporate Client Detail</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/RegisterClientList">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <div className="ind-card" id="ind-card">
            <Row>
              <Col className="col-lg-12 col-md-12 col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                      <Col className="col-lg-12 col-md-12 col-12 mb-2">
                        <CardTitle>Corporate Lead</CardTitle>
                        <hr />
                      </Col>


                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Network Type </p>
                        <select className="form-select" Id="ddlNetworkType" name="ddlNetworkType" onChange={e => { setNetworkTypeId(e.target.value); getDropdownChannelMasterList(); }}>
                          <option value={"0"}>Select Network Type</option>
                          {NetworkTypeList.map(state => (
                            <option key={state.ChannelTypeId} value={state.ChannelTypeId} selected={NetworkTypeId === state.ChannelTypeId}>
                              {" "}
                              {state.ChannelTypeName}{" "}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Network Source </p>
                        <select className="form-select" Id="ddlNetworkSource" name="ddlNetworkSource" onChange={e => { setCompanyId(e.target.value) }}>
                          <option value={"0"}>Select Source</option>
                          {DropdownChannelList.map(item => (
                            <option key={item.ChannelMasterId} value={item.ChannelMasterId} selected={CompanyId === item.ChannelMasterId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col>
                        <div className="col-lg-3 col-md-6 col-12 mt-3">
                          <label htmlFor="EmailId" className="form-label">
                            EmailId
                          </label>
                          <Input
                            type="text"
                            placeholder="EmailId"
                            className="form-control"
                            id="txtNetworkSourceEmailAddress"
                            maxlength="50"
                            defaultValue={MEmailId}
                            onChange={e => setmEmailId(e.target.value)}
                          />

                        </div>
                      </Col>
                      <Col className="col-lg-12 col-md-12 col-12 mt-3 mt-md-3 mt-lg-0">
                        <p className="mt-3 mb-2 fontWeight500"> Address </p>
                        <div className="input-group auth-pass-inputgroup">
                          <textarea className="form-control" placeholder="Address" id="txtNetworkSourceAddress" rows="3" name="txtNetworkSourceAddress" defaultValue={Address} onChange={e => setAddress(e.target.value)}></textarea>
                        </div>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> State </p>
                        <select className="form-select" id="ddlNetworkSourceState" name="ddlNetworkSourceState" onChange={e => { getDistrictList(e.target.value); setStateId(e.target.value) }}>
                          <option key="0" value="0">Select State</option>
                          {StateList.map(item => (
                            <option key={item.StateId} value={item.StateId} selected={StateId === item.StateId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> District </p>
                        <select className="form-select" Id="ddlNetworkSourceDistrict" name="ddlNetworkSourceDistrict" onChange={e => { getCompanyTehsilList(e.target.value); setCompanyDistrictId(e.target.value) }}>
                          <option key="0" value="0">Select District</option>
                          {DistrictList.map(item => (
                            <option key={item.DistrictId} value={item.DistrictId} selected={CompanyDistrictId === item.DistrictId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label htmlFor="formFileSm" className="form-label">Tehsil</label>
                          </div>
                          <div>
                            {AddTehsil == "A" ? DistrictId > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddPerTehsil">+ Add New</a></span> : '' : ''}
                          </div>
                        </div>
                        <select className="form-select" id="ddlNetworkSourceTehsilId" name="ddlNetworkSourceTehsilId" onChange={e => { getCityList(e.target.value); setCompanyTehsilId(e.target.value) }}>
                          <option key="0" value="0">Select Tehsil</option>
                          {TehsilList.map(item => (
                            <option key={item.TehsilId} value={item.TehsilId} selected={CompanyTehsilId === item.TehsilId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label htmlFor="formFileSm" className="form-label">City/Village/Town</label>
                          </div>
                          <div>
                            {AddCity == "A" ? TehsilId > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddPerCity">+ Add New</a></span> : '' : ''}
                          </div>
                        </div>
                        <select className="form-select" id="ddlNetworkSourceCity" name="ddlNetworkSourceCity" onChange={e => setCityId(e.target.value)}>
                          <option key="0" value="0">Select City/Village/Town</option>
                          {CityList.map(item => (
                            <option key={item.CityId} value={item.CityId} selected={CompanyCityId === item.CityId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> PinCode </p>
                        <Input type="text" className="" id="txtNetworkSourcePincode" defaultValue={Pincode} onChange={e => setPincode(e.target.value)} placeholder="Enter PinCode" />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Branch Code </p>
                        <Input type="text" className="" id="txtNetworkSourceBarcode" defaultValue={Branchcode} onChange={e => setBranchCode(e.target.value)} placeholder="Enter Branch Code" />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-12 col-md-12 mb-2">
                        <CardTitle>Services Package Register</CardTitle>
                        <hr />
                      </Col>
                      <Col className={ServiceId == 0 ? 'col-lg-3 col-md-6 col-12 mb-3' : 'col-lg-3 col-md-6 col-12'}>
                        <p className="mb-2 fontWeight500"> Service </p>
                        <select className="form-select" id="ddlService" name="ddlService" onChange={e => { getrateandgst(e.target.value); setServiceId(e.target.value); }}>
                          <option key="0" value="0">Select Service</option>
                          {ServiceList.map(item => (
                            <option key={item.Id} value={item.Id}>
                              {item.value}
                            </option>
                          ))}
                        </select>
                      </Col>
                      {ServiceId > 0 ?
                        <Col className="col-lg-12 col-md-6 col-12 mt-3" id="divServicePrice">
                          <p className="mb-2 fontWeight500"> Services Package Price </p>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Monthly" onClick={e => { SelectServicesPackageType(e.target.value); setServicesPackageType(e.target.value) }} />
                            <label className="form-check-label mb-3" htmlFor="inlineRadio1">Monthly</label> | <span className="maintexttexteditb"> {MonthlyRate} + {MonthlyGSTRate} = </span><span className="maincountgst">{MonthlyTotalRate}</span>

                            {/* <div className="maingsttexteditbox">
                              <p>(<span className="maintexttexteditb"> {MonthlyRate} + {MonthlyGSTRate} =</span><span className="maincountgst">{MonthlyTotalRate}</span>)</p>
                            </div> */}
                          </div>
                          {/* <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Quaterly" onClick={e => { SelectServicesPackageType(e.target.value); setServicesPackageType(e.target.value) }} />
                            <label className="form-check-label" htmlFor="inlineRadio2">Quaterly</label>
                            <div className="maingsttexteditbox">
                              <p>(<span className="maintexttexteditb"> {QuaterlyRate} + {QuaterlyGSTRate} =</span><span className="maincountgst">{QuaterlyTotalRate}</span>)</p>
                            </div>
                          </div>

                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="Half Yearly" onClick={e => { SelectServicesPackageType(e.target.value); setServicesPackageType(e.target.value) }} />
                            <label className="form-check-label" htmlFor="inlineRadio3">Half yearly</label>
                            <div className="maingsttexteditbox">
                              <p>(<span className="maintexttexteditb"> {HalfYearlyRate} + {HalfYearlyGSTRate} =</span><span className="maincountgst">{HalfYearlyTotalRate}</span>)</p>
                            </div>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="Yearly" onClick={e => { SelectServicesPackageType(e.target.value); setServicesPackageType(e.target.value) }} />
                            <label className="form-check-label" htmlFor="inlineRadio4">yearly</label>
                            <div className="maingsttexteditbox">
                              <p>(<span className="maintexttexteditb"> {YearlyRate} + {YearlyGSTRate} =</span><span className="maincountgst">{YearlyTotalRate}</span>)</p>
                            </div>
                          </div> */}
                        </Col>
                        : ''}
                      {ServiceId > 0 ?
                        <Row className="mb-2">
                          <Col className="col-lg-3 col-md-4 col-12 mb-3 mb-md-3 mb-lg-0">
                            <p className="mb-2 fontWeight500">No. of Due</p>
                            <Input type="text" className="" id="txtNoofDue" placeholder="Enter No. of Due" onChange={(e) => { setNoofDue(e.target.value); onChangeNoofDue(e.target.value) }} />
                          </Col>
                          <Col className="col-lg-3 col-md-4 col-12 mb-3 mb-md-3 mb-lg-0">
                            <p className="mb-2 fontWeight500">Plan Amount </p>
                            <Input type="text" className="" id="txtPlanGstAmount" Value={PlanAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })} readOnly />
                          </Col>
                          <Col className="col-lg-3 col-md-4 col-12 mb-3 mb-md-3 mb-lg-0">
                            <p className="mb-2 fontWeight500">Total Amount </p>
                            <Input type="text" className="" id="txtTotalAmount" Value={TotalAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })} readOnly />
                          </Col>
                          <Col className="col-lg-3 col-md-4 col-12 mb-3 mb-md-3 mb-lg-0">
                            <p className="mb-2 fontWeight500">Total Gst Amount </p>
                            <Input type="text" className="" id="txtTotalGstAmount" Value={TotalGstAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })} readOnly />
                          </Col>
                        </Row>
                        : ''}
                      {ServiceId > 0 ?
                        <Row className="mb-2">
                          <div className="co-md-12 mt-3">
                            <a className="btn btn_success me-2" onClick={() => RegisterClientDeails()}> Register </a>
                            <a className="btn btn_danger me-2"> Cancel </a>
                          </div>
                        </Row>
                        : ''}

                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-12 col-md-12 mb-2">
                        <CardTitle>Registration Services Package</CardTitle>
                        <hr />
                      </Col>
                      <Col className="col-lg-12 col-md-12 mt-3">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col" className='text-center'>#</th>
                              <th scope="col">Services Package</th>
                              <th scope="col" className='text-center'>Type</th>
                              <th scope="col" className='text-center'>Price</th>
                              <th scope="col" className='text-center'>Total Amount</th>
                              <th scope="col" className='text-center'>Recived Amount</th>
                              <th scope="col" className='text-center'>Due Amount</th>

                              <th className="text-center">Manage Bureau</th>
                            </tr>
                          </thead>
                          <tbody>
                            {CorSerPackageList?.map((item, idx) => (
                              <tr key={idx}>
                                <td className='text-center'>{idx + 1}</td>
                                {/* <td><a href="/CorLeads/ManageSerInfo" onClick={(Redirection.bind(this, item.CorporateleadId))}>{item.ServiceTitle}</a></td> */}
                                <td>{item.ServiceTitle}</td>
                                <td className='text-center'>{item.ServicesPackageType}</td>
                                <td className='text-center'>{item.PlanAmount}</td>
                                <td className='text-center'>{item.TotalAmount}</td>
                                <td className='text-center'>{item.TotalRecivedAmount}</td>
                                <td className='text-center'>{item.TotalDueAmount}</td>
                                <td className='text-center'>{item.BArray.map((item2, idx) => (<a className="btn btn-primary btn-sm font-14 btncolor Bureaubtn" onClick={ManageRedirection.bind(this, item, item2)} key={idx}>{item2.VTitle}</a>))}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {CorporateServicesPackageBureauList.length > 0 ?
                  <Card>
                    <CardBody>
                      <Row>
                        <Col className="col-lg-12 col-md-12 mb-2">
                          <CardTitle>Services Package Bureau</CardTitle>
                          <hr />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-lg-12 col-md-12 mb-2">
                          {CorporateServicesPackageBureauList?.map((item, idx) => (
                            <a className="btn btn-primary btn-sm font-14 btncolor me-2" onClick={(ManageServiceActivitiesBureauStepRecord.bind(this, item))} key={idx}>Manage {item.Title}</a>
                          ))}
                          {/* <a className="btn btn-primary btn-sm font-14 btncolor" > Manage Bureau</a> */}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  : ''}
                {CorporateServicesPackageBureauStepList.length > 0 ?
                  <Card>
                    <CardBody>
                      <Row>
                        <Col className="col-lg-12 col-md-12 mb-2">
                          <CardTitle>Services Package Bureau Step</CardTitle>
                          <hr />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-lg-12 col-md-12 mb-2">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col" className='text-center'>Title</th>
                                <th scope="col" className='text-center'>Description</th>
                                <th scope="col" className='text-center'>Status</th>
                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {CorporateServicesPackageBureauStepList?.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{idx + 1}</td>
                                  <td className='text-center'>{item.Title}</td>
                                  <td className='text-center'>{item.Description}</td>
                                  <td className='text-center'>{item.ServiceActivitiesStatus}</td>
                                  <td className='text-center'>
                                    <a onClick={(ManageServiceActivitiesBureauDueRecord.bind(this, item))} className="btn btn-primary btn-sm font-14 btncolor me-2" > Manage Due</a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  : ''}
                <Card>

                  <CardBody>
                    <Col className="col-lg-3 col-md-6 col-12 mt-3">

                    </Col>
                  </CardBody>
                </Card>
                <div className="ind-card" id="ind-card">
                  <Row>
                    <Col className="col-lg-12 col-md-12 col-12">
                      <Card>
                        <CardBody>
                          <Row className="mb-2" >


                            <Col className="col-lg-9">
                              <CardTitle>Manage Information </CardTitle>

                            </Col>
                            
                            <Col className="col-lg-3 col d-flex align-items-center justify-content-end">
                              <select className="form-select" Id="ddlBureau" name="ddlBureau" onChange={e => { setBureauId(e.target.value); getManageInfoBureauDetails(e.target.value); }} >
                                <option value={"0"}>Select Bureau </option>
                                {BureauCategoryList.map(bureau => (
                                  <option key={bureau.BId} name={bureau.Title} value={bureau.BId} >
                                    {" "}
                                    {bureau.Title}{" "}
                                  </option>
                                ))}
                              </select>
                             



                                <ReactHtmlTableToExcel
                                  className="btn btn-primary float-end font-14 ms-2 exportexcelbtn"
                                  table="emp"
                                  filename="CorporateLeadManageInfoReport"
                                  sheet="Sheet"
                                  buttonText="Export Excel" />
                            </Col>
                            <Col className="col-lg-3 col-md-6 col-12 mt-3">
                              <p className="mb-2 fontWeight500">Financial Institution</p>
                              <Input
                                type="text"
                                placeholder="Financial Institution"
                                className="form-control"
                                id="txtFinInstName"
                                onChange={e => setFinanceInstName(e.target.value)}
                                defaultValue={FinanceInstName}
                              />

                            </Col>
                            <Col className="col-lg-3 col-md-6 col-12 mt-3">
                              <p className="mb-2 fontWeight500"> Member Code</p>
                              <Input
                                type="text"
                                placeholder=" Member Code"
                                className="form-control"
                                id="txtMemberCode"
                                onChange={e => setMemberCode(e.target.value)}
                                defaultValue={MemberCode}

                              />

                            </Col>
                            <Col className="col-lg-3 col-md-6 col-12 mt-3">
                              <p className="mb-2 fontWeight500">Communication Email</p>
                              <Input
                                type="text"
                                placeholder="Email"
                                className="form-control"
                                id="txtCEmail"
                                onChange={e => setCMail(e.target.value)}
                                defaultValue={CMail}


                              />

                            </Col>
                            <Col className="col-lg-3 col-md-6 col-12 mt-3">
                              <p className="mb-2 fontWeight500">Communication Password</p>
                              <Input
                                type="text"
                                placeholder=" Password"
                                className="form-control"
                                id="txtCPassword"
                                onChange={e => setCPassword(e.target.value)}
                                defaultValue={CPassword}
                              />

                            </Col>
                            <Col className="col-lg-12 col-md-12 col-12 mt-3 mt-md-3 mt-lg-0">
                              <p className="mt-3 mb-2 fontWeight500">Communication Address </p>
                              <div className="input-group auth-pass-inputgroup">
                                <textarea className="form-control" placeholder="Address" id="txtCAddress" rows="3" onChange={e => setCAddress(e.target.value)} defaultValue={CAddress}></textarea>
                              </div>
                            </Col>
                            <Col className="col-lg-3 col-md-6 col-12 mt-3">
                              <div className="mb-3">
                                <label htmlFor="State" className="form-label">
                                  State
                                </label>
                                <select className="form-select" Id="ddlCompanyState" name="ddlCompanyState" onChange={e => { getCompanyDistrictList(e.target.value); setStateId(e.target.value) }}>
                                  <option value={"0"}>Select State</option>
                                  {StateList.map(state => (
                                    <option key={state.StateId} value={state.StateId} selected={StateId == state.StateId}>
                                      {" "}
                                      {state.Title}{" "}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            <Col className="col-lg-3 col-md-6 col-12 mt-3">
                              <div className="mb-3">
                                <label htmlFor="District" className="form-label">
                                  District
                                </label>
                                <select className="form-select" Id="ddlCompanyDistrict" name="ddlCompanyDistrict" onChange={e => { getCompanyTehsilList(e.target.value); setDistrictId(e.target.value) }}>
                                  <option value={"0"}>Select District</option>
                                  {DistrictList.map(district => (
                                    <option key={district.DistrictId} value={district.DistrictId} selected={DistrictId == district.DistrictId}>
                                      {district.Title}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            <Col className="col-lg-3 col-md-6 col-12 mt-3">
                              <div className="mb-3">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <label htmlFor="formFileSm" className="form-label">Tehsil</label>
                                  </div>
                                  <div>
                                    {AddTehsil == "A" ? parseInt(CompanyDistrictId) > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddFirmTehsil">+ Add New</a></span> : '' : ''}
                                  </div>
                                </div>
                                <select className="form-select" Id="ddlCompanyTehsilId" name="ddlCompanyTehsilId" onChange={e => { getCompanyCityList(e.target.value); setTehsilId(e.target.value) }}>
                                  <option value={"0"}>Select Tehsil</option>
                                  {TehsilList.map(tehsil => (
                                    <option key={tehsil.TehsilId} value={tehsil.TehsilId} selected={tehsil.TehsilId == TehsilId}>
                                      {tehsil.Title}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            <Col className="col-lg-3 col-md-6 col-12 mt-3">
                              <div className="mb-3">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <label htmlFor="formFileSm" className="form-label">City/Village/Town</label>
                                  </div>
                                  <div>
                                    {AddCity == "A" ? parseInt(CompanyTehsilId) > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddFirmCity">+ Add New</a></span> : '' : ''}
                                  </div>
                                </div>
                                <select className="form-select" Id="ddlCompanyCity" name="ddlCompanyCity" onChange={e => setCityId(e.target.value)}>
                                  <option value={"0"}>Select City/Village/Town</option>
                                  {CityList.map(city => (
                                    <option key={city.CityId} value={city.CityId} selected={city.CityId == CityId}>
                                      {city.Title}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            <Col className="col-lg-3 col-md-6 col-12 mt-3">
                              <p className="mb-2 fontWeight500"> PinCode </p>
                              <Input type="text" className="" id="txtPinCode" defaultValue={MPincode} onChange={e => setMPincode(e.target.value)} placeholder="Enter PinCode" />
                            </Col>

                            <Col className="col-lg-3 col-md-6 col-12 mt-3">
                              <p className="mb-2 fontWeight500"> PAN</p>
                              <Input type="text" className="" id="txtPAN" defaultValue={PAN} onChange={e => setPAN(e.target.value)} placeholder="PAN" />
                            </Col>

                            <Col className="col-lg-3 col-md-6 col-12 mt-3">
                              <p className="mb-2 fontWeight500"> TAN</p>
                              <Input type="text" className="" id="txtTAN" defaultValue={TAN} onChange={e => setTAN(e.target.value)} placeholder="TAN" />
                            </Col>

                            <Col className="col-lg-3 col-md-6 col-12 mt-3">
                              <p className="mb-2 fontWeight500"> GST Provisional ID </p>
                              <Input type="text" className="" id="txtGSTId" defaultValue={GSTProvId} onChange={e => setGSTProvId(e.target.value)} placeholder="GST Provisional ID " />
                            </Col>

                            <Col className="col-lg-3 col-md-6 col-12 mt-3">
                              <p className="mb-2 fontWeight500"> GST ARN </p>
                              <Input type="text" className="" id="txtGstARN" defaultValue={GstARN} onChange={e => setGstARN(e.target.value)} placeholder="GST ARN " />
                            </Col>
                          </Row>

                          <Row className="" >
                            <Col className="col-lg-12 mb-2">
                              <CardTitle>Contact Information</CardTitle>
                              <hr />
                            </Col>
                            <Col lg="12">
                              <table style={{ width: "100%" }}>
                                <tbody>
                                  <tr id="addr" >
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="">
                                            <Label htmlFor="name">
                                              Nodal Officer Name
                                            </Label>
                                            <Input type="text" id="txtContactName" className="form-control" defaultValue={NodelofficerName} placeholder=" Nodal Officer Name" />
                                          </Col>

                                        </Row>
                                      </div>
                                    </td>
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="">
                                            <Label htmlFor="name">
                                              Designation
                                            </Label>
                                            <Input type="text" id="txtContactDesignation" defaultValue={CDesignation} className="form-control" placeholder="Designation" />
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="">
                                            <Label htmlFor="name">
                                              Mobile No
                                            </Label>
                                            <Input type="number" id="txtContactMobile" defaultValue={CMobileNo} onKeyPress={inputHandler} maxLength={10} className="form-control" placeholder=" Mobile No" />
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="">
                                            <Label htmlFor="name">
                                              Landline
                                            </Label>
                                            <Input type="text" id="txtContactLandline" defaultValue={CLandLine} onKeyPress={inputHandler} maxLength={16} className="form-control" placeholder="Landline No" />
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="">
                                            <Label htmlFor="name">
                                              Email
                                            </Label>
                                            <Input type="text" id="txtContactEmail" defaultValue={CEmailId} className="form-control" placeholder=" Email ID" />
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="mt-4">
                                            {/* <Button color="primary" className="btn-sm mb-1 btncolor" onClick={ContacthandleAddRow}><i className="fas fa-plus me-2"></i>Add</Button>              
                                  */}
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Col>
                            {/* <div className="co-md-12 mt-3"><a href="#" className="btn btn_success me-2" > Save </a><a href="#" className="btn btn_danger me-2"> Cancel </a></div> */}
                          </Row>

                          <Row className="">
                            <Col className="col-lg-12 mb-2">
                              <CardTitle>Alternate Contact Information</CardTitle>
                              <hr />
                            </Col>
                            <Col lg="12">
                              <table style={{ width: "100%" }}>
                                <tbody>


                                  <tr id="addr" >
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="">
                                            <Label htmlFor="name">
                                              Name
                                            </Label>
                                            <Input type="text" id="txtAlterContactName" defaultValue={AlternatecontactName} className="form-control" placeholder=" Alternate Contact Name" />
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">

                                          <Col lg="11" className="">
                                            <Label htmlFor="name">
                                              Designation
                                            </Label>
                                            <Input type="text" id="txtAlterContactDesignation" defaultValue={ADesignation} className="form-control" placeholder="Designation" />
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="">
                                            <Label htmlFor="name">
                                              Mobile No
                                            </Label>
                                            <Input type="number" id="txtAlterContactMobile" defaultValue={AMobileNo} onKeyPress={inputHandler} maxLength={10} className="form-control" placeholder=" Mobile No" />
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="">
                                            <Label htmlFor="name">
                                              Landline
                                            </Label>
                                            <Input type="text" id="txtAlterContactLandline" defaultValue={ALandLine} onKeyPress={inputHandler} maxLength={16} className="form-control" placeholder="Landline No" />
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="">
                                            <Label htmlFor="name">
                                              Email
                                            </Label>
                                            <Input type="text" id="txtAlterContactEmail" defaultValue={AEmailId} className="form-control" placeholder=" Email ID" />
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="mt-4">
                                            {/* <Button color="primary" className="btn-sm mb-1 btncolor" onClick={AlternateContacthandleAddRow}><i className="fas fa-plus me-2"></i>Add</Button>               */}
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col className="col-lg-12 mb-2">
                              <CardTitle>Billing Contact Information</CardTitle>
                              <hr />
                            </Col>
                            <Col lg="12">
                              <table style={{ width: "100%" }}>
                                <tbody>

                                  <tr id="addr">
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="">
                                            <Label htmlFor="name">
                                              Name
                                            </Label>
                                            <Input type="text" id="txtBillContactName" className="form-control" defaultValue={BillingName} placeholder=" Billing Person Name" />
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="">
                                            <Label htmlFor="name">
                                              Designation
                                            </Label>
                                            <Input type="text" id="txtBillContactDesignation" defaultValue={BDesignation} className="form-control" placeholder="Designation" />
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="">
                                            <Label htmlFor="name">
                                              Mobile No
                                            </Label>
                                            <Input type="number" id="txtBillContactMobile" defaultValue={BMobileNo} onKeyPress={inputHandler} maxLength={10} className="form-control" placeholder=" Mobile No" />
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="">
                                            <Label htmlFor="name">
                                              Landline
                                            </Label>
                                            <Input type="text" id="txtBillContactLandline" defaultValue={BLandLine} onKeyPress={inputHandler} maxLength={16} className="form-control" placeholder="Landline No" />
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="">
                                            <Label htmlFor="name">
                                              Email
                                            </Label>
                                            <Input type="text" id="txtBillContactEmail" className="form-control" defaultValue={BEmailId} placeholder=" Email ID" />
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                    <td>
                                      <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end mb-3">
                                          <Col lg="11" className="mt-4">
                                            {/* <Button color="primary" className="btn-sm mb-1 btncolor" onClick={BillingContacthandleAddRow}><i className="fas fa-plus me-2"></i>Add</Button>               */}
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Col>
                            <div className="co-md-12 mt-3">

                              {cminfoId == 0 ? <button id="savebtnid" className="btn btn_success me-2 btncolor" onClick={saveManageInfo}> Save </button> :

                                <button id="updatebtnid" className="btn btn_success me-2 btncolor" onClick={UpdateManageInfo}> Update </button>}
                              <a href="#" className="btn btn_danger me-2"> Cancel </a></div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <Card>
                  <CardBody>
                    <Row className="">
                      <Col className="col-lg-12 mb-2">
                        <CardTitle>Meeting Information</CardTitle>
                        <hr />
                      </Col>
                      <Row className="align-items-center">
                        <div id="CorporateListdiv" >
                          <Col lg="6" className="mb-3 col-md-6">
                            <Label htmlFor="name">Corporate</Label>
                            <select className="form-select" Id="ddlCorporate" name="ddlCorporate" onChange={e => getCorporateContactPersonList(e.target.value)}>
                              <option value={"0"}>Select Corporate</option>
                              {CorporateList.map((Corporate) => (
                                <option key={Corporate.CorporateId} value={Corporate.CorporateId} selected={Corporate.CorporateId == MeetCorporateId}> {Corporate.CorporateName} </option>
                              ))}
                            </select>   </Col>
                        </div>
                      </Row>
                      <Row className="align-items-center">

                        <Label htmlFor="name">Corporate Contact Information</Label>

                        {CorporateLeadContactList.map((item, idx) => (

                          <Col lg="3" className="col-md-6" key={idx}>
                            <input className="form-check-input" type="checkbox" id={item.ChkId} value={item.CorporateleadContactId} />{" "}<label className="">{item.Name}</label>
                          </Col>

                        ))}

                      </Row>
                      <Row className="align-items-center">
                        <Col lg="6" className="mb-3 col-md-6">
                          <Label htmlFor="name">Meeting Headnote</Label>
                          <Input type="text" id="txtMeetNote" className="form-control" placeholder="Enter Meeting HeadNote" onChange={e => setMeetNote(e.target.value)} />
                        </Col>
                        <Col lg="6" className="mb-3 col-md-6">
                          <Label htmlFor="name">Meeting Summary</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Input type="text" id="txtMeetSummary" className="form-control" placeholder="Enter Meeting Summary" onChange={e => setMeetSummary(e.target.value)} /> </div>
                        </Col>
                      </Row>
                      <Row className="">
                        <Col lg="4">
                          <table style={{ width: "100%" }}>
                            <tbody>
                              {MeetPointList?.map((item, idx) => (
                                <tr id={"addr" + idx} key={idx}>
                                  <td>
                                    <div data-repeater-list="group-a mb-3">
                                      <Row data-repeater-item className="align-items-center mobile-mt">
                                        <Col lg="9" className="mb-3 col-md-6">
                                          <Label htmlFor="name">Meeting Points</Label>
                                          <Input type="text" id={item.MeetPointTextBoxControlId} className="form-control" placeholder="Enter Meeting Agenda" defaultValue={item.Point} />
                                        </Col>

                                        <Col lg="2" className="col-5 col-md-2">
                                          <Button color="danger" className="mt-2 btn-sm btn-width" onClick={e =>
                                            MeetPointhandleRemoveRow(e, item.RowId, item.MeetPointId)
                                          }>
                                            <i className="fas fa-trash-alt"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item className="align-items-center">
                              <Col lg="9" className="mb-3 col-md-6">
                                <Label htmlFor="name">Meeting Points</Label>
                                <Input type="text" id="txtMeetingPoint" className="form-control" placeholder="Enter Meeting Points" defaultValue={MeetPoint} />
                              </Col>
                              <Col lg="3">
                                <Button color="primary" className="mt-2 btn-sm" onClick={MeetPointhandleAddRow}>
                                  <i className="fas fa-plus me-2"></i>Add
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col lg="4">
                          <table style={{ width: "100%" }}>
                            <tbody>
                              {AttachmentList?.map((item, idx) => (
                                <tr id={"addr" + idx} key={idx}>
                                  <td>
                                    <div data-repeater-list="group-a mb-3">
                                      <Row data-repeater-item className="align-items-center mobile-mt">
                                        <Col lg="9" className="mb-3 col-md-6">
                                          <Label htmlFor="name">Attachment</Label>
                                          <span className="form-control form-control"><i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i><a href={item.AttachFilePath} target="_blank" rel="noopener noreferrer" >{item.AttachFile}</a></span>
                                          <input type="hidden" className="form-control form-control" id={item.UploadFileTextBoxControlId} defaultValue={item.AttachFilePath} />
                                        </Col>
                                        <Col lg="2" className="col-5 col-md-2">
                                          <Button color="danger" className="mt-2 btn-sm btn-width" onClick={e =>
                                            AttachmenthandleRemoveRow(e, item.RowId, item.AttachId)
                                          }>
                                            <i className="fas fa-trash-alt"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item className="align-items-center">
                              <Col lg="9" className="mb-3 col-md-6">
                                <Label htmlFor="name">Attachment</Label>

                                {DisplayAttachFile > 0 ?
                                  <span className="float-end text-primary font-12" id="spandoctitle">
                                    <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                                    <a href={AttachmentFilePath} target="_blank" rel="noopener noreferrer" >{AttachmentFile}</a></span> : ''}
                                <input type="file" className="form-control form-control" id="AttachfileId" onChange={AttachmentfetchPost} />

                              </Col>

                              <Col lg="3">
                                <Button color="primary" className="mt-2 btn-sm" onClick={AttachmenthandleAddRow}>
                                  <i className="fas fa-plus me-2"></i>Add
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col lg="4">
                          <table style={{ width: "100%" }}>
                            <tbody>
                              {MeetAgendaList?.map((item, idx) => (
                                <tr id={"addr" + idx} key={idx}>
                                  <td>
                                    <div data-repeater-list="group-a mb-3">
                                      <Row data-repeater-item className="align-items-center mobile-mt">
                                        <Col lg="9" className="mb-3 col-md-6">
                                          <Label htmlFor="name">Next Meeting Agenda</Label>
                                          <Input type="text" id={item.MeetAgendaTextBoxControlId} className="form-control" placeholder="Enter Meeting Agenda" defaultValue={item.Agenda} />
                                        </Col>

                                        <Col lg="2" className="col-5 col-md-2">
                                          <Button color="danger" className="mt-2 btn-sm btn-width" onClick={e =>
                                            MeetAgendahandleRemoveRow(e, item.RowId, item.MeetAgendaId)}>
                                            <i className="fas fa-trash-alt"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item className="align-items-center">
                              <Col lg="9" className="mb-3 col-md-6">
                                <Label htmlFor="name">Next Meeting Agenda</Label>
                                <Input type="text" id="txtMeetingAgenda" className="form-control" placeholder="Enter Meeting Agenda" />
                              </Col>
                              <Col lg="3">
                                <Button color="primary" className="mt-2 btn-sm" onClick={MeetAgendahandleAddRow}>
                                  <i className="fas fa-plus me-2"></i>Add
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col lg="4" className="mb-3 col-md-6">
                          <Label htmlFor="name">Meeting Date</Label>
                          <Input type="date" id="txtMeetDate" className="form-control" placeholder="Enter Meeting Date" min={new Date().toISOString().split('T')[0]} onChange={e => setMeetDate(e.target.value)} />
                        </Col>
                        <Col lg="4" className="mb-3 col-md-6">
                          <Label htmlFor="name">Meeting Time</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <input id="txtMeetTime" type="time" className="form-control" placeholder="Enter Meeting Time" onChange={e => setMeetTime(e.target.value)} />
                          </div>
                        </Col>
                        <Col lg="4" className="mb-3 col-md-6">
                          <Label htmlFor="name">Next Meeting Date</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <input id="txtNextMeetDate" type="date" className="form-control" placeholder="Enter Next Meeting Date" min={new Date().toISOString().split('T')[0]} onChange={e => setNextMeetDate(e.target.value)} />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className="col-lg-12">
                          <button
                            type="button"
                            className="btn btn_success me-2"
                            onClick={e => saveMeetDetails()}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn btn_danger"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Row>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-12 col-md-12 mb-2">
                        <CardTitle>Meeting Details List</CardTitle>
                        <hr />
                      </Col>
                      <Col className="col-lg-12 col-md-12 mt-3">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th className="text-center" scope="col">#</th>
                              <th className="text-center" scope="col">Meeting headnote</th>
                              <th className="text-center" scope="col">Meeting summary</th>
                              <th className="text-center" scope="col">Meeting Date</th>
                              <th className="text-center" scope="col">Meeting Time</th>
                              <th className="text-center" scope="col">Next Meeting date</th>
                              <th className="text-center" scope="col">Point</th>
                              <th className="text-center" scope="col">Agenda</th>
                              <th className="text-center" scope="col">Attachment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {MeetDetails.map((record, index) => {
                              return (
                                <tr key={record.RowNo}>
                                  <td className="text-center">{record.Srno}</td>
                                  <td className="text-center">{record.Meetingheadnote}</td>
                                  <td className="text-center">{record.Meetingsummary}</td>
                                  <td className="text-center">{record.MeetingDate}</td>
                                  <td className="text-center">{record.Meetingtime}</td>
                                  <td className="text-center">{record.Nextmeetingdate}</td>
                                  <td className="text-center">{record.Point}</td>
                                  <td className="text-center">{record.Agenda}</td>
                                  <td className="text-center">{record.MediaFileName}</td>

                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <table id="emp" className="table" hidden="hidden">
                  <thead>
                    <tr></tr>
                    <tr><th></th><th></th><th> <h4>Corporate Lead Information {' ( ' + BureauName + ' ) '}</h4></th></tr>
                    <tr></tr>
                    <tr>
                      <th>#</th>
                      <th>Financial Institution</th>
                      <th>Membership Code</th>
                      <th>communication Email</th>
                      <th>communicationPassword</th>
                      <th>communicationAddress</th>
                      <th>State</th>
                      <th>District</th>
                      <th>Tehsil</th>
                      <th>City</th>
                      <th>pincode</th>
                      <th>PAN</th>
                      <th>TAN</th>
                      <th>GSTProvisionalId</th>
                      <th>GSTARN</th>
                    </tr>

                  </thead>
                  <tbody>
                    {
                      ManageInfo.map((item, index) => {
                        return <tr key={index}>
                          <td> {index + 1} </td>
                          <td>{item.Nameofthefinancialinstitution}</td>
                          <td>{item.MemberCode}</td>
                          <td>{item.communicationEmail}</td>
                          <td>{item.communicationPassword}</td>
                          <td>{item.communicationAddress}</td>
                          <td>{item.StateName}</td>
                          <td>{item.DistrictName}</td>
                          <td>{item.TehsilName}</td>
                          <td>{item.CityName}</td>
                          <td>{item.pincode}</td>
                          <td>{item.PAN}</td>
                          <td>{item.TAN}</td>
                          <td>{item.GSTProvisionalId}</td>
                          <td>{+ item.GSTARN}</td>

                        </tr>
                      })
                    }
                  </tbody>

                  <thead>
                    <tr></tr>
                    <tr><th></th><th></th><th> <h4>Contact Information</h4></th></tr>
                    <tr></tr>
                    <tr>
                      <th>#</th>
                      <th>Nodel officer Name</th>
                      <th>Designation</th>
                      <th>Mobile No</th>
                      <th>Email Id</th>
                      <th>LandLine</th>

                    </tr>
                  </thead>
                  <tbody>
                    {
                      ContactInfo.map((item, index) => {
                        return <tr key={index}>
                          <td> {index + 1} </td>
                          <td>{item.NodelofficerName}</td>
                          <td>{item.Designation}</td>
                          <td>{item.MobileNo}</td>
                          <td>{item.EmailId}</td>
                          <td>{item.LandLine}</td>

                        </tr>
                      })
                    }
                  </tbody>
                  <thead>
                    <tr></tr>
                    <tr><th></th><th></th><th> <h4>Alternate Information</h4></th></tr>
                    <tr></tr>
                    <tr>
                      <th>#</th>
                      <th>Alternate Contact Name</th>
                      <th>Designation</th>
                      <th>Mobile No</th>
                      <th>Email Id</th>
                      <th>LandLine</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      AlternateInfo.map((item, index) => {
                        return <tr key={index}>
                          <td> {index + 1} </td>
                          <td>{item.AlternatecontactName}</td>
                          <td>{item.Designation}</td>
                          <td>{item.MobileNo}</td>
                          <td>{item.EmailId}</td>
                          <td>{item.LandLine}</td>

                        </tr>
                      })
                    }
                  </tbody>
                  <thead>
                    <tr></tr>
                    <tr><th></th><th></th><th> <h4>Billing Information</h4></th></tr>
                    <tr></tr>
                    <tr>
                      <th>#</th>
                      <th>Billing Name</th>
                      <th>Designation</th>
                      <th>Mobile No</th>
                      <th>Email Id</th>
                      <th>LandLine</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      BillingInfo.map((item, index) => {
                        return <tr key={index}>
                          <td> {index + 1} </td>
                          <td>{item.CBilling}</td>
                          <td>{item.Designation}</td>
                          <td>{item.MobileNo}</td>
                          <td>{item.EmailId}</td>
                          <td>{item.LandLine}</td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

export default EditRegisterClient;
