import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import img2 from "assets/images/PAN.png";
import axios from "axios";
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody, Input, Label, Button } from "reactstrap";
import PlacholderImgC from "assets/images/favicon.png";
import { ResetTvRounded } from "@mui/icons-material";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { getSliderUtilityClass } from "@mui/base";
import { set } from "lodash";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import moment from "moment";

const ServiceMonthlyDues = () => {
  const history = useHistory();

  const [CorporateLeadId, setCorporateLeadId] = useState(0);
  const [StateList, setStateList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);
  const [CompanyDistrictId, setCompanyDistrictId] = useState("");
  const [CompanyTehsilId, setCompanyTehsilId] = useState("");
  const [LeadPartnerList, setLeadPartnerList] = useState([]);
  const [AddTehsil, setAddTehsil] = useState('D');
  const [AddCity, setAddCity] = useState('D');
  const [CompanyCityId, setCompanyCityId] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Branchcode, setBranchcode] = useState("");
  const [ComapanyList, setCompanyList] = useState([]);
  const [CompanyId, setCompanyId] = useState("");
  const [NetworkTypeList, setNetworkTypeList] = useState([]);
  const [NetworkType, setNetworkType] = useState([]);
  const [NetworkTypeId, setNetworkTypeId] = useState("");
  const [DistrictName, setDistrictName] = useState([]);
  const [TehsilName, setTehsilName] = useState([]);
  const [CityName, setCityName] = useState([]);
  const [LeadContactList, setLeadContactList] = useState([]);
  const [Address, setAddress] = useState("");
  const [LeadFollowupList, setLeadFollowupList] = useState([]);
  const [ChannelTypeId, setChannelTypeId] = useState("");
  const [StateId, setStateId] = useState("");
  const [StateName, setStateName] = useState([]);
  const [EditcorLeadId, setcorEditLeadId] = useState("");
  const [NextFollowupDate, setNextFollowupDate] = useState('');
  const [NextFollowupTime, setNextFollowupTime] = useState('');
  const [NextFollowupMode, setNextFollowupMode] = useState('');
  const [NextFollowupNote, setNextFollowupNote] = useState('');
  const [MEmailId, setmEmailId] = useState("");
  const [ConvertDate, setConvertDate] = useState('');
  const [ConvertRemark, setConvertRemark] = useState('');
  const [ServiceId, setServiceId] = useState(0);
  const [CloseDate, setCloseDate] = useState('');
  const [CloseRemark, setCloseRemark] = useState('');
  const [MeetNote, setMeetNote] = useState([]);
  const [MeetSummary, setMeetSummary] = useState([]);
  const [MeetDate, setMeetDate] = useState([]);
  const [MeetTime, setMeetTime] = useState([]);
  const [NextMeetDate, setNextMeetDate] = useState([]);
  const [MeetPointList, setMeetPointList] = useState([]);
  const [MeetAgendaList, setMeetAgendaList] = useState([]);
  const [AttachmentList, setAttachmentList] = useState([]);
  const [AttachmentFilePath, setAttachmentFilePath] = useState([]);
  const [AttachmentFile, setAttachmentFile] = useState([]);
  const [MeetDetails, setMeetDetails] = useState([]);
  const [DisplayAttachFile, setDisplayAttachFile] = useState([]);
  const [DropdownChannelList, setDropdownChannelList] = useState([]);
  const [CorporateList, setCorporateList] = useState([]);
  const [MeetCorporateId, setMeetCorporateId] = useState(0);
  const [CorporateId, setCorporateId] = useState(0);
  const [MeetingId, setMeetingId] = useState(0);
  const [CorporateLeadContactList, setCorporateLeadContactList] = useState([]);

  const [MeetPoint, setMeetPoint] = useState([]);
  const [ClientList, setClientList] = useState([]);

  const [pointde, setpoint] = useState([]);
  const [agendade, setagenda] = useState([]);
  const [Idset, setId] = useState([]);
  const [attach, setattachment] = useState([]);
  const [mid, setmid] = useState([]);
  //let CoLeadId = localStorage.getItem("CorporateleadId");
  const [SID, setSID] = useState([]);

  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const [CorporateServicesPackageList, setCorporateServicesPackageList] = useState([])
  const [CorporateServicesPackageBureauList, setCorporateServicesPackageBureauList] = useState([])
  const [CorporateServicesPackageBureauStepList, setCorporateServicesPackageBureauStepList] = useState([])
  const [CorporateServicesPackageBureauDueList, setCorporateServicesPackageBureauDueList] = useState([]);
  const [CorporateSerPackBureauDueList, setCorporateSerPackBureauDueList] = useState([]);

  let { id } = useParams();
  let MIdstorage = localStorage.getItem("MId");

  const [MonthlyRate, setMonthlyRate] = useState(0);
  const [QuaterlyRate, setQuaterlyRate] = useState(0);
  const [HalfYearlyRate, setHalfYearlyRate] = useState(0);
  const [YearlyRate, setYearlyRate] = useState(0);

  const [MonthlyGSTRate, setMonthlyGSTRate] = useState(0);
  const [QuaterlyGSTRate, setQuaterlyGSTRate] = useState(0);
  const [HalfYearlyGSTRate, setHalfYearlyGSTRate] = useState(0);
  const [YearlyGSTRate, setYearlyGSTRate] = useState(0);

  const [MonthlyTotalRate, setMonthlyTotalRate] = useState(0);
  const [QuaterlyTotalRate, setQuaterlyTotalRate] = useState(0);
  const [HalfYearlyTotalRate, setHalfYearlyTotalRate] = useState(0);
  const [YearlyTotalRate, setYearlyTotalRate] = useState(0);

  const [GstPercentage, setGstPercentage] = useState(0);
  const [NoofDue, setNoofDue] = useState(0);

  const [PlanAmount, setPlanAmount] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [TotalGstAmount, setTotalGstAmount] = useState(0);
  const [ServicesPackageType, setServicesPackageType] = useState('');
  const [ServiceList, setServiceList] = useState([]);
  const [NetworkSource, setNetworkSource] = useState([]);
  const [ServiceTitleList, setServiceTitleList] = useState([]);
  const [SerPackageList, setSerPackageList] = useState([]);
  const [ServicePackageId, setServicePackageId] = useState(0);
  const [CorSerBureauList, setCorSerBureauList] = useState([]);
  const [CorSerBL, setCorSerBL] = useState([]);
  const [CorporateServicesPackageBureau, setCorporateServicesPackageBureau] = useState([]);
  const [CorSerPackageList, setCorSerPackageList] = useState([]);
  const [ServiceMonthlyDue, setServiceMonthlyDue] = useState([]);

  useEffect(() => {
    let CorporateLeadId = localStorage.getItem("EditCorporateRegisterClientId");
    if (CorporateLeadId != null) {
      if (CorporateLeadId != "0") {

        setId(CorporateLeadId);
        getCorporateList();
        getservicelist();
        getCorporateServicesPackageList();
        getServiceDetails(CorporateLeadId)
        getManageServiceActivitiesBureauDueRecord();
        getCompanyList();
        getNetWorkList();
        getDropdownChannelMasterList();
        getStateList("1");
        getCityList();
        getLeadDetailById(CorporateLeadId);
        getSerPackageDetails();

      } else {
        history.push("CorporateLeads");
      }
    } else {
      history.push("CorporateLeads");
    }
  }, []);


  const getSerPackageDetails = () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");
    let VCorporateServiceId = parseInt(localStorage.getItem("CorporateServiceId") == null ? "0" : localStorage.getItem("CorporateServiceId") == "" ? "0" : localStorage.getItem("CorporateServiceId"));


    const SData = {
      "Id": VCorporateleadId,
      "ServicesPackageId": VCorporateServiceId
    }
    axios.post(global.APIURL + '/CorporateLeadServicepaymentDetails ', SData)
      .then(res => {
        setSerPackageList(res.data.Result[0].ServiceDetails);
        setServiceTitleList(res.data.Result[0].Servicetitle);
      });

  }

  const getCorporateServicesPackageList = async () => {
    let VSerPackageDetails = parseInt(localStorage.getItem("CorporateServicePackageId") == null ? "0" : localStorage.getItem("CorporateServicePackageId") == "" ? "0" : localStorage.getItem("CorporateServicePackageId"));
    let VBId = parseInt(localStorage.getItem("CorporateServiceBureauId") == null ? "0" : localStorage.getItem("CorporateServiceBureauId") == "" ? "0" : localStorage.getItem("CorporateServiceBureauId"));

    let CorporateLeadId = localStorage.getItem("EditCorporateRegisterClientId");
    const CorporateData = {
      Id: CorporateLeadId,
    }
    axios.post(global.APIURL + '/CorporateServicesPackageBureau', CorporateData)
      .then(res => {

        setCorporateServicesPackageList(res.data.ServicesPackage);
        setCorporateServicesPackageBureau(res.data.ServicesPackageBureau);
        let SArray = [];

        let VCorporateServicesPackageList = [];

        VCorporateServicesPackageList = res.data.ServicesPackage.filter(x => x.Id == VSerPackageDetails);

        for (let i = 0; i < VCorporateServicesPackageList.length; i++) {

          for (let j = 0; j < VCorporateServicesPackageList.length; j++) {

            let VSerPackageId = VCorporateServicesPackageList[j].ServicesPackageId;
            let SerBureauList = [];
            SerBureauList = res.data.ServicesPackageBureau.filter(x => x.ServiceId == VSerPackageId && x.BId == VBId);

            let BArray = [];

            for (let k = 0; k < SerBureauList.length; k++) {
              const SItem = {
                VRowId: SerBureauList[k].RowId,
                VBId: SerBureauList[k].BId,
                VServiceId: SerBureauList[k].ServiceId,
                VTitle: SerBureauList[k].Title,
                VCorporateLeadId: SerBureauList[k].CorporateLeadId
              }
              BArray.push(SItem);
            }

            const SItem2 = {
              Id: VCorporateServicesPackageList[j].Id,
              CorporateleadId: VCorporateServicesPackageList[j].CorporateleadId,
              ServicesPackageId: VCorporateServicesPackageList[j].ServicesPackageId,
              ServicesPackageType: VCorporateServicesPackageList[j].ServicesPackageType,
              PlanAmount: VCorporateServicesPackageList[j].PlanAmount,
              TotalAmount: VCorporateServicesPackageList[j].TotalAmount,
              TotalRecivedAmount: VCorporateServicesPackageList[j].TotalRecivedAmount,
              TotalDueAmount: VCorporateServicesPackageList[j].TotalDueAmount,
              PaymentStaus: VCorporateServicesPackageList[j].PaymentStaus,
              PaymentType: VCorporateServicesPackageList[j].PaymentType,
              ServiceTitle: VCorporateServicesPackageList[j].ServiceTitle,
              BArray: BArray
            }
            SArray.push(SItem2);
            setCorSerPackageList(SArray);
          }
        }
      });
  }

  const getservicelist = async () => {
    var ACUrl = global.APIURL + '/CorporateServiceList';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setServiceList(CityL);

      });
  };

  const getCityList = async TId => {
    var AUrlC = global.APIURL + '/ActiveCity/' + TId;
    axios.get(AUrlC)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCityList(CityL);
      });
  };
  const getLeadDetailById = async LId => {
    var AUrl = global.APIURL + '/CorporateleadEdit/' + LId;
    axios.get(AUrl)
      .then(res => {
        if (res.data.length > 0) {
          setCorporateLeadId(parseInt(res.data[0].CorporateleadId == null ? '0' : res.data[0].CorporateleadId == '' ? '0' : res.data[0].CorporateleadId));
          setCompanyId(res.data[0].CompanyId);
          setNetworkTypeId(res.data[0].ChannelTypeId);
          setAddress(res.data[0].Address);
          setStateId(res.data[0].StateId);
          getDistrictList(res.data[0].StateId);
          setCompanyDistrictId(res.data[0].DistrictId);
          getCompanyTehsilList(res.data[0].DistrictId);
          setCompanyCityId(res.data[0].CityId);
          setCompanyTehsilId(res.data[0].TehsilId);
          getCityList(res.data[0].TehsilId)
          setPincode(res.data[0].Pincode);
          setBranchcode(res.data[0].Branchcode);
          setcorEditLeadId(res.data[0].CorporateleadId);
          setmEmailId(res.data[0].EmailId);
          getEditDropdownChannelMasterList(res.data[0].ChannelTypeId);

          const CorporateData = {
            Id: parseInt(res.data[0].CorporateleadId == null ? '0' : res.data[0].CorporateleadId == '' ? '0' : res.data[0].CorporateleadId),
          }
          axios.post(global.APIURL + '/CorporateServicesPackage', CorporateData)
            .then(res => {
              setCorporateServicesPackageList(res.data);

            });
        }
      });
  };


  const getDropdownChannelMasterList = async () => {
    var VNetworkTypeId = document.getElementById("ddlNetworkType").value;
    if (parseInt(VNetworkTypeId) > 0) {
      const ChannelDropdownFilter = {
        ChannelTypeId: VNetworkTypeId,
        ChannelMasterId: 0,
        CityId: 0,
        SpMode: 'ChannelMasterSelectAll',
      };
      axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
        .then(CLres => {
          let VChannelMasterList = CLres.data.map(vcl => {
            return vcl;
          });
          setDropdownChannelList(VChannelMasterList);
        });
    }
  };

  const getServiceDetails = async (VId) => {
    const SData = {
      "Id": VId
    }
    axios.post(global.APIURL + '/CorporateleadclientDetails ', SData)
      .then(res => {
        setClientList(res.data.Result[0].ClientDetails);
        setNetworkSource(res.data.Result[0].ClientDetails[0].CompanyName);
        setNetworkType(res.data.Result[0].ClientDetails[0].ChannelTypeName);
        setAddress(res.data.Result[0].ClientDetails[0].Address);
        setStateName(res.data.Result[0].ClientDetails[0].StateName);
        setDistrictName(res.data.Result[0].ClientDetails[0].districtName);
        setTehsilName(res.data.Result[0].ClientDetails[0].TehsilName);
        setCityName(res.data.Result[0].ClientDetails[0].CityName);
        setPincode(res.data.Result[0].ClientDetails[0].Pincode);
        setBranchcode(res.data.Result[0].ClientDetails[0].Branchcode);
        setmEmailId(res.data.Result[0].ClientDetails[0].EmailId);
        setLeadContactList(res.data.Result[0].de);
      });
  }

  const getCorporateList = async () => {
    fetch(global.APIURL + "/MeetingCorporateClient")
      .then((res) => res.json())
      .then((json) => {
        setCorporateList(json)
      })
  }

  const getCompanyList = async () => {
    var ACUrl = global.APIURL + '/CompanyActive/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCompanyList(CityL);
      });
  };
  const getNetWorkList = async () => {
    var ACUrl = global.APIURL + '/ChannalTypeList/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setNetworkTypeList(CityL);
      });
  };

  const getStateList = async CId => {
    var AUrl = global.APIURL + '/ActiveState/' + CId;
    axios.get(AUrl)
      .then(Sres => {
        let StateL = Sres.data.map(kcstate => {
          return kcstate;
        });
        setStateList(StateL);
      });
  };

  const getDistrictList = async SId => {
    setStateId(SId);
    var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
    axios.get(AUrl)
      .then(Dres => {
        let DistrictL = Dres.data.map(kcdistrict => {
          return kcdistrict;
        });
        setDistrictList(DistrictL);
      });
  };

  const getCompanyTehsilList = async DId => {
    var AUrl = global.APIURL + '/ActiveTehsil/' + DId;
    axios.get(AUrl)
      .then(Dres => {
        let TehsilL = Dres.data.map(kctehsil => {
          return kctehsil;
        });
        setTehsilList(TehsilL);
      });
  };


  const getEditDropdownChannelMasterList = async (NetworkTypeId) => {
    var VNetworkTypeId = NetworkTypeId
    if (parseInt(VNetworkTypeId) > 0) {
      const ChannelDropdownFilter = {
        ChannelTypeId: VNetworkTypeId,
        ChannelMasterId: 0,
        CityId: 0,
        SpMode: 'ChannelMasterSelectAll',
      };
      axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
        .then(CLres => {
          let VChannelMasterList = CLres.data.map(vcl => {
            return vcl;
          });
          setDropdownChannelList(VChannelMasterList);
        });
    }
  };

  const ManageServiceActivitiesBureauStepRecord = () => {

    let VCorporateLeadId = parseInt(localStorage.getItem("EditCorporateRegisterClientId") == null ? "0" : localStorage.getItem("EditCorporateRegisterClientId") == "" ? "0" : localStorage.getItem("EditCorporateRegisterClientId"));
    let VCorporateServiceId = parseInt(localStorage.getItem("CorporateServiceId") == null ? "0" : localStorage.getItem("CorporateServiceId") == "" ? "0" : localStorage.getItem("CorporateServiceId"));
    let VBId = parseInt(localStorage.getItem("CorporateServiceBureauId") == null ? "0" : localStorage.getItem("CorporateServiceBureauId") == "" ? "0" : localStorage.getItem("CorporateServiceBureauId"));

    const UserDetailArr = {
      CorporateLeadId: VCorporateLeadId,
      ServiceId: VCorporateServiceId,
      BureauId: parseInt(VBId == null ? '0' : VBId == '' ? '0' : VBId),
    }
    var AUrl = global.APIURL + '/CorporateServiceActivitiesBureauStep';
    axios.post(AUrl, UserDetailArr)
      .then(res => {
        setCorporateServicesPackageBureauStepList(res.data);
      });
  }

  const getManageServiceActivitiesBureauDueRecord = async () => {
    let VCorporateLeadId = parseInt(localStorage.getItem("EditCorporateRegisterClientId") == null ? "0" : localStorage.getItem("EditCorporateRegisterClientId") == "" ? "0" : localStorage.getItem("EditCorporateRegisterClientId"));
    let VCorporateServiceId = parseInt(localStorage.getItem("CorporateServiceId") == null ? "0" : localStorage.getItem("CorporateServiceId") == "" ? "0" : localStorage.getItem("CorporateServiceId"));
    let VCorporateServiceBureauId = parseInt(localStorage.getItem("CorporateServiceBureauId") == null ? "0" : localStorage.getItem("CorporateServiceBureauId") == "" ? "0" : localStorage.getItem("CorporateServiceId"));
    //localStorage.setItem("CorporateServiceBureauStepId", parseInt(item.Id == null ? '0' : item.Id == '' ? '0' : item.Id))

    const UserDetailArr = {
      CorporateLeadId: VCorporateLeadId,
      ServiceId: VCorporateServiceId,
      BureauId: VCorporateServiceBureauId,
      BureauStepId: 0
    }
    var AUrl = global.APIURL + '/CorporateServiceActivitiesBureauDue';
    axios.post(AUrl, UserDetailArr)
      .then(res => {
        setCorporateServicesPackageBureauDueList(res.data);

        const CArray = [];
        for (let i = 0; i < res.data.length; i++) {
          let DateConvert = moment(res.data[i].DueDate, 'DD-MM-YYYY').format('MM-DD-YYYY');
          let VDateConvert = moment(DateConvert).format('MMMM', 'YYYY');
          let VYear = moment(res.data[i].DueDate, 'DD-MM-YYYY').format('YYYY');
          const CItem = {
            VRowId: res.data[i].RowId,
            VDueTitle: res.data[i].DueTitle,
            VDueDate: res.data[i].DueDate,
            VDueDateConvert: VDateConvert,
            VYear: VYear,
            VIsAccess: res.data[i].IsAccess,
            VDueStatus: res.data[i].DueStatus,
            VCorporateleadId: res.data[i].CorporateleadId,
            VServicesPackageId: res.data[i].ServicesPackageId,
            VId: res.data[i].Id,
            VAmount: res.data[i].Amount,
            VGstAmount: res.data[i].GstAmount,
            VTotalAmount: res.data[i].TotalAmount,
            VGstPercentage: res.data[i].GstPercentage,
            VServiceActivitiesStatus: res.data[i].ServiceActivitiesStatus
          }
          CArray.push(CItem);
        }
        setCorporateSerPackBureauDueList(CArray);
      });
  }

  const ManageServiceActivitiesBureauDueActionRecord = (item) => {
    localStorage.setItem("CorporateServiceBureauStepId", parseInt(item.Id == null ? '0' : item.Id == '' ? '0' : item.Id))
    window.location.href = "/CorporateClientServiceActivities";
  }

  const getMonthlyPayDetails = (Vitem) => {
    let VDueDate = Vitem.VDueDate;

    let MonthlyPayArray = [];
    MonthlyPayArray = CorporateSerPackBureauDueList.filter(x => x.VDueDate == VDueDate);
    setServiceMonthlyDue(MonthlyPayArray);

    let VCorporateLeadId = parseInt(localStorage.getItem("EditCorporateRegisterClientId") == null ? "0" : localStorage.getItem("EditCorporateRegisterClientId") == "" ? "0" : localStorage.getItem("EditCorporateRegisterClientId"));
    let VCorporateServiceId = parseInt(localStorage.getItem("CorporateServiceId") == null ? "0" : localStorage.getItem("CorporateServiceId") == "" ? "0" : localStorage.getItem("CorporateServiceId"));
    let VBId = parseInt(localStorage.getItem("CorporateServiceBureauId") == null ? "0" : localStorage.getItem("CorporateServiceBureauId") == "" ? "0" : localStorage.getItem("CorporateServiceBureauId"));
    localStorage.setItem("CorporateServiceBureauDueId", parseInt(Vitem.VId == null ? '0' : Vitem.VId == '' ? '0' : Vitem.VId));

    const UserDetailArr = {
      CorporateLeadId: VCorporateLeadId,
      ServiceId: VCorporateServiceId,
      BureauId: parseInt(VBId == null ? '0' : VBId == '' ? '0' : VBId),
    }

    var AUrl = global.APIURL + '/CorporateServiceActivitiesBureauStep';
    axios.post(AUrl, UserDetailArr)
      .then(res => {
        setCorporateServicesPackageBureauStepList(res.data);
      });

  }
  const exportPDF = () => {

    const title = "Client Service Details";

    const header = [[{ content: 'Corporate Information', colSpan: 10, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }], ["#", "Network Source", "Network Type", "Email", "Address", "State", "District", "Tehsil", "City", "Pincode", "Branch code"]];
    const data = ClientList.map((item, index) => [index + 1, item.CompanyName, item.ChannelTypeName, item.EmailId, item.Address, item.StateName, item.districtName, item.TehsilName, item.CityName, item.Pincode, item.Branchcode]);
    let content = {
      startY: 50,
      head: header,
      body: data,
      headStyles: { fillColor: ['#3982b7'] },
    };

    const header1 = [[{ content: 'Service Package Details', colSpan: 7, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }], ["#", "Service Title", "Type", "Price", "Total Amount", "Recived Amount", "Due Amount"]];
    const data1 = ServiceTitleList.map((item, index) => [index + 1, item.ServiceTitle, item.ServicesPackageType, item.PlanAmount, item.TotalAmount, item.TotalRecivedAmount, item.TotalDueAmount]);
    let content1 = {

      head: header1,
      body: data1,
      headStyles: { fillColor: ['#3982b7'] },
      // styles: {fontSize: 14 , fontFamily:"Hebrew",fontStyle:"bold"}
      //alternateRowStyles: {fillColor : [209,225,249]}
    };

    const header2 = [[{ content: 'Payment Details', colSpan: 7, styles: { halign: 'center', fillColor: '#F0F0F0', textColor: 'black' } }], ["#", "Service Title", "Type", "DueDate", "Amount", "Gst Amount", "TotalAmount", "PaymentStatus"]];
    const data2 = SerPackageList.map((item, index) => [index + 1, item.ServiceTitle, item.ServicesPackageType, item.DueDate, item.Amount, item.GstAmount, item.TotalAmount, item.PaymentStatus]);
    let content2 = {

      head: header2,
      body: data2,
      headStyles: { fillColor: ['#3982b7'] },

    };


    var doc = new jsPDF('p', 'pt');
    doc.text(title, 40, 40);
    doc.autoTable(content);
    doc.autoTable(content1);
    doc.autoTable(content2);

    doc.save("ClientService.pdf")

  }

  const FutureDueClicked = () => {
    alert("You can Manage Activities of Paid Month Only");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Corporate Leads | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle>Service Monthly Dues</CardTitle>
                <a href="/" className="me-2">Home</a>{" "}
                <span>/</span>
                <a href="/EditRegisterClient" className="me-2 ms-2">Register Client</a>{" "}
                <span>/</span>
                <span className="ms-2">Service Monthly Dues</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/RegisterClientList">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
              <ReactHtmlTableToExcel
                className="btn btn-primary btn-sm float-end font-14 me-2 btncolor"
                table="emp"
                filename="ClientService"
                sheet="Sheet"
                buttonText="Export Excel" />

              {<button className="btn btn-primary btn-sm float-end font-14 me-2 btncolor" onClick={exportPDF}>Export PDF</button>}

            </Col>
          </Row>
          <div className="ind-card" id="ind-card">
            <Row>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col className="col-lg-12 col-md-12 col-12 mb-2">
                      {CorSerPackageList.map((item, idx) => (<Col className="col-lg-12 col-md-12 mb-2 spkgtitle" key={idx}>
                        <CardTitle>{item.ServiceTitle}{item.BArray.map((item2, idx) => (<span key={idx}>{"  "}({item2.VTitle})</span>))}</CardTitle>
                      </Col>))}

                    </Col>
                    <div className="row">
                      <div className="card-body">
                        <p className="maincommontitlefordahboard"></p>
                        {CorSerPackageList.map((item, idx) => (<div className="row" key={idx}>
                          <div className="col-md-4" >

                            <div className="ccboxmaindashb">

                              <div className="d-flex ccdetailscall mb-1">

                                <div>
                                  <p className="mb-0">Service Package Details</p>
                                </div>
                              </div>
                              <div className="ccboxlinetext"></div>
                              <div className="d-flex sercss spackage">
                                <div>
                                  <i className="fa-solid fa-cube"></i>   <p className="ciboxtitlegrid pkgcss pkgtitlecss1">Package Type</p><p className="pkgcss pkgdetailcss">{item.ServicesPackageType}</p>
                                </div>
                              </div>
                              <div className="d-flex sercss spackage">
                                <div>
                                  <i className="fa-sharp fa-solid fa-tag"></i>  <p className="ciboxtitlegrid pkgcss pkgtitlecss2">Plan Amount</p><p className="pkgcss pkgdetailcss2">{item.PlanAmount}</p>
                                </div>
                              </div>
                              <div className="d-flex sercss spackage">
                                <div>
                                  <i className="fa-sharp fa-solid fa-wallet"></i>   <p className="ciboxtitlegrid pkgcss pkgtitlecss3">Total Amount</p><p className="pkgcss pkgdetailcss3">{item.TotalAmount}</p>
                                </div>
                              </div>
                              <div className="d-flex sercss spackage">
                                <div>
                                  <i className="fa-solid fa-hand-holding-dollar"></i>  <p className="ciboxtitlegrid pkgcss pkgtitlecss4">Total Received Amount</p><p className="pkgcss pkgdetailcss4">{item.TotalRecivedAmount}</p>
                                </div>
                              </div>
                              <div className="d-flex spackage spackage">
                                <div>
                                  <i className="fa-solid fa-circle-dollar-to-slot"></i>  <p className="ciboxtitlegrid pkgcss pkgtitlecss5">Total Due Amount</p><p className="pkgcss pkgdetailcss5">{item.TotalDueAmount}</p>

                                </div>
                              </div>




                            </div>
                          </div>
                        </div>))}
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {/* <Row>
                    <Col className="col-lg-12 col-md-12 mb-2">
                      <CardTitle>Services Package Details</CardTitle>
                      <hr />
                    </Col> 
                  { CorSerPackageList.map((item, idx) => (<Col className="col-lg-12 col-md-12 mb-2" key={idx}>
                      <CardTitle>{item.ServiceTitle}{item.BArray.map((item2, idx) => (<span key={idx}>{"  "}({item2.VTitle})</span>))}</CardTitle>
                    
                      <hr />
                    </Col>))}
               
                                  
                  </Row> */}
                  <Row>
                    <Col className="col-lg-3 col-md-6 col-12 mt-3">
                      <p className="mb-2 fontWeight500"> Network Type </p>
                      <select className="form-select" Id="ddlNetworkType" name="ddlNetworkType" onChange={e => { getDropdownChannelMasterList(); }}>
                        <option value={"0"}>Select Network Type</option>
                        {NetworkTypeList.map(state => (
                          <option key={state.ChannelTypeId} value={state.ChannelTypeId} selected={NetworkTypeId === state.ChannelTypeId}>
                            {" "}
                            {state.ChannelTypeName}{" "}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col className="col-lg-3 col-md-6 col-12 mt-3">
                      <p className="mb-2 fontWeight500"> Network Source </p>
                      <select className="form-select" Id="ddlNetworkSource" name="ddlNetworkSource" >
                        <option value={"0"}>Select Source</option>
                        {DropdownChannelList.map(item => (
                          <option key={item.ChannelMasterId} value={item.ChannelMasterId} selected={CompanyId === item.ChannelMasterId}>
                            {item.Title}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col>
                      <div className="col-lg-3 col-md-6 col-12 mt-3">
                        <label htmlFor="EmailId" className="form-label">
                          EmailId
                        </label>
                        <Input
                          type="text"
                          placeholder="EmailId"
                          className="form-control"
                          id="txtNetworkSourceEmailAddress"
                          maxlength="50"
                          defaultValue={MEmailId}

                        />

                      </div>
                    </Col>
                    <Col className="col-lg-12 col-md-12 col-12 mt-3 mt-md-3 mt-lg-0">
                      <p className="mt-3 mb-2 fontWeight500"> Address </p>
                      <div className="input-group auth-pass-inputgroup">
                        <textarea className="form-control" placeholder="Address" id="txtNetworkSourceAddress" rows="3" name="txtNetworkSourceAddress" defaultValue={Address} onChange={e => setAddress(e.target.value)}></textarea>
                      </div>
                    </Col>
                    <Col className="col-lg-3 col-md-6 col-12 mt-3">
                      <p className="mb-2 fontWeight500"> State </p>
                      <select className="form-select" id="ddlNetworkSourceState" name="ddlNetworkSourceState" onChange={e => { getDistrictList(e.target.value); setStateId(e.target.value) }}>
                        <option key="0" value="0">Select State</option>
                        {StateList.map(item => (
                          <option key={item.StateId} value={item.StateId} selected={StateId === item.StateId}>
                            {item.Title}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col className="col-lg-3 col-md-6 col-12 mt-3">
                      <p className="mb-2 fontWeight500"> District </p>
                      <select className="form-select" Id="ddlNetworkSourceDistrict" name="ddlNetworkSourceDistrict" onChange={e => { getCompanyTehsilList(e.target.value); setCompanyDistrictId(e.target.value) }}>
                        <option key="0" value="0">Select District</option>
                        {DistrictList.map(item => (
                          <option key={item.DistrictId} value={item.DistrictId} selected={CompanyDistrictId === item.DistrictId}>
                            {item.Title}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col className="col-lg-3 col-md-6 col-12 mt-3">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label htmlFor="formFileSm" className="form-label">Tehsil</label>
                        </div>
                        <div>
                          {AddTehsil == "A" ? DistrictId > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddPerTehsil">+ Add New</a></span> : '' : ''}
                        </div>
                      </div>
                      <select className="form-select" id="ddlNetworkSourceTehsilId" name="ddlNetworkSourceTehsilId" onChange={e => { getCityList(e.target.value); setCompanyTehsilId(e.target.value) }}>
                        <option key="0" value="0">Select Tehsil</option>
                        {TehsilList.map(item => (
                          <option key={item.TehsilId} value={item.TehsilId} selected={CompanyTehsilId === item.TehsilId}>
                            {item.Title}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col className="col-lg-3 col-md-6 col-12 mt-3">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label htmlFor="formFileSm" className="form-label">City/Village/Town</label>
                        </div>
                        <div>
                          {AddCity == "A" ? TehsilId > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddPerCity">+ Add New</a></span> : '' : ''}
                        </div>
                      </div>
                      <select className="form-select" id="ddlNetworkSourceCity" name="ddlNetworkSourceCity" onChange={e => setCityId(e.target.value)}>
                        <option key="0" value="0">Select City/Village/Town</option>
                        {CityList.map(item => (
                          <option key={item.CityId} value={item.CityId} selected={CompanyCityId === item.CityId}>
                            {item.Title}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col className="col-lg-3 col-md-6 col-12 mt-3">
                      <p className="mb-2 fontWeight500"> PinCode </p>
                      <Input type="text" className="" id="txtNetworkSourcePincode" defaultValue={Pincode} onChange={e => setPincode(e.target.value)} placeholder="Enter PinCode" />
                    </Col>
                    <Col className="col-lg-3 col-md-6 col-12 mt-3">
                      <p className="mb-2 fontWeight500"> Branch Code </p>
                      <Input type="text" className="" id="txtNetworkSourceBarcode" defaultValue={Branchcode} placeholder="Enter Branch Code" />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-lg-12 col-md-12 mb-2">
                      <CardTitle>Due Months</CardTitle>
                    </Col>
                  </Row>
                  <Row>
                    {CorporateSerPackBureauDueList?.map((item, idx) => (
                      <div className="col-md-2 mb-2" key={idx}>
                        <a className="btn btn-primary btn-sm font-14 me-2 btncolor btnwidth Monthbtn" onClick={getMonthlyPayDetails.bind(this, item)}>{item.VDueDateConvert}{" "}{item.VYear}</a>
                      </div>
                    ))}
                  </Row>
                </CardBody>
              </Card>

              {ServiceMonthlyDue.length > 0 ?
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-12 col-md-12 mb-2">
                        <CardTitle>Services Package Month Due</CardTitle>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-lg-12 col-md-12 mb-2">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col" className='text-center'>#</th>
                              <th scope="col" className='text-center'>Title</th>
                              <th scope="col" className='text-center'>Due Date</th>
                              <th scope="col" className='text-center'>Amount</th>
                              <th scope="col" className='text-center'>Gst Amount</th>
                              <th scope="col" className='text-center'>Total Amount</th>
                              <th scope="col" className='text-center'>Payment Status</th>
                              <th scope="col" className='text-center'>Status</th>

                            </tr>
                          </thead>
                          <tbody>
                            {ServiceMonthlyDue?.map((item, idx) => (
                              <tr key={idx}>
                                <td className='text-center'>{idx + 1}</td>
                                <td className='text-center'>{item.VDueTitle}</td>
                                <td className='text-center'>{item.VDueDate}</td>
                                <td className='text-center'>{item.VAmount}</td>
                                <td className='text-center'>{item.VGstAmount} ({item.GstPercentage}%)</td>
                                <td className='text-center'>{item.VTotalAmount}</td>
                                <td className='text-center'>{item.VDueStatus}</td>
                                <td className='text-center'>{item.VServiceActivitiesStatus}</td>

                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                : ''}

              <Col className="col-lg-12 col-md-12 col-12">
                {CorporateServicesPackageBureauStepList.length > 0 ?
                  <Card>
                    <CardBody>
                      <Row>
                        <Col className="col-lg-12 col-md-12 mb-2">
                          <CardTitle>Services Package Bureau Step</CardTitle>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-lg-12 col-md-12 mb-2">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col" className='text-center'>Title</th>
                                <th scope="col" className='text-center'>Description</th>
                                <th scope="col" className='text-center'>Status</th>
                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {CorporateServicesPackageBureauStepList?.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{idx + 1}</td>
                                  <td className='text-center'>{item.Title}</td>
                                  <td className='text-center'>{item.Description}</td>
                                  <td className='text-center'>{item.ServiceActivitiesStatus}</td>
                                  <td className='text-center'>
                                    <a className="btn btn-primary btn-sm font-14 btncolor me-2" onClick={ManageServiceActivitiesBureauDueActionRecord.bind(this, item)}> Manage Due</a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  : ''}
                <Card>
                </Card>
                <Card>
                  <table id="emp" className="table" hidden >
                    <thead>
                      <tr><td></td>
                        <td></td>
                        <td></td>
                        <td><h2>Corporate Details</h2></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr></tr>
                      <tr>
                        <th>#</th>
                        <th>Network Source</th>
                        <th>Network Type</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>State</th>
                        <th>District</th>
                        <th>Tehsil</th>
                        <th>City</th>
                        <th>Pincode</th>
                        <th>Branch code</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        ClientList.map((item, index) => {
                          return <tr key={index}>
                            <td> {index + 1} </td>
                            <td>{item.CompanyName}</td>
                            <td>{item.ChannelTypeName}</td>
                            <td>{item.EmailId}</td>
                            <td>{item.Address}</td>
                            <td>{item.StateName}</td>
                            <td>{item.districtName}</td>
                            <td>{item.TehsilName}</td>
                            <td>{item.CityName}</td>
                            <td>{item.Pincode}</td>
                            <td>{item.Branchcode}</td>
                          </tr>
                        })
                      }
                      <tr><td></td></tr>
                      <tr><td></td></tr>
                      <tr><td></td>
                        <td></td>
                        <td></td>
                        <td><h2>Service</h2></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr><td></td></tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Service Title</th>
                        <th>Type</th>
                        <th>Price</th>
                        <th>Total Amount</th>
                        <th>Total Received Amount</th>
                        <th>Total Due Amount</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        ServiceTitleList.map((item, index) => {
                          return <tr key={index}>
                            <td> {index + 1} </td>

                            <td>{item.ServiceTitle}</td>
                            <td>{item.ServicesPackageType}</td>
                            <td>{item.PlanAmount}</td>
                            <td>{item.TotalAmount}</td>
                            <td>{item.TotalRecivedAmount}</td>
                            <td>{item.TotalDueAmount}</td>


                          </tr>
                        })
                      }
                      <tr><td></td></tr>
                      <tr><td></td></tr>

                      <tr><td></td>
                        <td></td>
                        <td></td>
                        <td><h2>Payment</h2></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr><td></td></tr>

                    </tbody>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Service Title</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Gst Amount</th>
                        <th>Total Amount</th>
                        <th>Due Date</th>
                        <th>Payment Status</th>
                      </tr>
                    </thead>
                    <tbody>



                      {
                        SerPackageList.map((item, index) => {
                          return <tr key={index}>
                            <td> {index + 1} </td>

                            <td>{item.ServiceTitle}</td>
                            <td>{item.ServicesPackageType}</td>
                            <td>{item.Amount}</td>
                            <td>{item.GstAmount}</td>
                            <td>{item.TotalAmount}</td>
                            <td>{item.DueDate}</td>
                            <td>{item.PaymentStatus}</td>


                          </tr>
                        })
                      }

                    </tbody>

                  </table>
                </Card>



              </Col>


            </Row>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

export default ServiceMonthlyDues;
