import React from "react";
import { Redirect } from "react-router-dom";


import ChartApex from "../pages/Charts/Apexcharts";
import ChartistChart from "../pages/Charts/ChartistChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/charts-knob";
import ReCharts from "../pages/Charts/ReCharts";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";


//Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index";
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";




// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";



// Dashboard
import Dashboard from "../pages/Dashboard/index";
import ToDo from "../pages/Dashboard/ToDo.js";
import ToDoView from "../pages/Dashboard/ToDoView.js";
import AddTask from "../pages/Dashboard/AddTask.js";

//service
import Service from "../pages/Service/index";
import Resolution from "../pages/Resolution/index";

import Services from "../pages/Corporate/Services/index.js";
import ServiceDetail from "../pages/Corporate/ServiceDetail/index.js";
import SupportTicket from "../pages/Corporate/SupportTicket/index.js";
import SupportTicketDetail from "../pages/Corporate/SupportTicketDetail/index.js";
import TaskRetail from "../pages/Corporate/TaskRetail/index.js";
import TaskDetail from "../pages/Corporate/TaskRetail/TaskDetail.js";

//Contacts
import ContactsGrid from "../pages/Contacts/contacts-grid";
import ContactsList from "../pages/Contacts/ContactList/contacts-list";
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile";
import repeater from "../pages/Retail/AddLead/repeater.js";
import NestedRetail from "../pages/Retail/AddLead/NestedRetail.js";
import EditPartnerDetails from "../pages/Retail/AddLead/EditPartnerDetails.js";
import Mobile from "../pages/Retail/AddLead/Mobile.js";

//kc-page
import kcEmailInbox from "../pages/KcPage/kc-email-inbox";
import kcEmail from "../pages/KcPage/kc-email";
import kcDetail from "../pages/KcPage/kc-detail";
import kcOne from "../pages/KcPage/kc-one";
import kcTwo from "../pages/KcPage/kc-two";
import kcThree from "../pages/KcPage/kc-three";
import kcFour from "../pages/KcPage/kc-four";
import kcFive from "../pages/KcPage/kc-five";
import kcSix from "../pages/KcPage/kc-six";
import kcSeven from "../pages/KcPage/kc-seven";
import kcEight from "../pages/KcPage/kc-eight";
import kcNine from "../pages/KcPage/kc-nine";
import kcTen from "../pages/KcPage/kc-ten";
import kcEle from "../pages/KcPage/kc-ele";
import kcTwe from "../pages/KcPage/kc-twe";
import kcThr from "../pages/KcPage/kc-thr";
import kcFort from "../pages/KcPage/kc-fort";
import kcTodo from "../pages/KcPage/kc-todo";
import kcTodo1 from "../pages/KcPage/kc-todo1";
import kcTodo2 from "../pages/KcPage/kc-todo2";
import kcTodo3 from "../pages/KcPage/kc-todo3";
import kc1 from "../pages/KcPage/kc1";
import kc2 from "../pages/KcPage/kc2";
import kc3 from "../pages/KcPage/kc3";
import kc4 from "../pages/KcPage/kc4";
import kc5 from "../pages/KcPage/kc5";
import kc6 from "../pages/KcPage/kc6";
import kc7 from "../pages/KcPage/kc7";
import kc8 from "../pages/KcPage/kc8";
import kc9 from "../pages/KcPage/kc9";
import kc10 from "../pages/KcPage/kc10";
import kc11 from "../pages/KcPage/kc11";
import kc12 from "../pages/KcPage/kc12";
import kc13 from "../pages/KcPage/kc13";
import kc14 from "../pages/KcPage/kc14";
import kc15 from "../pages/KcPage/kc15";
import kc16 from "../pages/KcPage/kc16";
import kc17 from "../pages/KcPage/kc17";
import kc18 from "../pages/KcPage/kc18";
import kc19 from "../pages/KcPage/kc19";
import kc20 from "../pages/KcPage/kc20";
import kc21 from "../pages/KcPage/kc21";
import kc22 from "../pages/KcPage/kc22";
import kc23 from "../pages/KcPage/kc23";
import kc24 from "../pages/KcPage/kc24";
import kc25 from "../pages/KcPage/kc25";
import kc26 from "../pages/KcPage/kc26";
import kc27 from "../pages/KcPage/kc27";
import kc28 from "../pages/KcPage/kc28";
import kc29 from "../pages/KcPage/kc29";
import kc30 from "../pages/KcPage/kc30";
import kc31 from "../pages/KcPage/kc31";
import kc32 from "../pages/KcPage/kc32";
import kc33 from "../pages/KcPage/kc33";
import kc34 from "../pages/KcPage/kc34";
import kc35 from "../pages/KcPage/kc35";
import kc36 from "../pages/KcPage/kc36";
import kc37 from "../pages/KcPage/kc37";
import kc38 from "../pages/KcPage/kc38";



//Retail
import ClientList from "../pages/Retail/ClientList/index";
import LeadList from "../pages/Retail/LeadList/index";
import EnrollPendingLeadList from "../pages/Retail/LeadList/EnrollPendingLeadList";
import QuickInquiryList from "../pages/Retail/QuickInquiryList/index";
import QuickInquiryForm from "../pages/Retail/QuickInquiryForm/index";
import ToDoList from "../pages/Retail/ToDoList/index";
import ChannelList from "../pages/Retail/ChannelList/index";
import ChannelPartnerList from "../pages/Retail/ChannelPartnerList/index";
import FollowUpList from "../pages/Retail/FollowUpList/index";
import EditClient from "../pages/Retail/EditClient/index";
import RetailDashboard from "../pages/Retail/Dashboard/index";
import LeadPaymentDue from "../pages/Retail/LeadList/LeadPaymentDue";

import InvoiceManage from "../pages/AccountManage/Invoice/index";
import AddInvoice from "../pages/AccountManage/Invoice/AddInvoice";
import EditInvoice from "../pages/AccountManage/Invoice/EditInvoice";

import UserVisitEntry from "../pages/General/UserVisitEntry";
import AddUserVisitEntry from "../pages/General/UserVisitEntry/AddUserVisitEntry";



//Accounts
import CustomerClientManagement from "../pages/Accounts/CustomerClientManagement/index";
import CustomerInvoiceList from "../pages/Accounts/CustomerInvoiceList/index";
import CustomerInvoicePreview from "../pages/Accounts/CustomerInvoiceList/PreviewInvoice/index";

import GSTLedger from "../pages/Accounts/GSTLedger/index";
import GSTReportGenerator from "../pages/Accounts/GSTReportGenerator/index";
import ProfitLossLedger from "../pages/Accounts/ProfitLossLedger/index";
import TCSLedger from "../pages/Accounts/TCSLedger/index";
import TDSLedger from "../pages/Accounts/TDSLedger/index";
import VendorInvoiceList from "../pages/Accounts/VendorInvoiceList/index";
import VendorPreviewInvoice from "../pages/Accounts/VendorInvoiceList/PreviewInvoice/index";
import VendorList from "../pages/Accounts/VendorList/index";

import InvoicePaymentHistory from "../pages/Accounts/VendorList/Invoice&PaymentHistory/index";
import AddVendorInvoice from "../pages/Accounts/VendorInvoiceList/AddVendorInvoice/index";



//HR 
import BulkAttendance from "../pages/HR/BulkAttendance/index";
import CVBank from "../pages/HR/CVBank/index";
import HRDashboard from "../pages/HR/HRDashboard/index";
import JobApplication from "../pages/HR/JobApplication/index";
import JobOpeningList from "../pages/HR/JobOpeningList/index";
import ManageAttendance from "../pages/HR/ManageAttendance/index";
import ManageLeaveType from "../pages/HR/ManageLeaveType/index";
import ManageSalaryRegister from "../pages/HR/ManageSalaryRegister/index";
import ManageSalaryScale from "../pages/HR/ManageSalaryScale/index";
import ManageSalarySlip from "../pages/HR/ManageSalarySlip/index";
import ShiftChangeList from "../pages/HR/ShiftChangeList/index";
import TimeChangeList from "../pages/HR/TimeChangeList/index";
import GenerateSalary from "../pages/HR/GenerateSalary/index";
import HolidayList from "../pages/HR/HolidayList/index";
import HolidayMaster from "../pages/HR/HolidayMaster/index";



//HR / Job Opening
import EditCandidate from "../pages/HR/JobOpeningList/EditCandidate/index";
import JobApplicants from "../pages/HR/JobOpeningList/JobApplicants/index";
import EditJob from "../pages/HR/JobOpeningList/EditJob/index";


//Corporate 
import CorporateLeads from "../pages/Corporate/CorLeads/index";
import Addcorleads from "../pages/Corporate/CorLeads/Addcorleads";
import Editcorleads from "../pages/Corporate/CorLeads/Editcorleads";
import EditCorConvertList from "../pages/Corporate/CorLeads/EditCorConvertList";
import EditCorCloseList from "../pages/Corporate/CorLeads/EditCorCloseList";
import EditRegisterClient from "../pages/Corporate/Registercorporateclient/EditRegisterClient";


import ClientCaseHistoryResolution from "../pages/Corporate/ClientCaseHistoryResolution/index";
import ClientFollowup from "../pages/Corporate/ClientFollowup/index";
import ClientMeetings from "../pages/Corporate/ClientMeetings/index";
import CorporateClients from "../pages/Corporate/Clients/index";
import CorprateClientTasks from "../pages/Corporate/ClientTasks/index";
import CorporateClientSupport from "../pages/Corporate/CorporateClientSupport/index";
import CorporateLeadFollowup from "../pages/Corporate/LeadFollowup/index";


import Bureauhelpdata from "../pages/Corporate/BureauHelp/Bureauhelpdata";
import BureauHelpList from "../pages/Corporate/BureauHelp/BureauHelpList";
import EditBureauHelp from "../pages/Corporate/BureauHelp/EditBureauHelp";


import CorporateServiceplan from "../pages/Corporate/CorporateServiceplan/CorporateServiceplan";
import CorporateserviceplanList from "../pages/Corporate/CorporateServiceplan/CorporateserviceplanList";
import EditCorporateServiceplan from "../pages/Corporate/CorporateServiceplan/EditCorporateServiceplan";
import Registerclient from "../pages/Corporate/Registercorporateclient/Registerclient";
import RegisterClientList from "../pages/Corporate/Registercorporateclient/RegisterClientList";

import ServicesFileUploadManage from "../pages/Corporate/Registercorporateclient/ServicesFileUpload";


//General Dashboard 
import GeneralDashboard from "../pages/General/index";
import UserType from "../pages/General/UserType/index";
import AddUserType from "../pages/General/UserType/AddUserType";
import EditUserType from "../pages/General/UserType/EditUserType";
import User from "../pages/General/User/index";
import AddUser from "../pages/General/User/AddUser";
import EditUser from "../pages/General/User/EditUser";
import Company from "../pages/General/Company/index";
import AddCompany from "../pages/General/Company/AddCompany";
import Branch from "../pages/General/Branch/index";
import AddBranch from "../pages/General/Branch/AddBranch";
import EditBranch from "../pages/General/Branch/EditBranch";
import DocumentType from "../pages/General/DocumentType/index";
import AddDocumentType from "../pages/General/DocumentType/AddDocumentType";
import EditDocumentType from "../pages/General/DocumentType/EditDocumentType";
import Document from "../pages/General/Document/index.js"
import AddDocument from "../pages/General/Document/AddDocument.js"
import EditDocument from "../pages/General/Document/EditDocument.js"
import DepartmentMaster from "../pages/General/DepartmentMaster/index";
import AddDepartment from "../pages/General/DepartmentMaster/AddDepartment";
import EditDepartment from "../pages/General/DepartmentMaster/EditDepartment";
import DesignationMaster from "../pages/General/DesignationMaster/index";
import AddDesignationMaster from "../pages/General/DesignationMaster/AddDesignationMaster";
import EditDesignationMaster from "../pages/General/DesignationMaster/EditDesignationMaster";
import EmployeeType from "../pages/General/EmployeeType/index";
import AddEmployeeType from "../pages/General/EmployeeType/AddEmployeeType";
import EditEmployeeType from "../pages/General/EmployeeType/EditEmployeeType";
import ShiftMaster from "../pages/General/ShiftMaster/index";
import AddShiftMaster from "../pages/General/ShiftMaster/AddShiftMater";
import EditShiftMaster from "../pages/General/ShiftMaster/EditShiftMaster";
import LeaveType from "../pages/General/LeaveType/index";
import CompanyLeaveMaster from "../pages/General/CompanyLeaveMaster/index";
import Allowance from "../pages/General/Allowance/index";
import ProfessionalTax from "../pages/General/ProfessionalTax/index";
import AddProfessionalTax from "../pages/General/ProfessionalTax/AddProfessionalTax";
import EditProfessionalTax from "../pages/General/ProfessionalTax/EditProfessionalTax";
import SalareyScale from "../pages/General/SalareyScale/index";
import PayCalculatedOn from "../pages/General/PayCalculatedOn/index";
import Overtime from "../pages/General/Overtime/index";
import DeductionTime from "../pages/General/DeductionTime/index";
import AddDeductionTime from "../pages/General/DeductionTime/AddDeductionTime";
import EditDeductionTime from "../pages/General/DeductionTime/EditDeductionTime";
import Pincode from "../pages/General/Pincode/index";
import Country from "../pages/General/Country/index";
import AddCountry from "../pages/General/Country/AddCountry";
import EditCountry from "../pages/General/Country/EditCountry";
import State from "../pages/General/State/index";
import AddState from "../pages/General/State/AddState";
import EditState from "../pages/General/State/EditState";
import City from "../pages/General/City/index";
import AddCity from "../pages/General/City/AddCity";
import EditCity from "../pages/General/City/EditCity";
import District from "../pages/General/District/index";
import AddDistrict from "../pages/General/District/AddDistrict";
import EditDistrict from "../pages/General/District/EditDistrict";
import ActionTypeMaster from "../pages/Retail/LeadList/ActionTypeMaster";
import AddActionTypeMaster from "../pages/Retail/LeadList/AddActionTypeMaster";
import EditActionTypeMaster from "../pages/Retail/LeadList/EditActionTypeMaster";
import ServicePackage from "../pages/General/ServicePackage/index";
import AddServicePackage from "../pages/General/ServicePackage/AddServicePackage";
import EditServicePackage from "../pages/General/ServicePackage/EditServicePackage";
import Tehsil from "../pages/General/Tehsil/index";
import AddTehsil from "../pages/General/Tehsil/AddTehsil";
import EditTehsil from "../pages/General/Tehsil/EditTehsil";
import ContactInformation from "pages/General/ContactInformation";

import Activities from "../pages/General/Activities/index";
import AddActivities from "../pages/General/Activities/AddActivities";
import EditActivities from "../pages/General/Activities/EditActivities";

import ListofAttendance from "../pages/Retail/Dashboard/ListofAttendance";
import ListofTodo from "../pages/Retail/Dashboard/ListofTodo";
import ConvertedInq from "../pages/Retail/LeadList/ConvertedInq";
import ClosedLead from "../pages/Retail/LeadList/ClosedLead";
import RegisteredList from "../pages/Retail/LeadList/RegisteredList";
import ManageClient from "../pages/Retail/LeadList/ManageClient";
import ClosedClient from "../pages/Retail/LeadList/ClosedClient";

import ConvertLeadFirm from "../pages/Retail/LeadList/ConvertLeadFirm";

import ClientIndividualInformation from "../pages/Retail/LeadList/ClientIndividualInformation";
import ClientIndividualServiceActivities from "../pages/Retail/LeadList/ClientIndividualServiceActivities";
import LeadInformation from "../pages/Retail/LeadList/LeadInformation";
import ClientActivities from "../pages/Retail/LeadList/ClientActivities";
import ServiceMaster from "../pages/Retail/LeadList/ServiceMaster";
import AddServiceMaster from "../pages/Retail/LeadList/AddServiceMaster";
import EditServiceMaster from "../pages/Retail/LeadList/EditServiceMaster";
import StatusMaster from "../pages/Retail/LeadList/StatusMaster";
import AddStatus from "../pages/Retail/LeadList/AddStatus";
import EditStatus from "../pages/Retail/LeadList/EditStatus";
import ClientAccount from "../pages/Retail/LeadList/ClientAccountIndividual";
import ClientAccountEnroll from "../pages/Retail/LeadList/ClientAccountIndividualenroll";
import ClientAccountFirm from "../pages/Retail/LeadList/ClientAccountFirm";
import ClientAccountFirmEnroll from "../pages/Retail/LeadList/ClientAccountFirmEnroll";
import ConvertLeadIndividual from "../pages/Retail/LeadList/ConvertLeadIndividual";
import ConvertLeadIndividualEnroll from "../pages/Retail/LeadList/ConvertLeadIndividualEnroll";
import ConvertLeadFirmEnroll from "../pages/Retail/LeadList/ConvertLeadFirmEnroll";
import ServicesPackagePayment from "../pages/Retail/LeadList/ServicesPackagePayment";

import RegisteredIndividualDetail from "../pages/Retail/LeadList/RegisteredIndividualDetail";
import RegisteredFirmDetail from "../pages/Retail/LeadList/RegisteredFirmDetail";

import ClosedIndividualDetail from "../pages/Retail/LeadList/ClosedIndividualDetail";
import ClosedFirmDetail from "../pages/Retail/LeadList/ClosedFirmDetail";

import CorConvertedList from "../pages/Corporate/CorLeads/CorConvertedList";

import CorCloselead from "../pages/Corporate/CorLeads/CorCloselead";

import AddLead from "../pages/Retail/AddLead/index.js";
import AddNewInquiry from "../pages/Retail/AddLead/AddNewInquiry.js";
import AllInq from "../pages/Retail/LeadList/AllInq.js";
import ClientDetailIndividual from "../pages/Retail/AddLead/ClientDetailIndividual.js";
import ClientDetailFirmCompany from "../pages/Retail/AddLead/ClientDetailFirmCompany.js";

import ActivityAddMaster from "../pages/General/Master/ActivityAddMaster.js";
import MyFollowUp from "../pages/MyFollowUp/MyFollowUp.js";
import EditFollowUp from "../pages/MyFollowUp/EditFollowUp.js";
import FollowupClientInformation from "../pages/MyFollowUp/FollowupClientInformation.js";
import CorLeadsFollowUps from "../pages/Corporate/CorLeads/CorLeadsFollowUps.js";

import CorporatePaymentDue from "../pages/Corporate/CorLeads/CorporatePaymentDue.js";

import CorporateClientServiceActivities from "../pages/Corporate/Registercorporateclient/CorporateClientServiceActivities";



import ChannelSource from "../pages/General/ChannelSource/index.js";
import AddChannelSource from "../pages/General/ChannelSource/AddChannelSource.js";
import EditChannelSource from "../pages/General/ChannelSource/EditChannelSource.js";

import ChannelSubSource from "../pages/General/ChannelSubSource/index.js"
import AddChannelSubSource from "../pages/General/ChannelSubSource/AddChannelSubSource.js"
import EditChannelSubSource from "../pages/General/ChannelSubSource/EditChannelSubSource.js"

import Priority from "../pages/General/Priority/index";
import AddPriority from "../pages/General/Priority/AddPriority";
import EditPriority from "../pages/General/Priority/EditPriority";

import Tags from "../pages/General/Tags/index";
import AddTags from "../pages/General/Tags/AddTags";
import EditTags from "../pages/General/Tags/EditTags";

import TagsForm from "../pages/General/TagsForm";

import Purpose from "../pages/General/Purpose/index";
import AddPurpose from "../pages/General/Purpose/AddPurpose";
import EditPurpose from "../pages/General/Purpose/EditPurpose";
import AC from "../pages/General/Company/AC";
import EC from "../pages/General/Company/EC";

import ChannelType from "../pages/General/ChannelType/index";
import AddChannelType from "../pages/General/ChannelType/AddChannelType";
import EditChannelType from "../pages/General/ChannelType/EditChannelType";

import Qualification from "../pages/General/Qualification/index";
import AddQualification from "../pages/General/Qualification/AddQualification";
import EditQualification from "../pages/General/Qualification/EditQualification";

import Category from "../pages/General/Category/index";
import AddCategory from "../pages/General/Category/AddCategory";
import EditCategory from "../pages/General/Category/EditCategory";


import Achievement from "../pages/General/Achievement/index";
import AddAchievement from "../pages/General/Achievement/AddAchievement";
import EditAchievement from "../pages/General/Achievement/EditAchievement";

import Testimonial from "../pages/General/Testimony/index";
import AddTestimonial from "../pages/General/Testimony/AddTestimonial";
import EditTestimonial from "../pages/General/Testimony/EditTestimonial";


import FAQ from "../pages/General/FAQ/index";
import AddFAQ from "../pages/General/FAQ/AddFAQ";
import EditFAQ from "../pages/General/FAQ/EditFAQ";


import WebServices from "../pages/General/WebServices/index";
import AddWebServices from "../pages/General/WebServices/AddWebServices";
import EditWebServices from "../pages/General/WebServices/EditWebServices";

import WebServicesDetails from "../pages/General/WebServicesDetails/index";
import AddWebServicesDetails from "../pages/General/WebServicesDetails/AddWebServicesDetails";
import EditWebServicesDetails from "../pages/General/WebServicesDetails/EditWebServicesDetails";

import ContactInquiry from "../pages/General/ContactInquiry/index"
import ServiceInquiry from "../pages/General/ServiceInquiry/index"
import NewsletterInquiry from "..//pages/General/NewsletterInquiry/index"


import AccountType from "../pages/General/AccountType/index";
import AddAccountType from "../pages/General/AccountType/AddAccountType";
import EditAccountType from "../pages/General/AccountType/EditAccountType";

import SuiteFileStatus from "../pages/General/SuiteFileStatus/index";
import AddSuiteFileStatus from "../pages/General/SuiteFileStatus/AddSuiteFileStatus";
import EditSuiteFileStatus from "../pages/General/SuiteFileStatus/EditSuiteFileStatus";

import CreditFacilityStatus from "../pages/General/CreditFacilityStatus/index";
import AddCreditFacilityStatus from "../pages/General/CreditFacilityStatus/AddCreditFacilityStatus";
import EditCreditFacilityStatus from "../pages/General/CreditFacilityStatus/EditCreditFacilityStatus";

import Religion from "../pages/General/Religion/index";
import AddReligion from "../pages/General/Religion/AddReligion";
import EditReligion from "../pages/General/Religion/EditReligion";

import GST from "../pages/General/GST/index";
import AddGST from "../pages/General/GST/AddGST";
import EditGST from "../pages/General/GST/EditGST";

import Occupation from "../pages/General/Occupation/index";
import AddOccupation from "../pages/General/Occupation/AddOccupation";
import EditOccupation from "../pages/General/Occupation/EditOccupation";

import ChannelMaster from "../pages/General/ChannelMaster/index";
import AddChannelMaster from "../pages/General/ChannelMaster/AddChannelMaster";
import EditChannelMaster from "../pages/General/ChannelMaster/EditChannelMaster";

import EditCorLeadFollowups from "../pages/Corporate/CorLeads/EditCorLeadFollowups";

import ManageMOMd from "../pages/Corporate/CorLeads/ManageMOMd";

import MOMList from "../pages/Corporate/CorLeads/MOMList";


import DashboardSettings from "../pages/General/DashboardSettings/index";
import AddDashboardSetting from "../pages/General/DashboardSettings/AddDashboardSetting";
import EditDashboardSetting from "../pages/General/DashboardSettings/EditDashboardSetting";

import Tasks from "../pages/Tasks/index";

import ChangePassword from "../pages/General/ChangePassword/index";


import bureaucategoryList from "../pages/General/Bureaucategory/bureaucategoryList";
import AddBureaucategory from "../pages/General/Bureaucategory/AddBureaucategory";
import EditBureaucategory from "../pages/General/Bureaucategory/EditBureaucategory";


import BureauSubcategoryList from "../pages/General/BureauSubcategory/BureauSubcategoryList";
import AddBureauSubcategory from "../pages/General/BureauSubcategory/AddBureauSubcategory";
import EditBureauSubcategory from "../pages/General/BureauSubcategory/EditBureauSubcategory";


import ManageInfo from "../pages/General/m/ManageInfo";
import ManageSerInfo from "../pages/Corporate/CorLeads/ManageSerInfo";

import SerPackageDetail from "../pages/Corporate/Registercorporateclient/SerPackageDetail";

import CorporateServiceActivities from "../pages/Corporate/CorporateServiceplan/CorporateServiceActivities";
import CorporateServiceActivitiesList from "../pages/Corporate/CorporateServiceplan/CorporateServiceActivitiesList";

import ClientServicesPackageRegistration from "../pages/Retail/LeadList/ClientServicesPackageRegistration";
import ClientServicesPackagePayment from "../pages/Retail/LeadList/ClientServicesPackagePayment";

import ClientFirmServicesPackageRegistration from "../pages/Retail/LeadList/ClientFirmServicesPackageRegistration";
import ClientFirmServicesPackagePayment from "../pages/Retail/LeadList/ClientFirmServicesPackagePayment";

import AddCorporateServiceActivities from "../pages/Corporate/CorporateServiceplan/AddCorporateServiceActivities";

import ServiceMonthlyDues from "../pages/Corporate/Registercorporateclient/ServiceMonthlyDues";

import Bureauhelplink from "../pages/General/Bureauhelplink/index";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/ToDo", component: ToDo },
  { path: "/ToDoView", component: ToDoView },
  { path: "/AddTask", component: AddTask },
  { path: "/AddTask", component: AddTask },
  { path: "/SupportTicketDetail", component: SupportTicketDetail },
  { path: "/SupportTicket", component: SupportTicket },
  { path: "/ListofAttendance", component: ListofAttendance },
  { path: "/ListofTodo", component: ListofTodo },
  { path: "/ConvertedInq", component: ConvertedInq },
  { path: "/ClosedLead", component: ClosedLead },
  { path: "/RegisteredList", component: RegisteredList },
  { path: "/ManageClient", component: ManageClient },
  { path: "/ClosedClient", component: ClosedClient },
  { path: "/ClientIndividualInformation", component: ClientIndividualInformation },
  { path: "/ClientIndividualServiceActivities", component: ClientIndividualServiceActivities },
  { path: "/LeadInformation", component: LeadInformation },
  { path: "/ClientActivities", component: ClientActivities },
  { path: "/ServiceMaster", component: ServiceMaster },
  { path: "/LeadList/AddServiceMaster", component: AddServiceMaster },
  { path: "/LeadList/EditServiceMaster/:id", component: EditServiceMaster },
  { path: "/LeadList/AddActionTypeMaster", component: AddActionTypeMaster },
  { path: "/LeadList/EditActionTypeMaster/:id", component: EditActionTypeMaster },
  { path: "/StatusMaster", component: StatusMaster },
  { path: "/LeadList/AddStatus", component: AddStatus },
  { path: "/LeadList/EditStatus/:id", component: EditStatus },
  { path: "/ClientAccount", component: ClientAccount },
  { path: "/ClientAccountEnroll", component: ClientAccountEnroll },
  { path: "/ClientAccountFirm", component: ClientAccountFirm },
  { path: "/ClientAccountFirmEnroll", component: ClientAccountFirmEnroll },
  { path: "/ConvertLeadIndividual", component: ConvertLeadIndividual },
  { path: "/ConvertLeadIndividualEnroll", component: ConvertLeadIndividualEnroll },
  { path: "/ConvertLeadFirm", component: ConvertLeadFirm },
  { path: "/ConvertLeadFirmEnroll", component: ConvertLeadFirmEnroll },

  { path: "/RegisteredIndividualDetail", component: RegisteredIndividualDetail },
  { path: "/RegisteredFirmDetail", component: RegisteredFirmDetail },

  { path: "/ClosedIndividualDetail", component: ClosedIndividualDetail },
  { path: "/ClosedFirmDetail", component: ClosedFirmDetail },

  { path: "/ServicesPackagePayment", component: ServicesPackagePayment },

  { path: "/ActionTypeMaster", component: ActionTypeMaster },
  { path: "/TaskRetail", component: TaskRetail },
  { path: "/TaskDetail", component: TaskDetail },
  { path: "/AddLead", component: AddLead },
  { path: "/AddNewInquiry", component: AddNewInquiry },
  { path: "/AllInq", component: AllInq },
  { path: "/ClientDetailIndividual", component: ClientDetailIndividual },
  { path: "/ClientDetailFirmCompany", component: ClientDetailFirmCompany },

  { path: "/ActivityAddMaster", component: ActivityAddMaster },
  { path: "/MyFollowUp", component: MyFollowUp },
  { path: "/EditFollowUp", component: EditFollowUp },

  { path: "/EditCorLeadFollowups", component: EditCorLeadFollowups },

  { path: "/FollowupClientInformation", component: FollowupClientInformation },
  { path: "/repeater", component: repeater },
  { path: "/NestedRetail", component: NestedRetail },
  { path: "/EditPartnerDetails", component: EditPartnerDetails },
  { path: "/Mobile", component: Mobile },

  { path: "/ChannelSource", component: ChannelSource },
  { path: "/ChannelSource/AddChannelSource", component: AddChannelSource },
  { path: "/ChannelSource/EditChannelSource/:id", component: EditChannelSource },

  { path: "/ChannelSubSource", component: ChannelSubSource },
  { path: "/ChannelSubSource/AddChannelSubSource", component: AddChannelSubSource },
  { path: "/ChannelSubSource/EditChannelSubSource/:id", component: EditChannelSubSource },

  { path: "/Tags", component: Tags },
  { path: "/Tags/AddTags", component: AddTags },
  { path: "/Tags/EditTags/:id", component: EditTags },

  { path: "/GST", component: GST },
  { path: "/GST/AddGST", component: AddGST },
  { path: "/GST/EditGST/:id", component: EditGST },

  { path: "/Priority", component: Priority },
  { path: "/Priority/AddPriority", component: AddPriority },
  { path: "/Priority/EditPriority/:id", component: EditPriority },

  { path: "/Activities", component: Activities },
  { path: "/Activities/AddActivities", component: AddActivities },
  { path: "/Activities/EditActivities/:id", component: EditActivities },

  { path: "/TagsForm", component: TagsForm },

  { path: "/UserVisitEntry", component: UserVisitEntry },
  { path: "/UserVisitEntry/AddUserVisitEntry", component: AddUserVisitEntry },

  { path: "/SerPackageDetail", component: SerPackageDetail },

  { path: "/ServiceMonthlyDues", component: ServiceMonthlyDues },

  { path: "/Bureauhelplink", component: Bureauhelplink },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartist-charts", component: ChartistChart },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },
  { path: "/charts-knob", component: ChartsKnob },
  { path: "/re-charts", component: ReCharts },
  //General  
  { path: "/GeneralDashboard", component: GeneralDashboard },
  { path: "/UserType", component: UserType },
  { path: "/UserType/AddUserType", component: AddUserType },
  { path: "/UserType/EditUserType/:id", component: EditUserType },

  { path: "/CorLeadsFollowUps", component: CorLeadsFollowUps },
  { path: "/CorporatePaymentDue", component: CorporatePaymentDue },


  { path: "/User", component: User },
  { path: "/User/AddUser", component: AddUser },
  { path: "/User/EditUser/:id", component: EditUser },

  { path: "/ServicePackage", component: ServicePackage },
  { path: "/ServicePackage/AddServicePackage", component: AddServicePackage },
  { path: "/ServicePackage/EditServicePackage/:id", component: EditServicePackage },


  { path: "/Company", component: Company },
  { path: "/Company/AddCompany", component: AddCompany },
  { path: "/Branch", component: Branch },
  { path: "/Branch/AddBranch", component: AddBranch },
  { path: "/Branch/EditBranch/:id", component: EditBranch },
  { path: "/DocumentType", component: DocumentType },
  { path: "/DocumentType/AddDocumentType", component: AddDocumentType },
  { path: "/DocumentType/EditDocumentType/:id", component: EditDocumentType },
  { path: "/Document", component: Document },
  { path: "/Document/AddDocument", component: AddDocument },
  { path: "/Document/EditDocument/:id", component: EditDocument },
  { path: "/DepartmentMaster", component: DepartmentMaster },
  { path: "/DepartmentMaster/AddDepartment", component: AddDepartment },
  { path: "/DepartmentMaster/EditDepartment/:id", component: EditDepartment },
  { path: "/DesignationMaster", component: DesignationMaster },
  { path: "/DesignationMaster/AddDesignationMaster", component: AddDesignationMaster },
  { path: "/DesignationMaster/EditDesignationMaster/:id", component: EditDesignationMaster },
  { path: "/EmployeeType", component: EmployeeType },
  { path: "/EmployeeType/AddEmployeeType", component: AddEmployeeType },
  { path: "/EmployeeType/EditEmployeeType/:id", component: EditEmployeeType },

  { path: "/ShiftMaster", component: ShiftMaster },
  { path: "/ShiftMaster/AddShiftMaster", component: AddShiftMaster },
  { path: "/ShiftMaster/EditShiftMaster", component: EditShiftMaster },
  { path: "/LeaveType", component: LeaveType },
  { path: "/CompanyLeaveMaster", component: CompanyLeaveMaster },
  { path: "/Allowance", component: Allowance },
  { path: "/ProfessionalTax", component: ProfessionalTax },
  { path: "/ProfessionalTax/AddProfessionalTax", component: AddProfessionalTax },
  { path: "/ProfessionalTax/EditProfessionalTax/:id", component: EditProfessionalTax },
  { path: "/SalareyScale", component: SalareyScale },
  { path: "/PayCalculatedOn", component: PayCalculatedOn },
  { path: "/Overtime", component: Overtime },
  { path: "/DeductionTime", component: DeductionTime },
  { path: "/DeductionTime/AddDeductionTime", component: AddDeductionTime },
  { path: "/DeductionTime/EditDeductionTime/:id", component: EditDeductionTime },

  { path: "/Qualification", component: Qualification },
  { path: "/Qualification/AddQualification", component: AddQualification },
  { path: "/Qualification/EditQualification/:id", component: EditQualification },

  { path: "/Category", component: Category },
  { path: "/Category/AddCategory", component: AddCategory },
  { path: "/Category/EditCategory/:id", component: EditCategory },

  { path: "/Achivement", component: Achievement },
  { path: "/Achievement/AddAchievement", component: AddAchievement },
  { path: "/Achievement/EditAchievement/:id", component: EditAchievement },

  { path: "/Testimonial", component: Testimonial },
  { path: "/Testimony/AddTestimonial", component: AddTestimonial },
  { path: "/Testimony/EditTestimonial/:id", component: EditTestimonial },

  { path: "/WebServices", component: WebServices },
  { path: "/WebServices/AddWebServices", component: AddWebServices },
  { path: "/WebServices/EditWebServices/:id", component: EditWebServices },

  { path: "/WebServicesDetails", component: WebServicesDetails },
  { path: "/WebServicesDetails/AddWebServicesDetails", component: AddWebServicesDetails },
  { path: "/WebServicesDetails/EditWebServicesDetails/:id", component: EditWebServicesDetails },

  { path: "/ContactInquiry", component: ContactInquiry },
  { path: "/ServiceInquiry", component: ServiceInquiry },
  { path: "/NewsletterInquiry", component: NewsletterInquiry },


  { path: "/FAQ", component: FAQ },
  { path: "/FAQ/AddFAQ", component: AddFAQ },
  { path: "/FAQ/EditFAQ/:id", component: EditFAQ },


  { path: "/AccountType", component: AccountType },
  { path: "/AccountType/AddAccountType", component: AddAccountType },
  { path: "/AccountType/EditAccountType/:id", component: EditAccountType },

  { path: "/SuiteFileStatus", component: SuiteFileStatus },
  { path: "/SuiteFileStatus/AddSuiteFileStatus", component: AddSuiteFileStatus },
  { path: "/SuiteFileStatus/EditSuiteFileStatus/:id", component: EditSuiteFileStatus },

  { path: "/CreditFacilityStatus", component: CreditFacilityStatus },
  { path: "/CreditFacilityStatus/AddCreditFacilityStatus", component: AddCreditFacilityStatus },
  { path: "/CreditFacilityStatus/EditCreditFacilityStatus/:id", component: EditCreditFacilityStatus },

  { path: "/CorporateClientServiceActivities", component: CorporateClientServiceActivities },

  { path: "/Religion", component: Religion },
  { path: "/Religion/AddReligion", component: AddReligion },
  { path: "/Religion/EditReligion/:id", component: EditReligion },

  { path: "/Occupation", component: Occupation },
  { path: "/Occupation/AddOccupation", component: AddOccupation },
  { path: "/Occupation/EditOccupation/:id", component: EditOccupation },

  { path: "/ChannelMaster", component: ChannelMaster },
  { path: "/ChannelMaster/AddChannelMaster", component: AddChannelMaster },
  { path: "/ChannelMaster/EditChannelMaster/:id", component: EditChannelMaster },

  { path: "/Pincode", component: Pincode },
  { path: "/Country", component: Country },
  { path: "/Country/AddCountry", component: AddCountry },
  { path: "/Country/EditCountry", component: EditCountry },
  { path: "/State", component: State },
  { path: "/State/AddState", component: AddState },
  { path: "/State/EditState/:id", component: EditState },
  { path: "/City", component: City },
  { path: "/City/AddCity", component: AddCity },
  { path: "/City/EditCity/:id", component: EditCity },
  { path: "/District", component: District },
  { path: "/District/AddDistrict", component: AddDistrict },
  { path: "/District/EditDistrict/:id", component: EditDistrict },

  { path: "/Tehsil", component: Tehsil },
  { path: "/Tehsil/AddTehsil", component: AddTehsil },
  { path: "/Tehsil/EditTehsil/:id", component: EditTehsil },

  { path: "/ContactInformation", component: ContactInformation },

  { path: "/Service", component: Service },
  { path: "/Resolution", component: Resolution },
  { path: "/Services", component: Services },
  { path: "/ServiceDetail", component: ServiceDetail },


  //Corporate  
  { path: "/CorporateLeads", component: CorporateLeads },
  { path: "/CorLeads/Addcorleads", component: Addcorleads },
  { path: "/CorLeads/Editcorleads/:id", component: Editcorleads },
  { path: "/CorLeads/EditCorConvertList/:id", component: EditCorConvertList },
  { path: "/CorLeads/EditCorCloseList/:id", component: EditCorCloseList },

  { path: "/EditRegisterClient", component: EditRegisterClient },

  { path: "/ClientCaseHistoryResolution", component: ClientCaseHistoryResolution },
  { path: "/ClientFollowup", component: ClientFollowup },
  { path: "/ClientMeetings", component: ClientMeetings },
  { path: "/CorporateClients", component: CorporateClients },
  { path: "/CorprateClientTasks", component: CorprateClientTasks },
  { path: "/CorporateClientSupport", component: CorporateClientSupport },
  { path: "/CorporateLeadFollowup", component: CorporateLeadFollowup },
  { path: "/ManageMOMd/:id", component: ManageMOMd },
  { path: "/MOMList", component: MOMList },
  { path: "/Bureauhelpdata", component: Bureauhelpdata },
  { path: "/BureauHelpList", component: BureauHelpList },
  { path: "/BureauHelp/EditBureauHelp/:Id", component: EditBureauHelp },

  { path: "/CorporateServiceplan", component: CorporateServiceplan },
  { path: "/CorporateserviceplanList", component: CorporateserviceplanList },
  { path: "/CorporateServiceplan/EditCorporateServiceplan/:id", component: EditCorporateServiceplan },
  { path: "/Registercorporateclient/Registerclient/:id", component: Registerclient },
  { path: "/RegisterClientList", component: RegisterClientList },
  { path: "/ServicesFileUploadManage", component: ServicesFileUploadManage },


  { path: "/bureaucategoryList", component: bureaucategoryList },
  { path: "/AddBureaucategory", component: AddBureaucategory },
  { path: "/Bureaucategory/EditBureaucategory/:id", component: EditBureaucategory },

  { path: "/BureauSubcategoryList", component: BureauSubcategoryList },
  { path: "/AddBureauSubcategory", component: AddBureauSubcategory },
  { path: "/BureauSubcategory/EditBureauSubcategory/:id", component: EditBureauSubcategory },


  { path: "/Purpose", component: Purpose },
  { path: "/Purpose/AddPurpose", component: AddPurpose },
  { path: "/Purpose/EditPurpose/:id", component: EditPurpose },

  { path: "/Company/AC", component: AC },
  { path: "/Company/EC/:id", component: EC },

  { path: "/ChannelType", component: ChannelType },
  { path: "/ChannelType/AddChannelType", component: AddChannelType },
  { path: "/ChannelType/EditChannelType/:id", component: EditChannelType },


  { path: "/DashboardSettings", component: DashboardSettings },

  { path: "/AddDashboardSetting", component: AddDashboardSetting },
  { path: "/EditDashboardSetting", component: EditDashboardSetting },

  { path: "/Tasks", component: Tasks },

  { path: "/ChangePassword", component: ChangePassword },

  { path: "/ManageInfo", component: ManageInfo },

  { path: "/CorLeads/ManageSerInfo", component: ManageSerInfo },

  { path: "/CorporateServiceActivities", component: CorporateServiceActivities },

  { path: "/CorporateServiceActivitiesList", component: CorporateServiceActivitiesList },
  //kc-page 

  { path: "/kc-email-inbox", component: kcEmailInbox },
  { path: "/kc-email", component: kcEmail },
  { path: "/kc-detail", component: kcDetail },
  { path: "/kc-one", component: kcOne },
  { path: "/kc-two", component: kcTwo },
  { path: "/kc-three", component: kcThree },
  { path: "/kc-four", component: kcFour },
  { path: "/kc-five", component: kcFive },
  { path: "/kc-six", component: kcSix },
  { path: "/kc-seven", component: kcSeven },
  { path: "/kc-eight", component: kcEight },
  { path: "/kc-nine", component: kcNine },
  { path: "/kc-ten", component: kcTen },
  { path: "/kc-ele", component: kcEle },
  { path: "/kc-twe", component: kcTwe },
  { path: "/kc-thr", component: kcThr },
  { path: "/kc-fort", component: kcFort },
  { path: "/kc-todo", component: kcTodo },
  { path: "/kc-todo1", component: kcTodo1 },
  { path: "/kc-todo2", component: kcTodo2 },
  { path: "/kc-todo3", component: kcTodo3 },
  { path: "/kc1", component: kc1 },
  { path: "/kc2", component: kc2 },
  { path: "/kc3", component: kc3 },
  { path: "/kc4", component: kc4 },
  { path: "/kc5", component: kc5 },
  { path: "/kc6", component: kc6 },
  { path: "/kc7", component: kc7 },
  { path: "/kc8", component: kc8 },
  { path: "/kc9", component: kc9 },
  { path: "/kc10", component: kc10 },
  { path: "/kc11", component: kc11 },
  { path: "/kc12", component: kc12 },
  { path: "/kc13", component: kc13 },
  { path: "/kc14", component: kc14 },
  { path: "/kc15", component: kc15 },
  { path: "/kc16", component: kc16 },
  { path: "/kc17", component: kc17 },
  { path: "/kc18", component: kc18 },
  { path: "/kc19", component: kc19 },
  { path: "/kc20", component: kc20 },
  { path: "/kc21", component: kc21 },
  { path: "/kc22", component: kc22 },
  { path: "/kc23", component: kc23 },
  { path: "/kc24", component: kc24 },
  { path: "/kc25", component: kc25 },
  { path: "/kc26", component: kc26 },
  { path: "/kc27", component: kc27 },
  { path: "/kc28", component: kc28 },
  { path: "/kc29", component: kc29 },
  { path: "/kc30", component: kc30 },
  { path: "/kc31", component: kc31 },
  { path: "/kc32", component: kc32 },
  { path: "/kc33", component: kc33 },
  { path: "/kc34", component: kc34 },
  { path: "/kc35", component: kc35 },
  { path: "/kc36", component: kc36 },
  { path: "/kc37", component: kc37 },
  { path: "/kc38", component: kc38 },



  //Retail 
  { path: "/ClientList", component: ClientList },
  { path: "/LeadList", component: LeadList },
  { path: "/CorConvertedList", component: CorConvertedList },
  { path: "/CorCloselead", component: CorCloselead },
  { path: "/EnrollPendingLeadList", component: EnrollPendingLeadList },
  { path: "/QuickInquiryList", component: QuickInquiryList },
  { path: "/QuickInquiryForm", component: QuickInquiryForm },
  { path: "/ToDoList", component: ToDoList },
  { path: "/ChannelList", component: ChannelList },
  { path: "/ChannelPartnerList", component: ChannelPartnerList },
  { path: "/FollowUpList", component: FollowUpList },
  { path: "/EditClient", component: EditClient },
  { path: "/RetailDashboard", component: RetailDashboard },
  { path: "/LeadPaymentDue", component: LeadPaymentDue },

  { path: "/InvoiceManage", component: InvoiceManage },
  { path: "/InvoiceManage/AddInvoice", component: AddInvoice },
  { path: "/InvoiceManage/EditInvoice", component: EditInvoice },

  //Accounts  
  { path: "/CustomerClientManagement", component: CustomerClientManagement },
  { path: "/CustomerInvoiceList", component: CustomerInvoiceList },
  { path: "/CustomerInvoicePreview", component: CustomerInvoicePreview },
  { path: "/GSTLedger", component: GSTLedger },
  { path: "/GSTReportGenerator", component: GSTReportGenerator },
  { path: "/ProfitLossLedger", component: ProfitLossLedger },
  { path: "/TCSLedger", component: TCSLedger },
  { path: "/TDSLedger", component: TDSLedger },
  { path: "/VendorInvoiceList", component: VendorInvoiceList },
  { path: "/VendorList", component: VendorList },
  { path: "/InvoicePaymentHistory", component: InvoicePaymentHistory },
  { path: "/AddVendorInvoice", component: AddVendorInvoice },
  { path: "/VendorPreviewInvoice", component: VendorPreviewInvoice },


  //HR 
  { path: "/BulkAttendance", component: BulkAttendance },
  { path: "/CVBank", component: CVBank },
  { path: "/HRDashboard", component: HRDashboard },
  { path: "/JobApplication", component: JobApplication },
  { path: "/JobOpeningList", component: JobOpeningList },
  { path: "/ManageAttendance", component: ManageAttendance },
  { path: "/ManageLeaveType", component: ManageLeaveType },
  { path: "/ManageSalaryRegister", component: ManageSalaryRegister },
  { path: "/ManageSalaryScale", component: ManageSalaryScale },
  { path: "/ManageSalarySlip", component: ManageSalarySlip },
  { path: "/ShiftChangeList", component: ShiftChangeList },
  { path: "/TimeChangeList", component: TimeChangeList },
  { path: "/GenerateSalary", component: GenerateSalary },
  { path: "/HolidayList", component: HolidayList },
  { path: "/HolidayMaster", component: HolidayMaster },

  //HR / Job Opening
  { path: "/EditCandidate", component: EditCandidate },
  { path: "/EditJob", component: EditJob },
  { path: "/JobApplicants", component: JobApplicants },









  //Crypto





  //profile
  { path: "/profile", component: UserProfile },




  //Ecommerce
  { path: "/ecommerce-products/:id", component: EcommerceProducts },
  { path: "/ecommerce-products", component: EcommerceProducts },
  { path: "/ecommerce-product-details/:id", component: EcommerceProductDetail },

  { path: "/ecommerce-orders", component: EcommerceOrders },
  { path: "/ecommerce-customers", component: EcommerceCustomers },
  { path: "/ecommerce-cart", component: EcommerceCart },
  { path: "/ecommerce-checkout", component: EcommerceCheckout },
  { path: "/ecommerce-shops", component: EcommerceShops },
  { path: "/ecommerce-add-product", component: EcommerceAddProduct },
  // Contacts
  { path: "/contacts-grid", component: ContactsGrid },
  { path: "/contacts-list", component: ContactsList },
  { path: "/contacts-profile", component: ContactsProfile },

  { path: "/ClientServicesPackageRegistration", component: ClientServicesPackageRegistration },
  { path: "/ClientServicesPackagePayment", component: ClientServicesPackagePayment },

  { path: "/ClientFirmServicesPackageRegistration", component: ClientFirmServicesPackageRegistration },
  { path: "/ClientFirmServicesPackagePayment", component: ClientFirmServicesPackagePayment },

  { path: "/AddCorporateServiceActivities", component: AddCorporateServiceActivities },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/ForgetPassword", component: ForgetPwd },
  { path: "/register", component: Register },

];

export { authProtectedRoutes, publicRoutes };