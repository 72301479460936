import React, {  Fragment, useState ,useEffect} from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import jsPDF from "jspdf";
import 'jspdf-autotable';

const OnSort =()=> {  
  let columns = [
    {
      d: "#",
      text: "#",
      cell: (record, index) => {
          let RowIndex = index+1;
          return (
              <Fragment>
                {RowIndex}
              </Fragment>
          );
      }
    },
    {
        d: "FullName",
        text: "Full Name",
        cell: (record, index) => {
            let FullName = record["FullName"];
            let LeadCode = record["LeadCode"];
            let Tag = record["Tag"]
            return (
                  // <a onClick={RedirectLeadDetail.bind(this, record)} className="text-heading nowrap">
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <img
                          src="/static/media/avatar-1.3921191a.jpg"
                          alt=""
                          className="img-thumbnail img-leads rounded-circle"
                        />
                      </div>
                      <div className="flex-grow-1 ms-1">
                        <p className="mb-1">{FullName}</p>
                        <p className="text-muted mb-0">{LeadCode}</p>
                        <span className="badge badge-soft-primary px-2 py-1">{Tag}</span>
                      </div>
                    </div>
                  //</a>
            );
        }
    },
	  {
      d: "Contact Number",
      text: "Contact Number ",
      cell: (record, index) => {          
          let ContactNumber = record["MobileNo"];
          return (
              <Fragment>
                <p className="mb-1 text-wrap1">{ContactNumber}</p>
              </Fragment>
          );
      }
    },
	  {
      d: "Email Address",
      text: "Email Address",
      cell: (record, index) => {
          let VEmailAddress = record["EmailAddress"];
          return (
              <Fragment>
                <p className="mb-1 text-wrap1">{VEmailAddress}</p>
              </Fragment>
          );
      }
    },
    {
      d: "Date",
      text: "Date",
      cell: (record, index) => {
          let ScheduledDate = record["ScheduledDate"];
          return (
              <Fragment>
                {ScheduledDate}
              </Fragment>
          );
      }
    },
    {
      d: "Mode",
      text: "Mode",
      cell: (record, index) => {
          let Mode = record["Mode"];
          return (
              <Fragment>
                {Mode}
              </Fragment>
          );
      }
    },
    {
      d: "Agenda",
      text: "Agenda",
      cell: (record, index) => {
          let VAgenda = record["Agenda"];
          return (
              <Fragment>
                {VAgenda}
              </Fragment>
          );
      }
    },
    // {
    //   d: "Remark",
    //   text: "Remark",
    //   cell: (record, index) => {
    //       let Remark = record["Remark"];
    //       return (
    //           <Fragment>
    //             {Remark}
    //           </Fragment>
    //       );
    //   }
    // },
    // {
    //   d: "Status",
    //   text: "Status ",
    //   cell: (record, index) => {
        
    //       let LeadStatus = record["LeadStatus"];
    //       let LeadStatusClass = record["LeadStatusClass"];
    //       return (
    //           <Fragment>
    //             <span className={LeadStatusClass}>{LeadStatus}</span>
    //           </Fragment>
    //       );
      
    //   }
    // },
   {
        d: "action",
        text: "Action",
        cell: (record, index) => {

        
            return (
                <Fragment>
                    <a className="btn btn-primary btn-sm float-end font-14" href="#" onClick={RedirectAddNewFollowup.bind(this, record)}><i className="mdi mdi-plus align-middle me-1"></i> Add Folollwup</a>
                    {/* <a className={IconrenderSwitchColor(record)}><i className={IconrenderSwitch(record)}></i></a> */}
                </Fragment>
            );
        }
    }
];
let config = {
  page_size: 10,
  length_menu: [10, 20, 50],
  show_filter: true,
  show_pagination: true,
  filename: "restaurents",
  button: {
      excel: true,
      print: true,
      csv: true
  }
}
const [records ,setRecords] =useState([])
let history = useHistory();

    function IconrenderSwitch(param) {
      let UTFlag=param["Flag"];
      switch(UTFlag) {
        case 'A':
          return 'mdi mdi-close font-size-18';
        default:
          return 'mdi mdi-check-all font-size-18';
      }
    }

    function  IconrenderSwitchColor(param) {
      let UTFlag=param["Flag"];
      switch(UTFlag) {
        case 'A':
          return 'text-danger';
        default:
          return 'text-success';
      }
    }

   function IconrenderSwitch(param) {
      let UTFlag=param["Flag"];
      switch(UTFlag) {
        case 'A':
          return 'mdi mdi-close font-size-18';
        default:
          return 'mdi mdi-check-all font-size-18';
      }
    }

    function  IconrenderSwitchColor(param) {
      let UTFlag=param["Flag"];
      switch(UTFlag) {
        case 'A':
          return 'text-danger';
        default:
          return 'text-success';
      }
    }

    useEffect(() => {
      fetch(global.APIURL+"/WebFollowupList")
      .then((res) => res.json())
      .then((json) => {
        setRecords(json)
          
      })
    });
    
   
    const onSort = (column, records, sortOrder) => {
      return orderBy(records, [column], [sortOrder]);
    }

    // const RedirectLeadDetail = (record) => {
    //   let id = record["LeadId"]
    //   localStorage.setItem('FollowupLeadId', id);
    //   window.location.href = "/FollowupClientInformation";
    // }


    const RedirectAddNewFollowup = (record) => {
      let VFLeadId = record["LeadId"]
      let VFollowUpId = record["FollowUpId"]
      localStorage.setItem('FollowupLeadId', VFLeadId);
      localStorage.setItem('FollowUpId', VFollowUpId);
      window.location.href = "/EditFollowUp";
    }

    const exportPDF=()=>{

      const unit = "pt";
      const size = "A2"; 
      const orientation = "portrait"; 
  
      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);
  
      doc.setFontSize(15);
      const title = "Follow Up List Report";
      const headers = [["#","FullName","Lead Code","Contact Number","Email","Scheduled Date","Mode","Agenda"]];
  
      const data = records.map((item,index)=> [index+1,item.FullName,item.LeadCode,item.MobileNo,item.EmailAddress,item.ScheduledDate,item.Mode,item.Agenda]);
  
      let content = {
        startY: 50,
        head: headers,
        body: data
      };
  
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save("FollowUpListReport.pdf")
    }

    return (
      <div className="page-content">
        <MetaTags>
          <title>FollowUp List | KC Admin Group</title>
        </MetaTags>
     
        <div className="container-fluid">
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
              <CardTitle className="h4">FollowUp List</CardTitle>
                <a  href="/" className="me-2">
                  Home
                </a>{" "}
                <span>/</span>
                <span className="ms-2">FollowUp List</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
              {/* <a className="btn btn-primary btn-sm float-end font-14 me-2" href="/FollowUp/AddFollowUp">
                <i className="mdi mdi-plus align-middle me-1"></i>Add New
              </a> */}
             
              <ReactHTMLTableToExcel
              className="btn btn-primary btn-sm float-end font-14 me-2"
              table="emp"
              filename="FollowUpReport"
              sheet="Sheet"
              buttonText="Export Excel" />
               <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
            </Col>
          </Row>

          <Row>
          <div>
            <table id="emp" className="table" hidden="hidden">
              <thead>
                <tr>
                <th>#</th>
                  <th>Full Name</th>
                  <th>Lead Code</th>                
                  <th>Contact Number</th>                 
                  <th>Email</th>   
                  <th>Scheduled Date</th>              
                  <th>Mode</th>
                  <th>Agenda</th>
                </tr>
              </thead>
              <tbody>              {
                records.map((item, index) => {
                  return <tr key={index}>
                    <td> {index +1} </td>
                    <td >{item.FullName}</td>
                    <td>{item.LeadCode}</td>                                                        
                    <td>{item.MobileNo}</td>
                    <td >{item.EmailAddress}</td>
                    <td>{item.ScheduledDate}</td>
                    <td >{item.Mode}</td>
                   <td >{item.Agenda}</td>
                  </tr>
                })
              }
              </tbody>
            </table>           
          </div>
        </Row>

          <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-lg-12">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort}/>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
        </div>
      </div>
    );
}

export default OnSort;