import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";

class FormRepeater extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      rows1: [],
    };
  }

  handleAddRow = () => {
    const item = {
      name: "",
    };
    this.setState({
      rows: [...this.state.rows, item],
    });
  };

  handleAddRowNested = () => {
    const item1 = {
      name1: "",
    };
    this.setState({
      rows1: [...this.state.rows1, item1],
    });
  };
  handleRemoveRow = (e, idx) => {
    if (idx === "01") {
      document.getElementById("addr" + idx).style.display = "block";
    } else if (typeof idx != "undefined") {
      document.getElementById("addr" + idx).style.display = "none";
    }
  };
  handleRemoveRowNested = (e, idx) => {
    document.getElementById("nested" + idx).style.display = "none";
  };

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>
            Form Repeater | KC Admin Group
          </title>
        </MetaTags>

        <Row>
          <CardTitle className="h6 mb-3 mt-3">Employee Specific</CardTitle>
          <Col lg="12">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr id="addr01" key="">
                  <td>
                    <Form className="repeater" encType="multipart/form-data">
                      <div data-repeater-list="group-a">
                        <Row data-repeater-item>
                          <Col lg="2" className="mb-3">
                            <Label htmlFor="name">Allowance Title</Label>
                            <Input
                              type="text"
                              id="name"
                              name="untyped-input"
                              className="form-control"
                              placeholder="Enter Your Name"
                            />
                          </Col>

                          <Col lg="2" className="mb-3">
                            <Label htmlFor="email">Value Type</Label>
                            <select id="inputState" className="form-select">
                              <option selected>Numeric</option>
                              <option>...</option>
                            </select>
                          </Col>

                          <Col lg="2" className="mb-3">
                            <Label htmlFor="name">Apply On</Label>
                            <select id="inputState" className="form-select">
                              <option selected>Gross</option>
                              <option>Basic</option>
                            </select>
                          </Col>

                          <Col lg="2" className="mb-3">
                            <Label htmlFor="resume">Percentage Value</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder=""
                            />
                          </Col>

                          <Col lg="2" className="mb-3">
                            <Label htmlFor="resume">Amount Value</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder=""
                            />
                          </Col>
                          {/* <Col lg="2" className="align-self-center">
                            <div className="d-grid">
                              <Button
                                onClick={e => this.handleRemoveRow(e, "01")}
                                color="primary"
                                style={{ width: "100%" }}
                                block
                              >
                                {" "}
                                Delete
                              </Button>
                            </div>
                          </Col> */}
                        </Row>
                      </div>
                    </Form>
                  </td>
                </tr>

                {this.state.rows.map((item, idx) => (
                  <tr id={"addr" + idx} key={idx}>
                    <td>
                      <Form
                        className="repeater mt-3"
                        encType="multipart/form-data"
                      >
                        <div data-repeater-list="group-a">
                          <Row data-repeater-item>
                            <Col lg="2" className="mb-3">
                              <Label htmlFor="name">Allowance Title</Label>
                              <Input
                                type="text"
                                id="name"
                                name="untyped-input"
                                className="form-control"
                                placeholder="Enter Your Name"
                              />
                            </Col>

                            <Col lg="2" className="mb-3">
                              <Label htmlFor="email">Value Type</Label>
                              <select id="inputState" className="form-select">
                                <option selected>Numeric</option>
                                <option>...</option>
                              </select>
                            </Col>

                            <Col lg="2" className="mb-3">
                              <Label htmlFor="name">Apply On</Label>
                              <select id="inputState" className="form-select">
                                <option selected>Gross</option>
                                <option>Basic</option>
                              </select>
                            </Col>

                            <Col lg="2" className="mb-3">
                              <Label htmlFor="resume">Percentage Value</Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-InputCity"
                                placeholder=""
                              />
                            </Col>

                            <Col lg="2" className="mb-3">
                              <Label htmlFor="resume">Amount Value</Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-InputCity"
                                placeholder=""
                              />
                            </Col>
                            <Col
                              lg="2"
                              className="form-group align-self-center"
                            >
                              <Button
                                onClick={e => this.handleRemoveRow(e, idx)}
                                color="primary"
                                className="mt-3"
                                style={{ width: "100%" }}
                              >
                                Delete
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Button
              onClick={this.handleAddRow}
              color="success"
              className="mt-3 mt-lg-0"
            >
              Add{" "}
            </Button>{" "}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default FormRepeater;
