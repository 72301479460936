import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
const cookies = new Cookies();
import { EditorState, convertToRaw, ContentState, convertFromRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { stateFromHTML } from 'draft-js-import-html';

const EditFAQ = () => {

    const [Title, setTitle] = useState('');
    const [Id, setId] = useState('');
    const [editorState, seteditorState] = useState(EditorState.createEmpty());

    const [Desc, setDesc] = useState("");
    const [Description, setDescription] = useState("");

    const history = useHistory();
    let { id } = useParams();

    useEffect(() => {


        if (id != null) {
            if (id != "0") {
                let newid = id.split(':');
                const Eid = newid[1];
                getFAQDetails(Eid);
            }
            else {
                history.push("/FAQ");
            }
        }
        else {
            history.push("/FAQ");
        }
    }, []);

    useEffect(() => {
        let contentState = stateFromHTML(Desc);
        seteditorState(EditorState.createWithContent(contentState));
    }, [Desc]);

    const onEditorStateChange = editorState => {
        seteditorState(editorState)
        setDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }


    const updateFAQ = async (e) => {
        e.preventDefault();
        let newid = id.split(':');
        const Eid = newid[1];
        let VTitle = e.target["txtTitle"].value;

        if (VTitle == "") {
            alert("Enter FAQ Title");
            return;
        }

        if (VTitle != '') {
            const FAQUpdateData = {
                Id: Eid,
                Title: VTitle,
                Description: Description == "" ? Desc : Description,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
            }
            axios.post(global.APIURL + '/FrequentlyAskedQuestionsUpdate', FAQUpdateData)
                .then(res => {
                    alert(res.data[0].RecordStatus);
                    history.push('/FAQ')
                });
        }
    }


    const getFAQDetails = async (UTId) => {

        const AData = {
            "Id": UTId
        }

        axios.post(global.APIURL + '/FrequentlyAskedQuestionsDetail', AData)
            .then(res => {
                setTitle(res.data[0].Title);
                setDesc(res.data[0].Description);
            })
    }

    return (
        <div className="page-content">

            <MetaTags>
                <title>FAQ | KC Admin Group</title>
            </MetaTags>

            <div className="container-fluid">

                <Row className="mb-3">
                    <Col className="col-lg-8 col-md-6 col-6">
                        <div className="breadcrum ps-0">
                            <CardTitle className="h4">Edit FAQ</CardTitle>
                            <a href="/GeneralDashboard" className="me-2">Home</a>
                            <span>/</span>
                            <a href="/Achivement"><span className="ms-2 me-2">FAQ</span></a>
                            <span>/</span>
                            <span className="ms-2">Edit FAQ</span>
                        </div>
                    </Col>
                    <Col className="col-lg-4 co-md-6 col-6">
                        <a className="btn btn-primary btn-sm float-end font-14" href="/FAQ">
                            <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                        </a>
                    </Col>
                </Row>
                <form onSubmit={updateFAQ}>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>


                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-2">
                                                <label className="form-label">Title</label>
                                                <Input type="text" className="form-control" id="txtTitle" placeholder="Enter FAQ Title" defaultValue={Title} />
                                            </div>
                                        </Col>



                                    </Row>
                                    <Row>

                                        <Col lg={12}>
                                            <div className="mb-2 " >
                                                <label className="form-label">Description</label>

                                                <Editor
                                                    editorState={editorState}
                                                    wrapperClassName="demo-wrapper"
                                                    editorClassName="demo-editor"
                                                    onEditorStateChange={onEditorStateChange}
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={12}>
                                            <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                                            <a className="btn btn_danger btn-sm ms-2" href="/Category">Clear</a>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    )
}

export default EditFAQ;