import React, { Component, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input, Label } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import FileBase64 from 'react-file-base64';

const AddUser = () => {
  const history = useHistory();
  const [Name, setName] = useState("");
  const [FilePath, setFilePath] = useState("");
  const [FileName, setFileName] = useState("");
  const [FileExtension, setFileExtension] = useState("");
  const [Email, setEmail] = useState("");
  const [DateofBirth, setDateofBirth] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");
  const [Gender, setGender] = useState("");
  const [Password, setPassword] = useState("");
  const [UserTypeId, setUserTypeId] = useState("");
  const [DepartmentId, setDepartmentId] = useState("");
  const [CompanyId, setCompanyId] = useState(0);
  const [BranchId, setBranchId] = useState(0);
  const [DesignationId, setDesignationId] = useState("");
  const [CountryId, setCountryId] = useState("");
  const [StateId, setStateId] = useState("");
  const [CityId, setCityId] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Address, setAddress] = useState("");
  const [DistrictId, setDistrictId] = useState("");
  const [TehsilId, setTehsilId] = useState("");
  const [CountryList, setCountryList] = useState([]);
  const [CompanyList, setCompanyList] = useState([]);
  const [BranchList, setBranchList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);
  const [DepartmentList, setDepartmentList] = useState([]);
  const [DesignationList, setDesignationList] = useState([]);
  const [UserTypeList, setUserTypeList] = useState([]);
  const [DashboardSettingList, setDashboardSettingList] = useState([]);
  const [DashboardSettingId, setDashboardSettingId] = useState("0");

  useEffect(() => {
    getCountryList();
    getCompanyList();
    getUserTypeList();
    getDepartmentList();
    getDesignationList();
    getDashboardSettingList('0');
  }, [])

  const getDashboardSettingList = async (UId) => {
    var VUserId = UId == null ? '0' : UId == '' ? '0' : UId;
    var AUrl = global.APIURL + '/DashboardSettingsActiveList/' + VUserId;
    axios.get(AUrl)
      .then(Cires => {
        let CehsilL = Cires.data.map((kctehsil) => {
          return kctehsil
        })
        setDashboardSettingList(CehsilL);
      })
  }

  const getUserTypeList = async () => {
    fetch(global.APIURL + "/UserTypeActiveList")
      .then((res) => res.json())
      .then((json) => {
        setUserTypeList(json)
      })
  }

  const getDepartmentList = async () => {
    fetch(global.APIURL + "/DepartmentActiveList")
      .then((res) => res.json())
      .then((json) => {
        setDepartmentList(json)
      })
  }

  const getDesignationList = async () => {
    fetch(global.APIURL + "/DesignationActiveList")
      .then((res) => res.json())
      .then((json) => {
        setDesignationList(json)
      })
  }

  const getCompanyList = async () => {
    fetch(global.APIURL + "/CompanyActive")
      .then((res) => res.json())
      .then((json) => {
        setCompanyList(json)
      })
  }

  const getBranchList = async (CId) => {
    var AUrl = global.APIURL + '/BranchActiveList/' + CId;
    axios.get(AUrl)
      .then(Branchres => {
        let BranchList = Branchres.data.map((branch) => {
          return branch
        })
        setBranchList(BranchList);
      })
  }

  const getCountryList = async () => {
    fetch(global.APIURL + "/CountryActiveList")
      .then((res) => res.json())
      .then((json) => {
        setCountryList(json)
      })
  }

  const getStateList = async (CId) => {
    var AUrl = global.APIURL + '/ActiveState/' + CId;
    axios.get(AUrl)
      .then(Sres => {
        let StateL = Sres.data.map((kcstate) => {
          return kcstate
        })
        setStateList(StateL);
      })
  }

  const getCityList = async (TId) => {
    var AUrl = global.APIURL + '/ActiveCity/' + TId;
    axios.get(AUrl)
      .then(Cires => {
        let CityL = Cires.data.map((kccity) => {
          return kccity
        })
        setCityList(CityL);
      })
  }


  const getDistrictList = async (SId) => {
    var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
    axios.get(AUrl)
      .then(Cires => {
        let DistrictL = Cires.data.map((kcDistrict) => {
          return kcDistrict
        })
        setDistrictList(DistrictL);
      })
  }
  const getTehsilList = async (SId) => {
    var AUrl = global.APIURL + '/ActiveTehsil/' + SId;
    axios.get(AUrl)
      .then(Cires => {
        let CehsilL = Cires.data.map((kctehsil) => {
          return kctehsil
        })
        setTehsilList(CehsilL);
      })
  }



  const UserDetailSave = async (e) => {
    e.preventDefault();
    let VName = Name;
    if (VName != '') {
      const UserDetailArr = {
        UserId: '0',
        Name: Name,
        FilePath: FilePath,
        FileName: FileName,
        FileExtension: FileExtension,
        Email: Email,
        DateofBirth: DateofBirth,
        MobileNumber: MobileNumber,
        Gender: Gender,
        Password: Password,
        UserTypeId: UserTypeId,
        DepartmentId: DepartmentId,
        DesignationId: DesignationId,
        DashboardSettingId: DashboardSettingId,
        CountryId: CountryId,
        StateId: StateId,
        CityId: CityId,
        Pincode: Pincode,
        Address: Address,
        DisctrictId: DistrictId,
        TehsilId: TehsilId,
        CompanyId: CompanyId,
        BranchId: BranchId,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
      }
      
      axios.post(global.APIURL + '/UserInsert', UserDetailArr)
        .then(res => {
          alert(res.data.StatusMessage);
          history.push("/User");
        });
    }
  }

  function ProfilefetchPost(e) {
    var details = e.target.files[0];
    let arr = details.name.toString().split(".");
    var FileEx = arr[1];
    var isUpload = 0;
    if (FileEx == 'jpg') {
      isUpload++;
    }
    else if (FileEx == 'JPG') {
      isUpload++;
    }
    else if (FileEx == 'jpeg') {
      isUpload++;
    }
    else if (FileEx == 'JPEG') {
      isUpload++;
    }
    else if (FileEx == 'png') {
      isUpload++;
    }
    else if (FileEx == 'PNG') {
      isUpload++;
    }
    else if (FileEx == 'gif') {
      isUpload++;
    }
    else if (FileEx == 'GIF') {
      isUpload++;
    }

    if (parseInt(isUpload) > 0) {
      var details1 = "UserProfileImage";
      const formData = new FormData();
      formData.append('Document', details);
      formData.append('FolderName', details1);
      var AUrl = global.APIURL + '/uploadavatar/';
      axios.post(AUrl, formData,
        {
          headers: { "Content-Type": details.type },
        })
        .then((res) => {
          document.getElementById("fuProfileImage").value = '';
          setFilePath(res.data.IName);
          //document.getElementById("IGProfileImage").src = res.data.IName;
        });
    }
    else {
      setFilePath('');
      document.getElementById("fuProfileImage").value = '';
      alert("Please upload file .jpg or .jpeg or .png or .gif image!");
    }
  }

  return (
    <div className="page-content">

      <MetaTags>
        <title>User | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-12 mb-2">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Add User</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <a href="/User" className="ms-2 me-2">User</a>
              <span>/</span>
              <span className="ms-2">Add User</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-12">
            <a className="btn btn-primary btn-sm float-end font-14" href="/User">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <form onSubmit={UserDetailSave}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <Row>
                    <Col lg={5}>
                      <div className="mb-2">
                        <label className="form-label">Name</label>
                        <Input type="text" className="form-control" id="txtName" placeholder="Enter Name" onChange={(e) => setName(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Email</label>
                        <Input type="text" className="form-control" id="txtEmail" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-2">
                        <label className="form-label">Mobile Number</label>
                        <Input type="text" className="form-control" id="txtMobileNumber" placeholder="Enter Mobile Number" onChange={(e) => setMobileNumber(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={3} className="mt-2">
                      <div className="mb-2 labeledit">
                        <label className="form-label">Upload Image</label>
                        <input type="file" id="fuProfileImage" className="form-control form-control" name="fuProfileImage" onChange={ProfilefetchPost} />
                        {/* <FileBase64 className="form-control" onDone={UploadProfileImage.bind(this)} /> */}
                      </div>
                    </Col>
                    <Col lg={3} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Date of Birth</label>
                        <Input type="date" className="form-control" id="txtDateofBirth" placeholder="Enter Date of Birth" onChange={(e) => setDateofBirth(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={3} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Password</label>
                        <Input type="password" className="form-control" id="txtPassword" placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={2} className="mt-2">
                      <label className="form-label">Gender</label>
                      <div className="d-flex align-items-center mt-2" onChange={e => setGender(e.target.value)}>
                        <div className="form-check mb-2">
                          <input id="RBGenderMale" className="form-check-input" name="RBGender" value="Male" type="radio" />
                          <Label className="form-check-label" htmlFor="RBGenderMale">
                            Male
                          </Label>
                        </div>
                        <div className="form-check mb-2 ms-2">
                          <input id="RBGenderFemale" className="form-check-input" name="RBGender" value="Female" type="radio" />
                          <Label className="form-check-label" htmlFor="RBGenderFemale">
                            Female
                          </Label>
                        </div>
                      </div>
                    </Col>
                    <Col lg={12} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Address</label>
                        <Input type="textarea" className="form-control" id="txtAddress" placeholder="Enter Address" onChange={(e) => setAddress(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={2} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Country</label>
                        <select className="form-select" Id="ddlCountry" name="ddlCountry" onChange={(e) => { setCountryId(e.target.value); getStateList(e.target.value) }}>
                          <option value={"0"}>Select Country</option>
                          {CountryList.map((country) => (
                            <option key={country.CountryId} value={country.CountryId}> {country.Title} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={2} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">State</label>
                        <select className="form-select" Id="ddlState" name="ddlState" onChange={(e) => { setStateId(e.target.value); getDistrictList(e.target.value) }}>
                          <option value={"0"}>Select State</option>
                          {StateList.map((state) => (
                            <option key={state.StateId} value={state.StateId}> {state.Title} </option>
                          ))}
                        </select>
                      </div>
                    </Col>

                    <Col lg={2} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">District</label>
                        <select className="form-select" Id="ddlDistrict" name="ddlDistrict" onChange={(e) => { setDistrictId(e.target.value); getTehsilList(e.target.value) }}>
                          <option value={"0"}>Select District</option>
                          {DistrictList.map((district) => (
                            <option key={district.DistrictId} value={district.DistrictId} selected={DistrictId === district.DistrictId}> {district.Title} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={2} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Tehsil</label>
                        <select className="form-select" Id="ddlTehsil" name="ddlTehsil" onChange={(e) => { setTehsilId(e.target.value); getCityList(e.target.value) }}>
                          <option value={"0"}>Select Tehsil</option>
                          {TehsilList.map((tehsil) => (
                            <option key={tehsil.TehsilId} value={tehsil.TehsilId} selected={TehsilId === tehsil.TehsilId}> {tehsil.Title} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={2} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">City/Village/Town</label>
                        <select className="form-select" Id="ddlCity" name="ddlCity" onChange={(e) => setCityId(e.target.value)}>
                          <option value={"0"}>Select City/Village/Town</option>
                          {CityList.map((city) => (
                            <option key={city.CityId} value={city.CityId}> {city.Title} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={2} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Pincode</label>
                        <Input type="text" className="form-control" id="txtPincode" placeholder="Enter Pincode" onChange={(e) => setPincode(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={3} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">User Type</label>
                        <select className="form-select" Id="ddlUserType" name="ddlUserType" onChange={(e) => setUserTypeId(e.target.value)}>
                          <option value={"0"}>Select User Type</option>
                          {UserTypeList.map((usertype) => (
                            <option key={usertype.Id} value={usertype.Id}> {usertype.Title} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={3} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Department</label>
                        <select className="form-select" Id="ddlDepartment" name="ddlDepartment" onChange={(e) => setDepartmentId(e.target.value)}>
                          <option value={"0"}>Select Department</option>
                          {DepartmentList.map((department) => (
                            <option key={department.Id} value={department.Id}> {department.Name} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={3} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Designation</label>
                        <select className="form-select" Id="ddlDesignation" name="ddlDesignation" onChange={(e) => setDesignationId(e.target.value)}>
                          <option value={"0"}>Select Designation</option>
                          {DesignationList.map((designation) => (
                            <option key={designation.Id} value={designation.Id}> {designation.Name} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={3} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Assign Dashboard Setting</label>
                        <select className="form-select" Id="ddlDesignation" name="ddlDashboardSetting" onChange={(e) => setDashboardSettingId(e.target.value)}>
                          <option value={"0"}>Select Dashboard Setting</option>
                          {DashboardSettingList.map((designation) => (
                            <option key={designation.Id} value={designation.Id}> {designation.Title} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={3} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Company</label>
                        <select className="form-select" Id="ddlCompany" name="ddlCompany" onChange={(e) =>{setCompanyId(e.target.value);getBranchList(e.target.value)}}>
                          <option value={"0"}>Select Company</option>
                          {CompanyList.map((company) => (
                            <option key={company.CompanyId} value={company.CompanyId}> {company.CompanyName} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={3} className="mt-2">
                      <div className="mb-2">
                        <label className="form-label">Branch</label>
                        <select className="form-select" Id="ddlBranch" name="ddlBranch" onChange={(e) => setBranchId(e.target.value)}>
                          <option value={"0"}>Select Branch</option>
                          {BranchList.map((BranchItem) => (
                            <option key={BranchItem.BranchId} value={BranchItem.BranchId}> {BranchItem.Title} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={12} className="mt-2">
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/AddUser">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>

    </div>
  )
}

export default AddUser;