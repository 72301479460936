import React, { Component, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';

const AddAccountType = () => {

    const history = useHistory();
  const saveProduct = async (e) => {
    e.preventDefault();
    let UTitle = event.target["txtTitle"].value;

    if (UTitle != '') {
      const AccountType = {
        Id: '0',
        Title: UTitle,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
      }
      axios.post(global.APIURL + '/AccountType', AccountType)
        .then(res => {
         
          alert(res.data.StatusMessage);
          document.getElementById("txtTitle").value='';
        
        });
    }
  }



    return (
        <div className="page-content">
    
          <MetaTags>
            <title>AccountType | KC Admin Group</title>
          </MetaTags>
    
          <div className="container-fluid">
    
            <Row className="mb-3">
              <Col className="col-lg-8 col-md-6 col-12 mb-2">
                <div className="breadcrum ps-0">
                  <CardTitle className="h4">Add AccountType</CardTitle>
                  <a href="/GeneralDashboard" className="me-2">Home</a>
                  <span>/</span>
                  <a href="/Category" className="ms-2 me-2">AccountType</a>
                  <span>/</span>
                  <span className="ms-2">Add AccountType</span>
                </div>
              </Col>
              <Col className="col-lg-4 co-md-6 col-12">
                <a className="btn btn-primary btn-sm float-end font-14" href="/AccountType">
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
              </Col>
            </Row>
            <form onSubmit={saveProduct}>
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
    
                      <Row>
                        <Col lg={4}>
                          <div className="mb-2">
                            <label className="form-label">Title</label>
                            <Input type="text" className="form-control" id="txtTitle" placeholder="Enter Title" />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                          <a className="btn btn_danger btn-sm ms-2" href="/AccountType">Clear</a>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </form>
          </div>
    
        </div>
      )



}

export default AddAccountType;