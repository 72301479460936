import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label,
  Input,
} from "reactstrap"

import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import classnames from "classnames"

class UiTabsAccordions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col5: true,
      col6: true,
      col7: true,
      col8: true,
      col9: true,
      col10: false,
      col11: false,
    }
    this.toggle = this.toggle.bind(this)
    this.toggle1 = this.toggle1.bind(this)

    this.t_col1 = this.t_col1.bind(this)
    this.t_col2 = this.t_col2.bind(this)
    this.t_col3 = this.t_col3.bind(this)
    this.t_col5 = this.t_col5.bind(this)
    this.t_col6 = this.t_col6.bind(this)
    this.t_col7 = this.t_col7.bind(this)
    this.t_col8 = this.t_col8.bind(this)
    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)

    this.toggle2 = this.toggle2.bind(this)
    this.toggle3 = this.toggle3.bind(this)

    this.toggleVertical = this.toggleVertical.bind(this)
    this.toggleVerticalIcon = this.toggleVerticalIcon.bind(this)
    this.toggleCustom = this.toggleCustom.bind(this)
    this.toggleIconCustom = this.toggleIconCustom.bind(this)
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false
    })
  }

  t_col2() {
    this.setState({
      col1: false,
      col2: !this.state.col2,
      col3: false
    })
  }

  t_col3() {
    this.setState({
      col1: false,
      col2: false,
      col3: !this.state.col3
    })
  }

  t_col5() {
    this.setState({ col5: !this.state.col5 })
  }

  t_col6() {
    this.setState({ col6: !this.state.col6 })
  }

  t_col7() {
    this.setState({ col7: !this.state.col7 })
  }

  t_col8() {
    this.setState({
      col6: !this.state.col6,
      col7: !this.state.col7
    })
  }

  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false
    })
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false
    })
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      })
    }
  }

  toggle2(tab) {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      })
    }
  }

  toggle3(tab) {
    if (this.state.activeTab3 !== tab) {
      this.setState({
        activeTab3: tab,
      })
    }
  }

  toggleVertical(tab) {
    if (this.state.verticalActiveTab !== tab) {
      this.setState({
        verticalActiveTab: tab,
      })
    }
  }

  toggleVerticalIcon(tab) {
    if (this.state.verticalActiveTabWithIcon !== tab) {
      this.setState({
        verticalActiveTabWithIcon: tab,
      })
    }
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      })
    }
  }

  toggleIconCustom(tab) {
    if (this.state.customIconActiveTab !== tab) {
      this.setState({
        customIconActiveTab: tab,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>To Do | KC Admin Group</title>
          </MetaTags>
          <Container fluid={true}>
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h4"> To Do’s </CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">To Do’s</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                    <a className ="btn btn-success btn-sm float-end font-14 me-3" href="/"><i className="mdi mdi-plus align-middle me-1"></i>Add</a>
                  </Col>
              </Row>
             
            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <Nav tabs className="nav-tabs-custom nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "1",
                          })}
                          onClick={() => {
                            this.toggleCustom("1")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                          <span className="d-none d-sm-block">To do Details</span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "2",
                          })}
                          onClick={() => {
                            this.toggleCustom("2")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                          <span className="d-none d-sm-block">Notes & History</span>
                        </NavLink>
                      </NavItem>

                    </Nav>

                    <TabContent activeTab={this.state.customActiveTab} className="p-3 text-muted">

                <TabPane tabId="1">
                      <Row className="mb-3">
                          <Col className="col-1 text-center">
                              <input
                                type="checkbox"
                                className="form-check-input input-mini "
                                id="closeButton"
                                value="checked" checked
                            />
                          </Col>
                          <Col className="col-11">
                            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                          </Col>  
                        </Row>
                        <Row>
                            <Col className="col-1  text-center">
                                <i className="mdi mdi-calendar-account-outline fs-5"></i>
                            </Col>
                            <Col className="col-11">
                                <span className="d-d-inline-block me-3  fs-6"> 16/8/2021 </span> 
                            <i className="mdi mdi-face-woman fs-5 me-2"></i>
                            <i className="mdi mdi-face-woman fs-5 me-2"></i>
                            <i className="mdi mdi-face-woman fs-5 me-2"></i>
                            <i className="mdi mdi-face-woman fs-5 me-2"></i>
                            <i className="mdi mdi-face-woman fs-5 me-2"></i>
                            </Col>
                        </Row>
                        
                    <hr className="bg-bg-grey-900" />
                    <Row>
                        <Col className="col-12 mb-3">
                        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                        </Col>
                        </Row>
                        
                    <Row>
                        <Col className="col-12 mb-3">
                            <ul>
                              <li className=" mb-2">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</li>
                              <li className=" mb-2">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</li>
                          </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12 mb-3">
                        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                        </Col>
                    </Row>
                    
                    <Row>
                          <Col className="col-lg-3 col-md-4 col-12 mb-3">
                            <span className="d-flex border_dotted p-3 rounded-3">
                              <div>
                              <i className="mdi mdi-attachment fs-3 me-3"></i>
                              </div>
                              <div>
                                <p className="mb-0">tododocument.PDF</p>
                                <p className="mb-0">18KB</p>
                              </div>
                            </span>
                          </Col>
                    </Row>


                </TabPane>
                      

                      <TabPane tabId="2">
                        <Row>
                          <Col className="col-12 mb-3">
                            <div className="pb-2">
                              <div className="notes_box pb-3">
                                Notes shared with <strong>Khushi Verma</strong>  on 1st Sep 21 <br />
                                <div className="text-success">by Kautzer Yessenia</div>
                                <div>Notes: Vitae perspiciatis velit similique autem. Ullam dolorem quaerat consequatur blanditiis. Incidunt occaecati modi numquam sapiente voluptatem molestiae. Sit omnis nostrum totam molestiae delectus.</div>
                              </div>
                              <div className="notes_box pb-3">
                                Mark as re-open on 30th June 21 <br />
                                <div className="text-success">by Kautzer Yessenia</div>
                              </div>
                              <div className="notes_box pb-3">
                                 Mark as complete on 30th June 21 <br />
                                 <div className="text-success">by Kautzer Yessenia</div>
                              </div>
                              <div className="notes_box pb-3">
                                Created new note with attachment on 25th June 21 <br />
                                <div className="text-success">by Kautzer Yessenia</div>
                                <div>Notes: Autem voluptatum praesentium sed magni quasi eum provident impedit voluptatum. Quas consequatur non quia et nisi. Qui quia qui autem sit laboriosam nisi. </div>
                                <Row>
                                  <Col className="col-md-3 col-6 m-3 ms-0">
                                    <span className="d-flex border_dotted p-3 rounded-3">
                                      <div>
                                      <i className="mdi mdi-attachment fs-3 me-3"></i>
                                      </div>
                                      <div>
                                        <p className="mb-0">tododocument.PDF</p>
                                        <p className="mb-0">18KB</p>
                                      </div>
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                              <div className="notes_box pb-3">
                                 Created new note on 25th June 21 <br />
                                <div className="text-success">by Kautzer Yessenia</div>
                                <div>Notes: Autem voluptatum praesentium sed magni quasi eum provident impedit </div>
                              </div>

                              <div className="notes_box pb-3">
                              Task Assign to Nikolaus Lavern on 17th June 21 <br />
                                <div className="text-success">by Kautzer Yessenia</div>
                              </div>

                              <div className="notes_box pb-3">
                                Open on 16th June 21  <br />
                                <div className="text-success">by Kautzer Yessenia</div>
                              </div>

                            </div>
                          </Col>
                        </Row>  
                      </TabPane>


                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default UiTabsAccordions
