import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import img2 from "assets/images/PAN.png";
import axios from "axios";
import { Link, useHistory, useParams } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody, Input, Label, Button } from "reactstrap";
import PlacholderImgC from "assets/images/favicon.png";


const ManageMOMd = () => {
  const history = useHistory();
  const [StateList, setStateList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);
  const [CompanyDistrictId, setCompanyDistrictId] = useState("");
  const [CompanyTehsilId, setCompanyTehsilId] = useState("");
  const [LeadPartnerList, setLeadPartnerList] = useState([]);
  const [AddTehsil, setAddTehsil] = useState('D');
  const [AddCity, setAddCity] = useState('D');
  const [CompanyCityId, setCompanyCityId] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Branchcode, setBranchcode] = useState("");
  const [CompanyList, setCompanyList] = useState([]);
  const [CompanyId, setCompanyId] = useState("");
  const [NetworkTypeList, setNetworkTypeList] = useState([]);
  const [NetworkTypeId, setNetworkTypeId] = useState("");
  const [LeadContactList, setLeadContactList] = useState([]);
  const [Address, setAddress] = useState("");
  const [LeadFollowupList, setLeadFollowupList] = useState([]);
  const [ChannelTypeId, setChannelTypeId] = useState("");
  const [StateId, setStateId] = useState("");
  const [EditcorLeadId, setcorEditLeadId] = useState("");
  const [NextFollowupDate, setNextFollowupDate] = useState('');
  const [NextFollowupTime, setNextFollowupTime] = useState('');
  const [NextFollowupMode, setNextFollowupMode] = useState('');
  const [NextFollowupNote, setNextFollowupNote] = useState('');
  const [CorporateleadContactId, setCorporateleadContactId] = useState("");

  const [ConvertDate, setConvertDate] = useState('');
  const [ConvertRemark, setConvertRemark] = useState('');

  const [CloseDate, setCloseDate] = useState('');
  const [CloseRemark, setCloseRemark] = useState('');

  const [Email, setEmail] = useState([]);
  const [Name, setName] = useState([]);
  const [MobileNo, setMobileNo] = useState([]);
  const [Designation, setDesignation] = useState([]);

  const [LeadEmailList, setLeadEmailList] = useState([]);

  const [MeetNote, setMeetNote] = useState([]);
  const [MeetSummary, setMeetSummary] = useState([]);
  const [MeetDate, setMeetDate] = useState([]);
  const [MeetTime, setMeetTime] = useState([]);
  const [NextMeetDate, setNextMeetDate] = useState([]);
  const [MeetPointList, setMeetPointList] = useState([]);
  const [MeetAgendaList, setMeetAgendaList] = useState([]);
  const [AttachmentList, setAttachmentList] = useState([]);
  const [AttachmentFilePath, setAttachmentFilePath] = useState([]);
  const [AttachmentFile, setAttachmentFile] = useState([]);
  const [MeetDetails, setMeetDetails] = useState([]);
  const [DisplayAttachFile, setDisplayAttachFile] = useState([]);

  const [CorporateList, setCorporateList] = useState([]);
  const [CorporateContactPersonList, setCorporateContactPersonList] = useState([]);
  const [CorporateId, setCorporateId] = useState(0);
  const [MeetContact, setMeetContact] = useState([]);
  const [MeetCorporateId, setMeetCorporateId] = useState(0);
  const [DropdownChannelList, setDropdownChannelList] = useState([]);

  const [pointde, setpoint] = useState([]);
  const [agendade, setagenda] = useState([]);
  const [MeetPoint, setMeetPoint] = useState([]);
  const [EmailIdd, setmEmailId] = useState([]);
  let CoLeadId = localStorage.getItem("CorporateleadId");

  let { id } = useParams();

  let MIdstorage = localStorage.getItem("MId");

  useEffect(() => {
    if (id != null) {
      if (id != "0") {
        let newid = id.split(':');
        getStateList("1");
        getLeadDetailById(CoLeadId);
        getCompanyList();
        getNetWorkList();
        getMenuHideShow();
        getLeadContactList(newid[1]);
        getMeetDetailList(newid[1]);
        getEditMeetPointDetails(newid[1]);
        getpointList(newid[1]);
        getagendaList(newid[1]);
        getCorporateList();
        CorporateMeetingDetail();
        document.getElementById("CorporateListdiv").style.display = 'none';
      }
    }
  }, []);

  const getCorporateList = async () => {
    fetch(global.APIURL + "/MeetingCorporateClient")
      .then((res) => res.json())
      .then((json) => {
        setCorporateList(json)
      })
  }


  const CorporateMeetingDetail = async () => {

    const MeetDetailsData = {
      CorporateMeetingId: MIdstorage
    }

    axios.post(global.APIURL + "/CorporateMeetingDetail", MeetDetailsData)
      .then(Mres => {
        setCorporateContactPersonList(Mres.data.Result[0].MeetingContactEdit);

if(Mres.data.Result[0].MeetingContact.length>0)
       { 
        
        setMeetCorporateId(Mres.data.Result[0].MeetingContact[0].CorporateId);}

      });
  }

  const getCorporateContactPersonList = async CompanyId => {
    const LeadInquiry = {
      CompanyId: CompanyId
    };
    setCorporateId(CompanyId);
    axios.post(global.APIURL + "/MeetingCorporateClientContact", LeadInquiry)
      .then(CorporateClientres => {
        let CorporateClientContact = CorporateClientres.data.map(contact => {
          return contact;
        });
        setCorporateContactPersonList(CorporateClientContact);
      });
  };

  const getMenuHideShow = async () => {
    const f = {
      MenuId: "48",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {
        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add Tehsil') {
            setAddTehsil(MS);
          }
          else if (MA == 'Add City') {
            setAddCity(MS);
          }
        }
      });
  };
  const getpointList = async (CID) => {
    const MData = {
      "CorporateleadId": CID,
      "MId": MIdstorage
    }

    axios.post(global.APIURL + '/pointinstweb', MData)
      .then(res => {

        setpoint(res.data);

      });


  }
  const getagendaList = async (CID) => {
    const MData = {
      "CorporateleadId": CID,
      "MId": MIdstorage
    }

    axios.post(global.APIURL + '/Agendainstweb', MData)
      .then(res => {

        setagenda(res.data);

      });


  }
  const getAttachmentList = async (CID) => {
    const MData = {
      "CorporateleadId": CID,
      "MId": MIdstorage
    }

    axios.post(global.APIURL + '/Attachmentweb', MData)
      .then(res => {

        setttache(res.data);

      });


  }
  const getCompanyList = async () => {
    var ACUrl = global.APIURL + '/CompanyActive/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCompanyList(CityL);
      });
  };

  const getNetWorkList = async () => {
    var ACUrl = global.APIURL + '/ChannalTypeList/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setNetworkTypeList(CityL);
      });
  };

  const getStateList = async CId => {
    var AUrl = global.APIURL + '/ActiveState/' + CId;
    axios.get(AUrl)
      .then(Sres => {
        let StateL = Sres.data.map(kcstate => {
          return kcstate;
        });
        setStateList(StateL);
      });
  };

  const getDistrictList = async SId => {
    setStateId(SId);

    var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
    axios.get(AUrl)
      .then(Dres => {

        let DistrictL = Dres.data.map(kcdistrict => {
          return kcdistrict;
        });
        setDistrictList(DistrictL);
      });
  };

  const getCompanyTehsilList = async DId => {
    var AUrl = global.APIURL + '/ActiveTehsil/' + DId;
    axios.get(AUrl)
      .then(Dres => {
        let TehsilL = Dres.data.map(kctehsil => {
          return kctehsil;
        });
        setTehsilList(TehsilL);
      });
  };

  const getLeadDetailById = async LId => {

    var AUrl = global.APIURL + '/CorporateleadEdit/' + LId;
    axios.get(AUrl)
      .then(res => {
        if (res.data.length > 0) {
          setLeadContactList(res.data);
          setCompanyId(res.data[0].CompanyId);
          setNetworkTypeId(res.data[0].ChannelTypeId);
          setAddress(res.data[0].Address);
          setStateId(res.data[0].StateId);
          getDistrictList(res.data[0].StateId);
          setCompanyDistrictId(res.data[0].DistrictId);
          getCompanyTehsilList(res.data[0].DistrictId);
          setCompanyCityId(res.data[0].CityId);
          setCompanyTehsilId(res.data[0].TehsilId);
          getCityList(res.data[0].TehsilId)
          setPincode(res.data[0].Pincode);
          setBranchcode(res.data[0].Branchcode);
          setcorEditLeadId(res.data[0].CorporateleadId);
          setCorporateleadContactId(res.data[0].CorporateleadContactId);
          setmEmailId(res.data[0].EmailId);
          getEditDropdownChannelMasterList(res.data[0].ChannelTypeId);
        }

      });
  };

  const getLeadContactList = async (CId) => {

    var AUrl = global.APIURL + '/CorporateleadcontactEditClient/' + CId;

    axios.post(AUrl)
      .then(res => {
        if(res.data.length>0){
   
          setEmail(res.data[0].Email);
          setName(res.data[0].Name);
          setMobileNo(res.data[0].Mobilenumber);
          setDesignation(res.data[0].Designation);
        }
      });
  }

  const getCityList = async TId => {
    var AUrlC = global.APIURL + '/ActiveCity/' + TId;
    axios.get(AUrlC)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCityList(CityL);
      });
  };


  const getMeetDetailList = async (CID) => {
    const MData = {
      "CorporateleadId": CID,
      "MId": MIdstorage
    }

    axios.post(global.APIURL + '/WebCorporateLeadMomListWeb', MData)
      .then(res => {

        setMeetDetails(res.data);


      });


  }

  const MeetPointhandleAddRow = e => {
    var LRNo = MeetPointList.length == 0 ? 1 : (MeetPointList[MeetPointList.length - 1].RowId + 1);

    var VMeetPoint = document.getElementById('txtMeetingPoint').value;

    const Mitem = {
      MeetPointId: 0,
      RowId: LRNo,
      Point: VMeetPoint,
      MeetPointTextBoxControlId: 'txtMeetingPoint' + LRNo,

    };
    setMeetPointList([...MeetPointList, Mitem]);
    document.getElementById('txtMeetingPoint').value = '';

  };

  const MeetPointhandleRemoveRow = (e, RowId, MeetPointId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowId;
      setMeetPointList(MeetPointList.filter(item => item.RowId !== RId));

      var VMeetPointId = parseInt(MeetPointId == null ? '0' : MeetPointId == '' ? '0' : MeetPointId);
      if (VMeetPointId > 0) {
        const MPItemArr = { Id: VMeetPointId }
        axios.post(global.APIURL + '/WebMOMPointDelete', MPItemArr).then(resem => { });
      }
    }
  };

  const MeetAgendahandleAddRow = e => {
    var LRNo = MeetAgendaList.length == 0 ? 1 : (MeetAgendaList[MeetAgendaList.length - 1].RowId + 1);

    var VAgenda = document.getElementById('txtMeetingAgenda').value;

    const Aitem = {
      MeetAgendaId: 0,
      RowId: LRNo,
      Agenda: VAgenda,
      MeetAgendaTextBoxControlId: 'txtMeetingAgenda' + LRNo,

    };
    setMeetAgendaList([...MeetAgendaList, Aitem]);
    document.getElementById('txtMeetingAgenda').value = '';
  };

  const MeetAgendahandleRemoveRow = (e, RowId, MeetAgendaId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowId;
      setMeetAgendaList(MeetAgendaList.filter(item => item.RowId !== RId));

      var VMeetAgendaId = parseInt(MeetAgendaId == null ? '0' : MeetAgendaId == '' ? '0' : MeetAgendaId);
      if (VMeetAgendaId > 0) {
        const MAItemArr = { Id: VMeetAgendaId }
        axios.post(global.APIURL + '/WebMOMagendaDelete', MAItemArr).then(resem => { });
      }
    }
  };


  const AttachmenthandleAddRow = e => {
    var LRNo = AttachmentList.length == 0 ? 1 : (AttachmentList[AttachmentList.length - 1].RowId + 1);
    var VAttachFile = AttachmentFile;
    var VAttachFilePath = AttachmentFilePath;

    const ALitem = {
      AttachId: 0,
      RowId: LRNo,
      AttachFile: VAttachFile,
      AttachFilePath: VAttachFilePath,
      UploadFileTextBoxControlId: "AttachfileId" + LRNo

    };
    setAttachmentList([...AttachmentList, ALitem]);
    document.getElementById("AttachfileId").value = "";
  };

  const AttachmenthandleRemoveRow = (e, RowId, AttachId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowId;
      setAttachmentList(AttachmentList.filter(item => item.RowId !== RId));

      var VAttachId = parseInt(AttachId == null ? '0' : AttachId == '' ? '0' : AttachId);
      if (VAttachId > 0) {
        const AItemArr = { Id: VAttachId }
        axios.post(global.APIURL + '/WebMOMAttachDelete', AItemArr).then(resem => { });
      }
    }
  };


  function AttachmentfetchPost(e) {
    var details = e.target.files[0];
    var details1 = "MeetAttachment";
    const formData = new FormData();
    formData.append('Document', details);
    formData.append('FolderName', details1);
    var AUrl = global.APIURL + '/uploadavatar/';
    axios.post(AUrl, formData,
      {
        headers: { "Content-Type": details.type },
      })
      .then((res) => {

        setAttachmentFile(res.data.FileName);
        setAttachmentFilePath(res.data.IName);


        if (e.target.files[0].name != null) {
          if (e.target.files[0].name != '') {
            setDisplayAttachFile(1);
          }
          else {
            setDisplayAttachFile(0);
          }
        }
      });
  }

  const getEditMeetPointDetails = async (CId) => {
    const Mdata = {

      "CorporateleadId": CId

    }
    axios.post(global.APIURL + '/WebCorporateLeadMompointEdit', Mdata).then(res => {

      if (res.data.length > 0)
        setMeetPoint(res.data[0].Point);
    });




  }

  const saveMeetDetails = async () => {


    let VMeetingPoint = document.getElementById("txtMeetingPoint").value;
    let VMeetingAgenda = document.getElementById("txtMeetingAgenda").value;
    let VAttachFile = AttachmentFile;
    let VAttachFilePath = AttachmentFilePath;

    if (MeetPointList.length == 0) {

      let IsDataInsert = 1;
      if (MeetNote == "") {
        IsDataInsert = 0;
      }
      else if (MeetSummary == "") {
        IsDataInsert = 0;
      }
      else if (MeetDate == "") {
        IsDataInsert = 0;
      } else if (MeetTime == "") {
        IsDataInsert = 0;
      } else if (NextMeetDate == "") {
        IsDataInsert = 0;
      }
      else if (VMeetingPoint == "") { IsDataInsert = 0; }
      else if (VMeetingAgenda == "") { IsDataInsert = 0; }
      else if (VAttachFile == "") { IsDataInsert = 0 }
      if (IsDataInsert == 1) {

        const MeetData = {
          Meetingheadnote: MeetNote,
          Meetingsummary: MeetSummary,
          MeetingDate: MeetDate,
          Meetingtime: MeetTime,
          Nextmeetingdate: NextMeetDate,
          "Flag": "A",
          "Flag": "A",
          CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
          CorporateleadId: CoLeadId
        }
        axios.post(global.APIURL + '/WebCorporateLeadManageofmeetingAdd', MeetData).then(res => {

          var VMId = res.data[0].MId;

          for (let ccp = 0; ccp < CorporateContactPersonList.length; ccp++) {

            const CorporateContactPersonDetail = CorporateContactPersonList[ccp];
            var VChkId = CorporateContactPersonDetail.ChkId;
            var VCorporateleadId = CorporateContactPersonDetail.CorporateleadId;
            var VCorporateleadContactId = CorporateContactPersonDetail.CorporateleadContactId;
            var VIsChk = document.getElementById(VChkId).checked;

            if (VIsChk == true) {

              const MeetItemArr = {
                Id: 0,
                MId: VMId,
                CorporateId: VCorporateleadId == null ? '0' : VCorporateleadId == '' ? '0' : VCorporateleadId,
                ContactId: VCorporateleadContactId == null ? '0' : VCorporateleadContactId == '' ? '0' : VCorporateleadContactId,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              };

              axios.post(global.APIURL + '/MeetingContactInformationSave', MeetItemArr).then(res => {});
            }
          }

          if (VMeetingPoint != '') {
            const MeetItemArr = {
              MId: VMId,
              Point: VMeetingPoint,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: CoLeadId
            };

            axios.post(global.APIURL + '/WebCorporateLeadMompointAdd', MeetItemArr).then(res => {


            });
          }

          if (VMeetingAgenda != '') {
            const MeetAgItemArr = {
              MId: VMId,
              Agenda: VMeetingAgenda,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: CoLeadId
            };


            axios.post(global.APIURL + '/WebCorporateLeadMomagendaAdd', MeetAgItemArr).then(res => {


            });
          }

          if (VAttachFile != '') {
            const ALItemArr = {
              MId: VMId,
              Attachment: VAttachFilePath,
              mediafileName: VAttachFile,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: CoLeadId
            };

            axios.post(global.APIURL + '/WebCorporateLeadMomagendaAdd', ALItemArr).then(res => {


            });
          }
          alert("Insert Successfully");
          window.setTimeout(() => {
            history.push("/MOMList");
            }, 1000);

        });
      }
    }
    else {

    
      let IsDataInsert = 1;
      if (MeetNote == "") {IsDataInsert = 0; alert("Enter Meeting Note")}
      else if (MeetSummary == "") {IsDataInsert = 0; alert("Enter Meeting Summary")}
      else if (MeetDate == "") {IsDataInsert = 0; alert("Enter Meeting Meeting Date")}
      else if (MeetTime == "") {IsDataInsert = 0; alert("Enter Meeting Time")}
      else if (NextMeetDate == "") {IsDataInsert = 0; alert("Enter Next Meeting Date")}
      else if (VMeetingPoint == "") { IsDataInsert = 0; alert("Enter Meeting Point")}
      else if (VMeetingAgenda == "") { IsDataInsert = 0; alert("Enter Meeting Agenda")}
      else if (VAttachFile == "") { IsDataInsert = 0; alert("Select Attachment") }

      if (IsDataInsert == 1) {
        const MeetData = {
          Meetingheadnote: MeetNote,
          Meetingsummary: MeetSummary,
          MeetingDate: MeetDate,
          Meetingtime: MeetTime,
          Nextmeetingdate: NextMeetDate,
          "Flag": "A",
          "Flag": "A",
          CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
          CorporateleadId: CoLeadId
        }
        axios.post(global.APIURL + '/WebCorporateLeadManageofmeetingAdd', MeetData).then(res => {

          var VMId = res.data[0].MId;

          for (let ccp = 0; ccp < CorporateContactPersonList.length; ccp++) {

            const CorporateContactPersonDetail = CorporateContactPersonList[ccp];
            var VChkId = CorporateContactPersonDetail.ChkId;
            var VCorporateleadId = CorporateContactPersonDetail.CorporateleadId;
            var VCorporateleadContactId = CorporateContactPersonDetail.CorporateleadContactId;
            var VIsChk = document.getElementById(VChkId).checked;

            if (VIsChk == true) {

              const MeetItemArr = {
                Id: 0,
                MId: VMId,
                CorporateId: VCorporateleadId == null ? '0' : VCorporateleadId == '' ? '0' : VCorporateleadId,
                ContactId: VCorporateleadContactId == null ? '0' : VCorporateleadContactId == '' ? '0' : VCorporateleadContactId,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              };

              axios.post(global.APIURL + '/MeetingContactInformationSave', MeetItemArr).then(res => {});
            }
          }

          if (VMeetingPoint != '') {
            const MeetItemArr = {
              MId: VMId,
              Point: VMeetingPoint,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: CoLeadId
            };

            axios.post(global.APIURL + '/WebCorporateLeadMompointAdd', MeetItemArr).then(res => {});
          }

          if (VMeetingAgenda != '') {
            const MeetAgItemArr = {
              MId: VMId,
              Agenda: VMeetingAgenda,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: CoLeadId
            };

            axios.post(global.APIURL + '/WebCorporateLeadMomagendaAdd', MeetAgItemArr).then(res => {});
          }

          if (VAttachFile != '') {
            const ALItemArr = {
              MId: VMId,
              Attachment: VAttachFilePath,
              mediafileName: VAttachFile,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: CoLeadId
            };

            axios.post(global.APIURL + '/WebCorporateLeadMomagendaAdd', ALItemArr).then(res => {});
          }
          for (let mpl = 0; mpl < MeetPointList.length; mpl++) {
            const MeetPointDetail = MeetPointList[mpl];

            var VMeetPointTextBoxControlId = MeetPointDetail.MeetPointTextBoxControlId;
            var VMeetPoint = document.getElementById(VMeetPointTextBoxControlId).value == null ? '' : document.getElementById(VMeetPointTextBoxControlId).value;

            if (VMeetPoint != '') {
              const MeetItemArr = {
                MId: VMId,
                Point: VMeetPoint,
                "Flag": "A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                CorporateleadId: CoLeadId
              };

              axios.post(global.APIURL + '/WebCorporateLeadMompointAdd', MeetItemArr).then(res => {});
            }
          }
    

          for (let mal = 0; mal < MeetAgendaList.length; mal++) {
            const MeetAgendaDetail = MeetAgendaList[mal];

            var VMeetAgendaTextBoxControlId = MeetAgendaDetail.MeetAgendaTextBoxControlId;
            var VMeetAgenda = document.getElementById(VMeetAgendaTextBoxControlId).value == null ? '' : document.getElementById(VMeetAgendaTextBoxControlId).value;

            if (VMeetAgenda != '') {
              const MeetAgItemArr = {
                MId: VMId,
                Agenda: VMeetAgenda,
                "Flag": "A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                CorporateleadId: CoLeadId
              };

              axios.post(global.APIURL + '/WebCorporateLeadMomagendaAdd', MeetAgItemArr).then(res => {});
            }
          }

          for (let al = 0; al < AttachmentList.length; al++) {
            const AttachmentListDetail = AttachmentList[al];

            var VAttachment = AttachmentListDetail.AttachFilePath;
            var VmediafileName = AttachmentListDetail.AttachFile;

            if (VAttachment != '') {
              const ALItemArr = {
                MId: VMId,
                Attachment: VAttachment,
                mediafileName: VmediafileName,
                "Flag": "A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                CorporateleadId: CoLeadId
              };

              axios.post(global.APIURL + '/WebCorporateLeadMomagendaAdd', ALItemArr).then(res => {});
            }
          }

        });

          alert("Insert Successfully");
          window.setTimeout(() => {
            history.push("/MOMList");
            }, 2000);
      }
    }
  }

  const getDropdownChannelMasterList = async () => {
    var VNetworkTypeId = document.getElementById("ddlNetworkType").value;
    if (parseInt(VNetworkTypeId) > 0) {
      const ChannelDropdownFilter = {
        ChannelTypeId: VNetworkTypeId,
        ChannelMasterId: 0,
        CityId: 0,
        SpMode: 'ChannelMasterSelectAll',
      };
      axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
        .then(CLres => {
          let VChannelMasterList = CLres.data.map(vcl => {
            return vcl;
          });
          setDropdownChannelList(VChannelMasterList);
        });
    }
  };

  const getEditDropdownChannelMasterList = async (NetworkTypeId) => {
    var VNetworkTypeId = NetworkTypeId
    if (parseInt(VNetworkTypeId) > 0) {
      const ChannelDropdownFilter = {
        ChannelTypeId: VNetworkTypeId,
        ChannelMasterId: 0,
        CityId: 0,
        SpMode: 'ChannelMasterSelectAll',
      };
      axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
        .then(CLres => {
          let VChannelMasterList = CLres.data.map(vcl => {
            return vcl;
          });
          setDropdownChannelList(VChannelMasterList);
        });
    }
  };

  return (
    <React.Fragment>

      <div className="page-content">
        <MetaTags>
          <title>Corporate Detail | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">

          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle>Corporate Detail</CardTitle>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a
                className="btn btn-primary btn-sm float-end font-14"
                href="/MOMList">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <div className="ind-card" id="ind-card">
            <Row>
              <Col className="col-lg-12 col-md-12 col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                      <Col className="col-lg-12 col-md-12 col-12 mb-2">
                        <CardTitle>Corporate Contact Detail</CardTitle>
                        <hr />
                      </Col>

                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Network Type </p>
                        <select className="form-select" id="ddlNetworktype" name="ddlNetworktype" onChange={e => { setNetworkTypeId(e.target.value) }}>
                          <option key="0" value="0">Select Network Type</option>
                          {NetworkTypeList.map(item => (
                            <option key={item.ChannelTypeId} value={item.ChannelTypeId} selected={NetworkTypeId === item.ChannelTypeId}>
                              {item.ChannelTypeName}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500">  Network Source  </p>
                        <select className="form-select" Id="ddlCompany" name="ddlCompany" onChange={e => { setCompanyId(e.target.value) }}>
                          <option value={"0"}>Select Source</option>
                          {DropdownChannelList.map(item => (
                            <option key={item.ChannelMasterId} value={item.ChannelMasterId} selected={CompanyId === item.ChannelMasterId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col>
                        <div className="col-lg-3 col-md-6 col-12 mt-3">
                          <label htmlFor="EmailId" className="form-label">
                            EmailId
                          </label>
                          <Input
                            type="text"
                            placeholder="EmailId"
                            className="form-control"
                            id="txtMEmailId"
                            maxlength="50"
                            defaultValue={EmailIdd}
                            onChange={e => setmEmailId(e.target.value)}
                          />

                        </div>
                      </Col>
                      <Col className="col-lg-12 col-md-12 col-12 mt-3 mt-md-3 mt-lg-0">
                        <p className="mt-3 mb-2 fontWeight500"> Address </p>
                        <div className="input-group auth-pass-inputgroup">
                          <textarea className="form-control" placeholder="Address" id="txtIndAddress" rows="3" name="txtIndAddress" defaultValue={Address} onChange={e => setAddress(e.target.value)}></textarea>
                        </div>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> State </p>
                        <select className="form-select" id="ddlPerState" name="ddlPerState" onChange={e => { getDistrictList(e.target.value); setStateId(e.target.value) }}>
                          <option key="0" value="0">Select State</option>
                          {StateList.map(item => (
                            <option key={item.StateId} value={item.StateId} selected={StateId === item.StateId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> District </p>
                        <select className="form-select" Id="ddlPerDistrictId" name="ddlPerDistrictId" onChange={e => { getCompanyTehsilList(e.target.value); setCompanyDistrictId(e.target.value) }}>
                          <option key="0" value="0">Select District</option>
                          {DistrictList.map(item => (
                            <option key={item.DistrictId} value={item.DistrictId} selected={CompanyDistrictId === item.DistrictId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label htmlFor="formFileSm" className="form-label">Tehsil</label>
                          </div>
                          <div>
                            {AddTehsil == "A" ? DistrictId > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddPerTehsil">+ Add New</a></span> : '' : ''}
                          </div>
                        </div>
                        <select className="form-select" id="ddlPerTehsil" name="ddlPerTehsil" onChange={e => { getCityList(e.target.value); setCompanyTehsilId(e.target.value) }}>
                          <option key="0" value="0">Select Tehsil</option>
                          {TehsilList.map(item => (
                            <option key={item.TehsilId} value={item.TehsilId} selected={CompanyTehsilId === item.TehsilId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label htmlFor="formFileSm" className="form-label">City/Village/Town</label>
                          </div>
                          <div>
                            {AddCity == "A" ? TehsilId > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddPerCity">+ Add New</a></span> : '' : ''}
                          </div>
                        </div>
                        <select className="form-select" id="ddlPerCity" name="ddlPerCity" onChange={e => setCityId(e.target.value)}>
                          <option key="0" value="0">Select City/Village/Town</option>
                          {CityList.map(item => (
                            <option key={item.CityId} value={item.CityId} selected={CompanyCityId === item.CityId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> PinCode </p>
                        <Input type="text" className="" id="txtPinCode" defaultValue={Pincode} onChange={e => setPincode(e.target.value)} placeholder="Enter PinCode" />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Branch Code </p>
                        <Input type="text" className="" id="txtBranchCode" defaultValue={Branchcode} onChange={e => setBranchCode(e.target.value)} placeholder="Enter Branch Code" />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="">
                      <Col className="col-lg-12 mb-2">
                        <CardTitle>Contact Information</CardTitle>
                        <hr />
                      </Col>
                      <Col lg="12">
                        <table style={{ width: "100%" }}>
                          <tbody>

                          </tbody>
                        </table>

                     {LeadContactList.map((item,idx)=>(   <div data-repeater-list="group-a" key={idx}>
                          <Row data-repeater-item className="align-items-end mb-3">
                            <Col lg="3" className="">
                              <Label htmlFor="name">
                                Name
                              </Label>
                              <Input type="text" id="txtName" className="form-control" placeholder="Enter Name" defaultValue={item.Name} />
                            </Col>
                            <Col lg="3" className="">
                              <Label htmlFor="name">
                                Email
                              </Label>
                              <Input type="text" id="txtEmail" className="form-control" placeholder="Enter Email" defaultValue={item.Email} />
                            </Col>
                            <Col lg="3" className="">
                              <Label htmlFor="name">
                                Mobile No
                              </Label>
                              <Input type="text" id="txtMobilenumber" className="form-control" placeholder="Enter Mobile No" defaultValue={item.Mobilenumber} />
                            </Col>
                            <Col lg="3" className="">
                              <Label htmlFor="name">
                                Designation
                              </Label>
                              <Input type="text" id="txtDesignation" className="form-control" placeholder="Enter Designation" defaultValue={item.Designation} />
                            </Col>


                          </Row>
                        </div>))
}
                      </Col>

                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="">
                      <Col className="col-lg-12 mb-2">
                        <CardTitle>Meeting Information</CardTitle>
                        <hr />
                      </Col>
                      <Row className="align-items-center">
                        <div id="CorporateListdiv" >
                          <Col lg="6" className="mb-3 col-md-6">
                            <Label htmlFor="name">Corporate</Label>
                            <select className="form-select" Id="ddlCorporate" name="ddlCorporate" onChange={e => getCorporateContactPersonList(e.target.value)}>
                              <option value={"0"}>Select Corporate</option>
                              {CorporateList.map((Corporate) => (
                                <option key={Corporate.CorporateId} value={Corporate.CorporateId} selected={Corporate.CorporateId == MeetCorporateId}> {Corporate.CorporateName} </option>
                              ))}
                            </select>   </Col>
                        </div>
                      </Row>
                      <Row className="align-items-center">

                        <Label htmlFor="name">Corporate Contact Information</Label>

                        {CorporateContactPersonList.map((item, idx) => (
                          item.IsSelect == 1 ?
                            <Col lg="3" className="col-md-6" key={idx}>
                              <input className="form-check-input" type="checkbox" id={item.ChkId} value={item.CorporateleadContactId} defaultChecked={1} />{" "}<label className="">{item.Name}</label>
                            </Col>
                            :
                            <Col lg="3" className="col-md-6" key={idx}>
                              <input className="form-check-input" type="checkbox" id={item.ChkId} value={item.CorporateleadContactId} defaultChecked={0} />{" "}<label className="">{item.Name}</label>
                            </Col>
                        ))}

                      </Row>
                      <Row className="align-items-center">
                        <Col lg="6" className="mb-3 col-md-6">
                          <Label htmlFor="name">Meeting Headnote</Label>
                          <Input type="text" id="txtMeetNote" className="form-control" placeholder="Enter Meeting HeadNote" onChange={e => setMeetNote(e.target.value)} />
                        </Col>
                        <Col lg="6" className="mb-3 col-md-6">
                          <Label htmlFor="name">Meeting Summary</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Input type="text" id="txtMeetSummary" className="form-control" placeholder="Enter Meeting Summary" onChange={e => setMeetSummary(e.target.value)} /> </div>
                        </Col>
                      </Row>
                      <Row className="">
                        <Col lg="4">
                          <table style={{ width: "100%" }}>
                            <tbody>
                              {MeetPointList?.map((item, idx) => (
                                <tr id={"addr" + idx} key={idx}>
                                  <td>
                                    <div data-repeater-list="group-a mb-3">
                                      <Row data-repeater-item className="align-items-center mobile-mt">
                                        <Col lg="9" className="mb-3 col-md-6">
                                          <Label htmlFor="name">Meeting Points</Label>
                                          <Input type="text" id={item.MeetPointTextBoxControlId} className="form-control" placeholder="Enter Meeting Agenda" defaultValue={item.Point} />
                                        </Col>

                                        <Col lg="2" className="col-5 col-md-2">
                                          <Button color="danger" className="mt-2 btn-sm btn-width" onClick={e =>
                                            MeetPointhandleRemoveRow(e, item.RowId, item.MeetPointId)
                                          }>
                                            <i className="fas fa-trash-alt"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item className="align-items-center">
                              <Col lg="9" className="mb-3 col-md-6">
                                <Label htmlFor="name">Meeting Points</Label>
                                <Input type="text" id="txtMeetingPoint" className="form-control" placeholder="Enter Meeting Points" />
                              </Col>
                              <Col lg="3">
                                <Button color="primary" className="mt-2 btn-sm" onClick={MeetPointhandleAddRow}>
                                  <i className="fas fa-plus me-2"></i>Add
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col lg="4">
                          <table style={{ width: "100%" }}>
                            <tbody>
                              {AttachmentList?.map((item, idx) => (
                                <tr id={"addr" + idx} key={idx}>
                                  <td>
                                    <div data-repeater-list="group-a mb-3">
                                      <Row data-repeater-item className="align-items-center mobile-mt">
                                        <Col lg="9" className="mb-3 col-md-6">
                                          <Label htmlFor="name">Attachment</Label>
                                          <span className="form-control form-control"><i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i><a href={item.AttachFilePath} target="_blank" rel="noopener noreferrer" >{item.AttachFile}</a></span>
                                          <input type="hidden" className="form-control form-control" id={item.UploadFileTextBoxControlId} defaultValue={item.AttachFilePath} />
                                        </Col>
                                        <Col lg="2" className="col-5 col-md-2">
                                          <Button color="danger" className="mt-2 btn-sm btn-width" onClick={e =>
                                            AttachmenthandleRemoveRow(e, item.RowId, item.AttachId)
                                          }>
                                            <i className="fas fa-trash-alt"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item className="align-items-center">
                              <Col lg="9" className="mb-3 col-md-6">
                                <Label htmlFor="name">Attachment</Label>

                                {DisplayAttachFile > 0 ?
                                  <span className="float-end text-primary font-12" id="spandoctitle">
                                    <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                                    <a href={AttachmentFilePath} target="_blank" rel="noopener noreferrer" >{AttachmentFile}</a></span> : ''}
                                <input type="file" className="form-control form-control" id="AttachfileId" onChange={AttachmentfetchPost} />

                              </Col>

                              <Col lg="3">
                                <Button color="primary" className="mt-2 btn-sm" onClick={AttachmenthandleAddRow}>
                                  <i className="fas fa-plus me-2"></i>Add
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col lg="4">
                          <table style={{ width: "100%" }}>
                            <tbody>
                              {MeetAgendaList?.map((item, idx) => (
                                <tr id={"addr" + idx} key={idx}>
                                  <td>
                                    <div data-repeater-list="group-a mb-3">
                                      <Row data-repeater-item className="align-items-center mobile-mt">
                                        <Col lg="9" className="mb-3 col-md-6">
                                          <Label htmlFor="name">Next Meeting Agenda</Label>
                                          <Input type="text" id={item.MeetAgendaTextBoxControlId} className="form-control" placeholder="Enter Meeting Agenda" defaultValue={item.Agenda} />
                                        </Col>

                                        <Col lg="2" className="col-5 col-md-2">
                                          <Button color="danger" className="mt-2 btn-sm btn-width" onClick={e =>
                                            MeetAgendahandleRemoveRow(e, item.RowId, item.MeetAgendaId)}>
                                            <i className="fas fa-trash-alt"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item className="align-items-center">
                              <Col lg="9" className="mb-3 col-md-6">
                                <Label htmlFor="name">Next Meeting Agenda</Label>
                                <Input type="text" id="txtMeetingAgenda" className="form-control" placeholder="Enter Meeting Agenda" />
                              </Col>
                              <Col lg="3">
                                <Button color="primary" className="mt-2 btn-sm" onClick={MeetAgendahandleAddRow}>
                                  <i className="fas fa-plus me-2"></i>Add
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col lg="4" className="mb-3 col-md-6">
                          <Label htmlFor="name">Meeting Date</Label>
                          <Input type="date" id="txtMeetDate" className="form-control" placeholder="Enter Meeting Date"  min={new Date().toISOString().split('T')[0]}  onChange={e => setMeetDate(e.target.value)} />
                        </Col>
                        <Col lg="4" className="mb-3 col-md-6">
                          <Label htmlFor="name">Meeting Time</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <input id="txtMeetTime" type="time" className="form-control" placeholder="Enter Meeting Time" onChange={e => setMeetTime(e.target.value)} />
                          </div>
                        </Col>
                        <Col lg="4" className="mb-3 col-md-6">
                          <Label htmlFor="name">Next Meeting Date</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <input id="txtNextMeetDate" type="date" className="form-control" placeholder="Enter Next Meeting Date"  min={new Date().toISOString().split('T')[0]}  onChange={e => setNextMeetDate(e.target.value)} />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className="col-lg-12">
                          <button
                            type="button"
                            className="btn btn_success me-2"
                            onClick={e => saveMeetDetails()}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn btn_danger"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Row>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-12 col-md-12 mb-2">
                        <CardTitle>Meeting Details List</CardTitle>
                        <hr />
                      </Col>
                      <Col className="col-lg-12 col-md-12 mt-3">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th className="text-center" scope="col">#</th>
                              <th className="text-center" scope="col">Meeting headnote</th>
                              <th className="text-center" scope="col">Meeting summary</th>
                              <th className="text-center" scope="col">Meeting Date</th>
                              <th className="text-center" scope="col">Meeting Time</th>
                              <th className="text-center" scope="col">Next Meeting date</th>
                              <th className="text-center" scope="col">Point</th>
                              <th className="text-center" scope="col">Agenda</th>
                              <th className="text-center" scope="col">Attachment</th>

                            </tr>
                          </thead>
                          <tbody>
                            {MeetDetails.map((record, index) => {
                              return (
                                <tr key={record.RowNo}>
                                  <td className="text-center">{record.Srno}</td>
                                  <td className="text-center">{record.Meetingheadnote}</td>
                                  <td className="text-center">{record.Meetingsummary}</td>
                                  <td className="text-center">{record.MeetingDate}</td>
                                  <td className="text-center">{record.Meetingtime}</td>
                                  <td className="text-center">{record.Nextmeetingdate}</td>
                                  <td className="text-center position-relative"><p className="mb-0" >{record.Point}</p>
                                    <small className="text-primary dropdown-toggle " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">View More</small>

                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                      {pointde.map((re, index) => { return (<li className="dropdown-item" key={re.mpoint}>{re.Point}</li>) })}
                                    </ul>

                                  </td>
                                  <td className="text-center position-relative"><p className="mb-0" >{record.Agenda}</p>
                                    <small className="text-primary dropdown-toggle " id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">View More</small>

                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">

                                      {agendade.map((re, index) => { return (<li className="dropdown-item" key={re.MAId}>{re.Agenda}</li>) })}
                                    </ul>

                                  </td>

                                  <td><span className="float-end text-primary font-12" id="spandoctitle">
                                    <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                                    <a href={record.Attachment} target="_blank" rel="noopener noreferrer" >{record.MediaFileName}</a></span></td>

                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

              </Col>

            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};


export default ManageMOMd;
