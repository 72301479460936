import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Label,
  Input,
} from "reactstrap";
import MetaTags from "react-meta-tags";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../../../assets/scss/datatables.scss"

// Table data
const products = [
  {
    Sr: 1,
    Bankname: "State Bank of India",
    Branchname: "Mohan Colony",
    Email: "sbi@gmail.com",
    Phone: "9874563213",
    status: "Approved",
    Pincode: "327001",
    action: (
      <>
        <i className="mdi mdi-pencil h5 me-3 text-success font-size-18 " data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"></i>
        <i className="mdi mdi-delete h5 me-3 text-danger font-size-18"></i>
      </>
    ),
  },
  {
    Sr: 2,
    Bankname: "Mangalam Associates",
    Branchname: "Ostwal Plaza",
    Email: "Mangalam@gmail.com",
    Phone: "9874563213",
    status: "Pending For Approval",
    Pincode: "313001",
    action: (
      <>
        <i className="mdi mdi-pencil h5 me-3 font-size-18 text-success " data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"></i>
        <i className="mdi mdi-delete h5 me-3 font-size-18 text-danger"></i>
      </>
    ),
  },

];

class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products,
    };
  }

  render() {
    const columns = [
      {
        dataField: "Sr",

        text: "Sr",
        sort: true,
      },
      {
        dataField: "Bankname",

        text: "Bank Name",
        sort: true,
      },
      {
        dataField: "Branchname",

        text: "Branch Name",
        sort: true,
      },
      {
        dataField: "Email",

        text: "Email",
        sort: true,
      },
      {
        dataField: "Phone",

        text: "Phone",
        sort: true,
      },
      {
        dataField: "status",

        text: "Approved",
        sort: true,
      },
      {
        dataField: "Pincode",

        text: "Pin Code",
        sort: true,
      },
      {
        dataField: "action",

        text: "Action",
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    };

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "25", value: 25 },
      { text: "All", value: this.state.productData.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;

    return (
      <React.Fragment>

<div className="offcanvas offcanvas-end offcanvas-add" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div className="offcanvas-header">
    <h5 id="offcanvasRightLabel">Edit Channel Information</h5>
    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div className="offcanvas-body">
  <Row>

<Col className="col-md-12">
  <CardTitle className="h6 mb-3 mt-3">Employee Specific</CardTitle>
</Col>
<Col className="col-md-4 mb-2">
  <Label htmlFor="name">Channel Type</Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>
<Col className="col-md-4  mb-2">
  <Label htmlFor="name">Channel Name</Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>
<Col className="col-md-4  mb-2">
  <Label htmlFor="name">Branch Name</Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>
<Col className="col-md-4  mb-2">
  <Label htmlFor="name">Branch Code</Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>
<Col className="col-md-4  mb-2">
  <Label htmlFor="name">IFSC Code</Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>
<Col className="col-md-4  mb-2">
  <Label htmlFor="name">Branch Location</Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>
<Col className="col-md-4  mb-2">
  <Label htmlFor="name">City</Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>
<Col className="col-md-4  mb-2">
  <Label htmlFor="name">Tehsil</Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>
<Col className="col-md-4  mb-2">
  <Label htmlFor="name">District</Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>
<Col className="col-md-4  mb-2">
  <Label htmlFor="name">State</Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>
<Col className="col-md-4  mb-2">
  <Label htmlFor="name">Pincode</Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>

</Row>
<Row>

<Col className="col-md-12">
  <CardTitle className="h6 mb-3 mt-3">Contact Person Details</CardTitle>
</Col>
<Col className="col-md-4 mb-2">
  <Label htmlFor="name">Name</Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>
<Col className="col-md-4  mb-2">
  <Label htmlFor="name">Mobile Number</Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>
<Col className="col-md-4  mb-2">
  <Label htmlFor="name">Alternate Mobile Number </Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>
<Col className="col-md-4  mb-2">
  <Label htmlFor="name">Email Address</Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>
<Col className="col-md-4  mb-2">
  <Label htmlFor="name">Alternate Email Address</Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>
<Col className="col-md-4  mb-2">
  <Label htmlFor="name">Landline Number</Label>
  <Input
    type="text"
    id="name"
    name="untyped-input"
    className="form-control"
    placeholder=""
  />
</Col>
<Col lg="12">
<div className="mb-3">
<label htmlFor="exampleFormControlTextarea1" className="form-label">Remarks</label>
<textarea className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
</div>
</Col>
<Col className="col-lg-4 co-md-6 col-6">
    <a className ="btn btn-primary btn-sm font-14" href="#">Save</a>
  </Col>

</Row>
  </div>
</div>

      
        <div className="page-content">
          <MetaTags>
          <title>Channel List | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
<Col className="col-lg-8 col-md-6 col-6">
  <div className="breadcrum ps-0">
      <CardTitle className="h6 mb-2">Channel List</CardTitle>
      <a href="#" className="me-2">Home</a> <span>/</span><span className="ms-2">Channel List</span>
  </div>
</Col>
  <Col className="col-lg-4 co-md-6 col-6">
    <a className ="btn btn-primary btn-sm float-end font-14" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
  </Col>
</Row> 

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>


                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col className="col-lg-4 col-md-4 col-6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-lg-8 col-md-8 col-6">
                                  <div className="float-end">
                                   
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light table-thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DatatableTables;
