import React,{ useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory,useParams  } from 'react-router-dom';
 
const EditState = () => { 
      const [CountryId, setCountryId] = useState('');
      const [CountryTitle, setCountryTitle] = useState('');
      const [StateId, setStateId] = useState('');
      const [Title, setTitle] = useState('');
      const history = useHistory();
      let { id } = useParams();

    useEffect(() => {
        if(id!=null)
        {
          if(id!="0")
          {
            let newid = id.split(':'); 
            let CId=localStorage.getItem("CountryId");
            let CT=localStorage.getItem("CountryTitle");
            setCountryId(CId);
            setCountryTitle(CT);
            getStateById(newid[1]);
          }
          else{
            history.push("/State");
          }
        }
        else{
          history.push("/State");
        }
    }, []);

      const updateProduct  = async (e) => {
        e.preventDefault();
        let STitle=event.target["txtState"].value; 
        if(STitle!='')
        {
            const StateArr = {
              StateId:StateId,
              CountryId:CountryId,
              Title: STitle,
              Flag:"A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
        
            }

            axios.post(global.APIURL+'/StateUpdate', StateArr)
            .then(res => 
            {
                    alert(res.data.StatusMessage);
                    history.push("/State");
            });
        }
    }

    const getStateById = async (SId) => {
      var AUrl=global.APIURL+'/State/'+SId;
        axios.get(AUrl)  
        .then(res => {  
           setTitle(res.data[0].Title);
           setStateId(res.data[0].StateId)
        })
    }

    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Edit State | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Edit State</CardTitle>
                <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/Country" className="me-2 ms-2">{CountryTitle}</a>
                <span>/</span>
                <a href="/State"><span className="ms-2 me-2">State List</span></a>
                <span>/</span>
                <span className="ms-2">Edit State</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/State">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={updateProduct}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">State</label>
                        <Input type="text" className="" id="txtState" defaultValue={Title}  placeholder="Enter State" />
                      </div>
                    </Col>
                    <Col lg={12}>
                        <input type="hidden" id="hdfStateId" value={StateId} />    
                        <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                        <a className="btn btn_danger btn-sm ms-2" href="/State">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
      </div>
    )
}

export default EditState;