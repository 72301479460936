import React, { Component} from "react"
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
 
const AddEmployeeType = () => { 
    const history = useHistory();
    const saveEmployeeType = async (e) => {
        e.preventDefault();
        let ETitle=event.target["txtEmployeeType"].value; 
        let EShortCode=event.target["txtShortCode"].value; 
        let EDescription=event.target["txtDescription"].value; 
        if(ETitle!='')
        {
            const EmployeeType = {
                Id:'0',
                Title: ETitle,
                ShortCode: EShortCode,
                Description: EDescription,
                Flag:"A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
                     
            }

            axios.post(global.APIURL+'/EmployeeType', EmployeeType)
            .then(res => 
            {
                    alert(res.data.StatusMessage);
                    history.push("/EmployeeType");
            });
        }
    }
    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Employee Type | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Add Employee Type</CardTitle>
                 <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/EmployeeType" className="me-2 ms-2">Employee Type</a>
                <span>/</span>
                <span className="ms-2">Add Employee Type</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/EmployeeType">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={saveEmployeeType}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Employee Type</label>
                        <Input type="text" className="form-control" id="txtEmployeeType" placeholder="Enter Employee Type"/>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Short Code</label>
                        <Input type="text" className="form-control" id="txtShortCode" placeholder="Enter Short Code"/>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-2">
                        <label className="form-label">Description</label>
                        <Input type="textarea" className="form-control" id="txtDescription" placeholder="Enter Description"/>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/AddEmployeeType">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
       
      </div>
    )
}

export default AddEmployeeType;