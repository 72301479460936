import React, {   useState ,useEffect} from "react"
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
 
const AddState = () => { 
    const history = useHistory();
    const [CountryId, setCountryId] = useState('');
    const [CountryTitle, setCountryTitle] = useState('');
    const saveState = async (e) => {  
        e.preventDefault();
        let StateTitle=event.target["txtState"].value; 
        if(StateTitle!='')
        {
            const StateArr = {
                StateId:'0',
                CountryId:CountryId,
                Title: StateTitle,
                Flag:"A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")             
            }

            axios.post(global.APIURL+'/State', StateArr)
            .then(res => 
            {
                    alert(res.data.StatusMessage);
                    history.push("/State");
            });
        }
    }

    useEffect(() => {
      let CId=localStorage.getItem("CountryId");
      let CT=localStorage.getItem("CountryTitle");
      setCountryId(CId);
      setCountryTitle(CT);
    });

    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Add State | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Add State</CardTitle>
                <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/Country" className="me-2 ms-2">{CountryTitle}</a>
                <span>/</span>
                <a href="/State" className="me-2 ms-2">State List</a>
                <span>/</span>
                <span className="ms-2">Add State</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/State">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={saveState}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">State</label>
                        <Input type="text" className="form-control" id="txtState" placeholder="Enter State"/>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/State">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
       
      </div>
    )
}

export default AddState;