import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label,
  Input,
} from "reactstrap"

import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import classnames from "classnames"

class UiTabsAccordions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col5: true,
      col6: true,
      col7: true,
      col8: true,
      col9: true,
      col10: false,
      col11: false,
    }
    this.toggle = this.toggle.bind(this)
    this.toggle1 = this.toggle1.bind(this)

    this.t_col1 = this.t_col1.bind(this)
    this.t_col2 = this.t_col2.bind(this)
    this.t_col3 = this.t_col3.bind(this)
    this.t_col5 = this.t_col5.bind(this)
    this.t_col6 = this.t_col6.bind(this)
    this.t_col7 = this.t_col7.bind(this)
    this.t_col8 = this.t_col8.bind(this)
    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)

    this.toggle2 = this.toggle2.bind(this)
    this.toggle3 = this.toggle3.bind(this)

    this.toggleVertical = this.toggleVertical.bind(this)
    this.toggleVerticalIcon = this.toggleVerticalIcon.bind(this)
    this.toggleCustom = this.toggleCustom.bind(this)
    this.toggleIconCustom = this.toggleIconCustom.bind(this)
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false
    })
  }

  t_col2() {
    this.setState({
      col1: false,
      col2: !this.state.col2,
      col3: false
    })
  }

  t_col3() {
    this.setState({
      col1: false,
      col2: false,
      col3: !this.state.col3
    })
  }

  t_col5() {
    this.setState({ col5: !this.state.col5 })
  }

  t_col6() {
    this.setState({ col6: !this.state.col6 })
  }

  t_col7() {
    this.setState({ col7: !this.state.col7 })
  }

  t_col8() {
    this.setState({
      col6: !this.state.col6,
      col7: !this.state.col7
    })
  }

  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false
    })
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false
    })
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      })
    }
  }

  toggle2(tab) {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      })
    }
  }

  toggle3(tab) {
    if (this.state.activeTab3 !== tab) {
      this.setState({
        activeTab3: tab,
      })
    }
  }

  toggleVertical(tab) {
    if (this.state.verticalActiveTab !== tab) {
      this.setState({
        verticalActiveTab: tab,
      })
    }
  }

  toggleVerticalIcon(tab) {
    if (this.state.verticalActiveTabWithIcon !== tab) {
      this.setState({
        verticalActiveTabWithIcon: tab,
      })
    }
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      })
    }
  }

  toggleIconCustom(tab) {
    if (this.state.customIconActiveTab !== tab) {
      this.setState({
        customIconActiveTab: tab,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Lead List| KC Admin Group</title>
          </MetaTags>
          <Container fluid={true}>
          <Row className="mb-2">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h3"> Edit Lead </CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Edit Lead</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
          <Card>
              <CardBody>
                <Row >
                  <Col className="col-md-10 col-8">
                    <div className="d-flex logo-main mb-3 gap-4">
                      <div className="bg-light profile_pic">
                        <p className="mb-0 text-center">User <br /> Photo
                        </p>
                      </div>
                          
                        <div className="align-self-center"> 
                          <h3 className="text-dark"> Rajal Chauhan </h3>
                          <div className="text-secondary mb-2"> Reilly Y. , HDFC, Gurukul , Ahmedabad </div>
                          <div className="d-flex gap-3">
                            <div className="bg_blue p-1 px-2 rounded-3"> Convert </div>
                            {/* <div className="bg_blue p-1 px-2 rounded-3"> Gujarat </div>
                            <div className="bg_blue p-1  px-2 rounded-3"> 380001 </div> */}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3 border-top pt-4">
                <CardTitle className="h3 mb-3"> Personal Details </CardTitle>
                <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">First Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="First Name"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Middle Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Middle Name"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Last Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Last Name"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Gender</label>
                            <select id="inputState" className="form-select">
                              <option selected>Gender</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">state</label>
                            <select id="inputState" className="form-select">
                              <option selected>State</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">District</label>
                            <select id="inputState" className="form-select">
                              <option selected>District</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">City</label>
                            <select id="inputState" className="form-select">
                              <option selected>City</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                        <div className="mb-3">
                        <label className="form-label">Tehsil</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Tehsil"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                        <div className="mb-3">
                        <label className="form-label">Pincode</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Pincode"
                            />
                          </div>
                        </Col>

                        <CardTitle className="h3 mb-3 mt-3"> Contact Information </CardTitle>
                        <Col lg={6}>
                        <div className="mb-3">
                        <label className="form-label">Mobile Number</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Enter 10digit Number"
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                        <div className="mb-3">
                        <label className="form-label">Email Address</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="abc@gmail.com"
                            />
                          </div>
                        </Col>   
                </Row>
                <Row>
                
                        <Col className="col-lg-6 col-8">
                        <CardTitle className="h3 mb-3 mt-3"> Banking Source </CardTitle>
                    <div className="d-flex logo-main mb-3 gap-4">
                      <div className="bg-light profile_pic">
                        <p className="mb-0 text-center">Bank <br></br>image
                        </p>
                      </div>
                          
                        <div className="align-self-center"> 
                          <h4 className="text-dark"> State Bank Of India </h4>
                          <div className="text-secondary mb-0"> Branch: Mohan Colony </div>
                          <div className="text-secondary mb-2"> IFSE Code : SBIN0056</div>
                          <div className="d-flex gap-3">
                            <div className="bg_blue p-1 px-2 rounded-3">Banswara </div>
                            <div className="bg_blue p-1 px-2 rounded-3">Rajasthan </div>
                            <div className="bg_blue p-1 px-2 rounded-3">327001 </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                
                  <Col className="col-lg-6">
                  <CardTitle className="h3 mb-3 mt-3"> Channel Partner </CardTitle>
         <div className="team-modal-box">
         <div className="d-flex align-items-center border-bottom pb-2">
               <div className="flex-shrink-0">
               <div className="avatar-md">
                 <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail rounded-circle" />
               </div>
               </div>
               <div className="flex-grow-1 ms-3">
               <div className="font-14 dark-font mb-1">Hardik Dudhrejiya</div>
                   <div className="mb-1"><a href="" className="table-link-a">Account Manager</a></div>
                   <div className="" ><a href="" className="table-link-a">State Bank Of India</a></div>
               </div>
             </div>
       
         <div className="modal-sub-box pt-2">
             <div className="department-box">
               <p className="mb-0"><i className="fas fa-phone-alt text-success me-2"></i>+91 4589236941</p>
             </div>
             <div className="emp-code">
             <p className="mb-0"><i className="fas fa-envelope text-success me-2"></i>Ella91@gmail.com</p>
             </div>
         </div>
         </div>
       </Col>
       <Col lg={12}>
         <CardTitle className="h3 mb-3 mt-3"> Reference </CardTitle>
                        <div className="mb-3">
                        <label className="form-label">Reference by</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="West Nichole"
                            />
                          </div>
                        </Col>
                </Row>
                <Row className="mb-4">
                <div className="col-lg-6">
                        <CardTitle className="h3 mb-3 mt-3"> Remarks </CardTitle>
                        <div className="mb-3">
                          <textarea className="form-control" placeholder="Add Remarks" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                        </div>
                       
                        <div className="col-lg-6">
                         <CardTitle className="h3 mb-3 mt-3"> Card Briefing </CardTitle>
                         <div className="mb-3">
                          <textarea className="form-control" placeholder="Add briefing" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                        </div>
                </Row>
                <Row>
                  <Col className="col-md-12">
                  <div className=""><a href="" className="btn btn_success me-2"> Save </a><a href="" className="btn btn_danger"> Cancel </a></div>

                  </Col>
                 </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default UiTabsAccordions
