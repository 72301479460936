import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle,Input, } from "reactstrap"
import MetaTags from 'react-meta-tags'
// import images



// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import "../../../assets/scss/datatables.scss"

// Table data
const products = [

 
  {
    "sr": 1,
    "name": <> <p className="fw-bold mb-1">Kartik Singh</p> <p className="mb-0"> KC00003 </p> </> ,
    "date": "29/06/2020",
    "actual time": "09:30 to 06:30	",
    "updated time": "10:30 to 07:30",
    "remarks": <a href="#"> Remarks </a>,
    "status":  <span className="badge rounded-pill badge-soft-success font-12">	Approved</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Approve </a>
                                    <a  className="d-block p-1" href="#"> Reject </a>
    </ul>
  </div>
  },

  {
    "sr": 2,
    "name": <> <p className="fw-bold mb-1">Kartik Singh</p> <p className="mb-0"> KC00003 </p> </> ,
    "date": "29/06/2020",
    "actual time": "09:30 to 06:30	",
    "updated time": "10:30 to 07:30",
    "remarks": <a href="#"> Remarks </a>,
    "status":  <span className="badge rounded-pill badge-soft-danger font-12">		Rejected</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Approve </a>
                                    <a  className="d-block p-1" href="#"> Reject </a>
    </ul>
  </div>
  },

  {
    "sr": 3,
    "name": <> <p className="fw-bold mb-1">Smith Jain</p> <p className="mb-0"> KC00003 </p> </> ,
    "date": "29/06/2020",
    "actual time": "09:30 to 06:30	",
    "updated time": "10:30 to 07:30",
    "remarks": <a href="#"> Remarks </a>,
    "status":  <span className="badge rounded-pill badge-soft-warning font-12">	Pending</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Approve </a>
                                    <a  className="d-block p-1" href="#"> Reject </a>
    </ul>
  </div>
  },

  {
    "sr": 4,
    "name": <> <p className="fw-bold mb-1">Kartik Singh</p> <p className="mb-0"> KC00003 </p> </> ,
    "date": "29/06/2020",
    "actual time": "09:30 to 06:30	",
    "updated time": "10:30 to 07:30",
    "remarks": <a href="#"> Remarks </a>,
    "status":  <span className="badge rounded-pill badge-soft-success font-12">	Approved</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Approve </a>
                                    <a  className="d-block p-1" href="#"> Reject </a>
    </ul>
  </div>
  },

  {
    "sr": 5,
    "name": <> <p className="fw-bold mb-1">Kartik Singh</p> <p className="mb-0"> KC00003 </p> </> ,
    "date": "29/06/2020",
    "actual time": "09:30 to 06:30	",
    "updated time": "10:30 to 07:30",
    "remarks": <a href="#"> Remarks </a>,
    "status":  <span className="badge rounded-pill badge-soft-danger font-12">		Rejected</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Approve </a>
                                    <a  className="d-block p-1" href="#"> Reject </a>
    </ul>
  </div>
  },

  {
    "sr": 6,
    "name": <> <p className="fw-bold mb-1">Smith Jain</p> <p className="mb-0"> KC00003 </p> </> ,
    "date": "29/06/2020",
    "actual time": "09:30 to 06:30	",
    "updated time": "10:30 to 07:30",
    "remarks": <a href="#"> Remarks </a>,
    "status":  <span className="badge rounded-pill badge-soft-warning font-12">	Pending</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Approve </a>
                                    <a  className="d-block p-1" href="#"> Reject </a>
    </ul>
  </div>
  },
  {
    "sr": 7,
    "name": <> <p className="fw-bold mb-1">Kartik Singh</p> <p className="mb-0"> KC00003 </p> </> ,
    "date": "29/06/2020",
    "actual time": "09:30 to 06:30	",
    "updated time": "10:30 to 07:30",
    "remarks": <a href="#"> Remarks </a>,
    "status":  <span className="badge rounded-pill badge-soft-success font-12">	Approved</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Approve </a>
                                    <a  className="d-block p-1" href="#"> Reject </a>
    </ul>
  </div>
  },

  {
    "sr": 8,
    "name": <> <p className="fw-bold mb-1">Kartik Singh</p> <p className="mb-0"> KC00003 </p> </> ,
    "date": "29/06/2020",
    "actual time": "09:30 to 06:30	",
    "updated time": "10:30 to 07:30",
    "remarks": <a href="#"> Remarks </a>,
    "status":  <span className="badge rounded-pill badge-soft-danger font-12">		Rejected</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Approve </a>
                                    <a  className="d-block p-1" href="#"> Reject </a>
    </ul>
  </div>
  },

  {
    "sr": 9,
    "name": <> <p className="fw-bold mb-1">Smith Jain</p> <p className="mb-0"> KC00003 </p> </> ,
    "date": "29/06/2020",
    "actual time": "09:30 to 06:30	",
    "updated time": "10:30 to 07:30",
    "remarks": <a href="#"> Remarks </a>,
    "status":  <span className="badge rounded-pill badge-soft-warning font-12">	Pending</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Approve </a>
                                    <a  className="d-block p-1" href="#"> Reject </a>
    </ul>
  </div>
  },
  {
    "sr": 10,
    "name": <> <p className="fw-bold mb-1">Kartik Singh</p> <p className="mb-0"> KC00003 </p> </> ,
    "date": "29/06/2020",
    "actual time": "09:30 to 06:30	",
    "updated time": "10:30 to 07:30",
    "remarks": <a href="#"> Remarks </a>,
    "status":  <span className="badge rounded-pill badge-soft-success font-12">	Approved</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Approve </a>
                                    <a  className="d-block p-1" href="#"> Reject </a>
    </ul>
  </div>
  },

  {
    "sr": 11,
    "name": <> <p className="fw-bold mb-1">Kartik Singh</p> <p className="mb-0"> KC00003 </p> </> ,
    "date": "29/06/2020",
    "actual time": "09:30 to 06:30	",
    "updated time": "10:30 to 07:30",
    "remarks": <a href="#"> Remarks </a>,
    "status":  <span className="badge rounded-pill badge-soft-danger font-12">		Rejected</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Approve </a>
                                    <a  className="d-block p-1" href="#"> Reject </a>
    </ul>
  </div>
  },

  {
    "sr": 12,
    "name": <> <p className="fw-bold mb-1">Smith Jain</p> <p className="mb-0"> KC00003 </p> </> ,
    "date": "29/06/2020",
    "actual time": "09:30 to 06:30	",
    "updated time": "10:30 to 07:30",
    "remarks": <a href="#"> Remarks </a>,
    "status":  <span className="badge rounded-pill badge-soft-warning font-12">	Pending</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Approve </a>
                                    <a  className="d-block p-1" href="#"> Reject </a>
    </ul>
  </div>
  },
  {
    "sr": 13,
    "name": <> <p className="fw-bold mb-1">Kartik Singh</p> <p className="mb-0"> KC00003 </p> </> ,
    "date": "29/06/2020",
    "actual time": "09:30 to 06:30	",
    "updated time": "10:30 to 07:30",
    "remarks": <a href="#"> Remarks </a>,
    "status":  <span className="badge rounded-pill badge-soft-success font-12">	Approved</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Approve </a>
                                    <a  className="d-block p-1" href="#"> Reject </a>
    </ul>
  </div>
  },

  {
    "sr": 14,
    "name": <> <p className="fw-bold mb-1">Kartik Singh</p> <p className="mb-0"> KC00003 </p> </> ,
    "date": "29/06/2020",
    "actual time": "09:30 to 06:30	",
    "updated time": "10:30 to 07:30",
    "remarks": <a href="#"> Remarks </a>,
    "status":  <span className="badge rounded-pill badge-soft-danger font-12">		Rejected</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Approve </a>
                                    <a  className="d-block p-1" href="#"> Reject </a>
    </ul>
  </div>
  },

  {
    "sr": 15,
    "name": <> <p className="fw-bold mb-1">Smith Jain</p> <p className="mb-0"> KC00003 </p> </> ,
    "date": "29/06/2020",
    "actual time": "09:30 to 06:30	",
    "updated time": "10:30 to 07:30",
    "remarks": <a href="#"> Remarks </a>,
    "status":  <span className="badge rounded-pill badge-soft-warning font-12">	Pending</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Approve </a>
                                    <a  className="d-block p-1" href="#"> Reject </a>
    </ul>
  </div>
  }

];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    const columns = [{
      dataField: 'sr',
      text: 'Sr',
      sort: true,
    }, {
      dataField: 'name',
      text: 'Name',
      sort: true
    }, {
      dataField: 'date',
      text: 'Date',
      sort: true
    }, {
      dataField: 'actual time',
      text: 'Actual Time',
      sort: true
    }, {
      dataField: 'updated time',
      text: 'Updated Time',
      sort: true
    }, {
      dataField: 'remarks',
      text: 'Remarks',
      sort: true
    }, {
      dataField: 'status',
      text: 'Status',
      sort: true
    }, {
      dataField: 'action',
      text: 'Action',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

  
  

    const { SearchBar } = Search;

    return (
      <React.Fragment>

        <div className="page-content">
          <MetaTags>
          <title>Time Change | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                      <CardTitle className="h6 mb-2">Time Change List</CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Time Change</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                    
                    {/* <a className ="btn btn-success btn-sm float-end font-14 me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" href="#"><i className="mdi mdi-plus align-middle me-1"></i>Add</a> */}
                  </Col>
              </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                                <Row className="mb-2">
                             
                                <Col className="col-lg-4 col-md-4 col-6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-lg-8 col-md-8 col-6">
              {/* <div className="float-end">
                      <Dropdown
                        isOpen={this.state.info_dropup111}
                        direction="left"
                        className="btn-group dropstart"
                        toggle={() =>
                          this.setState({
                            info_dropup111: !this.state.info_dropup111,
                          })
                        }
                      >
                        <DropdownToggle className="btn btn-primary-drop">
                          <i className="mdi mdi-filter"></i> Filter
                        </DropdownToggle>
                        <DropdownMenu data-popper-placement="left-start">
                          <DropdownItem>Select</DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem>Select</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div> */}
              </Col>
                              </Row>



                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                     
                                      classes={
                                        "table align-middle table-nowrap todo-text"
                                      }
                                      headerWrapperClasses={"thead-light table-thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>
        </div>
        <div className="offcanvas offcanvas-end offcanvas-add" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div className="offcanvas-header">
    <h5 id="offcanvasRightLabel">Add Channel Partner</h5>
    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div className="offcanvas-body">
    <Row>
        <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Name</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="First Name"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Designation/Role</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Branch Manager"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Department</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Department"
                      />
                    </div>
                   
         
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Mobile Number</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Mobile Number"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Alternate Mobile Number</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Alternate Number"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Email Address</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Email Address"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Alternate Email Address</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Alternate Email Address"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Visting Card</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Add Card"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Current Address</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Add Address"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">City</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Enter City"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Tehsil</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Add Tehsil"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">District</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Add District"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">State</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Add State"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Date of Birth</label>
                      <Input
                        type="date"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Alternate Email Address"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Date Of Anniversary</label>
                      <Input
                        type="date"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Alternate Email Address"
                      />
                    </div>
          </Col>
          <Col lg="12">
          <div className="mb-3">
<label htmlFor="exampleFormControlTextarea1" className="form-label">First Meeting Brief</label>
<textarea className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
</div>
          </Col>
          <div className="col-lg-4">
          <a className ="btn btn-primary btn-sm font-14" href="#">Save</a>
          </div>
       </Row>
  </div>
</div>



      </React.Fragment>
    )
  }
}

export default DatatableTables