import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';

const AddBranch = () => {
  const history = useHistory();
  const [CompanyList, setCompanyList] = useState([]);
  const [companyId, setcompanyId] = useState("");
  const saveProduct = async (e) => {

    e.preventDefault();
    let Title = e.target["txtTitle"].value;
    let Address = e.target["txtAddress"].value;
    let Contact = e.target["txtContact"].value;
    let Email = e.target["txtEmail"].value;

    if (Title != '') {
      const Department = {
        BranchId: '0',
        Title: Title,
        companyId: companyId,
        Address: Address,
        Contact: Contact,
        Email: Email,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")

      }
    
      axios.post(global.APIURL + '/Branch', Department)
        .then(res => {
          alert(res.data.StatusMessage);
          history.push("/Branch");
        });
    }
  }
  useEffect(() => {

    getCompanyList();
  }, []);

  const getCompanyList = async () => {
    var AUrl = global.APIURL + '/CompanyActive';
    axios.get(AUrl)
      .then(Tres => {
        let ActiveTag = Tres.data.map((kctag) => {
          return kctag
        })
        setCompanyList(ActiveTag);

      })
  }
  return (
    <div className="page-content">

      <MetaTags>
        <title>Branch | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Add Branch</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <a href="/Branch" className="ms-2 me-2">Branch</a>
              <span>/</span>
              <span className="ms-2">Add Branch</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-6">
            <a className="btn btn-primary btn-sm float-end font-14" href="/Branch">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <form onSubmit={saveProduct}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>


                  <Row>
                  <Col lg={3}>
                      <label className="form-label">Company</label>
                      <select className="form-select" name="ddlTags" onChange={(e) => setcompanyId(e.target.value)}>
                        <option value={"0"}>Select Company</option>
                        {CompanyList.map((item) => (
                          <option key={item.CompanyName} value={item.CompanyId}>
                            {item.CompanyName}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-2">
                        <label className="form-label">Title</label>
                        <Input type="text" className="form-control" placeholder="Title" id="txtTitle"/>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-2">
                        <label className="form-label">Contact</label>
                        <Input type="text" className="form-control" id="txtContact" placeholder="Contact"/>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-2">
                        <label className="form-label">Email</label>
                        <Input type="text" className="form-control" id="txtEmail" placeholder="Email"/>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <label className="form-label">Address</label>
                      <textarea className="form-control" placeholder="Address" id="Remarks" rows="1" name="txtAddress"></textarea>
                    </Col>
                    <Col lg={12} className="mt-3">
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>

    </div>
  )
}

export default AddBranch;