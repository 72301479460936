import React, { useEffect, useState } from "react";
import axios from 'axios'
import { Col, Row, CardTitle, Card, CardBody } from "reactstrap";
import { useHistory } from 'react-router-dom';

const UiRating = () => {
    const history = useHistory();
    const [ServicePackageList, setServicePackageList] = useState([]);
    const [ServiceWiseActivityList, setServiceWiseActivityList] = useState([]);
    const [ServiceActivitiesAdd, setServiceActivitiesAdd] = useState([]);
    const [PaymentType, setPaymentType] = useState('Full');
    const [ActionType, setActionType] = useState('Default');
    const [ButtonText, setButtonText] = useState('Next');

    useEffect(() => {
        getServicePackageList();
        getRegisterServicePackageList();
    }, []);

    const getServicePackageList = async () => {
        var AUrl = global.APIURL + '/ServicePackageActive';
        axios.get(AUrl).then(SPres => {
            let ServicePackageL = SPres.data.map((ServicePackage) => {
                return ServicePackage
            })
            setServicePackageList(ServicePackageL);
        })
    }

    const getRegisterServicePackageList = async () => {
        let VInLeadId = localStorage.getItem("ConvertIndividualLeadId") == null ? "0" : localStorage.getItem("ConvertIndividualLeadId") == "" ? "0" : localStorage.getItem("ConvertIndividualLeadId");
        let VFILeadId = localStorage.getItem("ConvertFirmLeadId") == null ? "0" : localStorage.getItem("ConvertFirmLeadId") == "" ? "0" : localStorage.getItem("ConvertFirmLeadId");
        let VFinalLeadId = VInLeadId == '0' ? VFILeadId : VInLeadId;
        const RegSerPackArr = {
            LeadId: VFinalLeadId
        };
        var RSAUrl = global.APIURL + '/LeadServicePackageEdit';
        axios.post(RSAUrl, RegSerPackArr).then(RSPPres => {
            let RegisterServicePackageList = RSPPres.data.map((RegisterServicePackage) => { return RegisterServicePackage })
            setServiceActivitiesAdd(RegisterServicePackageList);
            let SPTotalAmount = 0;
            let SPTotalRecivedAmount = 0;
            let SPTotalPendingAmount = 0;
            for (let sa = 0; sa < RSPPres.data.length; sa++) {
                let VServicePackageAmount = RSPPres.data[sa].ServicePackageAmount;
                let VServicePackagePaidAmount = RSPPres.data[sa].TotalRecivedAmount;
                let VServicePackageDueAmount = RSPPres.data[sa].TotalPendingAmount;
                SPTotalAmount = SPTotalAmount + VServicePackageAmount;
                SPTotalRecivedAmount = SPTotalRecivedAmount + VServicePackagePaidAmount;
                SPTotalPendingAmount = SPTotalPendingAmount + VServicePackageDueAmount;
            }
            document.getElementById("txtFinalAmount").value = SPTotalAmount;
            document.getElementById("txtTotalRecivedAmount").value = SPTotalRecivedAmount;
            document.getElementById("txtTotalPendingAmount").value = SPTotalPendingAmount;
        })
    }

    const getServiceWiseActivity = async () => {
        var SPId = document.getElementById("ddlServicePackage").value;
        var VPaymentType = document.getElementById("ddlPaymentType").value;
        var VActionType = document.getElementById("ddlActionType").value;
        setActionType(VActionType);
        setPaymentType(VPaymentType);
        getServicePackageDetail(SPId);
        var AUrl = global.APIURL + '/ServicePackageActivities/' + SPId;
        axios.get(AUrl).then(SPres => {
            let ServicePackAct = SPres.data.map((spa) => {
                return spa
            })
            setServiceWiseActivityList(ServicePackAct);
        })
    };


    const getServicePackageDetail = async (SPId) => {
        var VActionType = document.getElementById("ddlActionType").value;
        var AUrl = global.APIURL + '/ServicePackageEdit/' + SPId;
        axios.get(AUrl)
            .then(res => {
                var SPAmount = parseFloat(res.data[0].Amount == null ? '0' : res.data[0].Amount == '' ? '0' : res.data[0].Amount);
                if (VActionType == 'Default') {
                    document.getElementById("txtDefaultSWTotalAmount").value = SPAmount;
                }
                else if (VActionType == 'Discounted') {
                    document.getElementById("txtDiscountedSWTotalAmount").value = SPAmount;
                }
            })
    }

    const ServiceActivitiesAddRow = e => {
        var SPId = document.getElementById("ddlServicePackage").value;
        var VActionType = document.getElementById("ddlActionType").value;
        var ServicesPackageAmount = 0;

        if (VActionType == 'Default') {
            var SPA = document.getElementById("txtDefaultSWTotalAmount").value;
            ServicesPackageAmount = parseFloat(SPA == null ? '0' : SPA == '' ? '0' : SPA);
        }
        else if (VActionType == 'Discounted') {
            var SPA = document.getElementById("txtDiscountedSWTotalAmount").value;
            ServicesPackageAmount = parseFloat(SPA == null ? '0' : SPA == '' ? '0' : SPA);
        }

        var ServiceActivitiesFilter = ServiceActivitiesAdd.filter(x => x.ServicePackageId == SPId);
        if (ServiceActivitiesFilter.length == 0) {
            var AUrl = global.APIURL + '/ServicePackageEdit/' + SPId;
            axios.get(AUrl).then(res => {
                if (res.data.length > 0) {
                    const ServiceActivitiesItem = {
                        RowId: (ServiceActivitiesAdd.length + 1),
                        ServicePackageId: res.data[0].ServicePackageId,
                        ServicePackage: res.data[0].Title,
                        ServicePackageAmount: ServicesPackageAmount,
                        PaymentType: document.getElementById("ddlPaymentType").value,
                        ActionType: document.getElementById("ddlActionType").value,
                        EntryType: 'N',
                        TotalRecivedAmount: 0,
                        TotalPendingAmount: ServicesPackageAmount
                    };

                    setServiceActivitiesAdd([...ServiceActivitiesAdd, ServiceActivitiesItem]);
                    var STAmount = document.getElementById("txtFinalAmount").value;
                    var STDmount = document.getElementById("txtTotalPendingAmount").value;
                    var FinalAmount = parseFloat(STAmount) + parseFloat(ServicesPackageAmount);
                    var DueAmount = parseFloat(STDmount) + parseFloat(ServicesPackageAmount);
                    document.getElementById("txtFinalAmount").value = FinalAmount;
                    document.getElementById("txtTotalPendingAmount").value = DueAmount;
                    document.getElementById("ddlServicePackage").value = '0';
                    document.getElementById("ddlPaymentType").value = 'Full';
                    document.getElementById("ddlActionType").value = 'Default';
                    ServiceWiseActivityList.length = 0;
                }
            })
            if (VActionType == 'Default') {
                document.getElementById("txtDefaultSWTotalAmount").value = '0';
            }
            else if (VActionType == 'Discounted') {
                document.getElementById("txtDiscountedSWTotalAmount").value = '0';
            }
            setButtonText('Save & Next');
        }
        else {
            alert("Service package add already exists.");
        }
    };

    const ServicePackageRegister = async () => {
        let VInLeadId = localStorage.getItem("ConvertIndividualLeadId") == null ? "0" : localStorage.getItem("ConvertIndividualLeadId") == "" ? "0" : localStorage.getItem("ConvertIndividualLeadId");
        let VFILeadId = localStorage.getItem("ConvertFirmLeadId") == null ? "0" : localStorage.getItem("ConvertFirmLeadId") == "" ? "0" : localStorage.getItem("ConvertFirmLeadId");
        let VFinalLeadId = VInLeadId == '0' ? VFILeadId : VInLeadId;
        var InsertRowCount = 0;
        for (let spa = 0; spa < ServiceActivitiesAdd.length; spa++) {
            var SPId = ServiceActivitiesAdd[spa].ServicePackageId;
            var VEntryType = ServiceActivitiesAdd[spa].EntryType;
            var PA = ServiceActivitiesAdd[spa].ServicePackageAmount;
            if (VEntryType == "N") {
                var AUrl = global.APIURL + '/ServicePackageEdit/' + SPId;
                axios.get(AUrl).then(res => {
                    if (res.data.length > 0) {
                        //var PA = res.data[0].ServicePackageAmount
                        var GST = res.data[0].GST
                        var PAGSTAmount = (parseFloat(PA) * parseFloat(GST)) / 100;

                        const SerPackRegArr = {
                            LeadServicePackageId: 0,
                            ServicePackageId: ServiceActivitiesAdd[spa].ServicePackageId,
                            LeadId: VFinalLeadId,
                            PackageAmount: ServiceActivitiesAdd[spa].ServicePackageAmount,
                            PackageGSTAmount: PAGSTAmount,
                            TotalAmount: ServiceActivitiesAdd[spa].ServicePackageAmount,
                            TotalRecivedAmount: "0",
                            TotalPendingAmount: ServiceActivitiesAdd[spa].TotalPendingAmount,
                            PaymentType: ServiceActivitiesAdd[spa].PaymentType,
                            ActionType: ServiceActivitiesAdd[spa].ActionType,
                            PaymentStaus: 'Due',
                            CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
                        };

                        axios.post(global.APIURL + "/ServicePackageRegister", SerPackRegArr).then(res => {
                            if (spa == (ServiceActivitiesAdd.length - 1)) {
                              
                                alert('Lead service package register successfully');
                                history.push("/ServicesPackagePayment");
                                InsertRowCount++;
                            }
                          
                        });
                    }
                })
            }
        }
    };


    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col className="">
                                    <CardTitle>
                                        Services Package Registration
                                        <hr className="mt-1 mb-2" />
                                    </CardTitle>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-lg-12 col-md-12 col-12">
                                    <Row>
                                        <Col className="col-md-3 col-12">
                                            <div className="mb-3">
                                                <label> Service Package </label>
                                                <select id="ddlServicePackage" className="form-select" aria-label="Default select example" onChange={e => getServiceWiseActivity()}>
                                                    <option key="0" value="0">Select Service Package</option>
                                                    {ServicePackageList.map((item) => (
                                                        <option key={item.ServicePackageId} value={item.ServicePackageId}>{item.Title}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col className="col-md-3 col-12">
                                            <div className="mb-3">
                                                <label> Payment Type </label>
                                                <select id="ddlPaymentType" className="form-select" aria-label="Default select example" onChange={e => getServiceWiseActivity()}>
                                                    <option value="Full">Full</option>
                                                    <option value="Installment">Installment</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col className="col-md-3 col-12">
                                            <div className="mb-3">
                                                <label> Action Type </label>
                                                <select id="ddlActionType" className="form-select" aria-label="Default select example" onChange={e => getServiceWiseActivity()}>
                                                    <option value="Default">Default</option>
                                                    <option value="Discounted">Discounted</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col className="col-md-2 col-12">
                                            <div className="mb-3">
                                                <label> Services Package Amount </label>
                                                {ActionType == 'Discounted' ? <input type="text" id="txtDiscountedSWTotalAmount" placeholder="" className="form-control" /> : ''}
                                                {ActionType == 'Default' ? <input type="text" id="txtDefaultSWTotalAmount" readOnly placeholder="" className="form-control" /> : ''}
                                            </div>
                                        </Col>
                                        <Col className="col-md-1 col-12">
                                            <button onClick={ServiceActivitiesAddRow} className="btn btn_success servicespackagebtnsuccess">+{"    "}Add</button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-md-12 col-12">
                                    <Col className="col-lg-12 mb-2">
                                        <CardTitle>Service Wise Activity List</CardTitle>
                                        <hr />
                                    </Col>
                                    <div className="table-responsive mb-3 mt-3">
                                        <table className="table table-sm table-bordered align-middle">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Activities</th>
                                                    <th className="textaligncenter">Milestone</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ServiceWiseActivityList.map(item => (
                                                    <tr key={item.ActivitiesId}>
                                                        <td>{item.RowId}</td>
                                                        <td>{item.ActivitiesTitle}</td>
                                                        <td className="textaligncenter">{item.MilestoneAlias}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                </Col>

                                <Col className="col-md-12 col-12">
                                    <CardTitle>Service Package List</CardTitle>
                                    <div className="table-responsive mt-3 mb-3">
                                        <table className="table table-sm table-bordered align-middle">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Service Package</th>
                                                    <th className="text-end">Total Amount </th>
                                                    <th className="text-end">Paid Amount </th>
                                                    <th className="text-end">Due Amount </th>
                                                    <th className="textalignend">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ServiceActivitiesAdd.map(item => (
                                                    <tr key={item.RowId}>
                                                        <th>{item.RowId}</th>
                                                        <td>{item.ServicePackage}</td>
                                                        <td className="text-end"> {item.ServicePackageAmount}</td>
                                                        <td className="text-end"> {item.TotalRecivedAmount}</td>
                                                        <td className="text-end"> {item.TotalPendingAmount}</td>
                                                        <td className="textaligncenter">{item.EntryType == "N" ? <a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a> : ''}</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <th></th>
                                                    <td className="textalignend">Total Amount : </td>
                                                    <td className="text-end"><input type="text" id="txtFinalAmount" readOnly defaultValue={0} placeholder="" className="form-control border_greyinput text-end" /></td>
                                                    <td className="text-end"><input type="text" id="txtTotalRecivedAmount" readOnly defaultValue={0} placeholder="" className="form-control border_greyinput text-end" /></td>
                                                    <td className="text-end"><input type="text" id="txtTotalPendingAmount" readOnly defaultValue={0} placeholder="" className="form-control border_greyinput text-end" /></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                    <div className="mb-3 text-end">
                                        <button className="btn btn_success" onClick={e => ServicePackageRegister()}>{" "}{ButtonText}{" "}</button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}
export default UiRating;