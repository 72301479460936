import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import logoLightSvg from "assets/images/logo.png";

import PlacholderImgC from "assets/images/favicon.png";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import html2canvas from "html2canvas";


const OnSort = () => {

  const [records, setRecords] = useState([])
  const [InvoiceItemList, setInvoiceItemList] = useState([])
  const [RightsAdd, setRightsAdd] = useState("D");
  const [RightsUpdate, setRightsUpdate] = useState("D");
  const [RightsDelete, setRightsDelete] = useState("D");
  const [PrintInvoiceItemList, setPrintInvoiceItemList] = useState([])

  const [InvoiceNumber, setInvoiceNumber] = useState('')
  const [InvoiceDate, setInvoiceDate] = useState('')
  const [InvoiceName, setInvoiceName] = useState('')
  const [InvoiceMobileNo, setInvoiceMobileNo] = useState('')
  const [InvoiceEmailAddress, setInvoiceEmailAddress] = useState('')
  const [InvoiceGSTNumber, setInvoiceGSTNumber] = useState('')
  const [InvoiceCompanyName, setInvoiceCompanyName] = useState('')
  const [InvoiceAddress, setInvoiceAddress] = useState('')
  const [InvoiceSubTotal, setInvoiceSubTotal] = useState(0)
  const [InvoiceCGSTAmount, setInvoiceCGSTAmount] = useState(0)
  const [InvoiceSGSTAmount, setInvoiceSGSTAmount] = useState(0)
  const [InvoiceIGSTAmount, setInvoiceIGSTAmount] = useState(0)
  const [InvoiceTotalAmount, setInvoiceTotalAmount] = useState(0)

  const [InvoiceState, setInvoiceState] = useState('')

  let columns = [
    {
      d: "#",
      text: "#",
      cell: (record, index) => {
        let RowIndex = index + 1;
        return (
          <Fragment>
            {RowIndex}
          </Fragment>
        );
      }
    },
    {
      d: "InvoiceNumber",
      text: "Invoice Number ",
      cell: (record, index) => {
        let VInvoiceNumber = record["InvoiceNumber"];
        return (
          <Fragment>
            <p className="mb-1">{VInvoiceNumber}</p>
          </Fragment>
        );
      }
    },
    {
      d: "InvoiceType",
      text: "Invoice Type ",
      cell: (record, index) => {
        let VInvoiceType = record["InvoiceType"];
        return (
          <Fragment>
            <p className="mb-1">{VInvoiceType}</p>
          </Fragment>
        );
      }
    },
    {
      d: "ClientDetail",
      text: "Client Detail ",
      cell: (record, index) => {
        let VInvoiceName = record["InvoiceName"];
        let VInvoiceEmailAddress = record["InvoiceEmailAddress"];
        let VInvoiceMobileNo = record["InvoiceMobileNo"];
        return (
          <Fragment>
            <p className="mb-1">{VInvoiceName}</p>
            <p className="mb-1">{VInvoiceEmailAddress}</p>
            <p className="mb-1">{VInvoiceMobileNo}</p>
          </Fragment>
        );
      }
    },
    {
      d: "GST Number &  Company Name",
      text: "GST Number &  Company Name",
      cell: (record, index) => {
        let VInvoiceGSTNumber = record["InvoiceGSTNumber"];
        let VInvoiceCompanyName = record["InvoiceCompanyName"];
        return (
          <Fragment>
            <p className="mb-1">{VInvoiceGSTNumber}</p>
            <p className="mb-1">{VInvoiceCompanyName}</p>
          </Fragment>
        );
      }
    },
    {
      d: "BillDate",
      text: "Bill Date",
      cell: (record, index) => {
        let VBillDate = record["BillDate"];
        return (
          <Fragment>
            <p className="mb-1">{VBillDate}</p>
          </Fragment>
        );
      }
    },
    {
      d: "DueDate",
      text: "Due Date",
      cell: (record, index) => {
        let VDueDate = record["DueDate"];
        return (
          <Fragment>
            <p className="mb-1">{VDueDate}</p>
          </Fragment>
        );
      }
    },
    {
      d: "SubTotal",
      text: "Amount",
      cell: (record, index) => {
        let VSubTotal = record["SubTotal"];
        return (
          <Fragment>
            <p className="mb-1">{VSubTotal}</p>
          </Fragment>
        );
      }
    },
    {
      d: "GSTAmount",
      text: "GST Amount",
      cell: (record, index) => {
        let VGSTAmount = record["GSTAmount"];
        return (
          <Fragment>
            <p className="mb-1">{VGSTAmount}</p>
          </Fragment>
        );
      }
    },
    {
      d: "TotalAmount",
      text: "Total Amount",
      cell: (record, index) => {
        let VTotalAmount = record["TotalAmount"];
        return (
          <Fragment>
            <p className="mb-1">{VTotalAmount}</p>
          </Fragment>
        );
      }
    },
    {
      d: "action",
      text: "Action",
      cell: (record, index) => {
        return (
          <Fragment>
            <div className="text-center">
              <a className="text-info me-3" onClick={(DetailRecord.bind(this, record))}><i className="mdi mdi-eye font-size-18"></i></a>
              <a className="text-warning" onClick={(PrintInvoiceRecord.bind(this, record))}><i className="mdi mdi-cloud-print font-size-18"></i></a><br />
              {RightsUpdate == "A" ? <a className="me-3 text-primary" onClick={(EditDetailRecord.bind(this, record))}><i className="mdi mdi-pencil font-size-18"></i></a> : ''}
              {RightsDelete == "A" ? <a className={IconrenderSwitchColor(record)} onClick={(DeleteRecord.bind(this, record))} ><i className={IconrenderSwitch(record)}></i></a> : ''}
            </div>
          </Fragment>
        );
      }
    }
  ];

  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
    button: {
      excel: true,
      print: true,
      csv: true
    }
  }



  useEffect(() => {

    const TableFilter = document.getElementsByClassName('table_filter');
    for (const tf of TableFilter) {
      tf.style.display = 'none';
    }

    const f = {
      MenuId: "70",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {
        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add') {
            setRightsAdd(MS);
          }
          else if (MA == 'Update') {
            setRightsUpdate(MS);
          }
          else if (MA == 'Delete') {
            setRightsDelete(MS);
          }
        }
      });

    getInvoiceList();

  }, []);

  const EditDetailRecord = (record) => {
    let VInvoiceId = record["Id"]
    localStorage.setItem('EditInvoiceId', VInvoiceId);
    window.location.href = "/InvoiceManage/EditInvoice";
  }

  const DeleteRecord = (record) => {
    let VId = record["Id"]
    let VFlag = record["Flag"]
    if (VFlag == "A") {
      const ItemArr = {
        Id: parseInt(VId == null ? "0" : VId == "" ? "0" : VId),
        Flag: 'D'
      }
      axios.post(global.APIURL + "/InvoiceDelete", ItemArr)
        .then(res => {
          var DStatus = res.data[0].RecordStatus;
          if (DStatus === 'Success') {
            alert('Record Inactive Successfully.')
          }
          else {
            alert('Opps! Something Went Wrong.')
          }
        });

      getInvoiceList();
    }
    else if (VFlag == "D") {
      const ItemArr = {
        Id: parseInt(VId == null ? "0" : VId == "" ? "0" : VId),
        Flag: 'A'
      }
      axios.post(global.APIURL + "/InvoiceDelete", ItemArr)
        .then(res => {
          var DStatus = res.data[0].RecordStatus;
          if (DStatus === 'Success') {
            alert('Record Active Successfully.')
          }
          else {
            alert('Opps! Something Went Wrong.')
          }
        });
      getInvoiceList();
    }
  }

  const DetailRecord = (record) => {
    let id = record["Id"];
    const ItemArr = {
      Id: parseInt(id == null ? "0" : id == "" ? "0" : id)
    }

    axios.post(global.APIURL + "/InvoiceItemDetail", ItemArr)
      .then(res => {
        setInvoiceItemList(res.data);
        $('#MPItemList').modal('show');
      });

  }

  const PrintInvoiceRecord = (record) => {
    let id = record["Id"];

    const ItemArr = {
      InvoiceId: id
    }

    console.log(ItemArr);

    axios.post(global.APIURL + "/PrintInvoiceDetail", ItemArr)
      .then(res => {

        setInvoiceNumber(res.data.InvoiceDetail[0].InvoiceNumber == null ? '' : res.data.InvoiceDetail[0].InvoiceNumber);

        setInvoiceDate(res.data.InvoiceDetail[0].DBillDate == null ? '' : res.data.InvoiceDetail[0].DBillDate);

        setInvoiceName(res.data.InvoiceDetail[0].InvoiceName == null ? '' : res.data.InvoiceDetail[0].InvoiceName);

        setInvoiceMobileNo(res.data.InvoiceDetail[0].InvoiceMobileNo == null ? '' : res.data.InvoiceDetail[0].InvoiceMobileNo);

        setInvoiceEmailAddress(res.data.InvoiceDetail[0].InvoiceEmailAddress == null ? '' : res.data.InvoiceDetail[0].InvoiceEmailAddress);

        setInvoiceGSTNumber(res.data.InvoiceDetail[0].InvoiceGSTNumber == null ? '' : res.data.InvoiceDetail[0].InvoiceGSTNumber);

        setInvoiceCompanyName(res.data.InvoiceDetail[0].InvoiceCompanyName == null ? '' : res.data.InvoiceDetail[0].InvoiceCompanyName);

        setInvoiceAddress(res.data.InvoiceDetail[0].InvoiceAddress == null ? '' : res.data.InvoiceDetail[0].InvoiceAddress);

        setInvoiceSubTotal(res.data.InvoiceDetail[0].SubTotal == null ? '' : res.data.InvoiceDetail[0].SubTotal);

        setInvoiceCGSTAmount(res.data.InvoiceDetail[0].CGSTAmount == null ? '' : res.data.InvoiceDetail[0].CGSTAmount);

        setInvoiceSGSTAmount(res.data.InvoiceDetail[0].SGSTAmount == null ? '' : res.data.InvoiceDetail[0].SGSTAmount);

        setInvoiceIGSTAmount(res.data.InvoiceDetail[0].IGSTAmount == null ? '' : res.data.InvoiceDetail[0].IGSTAmount);

        setInvoiceTotalAmount(res.data.InvoiceDetail[0].TotalAmount == null ? '' : res.data.InvoiceDetail[0].TotalAmount);

        setInvoiceState(res.data.InvoiceDetail[0].InvoiceState == null ? '' : res.data.InvoiceDetail[0].InvoiceState)

        setPrintInvoiceItemList(res.data.InvoiceItemDetail);

        console.log(res.data.InvoiceItemDetail);

        $('#MPPrintInvoice').modal('show');
      });



  }

  function IconrenderSwitch(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'mdi mdi-close font-size-18';
      default:
        return 'mdi mdi-check-all font-size-18';
    }
  }

  function IconrenderSwitchColor(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }

  function IconrenderSwitch(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'mdi mdi-close font-size-18';
      default:
        return 'mdi mdi-check-all font-size-18';
    }
  }

  function IconrenderSwitchColor(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }

  const getInvoiceList = async () => {
    var QuickSearch = document.getElementById("txtQuickSearch").value;
    var InvoiceType = document.getElementById("ddlInvoiceType").value;
    var StartDate = document.getElementById("txtStartDate").value;
    var EndDate = document.getElementById("txtEndDate").value;
    var RecordStatus = document.getElementById("ddlRecordStatus").value;

    const LeadData = {
      QuickSearch: QuickSearch,
      InvoiceType: InvoiceType,
      FromDate: StartDate,
      ToDate: EndDate,
      RecordStatus: RecordStatus
    }

    axios.post(global.APIURL + "/InvoiceList", LeadData)
      .then(CLres => {
        let VInvoiceList = CLres.data.map(vcl => {
          return vcl;
        });
        setRecords(VInvoiceList);
      });
  };

  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }

  const exportPDF = () => {

    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "All Inquiry List Report";
    const headers = [["#", "FullName", "Lead Code", "Created Date", "Lead Type", "Source", "Source-City", "Sub-Source", "Sub-Source Designation", "Email", "Contact Number", "Tags", "Priority", "Enroll Status", "Status"]];

    const data = records.map((item, index) => [index + 1, item.FullName, item.LeadCode, item.CreateDate, item.LeadType, item.ChannelName + ` (#` + (item.ChannelCode) + `)`, item.City, item.ChannelPartnerName, item.Designation, item.EmailAddress, item.MobileNo, item.Tags, item.Priority, item.LeadEnrollTitle, item.LeadStatus]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("AllInquiryListReport.pdf")
  }

  function printDocument() {

    // htmlToImage.toPng(document.getElementById('divprintreceiptmindiv'), { quality: 0.95 })
    // .then(function (dataUrl) {
    //   var link = document.createElement('a');
    //   link.download = 'my-image-name.jpeg';
    //   const pdf = new jsPDF();
    //   const imgProps= pdf.getImageProperties(dataUrl);
    //   const pdfWidth = pdf.internal.pageSize.getWidth();
    //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //   pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight);
    //   pdf.save("download.pdf"); 
    // });

    html2canvas(document.getElementById('divInvoiceDetail'), { quality: 0.95 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      var Pdfname = InvoiceNumber + 'InvoiceReceipt.pdf';
      pdf.save(Pdfname);
    });
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>All Inquiry | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">
        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-12 mb-2">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Invoice Manage</CardTitle>
              <a href="/" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <span className="ms-2">Invoice Manage</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-12 text-end">
            <a className="btn btn-primary btn-sm font-14 me-2 " data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
              <i className="fas fa-filter"></i>
            </a>
            {RightsAdd == "A" ? <a className="btn btn-primary btn-sm  font-14 me-2" href="/InvoiceManage/AddInvoice">
              <i className="mdi mdi-plus align-middle me-1"></i>Add New
            </a> : ''}
            <ReactHTMLTableToExcel
              className="btn btn-primary btn-sm float-end font-14 me-2"
              table="emp"
              filename="AllInqReport"
              sheet="Sheet"
              buttonText="Export Excel" />
            <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
          </Col>
        </Row>
        <div className="modal fade bd-example-modal-sm" id="MPItemList" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Invoice Item Detail</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>
                  <Col lg={12} >
                    <div className='maindasboardtableeditnew mt-0 verticalaligntable'>
                      <table className="table">
                        <thead className="table-light">
                          <tr>
                            <th scope="col" className='text-center'>#</th>
                            <th scope="col" className='text-center'>Particular</th>
                            <th scope="col" className="text-center">Amount</th>
                            <th scope="col" className='text-center'>Is Gst Apply</th>
                            <th scope="col" className='text-center'>GST Amount</th>
                            <th scope="col" className='text-center'>Total Amount</th>
                            <th scope="col" className='text-center'>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {InvoiceItemList.map((item, index) => (
                            <tr key={index}>
                              <td className='text-center'>{index + 1}</td>
                              <td className='text-center'>{item.Particular}</td>
                              <td className='text-center'>{item.Amount}</td>
                              <td className='text-center'>{item.IsGstApply}</td>
                              <td className='text-center'>{item.IsGstApply === 'Yes' ? item.GSTAmount + ' (' + item.GSTPer + '%' + ')' : item.GSTAmount}</td>
                              <td className='text-center'>{item.TotalAmount}</td>
                              <td className='text-center'>{item.Description}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Col>

                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-sm" id="MPPrintInvoice" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Invoice</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
              </div>
              <div className="modal-body">
                <div className="main-page">
                  <div className="sub-pagect" id="divInvoiceDetail">

                    <div className="mainheaderdiv">
                      <div className="maindivneww">

                        <img src={logoLightSvg} className="raasapdflogo" alt="" />

                      </div>
                      <div className="mainheaderrightside">
                        <p className="maintextinvoice">TAX INVOICE</p>

                        <p className="commonheadertext mt-2">Invoice Number: #{InvoiceNumber}</p>
                        <p className="commonheadertext">Invoice Date: {InvoiceDate}</p>
                      </div>
                    </div>
                    <div className="mainadddd">
                      <div className="address11">
                        <p className="commonaddresstitle">Bill To:</p>

                        <p className="addresspersonname mt-2">{InvoiceName}</p>
                        {/* <p className="commonaddresstext">{InvoiceEmailAddress}</p> */}
                        <p className="commonaddresstext">{InvoiceMobileNo}</p>

                        {InvoiceGSTNumber != '' ? <p className="commonaddresstext">GST No {InvoiceGSTNumber}</p> : ''}
                        <p className="commonaddresstext">{InvoiceAddress}</p>

                      </div>
                      <div className="address111">
                        <div className="d-flex justify-content-end">
                          <div >
                            <p className="commonaddresstitle">Bill From:
                            </p>

                            <p className="addresspersonname mt-2">
                            KC FINANCIAL SOLUTIONS PRIVATE LIMITED.
                            </p>
                            <p className="commonaddresstext">3/139, Khandu Colony, Khandu Colony, Banswara,
Banswara, Rajasthan, 327001.
                            </p>
                            <p className="commonaddresstext">info@kcfinserv.in
                            </p>
                            <p className="commonaddresstext">+91 9460429835
                            </p>
                            <p className="commonaddresstext">GST No: 08AAICK1954A1ZR
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table-responsive tablemain">
                          <table className="table table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" className="invoicehead invoicetextcenter">No.</th>
                                <th scope="col" className="descriptiontable invoicehead">Particular</th>
                                <th scope="col" className="invoicehead invoicetextright">Amount</th>
                                <th scope="col" className="invoicehead invoicetextright">CGST Amount</th>
                                <th scope="col" className="invoicehead invoicetextright">SGST Amount</th>
                                <th scope="col" className="invoicehead invoicetextright">IGST Amount</th>
                                <th scope="col" className="invoicehead invoicetextright">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {PrintInvoiceItemList.map((item, index) => (
                                <tr key={'InvoiceItem' + index}>
                                  <td className="tdinovice invoicetextcenter">{item.RowNo}</td>
                                  <td className="tdinovice invoicetextleft"><b>{item.Particular}</b><br />{item.Description}</td>
                                  <td className="tdinovice invoicetextright">{item.Amount}</td>
                                  <td className="tdinovice invoicetextright">{item.CGSTAmount}{item.CGSTAmount > 0 ? ' (' + item.CGSTPer + '%)' : ''}</td>
                                  <td className="tdinovice invoicetextright">{item.SGSTAmount}{item.SGSTAmount > 0 ? ' (' + item.SGSTPer + '%)' : ''}</td>
                                  <td className="tdinovice invoicetextright">{item.IGSTAmount}{item.IGSTAmount > 0 ? ' (' + item.IGSTPer + '%)' : ''}</td>
                                  <td className="tdinovice invoicetextright">{item.TotalAmount}</td>
                                </tr>
                              ))}

                              <tr className="border-top-invoice border-0">
                                <td colSpan="5" className="tdinovice invoicetextright border-0 pb-0"></td>
                                <td className="tdinovice invoicetextright pb-0">Sub Total :</td>
                                <td className="tdinovice invoicetextright pb-0"> {InvoiceSubTotal}</td>
                              </tr>

                              <tr className="border-none border-0">
                                <td colSpan="5" className="tdinovice invoicetextright border-0 pb-0"></td>
                                <td className="tdinovice invoicetextright pb-0">CGST Amount :</td>
                                <td className="tdinovice invoicetextright pb-0"> {InvoiceCGSTAmount}</td>
                              </tr>

                              <tr className="border-none border-0">
                                <td colSpan="5" className="tdinovice invoicetextright border-0 pb-0"></td>
                                <td className="tdinovice invoicetextright pb-0">SGST Amount :</td>
                                <td className="tdinovice invoicetextright pb-0"> {InvoiceSGSTAmount}</td>
                              </tr>

                              <tr className="border-none border-0">
                                <td colSpan="5" className="tdinovice invoicetextright border-0 pb-0"></td>
                                <td className="tdinovice invoicetextright pb-0">IGST Amount :</td>
                                <td className="tdinovice invoicetextright pb-0"> {InvoiceIGSTAmount}</td>
                              </tr>

                              <tr className="footertable border-0">
                                <td colSpan="5" className="tdinovice invoicetextright border-0 pb-0"></td>
                                <td className="tdinovice invoicetextright pb-0">Total Amount :</td>
                                <td className="tdinovice invoicetextright pb-0"> {InvoiceTotalAmount}</td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>
                    <div className="mainfooterpage">
                      <div className="row">
                        <div className="col-md-12">
                          <p className="raasalifestyletextedit">KC FINANCIAL SOLUTIONS PRIVATE LIMITED. </p>

                          <p className="authorizedtext">Authorized Signatory
                          </p>
                        </div>
                        <div className="col-md-12">
                          <div className="mianfooterborder">

                          </div>

                        </div>
                      </div>
                      <div className="footermain row m-0">
                        <div className="col-md-7 new p-0">
                          <div className="footerbootemleft">
                            <p>For Terms & Conditions, refer our website
                            </p>
                            <p>Support Contact: +91 9460429835</p>
                          </div>
                        </div>
                        <div className="col-md-5 new p-0">
                          <div className="footerbootemright">
                            <p>Kindly Regards,  </p>
                            <p>KC FINANCIAL SOLUTIONS PRIVATE LIMITED. </p>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>

                </div>
              </div>
              <div className="modal-footer justify-content-end">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" onClick={printDocument}>Print</button>
              </div>
            </div>
          </div>
        </div>
        <Row>
          <Col className="col-12">
            <Row>
              <Col xl="12">
                <div className="collapse" id="collapseExample">
                  <div className="card card-body">
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label className="form-label"> Quick search</label>
                        <form className="app-search app-searchnew p-0 d-none d-lg-block">
                          <div className="position-relative"><input type="text" className="form-control" placeholder="Search..." id="txtQuickSearch" /><span className="bx bx-search-alt"></span>
                          </div>
                        </form>
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">Lead Type </label>
                        <select className="form-select" aria-label="Default select example" id="ddlInvoiceType">
                          <option value=''>All</option>
                          <option value="Retail">Retail</option>
                          <option value="Corporate">Corporate</option>
                        </select>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">Start Date </label>
                          <input type="date" className="form-control" id="txtStartDate" placeholder="name@example.com" />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">End Date </label>
                          <input type="date" className="form-control" id="txtEndDate" placeholder="name@example.com" />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="" className="form-label">Record Status</label>
                          <select className="form-select" aria-label="Default select example" id="ddlRecordStatus">
                            <option value="">All</option>
                            <option value="A">Active</option>
                            <option value="D">In-Active</option>
                          </select>
                        </div>

                      </div>

                      <div className="col-md-12 mt-3 mb-2">
                        <button type="button" className="btn btn-sm btn-success font-14" onClick={getInvoiceList}>Go</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Row>
            <div>
              <table id="emp" className="table" hidden="hidden">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Invoice Number</th>
                    <th>Invoice Type</th>
                    <th>Client Detail</th>
                    <th>GST Number &  Company Name</th>
                    <th>Bill Date</th>
                    <th>Due Date</th>
                    <th>Amount</th>
                    <th>GST Amount</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>
                <tbody>              {
                  records.map((item, index) => {
                    return <tr key={index}>
                      <td> {index + 1} </td>
                      <td >{item.InvoiceNumber}</td>
                      <td>{item.InvoiceType}</td>
                      <td>{item.InvoiceName}<br />{item.InvoiceEmailAddress}<br />{item.InvoiceMobileNo}</td>
                      <td>{item.InvoiceGSTNumber}<br />{item.InvoiceCompanyName}</td>
                      <td >{item.BillDate}</td>
                      <td>{item.DueDate}</td>
                      <td >{item.SubTotal}</td>
                      <td>{item.GSTAmount}</td>
                      <td>{item.TotalAmount}</td>
                    </tr>
                  })
                }
                </tbody>
              </table>
            </div>
          </Row>
        </Row>
        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12" className="customreactdatatable">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default OnSort;