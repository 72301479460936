import React,{ useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle,Input,Label } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory,useParams  } from 'react-router-dom';

 
const EditDocument = () => { 
      
      const [DocumentId, setDocumentId] = useState('');
      const [DocumentType, setDocumentType] = useState('');
      const [DocumentTitle, setDocumentTitle] = useState('');
      const [Description, setDescription] = useState('');
      const [DocumentTypeList, setDocumentTypeList] = useState([]);
      const [DocumentTypeId, setDocumentTypeId] = useState("");
      const history = useHistory();
      let { id } = useParams();
      const [posts, setPosts] = useState('');
      const [de, setde] = useState("");
      const DocumentTypeUpdate  = async (e) => {
        e.preventDefault();
      
   
       
            const DocumentType = {
              DocumentId:DocumentId,
              DocumentTypeId:DocumentTypeId,
              Title: DocumentTitle,
              Description: Description,
              Url:posts,
              Flag :"A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
                    
            }

            axios.post(global.APIURL+'/DocumentUpdate', DocumentType)
            .then(res => 
            {
                    alert(res.data.StatusMessage);
                    history.push("/Document");
            });
        
    }

    useEffect(() => {
        getDocumentTypeList();
        if(id!=null)
        {
          if(id!="0")
          {
            let newid = id.split(':'); 
            getDocumentTypeById(newid[1]);
          }
          else{
            history.push("/Document");
          }
        }
        else{
          history.push("/Document");
        }
    }, []);

    function fetchPost(e){
        var details = e.target.files[0];
        var details1 = "Document";
        setde(details);
        const formData = new FormData();
       
        formData.append('Document', details);
        formData.append('FolderName', details1);
  
        axios.post('http://localhost:8282/uploadavatar',formData,{
          
             headers: { "Content-Type": details.type},
        
        }) .then((res) => {
       
              setPosts(res.data.IName);
           
    
            });
      
      }
    const getDocumentTypeById = async (DId) => {
      var AUrl=global.APIURL+'/DocumentEdit/'+DId;
        axios.get(AUrl)  
        .then(res => {  
           setDocumentId(res.data[0].DocumentId)
        
           setDocumentTitle(res.data[0].Title);
           setDescription(res.data[0].Description);
           setDocumentTypeId(res.data[0].DocumentTypeId);
        })
    }
    const getDocumentTypeList = async () => {
        axios.get(global.APIURL+'/ActiveDocumentType')
          .then(DTres => {
            let DTList = DTres.data.map(documenytype => {
              return documenytype;
            });
            setDocumentTypeList(DTList);
          });      
      };
    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Edit Document | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Edit Document </CardTitle>
                 <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/DocumentType"><span className="ms-2 me-2">Document </span></a>
                <span>/</span>
                <span className="ms-2">Edit Document </span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/Document">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={DocumentTypeUpdate}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                  <Row>
                  <Col lg={4}>
                          <div className="mb-3">
                                <label className="form-label">Document Type</label>
                                  <select id="ddlDocumentType" className="form-select" onChange={e => setDocumentTypeId(e.target.value)}>
                                    <option value={"0"}>Select Documet type</option>
                                    {DocumentTypeList.map(doctype => (
                                      <option key={doctype.DocumentId} value={doctype.DocumentId} selected={DocumentTypeId === doctype.DocumentId}>{doctype.DocumentType}</option>
                                    ))}
                                  </select>
                                </div>
                          </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Title</label>
                        <Input type="text" className="form-control" id="txtDocumentTitle" defaultValue={DocumentTitle} placeholder="Enter Document Title"/>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-2">
                        <label className="form-label">Document Description</label>
                        <Input type="textarea" className="form-control" id="txtDocumentDescription" defaultValue={Description} placeholder="Enter Document Description"/>
                      </div>
                    </Col>
                    <Col lg={4} className="mt-2">
                  <div className="mb-3 labeledit">
                    <label htmlFor="uploadimage" className="form-label">
                    Document
                    </label>
                  
                       <input type="file" className="form-control form-control" name="file" onChange={fetchPost}/>
                  </div>
                </Col>
                    <Col lg={12}>    
                        <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                        <a className="btn btn_danger btn-sm ms-2" href="/DocumentType">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
      </div>
    )
}

export default EditDocument;