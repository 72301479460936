import React,{ useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory,useParams  } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
const cookies = new Cookies();
 
const EditChannelType = () => { 
    const [ChannelTypeName, setChannelTypeName] = useState('');
    const [Id, setId] = useState('');
    const history = useHistory();
    let { id } = useParams();

    const updateProduct  = async (e) => {
      e.preventDefault();
      let UTChannelTypeName=ChannelTypeName; 
      let UTId=Id; 
      if(UTChannelTypeName!='')
      {
        const ChannelType = {
          Id:UTId==''?'0':UTId,
          ChannelTypeName: UTChannelTypeName,
          CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),         
        }
        axios.post(global.APIURL+'/ChannelTypeUpdate', ChannelType).then(res => 
        {
          alert(res.data[0].RecordStatus);
          history.push("/ChannelType");
        });
      }
    }

    useEffect(() => {
      if(id!=null)
      {
        if(id!="0")
        {
          let newid = id.split(':'); 
          getUserTyprById(newid[1]);
        }
        else{
          history.push("/ChannelType");
        }
      }
      else{
        history.push("/ChannelType");
      }
    }, []);
 
    const getUserTyprById = async (UTId) => {
        var AUrl=global.APIURL+'/ChannelTypeEdit/'+UTId;
        axios.get(AUrl)  
        .then(res => {  
          setChannelTypeName(res.data[0].ChannelTypeName);
          setId(res.data[0].ChannelTypeId)
        })
    }

    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Channel Type | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Edit Channel Type</CardTitle>
                 <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/ChannelType"><span className="ms-2 me-2">Channel Type</span></a>
                <span>/</span>
                <span className="ms-2">Edit Channel Type</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/ChannelType">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={updateProduct}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Channel Type</label>
                        <Input type="text" className="" id="txtChannelType" defaultValue={ChannelTypeName} onChange={e => setChannelTypeName(e.target.value)}  placeholder="Enter Channel Type" />
                      </div>
                    </Col>
                    <Col lg={12}>
                        <input type="hidden" id="hdfChannelTypeId" value={Id} />    
                        <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                        <a className="btn btn_danger btn-sm ms-2" href="/ChannelType">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
      </div>
    )
}

export default EditChannelType;