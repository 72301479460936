import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle,Dropdown,DropdownItem,DropdownToggle,DropdownMenu, Modal, Label,
  Input, } from "reactstrap"
import MetaTags from 'react-meta-tags'


// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb

import "../../../assets/scss/datatables.scss"

// Table data
const products = [

 
  {
    "sr": 1,
    "user":  <div className="d-flex">
    <div className="flex-shrink-0">
      <img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/>
    </div>
    <div className="flex-grow-1 ms-1">
      <p className="mb-0">Hirva Suthar</p>
      <p className="mb-0">Account Manager</p>
    </div>
  </div>,
    "bank name": "SBI",
    "email": "abc@gmail.com",
    "phone": "+91 6589354896",
    "action": <> 
                  <a href="#" className="table-link-a" data-bs-toggle="modal" data-bs-target="#Edit_followup" > <i className="mdi mdi-pencil me-3 font-size-18 text-success"></i> </a>
                  <a className="table-link-a text-danger" href="#"> <i className="mdi mdi-delete font-size-18 me-3"></i> </a>
                  </>
  },
  {
    "sr": 2,
    "user":  <div className="d-flex">
    <div className="flex-shrink-0">
      <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/>
    </div>
    <div className="flex-grow-1 ms-1">
      <p className="mb-0">Hiren Suthar</p>
      <p className="mb-0">Account Manager</p>
    </div>
  </div>,
    "bank name": "BOB",
    "email": "abc@gmail.com",
    "phone": "+91 6589354896",
    "action": <><a href="#" className="table-link-a" data-bs-toggle="modal" data-bs-target="#Edit_followup"> <i className="mdi mdi-pencil me-3 font-size-18 text-success"></i> </a>
     <a className="table-link-a text-danger" href="#"> <i className="mdi mdi-delete font-size-18 me-3"></i> </a></>
  },

];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    const columns = [{
      dataField: 'sr',
      text: 'Sr',
      sort: true,
    }, {
      dataField: 'user',
      text: 'User',
      sort: true
    }, {
      dataField: 'bank name',
      text: 'Bank Name',
      sort: true
    }, {
      dataField: 'email',
      text: 'Email',
      sort: true
    }, {
      dataField: 'phone',
      text: 'Phone',
      sort: true
    }, {
      dataField: 'action',
      text: 'Action',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

  
    // Select All Button operation
    const selectRow = {
      mode: 'checkbox'
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>

        <div className="page-content">
          <MetaTags>
          <title>List of Channel Partner | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                      <CardTitle className="h6 mb-2">List of Channel Partner</CardTitle>
                      <a href="#" className="me-2">Home</a> <span>/</span><span className="ms-2">List of Channel Partner</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary margin-20-back btn-sm float-end font-14" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                   
                  </Col>
              </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                                <Row className="mb-2">
                             
                                <Col className="col-lg-4 col-md-4 col-6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-lg-8 col-md-8 col-6">
              <div className="float-end">
              <a className ="btn btn-green btn-sm float-end font-14" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" href="#"><i className="mdi mdi-plus align-middle me-1"></i>Add</a>
                    </div>
              </Col>
                              </Row>



                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap todo-text"
                                      }
                                      headerWrapperClasses={"thead-light table-thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>
        </div>
        <div className="offcanvas offcanvas-end offcanvas-add" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div className="offcanvas-header">
    <h5 id="offcanvasRightLabel">Add Channel Partner</h5>
    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div className="offcanvas-body">
    <Row>
        <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Name</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="First Name"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Designation/Role</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Branch Manager"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Department</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Department"
                      />
                    </div>
                   
         
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Mobile Number</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Mobile Number"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Alternate Mobile Number</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Alternate Number"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Email Address</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Email Address"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Alternate Email Address</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Alternate Email Address"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Visting Card</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Add Card"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Current Address</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Add Address"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">City</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Enter City"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Tehsil</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Add Tehsil"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">District</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Add District"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">State</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Add State"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Date of Birth</label>
                      <Input
                        type="date"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Alternate Email Address"
                      />
                    </div>
          </Col>
          <Col lg={4}>
                    <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Date Of Anniversary</label>
                      <Input
                        type="date"
                        className="form-control"
                        id="formrow-InputCity"
                        placeholder="Alternate Email Address"
                      />
                    </div>
          </Col>
          <Col lg="12">
          <div className="mb-3">
<label htmlFor="exampleFormControlTextarea1" className="form-label">First Meeting Brief</label>
<textarea className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
</div>
          </Col>
          <div className="col-lg-4">
          <a className ="btn btn-primary btn-sm font-14" href="#">Save</a>
          </div>
       </Row>
  </div>
</div>



      </React.Fragment>
    )
  }
}

export default DatatableTables