import React, { Component } from "react";
import MetaTags from 'react-meta-tags';

import {
  Card,
  CardBody,
  CardColumns,
  CardDeck,
  CardFooter,
  CardHeader,
  CardImg,
  CardImgOverlay,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

// import images
import img1 from "../../assets/images/small/img-1.jpg";
import img2 from "../../assets/images/small/img-2.jpg";
import img3 from "../../assets/images/small/img-3.jpg";
import img4 from "../../assets/images/small/img-4.jpg";
import img5 from "../../assets/images/small/img-5.jpg";
import img6 from "../../assets/images/small/img-6.jpg";
import img7 from "../../assets/images/small/img-7.jpg";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class UiCards extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
      <div className="page-content">
          <MetaTags>
            <title>Company List | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-5">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Company List</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
          
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
              <Col className="col-12">
                <div className ="d-flex align-items-center justify-content-between">
                 <div className="d-flex">
                  <CardTitle className="h6 mb-4">Company List</CardTitle>
                 </div>
                  <div className ="d-flex">
                  <div className="text-end me-5">
                      <div className="border-bottom">
                        Search <i className="mdi mdi-filter h4 ms-3"></i>
                      </div>
                    </div>
                    <a className ="btn btn-primary btn-sm" href="/">Add</a>
                  </div>
                </div>
              
              </Col>
            <Row>
              
              <Col className="col-12 mt-4">
                <Card>
                  <CardBody>
                     {/* User list */}
                  
               <div className="table-responsive">
               <table className="table table-striped">
                      <thead>
                        <tr className="">
                          <th scope="col">Sr.</th>
                          <th scope="col" className="">Company</th>
                          <th scope="col" className="">Address</th>
                          <th scope="col" className="">Contact</th>
                          <th scope="col" className="">Email</th>
                          <th scope="col" className="">Branch</th>
                          <th scope="col" className="">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="user-td">1</th>
                          <td>KC Finance 
                            <p className="mt-2 mb-0">(KCF)</p>
                          </td>
                          <td>Address line 1 <p className="mt-2 mb-0">Address line 2</p></td>
                          <td>+91 98767543212<p className="mt-2 mb-0">+91 98767543212</p></td>
                          <td>email@gmail.com<p className="mt-2 mb-0">email@gmail.com</p></td>
                          <td><a hrefLang="#" className="table-link">Branch List</a></td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil me-3"></i></a>
                              <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="user-td">2</th>
                          <td>KC Finance 
                            <p className="mt-2 mb-0">(KCF)</p>
                          </td>
                          <td>Address line 1 <p className="mt-2 mb-0">Address line 2</p></td>
                          <td>+91 98767543212<p className="mt-2 mb-0">+91 98767543212</p></td>
                          <td>email@gmail.com<p className="mt-2 mb-0">email@gmail.com</p></td>
                          <td><a hrefLang="#" className="table-link">Branch List</a></td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil me-3"></i></a>
                              <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="user-td">3</th>
                          <td>KC Finance 
                            <p className="mt-2 mb-0">(KCF)</p>
                          </td>
                          <td>Address line 1 <p className="mt-2 mb-0">Address line 2</p></td>
                          <td>+91 98767543212<p className="mt-2 mb-0">+91 98767543212</p></td>
                          <td>email@gmail.com<p className="mt-2 mb-0">email@gmail.com</p></td>
                          <td><a hrefLang="#" className="table-link">Branch List</a></td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil me-3"></i></a>
                              <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                  </table>
               </div>
                  <nav className="mt-4" aria-label="Page navigation example">
  <ul className="pagination justify-content-center">
    <li className="page-item disabled">
      <a className="page-link" href="#" tabIndex ="-1" aria-disabled="true">Previous</a>
    </li>
    <li className="page-item"><a className="page-link" href="#">1</a></li>
    <li className="page-item"><a className="page-link" href="#">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item">
      <a className="page-link" href="#">Next</a>
    </li>
  </ul>
</nav>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UiCards;
