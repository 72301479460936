import React, { Component, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';

const AddCategory = () => {
  const history = useHistory();
  const saveProduct = async (e) => {
    e.preventDefault();
    let UTitle = event.target["txtCategory"].value;
    if (UTitle != '') {
      const Category = {
        Id: '0',
        Title: UTitle,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
      }

      axios.post(global.APIURL + '/Category', Category)
        .then(res => {
          alert(res.data.StatusMessage);
          document.getElementById("txtCategory").value='';
        });
    }
  }
  return (
    <div className="page-content">

      <MetaTags>
        <title>Category | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-12 mb-2">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Add Category</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <a href="/Category" className="ms-2 me-2">Category</a>
              <span>/</span>
              <span className="ms-2">Add Category</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-12">
            <a className="btn btn-primary btn-sm float-end font-14" href="/Category">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <form onSubmit={saveProduct}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Category</label>
                        <Input type="text" className="form-control" id="txtCategory" placeholder="Enter Category" />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/Category">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>

    </div>
  )
}

export default AddCategory;