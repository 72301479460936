import React, { Component } from "react"
import MetaTags from 'react-meta-tags';

//Import Breadcrumb
import { Col, Row, CardTitle, CardBody, Card, } from "reactstrap"
import img2 from "../../../assets/images/users/avatar-5.jpg";
import img3 from "../../../assets/images/users/avatar-1.jpg";
import img4 from "../../../assets/images/users/avatar-2.jpg";
import img5 from "../../../assets/images/users/avatar-3.jpg";
// Rating Plugin

class UiRating extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tooltipContent: ["Rate 1", "Rate 2", "Rate 3", "Rate 4", "Rate 5"],
      tooltipContentMore: ["1", "2", "3", "4", "5", "6", "7", "8"],
      tooltipContentHalf: ["6", "7", "8", "9", "10"],
      tooltipContentMiddle: [
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      tooltipContentStep: ["2", "4", "6", "8", "10"],
      default: "",
      half: "",
      customize: "",
    }
  }

  render() {
    return (
      <React.Fragment>
       <div className="page-content">
          <MetaTags>
            <title>Services | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          <div className="modal fade service-modal-z" id="addservices" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Add Service</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <Row>
          <div className="col-lg-12">
            <div className="h5">Service Title</div>
            <select className="form-select" aria-label="Default select example">
  <option selected>Recusandae vitae enim et nostrum ipsum et.</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
          </div>
          <div className="col-lg-12 mt-3">
            <CardTitle>About Service</CardTitle>
            <p>Qui accusamus similique in dolore voluptatum ut est sequi ea. Temporibus repellendus non temporibus aliquam deserunt debitis dolorum et et. Non porro assumenda voluptatibus qui numquam. Quos aliquid et non accusamus. Tempore aperiam temporibus dicta expedita autem provident.</p>
          </div>
          <div className="col-lg-12 mt-3">
            <CardTitle>Deliverable</CardTitle>
            <ul className="">
              <li className="">Eum quidem rerum nam neque odit provident necessitatibus rerum. Nihil voluptas natus temporibus. Perferendis velit minima libero sit eligendi repellendus possimus non nobis. Quo commodi rerum molestiae aspernatur. Eos voluptatibus similique pariatur ut. Non autem nostrum accusamus laborum.</li>
              <li className="mt-2">Quod nam necessitatibus itaque tenetur. Ut accusamus voluptas aut non. Minus assumenda ex voluptatem ut sed explicabo non.</li>
              <li className="mt-2">Aut voluptatem quas enim hic eos sit officia. Molestias omnis eos nulla non magnam tenetur ab dolores animi. Repudiandae aut nihil autem quas dolores. Ducimus vel et vitae vel. Repellat autem quia iste dignissimos pariatur et est dolorem. Consequuntur ut eaque at nesciunt voluptatem tempore omnis magnam.</li>
              <li className="mt-2">Quas sit minima laborum facere aut repellendus ut voluptatem. Optio debitis beatae architecto qui ut. Delectus labore eveniet vero. Consectetur sed aut et harum.</li>
            </ul>
          </div>
          <div className="col-lg-12 mt-3">
            <CardTitle>Write Comments</CardTitle>
            <div className="">
            <textarea className="form-control" placeholder="Type Something" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
          </div>
          <div className="col-lg-12 mt-3 mb-2">
            <CardTitle>Select Member</CardTitle>
          </div>
          <div className="col-lg-6">
          <div className="leave-sub-box mb-3">
          <span className="float-end"><input type="checkbox" className=""/></span>
                                        <div className="d-flex align-items-center mt-4">
                                        <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail me-3 img-leads-retail rounded-circle"/>
                                          <div className="name-box">
                                              <div className="h6 font-16">Smith Jain</div>
                                              <p className="mb-2">+91 4589236941</p>
                                              <p className="mb-0">Alfredo.Goyette@gmail.com</p>
                                          </div>
                                          
                                        </div>
                                      <div className="box-2 mt-2"></div>
                                    <div className="mt-2 d-flex align-items-center justify-content-between">
                                        <a href="" className="">Department : <span className="text-success fw-bold">Accounts</span></a>
                                        <a href="" className="">Employee Code : <span className="text-orange fw-bold">08462</span></a>
                                    </div>
                    </div> 
          </div>
          <div className="col-lg-6">
          <div className="leave-sub-box mb-3">
          <span className="float-end"><input type="checkbox" className=""/></span>
                                        <div className="d-flex align-items-center mt-4">
                                        <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail me-3 img-leads-retail rounded-circle"/>
                                          <div className="name-box">
                                              <div className="h6 font-16">Kartik Singh</div>
                                              <p className="mb-2">+91 4589236941</p>
                                              <p className="mb-0">Alfredo.Goyette@gmail.com</p>
                                          </div>
                                          
                                        </div>
                                      <div className="box-2 mt-2"></div>
                                    <div className="mt-2 d-flex align-items-center justify-content-between">
                                        <a href="" className="">Department : <span className="text-success fw-bold">Manager</span></a>
                                        <a href="" className="">Employee Code : <span className="text-orange fw-bold">08569</span></a>
                                    </div>
                    </div> 
          </div>
        </Row>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn_success">Save changes</button>
        <button type="button" className="btn btn_danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
          <Row className="mb-5">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle>Services</CardTitle>
                      <a href="#" className="me-2">Home</a> <span>/</span><span className="ms-2">Services</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                    <a className="btn btn-green btn-sm float-end font-14 me-2" data-bs-toggle="modal" data-bs-target="#addservices" href="/EditClient"
      ><i className="mdi mdi-plus align-middle me-1"></i>Add Service</a
    >
                  </Col>
              </Row>
            <Row>
              <div className="col-lg-12">
                <Card>
                  <CardBody>
                 
<Row className="mb-2 justify-content-center">
<Col className="col-lg-4 col-md-4 col-4">
                               
                {/* <div className="dropdown">
  <button className="btn btn-border  dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Export
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><a className="dropdown-item" href="#">Action</a></li>
    <li><a className="dropdown-item" href="#">Another action</a></li>
    <li><a className="dropdown-item" href="#">Something else here</a></li>
  </ul>
</div> */}
                                </Col>
                                <div className="col-lg-4 col-md- col-4 text-center col">
    <div className="search-box me-2 mb-2 d-inline-block">
        <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
                <span id="search-bar-0-label" className="sr-only">Search this table</span>
                <input id="search-bar-0" type="text" aria-labelledby="search-bar-0-label" className="form-control " placeholder="Search" value="" />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
        </div>
    </div>
</div>
                                
                                <Col className="col-lg-4 col-md-4 col-4">
                                <div className="d-inline float-end">
                                <div className="dropdown">
  <button className="btn btn-border dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    10<i className="fas fa-angle-down align-middle ms-2"></i>
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><a className="dropdown-item" href="#">25</a></li>
    <li><a className="dropdown-item" href="#">30</a></li>
    <li><a className="dropdown-item" href="#">35</a></li>
  </ul>
</div>
                                  </div>
                                </Col>
                              </Row>
                    <Row>
                      <div className="col-lg-6">
                         <a href="/ServiceDetail">
                         <div className="co-service-box bg-light p-4 mb-3">
                              <div className="h6">
                              Asperiores nobis et similique error provident cabo ut veniam.
                              </div>
                              <p className="text-success fw-bold">Thu, 1st April 2021 | 09:00 AM</p>
                              <p className="mb-0">
                                Qui accusamus similique in dolore voluptatum ut est sequi ea. Temporibus repellendus non temporibus aliquam deserunt debitis dolorum et et. Non porro assumenda voluptatibus qui numquam. Quos aliquid et non accusamus. Tempore aperiam temporibus dicta expedita autem provident.
                                </p>
                          </div>
                         </a>
                      </div>
                      <div className="col-lg-6">
                         <a href="/ServiceDetail">
                         <div className="co-service-box bg-light p-4 mb-3">
                              <div className="h6">
                              Asperiores nobis et similique error provident cabo ut veniam.
                              </div>
                              <p className="text-success fw-bold">Thu, 1st April 2021 | 09:00 AM</p>
                              <p className="mb-0">
                                Qui accusamus similique in dolore voluptatum ut est sequi ea. Temporibus repellendus non temporibus aliquam deserunt debitis dolorum et et. Non porro assumenda voluptatibus qui numquam. Quos aliquid et non accusamus. Tempore aperiam temporibus dicta expedita autem provident.
                                </p>
                          </div>
                         </a>
                      </div>
                      <div className="col-lg-6">
                         <a href="/ServiceDetail">
                         <div className="co-service-box bg-light p-4 mb-3">
                              <div className="h6">
                              Asperiores nobis et similique error provident cabo ut veniam.
                              </div>
                              <p className="text-success fw-bold">Thu, 1st April 2021 | 09:00 AM</p>
                              <p className="mb-0">
                                Qui accusamus similique in dolore voluptatum ut est sequi ea. Temporibus repellendus non temporibus aliquam deserunt debitis dolorum et et. Non porro assumenda voluptatibus qui numquam. Quos aliquid et non accusamus. Tempore aperiam temporibus dicta expedita autem provident.
                                </p>
                          </div>
                         </a>
                      </div>
                      <div className="col-lg-6">
                         <a href="/ServiceDetail">
                         <div className="co-service-box bg-light p-4 mb-3">
                              <div className="h6">
                              Asperiores nobis et similique error provident cabo ut veniam.
                              </div>
                              <p className="text-success fw-bold">Thu, 1st April 2021 | 09:00 AM</p>
                              <p className="mb-0">
                                Qui accusamus similique in dolore voluptatum ut est sequi ea. Temporibus repellendus non temporibus aliquam deserunt debitis dolorum et et. Non porro assumenda voluptatibus qui numquam. Quos aliquid et non accusamus. Tempore aperiam temporibus dicta expedita autem provident.
                                </p>
                          </div>
                         </a>
                      </div>
                      <div className="col-lg-6">
                         <a href="/ServiceDetail">
                         <div className="co-service-box bg-light p-4 mb-3">
                              <div className="h6">
                              Asperiores nobis et similique error provident cabo ut veniam.
                              </div>
                              <p className="text-success fw-bold">Thu, 1st April 2021 | 09:00 AM</p>
                              <p className="mb-0">
                                Qui accusamus similique in dolore voluptatum ut est sequi ea. Temporibus repellendus non temporibus aliquam deserunt debitis dolorum et et. Non porro assumenda voluptatibus qui numquam. Quos aliquid et non accusamus. Tempore aperiam temporibus dicta expedita autem provident.
                                </p>
                          </div>
                         </a>
                      </div>
                      
                    </Row>
                    <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                
                                  <div className="text-md-right ms-auto">
                                  <div className="pagination pagination-rounded justify-content-end">
    <ul className="pagination react-bootstrap-table-page-btns-ul">
        <li className="page-item" title="previous page"><a href="#" className="page-link">&lt;</a></li>
        <li className="page-item" title="1"><a href="#" className="page-link">1</a></li>
        <li className="active page-item" title="2"><a href="#" className="page-link">2</a></li>
        <li className="page-item" title="3"><a href="#" className="page-link">3</a></li>
        <li className="page-item" title="next page"><a href="#" className="page-link">&gt;</a></li>
    </ul>
</div>
                                  </div>
                                </Col>
                              </Row>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default UiRating


