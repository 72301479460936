import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';

import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import LinkApp from 'App';



const OnSort = () => {
  let columns = [
    {
      key: "Srno",
      text: "#",
      sortable: true
    },
    {
      key: "Title",
      text: "Title",
      sortable: true
    },
    {
      key: "Description",
      text: "Description",
      sortable: true
    },
    {
      d: "Status",
      text: "Status",
      cell: (record, index) => {
        let VFlag = record["Flag"];
        let VFlagStatus = VFlag == "A" ? "Active" : "Inactive";
        return (
          <Fragment>
            <span className={IconrenderSwitchColorStatus(record)}>{VFlagStatus}</span>
          </Fragment>
        );
      }
    },
    {
      d: "action",
      text: "Action",
      cell: (record, index) => {
        return (
          <Fragment>
            {RightsUpdate == "A" ? <a className="me-3 text-primary" onClick={(editRecord.bind(this, record))}><i className="mdi mdi-pencil font-size-18"></i></a> : ''}
            {RightsDelete == "A" ? <a className={IconrenderSwitchColor(record)} onClick={deleteRecord.bind(this, record)}><i className={IconrenderSwitch(record)}></i></a> : ''}
          </Fragment>
        );
      }
    }
  ];

  const deleteRecord = (record) => {
    let UTId = record["BId"];
    let UTFlag = record["Flag"];
    const BureaucCategory = {
      BId: UTId,
      Flag:UTFlag,
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    }
    let AlertMessgae = UTFlag == "A" ? "Are you sure you want to inactive this record?" : "Are you sure you want to active this record?";
    if (confirm(AlertMessgae) == true) {
      axios.post(global.APIURL + '/bureaucategoryStausUpdate', BureaucCategory)
        .then(res => {
          alert(res.data[0].RecordStatus);
          fetch(global.APIURL + "/bureaucategoryList")
            .then((res) => res.json())
            .then((json) => {
              setRecords(json)
            })
        })
    }
  }

  function IconrenderSwitch(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'mdi mdi-close font-size-18';
      default:
        return 'mdi mdi-check-all font-size-18';
    }
  }

  function IconrenderSwitchColor(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }
  function IconrenderSwitchColorStatus(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'D':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }

  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
  }
  const [records, setRecords] = useState([])
  const [CreditStatusFlag, setCreditStatusFlag] = useState("");
  const [RightsAdd, setRightsAdd] = useState("D");
  const [RightsUpdate, setRightsUpdate] = useState("D");
  const [RightsDelete, setRightsDelete] = useState("D");


  let history = useHistory();



  useEffect(() => {
    const f = {
      MenuId: "53",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {

        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add') {
            setRightsAdd(MS);
          }
          else if (MA == 'Update') {
            setRightsUpdate(MS);
          }
          else if (MA == 'Delete') {
            setRightsDelete(MS);
          }
        }
      });
    fetch(global.APIURL + "/bureaucategoryList")
      .then((res) => res.json())
      .then((json) => {
        setRecords(json)
      })
  }, []);


  const editRecord = (record) => {

    let id = record["BId"]
    let path = `Bureaucategory/EditBureaucategory/:${id}`;
    history.push(path);
  }

  const exportPDF = () => {

    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Bureau Category List Report";
    const headers = [["#", "Title", "Description"]];

    const data = records.map((item, index) => [index + 1, item.Title, item.Description]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("BureauCategoryListReport.pdf")
  }

  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Bureau Category</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-6 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Bureau Category</CardTitle>
              <a href="/GeneralDashboard" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <span className="ms-2">Bureau Category</span>
            </div>
          </Col>
          <Col className="col-lg-6 co-md-6 col-6">
            <div className="d-flex align-items-center justify-content-end ">

              <div>
                <a className="btn btn-primary btn-sm float-end font-14" href="/GeneralDashboard">
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
                {RightsAdd == "A" ? <a className="btn btn-primary btn-sm float-end font-14 me-2" href="/AddBureaucategory">
                  <i className="mdi mdi-plus align-middle me-1"></i>Add New
                </a> : ''}
                <ReactHtmlTableToExcel
                  className="btn btn-primary btn-sm float-end font-14 me-2"
                  table="emp"
                  filename="BureauCategoryListReport"
                  sheet="Sheet"
                  buttonText="Export Excel" />
                <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
              </div>
            </div>
          </Col>
        </Row>


        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row>
                  <Col xl="12">
                    <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <table id="emp" className="table" hidden="hidden">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {
              records.map((item, index) => {
                return <tr key={index}>
                  <td> {index + 1} </td>
                  <td>{item.Title}</td>
                  <td>{item.Description}</td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OnSort;