import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Media, Row, CardTitle, Input, Label, } from "reactstrap";
import MetaTags from 'react-meta-tags';

//Import Image
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import img3 from "../../assets/images/small/img-3.jpg";
import img4 from "../../assets/images/small/img-4.jpg";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";




class EmailRead extends Component {
  render() {
    return (
      <React.Fragment>
       <div className="page-content">
          <MetaTags>
            <title>Holiday List | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h6">Holiday List</CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Holiday List</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
            
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EmailRead;
