import React, { useState } from "react";
import { Button, CardTitle, Col, Form, Input, Label, Row } from "reactstrap";
import axios from "axios";

const FormRepeater = () => {
  const [PartnerName, setPartnerName] = useState("");
  const [PartnerPAN, setPartnerPAN] = useState("");
  const [PartnerContactNo, setPartnerContactNo] = useState("");
  const [PartnerEmailAddress, setPartnerEmailAddress] = useState("");

  const changePartnerName = event => {
    setPartnerName(event.target.value);
  };

  const changePartnerPAN = event => {
    setPartnerPAN(event.target.value);
  };
  const changePartnerContactNo = event => {
    setPartnerContactNo(event.target.value);
  };
  const changePartnerEmailAddress = event => {
    setPartnerEmailAddress(event.target.value);
  };

  const transferValue = event => {
    event.preventDefault();

    var data = {
      LeadId: "1",
      PartnerName: PartnerName,
      PartnerPAN: PartnerPAN,
      PartnerContactNo: PartnerContactNo,
      PartnerEmailAddress: PartnerEmailAddress,
      CreateUser:localStorage.getItem("LoginUserId")==null?"0":localStorage.getItem("LoginUserId")==""?"0":localStorage.getItem("LoginUserId")
    };

    axios
      .post(global.APIURL+"/WebLeadPartnerAdd", data)
      .then(res => {
      })
      .catch(err => console.log(err));

    clearState();
  };

  const clearState = () => {
    setPartnerName("");
    setPartnerContactNo("");
    setPartnerPAN("");
    setPartnerEmailAddress("");
  };

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <CardTitle>Partner Details</CardTitle>
          <hr />
          <table style={{ width: "100%" }}>
            <tbody>
              <tr id="addr01" key="">
                <td>
                  {/* Form starts here */}

                  <Form className="repeater" encType="multipart/form-data">
                    <div data-repeater-list="group-a">
                      <Row data-repeater-item>
                        {/* Form Inputs */}
                        <Col lg="4" className="mb-3">
                          <Label htmlFor="name">Partner Name</Label>
                          <Input
                            type="text"
                            id="name"
                            name="PartnerName"
                            value={PartnerName}
                            onChange={changePartnerName}
                            className="form-control"
                            placeholder="Partner Name"
                          />
                        </Col>

                        <Col lg="4" className="mb-3">
                          <Label htmlFor="pan"> Partner PAN</Label>
                          <Input
                            type="number"
                            name="PartnerPAN"
                            id="pan"
                            className="form-control"
                            placeholder="Partner PAN"
                            value={PartnerPAN}
                            onChange={changePartnerPAN}
                          />
                        </Col>

                        <Col lg="4" className="mb-3">
                          <Label htmlFor="contactno">
                            {" "}
                            Partner Contact No.
                          </Label>
                          <Input
                            name="PartnerContactNo"
                            type="number"
                            id="contactno"
                            className="form-control"
                            placeholder="Partner Contact No."
                            value={PartnerContactNo}
                            onChange={changePartnerContactNo}
                          />
                        </Col>

                        <Col lg="4" className="mb-3">
                          <Label htmlFor="emailaddress">
                            {" "}
                            Partner Email Address.
                          </Label>
                          <Input
                            name="PartnerEmailAddress"
                            type="email"
                            id="emailaddress"
                            className="form-control"
                            placeholder="Partner Email Address."
                            value={PartnerEmailAddress}
                            onChange={changePartnerEmailAddress}
                          />
                        </Col>

                        <Col lg="1" className="align-self-center">
                          <div className="d-grid w-17">
                            <Button
                              onClick={e => handleRemoveRow(e, "01")}
                              color="danger"
                              style={{ width: "60% !important" }}
                              className="btn-sm mt-3"
                            >
                              <i className="fas fa-trash-alt"></i>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            onClick={transferValue}
            color="primary"
            className="mt-3 mt-lg-0 btn-sm"
          >
            <i className="fas fa-plus me-2"></i>
            Add
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FormRepeater;
