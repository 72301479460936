import React,{ useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory,useParams  } from 'react-router-dom';
 
const EditTehsil = () => { 
    const [CountryId, setCountryId] = useState(''); 
    const [CountryTitle, setCountryTitle] = useState('');
    const [StateId, setStateId] = useState(''); 
    const [StateTitle, setStateTitle] = useState('');
    const [DistrictId, setDistrictId] = useState(''); 
    const [DistrictTitle, setDistrictTitle] = useState('');
    const [TehsilId, setTehsilId] = useState('');
    const [Title, setTitle] = useState('');
    const history = useHistory();
    let { id } = useParams();

    useEffect(() => {
        if(id!=null)
        {
          if(id!="0")
          {
            let newid = id.split(':'); 
            let VCountryId=localStorage.getItem("CountryTitle");
            let VCountryTitle=localStorage.getItem("CountryTitle");
            let VStateId=localStorage.getItem("DistrictStateId");
            let VStateTitle=localStorage.getItem("DistrictStateTitle");
            let VDistrictId=localStorage.getItem("TehsilDistrictId");
            let VDistrictTitle=localStorage.getItem("TehsilDistrictTitle");
            setCountryId(VCountryId);
            setCountryTitle(VCountryTitle);
            setStateId(VStateId);
            setStateTitle(VStateTitle);
            setDistrictId(VDistrictId);
            setDistrictTitle(VDistrictTitle)
            getTehsilById(newid[1]);
          }
          else{
            history.push("/Tehsil");
          }
        }
        else{
          history.push("/Tehsil");
        }
    }, []);

      const TehsilUpdate  = async (e) => {
        e.preventDefault();
        let TehsilTitle=event.target["txtTehsil"].value; 
        if(TehsilTitle!='')
        {
          const TehsilArr = {
            TehsilId:TehsilId,
            CountryId:CountryId,
            StateId:StateId,
            DistrictId:DistrictId,
            Title: TehsilTitle,
            CreateUser:"1"       
          }

          axios.post(global.APIURL+'/TehsilUpdate', TehsilArr)
          .then(res => 
          {
              alert(res.data);
              history.push("/Tehsil");
          });
        }
    }

    const getTehsilById = async (TId) => {
      var AUrl=global.APIURL+'/TehsilEdit/'+TId;
      axios.get(AUrl)  
      .then(res => {  
          setTitle(res.data[0].Title);
          setTehsilId(res.data[0].TehsilId)
      })
    }

    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Edit Tehsil | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Edit Tehsil</CardTitle>
                <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/Country" className="ms-2 me-2">{CountryTitle}</a>
                <span>/</span>
                <a href="/State" className="ms-2 me-2">{StateTitle}</a>
                <span>/</span>
                <a href="/District" className="ms-2 me-2">{DistrictTitle}</a>
                <span>/</span>
                <a href="/Tehsil"><span className="ms-2 me-2">Tehsil List</span></a>
                <span>/</span>
                <span className="ms-2">Edit Tehsil</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/Tehsil">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={TehsilUpdate}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Tehsil</label>
                        <Input type="text" className="" id="txtTehsil" defaultValue={Title}  placeholder="Enter Tehsil" />
                      </div>
                    </Col>
                    <Col lg={12}>
                        <input type="hidden" id="hdfTehsilId" value={TehsilId} />    
                        <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                        <a className="btn btn_danger btn-sm ms-2" href="/Tehsil">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
      </div>
    )
}

export default EditTehsil;