import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Label } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { Link } from 'react-router-dom';
import totalleads from "../../assets/images/dashboardimg/totalleads.png";
import totalindivisuallead from "../../assets/images/dashboardimg/totalindivisuallead.png";
import totalfirm from "../../assets/images/dashboardimg/totalfirm.png";
import totalclient from "../../assets/images/dashboardimg/totalclient.png";
import leadmain from "../../assets/images/dashboardimg/leadmain.png";
import convertedlead from "../../assets/images/dashboardimg/convertedlead.png";
import registeredlead from "../../assets/images/dashboardimg/registeredlead.png";
import closedlead from "../../assets/images/dashboardimg/closedlead.png";
import clientlead from "../../assets/images/dashboardimg/clientlead.png";


import axios from 'axios'
//import Apaexlinecolumn from "pages/AllCharts/apex/apaexlinecolumn";
import ReactApexChart from "react-apexcharts"
const OnSort = () => {

  const [TotalLead, setTotalLead] = useState("0");
  const [TotalIndividualLead, setTotalIndividualLead] = useState("0");
  const [TotalFirmCompanyLead, setTotalFirmCompanyLead] = useState("0");
  const [TotalEnrollLead, setTotalEnrollLead] = useState("0");
  const [TotalIndividualLeadEnroll, setTotalIndividualLeadEnroll] = useState("0");
  const [TotalFirmCompanyLeadEnroll, setTotalFirmCompanyLeadEnroll] = useState("0");
  const [TotalConvertLead, setTotalConvertLead] = useState("0");
  const [TotalConvertIndividualLead, setTotalConvertIndividualLead] = useState("0");
  const [TotalConvertFirmCompanyLead, setTotalConvertFirmCompanyLead] = useState("0");
  const [TotalRegisterLead, setTotalRegisterLead] = useState("0");
  const [TotalRegisterIndividualLead, setTotalRegisterIndividualLead] = useState("0");
  const [TotalRegisterFirmCompanyLead, setTotalRegisterFirmCompanyLead] = useState("0");
  const [TotalCloseLead, setTotalCloseLead] = useState("0");
  const [TotalCloseIndividualLead, setTotalCloseIndividualLead] = useState("0");
  const [TotalCloseFirmCompanyLead, setTotalCloseFirmCompanyLead] = useState("0");
  const [TotalClient, setTotalClient] = useState("0");
  const [TotalIndividualClient, setTotalIndividualClient] = useState("0");
  const [TotalFirmCompanyClient, setTotalFirmCompanyClient] = useState("0");
  const [RegisterClient, SetRegisterClient] = useState([]);

  const [LeadList, setLeadList] = useState([]);
  const [EnrolledList, setEnrolledList] = useState([]);
  const [ConvertedList, setConvertedList] = useState([]);
  const [RegisteredList, setRegisteredList] = useState([]);
  const [ClosedList, setClosedList] = useState([]);
  const [ClientList, setClientList] = useState([]);
  const Total = TotalLead + TotalEnrollLead + TotalConvertLead + TotalRegisterLead + TotalCloseLead + TotalClient;

  const [DisplayTotalLead, setDisplayTotalLead] = useState(0);
  const [DisplayTotalIndividualLead, setDisplayTotalIndividualLead] = useState(0);
  const [DisplayTotalFirmCompanyLead, setDisplayTotalFirmCompanyLead] = useState(0);
  const [DisplayTotalEnrollLead, setDisplayTotalEnrollLead] = useState(0);
  const [DisplayTotalIndividualLeadEnroll, setDisplayTotalIndividualLeadEnroll] = useState(0);
  const [DisplayTotalFirmCompanyLeadEnroll, setDisplayTotalFirmCompanyLeadEnroll] = useState(0);
  const [DisplayTotalConvertLead, setDisplayTotalConvertLead] = useState(0);
  const [DisplayTotalConvertIndividualLead, setDisplayTotalConvertIndividualLead] = useState(0);
  const [DisplayTotalConvertFirmCompanyLead, setDisplayTotalConvertFirmCompanyLead] = useState(0);
  const [DisplayTotalRegisterLead, setDisplayTotalRegisterLead] = useState(0);
  const [DisplayTotalRegisterIndividualLead, setDisplayTotalRegisterIndividualLead] = useState(0);
  const [DisplayTotalRegisterFirmCompanyLead, setDisplayTotalRegisterFirmCompanyLead] = useState(0);
  const [DisplayTotalCloseLead, setDisplayTotalCloseLead] = useState(0);
  const [DisplayTotalCloseIndividualLead, setDisplayTotalCloseIndividualLead] = useState(0);
  const [DisplayTotalCloseFirmCompanyLead, setDisplayTotalCloseFirmCompanyLead] = useState(0);
  const [DisplayTotalClient, setDisplayTotalClient] = useState(0);
  const [DisplayTotalIndividualClient, setDisplayTotalIndividualClient] = useState(0);
  const [DisplayTotalFirmCompanyClient, setDisplayTotalFirmCompanyClient] = useState(0);

  const [DisplaySubBox, setDisplaySubBox] = useState(0);

  const [BarChart, setBarChart] = useState(0);
  const [PieChart, setPieChart] = useState(0);
  const [LineChart, setLineChart] = useState(0);
  const [AreaChart, setAreaChart] = useState(0);
  const [StackedAreaChart, setStackedAreaChart] = useState(0);

  const [DisplayLeadList, setDisplayLeadList] = useState(0);
  const [DisplayIndividualLeadList, setDisplayIndividualLeadList] = useState(0);
  const [DisplayFirmCompanyLeadList, setDisplayFirmCompanyLeadList] = useState(0);
  const [DisplayEnrollLeadList, setDisplayEnrollLeadList] = useState(0);
  const [DisplayIndividualEnrollLeadList, setDisplayIndividualEnrollLeadList] = useState(0);
  const [DisplayFirmCompanyEnrollLeadList, setDisplayFirmCompanyEnrollLeadList] = useState(0);
  const [DisplayConvertLeadList, setDisplayConvertLeadList] = useState(0);
  const [DisplayConvertIndividualLeadList, setDisplayConvertIndividualLeadList] = useState(0);
  const [DisplayConvertFirmCompanyLead, setDisplayConvertFirmCompanyLead] = useState(0);
  const [DisplayRegisterLeadList, setDisplayRegisterLeadList] = useState(0);
  const [DisplayRegisterIndividualLeadList, setDisplayRegisterIndividualLeadList] = useState(0);
  const [DisplayRegisterFirmCompanyLeadList, setDisplayRegisterFirmCompanyLeadList] = useState(0);
  const [DisplayCloseLeadList, setDisplayCloseLeadList] = useState(0);
  const [DisplayCloseIndividualLeadList, setDisplayCloseIndividualLeadList] = useState(0);
  const [DisplayCloseFirmCompanyLeadList, setDisplayCloseFirmCompanyLeadList] = useState(0);
  const [DisplayClientList, setDisplayClientList] = useState(0);
  const [DisplayIndividualClientList, setDisplayIndividualClientList] = useState(0);
  const [DisplayFirmCompanyClientList, setDisplayFirmCompanyClientList] = useState(0);

  const [DisplayLayoutTypeCount, setDisplayLayoutTypeCount] = useState('col-md-3');
  const [DisplayLayoutTypeCount1, setDisplayLayoutTypeCount1] = useState('3');
  const [DisplayLayoutTypeChart, setDisplayLayoutTypeChart] = useState('6');
  const [DisplayLayoutTypeList, setDisplayLayoutTypeList] = useState('12');

  const chartData = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "45%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["transparent"],
      },

      colors: ["#34c38f", "#556ee6", "#f46a6a"],
      xaxis: {
        categories: [
          "Lead",
          "Enroll",
          "Convert",
          "Register",
          "Close",
          "Client"
        ],
      },
      yaxis: {
        title: {
          text: "No. Of Inquiry",
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          },
        },
      },
    }, series: [
      {
        name: "Individual",
        data: [TotalIndividualLead, TotalIndividualLeadEnroll,
          TotalConvertIndividualLead, TotalRegisterIndividualLead,
          TotalCloseIndividualLead, TotalIndividualClient],
      },
      {
        name: "Total",
        data: [TotalLead, TotalEnrollLead, TotalConvertLead,
          TotalRegisterLead, TotalCloseLead, TotalClient],
      },
      {
        name: "Firm/Company",
        data: [TotalFirmCompanyLead, TotalFirmCompanyLeadEnroll,
          TotalConvertFirmCompanyLead,
          TotalRegisterFirmCompanyLead, TotalCloseFirmCompanyLead, TotalFirmCompanyClient],
      }
    ]
  };

  const pieChart = {
    series: [TotalLead, TotalEnrollLead, TotalConvertLead,
      TotalRegisterLead, TotalCloseLead, TotalClient],
    options: {
      labels: ["Lead",
        "Enroll",
        "Convert",
        "Register",
        "Close",
        "Client"],
      colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c", "#556ee6"],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: false,
        fontSize: "14px",
        offsetX: 0,
        offsetY: -10,
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 240,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  }

  const lineChart = {
    series: [
      {
        name: "Individual",
        data: [TotalIndividualLead, TotalIndividualLeadEnroll,
          TotalConvertIndividualLead, TotalRegisterIndividualLead,
          TotalCloseIndividualLead, TotalIndividualClient],
      },
      {
        name: "Total",
        data: [TotalLead, TotalEnrollLead, TotalConvertLead,
          TotalRegisterLead, TotalCloseLead, TotalClient],
      },
      {
        name: "Firm/Company",
        data: [TotalFirmCompanyLead, TotalFirmCompanyLeadEnroll,
          TotalConvertFirmCompanyLead,
          TotalRegisterFirmCompanyLead, TotalCloseFirmCompanyLead, TotalFirmCompanyClient],
      },
    ],
    options: {
      chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
      colors: ["#556ee6", "#f46a6a", "#34c38f"],
      dataLabels: { enabled: !1 },
      stroke: { width: [3, 4, 3], curve: "straight", dashArray: [0, 8, 5] },

      markers: { size: 0, hover: { sizeOffset: 6 } },
      xaxis: {
        categories: [
          "Lead",
          "Enroll",
          "Convert",
          "Register",
          "Close",
          "Client"
        ],
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (e) {
                return e + " (mins)"
              },
            },
          },
          {
            title: {
              formatter: function (e) {
                return e + " per session"
              },
            },
          },
          {
            title: {
              formatter: function (e) {
                return e
              },
            },
          },
        ],
      },
      grid: { borderColor: "#f1f1f1" },
    },

  };

  const areaChart = {
    series: [
      {
        name: "Individual",
        data: [TotalIndividualLead, TotalIndividualLeadEnroll,
          TotalConvertIndividualLead, TotalRegisterIndividualLead,
          TotalCloseIndividualLead, TotalIndividualClient],
      },
      {
        name: "Total",
        data: [TotalLead, TotalEnrollLead, TotalConvertLead,
          TotalRegisterLead, TotalCloseLead, TotalClient],
      },
      {
        name: "Firm/Company",
        data: [TotalFirmCompanyLead, TotalFirmCompanyLeadEnroll,
          TotalConvertFirmCompanyLead,
          TotalRegisterFirmCompanyLead, TotalCloseFirmCompanyLead, TotalFirmCompanyClient],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },

      colors: ["#556ee6", "#34c38f", "#f46a6a"],
      xaxis: {
        categories: [
          "Lead",
          "Enroll",
          "Convert",
          "Register",
          "Close",
          "Client"
        ],
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    }
  };

  const stackedBarChart = {
    options: {
      colors: ["#34c38f", "#556ee6", "#f46a6a"],
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: "40%",
        },
      },
      grid: {
        borderColor: "#f8f8fa",
        row: {
          colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },

      xaxis: {
        categories: [
          "Lead",
          "Enroll",
          "Convert",
          "Register",
          "Close",
          "Client"
        ],
        labels: {
          formatter: function (val) {
            return val
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          },
        },
      },
      fill: {
        opacity: 1,
      },

      legend: {
        show: false,
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
    series: [
      {
        name: "Individual",
        data: [TotalIndividualLead, TotalIndividualLeadEnroll,
          TotalConvertIndividualLead, TotalRegisterIndividualLead,
          TotalCloseIndividualLead, TotalIndividualClient],
      },
      {
        name: "Firm/Company",
        data: [TotalFirmCompanyLead, TotalFirmCompanyLeadEnroll,
          TotalConvertFirmCompanyLead,
          TotalRegisterFirmCompanyLead, TotalCloseFirmCompanyLead, TotalFirmCompanyClient],
      },
      {
        name: "Total",
        data: [TotalLead, TotalEnrollLead, TotalConvertLead,
          TotalRegisterLead, TotalCloseLead, TotalClient],
      }
    ],

  };

  useEffect(() => {
    GetUserDashboardWidgetLayout();
    GetUserDashboardWidgetList();
    getDashboardCount();
    getLeadList();
    getEnrolledList();
    getConvertedList();
    getRegisteredList();
    getClosedList();
    getClientList();
    registerClientdetails();
  }, []);

  const GetUserDashboardWidgetLayout = async () => {
    var VLoginDashboardSettingId = localStorage.getItem("LoginDashboardSettingId") == null ? "0" : localStorage.getItem("LoginDashboardSettingId") == "" ? "0" : localStorage.getItem("LoginDashboardSettingId")
    var AUrl = global.APIURL + '/UserDashboardSettingWidgetLayout/' + VLoginDashboardSettingId;
    axios.get(AUrl)
      .then(res => {
        if (res.data.length > 0) {
          const VDashboardSetting = res.data[0];
          var VDisplayLayoutTypeCount = VDashboardSetting.DisplayLayoutTypeCount == null ? 'col-3' : VDashboardSetting.DisplayLayoutTypeCount == '' ? 'col-3' : VDashboardSetting.DisplayLayoutTypeCount;
          var VDisplayLayoutTypeChart = VDashboardSetting.DisplayLayoutTypeChart == null ? 'col-6' : VDashboardSetting.DisplayLayoutTypeChart == '' ? 'col-6' : VDashboardSetting.DisplayLayoutTypeChart;
          var VDisplayLayoutTypeList = VDashboardSetting.DisplayLayoutTypeList == null ? 'col-6' : VDashboardSetting.DisplayLayoutTypeList == '' ? 'col-6' : VDashboardSetting.DisplayLayoutTypeList;

          if (VDisplayLayoutTypeCount == 'col-3') {
            setDisplayLayoutTypeCount('col-md-3');
            setDisplayLayoutTypeCount1('3')
          }
          else if (VDisplayLayoutTypeCount == 'col-4') {
            setDisplayLayoutTypeCount('col-md-4');
            setDisplayLayoutTypeCount1('4')
          }
          else {
            setDisplayLayoutTypeCount('col-md-3');
            setDisplayLayoutTypeCount1('3')
          }

          if (VDisplayLayoutTypeChart == 'col-6') {
            setDisplayLayoutTypeChart('6');
          }
          else if (VDisplayLayoutTypeChart == 'col-12') {
            setDisplayLayoutTypeChart('12');
          }
          else {
            setDisplayLayoutTypeChart('6');
          }

          if (VDisplayLayoutTypeList == 'col-6') {
            setDisplayLayoutTypeList('6');
          }
          else if (VDisplayLayoutTypeList == 'col-12') {
            setDisplayLayoutTypeList('12');
          }
          else {
            setDisplayLayoutTypeList('6');
          }
        }
        else {
          setDisplayLayoutTypeCount('col-md-3');
          setDisplayLayoutTypeCount1('3')
          setDisplayLayoutTypeChart('6');
          setDisplayLayoutTypeList('6');
        }
      })
  }

  const GetUserDashboardWidgetList = async () => {
    var VLoginDashboardSettingId = localStorage.getItem("LoginDashboardSettingId") == null ? "0" : localStorage.getItem("LoginDashboardSettingId") == "" ? "0" : localStorage.getItem("LoginDashboardSettingId")
    var AUrl = global.APIURL + '/UserDashboardSettingWidgetList/' + VLoginDashboardSettingId;
    axios.get(AUrl)
      .then(res => {
        if (res.data.length > 0) {
          const VDashboardSetting = res.data[0];
          var VLayoutType = VDashboardSetting.LayoutType;

          if (VLayoutType == 'col-3') {
            setDisplayLayoutTypeCount('col-md-3')
          }
          else if (VLayoutType == 'col-4') {
            setDisplayLayoutTypeCount('col-md-4')
          }
          else {
            setDisplayLayoutTypeCount('col-md-3')
          }
        }
        else {
          setDisplayLayoutTypeCount('col-md-3')
        }

        for (let ds = 0; ds < res.data.length; ds++) {
          const DashboardSetting = res.data[ds];
          var VWidgetValue = DashboardSetting.WidgetValue;
          if (VWidgetValue == 'Total Lead') {
            setDisplayTotalLead(1);
          }
          else if (VWidgetValue == 'Total Individual Lead') {
            setDisplayTotalIndividualLead(1);
          }
          else if (VWidgetValue == 'Total Firm/Company Lead') {
            setDisplayTotalFirmCompanyLead(1);
          }
          else if (VWidgetValue == 'Total Enrolled Lead') {
            setDisplayTotalEnrollLead(1);
            setDisplaySubBox(1);
          }
          else if (VWidgetValue == 'Total Individual Enrolled Lead') {
            setDisplayTotalIndividualLeadEnroll(1);
            setDisplaySubBox(1);
          }
          else if (VWidgetValue == 'Total Firm/Company Enrolled Lead') {
            setDisplayTotalFirmCompanyLeadEnroll(1);
            setDisplaySubBox(1);
          }
          else if (VWidgetValue == 'Total Converted Lead') {
            setDisplayTotalConvertLead(1);
            setDisplaySubBox(1);
          }
          else if (VWidgetValue == 'Total Individual Converted Lead') {
            setDisplayTotalConvertIndividualLead(1);
            setDisplaySubBox(1);
          }
          else if (VWidgetValue == 'Total Firm/Company Converted Lead') {
            setDisplayTotalConvertFirmCompanyLead(1);
            setDisplaySubBox(1);
          }
          else if (VWidgetValue == 'Total Registered Lead') {
            setDisplayTotalRegisterLead(1);
            setDisplaySubBox(1);
          }
          else if (VWidgetValue == 'Total Individual Registered Lead') {
            setDisplayTotalRegisterIndividualLead(1);
            setDisplaySubBox(1);
          }
          else if (VWidgetValue == 'Total Firm/Company Registered Lead') {
            setDisplayTotalRegisterFirmCompanyLead(1);
            setDisplaySubBox(1);
          }
          else if (VWidgetValue == 'Total Closed Lead') {
            setDisplayTotalCloseLead(1);
            setDisplaySubBox(1);
          }
          else if (VWidgetValue == 'Total Individual Closed Lead') {
            setDisplayTotalCloseIndividualLead(1);
            setDisplaySubBox(1);
          }
          else if (VWidgetValue == 'Total Firm/Company Closed Lead') {
            setDisplayTotalCloseFirmCompanyLead(1);
            setDisplaySubBox(1);
          }
          else if (VWidgetValue == 'Total Client') {
            setDisplayTotalClient(1);
            setDisplaySubBox(1);
          }
          else if (VWidgetValue == 'Total Individual Client') {
            setDisplayTotalIndividualClient(1);
            setDisplaySubBox(1);
          }
          else if (VWidgetValue == 'Total Firm/Company Client') {
            setDisplayTotalFirmCompanyClient(1);
            setDisplaySubBox(1);
          }
          else if (VWidgetValue == 'Bar Chart') {
            setBarChart(1);
          }
          else if (VWidgetValue == 'Pie Chart') {
            setPieChart(1);
          }
          else if (VWidgetValue == 'Line Chart') {
            setLineChart(1);
          }
          else if (VWidgetValue == 'Area Chart') {
            setAreaChart(1);
          }
          else if (VWidgetValue == 'Stacked Area Chart') {
            setStackedAreaChart(1);
          }
          else if (VWidgetValue == 'Lead List') {
            setDisplayLeadList(1);
          }
          else if (VWidgetValue == 'Lead Individual List') {
            setDisplayIndividualLeadList(1);
          }
          else if (VWidgetValue == 'Lead Firm/Company List') {
            setDisplayFirmCompanyLeadList(1);
          }
          else if (VWidgetValue == 'Enrolled List') {
            setDisplayEnrollLeadList(1);
          }
          else if (VWidgetValue == 'Enrolled Individual List') {
            setDisplayIndividualEnrollLeadList(1);
          }
          else if (VWidgetValue == 'Enrolled Firm/Company List') {
            setDisplayFirmCompanyEnrollLeadList(1);
          }
          else if (VWidgetValue == 'Converted List') {
            setDisplayConvertLeadList(1);
          }
          else if (VWidgetValue == 'Converted Individual List') {
            setDisplayConvertIndividualLeadList(1);
          }
          else if (VWidgetValue == 'Converted Firm/Company List') {
            setDisplayConvertFirmCompanyLead(1);
          }
          else if (VWidgetValue == 'Registered List') {
            setDisplayRegisterLeadList(1);
          }
          else if (VWidgetValue == 'Registered Individual List') {
            setDisplayRegisterIndividualLeadList(1);
          }
          else if (VWidgetValue == 'Registered Firm/Company List') {
            setDisplayRegisterFirmCompanyLeadList(1);
          }
          else if (VWidgetValue == 'Closed List') {
            setDisplayCloseLeadList(1);
          }
          else if (VWidgetValue == 'Closed Individual List') {
            setDisplayCloseIndividualLeadList(1);
          }
          else if (VWidgetValue == 'Closed Firm/Company List') {
            setDisplayCloseFirmCompanyLeadList(1);
          }
          else if (VWidgetValue == 'Client List') {
            setDisplayClientList(1);
          }
          else if (VWidgetValue == 'Client Individual List') {
            setDisplayIndividualClientList(1);
          }
          else if (VWidgetValue == 'Client Firm/Company List') {
            setDisplayFirmCompanyClientList(1);
          }
        }
      })
  }

  const getDashboardCount = async () => {
    var VRecordType = document.getElementById("ddlRecordType").value;
    const LeadListFilterArr = { RecordType: VRecordType, SpMode: 'DashboardCountSelectAll' }
    var AUrl = global.APIURL + '/DashboardCount/';
    axios.post(AUrl, LeadListFilterArr)
      .then(res => {
        setTotalLead(res.data[0].TotalLead);
        setTotalIndividualLead(res.data[0].TotalIndividualLead);
        setTotalFirmCompanyLead(res.data[0].TotalFirmCompanyLead);
        setTotalEnrollLead(res.data[0].TotalEnrollLead);
        setTotalIndividualLeadEnroll(res.data[0].TotalIndividualLeadEnroll);
        setTotalFirmCompanyLeadEnroll(res.data[0].TotalFirmCompanyLeadEnroll);
        setTotalConvertLead(res.data[0].TotalConvertLead);
        setTotalConvertIndividualLead(res.data[0].TotalConvertIndividualLead);
        setTotalConvertFirmCompanyLead(res.data[0].TotalConvertFirmCompanyLead);
        setTotalRegisterLead(res.data[0].TotalRegisterLead);
        setTotalRegisterIndividualLead(res.data[0].TotalRegisterIndividualLead);
        setTotalRegisterFirmCompanyLead(res.data[0].TotalRegisterFirmCompanyLead);
        setTotalCloseLead(res.data[0].TotalCloseLead);
        setTotalCloseIndividualLead(res.data[0].TotalCloseIndividualLead);
        setTotalCloseFirmCompanyLead(res.data[0].TotalCloseFirmCompanyLead);
        setTotalClient(res.data[0].TotalClient);
        setTotalIndividualClient(res.data[0].TotalIndividualClient);
        setTotalFirmCompanyClient(res.data[0].TotalFirmCompanyClient);
      })
  }

  const RecordTypeOnChange = e => {
    getDashboardCount();
    getLeadList();
    getEnrolledList();
    getConvertedList();
    getRegisteredList();
    getClosedList();
    getClientList();
  };

  const getLeadList = async () => {
    var VLeadType = document.getElementById("ddlALLeadType").value
    var VRecordType = document.getElementById("ddlRecordType").value
    const LeadListFilterArr = { LeadType: VLeadType, RecordType: VRecordType, SpMode: 'DashboardLeadListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setLeadList(VLeadList);
      });
  }

  const LeadTypeOnChange = e => {
    getLeadList();
  };

  const getEnrolledList = async () => {
    var VLeadType = document.getElementById("ddlALLeadType").value
    var VRecordType = document.getElementById("ddlRecordType").value
    const LeadListFilterArr = { LeadType: VLeadType, RecordType: VRecordType, SpMode: 'DashboardEnrolledListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setEnrolledList(VLeadList);
      });
  }

  const getConvertedList = async () => {
    var VLeadType = document.getElementById("ddlALLeadType").value
    var VRecordType = document.getElementById("ddlRecordType").value
    const LeadListFilterArr = { LeadType: VLeadType, RecordType: VRecordType, SpMode: 'DashboardConvertedListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setConvertedList(VLeadList);
      });
  }

  const getRegisteredList = async () => {
    var VLeadType = document.getElementById("ddlALLeadType").value
    var VRecordType = document.getElementById("ddlRecordType").value
    const LeadListFilterArr = { LeadType: VLeadType, RecordType: VRecordType, SpMode: 'DashboardRegisteredListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setRegisteredList(VLeadList);
      });
  }

  const getClosedList = async () => {
    var VLeadType = document.getElementById("ddlALLeadType").value
    var VRecordType = document.getElementById("ddlRecordType").value
    const LeadListFilterArr = { LeadType: VLeadType, RecordType: VRecordType, SpMode: 'DashboardClosedListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setClosedList(VLeadList);
      });
  }

  const registerClientdetails = async (e) => {

    fetch(global.APIURL + "/CorporateRegisterClientList")
      .then((res) => res.json())
      .then((json) => {
        SetRegisterClient(json)



      })
  };


  const getClientList = async () => {
    var VLeadType = document.getElementById("ddlALLeadType").value
    var VRecordType = document.getElementById("ddlRecordType").value
    const LeadListFilterArr = { LeadType: VLeadType, RecordType: VRecordType, SpMode: 'DashboardClientListAll' }
    var AUrl = global.APIURL + '/DashboardLeadList/';
    axios.post(AUrl, LeadListFilterArr)
      .then(LCdres => {
        let VLeadList = LCdres.data.map((LeadL) => {
          return LeadL
        })
        setClientList(VLeadList);
      });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | KC Admin Group</title>
        </MetaTags>
        <div className="container-fluid">
          <Row className="align-items-center justify-content-between mb-3">
            <div className="col-lg-8">
              <CardTitle className="mb-0">Dashboard</CardTitle>
            </div>

            <Col className="col-lg-4 mb-0">
              <div className="d-flex align-items-center justify-content-end">
                <div className="d-flex align-items-center me-3 justify-content-center">
                  <div className="me-2">
                    <CardTitle className="me-3 mb-0">Lead Type :</CardTitle>
                  </div>
                  <div>
                    <select id="ddlALLeadType" className="form-select selectbtnedit" onChange={LeadTypeOnChange} >
                      <option value={"ALL"}>ALL</option>
                      <option value={"Individual"}>Individual</option>
                      <option value={"Firm/Company"}>Firm/Company</option>
                    </select>
                  </div>
                </div>
                <div >
                  <select id="ddlRecordType" className="form-select selectbtnedit" onChange={RecordTypeOnChange} >
                    <option value={"Today"}>Today</option>
                    <option value={"Week"}>This Week</option>
                    <option value={"Month"}>This Month</option>
                    <option value={"Year"}>This Year</option>
                    <option value={"ALL"}>ALL</option>
                  </select>
                </div>

              </div>
            </Col>


          </Row>
          <Row>
            {DisplayTotalLead > 0 ?
              <div className={DisplayLayoutTypeCount}>
                <a href='AllInq'>
                  <div className="card">
                    <div className="p-3">
                      <div className="d-flex justify-content-between">
                        <div className="contentmaindashboard">
                          <h2>
                            {TotalLead}
                          </h2>
                          <p>
                            Total Leads
                          </p>
                        </div>
                        <div className="imgboxdashboardmain">
                          <img src={totalleads}></img>
                        </div>
                      </div>

                    </div>

                  </div>
                </a>
              </div> : ''}
            {DisplayTotalIndividualLead > 0 ?
              <div className={DisplayLayoutTypeCount}>
                <a href='AllInq'>
                  <div className="card">
                    <div className="p-3">
                      <div className="d-flex justify-content-between">
                        <div className="contentmaindashboard">
                          <h2>
                            {TotalIndividualLead}
                          </h2>
                          <p>
                            Total Individual Lead
                          </p>
                        </div>
                        <div className="imgboxdashboardmain">
                          <img src={totalindivisuallead}></img>
                        </div>
                      </div>

                    </div>

                  </div>
                </a>

              </div> : ''}
            {DisplayTotalFirmCompanyLead > 0 ?
              <div className={DisplayLayoutTypeCount}>
                <a href='AllInq'>
                  <div className="card">
                    <div className="p-3">
                      <div className="d-flex justify-content-between">
                        <div className="contentmaindashboard">
                          <h2>
                            {TotalFirmCompanyLead}
                          </h2>
                          <p>
                            Total Firm/Company Lead
                          </p>
                        </div>
                        <div className="imgboxdashboardmain">
                          <img src={totalfirm}></img>
                        </div>
                      </div>

                    </div>

                  </div>
                </a>


              </div> : ''}

            {DisplayTotalClient > 0 ?
              <div className={DisplayLayoutTypeCount}>
                <a href='ClientLead'>
                  <div className="card">
                    <div className="p-3">
                      <div className="d-flex justify-content-between">
                        <div className="contentmaindashboard">
                          <h2>
                            {TotalClient}
                          </h2>
                          <p> Total Client
                          </p>
                        </div>
                        <div className="imgboxdashboardmain">
                          <img src={totalclient}></img>
                        </div>
                      </div>

                    </div>

                  </div>
                </a>


              </div> : ''}
          </Row>

          <Row>
            {DisplaySubBox > 0 ?
              <div className="col-md-12">
                <div className="card">
                  <div className="p-4">
                    <div className="row">
                      {DisplayTotalEnrollLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                        <a href='LeadList'>
                          <Row>
                            <Col md="12" className="mb-2">
                              <div className="mini-stats-wid mini-stats-widnew">
                                <div>
                                  <div className="countimgmain">
                                    <img src={leadmain}></img>
                                  </div>
                                </div>
                                <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                                  <div className="d-flex justify-content-between w-100 align-items-center">
                                    <div>
                                      <p className="ndtitaleedit">
                                        <span className="mb-0 ndedit">{TotalEnrollLead}</span>
                                        Total Enrolled Lead
                                      </p>

                                    </div>
                                    <div>
                                      <i className="fas fa-chevron-right"></i>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </a>
                      </Col> : ''}

                      {DisplayTotalIndividualLeadEnroll > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                        <a href='LeadList'>
                          <Row>
                            <Col md="12" className="mb-2">
                              <div className="mini-stats-wid mini-stats-widnew">
                                <div>
                                  <div className="countimgmain">
                                    <img src={leadmain}></img>
                                  </div>
                                </div>
                                <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                                  <div className="d-flex justify-content-between w-100 align-items-center">
                                    <div>
                                      <p className="ndtitaleedit">
                                        <span className="mb-0 ndedit">{TotalIndividualLeadEnroll}</span>
                                        Total Individual Enrolled Lead
                                      </p>

                                    </div>
                                    <div>
                                      <i className="fas fa-chevron-right"></i>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </a>
                      </Col> : ''}

                      {DisplayTotalFirmCompanyLeadEnroll > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                        <a href='LeadList'>
                          <Row>
                            <Col md="12" className="mb-2">
                              <div className="mini-stats-wid mini-stats-widnew">
                                <div>
                                  <div className="countimgmain">
                                    <img src={leadmain}></img>
                                  </div>
                                </div>
                                <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                                  <div className="d-flex justify-content-between w-100 align-items-center">
                                    <div>
                                      <p className="ndtitaleedit">
                                        <span className="mb-0 ndedit">{TotalFirmCompanyLeadEnroll}</span>
                                        Total Firm/Company Enrolled Lead
                                      </p>

                                    </div>
                                    <div>
                                      <i className="fas fa-chevron-right"></i>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </a>
                      </Col> : ''}

                      {DisplayTotalConvertLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                        <a href='ConvertedInq'>
                          <Row>
                            <Col md="12" className="mb-2">
                              <div className="mini-stats-wid mini-stats-widnew">
                                <div>
                                  <div className="countimgmain">
                                    <img src={convertedlead}></img>
                                  </div>
                                </div>
                                <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                                  <div className="d-flex justify-content-between w-100 align-items-center">
                                    <div>
                                      <p className="ndtitaleedit">
                                        <span className="mb-0 ndedit">{TotalConvertLead}</span>
                                        Total Converted Lead
                                      </p>

                                    </div>
                                    <div>
                                      <i className="fas fa-chevron-right"></i>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </a>
                      </Col> : ''}

                      {DisplayTotalConvertIndividualLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                        <a href='ConvertedInq'>
                          <Row>
                            <Col md="12" className="mb-2">
                              <div className="mini-stats-wid mini-stats-widnew">
                                <div>
                                  <div className="countimgmain">
                                    <img src={convertedlead}></img>
                                  </div>
                                </div>
                                <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                                  <div className="d-flex justify-content-between w-100 align-items-center">
                                    <div>
                                      <p className="ndtitaleedit">
                                        <span className="mb-0 ndedit">{TotalConvertIndividualLead}</span>
                                        Total Converted Individual Lead
                                      </p>

                                    </div>
                                    <div>
                                      <i className="fas fa-chevron-right"></i>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </a>
                      </Col> : ''}

                      {DisplayTotalConvertFirmCompanyLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                        <a href='ConvertedInq'>
                          <Row>
                            <Col md="12" className="mb-2">
                              <div className="mini-stats-wid mini-stats-widnew">
                                <div>
                                  <div className="countimgmain">
                                    <img src={convertedlead}></img>
                                  </div>
                                </div>
                                <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                                  <div className="d-flex justify-content-between w-100 align-items-center">
                                    <div>
                                      <p className="ndtitaleedit">
                                        <span className="mb-0 ndedit">{TotalConvertFirmCompanyLead}</span>
                                        Total Converted Firm/Company Lead
                                      </p>

                                    </div>
                                    <div>
                                      <i className="fas fa-chevron-right"></i>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </a>
                      </Col> : ''}

                      {DisplayTotalRegisterLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                        <a href='RegisteredList'>
                          <Row>
                            <Col md="12" className="mb-2">
                              <div className="mini-stats-wid mini-stats-widnew">
                                <div>
                                  <div className="countimgmain">
                                    <img src={registeredlead}></img>
                                  </div>
                                </div>
                                <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                                  <div className="d-flex justify-content-between w-100 align-items-center">
                                    <div>
                                      <p className="ndtitaleedit">
                                        <span className="mb-0 ndedit">{TotalRegisterLead}</span>
                                        Total Registered Lead
                                      </p>

                                    </div>
                                    <div>
                                      <i className="fas fa-chevron-right"></i>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </a>
                      </Col> : ''}

                      {DisplayTotalRegisterIndividualLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                        <a href='RegisteredList'>
                          <Row>
                            <Col md="12" className="mb-2">
                              <div className="mini-stats-wid mini-stats-widnew">
                                <div>
                                  <div className="countimgmain">
                                    <img src={registeredlead}></img>
                                  </div>
                                </div>
                                <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                                  <div className="d-flex justify-content-between w-100 align-items-center">
                                    <div>
                                      <p className="ndtitaleedit">
                                        <span className="mb-0 ndedit">{TotalRegisterIndividualLead}</span>
                                        Total Registered Individual Lead
                                      </p>

                                    </div>
                                    <div>
                                      <i className="fas fa-chevron-right"></i>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </a>
                      </Col> : ''}

                      {DisplayTotalRegisterFirmCompanyLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                        <a href='RegisteredList'>
                          <Row>
                            <Col md="12" className="mb-2">
                              <div className="mini-stats-wid mini-stats-widnew">
                                <div>
                                  <div className="countimgmain">
                                    <img src={registeredlead}></img>
                                  </div>
                                </div>
                                <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                                  <div className="d-flex justify-content-between w-100 align-items-center">
                                    <div>
                                      <p className="ndtitaleedit">
                                        <span className="mb-0 ndedit">{TotalRegisterFirmCompanyLead}</span>
                                        Total Registered Firm/Company Lead
                                      </p>

                                    </div>
                                    <div>
                                      <i className="fas fa-chevron-right"></i>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </a>
                      </Col> : ''}

                      {DisplayTotalCloseLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                        <a href='ClosedList'>
                          <Row>
                            <Col md="12" className="mb-2">
                              <div className="mini-stats-wid mini-stats-widnew">
                                <div>
                                  <div className="countimgmain">
                                    <img src={closedlead}></img>
                                  </div>
                                </div>
                                <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                                  <div className="d-flex justify-content-between w-100 align-items-center">
                                    <div>
                                      <p className="ndtitaleedit">
                                        <span className="mb-0 ndedit">{TotalCloseLead}</span>
                                        Total Closed Lead
                                      </p>

                                    </div>
                                    <div>
                                      <i className="fas fa-chevron-right"></i>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </a>
                      </Col> : ''}

                      {DisplayTotalCloseIndividualLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                        <a href='ClosedList'>
                          <Row>
                            <Col md="12" className="mb-2">
                              <div className="mini-stats-wid mini-stats-widnew">
                                <div>
                                  <div className="countimgmain">
                                    <img src={closedlead}></img>
                                  </div>
                                </div>
                                <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                                  <div className="d-flex justify-content-between w-100 align-items-center">
                                    <div>
                                      <p className="ndtitaleedit">
                                        <span className="mb-0 ndedit">{TotalCloseIndividualLead}</span>
                                        Total Closed Individual Lead
                                      </p>

                                    </div>
                                    <div>
                                      <i className="fas fa-chevron-right"></i>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </a>
                      </Col> : ''}

                      {DisplayTotalCloseFirmCompanyLead > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                        <a href='ClosedList'>
                          <Row>
                            <Col md="12" className="mb-2">
                              <div className="mini-stats-wid mini-stats-widnew">
                                <div>
                                  <div className="countimgmain">
                                    <img src={closedlead}></img>
                                  </div>
                                </div>
                                <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                                  <div className="d-flex justify-content-between w-100 align-items-center">
                                    <div>
                                      <p className="ndtitaleedit">
                                        <span className="mb-0 ndedit">{TotalCloseFirmCompanyLead}</span>
                                        Total Closed Firm/Company Lead
                                      </p>

                                    </div>
                                    <div>
                                      <i className="fas fa-chevron-right"></i>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </a>
                      </Col> : ''}

                      {DisplayTotalClient > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                        <a href='ClientLead'>
                          <Row>
                            <Col md="12" className="mb-2">
                              <div className="mini-stats-wid mini-stats-widnew">
                                <div>
                                  <div className="countimgmain">
                                    <img src={clientlead}></img>
                                  </div>
                                </div>
                                <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                                  <div className="d-flex justify-content-between w-100 align-items-center">
                                    <div>
                                      <p className="ndtitaleedit">
                                        <span className="mb-0 ndedit">{TotalClient}</span>
                                        Total Client
                                      </p>

                                    </div>
                                    <div>
                                      <i className="fas fa-chevron-right"></i>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </a>
                      </Col> : ''}

                      {DisplayTotalIndividualClient > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                        <a href='ClientLead'>
                          <Row>
                            <Col md="12" className="mb-2">
                              <div className="mini-stats-wid mini-stats-widnew">
                                <div>
                                  <div className="countimgmain">
                                    <img src={clientlead}></img>
                                  </div>
                                </div>
                                <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                                  <div className="d-flex justify-content-between w-100 align-items-center">
                                    <div>
                                      <p className="ndtitaleedit">
                                        <span className="mb-0 ndedit">{TotalIndividualClient}</span>
                                        Total Individual Client
                                      </p>

                                    </div>
                                    <div>
                                      <i className="fas fa-chevron-right"></i>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </a>
                      </Col> : ''}

                      {DisplayTotalFirmCompanyClient > 0 ? <Col xl={DisplayLayoutTypeCount1}>
                        <a href='ClientLead'>
                          <Row>
                            <Col md="12" className="mb-2">
                              <div className="mini-stats-wid mini-stats-widnew">
                                <div>
                                  <div className="countimgmain">
                                    <img src={clientlead}></img>
                                  </div>
                                </div>
                                <div className="w-100 ps-3 pe-3 pt-2 pb-2">
                                  <div className="d-flex justify-content-between w-100 align-items-center">
                                    <div>
                                      <p className="ndtitaleedit">
                                        <span className="mb-0 ndedit">{TotalFirmCompanyClient}</span>
                                        Total Firm/Company Client
                                      </p>

                                    </div>
                                    <div>
                                      <i className="fas fa-chevron-right"></i>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                        </a>
                      </Col> : ''}
                    </div>

                  </div>

                </div>

              </div>
              : ''}

            {BarChart > 0 ? <Col xl={DisplayLayoutTypeChart}>
              <Card className="heighfixdashbiardchart">
                <CardBody>
                  <Row className="pt-4">
                    <div className="card-body">
                      <p className="text-muted fw-medium">
                        Bar Chart
                      </p>
                      <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={300} className="apex-charts" />
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col> : ''}

            {PieChart > 0 ? <Col xl={DisplayLayoutTypeChart}>
              <Card className="heighfixdashbiardchart">
                <CardBody>
                  <Row className="pt-4">
                    <div className="card-body">
                      <p className="text-muted fw-medium">
                        Pie Chart
                      </p>
                      <ReactApexChart options={pieChart.options} series={pieChart.series} type="pie" height={300} className="apex-charts" />
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col> : ''}

            {LineChart > 0 ? <Col xl={DisplayLayoutTypeChart}>
              <Card className="heighfixdashbiardchart">
                <CardBody>
                  <Row className="pt-4">
                    <div className="card-body">
                      <p className="text-muted fw-medium">
                        Line Chart
                      </p>
                      <ReactApexChart options={lineChart.options} series={lineChart.series} type="line" height={300} className="apex-charts" />
                    </div>
                  </Row>
                </CardBody>
              </Card >
            </Col > : ''}

            {AreaChart > 0 ? <Col xl={DisplayLayoutTypeChart}>
              <Card className="heighfixdashbiardchart">
                <CardBody>
                  <Row className="pt-4">
                    <div className="card-body">
                      <p className="text-muted fw-medium">
                        Area Chart
                      </p>
                      <ReactApexChart options={areaChart.options} series={areaChart.series} type="area" height={300} className="apex-charts" />
                    </div>
                  </Row>
                </CardBody>
              </Card >
            </Col > : ''}

            {StackedAreaChart > 0 ? <Col xl={DisplayLayoutTypeChart}>
              <Card className="heighfixdashbiardchart">
                <CardBody>
                  <Row className="pt-4">
                    <div className="card-body">
                      <p className="text-muted fw-medium">
                        Stacked Area Chart
                      </p>
                      <ReactApexChart options={stackedBarChart.options} series={stackedBarChart.series} type="bar" height={300} className="apex-charts" />
                    </div>
                  </Row>
                </CardBody>
              </Card >
            </Col > : ''}

            {DisplayLeadList > 0 ? <Col xl={DisplayLayoutTypeList}>
              <Row>
                <Col md="12" className="mb-2">
                  <Card className="mini-stats-wid heighfixdashbiard">
                    <CardBody>
                      <Row className="justify-content-between">
                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2">Lead List</CardTitle>
                        </Col>

                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2"><a href='/AllInq' className="viewlistbutton">View More</a></CardTitle>
                        </Col>

                      </Row>
                      <Col className="col-lg-12 mb-2">
                        <div>
                          <table className="table">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col" className='text-center'>Inquiry Date</th>
                                <th scope="col">Name</th>
                                <th scope="col" className='text-center'>Lead Type</th>
                                <th scope="col">Contact </th>
                                <th scope="col">Email Address</th>
                                {/* <th scope="col">Source</th>
                                <th scope="col">Sub Source</th>
                                <th scope="col" className='text-center'>Priority</th>
                                <th scope="col" className='text-center'>Status</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {LeadList.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{item.SR}</td>
                                  <td className='text-center'>{item.CreateDate}</td>
                                  <td>{item.FullName}<br />{item.LeadCode}</td>
                                  <td className='text-center'>{item.LeadType}</td>
                                  <td>{item.MobileNo}</td>
                                  <td>{item.EmailAddress}</td>
                                  {/* <td>{item.ChannelName}</td>
                                  <td>{item.ChannelPartnerName}</td>
                                  <td className='text-center'>{item.Priority}</td>
                                  <td className='text-center'>{item.LeadStatus}</td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col> : ''}

            {DisplayEnrollLeadList > 0 ? <Col xl={DisplayLayoutTypeList}>
              <Row>
                <Col md="12" className="mb-2">
                  <Card className="mini-stats-wid heighfixdashbiard">
                    <CardBody>
                      <Row className="justify-content-between">
                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2">Enrolled List</CardTitle>
                        </Col>

                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2"><a href='/LeadList' className="viewlistbutton">View More</a></CardTitle>
                        </Col>

                      </Row>
                      <Col className="col-lg-12 mb-2">
                        <div>
                          <table className="table">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col" className='text-center'>Inquiry Date</th>
                                <th scope="col">Name</th>
                                <th scope="col" className='text-center'>Lead Type</th>
                                <th scope="col">Contact Number</th>
                                <th scope="col">Email Address</th>
                                {/* <th scope="col">Source</th>
                                <th scope="col">Sub Source</th>
                                <th scope="col" className='text-center'>Priority</th>
                                <th scope="col" className='text-center'>Status</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {EnrolledList.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{item.SR}</td>
                                  <td className='text-center'>{item.CreateDate}</td>
                                  <td>{item.FullName}<br />{item.LeadCode}</td>
                                  <td className='text-center'>{item.LeadType}</td>
                                  <td>{item.MobileNo}</td>
                                  <td>{item.EmailAddress}</td>
                                  {/* <td>{item.ChannelName}</td>
                                  <td>{item.ChannelPartnerName}</td>
                                  <td className='text-center'>{item.Priority}</td>
                                  <td className='text-center'>{item.LeadStatus}</td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col> : ''}

            {DisplayConvertLeadList > 0 ? <Col xl={DisplayLayoutTypeList}>
              <Row>
                <Col md="12" className="mb-2">
                  <Card className="mini-stats-wid heighfixdashbiard">
                    <CardBody>
                      <Row className="justify-content-between">
                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2">Converted List</CardTitle>
                        </Col>

                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2"><a href='/ConvertedInq' className="viewlistbutton">View More</a></CardTitle>
                        </Col>

                      </Row>
                      <Col className="col-lg-12 mb-2">
                        <div>
                          <table className="table">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col" className='text-center'>Converted Date</th>
                                <th scope="col">Name</th>
                                <th scope="col" className='text-center'>Lead Type</th>
                                <th scope="col">Contact Number</th>
                                <th scope="col">Email Address</th>
                                {/* <th scope="col">Source</th>
                                <th scope="col">Sub Source</th>
                                <th scope="col" className='text-center'>Priority</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {ConvertedList.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{item.SR}</td>
                                  <td className='text-center'>{item.ConvertDate}</td>
                                  <td>{item.FullName}<br />{item.LeadCode}</td>
                                  <td className='text-center'>{item.LeadType}</td>
                                  <td>{item.MobileNo}</td>
                                  <td>{item.EmailAddress}</td>
                                  {/* <td>{item.ChannelName}</td>
                                  <td>{item.ChannelPartnerName}</td>
                                  <td className='text-center'>{item.Priority}</td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col> : ''}

            {DisplayRegisterLeadList > 0 ? <Col xl={DisplayLayoutTypeList}>
              <Row>
                <Col md="12" className="mb-2">
                  <Card className="mini-stats-wid heighfixdashbiard">
                    <CardBody>
                      <Row className="justify-content-between">
                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2">Registered List</CardTitle>
                        </Col>

                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2"><a href='/RegisteredList' className="viewlistbutton">View More</a></CardTitle>
                        </Col>

                      </Row>
                      <Col className="col-lg-12 mb-2">
                        <div>
                          <table className="table">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col" className='text-center'>Registered Date</th>
                                <th scope="col">Name</th>
                                <th scope="col" className='text-center'>Lead Type</th>
                                <th scope="col">Contact Number</th>
                                <th scope="col">Email Address</th>
                                {/* <th scope="col">Source</th>
                                <th scope="col">Sub Source</th>
                                <th scope="col" className='text-center'>Priority</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {RegisteredList.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{item.SR}</td>
                                  <td className='text-center'>{item.RegisterDate}</td>
                                  <td>{item.FullName}<br />{item.LeadCode}</td>
                                  <td className='text-center'>{item.LeadType}</td>
                                  <td>{item.MobileNo}</td>
                                  <td>{item.EmailAddress}</td>
                                  {/* <td>{item.ChannelName}</td>
                                  <td>{item.ChannelPartnerName}</td>
                                  <td className='text-center'>{item.Priority}</td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col> : ''}

            {DisplayCloseLeadList > 0 ? <Col xl={DisplayLayoutTypeList}>
              <Row>
                <Col md="12" className="mb-2">
                  <Card className="mini-stats-wid heighfixdashbiard">
                    <CardBody>
                      <Row className="justify-content-between">
                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2">Closed List</CardTitle>
                        </Col>

                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2"><a href='/ClosedList' className="viewlistbutton">View More</a></CardTitle>
                        </Col>

                      </Row>
                      <Col className="col-lg-12 mb-2">
                        <div>
                          <table className="table">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col" className='text-center'>Closed Date</th>
                                <th scope="col">Name</th>
                                <th scope="col" className='text-center'>Lead Type</th>
                                <th scope="col">Contact Number</th>
                                <th scope="col">Email Address</th>
                                {/* <th scope="col">Source</th>
                                <th scope="col">Sub Source</th>
                                <th scope="col" className='text-center'>Priority</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {ClosedList.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{item.SR}</td>
                                  <td className='text-center'>{item.CloseDate}</td>
                                  <td>{item.FullName}<br />{item.LeadCode}</td>
                                  <td className='text-center'>{item.LeadType}</td>
                                  <td>{item.MobileNo}</td>
                                  <td>{item.EmailAddress}</td>
                                  {/* <td>{item.ChannelName}</td>
                                  <td>{item.ChannelPartnerName}</td>
                                  <td className='text-center'>{item.Priority}</td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col> : ''}

            {DisplayClientList > 0 ? <Col xl={DisplayLayoutTypeList}>
              <Row>
                <Col md="12" className="mb-2">
                  <Card className="mini-stats-wid heighfixdashbiard">
                    <CardBody>
                      <Row className="justify-content-between">
                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2">Client List</CardTitle>
                        </Col>

                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2"><a href='/ClientLead' className="viewlistbutton">View More</a></CardTitle>
                        </Col>

                      </Row>
                      <Col className="col-lg-12 mb-2">
                        <div>
                          <table className="table">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col" className='text-center'>Register Date</th>
                                <th scope="col">Name</th>
                                <th scope="col" className='text-center'>Client Type</th>
                                <th scope="col">Contact Number</th>
                                <th scope="col">Email Address</th>
                                {/* <th scope="col">Source</th>
                                <th scope="col">Sub Source</th>
                                <th scope="col" className='text-center'>Priority</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {ClientList.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{item.SR}</td>
                                  <td className='text-center'>{item.CreateDate}</td>
                                  <td>{item.FullName}<br />{item.CustomerCode}</td>
                                  <td className='text-center'>{item.CustomerType}</td>
                                  <td>{item.MobileNo}</td>
                                  <td>{item.EmailAddress}</td>
                                  {/* <td>{item.ChannelName}</td>
                                  <td>{item.ChannelPartnerName}</td>
                                  <td className='text-center'>{item.Priority}</td> */}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col> : ''}
            {DisplayClientList > 0 ? <Col xl={DisplayLayoutTypeList}>
              <Row>
                <Col md="12" className="mb-2">
                  <Card className="mini-stats-wid heighfixdashbiard">
                    <CardBody>
                      <Row className="justify-content-between">
                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2">Register Corporate Client</CardTitle>
                        </Col>

                        <Col className="col-lg-4 mb-0">
                          <CardTitle className="mt-2"><a href='/RegisterClientList' className="viewlistbutton">View More</a></CardTitle>
                        </Col>

                      </Row>
                      <Col className="col-lg-12 mb-2">
                        <div >
                          <table className="table">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col" className='text-center'>Name</th>
                                <th scope="col">Contact & Email</th>
                                <th scope="col">Rate</th>
                              </tr>
                            </thead>
                            <tbody>
                              {RegisterClient.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{idx + 1}</td>
                                  <td className='text-center'>{item.Name}</td>
                                  <td>{item.MobileNo}<br />{item.ClientEmail}</td>
                                  <td>{item.Rate}<br />{item.RateValue}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col> : ''}
          </Row>
        </div>
      </div>

    </React.Fragment>
  );

}

export default OnSort;
