import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input, Form } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom';
import e from "cors";

const OnSort = () => {
  const history = useHistory();
  const [ChannelSourceList, setChannelSourceList] = useState([]);
  const [ChannelSubSourceList, setChannelSubSourceList] = useState([]);
  const [ChannelId, setChannelId] = useState(0);
  const [ChannelSubSourceId, setChannelSubSourceId] = useState(0);
  const [SelectedVisitPhoto1, SetSelectedVisitPhoto1] = useState('');
  const [SelectedVisitPhoto2, SetSelectedVisitPhoto2] = useState('');
  const [SelectedVisitPhoto3, SetSelectedVisitPhoto3] = useState('');
  const [SelectedVisitPhoto4, SetSelectedVisitPhoto4] = useState('');
  const [SelectedVisitPhoto5, SetSelectedVisitPhoto5] = useState('');
  const [DisplayFile1,setDisplayFile1]=useState('');
  const [DisplayFile2,setDisplayFile2]=useState('');
  const [DisplayFile3,setDisplayFile3]=useState('');
  const [DisplayFile4,setDisplayFile4]=useState('');
  const [DisplayFile5,setDisplayFile5]=useState('');
  const [DisplayDocumentFile,setDisplayDocumentFile]=useState('');
  const [VisitType, setVisitType] = useState('');
  useEffect(() => {
    getChannelSourceList();
   
  }, [])

  const getChannelSourceList = async () => {
    var AUrl = global.APIURL + '/ChannelSourceList';
    axios.post(AUrl).then(res => {
      setChannelSourceList(res.data);
    })
  }

  const getChannelSubSourceList = async (VChannelId) => {

    var AUrl = global.APIURL + '/ChannelSubSourceListdrop';
    var ChannelData = {
      ChannelId: VChannelId
    }
    axios.post(AUrl, ChannelData).then(res => {
      setChannelSubSourceList(res.data);
    })
  }

  const getSelectedVisitPhoto1 = async (e) => {
    var details = e.target.files[0];
    var details1 = "UserVisitPhotos";
    if (details != undefined) {
      const formData = new FormData();
      formData.append('Document', details);
      formData.append('FolderName', details1);
      var AUrl = global.APIURL + '/uploadavatar/';
      axios.post(AUrl, formData, {
        headers: { "Content-Type": details.type },
      })
        .then((res) => {
          SetSelectedVisitPhoto1(res.data.IName);
          setDisplayFile1(res.data.FileName);
          if (e.target.files[0].name != null) {
              if (e.target.files[0].name != '') {
                  setDisplayDocumentFile(1);
              }
              else {
                  setDisplayDocumentFile(0);
             }
        }
        });
    }
    else {
      SetSelectedVisitPhoto1('');
    }
  }
  const getSelectedVisitPhoto2 = async (e) => {

    var details = e.target.files[0];
    if (details != undefined) {
      var details1 = "UserVisitPhotos";
      const formData = new FormData();
      formData.append('Document', details);
      formData.append('FolderName', details1);
      var AUrl = global.APIURL + '/uploadavatar/';
      axios.post(AUrl, formData, {
        headers: { "Content-Type": details.type },
      })
        .then((res) => {
          SetSelectedVisitPhoto2(res.data.IName);
          setDisplayFile2(res.data.FileName);
          if (e.target.files[0].name != null) {
              if (e.target.files[0].name != '') {
                  setDisplayDocumentFile(1);
              }
              else {
                  setDisplayDocumentFile(0);
             }
        }
        });
    }
    else {
      SetSelectedVisitPhoto2('');
    }
  }
  const getSelectedVisitPhoto3 = async (e) => {
    var details = e.target.files[0];
    if (details != undefined) {
      var details1 = "UserVisitPhotos";
      const formData = new FormData();
      formData.append('Document', details);
      formData.append('FolderName', details1);
      var AUrl = global.APIURL + '/uploadavatar/';
      axios.post(AUrl, formData, {
        headers: { "Content-Type": details.type },
      })
        .then((res) => {
          SetSelectedVisitPhoto3(res.data.IName);
          setDisplayFile3(res.data.FileName);
          if (e.target.files[0].name != null) {
              if (e.target.files[0].name != '') {
                  setDisplayDocumentFile(1);
              }
              else {
                  setDisplayDocumentFile(0);
             }
        }
        });
    }
    else {
      SetSelectedVisitPhoto3('');
    }
  }
  const getSelectedVisitPhoto4 = async (e) => {

    var details = e.target.files[0];
    if (details != undefined) {
      var details1 = "UserVisitPhotos";
      const formData = new FormData();
      formData.append('Document', details);
      formData.append('FolderName', details1);
      var AUrl = global.APIURL + '/uploadavatar/';
      axios.post(AUrl, formData, {
        headers: { "Content-Type": details.type },
      })
        .then((res) => {
          SetSelectedVisitPhoto4(res.data.IName);
          setDisplayFile4(res.data.FileName);
          if (e.target.files[0].name != null) {
              if (e.target.files[0].name != '') {
                  setDisplayDocumentFile(1);
              }
              else {
                  setDisplayDocumentFile(0);
             }
        }
        });
    }
    else {
      SetSelectedVisitPhoto4('');
    }
  }

  const getSelectedVisitPhoto5 = async (e) => {

    var details = e.target.files[0];
    if (details != undefined) {
      var details1 = "UserVisitPhotos";
      const formData = new FormData();
      formData.append('Document', details);
      formData.append('FolderName', details1);
      var AUrl = global.APIURL + '/uploadavatar/';
      axios.post(AUrl, formData, {
        headers: { "Content-Type": details.type },
      })
        .then((res) => {
          SetSelectedVisitPhoto5(res.data.IName);
          setDisplayFile5(res.data.FileName);
          if (e.target.files[0].name != null) {
              if (e.target.files[0].name != '') {
                  setDisplayDocumentFile(1);
              }
              else {
                  setDisplayDocumentFile(0);
             }
        }
         
        });
    }
    else {
      SetSelectedVisitPhoto5('');
    }
  }

  const saveUserVisit = async (e) => {

    e.preventDefault();

    var VisitDate = document.getElementById("txtVisitDate").value;
    var VisitFromTime = document.getElementById("txtVisitFromTime").value;
    var VisitToTime = document.getElementById("txtVisitToTime").value;
    var VisitPersonName = document.getElementById("txtVisitPersonName").value;
    var VisitPersonEmail = document.getElementById("txtVisitPersonEmail").value;
    var VisitPersonContact = document.getElementById("txtVisitPersonContact").value;
    var VisitPlaceLocation = document.getElementById("txtVisitPlaceLocation").value;
    var VisitAgenda = document.getElementById("txtVisitAgenda").value;
    var VisitRemark = document.getElementById("txtVisitRemark").value;

    if(VisitDate!="" && VisitFromTime!="" && VisitToTime!="" && SelectedVisitPhoto1!="" && VisitPlaceLocation!="" && VisitAgenda!="" && VisitType!="" && VisitRemark!="")
    {
    const UserVisitData = {
      UserId: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
      ChannelSourceId: ChannelId,
      ChannelSubSourceId: ChannelSubSourceId,
      VisitDate: VisitDate,
      VisitFromTime: VisitFromTime,
      VisitToTime: VisitToTime,
      VisitPhotos1: SelectedVisitPhoto1,
      VisitPhotos2: SelectedVisitPhoto2,
      VisitPhotos3: SelectedVisitPhoto3,
      VisitPhotos4: SelectedVisitPhoto4,
      VisitPhotos5: SelectedVisitPhoto5,
      VisitPersonName: VisitPersonName,
      VisitPersonEmail: VisitPersonEmail,
      VisitPersonContact: VisitPersonContact,
      VisitPlaceLocation: VisitPlaceLocation,
      VisitAgenda: VisitAgenda,
      VisitType: VisitType,
      VisitRemark: VisitRemark
    }
  
    axios.post(global.APIURL + '/UserVisitSave', UserVisitData)
      .then(res => {
        alert(res.data[0].RecordStatus);
        history.push("/UserVisitEntry");
      });
  }
}

  return (
    <div className="page-content">

      <MetaTags>
        <title>Add User Visit Entry| KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-12 mb-2">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Add User Visit Entry</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <a href="/UserVisitEntry" className="ms-2 me-2">User Visit Entry</a>
              <span>/</span>
              <span className="ms-2">Add User Visit Entry</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Form onSubmit={saveUserVisit}>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Channel Source</label>
                        <select className="form-select" Id="ddlChannelSource" name="ddlChannelSource" onChange={e => { getChannelSubSourceList(e.target.value); setChannelId(e.target.value) }} >
                          <option value={"0"}>Select Channel Source List</option>
                          {ChannelSourceList.map((ChannelMaster) => (
                            <option key={ChannelMaster.ChannelId} value={ChannelMaster.ChannelId}> {ChannelMaster.ChannelName} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Channel Sub Source</label>
                        <select className="form-select" Id="ddlChannelSubSource" name="ddlChannelSubSource" onChange={(e) => setChannelSubSourceId(e.target.value)}>
                          <option value={"0"}>Select Channel Source List</option>
                          {ChannelSubSourceList.map((ChannelMaster) => (
                            <option key={ChannelMaster.ChannelPartnerId} value={ChannelMaster.ChannelPartnerId}> {ChannelMaster.Name} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={4}></Col>
                    <Col lg={2}>
                      <div className="mb-2">
                        <label className="form-label">Visit Date</label>
                        <Input type="date" className="form-control" id="txtVisitDate" placeholder="" required />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-2">
                        <label className="form-label">Visit From Time</label>
                        <Input type="time" className="form-control" id="txtVisitFromTime" placeholder="" required />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-2">
                        <label className="form-label">Visit To Time</label>
                        <Input type="time" className="form-control" id="txtVisitToTime" placeholder="Enter Confirm Password" required />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-2">
                    
                        <label className="form-label">Visit File 1</label>
                        {DisplayDocumentFile > 0 ?
                        <span className="float-end text-primary font-12" id="spandoctitle">
                        <Link to={DisplayFile1} target="_blank" >{DisplayFile1}</Link></span> : ''}
                        <Input type="file" className="form-control" id="txtVisitPhoto1" placeholder="" onChange={getSelectedVisitPhoto1} required />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-2">
                        <label className="form-label">Visit File 2</label>
                        {DisplayDocumentFile > 0 ?
                        <span className="float-end text-primary font-12" id="spandoctitle">
                        <Link to={DisplayFile2} target="_blank" >{DisplayFile2}</Link></span> : ''}
                        <Input type="file" className="form-control" id="txtVisitPhoto2" placeholder="" onChange={getSelectedVisitPhoto2} />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Visit File 3</label>
                        {DisplayDocumentFile > 0 ?
                        <span className="float-end text-primary font-12" id="spandoctitle">
                        <Link to={DisplayFile3} target="_blank" >{DisplayFile3}</Link></span> : ''}
                        <Input type="file" className="form-control" id="txtVisitPhoto3" placeholder="" onChange={getSelectedVisitPhoto3} />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Visit File 4</label>
                        {DisplayDocumentFile > 0 ?
                        <span className="float-end text-primary font-12" id="spandoctitle">
                        <Link to={DisplayFile4} target="_blank" >{DisplayFile4}</Link></span> : ''}
                        <Input type="file" className="form-control" id="txtVisitPhoto4" placeholder="" onChange={getSelectedVisitPhoto4} />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Visit File 5</label>
                        {DisplayDocumentFile > 0 ?
                        <span className="float-end text-primary font-12" id="spandoctitle">
                        <Link to={DisplayFile5} target="_blank" >{DisplayFile5}</Link></span> : ''}
                        <Input type="file" className="form-control" id="txtVisitPhoto5" placeholder="" onChange={getSelectedVisitPhoto5} />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-2">
                        <label className="form-label">Person Name</label>
                        <Input type="text" className="form-control" id="txtVisitPersonName" placeholder="" />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-2">
                        <label className="form-label">Person Email</label>
                        <Input type="text" className="form-control" id="txtVisitPersonEmail" placeholder=""  />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-2">
                        <label className="form-label">Person Contact</label>
                        <Input type="text" className="form-control" id="txtVisitPersonContact" placeholder=""/>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-2">
                        <label className=""> Visit Mode </label>
                        <select className="form-select" aria-label="Default select example" onChange={(e) => setVisitType(e.target.value)} required>
                          <option selected>Select Mode</option>
                          <option value="Call">Call</option>
                          <option value="Email">Email</option>
                          <option value="Meeting">Meeting</option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-2">
                        <label className="form-label">Address</label>
                        <Input type="text" className="form-control" id="txtVisitPlaceLocation" placeholder="" required />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-2">
                        <label className="form-label">Agenda</label>
                        <Input type="textarea" className="form-control" id="txtVisitAgenda" placeholder="" required />
                      </div>
                    </Col>
                    
                    <Col lg={6}>
                      <div className="mb-2">
                        <label className="form-label">Remarks</label>
                        <Input type="textarea" className="form-control" id="txtVisitRemark" placeholder="" required />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" target="_self">Clear</a>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </div>
  )
}
export default OnSort;