import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import axios from 'axios'

const FormRepeater = () => {
  const [LeadPartnerList, setLeadPartnerList] = useState([]);

  useEffect(() => {
    let LeadId = localStorage.getItem("FirmCompanyLeadId") == null ? "0" : localStorage.getItem("FirmCompanyLeadId") == "" ? "0" : localStorage.getItem("FirmCompanyLeadId");
    // alert(LeadId);
    getLeadPartnerList(LeadId);
  });

  const getLeadPartnerList = async (LeadId) => {
    var AUrl = global.APIURL + '/LeadPartnerDetailsList/' + LeadId;
    axios.get(AUrl)
      .then(LPres => {
        let LeadPartnerList = LPres.data.map((LeadPartnerDetail) => {
          return LeadPartnerDetail
        })
        setLeadPartnerList(LeadPartnerList);
      });
  }

  const [VPartnerName, setPartnerName] = useState('');
  const [VPartnerPAN, setPartnerPAN] = useState('');
  const [VPartnerContactNo, setPartnerContactNo] = useState('');
  const [VPartnerEmailAddress, setPartnerEmailAddress] = useState('');

  const handleAddRow = async (e) => {
    e.preventDefault();
    alert(VPartnerName);
    const item = {
      PartnerName: VPartnerName,
      PartnerPAN: VPartnerPAN,
      PartnerContactNo: VPartnerContactNo,
      PartnerEmailAddress: VPartnerEmailAddress,
    };
    setLeadPartnerList(item);
  };

  const handleRemoveRow = async (e, idx) => {
    if (idx === "01") {
      document.getElementById("addr" + idx).style.display = "";
    } else if (typeof idx != "undefined") {
      document.getElementById("addr" + idx).style.display = "none";
    }
  };



  return (
    <React.Fragment>
      <CardTitle>Partner Details</CardTitle>
      <hr />
      <table style={{ width: "100%" }}>
        <tbody>
          {LeadPartnerList.map((item, idx) => (
            <tr id={"addr" + idx} key={idx}>
              <td>
                <Form className="repeater mt-3" encType="multipart/form-data">
                  <div data-repeater-list="group-a">
                    <Row data-repeater-item>
                      <Col lg="3" className="mb-3">
                        <Label htmlFor="name">Name {idx + 1}</Label>
                        <Input type="text" id="name" className="form-control" defaultValue={item.PartnerName} placeholder="Partner Name" />
                      </Col>
                      <Col lg="2" className="mb-3">
                        <Label htmlFor="name">PAN {idx + 1}</Label>
                        <Input type="text" id="name" className="form-control" defaultValue={item.PartnerPAN} name="untyped-input" placeholder="Partner PAN Number" />
                      </Col>
                      <Col lg="3" className="mb-3">
                        <Label htmlFor="email">Contact Noumber {idx + 1}</Label>
                        <Input type="email" id="name" className="form-control" defaultValue={item.PartnerContactNo} placeholder="Partner Contact Noumber" />
                      </Col>
                      <Col lg="3" className="mb-3">
                        <Label htmlFor="email">Email Address {idx + 1}</Label>
                        <Input type="email" id="name" className="form-control" defaultValue={item.PartnerEmailAddress} placeholder="Partner Email Address" />
                      </Col>
                      <Col lg="1" className="form-group align-self-center">
                        <Button onClick={e => handleRemoveRow(e, idx)} color="danger" className="mt-3 btn-sm" style={{ width: "30%" }}><i className="fas fa-trash-alt"></i></Button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </td>
            </tr>
          ))}
          <tr id="addr01" key="">
            <td>
              <div data-repeater-list="group-a">
                <Row data-repeater-item>
                  <Col lg="3" className="mb-3">
                    <Label htmlFor="name">Name</Label>
                    <Input type="text" id="txtPartnerName" className="form-control" placeholder="Partner Name" onChange={(e) => setPartnerName(e.target.value)} />
                  </Col>
                  <Col lg="2" className="mb-3">
                    <Label htmlFor="name">PAN</Label>
                    <Input type="text" id="txtPartnerPAN" className="form-control" placeholder="Partner PAN" onChange={(e) => setPartnerPAN(e.target.value)} />
                  </Col>
                  <Col lg="3" className="mb-3">
                    <Label htmlFor="name">Contact No.</Label>
                    <Input type="text" id="txtPartnerContactNo" className="form-control" placeholder="Partner Contact No." onChange={(e) => setPartnerContactNo(e.target.value)} />
                  </Col>
                  <Col lg="3" className="mb-3">
                    <Label htmlFor="name">Contact Email Address</Label>
                    <Input type="text" id="txtPartnerEmailAddress" className="form-control" placeholder="Partner Email Address" onChange={(e) => setPartnerEmailAddress(e.target.value)} />
                  </Col>
                  <Col lg="1" className="form-group align-self-center">
                    <Button onClick={e => handleRemoveRow(e, idx)} color="danger" className="mt-3 btn-sm" style={{ width: "30%" }}><i className="fas fa-trash-alt"></i></Button>
                  </Col>
                </Row>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Button onClick={handleAddRow} color="primary" className="mt-3 mt-lg-0 btn-sm"><i className="fas fa-plus me-2"></i>Add</Button>
    </React.Fragment>
  );
}

export default FormRepeater;
