import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';


const EditBranch = () => {
  const [Title, setTitle] = useState('');
  const [CompanyId, setCompanyId] = useState(0);
  const [Address, setAddress] = useState('');
  const [Contact, setContact] = useState('');
  const [Email, setEmail] = useState('');
  const [BranchId, setBranchId] = useState('');
  const [CompanyList, setCompanyList] = useState([]);

  const history = useHistory();

  let { id } = useParams();

  const updateProduct = async (e) => {
    e.preventDefault();
    let Title = e.target["txtTitle"].value;
    let Address = e.target["txtAddress"].value;
    let Contact = e.target["txtContact"].value;
    let Email = e.target["txtEmail"].value;
    let UBranchId = e.target["hdfBranchId"].value;

    if (Title != '') {
      const Department = {
        BranchId: UBranchId == '' ? '0' : UBranchId,
        Title: Title,
        companyId: CompanyId,
        Address: Address,
        Contact: Contact,
        Email: Email,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")

      }

      axios.post(global.APIURL + '/BranchUpdate', Department)
        .then(res => {
          alert(res.data.StatusMessage);
          history.push("/Branch");

        });
    }
  }

  useEffect(() => {



    if (id != null) {
      if (id != "0") {
        let newid = id.split(':');
        getCompanyList();
        getUserTyprById(newid[1]);
      }
      else {
        history.push("/Branch");
      }
    }
    else {
      history.push("/Branch");
    }
  }, []);

  const getUserTyprById = async (UTId) => {
    var AUrl = global.APIURL + '/BranchEdit/' + UTId;
    axios.get(AUrl)
      .then(res => {
        setTitle(res.data[0].Title);
        setCompanyId(res.data[0].companyId);
        setAddress(res.data[0].Address);
        setContact(res.data[0].Contact);
        setEmail(res.data[0].Email);
        setBranchId(res.data[0].BranchId)
      })
  }

  const getCompanyList = async () => {
    var AUrl = global.APIURL + '/CompanyActive';
    axios.get(AUrl)
      .then(Tres => {
        let ActiveTag = Tres.data.map((kctag) => {
          return kctag
        })
        setCompanyList(ActiveTag);
      })
  }
  return (
    <div className="page-content">

      <MetaTags>
        <title>Branch | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Edit Branch</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <a href="/Branch"><span className="ms-2 me-2">Branch</span></a>
              <span>/</span>
              <span className="ms-2">Edit Branch</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-6">
            <a className="btn btn-primary btn-sm float-end font-14" href="/Branch">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <form onSubmit={updateProduct}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={3}>
                      <label className="form-label">Company</label>
                      <select className="form-select" name="ddlTags" onChange={(e) => setCompanyId(e.target.value)}>
                        <option value={"0"}>Select Company</option>
                        {CompanyList.map((item) => (
                          <option key={item.CompanyId} value={item.CompanyId} selected={item.CompanyId == CompanyId}>
                            {item.CompanyName}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-2">
                        <label className="form-label">Title</label>
                        <Input type="text" className="form-control" placeholder="Title" id="txtTitle" defaultValue={Title} />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-2">
                        <label className="form-label">Contact</label>
                        <Input type="text" className="form-control" id="txtContact" placeholder="Contact" defaultValue={Contact} />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-2">
                        <label className="form-label">Email</label>
                        <Input type="text" className="form-control" id="txtEmail" placeholder="Email" defaultValue={Email} />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <label className="form-label">Address</label>
                      <textarea className="form-control" placeholder="Address" id="Remarks" rows="1" name="txtAddress" defaultValue={Address}></textarea>
                    </Col>
                    <Col lg={12} className="mt-3 mb-2">
                      <input type="hidden" id="hdfBranchId" value={BranchId} />
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  )
}

export default EditBranch;