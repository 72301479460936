import React, { Component } from "react"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { isEmpty, size } from "lodash"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { withRouter } from "react-router-dom"

//Import Breadcrumb

//Import Product Images
import { getCartData } from "../../../store/actions"

class EcommerceCart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productList: [],
    }
  }

  componentDidMount() {
    const {
      cartData: { products },
      onGetCartData,
    } = this.props
    onGetCartData()
    this.setState({ productList: products })
  }

  componentDidUpdate(prevProps) {
    const {
      cartData: { products },
    } = this.props
    if (
      !isEmpty(products) &&
      size(products) !== size(prevProps.cartData.products)
    ) {
      this.setState({ productList: products })
    }
  }

  removeCartItem = id => {
    let productList = this.state.productList
    const filtered = productList.filter(function (item) {
      return item.id !== id
    })

    this.setState({ productList: filtered })
  }

  countUP = (id, prev_data_attr) => {
    this.setState({
      productList: this.state.productList.map(p =>
        p.id === id ? { ...p, data_attr: prev_data_attr + 1 } : p
      ),
    })
  }

  countDown = (id, prev_data_attr) => {
    this.setState({
      productList: this.state.productList.map(p =>
        p.id === id ? { ...p, data_attr: prev_data_attr - 1 } : p
      ),
    })
  }

  render() {
    const {
      cartData: { orderSummary },
    } = this.props
    const { productList } = this.state

    return (
      <React.Fragment>
       <div className="page-content">
          <MetaTags>
            <title>Zipcode | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          <Col className="col-12 mb-3">
                <div className="breadcrumb align-items-center justify-content-end">
                <a href="/dashboard" className="me-2">Home</a> <span className="me-2">/</span><a href="/ecommerce-products" className="me-2">City List</a> <span>/</span><span className="ms-2">Zipcode List</span>
                </div>
              </Col>
              <Col className="col-12">
                <CardTitle className="h3 mb-4">Zipcode List</CardTitle>
               
              </Col>
              <Col className="col-lg-6 mb-4">
              <div className="d-flex gap-2 flex-wrap">
                      <Dropdown
                        isOpen={this.state.btnprimary1}
                        toggle={() =>
                          this.setState({
                            btnprimary1: !this.state.btnprimary1,
                          })
                        }
                      >
                        <DropdownToggle
                          tag="button"
                          className="btn btn-primary"
                        >
                          Select Zipcode <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>Maharastra</DropdownItem>
                          <DropdownItem>Goa</DropdownItem>
                          <DropdownItem>Gujarat</DropdownItem>
                          <DropdownItem>Uttar Pradesh</DropdownItem>
                          <DropdownItem>Kerala</DropdownItem>
                          <DropdownItem>Jammu</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>{" "}
                     
                    </div>
                  
              </Col>
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
              <Col className="col-12">
                <div className ="d-flex align-items-center justify-content-between">
                 <div className="d-flex">
                  <CardTitle className="h6 mb-4">State  Name </CardTitle>
                    <div  className="form-check ms-5">
                      <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                      <label  className="form-check-label" htmlFor="flexCheckDefault">
                      </label>
                  </div>
                 </div>
                  <div className ="d-flex">
                    <a className ="btn btn-primary btn-sm" href="/">Save</a>
                    <a className ="btn btn-primary btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
              <Col className=" d-flex justify-content-end mb-4 mt-3">
                <div className="text-end">
                    <div className="border-bottom">
                      Search <i className="mdi mdi-filter h4 ms-3"></i>
                    </div>
                  </div>
              </Col>
            <Row>
              
              <Col className="col-12">
                <Card>
                  <CardBody>
                   {/* zip list */}
                   <div className ="table-responsive mb-5">
                        <table className="table table-striped mb-0 table">
                            <tbody className="">
                                <tr>
                                    <th className="width-20" scope="row">1</th>
                                    <td className="width-100">380060</td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="width-50">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>380061</td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>380064</td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>380034</td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td>380035</td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <td>380052</td>
                                    <td className="text-end"><i className="mdi mdi-pencil"></i></td>
                                    <td className="">
                                    <div  className="form-check">
                                          <input className ="form-check-input float-end" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                  </div>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

EcommerceCart.propTypes = {
  cartData: PropTypes.any,
  onGetCartData: PropTypes.func,
}

const mapStateToProps = state => ({
  cartData: state.ecommerce.cartData,
})

const mapDispatchToProps = dispatch => ({
  onGetCartData: () => dispatch(getCartData()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EcommerceCart))
