import React, { useEffect, useState } from "react";
import { Button, Col, Input, Label, Row, Card, CardBody } from "reactstrap";
import axios from 'axios'
import { Link } from "react-router-dom";


const OnSort = () => {
    const [LeadCIBILList, setLeadCIBILList] = useState([]);
    const [LeadEqifaxList, setLeadEqifaxList] = useState([]);
    const [LeadExperianList, setLeadExperianList] = useState([]);
    const [LeadCRIFList, setLeadCRIFList] = useState([]);

    const [CheckLeadCIBILValid, setCheckLeadCIBILValid] = useState(0);
    const [CheckLeadEqifaxValid, setCheckLeadEqifaxValid] = useState(0);
    const [CheckLeadExperianValid, setCheckLeadExperianValid] = useState(0);
    const [CheckLeadCRIFValid, setCheckLeadCRIFValid] = useState(0);

    const [AccountTypeList, setAccountTypeList] = useState([]);
    const [ChannelMasterList, setChannelMasterList] = useState([]);
    const [SuiteStatusList, setSuiteStatusList] = useState([]);
    const [CreditFacilityStatusList, setCreditFacilityStatusList] = useState([]);

    const [CIBILDocumentFilePath, setCIBILDocumentFilePath] = useState("");
    const [CIBILDocumentFileName, setCIBILDocumentFileName] = useState("");
    const [ISUploadModelCIBILDocument, setISUploadModelCIBILDocument] = useState(0);
    const [ISUploadCIBILDocument, setISUploadCIBILDocument] = useState(0);

    const [EqifaxDocumentFilePath, setEqifaxDocumentFilePath] = useState("");
    const [EqifaxDocumentFileName, setEqifaxDocumentFileName] = useState("");
    const [ISUploadModelEqifaxDocument, setISUploadModelEqifaxDocument] = useState(0);
    const [ISUploadEqifaxDocument, setISUploadEqifaxDocument] = useState(0);

    const [ExperianDocumentFilePath, setExperianDocumentFilePath] = useState("");
    const [ExperianDocumentFileName, setExperianDocumentFileName] = useState("");
    const [ISUploadModelExperianDocument, setISUploadModelExperianDocument] = useState(0);
    const [ISUploadExperianDocument, setISUploadExperianDocument] = useState(0);

    const [CRIFDocumentFilePath, setCRIFDocumentFilePath] = useState("");
    const [CRIFDocumentFileName, setCRIFDocumentFileName] = useState("");
    const [ISUploadModelCRIFDocument, setISUploadModelCRIFDocument] = useState(0);
    const [ISUploadCRIFDocument, setISUploadCRIFDocument] = useState(0);

    useEffect(() => {
        let LeadId = localStorage.getItem("ConvertIndividualLeadId");
        if (LeadId != null) {
            if (LeadId != "0") {
                getLeadClientCreditReportFile();
                getAccountTypeList();
                getChannelMasterList();
                getSuiteStatusList();
                getCreditFacilityStatusList();
                getLeadCIBILCreditDetail(LeadId);
                getLeadEqifaxCreditDetail(LeadId);
                getLeadExperianCreditDetail(LeadId);
                getLeadCRIFCreditDetail(LeadId);
            }
        }
    }, []);

    const getAccountTypeList = async () => {
        var AUrl = global.APIURL + '/AccountTypeActiveList';
        axios.get(AUrl)
            .then(LCCdres => {
                let AccountTypeList = LCCdres.data.map((AccountTypeDetail) => {
                    return AccountTypeDetail
                })
                setAccountTypeList(AccountTypeList);
            });
    }

    const getChannelMasterList = async () => {
        var AUrl = global.APIURL + '/ChannelMasterActiveAllList';
        fetch(AUrl)
            .then((res) => res.json())
            .then((json) => {
                setChannelMasterList(json)
            })
    }

    const getSuiteStatusList = async () => {
        var AUrl = global.APIURL + '/SuiteStatusActiveList';
        fetch(AUrl)
            .then((res) => res.json())
            .then((json) => {
                setSuiteStatusList(json)
            })
    }

    const getCreditFacilityStatusList = async () => {
        var AUrl = global.APIURL + '/CreditFacilityStatusActiveList';
        fetch(AUrl)
            .then((res) => res.json())
            .then((json) => {
                setCreditFacilityStatusList(json)
            })
    }

    const getLeadCIBILCreditDetail = async (LeadId) => {
        var AUrl = global.APIURL + '/LeadEnrollCIBILCreditDetail/' + LeadId;
        axios.get(AUrl)
            .then(LCCdres => {
                let CIBILCreditList = LCCdres.data.map((CIBILCreditDetail) => {
                    return CIBILCreditDetail
                })
                setLeadCIBILList(CIBILCreditList);
            });
    }

    const getLeadEqifaxCreditDetail = async (LeadId) => {
        var AUrl = global.APIURL + '/LeadEnrollEqifaxCreditDetail/' + LeadId;
        axios.get(AUrl)
            .then(LCCdres => {
                let EqifaxCreditList = LCCdres.data.map((EqifaxCreditDetail) => {
                    return EqifaxCreditDetail
                })
                setLeadEqifaxList(EqifaxCreditList);
            });
    }

    const getLeadExperianCreditDetail = async (LeadId) => {
        var AUrl = global.APIURL + '/LeadEnrollExperianCreditDetail/' + LeadId;
        axios.get(AUrl)
            .then(LCCdres => {
                let ExperianCreditList = LCCdres.data.map((ExperianCreditDetail) => {
                    return ExperianCreditDetail
                })
                setLeadExperianList(ExperianCreditList);
            });
    }

    const getLeadCRIFCreditDetail = async (LeadId) => {
        var AUrl = global.APIURL + '/LeadEnrollCRIFCreditDetail/' + LeadId;
        axios.get(AUrl)
            .then(LCCdres => {
                let CRIFCreditList = LCCdres.data.map((CRIFCreditDetail) => {
                    return CRIFCreditDetail
                })
                setLeadCRIFList(CRIFCreditList);
            });
    }

    const getLeadClientCreditReportFile = async () => {
        let VLeadId = localStorage.getItem("ConvertIndividualLeadId");
        const LPitem = { LeadId: VLeadId };
        var AUrl = global.APIURL + '/LeadCreditDetailReportFileSelectAll';
        axios.post(AUrl, LPitem)
            .then(res => {
                if (res.data.length > 0) {
                    for (let index = 0; index < res.data.length; index++) {
                        if (res.data[0].CreditDetailReportType == 'CIBIL') {
                            setCIBILDocumentFilePath(res.data[0].ReportFilePath);
                            setCIBILDocumentFileName(res.data[0].ReportFileName);
                            setISUploadCIBILDocument(res.data[0].ReportFilePath == null ? 0 : res.data[0].ReportFilePath == "" ? 0 : 1);
                        }
                        else if (res.data[0].CreditDetailReportType == 'Eqifax') {
                            setEqifaxDocumentFilePath(res.data[0].ReportFilePath);
                            setEqifaxDocumentFileName(res.data[0].ReportFileName);
                            setISUploadEqifaxDocument(res.data[0].ReportFilePath == null ? 0 : res.data[0].ReportFilePath == "" ? 0 : 1);
                        }
                        else if (res.data[0].CreditDetailReportType == 'Experian') {
                            setExperianDocumentFilePath(res.data[0].ReportFilePath);
                            setExperianDocumentFileName(res.data[0].ReportFileName);
                            setISUploadExperianDocument(res.data[0].ReportFilePath == null ? 0 : res.data[0].ReportFilePath == "" ? 0 : 1);
                        }
                        else if (res.data[0].CreditDetailReportType == 'CRIF') {
                            setCRIFDocumentFilePath(res.data[0].ReportFilePath);
                            setCRIFDocumentFileName(res.data[0].ReportFileName);
                            setISUploadCRIFDocument(res.data[0].ReportFilePath == null ? 0 : res.data[0].ReportFilePath == "" ? 0 : 1);
                        }
                    }
                }
            });
    }

    const LeadCIBILAdd = () => {
        var LRNo = LeadCIBILList.length == 0 ? 1 : (LeadCIBILList[LeadCIBILList.length - 1].RowId + 1);
        const Pitem = {
            RowId: LRNo,
            LeadEnrollCIBILId: 0,
            DateofReportPulledOut: document.getElementById("txtCIBILDateofReportPulledOut").value,
            CibilScore: document.getElementById("txtCIBILScore").value,
            NameofBorrower: document.getElementById("txtCIBILNameofBorrower").value,
            PANNo: document.getElementById("txtCIBILPANNo").value,
            AdditionalKYCCIR: document.getElementById("txtCIBILAdditionalKYCCIR").value,
            MemberId: document.getElementById("ddlCIBILMember").value,
            AccountTypeId: document.getElementById("ddlCIBILAccountType").value,
            AccountNumber: document.getElementById("txtCIBILAccountNumber").value,
            OwnershipIndicator: document.getElementById("txtCIBILOwnershipIndicator").value,
            CreditLimit: document.getElementById("txtCIBILCreditLimit").value,
            HighCredit: document.getElementById("txtCIBILHighCredit").value,
            CurrentBalance: document.getElementById("txtCIBILCurrentBalance").value,
            AmountOverdue: document.getElementById("txtCIBILAmountOverdue").value,
            DPDsforLatestMonth: document.getElementById("txtCIBILDPDsforLatestMonth").value,
            DateOpened: document.getElementById("txtCIBILDateOpened").value,
            DateClosed: document.getElementById("txtCIBILDateClosed").value,
            DateofLastPayment: document.getElementById("txtCIBILDateofLastPayment").value,
            DateReported: document.getElementById("txtCIBILDateReported").value,
            SuiteFiled: document.getElementById("ddlCIBILSuiteFiled").value,
            CreditFacilityStatus: document.getElementById("ddlCIBILCreditFacilityStatus").value,
            WrittenofAmount: document.getElementById("txtCIBILWrittenofAmount").value,
            SettlementAmount: document.getElementById("txtCIBILSettlementAmount").value,
            TotalNonReportingMonth: document.getElementById("txtCIBILTotalNonReportingMonth").value,

            Enquiry: document.getElementById("txtCIBILEnquiry").value,
            DateofReportPulledOuttxtId: LeadCIBILList.length == 0 ? 'txtCIBILDateofReportPulledOut' + LRNo : '',
            CibilScoretxtId: LeadCIBILList.length == 0 ? 'txtCIBILScore' + LRNo : '',
            NameofBorrowertxtId: LeadCIBILList.length == 0 ? 'txtCIBILNameofBorrower' + LRNo : '',
            PANNotxtId: LeadCIBILList.length == 0 ? 'txtCIBILPANNo' + LRNo : '',
            AdditionalKYCCIRtxtId: LeadCIBILList.length == 0 ? 'txtCIBILAdditionalKYCCIR' + LRNo : '',

            MemberIdtxtId: 'ddlCIBILMember' + LRNo,
            AccountTypetxtId: 'ddlCIBILAccountType' + LRNo,
            AccountNumbertxtId: 'txtCIBILAccountNumber' + LRNo,
            OwnershipIndicatortxtId: 'txtCIBILOwnershipIndicator' + LRNo,
            CreditLimittxtId: 'txtCIBILCreditLimit' + LRNo,
            HighCredittxtId: 'txtCIBILHighCredit' + LRNo,
            CurrentBalancetxtId: 'txtCIBILCurrentBalance' + LRNo,
            AmountOverduetxtId: 'txtCIBILAmountOverdue' + LRNo,
            DPDsforLatestMonthtxtId: 'txtCIBILDPDsforLatestMonth' + LRNo,
            DateOpenedtxtId: 'txtCIBILDateOpened' + LRNo,
            DateClosedtxtId: 'txtCIBILDateClosed' + LRNo,
            DateofLastPaymenttxtId: 'txtCIBILDateofLastPayment' + LRNo,
            DateReportedtxtId: 'txtCIBILDateReported' + LRNo,
            SuiteFiledtxtId: 'ddlCIBILSuiteFiled' + LRNo,
            CreditFacilityStatustxtId: 'ddlCIBILCreditFacilityStatus' + LRNo,
            WrittenofAmounttxtId: 'txtCIBILWrittenofAmount' + LRNo,
            SettlementAmounttxtId: 'txtCIBILSettlementAmount' + LRNo,
            TotalNonReportingMonthtxtId: 'txtCIBILTotalNonReportingMonth' + LRNo,
            EnquirytxtId: LeadCIBILList.length == 0 ? 'txtCIBILEnquiry' + LRNo : '',
        };

        if (LeadCIBILList.length == 0) {
            document.getElementById("txtCIBILDateofReportPulledOut").value = '';
            document.getElementById("txtCIBILScore").value = '';
            document.getElementById("txtCIBILNameofBorrower").value = '';
            document.getElementById("txtCIBILPANNo").value = '';
            document.getElementById("txtCIBILAdditionalKYCCIR").value = '';
            document.getElementById("txtCIBILEnquiry").value = '';
        }
        document.getElementById("ddlCIBILMember").value = '0';
        document.getElementById("ddlCIBILAccountType").value = '0';
        document.getElementById("txtCIBILAccountNumber").value = '';
        document.getElementById("txtCIBILOwnershipIndicator").value = '';
        document.getElementById("txtCIBILCreditLimit").value = '';
        document.getElementById("txtCIBILHighCredit").value = '';
        document.getElementById("txtCIBILCurrentBalance").value = '';
        document.getElementById("txtCIBILAmountOverdue").value = '';
        document.getElementById("txtCIBILDPDsforLatestMonth").value = '';
        document.getElementById("txtCIBILDateOpened").value = '';
        document.getElementById("txtCIBILDateClosed").value = '';
        document.getElementById("txtCIBILDateofLastPayment").value = '';
        document.getElementById("txtCIBILDateReported").value = '';
        document.getElementById("ddlCIBILSuiteFiled").value = '0';
        document.getElementById("ddlCIBILCreditFacilityStatus").value = '';
        document.getElementById("txtCIBILWrittenofAmount").value = '';
        document.getElementById("txtCIBILSettlementAmount").value = '';
        document.getElementById("txtCIBILTotalNonReportingMonth").value = '';

        setLeadCIBILList([...LeadCIBILList, Pitem]);
    };

    const LeadEqifaxAdd = () => {
        var LRNo = LeadEqifaxList.length == 0 ? 1 : (LeadEqifaxList[LeadEqifaxList.length - 1].RowId + 1);
        const Pitem = {
            RowId: LRNo,
            LeadEnrollEqifaxId: 0,
            DateofReportPulledOut: document.getElementById("txtEqifaxDateofReportPulledOut").value,
            EqifaxScore: document.getElementById("txtEqifaxScore").value,
            NameofBorrower: document.getElementById("txtEqifaxNameofBorrower").value,
            PANNo: document.getElementById("txtEqifaxPANNo").value,
            AdditionalKYCCIR: document.getElementById("txtEqifaxAdditionalKYCCIR").value,
            MemberId: document.getElementById("ddlEqifaxMember").value,
            AccountTypeId: document.getElementById("ddlEqifaxAccountType").value,
            AccountNumber: document.getElementById("txtEqifaxAccountNumber").value,
            OwnershipIndicator: document.getElementById("txtEqifaxOwnershipIndicator").value,
            CreditLimit: document.getElementById("txtEqifaxCreditLimit").value,
            HighCredit: document.getElementById("txtEqifaxHighCredit").value,
            CurrentBalance: document.getElementById("txtEqifaxCurrentBalance").value,
            AmountOverdue: document.getElementById("txtEqifaxAmountOverdue").value,
            DPDsforLatestMonth: document.getElementById("txtEqifaxDPDsforLatestMonth").value,
            DateOpened: document.getElementById("txtEqifaxDateOpened").value,
            DateClosed: document.getElementById("txtEqifaxDateClosed").value,
            DateofLastPayment: document.getElementById("txtEqifaxDateofLastPayment").value,
            DateReported: document.getElementById("txtEqifaxDateReported").value,
            SuiteFiled: document.getElementById("ddlEqifaxSuiteFiled").value,
            CreditFacilityStatus: document.getElementById("ddlEqifaxCreditFacilityStatus").value,
            WrittenofAmount: document.getElementById("txtEqifaxWrittenofAmount").value,
            SettlementAmount: document.getElementById("txtEqifaxSettlementAmount").value,
            TotalNonReportingMonth: document.getElementById("txtEqifaxTotalNonReportingMonth").value,

            Enquiry: document.getElementById("txtEqifaxEnquiry").value,
            DateofReportPulledOuttxtId: LeadEqifaxList.length == 0 ? 'txtEqifaxDateofReportPulledOut' + LRNo : '',
            EqifaxScoretxtId: LeadEqifaxList.length == 0 ? 'txtEqifaxScore' + LRNo : '',
            NameofBorrowertxtId: LeadEqifaxList.length == 0 ? 'txtEqifaxNameofBorrower' + LRNo : '',
            PANNotxtId: LeadEqifaxList.length == 0 ? 'txtEqifaxPANNo' + LRNo : '',
            AdditionalKYCCIRtxtId: LeadEqifaxList.length == 0 ? 'txtEqifaxAdditionalKYCCIR' + LRNo : '',
            MemberIdtxtId: 'ddlEqifaxMember' + LRNo,
            AccountTypetxtId: 'ddlEqifaxAccountType' + LRNo,
            AccountNumbertxtId: 'txtEqifaxAccountNumber' + LRNo,
            OwnershipIndicatortxtId: 'txtEqifaxOwnershipIndicator' + LRNo,
            CreditLimittxtId: 'txtEqifaxCreditLimit' + LRNo,
            HighCredittxtId: 'txtEqifaxHighCredit' + LRNo,
            CurrentBalancetxtId: 'txtEqifaxCurrentBalance' + LRNo,
            AmountOverduetxtId: 'txtEqifaxAmountOverdue' + LRNo,
            DPDsforLatestMonthtxtId: 'txtEqifaxDPDsforLatestMonth' + LRNo,
            DateOpenedtxtId: 'txtEqifaxDateOpened' + LRNo,
            DateClosedtxtId: 'txtEqifaxDateClosed' + LRNo,
            DateofLastPaymenttxtId: 'txtEqifaxDateofLastPayment' + LRNo,
            DateReportedtxtId: 'txtEqifaxDateReported' + LRNo,
            SuiteFiledtxtId: 'ddlEqifaxSuiteFiled' + LRNo,
            CreditFacilityStatustxtId: 'ddlEqifaxCreditFacilityStatus' + LRNo,
            WrittenofAmounttxtId: 'txtEqifaxWrittenofAmount' + LRNo,
            SettlementAmounttxtId: 'txtEqifaxSettlementAmount' + LRNo,
            TotalNonReportingMonthtxtId: 'txtEqifaxTotalNonReportingMonth' + LRNo,
            EnquirytxtId: LeadEqifaxList.length == 0 ? 'txtEqifaxEnquiry' + LRNo : '',
        };

        if (LeadEqifaxList.length == 0) {
            document.getElementById("txtEqifaxDateofReportPulledOut").value = '';
            document.getElementById("txtEqifaxScore").value = '';
            document.getElementById("txtEqifaxNameofBorrower").value = '';
            document.getElementById("txtEqifaxPANNo").value = '';
            document.getElementById("txtEqifaxAdditionalKYCCIR").value = '';
            document.getElementById("txtEqifaxEnquiry").value = '';
        }
        document.getElementById("ddlEqifaxMember").value = '0';
        document.getElementById("ddlEqifaxAccountType").value = '0';
        document.getElementById("txtEqifaxAccountNumber").value = '';
        document.getElementById("txtEqifaxOwnershipIndicator").value = '';
        document.getElementById("txtEqifaxCreditLimit").value = '';
        document.getElementById("txtEqifaxHighCredit").value = '';
        document.getElementById("txtEqifaxCurrentBalance").value = '';
        document.getElementById("txtEqifaxAmountOverdue").value = '';
        document.getElementById("txtEqifaxDPDsforLatestMonth").value = '';
        document.getElementById("txtEqifaxDateOpened").value = '';
        document.getElementById("txtEqifaxDateClosed").value = '';
        document.getElementById("txtEqifaxDateofLastPayment").value = '';
        document.getElementById("txtEqifaxDateReported").value = '';
        document.getElementById("ddlEqifaxSuiteFiled").value = '0';
        document.getElementById("ddlEqifaxCreditFacilityStatus").value = '';
        document.getElementById("txtEqifaxWrittenofAmount").value = '';
        document.getElementById("txtEqifaxSettlementAmount").value = '';
        document.getElementById("txtEqifaxTotalNonReportingMonth").value = '';

        setLeadEqifaxList([...LeadEqifaxList, Pitem]);
    };

    const LeadExperianAdd = () => {
        var LRNo = LeadExperianList.length == 0 ? 1 : (LeadExperianList[LeadExperianList.length - 1].RowId + 1);
        const Pitem = {
            RowId: LRNo,
            LeadEnrollExperianId: 0,
            DateofReportPulledOut: document.getElementById("txtExperianDateofReportPulledOut").value,
            ExperianScore: document.getElementById("txtExperianScore").value,
            NameofBorrower: document.getElementById("txtExperianNameofBorrower").value,
            PANNo: document.getElementById("txtExperianPANNo").value,
            AdditionalKYCCIR: document.getElementById("txtExperianAdditionalKYCCIR").value,
            MemberId: document.getElementById("ddlExperianMember").value,
            AccountTypeId: document.getElementById("ddlExperianAccountType").value,
            AccountNumber: document.getElementById("txtExperianAccountNumber").value,
            OwnershipIndicator: document.getElementById("txtExperianOwnershipIndicator").value,
            CreditLimit: document.getElementById("txtExperianCreditLimit").value,
            HighCredit: document.getElementById("txtExperianHighCredit").value,
            CurrentBalance: document.getElementById("txtExperianCurrentBalance").value,
            AmountOverdue: document.getElementById("txtExperianAmountOverdue").value,
            DPDsforLatestMonth: document.getElementById("txtExperianDPDsforLatestMonth").value,
            DateOpened: document.getElementById("txtExperianDateOpened").value,
            DateClosed: document.getElementById("txtExperianDateClosed").value,
            DateofLastPayment: document.getElementById("txtExperianDateofLastPayment").value,
            DateReported: document.getElementById("txtExperianDateReported").value,
            SuiteFiled: document.getElementById("ddlExperianSuiteFiled").value,
            CreditFacilityStatus: document.getElementById("ddlExperianCreditFacilityStatus").value,
            WrittenofAmount: document.getElementById("txtExperianWrittenofAmount").value,
            SettlementAmount: document.getElementById("txtExperianSettlementAmount").value,
            TotalNonReportingMonth: document.getElementById("txtExperianTotalNonReportingMonth").value,

            Enquiry: document.getElementById("txtExperianEnquiry").value,
            DateofReportPulledOuttxtId: LeadExperianList.length == 0 ? 'txtExperianDateofReportPulledOut' + LRNo : '',
            ExperianScoretxtId: LeadExperianList.length == 0 ? 'txtExperianScore' + LRNo : '',
            NameofBorrowertxtId: LeadExperianList.length == 0 ? 'txtExperianNameofBorrower' + LRNo : '',
            PANNotxtId: LeadExperianList.length == 0 ? 'txtExperianPANNo' + LRNo : '',
            AdditionalKYCCIRtxtId: LeadExperianList.length == 0 ? 'txtExperianAdditionalKYCCIR' + LRNo : '',

            MemberIdtxtId: 'ddlExperianMember' + LRNo,
            AccountTypetxtId: 'ddlExperianAccountType' + LRNo,
            AccountNumbertxtId: 'txtExperianAccountNumber' + LRNo,
            OwnershipIndicatortxtId: 'txtExperianOwnershipIndicator' + LRNo,
            CreditLimittxtId: 'txtExperianCreditLimit' + LRNo,
            HighCredittxtId: 'txtExperianHighCredit' + LRNo,
            CurrentBalancetxtId: 'txtExperianCurrentBalance' + LRNo,
            AmountOverduetxtId: 'txtExperianAmountOverdue' + LRNo,
            DPDsforLatestMonthtxtId: 'txtExperianDPDsforLatestMonth' + LRNo,
            DateOpenedtxtId: 'txtExperianDateOpened' + LRNo,
            DateClosedtxtId: 'txtExperianDateClosed' + LRNo,
            DateofLastPaymenttxtId: 'txtExperianDateofLastPayment' + LRNo,
            DateReportedtxtId: 'txtExperianDateReported' + LRNo,
            SuiteFiledtxtId: 'ddlExperianSuiteFiled' + LRNo,
            CreditFacilityStatustxtId: 'ddlExperianCreditFacilityStatus' + LRNo,
            WrittenofAmounttxtId: 'txtExperianWrittenofAmount' + LRNo,
            SettlementAmounttxtId: 'txtExperianSettlementAmount' + LRNo,
            TotalNonReportingMonthtxtId: 'txtExperianTotalNonReportingMonth' + LRNo,
            EnquirytxtId: LeadExperianList.length == 0 ? 'txtExperianEnquiry' + LRNo : '',
        };
        setLeadExperianList([...LeadExperianList, Pitem]);
        if (LeadExperianList.length == 0) {
            document.getElementById("txtExperianDateofReportPulledOut").value = '';
            document.getElementById("txtExperianScore").value = '';
            document.getElementById("txtExperianNameofBorrower").value = '';
            document.getElementById("txtExperianPANNo").value = '';
            document.getElementById("txtExperianAdditionalKYCCIR").value = '';
            document.getElementById("txtExperianEnquiry").value = '';
        }
        document.getElementById("ddlExperianMember").value = '0';
        document.getElementById("ddlExperianAccountType").value = '0';
        document.getElementById("txtExperianAccountNumber").value = '';
        document.getElementById("txtExperianOwnershipIndicator").value = '';
        document.getElementById("txtExperianCreditLimit").value = '';
        document.getElementById("txtExperianHighCredit").value = '';
        document.getElementById("txtExperianCurrentBalance").value = '';
        document.getElementById("txtExperianAmountOverdue").value = '';
        document.getElementById("txtExperianDPDsforLatestMonth").value = '';
        document.getElementById("txtExperianDateOpened").value = '';
        document.getElementById("txtExperianDateClosed").value = '';
        document.getElementById("txtExperianDateofLastPayment").value = '';
        document.getElementById("txtExperianDateReported").value = '';
        document.getElementById("ddlExperianSuiteFiled").value = '0';
        document.getElementById("ddlExperianCreditFacilityStatus").value = '';
        document.getElementById("txtExperianWrittenofAmount").value = '';
        document.getElementById("txtExperianSettlementAmount").value = '';
        document.getElementById("txtExperianTotalNonReportingMonth").value = '';
    };

    const LeadCRIFAdd = () => {
        var LRNo = LeadCRIFList.length == 0 ? 1 : (LeadCRIFList[LeadCRIFList.length - 1].RowId + 1);
        const Pitem = {
            RowId: LRNo,
            LeadEnrollCRIFId: 0,
            DateofReportPulledOut: document.getElementById("txtCRIFDateofReportPulledOut").value,
            CRIFScore: document.getElementById("txtCRIFScore").value,
            NameofBorrower: document.getElementById("txtCRIFNameofBorrower").value,
            PANNo: document.getElementById("txtCRIFPANNo").value,
            AdditionalKYCCIR: document.getElementById("txtCRIFAdditionalKYCCIR").value,
            MemberId: document.getElementById("ddlCRIFMember").value,
            AccountTypeId: document.getElementById("ddlCRIFAccountType").value,
            AccountNumber: document.getElementById("txtCRIFAccountNumber").value,
            OwnershipIndicator: document.getElementById("txtCRIFOwnershipIndicator").value,
            CreditLimit: document.getElementById("txtCRIFCreditLimit").value,
            HighCredit: document.getElementById("txtCRIFHighCredit").value,
            CurrentBalance: document.getElementById("txtCRIFCurrentBalance").value,
            AmountOverdue: document.getElementById("txtCRIFAmountOverdue").value,
            DPDsforLatestMonth: document.getElementById("txtCRIFDPDsforLatestMonth").value,
            DateOpened: document.getElementById("txtCRIFDateOpened").value,
            DateClosed: document.getElementById("txtCRIFDateClosed").value,
            DateofLastPayment: document.getElementById("txtCRIFDateofLastPayment").value,
            DateReported: document.getElementById("txtCRIFDateReported").value,
            SuiteFiled: document.getElementById("ddlCRIFSuiteFiled").value,
            CreditFacilityStatus: document.getElementById("ddlCRIFCreditFacilityStatus").value,
            WrittenofAmount: document.getElementById("txtCRIFWrittenofAmount").value,
            SettlementAmount: document.getElementById("txtCRIFSettlementAmount").value,
            TotalNonReportingMonth: document.getElementById("txtCRIFTotalNonReportingMonth").value,

            Enquiry: document.getElementById("txtCRIFEnquiry").value,
            DateofReportPulledOuttxtId: LeadCRIFList.length == 0 ? 'txtCRIFDateofReportPulledOut' + LRNo : '',
            CRIFScoretxtId: LeadCRIFList.length == 0 ? 'txtCRIFScore' + LRNo : '',
            NameofBorrowertxtId: LeadCRIFList.length == 0 ? 'txtCRIFNameofBorrower' + LRNo : '',
            PANNotxtId: LeadCRIFList.length == 0 ? 'txtCRIFPANNo' + LRNo : '',
            AdditionalKYCCIRtxtId: LeadCRIFList.length == 0 ? 'txtCRIFAdditionalKYCCIR' + LRNo : '',

            MemberIdtxtId: 'ddlCRIFMember' + LRNo,
            AccountTypetxtId: 'ddlCRIFAccountType' + LRNo,
            AccountNumbertxtId: 'txtCRIFAccountNumber' + LRNo,
            OwnershipIndicatortxtId: 'txtCRIFOwnershipIndicator' + LRNo,
            CreditLimittxtId: 'txtCRIFCreditLimit' + LRNo,
            HighCredittxtId: 'txtCRIFHighCredit' + LRNo,
            CurrentBalancetxtId: 'txtCRIFCurrentBalance' + LRNo,
            AmountOverduetxtId: 'txtCRIFAmountOverdue' + LRNo,
            DPDsforLatestMonthtxtId: 'txtCRIFDPDsforLatestMonth' + LRNo,
            DateOpenedtxtId: 'txtCRIFDateOpened' + LRNo,
            DateClosedtxtId: 'txtCRIFDateClosed' + LRNo,
            DateofLastPaymenttxtId: 'txtCRIFDateofLastPayment' + LRNo,
            DateReportedtxtId: 'txtCRIFDateReported' + LRNo,
            SuiteFiledtxtId: 'ddlCRIFSuiteFiled' + LRNo,
            CreditFacilityStatustxtId: 'ddlCRIFCreditFacilityStatus' + LRNo,
            WrittenofAmounttxtId: 'txtCRIFWrittenofAmount' + LRNo,
            SettlementAmounttxtId: 'txtCRIFSettlementAmount' + LRNo,
            TotalNonReportingMonthtxtId: 'txtCRIFTotalNonReportingMonth' + LRNo,
            EnquirytxtId: LeadCRIFList.length == 0 ? 'txtCRIFEnquiry' + LRNo : '',
        };

        if (LeadCRIFList.length == 0) {
            document.getElementById("txtCRIFDateofReportPulledOut").value = '';
            document.getElementById("txtCRIFScore").value = '';
            document.getElementById("txtCRIFNameofBorrower").value = '';
            document.getElementById("txtCRIFPANNo").value = '';
            document.getElementById("txtCRIFAdditionalKYCCIR").value = '';
            document.getElementById("txtCRIFEnquiry").value = '';
        }
        document.getElementById("ddlCRIFMember").value = '0';
        document.getElementById("ddlCRIFAccountType").value = '0';
        document.getElementById("txtCRIFAccountNumber").value = '';
        document.getElementById("txtCRIFOwnershipIndicator").value = '';
        document.getElementById("txtCRIFCreditLimit").value = '';
        document.getElementById("txtCRIFHighCredit").value = '';
        document.getElementById("txtCRIFCurrentBalance").value = '';
        document.getElementById("txtCRIFAmountOverdue").value = '';
        document.getElementById("txtCRIFDPDsforLatestMonth").value = '';
        document.getElementById("txtCRIFDateOpened").value = '';
        document.getElementById("txtCRIFDateClosed").value = '';
        document.getElementById("txtCRIFDateofLastPayment").value = '';
        document.getElementById("txtCRIFDateReported").value = '';
        document.getElementById("ddlCRIFSuiteFiled").value = '0';
        document.getElementById("ddlCRIFCreditFacilityStatus").value = '0';
        document.getElementById("txtCRIFWrittenofAmount").value = '';
        document.getElementById("txtCRIFSettlementAmount").value = '';
        document.getElementById("txtCRIFTotalNonReportingMonth").value = '';


        setLeadCRIFList([...LeadCRIFList, Pitem]);

    };

    const LeadCreditDetailSave = () => {
        SaveLeadCIBIL();
        SaveLeadEqifax();
        SaveLeadExperian();
        SaveLeadCRIF();
        alert("Lead credit detail save successfully");
    };

    const CheckLeadCIBIL = async () => {
        if (LeadCIBILList.length == 0) {
            var VDateofReportPulledOut = document.getElementById("txtCIBILDateofReportPulledOut").value;
            var VCibilScore = document.getElementById("txtCIBILScore").value;
            var VNameofBorrower = document.getElementById("txtCIBILNameofBorrower").value;
            var VPANNo = document.getElementById("txtCIBILPANNo").value;
            var VAdditionalKYCCIR = document.getElementById("txtCIBILAdditionalKYCCIR").value;
            var VEnquiry = document.getElementById("txtCIBILEnquiry").value;
        }
        var VMemberId = document.getElementById("ddlCIBILMember").value;
        var VAccountTypeId = document.getElementById("ddlCIBILAccountType").value;
        var VAccountNumber = document.getElementById("txtCIBILAccountNumber").value;
        var VOwnershipIndicator = document.getElementById("txtCIBILOwnershipIndicator").value;
        var VCreditLimit = document.getElementById("txtCIBILCreditLimit").value;
        var VHighCredit = document.getElementById("txtCIBILHighCredit").value;
        var VCurrentBalance = document.getElementById("txtCIBILCurrentBalance").value;
        var VAmountOverdue = document.getElementById("txtCIBILAmountOverdue").value;
        var VDPDsforLatestMonth = document.getElementById("txtCIBILDPDsforLatestMonth").value;
        var VDateOpened = document.getElementById("txtCIBILDateOpened").value;
        var VDateClosed = document.getElementById("txtCIBILDateClosed").value;
        var VDateofLastPayment = document.getElementById("txtCIBILDateofLastPayment").value;
        var VDateReported = document.getElementById("txtCIBILDateReported").value;
        var VSuiteFiled = document.getElementById("ddlCIBILSuiteFiled").value;
        var VCreditFacilityStatus = document.getElementById("ddlCIBILCreditFacilityStatus").value;
        var VWrittenofAmount = document.getElementById("txtCIBILWrittenofAmount").value;
        var VSettlementAmount = document.getElementById("txtCIBILSettlementAmount").value;

        if (LeadCIBILList.length == 0) {
            if (VDateofReportPulledOut != null) {
                if (VDateofReportPulledOut != '') {
                    setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
                }
            }
            if (VCibilScore != null) {
                if (VCibilScore != '') {
                    setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
                }
            }
            if (VNameofBorrower != null) {
                if (VNameofBorrower != '') {
                    setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
                }
            }
            if (VPANNo != null) {
                if (VPANNo != '') {
                    setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
                }
            }
            if (VAdditionalKYCCIR != null) {
                if (VAdditionalKYCCIR != '') {
                    setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
                }
            }
            if (VEnquiry != null) {
                if (VEnquiry != '') {
                    setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
                }
            }
        }
        if (VMemberId != null) {
            if (VMemberId != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }
        if (VAccountTypeId != null) {
            if (VAccountTypeId != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }
        if (VAccountNumber != null) {
            if (VAccountNumber != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }
        if (VOwnershipIndicator != null) {
            if (VOwnershipIndicator != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }
        if (VCreditLimit != null) {
            if (VCreditLimit != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }
        if (VHighCredit != null) {
            if (VHighCredit != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }
        if (VCurrentBalance != null) {
            if (VCurrentBalance != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }
        if (VAmountOverdue != null) {
            if (VAmountOverdue != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }
        if (VDPDsforLatestMonth != null) {
            if (VDPDsforLatestMonth != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }
        if (VDateOpened != null) {
            if (VDateOpened != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }
        if (VDateClosed != null) {
            if (VDateClosed != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }
        if (VDateofLastPayment != null) {
            if (VDateofLastPayment != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }
        if (VDateReported != null) {
            if (VDateReported != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }
        if (VSuiteFiled != null) {
            if (VSuiteFiled != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }
        if (VCreditFacilityStatus != null) {
            if (VCreditFacilityStatus != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }
        if (VWrittenofAmount != null) {
            if (VWrittenofAmount != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }
        if (VSettlementAmount != null) {
            if (VSettlementAmount != '') {
                setCheckLeadCIBILValid(CheckLeadCIBILValid + 1);
            }
        }

    }

    const CheckLeadEqifax = async () => {
        if (LeadEqifaxList.length == 0) {
            var VDateofReportPulledOut = document.getElementById("txtEqifaxDateofReportPulledOut").value;
            var VEqifaxScore = document.getElementById("txtEqifaxScore").value;
            var VNameofBorrower = document.getElementById("txtEqifaxNameofBorrower").value;
            var VPANNo = document.getElementById("txtEqifaxPANNo").value;
            var VAdditionalKYCCIR = document.getElementById("txtEqifaxAdditionalKYCCIR").value;
            var VEnquiry = document.getElementById("txtEqifaxEnquiry").value;

            if (VDateofReportPulledOut != null) {
                if (VDateofReportPulledOut != '') {
                    setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
                }
            }
            if (VEqifaxScore != null) {
                if (VEqifaxScore != '') {
                    setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
                }
            }
            if (VNameofBorrower != null) {
                if (VNameofBorrower != '') {
                    setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
                }
            }
            if (VPANNo != null) {
                if (VPANNo != '') {
                    setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
                }
            }
            if (VAdditionalKYCCIR != null) {
                if (VAdditionalKYCCIR != '') {
                    setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
                }
            }
            if (VEnquiry != null) {
                if (VEnquiry != '') {
                    setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
                }
            }
        }

        var VMemberId = document.getElementById("ddlEqifaxMember").value;
        var VAccountTypeId = document.getElementById("ddlEqifaxAccountType").value;
        var VAccountNumber = document.getElementById("txtEqifaxAccountNumber").value;
        var VOwnershipIndicator = document.getElementById("txtEqifaxOwnershipIndicator").value;
        var VCreditLimit = document.getElementById("txtEqifaxCreditLimit").value;
        var VHighCredit = document.getElementById("txtEqifaxHighCredit").value;
        var VCurrentBalance = document.getElementById("txtEqifaxCurrentBalance").value;
        var VAmountOverdue = document.getElementById("txtEqifaxAmountOverdue").value;
        var VDPDsforLatestMonth = document.getElementById("txtEqifaxDPDsforLatestMonth").value;
        var VDateOpened = document.getElementById("txtEqifaxDateOpened").value;
        var VDateClosed = document.getElementById("txtEqifaxDateClosed").value;
        var VDateofLastPayment = document.getElementById("txtEqifaxDateofLastPayment").value;
        var VDateReported = document.getElementById("txtEqifaxDateReported").value;
        var VSuiteFiled = document.getElementById("ddlEqifaxSuiteFiled").value;
        var VCreditFacilityStatus = document.getElementById("ddlEqifaxCreditFacilityStatus").value;
        var VWrittenofAmount = document.getElementById("txtEqifaxWrittenofAmount").value;
        var VSettlementAmount = document.getElementById("txtEqifaxSettlementAmount").value;



        if (VMemberId != null) {
            if (VMemberId != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }
        if (VAccountTypeId != null) {
            if (VAccountTypeId != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }
        if (VAccountNumber != null) {
            if (VAccountNumber != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }
        if (VOwnershipIndicator != null) {
            if (VOwnershipIndicator != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }
        if (VCreditLimit != null) {
            if (VCreditLimit != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }
        if (VHighCredit != null) {
            if (VHighCredit != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }
        if (VCurrentBalance != null) {
            if (VCurrentBalance != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }
        if (VAmountOverdue != null) {
            if (VAmountOverdue != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }
        if (VDPDsforLatestMonth != null) {
            if (VDPDsforLatestMonth != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }
        if (VDateOpened != null) {
            if (VDateOpened != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }
        if (VDateClosed != null) {
            if (VDateClosed != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }
        if (VDateofLastPayment != null) {
            if (VDateofLastPayment != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }
        if (VDateReported != null) {
            if (VDateReported != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }
        if (VSuiteFiled != null) {
            if (VSuiteFiled != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }
        if (VCreditFacilityStatus != null) {
            if (VCreditFacilityStatus != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }
        if (VWrittenofAmount != null) {
            if (VWrittenofAmount != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }
        if (VSettlementAmount != null) {
            if (VSettlementAmount != '') {
                setCheckLeadEqifaxValid(CheckLeadEqifaxValid + 1);
            }
        }

    }

    const CheckLeadExperian = async () => {
        if (LeadExperianList.length == 0) {
            var VDateofReportPulledOut = document.getElementById("txtExperianDateofReportPulledOut").value;
            var VExperianScore = document.getElementById("txtExperianScore").value;
            var VNameofBorrower = document.getElementById("txtExperianNameofBorrower").value;
            var VPANNo = document.getElementById("txtExperianPANNo").value;
            var VAdditionalKYCCIR = document.getElementById("txtExperianAdditionalKYCCIR").value;
            var VEnquiry = document.getElementById("txtExperianEnquiry").value;
            if (VDateofReportPulledOut != null) {
                if (VDateofReportPulledOut != '') {
                    setCheckLeadExperianValid(CheckLeadExperianValid + 1);
                }
            }
            if (VExperianScore != null) {
                if (VExperianScore != '') {
                    setCheckLeadExperianValid(CheckLeadExperianValid + 1);
                }
            }
            if (VNameofBorrower != null) {
                if (VNameofBorrower != '') {
                    setCheckLeadExperianValid(CheckLeadExperianValid + 1);
                }
            }
            if (VPANNo != null) {
                if (VPANNo != '') {
                    setCheckLeadExperianValid(CheckLeadExperianValid + 1);
                }
            }
            if (VAdditionalKYCCIR != null) {
                if (VAdditionalKYCCIR != '') {
                    setCheckLeadExperianValid(CheckLeadExperianValid + 1);
                }
            }
            if (VEnquiry != null) {
                if (VEnquiry != '') {
                    setCheckLeadExperianValid(CheckLeadExperianValid + 1);
                }
            }
        }
        var VMemberId = document.getElementById("ddlExperianMember").value;
        var VAccountTypeId = document.getElementById("ddlExperianAccountType").value;
        var VAccountNumber = document.getElementById("txtExperianAccountNumber").value;
        var VOwnershipIndicator = document.getElementById("txtExperianOwnershipIndicator").value;
        var VCreditLimit = document.getElementById("txtExperianCreditLimit").value;
        var VHighCredit = document.getElementById("txtExperianHighCredit").value;
        var VCurrentBalance = document.getElementById("txtExperianCurrentBalance").value;
        var VAmountOverdue = document.getElementById("txtExperianAmountOverdue").value;
        var VDPDsforLatestMonth = document.getElementById("txtExperianDPDsforLatestMonth").value;
        var VDateOpened = document.getElementById("txtExperianDateOpened").value;
        var VDateClosed = document.getElementById("txtExperianDateClosed").value;
        var VDateofLastPayment = document.getElementById("txtExperianDateofLastPayment").value;
        var VDateReported = document.getElementById("txtExperianDateReported").value;
        var VSuiteFiled = document.getElementById("ddlExperianSuiteFiled").value;
        var VCreditFacilityStatus = document.getElementById("ddlExperianCreditFacilityStatus").value;
        var VWrittenofAmount = document.getElementById("txtExperianWrittenofAmount").value;
        var VSettlementAmount = document.getElementById("txtExperianSettlementAmount").value;



        if (VMemberId != null) {
            if (VMemberId != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }
        if (VAccountTypeId != null) {
            if (VAccountTypeId != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }
        if (VAccountNumber != null) {
            if (VAccountNumber != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }
        if (VOwnershipIndicator != null) {
            if (VOwnershipIndicator != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }
        if (VCreditLimit != null) {
            if (VCreditLimit != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }
        if (VHighCredit != null) {
            if (VHighCredit != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }
        if (VCurrentBalance != null) {
            if (VCurrentBalance != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }
        if (VAmountOverdue != null) {
            if (VAmountOverdue != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }
        if (VDPDsforLatestMonth != null) {
            if (VDPDsforLatestMonth != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }
        if (VDateOpened != null) {
            if (VDateOpened != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }
        if (VDateClosed != null) {
            if (VDateClosed != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }
        if (VDateofLastPayment != null) {
            if (VDateofLastPayment != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }
        if (VDateReported != null) {
            if (VDateReported != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }
        if (VSuiteFiled != null) {
            if (VSuiteFiled != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }
        if (VCreditFacilityStatus != null) {
            if (VCreditFacilityStatus != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }
        if (VWrittenofAmount != null) {
            if (VWrittenofAmount != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }
        if (VSettlementAmount != null) {
            if (VSettlementAmount != '') {
                setCheckLeadExperianValid(CheckLeadExperianValid + 1);
            }
        }

    }

    const CheckLeadCRIF = async () => {
        if (LeadCRIFList.length == 0) {
            var VDateofReportPulledOut = document.getElementById("txtCRIFDateofReportPulledOut").value;
            var VCRIFScore = document.getElementById("txtCRIFScore").value;
            var VNameofBorrower = document.getElementById("txtCRIFNameofBorrower").value;
            var VPANNo = document.getElementById("txtCRIFPANNo").value;
            var VAdditionalKYCCIR = document.getElementById("txtCRIFAdditionalKYCCIR").value;
            var VEnquiry = document.getElementById("txtCRIFEnquiry").value;
        }
        var VMemberId = document.getElementById("ddlCRIFMember").value;
        var VAccountTypeId = document.getElementById("ddlCRIFAccountType").value;
        var VAccountNumber = document.getElementById("txtCRIFAccountNumber").value;
        var VOwnershipIndicator = document.getElementById("txtCRIFOwnershipIndicator").value;
        var VCreditLimit = document.getElementById("txtCRIFCreditLimit").value;
        var VHighCredit = document.getElementById("txtCRIFHighCredit").value;
        var VCurrentBalance = document.getElementById("txtCRIFCurrentBalance").value;
        var VAmountOverdue = document.getElementById("txtCRIFAmountOverdue").value;
        var VDPDsforLatestMonth = document.getElementById("txtCRIFDPDsforLatestMonth").value;
        var VDateOpened = document.getElementById("txtCRIFDateOpened").value;
        var VDateClosed = document.getElementById("txtCRIFDateClosed").value;
        var VDateofLastPayment = document.getElementById("txtCRIFDateofLastPayment").value;
        var VDateReported = document.getElementById("txtCRIFDateReported").value;
        var VSuiteFiled = document.getElementById("ddlCRIFSuiteFiled").value;
        var VCreditFacilityStatus = document.getElementById("ddlCRIFCreditFacilityStatus").value;
        var VWrittenofAmount = document.getElementById("txtCRIFWrittenofAmount").value;
        var VSettlementAmount = document.getElementById("txtCRIFSettlementAmount").value;


        if (LeadCRIFList.length == 0) {
            if (VDateofReportPulledOut != null) {
                if (VDateofReportPulledOut != '') {
                    setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
                }
            }
            if (VCRIFScore != null) {
                if (VCRIFScore != '') {
                    setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
                }
            }
            if (VNameofBorrower != null) {
                if (VNameofBorrower != '') {
                    setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
                }
            }
            if (VPANNo != null) {
                if (VPANNo != '') {
                    setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
                }
            }
            if (VAdditionalKYCCIR != null) {
                if (VAdditionalKYCCIR != '') {
                    setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
                }
            }
            if (VEnquiry != null) {
                if (VEnquiry != '') {
                    setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
                }
            }
        }
        if (VMemberId != null) {
            if (VMemberId != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }
        if (VAccountTypeId != null) {
            if (VAccountTypeId != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }
        if (VAccountNumber != null) {
            if (VAccountNumber != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }
        if (VOwnershipIndicator != null) {
            if (VOwnershipIndicator != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }
        if (VCreditLimit != null) {
            if (VCreditLimit != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }
        if (VHighCredit != null) {
            if (VHighCredit != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }
        if (VCurrentBalance != null) {
            if (VCurrentBalance != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }
        if (VAmountOverdue != null) {
            if (VAmountOverdue != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }
        if (VDPDsforLatestMonth != null) {
            if (VDPDsforLatestMonth != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }
        if (VDateOpened != null) {
            if (VDateOpened != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }
        if (VDateClosed != null) {
            if (VDateClosed != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }
        if (VDateofLastPayment != null) {
            if (VDateofLastPayment != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }
        if (VDateReported != null) {
            if (VDateReported != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }
        if (VSuiteFiled != null) {
            if (VSuiteFiled != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }
        if (VCreditFacilityStatus != null) {
            if (VCreditFacilityStatus != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }
        if (VWrittenofAmount != null) {
            if (VWrittenofAmount != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }
        if (VSettlementAmount != null) {
            if (VSettlementAmount != '') {
                setCheckLeadCRIFValid(CheckLeadCRIFValid + 1);
            }
        }

    }

    const SaveLeadCIBIL = async () => {
        let VLeadId = localStorage.getItem("ConvertIndividualLeadId");

        for (let par = 0; par < LeadCIBILList.length; par++) {
            const LeadCIBILDetail = LeadCIBILList[par];
            var VDateofReportPulledOuttxtId = LeadCIBILDetail.DateofReportPulledOuttxtId;
            var VCibilScoretxtId = LeadCIBILDetail.CibilScoretxtId;
            var VNameofBorrowertxtId = LeadCIBILDetail.NameofBorrowertxtId;
            var VPANNotxtId = LeadCIBILDetail.PANNotxtId;
            var VAdditionalKYCCIRtxtId = LeadCIBILDetail.AdditionalKYCCIRtxtId;
            var VEnquirytxtId = LeadCIBILDetail.EnquirytxtId;
            var VMemberIdtxtId = LeadCIBILDetail.MemberIdtxtId;
            var VAccountTypetxtId = LeadCIBILDetail.AccountTypetxtId;
            var VAccountNumbertxtId = LeadCIBILDetail.AccountNumbertxtId;
            var VOwnershipIndicatortxtId = LeadCIBILDetail.OwnershipIndicatortxtId;
            var VCreditLimittxtId = LeadCIBILDetail.CreditLimittxtId;
            var VHighCredittxtId = LeadCIBILDetail.HighCredittxtId;
            var VCurrentBalancetxtId = LeadCIBILDetail.CurrentBalancetxtId;
            var VAmountOverduetxtId = LeadCIBILDetail.AmountOverduetxtId;
            var VDPDsforLatestMonthtxtId = LeadCIBILDetail.DPDsforLatestMonthtxtId;
            var VDateOpenedtxtId = LeadCIBILDetail.DateOpenedtxtId;
            var VDateClosedtxtId = LeadCIBILDetail.DateClosedtxtId;
            var VDateofLastPaymenttxtId = LeadCIBILDetail.DateofLastPaymenttxtId;
            var VDateReportedtxtId = LeadCIBILDetail.DateReportedtxtId;
            var VSuiteFiledtxtId = LeadCIBILDetail.SuiteFiledtxtId;
            var VCreditFacilityStatustxtId = LeadCIBILDetail.CreditFacilityStatustxtId;
            var VWrittenofAmounttxtId = LeadCIBILDetail.WrittenofAmounttxtId;
            var VSettlementAmounttxtId = LeadCIBILDetail.SettlementAmounttxtId;
            var VTotalNonReportingMonthtxtId = LeadCIBILDetail.TotalNonReportingMonthtxtId;


            var VDateofReportPulledOut = document.getElementById("txtCIBILDateofReportPulledOut").value == null ? '' : document.getElementById("txtCIBILDateofReportPulledOut").value;
            var VCibilScore = document.getElementById("txtCIBILScore").value == null ? '' : document.getElementById("txtCIBILScore").value;
            var VNameofBorrower = document.getElementById("txtCIBILNameofBorrower").value == null ? '' : document.getElementById("txtCIBILNameofBorrower").value;
            var VPANNo = document.getElementById("txtCIBILPANNo").value == null ? '' : document.getElementById("txtCIBILPANNo").value;
            var VAdditionalKYCCIR = document.getElementById("txtCIBILAdditionalKYCCIR").value == null ? '' : document.getElementById("txtCIBILAdditionalKYCCIR").value;
            var VEnquiry = document.getElementById("txtCIBILEnquiry").value == null ? '' : document.getElementById("txtCIBILEnquiry").value;

            var VMemberId = document.getElementById(VMemberIdtxtId).value == null ? '0' : document.getElementById(VMemberIdtxtId).value = '' ? '0' : document.getElementById(VMemberIdtxtId).value;
            var VAccountTypeId = document.getElementById(VAccountTypetxtId).value == null ? '' : document.getElementById(VAccountTypetxtId).value = '' ? '' : document.getElementById(VAccountTypetxtId).value;
            var VAccountNumber = document.getElementById(VAccountNumbertxtId).value == null ? '' : document.getElementById(VAccountNumbertxtId).value = '' ? '' : document.getElementById(VAccountNumbertxtId).value;
            var VOwnershipIndicator = document.getElementById(VOwnershipIndicatortxtId).value == null ? '' : document.getElementById(VOwnershipIndicatortxtId).value = '' ? '' : document.getElementById(VOwnershipIndicatortxtId).value;
            var VCreditLimit = document.getElementById(VCreditLimittxtId).value == null ? '' : document.getElementById(VCreditLimittxtId).value = '' ? '' : document.getElementById(VCreditLimittxtId).value;
            var VHighCredit = document.getElementById(VHighCredittxtId).value == null ? '' : document.getElementById(VHighCredittxtId).value = '' ? '' : document.getElementById(VHighCredittxtId).value;
            var VCurrentBalance = document.getElementById(VCurrentBalancetxtId).value == null ? '' : document.getElementById(VCurrentBalancetxtId).value = '' ? '' : document.getElementById(VCurrentBalancetxtId).value;
            var VAmountOverdue = document.getElementById(VAmountOverduetxtId).value == null ? '' : document.getElementById(VAmountOverduetxtId).value = '' ? '' : document.getElementById(VAmountOverduetxtId).value;
            var VDPDsforLatestMonth = document.getElementById(VDPDsforLatestMonthtxtId).value == null ? '' : document.getElementById(VDPDsforLatestMonthtxtId).value = '' ? '' : document.getElementById(VDPDsforLatestMonthtxtId).value;
            var VDateOpened = document.getElementById(VDateOpenedtxtId).value == null ? '' : document.getElementById(VDateOpenedtxtId).value = '' ? '' : document.getElementById(VDateOpenedtxtId).value;
            var VDateClosed = document.getElementById(VDateClosedtxtId).value == null ? '' : document.getElementById(VDateClosedtxtId).value = '' ? '' : document.getElementById(VDateClosedtxtId).value;
            var VDateofLastPayment = document.getElementById(VDateofLastPaymenttxtId).value == null ? '' : document.getElementById(VDateofLastPaymenttxtId).value = '' ? '' : document.getElementById(VDateofLastPaymenttxtId).value;
            var VDateReported = document.getElementById(VDateReportedtxtId).value == null ? '' : document.getElementById(VDateReportedtxtId).value = '' ? '' : document.getElementById(VDateReportedtxtId).value;
            var VSuiteFiled = document.getElementById(VSuiteFiledtxtId).value == null ? '0' : document.getElementById(VSuiteFiledtxtId).value = '' ? '0' : document.getElementById(VSuiteFiledtxtId).value;
            var VCreditFacilityStatus = document.getElementById(VCreditFacilityStatustxtId).value == null ? '0' : document.getElementById(VCreditFacilityStatustxtId).value = '' ? '0' : document.getElementById(VCreditFacilityStatustxtId).value;
            var VWrittenofAmount = document.getElementById(VWrittenofAmounttxtId).value == null ? '' : document.getElementById(VWrittenofAmounttxtId).value = '' ? '' : document.getElementById(VWrittenofAmounttxtId).value;
            var VSettlementAmount = document.getElementById(VSettlementAmounttxtId).value == null ? '' : document.getElementById(VSettlementAmounttxtId).value = '' ? '' : document.getElementById(VSettlementAmounttxtId).value;
            var VTotalNonReportingMonth = document.getElementById(VTotalNonReportingMonthtxtId).value == null ? '' : document.getElementById(VTotalNonReportingMonthtxtId).value = '' ? '' : document.getElementById(VTotalNonReportingMonthtxtId).value;



            const LeadCIBILItemArr =
            {
                LeadEnrollCIBILId: LeadCIBILDetail.LeadEnrollCIBILId,
                LeadId: VLeadId,
                DateofReportPulledOut: VDateofReportPulledOut,
                CibilScore: VCibilScore,
                NameofBorrower: VNameofBorrower,
                PANNo: VPANNo,
                AdditionalKYCCIR: VAdditionalKYCCIR,
                MemberId: VMemberId,
                AccountTypeId: VAccountTypeId,
                AccountNumber: VAccountNumber,
                OwnershipIndicator: VOwnershipIndicator,
                CreditLimit: VCreditLimit,
                HighCredit: VHighCredit,
                CurrentBalance: VCurrentBalance,
                AmountOverdue: VAmountOverdue,
                DPDsforLatestMonth: VDPDsforLatestMonth,
                DateOpened: VDateOpened,
                DateClosed: VDateClosed,
                DateofLastPayment: VDateofLastPayment,
                DateReported: VDateReported,
                SuiteFiled: VSuiteFiled,
                CreditFacilityStatus: VCreditFacilityStatus,
                WrittenofAmount: VWrittenofAmount,
                SettlementAmount: VSettlementAmount,
                Enquiry: VEnquiry,
                TotalNonReportingMonth: VTotalNonReportingMonth,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            };
            axios.post(global.APIURL + '/LeadEnrollCIBILSave', LeadCIBILItemArr).then(res => { if (res.data.length > 0) { } });
        }

        if (CheckLeadCIBILValid > 0) {
            var VDateofReportPulledOut = document.getElementById("txtCIBILDateofReportPulledOut").value;
            var VCibilScore = document.getElementById("txtCIBILScore").value;
            var VNameofBorrower = document.getElementById("txtCIBILNameofBorrower").value;
            var VPANNo = document.getElementById("txtCIBILPANNo").value;
            var VAdditionalKYCCIR = document.getElementById("txtCIBILAdditionalKYCCIR").value;
            var VMemberId = document.getElementById("ddlCIBILMember").value;
            var VAccountTypeId = document.getElementById("ddlCIBILAccountType").value;
            var VAccountNumber = document.getElementById("txtCIBILAccountNumber").value;
            var VOwnershipIndicator = document.getElementById("txtCIBILOwnershipIndicator").value;
            var VCreditLimit = document.getElementById("txtCIBILCreditLimit").value;
            var VHighCredit = document.getElementById("txtCIBILHighCredit").value;
            var VCurrentBalance = document.getElementById("txtCIBILCurrentBalance").value;
            var VAmountOverdue = document.getElementById("txtCIBILAmountOverdue").value;
            var VDPDsforLatestMonth = document.getElementById("txtCIBILDPDsforLatestMonth").value;
            var VDateOpened = document.getElementById("txtCIBILDateOpened").value;
            var VDateClosed = document.getElementById("txtCIBILDateClosed").value;
            var VDateofLastPayment = document.getElementById("txtCIBILDateofLastPayment").value;
            var VDateReported = document.getElementById("txtCIBILDateReported").value;
            var VSuiteFiled = document.getElementById("ddlCIBILSuiteFiled").value;
            var VCreditFacilityStatus = document.getElementById("ddlCIBILCreditFacilityStatus").value;
            var VWrittenofAmount = document.getElementById("txtCIBILWrittenofAmount").value;
            var VSettlementAmount = document.getElementById("txtCIBILSettlementAmount").value;
            var VEnquiry = LeadCIBILList.length == 0 ? document.getElementById("txtCIBILEnquiry").value : '';
            var VTotalNonReportingMonth = document.getElementById("txtCIBILTotalNonReportingMonth").value;

            const LeadCIBILItemArr =
            {
                LeadEnrollCIBILId: 0,
                LeadId: VLeadId,
                DateofReportPulledOut: VDateofReportPulledOut,
                CibilScore: VCibilScore,
                NameofBorrower: VNameofBorrower,
                PANNo: VPANNo,
                AdditionalKYCCIR: VAdditionalKYCCIR,
                MemberId: VMemberId,
                AccountTypeId: VAccountTypeId,
                AccountNumber: VAccountNumber,
                OwnershipIndicator: VOwnershipIndicator,
                CreditLimit: VCreditLimit,
                HighCredit: VHighCredit,
                CurrentBalance: VCurrentBalance,
                AmountOverdue: VAmountOverdue,
                DPDsforLatestMonth: VDPDsforLatestMonth,
                DateOpened: VDateOpened,
                DateClosed: VDateClosed,
                DateofLastPayment: VDateofLastPayment,
                DateReported: VDateReported,
                SuiteFiled: VSuiteFiled,
                CreditFacilityStatus: VCreditFacilityStatus,
                WrittenofAmount: VWrittenofAmount,
                SettlementAmount: VSettlementAmount,
                Enquiry: VEnquiry,
                TotalNonReportingMonth: VTotalNonReportingMonth,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            };
            axios.post(global.APIURL + '/LeadEnrollCIBILSave', LeadCIBILItemArr).then(res => { if (res.data.length > 0) { } });
        }
    }

    const SaveLeadEqifax = async () => {
        let VLeadId = localStorage.getItem("ConvertIndividualLeadId");
        var VchkSameEqifax = document.getElementById("chkSameEqifax").checked;
        if (VchkSameEqifax == true) {
            var VLId = parseInt(VLeadId == null ? '0' : VLeadId == '' ? '0' : VLeadId);
            if (VLId > 0) {
                const LeadItemArr = { Id: VLId }
                axios.post(global.APIURL + '/LeadEqifaxCreditDetailDeleteByLeadId', LeadItemArr).then(resem => { });
            }
        }



        for (let par = 0; par < LeadEqifaxList.length; par++) {
            const LeadEqifaxDetail = LeadEqifaxList[par];
            var VDateofReportPulledOuttxtId = LeadEqifaxDetail.DateofReportPulledOuttxtId;
            var VEqifaxScoretxtId = LeadEqifaxDetail.EqifaxScoretxtId;
            var VNameofBorrowertxtId = LeadEqifaxDetail.NameofBorrowertxtId;
            var VPANNotxtId = LeadEqifaxDetail.PANNotxtId;
            var VAdditionalKYCCIRtxtId = LeadEqifaxDetail.AdditionalKYCCIRtxtId;
            var VEnquirytxtId = LeadEqifaxDetail.EnquirytxtId;
            var VMemberIdtxtId = LeadEqifaxDetail.MemberIdtxtId;
            var VAccountTypetxtId = LeadEqifaxDetail.AccountTypetxtId;
            var VAccountNumbertxtId = LeadEqifaxDetail.AccountNumbertxtId;
            var VOwnershipIndicatortxtId = LeadEqifaxDetail.OwnershipIndicatortxtId;
            var VCreditLimittxtId = LeadEqifaxDetail.CreditLimittxtId;
            var VHighCredittxtId = LeadEqifaxDetail.HighCredittxtId;
            var VCurrentBalancetxtId = LeadEqifaxDetail.CurrentBalancetxtId;
            var VAmountOverduetxtId = LeadEqifaxDetail.AmountOverduetxtId;
            var VDPDsforLatestMonthtxtId = LeadEqifaxDetail.DPDsforLatestMonthtxtId;
            var VDateOpenedtxtId = LeadEqifaxDetail.DateOpenedtxtId;
            var VDateClosedtxtId = LeadEqifaxDetail.DateClosedtxtId;
            var VDateofLastPaymenttxtId = LeadEqifaxDetail.DateofLastPaymenttxtId;
            var VDateReportedtxtId = LeadEqifaxDetail.DateReportedtxtId;
            var VSuiteFiledtxtId = LeadEqifaxDetail.SuiteFiledtxtId;
            var VCreditFacilityStatustxtId = LeadEqifaxDetail.CreditFacilityStatustxtId;
            var VWrittenofAmounttxtId = LeadEqifaxDetail.WrittenofAmounttxtId;
            var VSettlementAmounttxtId = LeadEqifaxDetail.SettlementAmounttxtId;
            var VTotalNonReportingMonthtxtId = LeadEqifaxDetail.TotalNonReportingMonthtxtId;


            var VDateofReportPulledOut = document.getElementById("txtEqifaxDateofReportPulledOut").value == null ? '' : document.getElementById("txtEqifaxDateofReportPulledOut").value;
            var VEqifaxScore = document.getElementById("txtEqifaxScore").value == null ? '' : document.getElementById("txtEqifaxScore").value;
            var VNameofBorrower = document.getElementById("txtEqifaxNameofBorrower").value == null ? '' : document.getElementById("txtEqifaxNameofBorrower").value;
            var VPANNo = document.getElementById("txtEqifaxPANNo").value == null ? '' : document.getElementById("txtEqifaxPANNo").value;
            var VAdditionalKYCCIR =  document.getElementById("txtEqifaxAdditionalKYCCIR").value == null ? '' : document.getElementById("txtEqifaxAdditionalKYCCIR").value;
            var VMemberId = document.getElementById(VMemberIdtxtId).value == null ? '0' : document.getElementById(VMemberIdtxtId).value = '' ? '0' : document.getElementById(VMemberIdtxtId).value;
            var VAccountTypeId = document.getElementById(VAccountTypetxtId).value == null ? '' : document.getElementById(VAccountTypetxtId).value = '' ? '' : document.getElementById(VAccountTypetxtId).value;
            var VAccountNumber = document.getElementById(VAccountNumbertxtId).value == null ? '' : document.getElementById(VAccountNumbertxtId).value = '' ? '' : document.getElementById(VAccountNumbertxtId).value;
            var VOwnershipIndicator = document.getElementById(VOwnershipIndicatortxtId).value == null ? '' : document.getElementById(VOwnershipIndicatortxtId).value = '' ? '' : document.getElementById(VOwnershipIndicatortxtId).value;
            var VCreditLimit = document.getElementById(VCreditLimittxtId).value == null ? '' : document.getElementById(VCreditLimittxtId).value = '' ? '' : document.getElementById(VCreditLimittxtId).value;
            var VHighCredit = document.getElementById(VHighCredittxtId).value == null ? '' : document.getElementById(VHighCredittxtId).value = '' ? '' : document.getElementById(VHighCredittxtId).value;
            var VCurrentBalance = document.getElementById(VCurrentBalancetxtId).value == null ? '' : document.getElementById(VCurrentBalancetxtId).value = '' ? '' : document.getElementById(VCurrentBalancetxtId).value;
            var VAmountOverdue = document.getElementById(VAmountOverduetxtId).value == null ? '' : document.getElementById(VAmountOverduetxtId).value = '' ? '' : document.getElementById(VAmountOverduetxtId).value;
            var VDPDsforLatestMonth = document.getElementById(VDPDsforLatestMonthtxtId).value == null ? '' : document.getElementById(VDPDsforLatestMonthtxtId).value = '' ? '' : document.getElementById(VDPDsforLatestMonthtxtId).value;
            var VDateOpened = document.getElementById(VDateOpenedtxtId).value == null ? '' : document.getElementById(VDateOpenedtxtId).value = '' ? '' : document.getElementById(VDateOpenedtxtId).value;
            var VDateClosed = document.getElementById(VDateClosedtxtId).value == null ? '' : document.getElementById(VDateClosedtxtId).value = '' ? '' : document.getElementById(VDateClosedtxtId).value;
            var VDateofLastPayment = document.getElementById(VDateofLastPaymenttxtId).value == null ? '' : document.getElementById(VDateofLastPaymenttxtId).value = '' ? '' : document.getElementById(VDateofLastPaymenttxtId).value;
            var VDateReported = document.getElementById(VDateReportedtxtId).value == null ? '' : document.getElementById(VDateReportedtxtId).value = '' ? '' : document.getElementById(VDateReportedtxtId).value;
            var VSuiteFiled = document.getElementById(VSuiteFiledtxtId).value == null ? '0' : document.getElementById(VSuiteFiledtxtId).value = '' ? '0' : document.getElementById(VSuiteFiledtxtId).value;
            var VCreditFacilityStatus = document.getElementById(VCreditFacilityStatustxtId).value == null ? '0' : document.getElementById(VCreditFacilityStatustxtId).value = '' ? '0' : document.getElementById(VCreditFacilityStatustxtId).value;
            var VWrittenofAmount = document.getElementById(VWrittenofAmounttxtId).value == null ? '' : document.getElementById(VWrittenofAmounttxtId).value = '' ? '' : document.getElementById(VWrittenofAmounttxtId).value;
            var VSettlementAmount = document.getElementById(VSettlementAmounttxtId).value == null ? '' : document.getElementById(VSettlementAmounttxtId).value = '' ? '' : document.getElementById(VSettlementAmounttxtId).value;
            var VEnquiry = document.getElementById("txtEqifaxEnquiry").value == null ? '' : document.getElementById("txtEqifaxEnquiry").value;
            var VTotalNonReportingMonth = document.getElementById(VTotalNonReportingMonthtxtId).value == null ? '' : document.getElementById(VTotalNonReportingMonthtxtId).value = '' ? '' : document.getElementById(VTotalNonReportingMonthtxtId).value;


            const LeadEqifaxItemArr =
            {
                LeadEnrollEqifaxId: LeadEqifaxDetail.LeadEnrollEqifaxId,
                LeadId: VLeadId,
                DateofReportPulledOut: VDateofReportPulledOut,
                CibilScore: VEqifaxScore,
                NameofBorrower: VNameofBorrower,
                PANNo: VPANNo,
                AdditionalKYCCIR: VAdditionalKYCCIR,
                MemberId: VMemberId,
                AccountTypeId: VAccountTypeId,
                AccountNumber: VAccountNumber,
                OwnershipIndicator: VOwnershipIndicator,
                CreditLimit: VCreditLimit,
                HighCredit: VHighCredit,
                CurrentBalance: VCurrentBalance,
                AmountOverdue: VAmountOverdue,
                DPDsforLatestMonth: VDPDsforLatestMonth,
                DateOpened: VDateOpened,
                DateClosed: VDateClosed,
                DateofLastPayment: VDateofLastPayment,
                DateReported: VDateReported,
                SuiteFiled: VSuiteFiled,
                CreditFacilityStatus: VCreditFacilityStatus,
                WrittenofAmount: VWrittenofAmount,
                SettlementAmount: VSettlementAmount,
                Enquiry: VEnquiry,
                TotalNonReportingMonth: VTotalNonReportingMonth,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            };
            axios.post(global.APIURL + '/LeadEnrollEqifaxSave', LeadEqifaxItemArr).then(res => { if (res.data.length > 0) { } });
        }


        if (CheckLeadEqifaxValid > 0) {
            var VDateofReportPulledOut = document.getElementById("txtEqifaxDateofReportPulledOut").value
            var VEqifaxScore = document.getElementById("txtEqifaxScore").value;
            var VNameofBorrower = document.getElementById("txtEqifaxNameofBorrower").value;
            var VPANNo = document.getElementById("txtEqifaxPANNo").value;
            var VAdditionalKYCCIR = document.getElementById("txtEqifaxAdditionalKYCCIR").value;
            var VMemberId = document.getElementById("ddlEqifaxMember").value;
            var VAccountTypeId = document.getElementById("ddlEqifaxAccountType").value;
            var VAccountNumber = document.getElementById("txtEqifaxAccountNumber").value;
            var VOwnershipIndicator = document.getElementById("txtEqifaxOwnershipIndicator").value;
            var VCreditLimit = document.getElementById("txtEqifaxCreditLimit").value;
            var VHighCredit = document.getElementById("txtEqifaxHighCredit").value;
            var VCurrentBalance = document.getElementById("txtEqifaxCurrentBalance").value;
            var VAmountOverdue = document.getElementById("txtEqifaxAmountOverdue").value;
            var VDPDsforLatestMonth = document.getElementById("txtEqifaxDPDsforLatestMonth").value;
            var VDateOpened = document.getElementById("txtEqifaxDateOpened").value;
            var VDateClosed = document.getElementById("txtEqifaxDateClosed").value;
            var VDateofLastPayment = document.getElementById("txtEqifaxDateofLastPayment").value;
            var VDateReported = document.getElementById("txtEqifaxDateReported").value;
            var VSuiteFiled = document.getElementById("ddlEqifaxSuiteFiled").value;
            var VCreditFacilityStatus = document.getElementById("ddlEqifaxCreditFacilityStatus").value;
            var VWrittenofAmount = document.getElementById("txtEqifaxWrittenofAmount").value;
            var VSettlementAmount = document.getElementById("txtEqifaxSettlementAmount").value;
            var VEnquiry = document.getElementById("txtEqifaxEnquiry").value;
            var VTotalNonReportingMonth = document.getElementById("txtEqifaxTotalNonReportingMonth").value;

            const LeadEqifaxItemArr =
            {
                LeadEnrollEqifaxId: 0,
                LeadId: VLeadId,
                DateofReportPulledOut: VDateofReportPulledOut,
                CibilScore: VEqifaxScore,
                NameofBorrower: VNameofBorrower,
                PANNo: VPANNo,
                AdditionalKYCCIR: VAdditionalKYCCIR,
                MemberId: VMemberId,
                AccountTypeId: VAccountTypeId,
                AccountNumber: VAccountNumber,
                OwnershipIndicator: VOwnershipIndicator,
                CreditLimit: VCreditLimit,
                HighCredit: VHighCredit,
                CurrentBalance: VCurrentBalance,
                AmountOverdue: VAmountOverdue,
                DPDsforLatestMonth: VDPDsforLatestMonth,
                DateOpened: VDateOpened,
                DateClosed: VDateClosed,
                DateofLastPayment: VDateofLastPayment,
                DateReported: VDateReported,
                SuiteFiled: VSuiteFiled,
                CreditFacilityStatus: VCreditFacilityStatus,
                WrittenofAmount: VWrittenofAmount,
                SettlementAmount: VSettlementAmount,
                Enquiry: VEnquiry,
                TotalNonReportingMonth: VTotalNonReportingMonth,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            };
            axios.post(global.APIURL + '/LeadEnrollEqifaxSave', LeadEqifaxItemArr).then(res => { if (res.data.length > 0) { } });
        }
    }

    const SaveLeadExperian = async () => {
        let VLeadId = localStorage.getItem("ConvertIndividualLeadId");
        var VchkSameExperian = document.getElementById("chkSameExperian").checked;
        if (VchkSameExperian == true) {
            var VLId = parseInt(VLeadId == null ? '0' : VLeadId == '' ? '0' : VLeadId);
            if (VLId > 0) {
                const LeadItemArr = { Id: VLId }
                axios.post(global.APIURL + '/LeadExperianCreditDetailDeleteByLeadId', LeadItemArr).then(resem => { });
            }
        }
        for (let par = 0; par < LeadExperianList.length; par++) {
            const LeadExperianDetail = LeadExperianList[par];
            var VDateofReportPulledOuttxtId = LeadExperianDetail.DateofReportPulledOuttxtId;
            var VExperianScoretxtId = LeadExperianDetail.ExperianScoretxtId;
            var VNameofBorrowertxtId = LeadExperianDetail.NameofBorrowertxtId;
            var VPANNotxtId = LeadExperianDetail.PANNotxtId;
            var VAdditionalKYCCIRtxtId = LeadExperianDetail.AdditionalKYCCIRtxtId;
            var VMemberIdtxtId = LeadExperianDetail.MemberIdtxtId;
            var VAccountTypetxtId = LeadExperianDetail.AccountTypetxtId;
            var VAccountNumbertxtId = LeadExperianDetail.AccountNumbertxtId;
            var VOwnershipIndicatortxtId = LeadExperianDetail.OwnershipIndicatortxtId;
            var VCreditLimittxtId = LeadExperianDetail.CreditLimittxtId;
            var VHighCredittxtId = LeadExperianDetail.HighCredittxtId;
            var VCurrentBalancetxtId = LeadExperianDetail.CurrentBalancetxtId;
            var VAmountOverduetxtId = LeadExperianDetail.AmountOverduetxtId;
            var VDPDsforLatestMonthtxtId = LeadExperianDetail.DPDsforLatestMonthtxtId;
            var VDateOpenedtxtId = LeadExperianDetail.DateOpenedtxtId;
            var VDateClosedtxtId = LeadExperianDetail.DateClosedtxtId;
            var VDateofLastPaymenttxtId = LeadExperianDetail.DateofLastPaymenttxtId;
            var VDateReportedtxtId = LeadExperianDetail.DateReportedtxtId;
            var VSuiteFiledtxtId = LeadExperianDetail.SuiteFiledtxtId;
            var VCreditFacilityStatustxtId = LeadExperianDetail.CreditFacilityStatustxtId;
            var VWrittenofAmounttxtId = LeadExperianDetail.WrittenofAmounttxtId;
            var VSettlementAmounttxtId = LeadExperianDetail.SettlementAmounttxtId;
            var VTotalNonReportingMonthtxtId = LeadExperianDetail.TotalNonReportingMonthtxtId;
            var VEnquirytxtId = LeadExperianDetail.EnquirytxtId;

            var VDateofReportPulledOut = document.getElementById("txtExperianDateofReportPulledOut").value == null ? '' : document.getElementById("txtExperianDateofReportPulledOut").value;
            var VExperianScore = document.getElementById("txtExperianScore").value == null ? '' : document.getElementById("txtExperianScore").value;
            var VNameofBorrower = document.getElementById("txtExperianNameofBorrower").value == null ? '' : document.getElementById("txtExperianNameofBorrower").value;
            var VPANNo = document.getElementById("txtExperianPANNo").value == null ? '' : document.getElementById("txtExperianPANNo").value;
            var VAdditionalKYCCIR = document.getElementById("txtExperianAdditionalKYCCIR").value == null ? '' : document.getElementById("txtExperianAdditionalKYCCIR").value;
            var VMemberId = document.getElementById(VMemberIdtxtId).value == null ? '0' : document.getElementById(VMemberIdtxtId).value = '' ? '0' : document.getElementById(VMemberIdtxtId).value;
            var VAccountTypeId = document.getElementById(VAccountTypetxtId).value == null ? '' : document.getElementById(VAccountTypetxtId).value = '' ? '' : document.getElementById(VAccountTypetxtId).value;
            var VAccountNumber = document.getElementById(VAccountNumbertxtId).value == null ? '' : document.getElementById(VAccountNumbertxtId).value = '' ? '' : document.getElementById(VAccountNumbertxtId).value;
            var VOwnershipIndicator = document.getElementById(VOwnershipIndicatortxtId).value == null ? '' : document.getElementById(VOwnershipIndicatortxtId).value = '' ? '' : document.getElementById(VOwnershipIndicatortxtId).value;
            var VCreditLimit = document.getElementById(VCreditLimittxtId).value == null ? '' : document.getElementById(VCreditLimittxtId).value = '' ? '' : document.getElementById(VCreditLimittxtId).value;
            var VHighCredit = document.getElementById(VHighCredittxtId).value == null ? '' : document.getElementById(VHighCredittxtId).value = '' ? '' : document.getElementById(VHighCredittxtId).value;
            var VCurrentBalance = document.getElementById(VCurrentBalancetxtId).value == null ? '' : document.getElementById(VCurrentBalancetxtId).value = '' ? '' : document.getElementById(VCurrentBalancetxtId).value;
            var VAmountOverdue = document.getElementById(VAmountOverduetxtId).value == null ? '' : document.getElementById(VAmountOverduetxtId).value = '' ? '' : document.getElementById(VAmountOverduetxtId).value;
            var VDPDsforLatestMonth = document.getElementById(VDPDsforLatestMonthtxtId).value == null ? '' : document.getElementById(VDPDsforLatestMonthtxtId).value = '' ? '' : document.getElementById(VDPDsforLatestMonthtxtId).value;
            var VDateOpened = document.getElementById(VDateOpenedtxtId).value == null ? '' : document.getElementById(VDateOpenedtxtId).value = '' ? '' : document.getElementById(VDateOpenedtxtId).value;
            var VDateClosed = document.getElementById(VDateClosedtxtId).value == null ? '' : document.getElementById(VDateClosedtxtId).value = '' ? '' : document.getElementById(VDateClosedtxtId).value;
            var VDateofLastPayment = document.getElementById(VDateofLastPaymenttxtId).value == null ? '' : document.getElementById(VDateofLastPaymenttxtId).value = '' ? '' : document.getElementById(VDateofLastPaymenttxtId).value;
            var VDateReported = document.getElementById(VDateReportedtxtId).value == null ? '' : document.getElementById(VDateReportedtxtId).value = '' ? '' : document.getElementById(VDateReportedtxtId).value;
            var VSuiteFiled = document.getElementById(VSuiteFiledtxtId).value == null ? '0' : document.getElementById(VSuiteFiledtxtId).value = '' ? '0' : document.getElementById(VSuiteFiledtxtId).value;
            var VCreditFacilityStatus = document.getElementById(VCreditFacilityStatustxtId).value == null ? '0' : document.getElementById(VCreditFacilityStatustxtId).value = '' ? '0' : document.getElementById(VCreditFacilityStatustxtId).value;
            var VWrittenofAmount = document.getElementById(VWrittenofAmounttxtId).value == null ? '' : document.getElementById(VWrittenofAmounttxtId).value = '' ? '' : document.getElementById(VWrittenofAmounttxtId).value;
            var VSettlementAmount = document.getElementById(VSettlementAmounttxtId).value == null ? '' : document.getElementById(VSettlementAmounttxtId).value = '' ? '' : document.getElementById(VSettlementAmounttxtId).value;
            var VEnquiry = document.getElementById("txtExperianEnquiry").value == null ? '' : document.getElementById("txtExperianEnquiry").value;
            var VTotalNonReportingMonth = document.getElementById(VTotalNonReportingMonthtxtId).value == null ? '' : document.getElementById(VTotalNonReportingMonthtxtId).value = '' ? '' : document.getElementById(VTotalNonReportingMonthtxtId).value;


            const LeadExperianItemArr =
            {
                LeadEnrollExperianId: LeadExperianDetail.LeadEnrollExperianId,
                LeadId: VLeadId,
                DateofReportPulledOut: VDateofReportPulledOut,
                CibilScore: VExperianScore,
                NameofBorrower: VNameofBorrower,
                PANNo: VPANNo,
                AdditionalKYCCIR: VAdditionalKYCCIR,
                MemberId: VMemberId,
                AccountTypeId: VAccountTypeId,
                AccountNumber: VAccountNumber,
                OwnershipIndicator: VOwnershipIndicator,
                CreditLimit: VCreditLimit,
                HighCredit: VHighCredit,
                CurrentBalance: VCurrentBalance,
                AmountOverdue: VAmountOverdue,
                DPDsforLatestMonth: VDPDsforLatestMonth,
                DateOpened: VDateOpened,
                DateClosed: VDateClosed,
                DateofLastPayment: VDateofLastPayment,
                DateReported: VDateReported,
                SuiteFiled: VSuiteFiled,
                CreditFacilityStatus: VCreditFacilityStatus,
                WrittenofAmount: VWrittenofAmount,
                SettlementAmount: VSettlementAmount,
                Enquiry: VEnquiry,
                TotalNonReportingMonth: VTotalNonReportingMonth,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            };
            axios.post(global.APIURL + '/LeadEnrollExperianSave', LeadExperianItemArr).then(res => { if (res.data.length > 0) { } });
        }

        if (CheckLeadExperianValid > 0) {
            var VDateofReportPulledOut = document.getElementById("txtExperianDateofReportPulledOut").value;
            var VExperianScore = document.getElementById("txtExperianScore").value;
            var VNameofBorrower = document.getElementById("txtExperianNameofBorrower").value;
            var VPANNo = document.getElementById("txtExperianPANNo").value;
            var VAdditionalKYCCIR = document.getElementById("txtExperianAdditionalKYCCIR").value;
            var VMemberId = document.getElementById("ddlExperianMember").value;
            var VAccountTypeId = document.getElementById("ddlExperianAccountType").value;
            var VAccountNumber = document.getElementById("txtExperianAccountNumber").value;
            var VOwnershipIndicator = document.getElementById("txtExperianOwnershipIndicator").value;
            var VCreditLimit = document.getElementById("txtExperianCreditLimit").value;
            var VHighCredit = document.getElementById("txtExperianHighCredit").value;
            var VCurrentBalance = document.getElementById("txtExperianCurrentBalance").value;
            var VAmountOverdue = document.getElementById("txtExperianAmountOverdue").value;
            var VDPDsforLatestMonth = document.getElementById("txtExperianDPDsforLatestMonth").value;
            var VDateOpened = document.getElementById("txtExperianDateOpened").value;
            var VDateClosed = document.getElementById("txtExperianDateClosed").value;
            var VDateofLastPayment = document.getElementById("txtExperianDateofLastPayment").value;
            var VDateReported = document.getElementById("txtExperianDateReported").value;
            var VSuiteFiled = document.getElementById("ddlExperianSuiteFiled").value;
            var VCreditFacilityStatus = document.getElementById("ddlExperianCreditFacilityStatus").value;
            var VWrittenofAmount = document.getElementById("txtExperianWrittenofAmount").value;
            var VSettlementAmount = document.getElementById("txtExperianSettlementAmount").value;
            var VEnquiry = document.getElementById("txtExperianEnquiry").value;
            var VTotalNonReportingMonth = document.getElementById("txtExperianTotalNonReportingMonth").value;

            const LeadExperianItemArr =
            {
                LeadEnrollExperianId: 0,
                LeadId: VLeadId,
                DateofReportPulledOut: VDateofReportPulledOut,
                CibilScore: VExperianScore,
                NameofBorrower: VNameofBorrower,
                PANNo: VPANNo,
                AdditionalKYCCIR: VAdditionalKYCCIR,
                MemberId: VMemberId,
                AccountTypeId: VAccountTypeId,
                AccountNumber: VAccountNumber,
                OwnershipIndicator: VOwnershipIndicator,
                CreditLimit: VCreditLimit,
                HighCredit: VHighCredit,
                CurrentBalance: VCurrentBalance,
                AmountOverdue: VAmountOverdue,
                DPDsforLatestMonth: VDPDsforLatestMonth,
                DateOpened: VDateOpened,
                DateClosed: VDateClosed,
                DateofLastPayment: VDateofLastPayment,
                DateReported: VDateReported,
                SuiteFiled: VSuiteFiled,
                CreditFacilityStatus: VCreditFacilityStatus,
                WrittenofAmount: VWrittenofAmount,
                SettlementAmount: VSettlementAmount,
                Enquiry: VEnquiry,
                TotalNonReportingMonth: VTotalNonReportingMonth,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            };
            axios.post(global.APIURL + '/LeadEnrollExperianSave', LeadExperianItemArr).then(res => { if (res.data.length > 0) { } });
        }
    }

    const SaveLeadCRIF = async () => {
        let VLeadId = localStorage.getItem("ConvertIndividualLeadId");
        var VchkSameCRIF = document.getElementById("chkSameCRIF").checked;
        if (VchkSameCRIF == true) {
            var VLId = parseInt(VLeadId == null ? '0' : VLeadId == '' ? '0' : VLeadId);
            if (VLId > 0) {
                const LeadItemArr = { Id: VLId }
                axios.post(global.APIURL + '/LeadCRIFCreditDetailDeleteByLeadId', LeadItemArr).then(resem => { });
            }
        }
        for (let par = 0; par < LeadCRIFList.length; par++) {
            const LeadCRIFDetail = LeadCRIFList[par];
            var VDateofReportPulledOuttxtId = LeadCRIFDetail.DateofReportPulledOuttxtId;
            var VCRIFScoretxtId = LeadCRIFDetail.CRIFScoretxtId;
            var VNameofBorrowertxtId = LeadCRIFDetail.NameofBorrowertxtId;
            var VPANNotxtId = LeadCRIFDetail.PANNotxtId;
            var VAdditionalKYCCIRtxtId = LeadCRIFDetail.AdditionalKYCCIRtxtId;
            var VMemberIdtxtId = LeadCRIFDetail.MemberIdtxtId;
            var VAccountTypetxtId = LeadCRIFDetail.AccountTypetxtId;
            var VAccountNumbertxtId = LeadCRIFDetail.AccountNumbertxtId;
            var VOwnershipIndicatortxtId = LeadCRIFDetail.OwnershipIndicatortxtId;
            var VCreditLimittxtId = LeadCRIFDetail.CreditLimittxtId;
            var VHighCredittxtId = LeadCRIFDetail.HighCredittxtId;
            var VCurrentBalancetxtId = LeadCRIFDetail.CurrentBalancetxtId;
            var VAmountOverduetxtId = LeadCRIFDetail.AmountOverduetxtId;
            var VDPDsforLatestMonthtxtId = LeadCRIFDetail.DPDsforLatestMonthtxtId;
            var VDateOpenedtxtId = LeadCRIFDetail.DateOpenedtxtId;
            var VDateClosedtxtId = LeadCRIFDetail.DateClosedtxtId;
            var VDateofLastPaymenttxtId = LeadCRIFDetail.DateofLastPaymenttxtId;
            var VDateReportedtxtId = LeadCRIFDetail.DateReportedtxtId;
            var VSuiteFiledtxtId = LeadCRIFDetail.SuiteFiledtxtId;
            var VCreditFacilityStatustxtId = LeadCRIFDetail.CreditFacilityStatustxtId;
            var VWrittenofAmounttxtId = LeadCRIFDetail.WrittenofAmounttxtId;
            var VSettlementAmounttxtId = LeadCRIFDetail.SettlementAmounttxtId;
            var VEnquirytxtId = LeadCRIFDetail.EnquirytxtId;
            var VTotalNonReportingMonthtxtId = LeadCRIFDetail.TotalNonReportingMonthtxtId;

            var VDateofReportPulledOut = document.getElementById("txtCRIFDateofReportPulledOut").value == null ? '' : document.getElementById("txtCRIFDateofReportPulledOut").value;
            var VCRIFScore = document.getElementById("txtCRIFScore").value == null ? '' : document.getElementById("txtCRIFScore").value;
            var VNameofBorrower = document.getElementById("txtCRIFNameofBorrower").value == null ? '' : document.getElementById("txtCRIFNameofBorrower").value;
            var VPANNo = document.getElementById("txtCRIFPANNo").value == null ? '' : document.getElementById("txtCRIFPANNo").value;
            var VAdditionalKYCCIR = document.getElementById("txtCRIFAdditionalKYCCIR").value == null ? '' : document.getElementById("txtCRIFAdditionalKYCCIR").value;
            var VMemberId = document.getElementById(VMemberIdtxtId).value == null ? '0' : document.getElementById(VMemberIdtxtId).value = '' ? '0' : document.getElementById(VMemberIdtxtId).value;
            var VAccountTypeId = document.getElementById(VAccountTypetxtId).value == null ? '' : document.getElementById(VAccountTypetxtId).value = '' ? '' : document.getElementById(VAccountTypetxtId).value;
            var VAccountNumber = document.getElementById(VAccountNumbertxtId).value == null ? '' : document.getElementById(VAccountNumbertxtId).value = '' ? '' : document.getElementById(VAccountNumbertxtId).value;
            var VOwnershipIndicator = document.getElementById(VOwnershipIndicatortxtId).value == null ? '' : document.getElementById(VOwnershipIndicatortxtId).value = '' ? '' : document.getElementById(VOwnershipIndicatortxtId).value;
            var VCreditLimit = document.getElementById(VCreditLimittxtId).value == null ? '' : document.getElementById(VCreditLimittxtId).value = '' ? '' : document.getElementById(VCreditLimittxtId).value;
            var VHighCredit = document.getElementById(VHighCredittxtId).value == null ? '' : document.getElementById(VHighCredittxtId).value = '' ? '' : document.getElementById(VHighCredittxtId).value;
            var VCurrentBalance = document.getElementById(VCurrentBalancetxtId).value == null ? '' : document.getElementById(VCurrentBalancetxtId).value = '' ? '' : document.getElementById(VCurrentBalancetxtId).value;
            var VAmountOverdue = document.getElementById(VAmountOverduetxtId).value == null ? '' : document.getElementById(VAmountOverduetxtId).value = '' ? '' : document.getElementById(VAmountOverduetxtId).value;
            var VDPDsforLatestMonth = document.getElementById(VDPDsforLatestMonthtxtId).value == null ? '' : document.getElementById(VDPDsforLatestMonthtxtId).value = '' ? '' : document.getElementById(VDPDsforLatestMonthtxtId).value;
            var VDateOpened = document.getElementById(VDateOpenedtxtId).value == null ? '' : document.getElementById(VDateOpenedtxtId).value = '' ? '' : document.getElementById(VDateOpenedtxtId).value;
            var VDateClosed = document.getElementById(VDateClosedtxtId).value == null ? '' : document.getElementById(VDateClosedtxtId).value = '' ? '' : document.getElementById(VDateClosedtxtId).value;
            var VDateofLastPayment = document.getElementById(VDateofLastPaymenttxtId).value == null ? '' : document.getElementById(VDateofLastPaymenttxtId).value = '' ? '' : document.getElementById(VDateofLastPaymenttxtId).value;
            var VDateReported = document.getElementById(VDateReportedtxtId).value == null ? '' : document.getElementById(VDateReportedtxtId).value = '' ? '' : document.getElementById(VDateReportedtxtId).value;
            var VSuiteFiled = document.getElementById(VSuiteFiledtxtId).value == null ? '0' : document.getElementById(VSuiteFiledtxtId).value = '' ? '0' : document.getElementById(VSuiteFiledtxtId).value;
            var VCreditFacilityStatus = document.getElementById(VCreditFacilityStatustxtId).value == null ? '0' : document.getElementById(VCreditFacilityStatustxtId).value = '' ? '0' : document.getElementById(VCreditFacilityStatustxtId).value;
            var VWrittenofAmount = document.getElementById(VWrittenofAmounttxtId).value == null ? '' : document.getElementById(VWrittenofAmounttxtId).value = '' ? '' : document.getElementById(VWrittenofAmounttxtId).value;
            var VSettlementAmount = document.getElementById(VSettlementAmounttxtId).value == null ? '' : document.getElementById(VSettlementAmounttxtId).value = '' ? '' : document.getElementById(VSettlementAmounttxtId).value;
            var VEnquiry = document.getElementById("txtCRIFEnquiry").value == null ? '' : document.getElementById("txtCRIFEnquiry").value;
            var VTotalNonReportingMonth = document.getElementById(VTotalNonReportingMonthtxtId).value == null ? '' : document.getElementById(VTotalNonReportingMonthtxtId).value = '' ? '' : document.getElementById(VTotalNonReportingMonthtxtId).value;


            const LeadCRIFItemArr =
            {
                LeadEnrollCRIFId: LeadCRIFDetail.LeadEnrollCRIFId,
                LeadId: VLeadId,
                DateofReportPulledOut: VDateofReportPulledOut,
                CibilScore: VCRIFScore,
                NameofBorrower: VNameofBorrower,
                PANNo: VPANNo,
                AdditionalKYCCIR: VAdditionalKYCCIR,
                MemberId: VMemberId,
                AccountTypeId: VAccountTypeId,
                AccountNumber: VAccountNumber,
                OwnershipIndicator: VOwnershipIndicator,
                CreditLimit: VCreditLimit,
                HighCredit: VHighCredit,
                CurrentBalance: VCurrentBalance,
                AmountOverdue: VAmountOverdue,
                DPDsforLatestMonth: VDPDsforLatestMonth,
                DateOpened: VDateOpened,
                DateClosed: VDateClosed,
                DateofLastPayment: VDateofLastPayment,
                DateReported: VDateReported,
                SuiteFiled: VSuiteFiled,
                CreditFacilityStatus: VCreditFacilityStatus,
                WrittenofAmount: VWrittenofAmount,
                SettlementAmount: VSettlementAmount,
                Enquiry: VEnquiry,
                TotalNonReportingMonth: VTotalNonReportingMonth,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            };
            axios.post(global.APIURL + '/LeadEnrollCRIFSave', LeadCRIFItemArr).then(res => { if (res.data.length > 0) { } });
        }

        if (CheckLeadCRIFValid > 0) {
            var VDateofReportPulledOut = document.getElementById("txtCRIFDateofReportPulledOut").value;
            var VCRIFScore = document.getElementById("txtCRIFScore").value;
            var VNameofBorrower = document.getElementById("txtCRIFNameofBorrower").value;
            var VPANNo = document.getElementById("txtCRIFPANNo").value;
            var VAdditionalKYCCIR = document.getElementById("txtCRIFAdditionalKYCCIR").value;
            var VMemberId = document.getElementById("ddlCRIFMember").value;
            var VAccountTypeId = document.getElementById("ddlCRIFAccountType").value;
            var VAccountNumber = document.getElementById("txtCRIFAccountNumber").value;
            var VOwnershipIndicator = document.getElementById("txtCRIFOwnershipIndicator").value;
            var VCreditLimit = document.getElementById("txtCRIFCreditLimit").value;
            var VHighCredit = document.getElementById("txtCRIFHighCredit").value;
            var VCurrentBalance = document.getElementById("txtCRIFCurrentBalance").value;
            var VAmountOverdue = document.getElementById("txtCRIFAmountOverdue").value;
            var VDPDsforLatestMonth = document.getElementById("txtCRIFDPDsforLatestMonth").value;
            var VDateOpened = document.getElementById("txtCRIFDateOpened").value;
            var VDateClosed = document.getElementById("txtCRIFDateClosed").value;
            var VDateofLastPayment = document.getElementById("txtCRIFDateofLastPayment").value;
            var VDateReported = document.getElementById("txtCRIFDateReported").value;
            var VSuiteFiled = document.getElementById("ddlCRIFSuiteFiled").value;
            var VCreditFacilityStatus = document.getElementById("ddlCRIFCreditFacilityStatus").value;
            var VWrittenofAmount = document.getElementById("txtCRIFWrittenofAmount").value;
            var VSettlementAmount = document.getElementById("txtCRIFSettlementAmount").value;
            var VEnquiry = LeadCRIFList.length == 0 ? document.getElementById("txtCRIFEnquiry").value : '';
            var VTotalNonReportingMonth = document.getElementById("txtCRIFTotalNonReportingMonth").value;

            const LeadCRIFItemArr =
            {
                LeadEnrollCRIFId: 0,
                LeadId: VLeadId,
                DateofReportPulledOut: VDateofReportPulledOut,
                CibilScore: VCRIFScore,
                NameofBorrower: VNameofBorrower,
                PANNo: VPANNo,
                AdditionalKYCCIR: VAdditionalKYCCIR,
                MemberId: VMemberId,
                AccountTypeId: VAccountTypeId,
                AccountNumber: VAccountNumber,
                OwnershipIndicator: VOwnershipIndicator,
                CreditLimit: VCreditLimit,
                HighCredit: VHighCredit,
                CurrentBalance: VCurrentBalance,
                AmountOverdue: VAmountOverdue,
                DPDsforLatestMonth: VDPDsforLatestMonth,
                DateOpened: VDateOpened,
                DateClosed: VDateClosed,
                DateofLastPayment: VDateofLastPayment,
                DateReported: VDateReported,
                SuiteFiled: VSuiteFiled,
                CreditFacilityStatus: VCreditFacilityStatus,
                WrittenofAmount: VWrittenofAmount,
                SettlementAmount: VSettlementAmount,
                Enquiry: VEnquiry,
                TotalNonReportingMonth: VTotalNonReportingMonth,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            };
            axios.post(global.APIURL + '/LeadEnrollCRIFSave', LeadCRIFItemArr).then(res => { if (res.data.length > 0) { } });
        }
    }

    const SameEqifaxhandleChange = () => {
        var VchkSameEqifax = document.getElementById("chkSameEqifax").checked;
        if (VchkSameEqifax == true) {
            var tempLeadCIBILList = [];
            LeadEqifaxList.length = 0;
            if (LeadCIBILList.length > 0) {
                for (let par = 0; par < LeadCIBILList.length; par++) {
                    const LeadCIBILDetail = LeadCIBILList[par];
                    var LRNo = par + 1;

                    if (par == 0) {
                        const Pitem = {
                            RowId: LRNo,
                            LeadEnrollEqifaxId: 0,
                            DateofReportPulledOut: document.getElementById("txtCIBILDateofReportPulledOut").value,
                            EqifaxScore: document.getElementById("txtCIBILScore").value,
                            NameofBorrower: document.getElementById("txtCIBILNameofBorrower").value,
                            PANNo: document.getElementById("txtCIBILPANNo").value,
                            AdditionalKYCCIR: document.getElementById("txtCIBILAdditionalKYCCIR").value,
                            MemberId: document.getElementById(LeadCIBILDetail.MemberIdtxtId).value,
                            AccountTypeId: document.getElementById(LeadCIBILDetail.AccountTypetxtId).value,
                            AccountNumber: document.getElementById(LeadCIBILDetail.AccountNumbertxtId).value,
                            OwnershipIndicator: document.getElementById(LeadCIBILDetail.OwnershipIndicatortxtId).value,
                            CreditLimit: document.getElementById(LeadCIBILDetail.CreditLimittxtId).value,
                            HighCredit: document.getElementById(LeadCIBILDetail.HighCredittxtId).value,
                            CurrentBalance: document.getElementById(LeadCIBILDetail.CurrentBalancetxtId).value,
                            AmountOverdue: document.getElementById(LeadCIBILDetail.AmountOverduetxtId).value,
                            DPDsforLatestMonth: document.getElementById(LeadCIBILDetail.DPDsforLatestMonthtxtId).value,
                            DateOpened: document.getElementById(LeadCIBILDetail.DateOpenedtxtId).value,
                            DateClosed: document.getElementById(LeadCIBILDetail.DateClosedtxtId).value,
                            DateofLastPayment: document.getElementById(LeadCIBILDetail.DateofLastPaymenttxtId).value,
                            DateReported: document.getElementById(LeadCIBILDetail.DateReportedtxtId).value,
                            SuiteFiled: document.getElementById(LeadCIBILDetail.SuiteFiledtxtId).value,
                            CreditFacilityStatus: document.getElementById(LeadCIBILDetail.CreditFacilityStatustxtId).value,
                            WrittenofAmount: document.getElementById(LeadCIBILDetail.WrittenofAmounttxtId).value,
                            SettlementAmount: document.getElementById(LeadCIBILDetail.SettlementAmounttxtId).value,
                            Enquiry: document.getElementById("txtCIBILEnquiry").value,
                            TotalNonReportingMonth: document.getElementById(LeadCIBILDetail.TotalNonReportingMonthtxtId).value,
                            DateofReportPulledOuttxtId: 'txtEqifaxDateofReportPulledOut',
                            EqifaxScoretxtId: 'txtEqifaxScore',
                            NameofBorrowertxtId: 'txtEqifaxNameofBorrower',
                            PANNotxtId: 'txtEqifaxPANNo',
                            AdditionalKYCCIRtxtId: 'txtEqifaxAdditionalKYCCIR',
                            MemberIdtxtId: 'ddlEqifaxMember' + LRNo,
                            AccountTypetxtId: 'ddlEqifaxAccountType' + LRNo,
                            AccountNumbertxtId: 'txtEqifaxAccountNumber' + LRNo,
                            OwnershipIndicatortxtId: 'txtEqifaxOwnershipIndicator' + LRNo,
                            CreditLimittxtId: 'txtEqifaxCreditLimit' + LRNo,
                            HighCredittxtId: 'txtEqifaxHighCredit' + LRNo,
                            CurrentBalancetxtId: 'txtEqifaxCurrentBalance' + LRNo,
                            AmountOverduetxtId: 'txtEqifaxAmountOverdue' + LRNo,
                            DPDsforLatestMonthtxtId: 'txtEqifaxDPDsforLatestMonth' + LRNo,
                            DateOpenedtxtId: 'txtEqifaxDateOpened' + LRNo,
                            DateClosedtxtId: 'txtEqifaxDateClosed' + LRNo,
                            DateofLastPaymenttxtId: 'txtEqifaxDateofLastPayment' + LRNo,
                            DateReportedtxtId: 'txtEqifaxDateReported' + LRNo,
                            SuiteFiledtxtId: 'ddlEqifaxSuiteFiled' + LRNo,
                            CreditFacilityStatustxtId: 'ddlEqifaxCreditFacilityStatus' + LRNo,
                            WrittenofAmounttxtId: 'txtEqifaxWrittenofAmount' + LRNo,
                            SettlementAmounttxtId: 'txtEqifaxSettlementAmount' + LRNo,
                            EnquirytxtId: 'txtEqifaxEnquiry',
                            TotalNonReportingMonthtxtId: 'txtEqifaxTotalNonReportingMonth' + LRNo
                        };
                        tempLeadCIBILList.push(Pitem);
                    }
                    else {
                        const Pitem = {
                            RowId: LRNo,
                            LeadEnrollEqifaxId: 0,
                            DateofReportPulledOut: document.getElementById("txtCIBILDateofReportPulledOut").value,
                            EqifaxScore: document.getElementById("txtCIBILScore").value,
                            NameofBorrower: document.getElementById("txtCIBILNameofBorrower").value,
                            PANNo: document.getElementById("txtCIBILPANNo").value,
                            AdditionalKYCCIR: document.getElementById("txtCIBILAdditionalKYCCIR").value,
                            MemberId: document.getElementById(LeadCIBILDetail.MemberIdtxtId).value,
                            AccountTypeId: document.getElementById(LeadCIBILDetail.AccountTypetxtId).value,
                            AccountNumber: document.getElementById(LeadCIBILDetail.AccountNumbertxtId).value,
                            OwnershipIndicator: document.getElementById(LeadCIBILDetail.OwnershipIndicatortxtId).value,
                            CreditLimit: document.getElementById(LeadCIBILDetail.CreditLimittxtId).value,
                            HighCredit: document.getElementById(LeadCIBILDetail.HighCredittxtId).value,
                            CurrentBalance: document.getElementById(LeadCIBILDetail.CurrentBalancetxtId).value,
                            AmountOverdue: document.getElementById(LeadCIBILDetail.AmountOverduetxtId).value,
                            DPDsforLatestMonth: document.getElementById(LeadCIBILDetail.DPDsforLatestMonthtxtId).value,
                            DateOpened: document.getElementById(LeadCIBILDetail.DateOpenedtxtId).value,
                            DateClosed: document.getElementById(LeadCIBILDetail.DateClosedtxtId).value,
                            DateofLastPayment: document.getElementById(LeadCIBILDetail.DateofLastPaymenttxtId).value,
                            DateReported: document.getElementById(LeadCIBILDetail.DateReportedtxtId).value,
                            SuiteFiled: document.getElementById(LeadCIBILDetail.SuiteFiledtxtId).value,
                            CreditFacilityStatus: document.getElementById(LeadCIBILDetail.CreditFacilityStatustxtId).value,
                            WrittenofAmount: document.getElementById(LeadCIBILDetail.WrittenofAmounttxtId).value,
                            SettlementAmount: document.getElementById(LeadCIBILDetail.SettlementAmounttxtId).value,
                            Enquiry: document.getElementById("txtCIBILEnquiry").value,
                            TotalNonReportingMonth: document.getElementById(LeadCIBILDetail.TotalNonReportingMonthtxtId).value,
                            DateofReportPulledOuttxtId: 'txtEqifaxDateofReportPulledOut' + LRNo,
                            EqifaxScoretxtId: 'txtEqifaxScore' + LRNo,
                            NameofBorrowertxtId: 'txtEqifaxNameofBorrower' + LRNo,
                            PANNotxtId: 'txtEqifaxPANNo' + LRNo,
                            AdditionalKYCCIRtxtId: 'txtEqifaxAdditionalKYCCIR' + LRNo,
                            MemberIdtxtId: 'ddlEqifaxMember' + LRNo,
                            AccountTypetxtId: 'ddlEqifaxAccountType' + LRNo,
                            AccountNumbertxtId: 'txtEqifaxAccountNumber' + LRNo,
                            OwnershipIndicatortxtId: 'txtEqifaxOwnershipIndicator' + LRNo,
                            CreditLimittxtId: 'txtEqifaxCreditLimit' + LRNo,
                            HighCredittxtId: 'txtEqifaxHighCredit' + LRNo,
                            CurrentBalancetxtId: 'txtEqifaxCurrentBalance' + LRNo,
                            AmountOverduetxtId: 'txtEqifaxAmountOverdue' + LRNo,
                            DPDsforLatestMonthtxtId: 'txtEqifaxDPDsforLatestMonth' + LRNo,
                            DateOpenedtxtId: 'txtEqifaxDateOpened' + LRNo,
                            DateClosedtxtId: 'txtEqifaxDateClosed' + LRNo,
                            DateofLastPaymenttxtId: 'txtEqifaxDateofLastPayment' + LRNo,
                            DateReportedtxtId: 'txtEqifaxDateReported' + LRNo,
                            SuiteFiledtxtId: 'ddlEqifaxSuiteFiled' + LRNo,
                            CreditFacilityStatustxtId: 'ddlEqifaxCreditFacilityStatus' + LRNo,
                            WrittenofAmounttxtId: 'txtEqifaxWrittenofAmount' + LRNo,
                            SettlementAmounttxtId: 'txtEqifaxSettlementAmount' + LRNo,
                            EnquirytxtId: 'txtEqifaxEnquiry' + LRNo,
                            TotalNonReportingMonthtxtId: 'txtEqifaxTotalNonReportingMonth' + LRNo
                        };
                        tempLeadCIBILList.push(Pitem);
                    }

                    if (LeadEqifaxList.length > 0) {
                        var ArrCount = par + 1;
                        var VCDateofReportPulledOuttxtId = "txtCIBILDateofReportPulledOut";
                        var VCCibilScoretxtId = "txtCIBILScore";
                        var VCNameofBorrowertxtId = "txtCIBILNameofBorrower";
                        var VCPANNotxtId = "txtCIBILPANNo";
                        var VCAdditionalKYCCIRtxtId = "txtCIBILAdditionalKYCCIR";
                        var VCEnquirytxtId = "txtCIBILEnquiry";
                        var VCMemberIdtxtId = LeadCIBILDetail.MemberIdtxtId;
                        var VCAccountTypetxtId = LeadCIBILDetail.AccountTypetxtId;
                        var VCAccountNumbertxtId = LeadCIBILDetail.AccountNumbertxtId;
                        var VCOwnershipIndicatortxtId = LeadCIBILDetail.OwnershipIndicatortxtId;
                        var VCCreditLimittxtId = LeadCIBILDetail.CreditLimittxtId;
                        var VCHighCredittxtId = LeadCIBILDetail.HighCredittxtId;
                        var VCCurrentBalancetxtId = LeadCIBILDetail.CurrentBalancetxtId;
                        var VCAmountOverduetxtId = LeadCIBILDetail.AmountOverduetxtId;
                        var VCDPDsforLatestMonthtxtId = LeadCIBILDetail.DPDsforLatestMonthtxtId;
                        var VCDateOpenedtxtId = LeadCIBILDetail.DateOpenedtxtId;
                        var VCDateClosedtxtId = LeadCIBILDetail.DateClosedtxtId;
                        var VCDateofLastPaymenttxtId = LeadCIBILDetail.DateofLastPaymenttxtId;
                        var VCDateReportedtxtId = LeadCIBILDetail.DateReportedtxtId;
                        var VCSuiteFiledtxtId = LeadCIBILDetail.SuiteFiledtxtId;
                        var VCCreditFacilityStatustxtId = LeadCIBILDetail.CreditFacilityStatustxtId;
                        var VCWrittenofAmounttxtId = LeadCIBILDetail.WrittenofAmounttxtId;
                        var VCSettlementAmounttxtId = LeadCIBILDetail.SettlementAmounttxtId;
                        var VCTotalNonReportingMonthtxtId = LeadCIBILDetail.TotalNonReportingMonthtxtId;

                        var VDateofReportPulledOuttxtId = 'txtEqifaxDateofReportPulledOut';
                        var VEqifaxScoretxtId = 'txtEqifaxScore';
                        var VNameofBorrowertxtId = 'txtEqifaxNameofBorrower';
                        var VPANNotxtId = 'txtEqifaxPANNo';
                        var VAdditionalKYCCIRtxtId = 'txtEqifaxAdditionalKYCCIR';
                        var VEnquirytxtId = 'txtEqifaxEnquiry';
                        var VMemberIdtxtId = 'ddlEqifaxMember' + ArrCount;
                        var VAccountTypetxtId = 'ddlEqifaxAccountType' + ArrCount;
                        var VAccountNumbertxtId = 'txtEqifaxAccountNumber' + ArrCount;
                        var VOwnershipIndicatortxtId = 'txtEqifaxOwnershipIndicator' + ArrCount;
                        var VCreditLimittxtId = 'txtEqifaxCreditLimit' + ArrCount;
                        var VHighCredittxtId = 'txtEqifaxHighCredit' + ArrCount;
                        var VCurrentBalancetxtId = 'txtEqifaxCurrentBalance' + ArrCount;
                        var VAmountOverduetxtId = 'txtEqifaxAmountOverdue' + ArrCount;
                        var VDPDsforLatestMonthtxtId = 'txtEqifaxDPDsforLatestMonth' + ArrCount;
                        var VDateOpenedtxtId = 'txtEqifaxDateOpened' + ArrCount;
                        var VDateClosedtxtId = 'txtEqifaxDateClosed' + ArrCount;
                        var VDateofLastPaymenttxtId = 'txtEqifaxDateofLastPayment' + ArrCount;
                        var VDateReportedtxtId = 'txtEqifaxDateReported' + ArrCount;
                        var VSuiteFiledtxtId = 'ddlEqifaxSuiteFiled' + ArrCount;
                        var VCreditFacilityStatustxtId = 'ddlEqifaxCreditFacilityStatus' + ArrCount;
                        var VWrittenofAmounttxtId = 'txtEqifaxWrittenofAmount' + ArrCount;
                        var VSettlementAmounttxtId = 'txtEqifaxSettlementAmount' + ArrCount;
                        var VTotalNonReportingMonthtxtId = 'txtEqifaxTotalNonReportingMonth' + ArrCount;

                        document.getElementById(VDateofReportPulledOuttxtId).value = document.getElementById(VCDateofReportPulledOuttxtId).value;
                        document.getElementById(VEqifaxScoretxtId).value = document.getElementById(VCCibilScoretxtId).value;
                        document.getElementById(VNameofBorrowertxtId).value = document.getElementById(VCNameofBorrowertxtId).value;
                        document.getElementById(VPANNotxtId).value = document.getElementById(VCPANNotxtId).value;
                        document.getElementById(VAdditionalKYCCIRtxtId).value = document.getElementById(VCAdditionalKYCCIRtxtId).value;
                        document.getElementById(VEnquirytxtId).value = document.getElementById(VCEnquirytxtId).value;
                        document.getElementById(VMemberIdtxtId).value = document.getElementById(VCMemberIdtxtId).value;
                        document.getElementById(VAccountTypetxtId).value = document.getElementById(VCAccountTypetxtId).value;
                        document.getElementById(VAccountNumbertxtId).value = document.getElementById(VCAccountNumbertxtId).value;
                        document.getElementById(VOwnershipIndicatortxtId).value = document.getElementById(VCOwnershipIndicatortxtId).value;
                        document.getElementById(VCreditLimittxtId).value = document.getElementById(VCCreditLimittxtId).value;
                        document.getElementById(VHighCredittxtId).value = document.getElementById(VCHighCredittxtId).value;
                        document.getElementById(VCurrentBalancetxtId).value = document.getElementById(VCCurrentBalancetxtId).value;
                        document.getElementById(VAmountOverduetxtId).value = document.getElementById(VCAmountOverduetxtId).value;
                        document.getElementById(VDPDsforLatestMonthtxtId).value = document.getElementById(VCDPDsforLatestMonthtxtId).value;
                        document.getElementById(VDateOpenedtxtId).value = document.getElementById(VCDateOpenedtxtId).value;
                        document.getElementById(VDateClosedtxtId).value = document.getElementById(VCDateClosedtxtId).value;
                        document.getElementById(VDateofLastPaymenttxtId).value = document.getElementById(VCDateofLastPaymenttxtId).value;
                        document.getElementById(VDateReportedtxtId).value = document.getElementById(VCDateReportedtxtId).value;
                        document.getElementById(VSuiteFiledtxtId).value = document.getElementById(VCSuiteFiledtxtId).value;
                        document.getElementById(VCreditFacilityStatustxtId).value = document.getElementById(VCCreditFacilityStatustxtId).value;
                        document.getElementById(VWrittenofAmounttxtId).value = document.getElementById(VCWrittenofAmounttxtId).value;
                        document.getElementById(VSettlementAmounttxtId).value = document.getElementById(VCSettlementAmounttxtId).value;
                        document.getElementById(VTotalNonReportingMonthtxtId).value = document.getElementById(VCTotalNonReportingMonthtxtId).value;
                    }
                }
            }

            LeadEqifaxList.length = 0;

            setLeadEqifaxList(tempLeadCIBILList);

            document.getElementById("txtEqifaxDateofReportPulledOut").value = document.getElementById('txtCIBILDateofReportPulledOut').value;
            document.getElementById("txtEqifaxScore").value = document.getElementById('txtCIBILScore').value;
            document.getElementById("txtEqifaxNameofBorrower").value = document.getElementById('txtCIBILNameofBorrower').value;
            document.getElementById("txtEqifaxPANNo").value = document.getElementById('txtCIBILPANNo').value;
            document.getElementById("txtEqifaxAdditionalKYCCIR").value = document.getElementById('txtCIBILAdditionalKYCCIR').value;
            document.getElementById("txtEqifaxEnquiry").value = document.getElementById('txtCIBILEnquiry').value;

            var VMemberId = document.getElementById('ddlCIBILMember').value == null ? '0' : document.getElementById('ddlCIBILMember').value = '' ? '0' : document.getElementById('ddlCIBILMember').value;
            var VAccountTypeId = document.getElementById('ddlCIBILAccountType').value == null ? '' : document.getElementById('ddlCIBILAccountType').value = '' ? '' : document.getElementById('ddlCIBILAccountType').value;
            var VAccountNumber = document.getElementById('txtCIBILAccountNumber').value == null ? '' : document.getElementById('txtCIBILAccountNumber').value = '' ? '' : document.getElementById('txtCIBILAccountNumber').value;
            var VOwnershipIndicator = document.getElementById('txtCIBILOwnershipIndicator').value == null ? '' : document.getElementById('txtCIBILOwnershipIndicator').value = '' ? '' : document.getElementById('txtCIBILOwnershipIndicator').value;
            var VCreditLimit = document.getElementById('txtCIBILCreditLimit').value == null ? '' : document.getElementById('txtCIBILCreditLimit').value = '' ? '' : document.getElementById('txtCIBILCreditLimit').value;
            var VHighCredit = document.getElementById('txtCIBILHighCredit').value == null ? '' : document.getElementById('txtCIBILHighCredit').value = '' ? '' : document.getElementById('txtCIBILHighCredit').value;
            var VCurrentBalance = document.getElementById('txtCIBILCurrentBalance').value == null ? '' : document.getElementById('txtCIBILCurrentBalance').value = '' ? '' : document.getElementById('txtCIBILCurrentBalance').value;
            var VAmountOverdue = document.getElementById('txtCIBILAmountOverdue').value == null ? '' : document.getElementById('txtCIBILAmountOverdue').value = '' ? '' : document.getElementById('txtCIBILAmountOverdue').value;
            var VDPDsforLatestMonth = document.getElementById('txtCIBILDPDsforLatestMonth').value == null ? '' : document.getElementById('txtCIBILDPDsforLatestMonth').value = '' ? '' : document.getElementById('txtCIBILDPDsforLatestMonth').value;
            var VDateOpened = document.getElementById('txtCIBILDateOpened').value == null ? '' : document.getElementById('txtCIBILDateOpened').value = '' ? '' : document.getElementById('txtCIBILDateOpened').value;
            var VDateClosed = document.getElementById('txtCIBILDateClosed').value == null ? '' : document.getElementById('txtCIBILDateClosed').value = '' ? '' : document.getElementById('txtCIBILDateClosed').value;
            var VDateofLastPayment = document.getElementById('txtCIBILDateofLastPayment').value == null ? '' : document.getElementById('txtCIBILDateofLastPayment').value = '' ? '' : document.getElementById('txtCIBILDateofLastPayment').value;
            var VDateReported = document.getElementById('txtCIBILDateReported').value == null ? '' : document.getElementById('txtCIBILDateReported').value = '' ? '' : document.getElementById('txtCIBILDateReported').value;
            var VSuiteFiled = document.getElementById('ddlCIBILSuiteFiled').value == null ? '0' : document.getElementById('ddlCIBILSuiteFiled').value = '' ? '0' : document.getElementById('ddlCIBILSuiteFiled').value;
            var VCreditFacilityStatus = document.getElementById('ddlCIBILCreditFacilityStatus').value == null ? '0' : document.getElementById('ddlCIBILCreditFacilityStatus').value = '' ? '0' : document.getElementById('ddlCIBILCreditFacilityStatus').value;
            var VWrittenofAmount = document.getElementById('txtCIBILWrittenofAmount').value == null ? '' : document.getElementById('txtCIBILWrittenofAmount').value = '' ? '' : document.getElementById('txtCIBILWrittenofAmount').value;
            var VSettlementAmount = document.getElementById('txtCIBILSettlementAmount').value == null ? '' : document.getElementById('txtCIBILSettlementAmount').value = '' ? '' : document.getElementById('txtCIBILSettlementAmount').value;
            var VTotalNonReportingMonth = document.getElementById('txtCIBILTotalNonReportingMonth').value == null ? '' : document.getElementById('txtCIBILTotalNonReportingMonth').value = '' ? '' : document.getElementById('txtCIBILTotalNonReportingMonth').value;

            document.getElementById("ddlEqifaxMember").value = VMemberId;
            document.getElementById("ddlEqifaxAccountType").value = VAccountTypeId;
            document.getElementById("txtEqifaxAccountNumber").value = VAccountNumber;
            document.getElementById("txtEqifaxOwnershipIndicator").value = VOwnershipIndicator;
            document.getElementById("txtEqifaxCreditLimit").value = VCreditLimit;
            document.getElementById("txtEqifaxHighCredit").value = VHighCredit;
            document.getElementById("txtEqifaxCurrentBalance").value = VCurrentBalance;
            document.getElementById("txtEqifaxAmountOverdue").value = VAmountOverdue;
            document.getElementById("txtEqifaxDPDsforLatestMonth").value = VDPDsforLatestMonth;
            document.getElementById("txtEqifaxDateOpened").value = VDateOpened;
            document.getElementById("txtEqifaxDateClosed").value = VDateClosed;
            document.getElementById("txtEqifaxDateofLastPayment").value = VDateofLastPayment;
            document.getElementById("txtEqifaxDateReported").value = VDateReported;
            document.getElementById("ddlEqifaxSuiteFiled").value = VSuiteFiled;
            document.getElementById("ddlEqifaxCreditFacilityStatus").value = VCreditFacilityStatus;
            document.getElementById("txtEqifaxWrittenofAmount").value = VWrittenofAmount;
            document.getElementById("txtEqifaxSettlementAmount").value = VSettlementAmount;
            document.getElementById("txtEqifaxTotalNonReportingMonth").value = VTotalNonReportingMonth;
        }
        else {
            setLeadEqifaxList([]);
        }
    };

    const SameExperianhandleChange = () => {
        var VchkSameExperian = document.getElementById("chkSameExperian").checked;
        if (VchkSameExperian == true) {
            LeadExperianList.length = 0;
            var tempLeadCIBILList = [];
            if (LeadCIBILList.length > 0) {
                for (let par = 0; par < LeadCIBILList.length; par++) {
                    const LeadCIBILDetail = LeadCIBILList[par];
                    var LRNo = par + 1;
                    const Pitem = {
                        RowId: LeadCIBILDetail.RowId,
                        LeadEnrollExperianId: 0,
                        DateofReportPulledOut: document.getElementById("txtCIBILDateofReportPulledOut").value,
                        ExperianScore: document.getElementById("txtCIBILScore").value,
                        NameofBorrower: document.getElementById("txtCIBILNameofBorrower").value,
                        PANNo:document.getElementById("txtCIBILPANNo").value,
                        AdditionalKYCCIR: document.getElementById("txtCIBILAdditionalKYCCIR").value,
                        MemberId: document.getElementById(LeadCIBILDetail.MemberIdtxtId).value,
                        AccountTypeId: document.getElementById(LeadCIBILDetail.AccountTypetxtId).value,
                        AccountNumber: document.getElementById(LeadCIBILDetail.AccountNumbertxtId).value,
                        OwnershipIndicator: document.getElementById(LeadCIBILDetail.OwnershipIndicatortxtId).value,
                        CreditLimit: document.getElementById(LeadCIBILDetail.CreditLimittxtId).value,
                        HighCredit: document.getElementById(LeadCIBILDetail.HighCredittxtId).value,
                        CurrentBalance: document.getElementById(LeadCIBILDetail.CurrentBalancetxtId).value,
                        AmountOverdue: document.getElementById(LeadCIBILDetail.AmountOverduetxtId).value,
                        DPDsforLatestMonth: document.getElementById(LeadCIBILDetail.DPDsforLatestMonthtxtId).value,
                        DateOpened: document.getElementById(LeadCIBILDetail.DateOpenedtxtId).value,
                        DateClosed: document.getElementById(LeadCIBILDetail.DateClosedtxtId).value,
                        DateofLastPayment: document.getElementById(LeadCIBILDetail.DateofLastPaymenttxtId).value,
                        DateReported: document.getElementById(LeadCIBILDetail.DateReportedtxtId).value,
                        SuiteFiled: document.getElementById(LeadCIBILDetail.SuiteFiledtxtId).value,
                        CreditFacilityStatus: document.getElementById(LeadCIBILDetail.CreditFacilityStatustxtId).value,
                        WrittenofAmount: document.getElementById(LeadCIBILDetail.WrittenofAmounttxtId).value,
                        SettlementAmount: document.getElementById(LeadCIBILDetail.SettlementAmounttxtId).value,
                        Enquiry: document.getElementById("txtCIBILEnquiry").value,
                        TotalNonReportingMonth: document.getElementById(LeadCIBILDetail.TotalNonReportingMonthtxtId).value,
                        DateofReportPulledOuttxtId: 'txtExperianDateofReportPulledOut',
                        ExperianScoretxtId: 'txtExperianScore',
                        NameofBorrowertxtId: 'txtExperianNameofBorrower',
                        PANNotxtId: 'txtExperianPANNo',
                        AdditionalKYCCIRtxtId: 'txtExperianAdditionalKYCCIR',
                        MemberIdtxtId: 'ddlExperianMember' + LRNo,
                        AccountTypetxtId: 'ddlExperianAccountType' + LRNo,
                        AccountNumbertxtId: 'txtExperianAccountNumber' + LRNo,
                        OwnershipIndicatortxtId: 'txtExperianOwnershipIndicator' + LRNo,
                        CreditLimittxtId: 'txtExperianCreditLimit' + LRNo,
                        HighCredittxtId: 'txtExperianHighCredit' + LRNo,
                        CurrentBalancetxtId: 'txtExperianCurrentBalance' + LRNo,
                        AmountOverduetxtId: 'txtExperianAmountOverdue' + LRNo,
                        DPDsforLatestMonthtxtId: 'txtExperianDPDsforLatestMonth' + LRNo,
                        DateOpenedtxtId: 'txtExperianDateOpened' + LRNo,
                        DateClosedtxtId: 'txtExperianDateClosed' + LRNo,
                        DateofLastPaymenttxtId: 'txtExperianDateofLastPayment' + LRNo,
                        DateReportedtxtId: 'txtExperianDateReported' + LRNo,
                        SuiteFiledtxtId: 'ddlExperianSuiteFiled' + LRNo,
                        CreditFacilityStatustxtId: 'ddlExperianCreditFacilityStatus' + LRNo,
                        WrittenofAmounttxtId: 'txtExperianWrittenofAmount' + LRNo,
                        SettlementAmounttxtId: 'txtExperianSettlementAmount' + LRNo,
                        EnquirytxtId: 'txtExperianEnquiry',
                        TotalNonReportingMonthtxtId: 'txtExperianTotalNonReportingMonth' + LRNo
                    };

                    if (LeadExperianList.length > 0) {
                        var ArrCount = par + 1;
                        var VCDateofReportPulledOuttxtId = "txtCIBILDateofReportPulledOut";
                        var VCCibilScoretxtId = "txtCIBILScore";
                        var VCNameofBorrowertxtId = "txtCIBILNameofBorrower";
                        var VCPANNotxtId = "txtCIBILPANNo";
                        var VCAdditionalKYCCIRtxtId = "txtCIBILAdditionalKYCCIR";
                        var VCEnquirytxtId = "txtCIBILEnquiry";
                        var VCMemberIdtxtId = LeadCIBILDetail.MemberIdtxtId;
                        var VCAccountTypetxtId = LeadCIBILDetail.AccountTypetxtId;
                        var VCAccountNumbertxtId = LeadCIBILDetail.AccountNumbertxtId;
                        var VCOwnershipIndicatortxtId = LeadCIBILDetail.OwnershipIndicatortxtId;
                        var VCCreditLimittxtId = LeadCIBILDetail.CreditLimittxtId;
                        var VCHighCredittxtId = LeadCIBILDetail.HighCredittxtId;
                        var VCCurrentBalancetxtId = LeadCIBILDetail.CurrentBalancetxtId;
                        var VCAmountOverduetxtId = LeadCIBILDetail.AmountOverduetxtId;
                        var VCDPDsforLatestMonthtxtId = LeadCIBILDetail.DPDsforLatestMonthtxtId;
                        var VCDateOpenedtxtId = LeadCIBILDetail.DateOpenedtxtId;
                        var VCDateClosedtxtId = LeadCIBILDetail.DateClosedtxtId;
                        var VCDateofLastPaymenttxtId = LeadCIBILDetail.DateofLastPaymenttxtId;
                        var VCDateReportedtxtId = LeadCIBILDetail.DateReportedtxtId;
                        var VCSuiteFiledtxtId = LeadCIBILDetail.SuiteFiledtxtId;
                        var VCCreditFacilityStatustxtId = LeadCIBILDetail.CreditFacilityStatustxtId;
                        var VCWrittenofAmounttxtId = LeadCIBILDetail.WrittenofAmounttxtId;
                        var VCSettlementAmounttxtId = LeadCIBILDetail.SettlementAmounttxtId;
                        var VCTotalNonReportingMonthtxtId = LeadCIBILDetail.TotalNonReportingMonthtxtId;

                        var VDateofReportPulledOuttxtId = 'txtExperianDateofReportPulledOut';
                        var VExperianScoretxtId = 'txtExperianScore';
                        var VNameofBorrowertxtId = 'txtExperianNameofBorrower';
                        var VPANNotxtId = 'txtExperianPANNo';
                        var VAdditionalKYCCIRtxtId = 'txtExperianAdditionalKYCCIR';
                        var VEnquirytxtId = 'txtExperianEnquiry';
                        var VMemberIdtxtId = 'ddlExperianMember' + ArrCount;
                        var VAccountTypetxtId = 'ddlExperianAccountType' + ArrCount;
                        var VAccountNumbertxtId = 'txtExperianAccountNumber' + ArrCount;
                        var VOwnershipIndicatortxtId = 'txtExperianOwnershipIndicator' + ArrCount;
                        var VCreditLimittxtId = 'txtExperianCreditLimit' + ArrCount;
                        var VHighCredittxtId = 'txtExperianHighCredit' + ArrCount;
                        var VCurrentBalancetxtId = 'txtExperianCurrentBalance' + ArrCount;
                        var VAmountOverduetxtId = 'txtExperianAmountOverdue' + ArrCount;
                        var VDPDsforLatestMonthtxtId = 'txtExperianDPDsforLatestMonth' + ArrCount;
                        var VDateOpenedtxtId = 'txtExperianDateOpened' + ArrCount;
                        var VDateClosedtxtId = 'txtExperianDateClosed' + ArrCount;
                        var VDateofLastPaymenttxtId = 'txtExperianDateofLastPayment' + ArrCount;
                        var VDateReportedtxtId = 'txtExperianDateReported' + ArrCount;
                        var VSuiteFiledtxtId = 'ddlExperianSuiteFiled' + ArrCount;
                        var VCreditFacilityStatustxtId = 'ddlExperianCreditFacilityStatus' + ArrCount;
                        var VWrittenofAmounttxtId = 'txtExperianWrittenofAmount' + ArrCount;
                        var VSettlementAmounttxtId = 'txtExperianSettlementAmount' + ArrCount;
                        var VTotalNonReportingMonthtxtId = 'txtExperianTotalNonReportingMonth' + ArrCount;

                        document.getElementById(VDateofReportPulledOuttxtId).value = document.getElementById(VCDateofReportPulledOuttxtId).value;
                        document.getElementById(VExperianScoretxtId).value = document.getElementById(VCCibilScoretxtId).value;
                        document.getElementById(VNameofBorrowertxtId).value = document.getElementById(VCNameofBorrowertxtId);
                        document.getElementById(VPANNotxtId).value = document.getElementById(VCPANNotxtId).value;
                        document.getElementById(VAdditionalKYCCIRtxtId).value = document.getElementById(VCAdditionalKYCCIRtxtId).value;
                        document.getElementById(VEnquirytxtId).value = document.getElementById(VCEnquirytxtId).value;
                        document.getElementById(VMemberIdtxtId).value = document.getElementById(VCMemberIdtxtId).value;
                        document.getElementById(VAccountTypetxtId).value = document.getElementById(VCAccountTypetxtId).value;
                        document.getElementById(VAccountNumbertxtId).value = document.getElementById(VCAccountNumbertxtId).value;
                        document.getElementById(VOwnershipIndicatortxtId).value = document.getElementById(VCOwnershipIndicatortxtId).value;
                        document.getElementById(VCreditLimittxtId).value = document.getElementById(VCCreditLimittxtId).value;
                        document.getElementById(VHighCredittxtId).value = document.getElementById(VCHighCredittxtId).value;
                        document.getElementById(VCurrentBalancetxtId).value = document.getElementById(VCCurrentBalancetxtId).value;
                        document.getElementById(VAmountOverduetxtId).value = document.getElementById(VCAmountOverduetxtId).value;
                        document.getElementById(VDPDsforLatestMonthtxtId).value = document.getElementById(VCDPDsforLatestMonthtxtId).value;
                        document.getElementById(VDateOpenedtxtId).value = document.getElementById(VCDateOpenedtxtId).value;
                        document.getElementById(VDateClosedtxtId).value = document.getElementById(VCDateClosedtxtId).value;
                        document.getElementById(VDateofLastPaymenttxtId).value = document.getElementById(VCDateofLastPaymenttxtId).value;
                        document.getElementById(VDateReportedtxtId).value = document.getElementById(VCDateReportedtxtId).value;
                        document.getElementById(VSuiteFiledtxtId).value = document.getElementById(VCSuiteFiledtxtId).value;
                        document.getElementById(VCreditFacilityStatustxtId).value = document.getElementById(VCCreditFacilityStatustxtId).value;
                        document.getElementById(VWrittenofAmounttxtId).value = document.getElementById(VCWrittenofAmounttxtId).value;
                        document.getElementById(VSettlementAmounttxtId).value = document.getElementById(VCSettlementAmounttxtId).value;
                        document.getElementById(VTotalNonReportingMonthtxtId).value = document.getElementById(VCTotalNonReportingMonthtxtId).value;
                    }
                    tempLeadCIBILList.push(Pitem);
                }
            }

            LeadExperianList.length = 0;

            setLeadExperianList(tempLeadCIBILList);

            if (LeadCIBILList.length == 0) {
                var VDateofReportPulledOut = LeadCIBILList.length == 0 ? document.getElementById('txtCIBILDateofReportPulledOut').value == null ? '' : document.getElementById('txtCIBILDateofReportPulledOut').value = '' ? '' : document.getElementById('txtCIBILDateofReportPulledOut').value : '';
                var VExperianScore = LeadCIBILList.length == 0 ? document.getElementById('txtCIBILScore').value == null ? '' : document.getElementById('txtCIBILScore').value = '' ? '' : document.getElementById('txtCIBILScore').value : '';
                var VNameofBorrower = LeadCIBILList.length == 0 ? document.getElementById('txtCIBILNameofBorrower').value == null ? '' : document.getElementById('txtCIBILNameofBorrower').value = '' ? '' : document.getElementById('txtCIBILNameofBorrower').value : '';
                var VPANNo = LeadCIBILList.length == 0 ? document.getElementById('txtCIBILPANNo').value == null ? '' : document.getElementById('txtCIBILPANNo').value = '' ? '' : document.getElementById('txtCIBILPANNo').value : '';
                var VAdditionalKYCCIR = LeadCIBILList.length == 0 ? document.getElementById('txtCIBILAdditionalKYCCIR').value == null ? '' : document.getElementById('txtCIBILAdditionalKYCCIR').value = '' ? '' : document.getElementById('txtCIBILAdditionalKYCCIR').value : '';
                var VEnquiry = LeadCIBILList.length == 0 ? document.getElementById('txtCIBILEnquiry').value == null ? '' : document.getElementById('txtCIBILEnquiry').value = '' ? '' : document.getElementById('txtCIBILEnquiry').value : '';

                document.getElementById("txtExperianDateofReportPulledOut").value = document.getElementById('txtCIBILDateofReportPulledOut').value;
                document.getElementById("txtExperianScore").value = document.getElementById('txtCIBILScore').value;
                document.getElementById("txtExperianNameofBorrower").value = document.getElementById('txtCIBILNameofBorrower').value;
                document.getElementById("txtExperianPANNo").value = document.getElementById('txtCIBILPANNo').value;
                document.getElementById("txtExperianAdditionalKYCCIR").value = document.getElementById('txtCIBILAdditionalKYCCIR').value;
                document.getElementById("txtExperianEnquiry").value = document.getElementById('txtCIBILEnquiry').value;
            }

            var VMemberId = document.getElementById('ddlCIBILMember').value == null ? '0' : document.getElementById('ddlCIBILMember').value = '' ? '0' : document.getElementById('ddlCIBILMember').value;
            var VAccountTypeId = document.getElementById('ddlCIBILAccountType').value == null ? '' : document.getElementById('ddlCIBILAccountType').value = '' ? '' : document.getElementById('ddlCIBILAccountType').value;
            var VAccountNumber = document.getElementById('txtCIBILAccountNumber').value == null ? '' : document.getElementById('txtCIBILAccountNumber').value = '' ? '' : document.getElementById('txtCIBILAccountNumber').value;
            var VOwnershipIndicator = document.getElementById('txtCIBILOwnershipIndicator').value == null ? '' : document.getElementById('txtCIBILOwnershipIndicator').value = '' ? '' : document.getElementById('txtCIBILOwnershipIndicator').value;
            var VCreditLimit = document.getElementById('txtCIBILCreditLimit').value == null ? '' : document.getElementById('txtCIBILCreditLimit').value = '' ? '' : document.getElementById('txtCIBILCreditLimit').value;
            var VHighCredit = document.getElementById('txtCIBILHighCredit').value == null ? '' : document.getElementById('txtCIBILHighCredit').value = '' ? '' : document.getElementById('txtCIBILHighCredit').value;
            var VCurrentBalance = document.getElementById('txtCIBILCurrentBalance').value == null ? '' : document.getElementById('txtCIBILCurrentBalance').value = '' ? '' : document.getElementById('txtCIBILCurrentBalance').value;
            var VAmountOverdue = document.getElementById('txtCIBILAmountOverdue').value == null ? '' : document.getElementById('txtCIBILAmountOverdue').value = '' ? '' : document.getElementById('txtCIBILAmountOverdue').value;
            var VDPDsforLatestMonth = document.getElementById('txtCIBILDPDsforLatestMonth').value == null ? '' : document.getElementById('txtCIBILDPDsforLatestMonth').value = '' ? '' : document.getElementById('txtCIBILDPDsforLatestMonth').value;
            var VDateOpened = document.getElementById('txtCIBILDateOpened').value == null ? '' : document.getElementById('txtCIBILDateOpened').value = '' ? '' : document.getElementById('txtCIBILDateOpened').value;
            var VDateClosed = document.getElementById('txtCIBILDateClosed').value == null ? '' : document.getElementById('txtCIBILDateClosed').value = '' ? '' : document.getElementById('txtCIBILDateClosed').value;
            var VDateofLastPayment = document.getElementById('txtCIBILDateofLastPayment').value == null ? '' : document.getElementById('txtCIBILDateofLastPayment').value = '' ? '' : document.getElementById('txtCIBILDateofLastPayment').value;
            var VDateReported = document.getElementById('txtCIBILDateReported').value == null ? '' : document.getElementById('txtCIBILDateReported').value = '' ? '' : document.getElementById('txtCIBILDateReported').value;
            var VSuiteFiled = document.getElementById('ddlCIBILSuiteFiled').value == null ? '0' : document.getElementById('ddlCIBILSuiteFiled').value = '' ? '0' : document.getElementById('ddlCIBILSuiteFiled').value;
            var VCreditFacilityStatus = document.getElementById('ddlCIBILCreditFacilityStatus').value == null ? '0' : document.getElementById('ddlCIBILCreditFacilityStatus').value = '' ? '0' : document.getElementById('ddlCIBILCreditFacilityStatus').value;
            var VWrittenofAmount = document.getElementById('txtCIBILWrittenofAmount').value == null ? '' : document.getElementById('txtCIBILWrittenofAmount').value = '' ? '' : document.getElementById('txtCIBILWrittenofAmount').value;
            var VSettlementAmount = document.getElementById('txtCIBILSettlementAmount').value == null ? '' : document.getElementById('txtCIBILSettlementAmount').value = '' ? '' : document.getElementById('txtCIBILSettlementAmount').value;
            var VTotalNonReportingMonth = document.getElementById('txtCIBILTotalNonReportingMonth').value == null ? '' : document.getElementById('txtCIBILTotalNonReportingMonth').value = '' ? '' : document.getElementById('txtCIBILTotalNonReportingMonth').value;

            document.getElementById("ddlExperianMember").value = VMemberId;
            document.getElementById("ddlExperianAccountType").value = VAccountTypeId;
            document.getElementById("txtExperianAccountNumber").value = VAccountNumber;
            document.getElementById("txtExperianOwnershipIndicator").value = VOwnershipIndicator;
            document.getElementById("txtExperianCreditLimit").value = VCreditLimit;
            document.getElementById("txtExperianHighCredit").value = VHighCredit;
            document.getElementById("txtExperianCurrentBalance").value = VCurrentBalance;
            document.getElementById("txtExperianAmountOverdue").value = VAmountOverdue;
            document.getElementById("txtExperianDPDsforLatestMonth").value = VDPDsforLatestMonth;
            document.getElementById("txtExperianDateOpened").value = VDateOpened;
            document.getElementById("txtExperianDateClosed").value = VDateClosed;
            document.getElementById("txtExperianDateofLastPayment").value = VDateofLastPayment;
            document.getElementById("txtExperianDateReported").value = VDateReported;
            document.getElementById("ddlExperianSuiteFiled").value = VSuiteFiled;
            document.getElementById("ddlExperianCreditFacilityStatus").value = VCreditFacilityStatus;
            document.getElementById("txtExperianWrittenofAmount").value = VWrittenofAmount;
            document.getElementById("txtExperianSettlementAmount").value = VSettlementAmount;
            document.getElementById("txtExperianTotalNonReportingMonth").value = VTotalNonReportingMonth;
        }
        else {
            setLeadExperianList([]);
        }
    };

    const SameCRIFhandleChange = () => {
        var VchkSameCRIF = document.getElementById("chkSameCRIF").checked;
        if (VchkSameCRIF == true) {
            LeadCRIFList.length = 0;
            var tempLeadCIBILList = [];
            if (LeadCIBILList.length > 0) {
                for (let par = 0; par < LeadCIBILList.length; par++) {
                    const LeadCIBILDetail = LeadCIBILList[par];
                    var LRNo = par + 1;
                    const Pitem = {
                        RowId: LRNo,
                        LeadEnrollCRIFId: 0,
                        CRIFScore: par == 0 ? document.getElementById(LeadCIBILDetail.CibilScoretxtId).value : '',
                        NameofBorrower: par == 0 ? document.getElementById(LeadCIBILDetail.NameofBorrowertxtId).value : '',
                        PANNo: par == 0 ? document.getElementById(LeadCIBILDetail.PANNotxtId).value : '',
                        AdditionalKYCCIR: par == 0 ? document.getElementById(LeadCIBILDetail.AdditionalKYCCIRtxtId).value : '',
                        MemberId: document.getElementById(LeadCIBILDetail.MemberIdtxtId).value,
                        AccountTypeId: document.getElementById(LeadCIBILDetail.AccountTypetxtId).value,
                        AccountNumber: document.getElementById(LeadCIBILDetail.AccountNumbertxtId).value,
                        OwnershipIndicator: document.getElementById(LeadCIBILDetail.OwnershipIndicatortxtId).value,
                        CreditLimit: document.getElementById(LeadCIBILDetail.CreditLimittxtId).value,
                        HighCredit: document.getElementById(LeadCIBILDetail.HighCredittxtId).value,
                        CurrentBalance: document.getElementById(LeadCIBILDetail.CurrentBalancetxtId).value,
                        AmountOverdue: document.getElementById(LeadCIBILDetail.AmountOverduetxtId).value,
                        DPDsforLatestMonth: document.getElementById(LeadCIBILDetail.DPDsforLatestMonthtxtId).value,
                        DateOpened: document.getElementById(LeadCIBILDetail.DateOpenedtxtId).value,
                        DateClosed: document.getElementById(LeadCIBILDetail.DateClosedtxtId).value,
                        DateofLastPayment: document.getElementById(LeadCIBILDetail.DateofLastPaymenttxtId).value,
                        DateReported: document.getElementById(LeadCIBILDetail.DateReportedtxtId).value,
                        SuiteFiled: document.getElementById(LeadCIBILDetail.SuiteFiledtxtId).value,
                        CreditFacilityStatus: document.getElementById(LeadCIBILDetail.CreditFacilityStatustxtId).value,
                        WrittenofAmount: document.getElementById(LeadCIBILDetail.WrittenofAmounttxtId).value,
                        SettlementAmount: document.getElementById(LeadCIBILDetail.SettlementAmounttxtId).value,
                        Enquiry: par == 0 ? document.getElementById(LeadCIBILDetail.EnquirytxtId).value : '',
                        TotalNonReportingMonth: document.getElementById(LeadCIBILDetail.TotalNonReportingMonthtxtId).value,
                        DateofReportPulledOuttxtId: 'txtCRIFDateofReportPulledOut' + LRNo,
                        CRIFScoretxtId: 'txtCRIFScore' + LRNo,
                        NameofBorrowertxtId: 'txtCRIFNameofBorrower' + LRNo,
                        PANNotxtId: 'txtCRIFPANNo' + LRNo,
                        AdditionalKYCCIRtxtId: 'txtCRIFAdditionalKYCCIR' + LRNo,
                        MemberIdtxtId: 'ddlCRIFMember' + LRNo,
                        AccountTypetxtId: 'ddlCRIFAccountType' + LRNo,
                        AccountNumbertxtId: 'txtCRIFAccountNumber' + LRNo,
                        OwnershipIndicatortxtId: 'txtCRIFOwnershipIndicator' + LRNo,
                        CreditLimittxtId: 'txtCRIFCreditLimit' + LRNo,
                        HighCredittxtId: 'txtCRIFHighCredit' + LRNo,
                        CurrentBalancetxtId: 'txtCRIFCurrentBalance' + LRNo,
                        AmountOverduetxtId: 'txtCRIFAmountOverdue' + LRNo,
                        DPDsforLatestMonthtxtId: 'txtCRIFDPDsforLatestMonth' + LRNo,
                        DateOpenedtxtId: 'txtCRIFDateOpened' + LRNo,
                        DateClosedtxtId: 'txtCRIFDateClosed' + LRNo,
                        DateofLastPaymenttxtId: 'txtCRIFDateofLastPayment' + LRNo,
                        DateReportedtxtId: 'txtCRIFDateReported' + LRNo,
                        SuiteFiledtxtId: 'ddlCRIFSuiteFiled' + LRNo,
                        CreditFacilityStatustxtId: 'ddlCRIFCreditFacilityStatus' + LRNo,
                        WrittenofAmounttxtId: 'txtCRIFWrittenofAmount' + LRNo,
                        SettlementAmounttxtId: 'txtCRIFSettlementAmount' + LRNo,
                        EnquirytxtId: 'txtCRIFEnquiry' + LRNo,
                        TotalNonReportingMonthtxtId: 'txtCRIFTotalNonReportingMonth' + LRNo,
                    };

                    if (LeadCRIFList.length > 0) {
                        var ArrCount = par + 1;
                        var VCDateofReportPulledOuttxtId = LeadCIBILDetail.DateofReportPulledOuttxtId;
                        var VCCibilScoretxtId = LeadCIBILDetail.CibilScoretxtId;
                        var VCNameofBorrowertxtId = LeadCIBILDetail.NameofBorrowertxtId;
                        var VCPANNotxtId = LeadCIBILDetail.PANNotxtId;
                        var VCAdditionalKYCCIRtxtId = LeadCIBILDetail.AdditionalKYCCIRtxtId;
                        var VCEnquirytxtId = LeadCIBILDetail.EnquirytxtId;
                        var VCMemberIdtxtId = LeadCIBILDetail.MemberIdtxtId;
                        var VCAccountTypetxtId = LeadCIBILDetail.AccountTypetxtId;
                        var VCAccountNumbertxtId = LeadCIBILDetail.AccountNumbertxtId;
                        var VCOwnershipIndicatortxtId = LeadCIBILDetail.OwnershipIndicatortxtId;
                        var VCCreditLimittxtId = LeadCIBILDetail.CreditLimittxtId;
                        var VCHighCredittxtId = LeadCIBILDetail.HighCredittxtId;
                        var VCCurrentBalancetxtId = LeadCIBILDetail.CurrentBalancetxtId;
                        var VCAmountOverduetxtId = LeadCIBILDetail.AmountOverduetxtId;
                        var VCDPDsforLatestMonthtxtId = LeadCIBILDetail.DPDsforLatestMonthtxtId;
                        var VCDateOpenedtxtId = LeadCIBILDetail.DateOpenedtxtId;
                        var VCDateClosedtxtId = LeadCIBILDetail.DateClosedtxtId;
                        var VCDateofLastPaymenttxtId = LeadCIBILDetail.DateofLastPaymenttxtId;
                        var VCDateReportedtxtId = LeadCIBILDetail.DateReportedtxtId;
                        var VCSuiteFiledtxtId = LeadCIBILDetail.SuiteFiledtxtId;
                        var VCCreditFacilityStatustxtId = LeadCIBILDetail.CreditFacilityStatustxtId;
                        var VCWrittenofAmounttxtId = LeadCIBILDetail.WrittenofAmounttxtId;
                        var VCSettlementAmounttxtId = LeadCIBILDetail.SettlementAmounttxtId;
                        var VCTotalNonReportingMonthtxtId = LeadCIBILDetail.TotalNonReportingMonthtxtId;

                        var VDateofReportPulledOuttxtId = 'txtCRIFDateofReportPulledOut' + ArrCount;
                        var VCRIFScoretxtId = 'txtCRIFScore' + ArrCount;
                        var VNameofBorrowertxtId = 'txtCRIFNameofBorrower' + ArrCount;
                        var VPANNotxtId = 'txtCRIFPANNo' + ArrCount;
                        var VAdditionalKYCCIRtxtId = 'txtCRIFAdditionalKYCCIR' + ArrCount;
                        var VEnquirytxtId = 'txtCRIFEnquiry' + ArrCount;
                        var VMemberIdtxtId = 'ddlCRIFMember' + ArrCount;
                        var VAccountTypetxtId = 'ddlCRIFAccountType' + ArrCount;
                        var VAccountNumbertxtId = 'txtCRIFAccountNumber' + ArrCount;
                        var VOwnershipIndicatortxtId = 'txtCRIFOwnershipIndicator' + ArrCount;
                        var VCreditLimittxtId = 'txtCRIFCreditLimit' + ArrCount;
                        var VHighCredittxtId = 'txtCRIFHighCredit' + ArrCount;
                        var VCurrentBalancetxtId = 'txtCRIFCurrentBalance' + ArrCount;
                        var VAmountOverduetxtId = 'txtCRIFAmountOverdue' + ArrCount;
                        var VDPDsforLatestMonthtxtId = 'txtCRIFDPDsforLatestMonth' + ArrCount;
                        var VDateOpenedtxtId = 'txtCRIFDateOpened' + ArrCount;
                        var VDateClosedtxtId = 'txtCRIFDateClosed' + ArrCount;
                        var VDateofLastPaymenttxtId = 'txtCRIFDateofLastPayment' + ArrCount;
                        var VDateReportedtxtId = 'txtCRIFDateReported' + ArrCount;
                        var VSuiteFiledtxtId = 'ddlCRIFSuiteFiled' + ArrCount;
                        var VCreditFacilityStatustxtId = 'ddlCRIFCreditFacilityStatus' + ArrCount;
                        var VWrittenofAmounttxtId = 'txtCRIFWrittenofAmount' + ArrCount;
                        var VSettlementAmounttxtId = 'txtCRIFSettlementAmount' + ArrCount;
                        var VTotalNonReportingMonthtxtId = 'txtCRIFTotalNonReportingMonth' + ArrCount;

                        par == 0 ? document.getElementById(VDateofReportPulledOuttxtId).value = document.getElementById(VCDateofReportPulledOuttxtId).value : '';
                        par == 0 ? document.getElementById(VCRIFScoretxtId).value = document.getElementById(VCCibilScoretxtId).value : '';
                        par == 0 ? document.getElementById(VNameofBorrowertxtId).value = document.getElementById(VCNameofBorrowertxtId).value : '';
                        par == 0 ? document.getElementById(VPANNotxtId).value = document.getElementById(VCPANNotxtId).value : '';
                        par == 0 ? document.getElementById(VAdditionalKYCCIRtxtId).value = document.getElementById(VCAdditionalKYCCIRtxtId).value : '';
                        par == 0 ? document.getElementById(VEnquirytxtId).value = document.getElementById(VCEnquirytxtId).value : '';
                        document.getElementById(VMemberIdtxtId).value = document.getElementById(VCMemberIdtxtId).value;
                        document.getElementById(VAccountTypetxtId).value = document.getElementById(VCAccountTypetxtId).value;
                        document.getElementById(VAccountNumbertxtId).value = document.getElementById(VCAccountNumbertxtId).value;
                        document.getElementById(VOwnershipIndicatortxtId).value = document.getElementById(VCOwnershipIndicatortxtId).value;
                        document.getElementById(VCreditLimittxtId).value = document.getElementById(VCCreditLimittxtId).value;
                        document.getElementById(VHighCredittxtId).value = document.getElementById(VCHighCredittxtId).value;
                        document.getElementById(VCurrentBalancetxtId).value = document.getElementById(VCCurrentBalancetxtId).value;
                        document.getElementById(VAmountOverduetxtId).value = document.getElementById(VCAmountOverduetxtId).value;
                        document.getElementById(VDPDsforLatestMonthtxtId).value = document.getElementById(VCDPDsforLatestMonthtxtId).value;
                        document.getElementById(VDateOpenedtxtId).value = document.getElementById(VCDateOpenedtxtId).value;
                        document.getElementById(VDateClosedtxtId).value = document.getElementById(VCDateClosedtxtId).value;
                        document.getElementById(VDateofLastPaymenttxtId).value = document.getElementById(VCDateofLastPaymenttxtId).value;
                        document.getElementById(VDateReportedtxtId).value = document.getElementById(VCDateReportedtxtId).value;
                        document.getElementById(VSuiteFiledtxtId).value = document.getElementById(VCSuiteFiledtxtId).value;
                        document.getElementById(VCreditFacilityStatustxtId).value = document.getElementById(VCCreditFacilityStatustxtId).value;
                        document.getElementById(VWrittenofAmounttxtId).value = document.getElementById(VCWrittenofAmounttxtId).value;
                        document.getElementById(VSettlementAmounttxtId).value = document.getElementById(VCSettlementAmounttxtId).value;
                        document.getElementById(VTotalNonReportingMonthtxtId).value = document.getElementById(VCTotalNonReportingMonthtxtId).value;
                    }

                    tempLeadCIBILList.push(Pitem);
                }
            }

            LeadCRIFList.length = 0;

            setLeadCRIFList(tempLeadCIBILList);


            if (LeadCIBILList.length == 0) {
                var VDateofReportPulledOut = LeadCIBILList.length == 0 ? document.getElementById('txtCIBILDateofReportPulledOut').value == null ? '' : document.getElementById('txtCIBILDateofReportPulledOut').value = '' ? '' : document.getElementById('txtCIBILDateofReportPulledOut').value : '';
                var VCRIFScore = LeadCIBILList.length == 0 ? document.getElementById('txtCIBILScore').value == null ? '' : document.getElementById('txtCIBILScore').value = '' ? '' : document.getElementById('txtCIBILScore').value : '';
                var VNameofBorrower = LeadCIBILList.length == 0 ? document.getElementById('txtCIBILNameofBorrower').value == null ? '' : document.getElementById('txtCIBILNameofBorrower').value = '' ? '' : document.getElementById('txtCIBILNameofBorrower').value : '';
                var VPANNo = LeadCIBILList.length == 0 ? document.getElementById('txtCIBILPANNo').value == null ? '' : document.getElementById('txtCIBILPANNo').value = '' ? '' : document.getElementById('txtCIBILPANNo').value : '';
                var VAdditionalKYCCIR = LeadCIBILList.length == 0 ? document.getElementById('txtCIBILAdditionalKYCCIR').value == null ? '' : document.getElementById('txtCIBILAdditionalKYCCIR').value = '' ? '' : document.getElementById('txtCIBILAdditionalKYCCIR').value : '';
                var VEnquiry = LeadCIBILList.length == 0 ? document.getElementById('txtCIBILEnquiry').value == null ? '' : document.getElementById('txtCIBILEnquiry').value = '' ? '' : document.getElementById('txtCIBILEnquiry').value : '';

                document.getElementById("txtCRIFDateofReportPulledOut").value = VDateofReportPulledOut;
                document.getElementById("txtCRIFScore").value = VCRIFScore;
                document.getElementById("txtCRIFNameofBorrower").value = VNameofBorrower;
                document.getElementById("txtCRIFPANNo").value = VPANNo;
                document.getElementById("txtCRIFAdditionalKYCCIR").value = VAdditionalKYCCIR;
                document.getElementById("txtCRIFEnquiry").value = VEnquiry;
            }

            var VMemberId = document.getElementById('ddlCIBILMember').value == null ? '0' : document.getElementById('ddlCIBILMember').value = '' ? '0' : document.getElementById('ddlCIBILMember').value;
            var VAccountTypeId = document.getElementById('ddlCIBILAccountType').value == null ? '' : document.getElementById('ddlCIBILAccountType').value = '' ? '' : document.getElementById('ddlCIBILAccountType').value;
            var VAccountNumber = document.getElementById('txtCIBILAccountNumber').value == null ? '' : document.getElementById('txtCIBILAccountNumber').value = '' ? '' : document.getElementById('txtCIBILAccountNumber').value;
            var VOwnershipIndicator = document.getElementById('txtCIBILOwnershipIndicator').value == null ? '' : document.getElementById('txtCIBILOwnershipIndicator').value = '' ? '' : document.getElementById('txtCIBILOwnershipIndicator').value;
            var VCreditLimit = document.getElementById('txtCIBILCreditLimit').value == null ? '' : document.getElementById('txtCIBILCreditLimit').value = '' ? '' : document.getElementById('txtCIBILCreditLimit').value;
            var VHighCredit = document.getElementById('txtCIBILHighCredit').value == null ? '' : document.getElementById('txtCIBILHighCredit').value = '' ? '' : document.getElementById('txtCIBILHighCredit').value;
            var VCurrentBalance = document.getElementById('txtCIBILCurrentBalance').value == null ? '' : document.getElementById('txtCIBILCurrentBalance').value = '' ? '' : document.getElementById('txtCIBILCurrentBalance').value;
            var VAmountOverdue = document.getElementById('txtCIBILAmountOverdue').value == null ? '' : document.getElementById('txtCIBILAmountOverdue').value = '' ? '' : document.getElementById('txtCIBILAmountOverdue').value;
            var VDPDsforLatestMonth = document.getElementById('txtCIBILDPDsforLatestMonth').value == null ? '' : document.getElementById('txtCIBILDPDsforLatestMonth').value = '' ? '' : document.getElementById('txtCIBILDPDsforLatestMonth').value;
            var VDateOpened = document.getElementById('txtCIBILDateOpened').value == null ? '' : document.getElementById('txtCIBILDateOpened').value = '' ? '' : document.getElementById('txtCIBILDateOpened').value;
            var VDateClosed = document.getElementById('txtCIBILDateClosed').value == null ? '' : document.getElementById('txtCIBILDateClosed').value = '' ? '' : document.getElementById('txtCIBILDateClosed').value;
            var VDateofLastPayment = document.getElementById('txtCIBILDateofLastPayment').value == null ? '' : document.getElementById('txtCIBILDateofLastPayment').value = '' ? '' : document.getElementById('txtCIBILDateofLastPayment').value;
            var VDateReported = document.getElementById('txtCIBILDateReported').value == null ? '' : document.getElementById('txtCIBILDateReported').value = '' ? '' : document.getElementById('txtCIBILDateReported').value;
            var VSuiteFiled = document.getElementById('ddlCIBILSuiteFiled').value == null ? '0' : document.getElementById('ddlCIBILSuiteFiled').value = '' ? '0' : document.getElementById('ddlCIBILSuiteFiled').value;
            var VCreditFacilityStatus = document.getElementById('ddlCIBILCreditFacilityStatus').value == null ? '0' : document.getElementById('ddlCIBILCreditFacilityStatus').value = '' ? '0' : document.getElementById('ddlCIBILCreditFacilityStatus').value;
            var VWrittenofAmount = document.getElementById('txtCIBILWrittenofAmount').value == null ? '' : document.getElementById('txtCIBILWrittenofAmount').value = '' ? '' : document.getElementById('txtCIBILWrittenofAmount').value;
            var VSettlementAmount = document.getElementById('txtCIBILSettlementAmount').value == null ? '' : document.getElementById('txtCIBILSettlementAmount').value = '' ? '' : document.getElementById('txtCIBILSettlementAmount').value;
            var VTotalNonReportingMonth = document.getElementById('txtCIBILTotalNonReportingMonth').value == null ? '' : document.getElementById('txtCIBILTotalNonReportingMonth').value = '' ? '' : document.getElementById('txtCIBILTotalNonReportingMonth').value;

            document.getElementById("ddlCRIFMember").value = VMemberId;
            document.getElementById("ddlCRIFAccountType").value = VAccountTypeId;
            document.getElementById("txtCRIFAccountNumber").value = VAccountNumber;
            document.getElementById("txtCRIFOwnershipIndicator").value = VOwnershipIndicator;
            document.getElementById("txtCRIFCreditLimit").value = VCreditLimit;
            document.getElementById("txtCRIFHighCredit").value = VHighCredit;
            document.getElementById("txtCRIFCurrentBalance").value = VCurrentBalance;
            document.getElementById("txtCRIFAmountOverdue").value = VAmountOverdue;
            document.getElementById("txtCRIFDPDsforLatestMonth").value = VDPDsforLatestMonth;
            document.getElementById("txtCRIFDateOpened").value = VDateOpened;
            document.getElementById("txtCRIFDateClosed").value = VDateClosed;
            document.getElementById("txtCRIFDateofLastPayment").value = VDateofLastPayment;
            document.getElementById("txtCRIFDateReported").value = VDateReported;
            document.getElementById("ddlCRIFSuiteFiled").value = VSuiteFiled;
            document.getElementById("ddlCRIFCreditFacilityStatus").value = VCreditFacilityStatus;
            document.getElementById("txtCRIFWrittenofAmount").value = VWrittenofAmount;
            document.getElementById("txtCRIFSettlementAmount").value = VSettlementAmount;
            document.getElementById("txtCRIFTotalNonReportingMonth").value = VTotalNonReportingMonth;
        }
        else {
            setLeadCRIFList([]);
        }
    };

    const CIBILhandleRemoveRow = (e, RowId, LeadEnrollCIBILId) => {
        let AlertMessgae = "Are you sure you want to delete this record?";
        if (confirm(AlertMessgae) == true) {
            const DRId = RowId;
            setLeadCIBILList(LeadCIBILList.filter(item => item.RowId !== DRId));

            var VLeadEnrollCIBILId = parseInt(LeadEnrollCIBILId == null ? '0' : LeadEnrollCIBILId == '' ? '0' : LeadEnrollCIBILId);
            if (VLeadEnrollCIBILId > 0) {
                const LeadItemArr = { Id: VLeadEnrollCIBILId }
                axios.post(global.APIURL + '/LeadCIBILCreditDetailDelete', LeadItemArr).then(resem => { });
            }

            alert('CIBIL credit detail delete successfully');
        }
    };

    const EqifaxhandleRemoveRow = (e, RowId, LeadEnrollEqifaxId) => {
        let AlertMessgae = "Are you sure you want to delete this record?";
        if (confirm(AlertMessgae) == true) {
            const DRId = RowId;
            setLeadEqifaxList(LeadEqifaxList.filter(item => item.RowId !== DRId));

            var VLeadEnrollEqifaxId = parseInt(LeadEnrollEqifaxId == null ? '0' : LeadEnrollEqifaxId == '' ? '0' : LeadEnrollEqifaxId);
            if (VLeadEnrollEqifaxId > 0) {
                const LeadItemArr = { Id: VLeadEnrollEqifaxId }
                axios.post(global.APIURL + '/LeadEqifaxCreditDetailDelete', LeadItemArr).then(resem => { });
            }

            alert('Eqifax credit detail delete successfully');
        }
    };

    const ExperianhandleRemoveRow = (e, RowId, LeadEnrollExperianId) => {
        let AlertMessgae = "Are you sure you want to delete this record?";
        if (confirm(AlertMessgae) == true) {
            const DRId = RowId;
            setLeadExperianList(LeadExperianList.filter(item => item.RowId !== DRId));

            var VLeadEnrollExperianId = parseInt(LeadEnrollExperianId == null ? '0' : LeadEnrollExperianId == '' ? '0' : LeadEnrollExperianId);
            if (VLeadEnrollExperianId > 0) {
                const LeadItemArr = { Id: VLeadEnrollExperianId }
                axios.post(global.APIURL + '/LeadExperianCreditDetailDelete', LeadItemArr).then(resem => { });
            }

            alert('Experian credit detail delete successfully');
        }
    };

    const CRIFhandleRemoveRow = (e, RowId, LeadEnrollCRIFId) => {
        let AlertMessgae = "Are you sure you want to delete this record?";
        if (confirm(AlertMessgae) == true) {
            const DRId = RowId;
            setLeadCRIFList(LeadCRIFList.filter(item => item.RowId !== DRId));

            var VLeadEnrollCRIFId = parseInt(LeadEnrollCRIFId == null ? '0' : LeadEnrollCRIFId == '' ? '0' : LeadEnrollCRIFId);
            if (VLeadEnrollCRIFId > 0) {
                const LeadItemArr = { Id: VLeadEnrollCRIFId }
                axios.post(global.APIURL + '/LeadCRIFCreditDetailDelete', LeadItemArr).then(resem => { });
            }

            alert('CRIF credit detail delete successfully');
        }
    };

    const CIBILFileUploadClose = async (e) => {
        e.preventDefault();
        document.getElementById("fuCIBILFileUpload").value = '';
        setISUploadModelCIBILDocument(0);
    }
    const CIBILFileUploadSave = async (e) => {
        e.preventDefault();
        let VLeadId = localStorage.getItem("ConvertIndividualLeadId");
        const LPDitem = {
            LeadId: VLeadId,
            CreditDetailReportType: 'CIBIL',
            ReportFilePath: CIBILDocumentFilePath,
            ReportFileName: CIBILDocumentFileName,
            CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
        };

        axios.post(global.APIURL + "/LeadCreditDetailReportFileSave", LPDitem).then(res => { alert(res.data.StatusMessage); });
        setISUploadModelCIBILDocument(0);
        document.getElementById("fuCIBILFileUpload").value = '';
        getLeadClientCreditReportFile();
    }

    function CIBILDocumentfetchPost(e) {
        var details = e.target.files[0] == null ? '' : e.target.files[0] == undefined ? '' : e.target.files[0];
        if (details != '') {
            var details1 = "LeadCIBILAccountReport";
            const formData = new FormData();
            formData.append('Document', details);
            formData.append('FolderName', details1);
            var AUrl = global.APIURL + '/uploadavatar/';
            axios.post(AUrl, formData,
                {
                    headers: { "Content-Type": details.type },
                })
                .then((res) => {
                    setCIBILDocumentFilePath(res.data.IName);
                    setCIBILDocumentFileName(e.target.files[0].name);
                    if (e.target.files[0].name != null) {
                        if (e.target.files[0].name != '') {
                            setISUploadCIBILDocument(1);
                            setISUploadModelCIBILDocument(1);
                        }
                        else {
                            setISUploadCIBILDocument(0);
                            setISUploadModelCIBILDocument(0);
                            setCIBILDocumentFilePath('');
                            setCIBILDocumentFileName('');
                        }
                    }
                    else {
                        setISUploadCIBILDocument(0);
                        setISUploadModelCIBILDocument(0);
                        setCIBILDocumentFilePath('');
                        setCIBILDocumentFileName('');
                    }
                });
        }
        else {
            setISUploadCIBILDocument(0);
            setISUploadModelCIBILDocument(0);
            setCIBILDocumentFilePath('');
            setCIBILDocumentFileName('');
        }
    }

    const EqifaxFileUploadClose = async (e) => {
        e.preventDefault();
        document.getElementById("fuEqifaxFileUpload").value = '';
        setISUploadModelEqifaxDocument(0);
    }
    const EqifaxFileUploadSave = async (e) => {
        e.preventDefault();
        let VLeadId = localStorage.getItem("ConvertIndividualLeadId");
        const LPDitem = {
            LeadId: VLeadId,
            CreditDetailReportType: 'Eqifax',
            ReportFilePath: EqifaxDocumentFilePath,
            ReportFileName: EqifaxDocumentFileName,
            CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
        };

        axios.post(global.APIURL + "/LeadCreditDetailReportFileSave", LPDitem).then(res => { alert(res.data.StatusMessage); });
        setISUploadModelEqifaxDocument(0);
        document.getElementById("fuEqifaxFileUpload").value = '';
        getLeadClientCreditReportFile();
    }

    function EqifaxDocumentfetchPost(e) {
        var details = e.target.files[0] == null ? '' : e.target.files[0] == undefined ? '' : e.target.files[0];
        if (details != '') {
            var details1 = "LeadEqifaxAccountReport";
            const formData = new FormData();
            formData.append('Document', details);
            formData.append('FolderName', details1);
            var AUrl = global.APIURL + '/uploadavatar/';
            axios.post(AUrl, formData,
                {
                    headers: { "Content-Type": details.type },
                })
                .then((res) => {
                    setEqifaxDocumentFilePath(res.data.IName);
                    setEqifaxDocumentFileName(e.target.files[0].name);
                    if (e.target.files[0].name != null) {
                        if (e.target.files[0].name != '') {
                            setISUploadEqifaxDocument(1);
                            setISUploadModelEqifaxDocument(1);
                        }
                        else {
                            setISUploadEqifaxDocument(0);
                            setISUploadModelEqifaxDocument(0);
                            setEqifaxDocumentFilePath('');
                            setEqifaxDocumentFileName('');
                        }
                    }
                    else {
                        setISUploadEqifaxDocument(0);
                        setISUploadModelEqifaxDocument(0);
                        setEqifaxDocumentFilePath('');
                        setEqifaxDocumentFileName('');
                    }
                });
        }
        else {
            setISUploadEqifaxDocument(0);
            setISUploadModelEqifaxDocument(0);
            setEqifaxDocumentFilePath('');
            setEqifaxDocumentFileName('');
        }
    }

    const ExperianFileUploadClose = async (e) => {
        e.preventDefault();
        document.getElementById("fuExperianFileUpload").value = '';
        setISUploadModelExperianDocument(0);
    }
    const ExperianFileUploadSave = async (e) => {
        e.preventDefault();
        let VLeadId = localStorage.getItem("ConvertIndividualLeadId");
        const LPDitem = {
            LeadId: VLeadId,
            CreditDetailReportType: 'Experian',
            ReportFilePath: ExperianDocumentFilePath,
            ReportFileName: ExperianDocumentFileName,
            CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
        };

        axios.post(global.APIURL + "/LeadCreditDetailReportFileSave", LPDitem).then(res => { alert(res.data.StatusMessage); });
        setISUploadModelExperianDocument(0);
        document.getElementById("fuExperianFileUpload").value = '';
        getLeadClientCreditReportFile();
    }

    function ExperianDocumentfetchPost(e) {
        var details = e.target.files[0] == null ? '' : e.target.files[0] == undefined ? '' : e.target.files[0];
        if (details != '') {
            var details1 = "LeadExperianAccountReport";
            const formData = new FormData();
            formData.append('Document', details);
            formData.append('FolderName', details1);
            var AUrl = global.APIURL + '/uploadavatar/';
            axios.post(AUrl, formData,
                {
                    headers: { "Content-Type": details.type },
                })
                .then((res) => {
                    setExperianDocumentFilePath(res.data.IName);
                    setExperianDocumentFileName(e.target.files[0].name);
                    if (e.target.files[0].name != null) {
                        if (e.target.files[0].name != '') {
                            setISUploadExperianDocument(1);
                            setISUploadModelExperianDocument(1);
                        }
                        else {
                            setISUploadExperianDocument(0);
                            setISUploadModelExperianDocument(0);
                            setExperianDocumentFilePath('');
                            setExperianDocumentFileName('');
                        }
                    }
                    else {
                        setISUploadExperianDocument(0);
                        setISUploadModelExperianDocument(0);
                        setExperianDocumentFilePath('');
                        setExperianDocumentFileName('');
                    }
                });
        }
        else {
            setISUploadExperianDocument(0);
            setISUploadModelExperianDocument(0);
            setExperianDocumentFilePath('');
            setExperianDocumentFileName('');
        }
    }

    const CRIFFileUploadClose = async (e) => {
        e.preventDefault();
        document.getElementById("fuCRIFFileUpload").value = '';
        setISUploadModelCRIFDocument(0);
    }
    const CRIFFileUploadSave = async (e) => {
        e.preventDefault();
        let VLeadId = localStorage.getItem("ConvertIndividualLeadId");
        const LPDitem = {
            LeadId: VLeadId,
            CreditDetailReportType: 'CRIF',
            ReportFilePath: CRIFDocumentFilePath,
            ReportFileName: CRIFDocumentFileName,
            CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
        };

        axios.post(global.APIURL + "/LeadCreditDetailReportFileSave", LPDitem).then(res => { alert(res.data.StatusMessage); });
        setISUploadModelCRIFDocument(0);
        document.getElementById("fuCRIFFileUpload").value = '';
        getLeadClientCreditReportFile();
    }

    function CRIFDocumentfetchPost(e) {
        var details = e.target.files[0] == null ? '' : e.target.files[0] == undefined ? '' : e.target.files[0];
        if (details != '') {
            var details1 = "LeadCRIFAccountReport";
            const formData = new FormData();
            formData.append('Document', details);
            formData.append('FolderName', details1);
            var AUrl = global.APIURL + '/uploadavatar/';
            axios.post(AUrl, formData,
                {
                    headers: { "Content-Type": details.type },
                })
                .then((res) => {
                    setCRIFDocumentFilePath(res.data.IName);
                    setCRIFDocumentFileName(e.target.files[0].name);
                    if (e.target.files[0].name != null) {
                        if (e.target.files[0].name != '') {
                            setISUploadCRIFDocument(1);
                            setISUploadModelCRIFDocument(1);
                        }
                        else {
                            setISUploadCRIFDocument(0);
                            setISUploadModelCRIFDocument(0);
                            setCRIFDocumentFilePath('');
                            setCRIFDocumentFileName('');
                        }
                    }
                    else {
                        setISUploadCRIFDocument(0);
                        setISUploadModelCRIFDocument(0);
                        setCRIFDocumentFilePath('');
                        setCRIFDocumentFileName('');
                    }
                });
        }
        else {
            setISUploadCRIFDocument(0);
            setISUploadModelCRIFDocument(0);
            setCRIFDocumentFilePath('');
            setCRIFDocumentFileName('');
        }
    }

    return (
        <React.Fragment>
            <div className="modal fade bd-example-modal-sm" id="CIBILFileUpload" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h5 className="modal-title" id="exampleModalLabel">CIBIL File Upload</h5>
                            </div>
                            <div>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={CIBILFileUploadClose}></button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <Row>

                                <Col lg={12} className="mt-2">
                                    <div className="mb-2">
                                        <label className="form-label">Select File</label>
                                        {ISUploadModelCIBILDocument > 0 ?
                                            <span className="float-end text-primary font-12" id="spandoctitle">
                                                <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                                                <a href={CIBILDocumentFilePath} target="_blank" rel="noopener noreferrer">{CIBILDocumentFileName}</a></span> : ''}
                                        <Input type="file" className="form-control" id="fuCIBILFileUpload" onChange={CIBILDocumentfetchPost} />
                                    </div>
                                </Col>
                                <Col lg={12} className="mt-2">
                                    <button className="btn btn_success btn-sm" id="btnsave" onClick={CIBILFileUploadSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={CIBILFileUploadClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade bd-example-modal-sm" id="EqifaxFileUpload" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h5 className="modal-title" id="exampleModalLabel">Eqifax File Upload</h5>
                            </div>
                            <div>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={EqifaxFileUploadClose}></button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <Row>

                                <Col lg={12} className="mt-2">
                                    <div className="mb-2">
                                        <label className="form-label">Select File</label>
                                        {ISUploadModelEqifaxDocument > 0 ?
                                            <span className="float-end text-primary font-12" id="spandoctitle">
                                                <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                                                <a href={EqifaxDocumentFilePath} target="_blank" rel="noopener noreferrer">{EqifaxDocumentFileName}</a></span> : ''}
                                        <Input type="file" className="form-control" id="fuEqifaxFileUpload" onChange={EqifaxDocumentfetchPost} />
                                    </div>
                                </Col>
                                <Col lg={12} className="mt-2">
                                    <button className="btn btn_success btn-sm" id="btnsave" onClick={EqifaxFileUploadSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={EqifaxFileUploadClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade bd-example-modal-sm" id="ExperianFileUpload" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h5 className="modal-title" id="exampleModalLabel">Experian File Upload</h5>
                            </div>
                            <div>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ExperianFileUploadClose}></button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <Row>

                                <Col lg={12} className="mt-2">
                                    <div className="mb-2">
                                        <label className="form-label">Select File</label>
                                        {ISUploadModelExperianDocument > 0 ?
                                            <span className="float-end text-primary font-12" id="spandoctitle">
                                                <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                                                <a href={ExperianDocumentFilePath} target="_blank" rel="noopener noreferrer">{ExperianDocumentFileName}</a></span> : ''}
                                        <Input type="file" className="form-control" id="fuExperianFileUpload" onChange={ExperianDocumentfetchPost} />
                                    </div>
                                </Col>
                                <Col lg={12} className="mt-2">
                                    <button className="btn btn_success btn-sm" id="btnsave" onClick={ExperianFileUploadSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={ExperianFileUploadClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade bd-example-modal-sm" id="CRIFFileUpload" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h5 className="modal-title" id="exampleModalLabel">CRIF File Upload</h5>
                            </div>
                            <div>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={CRIFFileUploadClose}></button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <Row>

                                <Col lg={12} className="mt-2">
                                    <div className="mb-2">
                                        <label className="form-label">Select File</label>
                                        {ISUploadModelCRIFDocument > 0 ?
                                            <span className="float-end text-primary font-12" id="spandoctitle">
                                                <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                                                <a href={CRIFDocumentFilePath} target="_blank" rel="noopener noreferrer">{CRIFDocumentFileName}</a></span> : ''}
                                        <Input type="file" className="form-control" id="fuCRIFFileUpload" onChange={CRIFDocumentfetchPost} />
                                    </div>
                                </Col>
                                <Col lg={12} className="mt-2">
                                    <button className="btn btn_success btn-sm" id="btnsave" onClick={CRIFFileUploadSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={CRIFFileUploadClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>

            <Card>
                <CardBody>
                    <Row>
                        <Col lg="10"> <h5>CIBIL</h5></Col>
                        <Col lg="2" className="text-end">
                            {ISUploadCIBILDocument > 0 ? <a href={CIBILDocumentFilePath} target="_blank" rel="noopener noreferrer"><i className="fas fa-eye me-2 fasfaeye" aria-hidden="true"></i></a> : ''}
                            | <a id="lnkCIBILFileUpload" href="#" data-bs-toggle="modal" data-bs-target="#CIBILFileUpload">+ Upload</a>
                        </Col>
                    </Row>
                    <hr />
                    <div className="row mt-2">
                        <div className="col-lg-12 mb-4">
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    {LeadCIBILList.filter(x => x.CibilScore != '').map((item, idx) => (
                                        <tr key={idx}>
                                            {idx == 0 ?
                                                <td>
                                                    <div data-repeater-list="group-a">
                                                        <Row data-repeater-item>
                                                            {LeadCIBILList.length > 0 ?
                                                                <Col lg="2" className="mb-3">
                                                                    <Label htmlFor="name">
                                                                        Date of Report Pulled Out
                                                                    </Label>
                                                                    <Input
                                                                        type="date"
                                                                        id="txtCIBILDateofReportPulledOut"
                                                                        name="untyped-input"
                                                                        defaultValue={item.DateofReportPulledOut}
                                                                        placeholder="Date of Report Pulled Out"
                                                                    />
                                                                </Col> : ''}
                                                            {LeadCIBILList.length > 0 ? <Col lg="1" className="mb-3">
                                                                <Label htmlFor="name">Cibil Score </Label>
                                                                <Input
                                                                    type="text"
                                                                    id="txtCIBILScore"
                                                                    name="untyped-input"
                                                                    defaultValue={item.CibilScore}
                                                                    placeholder="Cibil Score"
                                                                />
                                                            </Col> : ''}
                                                            {LeadCIBILList.length > 0 ? <Col lg="3" className="mb-3">
                                                                <Label htmlFor="name">
                                                                    Name of Borrower as per CIR Report Pulled Out{" "}

                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    id="txtCIBILNameofBorrower"
                                                                    name="untyped-input"
                                                                    defaultValue={item.NameofBorrower}
                                                                    placeholder="Name of Borrower as per CIR Report Pulled Out"
                                                                />
                                                            </Col> : ''}
                                                            {LeadCIBILList.length > 0 ? <Col lg="2" className="mb-3">
                                                                <Label htmlFor="name">
                                                                    PAN no. as per CIR
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    id="txtCIBILPANNo"
                                                                    name="untyped-input"
                                                                    defaultValue={item.PANNo}
                                                                    placeholder="PAN no. as per CIR"
                                                                />
                                                            </Col> : ''}
                                                            {LeadCIBILList.length > 0 ? <Col lg="2" className="mb-3">
                                                                <Label htmlFor="name">
                                                                    Additional KYC as per CIR
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    id="txtCIBILAdditionalKYCCIR"
                                                                    name="untyped-input"
                                                                    defaultValue={item.AdditionalKYCCIR}
                                                                    placeholder="Additional KYC as per CIR"
                                                                />
                                                            </Col> : ''}
                                                            {LeadCIBILList.length > 0 ? <Col lg="2" className="mb-3">
                                                                <Label htmlFor="name">Enquiry</Label>
                                                                <Input
                                                                    type="text"
                                                                    id="txtCIBILEnquiry"
                                                                    name="untyped-input"
                                                                    defaultValue={item.Enquiry}
                                                                    placeholder="Enquiry"
                                                                />
                                                            </Col> : ''}
                                                            {LeadCIBILList.length > 0 ? <Col lg="12" className="mb-3">
                                                                <hr />
                                                            </Col>
                                                                : ''}
                                                        </Row>
                                                    </div>
                                                </td>
                                                : ""}
                                        </tr>
                                    ))}
                                    {LeadCIBILList.map((item, idx) => (
                                        <tr key={idx}>
                                            <td>
                                                <div className="accordion mb-3" id="accordionExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id={'CIBILH' + idx}>
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#CIBILC' + idx} aria-expanded="true" aria-controls={'CIBILC' + idx}>
                                                                CIBIL Account #{idx + 1}
                                                            </button>
                                                        </h2>
                                                        <div id={'CIBILC' + idx} className="accordion-collapse collapse" aria-labelledby={'CIBILH' + idx} data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <div data-repeater-list="group-a">
                                                                    <Row data-repeater-item>

                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Member (Lender) Name
                                                                            </Label>
                                                                            <select className="form-select" id={item.MemberIdtxtId}>
                                                                                <option value={"0"}>Select Member</option>
                                                                                {ChannelMasterList.map((VAccountType) => (
                                                                                    <option key={VAccountType.ChannelMasterId} value={VAccountType.ChannelMasterId} selected={VAccountType.ChannelMasterId == item.MemberId}> {VAccountType.Title} </option>
                                                                                ))}
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">Account Type </Label>
                                                                            <select className="form-select" Id={item.AccountTypetxtId}>
                                                                                <option value={"0"}>Select Account Type</option>
                                                                                {AccountTypeList.map((VAccountType) => (
                                                                                    <option key={VAccountType.AccountTypeId} value={VAccountType.AccountTypeId} selected={VAccountType.AccountTypeId == item.AccountTypeId}> {VAccountType.AccountTypeName} </option>
                                                                                ))}
                                                                            </select>

                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Loan number
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.AccountNumbertxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.AccountNumber}
                                                                                placeholder="Loan number"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Ownership Indicator
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.OwnershipIndicatortxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.OwnershipIndicator}
                                                                                placeholder="Ownership Indicator"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Credit Limit (if Credit Card)
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.CreditLimittxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.CreditLimit}
                                                                                placeholder="Credit Limit (if Credit Card)"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Sanctioned Amount
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.HighCredittxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.HighCredit}
                                                                                placeholder="Sanctioned Amount"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Current Balance
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.CurrentBalancetxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.CurrentBalance}
                                                                                placeholder="Current Balance"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Amount Overdue
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.AmountOverduetxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.AmountOverdue}
                                                                                placeholder="Amount Overdue"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                No. of DPDs for Latest Month Reported
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.DPDsforLatestMonthtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DPDsforLatestMonth}
                                                                                placeholder="No. of DPDs for Latest Month Reported"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Date Opened / Disbursed
                                                                            </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={item.DateOpenedtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DateOpened}
                                                                                placeholder="Date Opened / Disbursed"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">Date Closed </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={item.DateClosedtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DateClosed}
                                                                                placeholder="Date Closed"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Date of Last Payment
                                                                            </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={item.DateofLastPaymenttxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DateofLastPayment}
                                                                                placeholder="Date of Last Payment"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Date Reported
                                                                            </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={item.DateReportedtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DateReported}
                                                                                placeholder="Date Reported"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Suite Filed/Wilful Default
                                                                            </Label>
                                                                            <select className="form-select" id={item.SuiteFiledtxtId}>
                                                                                <option value={"0"}>Select Suite File Status</option>
                                                                                {SuiteStatusList.map((VAccountType) => (
                                                                                    <option key={VAccountType.Id} value={VAccountType.Id} selected={VAccountType.Id == item.SuiteFiled}> {VAccountType.Title} </option>
                                                                                ))}
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Credit Facility Status
                                                                            </Label>
                                                                            <select className="form-select" id={item.CreditFacilityStatustxtId}>
                                                                                <option value={"0"}>Select Suite File Status</option>
                                                                                {CreditFacilityStatusList.map((VCreditFacilityStatus) => (
                                                                                    <option key={VCreditFacilityStatus.Id} value={VCreditFacilityStatus.Id} selected={VCreditFacilityStatus.Id == item.CreditFacilityStatus}> {VCreditFacilityStatus.Title} </option>
                                                                                ))}
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Written of Amount
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.WrittenofAmounttxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.WrittenofAmount}
                                                                                placeholder="Written of Amount"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Settlement Amount
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.SettlementAmounttxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.SettlementAmount}
                                                                                placeholder="Settlement Amount"
                                                                            />
                                                                        </Col>

                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Total Non Reporting Month
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.TotalNonReportingMonthtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.TotalNonReportingMonth}
                                                                                placeholder="Total Non Reporting Month"
                                                                            />
                                                                        </Col>

                                                                        <Col lg="1" className="align-self-center">
                                                                            <div className="d-grid w-17">
                                                                                <Button
                                                                                    onClick={e => CIBILhandleRemoveRow(e, item.RowId, item.LeadEnrollCIBILId)}
                                                                                    color="danger"
                                                                                    style={{ width: "60% !important" }}
                                                                                    className="btn-sm mt-3"
                                                                                >
                                                                                    {" "}
                                                                                    <i className="fas fa-trash-alt"></i>
                                                                                </Button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr className="mt-2">
                                        <td>
                                            <div data-repeater-list="group-a">
                                                <Row data-repeater-item>
                                                    {LeadCIBILList.length == 0 ? <Col lg="2" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Date of Report Pulled Out
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            id="txtCIBILDateofReportPulledOut"
                                                            name="untyped-input"
                                                            placeholder="Date of Report Pulled Out"
                                                        />
                                                    </Col> : ''}
                                                    {LeadCIBILList.length == 0 ? <Col lg="1" className="mb-3">
                                                        <Label htmlFor="name">Cibil Score</Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCIBILScore"
                                                            name="untyped-input"
                                                            placeholder="Cibil Score"
                                                        />
                                                    </Col> : ''}
                                                    {LeadCIBILList.length == 0 ? <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Name of Borrower as per CIR Report Pulled Out
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCIBILNameofBorrower"
                                                            name="untyped-input"
                                                            placeholder="Name of Borrower as per CIR Report Pulled Out"
                                                        />
                                                    </Col> : ''}
                                                    {LeadCIBILList.length == 0 ? <Col lg="2" className="mb-3">
                                                        <Label htmlFor="name">
                                                            PAN no. as per CIR
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCIBILPANNo"
                                                            name="untyped-input"
                                                            placeholder="PAN no. as per CIR"
                                                        />
                                                    </Col> : ''}
                                                    {LeadCIBILList.length == 0 ? <Col lg="2" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Additional KYC as per CIR
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCIBILAdditionalKYCCIR"
                                                            name="untyped-input"
                                                            placeholder="Additional KYC as per CIR"
                                                        />
                                                    </Col> : ''}
                                                    {LeadCIBILList.length == 0 ? <Col lg="2" className="mb-3">
                                                        <Label htmlFor="name">Enquiry</Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCIBILEnquiry"
                                                            name="untyped-input"
                                                            placeholder="Enquiry"
                                                        />
                                                    </Col> : ''}
                                                    {LeadCIBILList.length == 0 ? <Col lg="12" className="mb-3">
                                                        <hr />
                                                    </Col> : ''}
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Member (Lender) Name
                                                        </Label>
                                                        <select className="form-select" id="ddlCIBILMember" onChange={(e) => setCheckLeadCIBILValid(1)}>
                                                            <option value={"0"}>Select Member</option>
                                                            {ChannelMasterList.map((VAccountType) => (
                                                                <option key={VAccountType.ChannelMasterId} value={VAccountType.ChannelMasterId}> {VAccountType.Title} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">Account Type</Label>
                                                        <select className="form-select" Id="ddlCIBILAccountType" onChange={(e) => setCheckLeadCIBILValid(1)}>
                                                            <option value={"0"}>Select Account Type</option>
                                                            {AccountTypeList.map((state) => (
                                                                <option key={state.AccountTypeId} value={state.AccountTypeId}> {state.AccountTypeName} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Loan number
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCIBILAccountNumber"
                                                            name="untyped-input"
                                                            placeholder="Loan number"
                                                            onChange={(e) => setCheckLeadCIBILValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Ownership Indicator
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCIBILOwnershipIndicator"
                                                            name="untyped-input"
                                                            placeholder="Ownership Indicator"
                                                            onChange={(e) => setCheckLeadCIBILValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Credit Limit (if Credit Card)
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCIBILCreditLimit"
                                                            name="untyped-input"
                                                            placeholder="Credit Limit (if Credit Card)"
                                                            onChange={(e) => setCheckLeadCIBILValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Sanctioned Amount
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCIBILHighCredit"
                                                            name="untyped-input"
                                                            placeholder="Sanctioned Amount"
                                                            onChange={(e) => setCheckLeadCIBILValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Current Balance
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCIBILCurrentBalance"
                                                            name="untyped-input"
                                                            placeholder="Current Balance"
                                                            onChange={(e) => setCheckLeadCIBILValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Amount Overdue
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCIBILAmountOverdue"
                                                            name="untyped-input"
                                                            placeholder="Amount Overdue"
                                                            onChange={(e) => setCheckLeadCIBILValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            No. of DPDs for Latest Month Reported
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCIBILDPDsforLatestMonth"
                                                            name="untyped-input"
                                                            placeholder="No. of DPDs for Latest Month Reported"
                                                            onChange={(e) => setCheckLeadCIBILValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Date Opened / Disbursed
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            id="txtCIBILDateOpened"
                                                            name="untyped-input"
                                                            placeholder="Date Opened / Disbursed"
                                                            onChange={(e) => setCheckLeadCIBILValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">Date Closed</Label>
                                                        <Input
                                                            type="date"
                                                            id="txtCIBILDateClosed"
                                                            name="untyped-input"
                                                            placeholder="Date Closed"
                                                            onChange={(e) => setCheckLeadCIBILValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Date of Last Payment
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            id="txtCIBILDateofLastPayment"
                                                            name="untyped-input"
                                                            placeholder="Date of Last Payment"
                                                            onChange={(e) => setCheckLeadCIBILValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Date Reported
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            id="txtCIBILDateReported"
                                                            name="untyped-input"
                                                            placeholder="Date Reported"
                                                            onChange={(e) => setCheckLeadCIBILValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Suite Filed/Wilful Default
                                                        </Label>
                                                        <select className="form-select" id="ddlCIBILSuiteFiled" onChange={(e) => setCheckLeadCIBILValid(1)}>
                                                            <option value={"0"}>Select Suite File Status</option>
                                                            {SuiteStatusList.map((VAccountType) => (
                                                                <option key={VAccountType.Id} value={VAccountType.Id}> {VAccountType.Title} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Credit Facility Status
                                                        </Label>
                                                        <select className="form-select" id="ddlCIBILCreditFacilityStatus" onChange={(e) => setCheckLeadCIBILValid(1)}>
                                                            <option value={"0"}>Select Suite File Status</option>
                                                            {CreditFacilityStatusList.map((VCreditFacilityStatus) => (
                                                                <option key={VCreditFacilityStatus.Id} value={VCreditFacilityStatus.Id}> {VCreditFacilityStatus.Title} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Written of Amount
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCIBILWrittenofAmount"
                                                            name="untyped-input"
                                                            placeholder="Written of Amount"
                                                            onChange={(e) => setCheckLeadCIBILValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Settlement Amount
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCIBILSettlementAmount"
                                                            name="untyped-input"
                                                            placeholder="Settlement Amount"
                                                            onChange={(e) => setCheckLeadCIBILValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Total Non Reporting Month
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCIBILTotalNonReportingMonth"
                                                            name="untyped-input"
                                                            placeholder="Total Non Reporting Month"
                                                            onChange={(e) => setCheckLeadCIBILValid(1)}
                                                        />
                                                    </Col>

                                                </Row>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Row>
                                                <div className="col-lg-1">
                                                    <div className="d-grid w-11">
                                                        <Button onClick={LeadCIBILAdd} color="primary" className="mt-3 mt-lg-0 btn-sm">
                                                            <i className="fas fa-plus me-2"></i>
                                                            Add{" "}
                                                        </Button>{" "}
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-1">
                                            <div className="d-grid w-17">
                                            <Button onClick={LeadCIBILSave} color="primary" className="mt-5 mt-lg-0 btn-sm">
                                                Save{" "}
                                            </Button>{" "}
                                            </div>
                                        </div> */}
                                            </Row>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <Row>
                        <Col lg="10"> <h5>Eqifax <input className="form-check-input ms-2" type="checkbox" id="chkSameEqifax" onChange={SameEqifaxhandleChange} /><Label className="chkissame">Is Same CIBIL ?</Label></h5></Col>
                        <Col lg="2" className="text-end">
                            {ISUploadEqifaxDocument > 0 ? <a href={EqifaxDocumentFilePath} target="_blank" rel="noopener noreferrer" ><i className="fas fa-eye me-2 fasfaeye" aria-hidden="true"></i></a> : ''}
                            | <a id="lnkEqifaxFileUpload" href="#" data-bs-toggle="modal" data-bs-target="#EqifaxFileUpload">+ Upload</a>
                        </Col>
                    </Row>
                    <hr className="mb-2" />
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    {LeadEqifaxList.filter(x => x.EqifaxScore != '').map((item, idx) => (
                                        <tr key={idx}>
                                            {idx == 0 ?
                                                <td>
                                                    <div data-repeater-list="group-a">
                                                        <Row data-repeater-item>
                                                            {LeadEqifaxList.length > 0 ? <Col lg="2" className="mb-3">
                                                                <Label htmlFor="name">
                                                                    Date of Report Pulled Out
                                                                </Label>
                                                                <Input
                                                                    type="date"
                                                                    id={item.DateofReportPulledOuttxtId}
                                                                    name="untyped-input"
                                                                    defaultValue={item.DateofReportPulledOut}
                                                                    placeholder="Date of Report Pulled Out"
                                                                />
                                                            </Col> : ''}
                                                            {LeadEqifaxList.length > 0 ? <Col lg="1" className="mb-3">
                                                                <Label htmlFor="name">Eqifax Score </Label>
                                                                <Input
                                                                    type="text"
                                                                    id={item.EqifaxScoretxtId}
                                                                    name="untyped-input"
                                                                    defaultValue={item.EqifaxScore}
                                                                    placeholder="Eqifaxl Score"
                                                                />
                                                            </Col> : ''}
                                                            {LeadEqifaxList.length > 0 ? <Col lg="3" className="mb-3">
                                                                <Label htmlFor="name">
                                                                    Name of Borrower as per CIR Report Pulled Out{" "}

                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    id={item.NameofBorrowertxtId}
                                                                    name="untyped-input"
                                                                    defaultValue={item.NameofBorrower}
                                                                    placeholder="Name of Borrower as per CIR Report Pulled Out"
                                                                />
                                                            </Col> : ''}
                                                            {LeadEqifaxList.length > 0 ? <Col lg="2" className="mb-3">
                                                                <Label htmlFor="name">
                                                                    PAN no. as per CIR
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    id={item.PANNotxtId}
                                                                    name="untyped-input"
                                                                    defaultValue={item.PANNo}
                                                                    placeholder="PAN no. as per CIR"
                                                                />
                                                            </Col> : ''}
                                                            {LeadEqifaxList.length > 0 ? <Col lg="2" className="mb-3">
                                                                <Label htmlFor="name">
                                                                    Additional KYC as per CIR
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    id={item.AdditionalKYCCIRtxtId}
                                                                    name="untyped-input"
                                                                    defaultValue={item.AdditionalKYCCIR}
                                                                    placeholder="Additional KYC as per CIR"
                                                                />
                                                            </Col> : ''}
                                                            {LeadEqifaxList.length > 0 ? <Col lg="2" className="mb-3">
                                                                <Label htmlFor="name">Enquiry</Label>
                                                                <Input
                                                                    type="text"
                                                                    id={item.EnquirytxtId}
                                                                    name="untyped-input"
                                                                    defaultValue={item.Enquiry}
                                                                    placeholder="Enquiry"
                                                                />
                                                            </Col> : ''}
                                                        </Row>
                                                    </div>
                                                    <hr className="mb-2" />
                                                </td>
                                                : ""}
                                        </tr>
                                    ))}
                                    {LeadEqifaxList.map((item, idx) => (
                                        <tr key={idx}>
                                            <td>
                                                <div className="accordion mb-3" id="accordionExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id={'EqifaxH' + idx}>
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#EqifaxC' + idx} aria-expanded="true" aria-controls={'EqifaxC' + idx}>
                                                                Eqifax Account #{idx + 1}
                                                            </button>
                                                        </h2>
                                                        <div id={'EqifaxC' + idx} className="accordion-collapse collapse" aria-labelledby={'EqifaxH' + idx} data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <div data-repeater-list="group-a">
                                                                    <Row data-repeater-item>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Member (Lender) Name
                                                                            </Label>
                                                                            <select className="form-select" id={item.MemberIdtxtId}>
                                                                                <option value={"0"}>Select Member</option>
                                                                                {ChannelMasterList.map((VAccountType) => (
                                                                                    <option key={VAccountType.ChannelMasterId} value={VAccountType.ChannelMasterId} selected={VAccountType.ChannelMasterId == item.MemberId}> {VAccountType.Title} </option>
                                                                                ))}
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">Account Type </Label>
                                                                            <select className="form-select" Id={item.AccountTypetxtId}>
                                                                                <option value={"0"}>Select Account Type</option>
                                                                                {AccountTypeList.map((state) => (
                                                                                    <option key={state.AccountTypeId} value={state.AccountTypeId} selected={state.AccountTypeId == item.AccountTypeId}> {state.AccountTypeName} </option>
                                                                                ))}
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Loan number
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.AccountNumbertxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.AccountNumber}
                                                                                placeholder="Loan number"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Ownership Indicator
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.OwnershipIndicatortxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.OwnershipIndicator}
                                                                                placeholder="Ownership Indicator"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Credit Limit (if Credit Card)
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.CreditLimittxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.CreditLimit}
                                                                                placeholder="Credit Limit (if Credit Card)"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Sanctioned Amount
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.HighCredittxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.HighCredit}
                                                                                placeholder="Sanctioned Amount"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Current Balance
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.CurrentBalancetxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.CurrentBalance}
                                                                                placeholder="Current Balance"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Amount Overdue
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.AmountOverduetxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.AmountOverdue}
                                                                                placeholder="Amount Overdue"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                No. of DPDs for Latest Month Reported
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.DPDsforLatestMonthtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DPDsforLatestMonth}
                                                                                placeholder="No. of DPDs for Latest Month Reported"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Date Opened / Disbursed
                                                                            </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={item.DateOpenedtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DateOpened}
                                                                                placeholder="Date Opened / Disbursed"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">Date Closed </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={item.DateClosedtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DateClosed}
                                                                                placeholder="Date Closed"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Date of Last Payment
                                                                            </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={item.DateofLastPaymenttxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DateofLastPayment}
                                                                                placeholder="Date of Last Payment"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Date Reported
                                                                            </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={item.DateReportedtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DateReported}
                                                                                placeholder="Date Reported"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Suite Filed/Wilful Default
                                                                            </Label>
                                                                            <select className="form-select" id={item.SuiteFiledtxtId}>
                                                                                <option value={"0"}>Select Suite File Status</option>
                                                                                {SuiteStatusList.map((VAccountType) => (
                                                                                    <option key={VAccountType.Id} value={VAccountType.Id}> {VAccountType.Title} </option>
                                                                                ))}
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Credit Facility Status
                                                                            </Label>
                                                                            <select className="form-select" id={item.CreditFacilityStatustxtId}>
                                                                                <option value={"0"}>Select Suite File Status</option>
                                                                                {CreditFacilityStatusList.map((VCreditFacilityStatus) => (
                                                                                    <option key={VCreditFacilityStatus.Id} value={VCreditFacilityStatus.Id} selected={VCreditFacilityStatus.Id == item.CreditFacilityStatus}> {VCreditFacilityStatus.Title} </option>
                                                                                ))}
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Written of Amount
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.WrittenofAmounttxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.WrittenofAmount}
                                                                                placeholder="Written of Amount"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Settlement Amount
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.SettlementAmounttxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.SettlementAmount}
                                                                                placeholder="Settlement Amount"
                                                                            />
                                                                        </Col>

                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Total Non Reporting Month
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.TotalNonReportingMonthtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.TotalNonReportingMonth}
                                                                                placeholder="Total Non Reporting Month"
                                                                            />
                                                                        </Col>

                                                                        <Col lg="1" className="align-self-center">
                                                                            <div className="d-grid w-17">
                                                                                <Button
                                                                                    onClick={e => EqifaxhandleRemoveRow(e, item.RowId, item.LeadEnrollEqifaxId)}
                                                                                    color="danger"
                                                                                    style={{ width: "60% !important" }}
                                                                                    className="btn-sm mt-3"
                                                                                >
                                                                                    {" "}
                                                                                    <i className="fas fa-trash-alt"></i>
                                                                                </Button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td>
                                            <div data-repeater-list="group-a">
                                                <Row data-repeater-item >
                                                    {LeadEqifaxList.length == 0 ? <Col lg="2" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Date of Report Pulled Out
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            id="txtEqifaxDateofReportPulledOut"
                                                            name="untyped-input"
                                                            placeholder="Date of Report Pulled Out"
                                                        />
                                                    </Col> : ''}
                                                    {LeadEqifaxList.length == 0 ? <Col lg="1" className="mb-3">
                                                        <Label htmlFor="name">Eqifaxl Score</Label>
                                                        <Input
                                                            type="text"
                                                            id="txtEqifaxScore"
                                                            name="untyped-input"
                                                            placeholder="Eqifaxl Score"
                                                        />
                                                    </Col> : ''}
                                                    {LeadEqifaxList.length == 0 ? <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Name of Borrower as per CIR Report Pulled Out
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtEqifaxNameofBorrower"
                                                            name="untyped-input"
                                                            placeholder="Name of Borrower as per CIR Report Pulled Out"
                                                        />
                                                    </Col> : ''}
                                                    {LeadEqifaxList.length == 0 ? <Col lg="2" className="mb-3">
                                                        <Label htmlFor="name">
                                                            PAN no. as per CIR
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtEqifaxPANNo"
                                                            name="untyped-input"
                                                            placeholder="PAN no. as per CIR"
                                                        />
                                                    </Col> : ''}
                                                    {LeadEqifaxList.length == 0 ? <Col lg="2" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Additional KYC as per CIR
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtEqifaxAdditionalKYCCIR"
                                                            name="untyped-input"
                                                            placeholder="Additional KYC as per CIR"
                                                        />
                                                    </Col> : ''}
                                                    {LeadEqifaxList.length == 0 ? <Col lg="2" className="mb-3">
                                                        <Label htmlFor="name">Enquiry</Label>
                                                        <Input
                                                            type="text"
                                                            id="txtEqifaxEnquiry"
                                                            name="untyped-input"
                                                            placeholder="Enquiry"
                                                        />
                                                    </Col> : ''}
                                                    {LeadEqifaxList.length == 0 ? <Col lg="12" className="mb-3">
                                                        <hr />
                                                    </Col> : ''}
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Member (Lender) Name
                                                        </Label>
                                                        <select className="form-select" id="ddlEqifaxMember" onChange={(e) => setCheckLeadEqifaxValid(1)}>
                                                            <option value={"0"}>Select Member</option>
                                                            {ChannelMasterList.map((VAccountType) => (
                                                                <option key={VAccountType.ChannelMasterId} value={VAccountType.ChannelMasterId}> {VAccountType.Title} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">Account Type</Label>
                                                        <select className="form-select" Id="ddlEqifaxAccountType" onChange={(e) => setCheckLeadEqifaxValid(1)}>
                                                            <option value={"0"}>Select Account Type</option>
                                                            {AccountTypeList.map((state) => (
                                                                <option key={state.AccountTypeId} value={state.AccountTypeId}> {state.AccountTypeName} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Loan number
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtEqifaxAccountNumber"
                                                            name="untyped-input"
                                                            placeholder="Loan number"
                                                            onChange={(e) => setCheckLeadEqifaxValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Ownership Indicator
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtEqifaxOwnershipIndicator"
                                                            name="untyped-input"
                                                            placeholder="Ownership Indicator"
                                                            onChange={(e) => setCheckLeadEqifaxValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Credit Limit (if Credit Card)
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtEqifaxCreditLimit"
                                                            name="untyped-input"
                                                            placeholder="Credit Limit (if Credit Card)"
                                                            onChange={(e) => setCheckLeadEqifaxValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Sanctioned Amount
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtEqifaxHighCredit"
                                                            name="untyped-input"
                                                            placeholder="Sanctioned Amount"
                                                            onChange={(e) => setCheckLeadEqifaxValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Current Balance
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtEqifaxCurrentBalance"
                                                            name="untyped-input"
                                                            placeholder="Current Balance"
                                                            onChange={(e) => setCheckLeadEqifaxValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Amount Overdue
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtEqifaxAmountOverdue"
                                                            name="untyped-input"
                                                            placeholder="Amount Overdue"
                                                            onChange={(e) => setCheckLeadEqifaxValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            No. of DPDs for Latest Month Reported
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtEqifaxDPDsforLatestMonth"
                                                            name="untyped-input"
                                                            placeholder="No. of DPDs for Latest Month Reported"
                                                            onChange={(e) => setCheckLeadEqifaxValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Date Opened / Disbursed
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            id="txtEqifaxDateOpened"
                                                            name="untyped-input"
                                                            placeholder="Date Opened / Disbursed"
                                                            onChange={(e) => setCheckLeadEqifaxValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">Date Closed</Label>
                                                        <Input
                                                            type="date"
                                                            id="txtEqifaxDateClosed"
                                                            name="untyped-input"
                                                            placeholder="Date Closed"
                                                            onChange={(e) => setCheckLeadEqifaxValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Date of Last Payment
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            id="txtEqifaxDateofLastPayment"
                                                            name="untyped-input"
                                                            placeholder="Date of Last Payment"
                                                            onChange={(e) => setCheckLeadEqifaxValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Date Reported
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            id="txtEqifaxDateReported"
                                                            name="untyped-input"
                                                            placeholder="Date Reported"
                                                            onChange={(e) => setCheckLeadEqifaxValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Suite Filed/Wilful Default
                                                        </Label>
                                                        <select className="form-select" id="ddlEqifaxSuiteFiled" onChange={(e) => setCheckLeadEqifaxValid(1)}>
                                                            <option value={"0"}>Select Suite File Status</option>
                                                            {SuiteStatusList.map((VAccountType) => (
                                                                <option key={VAccountType.Id} value={VAccountType.Id}> {VAccountType.Title} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Credit Facility Status
                                                        </Label>
                                                        <select className="form-select" id="ddlEqifaxCreditFacilityStatus" onChange={(e) => setCheckLeadEqifaxValid(1)}>
                                                            <option value={"0"}>Select Suite File Status</option>
                                                            {CreditFacilityStatusList.map((VCreditFacilityStatus) => (
                                                                <option key={VCreditFacilityStatus.Id} value={VCreditFacilityStatus.Id}> {VCreditFacilityStatus.Title} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Written of Amount
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtEqifaxWrittenofAmount"
                                                            name="untyped-input"
                                                            placeholder="Written of Amount"
                                                            onChange={(e) => setCheckLeadEqifaxValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Settlement Amount
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtEqifaxSettlementAmount"
                                                            name="untyped-input"
                                                            placeholder="Settlement Amount"
                                                            onChange={(e) => setCheckLeadEqifaxValid(1)}
                                                        />
                                                    </Col>

                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Total Non Reporting Month
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtEqifaxTotalNonReportingMonth"
                                                            name="untyped-input"
                                                            placeholder="Total Non Reporting Month"
                                                            onChange={(e) => setCheckLeadEqifaxValid(1)}
                                                        />
                                                    </Col>

                                                </Row>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Row>
                                                <div className="col-lg-1">
                                                    <div className="d-grid w-11">
                                                        <Button onClick={LeadEqifaxAdd} color="primary" className="mt-3 mt-lg-0 btn-sm">
                                                            <i className="fas fa-plus me-2"></i>
                                                            Add{" "}
                                                        </Button>{" "}
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-1">
                                            <div className="d-grid w-17">
                                            <Button onClick={LeadEqifaxSave} color="primary" className="mt-5 mt-lg-0 btn-sm">
                                                Save{" "}
                                            </Button>{" "}
                                            </div>
                                        </div> */}
                                            </Row>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <Row>
                        <Col lg="10"><h5>Experian<input className="form-check-input ms-2" type="checkbox" id="chkSameExperian" onChange={SameExperianhandleChange} /><Label className="chkissame">Is Same CIBIL ?</Label></h5></Col>
                        <Col lg="2" className="text-end">
                            {ISUploadExperianDocument > 0 ? <a href={ExperianDocumentFilePath} target="_blank" rel="noopener noreferrer"><i className="fas fa-eye me-2 fasfaeye" aria-hidden="true"></i></a> : ''}
                            | <a id="lnkExperianFileUpload" href="#" data-bs-toggle="modal" data-bs-target="#ExperianFileUpload">+ Upload</a>
                        </Col>
                    </Row>
                    <hr className="mb-2" />
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    {LeadExperianList.filter(x => x.EqifaxScore != '').map((item, idx) => (
                                        <tr key={idx}>
                                            {idx == 0 ?
                                                <td>
                                                    <div data-repeater-list="group-a">
                                                        <Row data-repeater-item>
                                                            {LeadExperianList.length > 0 ?
                                                                <Col lg="2" className="mb-2">
                                                                    <Label htmlFor="name">
                                                                        Date of Report Pulled Out
                                                                    </Label>
                                                                    <Input
                                                                        type="date"
                                                                        id={item.DateofReportPulledOuttxtId}
                                                                        name="untyped-input"
                                                                        defaultValue={item.DateofReportPulledOut}
                                                                        placeholder="Date of Report Pulled Out"
                                                                    />
                                                                </Col>
                                                                : ''}
                                                            {LeadExperianList.length > 0 ?
                                                                <Col lg="1" className="mb-3">
                                                                    <Label htmlFor="name">Exp. Score </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id={item.ExperianScoretxtId}
                                                                        name="untyped-input"
                                                                        defaultValue={item.ExperianScore}
                                                                        placeholder="Exp. Score"
                                                                    />
                                                                </Col>
                                                                : ''}
                                                            {LeadExperianList.length > 0 ?
                                                                <Col lg="3" className="mb-3">
                                                                    <Label htmlFor="name">
                                                                        Name of Borrower as per CIR Report Pulled Out{" "}

                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id={item.NameofBorrowertxtId}
                                                                        name="untyped-input"
                                                                        defaultValue={item.NameofBorrower}
                                                                        placeholder="Name of Borrower as per CIR Report Pulled Out"
                                                                    />
                                                                </Col>
                                                                : ''}
                                                            {LeadExperianList.length > 0 ?
                                                                <Col lg="2" className="mb-3">
                                                                    <Label htmlFor="name">
                                                                        PAN no. as per CIR
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id={item.PANNotxtId}
                                                                        name="untyped-input"
                                                                        defaultValue={item.PANNo}
                                                                        placeholder="PAN no. as per CIR"
                                                                    />
                                                                </Col>
                                                                : ''}
                                                            {LeadExperianList.length > 0 ?
                                                                <Col lg="2" className="mb-3">
                                                                    <Label htmlFor="name">
                                                                        Additional KYC as per CIR
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id={item.AdditionalKYCCIRtxtId}
                                                                        name="untyped-input"
                                                                        defaultValue={item.AdditionalKYCCIR}
                                                                        placeholder="Additional KYC as per CIR"
                                                                    />
                                                                </Col>
                                                                : ''}
                                                            {LeadExperianList.length > 0 ?
                                                                <Col lg="2" className="mb-3">
                                                                    <Label htmlFor="name">Enquiry</Label>
                                                                    <Input
                                                                        type="text"
                                                                        id={item.EnquirytxtId}
                                                                        name="untyped-input"
                                                                        defaultValue={item.Enquiry}
                                                                        placeholder="Enquiry"
                                                                    />
                                                                </Col>
                                                                : ''}

                                                        </Row>
                                                    </div>
                                                    <hr className="mb-2" />
                                                </td>
                                                : ""}
                                        </tr>
                                    ))}
                                    {LeadExperianList.map((item, idx) => (
                                        <tr key={idx}>
                                            <td>
                                                <div className="accordion mb-3" id="accordionExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id={'ExperianH' + idx}>
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#ExperianC' + idx} aria-expanded="true" aria-controls={'ExperianC' + idx}>
                                                                Experian Account #{idx + 1}
                                                            </button>
                                                        </h2>
                                                        <div id={'ExperianC' + idx} className="accordion-collapse collapse" aria-labelledby={'ExperianH' + idx} data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <div data-repeater-list="group-a">
                                                                    <Row data-repeater-item>

                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Member (Lender) Name
                                                                            </Label>
                                                                            <select className="form-select" id={item.MemberIdtxtId}>
                                                                                <option value={"0"}>Select Member</option>
                                                                                {ChannelMasterList.map((VAccountType) => (
                                                                                    <option key={VAccountType.ChannelMasterId} value={VAccountType.ChannelMasterId} selected={VAccountType.ChannelMasterId == item.MemberId}> {VAccountType.Title} </option>
                                                                                ))}
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">Account Type </Label>
                                                                            <select className="form-select" Id={item.AccountTypetxtId}>
                                                                                <option value={"0"}>Select Account Type</option>
                                                                                {AccountTypeList.map((state) => (
                                                                                    <option key={state.AccountTypeId} value={state.AccountTypeId} selected={state.AccountTypeId == item.AccountTypeId}> {state.AccountTypeName} </option>
                                                                                ))}
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Loan number
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.AccountNumbertxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.AccountNumber}
                                                                                placeholder="Loan number"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Ownership Indicator
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.OwnershipIndicatortxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.OwnershipIndicator}
                                                                                placeholder="Ownership Indicator"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Credit Limit (if Credit Card)
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.CreditLimittxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.CreditLimit}
                                                                                placeholder="Credit Limit (if Credit Card)"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Sanctioned Amount
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.HighCredittxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.HighCredit}
                                                                                placeholder="Sanctioned Amount"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Current Balance
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.CurrentBalancetxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.CurrentBalance}
                                                                                placeholder="Current Balance"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Amount Overdue
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.AmountOverduetxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.AmountOverdue}
                                                                                placeholder="Amount Overdue"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                No. of DPDs for Latest Month Reported
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.DPDsforLatestMonthtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DPDsforLatestMonth}
                                                                                placeholder="No. of DPDs for Latest Month Reported"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Date Opened / Disbursed
                                                                            </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={item.DateOpenedtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DateOpened}
                                                                                placeholder="Date Opened / Disbursed"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">Date Closed </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={item.DateClosedtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DateClosed}
                                                                                placeholder="Date Closed"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Date of Last Payment
                                                                            </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={item.DateofLastPaymenttxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DateofLastPayment}
                                                                                placeholder="Date of Last Payment"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Date Reported
                                                                            </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={item.DateReportedtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DateReported}
                                                                                placeholder="Date Reported"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Suite Filed/Wilful Default
                                                                            </Label>
                                                                            <select className="form-select" id={item.SuiteFiledtxtId}>
                                                                                <option value={"0"}>Select Suite File Status</option>
                                                                                {SuiteStatusList.map((VAccountType) => (
                                                                                    <option key={VAccountType.Id} value={VAccountType.Id} selected={VAccountType.Id == item.SuiteFiled}> {VAccountType.Title} </option>
                                                                                ))}
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Credit Facility Status
                                                                            </Label>
                                                                            <select className="form-select" id={item.CreditFacilityStatustxtId}>
                                                                                <option value={"0"}>Select Suite File Status</option>
                                                                                {CreditFacilityStatusList.map((VCreditFacilityStatus) => (
                                                                                    <option key={VCreditFacilityStatus.Id} value={VCreditFacilityStatus.Id} selected={VCreditFacilityStatus.Id == item.CreditFacilityStatus}> {VCreditFacilityStatus.Title} </option>
                                                                                ))}
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Written of Amount
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.WrittenofAmounttxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.WrittenofAmount}
                                                                                placeholder="Written of Amount"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Settlement Amount
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.SettlementAmounttxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.SettlementAmount}
                                                                                placeholder="Written of Amount"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Total Non Reporting Month
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.TotalNonReportingMonthtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.TotalNonReportingMonth}
                                                                                placeholder="Total Non Reporting Month"
                                                                            />
                                                                        </Col>

                                                                        <Col lg="1" className="align-self-center">
                                                                            <div className="d-grid w-17">
                                                                                <Button
                                                                                    onClick={e => ExperianhandleRemoveRow(e, item.RowId, item.LeadEnrollExperianId)}
                                                                                    color="danger"
                                                                                    style={{ width: "60% !important" }}
                                                                                    className="btn-sm mt-3"
                                                                                >
                                                                                    {" "}
                                                                                    <i className="fas fa-trash-alt"></i>
                                                                                </Button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td>
                                            <div data-repeater-list="group-a">
                                                <Row data-repeater-item>
                                                    {LeadExperianList.length == 0 ?
                                                        <Col lg="2" className="mb-3">
                                                            <Label htmlFor="name">
                                                                Date of Report Pulled Out
                                                            </Label>
                                                            <Input
                                                                type="date"
                                                                id="txtExperianDateofReportPulledOut"
                                                                name="untyped-input"
                                                                placeholder="Date of Report Pulled Out"
                                                            />
                                                        </Col>
                                                        : ''}
                                                    {LeadExperianList.length == 0 ?
                                                        <Col lg="1" className="mb-3">
                                                            <Label htmlFor="name">Exp. Score</Label>
                                                            <Input
                                                                type="text"
                                                                id="txtExperianScore"
                                                                name="untyped-input"
                                                                placeholder="Exp. Score"
                                                            />
                                                        </Col>
                                                        : ''}
                                                    {LeadExperianList.length == 0 ?
                                                        <Col lg="3" className="mb-3">
                                                            <Label htmlFor="name">
                                                                Name of Borrower as per CIR Report Pulled Out
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                id="txtExperianNameofBorrower"
                                                                name="untyped-input"
                                                                placeholder="Name of Borrower as per CIR Report Pulled Out"
                                                            />
                                                        </Col>
                                                        : ''}
                                                    {LeadExperianList.length == 0 ?
                                                        <Col lg="2" className="mb-3">
                                                            <Label htmlFor="name">
                                                                PAN no. as per CIR
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                id="txtExperianPANNo"
                                                                name="untyped-input"
                                                                placeholder="PAN no. as per CIR"
                                                            />
                                                        </Col>
                                                        : ''}
                                                    {LeadExperianList.length == 0 ?
                                                        <Col lg="2" className="mb-3">
                                                            <Label htmlFor="name">
                                                                Additional KYC as per CIR
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                id="txtExperianAdditionalKYCCIR"
                                                                name="untyped-input"
                                                                placeholder="Additional KYC as per CIR"
                                                            />
                                                        </Col>
                                                        : ''}
                                                    {LeadExperianList.length == 0 ?
                                                        <Col lg="2" className="mb-3">
                                                            <Label htmlFor="name">Enquiry</Label>
                                                            <Input
                                                                type="text"
                                                                id="txtExperianEnquiry"
                                                                name="untyped-input"
                                                                placeholder="Enquiry"
                                                            />
                                                        </Col>
                                                        : ''}
                                                    {LeadExperianList.length == 0 ?
                                                        <Col lg="12" className="mb-3">
                                                            <hr className="mb-2" />
                                                        </Col>
                                                        : ''}
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Member (Lender) Name
                                                        </Label>
                                                        <select className="form-select" id="ddlExperianMember" onChange={(e) => setCheckLeadExperianValid(1)}>
                                                            <option value={"0"}>Select Member</option>
                                                            {ChannelMasterList.map((VAccountType) => (
                                                                <option key={VAccountType.ChannelMasterId} value={VAccountType.ChannelMasterId}> {VAccountType.Title} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">Account Type</Label>
                                                        <select className="form-select" Id="ddlExperianAccountType" onChange={(e) => setCheckLeadExperianValid(1)}>
                                                            <option value={"0"}>Select Account Type</option>
                                                            {AccountTypeList.map((state) => (
                                                                <option key={state.AccountTypeId} value={state.AccountTypeId}> {state.AccountTypeName} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Loan number
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtExperianAccountNumber"
                                                            name="untyped-input"
                                                            placeholder="Loan number"
                                                            onChange={(e) => setCheckLeadExperianValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Ownership Indicator
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtExperianOwnershipIndicator"
                                                            name="untyped-input"
                                                            placeholder="Ownership Indicator"
                                                            onChange={(e) => setCheckLeadExperianValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Credit Limit (if Credit Card)
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtExperianCreditLimit"
                                                            name="untyped-input"
                                                            placeholder="Credit Limit (if Credit Card)"
                                                            onChange={(e) => setCheckLeadExperianValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Sanctioned Amount
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtExperianHighCredit"
                                                            name="untyped-input"
                                                            placeholder="Sanctioned Amount"
                                                            onChange={(e) => setCheckLeadExperianValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Current Balance
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtExperianCurrentBalance"
                                                            name="untyped-input"
                                                            placeholder="Current Balance"
                                                            onChange={(e) => setCheckLeadExperianValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Amount Overdue
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtExperianAmountOverdue"
                                                            name="untyped-input"
                                                            placeholder="Amount Overdue"
                                                            onChange={(e) => setCheckLeadExperianValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            No. of DPDs for Latest Month Reported
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtExperianDPDsforLatestMonth"
                                                            name="untyped-input"
                                                            placeholder="No. of DPDs for Latest Month Reported"
                                                            onChange={(e) => setCheckLeadExperianValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Date Opened / Disbursed
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            id="txtExperianDateOpened"
                                                            name="untyped-input"
                                                            placeholder="Date Opened / Disbursed"
                                                            onChange={(e) => setCheckLeadExperianValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">Date Closed</Label>
                                                        <Input
                                                            type="date"
                                                            id="txtExperianDateClosed"
                                                            name="untyped-input"
                                                            placeholder="Date Closed"
                                                            onChange={(e) => setCheckLeadExperianValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Date of Last Payment
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            id="txtExperianDateofLastPayment"
                                                            name="untyped-input"
                                                            placeholder="Date of Last Payment"
                                                            onChange={(e) => setCheckLeadExperianValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Date Reported
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            id="txtExperianDateReported"
                                                            name="untyped-input"
                                                            placeholder="Date Reported"
                                                            onChange={(e) => setCheckLeadExperianValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Suite Filed/Wilful Default
                                                        </Label>
                                                        <select className="form-select" id="ddlExperianSuiteFiled" onChange={(e) => setCheckLeadExperianValid(1)}>
                                                            <option value={"0"}>Select Suite File Status</option>
                                                            {SuiteStatusList.map((VAccountType) => (
                                                                <option key={VAccountType.Id} value={VAccountType.Id}> {VAccountType.Title} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Credit Facility Status
                                                        </Label>
                                                        <select className="form-select" id="ddlExperianCreditFacilityStatus" onChange={(e) => setCheckLeadExperianValid(1)}>
                                                            <option value={"0"}>Select Suite File Status</option>
                                                            {CreditFacilityStatusList.map((VCreditFacilityStatus) => (
                                                                <option key={VCreditFacilityStatus.Id} value={VCreditFacilityStatus.Id}> {VCreditFacilityStatus.Title} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Written of Amount
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtExperianWrittenofAmount"
                                                            name="untyped-input"
                                                            placeholder="Written of Amount"
                                                            onChange={(e) => setCheckLeadExperianValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Settlement Amount
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtExperianSettlementAmount"
                                                            name="untyped-input"
                                                            placeholder="Total Non Reporting Month"
                                                            onChange={(e) => setCheckLeadExperianValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Total Non Reporting Month
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtExperianTotalNonReportingMonth"
                                                            name="untyped-input"
                                                            placeholder="Total Non Reporting Month"
                                                            onChange={(e) => setCheckLeadExperianValid(1)}
                                                        />
                                                    </Col>


                                                </Row>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Row>
                                                <div className="col-lg-1">
                                                    <div className="d-grid w-11">
                                                        <Button onClick={LeadExperianAdd} color="primary" className="mt-3 mt-lg-0 btn-sm">
                                                            <i className="fas fa-plus me-2"></i>
                                                            Add{" "}
                                                        </Button>{" "}
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-1">
                                            <div className="d-grid w-17">
                                            <Button onClick={LeadExperianSave} color="primary" className="mt-5 mt-lg-0 btn-sm">
                                                Save{" "}
                                            </Button>{" "}
                                            </div>
                                        </div> */}
                                            </Row>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <Row>
                        <Col lg="10"><h5>CRIF<input className="form-check-input ms-2" type="checkbox" id="chkSameCRIF" onChange={SameCRIFhandleChange} /><Label className="chkissame">Is Same CIBIL ?</Label></h5></Col>
                        <Col lg="2" className="text-end">
                            {ISUploadCRIFDocument > 0 ? <a href={CRIFDocumentFilePath} target="_blank" rel="noopener noreferrer"><i className="fas fa-eye me-2 fasfaeye" aria-hidden="true"></i></a> : ''}
                            | <a id="lnkCRIFFileUpload" href="#" data-bs-toggle="modal" data-bs-target="#CRIFFileUpload">+ Upload</a>
                        </Col>
                    </Row>
                    <hr className="mb-2" />
                    <div className="row">
                        <div className="col-lg-12 mb-4">
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    {LeadCRIFList.filter(x => x.CRIFScore != '' == 1).map((item, idx) => (
                                        <tr key={idx}>
                                            {idx == 0 ?
                                                <td>
                                                    <div data-repeater-list="group-a">
                                                        <Row data-repeater-item>
                                                            {LeadCRIFList.length > 0 ?
                                                                <Col lg="2" className="mb-3">
                                                                    <Label htmlFor="name">
                                                                        Date of Report Pulled Out
                                                                    </Label>
                                                                    <Input
                                                                        type="date"
                                                                        id={item.DateofReportPulledOuttxtId}
                                                                        name="untyped-input"
                                                                        defaultValue={item.DateofReportPulledOut}
                                                                        placeholder="Date of Report Pulled Out"
                                                                    />
                                                                </Col>
                                                                : ''}
                                                            {LeadCRIFList.length > 0 ?
                                                                <Col lg="1" className="mb-3">
                                                                    <Label htmlFor="name">CRIF Score </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id={item.CRIFScoretxtId}
                                                                        name="untyped-input"
                                                                        defaultValue={item.CRIFScore}
                                                                        placeholder="CRIFl Score"
                                                                    />
                                                                </Col>
                                                                : ''}
                                                            {LeadCRIFList.length > 0 ?
                                                                <Col lg="3" className="mb-3">
                                                                    <Label htmlFor="name">
                                                                        Name of Borrower as per CIR Report Pulled Out{" "}

                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id={item.NameofBorrowertxtId}
                                                                        name="untyped-input"
                                                                        defaultValue={item.NameofBorrower}
                                                                        placeholder="Name of Borrower as per CIR Report Pulled Out"
                                                                    />
                                                                </Col>
                                                                : ''}
                                                            {LeadCRIFList.length > 0 ?
                                                                <Col lg="2" className="mb-3">
                                                                    <Label htmlFor="name">
                                                                        PAN no. as per CIR
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id={item.PANNotxtId}
                                                                        name="untyped-input"
                                                                        defaultValue={item.PANNo}
                                                                        placeholder="PAN no. as per CIR"
                                                                    />
                                                                </Col>
                                                                : ''}
                                                            {LeadCRIFList.length > 0 ?
                                                                <Col lg="2" className="mb-3">
                                                                    <Label htmlFor="name">
                                                                        Additional KYC as per CIR
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        id={item.AdditionalKYCCIRtxtId}
                                                                        name="untyped-input"
                                                                        defaultValue={item.AdditionalKYCCIR}
                                                                        placeholder="Additional KYC as per CIR"
                                                                    />
                                                                </Col>
                                                                : ''}
                                                            {LeadCRIFList.length > 0 ?
                                                                <Col lg="2" className="mb-3">
                                                                    <Label htmlFor="name">Enquiry</Label>
                                                                    <Input
                                                                        type="text"
                                                                        id={item.EnquirytxtId}
                                                                        name="untyped-input"
                                                                        defaultValue={item.Enquiry}
                                                                        placeholder="Enquiry"
                                                                    />
                                                                </Col>
                                                                : ''}

                                                        </Row>
                                                    </div>
                                                    <hr className="mb-2" />
                                                </td>
                                                : ''}
                                        </tr>
                                    ))}
                                    {LeadCRIFList.map((item, idx) => (
                                        <tr key={idx}>
                                            <td>
                                                <div className="accordion mb-3" id="accordionExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id={'CRIFH' + idx}>
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#CRIFC' + idx} aria-expanded="true" aria-controls={'CRIFC' + idx}>
                                                                CRIF Account #{idx + 1}
                                                            </button>
                                                        </h2>
                                                        <div id={'CRIFC' + idx} className="accordion-collapse collapse" aria-labelledby={'CRIFH' + idx} data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <div data-repeater-list="group-a">
                                                                    <Row data-repeater-item>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Member (Lender) Name
                                                                            </Label>
                                                                            <select className="form-select" id={item.MemberIdtxtId}>
                                                                                <option value={"0"}>Select Member</option>
                                                                                {ChannelMasterList.map((VAccountType) => (
                                                                                    <option key={VAccountType.ChannelMasterId} value={VAccountType.ChannelMasterId} selected={VAccountType.ChannelMasterId == item.MemberId}> {VAccountType.Title} </option>
                                                                                ))}
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">Account Type </Label>
                                                                            <select className="form-select" id={item.AccountTypetxtId}>
                                                                                <option value={"0"}>Select Account Type</option>
                                                                                {AccountTypeList.map((state) => (
                                                                                    <option key={state.AccountTypeId} value={state.AccountTypeId} selected={state.AccountTypeId == item.AccountTypeId}> {state.AccountTypeName} </option>
                                                                                ))}
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Loan number
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.AccountNumbertxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.AccountNumber}
                                                                                placeholder="Loan number"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Ownership Indicator
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.OwnershipIndicatortxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.OwnershipIndicator}
                                                                                placeholder="Ownership Indicator"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Credit Limit (if Credit Card)
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.CreditLimittxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.CreditLimit}
                                                                                placeholder="Credit Limit (if Credit Card)"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Sanctioned Amount
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.HighCredittxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.HighCredit}
                                                                                placeholder="Sanctioned Amount"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Current Balance
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.CurrentBalancetxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.CurrentBalance}
                                                                                placeholder="Current Balance"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Amount Overdue
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.AmountOverduetxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.AmountOverdue}
                                                                                placeholder="Amount Overdue"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                No. of DPDs for Latest Month Reported
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.DPDsforLatestMonthtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DPDsforLatestMonth}
                                                                                placeholder="No. of DPDs for Latest Month Reported"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Date Opened / Disbursed
                                                                            </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={item.DateOpenedtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DateOpened}
                                                                                placeholder="Date Opened / Disbursed"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">Date Closed </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={item.DateClosedtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DateClosed}
                                                                                placeholder="Date Closed"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Date of Last Payment
                                                                            </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={item.DateofLastPaymenttxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DateofLastPayment}
                                                                                placeholder="Date of Last Payment"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Date Reported
                                                                            </Label>
                                                                            <Input
                                                                                type="date"
                                                                                id={item.DateReportedtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.DateReported}
                                                                                placeholder="Date Reported"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Suite Filed/Wilful Default
                                                                            </Label>
                                                                            <select className="form-select" id={item.SuiteFiledtxtId}>
                                                                                <option value={"0"}>Select Suite File Status</option>
                                                                                {SuiteStatusList.map((VAccountType) => (
                                                                                    <option key={VAccountType.Id} value={VAccountType.Id} selected={VAccountType.Id == item.SuiteFiled}> {VAccountType.Title} </option>
                                                                                ))}
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Credit Facility Status
                                                                            </Label>
                                                                            <select className="form-select" id={item.CreditFacilityStatustxtId}>
                                                                                <option value={"0"}>Select Suite File Status</option>
                                                                                {CreditFacilityStatusList.map((VCreditFacilityStatus) => (
                                                                                    <option key={VCreditFacilityStatus.Id} value={VCreditFacilityStatus.Id} selected={VCreditFacilityStatus.Id == item.CreditFacilityStatus}> {VCreditFacilityStatus.Title} </option>
                                                                                ))}
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Written of Amount
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.WrittenofAmounttxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.WrittenofAmount}
                                                                                placeholder="Written of Amount"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Settlement Amount
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.SettlementAmounttxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.SettlementAmount}
                                                                                placeholder="Settlement Amount"
                                                                            />
                                                                        </Col>
                                                                        <Col lg="3" className="mb-3">
                                                                            <Label htmlFor="name">
                                                                                Total Non Reporting Month
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                id={item.TotalNonReportingMonthtxtId}
                                                                                name="untyped-input"
                                                                                defaultValue={item.TotalNonReportingMonth}
                                                                                placeholder="Total Non Reporting Month"
                                                                            />
                                                                        </Col>

                                                                        <Col lg="1" className="align-self-center">
                                                                            <div className="d-grid w-17">
                                                                                <Button
                                                                                    onClick={e => CRIFhandleRemoveRow(e, item.RowId, item.LeadEnrollCRIFId)}
                                                                                    color="danger"
                                                                                    style={{ width: "60% !important" }}
                                                                                    className="btn-sm mt-3"
                                                                                >
                                                                                    {" "}
                                                                                    <i className="fas fa-trash-alt"></i>
                                                                                </Button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td>
                                            <div data-repeater-list="group-a">
                                                <Row data-repeater-item>

                                                    {LeadCRIFList.length == 0 ?
                                                        <Col lg="2" className="mb-3">
                                                            <Label htmlFor="name">
                                                                Date of Report Pulled Out
                                                            </Label>
                                                            <Input
                                                                type="date"
                                                                id="txtCRIFDateofReportPulledOut"
                                                                name="untyped-input"
                                                                placeholder="Date of Report Pulled Out"
                                                            />
                                                        </Col>
                                                        : ''}
                                                    {LeadCRIFList.length == 0 ?
                                                        <Col lg="1" className="mb-3">
                                                            <Label htmlFor="name">CRIFl Score </Label>
                                                            <Input
                                                                type="text"
                                                                id="txtCRIFScore"
                                                                name="untyped-input"
                                                                placeholder="CRIFl Score"
                                                            />
                                                        </Col>
                                                        : ''}
                                                    {LeadCRIFList.length == 0 ?
                                                        <Col lg="3" className="mb-3">
                                                            <Label htmlFor="name">
                                                                Name of Borrower as per CIR Report Pulled Out
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                id="txtCRIFNameofBorrower"
                                                                name="untyped-input"
                                                                placeholder="Name of Borrower as per CIR Report Pulled Out"
                                                            />
                                                        </Col>
                                                        : ''}
                                                    {LeadCRIFList.length == 0 ?
                                                        <Col lg="2" className="mb-3">
                                                            <Label htmlFor="name">
                                                                PAN no. as per CIR
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                id="txtCRIFPANNo"
                                                                name="untyped-input"
                                                                placeholder="PAN no. as per CIR"
                                                            />
                                                        </Col>
                                                        : ''}
                                                    {LeadCRIFList.length == 0 ?
                                                        <Col lg="2" className="mb-3">
                                                            <Label htmlFor="name">
                                                                Additional KYC as per CIR
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                id="txtCRIFAdditionalKYCCIR"
                                                                name="untyped-input"
                                                                placeholder="Additional KYC as per CIR"
                                                            />
                                                        </Col>
                                                        : ''}
                                                    {LeadCRIFList.length == 0 ?
                                                        <Col lg="2" className="mb-3">
                                                            <Label htmlFor="name">Enquiry </Label>
                                                            <Input
                                                                type="text"
                                                                id="txtCRIFEnquiry"
                                                                name="untyped-input"
                                                                placeholder="Enquiry"
                                                            />
                                                        </Col>
                                                        : ''}
                                                    {LeadCRIFList.length == 0 ?
                                                        <Col lg="12" className="mb-3">
                                                            <hr className="mb-2" />
                                                        </Col>
                                                        : ''}
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Member (Lender) Name
                                                        </Label>
                                                        <select className="form-select" id="ddlCRIFMember" onChange={(e) => setCheckLeadCRIFValid(1)}>
                                                            <option value={"0"}>Select Member</option>
                                                            {ChannelMasterList.map((VAccountType) => (
                                                                <option key={VAccountType.ChannelMasterId} value={VAccountType.ChannelMasterId}> {VAccountType.Title} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">Account Type </Label>
                                                        <select className="form-select" id="ddlCRIFAccountType" onChange={(e) => setCheckLeadCRIFValid(1)}>
                                                            <option value={"0"}>Select Account Type</option>
                                                            {AccountTypeList.map((state) => (
                                                                <option key={state.AccountTypeId} value={state.AccountTypeId}> {state.AccountTypeName} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Loan number
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCRIFAccountNumber"
                                                            name="untyped-input"
                                                            placeholder="Loan number"
                                                            onChange={(e) => setCheckLeadCRIFValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Ownership Indicator
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCRIFOwnershipIndicator"
                                                            name="untyped-input"
                                                            placeholder="Ownership Indicator"
                                                            onChange={(e) => setCheckLeadCRIFValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Credit Limit (if Credit Card)
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCRIFCreditLimit"
                                                            name="untyped-input"
                                                            placeholder="Credit Limit (if Credit Card)"
                                                            onChange={(e) => setCheckLeadCRIFValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Sanctioned Amount
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCRIFHighCredit"
                                                            name="untyped-input"
                                                            placeholder="Sanctioned Amount"
                                                            onChange={(e) => setCheckLeadCRIFValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Current Balance
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCRIFCurrentBalance"
                                                            name="untyped-input"
                                                            placeholder="Current Balance"
                                                            onChange={(e) => setCheckLeadCRIFValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Amount Overdue
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCRIFAmountOverdue"
                                                            name="untyped-input"
                                                            placeholder="Amount Overdue"
                                                            onChange={(e) => setCheckLeadCRIFValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            No. of DPDs for Latest Month Reported
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCRIFDPDsforLatestMonth"
                                                            name="untyped-input"
                                                            placeholder="No. of DPDs for Latest Month Reported"
                                                            onChange={(e) => setCheckLeadCRIFValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Date Opened / Disbursed
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            id="txtCRIFDateOpened"
                                                            name="untyped-input"
                                                            placeholder="Date Opened / Disbursed"
                                                            onChange={(e) => setCheckLeadCRIFValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">Date Closed </Label>
                                                        <Input
                                                            type="date"
                                                            id="txtCRIFDateClosed"
                                                            name="untyped-input"
                                                            placeholder="Date Closed"
                                                            onChange={(e) => setCheckLeadCRIFValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Date of Last Payment
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            id="txtCRIFDateofLastPayment"
                                                            name="untyped-input"
                                                            placeholder="Date of Last Payment"
                                                            onChange={(e) => setCheckLeadCRIFValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Date Reported
                                                        </Label>
                                                        <Input
                                                            type="date"
                                                            id="txtCRIFDateReported"
                                                            name="untyped-input"
                                                            placeholder="Date Reported"
                                                            onChange={(e) => setCheckLeadCRIFValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Suite Filed/Wilful Default
                                                        </Label>
                                                        <select className="form-select" id="ddlCRIFSuiteFiled" onChange={(e) => setCheckLeadCRIFValid(1)}>
                                                            <option value={"0"}>Select Suite File Status</option>
                                                            {SuiteStatusList.map((VAccountType) => (
                                                                <option key={VAccountType.Id} value={VAccountType.Id}> {VAccountType.Title} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Credit Facility Status
                                                        </Label>
                                                        <select className="form-select" id="ddlCRIFCreditFacilityStatus" onChange={(e) => setCheckLeadCRIFValid(1)}>
                                                            <option value={"0"}>Select Suite File Status</option>
                                                            {CreditFacilityStatusList.map((VCreditFacilityStatus) => (
                                                                <option key={VCreditFacilityStatus.Id} value={VCreditFacilityStatus.Id}> {VCreditFacilityStatus.Title} </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Written of Amount
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCRIFWrittenofAmount"
                                                            name="untyped-input"
                                                            placeholder="Written of Amount"
                                                            onChange={(e) => setCheckLeadCRIFValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Settlement Amount
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCRIFSettlementAmount"
                                                            name="untyped-input"
                                                            placeholder="Settlement Amount"
                                                            onChange={(e) => setCheckLeadCRIFValid(1)}
                                                        />
                                                    </Col>
                                                    <Col lg="3" className="mb-3">
                                                        <Label htmlFor="name">
                                                            Total Non Reporting Month
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            id="txtCRIFTotalNonReportingMonth"
                                                            name="untyped-input"
                                                            placeholder="Total Non Reporting Month"
                                                            onChange={(e) => setCheckLeadCRIFValid(1)}
                                                        />
                                                    </Col>

                                                </Row>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Row>
                                                <div className="col-lg-1">
                                                    <div className="d-grid w-11">
                                                        <Button onClick={LeadCRIFAdd} color="primary" className="mt-3 mt-lg-0 btn-sm">
                                                            <i className="fas fa-plus me-2"></i>
                                                            Add{" "}
                                                        </Button>{" "}
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-1">
                                            <div className="d-grid w-17">
                                            <Button onClick={LeadCRIFSave} color="primary" className="mt-5 mt-lg-0 btn-sm">
                                                Save{" "}
                                            </Button>{" "}
                                            </div>
                                        </div> */}
                                            </Row>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <div className="row">
                        <div className="col-lg-5"> </div>
                        <div className="col-lg-2 text-center">
                            <Button onClick={LeadCreditDetailSave} className="btn btn_success me-2">Save{" "}</Button>{" "}
                        </div>
                        <div className="col-lg-5"> </div>
                    </div>
                </CardBody>
            </Card>

        </React.Fragment>
    );
}
export default OnSort;