import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle, } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import "../../../assets/scss/datatables.scss"

// Table data
const products = [

 
  {
    "id": 1,
    "created date": <> <p className="mb-1">16/12/2021</p> <p className="mb-0"> <span className="invoice_status  inv_status_yellow"> </span> 8:30 PM </p> </>,
    "invoice id": "0002"	,
    "invoice date": "13/12/2021",
    "customer name": "Excelsior",
    "company name": "SIlverTech",
    "invoice due date": "05/1/2022",
    "amount": "1320.00",
    "invoice type": <span className="badge badge-pill invoice_type_style">Normal Invoice	</span> ,
    "publish status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Published </span> ,
    "status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Active </span>,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Pay Now </a>
        <a className="d-block border-bottom  p-1" href="#"> Payment </a>
        <a className="d-block border-bottom  p-1" href="/CustomerInvoicePreview"> Invoice Preview </a>
        <a className="d-block p-1" href="#"> Edit </a>
        
    </ul>
  </div>
  },

  {
    "id": 2,
    "created date": <> <p className="mb-1">16/12/2021</p> <p className="mb-0"> <span className="invoice_status"> </span> 8:30 PM </p> </>,
    "invoice id": "0002"	,
    "invoice date": "13/12/2021",
    "customer name": "Excelsior",
    "company name": "SIlverTech",
    "invoice due date": "05/1/2022",
    "amount": "1320.00",
    "invoice type": <span className="badge badge-pill invoice_type_style">Normal Invoice	</span> ,
    "publish status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Published </span> ,
    "status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Active </span>,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Pay Now </a>
        <a className="d-block border-bottom  p-1" href="#"> Payment </a>
        <a className="d-block border-bottom  p-1" href="/CustomerInvoicePreview"> Invoice Preview </a>
        <a className="d-block p-1" href="#"> Edit </a>
        
    </ul>
  </div>
  },

  {
    "id": 3,
    "created date": <> <p className="mb-1">16/12/2021</p> <p className="mb-0"> <span className="invoice_status"> </span> 8:30 PM </p> </>,
    "invoice id": "0002"	,
    "invoice date": "13/12/2021",
    "customer name": "Excelsior",
    "company name": "SIlverTech",
    "invoice due date": "05/1/2022",
    "amount": "1320.00",
    "invoice type": <span className="badge badge-pill invoice_type_style">Normal Invoice	</span> ,
    "publish status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Published </span> ,
    "status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Active </span>,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Pay Now </a>
        <a className="d-block border-bottom  p-1" href="#"> Payment </a>
        <a className="d-block border-bottom  p-1" href="/CustomerInvoicePreview"> Invoice Preview </a>
        <a className="d-block p-1" href="#"> Edit </a>
        
    </ul>
  </div>
  },

  {
    "id": 4,
    "created date": <> <p className="mb-1">16/12/2021</p> <p className="mb-0"> <span className="invoice_status"> </span> 8:30 PM </p> </>,
    "invoice id": "0002"	,
    "invoice date": "13/12/2021",
    "customer name": "Excelsior",
    "company name": "SIlverTech",
    "invoice due date": "05/1/2022",
    "amount": "1320.00",
    "invoice type": <span className="badge badge-pill invoice_type_style">Normal Invoice	</span> ,
    "publish status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Published </span> ,
    "status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Active </span>,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Pay Now </a>
        <a className="d-block border-bottom  p-1" href="#"> Payment </a>
        <a className="d-block border-bottom  p-1" href="/CustomerInvoicePreview"> Invoice Preview </a>
        <a className="d-block p-1" href="#"> Edit </a>
        
    </ul>
  </div>
  },

  {
    "id": 5,
    "created date": <> <p className="mb-1">16/12/2021</p> <p className="mb-0"> <span className="invoice_status  inv_status_yellow"> </span> 8:30 PM </p> </>,
    "invoice id": "0002"	,
    "invoice date": "13/12/2021",
    "customer name": "Excelsior",
    "company name": "SIlverTech",
    "invoice due date": "05/1/2022",
    "amount": "1320.00",
    "invoice type": <span className="badge badge-pill invoice_type_style">Normal Invoice	</span> ,
    "publish status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Published </span> ,
    "status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Active </span>,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Pay Now </a>
        <a className="d-block border-bottom  p-1" href="#"> Payment </a>
        <a className="d-block border-bottom  p-1" href="/CustomerInvoicePreview"> Invoice Preview </a>
        <a className="d-block p-1" href="#"> Edit </a>
        
    </ul>
  </div>
  },

  {
    "id": 6,
    "created date": <> <p className="mb-1">16/12/2021</p> <p className="mb-0"> <span className="invoice_status"> </span> 8:30 PM </p> </>,
    "invoice id": "0002"	,
    "invoice date": "13/12/2021",
    "customer name": "Excelsior",
    "company name": "SIlverTech",
    "invoice due date": "05/1/2022",
    "amount": "1320.00",
    "invoice type": <span className="badge badge-pill invoice_type_style">Normal Invoice	</span> ,
    "publish status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Published </span> ,
    "status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Active </span>,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Pay Now </a>
        <a className="d-block border-bottom  p-1" href="#"> Payment </a>
        <a className="d-block border-bottom  p-1" href="/CustomerInvoicePreview"> Invoice Preview </a>
        <a className="d-block p-1" href="#"> Edit </a>
        
    </ul>
  </div>
  },

  {
    "id": 7,
    "created date": <> <p className="mb-1">16/12/2021</p> <p className="mb-0"> <span className="invoice_status"> </span> 8:30 PM </p> </>,
    "invoice id": "0002"	,
    "invoice date": "13/12/2021",
    "customer name": "Excelsior",
    "company name": "SIlverTech",
    "invoice due date": "05/1/2022",
    "amount": "1320.00",
    "invoice type": <span className="badge badge-pill invoice_type_style">Normal Invoice	</span> ,
    "publish status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Published </span> ,
    "status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Active </span>,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Pay Now </a>
        <a className="d-block border-bottom  p-1" href="#"> Payment </a>
        <a className="d-block border-bottom  p-1" href="/CustomerInvoicePreview"> Invoice Preview </a>
        <a className="d-block p-1" href="#"> Edit </a>
        
    </ul>
  </div>
  },

  {
    "id": 8,
    "created date": <> <p className="mb-1">16/12/2021</p> <p className="mb-0"> <span className="invoice_status  inv_status_yellow"> </span> 8:30 PM </p> </>,
    "invoice id": "0002"	,
    "invoice date": "13/12/2021",
    "customer name": "Excelsior",
    "company name": "SIlverTech",
    "invoice due date": "05/1/2022",
    "amount": "1320.00",
    "invoice type": <span className="badge badge-pill invoice_type_style">Normal Invoice	</span> ,
    "publish status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Published </span> ,
    "status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Active </span>,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Pay Now </a>
        <a className="d-block border-bottom  p-1" href="#"> Payment </a>
        <a className="d-block border-bottom  p-1" href="/CustomerInvoicePreview"> Invoice Preview </a>
        <a className="d-block p-1" href="#"> Edit </a>
        
    </ul>
  </div>
  },

  {
    "id": 9,
    "created date": <> <p className="mb-1">16/12/2021</p> <p className="mb-0"> <span className="invoice_status"> </span> 8:30 PM </p> </>,
    "invoice id": "0002"	,
    "invoice date": "13/12/2021",
    "customer name": "Excelsior",
    "company name": "SIlverTech",
    "invoice due date": "05/1/2022",
    "amount": "1320.00",
    "invoice type": <span className="badge badge-pill invoice_type_style">Normal Invoice	</span> ,
    "publish status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Published </span> ,
    "status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Active </span>,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Pay Now </a>
        <a className="d-block border-bottom  p-1" href="#"> Payment </a>
        <a className="d-block border-bottom  p-1" href="/CustomerInvoicePreview"> Invoice Preview </a>
        <a className="d-block p-1" href="#"> Edit </a>
        
    </ul>
  </div>
  },

  {
    "id": 10,
    "created date": <> <p className="mb-1">16/12/2021</p> <p className="mb-0"> <span className="invoice_status"> </span> 8:30 PM </p> </>,
    "invoice id": "0002"	,
    "invoice date": "13/12/2021",
    "customer name": "Excelsior",
    "company name": "SIlverTech",
    "invoice due date": "05/1/2022",
    "amount": "1320.00",
    "invoice type": <span className="badge badge-pill invoice_type_style">Normal Invoice	</span> ,
    "publish status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Published </span> ,
    "status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Active </span>,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Pay Now </a>
        <a className="d-block border-bottom  p-1" href="#"> Payment </a>
        <a className="d-block border-bottom  p-1" href="/CustomerInvoicePreview"> Invoice Preview </a>
        <a className="d-block p-1" href="#"> Edit </a>
        
    </ul>
  </div>
  },

  {
    "id": 11,
    "created date": <> <p className="mb-1">16/12/2021</p> <p className="mb-0"> <span className="invoice_status  inv_status_yellow"> </span> 8:30 PM </p> </>,
    "invoice id": "0002"	,
    "invoice date": "13/12/2021",
    "customer name": "Excelsior",
    "company name": "SIlverTech",
    "invoice due date": "05/1/2022",
    "amount": "1320.00",
    "invoice type": <span className="badge badge-pill invoice_type_style">Normal Invoice	</span> ,
    "publish status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Published </span> ,
    "status": <span className="bg_green text-success p-1 fw-bold border_radius20 px-2 font-12"> Active </span>,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Pay Now </a>
        <a className="d-block border-bottom  p-1" href="#"> Payment </a>
        <a className="d-block border-bottom  p-1" href="/CustomerInvoicePreview"> Invoice Preview </a>
        <a className="d-block p-1" href="#"> Edit </a>
        
    </ul>
  </div>
  }


 
  

];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    const columns = [{
      dataField: 'id',
      text: '#',
      sort: true,
    }, {
      dataField: 'created date',
      text: 'Created Date',
      sort: true
    }, {
      dataField: 'invoice id',
      text: 'Invoice Id	',
      sort: true
    },  {
      dataField: 'invoice date',
      text: 'Invoice Date',
      sort: true
    }, {
      dataField: 'customer name',
      text: 'Customer Name',
      sort: true
    }, {
      dataField: 'company name',
      text: 'Company Name',
      sort: true
    }, {
      dataField: 'invoice due date',
      text: 'Invoice Due Date',
      sort: true
    }, {
      dataField: 'amount',
      text: 'Amount',
      sort: true
    }, {
      dataField: 'invoice type',
      text: 'Invoice Type',
      sort: true
    }, {
      dataField: 'publish status',
      text: 'Publish Status',
      sort: true
    }, {
      dataField: 'status',
      text: 'Status',
      sort: true
    }, {
      dataField: 'action',
      text: 'Action',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

  
   

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Customer Invoice List | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
            
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                    <CardTitle className="h3">Customer Invoice List</CardTitle>
                      <a href="#" className="me-2">Home</a> <span>/</span><span className="ms-2">Customer Invoice List</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>

            

              <Card>
          <CardBody>
                <Row>
                  <Col className="col-lg-6 col-md-6 col-12 mb-3">
                    <div className="vendor_summary_box  bg-dark text-light">
                        
                      <ul className="d-flex list-unstyled mb-0 border-0">
                        <li className=" flex-grow-1 py-2">
                        
                                    <h5 className="mb-0  text-light fs-4">Summary</h5>
                        </li>
                      </ul> 
                      <ul className="d-flex list-unstyled mb-0">
                        <li className="border_right flex-grow-1 border_right py-2">
                        <p className="mb-0">Total</p>
                                    <h6 className="mb-0  text-light fs-4">100</h6>
                        </li>
                        <li className="border_right flex-grow-1 py-2">
                        <p className="mb-0">Approved</p>
                                    <h6 className="mb-0  text-light  fs-4">100</h6>
                        </li>
                        <li className="flex-grow-1 py-2">
                        <p className="mb-0">Pending</p>
                                    <h6 className="mb-0  text-light fs-4">100</h6>
                        </li>
                      </ul> 
                     </div>
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-12 mb-3">
                    <div className="vendor_summary_box  bg-dark text-light">
                        
                      <ul className="d-flex list-unstyled mb-0 border-0">
                        <li className=" flex-grow-1 py-2">
                        
                                    <h5 className="mb-0  text-light fs-4">Approved Invoices</h5>
                        </li>
                      </ul> 
                      <ul className="d-flex list-unstyled mb-0">
                        <li className="border_right flex-grow-1 border_right py-2">
                        <p className="mb-0">RS. 30,15,108</p>
                                    <h6 className="mb-0  text-light fs-4">Paid (20%)</h6>
                        </li>
                        <li className=" flex-grow-1 py-2">
                        <p className="mb-0">RS. 20,15,108</p>
                                    <h6 className="mb-0  text-light  fs-4">Unpaid (80%)</h6>
                        </li>
                        
                      </ul> 
                     </div>
                  </Col>
                  
                </Row>
          </CardBody>
        </Card>







            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                                <Row className="mb-2">
                             
                                <Col className="col-lg-8 col-md-8 col-6">
              <div className="py-2">
                <span href="#" className="me-3 bg_green  px-3 py-2 border_radius20 fw-bold" ><i className="far fa-file"></i> Total : 12 </span>
                <span href="#" className="me-3 bg_yellow px-3 py-2 border_radius20  fw-bold " ><i className="far fa-file"></i> Draft : 6 </span>
                <span href="#" className="me-3 bg_red px-3 py-2 border_radius20  fw-bold " ><i className="far fa-file"></i> Draft : 6 </span>
                <span href="#" className="bg_blue py-2 px-3 border_radius20  fw-bold" ><i className="far fa-file"></i> Published : 6 </span>
                                    
                    </div>
              </Col>
                              </Row>

                              <Row className="mt-4"> 
                <Col className="col-lg-4 col-md-4 col-4">
                               
                               <div className="dropdown">
                 <button className="btn btn-border  dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                   Export
                 </button>
                 <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                   <li><a className="dropdown-item" href="#">Action</a></li>
                   <li><a className="dropdown-item" href="#">Another action</a></li>
                   <li><a className="dropdown-item" href="#">Something else here</a></li>
                 </ul>
               </div>
                                               </Col>
                                               <div className="col-lg-4 col-md- col-4 text-center col">
                   <div className="search-box me-2 mb-2 d-inline-block">
                       <div className="position-relative">
                           <label htmlFor="search-bar-0" className="search-label">
                               <span id="search-bar-0-label" className="sr-only">Search this table</span>
                               <input id="search-bar-0" type="text" aria-labelledby="search-bar-0-label" className="form-control " placeholder="Search" value="" />
                           </label>
                           <i className="bx bx-search-alt search-icon"></i>
                       </div>
                   </div>
               </div>
                                               
                                               <Col className="col-lg-4 col-md-4 col-4">
                                               <div className="d-inline float-end">
                                               <div className="dropdown">
                 <button className="btn btn-border dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                   10<i className="fas fa-angle-down align-middle ms-2"></i>
                 </button>
                 <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                   <li><a className="dropdown-item" href="#">25</a></li>
                   <li><a className="dropdown-item" href="#">30</a></li>
                   <li><a className="dropdown-item" href="#">35</a></li>
                 </ul>
               </div>
                                                 </div>
                                               </Col>
        

</Row>



                              <Row className="mb-3">
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light table-thead-light font-12"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                
                                  <div className="text-md-right ms-auto">
                                  <div className="pagination pagination-rounded justify-content-end">
    <ul className="pagination react-bootstrap-table-page-btns-ul">
        <li className="page-item" title="previous page"><a href="#" className="page-link">&lt;</a></li>
        <li className="page-item" title="1"><a href="#" className="page-link">1</a></li>
        <li className="active page-item" title="2"><a href="#" className="page-link">2</a></li>
        <li className="page-item" title="3"><a href="#" className="page-link">3</a></li>
        <li className="page-item" title="next page"><a href="#" className="page-link">&gt;</a></li>
    </ul>
</div>
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DatatableTables