import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import { Email } from '@mui/icons-material';


const CorporateServiceActivities = () => {
    const history = useHistory();
    const [StatusList, setStatusList] = useState([]);
    const [CorporateServiceActivitiesId, setCorporateServiceActivitiesId] = useState(0);
    const [Title, setTitle] = useState("");
    const [Description, setDescription] = useState("");
    const [ActionTitle, setActionTitle] = useState("");
    const [ActionType, setActionType] = useState("");
    const [StatusId, setStatusId] = useState(0);
    const [StatusTitle, setStatusTitle] = useState("");
    const [ServiceActivitiesList, setServiceActivitiesList] = useState([]);
    const [ServiceActivitiesFileList, setServiceActivitiesFileList] = useState([]);
    const [FileName, setFileName] = useState("");
    const [FilePath, setFilePath] = useState("");
    const [BureauStepList, setBureauStepList] = useState([]);

    useEffect(() => {
        getStatusList();
        getBureauStepList();
        let VCorporateServiceId = parseInt(localStorage.getItem("CorporateServiceId") == null ? "0" : localStorage.getItem("CorporateServiceId") == "" ? "0" : localStorage.getItem("CorporateServiceId"));
        let VCorporateServiceIdBureauId = parseInt(localStorage.getItem("CorporateServiceBureauId") == null ? "0" : localStorage.getItem("CorporateServiceBureauId") == "" ? "0" : localStorage.getItem("CorporateServiceBureauId"));
        let VCorporateServiceIdBureauRowId = parseInt(localStorage.getItem("CorporateServiceIdBureauRowId") == null ? "0" : localStorage.getItem("CorporateServiceIdBureauRowId") == "" ? "0" : localStorage.getItem("CorporateServiceIdBureauRowId"));
        if (VCorporateServiceId > 0 && VCorporateServiceIdBureauId > 0) {
            getCorporateServiceActivitiesDetail();
        }
        else {
            let path = `CorporateserviceplanList`;
            history.push(path);
        }
    }, []);


    const getBureauStepList = async () => {
        const BureauCategoryItem = {
            BureauCategoryId: parseInt(localStorage.getItem("CorporateServiceBureauId") == null ? "0" : localStorage.getItem("CorporateServiceBureauId") == "" ? "0" : localStorage.getItem("CorporateServiceBureauId"))
        };
        axios.post(global.APIURL + "/BureauCategoryStepList", BureauCategoryItem)
            .then(res => {
                setBureauStepList(res.data)
            });
    }

    const getStatusList = async () => {

        axios.get(global.APIURL + '/StatusActiveList')
            .then(Tres => {
                let StatusList = Tres.data.map((statusal) => {
                    return statusal
                })
                setStatusList(StatusList);

            })
    }

    const getCorporateServiceActivitiesDetail = async () => {
        let VCorporateServiceId = parseInt(localStorage.getItem("CorporateServiceId") == null ? "0" : localStorage.getItem("CorporateServiceId") == "" ? "0" : localStorage.getItem("CorporateServiceId"));
        let VCorporateServiceIdBureauId = parseInt(localStorage.getItem("CorporateServiceBureauId") == null ? "0" : localStorage.getItem("CorporateServiceBureauId") == "" ? "0" : localStorage.getItem("CorporateServiceBureauId"));
        let VCorporateServiceIdBureauRowId = parseInt(localStorage.getItem("CorporateServiceIdBureauRowId") == null ? "0" : localStorage.getItem("CorporateServiceIdBureauRowId") == "" ? "0" : localStorage.getItem("CorporateServiceIdBureauRowId"));
        var CorporateServiceActivitiesItem = {
            CorporateServiceId: VCorporateServiceId,
            BureauId: VCorporateServiceIdBureauId,
            RowId: VCorporateServiceIdBureauRowId
        }
        axios.post(global.APIURL + "/CorporateServiceActivitiesDetailByBureau", CorporateServiceActivitiesItem)
            .then(res => {
                if (res.data.Result.length > 0) {
                    let ActivitiesDetailArr = res.data.Result[0].ActivitiesDetail;
                    if (ActivitiesDetailArr.length > 0) {
                        $("#ddlBureauSteps").val(ActivitiesDetailArr[0].BureauStepId);
                        $("#txtTitle").val(ActivitiesDetailArr[0].Title);
                        $("#txtDescription").val(ActivitiesDetailArr[0].Description);
                        setCorporateServiceActivitiesId(ActivitiesDetailArr[0].Id);
                    }
                    else {
                        $("#ddlBureauSteps").val(0);
                        $("#txtTitle").val('');
                        $("#txtDescription").val('');
                        setCorporateServiceActivitiesId(0);
                    }
                    let ActivitiesFileListArr = res.data.Result[0].ActivitiesFileList;
                    let ServiceActivitiesFileListarr = [];
                    for (let af = 0; af < ActivitiesFileListArr.length; af++) {
                        const ActivitiesFileDetail = ActivitiesFileListArr[af];
                        const ActivitiesFileItem = {
                            RowId: ActivitiesFileDetail.Id,
                            RowIndex: (af + 1),
                            FileName: ActivitiesFileDetail.FileName,
                            FilePath: ActivitiesFileDetail.FilePath
                        };
                        ServiceActivitiesFileListarr.push(ActivitiesFileItem);
                    }
                    setServiceActivitiesFileList(ServiceActivitiesFileListarr);

                    let ActivitiesActionListArr = res.data.Result[0].ActivitiesActionList;
                    let ServiceActivitiesListArr = [];
                    for (let aa = 0; aa < ActivitiesActionListArr.length; aa++) {
                        const ActivitiesActionDetail = ActivitiesActionListArr[aa];
                        const ActivitiesItem = {
                            RowId: ActivitiesActionDetail.Id,
                            RowIndex: (aa + 1),
                            ActionTitle: ActivitiesActionDetail.ActionTitle,
                            ActionType: ActivitiesActionDetail.ActionType,
                            StatusId: ActivitiesActionDetail.ActionStatusId,
                            StatusTitle: ActivitiesActionDetail.ActionStatus,
                        };
                        ServiceActivitiesListArr.push(ActivitiesItem);
                    }
                    setServiceActivitiesList(ServiceActivitiesListArr);
                }
            });

    };

    const StatusOnChange = e => {
        var VStatus = e.nativeEvent.target.selectedIndex;
        var VStatusId = e.nativeEvent.target[VStatus].value;
        var VStatusTitle = e.nativeEvent.target[VStatus].text;
        setStatusId(VStatusId);
        setStatusTitle(VStatusTitle);
    }

    const ActivitiesAddRow = e => {
        var SAEx = ServiceActivitiesList.filter(x => x.ActionTitle == ActionTitle);
        if (SAEx.length == 0) {
            let SAId = ServiceActivitiesList.length == 0 ? 1 : ((ServiceActivitiesList[ServiceActivitiesList.length - 1].RowIndex) + 1)
            const ActivitiesItem = {
                RowId: 0,
                RowIndex: SAId,
                ActionTitle: ActionTitle,
                ActionType: ActionType == "Select Action Type" ? "" : ActionType,
                StatusId: StatusId,
                StatusTitle: StatusTitle == "" ? "Pending" : StatusTitle == "Select Status" ? "Pending" : StatusTitle,
            };
            setServiceActivitiesList([...ServiceActivitiesList, ActivitiesItem]);
            setActionTitle('');
            setActionType('');
            setStatusId(0);
            setStatusTitle('');
        }
        else {
            alert('Same name record exist.');
        }
    };

    const ActivitiesDeleteRow = (e, idx, RowId) => {
        let AlertMessgae = "Are you sure you want to delete this record?";
        if (confirm(AlertMessgae) == true) {
            const RId = idx;
            let DeleteRowId = parseInt(RowId == null ? '0' : RowId == '' ? '0' : RowId);
            if (DeleteRowId > 0) {
                var DeleteRowItem = {
                    Id: DeleteRowId
                }
                axios.post(global.APIURL + "/CorporateServiceActivitiesActionDelete", DeleteRowItem)
                    .then(res => {
                        alert(res.data[0].RecordStatus);
                        setServiceActivitiesList(ServiceActivitiesList.filter(item => item.RowIndex !== RId));
                    });
            }
            else {
                setServiceActivitiesList(ServiceActivitiesList.filter(item => item.RowIndex !== RId));
            }
        }
    };

    function ActivitiesReferenceFilePost(e) {
        var details = e.target.files[0];
        let arr = details.name.toString().split(".");
        var FileEx = arr[1];

        var details1 = "ActivitiesReferenceFile";
        const formData = new FormData();
        formData.append('Document', details);
        formData.append('FolderName', details1);
        var AUrl = global.APIURL + '/uploadavatar/';
        axios.post(AUrl, formData,
            {
                headers: { "Content-Type": details.type },
            })
            .then((res) => {
                setFileName(details.name.toString());
                setFilePath(res.data.IName);
            });
    }

    const ActivitiesReferenceFileAddRow = e => {
        let SAFId = ServiceActivitiesFileList.length == 0 ? 1 : ((ServiceActivitiesFileList[ServiceActivitiesFileList.length - 1].RowIndex) + 1)
        if (FilePath != '') {
            const ActivitiesFileItem = {
                RowId: 0,
                RowIndex: SAFId,
                FileName: FileName,
                FilePath: FilePath
            };
            setServiceActivitiesFileList([...ServiceActivitiesFileList, ActivitiesFileItem]);
            setFileName('');
            setFilePath('');
        }
    };

    const ActivitiesReferenceFileDeleteRow = (e, idx, RowId) => {
        let AlertMessgae = "Are you sure you want to delete this record?";
        if (confirm(AlertMessgae) == true) {
            const RId = idx;

            let DeleteRowId = parseInt(RowId == null ? '0' : RowId == '' ? '0' : RowId);
            if (DeleteRowId > 0) {
                var DeleteRowItem = {
                    Id: DeleteRowId
                }
                axios.post(global.APIURL + "/CorporateServiceActivitiesFileDelete", DeleteRowItem)
                    .then(res => {
                        alert(res.data[0].RecordStatus);
                        setServiceActivitiesFileList(ServiceActivitiesFileList.filter(item => item.RowIndex !== RId));
                    });
            }
            else {
                setServiceActivitiesFileList(ServiceActivitiesFileList.filter(item => item.RowIndex !== RId));
            }
        }
    };

    function CorporateServiceActivitiesSave() {
        let VCorporateServiceId = parseInt(localStorage.getItem("CorporateServiceId") == null ? "0" : localStorage.getItem("CorporateServiceId") == "" ? "0" : localStorage.getItem("CorporateServiceId"));
        let VCorporateServiceIdBureauId = parseInt(localStorage.getItem("CorporateServiceBureauId") == null ? "0" : localStorage.getItem("CorporateServiceBureauId") == "" ? "0" : localStorage.getItem("CorporateServiceBureauId"));
        let IsInsert = 1;
        let BureauStepsId = parseInt($("#ddlBureauSteps").val() == null ? '0' : $("#ddlBureauSteps").val() == '' ? '0' : $("#ddlBureauSteps").val());
        let VTitle = $("#txtTitle").val() == null ? '' : $("#txtTitle").val();
        if (BureauStepsId == 0) {
            alert("Please select bureau steps.");
            IsInsert = 0;
            return;
        }
        if (VTitle == '') {
            alert("Please input title.");
            IsInsert = 0;
            return;
        }
        if (IsInsert == 1) {
            var CorporateServiceActivitiesItem = {
                CorporateServiceActivitiesId: CorporateServiceActivitiesId,
                ServiceId: VCorporateServiceId,
                BureauId: VCorporateServiceIdBureauId,
                BureauStepsId: BureauStepsId,
                Title: $("#txtTitle").val() == null ? '' : $("#txtTitle").val(),
                Description: $("#txtDescription").val() == null ? '' : $("#txtDescription").val(),
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                ReferenceFileList: ServiceActivitiesFileList,
                ActivitiesAction: ServiceActivitiesList
            }
            axios.post(global.APIURL + "/CorporateServiceActivitiesSave", CorporateServiceActivitiesItem)
                .then(res => {
                    alert(res.data.StatusMessage);
                    window.location.href = "/CorporateServiceActivitiesList";
                });
        }
    }



    return (

        <div className="page-content">

            <MetaTags>
                <title>Corporate Service Activities | KC Admin Group</title>
            </MetaTags>

            <div className="container-fluid">

                <Row className="mb-3">
                    <Col className="col-lg-8 col-md-6 col-6">
                        <div className="breadcrum ps-0">
                            <CardTitle className="h4">Add Corporate Service Activities</CardTitle>
                            <a href="/GeneralDashboard" className="me-2">Home</a>
                            <span>/</span>
                            <a href="/CorporateserviceplanList" className="ms-2 me-2">Corporate Service</a>
                            <span>/</span>
                            <a href="/CorporateServiceActivitiesList" className="ms-2 me-2">Corporate Service Plan Bureau Step</a>
                            <span>/</span>
                            <span className="ms-2">Add Corporate Service Activities</span>
                        </div>
                    </Col>
                    <Col className="col-lg-4 co-md-6 col-6">
                        <a className="btn btn-primary btn-sm float-end font-14" href="/CorporateServiceActivitiesList">
                            <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                        </a>
                    </Col>
                </Row>

                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={3}>
                                        <label className="form-label">Select Bureau Steps</label>
                                        <select className="form-select" name="ddlStatus" id="ddlBureauSteps">
                                            <option selected value="0">Select Bureau Steps</option>
                                            {BureauStepList.map((item) => (
                                                <option key={item.Id} value={item.Id}>
                                                    {item.Title}
                                                </option>
                                            ))}

                                        </select>
                                    </Col>
                                    <Col lg={9}>
                                        <div className="mb-2">
                                            <label className="form-label">Title</label>
                                            <Input type="text" className="form-control" placeholder="Title" id="txtTitle" />
                                        </div>
                                    </Col>


                                    <Col lg={12}>
                                        <div className="mb-2">
                                            <label className="form-label">Description</label>
                                            <Input type="textarea" className="form-control" placeholder="Description" id="txtDescription" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} className="mb-2">
                                        <CardTitle>Activities File List</CardTitle>
                                        <hr />
                                    </Col>
                                    <Col lg={4} className="col-md-6 col-lg-4 mb-md-3 mb-lg-0">
                                        <label className="form-label">Upload File</label>
                                        <Input type="file" className="form-control" placeholder="Title" name="txtActionTitle" id="txtActionTitle" onChange={ActivitiesReferenceFilePost}></Input>
                                    </Col>

                                    <Col lg={2} className="col-md-12 col-lg-2 mb-md-3 mb-lg-0 pt-1">
                                        <button type="button" className="btn btn-primary mt-md-0 mt-lg-4" onClick={ActivitiesReferenceFileAddRow}>
                                            Add
                                        </button>
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            <table className="table table-bordered align-middle">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Upload File</th>
                                                        <th className="text-center" scope="col">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ServiceActivitiesFileList.map((item, idx) => (
                                                        <tr key={idx}>
                                                            <th scope="row">{idx + 1}</th>
                                                            <td><a href={item.FilePath} target="_blank" rel="noopener noreferrer">{item.FileName}</a></td>
                                                            <td className="text-center"><a className="text-danger" href="#" onClick={e => ActivitiesReferenceFileDeleteRow(e, item.RowIndex, item.RowId)}><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <Col lg={12} className="mb-2">
                                        <CardTitle>Action Type List</CardTitle>
                                        <hr />
                                    </Col>
                                    <Col lg={4} className="col-md-6 col-lg-4 mb-md-3 mb-lg-0">
                                        <label className="form-label">Title</label>
                                        <Input type="text" className="form-control" placeholder="Title" name="txtActionTitle" id="txtActionTitle" onChange={(e) => setActionTitle(e.target.value)}></Input>
                                    </Col>
                                    <Col lg={2} className="col-md-6 col-lg-2 mb-md-3 mb-lg-0">
                                        <label className="form-label">Action Type</label>
                                        <select className="form-select" name="ddlActionType" id="ddlActionType" onChange={(e) => setActionType(e.target.value)}>
                                            <option selected value="">Select Action Type </option>
                                            <option value="Text">Text</option>
                                            <option value="Number">Number</option>
                                            <option value="Date">Date</option>
                                            <option value="TextArea">TextArea</option>
                                            <option value="File">Upload File</option>
                                        </select>
                                    </Col>
                                    <Col lg={2} className="col-md-6 col-lg-2 mb-md-3 mb-lg-0">
                                        <label className="form-label">Select Status</label>
                                        <select className="form-select" name="ddlStatus" id="ddlStatus" onChange={StatusOnChange}>
                                            <option selected value="0">Select Status</option>
                                            {StatusList.map((item) => (
                                                <option key={item.StatusId} value={item.StatusId}>
                                                    {item.StatusTitle}
                                                </option>
                                            ))}

                                        </select>
                                    </Col>
                                    <Col lg={2} className="col-md-12 col-lg-2 mb-md-3 mb-lg-0 pt-1">
                                        <button type="button" className="btn btn-primary mt-md-0 mt-lg-4" onClick={ActivitiesAddRow}>
                                            Add Activities
                                        </button>
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            <table className="table table-bordered align-middle">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Action</th>
                                                        <th scope="col">Action Type</th>
                                                        <th scope="col">Status</th>
                                                        <th className="text-center" scope="col">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ServiceActivitiesList.map((item, idx) => (
                                                        <tr key={idx}>
                                                            <th scope="row">{idx + 1}</th>
                                                            <td>{item.ActionTitle}</td>
                                                            <td>{item.ActionType}</td>
                                                            <td>{item.StatusTitle}</td>
                                                            <td className="text-center"><a className="text-danger" onClick={e => ActivitiesDeleteRow(e, item.RowIndex, item.RowId)}><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <Col lg={12}>
                                        <button className="btn btn_success btn-sm" id="btnsave" onClick={CorporateServiceActivitiesSave} >Save</button>
                                        <a className="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default CorporateServiceActivities;