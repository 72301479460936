import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input, Label } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';
import FileBase64 from 'react-file-base64';



const EditChannelSubSource = () => {
    const history = useHistory();
    const [OLDChannelSubSourceLog, setOLDChannelSubSourceLog] = useState('');
    const [OLDChannelSubSourceVisitingCard, setOLDChannelSubSourceVisitingCard] = useState('');
    const [Name, setName] = useState("");
    const [DesignationList, setDesignationList] = useState([]);
    const [DesignationId, setDesignationId] = useState("");
    const [DepartmenthhList, setDepartmentList] = useState([]);
    const [DId, setDId] = useState("");
    const [MobileNumber, setmobileNumber] = useState("");
    const [AlterMobileNumber, setAltermobileNumber] = useState("");
    const [Email, setEmail] = useState("");
    const [AlterEmail, setAlterEmail] = useState("");
    const [CuAddress, setCuAddress] = useState("");
    const [TehsilList, setTehsilList] = useState([]);
    const [DistrictList, setDistrictList] = useState([]);
    const [StateList, setStateList] = useState([]);
    const [CityList, setCityList] = useState([]);
    const [CountryList, setCountryList] = useState([]);
    const [CountryId, setCountryId] = useState("");
    const [StateId, setStateId] = useState("");
    const [DistrictId, setDistrictId] = useState("");
    const [CityId, setCityId] = useState("");
    const [TehsilId, setTehsilId] = useState("");
    const [Pincode, setPincode] = useState("");
    const [DateOfBirth, setDateOfBirth] = useState("");
    const [DateOfAnniversary, setDateOfAnniversary] = useState("");
    const [Firstmeetingbrief, setFirstmeetingbrief] = useState("");
    const [ModelChannelList, setModelChannelList] = useState([]);
    const [DropdownChannelList, setDropdownChannelList] = useState([]);
    const [DropdownChannelTypeList, setDropdownChannelTypeList] = useState([]);
    const [DropdownCityList, setDropdownCityList] = useState([]);
    const [DropdownChannelBranchList, setDropdownChannelBranchList] = useState([]);
    const [SelectChannelDetail, setSelectChannelDetail] = useState([]);
    const [ChannelId, setChannelId] = useState("0");
    const [ChannelPartnerId, setChannelPartnerId] = useState("0");
    const [AddTehsilRights, setAddTehsilRights] = useState('D');
    const [AddCityRights, setAddCityRights] = useState('D');

    let { id } = useParams();

    useEffect(() => {
        if (id != null) {
            if (id != "0") {
                let newid = id.split(':');
                getMenuHideShow();
                getSubchannalid(newid[1]);
                getCountryList();
                getDepartment();
                getDesignationListdetails();
                getDropdownChannelTypeList();
            }
            else {
                history.push("/ChannelSubSource");
            }
        }
        else {
            history.push("/ChannelSubSource"); s
        }
    }, [])

    const getMenuHideShow = async () => {
        const f = {
            MenuId: "38",
            UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
        }

        axios.post(global.APIURL + '/HideShowWeb', f)
            .then(res => {
               
                for (let con = 0; con < res.data.length; con++) {
                    const hidef = res.data[con];
                    var MA = hidef.MenuAction;
                    var MS = hidef.Status;
                    if (MA == 'Add Tehsil') {
                        setAddTehsilRights(MS);
                    }
                    else if (MA == 'Add City') {
                        setAddCityRights(MS);
                    }
                }
            });
    };

    const getDropdownChannelTypeList = async () => {
        axios.get(global.APIURL + "/ChannalTypeList")
            .then(CLres => {
                let VChannelTypeList = CLres.data.map(vcl => {
                    return vcl;
                });
                setDropdownChannelTypeList(VChannelTypeList);
            });
    };

    const getModelLeadChannelList = async () => {
        var VChannelMasterId = document.getElementById("ddlChannel").value;
        var VChannelTypeId = document.getElementById("ddlChannelType").value;
        var VBranchId = document.getElementById("ddlBranch").value;
        var VCityId = document.getElementById("ddlCity").value;

        const LeadChannelFilter = {
            ChannelTypeId: VChannelTypeId,
            ChannelMasterId: VChannelMasterId,
            BranchId: VBranchId,
            CityId: VCityId
            //FilterSearchByName: "",
        };

        axios.post(global.APIURL + "/ActiveChannelFilter", LeadChannelFilter)
            .then(CLres => {
                let VChannelList = CLres.data.map(vcl => {
                    return vcl;
                });
                setModelChannelList(VChannelList);
            });
    };

    const getDropdownChannelMasterList = async () => {
        var VChannelTypeId = document.getElementById("ddlChannelType").value;
        if (parseInt(VChannelTypeId) > 0) {
            const ChannelDropdownFilter = {
                ChannelTypeId: VChannelTypeId,
                ChannelMasterId: 0,
                CityId: 0,
                SpMode: 'ChannelMasterSelectAll',
            };
            axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
                .then(CLres => {
                    let VChannelMasterList = CLres.data.map(vcl => {
                        return vcl;
                    });
                    setDropdownChannelList(VChannelMasterList);
                });
        }
    };

    const getDropdownCityList = async () => {
        var VChannelTypeId = document.getElementById("ddlChannelType").value;
        var VChannelMasterId = document.getElementById("ddlChannel").value;

        if (parseInt(VChannelTypeId) > 0 && parseInt(VChannelMasterId) > 0) {
            const ChannelDropdownFilter = {
                ChannelTypeId: VChannelTypeId,
                ChannelMasterId: VChannelMasterId,
                CityId: 0,
                SpMode: 'ChannelCitySelectAll',
            };
            axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
                .then(CLres => {
                    let VChannelMasterList = CLres.data.map(vcl => {
                        return vcl;
                    });
                    setDropdownCityList(VChannelMasterList);
                });
        }
    };

    const getDropdownBranchList = async () => {
        var VChannelTypeId = document.getElementById("ddlChannelType").value;
        var VChannelMasterId = document.getElementById("ddlChannel").value;
        var VCityId = document.getElementById("ddlCity").value;

        if (parseInt(VChannelTypeId) > 0 && parseInt(VChannelMasterId) > 0 && parseInt(VCityId) > 0) {
            const ChannelDropdownFilter = {
                ChannelTypeId: VChannelTypeId,
                ChannelMasterId: VChannelMasterId,
                CityId: VCityId,
                SpMode: 'ChannelBranchSelectAll',
            };
            axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
                .then(CLres => {
                    let VChannelMasterList = CLres.data.map(vcl => {
                        return vcl;
                    });
                    setDropdownChannelBranchList(VChannelMasterList);
                });
        }
    };

    const getSubchannalid = async (UId) => {
        var AUrl = global.APIURL + '/ChannelSubSourceWeb/' + UId;
        axios.get(AUrl)
            .then(res => {
                getSelectChannelDetailById(res.data[0].ChannelId);
                setChannelPartnerId(res.data[0].ChannelPartnerId);
                setChannelId(res.data[0].ChannelId);
                setName(res.data[0].Name);
                setDId(res.data[0].DepartmentId)
                setmobileNumber(res.data[0].MobileNumber);
                setAltermobileNumber(res.data[0].AlternetMobileNumber)
                setEmail(res.data[0].Email);
                setAlterEmail(res.data[0].AlternetEmail);
                setDesignationId(res.data[0].DesignationId);
                setCuAddress(res.data[0].CurrentAddress);
                setCountryId(1);
                setStateId(res.data[0].StateId);
                setCityId(res.data[0].CityId);
                setTehsilId(res.data[0].TehsilId);
                setDistrictId(res.data[0].DistrictId);
                setPincode(res.data[0].Pincode);
                setDateOfBirth(res.data[0].DateOfBirth);
                setDateOfAnniversary(res.data[0].DateOfAnniversary);
                setFirstmeetingbrief(res.data[0].FirstMeetingBrief);
                getStateList(1);
                getCityList(res.data[0].TehsilId);
                getDistrictList(res.data[0].StateId);
                getTehsilList(res.data[0].DistrictId);
                setOLDChannelSubSourceLog(res.data[0].ChannelPartnerLogo);
                setOLDChannelSubSourceVisitingCard(res.data[0].VistingCard);
            })
    }

    const getSelectChannelDetailById = async (ChId) => {
        var AUrl = global.APIURL + '/WebChannelDetail/' + ChId;
        axios.get(AUrl)
            .then(CDres => {
                let VChannelDetail = CDres.data.map((CDres) => {
                    return CDres
                })
                setSelectChannelDetail(VChannelDetail);
            })
    }

    const getDesignationListdetails = async () => {
        fetch(global.APIURL + "/DesignationActiveList")
            .then((res) => res.json())
            .then((json) => {
                setDesignationList(json)

            })
    }

    const getCountryList = async () => {
        fetch(global.APIURL + "/CountryActiveList")
            .then((res) => res.json())
            .then((json) => {
                setCountryList(json)
            })
    }

    const getStateList = async (CId) => {
        var AUrl = global.APIURL + '/ActiveState/' + CId;
        axios.get(AUrl)
            .then(Sres => {
                let StateL = Sres.data.map((kcstate) => {
                    return kcstate
                })

                setStateList(StateL);
            })
    }

    const getDistrictList = async (SId) => {
        setStateId(SId);
        var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
        axios.get(AUrl)
            .then(Cires => {
                let DistrictL = Cires.data.map((kcDistrict) => {
                    return kcDistrict
                })
                setDistrictList(DistrictL);
            })
    }

    const getTehsilList = async (DId) => {
        var AUrl = global.APIURL + '/ActiveTehsil/' + DId;
        fetch(AUrl)
            .then((res) => res.json())
            .then((json) => {
                setTehsilList(json)
            })
    }

    const getCityList = async (TId) => {
        var AUrl = global.APIURL + '/ActiveCity/' + TId;
        axios.get(AUrl)
            .then(Cires => {
                let CityL = Cires.data.map((kccity) => {
                    return kccity
                })
                setCityList(CityL);
            })
    }

    const getDepartment = async () => {
        fetch(global.APIURL + "/DepartmentActiveList")
            .then((res) => res.json())
            .then((json) => {
                setDepartmentList(json)
            })
    }

    const [ChannelSubSourceLog, setChannelSubSourceLog] = useState('');
    const [ChannelSubSourceVisitingCard, setChannelSubSourceVisitingCard] = useState('');

    function ChannelSubSourceLogofetchPost(e) {
        var details = e.target.files[0];
        var details1 = "ChannelSubSourceLogo";
        const formData = new FormData();
        formData.append('Document', details);
        formData.append('FolderName', details1);
        var AUrl = global.APIURL + '/uploadavatar/';
        axios.post(AUrl, formData,
            {
                headers: { "Content-Type": details.type },
            })
            .then((res) => {
                setChannelSubSourceLog(res.data.IName);
               
            });
    }

    function ChannelSubSourceVisitingCardfetchPost(e) {
        var details = e.target.files[0];
        var details1 = "ChannelSubSourceVisitingCard";
        const formData = new FormData();
        formData.append('Document', details);
        formData.append('FolderName', details1);
        var AUrl = global.APIURL + '/uploadavatar/';
        axios.post(AUrl, formData,
            {
                headers: { "Content-Type": details.type },
            })
            .then((res) => {
                setChannelSubSourceVisitingCard(res.data.IName);
            });
    }

    const SaveNewInqiry = async () => {
        const Channellist = {
            ChannelPartnerId: ChannelPartnerId,
            ChannelId: ChannelId,
            Name: Name,
            ChannelSubSourceLogo: ChannelSubSourceLog,
            DepartmentId: DId,
            DesignationId: DesignationId,
            MobileNumber: MobileNumber,
            AlternetMobileNumber: AlterMobileNumber,
            Email: Email,
            AlternetEmail: AlterEmail,
            VistingCard: ChannelSubSourceVisitingCard,
            DateOfBirth: DateOfBirth,
            DateOfAnniversary: DateOfAnniversary,
            FirstMeetingBrief: Firstmeetingbrief,
            CurrentAddress: CuAddress,
            CountryId: 1,
            StateId: StateId,
            CityId: CityId,
            DistrictId: DistrictId,
            TehsilId: TehsilId,
            Pincode: Pincode,
            CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),

        };
      
        axios.post(global.APIURL + "/ChannelSubSourceUpdate", Channellist)
            .then(res => {
                if (res.data.length > 0) {
                    alert(res.data);
                    history.push("/ChannelSubSource");
                }
            });

    };

    const GetSetChannelId = async CId => {
        setChannelId(CId);
        getSelectChannelDetailById(CId);
    };

    const TehsilDetailSave = async (e) => {
        e.preventDefault();
        var VTehsilTitle = document.getElementById("txtTehsil").value;
        if (VTehsilTitle != '') {
            const TehsilArr = {
                TehsilId: '0',
                CountryId: document.getElementById("ddlCountry").value,
                StateId: document.getElementById("ddlState").value,
                DistrictId: document.getElementById("ddlDistrict").value,
                Title: VTehsilTitle,
                CreateUser: "1"
            }

            axios.post(global.APIURL + '/Tehsil', TehsilArr)
                .then(res => {
                    alert(res.data);
                    var DistrictId = document.getElementById("ddlDistrict").value;
                    getTehsilList(DistrictId);
                });
        }
        document.getElementById("txtTehsil").value = '';
    }

    const TehsilClose = async (e) => {
        e.preventDefault();
        document.getElementById("txtPerTehsil").value = '';
    }

    const CityDetailSave = async (e) => {
        e.preventDefault();
        var VCityTitle = document.getElementById("txtCity").value;
        if (VCityTitle != '') {
            const CityArr = {
                CountryId: document.getElementById("ddlCountry").value,
                StateId: document.getElementById("ddlState").value,
                DistrictId: document.getElementById("ddlDistrict").value,
                TehsilId: document.getElementById("ddlTehsil").value,
                CityId: '0',
                Title: VCityTitle,
                Flag: "A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
            }
            axios.post(global.APIURL + '/City', CityArr)
                .then(res => {
                    alert(res.data.StatusMessage);
                    var VTehsilId = document.getElementById("ddlTehsil").value;
                    getCityList(VTehsilId)
                });
        }
        document.getElementById("txtCity").value = '';
    }

    const CityClose = async (e) => {
        e.preventDefault();
        document.getElementById("txtCity").value = '';
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Channel Sub Source | KC Admin Group</title>
                </MetaTags>
                <div className="container-fluid">
                    <div className="modal fade" id="banking1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        Channel List
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <Row>
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label htmlFor="formFileSm" className="form-label">Channel Type</label>
                                                <select id="ddlChannelType" className="form-select" aria-label="Default select example" onChange={e => { getModelLeadChannelList(); getDropdownChannelMasterList(); getDropdownCityList(); getDropdownBranchList() }}>
                                                    <option selected value="0">Select Channel Type</option>
                                                    {DropdownChannelTypeList.map(item => (
                                                        <option key={item.ChannelTypeId} value={item.ChannelTypeId}>
                                                            {item.ChannelTypeName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label htmlFor="formFileSm" className="form-label">Channel Master</label>
                                                <select id="ddlChannel" className="form-select" aria-label="Default select example" onChange={e => { getModelLeadChannelList(); getDropdownCityList(); getDropdownBranchList() }}>
                                                    <option selected value="0">Select Channel</option>
                                                    {DropdownChannelList.map(item => (
                                                        <option key={item.ChannelMasterId} value={item.ChannelMasterId}>
                                                            {item.Title}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label htmlFor="formFileSm" className="form-label">City</label>
                                                <select id="ddlCity" className="form-select" aria-label="Default select example" onChange={e => { getModelLeadChannelList(); getDropdownBranchList() }}>
                                                    <option selected value="0">Select City</option>
                                                    {DropdownCityList.map(item => (
                                                        <option key={item.CityId} value={item.CityId}>
                                                            {item.Title}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-3">
                                                <label htmlFor="formFileSm" className="form-label">Branch</label>
                                                <select id="ddlBranch" className="form-select" aria-label="Default select example" onChange={e => getModelLeadChannelList()}>
                                                    <option selected value="0">Select Branch</option>
                                                    {DropdownChannelBranchList.map(item => (
                                                        <option key={item.BranchId} value={item.BranchId}>
                                                            {item.BranchName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">

                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <label htmlFor="formFileSm" className="form-label">
                                                        Result
                                                    </label>
                                                </div>
                                                <div>
                                                    <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddNewBank">+ Add New</a></span>
                                                </div>
                                            </div>
                                            <hr />
                                            {ModelChannelList.map(item => (
                                                <div className="team-modal-box mb-2 mt-2" key={item.ChannelId}>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="d-flex align-items-center border-bottom pb-2">
                                                            <div className="flex-shrink-0">
                                                                <div className="avatar-md">
                                                                    <img
                                                                        src={item.ChannelImage}
                                                                        alt=""
                                                                        className="img-thumbnail"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <div className="font-14 dark-font mb-1">
                                                                    {item.ChannelName}
                                                                </div>
                                                                <div className="mb-1">
                                                                    <a href="" className="table-link-a font-12">
                                                                        Channel : {item.ChannelMaster}
                                                                    </a>
                                                                </div>
                                                                <div className="mb-1">
                                                                    <a href="" className="table-link-a font-12">
                                                                        Type : {item.ChannelTypeName}
                                                                    </a>
                                                                </div>
                                                                <div className="mb-1">
                                                                    <a href="" className="table-link-a font-12">
                                                                        Branch : {item.BranchName} |  IFSE Code : {item.BranchIFSCCOde}
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                type="radio"
                                                                id="1"
                                                                className="form-check-input"
                                                                name="ChannelList"
                                                                value={item.ChannelId}
                                                                data-bs-dismiss="modal" aria-label="Close"
                                                                onChange={() => GetSetChannelId(parseInt(item.ChannelId == null ? 0 : item.ChannelId == '' ? 0 : item.ChannelId))}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="pt-2">
                                                        <span className="badge badge-soft-primary p-2 me-2">
                                                            {item.BranchStateName}
                                                        </span>
                                                        <span className="badge badge-soft-primary p-2 me-2">
                                                            {item.BranchDistrictName}
                                                        </span>
                                                        <span className="badge badge-soft-primary p-2 me-2">
                                                            {item.BranchCityName}
                                                        </span>
                                                        <span className="badge badge-soft-primary p-2 me-2">
                                                            {item.BranchTehsilName}
                                                        </span>
                                                        <span className="badge badge-soft-primary p-2 me-2">
                                                            {item.BranchPincode}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade bd-example-modal-sm" id="AddTehsil" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div>
                                        <h5 className="modal-title" id="exampleModalLabel">Add Tehsil</h5>
                                    </div>
                                    <div>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={TehsilClose}></button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <Row>

                                        <Col lg={12} className="mt-2">
                                            <div className="mb-2">
                                                <label className="form-label">Tehsil</label>
                                                <Input type="text" className="form-control" id="txtTehsil" placeholder="Enter Tehsil" />
                                            </div>
                                        </Col>
                                        <Col lg={12} className="mt-2">
                                            <button className="btn btn_success btn-sm" id="btnsave" onClick={TehsilDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                                            <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={TehsilClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade bd-example-modal-sm" id="AddCity" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div>
                                        <h5 className="modal-title" id="exampleModalLabel">Add City</h5>
                                    </div>
                                    <div>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={CityClose}></button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <Row>

                                        <Col lg={12} className="mt-2">
                                            <div className="mb-2">
                                                <label className="form-label">City</label>
                                                <Input type="text" className="form-control" id="txtCity" placeholder="Enter City" />
                                            </div>
                                        </Col>
                                        <Col lg={12} className="mt-2">
                                            <button className="btn btn_success btn-sm" id="btnsave" onClick={CityDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                                            <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={CityClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ind-card" id="ind-card">
                        <Row className="mb-3">
                            <Col className="col-lg-8 col-md-6 col-12">
                                <div className="breadcrum ps-0">
                                    <CardTitle className="h4">Edit Channel Sub Source</CardTitle>
                                    <a href="/GeneralDashboard" className="me-2">Home</a>{" "}
                                    <span>/</span>
                                    <a href="/ChannelSubSource" className="me-2 ms-2">Channel Sub Source</a>{" "}
                                    <span>/</span>
                                    <span className="ms-2">Edit Channel Sub Source</span>
                                </div>
                            </Col>
                            <Col className="col-lg-4 co-md-6 col-12">
                                <a className="btn btn-primary btn-sm float-end font-14" href="/ChannelSubSource">
                                    <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                                </a>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <Row>
                                    <div className="col-lg-6 col-md-6">
                                        <CardTitle>
                                            Channel
                                            <span className="float-end text-primary font-12">
                                                <a
                                                    href="#"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#banking1"
                                                >
                                                    <i className="far fa-check-circle"> Select</i>
                                                </a>
                                            </span>
                                        </CardTitle>
                                        <hr />
                                        {SelectChannelDetail.map(item => (
                                            <div className="team-modal-box mt-3 mb-2" key={item.ChannelId}>
                                                <div className="d-md-flex d-block align-items-center justify-content-between">
                                                    <div className="d-md-flex d-block align-items-center">

                                                        <div className="flex-shrink-0">
                                                            <div className="avatar-md">
                                                                <img
                                                                    src={item.ChannelImage}
                                                                    alt=""
                                                                    className="img-thumbnail"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 ms-md-3 ms-0 mb-md-0 mb-3">
                                                            <div className="font-14 dark-font mb-1">
                                                                {item.ChannelName} ({item.ChannelMaster})
                                                            </div>
                                                            <div className="mb-1">
                                                                <a href="" className="table-link-a font-12">
                                                                    Type : {item.ChannelTypeName}
                                                                </a>
                                                            </div>
                                                            <div className="">
                                                                <a href="" className="table-link-a font-12">
                                                                    Branch : {item.BranchName} | IFSE Code : {item.BranchIFSCCOde}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bankdiv">
                                                        <div className="">
                                                            <a href="" className="table-link-a">
                                                                <p className="mb-2 text-success">
                                                                    <i className="fas fa-phone-alt me-1"></i>
                                                                    {item.BranchContactNumber}
                                                                </p>
                                                            </a>
                                                            <a href="" className="table-link-a">
                                                                <p className="mb-0 text-primary">
                                                                    <i className="fas fa-envelope me-1"></i>
                                                                    {item.BranchEmailAddress}
                                                                </p>
                                                            </a>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="d-md-flex d-block justify-content-between align-items-center">
                                                    <div className="ms-2 mt-2">
                                                        <div className="">
                                                            <span className="badge badge-soft-primary p-2 me-2">
                                                                {item.BranchState}
                                                            </span>
                                                            <span className="badge badge-soft-primary p-2 me-2">
                                                                {item.BranchDistrict}
                                                            </span>
                                                            <span className="badge badge-soft-primary p-2 me-2">
                                                                {item.BranchCity}
                                                            </span>
                                                            <span className="badge badge-soft-primary p-2 me-2">
                                                                {item.BranchTehsil}
                                                            </span>
                                                            <span className="badge badge-soft-primary p-2 me-2">
                                                                {item.Pincode}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="bankdiv1 mt-1">
                                                        <p className="mb-0 font-12">Approved | CID : {item.ChannelCode}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col className="col-lg-12 mb-2">
                                        <CardTitle>Contact Person Detail</CardTitle>
                                        <hr />
                                    </Col>
                                    <Col lg={4} className="mt-2">
                                        <div className="mb-3 labeledit">
                                            <label htmlFor="uploadimage" className="form-label">
                                                Logo / Profile Image
                                            </label>
                                            <input type="file" className="form-control form-control" name="file" onChange={ChannelSubSourceLogofetchPost} defaultValue={OLDChannelSubSourceVisitingCard} />
                                        </div>
                                    </Col>
                                    <Col lg={4} className="mt-2">
                                        <div className="mb-2">
                                            <label className="form-label">Name</label>
                                            <Input type="text" className="form-control" id="txtName"
                                                placeholder="Enter Name" onChange={(e) => setName(e.target.value)}
                                                defaultValue={Name} />
                                        </div>
                                    </Col>
                                    <Col lg={4} className="mt-2">
                                        <div className="mb-2">
                                            <label className="form-label">Designation</label>
                                            <select className="form-select" Id="ddlDesignation" name="ddlDesignation" onChange={(e) => setDesignationId(e.target.value)}>
                                                <option value={"0"}>Select Designation</option>
                                                {DesignationList.map((di) => (
                                                    <option key={di.Id} value={di.Id} selected={DesignationId === di.Id}> {di.Name} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col lg={4} className="mt-2">
                                        <div className="mb-2">
                                            <label className="form-label">Department</label>
                                            <select className="form-select" Id="ddlCountry" name="ddlDepartment" onChange={(e) => setDId(e.target.value)}>
                                                <option value={"0"}>Select Department</option>
                                                {DepartmenthhList.map((d) => (
                                                    <option key={d.Id} value={d.Id} selected={DId === d.Id}> {d.Name} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col lg={4} className="mt-2">
                                        <div className="mb-2">
                                            <label className="form-label">Mobile Number</label>
                                            <Input type="text" className="form-control" id="txtmobileNumber"
                                                placeholder="Enter MobileNumber"
                                                onChange={(e) => setmobileNumber(e.target.value)}
                                                defaultValue={MobileNumber} />
                                        </div>
                                    </Col>
                                    <Col lg={4} className="mt-2">
                                        <div className="mb-2">
                                            <label className="form-label">Alternate Mobile Number</label>
                                            <Input type="text" className="form-control" id="txtAlternatemobileNumber"
                                                placeholder="Enter Alternate MobileNumber"
                                                onChange={(e) => setAltermobileNumber(e.target.value)}
                                                defaultValue={AlterMobileNumber} />
                                        </div>
                                    </Col>
                                    <Col lg={4} className="mt-2">
                                        <div className="mb-2">
                                            <label className="form-label">Email</label>
                                            <Input type="text" className="form-control" id="txtEmail" placeholder="Enter Email"
                                                onChange={(e) => setEmail(e.target.value)}
                                                defaultValue={Email} />
                                        </div>
                                    </Col>
                                    <Col lg={4} className="mt-2">
                                        <div className="mb-2">
                                            <label className="form-label"> Alternate Email</label>
                                            <Input type="text" className="form-control" id="txtEmail"
                                                placeholder="Enter AlternateEmail" onChange={(e) => setAlterEmail(e.target.value)}
                                                defaultValue={AlterEmail} />
                                        </div>
                                    </Col>
                                    <Col lg={4} className="mt-2">
                                        <div className="mb-3 labeledit">
                                            <label htmlFor="uploadimage" className="form-label">
                                                Visiting Card
                                            </label>
                                            <input type="file" className="form-control form-control" name="file" onChange={ChannelSubSourceVisitingCardfetchPost} defaultValue={OLDChannelSubSourceVisitingCard} />

                                        </div>
                                    </Col>
                                    <Col lg={4} className="mt-2">
                                        <div className="mb-2">
                                            <label className="form-label"> Date Of Birth</label>
                                            <Input type="date" className="form-control" id="txtBirth"
                                                onChange={(e) => setDateOfBirth(e.target.value)}
                                                defaultValue={DateOfBirth} />
                                        </div>
                                    </Col>
                                    <Col lg={4} className="mt-2">
                                        <div className="mb-2">
                                            <label className="form-label"> Date Of Anniversary</label>
                                            <Input type="date" className="form-control" id="txtAnniversary"
                                                onChange={(e) => setDateOfAnniversary(e.target.value)}
                                                defaultValue={DateOfAnniversary} />
                                        </div>
                                    </Col>
                                    <Col lg={6} className="mt-2">
                                        <div className="mb-2">
                                            <label className="form-label">First Meeting Brief </label>
                                            <Input type="text"
                                                className="form-control"
                                                placeholder="First Meeting Brief"
                                                id="txtFirst"
                                                rows="3"
                                                onChange={(e) => setFirstmeetingbrief(e.target.value)}
                                                defaultValue={Firstmeetingbrief} />
                                        </div>
                                    </Col>
                                    <Col lg={6} className="mt-2">
                                        <div className="mb-2">
                                            <label className="form-label">Current Address</label>
                                            <Input type="text" className="form-control" id="txtcAddress"
                                                placeholder="Enter CAddress"
                                                onChange={(e) => setCuAddress(e.target.value)}
                                                defaultValue={CuAddress} />
                                        </div>
                                    </Col>
                                    <Col lg={2} className="mt-2">
                                        <div className="mb-2">
                                            <label className="form-label">Country</label>
                                            <select className="form-select" Id="ddlCountry" name="ddlCountry" onChange={(e) => { getStateList(e.target.value); setCountryId(e.target.value) }}>
                                                <option value={"0"}>Select Country</option>
                                                {CountryList.map((country) => (
                                                    <option key={country.CountryId} value={country.CountryId} selected={CountryId === country.CountryId}> {country.Title} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col lg={2} className="mt-2">
                                        <div className="mb-2">
                                            <label className="form-label">State</label>
                                            <select className="form-select" Id="ddlState" name="ddlState" onChange={(e) => { getDistrictList(e.target.value); setStateId(e.target.value) }}>
                                                <option value={"0"}>Select State</option>
                                                {StateList.map((state) => (
                                                    <option key={state.StateId} value={state.StateId} selected={StateId === state.StateId}> {state.Title} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Col>

                                    <Col lg={2} className="mt-2">
                                        <div className="mb-2">
                                            <label className="form-label">District</label>
                                            <select className="form-select" Id="ddlDistrict" name="ddlDistrict" onChange={(e) => { getTehsilList(e.target.value); setDistrictId(e.target.value) }}>
                                                <option value={"0"}>Select District</option>
                                                {DistrictList.map((district) => (
                                                    <option key={district.DistrictId} value={district.DistrictId} selected={DistrictId === district.DistrictId}> {district.Title} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Col>

                                    <Col lg={2} className="mt-2">
                                        <div className="mb-2">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <label htmlFor="formFileSm" className="form-label">Tehsil</label>
                                                </div>
                                                <div>
                                                    {AddTehsilRights == "A" ? DistrictId > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddTehsil">+ Add New</a></span> : '' : ''}
                                                </div>
                                            </div>
                                            <select className="form-select" Id="ddlTehsil" name="ddlTehsil" onChange={(e) => { getCityList(e.target.value); setTehsilId(e.target.value) }}>
                                                <option value={"0"}>Select Tehsil</option>
                                                {TehsilList.map((Tehsil) => (
                                                    <option key={Tehsil.TehsilId} value={Tehsil.TehsilId} selected={TehsilId === Tehsil.TehsilId}> {Tehsil.Title} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col lg={2} className="mt-2">
                                        <div className="mb-2">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <label htmlFor="formFileSm" className="form-label">City/Village/Town</label>
                                                </div>
                                                <div>
                                                    {AddCityRights == "A" ? TehsilId > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddCity">+ Add New</a></span> : '' : ''}
                                                </div>
                                            </div>
                                            <select className="form-select" Id="ddlCity" name="ddlCity" onChange={(e) => setCityId(e.target.value)}>
                                                <option value={"0"}>Select City/Village/Town</option>
                                                {CityList.map((city) => (
                                                    <option key={city.CityId} value={city.CityId} selected={CityId === city.CityId}> {city.Title} </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col lg={2} className="mt-2">
                                        <div className="mb-2">
                                            <label className="form-label">Pincode</label>
                                            <Input type="text" className="form-control" id="txtPincode" placeholder="Enter Pincode" onChange={(e) => setPincode(e.target.value)} defaultValue={Pincode} />
                                        </div>
                                    </Col>
                                    <Col lg={12} className="mt-2">
                                        <button className="btn btn_success btn-sm" id="btnsave" onClick={SaveNewInqiry} >Save</button>
                                        <a className="btn btn_danger btn-sm ms-2" href="/ChannelSubSource">Clear</a>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>




                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EditChannelSubSource;
