import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle, } from "reactstrap"
import MetaTags from 'react-meta-tags'
// import images



// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import "../../../assets/scss/datatables.scss"

// Table data
const products = [

 
  {
    "sr": 1,
    "date time": <> <p className=" mb-1">11 October 2022 To
    </p> <p className="mb-0"> 03:24 PM </p> </> ,
    "interview date": <> <p className=" mb-1">08 October 2021 To
    </p> <p className="mb-0"> 12 October 2021 </p> </>,
    "position": "Laravel developer	",
    "walking": "Yes",
    "details": <a className="text-decoration-underline" href="#" data-bs-toggle="modal" data-bs-target="#job_detail"> View Detail </a>,
    "applicant": <a className="text-decoration-underline" href="/JobApplicants"> View Detail </a> ,
    "status": <span className="badge rounded-pill badge-soft-success font-12">	Active</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a  className="d-block p-1" href="#"> Delete Record </a>
    </ul>
  </div>
  },

  {
    "sr": 2,
    "date time": <> <p className=" mb-1">11 October 2022 To
    </p> <p className="mb-0"> 03:24 PM </p> </> ,
    "interview date": <> <p className=" mb-1">08 October 2021 To
    </p> <p className="mb-0"> 12 October 2021 </p> </>,
    "position": "Laravel developer	",
    "walking": "Yes",
    "details": <a className="text-decoration-underline" href="#" data-bs-toggle="modal" data-bs-target="#job_detail"> View Detail </a>,
    "applicant": <a className="text-decoration-underline" href="/JobApplicants"> View Detail </a> ,
    "status": <span className="badge rounded-pill badge-soft-danger font-12">	Closed</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a  className="d-block p-1" href="#"> Delete Record </a>
    </ul>
  </div>
  },

  {
    "sr": 3,
    "date time": <> <p className=" mb-1">11 October 2022 To
    </p> <p className="mb-0"> 03:24 PM </p> </> ,
    "interview date": <> <p className=" mb-1">08 October 2021 To
    </p> <p className="mb-0"> 12 October 2021 </p> </>,
    "position": "Laravel developer	",
    "walking": "Yes",
    "details": <a className="text-decoration-underline" href="#" data-bs-toggle="modal" data-bs-target="#job_detail"> View Detail </a>,
    "applicant": <a className="text-decoration-underline" href="/JobApplicants"> View Detail </a> ,
    "status": <span className="badge rounded-pill badge-soft-success font-12">	Active</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a  className="d-block p-1" href="#"> Delete Record </a>
    </ul>
  </div>
  },

  {
    "sr": 4,
    "date time": <> <p className=" mb-1">11 October 2022 To
    </p> <p className="mb-0"> 03:24 PM </p> </> ,
    "interview date": <> <p className=" mb-1">08 October 2021 To
    </p> <p className="mb-0"> 12 October 2021 </p> </>,
    "position": "Laravel developer	",
    "walking": "Yes",
    "details": <a className="text-decoration-underline" href="#" data-bs-toggle="modal" data-bs-target="#job_detail"> View Detail </a>,
    "applicant": <a className="text-decoration-underline" href="/JobApplicants"> View Detail </a> ,
    "status": <span className="badge rounded-pill badge-soft-success font-12">	Active</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a  className="d-block p-1" href="#"> Delete Record </a>
    </ul>
  </div>
  },

  {
    "sr": 5,
    "date time": <> <p className=" mb-1">11 October 2022 To
    </p> <p className="mb-0"> 03:24 PM </p> </> ,
    "interview date": <> <p className=" mb-1">08 October 2021 To
    </p> <p className="mb-0"> 12 October 2021 </p> </>,
    "position": "Laravel developer	",
    "walking": "Yes",
    "details": <a className="text-decoration-underline" href="#" data-bs-toggle="modal" data-bs-target="#job_detail"> View Detail </a>,
    "applicant": <a className="text-decoration-underline" href="/JobApplicants"> View Detail </a> ,
    "status": <span className="badge rounded-pill badge-soft-success font-12">	Active</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a  className="d-block p-1" href="#"> Delete Record </a>
    </ul>
  </div>
  },

  {
    "sr": 6,
    "date time": <> <p className=" mb-1">11 October 2022 To
    </p> <p className="mb-0"> 03:24 PM </p> </> ,
    "interview date": <> <p className=" mb-1">08 October 2021 To
    </p> <p className="mb-0"> 12 October 2021 </p> </>,
    "position": "Laravel developer	",
    "walking": "Yes",
    "details": <a className="text-decoration-underline" href="#" data-bs-toggle="modal" data-bs-target="#job_detail"> View Detail </a>,
    "applicant": <a className="text-decoration-underline" href="/JobApplicants"> View Detail </a> ,
    "status": <span className="badge rounded-pill badge-soft-success font-12">	Active</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a  className="d-block p-1" href="#"> Delete Record </a>
    </ul>
  </div>
  },

  {
    "sr": 7,
    "date time": <> <p className=" mb-1">11 October 2022 To
    </p> <p className="mb-0"> 03:24 PM </p> </> ,
    "interview date": <> <p className=" mb-1">08 October 2021 To
    </p> <p className="mb-0"> 12 October 2021 </p> </>,
    "position": "Laravel developer	",
    "walking": "Yes",
    "details": <a className="text-decoration-underline" href="#" data-bs-toggle="modal" data-bs-target="#job_detail"> View Detail </a>,
    "applicant": <a className="text-decoration-underline" href="/JobApplicants"> View Detail </a> ,
    "status": <span className="badge rounded-pill badge-soft-success font-12">	Active</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a  className="d-block p-1" href="#"> Delete Record </a>
    </ul>
  </div>
  },

  {
    "sr": 8,
    "date time": <> <p className=" mb-1">11 October 2022 To
    </p> <p className="mb-0"> 03:24 PM </p> </> ,
    "interview date": <> <p className=" mb-1">08 October 2021 To
    </p> <p className="mb-0"> 12 October 2021 </p> </>,
    "position": "Laravel developer	",
    "walking": "Yes",
    "details": <a className="text-decoration-underline" href="#" data-bs-toggle="modal" data-bs-target="#job_detail"> View Detail </a>,
    "applicant": <a className="text-decoration-underline" href="/JobApplicants"> View Detail </a> ,
    "status": <span className="badge rounded-pill badge-soft-success font-12">	Active</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a  className="d-block p-1" href="#"> Delete Record </a>
    </ul>
  </div>
  },

  {
    "sr": 9,
    "date time": <> <p className=" mb-1">11 October 2022 To
    </p> <p className="mb-0"> 03:24 PM </p> </> ,
    "interview date": <> <p className=" mb-1">08 October 2021 To
    </p> <p className="mb-0"> 12 October 2021 </p> </>,
    "position": "Laravel developer	",
    "walking": "Yes",
    "details": <a className="text-decoration-underline" href="#" data-bs-toggle="modal" data-bs-target="#job_detail"> View Detail </a>,
    "applicant": <a className="text-decoration-underline" href="/JobApplicants"> View Detail </a> ,
    "status": <span className="badge rounded-pill badge-soft-success font-12">	Active</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a  className="d-block p-1" href="#"> Delete Record </a>
    </ul>
  </div>
  },

  {
    "sr": 10,
    "date time": <> <p className=" mb-1">11 October 2022 To
    </p> <p className="mb-0"> 03:24 PM </p> </> ,
    "interview date": <> <p className=" mb-1">08 October 2021 To
    </p> <p className="mb-0"> 12 October 2021 </p> </>,
    "position": "Laravel developer	",
    "walking": "Yes",
    "details": <a className="text-decoration-underline" href="#" data-bs-toggle="modal" data-bs-target="#job_detail"> View Detail </a>,
    "applicant": <a className="text-decoration-underline" href="/JobApplicants"> View Detail </a> ,
    "status": <span className="badge rounded-pill badge-soft-success font-12">	Active</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a  className="d-block p-1" href="#"> Delete Record </a>
    </ul>
  </div>
  },

  {
    "sr": 11,
    "date time": <> <p className=" mb-1">11 October 2022 To
    </p> <p className="mb-0"> 03:24 PM </p> </> ,
    "interview date": <> <p className=" mb-1">08 October 2021 To
    </p> <p className="mb-0"> 12 October 2021 </p> </>,
    "position": "Laravel developer	",
    "walking": "Yes",
    "details": <a className="text-decoration-underline" href="#" data-bs-toggle="modal" data-bs-target="#job_detail"> View Detail </a>,
    "applicant": <a className="text-decoration-underline" href="/JobApplicants"> View Detail </a> ,
    "status": <span className="badge rounded-pill badge-soft-success font-12">	Active</span> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a  className="d-block p-1" href="#"> Delete Record </a>
    </ul>
  </div>
  },

];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    const columns = [{
      dataField: 'sr',
      text: 'Sr',
      sort: true,
    }, {
      dataField: 'date time',
      text: 'Date & Time',
      sort: true
    }, {
      dataField: 'interview date',
      text: 'Interview Date',
      sort: true
    }, {
      dataField: 'position',
      text: 'Position',
      sort: true
    }, {
      dataField: 'walking',
      text: 'Walking',
      sort: true
    }, {
      dataField: 'details',
      text: 'Details',
      sort: true
    }, {
      dataField: 'applicant',
      text: 'Applicant',
      sort: true
    }, {
      dataField: 'status',
      text: 'Status',
      sort: true
    }, {
      dataField: 'action',
      text: 'Action',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

  
  

    const { SearchBar } = Search;

    return (
      <React.Fragment>

        <div className="page-content">
          <MetaTags>
          <title>Job Opening List | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                      <CardTitle className="h6 mb-2">Job Opening List</CardTitle>
                      <a href="#" className="me-2">Home</a> <span>/</span><span className="ms-2">Job Opening List</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                    
                    {/* <a className ="btn btn-success btn-sm float-end font-14 me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" href="#"><i className="mdi mdi-plus align-middle me-1"></i>Add</a> */}
                  </Col>
              </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                                <Row className="mb-2">
                             
                                <Col className="col-lg-4 col-md-4 col-6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-lg-8 col-md-8 col-6">

                                <a className="btn btn-green btn-sm float-end font-14" href="/EditJob"><i className="mdi mdi-plus align-middle me-1"></i>Add Job Opening</a>
              {/* <div className="float-end">
                      <Dropdown
                        isOpen={this.state.info_dropup111}
                        direction="left"
                        className="btn-group dropstart"
                        toggle={() =>
                          this.setState({
                            info_dropup111: !this.state.info_dropup111,
                          })
                        }
                      >
                        <DropdownToggle className="btn btn-primary-drop">
                          <i className="mdi mdi-filter"></i> Filter
                        </DropdownToggle>
                        <DropdownMenu data-popper-placement="left-start">
                          <DropdownItem>Select</DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem>Select</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div> */}
              </Col>
                              </Row>



                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                     
                                      classes={
                                        "table align-middle table-nowrap todo-text"
                                      }
                                      headerWrapperClasses={"thead-light table-thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>
        </div>
      
        

<div className="modal fade" id="job_detail" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Job Details</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        ...
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn_danger" data-bs-dismiss="modal">Close</button>
         
      </div>
    </div>
  </div>
</div>


      </React.Fragment>
    )
  }
}

export default DatatableTables