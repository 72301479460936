import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle,Dropdown,DropdownItem,DropdownToggle,DropdownMenu, } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


import "../../../../assets/scss/datatables.scss"

// Table data
const products = [

 
  {
    "id": 1,
    "invoice no": "00002",
    "company": "Sunshine Technology",
    "vendor": "SIlver Tech",
    "invoice date": "2021-12-13",
    "total amount": "1500.00"
  },
  {
    "id": 2,
    "invoice no": "00002",
    "company": "Sunshine Technology",
    "vendor": "SIlver Tech",
    "invoice date": "2021-12-13",
    "total amount": "1500.00"
  },
  {
    "id": 3,
    "invoice no": "00002",
    "company": "Sunshine Technology",
    "vendor": "SIlver Tech",
    "invoice date": "2021-12-13",
    "total amount": "1500.00"
  },
  {
    "id": 4,
    "invoice no": "00002",
    "company": "Sunshine Technology",
    "vendor": "SIlver Tech",
    "invoice date": "2021-12-13",
    "total amount": "1500.00"
  },
  {
    "id": 5,
    "invoice no": "00002",
    "company": "Sunshine Technology",
    "vendor": "SIlver Tech",
    "invoice date": "2021-12-13",
    "total amount": "1500.00"
  },
  {
    "id": 6,
    "invoice no": "00002",
    "company": "Sunshine Technology",
    "vendor": "SIlver Tech",
    "invoice date": "2021-12-13",
    "total amount": "1500.00"
  },
  {
    "id": 7,
    "invoice no": "00002",
    "company": "Sunshine Technology",
    "vendor": "SIlver Tech",
    "invoice date": "2021-12-13",
    "total amount": "1500.00"
  },
  {
    "id": 8,
    "invoice no": "00002",
    "company": "Sunshine Technology",
    "vendor": "SIlver Tech",
    "invoice date": "2021-12-13",
    "total amount": "1500.00"
  },
  {
    "id": 9,
    "invoice no": "00002",
    "company": "Sunshine Technology",
    "vendor": "SIlver Tech",
    "invoice date": "2021-12-13",
    "total amount": "1500.00"
  },
  {
    "id": 10,
    "invoice no": "00002",
    "company": "Sunshine Technology",
    "vendor": "SIlver Tech",
    "invoice date": "2021-12-13",
    "total amount": "1500.00"
  },

  {
    "id": 11,
    "invoice no": "00002",
    "company": "Sunshine Technology",
    "vendor": "SIlver Tech",
    "invoice date": "2021-12-13",
    "total amount": "1500.00"
  },
  

  {
    "id": 12,
    "invoice no": "00002",
    "company": "Sunshine Technology",
    "vendor": "SIlver Tech",
    "invoice date": "2021-12-13",
    "total amount": "1500.00"
  }
  
  

];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    const columns = [{
      dataField: 'id',
      text: '#',
      sort: true,
    }, {
      dataField: 'invoice no',
      text: 'Invoice No',
      sort: true
    }, {
      dataField: 'company',
      text: 'Company	Vendor',
      sort: true
      }, {
        dataField: 'vendor',
        text: 'Vendor',
        sort: true
      },  , {
      dataField: 'invoice date',
      text: 'Invoice Date',
      sort: true
    }, {
      dataField: 'total amount',
      text: 'Total Amount',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

 

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Invoice & Payment History | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
            

            <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h3">Invoice & Payment History</CardTitle>
                      <a href="/VendorList" className="me-2">Vendor</a> <span>/</span><span className="ms-2">Invoice & Payment History</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 col-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>

              <Card>
          <CardBody>
                <Row>
                  <Col className="col-lg-4 col-md-12 col-12 mb-3">
                    <div className="vendor_summary_box">
                        
                      <ul className="d-flex list-unstyled mb-0 border-0">
                        <li className=" flex-grow-1 border_right py-2">
                        <p className="mb-0">Total</p>
                                    <h6 className="mb-0 fs-4 text-black-50">11362.00</h6>
                        </li>
                        <li className="flex-grow-1 py-2">
                        <p className="mb-0">Total Tax</p>
                                    <h6 className="mb-0 text-black-50 fs-4 ">11362.00</h6>
                        </li>
                      </ul> 
                      <ul className="d-flex list-unstyled mb-0">
                        <li className="border_right flex-grow-1 border_right py-2">
                        <p className="mb-0">Total SGST</p>
                                    <h6 className="mb-0 text-black-50 fs-4 ">11362.00</h6>
                        </li>
                        <li className="border_right flex-grow-1 py-2">
                        <p className="mb-0">Total CGST</p>
                                    <h6 className="mb-0 text-black-50 fs-4 ">11362.00</h6>
                        </li>
                        <li className="flex-grow-1 py-2">
                        <p className="mb-0">Total IGST</p>
                                    <h6 className="mb-0 text-black-50 fs-4 ">11362.00</h6>
                        </li>
                      </ul> 
                     </div>
                  </Col>
                  <Col className="col-lg-4 col-md-12  col-12 mb-3">
                    <div className="vendor_summary_box">
                         <h4 className="py-3 mb-0 fw-bolder">Paid Amount</h4>
                      <ul className="d-flex list-unstyled mb-0">
                        <li className=" flex-grow-1 py-3">
                         
                                    <h6 className="mb-0 text-black-50 fs-3 ">11362.00</h6>
                        </li>
                       </ul> 
                       
                     </div>
                  </Col>
                  <Col className="col-lg-4 col-md-12  col-12 mb-3">
                    <div className="vendor_summary_box">
                         <h4 className="py-3 mb-0 fw-bolder">Due Amount</h4>
                      <ul className="d-flex list-unstyled mb-0">
                        <li className=" flex-grow-1 py-3">
                         
                                    <h6 className="mb-0 text-black-50  fs-3">11362.00</h6>
                        </li>
                       </ul> 
                       
                     </div>
                  </Col>
                </Row>
          </CardBody>
            </Card>
            

            <Card>
                <CardBody>
                <Row>
                  <Col className="col-md-8 col-12">
                    <h5 className="fw-bolder"> Vendor Details  : </h5>
                  </Col>
                  {/* <Col className="col-md-4 col-12 text-md-end text-start">
                    <p className="mb-0"> Total Applicants : 3 </p>
                  </Col> */}
                </Row>
                <hr className="bg_hrdark my-2" />
                <Row>
                  <Col className="col-md-3 col-6 mb-2">
                    <p className="mb-0 text-transform-uppercase"> COMPANY NAME  </p>
                     <p className="fw-bolder mb-0">shine</p>
                  </Col>
                  <Col className="col-md-3 col-6  mb-2">
                    <p className="mb-0 text-transform-uppercase"> PERSON NAME  </p>
                     <p className="fw-bolder mb-0">Smith Jain</p>
                  </Col>
                  <Col className="col-md-3 col-6  mb-2">
                    <p className="mb-0 text-transform-uppercase"> ADDRESS </p>
                     <p className="fw-bolder mb-0">No Data</p>
                  </Col>
                  <Col className="col-md-3 col-6  mb-2">
                    <p className="mb-0 text-transform-uppercase"> CONTACT NUMBER   </p>
                     <p className="fw-bolder mb-0">9833332221</p>
                  </Col>
                </Row>
                <hr className="bg_hrlight my-2" />
                <Row>
                  <Col className="col-md-3 col-6  mb-2">
                    <p className="mb-0 text-transform-uppercase"> EMAIL ID  </p>
                     <p className="fw-bolder mb-0">SmithJ@gmail.com</p>
                  </Col>
                  
                  
                </Row>
                <hr className="bg_hrdark my-2" />
                </CardBody>
            </Card>













            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                  <Col className="col-12">
               
              </Col>
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
              <Col className="col-12 mb-4">
                <div className ="d-flex align-items-center justify-content-between">
                 <div className="d-flex">
                  <CardTitle className="h3 h3 card-title ">Invoice List </CardTitle>
                    <div  className="form-check ms-5  align-self-center">
                     
                  </div>
                 </div>
                  {/* <div className ="d-flex">
                    <a className ="btn btn-primary btn-sm" href="#">Save</a>
                    <a className ="btn btn-primary btn-sm ms-2" href="#">Clear</a>
                  </div> */}
                </div>
              
              </Col>
                    
                  
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                                <Row className="mb-2">
                             
                                <Col className="col-lg-4 col-md-4 col-6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-lg-8 col-md-8 col-6">
              <div className="float-end">
                      <Dropdown
                        isOpen={this.state.info_dropup111}
                        direction="left"
                        className="btn-group dropstart"
                        toggle={() =>
                          this.setState({
                            info_dropup111: !this.state.info_dropup111,
                          })
                        }
                      >
                        <DropdownToggle className="btn btn-primary-drop">
                          <i className="mdi mdi-filter"></i> Filter
                        </DropdownToggle>
                        <DropdownMenu data-popper-placement="left-start">
                          <DropdownItem header>Header</DropdownItem>
                          <DropdownItem disabled>Action</DropdownItem>
                          <DropdownItem>Another Action</DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem>Another Action</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
              </Col>
                              </Row>



                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                     
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light table-thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                       <Col className="col-12 mb-4">
                        <div className ="d-flex align-items-center justify-content-between">
                        <div className="d-flex">
                          <CardTitle className="h3 h3 card-title ">Invoice Payment List </CardTitle>
                            <div  className="form-check ms-5  align-self-center">
                            
                          </div>
                        </div>
                          {/* <div className ="d-flex">
                            <a className ="btn btn-primary btn-sm" href="#">Save</a>
                            <a className ="btn btn-primary btn-sm ms-2" href="#">Clear</a>
                          </div> */}
                        </div>
                      
                       </Col> 
                      </Row>

                          



                          <Row>
                      
                      <Col>
                        <div className="table-responsive">
                          
                       
                                <table className="table">
  <thead className="table-thead-light">
    <tr>
      <th>#</th>
      <th>Invoice No</th>
      <th>Payment Date</th>
      <th>Payment Mode</th>
      <th>Amount</th>
      <th>Bank</th>
      <th>Company</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th>1</th>
      <td>0000002</td>
      <td>2021-12-21</td>
      <td>Cheque</td>
      <td>@130</td>
      <td>BOB</td>
      <td>Excelsior Technology</td>
                              </tr>
                              <tr>
      <th>2</th>
      <td>0000002</td>
      <td>2021-12-21</td>
      <td>Cheque</td>
      <td>@130</td>
      <td>BOB</td>
      <td>Excelsior Technology</td>
                              </tr>
                              <tr>
      <th>3</th>
      <td>0000002</td>
      <td>2021-12-21</td>
      <td>Cheque</td>
      <td>@130</td>
      <td>BOB</td>
      <td>Excelsior Technology</td>
                              </tr>
                              <tr>
      <th>4</th>
      <td>0000002</td>
      <td>2021-12-21</td>
      <td>Cheque</td>
      <td>@130</td>
      <td>BOB</td>
      <td>Excelsior Technology</td>
                              </tr>
                              <tr>
      <th>5</th>
      <td>0000002</td>
      <td>2021-12-21</td>
      <td>Cheque</td>
      <td>@130</td>
      <td>BOB</td>
      <td>Excelsior Technology</td>
                              </tr>
                              <tr>
      <th>6</th>
      <td>0000002</td>
      <td>2021-12-21</td>
      <td>Cheque</td>
      <td>@130</td>
      <td>BOB</td>
      <td>Excelsior Technology</td>
                              </tr>
                              <tr>
      <th>7</th>
      <td>0000002</td>
      <td>2021-12-21</td>
      <td>Cheque</td>
      <td>@130</td>
      <td>BOB</td>
      <td>Excelsior Technology</td>
                              </tr>
                              <tr>
      <th>8</th>
      <td>0000002</td>
      <td>2021-12-21</td>
      <td>Cheque</td>
      <td>@130</td>
      <td>BOB</td>
      <td>Excelsior Technology</td>
                              </tr>
                              <tr>
      <th>9</th>
      <td>0000002</td>
      <td>2021-12-21</td>
      <td>Cheque</td>
      <td>@130</td>
      <td>BOB</td>
      <td>Excelsior Technology</td>
                              </tr>
                              <tr>
      <th>10</th>
      <td>0000002</td>
      <td>2021-12-21</td>
      <td>Cheque</td>
      <td>@130</td>
      <td>BOB</td>
      <td>Excelsior Technology</td>
    </tr>
   
  </tbody>
</table>
                                

                        </div>
                      </Col>
                          </Row>


                    <Row>
                      <div className="text-md-right ms-auto">
                        <ul className="pagination react-bootstrap-table-page-btns-ul">
                          <li className="active page-item" title="1"><a href="#" className="page-link">1</a></li>
                          <li className="page-item" title="2"><a href="#" className="page-link">2</a></li>
                          <li className="page-item" title="next page"><a href="#" className="page-link">&gt;</a></li>
                        </ul></div>
</Row>





                  </CardBody>
                </Card>
              </Col>
            </Row>
            












            {/* <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                  <Col className="col-12">
               
              </Col>
               
              <Col className="col-12 mb-4">
                <div className ="d-flex align-items-center justify-content-between">
                 <div className="d-flex">
                  <CardTitle className="h3 h3 card-title ">Invoice Payment List </CardTitle>
                    <div  className="form-check ms-5  align-self-center">
                     
                  </div>
                 </div>
                   
                </div>
              
              </Col>
                    
                  
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                                <Row className="mb-2">
                             
                                <Col className="col-lg-4 col-md-4 col-6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-lg-8 col-md-8 col-6">
              <div className="float-end">
                      <Dropdown
                        isOpen={this.state.info_dropup111}
                        direction="left"
                        className="btn-group dropstart"
                        toggle={() =>
                          this.setState({
                            info_dropup111: !this.state.info_dropup111,
                          })
                        }
                      >
                        <DropdownToggle className="btn btn-primary-drop">
                          <i className="mdi mdi-filter"></i> Filter
                        </DropdownToggle>
                        <DropdownMenu data-popper-placement="left-start">
                          <DropdownItem header>Header</DropdownItem>
                          <DropdownItem disabled>Action</DropdownItem>
                          <DropdownItem>Another Action</DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem>Another Action</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
              </Col>
                              </Row>



                              

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}








          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DatatableTables