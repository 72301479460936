import React, { Component, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const AddWebServices = () => {


    const history = useHistory();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [Description, setDescription] = useState("");
    const [IconFilePath, setIconFilePath] = useState("");
    const [IconFileName, setIconFileName] = useState("");
    const [IconFileDisplay, setIconFileDisplay] = useState(0);

    const saveWebServicesDetails = async (e) => {
        e.preventDefault();
        let VWebServicesTitle = e.target["txtWebServicesTitle"].value;
        let VDescription = e.target["txtDescription"].value;

        if (VWebServicesTitle == "") {
            alert("Enter Web Service Title");
            return;
        }
        if (VDescription == "") {
            alert("Enter Web Service Description");
            return;
        }

        if (VWebServicesTitle != '') {
            const WSData = {
                Title: VWebServicesTitle,
                IconFile: IconFilePath,
                Description: VDescription,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),

            }

            axios.post(global.APIURL + '/WebServicesSave', WSData)
                .then(res => {
                    alert(res.data[0].RecordStatus);
                    history.push('/WebServices');
                });
        }
    }

    const getIconFile = (e) => {
        var details = e.target.files[0];
        var details1 = "WebServices";

        const formData = new FormData();

        formData.append('Document', details);
        formData.append('FolderName', details1);


        axios.post(global.APIURL + '/uploadavatar', formData, {

            headers: { "Content-Type": details.type },

        }).then((res) => {
            setIconFilePath(res.data.IName);

            setIconFileName(res.data.FileName);

            if (e.target.files[0].name != null) {
                if (e.target.files[0].name != '') {
                    setIconFileDisplay(1);
                }
                else {
                    setIconFileDisplay(0);
                }
            }

        });
    }



    return (
        <div className="page-content">

            <MetaTags>
                <title>Web Services | KC Admin Group</title>
            </MetaTags>

            <div className="container-fluid">

                <Row className="mb-3">
                    <Col className="col-lg-8 col-md-6 col-12 mb-2">
                        <div className="breadcrum ps-0">
                            <CardTitle className="h4">Add Web Services </CardTitle>
                            <a href="/GeneralDashboard" className="me-2">Home</a>
                            <span>/</span>
                            <a href="/WebServices " className="ms-2 me-2">Web Services </a>
                            <span>/</span>
                            <span className="ms-2">Add Web Services </span>
                        </div>
                    </Col>
                    <Col className="col-lg-4 co-md-6 col-12">
                        <a className="btn btn-primary btn-sm float-end font-14" href="/WebServices ">
                            <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                        </a>
                    </Col>
                </Row>
                <form onSubmit={saveWebServicesDetails}>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col lg={5}>
                                            <div className="mb-2">
                                                <label className="form-label">Title</label>
                                                <Input type="text" className="form-control" id="txtWebServicesTitle" placeholder="Enter Web Services Title" />
                                            </div>
                                        </Col>
                                        <Col lg={5}>
                                            <div className="mb-2">
                                                <label className="form-label">Icon File</label>
                                                {IconFileDisplay > 0 ?
                                                    <span className="float-end text-primary font-12" id="spandoctitle">
                                                        <Link to={IconFileName} target="_blank" >{IconFileName}</Link></span> : ''}
                                                <Input type="file" className="form-control" id="txtTestimonyFilePath" accept=".png,.jpg,.jpeg,.gif" onChange={(e) => getIconFile(e)} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col lg={10}>


                                            <div className="mb-2">
                                                <label className="form-label">Description</label>
                                                <textarea
                                                    type="text"
                                                    className="form-control pb-4"
                                                    id="txtDescription"
                                                    rows="1"
                                                    placeholder="Enter Web Services Description"

                                                />
                                            </div>

                                        </Col>

                                        <Col lg={12}>
                                            <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                                            <a className="btn btn_danger btn-sm ms-2" href="/Category">Clear</a>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </form>
            </div>

        </div>
    )
}

export default AddWebServices;