import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label,
  Input,
} from "reactstrap"

import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import classnames from "classnames"

class UiTabsAccordions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col5: true,
      col6: true,
      col7: true,
      col8: true,
      col9: true,
      col10: false,
      col11: false,
    }
    this.toggle = this.toggle.bind(this)
    this.toggle1 = this.toggle1.bind(this)

    this.t_col1 = this.t_col1.bind(this)
    this.t_col2 = this.t_col2.bind(this)
    this.t_col3 = this.t_col3.bind(this)
    this.t_col5 = this.t_col5.bind(this)
    this.t_col6 = this.t_col6.bind(this)
    this.t_col7 = this.t_col7.bind(this)
    this.t_col8 = this.t_col8.bind(this)
    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)

    this.toggle2 = this.toggle2.bind(this)
    this.toggle3 = this.toggle3.bind(this)

    this.toggleVertical = this.toggleVertical.bind(this)
    this.toggleVerticalIcon = this.toggleVerticalIcon.bind(this)
    this.toggleCustom = this.toggleCustom.bind(this)
    this.toggleIconCustom = this.toggleIconCustom.bind(this)
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false
    })
  }

  t_col2() {
    this.setState({
      col1: false,
      col2: !this.state.col2,
      col3: false
    })
  }

  t_col3() {
    this.setState({
      col1: false,
      col2: false,
      col3: !this.state.col3
    })
  }

  t_col5() {
    this.setState({ col5: !this.state.col5 })
  }

  t_col6() {
    this.setState({ col6: !this.state.col6 })
  }

  t_col7() {
    this.setState({ col7: !this.state.col7 })
  }

  t_col8() {
    this.setState({
      col6: !this.state.col6,
      col7: !this.state.col7
    })
  }

  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false
    })
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false
    })
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      })
    }
  }

  toggle2(tab) {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      })
    }
  }

  toggle3(tab) {
    if (this.state.activeTab3 !== tab) {
      this.setState({
        activeTab3: tab,
      })
    }
  }

  toggleVertical(tab) {
    if (this.state.verticalActiveTab !== tab) {
      this.setState({
        verticalActiveTab: tab,
      })
    }
  }

  toggleVerticalIcon(tab) {
    if (this.state.verticalActiveTabWithIcon !== tab) {
      this.setState({
        verticalActiveTabWithIcon: tab,
      })
    }
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      })
    }
  }

  toggleIconCustom(tab) {
    if (this.state.customIconActiveTab !== tab) {
      this.setState({
        customIconActiveTab: tab,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Service Detail | KC Admin Group</title>
          </MetaTags>
          <Container fluid={true}>
            <div className="modal fade service-modal-z" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <Row>
                      <Col className="col-12 mb-3">
                        <div className="pb-2">
                          <div className="notes_box pb-3">
                            <p className="mb-1"> Nihil consectetur sint eos vitae voluptate suscipit dolores nihil. Enim dolores qui quam. Modi quae dolorem exercitationem neque.</p>
                            <div className="modal-sub-box border-bottom pb-3">
                              <div className="department-box">
                                <p className="mb-0">Status: <span className="text-orange">In Process</span></p>
                              </div>
                              <div className="emp-code">
                                <div className="d-flex align-items-center">
                                  <div className="">
                                    <p className="mb-0"><i className="fa fa-comments text-success"></i> <span className="">135 Replay</span></p>
                                  </div>
                                  <div className="">
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-start pt-3 border-bottom pb-3">
                              <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail me-3 img-leads-retail rounded-circle" />
                              <div className="name-box">
                                <div className="h6 font-16">Smith Jain</div>
                                <p className="mb-2">District Group Planner </p>
                                <p className="mb-0">Sed est voluptatem. Cum dolore nihil im dolorem corporis ut. Quisquam quo repudiandae eveniet aut sit molestiae nulla dignissimos. Consectetur harum illo quia omnis temporibus et voluptatem accusamus.</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-start pt-3 border-bottom pb-3">
                              <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail me-3 img-leads-retail rounded-circle" />
                              <div className="name-box">
                                <div className="h6 font-16">Kartik Singh</div>
                                <p className="mb-2">Legacy Response Orchestrator</p>
                                <p className="mb-0">Sed est voluptatem. Cum dolore nihil im dolorem corporis ut. Quisquam quo repudiandae eveniet aut sit molestiae nulla dignissimos. Consectetur harum illo quia omnis temporibus et voluptatem accusamus.</p>
                              </div>
                            </div>
                          </div>
                          <div className="notes_box pb-3">
                            <p className="mb-1"> Saepe consequatur ut earum. Voluptate et qui nulla. Qui et dolor ab occaecati commodi magni nulla harum odio. Rerum nobis corporis harum recusandae sed facere.</p>
                            <div className="modal-sub-box border-bottom pb-3">
                              <div className="department-box">
                                <p className="mb-0">Status: <span className="text-orange">In Process</span></p>
                              </div>
                              <div className="emp-code">
                                <div className="d-flex align-items-center">
                                  <div className="">
                                    <p className="mb-0"><i className="fa fa-comments text-success"></i> <span className="">135 Replay</span></p>
                                  </div>
                                  <div className="">
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-start pt-3 border-bottom pb-3">
                              <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail me-3 img-leads-retail rounded-circle" />
                              <div className="name-box">
                                <div className="h6 font-16">Smith Jain</div>
                                <p className="mb-2">District Group Planner </p>
                                <p className="mb-0">Sed est voluptatem. Cum dolore nihil im dolorem corporis ut. Quisquam quo repudiandae eveniet aut sit molestiae nulla dignissimos. Consectetur harum illo quia omnis temporibus et voluptatem accusamus.</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-start pt-3 border-bottom pb-3">
                              <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail me-3 img-leads-retail rounded-circle" />
                              <div className="name-box">
                                <div className="h6 font-16">Kartik Singh</div>
                                <p className="mb-2">Legacy Response Orchestrator</p>
                                <p className="mb-0">Sed est voluptatem. Cum dolore nihil im dolorem corporis ut. Quisquam quo repudiandae eveniet aut sit molestiae nulla dignissimos. Consectetur harum illo quia omnis temporibus et voluptatem accusamus.</p>
                              </div>
                            </div>
                          </div>
                          <div className="notes_box pb-3">
                            <p className="mb-1"> Saepe consequatur ut earum. Voluptate et qui nulla. Qui et dolor ab occaecati commodi magni nulla harum odio. Rerum nobis corporis harum recusandae sed facere.</p>
                            <div className="modal-sub-box border-bottom pb-3">
                              <div className="department-box">
                                <p className="mb-0">Status: <span className="text-orange">In Process</span></p>
                              </div>
                              <div className="emp-code">
                                <div className="d-flex align-items-center">
                                  <div className="">
                                    <p className="mb-0"><i className="fa fa-comments text-success"></i> <span className="">135 Replay</span></p>
                                  </div>
                                  <div className="">
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-start pt-3 border-bottom pb-3">
                              <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail me-3 img-leads-retail rounded-circle" />
                              <div className="name-box">
                                <div className="h6 font-16">Smith Jain</div>
                                <p className="mb-2">District Group Planner </p>
                                <p className="mb-0">Sed est voluptatem. Cum dolore nihil im dolorem corporis ut. Quisquam quo repudiandae eveniet aut sit molestiae nulla dignissimos. Consectetur harum illo quia omnis temporibus et voluptatem accusamus.</p>
                              </div>
                            </div>
                            <div className="d-flex align-items-start pt-3 border-bottom pb-3">
                              <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail me-3 img-leads-retail rounded-circle" />
                              <div className="name-box">
                                <div className="h6 font-16">Kartik Singh</div>
                                <p className="mb-2">Legacy Response Orchestrator</p>
                                <p className="mb-0">Sed est voluptatem. Cum dolore nihil im dolorem corporis ut. Quisquam quo repudiandae eveniet aut sit molestiae nulla dignissimos. Consectetur harum illo quia omnis temporibus et voluptatem accusamus.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="modal-footer">
                    {/* <button type="button" className="btn btn_success">Save changes</button> */}
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>



            <div className="modal fade" id="sd" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Service Detail</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <Row>
                      <Col lg="12">
                        <div className="p-3">
                          <div className="d-flex align-items-center gap-3 mb-2">
                            <div> Et est vel nemo dolores magnam volas officiis voluptatem magnam volas officiisEt est vel nemo dolores magnam volas officiis voluptatem magnam volas officiisEt est vel nemo dolores magnam volas officiis voluptatem magnam volas officiis.</div>
                          </div>
                          <Row className="align-items-center">
                            <Col className="col-lg-6">
                              <p className="mb-0 font-12">Thu, 1st April 2021 | 09:00 AM - 12:00 PM</p>
                            </Col>
                            <Col className="col-lg-6 text-end">
                              <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                              <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                              <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                              <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            </Col>
                          </Row>
                          <hr></hr>
                          <Row>
                            <Col md="6">
                              <p className="font-14"><i className="fas fa-video me-2"></i>On Zoom Call</p>
                              <p className="font-14"><i className="fas fa-users me-2"></i>3 Member Attended , 2 Decline</p>
                            </Col>
                          </Row>

                          <Row className="mt-3">
                            <Col md={12}>
                              <a href="#">    <span className="product_tag d-inline-block  text-black">Kira Saiyager<i className="fas fa-times text-end text-black ms-3"></i></span></a>
                              <a href="#">  <span className="product_tag d-inline-block text-black"> Hardik Dudhrejiya <i className="fas fa-times text-end text-black ms-3"></i></span></a>
                              <a href="#">   <span className="product_tag d-inline-block text-black"> Yogesh Ramani  <i className="fas fa-times text-end text-black ms-3"></i></span></a>
                              <a href="#"> <span className="product_tag d-inline-block text-black"> Sonia Patel  <i className="fas fa-times text-end text-black ms-3"></i></span></a>
                              <a href="#"> <span className="product_tag d-inline-block text-black"> Kaivil Gajjar  <i className="fas fa-times text-end text-black ms-3"></i></span></a>
                            </Col>
                          </Row>

                        </div>
                      </Col>
                      <div className="col-lg-12">
                        <p>
                          Qui accusamus similique in dolore voluptatum ut est sequi ea. Temporibus repellendus non temporibus aliquam deserunt debitis dolorum et et. Non porro assumenda voluptatibus qui numquam. Quos aliquid et non accusamus. Tempore aperiam temporibus dicta expedita autem provident.
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <p>
                          Rerum magni hic officia at. Quidem quisquam ut placeat quis sit. Ratione fugit reiciendis nihil voluptatem voluptatem eius non in. Et natus eveniet sunt qui autem mollitia cupiditate.
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <p>
                          Sunt dolor iure aut quam neque rem impedit. Alias molestiae deleniti iusto repellendus. At nam ut dignissimos.
                        </p>
                      </div>

                      <div className="col-lg-12 mt-3">
                        <h6 className="mt-3">Upcoming Meeting</h6>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <p className="mb-0">Date</p>
                        <input className="form-control" type="date" id="example-date-input" value=""></input>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <p className="mb-0">Time</p>
                        <input className="form-control" type="time" id="example-date-input" value=""></input>
                      </div>
                    </Row>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn_success">Save changes</button   >
                    <button type="button" className="btn btn_danger" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade service-modal-z" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Notes</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">

                    <Row>
                      <div className="col-lg-12 mb-4">
                        <h6>Add Notes</h6>
                        <textarea className="form-control" placeholder="Type Something" id="exampleFormControlTextarea1" rows="3"></textarea>
                      </div>
                      <Col className="col-12 mb-3">
                        <div className="pb-2">
                          <div className="notes_box notes_box_yellow pb-3 text-black">
                            Open on 16th June 21  <br />
                            <div className="text-muted">by Kartik Singh Mehta</div>
                            <div>Vitae perspiciatis velit similique autem. Ullam dolorem quaerat consequatur blanditiis. Incidunt occaecati modi numquam sapiente voluptatem molestiae. Sit omnis nostrum totam molestiae delectus.</div>
                          </div>
                          <div className="notes_box notes_box_yellow pb-3  text-black">
                            Task Assign to <span className="text-info">Kartik Singh</span> on 17th June 21 <br />
                            <div className="text-muted">by Kartik Singh Mehta</div>
                          </div>
                          <div className="notes_box notes_box_yellow  pb-3  text-black">
                            Created new note on 25th June 21 <br />
                            <div className="text-muted">by Kartik Singh Mehta</div>
                            <div className="bg-light p-3 rounded-2"> Vitae perspiciatis velit similique autem. Ullam dolorem quaerat consequatur blanditiis. Incidunt occaecati modi numquam sapiente voluptatem molestiae. Sit omnis nostrum totam molestiae delectus. </div>
                          </div>
                          <div className="notes_box notes_box_green pb-3 text-black">
                            Created new note with attachment on 25th June 21 <br />
                            <div className="text-muted">by Kartik Singh Mehta</div>
                            <div className="bg-light p-3 rounded-2">Autem voluptatum praesentium sed magni quasi eum provident impedit voluptatum. Quas consequatur non quia et nisi. Qui quia qui autem sit laboriosam nisi. </div>
                            <Row>
                              <Col className="col-md-3 col-6 m-3 ms-0">
                                <span className="d-flex border_dotted p-3 rounded-3 bg-light">
                                  <div>
                                    <i className="mdi mdi-attachment fs-3 me-3"></i>
                                  </div>
                                  <div className="">
                                    <p className="mb-0">todo.PDF</p>
                                    <p className="mb-0">18KB</p>
                                  </div>
                                </span>
                              </Col>
                            </Row>
                          </div>
                          <div className="notes_box notes_box_green pb-3  text-black">
                            Mark as completed on 30th June 21 <br />
                            <div className="text-muted">by Kartik Singh Mehta</div>

                          </div>

                          <div className="notes_box notes_box_blue pb-3  text-black">
                            Mark as re-open on 30th June 21 <br />
                            <div className="text-muted">by Kartik Singh Mehta</div>
                          </div>

                          <div className="notes_box notes_box_lightblue pb-3  text-black">
                            Notes shared on 1st Sep 21  <br />
                            <div className="text-muted">by Kartik Singh Mehta</div>
                          </div>

                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn_success">Save changes</button>
                    <button type="button" className="btn btn_danger" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>





            <div className="modal fade" id="meetings" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Add Meetings</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <Row>
                      <div className="col-lg-12">
                        <h6>Meeting Purpose </h6>
                        <textarea className="form-control" placeholder="Enter Purpose of your meeting" id="exampleFormControlTextarea1" rows="3"></textarea>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <h6>Platform</h6>
                        <div className="d-flex">
                          <div className="form-check mb-2">
                            <input
                              type="radio"
                              id="radio1"
                              name="toastType"
                              className="form-check-input"
                              value="success"
                              defaultChecked
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="radio1"
                            >
                              On Zoom Call
                            </Label>
                          </div>

                          <div className="form-check mb-2 ms-2">
                            <input
                              type="radio"
                              id="radio2"
                              name="toastType"
                              className="form-check-input"
                              value="info"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="radio2"
                            >
                              Offline
                            </Label>
                          </div>
                        </div>
                      </div>

                    </Row>
                    <Row className="mt-3">
                      <h6>Schedule Meeting</h6>
                      <div className="col-lg-6">
                        <p className="mb-0">Date</p>
                        <input className="form-control" type="date" id="example-date-input" value=""></input>
                      </div>
                      <div className="col-lg-6">
                        <p className="mb-0">Time</p>
                        <input className="form-control" type="time" id="example-date-input" value=""></input>
                      </div>
                    </Row>
                    <Row className="mt-3">
                      <div className="col-lg-12">
                        <h6></h6>
                      </div>
                    </Row>
                    <Row className="mt-3">
                      <div className="col-lg-12">
                        <h6>Conclusion</h6>
                        <textarea className="form-control" placeholder="Enter Conclusion" id="exampleFormControlTextarea1" rows="3"></textarea>
                      </div>
                      <div className="col-lg-12">
                        <h6 className="mt-3">Schedule Your Next Meeting<span className="float-end">+Add Attendance</span></h6>
                      </div>
                      <div className="">
                        <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" /><img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" /><img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" /><img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" /><img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail img-leads-retail rounded-circle" />
                      </div>
                      <div className="col-lg-12 mt-3">
                        <h6 className="mt-3">Meeting Notes</h6>
                        <textarea className="form-control" placeholder="Enter notes" id="exampleFormControlTextarea1" rows="3"></textarea>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <p className="mb-0">Date</p>
                        <input className="form-control" type="date" id="example-date-input" value=""></input>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <p className="mb-0">Time</p>
                        <input className="form-control" type="time" id="example-date-input" value=""></input>
                      </div>

                    </Row>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn_success">Save changes</button>
                    <button type="button" className="btn btn_danger" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>



            {/* <Breadcrumbs
              title="UI Elements"
              breadcrumbItem="Tabs & Accordions"
            /> */}
            <Row className="mb-3">
              <Col className="col-lg-8 col-md-6 col-6">
                <div className="breadcrum ps-0">
                  <CardTitle className="h4">Service Detail</CardTitle>
                  <a href="#" className="me-2">
                    Home
                  </a>{" "}
                  <span>/</span>
                  <span className="ms-2">Service Detail</span>
                </div>
              </Col>
              <Col className="col-lg-4 co-md-6 col-6">
                <a
                  className="btn btn-primary btn-sm float-end font-14 margin-20-back"
                  href="#"
                >
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
                {/* <a href="#" className="btn btn-primary waves-effect btn-sm float-end font-14 btn-label waves-light me-3"><i className="mdi mdi-arrow-left label-icon"></i>Back</a> */}

                {/* <a href="/maps-google" className="btn btn-green waves-effect btn-sm float-end font-14 btn-label waves-light me-3"><i className="bx bx-plus label-icon"></i>ADD</a> */}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <Nav tabs className="nav-tabs-custom nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "1",
                          })}
                          onClick={() => {
                            this.toggleCustom("1")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                          <span className="d-none d-sm-block">Service</span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "2",
                          })}
                          onClick={() => {
                            this.toggleCustom("2")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                          <span className="d-none d-sm-block">Meetings</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "3",
                          })}
                          onClick={() => {
                            this.toggleCustom("3")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                          <span className="d-none d-sm-block">History</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "4",
                          })}
                          onClick={() => {
                            this.toggleCustom("4")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                          <span className="d-none d-sm-block">Status</span>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.customActiveTab} className="p-3 text-muted">

                      <TabPane tabId="1">
                        <Row className="mb-3 mt-3">
                          <Col className="col-lg-12">
                            <h6>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</h6>
                          </Col>
                          <Col className="col-lg-3">
                            <p className="mb-0">Thu, 1st April 2021</p>
                          </Col>
                        </Row>

                        <hr className="bg-bg-grey-900" />
                        <Row>
                          <Col className="col-12 mb-3">
                            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          <h5>Teams</h5>
                          <div className="col-lg-6">
                            <div className="leave-sub-box mb-3">
                              <div className="d-flex align-items-center">
                                <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail me-3 img-leads-retail rounded-circle" />
                                <div className="name-box">
                                  <div className="h6 font-16">Smith Jain</div>
                                  <p className="mb-2">+91 4589236941</p>
                                  <p className="mb-0">Alfredo.Goyette@gmail.com</p>
                                </div>

                              </div>
                              <div className="box-2 mt-2"></div>
                              <div className="mt-2 d-flex align-items-center justify-content-between">
                                <a href="" className="">Department : <span className="text-success fw-bold">Accounts</span></a>
                                <a href="" className="">Employee Code : <span className="text-orange fw-bold">08462</span></a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="leave-sub-box mb-3">
                              <div className="d-flex align-items-center">
                                <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail me-3 img-leads-retail rounded-circle" />
                                <div className="name-box">
                                  <div className="h6 font-16">Kartik Singh</div>
                                  <p className="mb-2">+91 4589236941</p>
                                  <p className="mb-0">Alfredo.Goyette@gmail.com</p>
                                </div>

                              </div>
                              <div className="box-2 mt-2"></div>
                              <div className="mt-2 d-flex align-items-center justify-content-between">
                                <a href="" className="">Department : <span className="text-success fw-bold">Manager</span></a>
                                <a href="" className="">Employee Code : <span className="text-orange fw-bold">08569</span></a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="leave-sub-box mb-3">
                              <div className="d-flex align-items-center">
                                <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail me-3 img-leads-retail rounded-circle" />
                                <div className="name-box">
                                  <div className="h6 font-16">Smith Jain</div>
                                  <p className="mb-2">+91 4589236941</p>
                                  <p className="mb-0">Alfredo.Goyette@gmail.com</p>
                                </div>

                              </div>
                              <div className="box-2 mt-2"></div>
                              <div className="mt-2 d-flex align-items-center justify-content-between">
                                <a href="" className="">Department : <span className="text-success fw-bold">Accounts</span></a>
                                <a href="" className="">Employee Code : <span className="text-orange fw-bold">08462</span></a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="leave-sub-box mb-3">
                              <div className="d-flex align-items-center">
                                <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail me-3 img-leads-retail rounded-circle" />
                                <div className="name-box">
                                  <div className="h6 font-16">Kartik Singh</div>
                                  <p className="mb-2">+91 4589236941</p>
                                  <p className="mb-0">Alfredo.Goyette@gmail.com</p>
                                </div>

                              </div>
                              <div className="box-2 mt-2"></div>
                              <div className="mt-2 d-flex align-items-center justify-content-between">
                                <a href="" className="">Department : <span className="text-success fw-bold">Manager</span></a>
                                <a href="" className="">Employee Code : <span className="text-orange fw-bold">08569</span></a>
                              </div>
                            </div>
                          </div>
                        </Row>

                      </TabPane>


                      <TabPane tabId="2">
                        <Row className="mt-3">
                          <div className="col-lg-12 col-md-12 col-12 col mb-3">
                            <a className="btn btn-green btn-sm float-end font-14" data-bs-toggle="modal" data-bs-target="#meetings"
                            ><i className="mdi mdi-plus align-middle me-1"></i>Add Meetings</a
                            >
                          </div>

                          <div className="col-lg-6">
                            <a href="#" data-bs-toggle="modal" data-bs-target="#sd">
                              <div className="leave-sub-box mb-3">
                                <h6>Voluptatem consequatur voluptatem exerctionem qui qui et expedita itaque esse.</h6>
                                <p>Thu, 1st April 20210  | 9:00 AM - 12:00 PM  |
                                  <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                </p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-6">
                            <a href="#" data-bs-toggle="modal" data-bs-target="#sd">
                              <div className="leave-sub-box mb-3">
                                <h6>Voluptatem consequatur voluptatem exerctionem qui qui et expedita itaque esse.</h6>
                                <p>Thu, 1st April 20210  | 9:00 AM - 12:00 PM  |
                                  <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                </p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-6">
                            <a href="#" data-bs-toggle="modal" data-bs-target="#sd">
                              <div className="leave-sub-box mb-3">
                                <h6>Voluptatem consequatur voluptatem exerctionem qui qui et expedita itaque esse.</h6>
                                <p>Thu, 1st April 20210  | 9:00 AM - 12:00 PM  |
                                  <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                </p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-6">
                            <a href="#" data-bs-toggle="modal" data-bs-target="#sd">
                              <div className="leave-sub-box mb-3">
                                <h6>Voluptatem consequatur voluptatem exerctionem qui qui et expedita itaque esse.</h6>
                                <p>Thu, 1st April 20210  | 9:00 AM - 12:00 PM  |
                                  <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                </p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-6">
                            <a href="#" data-bs-toggle="modal" data-bs-target="#sd">
                              <div className="leave-sub-box mb-3">
                                <h6>Voluptatem consequatur voluptatem exerctionem qui qui et expedita itaque esse.</h6>
                                <p>Thu, 1st April 20210  | 9:00 AM - 12:00 PM  |
                                  <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                </p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-6">
                            <a href="#" data-bs-toggle="modal" data-bs-target="#sd">
                              <div className="leave-sub-box mb-3">
                                <h6>Voluptatem consequatur voluptatem exerctionem qui qui et expedita itaque esse.</h6>
                                <p>Thu, 1st April 20210  | 9:00 AM - 12:00 PM  |
                                  <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                  <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle" />
                                </p>
                              </div>
                            </a>
                          </div>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col className="col-12 mb-3">
                            <div className="pb-2">
                              <div className="notes_box pb-3">
                                <p className="mb-1"> Nihil consectetur sint eos vitae voluptate suscipit dolores nihil. Enim dolores qui quam. Modi quae dolorem exercitationem neque.</p>
                                <div className="modal-sub-box">
                                  <div className="department-box">
                                    <p className="mb-0">Status: <span className="text-orange">In Process</span></p>
                                  </div>
                                  <div className="emp-code">
                                    <div className="d-flex align-items-center">
                                      <div className="">
                                        <p className="mb-0"><i className="fa fa-comments text-success"></i> <span className="">135 Replay</span></p>
                                      </div>
                                      <div className="">
                                        <a className="btn btn-primary btn-sm float-end font-10 ms-3" data-bs-toggle="modal" data-bs-target="#exampleModal1" href="#">View Details</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="notes_box pb-3">
                                <p className="mb-1"> Saepe consequatur ut earum. Voluptate et qui nulla. Qui et dolor ab occaecati commodi magni nulla harum odio. Rerum nobis corporis harum recusandae sed facere.</p>
                                <div className="modal-sub-box">
                                  <div className="department-box">
                                    <p className="mb-0">Status: <span className="text-success">Complete</span></p>
                                  </div>
                                  <div className="emp-code">
                                    <div className="d-flex align-items-center">
                                      <div className="">
                                        <p className="mb-0"><i className="fa fa-comments text-success"></i> <span className="">135 Replay</span></p>
                                      </div>
                                      <div className="">
                                        <a className="btn btn-primary btn-sm float-end font-10 ms-3" data-bs-toggle="modal" data-bs-target="#exampleModal1" href="#">View Details</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="notes_box pb-3">
                                <p className="mb-1"> Saepe consequatur ut earum. Voluptate et qui nulla. Qui et dolor ab occaecati commodi magni nulla harum odio. Rerum nobis corporis harum recusandae sed facere.</p>
                                <div className="modal-sub-box">
                                  <div className="department-box">
                                    <p className="mb-0">Status: <span className="text-success">Complete</span></p>
                                  </div>
                                  <div className="emp-code">
                                    <div className="d-flex align-items-center">
                                      <div className="">
                                        <p className="mb-0"><i className="fa fa-comments text-success"></i> <span className="">135 Replay</span></p>
                                      </div>
                                      <div className="">
                                        <a className="btn btn-primary btn-sm float-end font-10 ms-3" data-bs-toggle="modal" data-bs-target="#exampleModal1" href="#">View Details</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row className="mb-3 mt-3">
                          <Col className="col-1 text-center" >
                            <input
                              type="checkbox"
                              className="form-check-input input-mini "
                              id="closeButton"
                              value="checked"
                            />
                          </Col>
                          <Col className="col-11">
                            <a className="table-link-a" data-bs-toggle="modal" data-bs-target="#exampleModal">
                              Perspiciatis dolores et aperiam et et. Ut animi mollitia. Ratione adipisci est. Unde sit aut magnam nostrum repellendus et ut omnis ea. Vitae a et non quis voluptatum reprehenderit at consequuntur. Corrupti eum distinctio dolores velit voluptatibus adipisci id fuga.
                            </a>
                          </Col>
                        </Row>


                        <Row className="mb-3">
                          <Col className="col-1 text-center">
                            <input
                              type="checkbox"
                              className="form-check-input input-mini "
                              id="closeButton"
                              value="checked"
                            />
                          </Col>
                          <Col className="col-11">
                            <a className="table-link-a" data-bs-toggle="modal" data-bs-target="#exampleModal">
                              Nihil soluta ipsa in aliquam quis iusto sit. Ducimus perferendis suscipit voluptatem. Sit voluptas alias laborum dolores.
                            </a>
                          </Col>
                        </Row>



                        <Row className="mb-3">
                          <Col className="col-1 text-center">
                            <input
                              type="checkbox"
                              className="form-check-input input-mini "
                              id="closeButton"
                              value="checked"
                            />
                          </Col>
                          <Col className="col-11">
                            <a className="table-link-a" data-bs-toggle="modal" data-bs-target="#exampleModal">
                              Voluptatum vel qui doloremque provident sit officiis. Repudiandae dicta doloremque ducimus. Ea pariatur pariatur deleniti molestias id et natus. Hic est ipsa qui ipsa similique et expedita ut. Inventore impedit consequatur pariatur aliquid.
                            </a>
                          </Col>
                        </Row>


                        <Row className="mb-3" >
                          <Col className="col-1 text-center">
                            <input
                              type="checkbox"
                              className="form-check-input input-mini "
                              id="closeButton"
                              value="checked"
                            />
                          </Col>
                          <Col className="col-11">
                            <a className="table-link-a" data-bs-toggle="modal" data-bs-target="#exampleModal">Est eum enim. Et veritatis ea. Eos qui nam in aperiam quia accusantium. Illo est ad quaerat.</a>
                          </Col>
                        </Row>
                      </TabPane>

                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default UiTabsAccordions
