import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';

const EditGST = () => {

  const [CityId, setCityId] = useState('');
  const [GSTPercentage, setGSTPercentage] = useState('');
  const history = useHistory();
  let { id } = useParams();

  useEffect(() => {
    if (id != null) {
      if (id != "0") {
        let newid = id.split(':');
        console.log("newid",newid);  
        getGSTById(newid[1]);  
      }
      else {
        history.push("/GST");
      }
    }
    else {
      history.push("/GST");
    }
  }, []);

  const GSTUpdate = async (e) => {
    e.preventDefault();
    let GSTPercentage = e.target["txtPercentage"].value;
    let newid = id.split(':');

    if ( GSTPercentage!= '') {
      const GSTArr = {
        GId: newid[1],
        Percentage: GSTPercentage,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      
        }

      axios.post(global.APIURL + '/GSTUpdate', GSTArr)
        .then(res => {
          console.log("res",res);
          alert(res.data[0].RecordStatus);
          history.push("/GST");
        });
    }
  }

  const getGSTById = async (GId) => {

    const GSTArr={
        Id:GId
    }

    axios.post(global.APIURL + '/GstSelectById', GSTArr)
        .then(res => {
            console.log("res565",res);
            setGSTPercentage(res.data[0].percentage);
        
        });
   
  }


  return (
    <div className="page-content">

      <MetaTags>
        <title>Edit City | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Edit GST</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <span className="ms-2">Edit GST</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-6">
            <a className="btn btn-primary btn-sm float-end font-14" href="/GST">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <form onSubmit={GSTUpdate}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">GST Percentage</label>
                        <Input type="text" className="" id="txtPercentage" defaultValue={GSTPercentage} placeholder="Enter GST Percentage" />
                      </div>
                    </Col>
                    <Col lg={12}>
                     
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/GST">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  )
}

export default EditGST;