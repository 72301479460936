import React,{ useState } from "react"

import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import {Row,Col,Card,CardBody,CardTitle,Label,FormGroup,InputGroup} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
 
 // var DInTime=this.DInTime.value; 

const AddShiftMaster = () => { 
    const history = useHistory();
    const saveEmployeeType = async (e) => {
        e.preventDefault();       
        let DInTime=event.target["txtDInTime"].value; 
        if(DInTime!='')
        {
            const ShiftData = {
                SId:'0',
                INTime: DInTime,
                OUTTime: DOutTime,
                Flag:"A"       
            }

            axios.post(global.APIURL+'/Shift', ShiftData)
            .then(res => 
            {
                    alert(res.data);
                    history.push("/ShiftMaster");
            });
        }
    }
    return (
      
      <div className="page-content">
  
        <MetaTags>
          <title>Shift | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Add Shift</CardTitle>
                 <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/ShiftMaster" className="me-2 ms-2">Shift</a>
                <span>/</span>
                <span className="ms-2">Add Shift</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/ShiftMaster">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={saveEmployeeType}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                       
                        {/* <Input type="text" className="form-control" id="txtSalaryStartRange" placeholder="Enter Start Range"/> */
}
<FormGroup className="mb-3">
                        <Label>In Time</Label>

                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Select time"
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "H:i",
                              id:"txtDInTime"
                              // value:{Indate},
                              // onchange:function() {
                              //   picker.setInDate[0].input.value 
                            //}
                        }}    
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-clock-outline" />

                            </span>
                          </div>
                        </InputGroup>
                      </FormGroup>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                      
                        {/* <Input type="text" className="form-control" id="txtSalaryEndRange" placeholder="Enter End Range"/> */}
                        <FormGroup className="mb-3">
                        <Label>Out Time</Label>

                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Select time"
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              // value:{DInTime},
                              dateFormat: "H:i"
                            }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-clock-outline" />
                            </span>
                          </div>
                        </InputGroup>
                      </FormGroup>
                      </div>

                    </Col>
                   
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/AddShiftMater">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
       
      </div>
    )
}

export default AddShiftMaster;