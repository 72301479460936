import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row, Label } from "reactstrap";
import { Link } from "react-router-dom";
import axios from 'axios'



import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';


// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/favicon.png";
import lightlogo from "../../assets/images/logo-light.svg";

import { concat } from "lodash";



const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// async function loginUser(credentials) {
//   return fetch(global.apiurl+'/WebLogin', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(credentials)
//   })
//     .then(data => data.json())
// }

const Login = () => {

  const classes = useStyles();

  // const [username, setUserName] = useState();
  // const [password, setPassword] = useState();



  const handleSubmit = async e => {
    e.preventDefault();


    const KCWebLogin = {
      Email: event.target["Email"].value,
      Password:event.target["Password"].value       
    }

    axios.post(global.APIURL+'/WebLogin/',KCWebLogin) 
    .then(res => {  
        if(res.data[0].UserId!=null)
        {
          if(res.data[0].UserId!="0")
          {
            // alert(res.data[0].Name);
            localStorage.setItem('user', JSON.stringify(res.data[0]));
            localStorage.setItem('authUser', JSON.stringify(res.data[0].UserId));
            localStorage.setItem('LoginUserId', res.data[0].UserId);
            localStorage.setItem('LoginUserTypeId', res.data[0].UserTypeId);
            localStorage.setItem('LoginDashboardSettingId', res.data[0].DashboardSettingId);
            localStorage.setItem('LoginUserName', res.data[0].Name);
             localStorage.setItem('LoginUserType', res.data[0].UserType);
           
            window.location.href = "/dashboard";
          }
          else
          {
            alert(res.data[0].LoginStatus);
          }
        }
        else
        {
            alert(res.data[0].LoginStatus);
        }
   })
    

    // const response = await loginUser({
    //   username,
    //   password
    // });

    
    // if ('accessToken' in response) {
    //   swal("Success", response.message, "success", {
    //     buttons: false,
    //     timer: 2000,
    //   })
    //     .then((value) => {
    //       localStorage.setItem('accessToken', response['accessToken']);
    //       localStorage.setItem('user', JSON.stringify(response['user']));
    //       window.location.href = "/";
    //     });
    // } else {
    //    
    // }
  }









  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to KC Group.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={lightlogo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className=""
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>



                  <div className="p-2">
                    {/* Form Implementation */}

                    <form className={classes.form} noValidate onSubmit={handleSubmit}>
                      <TextField variant="outlined" margin="normal" required fullWidth id="Email" name="Email" label="Email Address" />
                      <TextField variant="outlined" margin="normal" required fullWidth id="Password" name="Password" label="Password" type="password" />
                      <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Sign In</Button>
                    </form>


                  </div>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}





export default Login

