import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import img2 from "assets/images/favicon.png";
import axios from 'axios'

import ClientAccountFirmDetail from "../LeadList/ClientAccountFirmDetail";
import LeadFirmCreditDetail from "../LeadList/LeadFirmCreditDetail";
//Import Breadcrumb
import { Col, Row, CardTitle, Card, CardBody, Input, Label, Form, Button } from "reactstrap";

// Rating Plugin

const UiRating = () => {
  const [LeadCode, setLeadCode] = useState('');
  useEffect(() => {
    let LeadId = localStorage.getItem("FirmCompanyLeadId");
    if (LeadId != null) {
      if (LeadId != "0") {
        getLeadDetailById(LeadId);
      }
      else {
        history.push("/Retail/LeadList/AllInq");
      }
    }
    else {
      history.push("/Retail/LeadList/AllInq");
    }
  }, []);

  const getLeadDetailById = async (LId) => {
    var AUrl = global.APIURL + '/WebLeadEdit/' + LId;
    axios.get(AUrl)
      .then(res => {
        setLeadCode(res.data[0].LeadCode)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Client Account | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">

          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-12 mb-2">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Lead Detail</CardTitle>
                <a href="/" className="me-2">
                  Home
                </a>{" "}
                <span>/</span>
                <a href="/LeadList" className="me-2 ms-2">
                  Manage Lead
                </a>{" "}
                <span>/</span>
                <span className="ms-2">Lead Detail</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a
                className="btn btn-primary btn-sm float-end font-14"
                href="/LeadList"
              >
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>

            </Col>
          </Row>
          <Card>
            <CardBody>
              <Row className="">
                <Col className="col-lg-4">
                  <ul
                    className="nav border-0 nav-tabs"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link ps-0 border-0 active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        {" "}
                        <div className="form-check">
                          <input
                            type="radio"
                            id="1"
                            name="radio1"
                            className="form-check-input"
                            value="success"
                            defaultChecked
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="radio2"
                          >
                            Client Account Detail
                          </Label>
                        </div>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link border-0"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        {" "}
                        <div className="form-check">
                          <input
                            type="radio"
                            id="2"
                            name="radio1"
                            className="form-check-input"
                            value="success"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="radio2"
                          >
                            Client Credit Detail
                          </Label>
                        </div>
                      </button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <ClientAccountFirmDetail />
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab" >
              <div className="company-card" id="company-card">
                <LeadFirmCreditDetail />
              </div>
            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  );
}

export default UiRating;
