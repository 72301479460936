import React, { Fragment, useState, useEffect, useRef } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import kcreceiptlogo from "assets/images/kcreceiptlogo.png";
import rsimage from "assets/images/rsimage.png";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import html2canvas from "html2canvas";



const OnSort = () => {
  let columns = [
    {
      d: "#",
      text: "#",
      cell: (record, index) => {
        let RowIndex = index + 1;
        return (
          <Fragment>
            {RowIndex}
          </Fragment>
        );
      }
    },
    {
      d: "FullName",
      text: "Full Name",
      cell: (record, index) => {
        let FullName = record["FullName"];
        let LeadCode = record["LeadCode"];
        let Tag = record["Tag"];
        let CreateDate = record["CreateDate"];
        let LeadType = record["LeadType"];
        let VLeadProfileImage = record["LeadProfileImage"];
        return (

          <a onClick={RedirectLeadDetail.bind(this, record)} className="text-heading nowrap">
            <div className="d-flex">
              <div className="flex-shrink-0">
                <img
                  src={VLeadProfileImage}
                  alt=""
                  className="img-thumbnail img-leads rounded-circle"
                />
              </div>
              <div className="flex-grow-1 ms-1">
                <p className="mb-1">{FullName}</p>
                <p className="text-muted mb-0">{LeadCode}</p>
                <span className="badge badge-soft-primary px-2 py-1">{Tag}</span>
                <p className="text-muted mb-0">{CreateDate}</p>
                <p className="text-muted mb-0">{LeadType}</p>
              </div>
            </div>
          </a>

        );
      }
    },

    {
      d: "Email & Contact Number",
      text: "Email & Contact Number ",
      cell: (record, index) => {
        let Email = record["EmailAddress"];
        let ContactNumber = record["MobileNo"];
        return (
          <Fragment>
            <p className="mb-1 text-wrap1">{Email}</p>
            <p className="mb-1 text-wrap1">{ContactNumber}</p>
          </Fragment>
        );
      }
    },

    {
      d: "Package Amount",
      text: "Package Amount ",
      cell: (record, index) => {
        let PackAmt = record["PackageAmount"];

        return (
          <Fragment>
            {PackAmt}
          </Fragment>
        );
      }

    },
    {
      d: "Total Amount",
      text: "Total Amount ",
      cell: (record, index) => {
        let TotalAmount = record["TotalAmount"];
        return (
          <Fragment>
            {TotalAmount}
          </Fragment>
        );
      }
    },
    {
      d: "Total Received Amount",
      text: "Total Received Amount ",
      cell: (record, index) => {
        let TotalRecivedAmount = record["TotalRecivedAmount"];
        return (
          <Fragment>
            {TotalRecivedAmount}
          </Fragment>
        );
      }
    },
    {
      d: "Total Pending Amount",
      text: "Total Pending Amount ",
      cell: (record, index) => {
        let TotalPendingAmount = record["TotalPendingAmount"];
        return (
          <Fragment>
            {TotalPendingAmount}
          </Fragment>
        );
      }
    },

    {
      d: "Pay",
      text: "Action",
      cell: (record, index) => {


        return (
          <Fragment>
            <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModalPay" style={{ marginRight: "16px" }} className="btn btn-sm btn-primary" onClick={RedirectLeadDetail.bind(this, record)}>Pay</button>
            <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModalViewPay" className="btn btn-sm btn-primary" onClick={getServicePackagePayment.bind(this, record)}>View Pay</button>

          </Fragment>
        );
      }
    }
  ];

  const RedirectLeadDetail = (record) => {
    let id = record["LeadId"]
    let LeadType = record["LeadType"]
    let ServicePkgId = record["ServicePackageId"];

    var AccruedTotalAmount = 0;
    var TotalGSTAmount = 0;
    var TotalAccruedAmount = 0;
    var TotalAccruedDue = 0;
    var TotalPaidAmount = 0;
    var TotalAmountToPay = 0;
    var FinalAmountToPay = 0;
    var TotalGSTAmountToPay = 0;

    var ServicePkg = 0;


    if (LeadType == "Individual") {

      localStorage.setItem('ConvertIndividualLeadId', id);
      localStorage.setItem('ConvertFirmLeadId', '0');
    }
    document.getElementById("txtLeadId").value = id;
    document.getElementById("txtServicePackageId").value = ServicePkgId;

    const SerPackRegArr = {
      LeadId: id
    };
    var AUrl = global.APIURL + '/LeadServicePackageRegister';

    axios.post(AUrl, SerPackRegArr).then(SPres => {
      let SerPackRegActList = SPres.data.map((ServicePackageAct) => {
        return ServicePackageAct
      })
      setServiceWiseActivityList(SerPackRegActList);


      for (let sa = 0; sa < SerPackRegActList.length; sa++) {


        ServicePkg = SerPackRegActList[sa].ServicePackage;
        AccruedTotalAmount = AccruedTotalAmount + parseFloat(SerPackRegActList[sa].PackageAmount);
        TotalGSTAmount = TotalGSTAmount + parseFloat(SerPackRegActList[sa].PackageGSTAmount);
        TotalAccruedAmount = TotalAccruedAmount + parseFloat(SerPackRegActList[sa].TotalAccruedAmount);
        TotalAccruedDue = TotalAccruedDue + parseFloat(SerPackRegActList[sa].AccruedDue);
        TotalPaidAmount = TotalPaidAmount + parseFloat(SerPackRegActList[sa].PaidAmount);
        TotalAmountToPay = TotalAmountToPay + parseFloat(SerPackRegActList[sa].AmounttoPay);

        var GSTPer = parseFloat(SerPackRegActList[sa].GSTPer == null ? '0' : SerPackRegActList[sa].GSTPer == '' ? '0' : SerPackRegActList[sa].GSTPer);
        TotalGSTAmountToPay = (TotalAmountToPay * GSTPer) / 100;
        FinalAmountToPay = TotalAmountToPay + TotalGSTAmountToPay;
        document.getElementById("txtSerPack").value = ServicePkg;
        document.getElementById("txtPackageAmount").value = AccruedTotalAmount;
        document.getElementById("txtPackageGSTAmount").value = TotalGSTAmount;
        document.getElementById("txtTotalAccruedAmount").value = TotalAccruedAmount;
        document.getElementById("txtAccruedDue").value = TotalAccruedDue;
        document.getElementById("txtDueAmt").value = '0';
        document.getElementById("txtPaidAmount").value = TotalPaidAmount;
        document.getElementById("txtAmounttoPay").value = TotalAmountToPay;
        document.getElementById("txtGSTPer").value = GSTPer;
        document.getElementById("txtGSTAmt").value = TotalGSTAmountToPay;
        document.getElementById("txtTotalAmtToPay").value = FinalAmountToPay;

      }
    })


  }



  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
    button: {
      excel: true,
      print: true,
      csv: true
    }
  }
  const [records, setRecords] = useState([]);
  const [ServiceWiseActivityList, setServiceWiseActivityList] = useState([]);
  const [CompanyId, setCompanyId] = useState(0);
  const [PaymentModeList, setPaymentModeList] = useState([]);
  const [PaymentModeId, setPaymentModeId] = useState(0);
  const [PaymentModeTitle, setPaymentModeTitle] = useState('');
  const [PaymentModeType, setPaymentModeType] = useState(0);
  const [CompanyList, setCompanyList] = useState([]);
  const [BankName, setBankName] = useState('');
  const [Branch, setBranch] = useState('');
  const [ChequeDDNo, setChequeDDNo] = useState('');
  const [ChequeDDDate, setChequeDDDate] = useState('');
  const [TransactionDate, setTransactionDate] = useState('');
  const [GSTAmount, setGSTAmount] = useState("");
  const [TotalAmount, setTotalAmount] = useState([]);
  const [ServicePackagePayment, setServicePackagePayment] = useState([]);

  const [UTR, setUTR] = useState('');
  const [Settlement, setSettlement] = useState('');

  const [ShowBankName, setShowBankName] = useState(0);
  const [ShowBranch, setShowBranch] = useState(0);
  const [ShowChequeDDDate, setShowChequeDDDate] = useState(0);
  const [ShowUTR, setShowUTR] = useState(0);
  const [ShowSettlement, setShowSettlement] = useState(0);
  const [ShowTransactionNumber, setShowTransactionNumber] = useState(0);

  const [ChannelTypeId, setChannelTypeId] = useState(0);
  const [ChannelMasterList, setChannelMasterList] = useState([]);
  const [ChannelTypeList, setChannelTypeList] = useState([]);
  const [ChannelMasterId, setChannelMasterId] = useState(0);
  const [ChannelPartnerList, setChannelPartnerList] = useState([]);
  const [ChannelPartnerId, setChannelPartnerId] = useState(0);

  const [ReceiptNumber, setReceiptNumber] = useState(0);
  const [ReceiptDate, setReceiptDate] = useState('');
  const [FullName, setFullName] = useState('');
  const [ReceiptTotalAmount, setReceiptTotalAmount] = useState(0);
  const [ReceiptTotalAmountInWord, setReceiptTotalAmountInWord] = useState('');
  const [PaymentMode, setPaymentMode] = useState('');
  const [ReceiptChequeDDNo, setReceiptChequeDDNo] = useState('');

  const a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
  const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  let history = useHistory();

  const inputRef = useRef(null);

  const getPaymentModeList = async () => {
    var AUrl = global.APIURL + '/PaymentModeActive';
    axios.get(AUrl).then(Pres => {
      let PaymentModeL = Pres.data.map((PaymentMode) => {
        return PaymentMode
      })
      setPaymentModeList(PaymentModeL);
    })
  }

  const PaymentModeOnChange = e => {
    var VPaymentMode = e.nativeEvent.target.selectedIndex;
    var PaymentModeId = e.nativeEvent.target[VPaymentMode].value;
    var PaymentModeText = e.nativeEvent.target[VPaymentMode].text;
    setPaymentModeId(PaymentModeId);
    setPaymentModeTitle(PaymentModeText);
    if (PaymentModeText == 'Cash') {
      setShowTransactionNumber(0);
      setShowBankName(0);
      setShowBranch(0);
      setShowChequeDDDate(0);
      setShowUTR(0);
      setShowSettlement(0);
    }
    else if (PaymentModeText == 'Cheque') {
      setShowTransactionNumber(1);
      setShowBankName(1);
      setShowBranch(1);
      setShowChequeDDDate(1);
      setShowUTR(0);
      setShowSettlement(0);
    }
    else if (PaymentModeText == 'Credit Card / Debit Card') {
      setShowTransactionNumber(1);
      setShowBankName(1);
      setShowBranch(1);
      setShowChequeDDDate(1);
      setShowUTR(0);
      setShowSettlement(0);
    }
    else if (PaymentModeText == 'NEFT') {
      setShowTransactionNumber(1);
      setShowBankName(1);
      setShowBranch(1);
      setShowChequeDDDate(1);
      setShowUTR(0);
      setShowSettlement(0);
    }
    else if (PaymentModeText == 'Paytm') {
      setShowTransactionNumber(1);
      setShowBankName(0);
      setShowBranch(0);
      setShowChequeDDDate(0);
      setShowUTR(1);
      setShowSettlement(1);
    }
    else if (PaymentModeText == 'E-Wallets except Paytm') {
      setShowTransactionNumber(1);
      setShowBankName(0);
      setShowBranch(0);
      setShowChequeDDDate(0);
      setShowUTR(1);
      setShowSettlement(1);
    }
    else if (PaymentModeText == 'Free') {
      setShowTransactionNumber(1);
      setShowBankName(0);
      setShowBranch(0);
      setShowChequeDDDate(0);
      setShowUTR(0);
      setShowSettlement(0);
    }
    else if (PaymentModeText == 'CASH & CARD') {
      setShowTransactionNumber(1);
      setShowBankName(1);
      setShowBranch(1);
      setShowChequeDDDate(1);
      setShowUTR(0);
      setShowSettlement(0);
    }

    else if (PaymentModeText == 'Cash & Cheque') {
      setShowTransactionNumber(1);
      setShowBankName(1);
      setShowBranch(1);
      setShowChequeDDDate(1);
      setShowUTR(0);
      setShowSettlement(0);
    }
    else if (PaymentModeText == 'Phone pay') {
      setShowTransactionNumber(1);
      setShowBankName(0);
      setShowBranch(0);
      setShowChequeDDDate(0);
      setShowUTR(1);
      setShowSettlement(1);
    }
    else {
      setShowTransactionNumber(0);
      setShowBankName(0);
      setShowBranch(0);
      setShowChequeDDDate(0);
      setShowUTR(0);
      setShowSettlement(0);
    }
  };

  const getCompanyList = async () => {
    var AUrl = global.APIURL + '/CompanyActive';
    axios.get(AUrl).then(SPres => {
      let CompanyL = SPres.data.map((Company) => {
        return Company
      })
      setCompanyList(CompanyL);
    })
  }

  const getServicePackagePayment = async (record) => {
    let VLeadId = record["LeadId"];
    const CSerPackArr = {
      LeadId: VLeadId
    };

    var AUrl = global.APIURL + '/ServicePackagePaymentList';
    axios.post(AUrl, CSerPackArr)
      .then(CSPres => {
        let ServicePackagePaymentList = CSPres.data.map(ServicePackagePayment => {
          return ServicePackagePayment;
        });
        setServicePackagePayment(ServicePackagePaymentList);
      });

  };

  useEffect(() => {
    const TableFilter = document.getElementsByClassName('table_filter');
    for (const tf of TableFilter) {
      tf.style.display = 'none';
    }
    getPaymentDueList();
    getPaymentModeList();
    getCompanyList();
    getChannelTypeList();

  }, []);

  const getPaymentDueList = async () => {
    var QSearch = document.getElementById("txtQuickSearch").value;
    var LeadType = document.getElementById("txtLeadType").value;
    var StartDate = document.getElementById("txtStartDate").value;
    var EndDate = document.getElementById("txtEndDate").value;
    var LeadEnroll = document.getElementById("txtLeadEnroll").value;
    var RecordStatus = document.getElementById("txtRecord").value;

    const LeadData = {
      QuickSearch: QSearch,
      LeadType: LeadType,
      FromDate: StartDate,
      ToDate: EndDate,
      LeadStatus: '',
      LeadEnrollStatus: LeadEnroll,
      RecordStatus: RecordStatus,
      SourceId: ChannelMasterId,
      SubSourceId: ChannelPartnerId
    }


    axios.post(global.APIURL + "/WebPaymentDueLeadList", LeadData)
      .then(CLres => {
        let VWebLeadList = CLres.data.map(vcl => {
          return vcl;
        });
        setRecords(VWebLeadList);
      });


  };

  const saveLead = async (e) => {
    getPaymentDueList();
  }

  const AmounttoPayOnChange = async () => {
    var SAmountToPay = parseFloat(document.getElementById("txtAmounttoPay").value == null ? '0' : document.getElementById("txtAmounttoPay").value == '' ? '0' : document.getElementById("txtAmounttoPay").value);
    var SGSRPer = parseFloat(document.getElementById("txtGSTPer").value == null ? '0' : document.getElementById("txtGSTPer").value == '' ? '0' : document.getElementById("txtGSTPer").value);
    var TotalAccruedAmount = parseFloat(document.getElementById("txtAccruedDue").value == null ? '0' : document.getElementById("txtAccruedDue").value == '' ? '0' : document.getElementById("txtAccruedDue").value);

    var VGSTAmount = (SAmountToPay * SGSRPer) / 100;
    var SFinalAmountToPay = SAmountToPay + VGSTAmount;
    var VTotalDueAmt = TotalAccruedAmount - SAmountToPay;

    document.getElementById("txtDueAmt").value = VTotalDueAmt;
    document.getElementById("txtGSTAmt").value = VGSTAmount;
    document.getElementById("txtTotalAmtToPay").value = SFinalAmountToPay;
    setTotalAmount(SFinalAmountToPay);
    setGSTAmount(VGSTAmount);
  };

  const FinalPaymentSubmit = () => {

    var VServicePkgId = document.getElementById("txtServicePackageId").value;
    var VLeadId = document.getElementById("txtLeadId").value;
    var VRecivedAmount = document.getElementById("txtAmounttoPay").value;
    var VGSTAmount = document.getElementById("txtGSTAmt").value;
    var VTotalAmtToPay = document.getElementById("txtTotalAmtToPay").value;

    if (VRecivedAmount != null) {
      if (VRecivedAmount != "") {
        let IsInsert = 1;
        if (CompanyId == 0) {
          IsInsert = 0;
          alert("Please select company");
          return;
        }
        if (PaymentModeId == 0) {
          IsInsert = 0;
          alert("Please select payment mode");
          return;
        }
        if (TransactionDate == '') {
          IsInsert = 0;
          alert("Please select transaction date");
          return;
        }
        if (PaymentModeId != 1) {
          if (ChequeDDNo == '') {
            IsInsert = 0;
            alert("Please input transaction number");
            return;
          }
        }

        if (PaymentModeId == 5) {
          if (UTR == '') {
            IsInsert = 0;
            alert("Please input UTR");
            return;
          }
        }
        if (PaymentModeId == 12) {
          if (UTR == '') {
            IsInsert = 0;
            alert("Please input UTR");
            return;
          }
        }

        if (PaymentModeId == 5) {
          if (Settlement == '') {
            IsInsert = 0;
            alert("Please input Settlement");
            return;
          }
        }
        if (PaymentModeId == 12) {
          if (Settlement == '') {
            IsInsert = 0;
            alert("Please input Settlement");
            return;
          }
        }

        if (IsInsert == 1) {
          const PaymentData = {
            ServicePackageId: VServicePkgId,
            LeadId: VLeadId,
            RecivedAmount: VRecivedAmount,
            RecivedGSTAmount: VGSTAmount,
            TotalAmount: VTotalAmtToPay,
            CompanyId: CompanyId,
            PaymentModeId: PaymentModeId,
            PaymentMode: PaymentModeTitle,
            BankName: BankName,
            Branch: Branch,
            ChequeDDNo: ChequeDDNo,
            ChequeDDDate: ChequeDDDate == "" ? TransactionDate : ChequeDDDate,
            TransactionDate: TransactionDate,
            CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
          }

          axios.post(global.APIURL + "/ServicePackageDuePayment", PaymentData)
            .then(res => {
              alert(res.data[0].RecordStatus);
              getPaymentDueList();
            });
        }
      }
      else {
        alert("Please Enter Amount To Pay.");
      }
    }
    else {
      alert("Please Enter Amount To Pay.");
    }
  }

  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }

  const exportPDF = () => {

    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Lead Payment Due List Report";
    const headers = [["#", "FullName", "Lead Code", "Created Date", "Lead Type", "Email", "ContactNumber", "Package Amount", "Total Amount", "Total Received Amount", "Total Pending Amount"]];

    const data = records.map((item, index) => [index + 1, item.FullName, item.LeadCode, item.CreateDate, item.LeadType, item.EmailAddress, item.MobileNo, item.PackageAmount, item.TotalAmount, item.TotalRecivedAmount, item.TotalPendingAmount]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("LeadPaymentDueListReport.pdf")
  }

  const getChannelMasterList = async (CTId) => {
    var AUrl = global.APIURL + '/ChannelMasterActiveList/' + CTId;
    fetch(AUrl)
      .then((res) => res.json())
      .then((json) => {
        setChannelMasterList(json)
      })
  }


  const getChannelTypeList = async () => {
    fetch(global.APIURL + "/ChannalTypeList")
      .then((res) => res.json())
      .then((json) => {
        setChannelTypeList(json)
      })
  }


  const getModelLeadChannelPartnerList = async CHId => {
    const LeadChannelPartnerFilter = {
      ChannelId: CHId,
      FilterSearchByName: "",
    };
    axios.post(global.APIURL + "/ActiveChannelPartner", LeadChannelPartnerFilter)
      .then(CPLres => {
        let VChannelPartnerList = CPLres.data.map(vcpl => {
          return vcpl;
        });
        setChannelPartnerList(VChannelPartnerList);
      });
  };

  const ServicePackagePaymentDetail = (item) => {
    console.log(item.LeadPaymentId);
    const ItemFilter = {
      Id: item.LeadPaymentId
    };
    axios.post(global.APIURL + "/LeadServicePackagePaymentDetail", ItemFilter)
      .then(CPLres => {
        let VServicePackagePaymentDetail = CPLres.data.map(vcpl => {
          return vcpl;
        });
        if (VServicePackagePaymentDetail.length > 0) {
          var VTotalAmount = VServicePackagePaymentDetail[0].TotalAmount == null ? '' : VServicePackagePaymentDetail[0].TotalAmount
          var VTotalAmountSplit = VTotalAmount.toString().split('.');
          var VAmountinWords = inWords(VTotalAmountSplit[0] == null ? '0' : VTotalAmountSplit[0] == '' ? '0' : VTotalAmountSplit[0])
          var VPointAmount = VTotalAmountSplit[1] == null ? '0' : VTotalAmountSplit[1] == '' ? '0' : VTotalAmountSplit[1].length == 1 ? VTotalAmountSplit[1] + '0' : VTotalAmountSplit[1];
          var VPointAmountinWords = VTotalAmountSplit.length > 1 ? inWords(VPointAmount) : '';

          var VFinalAmountinWords = '';

          if (VPointAmountinWords != '') {
            VFinalAmountinWords = VAmountinWords + ' rupees and ' + VPointAmountinWords + ' paisa';
          }
          else {
            VFinalAmountinWords = VAmountinWords + ' rupees';
          }

          setReceiptTotalAmountInWord(VFinalAmountinWords);
          setReceiptNumber(VServicePackagePaymentDetail[0].ReceiptNumber == null ? 0 : VServicePackagePaymentDetail[0].ReceiptNumber == '' ? 0 : VServicePackagePaymentDetail[0].ReceiptNumber);
          setReceiptDate(VServicePackagePaymentDetail[0].TransactionDate == null ? '' : VServicePackagePaymentDetail[0].TransactionDate);
          setFullName(VServicePackagePaymentDetail[0].FullName == null ? '' : VServicePackagePaymentDetail[0].FullName);
          setReceiptTotalAmount(VServicePackagePaymentDetail[0].TotalAmount == null ? '' : VServicePackagePaymentDetail[0].TotalAmount);
          setPaymentMode(VServicePackagePaymentDetail[0].PaymentMode == null ? '' : VServicePackagePaymentDetail[0].PaymentMode);
          setReceiptChequeDDNo(VServicePackagePaymentDetail[0].ChequeDDNo == null ? '' : VServicePackagePaymentDetail[0].ChequeDDNo);
        }
        else {
          setReceiptNumber(0);
          setReceiptDate('');
          setFullName('');
          setReceiptTotalAmount('');
          setPaymentMode('');
          setReceiptChequeDDNo('');
        }
      });

    $('#receiptModal').modal('show');
  }



  function inWords(num) {
    if ((num = num.toString()).length > 9) return 'overflow';
    var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? ' ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + ' ' : '';
    return str;
  }


  function printDocument() {

    // htmlToImage.toPng(document.getElementById('divprintreceiptmindiv'), { quality: 0.95 })
    // .then(function (dataUrl) {
    //   var link = document.createElement('a');
    //   link.download = 'my-image-name.jpeg';
    //   const pdf = new jsPDF();
    //   const imgProps= pdf.getImageProperties(dataUrl);
    //   const pdfWidth = pdf.internal.pageSize.getWidth();
    //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //   pdf.addImage(dataUrl, 'PNG', 0, 0,pdfWidth, pdfHeight);
    //   pdf.save("download.pdf"); 
    // });

    html2canvas(document.getElementById('divprintreceiptmindiv'), { quality: 0.95 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("l", "mm", "a5");
      pdf.addImage(imgData, "JPEG", 0, 0, 210, 150);
      var Pdfname = ReceiptNumber + 'PaymentReceipt.pdf';
      pdf.save(Pdfname);
    });
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Payment | KC Admin Group</title>
      </MetaTags>
      <div className="modal fade" id="exampleModalPay" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Payment Details </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form >
              <div className="modal-body">
                <Row>
                  <Col className="col-12">
                    <div>
                      <div>
                        <Row>
                          <Col lg={4}>
                            <div className="mb-2">
                              <label className="form-label">Service Package</label>
                              <input type="text" className="form-control" id="txtSerPack" placeholder="" readOnly />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-2">
                              <label className="form-label">Package Amount</label>
                              <input type="text" className="form-control" id="txtPackageAmount" placeholder="" readOnly />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-2">
                              <label className="form-label">Package GST Amount</label>
                              <input type="text" className="form-control" id="txtPackageGSTAmount" placeholder="" readOnly />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-2">
                              <label className="form-label">Total Accrued Amount </label>
                              <input type="text" className="form-control" id="txtTotalAccruedAmount" placeholder="" readOnly />
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div className="mb-2">
                              <label className="form-label">Accrued Due</label>
                              <input type="text" className="form-control" id="txtAccruedDue" placeholder="" readOnly />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-2">
                              <label className="form-label">Paid Amount </label>
                              <input type="text" className="form-control" id="txtPaidAmount" placeholder="" readOnly />
                            </div>
                          </Col>
                          <Col lg={12} className="mb-3 mt-3">
                            <hr />
                          </Col>
                          <Col lg={4}>
                            <div className="mb-2">
                              <label className="form-label">Amount to Pay </label>
                              <input type="text" id="txtAmounttoPay" onChange={AmounttoPayOnChange} className="form-control" />
                            </div>
                          </Col>
                          <Col lg={12} className="mb-3 mt-3">
                            <hr />
                          </Col>
                          <Col lg={4}>
                            <div className="mb-2">
                              <label className="form-label">Total Due Amount</label>
                              <input type="text" className="form-control" id="txtDueAmt" placeholder="" readOnly />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-2">
                              <label className="form-label">Total GST Amount</label>
                              <input type="text" className="form-control" id="txtGSTAmt" placeholder="" readOnly />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-2">
                              <label className="form-label">Total Amount To Pay </label>
                              <input type="text" className="form-control" id="txtTotalAmtToPay" placeholder="" readOnly />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <p className="mb-2 fontWeight500"> Company </p>
                            <select id="ddlCompany" className="form-select" aria-label="Default select example" onChange={e => setCompanyId(e.target.value)}>
                              <option key="0" value="0">Select Company</option>
                              {CompanyList.map((item) => (
                                <option key={item.CompanyId} value={item.CompanyId}>{item.CompanyName}</option>
                              ))}
                            </select>
                          </Col>
                          <Col className="col-lg-4 col-md-4 col-12">
                            <p className="mb-2 fontWeight500"> Payment Mode </p>
                            <select id="ddlPaymentMode" className="form-select" aria-label="Default select example" onChange={PaymentModeOnChange}>
                              <option key="0" value="0">Select Payment Mode</option>
                              {PaymentModeList.map((item) => (
                                <option key={item.PaymentModeId} value={item.PaymentModeId}>{item.PaymenMode}</option>
                              ))}
                            </select>
                          </Col>
                          {ShowTransactionNumber > 0 ? <Col className="col-lg-4 col-md-4 col-12">
                            <p className="mb-2 fontWeight500"> Transaction Number</p>
                            <Input type="text" className="" id="txtChequeDDNo" placeholder="Enter Transaction No." onChange={e => setChequeDDNo(e.target.value)} />
                          </Col> : ''}
                          <Col className="col-lg-4 col-md-4 col-12 mt-3">
                            <p className="mb-2 fontWeight500"> Transaction Date</p>
                            <Input type="date" className="" id="txtTransactionDate" placeholder="Enter Transaction Date" onChange={e => setTransactionDate(e.target.value)} />
                          </Col>
                          {ShowBankName > 0 ? <Col className="col-lg-4 col-md-4 col-12 mt-3">
                            <p className="mb-2 fontWeight500"> Bank Name </p>
                            <Input type="text" className="" id="txtBank Name" placeholder="Enter Bank Name" onChange={e => setBankName(e.target.value)} />
                          </Col> : ''}
                          {ShowBranch > 0 ? <Col className="col-lg-4 col-md-4 col-12 mt-3">
                            <p className="mb-2 fontWeight500"> Branch</p>
                            <Input type="text" className="" id="txtBranch" placeholder="Enter Branch" onChange={e => setBranch(e.target.value)} />
                          </Col> : ''}

                          {ShowChequeDDDate > 0 ? <Col className="col-lg-4 col-md-4 col-12 mt-3" id="divChequeDDDate">
                            <p className="mb-2 fontWeight500"> Cheque/DD Date</p>
                            <Input type="date" className="" id="txtChequeDDDate" placeholder="Enter Cheque/DD Date" onChange={e => setChequeDDDate(e.target.value)} />
                          </Col> : ''}

                          {ShowUTR > 0 ? <Col className="col-lg-4 col-md-4 col-12 mt-3">
                            <p className="mb-2 fontWeight500"> UTR</p>
                            <Input type="text" className="" id="txtUTR" placeholder="Enter UTR" onChange={e => setUTR(e.target.value)} />
                          </Col> : ''}
                          {ShowSettlement > 0 ? <Col className="col-lg-4 col-md-4 col-12 mt-3">
                            <p className="mb-2 fontWeight500"> Settlement</p>
                            <Input type="text" className="" id="txtSettlement" placeholder="Enter Settlement" onChange={e => setSettlement(e.target.value)} />
                          </Col> : ''}

                        </Row>

                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="modal-footer justify-content-center">
                <div className="text-end w-100">
                  <button type="button" className="btn btn_success " id="btnsave" aria-label="Close" data-bs-dismiss="modal" onClick={FinalPaymentSubmit}>Pay</button>
                  <input type="hidden" className="form-control" id="txtGSTPer" />
                  <input type="hidden" className="form-control" id="txtLeadId" />
                  <input type="hidden" className="form-control" id="txtServicePackageId" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="modal fade" id="exampleModalViewPay" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">View Payment</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form>
              <div className="modal-body">


                <div className="table-responsive text-center" id="style-4">
                  <table className="table table-sm table-bordered  ">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ReceiptNo.</th>
                        <th>Services</th>
                        <th>Recived Amount</th>
                        <th>GST Amount</th>
                        <th>Total Amount</th>

                        <th>Payment Mode</th>
                        <th>Transaction Date</th>
                        <th>Download Receipt</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ServicePackagePayment.map(item => (
                        <tr key={item.RowId}>
                          <td>{item.RowId}</td>
                          <td>{item.ReceiptCode}</td>
                          <td>{item.ServicePackage}</td>
                          <td>{item.RecivedAmount}</td>
                          <td>{item.RecivedGSTAmount}</td>
                          <td>{item.TotalAmount}</td>

                          <td>{item.PaymentMode}</td>
                          <td>{item.TransactionDate}</td>
                          <td> <a className="btn-sm btn btn-outline-primary" onClick={e => ServicePackagePaymentDetail(item)} >Download</a> </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="modal fade" id="receiptModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Receipt</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <div className="receiptmindiv" id="divprintreceiptmindiv" ref={inputRef}>
                  <div className="receiptmindivcontent">
                    <div className="d-flex align-items-center">
                      <div>
                        <img src={kcreceiptlogo} className="receiptlogo" alt="" />
                      </div>
                      <div>
                        <p className="receipttitle">KC FINANCIAL SOLUTIONS PVT.LTD.</p>
                      </div>
                    </div>
                    <div className="centertitle">
                      <div>
                        (Cibil & Loan Consultancy)
                      </div>
                    </div>

                    <p className="loactiondetailreceipt"><i className="fas fa-map-marker-alt"></i>H.O. : 3/139, Khandu
                      Colony,Banswara (Raj.), E-mail : Kcgroup2019@gmail.com</p>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="mainbiilno">
                        <span className="text">No.</span><span className="count">{ReceiptNumber}</span>
                      </div>
                      <div className="receiptbtn">
                        RECEIPT
                      </div>
                      <div className="d-flex">
                        <div className="inputtext">
                          Date :
                        </div>
                        <div className="receiptinput dateinput">
                          <span>{ReceiptDate}</span>
                        </div>
                      </div>

                    </div>
                    <div className="d-flex mt-3">
                      <div className="inputtext receivedwiththanksfortext">
                        Received with thanks from
                      </div>
                      <div className="receiptinput  receivedwiththanksfor">
                        <span> {FullName}</span>
                      </div>
                    </div>
                    <div className="d-flex mt-3">
                      <div className="inputtext">
                        the sum of Rs.
                      </div>
                      <div className="receiptinput  receivedwiththanksfor">
                        <span>{ReceiptTotalAmount}</span>
                      </div>
                    </div>
                    <div className="d-flex mt-3">
                      <div className="inputtext">
                        In words Rupees
                      </div>
                      <div className="receiptinput  receivedwiththanksfor">
                        <span>{ReceiptTotalAmountInWord} </span>
                      </div>
                    </div>
                    <div className="d-flex mt-3">
                      <div className="inputtext">
                        by {PaymentMode} No.
                      </div>
                      <div className="receiptinput  receivedwiththanksfor2">
                        <span> {ReceiptChequeDDNo}</span>
                      </div>
                    </div>

                    <div className="bottomreceiptdiv">
                      <div className="receiptamount">
                        <div className="amountboxlable">
                          <img src={rsimage} alt="" />
                        </div>
                        <div className="amountbox">
                          {ReceiptTotalAmount}
                        </div>

                      </div>
                      <div className="signature">
                        Auth. Singnature
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-end">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={printDocument}>Print</button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-12 mb-2">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Lead Payment Due</CardTitle>
              <a href="/" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <span className="ms-2">Lead Payment Due</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-12 text-end">
            <a className="btn btn-primary btn-sm font-14 me-2 " data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
              <i className="fas fa-filter"></i>
            </a>
            <ReactHtmlTableToExcel
              className="btn btn-primary btn-sm float-end font-14 me-2"
              table="emp"
              filename="LeadPaymentDueListReport"
              sheet="Sheet"
              buttonText="Export Excel" />
            <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Row>
              <Col xl="12">
                <div className="collapse" id="collapseExample">
                  <div className="card card-body">
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label className="form-label"> Quick search</label>
                        <form className="app-search app-searchnew p-0 d-none d-lg-block">
                          <div className="position-relative"><input type="text" className="form-control" placeholder="Search..." id="txtQuickSearch" /><span className="bx bx-search-alt"></span>
                          </div>
                        </form>
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">Lead Type </label>
                        <select className="form-select" aria-label="Default select example" id="txtLeadType">
                          <option value=''>All</option>
                          <option value="Individual">Individual</option>
                          <option value="Firm/Company">Firm/Company</option>

                        </select>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">Start Date </label>
                          <input type="date" className="form-control" id="txtStartDate" placeholder="name@example.com" />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">End Date </label>
                          <input type="date" className="form-control" id="txtEndDate" placeholder="name@example.com" />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="" className="form-label">Lead Enroll Status</label>
                          <select className="form-select" aria-label="Default select example" id="txtLeadEnroll">
                            <option value="">All</option>
                            <option value="P">Pending</option>
                            <option value="E">Enrolled</option>

                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="" className="form-label">Record Status</label>
                          <select className="form-select" aria-label="Default select example" id="txtRecord">
                            <option value="">All</option>
                            <option value="A">Active</option>
                            <option value="D">In-Active</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="mb-3">
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                        </div>
                      </div>

                      <div className="col-md-3">
                        <label className="form-label">Channel Type</label>
                        <select className="form-select" Id="ddlChanneltype" name="ddlChanneltype" onChange={(e) => { setChannelTypeId(e.target.value); getChannelMasterList(e.target.value) }}>
                          <option value={"0"}>Select Channel Type</option>
                          {ChannelTypeList.map((ChannelType) => (
                            <option key={ChannelType.ChannelTypeId} value={ChannelType.ChannelTypeId}> {ChannelType.ChannelTypeName} </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-md-3">
                        <label className="form-label">Channel Master</label>
                        <select className="form-select" Id="ddlChannelMaster" name="ddlChannelMaster" onChange={(e) => { setChannelMasterId(e.target.value); getModelLeadChannelPartnerList(e.target.value) }}>
                          <option value={"0"}>Select Channel Master</option>
                          {ChannelMasterList.map((ChannelMaster) => (
                            <option key={ChannelMaster.ChannelMasterId} value={ChannelMaster.ChannelMasterId}> {ChannelMaster.Title} </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-3">
                        <label className="form-label">Channel Partner</label>
                        <select className="form-select" Id="ddlChannelMaster" name="ddlChannelMaster" onChange={(e) => setChannelPartnerId(e.target.value)}>
                          <option value={"0"}>Select Channel Master</option>
                          {ChannelPartnerList.map((ChannelPartner) => (
                            <option key={ChannelPartner.ChannelPartnerId} value={ChannelPartner.ChannelPartnerId}> {ChannelPartner.Name} </option>
                          ))}
                        </select>
                      </div>


                      <div className="col-md-12 mt-3 mb-2">
                        <button type="button" className="btn btn-sm btn-success font-14" onClick={saveLead}>Go</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

          </Col>
        </Row>

        <Row>
          <div>
            <table id="emp" className="table" hidden="hidden">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Full Name</th>
                  <th>Lead Code</th>
                  <th>Created date</th>
                  <th>Lead Type</th>
                  <th>Email</th>
                  <th>Contact Number</th>
                  <th>Package Amount</th>
                  <th>Total Amount</th>
                  <th>Total Received Amount</th>
                  <th>Total Pending Amount</th>
                </tr>
              </thead>
              <tbody>              {
                records.map((item, index) => {
                  return <tr key={index}>
                    <td> {index + 1} </td>
                    <td >{item.FullName}</td>
                    <td>{item.LeadCode}</td>
                    <td>{item.CreateDate}</td>
                    <td>{item.LeadType}</td>
                    <td >{item.ConvertDate}</td>
                    <td >{item.EmailAddress}</td>
                    <td>{item.MobileNo}</td>
                    <td>{item.PackageAmount}</td>
                    <td >{item.TotalAmount}</td>
                    <td>{item.TotalRecivedAmount}</td>
                    <td>{item.TotalPendingAmount}</td>
                  </tr>
                })
              }
              </tbody>
            </table>

          </div>
        </Row>

        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default OnSort;