import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle,Dropdown,DropdownItem,DropdownToggle,DropdownMenu,  Progress, } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../assets/scss/datatables.scss"








// Table data
const products = [

  {
    "id": 1,
    "name": "Airi Satou 111",
    "position": "Accountant",
    "office": "Tokyo",
    "age": "33",
    "startdate": "",
    "salary": "$162,700"
  },

];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }
   
  }






  render() {

    const columns = [{
      dataField: 'id',
      text: 'Id',
      sort: true,
    }, {
      dataField: 'name',
      text: 'Name',
      sort: true
    }, {
      dataField: 'position',
      text: 'Position',
      sort: true
    }, {
      dataField: 'office',
      text: 'Office',
      sort: true
    }, {
      dataField: 'age',
      text: 'Age',
      sort: true
    }, {
      dataField: 'startdate',
      text: 'Start Date',
      sort: true
    }, {
      dataField: 'salary',
      text: 'Salary',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

  
    // Select All Button operation
    const selectRow = {
      mode: 'checkbox'
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
          <title>Task List | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
         
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h3"> Task List </CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2"> Task List </span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>

                <Card>
                  <CardBody>
                  <Row className=""> 
                <Col className="col-lg-4 col-md-4 col-4">
                               
                               {/* <div className="dropdown">
                 <button className="btn btn-border  dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                   Export
                 </button>
                 <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                   <li><a className="dropdown-item" href="#">Action</a></li>
                   <li><a className="dropdown-item" href="#">Another action</a></li>
                   <li><a className="dropdown-item" href="#">Something else here</a></li>
                 </ul>
               </div> */}
                                               </Col>
                                               <div className="col-lg-4 col-md- col-4 text-center col">
                   <div className="search-box me-2 mb-2 d-inline-block">
                       <div className="position-relative">
                           <label htmlFor="search-bar-0" className="search-label">
                               <span id="search-bar-0-label" className="sr-only">Search this table</span>
                               <input id="search-bar-0" type="text" aria-labelledby="search-bar-0-label" className="form-control " placeholder="Search" value="" />
                           </label>
                           <i className="bx bx-search-alt search-icon"></i>
                       </div>
                   </div>
               </div>
                                               
                                               <Col className="col-lg-4 col-md-4 col-4">
                                               <div className="d-inline float-end">
                                               <div className="dropdown">
                 <button className="btn btn-border dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                   10<i className="fas fa-angle-down align-middle ms-2"></i>
                 </button>
                 <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                   <li><a className="dropdown-item" href="#">25</a></li>
                   <li><a className="dropdown-item" href="#">30</a></li>
                   <li><a className="dropdown-item" href="#">35</a></li>
                 </ul>
               </div>
                                                 </div>
                                               </Col>
        

</Row>
                    <Row>
                  <Col className="col-md-6 mt-3">
                   <a href="/TaskDetail" className="table-link-a">
                   <div className="bg_grey8 p-3">
                        <div className="d-flex align-items-center gap-3 mb-2">
                          <div className="bg_lightgreen rounded-2 p-2 text-success text-center">   16 May 2022    </div>
                          <div> Et est vel nemo dolores magnam volas officiis voluptatem magnam volas officiis.</div>
                        </div> 
                        <Row className="align-items-center">
                            <Col className="col-lg-6">
                            <p className="mb-1 font-12">24% Complete</p>
                            <div className="progress bg_blue">
                              <div className="progress-bar bg-primary" style={{width:"40%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                            </Col>
                            <Col className="col-lg-6 text-end">
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            </Col>
                        </Row>
                      
                    </div>
                   </a>
                       </Col>  
                       
                       <Col className="col-md-6 mt-3">
                        <a href="/TaskDetail" className="table-link-a">
                        <div className="bg_grey8 p-3">
                        <div className="d-flex align-items-center gap-3 mb-2">
                          <div className="bg_lightgreen rounded-2 p-2 text-success text-center">   11 May 2022    </div>
                          <div> Et est vel nemo dolores magnam volas officiis voluptatem magnam volas officiis.</div>
                        </div> 
                        <Row className="align-items-center">
                            <Col className="col-lg-6">
                            <p className="mb-1 font-12">24% Complete</p>
                            <div className="progress bg_blue">
                              <div className="progress-bar bg-primary" style={{width:"40%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                            </Col>
                            <Col className="col-lg-6 text-end">
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            </Col>
                        </Row>
                      
                    </div>
                        </a>
                       </Col> 

                       <Col className="col-md-6 mt-3">
                        <a href="/TaskDetail" className="table-link-a">
                        <div className="bg_grey8 p-3">
                        <div className="d-flex align-items-center gap-3 mb-2">
                          <div className="bg_lightgreen rounded-2 p-2 text-success text-center">   16 May 2022    </div>
                          <div> Et est vel nemo dolores magnam volas officiis voluptatem magnam volas officiis.</div>
                        </div> 
                        <Row className="align-items-center">
                            <Col className="col-lg-6">
                            <p className="mb-1 font-12">24% Complete</p>
                            <div className="progress bg_blue">
                              <div className="progress-bar bg-primary" style={{width:"40%"}} role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                            </Col>
                            <Col className="col-lg-6 text-end">
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle client-img-width" />
                            </Col>
                        </Row>
                      
                    </div>
                        </a>
                       </Col>   
                       
                       
                    </Row>
                    <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                
                                  <div className="text-md-right ms-auto">
                                  <div className="pagination pagination-rounded justify-content-end">
    <ul className="pagination react-bootstrap-table-page-btns-ul">
        <li className="page-item" title="previous page"><a href="#" className="page-link">&lt;</a></li>
        <li className="page-item" title="1"><a href="#" className="page-link">1</a></li>
        <li className="active page-item" title="2"><a href="#" className="page-link">2</a></li>
        <li className="page-item" title="3"><a href="#" className="page-link">3</a></li>
        <li className="page-item" title="next page"><a href="#" className="page-link">&gt;</a></li>
    </ul>
</div>
                                  </div>
                                </Col>
                              </Row>
                    
                  </CardBody>
                </Card>
                <div className="modal fade" id="team" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Team Detail</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <Row className="pb-4">
          <div className="col-lg-12">
          <div className="h5 mb-4">Employees :</div>
          </div>
          <Col className="col-lg-6">
         
            <div className="team-modal-box">
            <div className="d-flex align-items-center border-bottom pb-2">
                  <div className="flex-shrink-0">
                  <div className="avatar-md">
                    <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail rounded-circle" />
                  </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                  <div className="font-14 dark-font mb-1">Hardik Dudhrejiya</div>
                      <div className="mb-1"><a href="" className="table-link-a">+436-426-3677</a></div>
                      <div className="" ><a href="" className="table-link-a">Vincenzo.Runolfsson43@hotmail.com</a></div>
                  </div>
                </div>
          
            <div className="modal-sub-box pt-2">
                <div className="department-box">
                  <p className="mb-0">Department: <span className="text-success">Accounts</span></p>
                </div>
                <div className="emp-code">
                <p className="mb-0">Employee Code: <span className="text-orange">08462</span></p>
                </div>
            </div>
            </div>
          </Col>
          <Col className="col-lg-6">
          
            <div className="team-modal-box">
            <div className="d-flex align-items-center border-bottom pb-2">
                  <div className="flex-shrink-0">
                  <div className="avatar-md">
                    <img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail rounded-circle" />
                  </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                  <div className="font-14 dark-font mb-1">Yogesh Ramani</div>
                      <div className="mb-1"><a href="" className="table-link-a">+436-426-3677</a></div>
                      <div className="" ><a href="" className="table-link-a">Ralph_Witting@gmail.com</a></div>
                  </div>
                </div>
          
            <div className="modal-sub-box pt-2">
                <div className="department-box">
                  <p className="mb-0">Department: <span className="text-success">Accounts</span></p>
                </div>
                <div className="emp-code">
                <p className="mb-0">Employee Code: <span className="text-orange">08462</span></p>
                </div>
            </div>
            </div>
          </Col>
        </Row>

        <Row className="pt-4 border-top">
          <div className="col-lg-12">
          <div className="h5 mb-4">Channel Partner :</div>
          </div>
          <Col className="col-lg-6">
         
            <div className="team-modal-box">
            <div className="d-flex align-items-center border-bottom pb-2">
                  <div className="flex-shrink-0">
                  <div className="avatar-md">
                    <img src="/static/media/avatar-4.b23e41d9.jpg" alt="" className="img-thumbnail rounded-circle" />
                  </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                  <div className="font-14 dark-font mb-1">Hardik Dudhrejiya</div>
                      <div className="mb-1"><a href="" className="table-link-a">+436-426-3677</a></div>
                      <div className="" ><a href="" className="table-link-a">Vincenzo.Runolfsson43@hotmail.com</a></div>
                  </div>
                </div>
          
            <div className="modal-sub-box pt-2">
                <div className="department-box">
                  <p className="mb-0">Partrner ID: <span className="text-success">#CP202200015</span></p>
                </div>
                {/* <div className="emp-code">
                <p className="mb-0">Employee Code: <span className="text-orange">08462</span></p>
                </div> */}
            </div>
            </div>
          </Col>
          <Col className="col-lg-6">
          
            <div className="team-modal-box">
            <div className="d-flex align-items-center border-bottom pb-2">
                  <div className="flex-shrink-0">
                  <div className="avatar-md">
                    <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle" />
                  </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                  <div className="font-14 dark-font mb-1">Yogesh Ramani</div>
                      <div className="mb-1"><a href="" className="table-link-a">+436-426-3677</a></div>
                      <div className="" ><a href="" className="table-link-a">Ralph_Witting@gmail.com</a></div>
                  </div>
                </div>
          
            <div className="modal-sub-box pt-2">
                <div className="department-box">
                <p className="mb-0">Partrner ID: <span className="text-success">#CP202200015</span></p>
                </div>
                {/* <div className="emp-code">
                <p className="mb-0">Employee Code: <span className="text-orange">08462</span></p>
                </div> */}
            </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        {/* <button type="button" className="btn btn-primary">Save changes</button> */}
      </div>
    </div>
  </div>
</div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DatatableTables