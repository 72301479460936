import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Reply_person from '../../../assets/images/users/avatar-2.jpg';
import placeholder from "assets/images/placholder-img.jpg"
import "../../../assets/scss/datatables.scss"

// Table data
const products = [

  { "id": 1, "name":<a href="/ClientInformation" className="text-heading">Smith Harshbhai Jain</a>, "channel": <><p className="mb-1">Ambawadi</p>
  <p className="">Ahemdabad</p></>, "contact": <><p className="mb-1"><a href="mailto:abc@gmail.com">abc@gmail.com</a></p><p className="mb-1"><a href="tel:+91 6598562369">+91 6598562369</a></p></>, "Service": <><p className="mb-0"><a href="">Service list 1</a></p><p className="mb-0"><a href="">Service list 2</a></p><p className="mb-0"><a href="">Service list 3</a></p></>,"con": "Nidhi Atul Shah","client": "Converted","date": "20/01/2022",action: (
    <>
     <div className="d-flex gap-3"><a className="text-success" href="/ClientAccount"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
    </>
  ), },

  { "id": 2, "name":<a href="/ClientInformation" className="text-heading">Kartik Balveer Singh</a>, "channel": <><p className="mb-1">Ambawadi</p>
  <p className="">Ahemdabad</p></>, "contact": <><p className="mb-1"><a href="mailto:abc@gmail.com">abc@gmail.com</a></p><p className="mb-1"><a href="tel:+91 6598562369">+91 6598562369</a></p></>, "Service": <><p className="mb-0"><a href="">Service list 1</a></p><p className="mb-0"><a href="">Service list 2</a></p><p className="mb-0"><a href="">Service list 3</a></p></>,"con": "Nidhi Atul Shah","client": "Converted","date": "20/01/2022",action: (
    <>
     <div className="d-flex gap-3"><a className="text-success" href="/ClientAccount"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
    </>
  ), },

  { "id": 3, "name":<a href="/ClientInformation" className="text-heading">Sheron Anilbhai Christian</a>, "channel": <><p className="mb-1">Ambawadi</p>
  <p className="">Ahemdabad</p></>, "contact": <><p className="mb-1"><a href="mailto:abc@gmail.com">abc@gmail.com</a></p><p className="mb-1"><a href="tel:+91 6598562369">+91 6598562369</a></p></>, "Service": <><p className="mb-0"><a href="">Service list 1</a></p><p className="mb-0"><a href="">Service list 2</a></p><p className="mb-0"><a href="">Service list 3</a></p></>,"con": "Nidhi Atul Shah","client": "Converted","date": "20/01/2022",action: (
    <>
     <div className="d-flex gap-3"><a className="text-success" href="/ClientAccount"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
    </>
  ), },

];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    const columns = [{
      dataField: 'id',
      text: 'Id',
      sort: true,
    }, {
      dataField: 'name',
      text: 'Full Name',
      sort: true
    }, {
      dataField: 'channel',
      text: 'Channel / Partner name ',
      sort: true
    }, {
      dataField: 'contact',
      text: 'Email & Contact Number',
      sort: true
    }, {
      dataField: 'Service',
      text: 'Service',
      sort: true
    }, {
      dataField: 'con',
      text: 'Assigned Counsellor',
      sort: true
    },
    {
        dataField: 'client',
        text: 'Client Status',
        sort: true
      },
      {
        dataField: 'date',
        text: 'Date',
        sort: true
      },
    {
      dataField: 'action',
      text: 'Action',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

  
    // Select All Button operation
    const selectRow = {
      mode: 'checkbox'
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
          <title>Add Lead | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
        
          <Row className="mb-3">
              <Col className="col-lg-8 col-md-6 col-6">
                <div className="breadcrum ps-0">
                <CardTitle className="h4">Add Lead</CardTitle>
                  <a href="#" className="me-2">
                    Home
                  </a>{" "}
                  <span>/</span>
                  <span className="ms-2">Add Lead</span>
                </div>
              </Col>
             
              <Col className="col-lg-4 col-md-6 col-6">
                <a
                  className="btn btn-primary btn-sm float-end font-14 margin-20-back"
                  href="#"
                >
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <Row className="">
                  <CardTitle className="mb-2">Personal Details</CardTitle>
                    <div className="col-lg-2">
                    <img className="width-100" src={placeholder}></img>
                    <form>
            <div className="mb-3 mt-2">
                <label htmlFor="formFileSm" className="form-label">Upload Image</label>
                <input className="form-control form-control-width" id="formFileSm" type="file" />
            </div>
        </form>
                  </div>
                  <Col className="col-lg-10">
                      <Row>
                      <div className="col-lg-4">
                    <form>
                        <div className="mb-3">
                          <label htmlFor="exampleInputEmail1" className="form-label">First Name</label>
                          <input type="email" placeholder="First Name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                        </div>
                    </form>
                  </div>
                  <div className="col-lg-4">
                    <form>
                        <div className="mb-3">
                          <label htmlFor="exampleInputEmail1" className="form-label">Middle Name</label>
                          <input type="email" placeholder="Middle Name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                        </div>
                    </form>
                  </div>
                  <div className="col-lg-4">
                    <form>
                        <div className="mb-3">
                          <label htmlFor="exampleInputEmail1" className="form-label">Last Name</label>
                          <input type="email" placeholder="Last Name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                        </div>
                    </form>
                  </div>
                  <div className="col-lg-4">
                    <form>
                        <div className="mb-3">
                          <label htmlFor="exampleInputEmail1" className="form-label">Gender</label>
                          <input type="email" placeholder="Gender" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                        </div>
                    </form>
                  </div>
                  <div className="col-lg-4">
                    <form>
                        <div className="mb-3">
                          <label htmlFor="exampleInputEmail1" className="form-label">State</label>
                          <input type="email" placeholder="State" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                        </div>
                    </form>
                  </div>
                  <div className="col-lg-4">
                    <form>
                        <div className="mb-3">
                          <label htmlFor="exampleInputEmail1" className="form-label">District</label>
                          <input type="email" placeholder="District" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                        </div>
                    </form>
                  </div>
                  <div className="col-lg-4">
                    <form>
                        <div className="mb-3">
                          <label htmlFor="exampleInputEmail1" className="form-label">City</label>
                          <input type="email" placeholder="City" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                        </div>
                    </form>
                  </div>
                  <div className="col-lg-4">
                    <form>
                        <div className="mb-3">
                          <label htmlFor="exampleInputEmail1" className="form-label">Tehsil</label>
                          <input type="email" placeholder="Tehsil" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                        </div>
                    </form>
                  </div>
                  <div className="col-lg-4">
                    <form>
                        <div className="mb-3">
                          <label htmlFor="exampleInputEmail1" className="form-label">Pincode</label>
                          <input type="email" placeholder="Pincode" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                        </div>
                    </form>
                  </div>
                      </Row>
                  </Col>
                    </Row>
                 
                   
                  
                  
                    
                
              </CardBody>
            </Card>

            <Card>
              <CardBody>
              <Row className="">
    <CardTitle className="mb-2">Contact Information</CardTitle>
    <div className="col-lg-6">
        <form>
            <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Mobile Number</label>
                <input type="email" placeholder="Mobile Number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
        </form>
    </div>
    <div className="col-lg-6">
        <form>
            <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Email Address</label>
                <input type="email" placeholder="Email Address" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
        </form>
    </div>
</Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
              <Row>
              <CardTitle className="mb-2">Channel Information</CardTitle>
              <div className="col-lg-4">
        <form>
            <div className="mb-3">
                <label htmlFor="formFileSm" className="form-label">Upload Image</label>
                <input className="form-control form-control" id="formFileSm" type="file" />
            </div>
        </form>
    </div>
    <div className="col-lg-4">
      <div className="mb-3">
      <label htmlFor="formFileSm" className="form-label">Channel Type</label>
            <select className="form-select" aria-label="Default select example">
                <option selected>Banking Network</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
            </select>
      </div>
    </div>
    <div className="col-lg-4">
      <div className="mb-3">
      <label htmlFor="formFileSm" className="form-label">Branch Name</label>
            <select className="form-select" aria-label="Default select example">
                <option selected>Select Branch Name</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
            </select>
      </div>
    </div>
    <div className="col-lg-4">
        <form>
            <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Branch Code</label>
                <input type="email" placeholder="Branch Code" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
        </form>
    </div>
    <div className="col-lg-4">
        <form>
            <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">IFSC Code</label>
                <input type="email" placeholder="IFSC Code" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
        </form>
    </div>
    <div className="col-lg-4">
        <form>
            <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Branch Location</label>
                <input type="email" placeholder="Branch Location" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
        </form>
    </div>
    <div className="col-lg-4">
    <div className="mb-3">
            <label htmlFor="formFileSm" className="form-label">City</label>
            <select className="form-select" aria-label="Default select example">
                <option selected>Select City</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
            </select>
        </div>
    </div>
    <div className="col-lg-4">
    <div className="mb-3">
    <label htmlFor="formFileSm" className="form-label">Tehsil</label>
            <select className="form-select" aria-label="Default select example">
                <option selected>Select Tehsil</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
            </select>
    </div>
    </div>
    <div className="col-lg-4">
        <div className="mb-3">
        <label htmlFor="formFileSm" className="form-label">District</label>
            <select className="form-select" aria-label="Default select example">
                <option selected>Select District</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
            </select>
        </div>
    </div>
    <div className="col-lg-4">
        <div className="mb-3">
        <label htmlFor="formFileSm" className="form-label">State</label>
            <select className="form-select" aria-label="Default select example">
                <option selected>Select State</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
            </select>
        </div>
    </div>
    <div className="col-lg-4">
        <form>
            <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Pincode</label>
                <input type="email" placeholder="Pincode" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
        </form>
    </div>
              </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
              <Row className="">
    <CardTitle className="mb-2">Channel Partner Details</CardTitle>
    <div className="col-lg-4">
        <form>
            <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Name</label>
                <input type="email" placeholder="Name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
        </form>
    </div>
    <div className="col-lg-4">
        <form>
            <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Mobile Number</label>
                <input type="email" placeholder="Mobile Number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
        </form>
    </div>
    <div className="col-lg-4">
        <form>
            <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Alternet Mobile Number</label>
                <input type="email" placeholder="Alternet Mobile Number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
        </form>
    </div>
    <div className="col-lg-4">
        <form>
            <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Alternet Email Address</label>
                <input type="email" placeholder="Alternet Email Address" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
        </form>
    </div>
    <div className="col-lg-4">
        <form>
            <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Landline Number</label>
                <input type="email" placeholder="Landline Number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
        </form>
    </div>
</Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
              <Row className="">
    <div className="col-lg-6">
        <CardTitle className="mb-2">Remarks</CardTitle>
        <div className="mb-3">
            {/* <label for="exampleFormControlTextarea1" class="form-label">Example textarea</label> */}
            <textarea className="form-control" placeholder="Remarks" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
    </div>
    <div className="col-lg-6">
        <CardTitle className="mb-2">Client Briefing</CardTitle>
        <div className="mb-3">
            {/* <label for="exampleFormControlTextarea1" class="form-label">Example textarea</label> */}
            <textarea className="form-control" placeholder="Briefing" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
    </div>
</Row>
<Row>
    <div className="col-lg-6">
        <button type="button" className="btn btn_success me-2">Save</button>
        <button type="button" className="btn btn_danger" data-bs-dismiss="modal">Close</button>
    </div>
</Row>
              </CardBody>
            </Card>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DatatableTables