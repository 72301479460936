import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, Input, Label, Row } from "reactstrap";
import "../../../assets/scss/datatables.scss";
import swal from 'sweetalert2';
import PlacholderImg from "assets/images/placholder-img.jpg";
import PlacholderImgC from "assets/images/favicon.png";
import { Link } from "react-router-dom";

const AddNewInquiryIndividual = () => {
  const history = useHistory();
  const [LeadId, setLeadId] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [MiddleName, setMiddleName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Gender, setGender] = useState("");
  const [Relationship, setRelationship] = useState("");
  const [RelationshipName, setRelationshipName] = useState("");
  const [DateOfBirth, setDateOfBirth] = useState("");
  const [PMobileNumber, setPMobileNumber] = useState("");
  const [PEmailAddress, setPEmailAddress] = useState("");
  const [LCIRID, setCIRID] = useState("");
  const [CIRPassword, setCIRPassword] = useState("");
  const [CountryId, setCountryId] = useState("");
  const [StateId, setStateId] = useState("");
  const [DistrictId, setDistrictId] = useState(0);
  const [CityId, setCityId] = useState("");
  const [TehsilId, setTehsilId] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Address, setAddress] = useState("");
  const [Tags, setTags] = useState("");
  const [PriorityId, setPriorityId] = useState("");
  const [CountryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);
  const [DocumentTypeList, setDocumentTypeList] = useState([]);
  const [DocumentList, setDocumentList] = useState([]);
  const [PriorityList, setPriorityList] = useState([]);
  const [TagsList, setTagsList] = useState([]);
  const [Remarks, setRemarks] = useState("");
  const [LeadBriefing, setLeadBriefing] = useState("");
  const [EPInputChange, setEPInputChange] = useState("");
  // Document Fields

  // Dropdown -Company-selected value

  const [LeadContactList, setLeadContactList] = useState([]);
  const [LeadEmailList, setLeadEmailList] = useState([]);
  const [LeadDocumentList, setLeadDocumentList] = useState([]);

  const [EmailAddress, setEmailAddress] = useState("");
  const [Password, setPassword] = useState("");

  const [DocumentTypeId, setDocumentTypeId] = useState("");
  const [DocumentType, setDocumentType] = useState("");
  const [DocumentId, setDocumentId] = useState("");
  const [Document, setDocument] = useState("");
  const [DocumentFilePath, setDocumentFilePath] = useState("");
  const [DocumentFileName, setDocumentFileName] = useState("");
  const [DisplayDocumentFile, setDisplayDocumentFile] = useState(0);

  const [DocumentRemarks, setDocumentRemarks] = useState("");

  const [ModelChannelList, setModelChannelList] = useState([]);
  const [DropdownChannelList, setDropdownChannelList] = useState([]);
  const [DropdownChannelTypeList, setDropdownChannelTypeList] = useState([]);
  const [DropdownCityList, setDropdownCityList] = useState([]);
  const [DropdownChannelBranchList, setDropdownChannelBranchList] = useState([]);
  const [SelectChannelDetail, setSelectChannelDetail] = useState([]);
  const [ChannelPartnerList, setChannelPartnerList] = useState([]);
  const [SelectChannelPartnerDetail, setSelectChannelPartnerDetail] = useState([]);
  const [ChannelTypeList, setChannelTypeList] = useState([]);

  const [ChannelId, setChannelId] = useState(0);
  const [ChannelPartnerId, setChannelPartnerId] = useState("0");

  const [AddTehsil, setAddTehsil] = useState('D');
  const [AddCity, setAddCity] = useState('D');


  useEffect(() => {
    getMenuHideShow();
    setCountryId("1");
    getStateList("1");
    getPriorityList();
    getTagsList();
    getDocumentTypeList();
    getDropdownChannelTypeList();
    getChannelTypeList();
    getCountryList();
    getChannelSubSourceDepartmentList();
    getChannelSubSourceDesignation();
    getChannelSubSourceCountryList();

  }, []);


  const getMenuHideShow = async () => {
    const f = {
      MenuId: "3",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {
        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add Tehsil') {
            setAddTehsil(MS);
          }
          else if (MA == 'Add City') {
            setAddCity(MS);
          }
        }
      });
  };

  const getDropdownChannelTypeList = async () => {
    axios.get(global.APIURL + "/ChannalTypeList")
      .then(CLres => {
        let VChannelTypeList = CLres.data.map(vcl => {
          return vcl;
        });
        setDropdownChannelTypeList(VChannelTypeList);
      });
  };

  const getModelLeadChannelList = async () => {
    var VChannelMasterId = document.getElementById("ddlChannel").value;
    var VChannelTypeId = document.getElementById("ddlChannelType").value;
    var VBranchId = document.getElementById("ddlBranch").value;
    var VCityId = document.getElementById("ddlCity").value;

    const LeadChannelFilter = {
      ChannelTypeId: VChannelTypeId,
      ChannelMasterId: VChannelMasterId,
      BranchId: VBranchId,
      CityId: VCityId
      //FilterSearchByName: "",
    };
    axios.post(global.APIURL + "/ActiveChannelFilter", LeadChannelFilter)
      .then(CLres => {
        let VChannelList = CLres.data.map(vcl => {
          return vcl;
        });
        setModelChannelList(VChannelList);
      });
  };

  const getDropdownChannelMasterList = async () => {
    var VChannelTypeId = document.getElementById("ddlChannelType").value;
    if (parseInt(VChannelTypeId) > 0) {
      const ChannelDropdownFilter = {
        ChannelTypeId: VChannelTypeId,
        ChannelMasterId: 0,
        CityId: 0,
        SpMode: 'ChannelMasterSelectAll',
      };
      axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
        .then(CLres => {
          let VChannelMasterList = CLres.data.map(vcl => {
            return vcl;
          });
          setDropdownChannelList(VChannelMasterList);
        });
    }
  };

  const getDropdownCityList = async () => {
    var VChannelTypeId = document.getElementById("ddlChannelType").value;
    var VChannelMasterId = document.getElementById("ddlChannel").value;

    if (parseInt(VChannelTypeId) > 0 && parseInt(VChannelMasterId) > 0) {
      const ChannelDropdownFilter = {
        ChannelTypeId: VChannelTypeId,
        ChannelMasterId: VChannelMasterId,
        CityId: 0,
        SpMode: 'ChannelCitySelectAll',
      };
      axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
        .then(CLres => {
          let VChannelMasterList = CLres.data.map(vcl => {
            return vcl;
          });
          setDropdownCityList(VChannelMasterList);
        });
    }
  };

  const getDropdownBranchList = async () => {
    var VChannelTypeId = document.getElementById("ddlChannelType").value;
    var VChannelMasterId = document.getElementById("ddlChannel").value;
    var VCityId = document.getElementById("ddlCity").value;

    if (parseInt(VChannelTypeId) > 0 && parseInt(VChannelMasterId) > 0 && parseInt(VCityId) > 0) {
      const ChannelDropdownFilter = {
        ChannelTypeId: VChannelTypeId,
        ChannelMasterId: VChannelMasterId,
        CityId: VCityId,
        SpMode: 'ChannelBranchSelectAll',
      };
      axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
        .then(CLres => {
          let VChannelMasterList = CLres.data.map(vcl => {
            return vcl;
          });
          setDropdownChannelBranchList(VChannelMasterList);
        });
    }
  };

  const getChannelDetail = async ChId => {
    var AUrl = global.APIURL + '/WebChannelDetail/' + ChId;
    axios.get(AUrl)
      .then(CDres => {
        let VChannelDetail = CDres.data.map(CDres => {
          return CDres;
        });
        setSelectChannelDetail(VChannelDetail);
      });
  };

  const getChannelPartnerDetail = async CHPId => {
    var AUrl = global.APIURL + '/WebChannelPartnerDetail/' + CHPId;
    axios.get(AUrl)
      .then(CDres => {
        let VChannelDetail = CDres.data.map(CDres => {
          return CDres;
        });
        setSelectChannelPartnerDetail(VChannelDetail);
      });
  };

  const getModelLeadChannelPartnerList = async CHId => {
    const LeadChannelPartnerFilter = {
      ChannelId: CHId,
      FilterSearchByName: "",
    };
    axios.post(global.APIURL + "/ActiveChannelPartner", LeadChannelPartnerFilter)
      .then(CPLres => {
        let VChannelPartnerList = CPLres.data.map(vcpl => {
          return vcpl;
        });
        setChannelPartnerList(VChannelPartnerList);
      });
  };

  const getStateList = async CId => {
    var AUrl = global.APIURL + '/ActiveState/' + CId;
    axios.get(AUrl)
      .then(Sres => {
        let StateL = Sres.data.map(kcstate => {
          return kcstate;
        });
        setStateList(StateL);
      });
  };

  const getPriorityList = async () => {
    axios.get(global.APIURL + '/ActivePriority')
      .then(Pres => {
        let Priority = Pres.data.map(priority => {
          return priority;
        });
        setPriorityList(Priority);
      });
  };

  const getTagsList = async () => {
    axios.get(global.APIURL + '/ActiveTag')
      .then(Tres => {
        let ActiveTag = Tres.data.map(kctag => {
          return kctag;
        });
        setTagsList(ActiveTag);
      });
  };

  const getDocumentTypeList = async () => {
    axios.get(global.APIURL + '/ActiveDocumentType')
      .then(DTres => {
        let DTList = DTres.data.map(documenytype => {
          return documenytype;
        });
        setDocumentTypeList(DTList);
      });
  };

  const getDocumentList = async (DTId) => {
    var AUrl = global.APIURL + '/DocumentActiveList/' + DTId;
    axios.get(AUrl)
      .then(DTres => {
        let DList = DTres.data.map(document => {
          return document;
        });
        setDocumentList(DList);
      });
  };

  const ContacthandleAddRow = () => {
    var LRNo = LeadContactList.length == 0 ? 1 : (LeadContactList[LeadContactList.length - 1].ContactId + 1);
    var VMobileNo = document.getElementById('txtMobileNo').value;
    var VIsActiveWhatsApp = document.getElementById("ChkActiveWhatsApp").checked;
    const Citem = {
      ContactId: LRNo,
      MobileNo: VMobileNo,
      TextBoxControlId: 'txtMobileNo' + LRNo,
      CheckBoxControlId: 'ChkActiveWhatsApp' + LRNo,
      IsActiveWhatsApp: VIsActiveWhatsApp,
      IsActiveWhatsAppYN: VIsActiveWhatsApp == true ? "Yes" : "No",
    };
    setLeadContactList([...LeadContactList, Citem]);
    document.getElementById('txtMobileNo').value = '';
    document.getElementById("ChkActiveWhatsApp").checked = false;
  };

  const ContacthandleRemoveRow = (e, RowIndex) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowIndex;
      setLeadContactList(LeadContactList.filter(item => item.ContactId !== RId));
    }
  };

  const EmailhandleAddRow = () => {
    var LRNo = LeadEmailList.length == 0 ? 1 : (LeadEmailList[LeadEmailList.length - 1].EmailId + 1);
    var VEmailAddress = document.getElementById('txtFEmailAddress').value;
    var VPassword = document.getElementById("txtFPassword").value;
    const Eitem = {
      EmailId: LRNo,
      EmailAddress: VEmailAddress,
      Password: VPassword,
      EmailTextBoxControlId: 'txtFEmailAddress' + LRNo,
      PasswordTextBoxControlId: 'txtFPassword' + LRNo,
    };
    setLeadEmailList([...LeadEmailList, Eitem]);
    document.getElementById('txtFEmailAddress').value = '';
    document.getElementById('txtFPassword').value = '';
  };

  const EmailhandleRemoveRow = (e, RowIndex) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowIndex;
      setLeadEmailList(LeadEmailList.filter(item => item.EmailId !== RId));
    }
  };

  const PasswordInputChange = (e, VEmailPassword) => {
    var FPasswordType = document.getElementById(VEmailPassword).type;
    if (FPasswordType == 'password') {
      document.getElementById(VEmailPassword).type = "input";
    }
    else if (FPasswordType == 'input') {
      document.getElementById(VEmailPassword).type = "password";
    }
    else {
      document.getElementById(VEmailPassword).type = "password";
    }
  };

  const DocumentTypeOnChange = e => {
    var VDocumentType = e.nativeEvent.target.selectedIndex;
    var DocumentTypeId = e.nativeEvent.target[VDocumentType].value;
    var DocumentTypeText = e.nativeEvent.target[VDocumentType].text;
    setDocumentTypeId(DocumentTypeId);
    setDocumentType(DocumentTypeText);
    getDocumentList(DocumentTypeId);
  };

  const DocumentOnChange = e => {
    var VDocument = e.nativeEvent.target.selectedIndex;
    var DocumentId = e.nativeEvent.target[VDocument].value;
    var DocumentText = e.nativeEvent.target[VDocument].text;
    setDocumentId(DocumentId);
    setDocument(DocumentText);
  };

  const DocumenthandleAddRow = e => {
    var LRNo = LeadDocumentList.length == 0 ? 1 : (LeadDocumentList[LeadDocumentList.length - 1].DocumentId + 1);
    const Ditem = {
      DocumentId: LRNo,
      DocumentTypeId: DocumentTypeId,
      DocumentType: DocumentType,
      DocumentId: DocumentId,
      Document: Document,
      KYCDocNo: document.getElementById("txtKYCDocNo").value,
      UDocumentFilePath: DocumentFilePath,
      UDocumentFileName: DocumentFileName,
      DocumentTypeTextBoxControlId: 'txtDocumentType' + LRNo,
      DocumentTypeHiddenControlId: 'hdfDocumentType' + LRNo,
      DocumentTextBoxControlId: 'txtDocument' + LRNo,
      DocumentHiddenControlId: 'hdfDocument' + LRNo,
      KYCDocNoTextBoxControlId: 'txtKYCDocNo' + LRNo,
      UploadFileTextBoxControlId: 'txtUploadFile' + LRNo,
    };

    document.getElementById("ddlDocumentTypeI").value = '0';
    document.getElementById("ddlDocumentI").value = '0';
    document.getElementById("FUDocumentFilePathF").value = '';
    document.getElementById("txtKYCDocNo").value = '';
    setDocumentTypeId('0');
    setDocumentType('');
    setDocumentId('0');
    setDocument('');
    setDocumentFilePath('');
    getDocumentTypeList();
    setLeadDocumentList([...LeadDocumentList, Ditem]);
  };

  const DocumenthandleRemoveRow = (e, idx) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = idx;
      setLeadDocumentList(
        LeadDocumentList.filter(item => item.DocumentId !== RId)
      );
    }
  };

  const getDistrictList = async SId => {
    var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
    axios.get(AUrl)
      .then(Dres => {
        let DistrictL = Dres.data.map(kcdistrict => {
          return kcdistrict;
        });
        setDistrictList(DistrictL);
      });
  };

  const getTehsilList = async DId => {
    var AUrl = global.APIURL + '/ActiveTehsil/' + DId;
    axios.get(AUrl)
      .then(Dres => {
        let TehsilL = Dres.data.map(kctehsil => {
          return kctehsil;
        });
        setTehsilList(TehsilL);
      });
  };

  const getCityList = async TId => {
    var AUrlC = global.APIURL + '/ActiveCity/' + TId;
    axios.get(AUrlC)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCityList(CityL);
      });
  };

  const SaveNewInqiry = async () => {
    const LeadInquiry = {
      Id: "0",
      LeadType: "Individual",
      IsLeadInquiry: "Yes",
      ProfileImage: ProfileImage == 'assets/images/placholder-img.jpg' ? '' : ProfileImage,
      ProfileImageName: '',
      ProfileImageExtension: '',
      FirstName: FirstName,
      MiddelName: MiddleName,
      LastName: LastName,
      Relationship: Relationship,
      RelationshipName: RelationshipName,
      DateOfBirth: DateOfBirth,
      MobileNumber: PMobileNumber,
      EmailAddress: PEmailAddress,
      Gender: Gender,
      Address: Address,
      CountryId: CountryId,
      StateId: StateId,
      DistrictId: DistrictId,
      CityId: CityId,
      TehsilId: TehsilId,
      Pincode: Pincode,
      CIRID: LCIRID,
      CIRPassword: CIRPassword,
      ISCompanyNew: "Yes",
      CompanyId: "0",
      CompanyType: "",
      CompanyName: "",
      CompanyMobileNumber: "",
      CompanyEmailAddress: "",
      CompanyPan: "",
      CompanyGSTNo: "",
      CompanyTINNo: "",
      CompanyStateId: "0",
      CompanyCityId: "0",
      CompanyDistrictId: "0",
      CompanyTehsilId: "0",
      CompanyPincode: "",
      ChannelId: ChannelId,
      ChannelPartnerId: ChannelPartnerId,
      Tags: Tags,
      PriorityId: PriorityId,
      Reference: "",
      Remarks: Remarks,
      ClientBriefing: LeadBriefing,
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
    };
    axios.post(global.APIURL + "/WebLeadAdd", LeadInquiry)
      .then(res => {
        var VLeadId = res.data[0].LeadId;

        for (let con = 0; con < LeadContactList.length; con++) {
          const LeadContactDetail = LeadContactList[con];
          var VTextBoxControlId = LeadContactDetail.TextBoxControlId;
          var VCheckBoxControlId = LeadContactDetail.CheckBoxControlId;
          var VIsActiveWhatsApp = document.getElementById(VCheckBoxControlId).checked;
          var MobileNo = document.getElementById(VTextBoxControlId).value == null ? '' : document.getElementById(VTextBoxControlId).value;
          if (MobileNo != '') {
            const ContactItemArr = {
              LeadContactId: 0,
              LeadId: VLeadId,
              MobileNo: MobileNo,
              IsActiveWhatsApp: VIsActiveWhatsApp,
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            };

            axios.post(global.APIURL + '/WebLeadContactInformationAdd', ContactItemArr).then(() => { });
          }
        }

        var BVIsActiveWhatsApp = document.getElementById('ChkActiveWhatsApp').checked;
        var BVMobileNo = document.getElementById('txtMobileNo').value == null ? '' : document.getElementById('txtMobileNo').value;

        if (BVMobileNo != '') {
          const ContactItemArr = {
            LeadContactId: 0,
            LeadId: VLeadId,
            MobileNo: BVMobileNo,
            IsActiveWhatsApp: BVIsActiveWhatsApp,
            CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
          };
          axios.post(global.APIURL + '/WebLeadContactInformationAdd', ContactItemArr).then(() => { });
        }

        for (let ema = 0; ema < LeadEmailList.length; ema++) {
          const LeadEmailDetail = LeadEmailList[ema];

          var VEmailTextBoxControlId = LeadEmailDetail.EmailTextBoxControlId;
          var VPasswordTextBoxControlId = LeadEmailDetail.PasswordTextBoxControlId;
          var VEmailAddress = document.getElementById(VEmailTextBoxControlId).value == null ? '' : document.getElementById(VEmailTextBoxControlId).value;
          var VPassword = document.getElementById(VPasswordTextBoxControlId).value == null ? '' : document.getElementById(VPasswordTextBoxControlId).value;

          const EmailItemArr = {
            LeadEmailId: 0,
            LeadId: VLeadId,
            EmailId: VEmailAddress,
            Password: VPassword,
            CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
          };

          axios.post(global.APIURL + '/WebLeadEmailInformationAdd', EmailItemArr).then(() => { });
        }

        var BVEmailAddress = document.getElementById('txtFEmailAddress').value == null ? '' : document.getElementById('txtFEmailAddress').value;
        var BVPassword = document.getElementById('txtFPassword').value == null ? '' : document.getElementById('txtFPassword').value;
        if (BVEmailAddress != '') {
          const BEmailItemArr = {
            LeadEmailId: 0,
            LeadId: VLeadId,
            EmailId: BVEmailAddress,
            Password: BVPassword,
            CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
          };

          axios.post(global.APIURL + '/WebLeadEmailInformationAdd', BEmailItemArr).then(() => { });
        }

        for (let ema = 0; ema < LeadDocumentList.length; ema++) {
          const LeadDocumentDetail = LeadDocumentList[ema];
          var VDocumentTypeHiddenControlId = LeadDocumentDetail.DocumentTypeHiddenControlId;
          var VDocumentHiddenControlId = LeadDocumentDetail.DocumentHiddenControlId;
          var VUploadFileTextBoxControlId = LeadDocumentDetail.UploadFileTextBoxControlId;
          var VKYCDocNoTextBoxControlId = LeadDocumentDetail.KYCDocNoTextBoxControlId;
          var VKYCDocNo = document.getElementById(VKYCDocNoTextBoxControlId).value == null ? '' : document.getElementById(VKYCDocNoTextBoxControlId).value;
          var VDocumentTypeId = document.getElementById(VDocumentTypeHiddenControlId).value == null ? '' : document.getElementById(VDocumentTypeHiddenControlId).value;
          var VDocumentId = document.getElementById(VDocumentHiddenControlId).value == null ? '' : document.getElementById(VDocumentHiddenControlId).value;
          var VUploadFile = document.getElementById(VUploadFileTextBoxControlId).value == null ? '' : document.getElementById(VUploadFileTextBoxControlId).value;

          const DocumentItemArr = {
            LeadId: VLeadId,
            DocumentTypeId: VDocumentTypeId,
            DocumentId: VDocumentId,
            DocumentFilePath: VUploadFile,
            Remarks: VKYCDocNo,
            CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
          };

          axios.post(global.APIURL + '/WebLeadDocumentAdd', DocumentItemArr).then(() => { });
        }

        alert(res.data[0].RecordStatus);
        history.push("/AllInq");
      });
  };

  const GetSetChannelId = async CId => {
    setChannelId(parseInt(CId == null ? 0 : CId == '' ? 0 : CId));
    getChannelDetail(CId);
    getModelLeadChannelPartnerList(CId);
  };

  const GetSetChannelPartnerId = async CPId => {
    setChannelPartnerId(CPId);
    getChannelPartnerDetail(CPId);
  };

  const [ProfileImage, setProfileImage] = useState(PlacholderImg);

  function ProfilefetchPost(e) {
    var details = e.target.files[0];
    let arr = details.name.toString().split(".");
    var FileEx = arr[1];
    var isUpload = 0;
    if (FileEx == 'jpg') {
      isUpload++;
    }
    else if (FileEx == 'JPG') {
      isUpload++;
    }
    else if (FileEx == 'jpeg') {
      isUpload++;
    }
    else if (FileEx == 'JPEG') {
      isUpload++;
    }
    else if (FileEx == 'png') {
      isUpload++;
    }
    else if (FileEx == 'PNG') {
      isUpload++;
    }
    else if (FileEx == 'gif') {
      isUpload++;
    }
    else if (FileEx == 'GIF') {
      isUpload++;
    }

    if (parseInt(isUpload) > 0) {
      var details1 = "LeadProfileImage";
      const formData = new FormData();
      formData.append('Document', details);
      formData.append('FolderName', details1);
      var AUrl = global.APIURL + '/uploadavatar/';
      axios.post(AUrl, formData,
        {
          headers: { "Content-Type": details.type },
        })
        .then((res) => {
          setProfileImage(res.data.IName);
          document.getElementById("IGProfileImage").src = res.data.IName;
        });
    }
    else {
      document.getElementById("fuProfileImage").value = '';
      alert("Please upload file .jpg or .jpeg or .png or .gif image!");
    }
  }

  function DocumentfetchPost(e) {
    var details = e.target.files[0] == null ? '' : e.target.files[0] == undefined ? '' : e.target.files[0];
    if (details != '') {
      var details1 = "LeadDocument";
      const formData = new FormData();
      formData.append('Document', details);
      formData.append('FolderName', details1);
      var AUrl = global.APIURL + '/uploadavatar/';
      axios.post(AUrl, formData,
        {
          headers: { "Content-Type": details.type },
        })
        .then((res) => {
          setDocumentFilePath(res.data.IName);
          setDocumentFileName(e.target.files[0].name);
          if (e.target.files[0].name != null) {
            if (e.target.files[0].name != '') {
              setDisplayDocumentFile(1);
            }
            else {
              setDisplayDocumentFile(0);
              setDocumentFilePath('');
              setDocumentFileName('');
            }
          }
          else {
            setDisplayDocumentFile(0);
            setDocumentFilePath('');
            setDocumentFileName('');
          }
        });
    }
    else {
      setDisplayDocumentFile(0);
      setDocumentFilePath('');
      setDocumentFileName('');
    }
  }

  const getChannelTypeList = async () => {
    fetch(global.APIURL + "/ChannalTypeList")
      .then((res) => res.json())
      .then((json) => {
        setChannelTypeList(json)
      })
  }

  function ChannelLogofetchPost(e) {
    var details = e.target.files[0];
    let arr = details.name.toString().split(".");
    var FileEx = arr[1];
    var isUpload = 0;
    if (FileEx == 'jpg') {
      isUpload++;
    }
    else if (FileEx == 'JPG') {
      isUpload++;
    }
    else if (FileEx == 'jpeg') {
      isUpload++;
    }
    else if (FileEx == 'JPEG') {
      isUpload++;
    }
    else if (FileEx == 'png') {
      isUpload++;
    }
    else if (FileEx == 'PNG') {
      isUpload++;
    }
    else if (FileEx == 'gif') {
      isUpload++;
    }
    else if (FileEx == 'GIF') {
      isUpload++;
    }
    if (parseInt(isUpload) > 0) {
      var details1 = "ChannelSource";
      const formData = new FormData();
      formData.append('Document', details);
      formData.append('FolderName', details1);
      var AUrl = global.APIURL + '/uploadavatar/';
      axios.post(AUrl, formData,
        {
          headers: { "Content-Type": details.type },
        })
        .then((res) => {
          setChannelLogo(res.data.IName);
          document.getElementById("IGProfileImage").src = res.data.IName;
        });
    }
    else {
      document.getElementById("fuChannelLogo").value = '';
      alert("Please upload file .jpg or .jpeg or .png or .gif image!");
    }
  }

  const getCountryList = async () => {
    fetch(global.APIURL + "/CountryActiveList")
      .then((res) => res.json())
      .then((json) => {
        setChannelCountryList(json)
      })
  }

  const getChannelStateList = async CId => {
    var AUrl = global.APIURL + '/ActiveState/' + CId;
    axios.get(AUrl)
      .then(Sres => {
        let StateL = Sres.data.map(kcstate => {
          return kcstate;
        });
        setChannelStateList(StateL);
      });
  };

  const getChannelDistrictList = async SId => {
    var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
    axios.get(AUrl)
      .then(Dres => {
        let DistrictL = Dres.data.map(kcdistrict => {
          return kcdistrict;
        });
        setChannelDistrictList(DistrictL);
      });
  };

  const getChannelTehsilList = async DId => {
    var AUrl = global.APIURL + '/ActiveTehsil/' + DId;
    axios.get(AUrl)
      .then(Dres => {
        let TehsilL = Dres.data.map(kctehsil => {
          return kctehsil;
        });
        setChannelTehsilList(TehsilL);
      });
  };

  const getChannelCityList = async TId => {
    var AUrlC = global.APIURL + '/ActiveCity/' + TId;
    axios.get(AUrlC)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setChannelCityList(CityL);
      });
  };


  const [ChannelCountryList, setChannelCountryList] = useState([]);
  const [ChannelStateList, setChannelStateList] = useState([]);
  const [ChannelDistrictList, setChannelDistrictList] = useState([]);
  const [ChannelCityList, setChannelCityList] = useState([]);
  const [ChannelTehsilList, setChannelTehsilList] = useState([]);
  const [ChannelMasterList, setChannelMasterList] = useState([]);

  const [ChannelLogo, setChannelLogo] = useState(PlacholderImgC);
  const [ChannelName, setChannelName] = useState("");
  const [ChannelTypeId, setChannelTypeId] = useState("");
  const [ChannelMasterId, setChannelMasterId] = useState("");
  const [BranchName, setBranchName] = useState("");
  const [BranchCode, setBranchCode] = useState("");
  const [IFSCCode, setIFSCCode] = useState("");
  const [BranchContactNo, setBranchContactNo] = useState("");
  const [BranchEmailAddress, setBranchEmailAddress] = useState("");
  const [BranchLocation, setBranchLocation] = useState("");
  const [BranchAddress, setBranchAddress] = useState("");
  const [BranchCountryId, setBranchCountryId] = useState("0");
  const [BranchStateId, setBranchStateId] = useState("0");
  const [BranchDistrictId, setBranchDistrictId] = useState("0");
  const [BranchTehsilId, setBranchTehsilId] = useState("0");
  const [BranchCityId, setBranchCityId] = useState("0");
  const [BranchPincode, setBranchPincode] = useState("");

  const ChannelDetailSave = async (e) => {
    e.preventDefault();

    const UserDetailArr = {
      action: "Add",
      ChannelId: "0",
      IsExistingBranch: "No",
      ChannelImage: ChannelLogo,
      ChannelTypeId: ChannelTypeId,
      ChannelMasterId: ChannelMasterId,
      IsNewChannel: "Yes",
      ExistingChannelId: "0",
      ExistingBranchId: "0",
      ChannelName: ChannelName,
      BranchName: BranchName,
      BranchCode: BranchCode,
      BranchIFSCCode: IFSCCode,
      BranchStateID: BranchStateId,
      BranchCityID: BranchCityId,
      BranchDistrictId: BranchDistrictId,
      BranchTehsilId: BranchTehsilId,
      BranchPincode: BranchPincode,
      BranchContactNumber: BranchContactNo,
      BranchEmailAddress: BranchEmailAddress,
      BranchAddress: BranchAddress,
      BranchLocation: BranchLocation,
      Flag: "A",
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    }

    axios.post(global.APIURL + '/ChannelAddEditWeb', UserDetailArr)
      .then(res => {
        if (res.data.length > 0) {
          alert(res.data);
          var VChannelMasterId = document.getElementById("ddlChannel").value;
          var VChannelTypeId = document.getElementById("ddlChannelType").value;
          var VBranchId = document.getElementById("ddlBranch").value;
          var VCityId = document.getElementById("ddlCity").value;

          const LeadChannelFilter = {
            ChannelTypeId: VChannelTypeId,
            ChannelMasterId: VChannelMasterId,
            BranchId: VBranchId,
            CityId: VCityId
            //FilterSearchByName: "",
          };

          axios.post(global.APIURL + "/ActiveChannelFilter", LeadChannelFilter)
            .then(CLres => {
              let VChannelList = CLres.data.map(vcl => {
                return vcl;
              });
              setModelChannelList(VChannelList);
            });

          document.getElementById("fuChannelLogo").value = '';
          document.getElementById("ddlChanneltype").value = 0;
          document.getElementById("txtChannelName").value = '';
          document.getElementById("txtBranchName").value = '';
          document.getElementById("txtBranchCode").value = '';
          document.getElementById("txtIFSC").value = '';
          document.getElementById("txtContactNo").value = '';
          document.getElementById("txtEmail").value = '';
          document.getElementById("txtBranchLocation").value = '';
          document.getElementById("txtAddress").value = '';
          document.getElementById("ddlChannelCountry").value = 0;
          document.getElementById("ddlChannelState").value = 0;
          document.getElementById("ddlChannelDistrict").value = 0;
          document.getElementById("ddlChannelTehsil").value = 0;
          document.getElementById("ddlChannelCity").value = 0;
          document.getElementById("txtChannelPincode").value = '';
        }
      });
  }

  const ChannelDetailClose = async (e) => {
    e.preventDefault();
    document.getElementById("fuChannelLogo").value = '';
    document.getElementById("ddlChanneltype").value = 0;
    document.getElementById("txtChannelName").value = '';
    document.getElementById("txtBranchName").value = '';
    document.getElementById("txtBranchCode").value = '';
    document.getElementById("txtIFSC").value = '';
    document.getElementById("txtContactNo").value = '';
    document.getElementById("txtEmail").value = '';
    document.getElementById("txtBranchLocation").value = '';
    document.getElementById("txtAddress").value = '';
    document.getElementById("ddlChannelCountry").value = 0;
    document.getElementById("ddlChannelState").value = 0;
    document.getElementById("ddlChannelDistrict").value = 0;
    document.getElementById("ddlChannelTehsil").value = 0;
    document.getElementById("ddlChannelCity").value = 0;
    document.getElementById("txtChannelPincode").value = '';
  }

  const [ChannelSubSourceLog, setChannelSubSourceLog] = useState('');
  const [ChannelSubSourceName, setChannelSubSourceName] = useState("");
  const [ChannelSubSourceDesignationId, setChannelSubSourceDesignationId] = useState(0);
  const [ChannelSubSourceDepartmentId, setChannelSubSourceDepartmentId] = useState(0);
  const [ChannelSubSourceMobileNumber, setChannelSubSourceMobileNumber] = useState('');
  const [ChannelSubSourceAltMobileNumber, setChannelSubSourceAltMobileNumber] = useState('');
  const [ChannelSubSourceEmail, setChannelSubSourceEmail] = useState('');
  const [ChannelSubSourceAltEmail, setChannelSubSourceAltEmail] = useState('');
  const [ChannelSubSourceVisitingCard, setChannelSubSourceVisitingCard] = useState('');
  const [ChannelSubSourceDateOfBirth, setChannelSubSourceDateOfBirth] = useState('');
  const [ChannelSubSourceDateOfAnniversary, setChannelSubSourceDateOfAnniversary] = useState('');
  const [ChannelSubSourceFirstMeetingBrief, setChannelSubSourceFirstMeetingBrief] = useState('');
  const [ChannelSubSourceAddress, setChannelSubSourceAddress] = useState('');
  const [ChannelSubSourceCountryId, setChannelSubSourceCountryId] = useState("0");
  const [ChannelSubSourceStateId, setChannelSubSourceStateId] = useState("0");
  const [ChannelSubSourceDistrictId, setChannelSubSourceDistrictId] = useState("0");
  const [ChannelSubSourceTehsilId, setChannelSubSourceTehsilId] = useState("0");
  const [ChannelSubSourceCityId, setChannelSubSourceCityId] = useState("0");
  const [ChannelSubSourcePincode, setChannelSubSourcePincode] = useState("");


  const [ChannelSubSourceDepartmentList, setChannelSubSourceDepartmentList] = useState([]);
  const [ChannelSubSourceDesignationList, setChannelSubSourceDesignationList] = useState([]);
  const [ChannelSubSourceCountryList, setChannelSubSourceCountryList] = useState([]);
  const [ChannelSubSourceStateList, setChannelSubSourceStateList] = useState([]);
  const [ChannelSubSourceDistrictList, setChannelSubSourceDistrictList] = useState([]);
  const [ChannelSubSourceCityList, setChannelSubSourceCityList] = useState([]);
  const [ChannelSubSourceTehsilList, setChannelSubSourceTehsilList] = useState([]);

  const getChannelSubSourceDepartmentList = async () => {
    fetch(global.APIURL + "/DepartmentActiveList")
      .then((res) => res.json())
      .then((json) => {
        setChannelSubSourceDepartmentList(json)
      })
  }

  const getChannelSubSourceDesignation = async () => {
    fetch(global.APIURL + "/DesignationActiveList")
      .then((res) => res.json())
      .then((json) => {
        setChannelSubSourceDesignationList(json)

      })
  }

  const getChannelSubSourceCountryList = async () => {
    fetch(global.APIURL + "/CountryActiveList")
      .then((res) => res.json())
      .then((json) => {
        setChannelSubSourceCountryList(json)
      })
  }

  const getChannelSubSourceStateList = async CId => {
    var AUrl = global.APIURL + '/ActiveState/' + CId;
    axios.get(AUrl)
      .then(Sres => {
        let StateL = Sres.data.map(kcstate => {
          return kcstate;
        });
        setChannelSubSourceStateList(StateL);
      });
  };

  const getChannelSubSourceDistrictList = async SId => {
    var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
    axios.get(AUrl)
      .then(Dres => {
        let DistrictL = Dres.data.map(kcdistrict => {
          return kcdistrict;
        });
        setChannelSubSourceDistrictList(DistrictL);
      });

  };

  const getChannelSubSourceTehsilList = async DId => {
    var AUrl = global.APIURL + '/ActiveTehsil/' + DId;
    axios.get(AUrl)
      .then(Dres => {
        let TehsilL = Dres.data.map(kctehsil => {
          return kctehsil;
        });
        setChannelSubSourceTehsilList(TehsilL);
      });
  };

  const getChannelSubSourceCityList = async TId => {
    var AUrlC = global.APIURL + '/ActiveCity/' + TId;
    axios.get(AUrlC)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setChannelSubSourceCityList(CityL);
      });
  };

  function ChannelSubSourceVisitingCardfetchPost(e) {
    var details = e.target.files[0];
    var details1 = "ChannelSubSourceVisitingCard";
    const formData = new FormData();
    formData.append('Document', details);
    formData.append('FolderName', details1);
    var AUrl = global.APIURL + '/uploadavatar/';
    axios.post(AUrl, formData,
      {
        headers: { "Content-Type": details.type },
      })
      .then((res) => {
        setChannelSubSourceVisitingCard(res.data.IName);
      });
  }

  function ChannelSubSourceLogofetchPost(e) {
    var details = e.target.files[0];
    var details1 = "ChannelSubSourceLogo";
    const formData = new FormData();
    formData.append('Document', details);
    formData.append('FolderName', details1);
    var AUrl = global.APIURL + '/uploadavatar/';
    axios.post(AUrl, formData,
      {
        headers: { "Content-Type": details.type },
      })
      .then((res) => {
        setChannelSubSourceLog(res.data.IName);
      });
  }

  const ChannelSubSourceDetailSave = async (e) => {
    e.preventDefault();

    const ChannelSubSourceArr = {
      ChannelPartnerId: "0",
      ChannelId: ChannelId,
      Name: ChannelSubSourceName,
      ChannelSubSourceLogo: ChannelSubSourceLog,
      DesignationId: ChannelSubSourceDesignationId,
      DepartmentId: ChannelSubSourceDepartmentId,
      MobileNumber: ChannelSubSourceMobileNumber,
      AlternetMobileNumber: ChannelSubSourceAltMobileNumber,
      Email: ChannelSubSourceEmail,
      AlternetEmail: ChannelSubSourceAltEmail,
      VistingCard: ChannelSubSourceVisitingCard,
      DateOfBirth: ChannelSubSourceDateOfBirth,
      DateOfAnniversary: ChannelSubSourceDateOfAnniversary,
      FirstMeetingBrief: ChannelSubSourceFirstMeetingBrief,
      CurrentAddress: ChannelSubSourceAddress,
      CountryId: ChannelSubSourceCountryId,
      StateId: ChannelSubSourceStateId,
      CityId: ChannelSubSourceDistrictId,
      DistrictId: ChannelSubSourceTehsilId,
      TehsilId: ChannelSubSourceCityId,
      Pincode: ChannelSubSourcePincode,
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
    };
    axios.post(global.APIURL + "/ChannelSubSourceInsert", ChannelSubSourceArr)
      .then(res => {
        if (res.data.length > 0) {
          alert("Add new channel sub source save successfully.");
          getModelLeadChannelPartnerList(ChannelId);
          document.getElementById("FUCSSLogo").value = '';
          document.getElementById("txtCSSName").value = '';
          document.getElementById("ddlCSSDesignation").value = 0;
          document.getElementById("ddlCSSDepartment").value = 0;
          document.getElementById("txtCSSMobileNumber").value = '';
          document.getElementById("txtCSSAlternatemobileNumber").value = '';
          document.getElementById("txtCSSEmail").value = '';
          document.getElementById("txtCSSAlternateEmail").value = '';
          document.getElementById("FUCSSVisitingCard").value = '';
          document.getElementById("txtCSSBirth").value = '';
          document.getElementById("txtCSSAnniversary").value = '';
          document.getElementById("txtCSSFirst").value = '';
          document.getElementById("ddlCSSCountry").value = 0;
          document.getElementById("ddlCSSState").value = 0;
          document.getElementById("ddlCSSDistrict").value = 0;
          document.getElementById("ddlCSSTehsil").value = 0;
          document.getElementById("ddlCSSCity").value = 0;
          document.getElementById("txtCSSPincode").value = '';
        }
      });
  }

  const ChannelSubSourceDetailClose = async (e) => {
    e.preventDefault();
    document.getElementById("FUCSSLogo").value = '';
    document.getElementById("txtCSSName").value = '';
    document.getElementById("ddlCSSDesignation").value = 0;
    document.getElementById("ddlCSSDepartment").value = 0;
    document.getElementById("txtCSSMobileNumber").value = '';
    document.getElementById("txtCSSAlternatemobileNumber").value = '';
    document.getElementById("txtCSSEmail").value = '';
    document.getElementById("txtCSSAlternateEmail").value = '';
    document.getElementById("FUCSSVisitingCard").value = '';
    document.getElementById("txtCSSBirth").value = '';
    document.getElementById("txtCSSAnniversary").value = '';
    document.getElementById("txtCSSFirst").value = '';
    document.getElementById("ddlCSSCountry").value = 0;
    document.getElementById("ddlCSSState").value = 0;
    document.getElementById("ddlCSSDistrict").value = 0;
    document.getElementById("ddlCSSTehsil").value = 0;
    document.getElementById("ddlCSSCity").value = 0;
    document.getElementById("txtCSSPincode").value = '';
  }

  const getChannelMasterList = async (CTId) => {
    var AUrl = global.APIURL + '/ChannelMasterActiveList/' + CTId;
    fetch(AUrl)
      .then((res) => res.json())
      .then((json) => {
        setChannelMasterList(json)
      })
  }

  const getChannelMasterDetail = async (CMId) => {
    var AUrl = global.APIURL + '/ChannelMasterEdit/' + CMId;
    axios.get(AUrl)
      .then(res => {
        setChannelLogo(res.data[0].ChannelLogo);
      })
  }

  const PerTehsilDetailSave = async (e) => {
    e.preventDefault();
    var VTehsilTitle = document.getElementById("txtPerTehsil").value;
    if (VTehsilTitle != '') {
      const TehsilArr = {
        TehsilId: '0',
        CountryId: 1,
        StateId: document.getElementById("ddlPerState").value,
        DistrictId: document.getElementById("ddlPerDistrictId").value,
        Title: VTehsilTitle,
        CreateUser: "1"
      }

      axios.post(global.APIURL + '/Tehsil', TehsilArr)
        .then(res => {
          alert(res.data);
          var DistrictId = document.getElementById("ddlPerDistrictId").value;
          getTehsilList(DistrictId);
        });
    }
    document.getElementById("txtPerTehsil").value = '';
  }

  const PerTehsilClose = async (e) => {
    e.preventDefault();
    document.getElementById("txtPerTehsil").value = '';
  }

  const PerCityDetailSave = async (e) => {
    e.preventDefault();
    var VCityTitle = document.getElementById("txtPerCity").value;
    if (VCityTitle != '') {
      const CityArr = {
        CountryId: 1,
        StateId: document.getElementById("ddlPerState").value,
        DistrictId: document.getElementById("ddlPerDistrictId").value,
        TehsilId: document.getElementById("ddlPerTehsil").value,
        CityId: '0',
        Title: VCityTitle,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      }
      axios.post(global.APIURL + '/City', CityArr)
        .then(res => {
          alert(res.data.StatusMessage);
          var VTehsilId = document.getElementById("ddlPerTehsil").value;
          getCityList(VTehsilId)
        });
    }
    document.getElementById("txtPerCity").value = '';
  }

  const PerCityClose = async (e) => {
    e.preventDefault();
    document.getElementById("txtPerCity").value = '';
  }

  const ChannelTehsilDetailSave = async (e) => {
    e.preventDefault();
    var VTehsilTitle = document.getElementById("txtChannelTehsil").value;
    if (VTehsilTitle != '') {
      const TehsilArr = {
        TehsilId: '0',
        CountryId: document.getElementById("ddlChannelCountry").value,
        StateId: document.getElementById("ddlChannelState").value,
        DistrictId: document.getElementById("ddlChannelDistrict").value,
        Title: VTehsilTitle,
        CreateUser: "1"
      }

      axios.post(global.APIURL + '/Tehsil', TehsilArr)
        .then(res => {
          alert(res.data);
          var VDistrictId = document.getElementById("ddlChannelDistrict").value;
          getChannelTehsilList(VDistrictId);
        });
    }
    document.getElementById("txtChannelTehsil").value = '';
  }

  const ChannelTehsilClose = async (e) => {
    e.preventDefault();
    document.getElementById("txtChannelTehsil").value = '';
  }

  const ChannelCityDetailSave = async (e) => {
    e.preventDefault();
    var VCityTitle = document.getElementById("txtChannelCity").value;
    if (VCityTitle != '') {
      const CityArr = {
        CountryId: document.getElementById("ddlChannelCountry").value,
        StateId: document.getElementById("ddlChannelState").value,
        DistrictId: document.getElementById("ddlChannelDistrict").value,
        TehsilId: document.getElementById("ddlChannelTehsil").value,
        CityId: '0',
        Title: VCityTitle,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      }
      axios.post(global.APIURL + '/City', CityArr)
        .then(res => {
          alert(res.data.StatusMessage);
          var VTehsilId = document.getElementById("ddlChannelTehsil").value;
          getChannelCityList(VTehsilId)
        });
    }
    document.getElementById("txtChannelCity").value = '';
  }

  const ChannelCityClose = async (e) => {
    e.preventDefault();
    document.getElementById("txtChannelCity").value = '';
  }

  const ChannelSubSourceTehsilDetailSave = async (e) => {
    e.preventDefault();
    var VTehsilTitle = document.getElementById("txtChannelSubSourceTehsil").value;
    if (VTehsilTitle != '') {
      const TehsilArr = {
        TehsilId: '0',
        CountryId: document.getElementById("ddlCSSCountry").value,
        StateId: document.getElementById("ddlCSSState").value,
        DistrictId: document.getElementById("ddlCSSDistrict").value,
        Title: VTehsilTitle,
        CreateUser: "1"
      }

      axios.post(global.APIURL + '/Tehsil', TehsilArr)
        .then(res => {
          alert(res.data);
          var VDistrictId = document.getElementById("ddlCSSDistrict").value;
          getChannelSubSourceTehsilList(VDistrictId);
        });
    }
    document.getElementById("txtChannelSubSourceTehsil").value = '';
  }

  const ChannelSubSourceTehsilClose = async (e) => {
    e.preventDefault();
    document.getElementById("txtChannelSubSourceTehsil").value = '';
  }

  const ChannelSubSourceCityDetailSave = async (e) => {
    e.preventDefault();
    var VCityTitle = document.getElementById("txtChannelSubSourceCity").value;
    if (VCityTitle != '') {
      const CityArr = {
        CountryId: document.getElementById("ddlCSSCountry").value,
        StateId: document.getElementById("ddlCSSState").value,
        DistrictId: document.getElementById("ddlCSSDistrict").value,
        TehsilId: document.getElementById("ddlCSSTehsil").value,
        CityId: '0',
        Title: VCityTitle,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      }
      axios.post(global.APIURL + '/City', CityArr)
        .then(res => {
          alert(res.data.StatusMessage);
          var VTehsilId = document.getElementById("ddlCSSTehsil").value;
          getChannelSubSourceCityList(VTehsilId)
        });
    }
    document.getElementById("txtChannelSubSourceCity").value = '';
  }

  const ChannelSubSourceCityClose = async (e) => {
    e.preventDefault();
    document.getElementById("txtChannelSubSourceCity").value = '';
  }

  const KYCDocOnChange = e => {
    var checkKYCDocNo = document.getElementById("txtKYCDocNo").value;
    if (DocumentTypeId == 10) {
      if (checkKYCDocNo.length > 10) {

        alert("Length should be 10 digit");
        document.getElementById("txtKYCDocNo").value = ''
      }
      else { KYCDocNo = checkKYCDocNo }
    }
    else if (DocumentTypeId == 17) {
      if (checkKYCDocNo.length > 12) {

        alert("Length should be 12 digit");
        document.getElementById("txtKYCDocNo").value = ''
      }
      else { KYCDocNo = checkKYCDocNo }
    }
  };

  return (
    <React.Fragment>
      <div className="ind-card" id="ind-card">
        <div className="modal fade" id="banking" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Bank List</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="formFileSm" className="form-label">Channel Type</label>
                      <select id="ddlChannelType" className="form-select" aria-label="Default select example" onChange={e => { getModelLeadChannelList(); getDropdownChannelMasterList(); getDropdownCityList(); getDropdownBranchList() }}>
                        <option selected value="0">Select Channel Type</option>
                        {DropdownChannelTypeList.map(item => (
                          <option key={item.ChannelTypeId} value={item.ChannelTypeId}>
                            {item.ChannelTypeName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="formFileSm" className="form-label">Channel Master</label>
                      <select id="ddlChannel" className="form-select" aria-label="Default select example" onChange={e => { getModelLeadChannelList(); getDropdownCityList(); getDropdownBranchList() }}>
                        <option selected value="0">Select Channel</option>
                        {DropdownChannelList.map(item => (
                          <option key={item.ChannelMasterId} value={item.ChannelMasterId}>
                            {item.Title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="formFileSm" className="form-label">City</label>
                      <select id="ddlCity" className="form-select" aria-label="Default select example" onChange={e => { getModelLeadChannelList(); getDropdownBranchList() }}>
                        <option selected value="0">Select City</option>
                        {DropdownCityList.map(item => (
                          <option key={item.CityId} value={item.CityId}>
                            {item.Title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="formFileSm" className="form-label">Branch</label>
                      <select id="ddlBranch" className="form-select" aria-label="Default select example" onChange={e => getModelLeadChannelList()}>
                        <option selected value="0">Select Branch</option>
                        {DropdownChannelBranchList.map(item => (
                          <option key={item.BranchId} value={item.BranchId}>
                            {item.BranchName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-12">

                    <div className="d-flex justify-content-between">
                      <div>
                        <label htmlFor="formFileSm" className="form-label">
                          Result
                        </label>
                      </div>
                      <div>
                        <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddNewBank">+ Add New</a></span>
                      </div>
                    </div>
                    <hr />
                    {ModelChannelList.map(item => (
                      <div className="team-modal-box mb-2 mt-2" key={item.ChannelId}>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center border-bottom pb-2">
                            <div className="flex-shrink-0">
                              <div className="avatar-md">
                                <img
                                  src={item.ChannelImage}
                                  alt=""
                                  className="img-thumbnail"
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="font-14 dark-font mb-1">
                                {item.ChannelName}
                              </div>
                              <div className="mb-1">
                                <a href="" className="table-link-a font-12">
                                  Channel : {item.ChannelMaster}
                                </a>
                              </div>
                              <div className="mb-1">
                                <a href="" className="table-link-a font-12">
                                  Type : {item.ChannelTypeName}
                                </a>
                              </div>
                              <div className="mb-1">
                                <a href="" className="table-link-a font-12">
                                  Branch : {item.BranchName} |  IFSE Code : {item.BranchIFSCCOde}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              id="1"
                              className="form-check-input"
                              name="ChannelList"
                              value={item.ChannelId}
                              data-bs-dismiss="modal" aria-label="Close"
                              onChange={() => GetSetChannelId(parseInt(item.ChannelId == null ? 0 : item.ChannelId == '' ? 0 : item.ChannelId))}
                            />
                          </div>
                        </div>

                        <div className="pt-2">
                          <span className="badge badge-soft-primary p-2 me-2">
                            {item.BranchStateName}
                          </span>
                          <span className="badge badge-soft-primary p-2 me-2">
                            {item.BranchDistrictName}
                          </span>
                          <span className="badge badge-soft-primary p-2 me-2">
                            {item.BranchCityName}
                          </span>
                          <span className="badge badge-soft-primary p-2 me-2">
                            {item.BranchTehsilName}
                          </span>
                          <span className="badge badge-soft-primary p-2 me-2">
                            {item.BranchPincode}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="channel" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Channel Partner List
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="d-flex justify-content-between">
                  <div>
                    <label htmlFor="formFileSm" className="form-label">
                      Result
                    </label>
                  </div>
                  <div>
                    {ChannelId > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddNewChannelPartner">+ Add New</a></span> : ''}
                  </div>
                </div>
                <Row>
                  {ChannelPartnerList.map(item => (
                    <div className="col-lg-12 mb-3" key={item.ChannelPartnerId}>
                      <div className="team-modal-box">

                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center border-bottom pb-2">
                            <div className="flex-shrink-0">
                              <div className="avatar-md">
                                <img
                                  src={item.ChannelPartnerLogo}
                                  alt=""
                                  className="img-thumbnail"
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="font-14 dark-font mb-1">
                                {item.Name}
                              </div>
                              <div className="mb-1">
                                <a href="" className="table-link-a font-12">
                                  {item.Designation}
                                </a>
                              </div>
                              <div className="">
                                <a href="" className="table-link-a font-12">
                                  {item.ChannelName}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              id="1"
                              className="form-check-input"
                              name="ChannelPartnerList"
                              value={item.ChannelPartnerId}
                              data-bs-dismiss="modal" aria-label="Close"
                              onChange={() => GetSetChannelPartnerId(item.ChannelPartnerId)}
                            />
                          </div>

                        </div>
                        <div className="modal-sub-box pt-2">
                          <div className="department-box">
                            <a className="mb-0 text-success">
                              <i className="fas fa-phone-alt me-1"></i>
                              {item.MobileNumber}
                            </a>
                          </div>
                          <div className="emp-code">
                            <a className="mb-0 text-primary">
                              <i className="fas fa-envelope me-1"></i>
                              {item.Email}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Row>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn_success">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn_danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-lg" id="AddNewBank" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add New Channel Source</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ChannelDetailClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>
                  <Col lg={4} className="mt-2"></Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-3 labeledit text-center">
                      <div>
                        <label htmlFor="uploadimage" className="form-label">
                          Upload Image
                        </label>
                      </div>
                      <img id="IGProfileImage" src={ChannelLogo} className="channellogoimage"></img>
                      <input type="file" id="fuChannelLogo" className="form-control form-control mt-3" onChange={ChannelLogofetchPost} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2"></Col>
                  <Col lg={6} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Channel Type</label>
                      <select className="form-select" Id="ddlChanneltype" name="ddlChanneltype" onChange={(e) => { setChannelTypeId(e.target.value); getChannelMasterList(e.target.value) }}>
                        <option value={"0"}>Select Channel Type</option>
                        {ChannelTypeList.map((ChannelType) => (
                          <option key={ChannelType.ChannelTypeId} value={ChannelType.ChannelTypeId}> {ChannelType.ChannelTypeName} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={6} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Channel Master</label>
                      <select className="form-select" Id="ddlChannelMaster" name="ddlChannelMaster" onChange={(e) => { getChannelMasterDetail(e.target.value); setChannelMasterId(e.target.value) }}>
                        <option value={"0"}>Select Channel Master</option>
                        {ChannelMasterList.map((ChannelMaster) => (
                          <option key={ChannelMaster.ChannelMasterId} value={ChannelMaster.ChannelMasterId}> {ChannelMaster.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Channel Source</label>
                      <Input type="text" className="form-control" id="txtChannelName" placeholder="Enter Channel Source" onChange={(e) => setChannelName(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Branch Name</label>
                      <Input type="text" className="form-control" id="txtBranchName" placeholder="Enter BranchName" onChange={(e) => setBranchName(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label"> Branch Code</label>
                      <Input type="text" className="form-control" id="txtBranchCode" placeholder="Enter BranchCode" onChange={(e) => setBranchCode(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label"> IFSC Code</label>
                      <Input type="text" className="form-control" id="txtIFSC" placeholder="Enter IFSC" onChange={(e) => setIFSCCode(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label"> Branch Contact No</label>
                      <Input type="text" className="form-control" id="txtContactNo" placeholder="Enter ContactNo" maxlength="10" onChange={(e) => setBranchContactNo(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label"> Branch Email Address</label>
                      <Input type="text" className="form-control" id="txtEmail" placeholder="Enter Email" onChange={(e) => setBranchEmailAddress(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label"> Branch Location</label>
                      <Input type="text" className="form-control" id="txtBranchLocation" placeholder="Enter Branch Location" onChange={(e) => setBranchLocation(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-2">
                      <label className="form-label">Address</label>
                      <Input type="textarea" className="form-control" id="txtAddress" placeholder="Enter Address" onChange={(e) => setBranchAddress(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Country</label>
                      <select className="form-select" Id="ddlChannelCountry" onChange={(e) => { getChannelStateList(e.target.value); setBranchCountryId(e.target.value) }}>
                        <option value={"0"}>Select Country</option>
                        {ChannelCountryList.map((country) => (
                          <option key={country.CountryId} value={country.CountryId}> {country.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">State</label>
                      <select className="form-select" Id="ddlChannelState" onChange={(e) => { getChannelDistrictList(e.target.value); setBranchStateId(e.target.value) }}>
                        <option value={"0"}>Select State</option>
                        {ChannelStateList.map((state) => (
                          <option key={state.StateId} value={state.StateId}> {state.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">District</label>
                      <select className="form-select" Id="ddlChannelDistrict" onChange={(e) => { getChannelTehsilList(e.target.value); setBranchDistrictId(e.target.value) }}>
                        <option value={"0"}>Select District</option>
                        {ChannelDistrictList.map((district) => (
                          <option key={district.DistrictId} value={district.DistrictId}> {district.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label htmlFor="formFileSm" className="form-label">Tehsil</label>
                        </div>
                        <div>
                          {AddTehsil == "A" ? parseInt(BranchDistrictId) > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddChannelTehsil">+ Add New</a></span> : '' : ''}
                        </div>
                      </div>
                      <select className="form-select" Id="ddlChannelTehsil" onChange={(e) => { getChannelCityList(e.target.value); setBranchTehsilId(e.target.value) }}>
                        <option value={"0"}>Select Tehsil</option>
                        {ChannelTehsilList.map((Tehsil) => (
                          <option key={Tehsil.TehsilId} value={Tehsil.TehsilId}> {Tehsil.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label htmlFor="formFileSm" className="form-label">City/Village/Town</label>
                        </div>
                        <div>
                          {AddCity == "A" ? parseInt(BranchTehsilId) > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddChannelCity">+ Add New</a></span> : '' : ''}
                        </div>
                      </div>
                      <select className="form-select" Id="ddlChannelCity" onChange={(e) => setBranchCityId(e.target.value)}>
                        <option value={"0"}>Select City/Village/Town</option>
                        {ChannelCityList.map((city) => (
                          <option key={city.CityId} value={city.CityId}> {city.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Pincode</label>
                      <Input type="text" className="form-control" id="txtChannelPincode" placeholder="Enter Pincode" maxlength="6" onChange={(e) => setBranchPincode(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={ChannelDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={ChannelDetailClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-lg" id="AddNewChannelPartner" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add New Channel Sub Source</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ChannelDetailClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Logo / Profile Image</label>
                      <input type="file" className="form-control form-control" id="FUCSSLogo" name="file" onChange={ChannelSubSourceLogofetchPost} />
                    </div>
                  </Col>
                  <Col lg={8} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Name</label>
                      <Input type="text" className="form-control" id="txtCSSName" placeholder="Enter Name" onChange={(e) => setChannelSubSourceName(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Designation</label>
                      <select className="form-select" Id="ddlCSSDesignation" name="ddlCSSDesignation" onChange={(e) => setChannelSubSourceDesignationId(e.target.value)}>
                        <option value={"0"}>Select Designation</option>
                        {ChannelSubSourceDesignationList.map((di) => (
                          <option key={di.Id} value={di.Id}> {di.Name} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Department</label>
                      <select className="form-select" Id="ddlCSSDepartment" name="ddlCSSDepartment" onChange={(e) => setChannelSubSourceDepartmentId(e.target.value)}>
                        <option value={"0"}>Select Department</option>
                        {ChannelSubSourceDepartmentList.map((d) => (
                          <option key={d.Id} value={d.Id}> {d.Name} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Mobile Number</label>
                      <Input type="text" className="form-control" id="txtCSSMobileNumber" maxlength="10" placeholder="Enter Mobile Number" onChange={(e) => setChannelSubSourceMobileNumber(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Alternate Mobile Number</label>
                      <Input type="text" className="form-control" id="txtCSSAlternatemobileNumber" maxlength="10" placeholder="Enter Alternate Mobile Number" onChange={(e) => setChannelSubSourceAltMobileNumber(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Email</label>
                      <Input type="text" className="form-control" id="txtCSSEmail" placeholder="Enter Email" onChange={(e) => setChannelSubSourceEmail(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label"> Alternate Email</label>
                      <Input type="text" className="form-control" id="txtCSSAlternateEmail" placeholder="Enter Alternate Email" onChange={(e) => setChannelSubSourceAltEmail(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-3 labeledit">
                      <label htmlFor="uploadimage" className="form-label">
                        Visiting Card
                      </label>
                      <input type="file" className="form-control form-control" id="FUCSSVisitingCard" name="file" onChange={ChannelSubSourceVisitingCardfetchPost} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label"> Date Of Birth</label>
                      <Input type="date" className="form-control" id="txtCSSBirth" onChange={(e) => setChannelSubSourceDateOfBirth(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label"> Date Of Anniversary</label>
                      <Input type="date" className="form-control" id="txtCSSAnniversary" onChange={(e) => setChannelSubSourceDateOfAnniversary(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={6} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">First Meeting Brief </label>
                      <textarea type="text" rows="1" className="form-control" placeholder="First Meeting Brief" id="txtCSSFirst" onChange={(e) => setChannelSubSourceFirstMeetingBrief(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={6} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Current Address</label>
                      <textarea type="text" rows="1" className="form-control" id="txtCSSAddress" placeholder="Enter CAddress" onChange={(e) => setChannelSubSourceAddress(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Country</label>
                      <select className="form-select" Id="ddlCSSCountry" name="ddlCSSCountry" onChange={(e) => { getChannelSubSourceStateList(e.target.value); setChannelSubSourceCountryId(e.target.value) }}>
                        <option value={"0"}>Select Country</option>
                        {ChannelSubSourceCountryList.map((country) => (
                          <option key={country.CountryId} value={country.CountryId}> {country.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">State</label>
                      <select className="form-select" Id="ddlCSSState" name="ddlCSSState" onChange={(e) => { getChannelSubSourceDistrictList(e.target.value); setChannelSubSourceStateId(e.target.value) }}>
                        <option value={"0"}>Select State</option>
                        {ChannelSubSourceStateList.map((state) => (
                          <option key={state.StateId} value={state.StateId}> {state.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">District</label>
                      <select className="form-select" Id="ddlCSSDistrict" name="ddlCSSDistrict" onChange={(e) => { getChannelSubSourceTehsilList(e.target.value); setChannelSubSourceDistrictId(e.target.value) }}>
                        <option value={"0"}>Select District</option>
                        {ChannelSubSourceDistrictList.map((district) => (
                          <option key={district.DistrictId} value={district.DistrictId}> {district.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label htmlFor="formFileSm" className="form-label">Tehsil</label>
                        </div>
                        <div>
                          {AddTehsil == "A" ? parseInt(ChannelSubSourceDistrictId) > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddChannelSubSourceTehsil">+ Add New</a></span> : '' : ''}
                        </div>
                      </div>
                      <select className="form-select" Id="ddlCSSTehsil" name="ddlCSSTehsil" onChange={(e) => { getChannelSubSourceCityList(e.target.value); setChannelSubSourceTehsilId(e.target.value) }}>
                        <option value={"0"}>Select Tehsil</option>
                        {ChannelSubSourceTehsilList.map((Tehsil) => (
                          <option key={Tehsil.TehsilId} value={Tehsil.TehsilId}> {Tehsil.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label htmlFor="formFileSm" className="form-label">City/Village/Town</label>
                        </div>
                        <div>
                          {AddCity == "A" ? parseInt(ChannelSubSourceTehsilId) > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddChannelSubSourceCity">+ Add New</a></span> : '' : ''}
                        </div>
                      </div>
                      <select className="form-select" Id="ddlCSSCity" name="ddlCSSCity" onChange={(e) => setChannelSubSourceCityId(e.target.value)}>
                        <option value={"0"}>Select City/Village/Town</option>
                        {ChannelSubSourceCityList.map((city) => (
                          <option key={city.CityId} value={city.CityId}> {city.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Pincode</label>
                      <Input type="text" className="form-control" id="txtCSSPincode" placeholder="Enter Pincode" maxlength="6" onChange={(e) => setChannelSubSourcePincode(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={ChannelSubSourceDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={ChannelSubSourceDetailClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-sm" id="AddPerTehsil" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add Tehsil</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={PerTehsilClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>

                  <Col lg={12} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Tehsil</label>
                      <Input type="text" className="form-control" id="txtPerTehsil" placeholder="Enter Tehsil" />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={PerTehsilDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={PerTehsilClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-sm" id="AddPerCity" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add City</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={PerCityClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>

                  <Col lg={12} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">City</label>
                      <Input type="text" className="form-control" id="txtPerCity" placeholder="Enter City" />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={PerCityDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={PerCityClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-sm" id="AddChannelTehsil" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add Tehsil</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ChannelTehsilClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>

                  <Col lg={12} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Tehsil</label>
                      <Input type="text" className="form-control" id="txtChannelTehsil" placeholder="Enter Tehsil" />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={ChannelTehsilDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={ChannelTehsilClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-sm" id="AddChannelCity" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add City</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ChannelCityClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>

                  <Col lg={12} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">City</label>
                      <Input type="text" className="form-control" id="txtChannelCity" placeholder="Enter City" />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={ChannelCityDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={ChannelCityClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-sm" id="AddChannelSubSourceTehsil" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add Tehsil</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ChannelSubSourceTehsilClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>

                  <Col lg={12} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Tehsil</label>
                      <Input type="text" className="form-control" id="txtChannelSubSourceTehsil" placeholder="Enter Tehsil" />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={ChannelSubSourceTehsilDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={ChannelSubSourceTehsilClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-sm" id="AddChannelSubSourceCity" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add City</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ChannelSubSourceCityClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>

                  <Col lg={12} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">City</label>
                      <Input type="text" className="form-control" id="txtChannelSubSourceCity" placeholder="Enter City" />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={ChannelSubSourceCityDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={ChannelSubSourceCityClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <Card>
          <CardBody>
            <form id="form1">
              <Row className="pb-4">
                <Col className="col-lg-12 mb-2">
                  <CardTitle>Personal Details</CardTitle>
                  <hr />
                </Col>
                <Col className="col-lg-2 col-md-6">
                  <div className="mb-3 labeledit text-center">
                    <div>
                      <label htmlFor="uploadimage" className="form-label">
                        Upload Image
                      </label>
                    </div>
                    <img id="IGProfileImage" src={ProfileImage} className="personaldetailsimage"></img>
                    <input type="file" id="fuProfileImage" className="form-control form-control mt-3" name="fuProfileImage" onChange={ProfilefetchPost} />
                  </div>
                </Col>

                <Col className="col-lg-10 col-md-6">
                  <Row>
                    <Col className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">First Name</label>
                        <input
                          type="text"
                          placeholder="First Name"
                          className="form-control"
                          id="txtFirstName"
                          name="txtFirstName"
                          onChange={e => setFirstName(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Middle Name</label>
                        <input
                          type="text"
                          placeholder="Middle Name"
                          className="form-control"
                          id="txtMiddleName"
                          name="txtMiddleName"
                          onChange={e => setMiddleName(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col className="col-lg-4 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Last Name</label>
                        <input
                          type="text"
                          placeholder="Last Name"
                          className="form-control"
                          id="txtLastName"
                          name="txtLastName"
                          onChange={e => setLastName(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col className="col-lg-3 col-md-6">
                      <div className="mb-3 mobile-mt">
                        <label className="form-label">Relationship</label>
                        <select className="form-select" id="ddlRelationship" aria-label="Default select example" onChange={e => setRelationship(e.target.value)}>
                          <option selected>Select Relationship</option>
                          <option value="S/O">Son of</option>
                          <option value="D/O">Daughter of</option>
                          <option value="W/O">Wife of</option>
                        </select>
                      </div>
                    </Col>
                    <Col className="col-lg-5 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Relationship Name</label>
                        <input
                          type="text"
                          placeholder="Relationship Name"
                          className="form-control"
                          id="txtRelationshipName"
                          name="txtRelationshipName"
                          onChange={e => setRelationshipName(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col className="col-lg-2 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Date Of Birth</label>
                        <input
                          type="Date"
                          placeholder="Date Of Birth"
                          className="form-control"
                          id="txtDateOfBirth"
                          name="txtDateOfBirth"
                          onChange={e => setDateOfBirth(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col className="col-lg-2 col-md-6">
                      <div className="mb-3 mobile-mt">
                        <label className="form-label">Gender</label>
                        <select className="form-select" aria-label="Default select example" onChange={e => setGender(e.target.value)}>
                          <option selected>Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </Col>

                    <Col className="col-lg-3">
                      <p className="mb-2 fontWeight500"> Mobile Number </p>
                      <Input
                        type="text"
                        className=""
                        id="txtMobileNumber"
                        maxlength="10"
                        onChange={e => setPMobileNumber(e.target.value)}
                        placeholder="Enter Mobile Number"
                      />
                    </Col>
                    <Col className="col-lg-4">
                      <p className="mb-2 fontWeight500"> Email Address </p>
                      <Input
                        type="text"
                        className=""
                        id="txtEmailAddress"
                        onChange={e => setPEmailAddress(e.target.value)}
                        placeholder="Enter Email Address"
                      />
                    </Col>


                    <div className="col-lg-2 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">CIR ID</label>
                        <input
                          type="text"
                          placeholder="CIR ID"
                          className="form-control"
                          id="txtCIRID"
                          name="txtCIRID"
                          onChange={e => setCIRID(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-6">
                      <div className="mb-3">
                        <label className="form-label">CIR Password</label>
                        <div className="input-group auth-pass-inputgroup">
                          <input id="txtCIRPassword" type="password" className="form-control" placeholder="Enter CIR Password" aria-label="Password" aria-describedby="password-addon" onChange={e => setCIRPassword(e.target.value)} />
                          <button className="btn btn-light " type="button" id="password-addon" onClick={e => PasswordInputChange(e, "txtCIRPassword")}><i id="iFPassword" className="mdi mdi-eye-outline"></i></button>
                        </div>

                      </div>
                    </div>
                  </Row>
                </Col>





                <div className="col-lg-12">
                  <label htmlFor="Address" className="form-label">
                    Address
                  </label>
                  <div className="mb-3">
                    <textarea
                      className="form-control"
                      placeholder="Address"
                      id="Remarks"
                      rows="3"
                      name="Address"
                      value={Address}
                      onChange={e => setAddress(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="mb-3">
                    <label htmlFor="State" className="form-label">
                      State
                    </label>
                    <select className="form-select" Id="ddlPerState" name="ddlPerState" onChange={e => { setStateId(e.target.value); getDistrictList(e.target.value) }}>
                      <option value={"0"}>Select State</option>
                      {StateList.map(state => (
                        <option key={state.StateId} value={state.StateId}>
                          {" "}
                          {state.Title}{" "}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="mb-3">
                    <label htmlFor="District" className="form-label">
                      District
                    </label>
                    <select className="form-select" Id="ddlPerDistrictId" name="ddlPerDistrictId" onChange={e => { setDistrictId(e.target.value); getTehsilList(e.target.value) }}>
                      <option value={"0"}>Select District</option>
                      {DistrictList.map(district => (
                        <option key={district.DistrictId} value={district.DistrictId}>
                          {district.Title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <label htmlFor="formFileSm" className="form-label">Tehsil</label>
                      </div>
                      <div>
                        {AddTehsil == "A" ? DistrictId > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddPerTehsil">+ Add New</a></span> : '' : ''}
                      </div>
                    </div>
                    <select className="form-select" id="ddlPerTehsil" name="ddlPerTehsil" onChange={e => { setTehsilId(e.target.value); getCityList(e.target.value) }}>
                      <option value={"0"}>Select Tehsil</option>
                      {TehsilList.map(tehsil => (
                        <option key={tehsil.TehsilId} value={tehsil.TehsilId}>
                          {tehsil.Title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <label htmlFor="formFileSm" className="form-label">City/Village/Town</label>
                      </div>
                      <div>
                        {AddCity == "A" ? TehsilId > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddPerCity">+ Add New</a></span> : '' : ''}
                      </div>
                    </div>
                    <select className="form-select" id="ddlPerCity" name="ddlPerCity" onChange={e => setCityId(e.target.value)}>
                      <option value={"0"}>Select City/Village/Town</option>
                      {CityList.map(city => (
                        <option key={city.CityId} value={city.CityId}>
                          {city.Title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-lg-2 col-md-6">
                  <div className="mb-3">
                    <label htmlFor="PinCode" className="form-label">
                      Pincode
                    </label>
                    <Input
                      type="numeric"
                      placeholder="Pin Code"
                      className="form-control"
                      id="PinCode"
                      name="Pincode"
                      maxlength="6"
                      onChange={e => setPincode(e.target.value)}
                    />
                  </div>
                </div>


              </Row>
            </form>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Row className="">
              <Col className="col-lg-12 mb-2">
                <CardTitle>Contact Information</CardTitle>
                <hr />
              </Col>
              <Col lg="4">
                <table style={{ width: "100%" }}>
                  <tbody>
                    {LeadContactList.map((item, idx) => (
                      <tr id={"addr" + idx} key={idx}>
                        <td>
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item className="align-items-end mb-3">
                              <Col lg="10" className="">
                                <Label htmlFor="name">
                                  Mobile No.{"       "}
                                  {item.IsActiveWhatsApp == true ? <input className="form-check-input ms-2" type="checkbox" id={item.CheckBoxControlId} checked /> : <input className="form-check-input ms-2" type="checkbox" id={item.CheckBoxControlId} />}{" "}Is Active WhatsApp?
                                </Label>
                                <Input type="text" id={item.TextBoxControlId} className="form-control" maxlength="10" placeholder="Enter Mobile No" defaultValue={item.MobileNo} />
                              </Col>
                              <Col lg="2 mobile-mt">
                                <Button
                                  onClick={e =>
                                    ContacthandleRemoveRow(e, item.ContactId)
                                  }
                                  color="danger"
                                  className="btn-sm btn-width mb-1 mt-md-3 mt-lg-0"
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div data-repeater-list="group-a">
                  <Row data-repeater-item className="align-items-end">
                    <Col lg="9" className="">
                      <Label htmlFor="name">
                        Mobile No.{"       "}
                        <input className="form-check-input ms-2" type="checkbox" id="ChkActiveWhatsApp" />{" "}Is Active WhatsApp?
                      </Label>
                      <Input type="text" id="txtMobileNo" className="form-control" placeholder="Enter Mobile No" maxlength="10" />
                    </Col>
                    <Col lg="3" className="mt-3">
                      <Button onClick={ContacthandleAddRow} color="primary" className="btn-sm mb-1"><i className="fas fa-plus me-2"></i>Add</Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg="7">

                <table style={{ width: "100%" }}>
                  <tbody>
                    {LeadEmailList.map((item, idx) => (
                      <tr id={"addr" + idx} key={idx}>
                        <td>
                          <div data-repeater-list="group-a mb-3">
                            <Row data-repeater-item className="align-items-center mobile-mt">
                              <Col lg="6" className="mb-3 col-md-6">
                                <Label htmlFor="name">Email</Label>
                                <Input type="text" id={item.EmailTextBoxControlId} className="form-control" placeholder="Enter Email" defaultValue={item.EmailAddress} />
                              </Col>
                              <Col lg="4" className="mb-3 col-md-6">
                                <Label htmlFor="name">Password</Label>
                                <div className="input-group auth-pass-inputgroup">
                                  <input id={item.PasswordTextBoxControlId} type="password" className="form-control" placeholder="Enter password" aria-label="Password" aria-describedby="password-addon" defaultValue={item.Password} />
                                  <button className="btn btn-light " type="button" id="password-addon" onClick={e => PasswordInputChange(e, item.PasswordTextBoxControlId)}><i id="iFPassword" className="mdi mdi-eye-outline"></i></button>
                                </div>
                              </Col>
                              <Col lg="2" className="col-5 col-md-2">
                                <Button onClick={e => EmailhandleRemoveRow(e, item.EmailId)} color="danger" className="mt-2 btn-sm btn-width">
                                  <i className="fas fa-trash-alt"></i>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div data-repeater-list="group-a">
                  <Row data-repeater-item className="align-items-center">
                    <Col lg="6" className="mb-3 col-md-6">
                      <Label htmlFor="name">Email</Label>
                      <Input type="text" id="txtFEmailAddress" className="form-control" placeholder="Enter Email" />
                    </Col>
                    <Col lg="4" className="mb-3 col-md-6">
                      <Label htmlFor="txtFPassword">Password</Label>
                      <div className="input-group auth-pass-inputgroup">
                        <input id="txtFPassword" type="password" className="form-control" placeholder="Enter password" aria-label="Password" aria-describedby="password-addon" />
                        <button className="btn btn-light " type="button" id="password-addon" onClick={e => PasswordInputChange(e, "txtFPassword")}><i id="iFPassword" className="mdi mdi-eye-outline"></i></button>
                      </div>
                    </Col>
                    <Col lg="2">
                      <Button onClick={EmailhandleAddRow} color="primary" className="mt-2 btn-sm">
                        <i className="fas fa-plus me-2"></i>Add
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {/* --------------------------------------------Inividual Form Third Part----------------------------------------------------- */}
        {/* Document Form */}
        <Card>
          <CardBody>
            <Row className="">
              <Col className="col-lg-12 mb-2">
                <CardTitle>Document Details </CardTitle>
                <hr />
              </Col>
              <div className="col-lg-3 col-md-6">
                <div className="mb-3">
                  <label htmlFor="DocumentType" className="form-label">Document Type</label>
                  <select className="form-select" id="ddlDocumentTypeI" onChange={DocumentTypeOnChange}>
                    <option value={"0"}>Select Document Type</option>
                    {DocumentTypeList.map(doctype => (
                      <option key={doctype.DocumentId} value={doctype.DocumentId}>{doctype.DocumentType}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="mb-3">
                  <label htmlFor="DocumentType" className="form-label">Document</label>
                  <select className="form-select" id="ddlDocumentI" onChange={DocumentOnChange}>
                    <option value={"0"}>Select Document</option>
                    {DocumentList.map(doctype => (
                      <option key={doctype.DocumentId} value={doctype.DocumentId}>{doctype.Title}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="mb-3">
                  <label htmlFor="txtKYCDocNo" className="form-label">KYC Doc. No.</label>
                  <input type="text" className="form-control form-control" id="txtKYCDocNo" placeholder="KYC Doc. No." onChange={KYCDocOnChange} />
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="mb-3">
                  <label htmlFor="DocumentType" className="form-label">Upload File </label>
                  {DisplayDocumentFile > 0 ?
                    <span className="float-end text-primary font-12" id="spandoctitle">
                      <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                      <a href={DocumentFilePath} target="_blank" rel="noopener noreferrer" >{DocumentFileName}</a></span> : ''}
                  <input type="file" className="form-control form-control" id="FUDocumentFilePathF" onChange={DocumentfetchPost} />
                </div>
              </div>
              <Col className="col-lg-1 mt-2">
                <Button onClick={DocumenthandleAddRow} color="primary" className="btn-sm mt-lg-4 mt-md-0 mb-md-3"><i className="fas fa-plus me-2"></i>Add</Button>
              </Col>
              <div className="col-lg-12">
                <table style={{ width: "100%" }}>
                  <tbody>
                    {LeadDocumentList.map((item, idx) => (
                      <tr key={idx}>
                        <td>
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item className="">
                              <Col lg="3" className="mb-3">
                                <Label htmlFor="name">Document Type</Label>
                                <input type="text" className="form-control form-control" readOnly id={item.DocumentTypeTextBoxControlId} defaultValue={item.DocumentType} />
                                <input type="hidden" className="form-control form-control" id={item.DocumentTypeHiddenControlId} defaultValue={item.DocumentTypeId} />
                              </Col>
                              <Col lg="3" className="mb-3">
                                <Label htmlFor="name">Document</Label>
                                <input type="text" className="form-control form-control" readOnly id={item.DocumentTextBoxControlId} defaultValue={item.Document} />
                                <input type="hidden" className="form-control form-control" id={item.DocumentHiddenControlId} defaultValue={item.DocumentId} />
                              </Col>
                              <Col lg="2" className="mb-3">
                                <div className="mb-3">
                                  <label htmlFor="txtKYCDocNo" className="form-label">KYC Doc. No.</label>
                                  <input type="text" className="form-control form-control" id={item.KYCDocNoTextBoxControlId} defaultValue={item.KYCDocNo} placeholder="KYC Doc. No." />
                                </div>
                              </Col>
                              <Col lg="3" className="mb-3">
                                <Label htmlFor="name">Upload File</Label>
                                <span className="form-control form-control"><i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i><a href={item.UDocumentFilePath} target="_blank" rel="noopener noreferrer" >{item.UDocumentFileName}</a></span>
                                <input type="hidden" className="form-control form-control" id={item.UploadFileTextBoxControlId} defaultValue={item.UDocumentFilePath} />
                              </Col>
                              <Col lg="1" className="mb-3 mt-2">
                                <Button onClick={e => DocumenthandleRemoveRow(e, item.DocumentId)} color="danger" className="btn-sm btn-width mt-lg-4 mt-md-0 mb-md-3">
                                  <i className="fas fa-trash-alt"></i>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Row>
          </CardBody>
        </Card>

        {/* -------------------------------------------- Individual Form Fourth Part------------------------------------------------------ */}

        {/* Banking Source+ Channel Partner Form */}
        <Card>
          <CardBody>
            <Row>
              <div className="col-lg-7 col-md-6">
                <CardTitle>
                  Banking Source
                  <span className="float-end text-primary font-12">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#banking">
                      <i className="far fa-check-circle"> Select</i>
                    </a>
                  </span>
                </CardTitle>
                <hr />
                {SelectChannelDetail.map(item => (
                  <div className="team-modal-box mt-3 mb-2" key={item.ChannelId}>
                    <div className="d-md-flex d-block align-items-center justify-content-between">
                      <div className="d-md-flex d-block align-items-center">

                        <div className="flex-shrink-0">
                          <div className="avatar-md">
                            <img
                              src={item.ChannelImage}
                              alt=""
                              className="img-thumbnail"
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1 ms-md-3 ms-0 mb-md-0 mb-3">
                          <div className="font-14 dark-font mb-1">
                            {item.ChannelName}
                          </div>
                          <div className="mb-1">
                            <a href="" className="table-link-a font-12">
                              Branch : {item.BranchName}
                            </a>
                          </div>
                          <div className="">
                            <a href="" className="table-link-a font-12">
                              IFSE Code : {item.BranchIFSCCOde}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="bankdiv">
                        <div className="">
                          <a href="" className="table-link-a">
                            <p className="mb-2 text-success">
                              <i className="fas fa-phone-alt me-1"></i>
                              {item.BranchContactNumber}
                            </p>
                          </a>
                          <a href="" className="table-link-a">
                            <p className="mb-0 text-primary">
                              <i className="fas fa-envelope me-1"></i>
                              {item.BranchEmailAddress}
                            </p>
                          </a>
                        </div>

                      </div>
                    </div>
                    <div className="d-md-flex d-block justify-content-between align-items-center">
                      <div className="mb-md-0 mb-3">
                        <div className="">
                          <span className="badge badge-soft-primary p-2 me-2">
                            {item.BranchState}
                          </span>
                          <span className="badge badge-soft-primary p-2 me-2">
                            {item.BranchDistrict}
                          </span>
                          <span className="badge badge-soft-primary p-2 me-2">
                            {item.BranchCity}
                          </span>
                          <span className="badge badge-soft-primary p-2 me-2">
                            {item.BranchTehsil}
                          </span>
                          <span className="badge badge-soft-primary p-2 me-2">
                            {item.Pincode}
                          </span>
                        </div>
                      </div>
                      <div className="bankdiv1">
                        <p className="mb-0 font-12">Approved | CID : {item.ChannelCode}</p>
                      </div>

                    </div>


                  </div>
                ))}
              </div>
              <div className="col-lg-5 col-md-6 mobile-mt">
                <CardTitle>
                  Channel Partner
                  {ChannelId > 0 ?
                    <span className="float-end text-primary font-12">
                      <a href="#" data-bs-toggle="modal" data-bs-target="#channel">
                        <i className="far fa-check-circle"> Select</i>
                      </a>
                    </span>
                    : ''}
                </CardTitle>
                <hr />
                {SelectChannelPartnerDetail.map(item => (
                  <div className="team-modal-box team-box-custom mt-3 mb-2" key={item.ChannelPartnerId}>
                    <div className="d-flex align-items-center border-bottom cpbottom">
                      <div className="flex-shrink-0">
                        <div className="avatar-md">
                          <img
                            src={item.ChannelPartnerLogo}
                            alt=""
                            className="img-thumbnail"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <div className="font-14 dark-font mb-1">
                          {item.Name}
                        </div>
                        <div className="mb-1">
                          <a href="" className="table-link-a font-12">
                            {item.Designation}
                          </a>
                        </div>
                        {/* <div className="">
                          <a href="" className="table-link-a font-12">
                            {item.ChannelName}
                          </a>
                        </div> */}
                      </div>
                    </div>

                    <div className="modal-sub-box pt-2 d-block d-md-flex">
                      <div className="department-box mb-2 mb-md-0">
                        <a className="mb-0 text-success">
                          <i className="fas fa-phone-alt me-1"></i>
                          {item.MobileNumber}
                        </a>
                      </div>
                      <div className="emp-code">
                        <a className="mb-0 text-primary">
                          <i className="fas fa-envelope me-1"></i>
                          {item.Email}
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Row>
          </CardBody>
        </Card>

        {/* --------------------------------------------Individual Form Fifth Part------------------------------------------------------------ */}


        {/* Remarks + Lead Briefing Form */}
        <Card>
          <CardBody>
            <Row className="">
              <Col className="col-lg-12 mb-2">
                <CardTitle>Other Details </CardTitle>
                <hr />
              </Col>
              <div className="col-lg-4 col-md-6">
                <div className="mb-3">
                  <label className="form-label">
                    Tags
                  </label>
                  <select
                    className="form-select"
                    name="ddlTags"
                    Id="ddlTags"
                    onChange={e => setTags(e.target.value)}
                  >
                    <option value={"0"}>Select Tags</option>
                    {TagsList.map(tag => (
                      <option key={tag.Tag} value={tag.Tag}>
                        {tag.Tag}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="mb-3">
                  <label htmlFor="priority" className="form-label">
                    Priority
                  </label>
                  <select
                    className="form-select"
                    id="ddlPriority"
                    name="ddlPriority"
                    onChange={e => setPriorityId(e.target.value)}
                  >
                    <option value={"0"}>Select Priority</option>
                    {PriorityList.map(priority => (
                      <option
                        key={priority.PriorityId}
                        value={priority.PriorityId}
                      >
                        {priority.Priority}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <label className="form-label">
                  Remarks
                </label>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Remarks"
                    id="txtRemarks"
                    rows="3"
                    onChange={e => setRemarks(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <label className="form-label">
                  Lead Briefing
                </label>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Lead Briefing"
                    id="txtLeadBriefing"
                    rows="3"
                    onChange={e => setLeadBriefing(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-lg-6">
                <button
                  type="button"
                  value="Click Me!"
                  className="btn btn_success me-2"
                  onClick={() => SaveNewInqiry()}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn_danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment >
  );
};

export default AddNewInquiryIndividual;
