import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Input, Form, Button } from "reactstrap";
import { useHistory } from 'react-router-dom';
import { Search } from "react-bootstrap-table2-toolkit";
import "../../../assets/scss/datatables.scss";
import axios from "axios";
import PlacholderImgC from "assets/images/favicon.png";
import { Link } from "react-router-dom";
import $ from 'jquery';


const { SearchBar } = Search;

const Addcorleads = () => {
  const history = useHistory();
  const [StateList, setStateList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);
  const [CompanyDistrictId, setCompanyDistrictId] = useState("");
  const [CompanyTehsilId, setCompanyTehsilId] = useState("");
  const [CompanyStateId, setCompanyStateId] = useState([]);
  const [AddTehsil, setAddTehsil] = useState('D');
  const [AddCity, setAddCity] = useState('D');
  const [CompanyCityId, setCompanyCityId] = useState("");
  const [Address, setAddress] = useState("");
  const [Pincode, setPincode] = useState("");
  const [barCode, setbarCode] = useState("");
  const [ComapanyList, setCompanyList] = useState([]);
  const [CompanyId, setCompanyId] = useState("");
  const [NetworkTypeList, setNetworkTypeList] = useState([]);
  const [NetworkTypeId, setNetworkTypeId] = useState("");
  const [LeadContactList, setLeadContactList] = useState([]);
  const [MEmailId, setMEmailId] = useState("");
  const [LeadEmailList, setLeadEmailList] = useState([]);
  const [LeadDocumentList, setLeadDocumentList] = useState([]);
  const [EmailId2, setEmailId2] = useState([]);
  const [EmailID, setEmailID] = useState([]);
  const [DropdownChannelList, setDropdownChannelList] = useState([]);
  const [ChannelMasterList, setChannelMasterList] = useState([]);
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;



  useEffect(() => {

    getStateList("1");
    getCompanyList();
    getNetWorkList();
    getMenuHideShow();
    getDropdownChannelMasterList();
  }, []);

  function getRandomNumber() {
    let min = 100000;
    let max = 999999;
    return Math.round(Math.random() * (max - min) + min);
  }

  const getDropdownChannelMasterList = async () => {
    var VNetworkTypeId = document.getElementById("ddlNetworkType").value;
    if (parseInt(VNetworkTypeId) > 0) {
      const ChannelDropdownFilter = {
        ChannelTypeId: VNetworkTypeId,
        ChannelMasterId: 0,
        CityId: 0,
        SpMode: 'ChannelMasterSelectAll',
      };
      axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
        .then(CLres => {
          let VChannelMasterList = CLres.data.map(vcl => {
            return vcl;
          });
          setDropdownChannelList(VChannelMasterList);
        });
    }
  };

  function getpassword() {
    let min = 100000;
    let max = 999999;
    return Math.round(Math.random() * (max - min) + min);
  }


  const getMenuHideShow = async () => {
    const f = {
      MenuId: "48",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {
        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add Tehsil') {
            setAddTehsil(MS);
          }
          else if (MA == 'Add City') {
            setAddCity(MS);
          }
        }
      });
  };

  const getStateList = async CId => {
    var AUrl = global.APIURL + '/ActiveState/' + CId;
    axios.get(AUrl)
      .then(Sres => {
        let StateL = Sres.data.map(kcstate => {
          return kcstate;
        });
        setStateList(StateL);
      });
  };

  const getCompanyDistrictList = async SId => {
    var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
    axios.get(AUrl)
      .then(Dres => {
        let DistrictL = Dres.data.map(kcdistrict => {
          return kcdistrict;
        });
        setDistrictList(DistrictL);
      });
  };

  const getCompanyTehsilList = async DId => {
    var AUrl = global.APIURL + '/ActiveTehsil/' + DId;
    axios.get(AUrl)
      .then(Dres => {
        let TehsilL = Dres.data.map(kctehsil => {
          return kctehsil;
        });
        setTehsilList(TehsilL);
      });
  };

  const getCompanyCityList = async TId => {
    var ACUrl = global.APIURL + '/ActiveCity/' + TId;
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCityList(CityL);
      });
  };

  const getCompanyList = async () => {
    var ACUrl = global.APIURL + '/CompanyActive/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCompanyList(CityL);
      });
  };
  const getNetWorkList = async () => {
    var ACUrl = global.APIURL + '/ChannalTypeList/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setNetworkTypeList(CityL);
      });
  };

  const ContacthandleAddRow = e => {
    var LRNo = LeadContactList.length == 0 ? 1 : (LeadContactList[LeadContactList.length - 1].ContactId + 1);
    var VName = document.getElementById('txtName').value;
    var VEmail = document.getElementById('txtEmail').value;
    var VDesignation = document.getElementById('txtDesignation').value;
    var VMobileNo = document.getElementById('txtFMobileNo').value;

    setEmailId2(VEmail);
    if (VName == "") {
      alert("Enter Name");
      return;
    }
    if (VEmail == "") {
      alert("Enter Email Id");
      return;
    }
    else {
      if (VEmail == MEmailId) {
        alert("Email should not be same");
        return;
      }
    }
    if (regex.test(VEmail) === false) {
      alert("Enter Valid Email ");
      return;

    }

    if (VMobileNo == "") {
      alert("Enter Mobile No ");
      return;
    }
    if (VMobileNo.length < 10) {
      alert("Enter valid number");
      return;
    }

    if (VDesignation == "") {
      alert("Enter Designation");
      return;
    }

    const Citem = {
      CorporateleadContactId: 0,
      CorporateleadId: LRNo,
      Name: VName,
      Email: VEmail,
      Designation: VDesignation,
      MobileNo: VMobileNo,
      TextBoxControlIdName: VName,
      TextBoxControlIdEmail: VEmail,
      TextBoxControlIdDesignation: VDesignation,
      TextBoxControlId: VMobileNo,
    };
    setLeadContactList([...LeadContactList, Citem]);
    document.getElementById('txtName').value = '';
    document.getElementById('txtEmail').value = '';
    document.getElementById('txtDesignation').value = '';
    document.getElementById('txtFMobileNo').value = '';
  };

  const ContacthandleRemoveRow = (e, CorporateleadId, CorporateleadContactId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = CorporateleadId;
      setLeadContactList(LeadContactList.filter(item => item.CorporateleadId !== RId));

      var VLeadContactId = parseInt(CorporateleadContactId == null ? '0' : CorporateleadContactId == '' ? '0' : CorporateleadContactId);
      if (VLeadContactId > 0) {
        const LeadContactItemArr = { Id: VLeadContactId }
        axios.post(global.APIURL + '/WebCorporateLeadContactDelete', LeadContactItemArr).then(resem => { });
      }

      alert('contact delete successfully');
    }
  };

  const SaveLeadsDeails = async () => {

    let LeadContactListArr = [];

    for (let con = 0; con < LeadContactList.length; con++) {
      const LeadContactDetail = LeadContactList[con];
      // var vTextBoxControlIdName = "#" + LeadContactDetail.TextBoxControlIdName;
      // var vTextBoxControlIdEmail = "#" + LeadContactDetail.TextBoxControlIdEmail;
      // var vTextBoxControlIdMobileNo = "#" + LeadContactDetail.TextBoxControlId;
      // var vTextBoxControlIdDesignation = "#" + LeadContactDetail.TextBoxControlIdDesignation;
      // let VName = $(vTextBoxControlIdName).val();
      // let VEmail = $(vTextBoxControlIdEmail).val();
      // let VMobileNo = $(vTextBoxControlIdMobileNo).val();
      // let VDesignation = $(vTextBoxControlIdDesignation).val();

      let VName=LeadContactDetail.Name;
      let VEmail=LeadContactDetail.Email;
      let VDesignation=LeadContactDetail.Designation;
      let VMobileNo=LeadContactDetail.MobileNo; 

      let ICCIns = 0;
      if (VName != null) {
        if (VName != null) {
          ICCIns++;
        }
      }
      if (VEmail != null) {
        if (VEmail != null) {
          ICCIns++;
        }
      }
      if (VMobileNo != null) {
        if (VMobileNo != null) {
          ICCIns++;
        }
      }
      if (VDesignation != null) {
        if (VDesignation != null) {
          ICCIns++;
        }
      }
      if (ICCIns > 0) {
        const ContactItemArr = {
          Name: VName,
          Email: VEmail,
          Designation: VDesignation,
          Mobilenumber: VMobileNo,
        };
        LeadContactListArr.push(ContactItemArr);
      }
    }

    let VName = document.getElementById("txtName").value;
    let VEmail = document.getElementById("txtEmail").value;
    let VMobileNo = document.getElementById("txtFMobileNo").value;
    let VDesignation = document.getElementById("txtDesignation").value;
    let ICCIns = 1;

    if(VName==""){
      ICCIns=0;    
         
    }
    if(VEmail ==""){
      ICCIns=0;   
     
    }
    if(VEmail!=""){
     
      if (regex.test(VEmail) === false) {
        ICCIns=0;
         
      }
    }
    if(VMobileNo!=""){
      if(VMobileNo.length<10){
        ICCIns=0;
      }
    }
    if (ICCIns == 1) {
      const ContactItemArr = {
        Name: VName,
        Email: VEmail,
        Designation: VDesignation,
        Mobilenumber: VMobileNo
      };
      LeadContactListArr.push(ContactItemArr);
    }

    let IsInsert = 1;
    let NetworkTypeId = parseInt($('#ddlNetworkType option:selected').val() == null ? '0' : $('#ddlNetworkType option:selected').val() == '' ? '0' : $('#ddlNetworkType option:selected').val());
    let NetworkSourceId = parseInt($('#ddlNetworkSource option:selected').val() == null ? '0' : $('#ddlNetworkSource option:selected').val() == '' ? '0' : $('#ddlNetworkSource option:selected').val());
    let NetworkSource = $('#ddlNetworkSource option:selected').text() == null ? '' : $('#ddlNetworkSource option:selected').text();
    let VEmailAddress = $('#txtNetworkSourceEmailAddress').val() == null ? '' : $('#txtNetworkSourceEmailAddress').val() == '' ? '' : $('#txtNetworkSourceEmailAddress').val();
    let VAddress = $('#txtNetworkSourceAddress').val() == null ? '' : $('#txtNetworkSourceAddress').val() == '' ? '' : $('#txtNetworkSourceAddress').val();
    let StateId = parseInt($('#ddlNetworkSourceState option:selected').val() == null ? '0' : $('#ddlNetworkSourceState option:selected').val() == '' ? '0' : $('#ddlNetworkSourceState option:selected').val());
    let DistrictId = parseInt($('#ddlNetworkSourceDistrict option:selected').val() == null ? '0' : $('#ddlNetworkSourceDistrict option:selected').val() == '' ? '0' : $('#ddlNetworkSourceDistrict option:selected').val());
    let TehsilId = parseInt($('#ddlNetworkSourceTehsilId option:selected').val() == null ? '0' : $('#ddlNetworkSourceTehsilId option:selected').val() == '' ? '0' : $('#ddlNetworkSourceTehsilId option:selected').val());
    let CityId = parseInt($('#ddlNetworkSourceCity option:selected').val() == null ? '0' : $('#ddlNetworkSourceCity option:selected').val() == '' ? '0' : $('#ddlNetworkSourceCity option:selected').val());
    let VPincode = $('#txtNetworkSourcePincode').val() == null ? '' : $('#txtNetworkSourcePincode').val();
    let VBranchcode = $('#txtNetworkSourceBarcode').val() == null ? '' : $('#txtNetworkSourceBarcode').val();
    if (NetworkTypeId == 0) {
      alert("Please select network type.");
      IsInsert = 0;
      return;
    }
    if (NetworkSourceId == 0) {
      alert("Please select network source.");
      IsInsert = 0;
      return;
    }
    if (VEmailAddress == "") {
      alert("Enter Email Address");
      IsInsert = 0;
      return;
    }
    else {
      if (regex.test(VEmailAddress) === false) {
        alert("Enter Valid Email ");
        IsInsert = 0;
        return;
      }
    }
    if(VEmailAddress!="" && VEmail!=""){
        if(VEmailAddress==VEmail){
          alert("Email Address cannot be same");
          IsInsert = 0;
          return;
        }
    }
    if (VAddress == "") {
      alert("Enter Address");
      IsInsert = 0;
      return;
    }
    if (StateId == 0) {
      alert("Please select state.");
      IsInsert = 0;
      return;
    }
    if (DistrictId == 0) {
      alert("Please select district.");
      IsInsert = 0;
      return;
    }
    if (TehsilId == 0) {
      alert("Please select tehsil.");
      IsInsert = 0;
      return;
    }
    if (CityId == 0) {
      alert("Please select city.");
      IsInsert = 0;
      return;
    }
    if (VPincode == "") {
      alert("Enter pincode");
      IsInsert = 0;
      return;
    }
    if (VBranchcode == "") {
      alert("Enter branch code");
      IsInsert = 0;
      return;
    }
    if (IsInsert == 1) {
      const LeadInquiry = {
        CorporateleadId: "0",
        ChannelTypeId: NetworkTypeId,
        CompanyId: NetworkSourceId,
        CompanyName: NetworkSource,
        EmailId: VEmailAddress,
        Address: VAddress,
        CountryId: "1",
        StateId: StateId,
        DistrictId: DistrictId,
        TehsilId: TehsilId,
        CityId: CityId,
        Pincode: VPincode,
        Branchcode: VBranchcode,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
        ContactPersonList: LeadContactListArr,
      };
      axios.post(global.APIURL + "/CorporateleadInsert", LeadInquiry)
        .then(res => {
          alert(res.data.StatusMessage);
          history.push("/CorporateLeads");
        });
    }
  };

  const inputHandler = e => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="mb-3">
            <Col className="col-lg-6 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Add Corporate Lead</CardTitle>
                <a href="/GeneralDashboard" className="me-2">Home</a>{" "}
                <span>/</span>
                <a href="/CorporateLeads" className="me-2 ms-2">Corporate Lead</a>{" "}
                <span>/</span>
                <span className="ms-2">Add Corporate Lead </span>
              </div>
            </Col>
            <Col className="col-lg-6 co-md-6 col-6">
              <div className="d-flex align-items-center justify-content-end ">

                <div>
                  <a className="btn btn-primary btn-sm float-end font-14" href="/CorporateLeads">
                    <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <div className="ind-card" id="ind-card">
            <Card>
              <CardBody>
                <Col className="col-lg-12 mb-2">
                  <CardTitle> Corporate Information </CardTitle>
                  <hr />
                </Col>
                <form>
                  <Row>
                    <Col className="col-lg-12">
                      <Row>
                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label htmlFor="State" className="form-label">
                              Network Type
                            </label>
                            <select className="form-select" Id="ddlNetworkType" name="ddlNetworkType" onChange={e => { setNetworkTypeId(e.target.value); getDropdownChannelMasterList(); }}>
                              <option value={"0"}>Select Network Type</option>
                              {NetworkTypeList.map(state => (
                                <option key={state.ChannelTypeId} value={state.ChannelTypeId}>
                                  {" "}
                                  {state.ChannelTypeName}{" "}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label htmlFor="State" className="form-label">
                              Network Source
                            </label>
                            <select className="form-select" Id="ddlNetworkSource" name="ddlNetworkSource" onChange={e => { setCompanyId(e.target.value) }}>
                              <option value={"0"}>Select Source</option>
                              {DropdownChannelList.map(item => (
                                <option key={item.ChannelMasterId} value={item.ChannelMasterId}>
                                  {item.Title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                          <div className="mb-3">
                            <label htmlFor="EmailId" className="form-label">
                              Email Address
                            </label>
                            <Input
                              type="text"
                              placeholder="Email Address"
                              className="form-control"
                              id="txtNetworkSourceEmailAddress"
                              maxlength="50"
                              onChange={e => setMEmailId(e.target.value)}
                            />
                          </div>
                        </div>
                      </Row>
                    </Col>
                    <div className="col-lg-12">
                      <label htmlFor="Address" className="form-label">
                        Address
                      </label>
                      <div className="mb-3">
                        <textarea
                          className="form-control"
                          placeholder="Address"
                          id="txtNetworkSourceAddress"
                          rows="3"
                          name="txtNetworkSourceAddress"
                          onChange={e => setAddress(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="State" className="form-label">
                          State
                        </label>
                        <select className="form-select" Id="ddlNetworkSourceState" name="ddlNetworkSourceState" onChange={e => { getCompanyDistrictList(e.target.value); setCompanyStateId(e.target.value) }}>
                          <option value={"0"}>Select State</option>
                          {StateList.map(state => (
                            <option key={state.StateId} value={state.StateId}>
                              {" "}
                              {state.Title}{" "}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="District" className="form-label">
                          District
                        </label>
                        <select className="form-select" Id="ddlNetworkSourceDistrict" name="ddlNetworkSourceDistrict" onChange={e => { getCompanyTehsilList(e.target.value); setCompanyDistrictId(e.target.value) }}>
                          <option value={"0"}>Select District</option>
                          {DistrictList.map(district => (
                            <option key={district.DistrictId} value={district.DistrictId}>
                              {district.Title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label htmlFor="formFileSm" className="form-label">Tehsil</label>
                          </div>
                          <div>
                            {AddTehsil == "A" ? parseInt(CompanyDistrictId) > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddFirmTehsil">+ Add New</a></span> : '' : ''}
                          </div>
                        </div>
                        <select className="form-select" Id="ddlNetworkSourceTehsilId" name="ddlNetworkSourceTehsilId" onChange={e => { getCompanyCityList(e.target.value); setCompanyTehsilId(e.target.value) }}>
                          <option value={"0"}>Select Tehsil</option>
                          {TehsilList.map(tehsil => (
                            <option key={tehsil.TehsilId} value={tehsil.TehsilId}>
                              {tehsil.Title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label htmlFor="formFileSm" className="form-label">City/Village/Town</label>
                          </div>
                          <div>
                            {AddCity == "A" ? parseInt(CompanyTehsilId) > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddFirmCity">+ Add New</a></span> : '' : ''}
                          </div>
                        </div>
                        <select className="form-select" Id="ddlNetworkSourceCity" name="ddlNetworkSourceCity" onChange={e => setCompanyCityId(e.target.value)}>
                          <option value={"0"}>Select City/Village/Town</option>
                          {CityList.map(city => (
                            <option key={city.CityId} value={city.CityId}>
                              {city.Title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="Pincode" className="form-label">
                          Pincode
                        </label>
                        <Input
                          type="number"
                          placeholder="Pincode"
                          className="form-control"
                          id="txtNetworkSourcePincode"
                          onKeyPress={inputHandler}
                          maxlength="6"
                          onChange={e => setPincode(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="Barcode" className="form-label">
                          Branch code
                        </label>
                        <Input
                          type="text"
                          placeholder="Barcode"
                          className="form-control"
                          id="txtNetworkSourceBarcode"
                          maxlength="6"
                          onChange={e => setbarCode(e.target.value)}
                        />
                      </div>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row className="">
                  <Col className="col-lg-12 mb-2">
                    <CardTitle>Contact Information</CardTitle>
                    <hr />
                  </Col>
                  <Col lg="12">
                    <table style={{ width: "100%" }}>
                      <tbody>
                        {LeadContactList.map((item, idx) => (
                          <tr id={"addr" + idx} key={idx}>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">
                                  <Col lg="3" className="">
                                    <Label htmlFor="name">
                                      Name
                                    </Label>
                                    <Input type="text" id={item.TextBoxControlIdName} className="form-control" placeholder="Enter Name" defaultValue={item.Name} />
                                  </Col>
                                  <Col lg="3" className="">
                                    <Label htmlFor="name">
                                      Email
                                    </Label>
                                    <Input type="text" id={item.TextBoxControlIdEmail} className="form-control" placeholder="Enter Email" defaultValue={item.Email} />
                                  </Col>
                                  <Col lg="3" className="">
                                    <Label htmlFor="name">
                                      Mobile No
                                    </Label>
                                    <Input type="number" id={item.TextBoxControlId} className="form-control" onKeyPress={inputHandler} placeholder="Enter Mobile No" defaultValue={item.MobileNo} />
                                  </Col>
                                  <Col lg="2" className="">
                                    <Label htmlFor="name">
                                      Designation
                                    </Label>
                                    <Input type="text" id={item.TextBoxControlIdDesignation} className="form-control" placeholder="Enter Designation" defaultValue={item.Designation} />
                                  </Col>
                                  <Col lg="1 mobile-mt">
                                    <Button
                                      onClick={e =>
                                        ContacthandleRemoveRow(e, item.CorporateleadId, item.CorporateleadContactId)
                                      }
                                      color="danger"
                                      className="btn-sm btn-width mb-1 mt-md-3 mt-lg-0"
                                    >
                                      <i className="fas fa-trash-alt"></i>
                                    </Button>
                                  </Col>


                                </Row>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div data-repeater-list="group-a">
                      <Row data-repeater-item className="align-items-end">
                        <Col lg="3" className="">
                          <Label htmlFor="name">
                            Name
                          </Label>
                          <Input type="text" id="txtName" className="form-control" placeholder="Enter Name" />
                        </Col>
                        <Col lg="3" className="">
                          <Label htmlFor="name">
                            Email
                          </Label>
                          <Input type="text" id="txtEmail" className="form-control" placeholder="Enter Email" onChange={(e) => setEmailID(e.target.value)} />
                        </Col>
                        <Col lg="3" className="">
                          <Label htmlFor="name">
                            Mobile No
                          </Label>
                          <Input type="number" id="txtFMobileNo" className="form-control" onKeyPress={inputHandler} placeholder="Enter Mobile No" maxLength={10} />
                        </Col>
                        <Col lg="2" className="">
                          <Label htmlFor="name">
                            Designation
                          </Label>
                          <Input type="text" id="txtDesignation" className="form-control" placeholder="Enter Designation" />
                        </Col>

                        <Col lg="1" className="mt-3">
                          <Button onClick={ContacthandleAddRow} color="primary" className="btn-sm mb-1"><i className="fas fa-plus me-2"></i>Add</Button>
                        </Col>

                      </Row>
                    </div>
                  </Col>
                  <div className="co-md-12 mt-3"><a href="#" className="btn btn_success me-2" onClick={() => SaveLeadsDeails()}> Save </a><a href="#" className="btn btn_danger me-2"> Cancel </a></div>

                </Row>
              </CardBody>
            </Card>

          </div>

        </div>
      </div>
    </React.Fragment >
  );
};

export default Addcorleads;

