import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import Mobile from "./Mobile";
import axios from "axios";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";

const FormRepeater = () => {
  const [EmailId, setEmailId] = useState("");
  const [Password, setPassword] = useState("");

  const changeEmail = event => {
    setEmailId(event.target.value);
  };

  const changePassword = event => {
    setPassword(event.target.value);
  };

  const transferValue = event => {
    event.preventDefault();

    var data = {
      LeadId: "1",
      EmailId: EmailId,

      Password: Password,
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    };

    axios
      .post(global.APIURL + "/WebLeadEmailInformationAdd", data)
      .then(res => {
      })
      .catch(err => console.log(err));

    clearState();
  };

  const clearState = () => {
    setEmailId("");
    setPassword("");
  };

  return (
    <React.Fragment>
      <div className="">
        <MetaTags>
          <title>Add New Inquiry | KC Admin Group</title>
        </MetaTags>

        <Card>
          <CardBody>
            <Row className="">
              <CardTitle>Contact Information</CardTitle>

              <Col lg="5" className="p-0">
                <Mobile />
              </Col>
              <Col lg="7">
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr id="addr01" key="">
                      <td>
                        <Form
                          className="repeater"
                          encType="multipart/form-data"
                        >
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item>
                              <Col lg="5" className="mb-3">
                                <Label htmlFor="email">Email</Label>
                                <Input
                                  type="email"
                                  id="email"
                                  name="EmailId"
                                  value={EmailId}
                                  className="form-control"
                                  onChange={changeEmail}
                                  placeholder="Enter Your Email ID"
                                />
                              </Col>
                              <Col lg="5" className="mb-3">
                                <Label htmlFor="password">Password</Label>
                                <Input
                                  type="password"
                                  name="Password"
                                  id="password"
                                  value={Password}
                                  onChange={changePassword}
                                  className="form-control"
                                  placeholder="Enter Your Password"
                                />
                              </Col>
                              <Col lg="1" className="align-self-center">
                                <div className="d-grid mt-2">
                                  <Button
                                    color="danger"
                                    style={{ width: "89%" }}
                                    className="btn-sm"
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Button
                  onClick={transferValue}
                  color="primary"
                  className="mt-3 mt-lg-0 btn-sm"
                >
                  <i className="fas fa-plus me-2"></i>
                  Add
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default FormRepeater;
