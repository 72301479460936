import React,{ useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory,useParams  } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
const cookies = new Cookies();
 
const EditStatus = () => { 
  const [code, setcode] = useState('');
  const [Title, setTitle] = useState('');
  const [Description, setDescription] = useState('');
  const [StatusId, setDId] = useState('');
  const history = useHistory();

      let { id } = useParams();

      const updateProduct  = async (e) => {
        e.preventDefault();
        let  Ucode=e.target["txtCode"].value; 
        let UTitle=e.target["txtTitle"].value; 
        let UDescription=e.target["txtDescription"].value; 
        let UStatusId=e.target["hdfStatusId"].value;  
      
        if(Ucode!='')
        {
            const StatusList = {
              StatusId:UStatusId==''?'0':UStatusId,
              code: Ucode,
              Title: UTitle,
              Description: UDescription,
              Flag:"A"        
            }

            axios.post(global.APIURL+'/StatusUpdate', StatusList)
            .then(res => 
            {
                    alert(res.data);
                    history.push("/StatusMaster");
            });
        }
    }

    useEffect(() => {

     
        
        if(id!=null)
        {
          if(id!="0")
          {
            let newid = id.split(':'); 
            
            
            getUserTyprById(newid[1]);
          }
          else{
            history.push("/StatusMaster");
          }
        }
        else{
          history.push("/StatusMaster");
        }
    }, []);
 
    const getUserTyprById = async (UTId) => {
      var AUrl=global.APIURL+'/StatusEdit/'+UTId;
        axios.get(AUrl)  
        .then(res => {  
         
          setcode(res.data[0].code);
           setTitle(res.data[0].Title);
           setDescription(res.data[0].Description);
           setDId(res.data[0].StatusId)
        })
    }

    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Status Master | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Edit Status Master </CardTitle>
                <a Link to="/" className="me-2">Home</a>
                <span>/</span>
                <a Link to="/StatusMaster"><span className="ms-2 me-2">Status Master</span></a>
                <span>/</span>
                <span className="ms-2">Edit Status Master</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" Link to="/StatusMaster">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={updateProduct}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
                <Row>
                  <Col lg={4}>
                        <div className="mb-2">
                        <label className="form-label">Code</label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Code"
                            id="txtCode"  
                            defaultValue={code}
                           
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-2">
                        <label className="form-label">Title</label>
                          <Input
                            type="text"
                            className="form-control"
                            id="txtTitle"  
                            placeholder="Title"
                            defaultValue={Title}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-2">
                        <label className="form-label">Description</label>
                          <Input
                            type="text"
                            className="form-control pb-4"
                            id="txtDescription"  
                          
                            placeholder="Description"
                            defaultValue={Description}
                          />
                        </div>
                      </Col>
                    <Col lg={12}>
                        <input type="hidden" id="hdfStatusId" value={StatusId} />    
                        {/* <input type="hidden" id="hdfUserTypeId" value={this.state.Id} /> */}
                        <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                        <a className="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                    </Col>
                  </Row>
               
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
      </div>
    )
}

export default EditStatus;