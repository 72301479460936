import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle,Input, } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins

import { Search } from 'react-bootstrap-table2-toolkit';


import "../../assets/scss/datatables.scss"

// Table data
const products = [
  {
    "id": 1,
    "name":   <p className="fw-bold mb-1">Smith Jain</p>,
    "last in": <><p className="fw-bold mb-1">10:00 AM</p><p>(20/06/2020)</p></> ,
    "last out":   <><p className="fw-bold mb-1">7:00 PM</p><p>(20/06/2020)</p></>,
    "action": <> <p className="text-decoration-underline mb-1"> <a href="#">Manage Attendance</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#download_master"> <a href="#">Download Master</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#new_request"> <a href="#">New Request</a> </p></>
  },
  

  

];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    const columns = [{
      dataField: 'id',
      text: '#',
      sort: true,
    }, {
      dataField: 'name',
      text: 'Name',
      sort: true
    }, {
      dataField: 'last in',
      text: 'Last In',
      sort: true
    },  {
      dataField: 'last out',
      text: 'Last Out',
      sort: true
    },{
      dataField: 'action',
      text: 'Action',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

  
    // Select All Button operation
   

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title> Resolution | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
            


            <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                    <CardTitle className="h3"> Resolution </CardTitle>
                      <a href="#" className="me-2">Services</a> <span>/</span><span className="ms-2"> Resolution</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 col-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14 margin-20-back" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
              <Row>
              <Col xl={12}>
                <Card>
                 <CardBody>
                    <div className="row align-items-center">
                    <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Lead ID</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Lead ID"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Customer ID</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Customer ID"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Service Status</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Service status"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Date of Lead</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="DOL"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-2">
                          <a className="btn btn-primary btn-sm font-14 me-3" href="#"> Update</a>
                          </div>
                        </Col>
                    </div>
                 </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
                <Col>
                <Card>
                    <CardBody>
                        <Row>
                        <Col className="col-lg-10 col-md-12">
                            <Row>
                            <Col className="col-lg-4 col-md-6 col-12">
                          <div className="mb-3">
                          <label className="form-label">Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Name"
                            />
                          </div>
                        </Col>
                        <Col className="col-lg-4 col-md-6 col-12">
                          <div className="mb-3">
                          <label className="form-label">Date Of Birth</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="DOB"
                            />
                          </div>
                        </Col>
                        <Col className="col-lg-4 col-md-6 col-12">
                          <div className="mb-3">
                          <label className="form-label">Mobile No.</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Enter 10digit mobile no"
                            />
                          </div>
                        </Col>
                        <Col className="col-lg-4 col-md-6 col-12">
                          <div className="mb-3">
                          <label className="form-label">Adhar</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Adhar"
                            />
                          </div>
                        </Col>
                        <Col className="col-lg-4 col-md-6 col-12">
                          <div className="mb-3">
                          <label className="form-label">PAN</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="PAN"
                            />
                          </div>
                        </Col>
                        <Col className="col-lg-4 col-md-6 col-12">
                          <div className="mb-3">
                          <label className="form-label">Email ID</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Email ID"
                            />
                          </div>
                        </Col>
                        <Col className="col-lg-4 col-md-6 col-12">
                          <div className="mb-3">
                          <label className="form-label">CIR ID 1</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="CIR"
                            />
                          </div>
                        </Col>
                        <Col className="col-lg-4 col-md-6 col-12">
                          <div className="mb-3">
                          <label className="form-label">CIR Password</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="CIR Password"
                            />
                          </div>
                        </Col>
                        
                       
                            </Row>
                          </Col>
                          <Col className="col-lg-2 col-md-4">
                          <label className="form-label d-block text-center">Upload Image</label>
                          <div className ="d-flex logo-main float-end">
                         
                              <div className="logo-box">
                                  <p className="mb-0">User</p>
                              </div>
                                <div className="icon-box">
                              <a href="">  <i className="mdi mdi-pencil"></i></a>
                                </div>
                        </div>
                          </Col>
                        </Row>
                    </CardBody>
                </Card>
                </Col>
            </Row>
             
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-md-4 col-12 mb-1">
                          <p className="mb-0"> Service No. <span className="fw-bold "> 1 </span> </p>
                          </Col>
                          <Col className="col-md-8  col-12 text-md-end text-left mb-3">
                         <a href="#"  data-bs-toggle="modal" data-bs-target="#resolution" className="text-decoration-underline text-primary "> Resolution Details </a>
                      </Col>  
                    </Row>
                    <Row>
                      <Col className="col-md-3 mb-3  col-6">
                        <div className="border border-black border-1 p-2 rounded-3">
                        <p className="fontWeight500 font-14 mb-1"> Service </p>
                          <p className="form-label mb-0"> Pan Card Detail Correction </p>
                        </div>
                      </Col>  
                      <Col className="col-md-3 mb-3  col-6">
                        <div className="border border-black border-1 p-2 rounded-3">
                        <p className="fontWeight500 font-14 mb-1"> Bank/ FI Name </p>
                          <p className="form-label  mb-0"> Bank of Baroda </p>
                        </div>
                      </Col> 
                      <Col className="col-md-3 mb-3  col-6">
                        <div className="border border-black border-1 p-2 rounded-3">
                        <p className="fontWeight500 font-14 mb-1"> Account Type </p>
                          <p className="form-label  mb-0"> Saving A/c </p>
                        </div>
                      </Col> 
                      <Col className="col-md-3 mb-3  col-6">
                        <div className="border border-black border-1 p-2 rounded-3">
                        <p className="fontWeight500 font-14 mb-1"> A/c No. </p>
                          <p className="form-label  mb-0"> XXXXXXXXXXXXXXXXX </p>
                        </div>
                      </Col> 
                    </Row>
                    <Row>
                      <Col className="col-md-3 mb-3  col-6">
                        <div className="border border-black border-1 p-2 rounded-3">
                        <p className="fontWeight500 font-14 mb-1"> Current Balance </p>
                          <p className="form-label  mb-0"> Current Balance </p>
                        </div>
                      </Col>  
                      <Col className="col-md-3 mb-3  col-6">
                        <div className="border border-black border-1 p-2 rounded-3">
                          <p className="fontWeight500 font-14 mb-1"> OD Balance </p>
                          <p className="form-label  mb-0"> Pan Card Detail Correction </p>
                        </div>
                      </Col> 
                      <Col className="col-md-6 col-12 mb-3">
                        <div className="border border-black border-1 p-2 rounded-3">
                        <p className="fontWeight500 font-14 mb-1"> Remark </p>
                          <p className="form-label  mb-0"> Pan Card Detail Correction </p>
                        </div>
                      </Col> 
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-md-4 col-12 mb-1">
                          <p className="mb-0"> Service No. <span className="fw-bold "> 2 </span> </p>
                          </Col>
                          <Col className="col-md-8  col-12 text-md-end text-left mb-3">
                         <a href="#"  data-bs-toggle="modal" data-bs-target="#resolution" className="text-decoration-underline text-primary "> Resolution Details </a>
                      </Col>  
                    </Row>
                    <Row>
                      <Col className="col-md-3 mb-3  col-6">
                        <div className="border border-black border-1 p-2 rounded-3">
                        <p className="fontWeight500 font-14 mb-1"> Service </p>
                          <p className="form-label mb-0"> Pan Card Detail Correction </p>
                        </div>
                      </Col>  
                      <Col className="col-md-3 mb-3  col-6">
                        <div className="border border-black border-1 p-2 rounded-3">
                        <p className="fontWeight500 font-14 mb-1"> Bank/ FI Name </p>
                          <p className="form-label  mb-0"> Bank of Baroda </p>
                        </div>
                      </Col> 
                      <Col className="col-md-3 mb-3  col-6">
                        <div className="border border-black border-1 p-2 rounded-3">
                        <p className="fontWeight500 font-14 mb-1"> Account Type </p>
                          <p className="form-label  mb-0"> Saving A/c </p>
                        </div>
                      </Col> 
                      <Col className="col-md-3 mb-3  col-6">
                        <div className="border border-black border-1 p-2 rounded-3">
                        <p className="fontWeight500 font-14 mb-1"> A/c No. </p>
                          <p className="form-label  mb-0"> XXXXXXXXXXXXXXXXX </p>
                        </div>
                      </Col> 
                    </Row>
                    <Row>
                      <Col className="col-md-3 mb-3  col-6">
                        <div className="border border-black border-1 p-2 rounded-3">
                        <p className="fontWeight500 font-14 mb-1"> Current Balance </p>
                          <p className="form-label  mb-0"> Current Balance </p>
                        </div>
                      </Col>  
                      <Col className="col-md-3 mb-3  col-6">
                        <div className="border border-black border-1 p-2 rounded-3">
                          <p className="fontWeight500 font-14 mb-1"> OD Balance </p>
                          <p className="form-label  mb-0"> OD Balance </p>
                        </div>
                      </Col> 
                      <Col className="col-md-6 col-12 mb-3">
                        <div className="border border-black border-1 p-2 rounded-3">
                        <p className="fontWeight500 font-14 mb-1"> Remark </p>
                          <p className="form-label  mb-0"> Pan Card Detail Correction </p>
                        </div>
                      </Col> 
                    </Row>
                   
                  </CardBody>
                </Card>
              </Col>
            </Row>
            
          </div>
        </div>


       
        





        <div className="modal fade" id="resolution" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Resolution Details</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
                <Row>
                  <Col className="col-12 mb-3">
                  <p className="fontWeight500 font-14 mb-1"> Person to be Contacted </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-3 col-6 mb-3">
                    <label className="form-label"> Name </label>
                    <input type="text" className="form-control form-control-sm" placeholder="Name" />
                  </Col> 
                  <Col className="col-md-3 col-6 mb-3">
                    <label className="form-label"> Mob No. 1 </label>
                    <input type="text" className="form-control form-control-sm" placeholder="Mob No. 1 " />
                  </Col> 
                  <Col className="col-md-3 col-6 mb-3">
                    <label className="form-label">  Mob No. 2 </label>
                    <input type="text" className="form-control form-control-sm" placeholder="Mob No. 2 " />
                  </Col> 
                  <Col className="col-md-3 col-6 mb-3">
                    <label className="form-label"> Email Official </label>
                    <input type="text" className="form-control form-control-sm" placeholder="Email Official" />
                  </Col> 
                  <hr className="bg-black-500" />
                </Row>  

                <Row>
                  <Col className="col-8 mb-3">
                  <p className="fontWeight500 font-14 mb-1"> Followup 1 </p>
                  </Col>
                  <Col lg={4}>
                        <button  type="button" className="btn btn-danger float-end mb-2 deleteMultipleImages float-right">
                            <i className="bx bx-trash d-block font-size-16"></i>
                        </button>
                        </Col>
                </Row>
                <Row>
                  <Col className="col-md-3 col-6 mb-3">
                    <label className="form-label"> Date </label>
                    <input type="date" className="form-control form-control-sm" placeholder="Name" />
                  </Col> 
                  <Col className="col-md-3 col-6 mb-3">
                    <label className="form-label"> Stage </label>
                    <input type="text" className="form-control form-control-sm" placeholder="Stage" />
                  </Col> 
                  <Col className="col-md-3 col-6 mb-3">
                    <label className="form-label"> Response </label>
                    <input type="text" className="form-control form-control-sm" placeholder="Response" />
                  </Col> 
                  <Col className="col-md-3 col-6 mb-3">
                    <label className="form-label"> Next Followup Due </label>
                    <input type="date" className="form-control form-control-sm" placeholder="Email Official" />
                  </Col> 
                 
                  <hr className="bg-black-500" />
                </Row>  

                <Row>
                  <Col className="col-8 mb-3">
                  <p className="fontWeight500 font-14 mb-1"> Followup 2 </p>
                  </Col>
                  <Col lg={4}>
                        <button  type="button" className="btn btn-danger float-end mb-2 deleteMultipleImages float-right">
                            <i className="bx bx-trash d-block font-size-16"></i>
                        </button>
                        </Col>
                </Row>
                <Row>
                  <Col className="col-md-3 col-6 mb-3">
                    <label className="form-label"> Date </label>
                    <input type="date" className="form-control form-control-sm" placeholder="Name" />
                  </Col> 
                  <Col className="col-md-3 col-6 mb-3">
                    <label className="form-label"> Stage </label>
                    <input type="text" className="form-control form-control-sm" placeholder="Stage" />
                  </Col> 
                  <Col className="col-md-3 col-6 mb-3">
                    <label className="form-label"> Response </label>
                    <input type="text" className="form-control form-control-sm" placeholder="Response" />
                  </Col> 
                  <Col className="col-md-3 col-6 mb-3">
                    <label className="form-label"> Next Followup Due </label>
                    <input type="date" className="form-control form-control-sm" placeholder="Email Official" />
                  </Col> 
                  <hr className="bg-black-500" />
                </Row>  

                <Row>
                  <Col className="col-12 mb-3">
                  <a className="fontWeight500 font-14 mb-1"> <i className="fas fa-plus"></i> Add Another Followup </a>
                  </Col>
                </Row>

                
                <Row>
                  <Col className="col-12 mb-3">
                    <a className="btn btn_success font-14 me-2" href="#"> Save </a>
                    <a className="btn btn_danger font-14 me-2" href="#"> Cancel </a>
                  </Col>
                </Row>








      </div>
    </div>
  </div>
</div>





      </React.Fragment>
      
    )
  }
}

export default DatatableTables