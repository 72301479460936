import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';

const OnSort = () => {
  let columns = [
    {
      key: "SR",
      text: "#",
      sortable: true
    },
    {
      d: "action",
      text: "Network Source Reg. Date",
      cell: (record, index) => {
        let VCompanyName = record["CompanyName"];
        let VRegisterDate = record["RegisterDate"];
        return (
          <Fragment>
            <p className="displayemail">{VCompanyName}</p>
            <p>{VRegisterDate}</p>
          </Fragment>
        );
      }
    },
    {
      d: "action",
      text: "Network Type Branch code",
      cell: (record, index) => {
        let VChannelTypeName = record["ChannelTypeName"];
        let VBranchcode = record["Branchcode"];
        return (
          <Fragment>
            <p className="displayemail">{VChannelTypeName}</p>
            <p>{VBranchcode}</p>
          </Fragment>
        );
      }
    },
    {
      d: "action",
      text: "Email & Mobile No.",
      cell: (record, index) => {
        let VEmail = record["Email"];
        let VMobilenumber = record["Mobilenumber"];
        return (
          <Fragment>
            <p className="displayemail">{VEmail}</p>
            <p>{VMobilenumber}</p>
          </Fragment>
        );
      }
    },
    {
      key: "ActivitiesStatus",
      text: "Activities Status",
      sortable: true
    },
    {
      key: "TotalAmount",
      text: "Total Amount",
      sortable: true
    },
    {
      key: "TotalRecivedAmount",
      text: "Recived Amount",
      sortable: true
    },
    {
      key: "TotalDueAmount",
      text: "Due Amount",
      sortable: true
    },
    {
      d: "Assign Manager",
      text: "Assign Manager",
      width: 250,
      cell: (record, index) => {
        let VAssignManagerName = record["AssignManagerName"] == null ? '' : record["AssignManagerName"];
        return (
          <Fragment>
            {VAssignManagerName == '' ?
              RightsAssignManager == "A" ?
                <a className="me-3 text-primary " onClick={(AssignManager.bind(this, record))} data-bs-toggle="modal" data-bs-target="#MPAssignManager">Assign Manager</a> : ''
              :
              VAssignManagerName}

            {VAssignManagerName == '' ?
              ''
              :
              RightsAssignManager == "A" ? <br /> : ''}

            {VAssignManagerName == '' ?
              ''
              :
              RightsChangeManager == "A" ? <a className="me-3 text-primary " onClick={(AssignManager.bind(this, record))} data-bs-toggle="modal" data-bs-target="#MPAssignManager"><i className="mdi mdi-pencil font-size-14 managerAlign"></i> Change Manager</a> : ''}
          </Fragment>
        );
      }
    },
    {
      d: "action",
      text: "Action",
      cell: (record, index) => {
        return (
          <Fragment>
            {RightsUpdate == "A" ? <a className="btn btn-primary btn-sm float-end font-14 me-2 mb-2" onClick={(editRecord.bind(this, record))}><i className="mdi mdi-pencil font-size-10"></i> Edit</a> : ''}
            <a className="btn btn-primary btn-sm float-end font-14 me-2" onClick={(FileUploadManageRecord.bind(this, record))}>File Upload Manage</a>
          </Fragment>
        );
      }
    }
  ];

  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
    // button: {
    //     excel: true,
    //     print: true,
    //     csv: true
    // }
  }
  const [records, setRecords] = useState([])
  const [ManagerList, setManagerList] = useState([]);
  const [ManagerName, setManagerName] = useState([]);

  const [RightsAdd, setRightsAdd] = useState("D");
  const [RightsUpdate, setRightsUpdate] = useState("D");
  const [RightsDelete, setRightsDelete] = useState("D");

  const [RightsAssignManager, setRightsAssignManager] = useState("D");
  const [RightsChangeManager, setRightsChangeManager] = useState("D");

  const [CorporateLeadId, setCorporateLeadId] = useState(0)

  const [CorSerPackageList, setCorSerPackageList] = useState([]);

  let history = useHistory();
  useEffect(() => {

    const f = {
      MenuId: "57",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {
        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add') {
            setRightsAdd(MS);
          }
          else if (MA == 'Update') {
            setRightsUpdate(MS);
          }
          else if (MA == 'Delete') {
            setRightsDelete(MS);
          }
          else if (MA == 'Assign Manager') {
            setRightsAssignManager(MS);
          }
          else if (MA == 'Change Manager') {
            setRightsChangeManager(MS);
          }
        }
      });

    getManagerList();
    fetch(global.APIURL + "/CorporateRegisterClientList")
      .then((res) => res.json())
      .then((json) => {
        setRecords(json);
      })
  }, []);
  const getManagerList = async () => {
    fetch(global.APIURL + "/UserList/A")
      .then((req) => req.json())
      .then((json) => {
        setManagerList(json)
      })
  };

  const editRecord = (record) => {
    let id = record["CorporateleadId"];
    localStorage.setItem('EditCorporateRegisterClientId', id);
    let path = `EditRegisterClient`;
    history.push(path);
  }

 

  const AssignManager = (record) => {
    let id = record["CorporateleadId"];
    setCorporateLeadId(id);
    setManagerName(record["AssignManagerName"]);
  }

  const exportPDF = () => {

    const unit = "pt";
    const size = "A3";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Register Client List Report";
    const headers = [["#", "Register Date", "Name", "Email", "Mobile Number", "Network Source", "Network Type", "Branch Code", "TotalAmount", "TotalRecivedAmount", "Total Due Amount"]];

    const data = records.map((item, index) => [index + 1, item.RegisterDate, item.Name, item.Email, item.Mobilenumber, item.CompanyName, item.ChannelTypeName, item.Branchcode, item.TotalAmount, item.TotalRecivedAmount, item.TotalDueAmount]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("RegisterClientListReport.pdf")
  }

  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }

  const btnAssignManagerClick = async (e) => {
    e.preventDefault();

    const CorporateLeadAssignManageArr = {
      CorporateLeadId: CorporateLeadId,
      ManagerId: $("#ddlManager").val()
    }
    axios.post(global.APIURL + '/CorporateLeadAssignManage', CorporateLeadAssignManageArr)
      .then(res => {
        if (res.data != null) {
          if (res.data != '') {
            $("#ddlManager").val('0');
            fetch(global.APIURL + "/CorporateRegisterClientList")
              .then((res) => res.json())
              .then((json) => {
                setRecords(json)
              })
          }
        }
      });
  }

  const FileUploadManageRecord = (record) => {
    let id = record["CorporateleadId"];
    localStorage.setItem('AdminCorporateRegisterClientId', id);
    localStorage.setItem('AdminCorporateRegisterUserId', 0);
    const CorporateData = {
      Id: id,
    }
    axios.post(global.APIURL + '/CorporateServicesPackageBureau', CorporateData)
      .then(res => {
        let SArray = [];
        for (let i = 0; i < res.data.ServicesPackage.length; i++) {
          let VCorporateServicesPackageList = res.data.ServicesPackage[i];
          let VSerPackageId = VCorporateServicesPackageList.ServicesPackageId;
          let SerBureauList = [];
          SerBureauList = res.data.ServicesPackageBureau.filter(x => x.ServiceId == VSerPackageId);
          let BArray = [];
          for (let j = 0; j < SerBureauList.length; j++) {
            const SItem = {
              VBId: SerBureauList[j].BId,
              VServiceId: SerBureauList[j].ServiceId,
              VTitle: SerBureauList[j].Title
            }
            BArray.push(SItem);
          }
          const SItem2 = {
            VId: VCorporateServicesPackageList.Id,
            ServicesPackageId: VCorporateServicesPackageList.ServicesPackageId,
            ServiceTitle: VCorporateServicesPackageList.ServiceTitle,
            BArray: BArray
          }
          SArray.push(SItem2);
          setCorSerPackageList(SArray);
        }
      });
    $('#MPRegisterServices').modal('show');
    //window.location.href = "/ServicesFileUploadManage";
  }

  const ManageRedirection = (item1, item2) => {
    localStorage.setItem("CorServiceBureauId", parseInt(item2.VBId == null ? '0' : item2.VBId == '' ? '0' : item2.VBId));
    localStorage.setItem("CorServicePackageId", parseInt(item1.VId == null ? '0' : item1.VId == '' ? '0' : item1.VId));
    window.location.href = "/ServicesFileUploadManage";
  }


  return (
    <div className="page-content">
      <MetaTags>
        <title>Register Client</title>
      </MetaTags>

      <div className="modal fade" id="MPAssignManager" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <div>
                <h5 className="modal-title" id="exampleModalLabel">Assign Manager</h5>
              </div>
              <div>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </div>
            <div className="modal-body">
              <Row>
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label htmlFor="formFileSm" className="form-label">Manager</label>
                    <select id="ddlManager" className="form-select" aria-label="Default select example">
                      <option selected value="0">Select Manager</option>
                      {ManagerList.map(item => (
                        <option key={item.UserId} value={item.UserId} selected={item.Name == ManagerName}>
                          {item.Name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button className="btn btn_success btn-sm" id="btnsave" onClick={btnAssignManagerClick} data-bs-dismiss="modal" aria-label="Close">Save</button>
                  <button className="btn btn_danger btn-sm ms-2" id="btnClose" data-bs-dismiss="modal" aria-label="Close">Close</button>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="MPRegisterServices" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <div>
                <h5 className="modal-title" id="exampleModalLabel">Select Services Package & Bureau</h5>
              </div>
              <div>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </div>
            <div className="modal-body">
              <Row>
                <div className="col-lg-12">
                  <div className="mb-3">
                  <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col" className='text-center'>#</th>
                              <th scope="col" className='text-center'>Services Package</th>
                              <th className="text-center">Manage Bureau</th>
                            </tr>
                          </thead>
                          <tbody>
                            {CorSerPackageList?.map((item, idx) => (
                              <tr key={idx}>
                                <td className='text-center'>{idx + 1}</td>
                                <td className='text-center'>{item.ServiceTitle}</td>
                                <td className='text-center'>{item.BArray.map((item2, idx) => (<a className="btn btn-primary btn-sm font-14 btncolor Bureaubtn" onClick={ManageRedirection.bind(this, item, item2)} key={idx}>{item2.VTitle}</a>))}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>


      <div className="container-fluid">
        <Row className="mb-3">

          <Col className="col-lg-6 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle>Register Corporate Client</CardTitle>
              <a href="/" className="me-2">Home</a>{" "}
              <span>/</span>
              <span className="ms-2">Register Corporate Client</span>
            </div>


          </Col>
          <Col className="col-lg-6 co-md-6 col-6">
            <div className="d-flex align-items-center justify-content-end ">

              <div>
                {RightsAdd == "A" ? <a className="btn btn-primary btn-sm float-end font-14 me-2" href="/CorLeads/Addcorleads">
                  <i className="mdi mdi-plus align-middle me-1"></i>Add New
                </a> : ''}
                <ReactHtmlTableToExcel
                  className="btn btn-primary btn-sm float-end font-14 me-2"
                  table="emp"
                  filename="RegisterListReport"
                  sheet="Sheet"
                  buttonText="Export Excel" />
                <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
              </div>

            </div>
          </Col>



        </Row>
        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
        <table id="emp" className="table" hidden="hidden">
          <thead>
            <tr>
              <th>#</th>
              <th>Register Date</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile Number</th>
              <th>Network Source</th>
              <th>Network Type</th>
              <th>Branch Code</th>
              <th>Total Amount</th>
              <th>Total Received Amount</th>
              <th>Total Due Amount</th>

            </tr>
          </thead>
          <tbody>
            {
              records.map((item, index) => {
                return <tr key={index}>
                  <td> {index + 1} </td>
                  <td>{item.RegisterDate}</td>
                  <td>{item.Name}</td>
                  <td>{item.Email}</td>
                  <td>{item.Mobilenumber}</td>
                  <td>{item.CompanyName}</td>
                  <td>{item.ChannelTypeName}</td>
                  <td>{item.Branchcode}</td>
                  <td>{item.TotalAmount}</td>
                  <td>{item.TotalRecivedAmount}</td>
                  <td>{item.TotalDueAmount}</td>

                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OnSort;