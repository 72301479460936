import React, { Fragment, useEffect, useState } from "react";

import MetaTags from "react-meta-tags";
import img1 from "assets/images/users/avatar-1.jpg";
import img2 from "assets/images/PAN.png";
import img3 from "assets/images/pdf.png";
import bank from "assets/images/bank-nm.png";
import axios from 'axios'
import ReactDatatable from '@ashvin27/react-datatable';
//Import Breadcrumb
import { Col, Row, CardTitle, Card, CardBody, Input } from "reactstrap";


import Bank_source from "assets/images/bank-nm.png";

// Rating Plugin

const UiRating = () => {
  const [FullName, setFullName] = useState('');
  const [MobileNumber, setMobileNumber] = useState('');
  const [EmailAddress, setEmailAddress] = useState('');
  const [LeadType, setLeadType] = useState('');
  const [Gender, setGender] = useState('');
  const [CIRID, setCIRID] = useState('');
  const [CIRPassword, setCIRPassword] = useState('');
  const [StateId, setStateId] = useState('');
  const [DistrictId, setDistrictId] = useState('');
  const [CityId, setCityId] = useState('');
  const [TehsilId, setTehsilId] = useState('');
  const [PinCode, setPinCode] = useState('');
  const [Remarks, setRemarks] = useState('');
  const [ClientBriefing, setClientBriefing] = useState('');
  const [PriorityId, setPriorityId] = useState('');
  const [SelectTags, setSelectTags] = useState('');
  const [StateList, setStateList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);
  const [ChannelId, setChannelId] = useState("0");
  const [BranchId, setBranchId] = useState("0");

  const [Date, setDate] = useState([]);
  const [Time, setTime] = useState([]);
  const [mode, setmode] = useState([]);
  const [Notes, setNotes] = useState([]);






  useEffect(() => {
    let LeadId = localStorage.getItem("IndividualLeadId");

    if (LeadId != null) {
      if (LeadId != "0") {
        //alert(LeadId); 
        getStateList('1');
        getLeadDetailById(LeadId);

      }
      else {
        history.push("/MyFollowUp");
      }
    }
    else {
      history.push("/MyFollowUp");
    }
  }, []);

  const getStateList = async () => {
    let CId = 1;
    var AUrl=global.APIURL+'/ActiveState/'+CId;
    axios.get(AUrl)
      .then(Sres => {
        let StateL = Sres.data.map((kcstate) => {
          return kcstate
        })
        setStateList(StateL);
      })
  }

  const getDistrictList = async () => {
    let SId = 1;
    var AUrl=global.APIURL+'/ActiveDistrict/'+SId;
    axios.get(AUrl)
      .then(Dres => {
        let DistrictL = Dres.data.map((kcdistrict) => {
          return kcdistrict
        })
        setDistrictList(DistrictL);
      })
    var CAUrl=global.APIURL+'/ActiveCity/'+SId;
    axios.get(CAUrl)
      .then(Cires => {
        let CityL = Cires.data.map((kccity) => {
          return kccity
        })
        setCityList(CityL);
      })
  }

  const getTehsilList = async () => {
    let DId = 1;
    var CAUrl=global.APIURL+'/ActiveTehsil/'+DId;
    axios.get(CAUrl)
      .then(Dres => {
        let TehsilL = Dres.data.map((kctehsil) => {
          return kctehsil
        })
        setTehsilList(TehsilL);
      })
  }




  const getLeadDetailById = async (LId) => {
    var AUrl=global.APIURL+'/WebFollowupEdit/'+LId;
    axios.get(AUrl)
      .then(res => {
        setFullName(res.data[0].FullName);
        setMobileNumber(res.data[0].ContactNumber1);
        setEmailAddress(res.data[0].EmailId1);
        setLeadType(res.data[0].LeadType);
        setGender(res.data[0].Gender);
        setCIRID(res.data[0].CIRID);
        setCIRPassword(res.data[0].CIRPassword);
        setStateId(res.data[0].StateId);
        getDistrictList(res.data[0].StateId);
        setDistrictId(res.data[0].DistrictId);
        getTehsilList(res.data[0].DistrictId);
        setCityId(res.data[0].CityId);
        setTehsilId(res.data[0].TehsilId);
        setPinCode(res.data[0].Pincode);
        setRemarks(res.data[0].Remarkdescription);
        setClientBriefing(res.data[0].ClientBriefing);
        setPriorityId(res.data[0].PriorityId);
        setSelectTags(res.data[0].Tags);
        setChannelId(res.data[0].ChannelId);
        setBranchId(res.data[0].BankId);
        setmode(res.data[0].Mode);
        setDate(res.data[0].ScheduledDate);
        setTime(res.data[0].ScheduledTime);

      })
  }


  useEffect(() => {
    
    fetch(global.APIURL+"/WebFollowupList")
      .then((res) => res.json())
      .then((json) => {
        setRecords(json)

      })
  });
  const [records, setRecords] = useState([])

   
    // let id = record["Id"]
    const updateProduct = async (e) => {
     e.preventDefault();
      let Remarks = event.target["txtRemarkscheck"].value;
      let UTId = records[0].FollowUpId;
      if (Remarks != '') {
        const remark = {
          Id: UTId == '' ? '0' : UTId,
          Remark: Remarks,
          Flag: "A"
        }
       
        axios.post(global.apiurl+'/WebFollowupupdateRemark', remark)
          .then(res => {
            if (res.data.length>0) {
              alert(res.data);
              history.push("/FollowupClientInformation");
            }
          });
      }


    
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Client Follow-Up Information | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">
          <div className="modal fade" id="edit" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Remark</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={updateProduct}>

                    <Row>
                      <Col className="col-lg-12">
                        <div className="mb-3">
                          <label htmlFor="exampleFormControlTextarea1" className="form-label">Remarks</label>
                          {/* <textarea className="form-control" id="txtRemarks"  rows="3"></textarea> */}
                          <Input type="text" className="" id="txtRemarkscheck" />
                    
                        </div>
                      </Col>
                      <Col className="text-center">
                        <button type="button" className="btn btn-primary">Submit</button>
                      </Col>
                    </Row>
                  </form>
                </div>

              </div>
            </div>
          </div>
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle>Client Follow-Up Information</CardTitle>
                <a href="#" className="me-2">
                  My Follow Up
                </a>{" "}
                <span>/</span>
                <span className="ms-2">Client Follow-Up Information</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a
                className="btn btn-primary btn-sm float-end font-14"
                href="/MyFollowUp"
              >
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>


          <Row>
            <Col className="col-lg-12 col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center">

                    <Col className="col-md-10 col-12">
                      <Row>
                        <Col className="col-12">
                          <h5 className="fw-bolder mb-2">   {FullName} </h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-lg-3">

                          <p className="mb-1 text-muted"> Mobile Number </p>
                          <Input type="text" className="" id="txtMobileNumber" defaultValue={MobileNumber} placeholder="Enter Mobile Number" />

                        </Col>
                        <Col className="col-lg-3">

                          <p className="mb-1 text-muted"> Email Address </p>
                          <Input type="text" className="" id="txtEmailAddress" defaultValue={EmailAddress} placeholder="Enter Email Address" />

                        </Col>

                        <Col className="col-lg-3">

                          <p className="mb-1 text-muted">Type </p>
                          <Input type="text" className="" id="txtType" defaultValue={LeadType} placeholder="Enter Type" />

                        </Col>

                      </Row>
                    </Col>
                    <Col className="col-md-2 col-lg-2 col-12 float-end">
                      {/* <div className="bg-light rounded-2 height250px pt-3">
                                              <a className=" bg-dark text-light rounded-circle p-2 ms-2"> <i className="fas fa-pencil-alt"></i> </a>    
                                          </div> */}
                      <div className="">
                        <img className="rounded-circle float-end clientactimg" src={img1}></img>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

            </Col>
          </Row>

          <Row>
            <Col className="col-lg-12 col-md-12 col-12">
              <Card>
                <CardBody>
                  <Row>
                    <CardTitle>Personal Details</CardTitle>
                    <Col className="col-lg-3 mb-2">

                      <p className="mb-1 text-muted"> Gender </p>
                      <Input type="text" className="" id="txtGender" defaultValue={Gender} placeholder="Enter Gender" />

                    </Col>
                    <Col className="col-lg-3 mb-2">

                      <p className="mb-1 text-muted"> CIR ID </p>
                      <Input type="text" className="" id="txtCIRID" defaultValue={CIRID} placeholder="Enter CIRID" />

                    </Col>
                    <Col className="col-lg-3 mb-2">

                      <p className="mb-1 text-muted"> CIR Password </p>
                      <Input type="password" className="" id="txtCIRPassword" defaultValue={CIRPassword} placeholder="Enter CIRPassword" />

                    </Col>
                    <Col className="col-lg-3 mb-2">

                      <p className="mb-1 text-muted"> State </p>
                      <select className="form-select" name="ddlStateList" onChange={(e) => getDistrictList(e.target.value)}>
                        <option key="0" value="0">Select State</option>
                        {StateList.map((item) => (
                          <option key={item.StateId} value={item.StateId}>
                            {item.Title}
                          </option>
                        ))}
                      </select>

                    </Col>
                    <Col className="col-lg-3 mb-2">

                      <p className="mb-1 text-muted"> District </p>
                      <select className="form-select" name="ddlDistrictList" onChange={(e) => getTehsilList(e.target.value)}>
                        <option key="0" value="0">Select District</option>
                        {DistrictList.map((item) => (
                          <option key={item.DistrictId} value={item.DistrictId}>
                            {item.Title}
                          </option>
                        ))}
                      </select>

                    </Col>
                    <Col className="col-lg-3 mb-2">

                      <p className="mb-1 text-muted"> City </p>
                      <select className="form-select" name="ddlCityList">
                        <option key="0" value="0">Select City</option>
                        {CityList.map((item) => (
                          <option key={item.CityId} value={item.CityId}>
                            {item.Title}
                          </option>
                        ))}
                      </select>

                    </Col>
                    <Col className="col-lg-3 mb-2">

                      <p className="mb-1 text-muted"> Tehsil </p>
                      <select className="form-select" name="ddlTehsilList">
                        <option key="0" value="0">Select Tehsil</option>
                        {TehsilList.map((item) => (
                          <option key={item.TehsilId} value={item.TehsilId}>
                            {item.Title}
                          </option>
                        ))}
                      </select>

                    </Col>
                    <Col className="col-lg-3 mb-2">

                      <p className="mb-1 text-muted"> PinCode </p>
                      <Input type="text" className="" id="txtPinCode" defaultValue={PinCode} placeholder="Enter PinCode" />

                    </Col>
                    <Col className="col-lg-3 mb-2">

                      <p className="mb-1 text-muted"> Mobile Number </p>
                      <Input type="text" className="" id="txtMobileNumber" defaultValue={MobileNumber} placeholder="Enter Mobile Number" />

                    </Col>
                    <Col className="col-lg-3  mb-2">

                      <p className="mb-1 text-muted"> Email Address </p>
                      <Input type="text" className="" id="txtEmailAddress" defaultValue={EmailAddress} placeholder="Enter Email Address" />

                    </Col>
                  </Row>
                  <hr />
                </CardBody>
              </Card>
            </Col>

          </Row>
          <Row>
            <Col className="col-lg-6">
              <Card>
                <CardBody>
                  <div className="">
                    <Card>
                      <CardBody>
                        <div className="">
                          <CardTitle>Lead Followup</CardTitle>
                          <hr />
                        </div>
                        <Row>
                          <ul className="nav client-detail-firm border-0 nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button className="nav-link border-0 active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"> <div className="form-check me-1 form-check-inline mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radio1"
                                  id="1"
                                  value="success"
                                  checked
                                />
                                <label className="form-check-label">
                                  Followup
                                </label>
                              </div></button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link border-0" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"> <div className="form-check me-1 form-check-inline  mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radio1"
                                  id="2"
                                  value="success"
                                />
                                <label className="form-check-label">
                                  Convert
                                </label>
                              </div></button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link border-0" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">  <div className="form-check me-1 form-check-inline  mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radio1"
                                  id="3"
                                  value="success"
                                />
                                <label className="form-check-label"> Close</label>
                              </div></button>
                            </li>
                          </ul>
                          <Col>

                            <div className="tab-content" id="myTabContent">
                              <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <Row>
                                  <Col>
                                    {" "}
                                    <CardTitle className="mt-2">Set Followup</CardTitle>
                                    <hr />
                                  </Col>
                                </Row>
                                <Row>
                                  <div className="col-md-12 mb-2">
                                    <label className=""> Next Follow up Date </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      placeholder=""
                                      value={Date}
                                    />
                                  </div>

                                  <div className="col-md-12 mb-2 mt-2">
                                    <label className=""> Next Follow up Time </label>
                                    <input
                                      type="time"
                                      className="form-control"
                                      placeholder=""
                                      value={Time}
                                    />
                                  </div>
                                  <div className="col-md-12 mb-2 mt-2">
                                    <label className=""> Next Follow up Mode </label>
                                    <Input type="text" className="" id="txtMode" defaultValue={mode} placeholder="Enter Mode" />

                                  </div>
                                </Row>
                                <Row>
                                  <div className="mb-3 mt-2">
                                    <label className="">
                                      {" "}
                                      Notes for Next Follow-up{" "}
                                    </label>
                                    <textarea
                                      className="form-control"
                                      placeholder="Enter Notes for Next Follow-up"
                                    >
                                      {" "}
                                    </textarea>
                                  </div>
                                </Row>
                                <Row>
                                  <div className="">
                                    <a href="#" className="btn btn_success me-2">
                                      {" "}
                                      Submit{" "}
                                    </a>
                                    <a href="#" className="btn btn_danger me-2">
                                      {" "}
                                      Cancel{" "}
                                    </a>
                                  </div>
                                </Row>
                              </div>
                              <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <Row>
                                  <Col>
                                    {" "}
                                    <CardTitle className="mt-2">Client to Convert</CardTitle>
                                    <hr />
                                  </Col>
                                </Row>
                                <Row>
                                  <div className="col-md-12 mb-2">
                                    <label className=""> Convert Date </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      placeholder=""
                                    />
                                  </div>
                                </Row>
                                <Row>
                                  <div className="mb-3 mt-2">
                                    <label className="">
                                      Remarks
                                    </label>
                                    <textarea
                                      className="form-control"
                                      placeholder="Enter Notes for Next Follow-up"
                                      id="txtRemarks"
                                    >
                                      {" "}
                                    </textarea>
                                  </div>
                                </Row>
                                <Row>
                                  <div className="">
                                    <a href="#" className="btn btn_success me-2">
                                      {" "}
                                      Submit{" "}
                                    </a>
                                    <a href="#" className="btn btn_danger me-2">
                                      {" "}
                                      Cancel{" "}
                                    </a>
                                  </div>
                                </Row>
                              </div>
                              <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                <Row>
                                  <Col>
                                    {" "}
                                    <CardTitle className="mt-2">Client to Close</CardTitle>
                                    <hr />
                                  </Col>
                                </Row>
                                <Row>
                                  <div className="col-md-12 mb-2">
                                    <label className=""> Closure Date </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      placeholder=""
                                    />
                                  </div>
                                </Row>
                                <Row>
                                  <div className="mb-3 mt-2">
                                    <label className="">
                                      {" "}
                                      Reason For Closure{" "}
                                    </label>
                                    <textarea
                                      className="form-control"
                                      placeholder="Enter Notes for Next Follow-up"
                                    >
                                      {" "}
                                    </textarea>
                                  </div>
                                </Row>
                                <Row>
                                  <div className="">
                                    <a href="#" className="btn btn_success me-2">
                                      {" "}
                                      Submit{" "}
                                    </a>
                                    <a href="#" className="btn btn_danger me-2">
                                      {" "}
                                      Cancel{" "}
                                    </a>
                                  </div>
                                </Row>
                              </div>
                            </div>

                          </Col>
                        </Row>

                      </CardBody>
                    </Card>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-lg-6">
              <Card>
                <CardBody>
                  <div className="">
                    <Card>
                      <CardBody>
                        <div className="">
                          <CardTitle>Client Briefing</CardTitle>
                          <hr />
                        </div>
                        <Row>
                          <Col className="col-lg-12">
                            <div className="mb-3">
                              <textarea className="form-control" id="txtClientBriefing" defaultValue={ClientBriefing} rows="10"></textarea>
                            </div>

                            <div className="">
                              <a href="#" className="btn btn_success me-2">
                                {" "}
                                Save{" "}
                              </a>
                            </div>

                          </Col>
                        </Row>

                      </CardBody>
                    </Card>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Row>

            <Card>
              <CardBody>
                <CardTitle>Followup List</CardTitle>
                <hr />

                <Col className="col-lg-12">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th className="text-center" scope="col">Date</th>
                          <th className="text-center" scope="col">Time</th>
                          <th className="text-center" scope="col">Mode</th>
                          <th scope="col">Notes for Next Follow-up</th>
                          <th scope="col">Remarks</th>
                          <th className="text-center" scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records.map((record, key) => {
                          return (
                            <tr key={key}>
                              <td>{record.ScheduledDate}</td>
                              <td>{record.ScheduledTime}</td>
                              <td>{record.Mode}</td>
                              <td></td>
                              <td>{record.Remark}</td>
                              <td className="text-center"> <a
                                href="/EditFollowUp"
                                data-bs-toggle="modal"
                                data-bs-target="#edit"
                                className="btn btn_success me-2 btn-sm-height"
                               
                              >
                                {" "}
                                Edit{" "}
                              </a></td>
                            </tr>
                          )
                        })}
                      </tbody>

                    </table>
                  </div>

                </Col>
              </CardBody>
            </Card>
          </Row>


        </div>
      </div>
    </React.Fragment>
  );
}

export default UiRating;
