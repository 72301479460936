import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';

const OnSort = () => {
  let columns = [
    {
      d: "#",
      text: "#",
      cell: (record, index) => {
        let RowIndex = index + 1;
        return (
          <Fragment>
            {RowIndex}
          </Fragment>
        );
      }
    },
    {
      d: "FullName",
      text: "Full Name",
      cell: (record, index) => {
        let FullName = record["FullName"];
        let LeadCode = record["LeadCode"];
        let Tag = record["Tag"];
        let CreateDate = record["CreateDate"];
        let LeadType = record["LeadType"];
        let VLeadProfileImage = record["LeadProfileImage"];
        return (

          <a href={RedirectLeadDetail.bind(this, record)} className="text-heading nowrap">
            <div className="d-flex">
              <div className="flex-shrink-0">
                <img
                  src={VLeadProfileImage}
                  alt=""
                  className="img-thumbnail img-leads rounded-circle"
                />
              </div>
              <div className="flex-grow-1 ms-1">
                <p className="mb-1">{FullName}</p>
                <p className="text-muted mb-0">{LeadCode}</p>
                <span className="badge badge-soft-primary px-2 py-1">{Tag}</span>
                <p className="text-muted mb-0">{CreateDate}</p>
                <p className="text-muted mb-0">{LeadType}</p>
              </div>
            </div>
          </a>

        );
      }
    },
    {
      key: "CloseDate",
      text: "Close Date",
      sortable: true
    },
    {
      d: "Source",
      text: "Source ",
      cell: (record, index) => {
        let ChannelName = record["ChannelName"];
        let ChannelCode = record["ChannelCode"];
        let City = record["City"]
        return (
          <Fragment>
            {ChannelName}
            {ChannelCode == null ? '' : ChannelCode == '' ? '' : <p className="mb-1 text-success">ID : #{ChannelCode} </p>}
            {City}
          </Fragment>
        );
      }
    },
    {
      d: "SubSource",
      text: "Sub Source ",
      cell: (record, index) => {
        let ChannelPartnerName = record["ChannelPartnerName"];
        let Designation = record["Designation"];
        return (
          <Fragment>
            <p className="mb-1 text-wrap1">{ChannelPartnerName}</p>
            <p className="mb-1 text-wrap1">{Designation}</p>
          </Fragment>
        );
      }
    },
    {
      d: "Email & Contact Number",
      text: "Email & Contact Number ",
      cell: (record, index) => {
        let Email = record["EmailAddress"];
        let ContactNumber = record["MobileNo"];
        return (
          <Fragment>
            <p className="mb-1 text-wrap1">{Email}</p>
            <p className="mb-1 text-wrap1">{ContactNumber}</p>
          </Fragment>
        );
      }
    },
    {
      d: "Tags & Priority",
      text: "Tags & Priority",
      cell: (record, index) => {
        let Priority = record["Priority"];
        let Tags = record["Tags"];
        return (
          <Fragment>
            <span className="badge badge-soft-primary font-12">{Tags}</span>
            <br />
            <span className="badge bg-danger px-2 py-1">{Priority}</span>
          </Fragment>
        );
      }
    },
    {
      d: "action",
      text: "Action",
      cell: (record, index) => {
        return (
          <Fragment>
            {Edit == "Update" ? <a className="me-3 text-primary" onClick={RedirectLeadDetail.bind(this, record)}><i className="mdi mdi-pencil font-size-18"></i></a> : null}
            {Edit == "Delete" ? <a className={IconrenderSwitchColor(record)}><i className={IconrenderSwitch(record)}></i></a> : null}
          </Fragment>
        );
      }
    }
  ];
  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
    button: {
      excel: true,
      print: true,
      csv: true
    }
  }
  const [records, setRecords] = useState([])
  const [Edit, setEdit] = useState("");
  let history = useHistory();
  const [ChannelTypeId, setChannelTypeId] = useState(0);
  const [ChannelMasterList, setChannelMasterList] = useState([]);
  const [ChannelTypeList,setChannelTypeList]=useState([]);
  const [ChannelMasterId, setChannelMasterId] = useState(0);
  const [ChannelPartnerList, setChannelPartnerList] = useState([]);
  const [ChannelPartnerId,setChannelPartnerId]=useState(0);

  function IconrenderSwitch(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'mdi mdi-close font-size-18';
      default:
        return 'mdi mdi-check-all font-size-18';
    }
  }

  const RedirectLeadDetail = (record) => {
    let id = record["LeadId"]
    let LeadType = record["LeadType"]
    if (LeadType == "Individual") {
      localStorage.setItem('ClosedIndividualLeadId', id);
      localStorage.setItem('ClosedFirmLeadId', '0');
      window.location.href = "/ClosedIndividualDetail";
    }
    else if (LeadType == "Firm/Company") {
      localStorage.setItem('ClosedIndividualLeadId', '0');
      localStorage.setItem('ClosedFirmLeadId', id);
      window.location.href = "/ClosedFirmDetail";
    }
  }

  function IconrenderSwitchColor(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }

  function IconrenderSwitch(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'mdi mdi-close font-size-18';
      default:
        return 'mdi mdi-check-all font-size-18';
    }
  }

  function IconrenderSwitchColor(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }

  useEffect(() => {
    const TableFilter = document.getElementsByClassName('table_filter');
    for (const tf of TableFilter) {
      tf.style.display = 'none';
    }
    getWebLeadList();
    getChannelTypeList();
  }, []);

  const getWebLeadList = async () => {
    var QSearch = document.getElementById("txtQuickSearch").value;
    var LeadType = document.getElementById("txtLeadType").value;
    var StartDate = document.getElementById("txtStartDate").value;
    var EndDate = document.getElementById("txtEndDate").value;
    var LeadEnroll = document.getElementById("txtLeadEnroll").value;
    var RecordStatus = document.getElementById("txtRecord").value;

    const LeadData = {
      QuickSearch: QSearch,
      LeadType: LeadType,
      FromDate: StartDate,
      ToDate: EndDate,
      LeadStatus: '',
      LeadEnrollStatus: LeadEnroll,
      RecordStatus: RecordStatus,
      SourceId:ChannelMasterId,
      SubSourceId:ChannelPartnerId
    }

    axios.post(global.APIURL + "/WebClosedLeadList", LeadData)
      .then(CLres => {
      
        let VWebLeadList = CLres.data.map(vcl => {
          return vcl;
        });
        setRecords(VWebLeadList);
      });
  };

  const saveLead = async (e) => {
    getWebLeadList();
  }


  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }

  const exportPDF = () => {

    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Closed List Report";
    const headers = [["#", "FullName", "Lead Code", "Created Date", "Lead Type", "Closed Date", "Source", "Source-City", "Sub-Source", "Sub-Source Designation", "Email", "ContactNumber", "Tags", "Priority"]];

    const data = records.map((item, index) => [index + 1, item.FullName, item.LeadCode, item.CreateDate, item.LeadType, item.CloseDate, item.ChannelName + ` (` + (item.ChannelCode) + `)`, item.City, item.ChannelPartnerName, item.Designation, item.EmailAddress, item.MobileNo, item.Tags, item.Priority]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("ClosedListReport.pdf")
  }

  const getChannelMasterList = async (CTId) => {
    var AUrl = global.APIURL + '/ChannelMasterActiveList/' + CTId;
    fetch(AUrl)
      .then((res) => res.json())
      .then((json) => {
        setChannelMasterList(json)
      })
  }

  
  const getChannelTypeList = async () => {
    fetch(global.APIURL + "/ChannalTypeList")
      .then((res) => res.json())
      .then((json) => {
        setChannelTypeList(json)
      })
  }


  const getModelLeadChannelPartnerList = async CHId => {
    const LeadChannelPartnerFilter = {
      ChannelId: CHId,
      FilterSearchByName: "",
    };
    axios.post(global.APIURL + "/ActiveChannelPartner", LeadChannelPartnerFilter)
      .then(CPLres => {
        let VChannelPartnerList = CPLres.data.map(vcpl => {
          return vcpl;
        });
        setChannelPartnerList(VChannelPartnerList);
      });
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Closed Inquiries | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">
        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-12 mb-2">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Closed Inquiries</CardTitle>
              <a href="/" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <span className="ms-2">Closed Inquiries</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-12 text-end">
            <a className="btn btn-primary btn-sm font-14 me-2 " data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
              <i className="fas fa-filter"></i>
            </a>
            <ReactHtmlTableToExcel
              className="btn btn-primary btn-sm float-end font-14 me-2"
              table="emp"
              filename="ClosedListReport"
              sheet="Sheet"
              buttonText="Export Excel" />
            <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Row>
              <Col xl="12">
                <div className="collapse" id="collapseExample">
                  <div className="card card-body">
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label className="form-label"> Quick search</label>
                        <form className="app-search app-searchnew p-0 d-none d-lg-block">
                          <div className="position-relative"><input type="text" className="form-control" placeholder="Search..." id="txtQuickSearch" /><span className="bx bx-search-alt"></span>
                          </div>
                        </form>
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">Lead Type </label>
                        <select className="form-select" aria-label="Default select example" id="txtLeadType">
                          <option value=''>All</option>
                          <option value="Individual">Individual</option>
                          <option value="Firm/Company">Firm/Company</option>

                        </select>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">Start Date </label>
                          <input type="date" className="form-control" id="txtStartDate" placeholder="name@example.com" />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">End Date </label>
                          <input type="date" className="form-control" id="txtEndDate" placeholder="name@example.com" />
                        </div>
                      </div>

                      <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="" className="form-label">Lead Enroll Status</label>
                        <select className="form-select" aria-label="Default select example" id="txtLeadEnroll">
                          <option value="">All</option>
                          <option value="P">Pending</option>
                          <option value="E">Enrolled</option>

                        </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="" className="form-label">Record Status</label>
                        <select className="form-select" aria-label="Default select example" id="txtRecord">
                          <option value="">All</option>
                          <option value="A">Active</option>
                          <option value="D">In-Active</option>
                        </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                      <div className="mb-3">
                        </div>
                      </div>
                      <div className="col-md-3">
                      <div className="mb-3">
                        </div>
                      </div>

                      <div className="col-md-3">
                      <label className="form-label">Channel Type</label>
                      <select className="form-select" Id="ddlChanneltype" name="ddlChanneltype" onChange={(e) => { setChannelTypeId(e.target.value); getChannelMasterList(e.target.value) }}>
                        <option value={"0"}>Select Channel Type</option>
                        {ChannelTypeList.map((ChannelType) => (
                          <option key={ChannelType.ChannelTypeId} value={ChannelType.ChannelTypeId}> {ChannelType.ChannelTypeName} </option>
                        ))}
                      </select>
                      </div>

                      <div className="col-md-3">
                      <label className="form-label">Channel Master</label>
                      <select className="form-select" Id="ddlChannelMaster" name="ddlChannelMaster" onChange={(e) => {setChannelMasterId(e.target.value);getModelLeadChannelPartnerList(e.target.value) }}>
                        <option value={"0"}>Select Channel Master</option>
                        {ChannelMasterList.map((ChannelMaster) => (
                          <option key={ChannelMaster.ChannelMasterId} value={ChannelMaster.ChannelMasterId}> {ChannelMaster.Title} </option>
                        ))}
                      </select>
                      </div>
                      <div className="col-md-3">
                      <label className="form-label">Channel Partner</label>
                      <select className="form-select" Id="ddlChannelMaster" name="ddlChannelMaster" onChange={(e) =>  setChannelPartnerId(e.target.value)}>
                        <option value={"0"}>Select Channel Master</option>
                        {ChannelPartnerList.map((ChannelPartner) => (
                          <option key={ChannelPartner.ChannelPartnerId} value={ChannelPartner.ChannelPartnerId}> {ChannelPartner.Name} </option>
                        ))}
                      </select>
                      </div>

                      <div className="col-md-12 mt-3 mb-2">
                        <button type="button" className="btn btn-sm btn-success font-14" onClick={saveLead}>Go</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <div>
            <table id="emp" className="table" hidden="hidden">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Full Name</th>
                  <th>Lead Code</th>
                  <th>Created date</th>
                  <th>Lead Type</th>
                  <th>Close Date</th>
                  <th>Source</th>
                  <th>Source City</th>
                  <th>Sub-Source</th>
                  <th>Sub-Source designation</th>
                  <th>Email</th>
                  <th>ContactNumber</th>
                  <th>Tags</th>
                  <th>Priority</th>
                </tr>
              </thead>
              <tbody>              {
                records.map((item, index) => {
                  return <tr key={index}>
                    <td> {index + 1} </td>
                    <td >{item.FullName}</td>
                    <td>{item.LeadCode}</td>
                    <td>{item.CloseDate}</td>
                    <td>{item.LeadType}</td>
                    <td>{item.CloseDate}</td>
                    <td >{item.ChannelName}({item.ChannelCode})</td>
                    <td>{item.City}</td>
                    <td >{item.ChannelPartnerName}</td>
                    <td>{item.Designation}</td>
                    <td >{item.EmailAddress}</td>
                    <td>{item.MobileNo}</td>
                    <td>{item.Tags}</td>
                    <td >{item.Priority}</td>
                  </tr>
                })
              }
              </tbody>
            </table>
          </div>
        </Row>
        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12" className="customreactdatatable  table-responsivenew">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default OnSort;