import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle, } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import "../../../assets/scss/datatables.scss"

// Table data
const products = [



  {
    "id": 1,
    "name": <p className="fw-bold mb-0"> Smith Jain</p> ,
    "total days": "30",
    "total working days":  "27",
    "total week off": "5",
    "total days": "31",
    "total public holiday": "3",
    "present": "24",
    "absent": "3",
    "leave": "2",
    "status": "Status",
  },

  {
    "id": 2,
    "name": <p className="fw-bold mb-0"> Smith Jain </p> ,
    "total days": "30",
    "total working days":  "27",
    "total week off": "5",
    "total days": "31",
    "total public holiday": "3",
    "present": "24",
    "absent": "3",
    "leave": "2",
    "status": "Status",
  },

  {
    "id": 3,
    "name": <p className="fw-bold mb-0"> Smith Jain</p> ,
    "total days": "30",
    "total working days":  "27",
    "total week off": "5",
    "total days": "31",
    "total public holiday": "3",
    "present": "24",
    "absent": "3",
    "leave": "2",
    "status": "Status",
  },

  {
    "id": 4,
    "name": <p className="fw-bold mb-0"> Smith Jain</p> ,
    "total days": "30",
    "total working days":  "27",
    "total week off": "5",
    "total days": "31",
    "total public holiday": "3",
    "present": "24",
    "absent": "3",
    "leave": "2",
    "status": "Status",
  },

  {
    "id": 5,
    "name": <p className="fw-bold mb-0"> Smith Jain</p> ,
    "total days": "30",
    "total working days":  "27",
    "total week off": "5",
    "total days": "31",
    "total public holiday": "3",
    "present": "24",
    "absent": "3",
    "leave": "2",
    "status": "Status",
  },

  {
    "id": 6,
    "name": <p className="fw-bold mb-0"> Smith Jain</p> ,
    "total days": "30",
    "total working days":  "27",
    "total week off": "5",
    "total days": "31",
    "total public holiday": "3",
    "present": "24",
    "absent": "3",
    "leave": "2",
    "status": "Status",
  },

  {
    "id": 7,
    "name": <p className="fw-bold mb-0"> Kartik Singh</p> ,
    "total days": "30",
    "total working days":  "27",
    "total week off": "5",
    "total days": "31",
    "total public holiday": "3",
    "present": "24",
    "absent": "3",
    "leave": "2",
    "status": "Status",
  },

  {
    "id": 8,
    "name": <p className="fw-bold mb-0"> Smith Jain</p> ,
    "total days": "30",
    "total working days":  "27",
    "total week off": "5",
    "total days": "31",
    "total public holiday": "3",
    "present": "24",
    "absent": "3",
    "leave": "2",
    "status": "Status",
  },

  {
    "id": 9,
    "name": <p className="fw-bold mb-0"> Kartik Singh</p> ,
    "total days": "30",
    "total working days":  "27",
    "total week off": "5",
    "total days": "31",
    "total public holiday": "3",
    "present": "24",
    "absent": "3",
    "leave": "2",
    "status": "Status",
  },

  {
    "id": 10,
    "name": <p className="fw-bold mb-0"> Smith Jain</p> ,
    "total days": "30",
    "total working days":  "27",
    "total week off": "5",
    "total days": "31",
    "total public holiday": "3",
    "present": "24",
    "absent": "3",
    "leave": "2",
    "status": "Status",
  },

  {
    "id": 11,
    "name": <p className="fw-bold mb-0"> Kartik Jain</p> ,
    "total days": "30",
    "total working days":  "27",
    "total week off": "5",
    "total days": "31",
    "total public holiday": "3",
    "present": "24",
    "absent": "3",
    "leave": "2",
    "status": "Status",
  },

  {
    "id": 12,
    "name": <p className="fw-bold mb-0"> Smith Jain</p> ,
    "total days": "30",
    "total working days":  "27",
    "total week off": "5",
    "total days": "31",
    "total public holiday": "3",
    "present": "24",
    "absent": "3",
    "leave": "2",
    "status": "Status",
  },




  

];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    const columns = [{
      dataField: 'id',
      text: '#',
      sort: true,
    }, {
      dataField: 'name',
      text: 'Name',
      sort: true
    }, {
      dataField: 'total days',
      text: 'Total Days',
      sort: true
    },  {
      dataField: 'total working days',
      text: 'Total Working Days',
      sort: true
    },{
      dataField: 'total week off',
      text: 'Total Week Off',
      sort: true
    },{
      dataField: 'total public holiday',
      text: 'Total Public Holiday',
      sort: true
    },{
      dataField: 'present',
      text: 'Present',
      sort: true
    },{
      dataField: 'absent',
      text: 'Absent',
      sort: true
    },{
      dataField: 'leave',
      text: 'Leave',
      sort: true
    },{
      dataField: 'status',
      text: 'Status',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

  
    // Select All Button operation
    

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Bulk Attendance | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
            
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                    <CardTitle className="h3">Bulk Attendance</CardTitle>
                      <a href="#" className="me-2">Home</a> <span>/</span><span className="ms-2">Bulk Attendance</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14 margin-20-back" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
            
             
            










            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                   
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
             
                    
                  
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
<Row className="mb-2">
                             
                             <Col className="col-lg-4 col-md-4 col-6">
                               <div className="search-box me-2 mb-2 d-inline-block">
                                 <div className="position-relative">
                                   <SearchBar
                                     {...toolkitProps.searchProps}
                                   />
                                   <i className="bx bx-search-alt search-icon" />
                                 </div>
                               </div>
                             </Col>
                             <Col className="col-lg-8 col-md-8 col-6">
                             <a className ="btn btn_success btn-sm float-end font-14 " data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" href="#"><i className="mdi mdi-plus align-middle me-1"></i>Add</a>
           </Col>
                           </Row>
                              



                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light table-thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30 mt-4">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DatatableTables