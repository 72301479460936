import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle,Dropdown,DropdownItem,DropdownToggle,DropdownMenu, } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

// Table data
const products = [

 
  {
    "id": 1,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 2,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 3,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 4,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 5,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 6,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 7,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 8,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 9,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 10,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 11,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 12,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  

];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    const columns = [{
      dataField: 'id',
      text: '#',
      sort: true,
    }, {
      dataField: 'employee name',
      text:  <span>Employee Name/ <br />Employee Id </span> ,
      sort: true
    }, {
      dataField: 'td',
      text: 'TD',
      sort: true
    }, {
      dataField: 'wd',
      text: 'WD',
      sort: true
    },{
      dataField: 'ph',
      text: 'PH',
      sort: true
    },{
      dataField: 'wo',
      text: 'WO',
      sort: true
    },{
      dataField: 'pd',
      text: 'PD',
      sort: true
    },{
      dataField: 'ad',
      text: 'AD',
      sort: true
    },{
      dataField: 'pl',
      text: 'PL',
      sort: true
    }, {
      dataField: 'pd',
      text: 'PD',
      sort: true
    }, {
      dataField: 'lwp',
      text: 'LWP',
      sort: true
    }, {
      dataField: 'action',
      text: 'Action',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

  
    

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
          <title>Quick Inquiry | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
            
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h3">Quick Inquiry
</CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Quick Inquiry
</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
             
              <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col className="col-md-3">
                   <label>Upload Image</label>
                   
                  <input className="form-control" type="file" id="formFile" />
                     
                     
                  </Col> 
                  <Col className="col-md-3">
                   <label>First Name*</label>
                   <input type="text" className="form-control" placeholder="Default Input" />
                      
                     
                  </Col> 
                  <Col className="col-md-3">
                   <label>Last Name*</label>
                   <input type="text" className="form-control" placeholder="Default Input" />
                      
                     
                  </Col> 
                  <Col className="col-md-3">
                   <label>Mobile Number* </label>
                   <input type="text" className="form-control" placeholder="Default Input" />
                      
                     
                  </Col> 
                </Row>

                <Row  className="mb-3">
                <Col className="col-md-3">
                   <label>Email Address </label>
                   <input type="text" className="form-control" placeholder="Default Input" />
                      
                  </Col> 
                  <Col className="col-md-3">
                   <label>City </label>
                   <input type="text" className="form-control" placeholder="Default Input" />
                      
                     
                  </Col> 
                                
                                        
                
                </Row>

                <Row className="mb-3">
                <Col className="col-md-12">
                 <label>Remarks</label>
 
  <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea">Quidem velit aliquam assumenda provident asperiores est nostrum. Facilis laudantium sunt quod impedit nostrum.  </textarea>
 
                     
                  </Col> 
                                
                                        
                
                </Row>
                <Row>
                  <Col className="col-md-12">
                  <div className=""><a href="" className="btn btn_success me-2"> Save </a><a href="" className="btn btn_danger"> Cancel </a></div>

                  </Col>
                 </Row>

              </CardBody>
              </Card>










            
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DatatableTables