import React, { Component, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags';
import axios from 'axios';
import moment from 'moment';
import { Link } from "react-router-dom";
import Select from "react-select";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import jsPDF from "jspdf";
import 'jspdf-autotable';

const Task = () => {

    const [TaskList, setTaskList] = useState([]);
    const [EmployeeList, setEmployeeList] = useState([]);
    const [TaskId, setTaskId] = useState();
    const [SelectedFile, setSelectedFile] = useState([]);
    const [TodoList, setTodoList] = useState([]);
    const [TaskListTitle, setTaskListTitle] = useState([]);
    const [EditTaskId, setEditTaskId] = useState("");
    const [EditTodoListMsg, setEditTodoListMsg] = useState([]);
    const [EditTodoId, setEditTodoId] = useState([]);
    const [EditTodoIdTaskId, setEditTodoIdTaskId] = useState([]);
    const [EditAssignToUser, setEditAssignToUser] = useState([]);
    const [EditFileName, setEditFileName] = useState([]);
    const [EditDueDate, setEditDueDate] = useState([]);
    const [EditDueTime, setEditDueTime] = useState([]);
    const [EditAssignToUserId, setEditAssignToUserId] = useState([]);
    const [DisplayDocumentFile, setDisplayDocumentFile] = useState([]);
    const [DisplayFile, setDisplayFile] = useState([]);
    const [options, setOptions] = useState([]);
    const [SelectedOption, setSelectedOption] = useState([]);
    const [EditSelectedOption, setEditSelectedOption,] = useState([]);
    const [ToDoStatus, setToDoStatus] = useState([]);


    useEffect(() => {
        getTaskList();
        getActiveUserList();

    }, [])

    const getSelectedFile = (e) => {

        var details = e.target.files[0];
        var details1 = "TodoTaskFiles";
        const formData = new FormData();
        formData.append('Document', details);
        formData.append('FolderName', details1);
        var AUrl = global.APIURL + '/uploadavatar/';
        axios.post(AUrl, formData, {
            headers: { "Content-Type": details.type },
        })
            .then((res) => {
                setSelectedFile(res.data.IName);
                setDisplayFile(res.data.FileName);
                if (e.target.files[0].name != null) {
                    if (e.target.files[0].name != '') {
                        setDisplayDocumentFile(1);
                    }
                    else {
                        setDisplayDocumentFile(0);
                    }
                }
            });
    }

    const handleAddTask = async (e) => {
        e.preventDefault();
        let VTitle = e.target["txtTitle"].value;
        // let VtxtTaskDesc=e.target["txtTaskDesc"].value;
        if (VTitle != "") {
            const TaskArr = {
                action: "Add",
                TaskId: "0",
                RegisterId: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                TaskMessage: VTitle,
                Status: "Pending",
                Flag: "1"
            }
            axios.post(global.APIURL + '/Task', TaskArr)
                .then(res => {
                    alert(res.data.StatusMessage);
                    document.getElementById("txtTitle").value = '';
                    $('#exampleModal').modal('hide');

                });
            getTaskList();
        }

    }
    const handleAddTodoTask = async (e) => {
        e.preventDefault();

        let VMessage = e.target["txtTaskMsg"].value;
        let VDate = e.target["txtDate"].value;
        let VTime = e.target["txtTime"].value;
        let VDateTime = VDate.concat(VTime);
        let filename = SelectedFile;
        let filearray = filename.split('/');
        let VMediafileName = filearray[5];
        let AU = '';
        for (let aus = 0; aus < SelectedOption.length; aus++) {
            const element = SelectedOption[aus].UserId;
            if (AU == '') {
                AU = element
            }
            else {
                AU = AU + ',' + element
            }
        }
        let AUName = "";
        for (let aName = 0; aName < SelectedOption.length; aName++) {
            const ele = SelectedOption[aName].Name;
            if (AUName == "") {
                AUName = ele
            } else {
                AUName = AUName + ',' + ele

            }
        }

        if (AU != "") {
            const TodoArray = {
                action: "Add",
                TodoId: 0,
                TaskId: TaskId,
                RegisterId: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                Message: VMessage,
                mediafile: SelectedFile,
                mediafileName: VMediafileName,
                AssignTo: AU,
                AssignName: AUName,
                AssignImage: "",
                AssignImageName: "",
                Status: "Pending",
                Flag: "A",
                DueDateTime: VDateTime

            }

            console.log("TodoArray",TodoArray);

            axios.post(global.APIURL + '/ToDoSave', TodoArray)
                .then(res => {
                    alert(res.data.StatusMessage);
                    $('#exampleModal2').modal('hide');
                });
        }
        document.getElementById("txtTaskMsg").value = "";
        document.getElementById("txtDate").value = "";
        document.getElementById("txtTime").value = "";
        document.getElementById("txtMediaFile").value = "";
        //document.getElementById("ddlEmployee").value = "";
        document.getElementById("txtSelect").value = "";

        setSelectedOption("");
        getTaskList();
    }
    const getTaskList = async (e) => {

        const TasklistArr = {
            RegisterId: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            SearchTitle: ""
        }
        await axios.post(global.APIURL + '/TaskList', TasklistArr)
            .then((res) => {
                setTaskList(res.data.Result[0].TaskList);
                setTodoList(res.data.Result[0].TodoList);

            });
    }
    const getActiveUserList = async (e) => {

        await axios.get(global.APIURL + '/ActiveUserList')
            .then((res) => {
                setEmployeeList(res.data);
                setOptions(res.data);
            });
    }

         console.log("setTaskId",TaskId);

    const handleDeleteTask = async (event) => {

        event.preventDefault();
        let did = event.target.id;

        const TaskArr = {
            action: "Delete",
            TaskId: did,
            RegisterId: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            TaskMessage: "",
            Status: "Pending",
            Flag: "1"
        }
        console.log("did",did);
        axios.post(global.APIURL + '/Task', TaskArr)
            .then(res => {
                alert(res.data.StatusMessage);
            });
        getTaskList();
    }
    const handleDeleteTodoTask = async (event) => {
        event.preventDefault();
        var id = event.target.id;
        const TodoArray = {
            action: "Delete",
            TodoId: id,
            TaskId: TaskId,
            RegisterId: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            Message: "",
            mediafile: "",
            mediafileName: "",
            AssignTo: "",
            AssignName: "",
            AssignImage: "",
            AssignImageName: "",
            Status: "Pending",
            Flag: "A",
            DueDateTime: ""

        }
        axios.post(global.APIURL + '/ToDoSave', TodoArray)
            .then(res => {

                alert(res.data.StatusMessage);
                getTaskList();
            });
        // window.location.reload();


    }
    const getEditTaskDetails = async (event, UTId) => {

        var AUrl = global.APIURL + '/TaskDetail/' + UTId;
        axios.get(AUrl)
            .then(res => {
                console.log("edit-res",res);

                setTaskListTitle(res.data[0].TaskMessage);
                setEditTaskId(res.data[0].TaskId);

            })
    }
    const updateTaskList = async (event) => {
        event.preventDefault();
        let VTitle = event.target["txtTitle"].value;
        if (VTitle != "") {
            const TaskArr = {
                action: "Edit",
                TaskId: EditTaskId,
                RegisterId: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                TaskMessage: VTitle,
                Status: "Pending",
                Flag: "1"
            }
            axios.post(global.APIURL + '/Task', TaskArr)
                .then(res => {

                    alert(res.data.StatusMessage);
                    document.getElementById("txtTitle").value = '';
                    $('#exampleModal4').modal('hide');



                });
            getTaskList();
        }
    }
    const getEditTodoDetails = async (event, UTId) => {
        var AUrl = global.APIURL + '/ToDoDetail/' + UTId;
        await axios.get(AUrl)
            .then(res => {
                setEditTodoListMsg(res.data.Result[0].ToDoDetail[0].Message);
                setEditTodoId(res.data.Result[0].ToDoDetail[0].TodoId);
                setEditTodoIdTaskId(res.data.Result[0].ToDoDetail[0].TaskId);
                setEditSelectedOption(res.data.Result[0].ToDoUserList)
                //setEditAssignToUser(res.data[0].AssignName);
                // setEditAssignToUserId(res.data[0].AssignTo);
                setEditFileName(res.data.Result[0].ToDoDetail[0].mediafileName);
                var sDate = res.data.Result[0].ToDoDetail[0].SDueDate;
                var convertedDate = sDate.split("-").reverse().join("-");
                setEditDueDate(convertedDate);
                var sTime = res.data.Result[0].ToDoDetail[0].SDueTime;
                var convertedTime = moment(sTime, 'hh:mm A').format('HH:mm')
                setEditDueTime(convertedTime);
            })
    }

    const updateTodoList = async (event) => {
        event.preventDefault();

        let VMessage = event.target["txtTaskMsg"].value;
        let VDate = event.target["txtDate"].value;
        let VTime = event.target["txtTime"].value;
        let VDateTime = VDate.concat(VTime);
        let filename = SelectedFile;
        let filearray = filename.length == 0 ? [] : filename.split('/');
        let VMediafileName = filearray.length == 0 ? '' : filearray[5];
        // let VAssignToUser = AssignToUser == "" ? EditAssignToUser : AssignToUser;
        // let VAssignToUserId = AssignToUserId == "" ? EditAssignToUserId : AssignToUserId;

        let AU = '';
        for (let aus = 0; aus < SelectedOption.length; aus++) {
            const element = SelectedOption[aus].UserId;
            if (AU == '') {
                AU = element
            }
            else {
                AU = AU + ',' + element
            }
        }
        let AUName = "";
        for (let aName = 0; aName < SelectedOption.length; aName++) {
            const ele = SelectedOption[aName].Name;
            if (AUName == "") {
                AUName = ele
            } else {
                AUName = AUName + ',' + ele

            }
        }
        let AUUpdate = AU == "" ? EditAssignToUserId : AU;
        let AUNameUpdate = AUName == "" ? EditAssignToUser : AUName;
        if (AUUpdate != "") {
            const TodoArray = {
                action: "Edit",
                TodoId: EditTodoId,
                TaskId: EditTodoIdTaskId,
                RegisterId: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                Message: VMessage,
                mediafile: SelectedFile == "" ? EditFileName : SelectedFile,
                mediafileName: VMediafileName,
                AssignTo: AUUpdate,
                AssignName: AUNameUpdate,
                AssignImage: "",
                AssignImageName: "",
                Status: "Pending",
                Flag: "A",
                DueDateTime: VDateTime

            }
            axios.post(global.APIURL + '/ToDoSave', TodoArray)
                .then(res => {
                    alert(res.data.StatusMessage);
                    $('#exampleModal5').modal('hide');
                    document.getElementById("txtTaskMsg").value = "";
                    document.getElementById("txtDate").value = "";
                    document.getElementById("txtTime").value = "";

                    getTaskList();


                });
        }



    }
    const handleTodoStatus = async (event, VTodoId) => {
        const checked = event.target.checked;
        if (checked) {
            var VTodoStatus = 'Y';
        }
        else if (!checked) {
            var VTodoStatus = 'N';
        }

        var AUrl = global.APIURL + '/TodoStatusUpdate';
        const StatusUpdateArray = {
            TodoId: VTodoId,
            TodoStatus: VTodoStatus,
            TodoRemarks: "",
            CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
        }
        await axios.post(AUrl, StatusUpdateArray)
            .then(res => {
            }).catch((error) => {
                console.log(error);
            });


    }

    <script>

        $(document).ready(function()
        {
            $('#mod_cls').on('click', function () {
                $('#form_id').trigger("reset");

            })
        });
    </script>

    const handleSelectChange = (obj) => {
        setSelectedOption(obj);
    }

    const exportPDF = () => {

        const unit = "pt";
        const size = "A2";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);
        const title = "Task List Report";
        const headers = [["#", "Task Id", "Todo Message", "Assign Employee", "Due Date", "Due Time", "Status"]];

        const data = TodoList.map((item, index) => [index + 1, item.TaskId, item.Message, item.AssignName, item.DueDate, item.DueTime, item.Status]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("TaskListReport.pdf")
    }

    return (<div className="page-content">

        <MetaTags>
            <title>Add Task | KC Admin Group</title>
        </MetaTags>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Tasks</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={handleAddTask}>
                        <div className="modal-body">

                            <Row>
                                <Col className="col-12">
                                    <div>
                                        <div>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-2">
                                                        <label className="form-label">Task</label>
                                                        <Input type="text" className="form-control" id="txtTitle" placeholder="Enter Task" required />
                                                    </div>
                                                </Col>
                                                <Col lg={12}>
                                                    <div className="mb-2 d-none">
                                                        <label className="form-label">Task Description</label>
                                                        <textarea className="form-control" id="txtTaskDesc" placeholder="Enter Task Description" />
                                                    </div>
                                                </Col>


                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                        <div className="modal-footer justify-content-center">
                            <div className="text-end w-100">
                                <button className="btn btn_success" id="btnsave" >Add</button>
                            </div>
                        </div>
                    </form>



                </div>
            </div>
        </div>
        <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add todo Task</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={handleAddTodoTask} >
                        <div className="modal-body">

                            <Row>
                                <Col className="col-12">
                                    <div>
                                        <div>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-2">
                                                        {/* {AssignToUser}
                                                        <button data-bs-toggle="modal" data-bs-target="#exampleModal3" className="btn btn-primary waves-effect btn-label waves-light addNewDetail btn-sm float-end font-14"><i className="bx bx-plus label-icon "></i>Task Assign To</button>
                                                */} </div>
                                                </Col>

                                                <Col lg={12}>
                                                    <div className="mb-2">
                                                        <label className="form-label">Task Message</label>
                                                        <textarea className="form-control" id="txtTaskMsg" placeholder="Enter Message" required />
                                                    </div>
                                                </Col>

                                                <Col lg="12">
                                                    <label className="mb-2">Assign Employees</label>
                                                    <div className="mb-2 select2-container" style={{ minWidth: "200px" }}>
                                                        <Select
                                                            style={{ minWidth: "200px" }}
                                                            isMulti={true}
                                                            getOptionLabel={option => {
                                                                return option.Name;
                                                            }}
                                                            getOptionValue={option => {
                                                                return option.UserId;
                                                            }}
                                                            options={options}
                                                            classNamePrefix="select2-selection"

                                                            onChange={(option) => handleSelectChange(option)}
                                                            id="txtSelect"
                                                            required />
                                                    </div>
                                                </Col>

                                                <Col lg={12}>

                                                    <div className="mb-2">
                                                        <label className="form-label">Attach File</label>

                                                        {DisplayDocumentFile > 0 ?
                                                            <span className="float-end text-primary font-12" id="spandoctitle">
                                                                <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                                                                <Link to={DisplayFile} target="_blank" >{DisplayFile}</Link></span> : ''}
                                                        <Input type="file" className="form-control" id="txtMediaFile" onChange={getSelectedFile} required />
                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <label className="form-label">Due Date</label>
                                                        <Input type="date" className="form-control" id="txtDate" min={new Date().toISOString().split('T')[0]} required />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <label className="form-label">Due time</label>
                                                        <Input type="time" className="form-control" id="txtTime" required />
                                                    </div>
                                                </Col>


                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                        <div className="modal-footer justify-content-center">
                            <div className="text-end w-100">
                                <button type="submit" className="btn btn_success " id="btnsave" >Add</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className="modal fade" id="exampleModal4" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Edit Task</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={updateTaskList}>
                        <div className="modal-body">

                            <Row>
                                <Col className="col-12">
                                    <div>
                                        <div>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-2">
                                                        <label className="form-label">Task</label>
                                                        <Input type="text" className="form-control" defaultValue={TaskListTitle} id="txtTitle" placeholder="Enter Task" />
                                                    </div>
                                                </Col>
                                                <Col lg={12}>
                                                    <div className="mb-2 d-none">
                                                        <label className="form-label">Task Description</label>
                                                        <textarea className="form-control" id="txtTaskDesc" placeholder="Enter Task Description" />
                                                    </div>
                                                </Col>


                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                        <div className="modal-footer justify-content-center">
                            <div className="text-end w-100">
                                <button className="btn btn_success " id="btnsave" >Update</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className="modal fade" id="exampleModal5" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Edit todo Task</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form onSubmit={updateTodoList} >
                        <div className="modal-body">

                            <Row>
                                <Col className="col-12">
                                    <div>
                                        <div>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mb-2">
                                                        <label className="form-label">Task Message</label>
                                                        <textarea className="form-control" id="txtTaskMsg" placeholder="Enter Message" defaultValue={EditTodoListMsg} />
                                                    </div>
                                                </Col>
                                                <Col lg="12">
                                                    <label className="mb-2">Edit Assign Employees</label>
                                                    <div className="mb-2 select2-container" style={{ minWidth: "200px" }}>
                                                        <Select
                                                            style={{ minWidth: "200px" }}
                                                            isMulti={true}
                                                            getOptionLabel={option => {
                                                                return option.Name;
                                                            }}
                                                            getOptionValue={option => {
                                                                return option.UserId;
                                                            }}
                                                            options={options}
                                                            classNamePrefix="select2-selection"
                                                            defaultValue={EditSelectedOption}
                                                            onChange={(option) => handleSelectChange(option)} />
                                                    </div>
                                                </Col>

                                                <Col lg={12}>
                                                    <div className="mb-2">
                                                        <label className="form-label">Attach File</label>
                                                        {DisplayDocumentFile > 0 ?
                                                            <span className="float-end text-primary font-12" id="spandoctitle">
                                                                <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                                                                <Link to={DisplayFile} target="_blank" >{DisplayFile}</Link></span> : <span className="float-end text-primary font-12" id="spandoctitle">
                                                                <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                                                                <Link to={EditFileName} target="_blank" >{EditFileName}</Link></span>}
                                                        <Input type="file" className="form-control" id="txtMediaFile" onChange={getSelectedFile} />
                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <label className="form-label">Due Date</label>
                                                        <Input type="date" className="form-control" id="txtDate" defaultValue={EditDueDate} min={new Date().toISOString().split('T')[0]} />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-2">
                                                        <label className="form-label">Due time</label>
                                                        <Input type="time" className="form-control" id="txtTime" defaultValue={EditDueTime} />
                                                    </div>
                                                </Col>


                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                        <div className="modal-footer justify-content-center">
                            <div className="text-end w-100">
                                <button className="btn btn_success " id="btnsave" type="submit">Update</button>
                            </div>
                        </div>
                    </form>



                </div>
            </div>
        </div>
        <div >
            <div className="container-fluid maintopedit">
                <Row className="mb-3 me-0 ms-0">
                    <Col className="col-lg-8 col-md-6 col-6 mb-2">
                        <div className="breadcrum ps-0">
                            <CardTitle className="h4">Add Task</CardTitle>
                            <a href="/GeneralDashboard" className="me-2">Home</a>
                            <span>/</span>

                            <span className="ms-2">Add Task</span>
                        </div>
                    </Col>
                    <Col className="col-lg-4 co-md-6 col-6">
                        <button data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-primary waves-effect btn-label waves-light addNewDetail btn-sm float-end font-14">Add Task</button>
                        {/* <a className="btn btn-primary btn-sm float-end font-14" href="">
                        <i className="mdi mdi-arrow-left align-middle me-1"></i>Add Task
                    </a> */}
                        <ReactHTMLTableToExcel
                            className="btn btn-primary btn-sm float-end font-14 me-2"
                            table="emp"
                            filename="TaskListReport"
                            sheet="Sheet"
                            buttonText="Export Excel" />
                        <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
                    </Col>
                </Row>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row">
                <Col lg={12} className="maintodocontentscroll">
                    <div className="row">
                        {
                            TaskList.slice(0).reverse().map((items) => (
                                <div className="col-md-6 mb-4" key={items.TaskId}>
                                    <div className="card h-100 maintodboxpadding">
                                        <div >
                                            <div className="d-flex justify-content-between maintitalbgedit align-items-center">
                                                <div>
                                                    <p className="tasktitaledit">{items.TaskMessage}</p>
                                                </div>
                                                <div>
                                                    <button data-bs-placement="top" title="Add New to do" data-bs-toggle="modal" data-bs-target="#exampleModal2" className="btn btn-sm btn-primary" id={items.TaskId} onClick={e=>setTaskId(items.TaskId)}><i className="fas fa-plus ciconedit"></i></button>

                                                    {items.EditAccesss == 'Y' ? (<button type="button" data-bs-toggle="modal" data-bs-placement="top" title="Edit" data-bs-target="#exampleModal4" className="btn-sm btn btn-success me-2 ms-2" onClick={event => getEditTaskDetails(event, items.TaskId)} id={items.TaskId}><i className="fas fa-pen ciconedit"></i></button>) : null}


                                                    {items.DeleteAccesss == 'Y' ? (<button type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" onClick={handleDeleteTask} className="btn-sm btn btn-danger" id={items.TaskId}><i className="fas fa-trash-alt ciconedit"></i> </button>) : null}



                                                </div>
                                            </div>
                                            <div className="maintodolistscroll">
                                                {
                                                    TodoList.filter(x => parseInt(x.TaskId) == parseInt(items.TaskId)).map((todoitems) => (
                                                        <div key={todoitems.TodoId} className="mt-3 pb-3 maintodolistedit">
                                                            <div className="d-flex ">
                                                                <div className="me-2">
                                                                    <i className="fas fa-circle dotecircleedit"></i>
                                                                </div>
                                                                <div className="w-100">
                                                                    <div className="d-flex justify-content-between w-100 align-items-center">
                                                                        <div>
                                                                            <p className="tasknamee">{todoitems.Message}
                                                                            </p>
                                                                        </div>
                                                                        <div >
                                                                            {todoitems.DeleteAccesss == 'Y' ? (<button className="text-danger btndeletetext " onClick={handleDeleteTodoTask}><i className="mdi mdi-delete" id={todoitems.TodoId}></i></button>) : null}

                                                                            {todoitems.EditAccesss == 'Y' ? (<button className="text-success btneditnew" data-bs-toggle="modal" data-bs-target="#exampleModal5" onClick={event => getEditTodoDetails(event, todoitems.TodoId)}><i className="mdi mdi-pencil"></i></button>) : null}
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <p className="assigntotext">To, {todoitems.AssignName}</p>
                                                                    </div>
                                                                    <div className="d-flex align-items-center">

                                                                        <div>
                                                                            <p className="duedatetextedit ">by, <span>{todoitems.DueDate}</span> | <span>{todoitems.DueTime}</span></p>
                                                                        </div>
                                                                        <div>
                                                                            <span className="badge bg-warning text-light">{todoitems.Status}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="form-check  mt-2">
                                                                            <input className="form-check-input" type="checkbox" id="checkTodoStatus" name="T-Lead" key="TodoStatus" value="TodoStatus" onChange={(event => handleTodoStatus(event, todoitems.TodoId))} defaultChecked={todoitems.ToDoStatus == 'Y'} />
                                                                            <label className="form-check-label lableeditnewwtext" htmlFor="inlineCheckbox1">check if complete</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>

                                            <div>

                                            </div>

                                        </div>

                                    </div>


                                </div>
                            ))
                        }
                    </div>
                    {/* {
                    TaskList.slice(0).reverse().map((items) => (
                        <Card key={items.TaskId}>
                            <CardBody>
                                <CardTitle className="mb-4">

                                    {items.TaskMessage}

                                    {items.DeleteAccesss == 'Y' ? (<button className="text-danger deletebuttonclass" onClick={handleDeleteTask}><i className="mdi mdi-delete font-size-18" id={items.TaskId}></i></button>) : null}

                                    {items.EditAccesss == 'Y' ? (<button data-bs-toggle="modal" data-bs-target="#exampleModal4" className="text-primary deletebuttonclass" onClick={event => getEditTaskDetails(event, items.TaskId)} id={items.TaskId}><i className="mdi mdi-pencil font-size-18"></i></button>) : null}

                                </CardTitle>
                                <div className="table-responsive">
                                    <table className="table table-nowrap align-middle mb-0">
                                        <tbody>
                                            {
                                                TodoList.filter(x => parseInt(x.TaskId) == parseInt(items.TaskId)).map((todoitems) => (<tr key={todoitems.TodoId}>
                                                    <td>
                                                        <h5 className="text-truncate font-size-14 m-0">
                                                            <a to="#" className="text-dark">
                                                                <span>Assign To  - {todoitems.AssignName}</span>
                                                            </a>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5 className="text-truncate font-size-14 m-0">
                                                            <a to="#" className="text-dark">
                                                                <span>Task  - {todoitems.Message}</span>
                                                            </a>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5 className="text-truncate font-size-14 m-0">
                                                            <a to="#" className="text-dark">
                                                                <span>Due Date  - {todoitems.DueDate}</span>

                                                            </a>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5 className="text-truncate font-size-14 m-0">
                                                            <a to="#" className="text-dark">
                                                                <span>Due Time  - {todoitems.DueTime}</span>

                                                            </a>
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <h5 className="text-truncate font-size-14 m-0">
                                                            <a to="#" className="text-dark">
                                                                <span>Status - {todoitems.Status}</span>

                                                            </a>
                                                        </h5>
                                                    </td>

                                                    <td>
                                                        <h5 className="text-truncate font-size-14 m-0">
                                                            <a to="#" className="text-dark">
                                                                <div className="form-check  mb-0">
                                                                    <input className="form-check-input" type="checkbox" id="checkTodoStatus" name="T-Lead" key="TodoStatus" value="TodoStatus" onChange={(event => handleTodoStatus(event, todoitems.TodoId))} defaultChecked={todoitems.ToDoStatus == 'Y'} />
                                                                    <label className="form-check-label" htmlFor="inlineCheckbox1">Completed</label>
                                                                </div>

                                                            </a>
                                                        </h5>
                                                    </td>

                                                    <td>

                                                        {todoitems.DeleteAccesss == 'Y' ? (<button className="text-danger deletebuttonclass" onClick={handleDeleteTodoTask}><i className="mdi mdi-delete font-size-18" id={todoitems.TodoId}></i></button>) : null}

                                                        {todoitems.EditAccesss == 'Y' ? (<button className="text-primary deletebuttonclass" data-bs-toggle="modal" data-bs-target="#exampleModal5" onClick={event => getEditTodoDetails(event, todoitems.TodoId)}><i className="mdi mdi-pencil font-size-18"></i></button>) : null}
                                                    </td>


                                                </tr>))}
                                        </tbody>
                                    </table>
                                    <button data-bs-toggle="modal" data-bs-target="#exampleModal2" className="btn btn-primary waves-effect btn-label waves-light addNewDetail btn-sm float-end font-14" id={items.TaskId} onClick={handleTaskId}><i className="bx bx-plus label-icon "></i>Add Todo Task</button>

                                </div>
                            </CardBody>
                        </Card>

                    ))
                } */}
                </Col>
            </div>
        </div>
        <Row>
            <div>
                <table id="emp" className="table" hidden="hidden">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Task Id</th>
                            <th>Todo Message</th>
                            <th>Assign Employee</th>
                            <th>Due Date</th>
                            <th>Due Time</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>              {
                        TodoList.map((item, index) => {
                            return <tr key={index}>
                                <td> {index + 1} </td>
                                <td >{item.TaskId}</td>
                                <td>{item.Message}</td>
                                <td>{item.AssignName}</td>
                                <td>{item.DueDate}</td>
                                <td>{item.DueTime}</td>
                                <td>{item.Status}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        </Row>

    </div>

    )

}

export default Task;