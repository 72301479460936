import React, { useEffect, useState, useRef, Component } from "react";

import MetaTags from "react-meta-tags";
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody, Input, Label } from "reactstrap";


const UiRating = () => {
  const history = useHistory();

  const messagesEndRef = React.createRef()

  const [CorporateClientActivitiesStatus, setCorporateClientActivitiesStatus] = useState('Pending');
  const [BureauStepDetail, setBureauStepDetail] = useState([]);
  const [BureauStepActivitiesDetail, setBureauStepActivitiesDetail] = useState([]);
  const [BureauStepActivitiesActionGroupBy, setBureauStepActivitiesActionGroupBy] = useState([]);
  const [BureauStepActivitiesActionDetail, setBureauStepActivitiesActionDetail] = useState([]);
  const [BureauStepActivitiesStatus, setBureauStepActivitiesStatus] = useState([]);
  const [BureauStepActivitiesFile, setBureauStepActivitiesFile] = useState([]);
  const [ActivitiesfollowupList, setActivitiesfollowupList] = useState([]);
  const [FollowupAction, setFollowupAction] = useState('');
  const [FollowupDate, setFollowupDate] = useState('');
  const [FollowupTime, setFollowupTime] = useState('');
  const [FollowupMode, setFollowupMode] = useState('');
  const [FollowupNote, setFollowupNote] = useState('');
  const [FollowupRemark, setFollowupRemark] = useState('');
  const [EditFollowupId, setEditFollowupId] = useState('0');
  const [SendMessageText, setSendMessageText] = useState('');
  const [SendMessageFileName, setSendMessageFileName] = useState('');
  const [SendMessageFilePath, setSendMessageFilePath] = useState('');
  const [SendMessageFileType, setSendMessageFileType] = useState('');
  const [UserMessageList, setUserMessageList] = useState([]);
  const [LoginUserId, setLoginUserId] = useState(0);

  const [DocumentFilePath, setDocumentFilePath] = useState('');

  const [EditRowId, setEditRowId] = useState(0);


  useEffect(() => {
    let CorporateLeadId = localStorage.getItem("EditCorporateRegisterClientId") == null ? '0' : localStorage.getItem("EditCorporateRegisterClientId") == '' ? '0' : localStorage.getItem("EditCorporateRegisterClientId");
    let SenderId = localStorage.getItem("LoginUserId") == null ? '0' : localStorage.getItem("LoginUserId") == '' ? '0' : localStorage.getItem("LoginUserId");
    setLoginUserId(parseInt(SenderId));
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");
    if (CorporateLeadId != null && CorporateServiceId != null) {
      if (CorporateLeadId != "0") {
        getUserChatList();
        getServicePackageActivities();
        getCorporateLeadServiceStatus();
        getActivitiesfollowupList();

      }
      else {
        history.push("/EditRegisterClient");
      }
    }
    else {
      history.push("/EditRegisterClient");
    }
  }, []);

  const getActivitiesfollowupList = async () => {
    let CorporateLeadId = localStorage.getItem("EditCorporateRegisterClientId") == null ? '0' : localStorage.getItem("EditCorporateRegisterClientId") == '' ? '0' : localStorage.getItem("EditCorporateRegisterClientId");
    const RequestArr = {
      Id: 0,
      CorporateLeadId: CorporateLeadId
    };
    var AUrl = global.APIURL + '/CorporateLeadServiceActivitiesFollowupList';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        setActivitiesfollowupList(CSPres.data);
      });


  };

  const getUserChatList = async () => {
    let SenderId = localStorage.getItem("LoginUserId") == null ? '0' : localStorage.getItem("LoginUserId") == '' ? '0' : localStorage.getItem("LoginUserId");
    let SenderType = 'U';
    let ReceiverId = localStorage.getItem("EditCorporateRegisterClientId") == null ? '0' : localStorage.getItem("EditCorporateRegisterClientId") == '' ? '0' : localStorage.getItem("EditCorporateRegisterClientId");
    let ReceiverType = 'C';
    const RequestArr = {
      SenderId: SenderId,
      SenderType: SenderType,
      ReceiverId: ReceiverId,
      ReceiverType: ReceiverType
    };
    var AUrl = global.APIURL + '/UserChatMessageSelectAll';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        setUserMessageList(CSPres.data);
      });

  };

  const getServicePackageActivities = async () => {
    let CorporateLeadId = localStorage.getItem("EditCorporateRegisterClientId") == null ? '0' : localStorage.getItem("EditCorporateRegisterClientId") == '' ? '0' : localStorage.getItem("EditCorporateRegisterClientId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");
    let CorporateServiceBureauId = localStorage.getItem("CorporateServiceBureauId") == null ? '0' : localStorage.getItem("CorporateServiceBureauId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauId");
    let CorporateServiceBureauStepId = localStorage.getItem("CorporateServiceBureauStepId") == null ? '0' : localStorage.getItem("CorporateServiceBureauStepId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauStepId");
    let CorporateServiceBureauDueId = localStorage.getItem("CorporateServiceBureauDueId") == null ? '0' : localStorage.getItem("CorporateServiceBureauDueId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauDueId");

    const RequestArr = {
      ServiceId: CorporateServiceId,
      CorporateLeadId: CorporateLeadId,
      BureauId: CorporateServiceBureauId,
      BureauStepId: CorporateServiceBureauStepId,
      PaymentDueId: CorporateServiceBureauDueId
    };
    var AUrl = global.APIURL + '/CorporateLeadServiceActivities';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {

        if (CSPres.data.Result.length > 0) {

          setBureauStepDetail(CSPres.data.Result[0].BureauStepDetail);
          setBureauStepActivitiesDetail(CSPres.data.Result[0].BureauStepActivitiesDetail);
          setBureauStepActivitiesActionGroupBy(CSPres.data.Result[0].BureauStepActivitiesActionGroupBy)
          setBureauStepActivitiesActionDetail(CSPres.data.Result[0].BureauStepActivitiesActionDetail);
          setBureauStepActivitiesStatus(CSPres.data.Result[0].BureauStepActivitiesActionStatus)

        }

      });
  };

  const getServicePackageActivitiesAction = async () => {
    let CorporateLeadId = localStorage.getItem("EditCorporateRegisterClientId") == null ? '0' : localStorage.getItem("EditCorporateRegisterClientId") == '' ? '0' : localStorage.getItem("EditCorporateRegisterClientId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");
    let CorporateServiceBureauId = localStorage.getItem("CorporateServiceBureauId") == null ? '0' : localStorage.getItem("CorporateServiceBureauId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauId");
    let CorporateServiceBureauStepId = localStorage.getItem("CorporateServiceBureauStepId") == null ? '0' : localStorage.getItem("CorporateServiceBureauStepId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauStepId");

    BureauStepActivitiesActionGroupBy.length = 0;
    BureauStepActivitiesActionDetail.length = 0;
    BureauStepActivitiesStatus.length = 0;

    const RequestArr = {
      ServiceId: CorporateServiceId,
      CorporateLeadId: CorporateLeadId,
      BureauId: CorporateServiceBureauId,
      BureauStepId: CorporateServiceBureauStepId
    };
    var AUrl = global.APIURL + '/CorporateLeadServiceActivitiesAction';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        if (CSPres.data.Result.length > 0) {
          setBureauStepActivitiesActionGroupBy(CSPres.data.Result[0].BureauStepActivitiesActionGroupBy)
          setBureauStepActivitiesActionDetail(CSPres.data.Result[0].BureauStepActivitiesActionDetail);
          setBureauStepActivitiesStatus(CSPres.data.Result[0].BureauStepActivitiesActionStatus)
        }
        else {
          BureauStepActivitiesActionGroupBy.length = 0;
          BureauStepActivitiesActionDetail.length = 0;
          BureauStepActivitiesStatus.length = 0;
        }
      });
  };

  const onClickActivitiesFile = async (ActivitiesId) => {
    const RequestArr = {
      ServiceActivitiesId: ActivitiesId
    };
    var AUrl = global.APIURL + '/CorporateServiceActivitiesFiles';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        setBureauStepActivitiesFile(CSPres.data);
        if (CSPres.data.length > 0) {
          $('#mpreferencedocuments').modal('show');
        }
        else {
          $('#mpreferencedocuments').modal('hide');
        }
      });
  };

  const onClickActivitiesAction = async (item) => {
    //const AddUpdatebtn = document.getElementById(ButtonControlId);
    let BureauStepActivitiesDetailArr = BureauStepActivitiesDetail.filter(x => x.MasterId == item.Id);
    let VAddButtonControlId = document.getElementById(item.AddButtonControlId).innerHTML;
    let CorporateLeadId = localStorage.getItem("EditCorporateRegisterClientId") == null ? '0' : localStorage.getItem("EditCorporateRegisterClientId") == '' ? '0' : localStorage.getItem("EditCorporateRegisterClientId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");
    let CorporateServiceBureauDueId = localStorage.getItem("CorporateServiceBureauDueId") == null ? '0' : localStorage.getItem("CorporateServiceBureauDueId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauDueId");
    let CorporateServiceBureauStepId = localStorage.getItem("CorporateServiceBureauStepId") == null ? '0' : localStorage.getItem("CorporateServiceBureauStepId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauStepId");
    if (VAddButtonControlId == "Save") {
      for (let bsa = 0; bsa < BureauStepActivitiesDetailArr.length; bsa++) {
        const VBureauStepActivitiesDetail = BureauStepActivitiesDetailArr[bsa];
        var VActionValue = '';
        let BureauStepActivitiesActionGroupByArr = BureauStepActivitiesActionGroupBy.filter(x => x.BureauId == item.BureauId);
        BureauStepActivitiesActionGroupByArr = BureauStepActivitiesActionGroupByArr.filter(x => x.PaymentDueId == CorporateServiceBureauDueId);
        BureauStepActivitiesActionGroupByArr = BureauStepActivitiesActionGroupByArr.filter(x => x.ServiceActivitiesId == item.Id);
        BureauStepActivitiesActionGroupByArr = BureauStepActivitiesActionGroupByArr.sort((a, b) => (a.RowIndexId - b.RowIndexId) ? -1 : 1);
        let LastIndexId = (BureauStepActivitiesActionGroupByArr.length == 0 ? 0 : BureauStepActivitiesActionGroupByArr[0].RowIndexId) + 1;
        var VActionType = VBureauStepActivitiesDetail.ActionType;
        var VDocFileName = '';
        if (VActionType == 'File') {
          let VDocumentFilepath = document.getElementById(VBureauStepActivitiesDetail.FormControlId).value == null ? '' : document.getElementById(VBureauStepActivitiesDetail.FormControlId).value;
          var filearray = VDocumentFilepath.toString().split('/');
          VDocFileName = filearray.length > 4 ? filearray[5] : '';
        }
        else {
          VActionValue = document.getElementById(VBureauStepActivitiesDetail.FormControlId).value == null ? '' : document.getElementById(VBureauStepActivitiesDetail.FormControlId).value;
        }
        const ActivitiesActionArr = {
          Id: 0,
          RowIndexId: LastIndexId,
          BureauId: item.BureauId,
          BureauStepId: CorporateServiceBureauStepId,
          CorporateLeadId: CorporateLeadId,
          ServiceId: CorporateServiceId,
          PaymentDueId: CorporateServiceBureauDueId,
          ServiceActivitiesId: item.Id,
          ServiceActivitiesActionId: VBureauStepActivitiesDetail.Id,
          ActionTitle: VBureauStepActivitiesDetail.ActionTitle,
          ActionValue: VBureauStepActivitiesDetail.ActionType == 'File' ? DocumentFilePath : VActionValue,
          ActionUploadFileName: VBureauStepActivitiesDetail.ActionType == 'File' ? VDocFileName : '',
          ActionType: VBureauStepActivitiesDetail.ActionType,
          ActionValueDate: VBureauStepActivitiesDetail.ActionType == 'Date' ? VActionValue == '' ? '1990-01-01' : VActionValue : '1990-01-01',
          ServiceActivitiesActionStausId: VActionValue == null ? parseInt(0) : VActionValue == '' ? parseInt(0) : VBureauStepActivitiesDetail.ActionStatusId,
          CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
          UserType: localStorage.getItem("LoginUserType") == null ? "" : localStorage.getItem("LoginUserType"),
          UserName: localStorage.getItem("LoginUserName") == null ? "" : localStorage.getItem("LoginUserName")
        }
        axios.post(global.APIURL + '/CorporateLeadServiceActivitiesActionSave', ActivitiesActionArr).then(res => { });

        if (bsa == (BureauStepActivitiesDetailArr.length - 1)) {
          alert('Corporate service activities action save successfully');
          setDocumentFilePath('');
          setEditRowId(0);
          document.getElementById(item.AddRowCountControlId).value = '0';
          getBureauStepStatus(item.BureauId, CorporateServiceBureauDueId, item.Id, item.ActivitiesStatusControlId);
          getServicePackageActivities();
          getCorporateLeadServiceStatus();
        }

        document.getElementById(VBureauStepActivitiesDetail.FormControlId).value = '';

      }
    }
    else if (VAddButtonControlId == "Update") {
      var VBureauStepActivitiesActionDetail = BureauStepActivitiesActionDetail.filter(x => x.BureauId == item.BureauId && x.PaymentDueId == CorporateServiceBureauDueId && x.ServiceActivitiesId == item.Id && x.RowIndexId == EditRowId);
      for (let bsu = 0; bsu < VBureauStepActivitiesActionDetail.length; bsu++) {
        const VBSActivitiesActionDetail = VBureauStepActivitiesActionDetail[bsu];
        var VActionType = VBSActivitiesActionDetail.ActionType;
        var VActionValue = '';
        if (VActionType == 'File') {
          VActionValue = document.getElementById(VBSActivitiesActionDetail.FormControlId).value == null ? '' : document.getElementById(VBSActivitiesActionDetail.FormControlId).value;
          let VDocumentFilepath = document.getElementById(VBSActivitiesActionDetail.FormControlId).value == null ? '' : document.getElementById(VBSActivitiesActionDetail.FormControlId).value;;
          var filearray = VDocumentFilepath.toString().split('/');
          VDocFileName = filearray.length > 4 ? filearray[5] : '';
        }
        else {
          VActionValue = document.getElementById(VBSActivitiesActionDetail.FormControlId).value == null ? '' : document.getElementById(VBSActivitiesActionDetail.FormControlId).value;
        }
        const ActivitiesActionArr = {
          Id: VBSActivitiesActionDetail.Id,
          ActionValue: VActionValue,
          ActionUploadFileName: VActionType == 'File' ? VDocFileName : '',
          ActionType: VActionType,
          ActionValueDate: VBSActivitiesActionDetail.ActionType == 'Date' ? VActionValue == '' ? '1990-01-01' : VActionValue : '1990-01-01',
          ActionStausId: VActionValue == null ? parseInt(0) : VActionValue == '' ? parseInt(0) : VBSActivitiesActionDetail.ServiceActivitiesActionStausId,
          CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
          UserType: localStorage.getItem("LoginUserType") == null ? "" : localStorage.getItem("LoginUserType"),
          UserName: localStorage.getItem("LoginUserName") == null ? "" : localStorage.getItem("LoginUserName")
        }
        axios.post(global.APIURL + '/CorporateLeadServiceActivitiesActionUpdate', ActivitiesActionArr).then(res => { });

        if (bsu == (VBureauStepActivitiesActionDetail.length - 1)) {
          alert('Corporate service activities action update successfully');
          setDocumentFilePath('');
          setEditRowId(0);
          getBureauStepStatus(item.BureauId, CorporateServiceBureauDueId, item.Id, item.ActivitiesStatusControlId);
          getCorporateLeadServiceStatus();
          document.getElementById(item.AddRowCountControlId).value = '0';
          getServicePackageActivities();
          getServicePackageActivitiesAction();
        }
        document.getElementById(VBSActivitiesActionDetail.FormControlId).value = '';
      }
      document.getElementById(item.AddButtonControlId).innerHTML = "Save";
    }

  };

  const onClickActivitiesCancelAction = async (Vitem2) => {

    let BureauStepActivitiesDetailArr = BureauStepActivitiesDetail.filter(x => x.MasterId == Vitem2.Id);
    for (let bsa = 0; bsa < BureauStepActivitiesDetailArr.length; bsa++) {
      const VBureauStepActivitiesDetail = BureauStepActivitiesDetailArr[bsa];
      document.getElementById(VBureauStepActivitiesDetail.FormControlId).value = '';
    }
  };

  const GetActionStatusCode = async (StepDetail, StepActionDetail) => {
    let CorporateLeadId = localStorage.getItem("EditCorporateRegisterClientId") == null ? '0' : localStorage.getItem("EditCorporateRegisterClientId") == '' ? '0' : localStorage.getItem("EditCorporateRegisterClientId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");
    let CorporateServiceBureauDueId = localStorage.getItem("CorporateServiceBureauDueId") == null ? '0' : localStorage.getItem("CorporateServiceBureauDueId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauDueId");


    const ActivitiesActionArr = {
      BureauId: StepDetail.BureauId,
      CorporateLeadId: CorporateLeadId,
      ServiceId: CorporateServiceId,
      PaymentDueId: CorporateServiceBureauDueId,
      ServiceActivitiesId: StepActionDetail.MasterId,
      ServiceActivitiesActionId: StepActionDetail.Id,
      ServiceActivitiesActionStausId: StepActionDetail.ActionStatusId,
      ServiceActivitiesActionStausCode: StepActionDetail.ActionStatusCode,
      ServiceActivitiesActionStausTitle: StepActionDetail.ActionStatusTitle
    }
    BureauStepActivitiesStatus.push(ActivitiesActionArr);

    var VBureauStepActivitiesLastCode = BureauStepActivitiesStatus.filter(x => x.BureauId == StepDetail.BureauId && x.CorporateLeadId == CorporateLeadId);
    VBureauStepActivitiesLastCode = VBureauStepActivitiesLastCode.filter(x => x.ServiceId == CorporateServiceId && x.PaymentDueId == CorporateServiceBureauDueId);
    VBureauStepActivitiesLastCode = VBureauStepActivitiesLastCode.filter(x => x.ServiceActivitiesId == StepActionDetail.MasterId);
    const FAS = VBureauStepActivitiesLastCode.sort((a, b) => (b.ServiceActivitiesActionStausCode - a.ServiceActivitiesActionStausCode));
    var LastFinalStatusCode = FAS[0].ServiceActivitiesActionStausCode + ' - ' + FAS[0].ServiceActivitiesActionStausTitle;
    document.getElementById(StepDetail.ActivitiesStatusControlId).value = LastFinalStatusCode;
  }

  const EditActivitiesAction = async (BureauStepDetail, BureauStepActivitiesActionGroupBy) => {
    let CorporateServiceBureauDueId = localStorage.getItem("CorporateServiceBureauDueId") == null ? '0' : localStorage.getItem("CorporateServiceBureauDueId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauDueId");
    var VBureauStepActivitiesActionDetail = BureauStepActivitiesActionDetail.filter(x => x.BureauId == BureauStepDetail.BureauId && x.PaymentDueId == CorporateServiceBureauDueId && x.ServiceActivitiesId == BureauStepDetail.Id && x.RowIndexId == BureauStepActivitiesActionGroupBy.RowIndexId);
    for (let bsa = 0; bsa < VBureauStepActivitiesActionDetail.length; bsa++) {
      const BureauStepActivitiesActionDetail = VBureauStepActivitiesActionDetail[bsa];
      var FCId = BureauStepActivitiesActionDetail.FormControlId;
      var VActionType = BureauStepActivitiesActionDetail.ActionType;
      if (VActionType == 'File') {
        document.getElementById(FCId).value = BureauStepActivitiesActionDetail.ActionValue;
        var filearray = BureauStepActivitiesActionDetail.ActionValue.toString().split('/');
        var  VDocFileName = filearray.length > 4 ? filearray[5] : '';
        var VElementById=BureauStepActivitiesActionDetail.LinkControlId;
        document.getElementById(VElementById).href = BureauStepActivitiesActionDetail.ActionValue;
        document.getElementById(VElementById).htmlFor = VDocFileName;
        //setDocumentFilePath(BureauStepActivitiesActionDetail.ActionValue);
      }
      else {
        document.getElementById(FCId).value = BureauStepActivitiesActionDetail.ActionValue;
      }
    }
    document.getElementById(BureauStepDetail.AddButtonControlId).innerHTML = "Update";
    var VRowIndexId = BureauStepDetail.AddRowCountControlId;
    var RId = BureauStepActivitiesActionGroupBy.RowIndexId;
    document.getElementById(VRowIndexId).value = RId.toString();
    setEditRowId(RId);
  }

  const DeleteActivitiesAction = async (BureauStepDetail, BureauStepActivitiesActionGroupBy) => {
    let CorporateLeadId = localStorage.getItem("EditCorporateRegisterClientId") == null ? '0' : localStorage.getItem("EditCorporateRegisterClientId") == '' ? '0' : localStorage.getItem("EditCorporateRegisterClientId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");
    let CorporateServiceBureauDueId = localStorage.getItem("CorporateServiceBureauDueId") == null ? '0' : localStorage.getItem("CorporateServiceBureauDueId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauDueId");
    let CorporateServiceBureauStepId = localStorage.getItem("CorporateServiceBureauStepId") == null ? '0' : localStorage.getItem("CorporateServiceBureauStepId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauStepId");
    const ActivitiesActionArr = {
      RowIndexId: BureauStepActivitiesActionGroupBy.RowIndexId,
      BureauId: BureauStepDetail.BureauId,
      BureauStepId: CorporateServiceBureauStepId,
      CorporateLeadId: CorporateLeadId,
      ServiceId: CorporateServiceId,
      PaymentDueId: CorporateServiceBureauDueId,
      ServiceActivitiesId: BureauStepDetail.Id,
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    }
    axios.post(global.APIURL + '/CorporateLeadServiceActivitiesActionDelete', ActivitiesActionArr)
      .then(res => {
        alert('Corporate service activities action delete successfully.');
        //window.location.reload();
        getServicePackageActivities();

      });
  }

  const getBureauStepStatus = async (BureauId, PaymentDueId, ServiceActivitiesId, ActivitiesStatusControlId) => {
    let CorporateLeadId = localStorage.getItem("EditCorporateRegisterClientId") == null ? '0' : localStorage.getItem("EditCorporateRegisterClientId") == '' ? '0' : localStorage.getItem("EditCorporateRegisterClientId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");
    let CorporateServiceBureauStepId = localStorage.getItem("CorporateServiceBureauStepId") == null ? '0' : localStorage.getItem("CorporateServiceBureauStepId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauStepId");
    const RequestArr = {
      ServiceId: CorporateServiceId,
      CorporateLeadId: CorporateLeadId,
      BureauId: BureauId,
      BureauStepId: CorporateServiceBureauStepId,
      PaymentDueId: PaymentDueId,
      ServiceActivitiesId: ServiceActivitiesId
    };
    var AUrl = global.APIURL + '/CorporateLeadServiceActivitiesLastStatus';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        if (CSPres.data.Result.length > 0) {
          var LastFinalStatusCode = CSPres.data.Result[0].ServiceActivitiesLastStatus[0].ServiceActivitiesStatus;
          document.getElementById(ActivitiesStatusControlId).value = LastFinalStatusCode;
        }
      });
  };

  const getCorporateLeadServiceStatus = async () => {
    let CorporateLeadId = localStorage.getItem("EditCorporateRegisterClientId") == null ? '0' : localStorage.getItem("EditCorporateRegisterClientId") == '' ? '0' : localStorage.getItem("EditCorporateRegisterClientId");
    let CorporateServiceId = localStorage.getItem("CorporateServiceId") == null ? '0' : localStorage.getItem("CorporateServiceId") == '' ? '0' : localStorage.getItem("CorporateServiceId");
    let CorporateServiceBureauId = localStorage.getItem("CorporateServiceBureauId") == null ? '0' : localStorage.getItem("CorporateServiceBureauId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauId");
    let CorporateServiceBureauStepId = localStorage.getItem("CorporateServiceBureauStepId") == null ? '0' : localStorage.getItem("CorporateServiceBureauStepId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauStepId");
    let CorporateServiceBureauDueId = localStorage.getItem("CorporateServiceBureauDueId") == null ? '0' : localStorage.getItem("CorporateServiceBureauDueId") == '' ? '0' : localStorage.getItem("CorporateServiceBureauDueId");

    const RequestArr = {
      ServiceId: CorporateServiceId,
      CorporateLeadId: CorporateLeadId,
      BureauId: CorporateServiceBureauId,
      BureauStepId: CorporateServiceBureauStepId,
      PaymentDueId: CorporateServiceBureauDueId,
      ServiceActivitiesId: 0
    };

    var AUrl = global.APIURL + '/CorporateLeadServiceLastStatus';
    axios.post(AUrl, RequestArr)
      .then(CSPres => {
        if (CSPres.data.Result.length > 0) {
          var LastFinalStatusCode = CSPres.data.Result[0].ServiceActivitiesLastStatus[0].ServiceActivitiesStatus;
          $('#txtCorporateClientActivitiesStatus').val(LastFinalStatusCode);
        }

      });
  };

  const onClickActivitiesFollowup = async () => {

    let CorporateLeadId = localStorage.getItem("EditCorporateRegisterClientId") == null ? '0' : localStorage.getItem("EditCorporateRegisterClientId") == '' ? '0' : localStorage.getItem("EditCorporateRegisterClientId");
    let IsInsert = 0;

    if (FollowupAction == null) {
      alert('Please input followup action.');
      IsInsert = 1;
      return;
    }
    if (FollowupAction == '') {
      alert('Please input followup action.');
      IsInsert = 1;
      return;
    }
    if (FollowupDate == null) {
      alert('Please input followup date.');
      IsInsert = 1;
      return;
    }
    if (FollowupDate == '') {
      alert('Please input followup date.');
      IsInsert = 1;
      return;
    }
    if (FollowupTime == null) {
      alert('Please input followup time.');
      IsInsert = 1;
      return;
    }
    if (FollowupTime == '') {
      alert('Please input followup time.');
      IsInsert = 1;
      return;
    }
    if (FollowupMode == null) {
      alert('Please input followup mode.');
      IsInsert = 1;
      return;
    }
    if (FollowupMode == '') {
      alert('Please input followup mode.');
      IsInsert = 1;
      return;
    }
    if (FollowupNote == null) {
      alert('Please input followup note.');
      IsInsert = 1;
      return;
    }
    if (FollowupNote == '') {
      alert('Please input followup note.');
      IsInsert = 1;
      return;
    }
    if (IsInsert == 0) {
      const ActivitiesFollowupArr = {
        Id: 0,
        CorporateLeadId: CorporateLeadId,
        FollowupAction: FollowupAction,
        FollowupDate: FollowupDate,
        FollowupTime: FollowupTime,
        FollowupMode: FollowupMode,
        FollowupNote: FollowupNote,
        FollowupRemark: FollowupRemark,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
      }
      axios.post(global.APIURL + '/CorporateLeadServiceActivitiesFollowupSave', ActivitiesFollowupArr).then(res => {
        $('#txtFollowupAction').val('');
        $('#txtFollowupDate').val('');
        $('#txtFollowupTime').val('');
        $('#txtFollowupNote').val('');
        $('#ddlFollowupMode').val('Select Mode');
        setFollowupAction('');
        setFollowupDate('');
        setFollowupTime('');
        setFollowupMode('');
        setFollowupNote('');
        alert('Corporate service activities Followup SAVE successfully.');
        getActivitiesfollowupList();
      });
    }
  };

  const onClickEditActivitiesFollowup = async (Id, EditFollowupRemark) => {
    setEditFollowupId(Id);
    $('#txtFollowupRemarks').val(EditFollowupRemark);
    $('#mpremarkupdate').modal('show');
  };

  const onClickActivitiesFollowupRemarksUpdate = async () => {
    const ActivitiesFollowupArr = {
      Id: EditFollowupId,
      FollowupRemark: FollowupRemark,
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
    }
    axios.post(global.APIURL + '/CorporateLeadServiceActivitiesFollowupUpdate', ActivitiesFollowupArr).then(res => {
      $('#mpremarkupdate').modal('hide');
      $('#txtFollowupRemarks').val('');
      setFollowupRemark('');
      setEditFollowupId('0');
      alert('Corporate service activities followup remarks update successfully.');
      getActivitiesfollowupList();
    });
  };

  const onClickActivitiesFollowupRemarksCancel = async () => {
    $('#mpremarkupdate').modal('hide');
    $('#txtFollowupRemarks').val('');
    setEditFollowupId('0');
  };

  const SendMessageClick = async () => {

    let SenderId = localStorage.getItem("LoginUserId") == null ? '0' : localStorage.getItem("LoginUserId") == '' ? '0' : localStorage.getItem("LoginUserId");
    let SubSenderId = localStorage.getItem("LoginUserId") == null ? '0' : localStorage.getItem("LoginUserId") == '' ? '0' : localStorage.getItem("LoginUserId");
    let SenderType = 'U';
    let ReceiverId = localStorage.getItem("EditCorporateRegisterClientId") == null ? '0' : localStorage.getItem("EditCorporateRegisterClientId") == '' ? '0' : localStorage.getItem("EditCorporateRegisterClientId");
    let SubReceiverId = localStorage.getItem("EditCorporateRegisterClientId") == null ? '0' : localStorage.getItem("EditCorporateRegisterClientId") == '' ? '0' : localStorage.getItem("EditCorporateRegisterClientId");
    let ReceiverType = 'C';

    let IsSend = 0;
    if (SendMessageText == '' && SendMessageFilePath != '') {
      IsSend = 1;
    }
    else {
      if (SendMessageText != '') {
        IsSend = 1;
      }
      else if (SendMessageFilePath != '') {
        IsSend = 1;
      }
      else {
        IsSend = 0;
      }
    }
    if (IsSend == 1) {
      const SendMessageArr = {
        Id: 0,
        SenderId: SenderId,
        SubSenderId: SubSenderId,
        SenderType: SenderType,
        ReceiverId: ReceiverId,
        SubReceiverId: SubReceiverId,
        ReceiverType: ReceiverType,
        MessageText: SendMessageText,
        MessageFileName: SendMessageFileName,
        MessageFilePath: SendMessageFilePath,
        MessageFileType: SendMessageFileType,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
      }
      axios.post(global.APIURL + '/UserChatMessageSave', SendMessageArr).then(res => {
        $('#txtSendMessage').val('');
        setSendMessageText('');
        setSendMessageFileName('');
        setSendMessageFilePath('');
        setSendMessageFileType('');
        getUserChatList();
        alert('Message send successfully.');
      });
    }
  };

  function handleChange(e) {
    var details = e.target.files[0];
    let arr = details.name.toString().split(".");
    var FileEx = arr[1];

    var details1 = "UserChatFile";
    const formData = new FormData();
    formData.append('Document', details);
    formData.append('FolderName', details1);
    var AUrl = global.APIURL + '/uploadavatar/';
    axios.post(AUrl, formData,
      {
        headers: { "Content-Type": details.type },
      })
      .then((res) => {
        setSendMessageFileName(details.name.toString());
        setSendMessageFilePath(res.data.IName);
        setSendMessageFileType(FileEx);
      });
  }

  const RefreshMessageClick = async () => {
    getUserChatList();
  };

  const getDocumentpath = async (e, FormControlId) => {
    var SetFileControlName = '#' + FormControlId;
    var details = e.target.files[0];
    var details1 = "CorporateServiceActivitiesDocument";
    var filename = details.name;
    const formData = new FormData();
    formData.append('Document', details);
    formData.append('FolderName', details1);
    var AUrl = global.APIURL + '/uploadavatar/';
    axios.post(AUrl, formData, {
      headers: { "Content-Type": details.type },
    })
      .then((res) => {
        document.getElementById(FormControlId).value = res.data.IName;
        setDocumentFilePath(res.data.IName);
      });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Client Service Activities | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">

          <div className="modal fade bd-example-modal-lg" id="mpreferencedocuments" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Reference Documents List</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <Row className="me-0">
                    {BureauStepActivitiesFile.map((item, idx) => (
                      <Col className="col-lg-6 col-md-6 " key={idx}>
                        <div className="bureaustepactivitiesfile">
                          <a href={item.FilePath} target="_blank" rel="noopener noreferrer" className="fadocumenttitleiconcolor">
                            <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true" ></i>{item.FileName}
                          </a>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade bd-example-modal-lg" id="mpremarkupdate" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Follow-up Remarks</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <Row className="me-0">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Notes for Next Follow-up</label>
                        <textarea type="" rows="2" className="form-control" placeholder="Enter Notes for Next Follow-up" id="txtFollowupRemarks" aria-describedby="emailHelp" onChange={e => setFollowupRemark(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <a className="btn btn_success btn-sm font-14 me-2" id="lnkFollowupRemarksUpdate" onClick={e => onClickActivitiesFollowupRemarksUpdate()}>Update</a>
                      <a className="btn btn_danger btn-sm  font-14" id="lnkFollowupRemarksCancel" onClick={e => onClickActivitiesFollowupRemarksCancel()}>Cancel</a>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-12">
              <div className="breadcrum ps-0">
                <CardTitle>Client Service Activities</CardTitle>
                <a href="/" className="me-2">Home</a>{" "}
                <span>/</span>
                <a href="/RegisterClientList" className="me-2 ms-2">Register Client list</a>{" "}
                <span>/</span>
                <a href="/EditRegisterClient" className="me-2 ms-2">Corporate Register Client Detail</a>{" "}
                <span>/</span>
                <span className="ms-2">Corporate Client Service Activities</span>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-6 col-12 mt-3 mt-md-0">
              <p className="mb-2 fontWeight500">Corporate Client Activities Status</p>
              <Input type="text" readOnly className="" id="txtCorporateClientActivitiesStatus" />
            </Col>
            <Col className="col-lg-1 co-md-6col-12 mt-3 mt-md-0 bckbtn">
              <a
                className="btn btn-primary btn-sm float-end font-14"
                href="/ServiceMonthlyDues"
              >
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>


          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div className="ind-card" id="ind-card">

                <Row>
                  <Col className="col-lg-6 col-12">
                    <Card>
                      <CardBody>
                        <div className="accordion">
                          <div className="accordion-item">
                            <h2 className="accordion-header" >
                              <button className="accordion-button" type="button">Follow ups{" "}</button>
                            </h2>
                            <div className="accordion-collapse collapse show">
                              <div className="accordion-body">

                                <div className="commonheightchatnew">
                                  <Row>
                                    <div className="col-lg-12">
                                      <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Action</label>
                                        <input type="text" className="form-control" placeholder="Enter Action" id="txtFollowupAction" aria-describedby="emailHelp" onChange={e => setFollowupAction(e.target.value)} />
                                      </div>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Follow-up Date</label>
                                        <input type="date" className="form-control" placeholder="Enter Next Follow up Date" id="txtFollowupDate" aria-describedby="emailHelp" onChange={e => setFollowupDate(e.target.value)} />
                                      </div>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Follow-up Time</label>
                                        <input type="time" className="form-control" placeholder="Enter Next Follow up Time" id="txtFollowupTime" aria-describedby="emailHelp" onChange={e => setFollowupTime(e.target.value)} />
                                      </div>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Follow-up Mode</label>
                                        <select id="ddlFollowupMode" className="form-select" aria-label="Default select example" onChange={e => setFollowupMode(e.target.value)}>
                                          <option selected value="Select Mode">Select Mode</option>
                                          <option value="Call">Call</option>
                                          <option value="Email">Email</option>
                                          <option value="Meeting">Meeting</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Notes for Follow-up</label>
                                        <textarea type="" rows="2" className="form-control" placeholder="Enter Notes for Next Follow-up" id="txtFollowupNote" aria-describedby="emailHelp" onChange={e => setFollowupNote(e.target.value)} />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <a className="btn btn_success btn-sm font-14 me-2" id="lnkFollowupSave" onClick={e => onClickActivitiesFollowup()}>Save</a>
                                      <a className="btn btn_danger btn-sm  font-14" href="#">Cancel</a>
                                    </div>
                                    <div className="col-lg-12 mt-3">
                                      <div className="table-responsive">
                                        <table className="table table-bordered align-middle whitenowrapedit">
                                          <thead>
                                            <tr>
                                              <th className="textaligncenter">#</th>
                                              <th className="textaligncenter">Action</th>
                                              <th className="textaligncenter">Date<br />Time</th>
                                              <th className="textaligncenter">Mode</th>
                                              <th>Note<br />Remarks</th>
                                              <th className="textaligncenter">Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {ActivitiesfollowupList.map((item, idx) => (
                                              <tr key={idx}>
                                                <td className="textaligncenter">{idx + 1}</td>
                                                <td className="textaligncenter">{item.FollowupAction}</td>
                                                <td className="textaligncenter">{item.SFollowupDate}<br />{item.SFollowupTime}</td>
                                                <td className="textaligncenter">{item.FollowupMode}</td>
                                                <td>Note : {item.FollowupNote}<br />Remarks : {item.FollowupRemark}</td>
                                                <td className="textaligncenter">
                                                  <button className="text-primary deletebuttonclass" onClick={e => onClickEditActivitiesFollowup(item.Id, item.FollowupRemark)}><i className="mdi mdi-pencil font-size-18"></i></button>
                                                </td>
                                              </tr>
                                            ))}

                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </Row>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="col-lg-6 col-12">
                    <Card>
                      <CardBody>
                        <div className="accordion">
                          <div className="accordion-item">
                            <h2 className="accordion-header" >
                              <button className="accordion-button" style={{ position: 'relative' }} type="button">Corporate Chat{" "}
                                <a style={{ position: 'absolute', right: '20px' }} onClick={e => RefreshMessageClick()}><i className="mdi mdi-refresh font-size-18"></i></a>
                              </button>
                            </h2>
                            <div className="accordion-collapse collapse show">
                              <div className="accordion-body">
                                <Row className="mb-2">
                                  <div className="col-lg-10 pe-0">
                                    <textarea rows="1" type="text" className="form-control" placeholder="Type a message" id="txtSendMessage" onChange={e => setSendMessageText(e.target.value)} />
                                  </div>
                                  <div className="col-lg-2 d-flex justify-content-end">
                                    <a className="btn btn_success btn-sm font-14 me-2 btnheight" id="btnUploadPropertyImage"><i className="mdi mdi-attachment font-size-18"></i></a>
                                    <a className="btn btn_success btn-sm font-14 me-0 btnheight" id="lnkSendMessage" onClick={e => SendMessageClick()}><i className="mdi mdi-telegram font-size-18"></i></a>
                                    <input type="file" id="fuUploadPropertyImage" onChange={handleChange} className="d-none" />
                                  </div>
                                </Row>
                                <Row>
                                  <div className="col-lg-12">
                                    <div className="mainhightnewedit" >
                                      <div id="divUserChat">
                                        {UserMessageList.map((item, idx) => (
                                          item.SenderType == 'U' ?
                                            <div className="chatborderend mb-1" key={idx}>
                                              <div className="mt-1">
                                                <p className="chatlistclass me-2 mb-1">{item.MessageText}</p>
                                                {item.MessageFilePathFlag == "A" ?
                                                  item.MessageFileType == "JPEG" ?
                                                    <a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer"><img className="chatimagefileclass mb-1 me-2 mt-1" src={item.MessageFilePath}></img></a>
                                                    :
                                                    item.MessageFileType == "JPG" ?
                                                      <a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer"><img className="chatimagefileclass mb-1 me-2 mt-1" src={item.MessageFilePath}></img></a>
                                                      :
                                                      item.MessageFileType == "PNG" ?
                                                        <a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer"><img className="chatimagefileclass mb-1 me-2 mt-1" src={item.MessageFilePath}></img></a>
                                                        :
                                                        item.MessageFileType == "GIF" ?
                                                          <a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer"><img className="chatimagefileclass mb-1 me-2 mt-1" src={item.MessageFilePath}></img></a>
                                                          :
                                                          <p className="chatlistclass newfilenameditname me-2 mt-1"><a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer"><i className="mdi mdi-folder-download font-size-18" aria-hidden="true" ></i> {item.MessageFileName}</a></p>
                                                  :
                                                  ""
                                                }
                                                <p className="chattimeclass me-2">{item.SendMessageDateTime}</p>
                                              </div>
                                            </div>
                                            :
                                            <div className="d-flex chatborder mb-1">
                                              <div className="me-2 ms-2 mt-1">
                                                <img className="rounded-circle chat-profile-user" src="/static/media/avatar-1.c69c03fa.jpg" alt="Header Avatar" />
                                              </div>
                                              <div className="mt-1">
                                                <p className="chatlistclass"><b>{item.SenderName}</b></p>
                                                <p className="chatlistclass mb-1">{item.MessageText}</p>
                                                {item.MessageFilePathFlag == "A" ?
                                                  item.MessageFileType == "JPEG" ?
                                                    <a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer"><img className="chatimagefileclass mb-1 mt-1" src={item.MessageFilePath}></img></a>
                                                    :
                                                    item.MessageFileType == "JPG" ?
                                                      <a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer"><img className="chatimagefileclass mb-1 mt-1" src={item.MessageFilePath}></img></a>
                                                      :
                                                      item.MessageFileType == "PNG" ?
                                                        <a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer"><img className="chatimagefileclass mb-1 mt-1" src={item.MessageFilePath}></img></a>
                                                        :
                                                        item.MessageFileType == "GIF" ?
                                                          <a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer"><img className="chatimagefileclass mb-1 mt-1" src={item.MessageFilePath}></img></a>
                                                          :
                                                          <p className="chatlistclass newfilenameditname mt-1"><a href={item.MessageFilePath} target="_blank" rel="noopener noreferrer"><i className="mdi mdi-folder-download font-size-18" aria-hidden="true" ></i> {item.MessageFileName}</a></p>
                                                  :
                                                  ""
                                                }
                                                <p className="chattimeclass">{item.SendMessageDateTime}</p>
                                              </div>
                                            </div>
                                        ))}
                                      </div>
                                      <div ref={messagesEndRef} />
                                    </div>
                                  </div>

                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-lg-12">
                    <Card>
                      <CardBody>
                        <div className="accordion" id="accordionExample">
                          {BureauStepDetail.map(item2 => (
                            <div className="accordion-item" key={item2.RowId}>
                              <h2 className="accordion-header" id={item2.AccordionId1}>
                                <button className={item2.RowId == 1 ? 'accordion-button' : 'accordion-button collapsed'} type="button" data-bs-toggle="collapse" data-bs-target={item2.collapse2} aria-expanded={item2.RowId == 1 ? 'true' : 'false'} aria-controls={item2.collapse1}>

                                  <Row className="duerowclass">
                                    <Col className="col-lg-4">{item2.Title}{" "}</Col>
                                    <Col className="col-lg-4 duedateclass"></Col>
                                    <Col className="col-lg-4 duestatusclass"> <input type="text" id={item2.ActivitiesStatusControlId} defaultValue={item2.ActivitiesStatusCode == null ? 'Pending' : item2.ActivitiesStatusCode} className="text-danger activity-status fw-bold textborderhide" aria-describedby="emailHelp" /></Col>
                                  </Row>

                                </button>
                              </h2>
                              <div id={item2.collapse1} className={item2.RowId == 1 ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'} aria-labelledby={item2.AccordionId1} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <Row>
                                    <Col className="col-lg-9 mb-1">
                                      <b><u>Description :</u></b>
                                    </Col>
                                    {item2.ServiceActivitiesFilesCount > 0 ?
                                      <Col className="col-lg-3 mb-1 duestatusclass">
                                        <b><u><a className="referencedocumentsclass" onClick={e => onClickActivitiesFile(item2.Id)}><i className="fas fa-eye ms-2 fasfaeye"></i><span className="ms-1">Reference Documents</span></a></u></b>
                                      </Col>
                                      : ''}
                                    <Col className="col-lg-12">
                                      {item2.Description}
                                    </Col>
                                    <Col className="col-lg-12 mt-3 mb-3">
                                      <hr />
                                    </Col>
                                  </Row>
                                  <Row>

                                    {BureauStepActivitiesDetail.filter(x => x.MasterId == item2.Id).map(ADitem => (
                                      <div className="col-md-6 col-lg-3 col-12" key={ADitem.RowId}>
                                        <form>
                                          <div className="mb-3">
                                            <label htmlFor={ADitem.FormControlId} className="form-label">{ADitem.ActionTitle}</label>
                                            {ADitem.ActionType == 'File'
                                              ?
                                              <a id={ADitem.LinkControlId} className="linkcontrolid">Jaydeep</a>
                                              :
                                              ''}
                                            {ADitem.ActionType == 'Date'
                                              ?
                                              <input type="date" id={ADitem.FormControlId} className="form-control" aria-describedby="emailHelp" onChange={e => GetActionStatusCode(item2, ADitem)} />
                                              :
                                              ADitem.ActionType == 'Text'
                                                ?
                                                <input type="text" className="form-control" placeholder={ADitem.FormControlPlaceholder} id={ADitem.FormControlId} aria-describedby="emailHelp" onChange={e => GetActionStatusCode(item2, ADitem)} />
                                                :
                                                ADitem.ActionType == 'TextArea'
                                                  ?
                                                  <textarea type="" rows="1" className="form-control" placeholder={ADitem.FormControlPlaceholder} id={ADitem.FormControlId} aria-describedby="emailHelp" onChange={e => GetActionStatusCode(item2, ADitem)} />
                                                  :
                                                  ADitem.ActionType == 'Number'
                                                    ?
                                                    <input type="number" className="form-control" placeholder={ADitem.FormControlPlaceholder} id={ADitem.FormControlId} aria-describedby="emailHelp" onChange={e => GetActionStatusCode(item2, ADitem)} />
                                                    :
                                                    ADitem.ActionType == 'File'
                                                      ?
                                                      <input type="file" className="form-control" placeholder={ADitem.FormControlPlaceholder} aria-describedby="emailHelp" onChange={e => getDocumentpath(e, ADitem.FormControlId)}  />
                                                      :
                                                      ''
                                            }
                                            {ADitem.ActionType == 'File'
                                              ?
                                              <input type="hidden" id={ADitem.FormControlId} className="form-control" aria-describedby="emailHelp" />
                                              :
                                              ''}
                                          </div>
                                        </form>
                                      </div>
                                    ))}
                                  </Row>
                                  <Row>
                                    <div className="col-lg-4 co-md-6 col-12 mb-3">
                                      <button className="btn btn_success btn-sm font-14 me-2" id={item2.AddButtonControlId} onClick={e => onClickActivitiesAction(item2)}>Save</button>
                                      <button className="btn btn_danger btn-sm  font-14" id={item2.CancelButtonControlId} onClick={e => onClickActivitiesCancelAction(item2)}>Cancel</button>
                                      <input type="hidden" id={item2.AddRowCountControlId} value="0"></input>
                                    </div>
                                  </Row>
                                  <Row>
                                    <Col className="col-lg-12">
                                      <hr />
                                      <CardTitle>Action Log</CardTitle>
                                      <hr />
                                    </Col>
                                    <div className="col-lg-12 mt-3">
                                      <div className="table-responsive">
                                        <table className="table table-bordered align-middle whitenowrapedit">
                                          <thead>
                                            <tr>
                                              <th className="textaligncenter">#</th>
                                              {BureauStepActivitiesDetail.filter(x => x.MasterId == item2.Id).map(ADitem => (
                                                <th scope="col" key={ADitem.RowId}>{ADitem.ActionTitle}</th>
                                              ))}
                                              <th className="textaligncenter">Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {BureauStepActivitiesActionGroupBy.filter(x => x.ServiceActivitiesId == item2.Id).map((rcitem, idx) => (
                                              <tr key={idx}>
                                                <td scope="col" className="text-center">{idx + 1}</td>
                                                {BureauStepActivitiesActionDetail.filter(x => x.ServiceActivitiesId == item2.Id && x.RowIndexId == rcitem.RowIndexId).sort((a, b) => (a.ServiceActivitiesActionId - b.ServiceActivitiesActionId)).map((rcisubtem, idx1) => (
                                                  <td scope="col" key={idx1}>
                                                    {rcisubtem.ActionType == 'Date' ? rcisubtem.SActionValueDate : rcisubtem.ActionType == 'File' ? <a href={rcisubtem.ActionValue} target="_blank" rel="noopener noreferrer" className="fadocumenttitleiconcolor">{rcisubtem.ActionUploadFileName}</a> : rcisubtem.ActionValue}
                                                  </td>
                                                ))}
                                                <td className="textaligncenter">
                                                  <button className="text-primary deletebuttonclass" onClick={e => EditActivitiesAction(item2, rcitem)}><i className="mdi mdi-pencil font-size-18"></i></button>
                                                  <button className="text-danger deletebuttonclass" onClick={e => DeleteActivitiesAction(item2, rcitem)}><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></button>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
  executeScroll = () => myRef.current.scrollIntoView();

}
export default UiRating;
