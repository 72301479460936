import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import $ from 'jquery';

const AddInvoice = () => {
    const [InvoiceType, setInvoiceType] = useState('');
    const [InvoiceClientId, setInvoiceClientId] = useState(0);
    const [InvoiceName, setInvoiceName] = useState('');
    const [InvoiceMobileNo, setInvoiceMobileNo] = useState('');
    const [InvoiceEmailAddress, setInvoiceEmailAddress] = useState('');
    const [InvoiceGSTNumber, setGSTNumber] = useState('');
    const [InvoiceCompanyName, setInvoiceCompanyName] = useState('');
    const [InvoiceAddress, setInvoiceAddress] = useState('');
    const [InvoiceState, setInvoiceState] = useState('');
    const [BillDate, setBillDate] = useState('');
    const [DueDate, setDueDate] = useState('');
    const [Particular, setParticular] = useState('');
    const [Amount, setAmount] = useState(0);
    const [IsGstApply, setIsGstApply] = useState('No');
    const [GstId, setGstId] = useState(0);
    const [Description, setDescription] = useState('');
    const [GSTList, SetGSTList] = useState([]);

    const [InvoiceItemList, SetInvoiceItemList] = useState([]);
    const [ServicePackageList, setServicePackageList] = useState([]);

    const [PaymentModeList, setPaymentModeList] = useState([]);
    const [CompanyList, setCompanyList] = useState([]);

    const [PaymentModeId, setPaymentModeId] = useState(0);
    const [PaymentModeTitle, setPaymentModeTitle] = useState('');

    const [ShowBankName, setShowBankName] = useState(0);
    const [ShowBranch, setShowBranch] = useState(0);
    const [ShowChequeDDDate, setShowChequeDDDate] = useState(0);
    const [ShowUTR, setShowUTR] = useState(0);
    const [ShowSettlement, setShowSettlement] = useState(0);
    const [ShowTransactionNumber, setShowTransactionNumber] = useState(0);

    const [CompanyId, setCompanyId] = useState(0);
    const [ChequeDDNo, setChequeDDNo] = useState('');
    const [ChequeDDDate, setChequeDDDate] = useState('');
    const [TransactionDate, setTransactionDate] = useState('');
    const [BankName, setBankName] = useState('');
    const [Branch, setBranch] = useState('');

    const [UTR, setUTR] = useState('');
    const [Settlement, setSettlement] = useState('');

    useEffect(() => {
        getGSTList();
        getPaymentModeList();
        getCompanyList();
    }, []);

    const getGSTList = async () => {
        var ACUrl = global.APIURL + '/GSTList';
        axios.get(ACUrl)
            .then(Cires => {
                let CityL = Cires.data.map(kccity => {
                    return kccity;
                });
                SetGSTList(CityL);
            });
    };

    const getPaymentModeList = async () => {
        var AUrl = global.APIURL + '/PaymentModeActive';
        axios.get(AUrl).then(Pres => {
            let PaymentModeL = Pres.data.map((PaymentMode) => {
                return PaymentMode
            })
            setPaymentModeList(PaymentModeL);
        })
    }

    const PaymentModeOnChange = e => {
        var VPaymentMode = e.nativeEvent.target.selectedIndex;
        var PaymentModeId = e.nativeEvent.target[VPaymentMode].value;
        var PaymentModeText = e.nativeEvent.target[VPaymentMode].text;
        setPaymentModeId(PaymentModeId);
        setPaymentModeTitle(PaymentModeText);
        if (PaymentModeText == 'Cash') {
            setShowTransactionNumber(0);
            setShowBankName(0);
            setShowBranch(0);
            setShowChequeDDDate(0);
            setShowUTR(0);
            setShowSettlement(0);
        }
        else if (PaymentModeText == 'Cheque') {
            setShowTransactionNumber(1);
            setShowBankName(1);
            setShowBranch(1);
            setShowChequeDDDate(1);
            setShowUTR(0);
            setShowSettlement(0);
        }
        else if (PaymentModeText == 'Credit Card / Debit Card') {
            setShowTransactionNumber(1);
            setShowBankName(1);
            setShowBranch(1);
            setShowChequeDDDate(1);
            setShowUTR(0);
            setShowSettlement(0);
        }
        else if (PaymentModeText == 'NEFT') {
            setShowTransactionNumber(1);
            setShowBankName(1);
            setShowBranch(1);
            setShowChequeDDDate(1);
            setShowUTR(0);
            setShowSettlement(0);
        }
        else if (PaymentModeText == 'Paytm') {
            setShowTransactionNumber(1);
            setShowBankName(0);
            setShowBranch(0);
            setShowChequeDDDate(0);
            setShowUTR(1);
            setShowSettlement(1);
        }
        else if (PaymentModeText == 'E-Wallets except Paytm') {
            setShowTransactionNumber(1);
            setShowBankName(0);
            setShowBranch(0);
            setShowChequeDDDate(0);
            setShowUTR(1);
            setShowSettlement(1);
        }
        else if (PaymentModeText == 'Free') {
            setShowTransactionNumber(1);
            setShowBankName(0);
            setShowBranch(0);
            setShowChequeDDDate(0);
            setShowUTR(0);
            setShowSettlement(0);
        }
        else if (PaymentModeText == 'CASH & CARD') {
            setShowTransactionNumber(1);
            setShowBankName(1);
            setShowBranch(1);
            setShowChequeDDDate(1);
            setShowUTR(0);
            setShowSettlement(0);
        }

        else if (PaymentModeText == 'Cash & Cheque') {
            setShowTransactionNumber(1);
            setShowBankName(1);
            setShowBranch(1);
            setShowChequeDDDate(1);
            setShowUTR(0);
            setShowSettlement(0);
        }
        else if (PaymentModeText == 'Phone pay') {
            setShowTransactionNumber(1);
            setShowBankName(0);
            setShowBranch(0);
            setShowChequeDDDate(0);
            setShowUTR(1);
            setShowSettlement(1);
        }
        else {
            setShowTransactionNumber(0);
            setShowBankName(0);
            setShowBranch(0);
            setShowChequeDDDate(0);
            setShowUTR(0);
            setShowSettlement(0);
        }
    };

    const getCompanyList = async () => {
        var AUrl = global.APIURL + '/CompanyActive';
        axios.get(AUrl).then(SPres => {
            let CompanyL = SPres.data.map((Company) => {
                return Company
            })
            setCompanyList(CompanyL);
        })
    }

    const RetailloadOptions = (inputValue, callback) => {
        var SearchValue = inputValue == null ? 'ALL' : inputValue == '' ? 'ALL' : inputValue;
        fetch(global.APIURL + "/RetailSearchList/" + SearchValue)
            .then((res) => res.json())
            .then((data) => {

                callback(
                    data.map((f) => {
                        return {
                            value: f.LeadId,
                            label: f.FullName
                        };
                    })
                );
            })
    };

    const CorporateloadOptions = (inputValue, callback) => {
        var SearchValue = inputValue == null ? 'ALL' : inputValue == '' ? 'ALL' : inputValue;
        fetch(global.APIURL + "/CorporateSearchList/" + SearchValue)
            .then((res) => res.json())
            .then((data) => {

                callback(
                    data.map((f) => {
                        return {
                            value: f.CorporateleadId,
                            label: f.Title
                        };
                    })
                );
            })
    };

    const getInviceClientDetail = async ItemArr => {
        const FilterItemArr = {
            InvoiceClientId: ItemArr.value == null ? 0 : ItemArr.value == '' ? 0 : ItemArr.value,
            SpMode: InvoiceType == 'Retail' ? 'RetailSearchList' : InvoiceType == 'Corporate' ? 'CorporateSearchList' : ''
        }
        axios.post(global.APIURL + '/InvoiceClientDetail', FilterItemArr)
            .then(res => {
                if (res.data != '') {
                    setInvoiceClientId(res.data[0].InvoiceClientId == null ? 0 : res.data[0].InvoiceClientId);
                    setInvoiceName(res.data[0].FullName == null ? '' : res.data[0].FullName);
                    setInvoiceMobileNo(res.data[0].MobileNumber == null ? '' : res.data[0].MobileNumber);
                    setInvoiceEmailAddress(res.data[0].EmailAddress == null ? '' : res.data[0].EmailAddress);
                    setGSTNumber(res.data[0].GSTNo == null ? '' : res.data[0].GSTNo);
                    setInvoiceCompanyName(res.data[0].CompanyName == null ? '' : res.data[0].CompanyName);
                    setInvoiceAddress(res.data[0].Address == null ? '' : res.data[0].Address);
                    setInvoiceState(res.data[0].StateName == null ? '' : res.data[0].StateName);
                }
                else {
                    setInvoiceClientId(0);;
                    setInvoiceName('');
                    setInvoiceMobileNo('');
                    setInvoiceEmailAddress('');
                    setGSTNumber('');
                    setInvoiceCompanyName('');
                    setInvoiceAddress('');
                    setInvoiceState('');
                }
            });

        if (InvoiceType == 'Retail') {
            const CSerPackArr = {
                LeadId: ItemArr.value == null ? 0 : ItemArr.value == '' ? 0 : ItemArr.value,
            };
            var AUrl = global.APIURL + '/ClientServicePackage';
            axios.post(AUrl, CSerPackArr)
                .then(CSPres => {
                    let ServicePackageList = CSPres.data.map(ServicePackage => {
                        return ServicePackage;
                    });
                    setServicePackageList(ServicePackageList);
                });
        }
        else if (InvoiceType == 'Corporate') {
            const CSerPackArr = {
                LeadId: ItemArr.value == null ? 0 : ItemArr.value == '' ? 0 : ItemArr.value,
            };
            var AUrl = global.APIURL + '/ClientServicePackage';
            axios.post(AUrl, CSerPackArr)
                .then(CSPres => {
                    let ServicePackageList = CSPres.data.map(ServicePackage => {
                        return ServicePackage;
                    });
                    setServicePackageList(ServicePackageList);
                });
        }
    };

    const getInviceClientServiceDetail = async (ServiceId) => {

        var VInvoiceClientId = parseInt(InvoiceClientId == null ? 0 : InvoiceClientId == '' ? 0 : InvoiceClientId)
        var VInvoiceClientServiceId = parseInt(ServiceId == null ? 0 : ServiceId == '' ? 0 : ServiceId)

        console.log(VInvoiceClientId);
        console.log(VInvoiceClientServiceId);

        const CSItemArr = {
            InvoiceClientId: VInvoiceClientId,
            InvoiceClientServiceId: VInvoiceClientServiceId
        };
        var AUrl = global.APIURL + '/InvoiceClientServiceDetail';
        axios.post(AUrl, CSItemArr)
            .then(CSPres => {
                if (CSPres.data.length > 0) {
                    console.log(CSPres.data[0])
                    $("#txtParticular").val(CSPres.data[0].Title == null ? '' : CSPres.data[0].Title);
                    $("#txtAmount").val(CSPres.data[0].TotalPendingAmount == null ? '' : CSPres.data[0].TotalPendingAmount);
                    $("#txtDescription").val(CSPres.data[0].Title == null ? '' : CSPres.data[0].Title);

                    setParticular(CSPres.data[0].Title == null ? '' : CSPres.data[0].Title);
                    setAmount(parseFloat(CSPres.data[0].TotalPendingAmount == null ? '0' : CSPres.data[0].TotalPendingAmount == '' ? '0' : CSPres.data[0].TotalPendingAmount));
                    setDescription(CSPres.data[0].Title == null ? '' : CSPres.data[0].Title);
                }
                else {
                    $("#txtParticular").val('');
                    $("#txtAmount").val('');
                    $("#txtDescription").val('');

                    setParticular('');
                    setAmount(0);
                    setDescription('');
                }
            });
    };

    const InvoiceItemAddRow = () => {
        var AddRow = 1;
        if (Particular == null) {
            alert('Please input Particular.');
            AddRow = 0;
        }

        if (Particular == '') {
            alert('Please input Particular.');
            AddRow = 0;
        }

        if (Amount == null) {
            alert('Please input Amount.');
            AddRow = 0;
        }

        if (Amount == '') {
            alert('Please input Amount.');
            AddRow = 0;
        }

        if (Amount == 0) {
            alert('Please input Amount.');
            AddRow = 0;
        }


        if (IsGstApply == null) {
            alert('Please select is gst apply.');
            AddRow = 0;
        }

        if (IsGstApply == '') {
            alert('Please select is gst apply.');
            AddRow = 0;
        }

        if (IsGstApply == 'Yes') {

            if (GstId == null) {
                alert('Please input Gst Per.');
                AddRow = 0;
            }

            if (GstId == '') {
                alert('Please input Gst Per.');
                AddRow = 0;
            }

            if (GstId == 0) {
                alert('Please input Gst Per.');
                AddRow = 0;
            }
        }

        if (AddRow == 1) {
            const InvoiceItemArr = {
                RowNo: InvoiceItemList.length + 1,
                Particular: Particular,
                Amount: Amount,
                IsGstApply: IsGstApply,
                GSTPerId: $("#ddlGST option:selected").val(),
                GSTPer: $("#ddlGST option:selected").text(),
                Description: Description,
            };
            InvoiceItemList.push(InvoiceItemArr);

            setParticular('');
            setAmount(0);
            setIsGstApply('No');
            setGstId(0);
            setDescription('');

            $("#txtParticular").val('');
            $("#txtAmount").val('');
            $("#ddlGST").val(0);
            $("#txtDescription").val('');
            $("input[name='rbIsGSTApply']").prop("checked", false);
        }
    };

    const InvoiceItemClear = () => {
        setParticular('');
        setAmount(0);
        setIsGstApply('No');
        setGstId(0);
        setDescription('');

        $("#txtParticular").val('');
        $("#txtAmount").val('');
        $("#ddlGST").val(0);
        $("#txtDescription").val('');
        $("input[name='rbIsGSTApply']").prop("checked", false);
    }

    const InvoiceClear = () => {
        window.location.href = "/InvoiceManage/AddInvoice";
    }

    const SaveInvoice = async () => {
        var AddRow = 1;
        if (InvoiceType == null) {
            alert('Select Invoice Type.');
            AddRow = 0;
            return;
        }

        if (InvoiceType == '') {
            alert('Select Invoice Type.');
            AddRow = 0;
            return;
        }

        if ($("#txtName").val() == null) {
            alert('Please Select Invoice Client.');
            AddRow = 0;
            return;
        }

        if ($("#txtName").val() == '') {
            alert('Please Select Invoice Client.');
            AddRow = 0;
            return;
        }

        // if ($("#txtMobileNo").val() == null) {
        //     alert('Please Input Mobile No..');
        //     AddRow = 0;
        //     return;
        // }

        // if ($("#txtMobileNo").val() == '') {
        //     alert('Please Input Mobile No..');
        //     AddRow = 0;
        //     return;
        // }

        // if ($("#txtEmailAddress").val() == null) {
        //     alert('Please Input Email Address.');
        //     AddRow = 0;
        //     return;
        // }

        // if ($("#txtEmailAddress").val() == '') {
        //     alert('Please Input Email Address.');
        //     AddRow = 0;
        //     return;
        // }

        if ($("#txtBillDate").val() == null) {
            alert('Please Input Bill Date.');
            AddRow = 0;
            return;
        }

        if ($("#txtBillDate").val() == '') {
            alert('Please Input Bill Date.');
            AddRow = 0;
            return;
        }

        if ($("#txtDueDate").val() == null) {
            alert('Please Input Due Date.');
            AddRow = 0;
            return;
        }

        if ($("#txtDueDate").val() == '') {
            alert('Please Input Due Date.');
            AddRow = 0;
            return;
        }

        if (CompanyId == 0) {
            AddRow = 0;
            alert("Please select company");
            return;
        }
        if (PaymentModeId == 0) {
            AddRow = 0;
            alert("Please select payment mode");
            return;
        }
        if (TransactionDate == '') {
            AddRow = 0;
            alert("Please select transaction date");
            return;
        }
        if (PaymentModeId != 1) {
            if (ChequeDDNo == '') {
                AddRow = 0;
                alert("Please input transaction number");
                return;
            }
        }

        if (PaymentModeId == 5) {
            if (UTR == '') {
                AddRow = 0;
                alert("Please input UTR");
                return;
            }
        }
        if (PaymentModeId == 12) {
            if (UTR == '') {
                AddRow = 0;
                alert("Please input UTR");
                return;
            }
        }

        if (PaymentModeId == 5) {
            if (Settlement == '') {
                AddRow = 0;
                alert("Please input Settlement");
                return;
            }
        }
        if (PaymentModeId == 12) {
            if (Settlement == '') {
                AddRow = 0;
                alert("Please input Settlement");
                return;
            }
        }

        if (AddRow > 0) {
            var VSubTotal = 0;
            var VGSTAmount = 0;
            var VTotalAmount = 0;

            for (let ii = 0; ii < InvoiceItemList.length; ii++) {
                const InvoiceItemDetail = InvoiceItemList[ii];
                var VIsGstApply = InvoiceItemDetail.IsGstApply == null ? '' : InvoiceItemDetail.IsGstApply;
                if (VIsGstApply == 'Yes') {
                    var VAmount = parseFloat(InvoiceItemDetail.Amount == null ? 0 : InvoiceItemDetail.Amount);
                    var VGSTPer = parseFloat(InvoiceItemDetail.GSTPer == null ? 0 : InvoiceItemDetail.GSTPer);
                    var VAGSTAmount = parseFloat(VAmount) * parseFloat(VGSTPer) / 100;
                    var VATotalAmount = parseFloat(VAmount) + parseFloat(VAGSTAmount);

                    VSubTotal = parseFloat(VSubTotal) + parseFloat(VAmount);
                    VGSTAmount = parseFloat(VGSTAmount) + parseFloat(VAGSTAmount);
                    VTotalAmount = parseFloat(VTotalAmount) + parseFloat(VATotalAmount);
                }
                else {
                    var VAmount = parseFloat(InvoiceItemDetail.Amount == null ? 0 : InvoiceItemDetail.Amount);
                    VSubTotal = parseFloat(VSubTotal) + parseFloat(VAmount);
                    VGSTAmount = parseFloat(VGSTAmount) + 0;
                    VTotalAmount = parseFloat(VTotalAmount) + parseFloat(VAmount);
                }
            }

            const InvoiceArr = {
                InvoiceId: 0,
                InvoiceType: InvoiceType,
                InvoiceClientId: InvoiceClientId,
                ClientServicePackageId: $("#ddlRetailService option:selected").val(),
                InvoiceName: $("#txtName").val(),
                InvoiceMobileNo: $("#txtMobileNo").val(),
                InvoiceEmailAddress: $("#txtEmailAddress").val(),
                InvoiceGSTNumber: $("#txtGSTNumber").val(),
                InvoiceCompanyName: $("#txtCompanyName").val(),
                BillDate: $("#txtBillDate").val(),
                DueDate: $("#txtDueDate").val(),
                InvoiceAddress: $("#txtAddress").val(),
                SubTotal: VSubTotal,
                GSTAmount: VGSTAmount,
                TotalAmount: VTotalAmount,

                CompanyId: CompanyId,
                PaymentModeId: PaymentModeId,
                PaymentMode: PaymentModeTitle,
                BankName: BankName,
                Branch: Branch,
                ChequeDDNo: ChequeDDNo,
                ChequeDDDate: ChequeDDDate == "" ? TransactionDate : ChequeDDDate,
                TransactionDate: TransactionDate,

                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                SpMode: 'InvoiceSave'
            };
            console.log(InvoiceArr);
            axios.post(global.APIURL + "/InvoiceSave", InvoiceArr)
                .then(res => {
                    var VRecordInsertId = res.data[0].RecordInsertId;
                    var VRowCount = InvoiceItemList.length;
                    for (let ii = 0; ii < InvoiceItemList.length; ii++) {
                        var VSubGSTAmount = 0;
                        var VSubCGSTAmount = 0;
                        var VSubSGSTAmount = 0;
                        var VSubIGSTAmount = 0;
                        var VSubTotalAmount = 0;
                        const InvoiceItemDetail = InvoiceItemList[ii];
                        var VAmount = parseFloat(InvoiceItemDetail.Amount == null ? 0 : InvoiceItemDetail.Amount);
                        var VGSTPer = parseFloat(InvoiceItemDetail.GSTPer == null ? 0 : InvoiceItemDetail.GSTPer);
                        var VIsGstApply = InvoiceItemDetail.IsGstApply == null ? '' : InvoiceItemDetail.IsGstApply;
                        if (VIsGstApply == 'Yes') {
                            VSubGSTAmount = parseFloat(VAmount * VGSTPer / 100);
                            VSubTotalAmount = parseFloat(VAmount + VSubGSTAmount);
                            VSubCGSTAmount = InvoiceState === 'gujarat' ? parseFloat(((VAmount * (VGSTPer / 2)) / 100)) : 0;
                            VSubSGSTAmount = InvoiceState === 'gujarat' ? parseFloat(((VAmount * (VGSTPer / 2)) / 100)) : 0;
                            VSubIGSTAmount = InvoiceState != 'gujarat' ? parseFloat(VAmount * VGSTPer / 100) : 0;
                        }
                        else {
                            VSubGSTAmount = 0;
                            VSubCGSTAmount = 0;
                            VSubSGSTAmount = 0;
                            VSubIGSTAmount = 0;
                            VSubTotalAmount = parseFloat(VAmount + VSubGSTAmount);
                        }

                        const InvoiceItemArr = {
                            InvoiceId: VRecordInsertId,
                            Particular: InvoiceItemDetail.Particular == null ? '' : InvoiceItemDetail.Particular,
                            Amount: VAmount,
                            IsGstApply: InvoiceItemDetail.IsGstApply == null ? 'No' : InvoiceItemDetail.IsGstApply,
                            GSTPerId: InvoiceItemDetail.IsGstApply == 'No' ? 0 : InvoiceItemDetail.GSTPerId == null ? 0 : InvoiceItemDetail.GSTPerId,
                            GSTPer: InvoiceItemDetail.IsGstApply == 'No' ? 0 : InvoiceItemDetail.GSTPer == null ? 0 : InvoiceItemDetail.GSTPer,
                            CGSTAmount: VSubCGSTAmount,
                            SGSTAmount: VSubSGSTAmount,
                            IGSTAmount: VSubIGSTAmount,
                            GSTAmount: VSubGSTAmount,
                            TotalAmount: VSubTotalAmount,
                            Description: InvoiceItemDetail.Description == null ? '' : InvoiceItemDetail.Description,
                            CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                            SpMode: 'InvoiceItemSave'
                        };
                        axios.post(global.APIURL + "/InvoiceItemSave", InvoiceItemArr)
                            .then(res1 => {

                                if (ii == (VRowCount - 1)) {
                                    alert('Invoice information save successfully.');
                                    window.location.href = "/InvoiceManage";
                                }
                            });
                    }
                });
        }
    }

    const InvoiceItemRemoveRow = (e, RowIndex) => {
        let AlertMessgae = "Are you sure you want to delete this record?";
        if (confirm(AlertMessgae) == true) {
            let VRowIndex = RowIndex;
            SetInvoiceItemList(
                InvoiceItemList.filter(item => item.RowNo !== VRowIndex)
            );
        }
    };

    const RBInvoiceTypeChange = async InvoiceType => {
        $("#txtParticular").val('');
        $("#txtAmount").val('');
        $("#txtDescription").val('');

        setParticular('');
        setAmount(0);
        setDescription('');

        if (InvoiceType == 'Retail') {
            $("#divRetail").removeClass("d-none");
            $("#divCorporate").removeClass("d-none");
            $("#divRetailService").removeClass("d-none");
            $("#divCorporatePaymentDue").removeClass("d-none");
            $("#divCorporate").addClass("d-none");
            $("#divCorporatePaymentDue").addClass("d-none");
        }
        else if (InvoiceType == 'Corporate') {
            $("#divRetail").removeClass("d-none");
            $("#divCorporate").removeClass("d-none");
            $("#divRetailService").removeClass("d-none");
            $("#divCorporatePaymentDue").removeClass("d-none");
            $("#divRetail").addClass("d-none");
            $("#divRetailService").addClass("d-none");
        }
        else {
            $("#divRetail").removeClass("d-none");
            $("#divCorporate").removeClass("d-none");
            $("#divRetailService").removeClass("d-none");
            $("#divCorporatePaymentDue").removeClass("d-none");

            $("#divRetail").addClass("d-none");
            $("#divCorporate").addClass("d-none");
            $("#divRetailService").addClass("d-none");
            $("#divCorporatePaymentDue").addClass("d-none");
        }
    };

    return (
        <div className="page-content">

            <MetaTags>
                <title>AccountType | KC Admin Group</title>
            </MetaTags>

            <div className="container-fluid">

                <Row className="mb-3">
                    <Col className="col-lg-8 col-md-6 col-12 mb-2">
                        <div className="breadcrum ps-0">
                            <CardTitle className="h4">Add Invoice</CardTitle>
                            <a href="/GeneralDashboard" className="me-2">Home</a>
                            <span>/</span>
                            <a href="/InvoiceManage" className="ms-2 me-2">Invoice Manage</a>
                            <span>/</span>
                            <span className="ms-2">Add Invoice</span>
                        </div>
                    </Col>
                    <Col className="col-lg-4 co-md-6 col-12">
                        <a className="btn btn-primary btn-sm float-end font-14" href="/InvoiceManage">
                            <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>

                                <Row>
                                    <Col lg={12} className="d-flex mb-3 mt-3">
                                        <div className="form-check me-2">
                                            <input type="radio" id="rbRetail" className="form-check-input" name="rbinvoicetype" value="Retail" onChange={e => { setInvoiceType('Retail'); RBInvoiceTypeChange('Retail') }} />
                                            <label className="form-check-label" htmlFor="rbRetail">Retail</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="radio" id="rbCorporate" className="form-check-input" name="rbinvoicetype" value="Corporate" onChange={e => { setInvoiceType('Corporate'); RBInvoiceTypeChange('Corporate') }} />
                                            <label className="form-check-label" htmlFor="rbCorporate">Corporate</label>
                                        </div>
                                    </Col>

                                    <Col lg={4} className="d-none" id='divRetail'>
                                        <AsyncSelect cacheOptions loadOptions={RetailloadOptions} defaultOptions onChange={e => getInviceClientDetail(e)} />
                                    </Col>
                                    <Col lg={4} className="d-none" id='divCorporate'>
                                        <AsyncSelect cacheOptions loadOptions={CorporateloadOptions} defaultOptions onChange={e => getInviceClientDetail(e)} />
                                    </Col>
                                    <Col lg={4} className="d-none" id='divRetailService'>
                                        <select id="ddlRetailService" className="form-select" aria-label="Default select example" onChange={e => getInviceClientServiceDetail(e.target.value)}>
                                            <option selected value="0">Select Service</option>
                                            {ServicePackageList.map(g => (
                                                <option key={g.ServicePackageId} value={g.ServicePackageId}>
                                                    {" "}
                                                    {g.ServicePackage}{" "}
                                                </option>
                                            ))}
                                        </select>
                                    </Col>
                                    <Col lg={4} className="d-none" id='divCorporatePaymentDue'>
                                    </Col>
                                    {InvoiceType == "" ? <Col lg={12}></Col> : <Col lg={4}></Col>}

                                    <Col lg={4} className="mt-3">
                                        <label className="form-label">Name</label>
                                        <Input type="text" className="form-control" id="txtName" defaultValue={InvoiceName} />
                                    </Col>
                                    <Col lg={4} className="mt-3">
                                        <label className="form-label">Mobile No.</label>
                                        <Input type="text" className="form-control" id="txtMobileNo" defaultValue={InvoiceMobileNo} />
                                    </Col>
                                    <Col lg={4} className="mt-3">
                                        <label className="form-label">Email Address</label>
                                        <Input type="text" className="form-control" id="txtEmailAddress" defaultValue={InvoiceEmailAddress} />
                                    </Col>
                                    <Col lg={3} className="mt-3">
                                        <label className="form-label">GST Number</label>
                                        <Input type="text" className="form-control" id="txtGSTNumber" placeholder="Enter GST Number" onChange={(e) => setGSTNumber(e.target.value)} />
                                    </Col>
                                    <Col lg={5} className="mt-3">
                                        <label className="form-label">Company Name</label>
                                        <Input type="text" className="form-control" id="txtCompanyName" placeholder="Enter Company Name" defaultValue={InvoiceCompanyName} onChange={(e) => setInvoiceCompanyName(e.target.value)} />
                                    </Col>
                                    <Col lg={2} className="mt-3">
                                        <label className="form-label">Bill Date</label>
                                        <Input type="date" className="form-control" id="txtBillDate" onChange={(e) => setBillDate(e.target.value)} />
                                    </Col>
                                    <Col lg={2} className="mt-3">
                                        <label className="form-label">Due Date</label>
                                        <Input type="date" className="form-control" id="txtDueDate" onChange={(e) => setDueDate(e.target.value)} />
                                    </Col>
                                    <Col lg={12} className="mt-3">
                                        <label className="form-label">Address</label>
                                        <Input type="text" className="form-control" id="txtAddress" defaultValue={InvoiceAddress} />
                                    </Col>


                                    <Col lg={12} className="mt-3">
                                        <hr />
                                        <h4 className="mt-2">Invoice Items</h4>
                                        <hr />
                                    </Col>
                                    <Col lg={12} className="mt-3">
                                        <label className="form-label">Particular</label>
                                        <Input type="text" className="form-control" placeholder="Enter Particular" id="txtParticular" maxLength={100} onChange={e => setParticular(e.target.value)} />
                                    </Col>
                                    <Col lg={3} className="mt-3">
                                        <label className="form-label">Amount</label>
                                        <Input type="text" className="form-control" id="txtAmount" placeholder="Enter Amount" onChange={e => setAmount(e.target.value)} />
                                    </Col>
                                    <Col lg={3} className="d-flex mb-3 mt-3">
                                        <label className="form-label">Is GST Apply</label>
                                        <div className="form-check isgstapplyrbyes">
                                            <input type="radio" id="rbIsGSTApplyYes" className="form-check-input" name="rbIsGSTApply" value="Yes" onChange={e => setIsGstApply(e.target.value)} />
                                            <label className="form-check-label" htmlFor="rbIsGSTApplyYes">Yes</label>
                                        </div>
                                        <div className="form-check isgstapplyrbno">
                                            <input type="radio" id="rbIsGSTApplyNo" className="form-check-input" name="rbIsGSTApply" value="No" onChange={e => setIsGstApply(e.target.value)} />
                                            <label className="form-check-label" htmlFor="rbIsGSTApplyNo">No</label>
                                        </div>
                                    </Col>
                                    {IsGstApply === 'Yes' ?
                                        <Col lg={3} className="mt-3">
                                            <label className="form-label">GST</label>
                                            <select id="ddlGST" className="form-select" aria-label="Default select example" onChange={e => setGstId(e.target.value)}>
                                                <option selected value="0">Select GST</option>
                                                {GSTList.map(g => (
                                                    <option key={g.GId} value={g.GId}>
                                                        {" "}
                                                        {g.percentage}{" "}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                        : ''}
                                    <Col lg={12} className="mt-3">
                                        <label className="form-label">Description</label>
                                        <Input type="textarea" className="form-control" placeholder="Enter Description" id="txtDescription" onChange={e => setDescription(e.target.value)} maxLength={180} />
                                    </Col>
                                    <Col lg={2} className="mt-3">
                                        <a className="btn btn_success btn-sm" id="btnAdd" onClick={InvoiceItemAddRow}>Add</a>
                                        <a className="btn btn_danger btn-sm ms-2" id="btnClear" onClick={InvoiceItemClear}>Clear</a>
                                    </Col>
                                    <Col lg={12} className="mt-3">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="text-center">#</th>
                                                    <th scope="col" className="text-center">Particular</th>
                                                    <th scope="col" className="text-center">Amount</th>
                                                    <th scope="col" className="text-center">Is GST Apply</th>
                                                    <th scope="col" className="text-center">GST Per</th>
                                                    <th scope="col" className="text-center">Description</th>
                                                    <th scope="col" className="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {InvoiceItemList.map(item => (
                                                    <tr key={item.RowNo}>
                                                        <th scope="row" className="text-center">{item.RowNo}</th>
                                                        <td className="text-center">{item.Particular}</td>
                                                        <td className="text-center">{item.Amount}</td>
                                                        <td className="text-center">{item.IsGstApply}</td>
                                                        <td className="text-center">{item.GSTPer}</td>
                                                        <td className="text-center">{item.Description}</td>
                                                        <td className="text-center">
                                                            <a className="documentdelete" onClick={e => InvoiceItemRemoveRow(e, item.RowNo)} ><i className="fas fa-trash-alt"></i></a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col lg={12} className="mt-3">
                                        <hr />
                                        <h4 className="mt-2">Payment Info</h4>
                                        <hr />
                                    </Col>
                                    <Col lg={4} className="mt-3">
                                        <p className="mb-2 fontWeight500"> Company </p>
                                        <select id="ddlCompany" className="form-select" aria-label="Default select example" onChange={e => setCompanyId(e.target.value)}>
                                            <option key="0" value="0">Select Company</option>
                                            {CompanyList.map((item) => (
                                                <option key={item.CompanyId} value={item.CompanyId}>{item.CompanyName}</option>
                                            ))}
                                        </select>
                                    </Col>
                                    <Col className="col-lg-4 col-md-4 col-12 mt-3">
                                        <p className="mb-2 fontWeight500"> Payment Mode </p>
                                        <select id="ddlPaymentMode" className="form-select" aria-label="Default select example" onChange={PaymentModeOnChange}>
                                            <option key="0" value="0">Select Payment Mode</option>
                                            {PaymentModeList.map((item) => (
                                                <option key={item.PaymentModeId} value={item.PaymentModeId}>{item.PaymenMode}</option>
                                            ))}
                                        </select>
                                    </Col>
                                    {ShowTransactionNumber > 0 ? <Col className="col-lg-4 col-md-4 col-12 mt-3">
                                        <p className="mb-2 fontWeight500"> Transaction Number</p>
                                        <Input type="text" className="" id="txtChequeDDNo" placeholder="Enter Transaction No." onChange={e => setChequeDDNo(e.target.value)} />
                                    </Col> : ''}
                                    <Col className="col-lg-4 col-md-4 col-12 mt-3">
                                        <p className="mb-2 fontWeight500"> Transaction Date</p>
                                        <Input type="date" className="" id="txtTransactionDate" placeholder="Enter Transaction Date" onChange={e => setTransactionDate(e.target.value)} />
                                    </Col>
                                    {ShowBankName > 0 ? <Col className="col-lg-4 col-md-4 col-12 mt-3">
                                        <p className="mb-2 fontWeight500"> Bank Name </p>
                                        <Input type="text" className="" id="txtBank Name" placeholder="Enter Bank Name" onChange={e => setBankName(e.target.value)} />
                                    </Col> : ''}
                                    {ShowBranch > 0 ? <Col className="col-lg-4 col-md-4 col-12 mt-3">
                                        <p className="mb-2 fontWeight500"> Branch</p>
                                        <Input type="text" className="" id="txtBranch" placeholder="Enter Branch" onChange={e => setBranch(e.target.value)} />
                                    </Col> : ''}

                                    {ShowChequeDDDate > 0 ? <Col className="col-lg-4 col-md-4 col-12 mt-3" id="divChequeDDDate">
                                        <p className="mb-2 fontWeight500"> Cheque/DD Date</p>
                                        <Input type="date" className="" id="txtChequeDDDate" placeholder="Enter Cheque/DD Date" onChange={e => setChequeDDDate(e.target.value)} />
                                    </Col> : ''}

                                    {ShowUTR > 0 ? <Col className="col-lg-4 col-md-4 col-12 mt-3">
                                        <p className="mb-2 fontWeight500"> UTR</p>
                                        <Input type="text" className="" id="txtUTR" placeholder="Enter UTR" onChange={e => setUTR(e.target.value)} />
                                    </Col> : ''}
                                    {ShowSettlement > 0 ? <Col className="col-lg-4 col-md-4 col-12 mt-3">
                                        <p className="mb-2 fontWeight500"> Settlement</p>
                                        <Input type="text" className="" id="txtSettlement" placeholder="Enter Settlement" onChange={e => setSettlement(e.target.value)} />
                                    </Col> : ''}
                                    <Col lg={12} className="mt-3">
                                        <a className="btn btn_success btn-sm" id="btnsave" onClick={SaveInvoice} >Save</a>
                                        <a className="btn btn_danger btn-sm ms-2" onClick={InvoiceClear} >Clear</a>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default AddInvoice;