import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import img1 from "assets/images/users/avatar-1.jpg";
//Import Breadcrumb
import { Col, Row, CardTitle, Card, CardBody } from "reactstrap";
import bank from "assets/images/bank-nm.png";
// Rating Plugin

class UiRating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipContent: ["Rate 1", "Rate 2", "Rate 3", "Rate 4", "Rate 5"],
      tooltipContentMore: ["1", "2", "3", "4", "5", "6", "7", "8"],
      tooltipContentHalf: ["6", "7", "8", "9", "10"],
      tooltipContentMiddle: [
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      tooltipContentStep: ["2", "4", "6", "8", "10"],
      default: "",
      half: "",
      customize: "",
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Client Information | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          <div
              className="modal fade"
              id="channel"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Channel List
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <Row>
                      <div className="col-lg-12">
                     <a href="">
                     <div className="team-modal-box">
                     <span className="float-end"><input type="checkbox" className="selection-input-4"/></span>
                          <div className="d-flex align-items-center border-bottom pb-2">
                            <div className="flex-shrink-0">
                              <div className="avatar-md">
                                <img
                                  src="/static/media/avatar-1.3921191a.jpg"
                                  alt=""
                                  className="img-thumbnail"
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="font-14 dark-font mb-1">
                                Yogita Ramani
                              </div>
                              <div className="mb-1">
                                <a href="" className="table-link-a font-12">
                                  Account Manager
                                </a>
                              </div>
                              <div className="">
                                <a href="" className="table-link-a font-12">
                                  State Bank Of India
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="modal-sub-box pt-2">
                            <div className="department-box">
                              <a className="mb-0 text-success">
                                <i className="fas fa-phone-alt me-1"></i>+91
                                4589236941
                              </a>
                            </div>
                            <div className="emp-code">
                              <a  className="mb-0 text-primary">
                                <i className="fas fa-envelope me-1"></i>
                                Ella91@gmail.com
                              </a>
                            </div>
                          </div>
                        </div>
                     </a>
                      <a href="">
                      <div className="team-modal-box mt-3">
                      <span className="float-end"><input type="checkbox" className="selection-input-4"/></span>
                          <div className="d-flex align-items-center border-bottom pb-2">
                            <div className="flex-shrink-0">
                              <div className="avatar-md">
                                <img
                                  src="/static/media/avatar-1.3921191a.jpg"
                                  alt=""
                                  className="img-thumbnail"
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="font-14 dark-font mb-1">
                                Yogita Ramani
                              </div>
                              <div className="mb-1">
                                <a href="" className="table-link-a font-12">
                                  Account Manager
                                </a>
                              </div>
                              <div className="">
                                <a href="" className="table-link-a font-12">
                                  State Bank Of India
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="modal-sub-box pt-2">
                            <div className="department-box">
                              <a className="mb-0 text-success">
                                <i className="fas fa-phone-alt me-1"></i>+91
                                4589236941
                              </a>
                            </div>
                            <div className="emp-code">
                              <a className="mb-0 text-primary">
                                <i className="fas fa-envelope me-1"></i>
                                Ella91@gmail.com
                              </a>
                            </div>
                          </div>
                        </div>
                      </a>
                      </div>
                    </Row>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn_success">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn_danger"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div> 
            <Row className="mb-5">
              <Col className="col-lg-8 col-md-6 col-6">
                <div className="breadcrum ps-0">
                  <CardTitle>Client Information</CardTitle>
                  <a href="#" className="me-2">
                    Lead List
                  </a>{" "}
                  <span>/</span>
                  <span className="ms-2">Client Information</span>
                </div>
              </Col>
              <Col className="col-lg-4 co-md-6 col-6">
                <a
                  className="btn btn-primary btn-sm float-end font-14"
                  href="#"
                >
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
                <a
                  className="btn btn_success btn-sm float-end font-14 me-2"
                  href="#"
                >
                  Save
                </a>
              </Col>
            </Row>

            <Row>
              <Col className="col-lg-12 col-12">
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col className="col-md-10 col-12">
                        <Row>
                          <Col className="col-12">
                            <h5 className="fw-bolder mb-2">
                              {" "}
                              Sheron Anilbhai Christian{" "}
                            </h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-lg-3">
                            <div className="border-box p-2 mb-2 ">
                              <p className="mb-2 fontWeight500">
                                {" "}
                                Mobile Number{" "}
                              </p>

                              <input
                                type="text"
                                placeholder="+91 6989523569"
                                className="form-control border-0 p-0 ms-0"
                                id="exampleInputPassword1"
                              />
                            </div>
                          </Col>
                          <Col className="col-lg-3">
                            <div className=" border-box p-2  mb-2 ">
                              <p className="mb-2 fontWeight500">
                                {" "}
                                Email Address{" "}
                              </p>
                              <input
                                type="text"
                                placeholder="abc@gmail.com"
                                className="form-control border-0 p-0 ms-0"
                                id="exampleInputPassword1"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="col-md-2 col-lg-2 col-12 float-end">
                        <div className="">
                          <img
                            className="rounded-circle float-end clientactimg"
                            src={img1}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col className="col-lg-12 col-md-12 col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <CardTitle>Personal Details</CardTitle>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> Gender </p>
                          <input
                            type="text"
                            placeholder="Male"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> CIR ID </p>
                          <input
                            type="text"
                            placeholder="CIR ID"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> CIR Password </p>
                          <input
                            type="text"
                            placeholder="CIR Password"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> State </p>
                          <input
                            type="text"
                            placeholder="Gujarat"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> District </p>
                          <input
                            type="text"
                            placeholder="Ahemdabad"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> City </p>
                          <input
                            type="text"
                            placeholder="Ahemdabad"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> Tehsil </p>
                          <input
                            type="text"
                            placeholder="Ahemdabad"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> PinCode </p>
                          <input
                            type="text"
                            placeholder="361006"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> Mobile Number </p>
                          <input
                            type="text"
                            placeholder="+91 8969365623"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> Email Address </p>
                          <input
                            type="text"
                            placeholder="abc@gmail.com"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <CardTitle>Channel Information</CardTitle>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> Channel Type </p>
                          <p className="mb-0 fontWeight500">
                            {" "}
                            <input
                            type="text"
                            placeholder="Banking Network"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />{" "}
                          </p>
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> Channel Name </p>
                          <p className="mb-0 fontWeight500">
                            {" "}
                            <input
                            type="text"
                            placeholder="State Bank Of India"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />{" "}
                          </p>
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> Branch Name </p>
                          <input
                            type="text"
                            placeholder="Mohan Colony"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> Branch Code </p>
                          <input
                            type="text"
                            placeholder="327001"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> IFSC Code </p>
                          <input
                            type="text"
                            placeholder="SBIN0004"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500">
                            {" "}
                            Branch Location{" "}
                          </p>
                          <p className="mb-0 fontWeight500">
                            {" "}
                            <input
                            type="text"
                            placeholder=" Mohan Colony, Banswara, Rajasthan"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                           {" "}
                          </p>
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> City </p>
                          <input
                            type="text"
                            placeholder="Banswara"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> Tehsil </p>
                          <p className="mb-0 fontWeight500">
                            {" "}
                            <input
                            type="text"
                            placeholder="Banswara Muncipality"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                            {" "}
                          </p>
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> District </p>
                          <input
                            type="text"
                            placeholder="Banswara"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> State </p>
                          <input
                            type="text"
                            placeholder="Rajasthan"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> Pincode </p>
                          <input
                            type="text"
                            placeholder="327001"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <CardTitle>Channel Partner Details</CardTitle>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> Name </p>
                          <input
                            type="text"
                            placeholder="Abab Babel"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500"> Mobile Number </p>
                          <input
                            type="text"
                            placeholder="8239785985"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                         
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500">
                            {" "}
                            Alternet Mobile Number{" "}
                          </p>
                          <input
                            type="text"
                            placeholder="(993) 370-8283"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                          
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500">Email Address </p>
                          <p className="mb-0 fontWeight500">
                            {" "}
                            <input
                            type="text"
                            placeholder="sbi.06741@sbi.co.in"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                           {" "}
                          </p>
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500">
                            {" "}
                            Alternet Email Address{" "}
                          </p>
                          <p className="mb-0 fontWeight500">
                            {" "}
                            <input
                            type="text"
                            placeholder="Kolby_Toy@yahoo.com"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                            {" "}
                          </p>
                        </div>
                      </Col>
                      <Col className="col-lg-3 mb-2">
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-2 fontWeight500">
                            {" "}
                            Landline Number{" "}
                          </p>
                          <input
                            type="text"
                            placeholder="0294-2464611"
                            className="form-control border-0 p-0 ms-0"
                            id="exampleInputPassword1"
                          />
                        </div>
                      </Col>
                    </Row>
                    <hr />

                    <Row>
                      <div className="col-lg-6">
                        <CardTitle>Banking Source</CardTitle>
                        <div className="team-modal-box">
                          <div className="float-end">
                            <p className="mb-0 text-success">ID : #000000056</p>
                            <p className="mb-0">Type : lorem</p>
                          </div>
                          <div className="d-flex align-items-center border-bottom pb-2">
                            <div className="flex-shrink-0">
                              <div className="avatar-md">
                                <img
                                  src={bank}
                                  alt=""
                                  className="img-thumbnail"
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="font-14 dark-font mb-1">
                                State Bank Of India
                              </div>
                              <div className="mb-1">
                                <a href="" className="table-link-a font-12">
                                  Branch : Mohan Colony
                                </a>
                              </div>
                              <div className="">
                                <a href="" className="table-link-a font-12">
                                  IFSE Code : SBIN0056
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="pt-2">
                            <span className="badge badge-soft-primary p-2 me-2">
                              Banswara
                            </span>
                            <span className="badge badge-soft-primary p-2 me-2">
                              Rajasthan
                            </span>
                            <span className="badge badge-soft-primary p-2 me-2">
                              327001
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <CardTitle>
                          Channel Partner
                          <span className="float-end text-primary font-12">
                            <a href="#" data-bs-toggle="modal"
                            data-bs-target="#channel">+ Add</a>
                          </span>
                        </CardTitle>
                        <div className="team-modal-box">
                          <a href="">
                            <i className="fas fa-window-close text-danger float-end"></i>
                          </a>
                          <div className="d-flex align-items-center border-bottom pb-2">
                            <div className="flex-shrink-0">
                              <div className="avatar-md">
                                <img
                                  src="/static/media/avatar-1.3921191a.jpg"
                                  alt=""
                                  className="img-thumbnail"
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="font-14 dark-font mb-1">
                                Yogita Ramani
                              </div>
                              <div className="mb-1">
                                <a href="" className="table-link-a font-12">
                                  Account Manager
                                </a>
                              </div>
                              <div className="">
                                <a href="" className="table-link-a font-12">
                                  State Bank Of India
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="modal-sub-box pt-2">
                            <div className="department-box">
                              <a className="mb-0 text-success">
                                <i className="fas fa-phone-alt me-1"></i>+91
                                4589236941
                              </a>
                            </div>
                            <div className="emp-code">
                              <a className="mb-0 text-primary">
                                <i className="fas fa-envelope me-1"></i>
                                Ella91@gmail.com
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <hr />
                    <Row>
                      <Col className="col-lg-6 mb-2">
                        <CardTitle>Remarks</CardTitle>
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-0 fontWeight500">
                            {" "}
                            Lorem Ipsum has been the industrys standard dummy
                            text ever since the 1500s, when an unknown printer
                            took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five
                            centuries{" "}
                          </p>
                        </div>
                      </Col>

                      <Col className="col-lg-6 mb-2">
                        <CardTitle>Lead Briefing</CardTitle>
                        <div className="border-box p-2 mb-2 ">
                          <p className="mb-0 fontWeight500">
                            {" "}
                            Lorem Ipsum has been the industrys standard dummy
                            text ever since the 1500s, when an unknown printer
                            took a galley of type and scrambled it to make a
                            type specimen book. It has survived not only five
                            centuries{" "}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col className="col-lg-12 col-md-12 col-12">
                <Card>
                  <CardBody>
                    <ul className="nav tab_bottom_main" role="tablist">
                      <li className="nav-item">
                        <a
                          className="tab_bottom table-link-a active"
                          data-bs-toggle="tab"
                          href="#home"
                        >
                          Service Information
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="tab_bottom  table-link-a"
                          data-bs-toggle="tab"
                          href="#menu1"
                        >
                          Fees Information
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content">
                      <div id="home" className=" tab-pane active">
                        <br />
                        <div
                          className="table-responsive text-center"
                          id="style-4"
                        >
                          <table className="table table-sm table-bordered  ">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Services</th>
                                <th>Total Accrued Amount</th>
                                <th>Total Paid</th>
                                <th>Total Amount to Pay</th>
                                <th>Activities</th>
                                <th>Documents</th>
                                <th>Status</th>
                                <th>Service Progress</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>1</th>
                                <td>Service One</td>
                                <td>12000</td>
                                <td>12000</td>
                                <th>0</th>
                                <td>
                                  {" "}
                                  <a
                                    href="/ClientActivities"
                                    className="btn btn_success btn-sm edit_activity_btn text-light"
                                  >
                                    <i className="fas fa-pencil-alt me-2"></i>
                                    Manage
                                  </a>{" "}
                                </td>
                                <td>
                                  {" "}
                                  <div className="btn btn_success btn-sm edit_activity_btn text-light">
                                    <i className="far fa-eye me-2"></i>View
                                  </div>{" "}
                                </td>
                                <td>Paid</td>
                                <td>
                                  <div
                                    className="progress"
                                    style={{ height: "20px" }}
                                  >
                                    <div
                                      className="progress-bar"
                                      style={{ width: "50%" }}
                                      aria-valuenow="50"
                                    >
                                      50%
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <th>2</th>
                                <td>Service One</td>
                                <td>12000</td>
                                <td>12000</td>
                                <th>0</th>
                                <td>
                                  {" "}
                                  <a
                                    href="/ClientActivities"
                                    className="btn btn_success btn-sm edit_activity_btn text-light"
                                  >
                                    <i className="fas fa-pencil-alt me-2"></i>
                                    Manage
                                  </a>{" "}
                                </td>
                                <td>
                                  {" "}
                                  <div className="btn btn_success btn-sm edit_activity_btn text-light">
                                    <i className="far fa-eye me-2"></i>View
                                  </div>{" "}
                                </td>
                                <td>Paid</td>
                                <td>
                                  <div
                                    className="progress"
                                    style={{ height: "20px" }}
                                  >
                                    <div
                                      className="progress-bar"
                                      style={{ width: "50%" }}
                                      aria-valuenow="50"
                                    >
                                      50%
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <th>3</th>
                                <td>Service One</td>
                                <td>12000</td>
                                <td>12000</td>
                                <th>0</th>
                                <td>
                                  {" "}
                                  <a
                                    href="/ClientActivities"
                                    className="btn btn_success btn-sm edit_activity_btn text-light"
                                  >
                                    <i className="fas fa-pencil-alt me-2"></i>
                                    Manage
                                  </a>{" "}
                                </td>
                                <td>
                                  {" "}
                                  <div className="btn btn_success btn-sm edit_activity_btn text-light">
                                    <i className="far fa-eye me-2"></i>View
                                  </div>{" "}
                                </td>
                                <td>Paid</td>
                                <td>
                                  <div
                                    className="progress"
                                    style={{ height: "20px" }}
                                  >
                                    <div
                                      className="progress-bar"
                                      style={{ width: "50%" }}
                                      aria-valuenow="50"
                                    >
                                      50%
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <th>4</th>
                                <td>Service One</td>
                                <td>12000</td>
                                <td>12000</td>
                                <th>0</th>
                                <td>
                                  {" "}
                                  <a
                                    href="/ClientActivities"
                                    className="btn btn_success btn-sm edit_activity_btn text-light"
                                  >
                                    <i className="fas fa-pencil-alt me-2"></i>
                                    Manage
                                  </a>{" "}
                                </td>
                                <td>
                                  {" "}
                                  <div className="btn btn_success btn-sm edit_activity_btn text-light">
                                    <i className="far fa-eye me-2"></i>View
                                  </div>{" "}
                                </td>
                                <td>Paid</td>
                                <td>
                                  <div
                                    className="progress"
                                    style={{ height: "20px" }}
                                  >
                                    <div
                                      className="progress-bar"
                                      style={{ width: "50%" }}
                                      aria-valuenow="50"
                                    >
                                      50%
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <th>5</th>
                                <td>Service One</td>
                                <td>12000</td>
                                <td>12000</td>
                                <th>0</th>
                                <td>
                                  {" "}
                                  <a
                                    href="/ClientActivities"
                                    className="btn btn_success btn-sm edit_activity_btn text-light"
                                  >
                                    <i className="fas fa-pencil-alt me-2"></i>
                                    Manage
                                  </a>{" "}
                                </td>
                                <td>
                                  {" "}
                                  <div className="btn btn_success btn-sm edit_activity_btn text-light">
                                    <i className="far fa-eye me-2"></i>View
                                  </div>{" "}
                                </td>
                                <td>Paid</td>
                                <td>
                                  <div
                                    className="progress"
                                    style={{ height: "20px" }}
                                  >
                                    <div
                                      className="progress-bar"
                                      style={{ width: "50%" }}
                                      aria-valuenow="50"
                                    >
                                      50%
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <th>6</th>
                                <td>Service One</td>
                                <td>12000</td>
                                <td>12000</td>
                                <th>0</th>
                                <td>
                                  {" "}
                                  <a
                                    href="/ClientActivities"
                                    className="btn btn_success btn-sm edit_activity_btn text-light"
                                  >
                                    <i className="fas fa-pencil-alt me-2"></i>
                                    Manage
                                  </a>{" "}
                                </td>
                                <td>
                                  {" "}
                                  <div className="btn btn_success btn-sm edit_activity_btn text-light">
                                    <i className="far fa-eye me-2"></i>View
                                  </div>{" "}
                                </td>
                                <td>Paid</td>
                                <td>
                                  <div
                                    className="progress"
                                    style={{ height: "20px" }}
                                  >
                                    <div
                                      className="progress-bar"
                                      style={{ width: "50%" }}
                                      aria-valuenow="50"
                                    >
                                      50%
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <th>7</th>
                                <td>Service One</td>
                                <td>12000</td>
                                <td>12000</td>
                                <th>0</th>
                                <td>
                                  {" "}
                                  <a
                                    href="/ClientActivities"
                                    className="btn btn_success btn-sm edit_activity_btn text-light"
                                  >
                                    <i className="fas fa-pencil-alt me-2"></i>
                                    Manage
                                  </a>{" "}
                                </td>
                                <td>
                                  {" "}
                                  <div className="btn btn_success btn-sm edit_activity_btn text-light">
                                    <i className="far fa-eye me-2"></i>View
                                  </div>{" "}
                                </td>
                                <td>Paid</td>
                                <td>
                                  <div
                                    className="progress"
                                    style={{ height: "20px" }}
                                  >
                                    <div
                                      className="progress-bar"
                                      style={{ width: "50%" }}
                                      aria-valuenow="50"
                                    >
                                      50%
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <th>8</th>
                                <td>Service One</td>
                                <td>12000</td>
                                <td>12000</td>
                                <th>0</th>
                                <td>
                                  {" "}
                                  <a
                                    href="/ClientActivities"
                                    className="btn btn_success btn-sm edit_activity_btn text-light"
                                  >
                                    <i className="fas fa-pencil-alt me-2"></i>
                                    Manage
                                  </a>{" "}
                                </td>
                                <td>
                                  {" "}
                                  <div className="btn btn_success btn-sm edit_activity_btn text-light">
                                    <i className="far fa-eye me-2"></i>View
                                  </div>{" "}
                                </td>
                                <td>Paid</td>
                                <td>
                                  <div
                                    className="progress"
                                    style={{ height: "20px" }}
                                  >
                                    <div
                                      className="progress-bar"
                                      style={{ width: "50%" }}
                                      aria-valuenow="50"
                                    >
                                      50%
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <th>9</th>
                                <td>Service One</td>
                                <td>12000</td>
                                <td>12000</td>
                                <th>0</th>
                                <td>
                                  {" "}
                                  <a
                                    href="/ClientActivities"
                                    className="btn btn_success btn-sm edit_activity_btn text-light"
                                  >
                                    <i className="fas fa-pencil-alt me-2"></i>
                                    Manage
                                  </a>{" "}
                                </td>
                                <td>
                                  {" "}
                                  <div className="btn btn_success btn-sm edit_activity_btn text-light">
                                    <i className="far fa-eye me-2"></i>View
                                  </div>{" "}
                                </td>
                                <td>Paid</td>
                                <td>
                                  <div
                                    className="progress"
                                    style={{ height: "20px" }}
                                  >
                                    <div
                                      className="progress-bar"
                                      style={{ width: "50%" }}
                                      aria-valuenow="50"
                                    >
                                      50%
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <th>10</th>
                                <td>Service One</td>
                                <td>12000</td>
                                <td>12000</td>
                                <th>0</th>
                                <td>
                                  {" "}
                                  <a
                                    href="/ClientActivities"
                                    className="btn btn_success btn-sm edit_activity_btn text-light"
                                  >
                                    <i className="fas fa-pencil-alt me-2"></i>
                                    Manage
                                  </a>{" "}
                                </td>
                                <td>
                                  {" "}
                                  <div className="btn btn_success btn-sm edit_activity_btn text-light">
                                    <i className="far fa-eye me-2"></i>View
                                  </div>{" "}
                                </td>
                                <td>Paid</td>
                                <td>
                                  <div
                                    className="progress"
                                    style={{ height: "20px" }}
                                  >
                                    <div
                                      className="progress-bar"
                                      style={{ width: "50%" }}
                                      aria-valuenow="50"
                                    >
                                      50%
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div id="menu1" className=" tab-pane fade">
                        <br />

                        <div className="table_height_scroll h100px" id="">
                          <table className="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Services</th>
                                <th>Total Fees</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>1</th>
                                <td>Service One</td>
                                <td>12000</td>
                                <td>Paid</td>
                              </tr>
                              <tr>
                                <th>2</th>
                                <td>Service Two</td>
                                <td>12000</td>
                                <td>Due</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UiRating;
