import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios'
import { orderBy } from 'lodash';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class OnSort extends Component {  
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "SR",
                text: "#",
                sortable: true
            },
            {
                key: "INTime",
                text: "In Time",
                sortable: true
            },
            {
                key: "OUTTime",
                text: "Out Time",
                sortable: true
            },
            {
              
              key: "TotalTime",
              text: "WorkingHours",
              sortable: true
            },
            {
                d: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <a className="me-3 text-primary" href="/ShiftMaster/EditShiftMaster" onClick={this.editRecord.bind(this, record)}><i className="mdi mdi-pencil font-size-18"></i></a>
                            <a className={this.IconrenderSwitchColor(record)} onClick={this.deleteRecord.bind(this, record)}><i className={this.IconrenderSwitch(record)}></i></a>
                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true
        }
        this.state = {
          records: []
        }
    }

    IconrenderSwitch(param) {
      let UTFlag=param["Flag"];
      switch(UTFlag) {
        case 'A':
          return 'mdi mdi-close font-size-18';
        default:
          return 'mdi mdi-check-all font-size-18';
      }
    }

    IconrenderSwitchColor(param) {
      let UTFlag=param["Flag"];
      switch(UTFlag) {
        case 'A':
          return 'text-danger';
        default:
          return 'text-success';
      }
    }
    
    componentDidMount() {
      fetch(global.APIURL+"/Shift")
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          records: json
        });
      })
    }

    editRecord = (record) => {
      let UTId=record["SId"]; 
      cookies.set('SId', UTId, { path: '/' });
    }

    deleteRecord = (record) => {     
        let ETId=record["SId"];
        let ETFlag=record["Flag"];
        let de = ETFlag=="A"?"D":ETFlag=="D"?"A":ETFlag;
        const ProfessionalTaxflag = {
          SId:ETId,
          Flag:de   
        }
        let AlertMessgae =ETFlag=="A"?"Are you sure you want to inactive this record?":"Are you sure you want to active this record?";

        if (confirm(AlertMessgae) == true) {
          axios.delete(global.APIURL+'/Shift',{ data: ProfessionalTaxflag })  
            .then(res => { 
              if(ETFlag=="A")
              {
                alert("Record Inactive Successfully");
                fetch(global.APIURL+"/Shift")
                .then((res) => res.json())
                .then((json) => {
                  this.setState({
                    records: json
                  });
                })
              }
              else if(ETFlag=="D")
              {
                alert("Record Active Successfully");
                fetch(global.APIURL+"/Shift")
                .then((res) => res.json())
                .then((json) => {
                  this.setState({
                    records: json
                  });
                })
              }
           })
        }
    }
   
    onSort = (column, records, sortOrder) => {
        return orderBy(records, [column], [sortOrder]);
    }

    render() {
      return (
        <div className="page-content">
          <MetaTags>
            <title>Shift | KC Admin Group</title>
          </MetaTags>
       
          <div className="container-fluid">
            <Row className="mb-3">
              <Col className="col-lg-8 col-md-6 col-12 mb-2">
                <div className="breadcrum ps-0">
                <CardTitle className="h4">shift</CardTitle>
                   <a href="/GeneralDashboard" className="me-2">
                    Home
                  </a>{" "}
                  <span>/</span>
                  <span className="ms-2">Shift</span>
                </div>
              </Col>
              <Col className="col-lg-4 co-md-6 col-12">
                <a className="btn btn-primary btn-sm float-end font-14" href="/GeneralDashboard">
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
                <a className="btn btn-primary btn-sm float-end font-14 me-2" href="/ShiftMaster/AddShiftMaster">
                  <i className="mdi mdi-plus align-middle me-1"></i>Add New
                </a>
              </Col>
            </Row>
            <form onSubmit={this.doSomething}>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="12" className="master-table">
                        <ReactDatatable config={this.config} records={this.state.records} columns={this.columns} onSort={this.onSort}/>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </form>
          </div>
        </div>
      );
    }
}

export default OnSort;