import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
} from "reactstrap"


//Import Breadcrumb


class UiTabsAccordions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col5: true,
      col6: true,
      col7: true,
      col8: true,
      col9: true,
      col10: false,
      col11: false,
    }
    this.toggle = this.toggle.bind(this)
    this.toggle1 = this.toggle1.bind(this)

    this.t_col1 = this.t_col1.bind(this)
    this.t_col2 = this.t_col2.bind(this)
    this.t_col3 = this.t_col3.bind(this)
    this.t_col5 = this.t_col5.bind(this)
    this.t_col6 = this.t_col6.bind(this)
    this.t_col7 = this.t_col7.bind(this)
    this.t_col8 = this.t_col8.bind(this)
    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)

    this.toggle2 = this.toggle2.bind(this)
    this.toggle3 = this.toggle3.bind(this)

    this.toggleVertical = this.toggleVertical.bind(this)
    this.toggleVerticalIcon = this.toggleVerticalIcon.bind(this)
    this.toggleCustom = this.toggleCustom.bind(this)
    this.toggleIconCustom = this.toggleIconCustom.bind(this)
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false
    })
  }

  t_col2() {
    this.setState({
      col1: false,
      col2: !this.state.col2,
      col3: false
    })
  }

  t_col3() {
    this.setState({
      col1: false,
      col2: false,
      col3: !this.state.col3
    })
  }

  t_col5() {
    this.setState({ col5: !this.state.col5 })
  }

  t_col6() {
    this.setState({ col6: !this.state.col6 })
  }

  t_col7() {
    this.setState({ col7: !this.state.col7 })
  }

  t_col8() {
    this.setState({
      col6: !this.state.col6,
      col7: !this.state.col7
    })
  }

  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false
    })
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false
    })
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      })
    }
  }

  toggle2(tab) {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      })
    }
  }

  toggle3(tab) {
    if (this.state.activeTab3 !== tab) {
      this.setState({
        activeTab3: tab,
      })
    }
  }

  toggleVertical(tab) {
    if (this.state.verticalActiveTab !== tab) {
      this.setState({
        verticalActiveTab: tab,
      })
    }
  }

  toggleVerticalIcon(tab) {
    if (this.state.verticalActiveTabWithIcon !== tab) {
      this.setState({
        verticalActiveTabWithIcon: tab,
      })
    }
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      })
    }
  }

  toggleIconCustom(tab) {
    if (this.state.customIconActiveTab !== tab) {
      this.setState({
        customIconActiveTab: tab,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Job Edit| KC Admin Group</title>
          </MetaTags>
          <Container fluid={true}>
          <Row className="mb-2">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h3"> Edit Job </CardTitle>
                      <a href="#" className="me-2">Home</a> <span>/</span><span className="ms-2">Edit Job</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
          <Card>
              <CardBody>
                
                <Row className="">
                 
                  <Col lg={12} className="mb-3">
                    
                          <div>
                          <label className="form-label">Job Title / Position</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="First Name"
                            />
                          </div>
                  </Col>

                  <Col lg={12} className="mb-3">
                    
                          <div>
                          <label className="form-label">Short Description</label>
                          <textarea className="form-control" placeholder="Short Description" ></textarea>
                          </div>
                </Col>

                <Col lg={12} className="mb-3">
                    
                     
                    <label className="form-label d-block">Walk-In Allowed</label>
                    
                    <div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
  <label className="form-check-label"  >Yes </label>
</div>
<div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
  <label className="form-check-label"  >No</label>
</div>
 
                     
              </Col>

              <Col lg={12} className="mb-3">
                    
                    <div>
                    <label className="form-label">Description</label>
                    <textarea className="form-control" style={{height: "200px"}} placeholder="Description" id="floatingTextarea" ></textarea>
                    </div>
             </Col>                                                 
                </Row>

                 <Row>
                <Col lg={6} className="mb-3">
                    
                    <div>
                    <label className="form-label">Job Post Date</label>
                    <Input
                              type="date"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="First Name"
                            />
                    </div>
                  </Col>     
                  <Col lg={6} className="mb-3">
                    
                    <div>
                    <label className="form-label"> &nbsp;</label>
                    <Input
                              type="date"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="First Name"
                            />
                    </div>
             </Col>          
                </Row>
              </CardBody>
            </Card>

            <Card><CardBody>
              
              <Row>
                  <Col lg={12} className="mb-3">
                    <h4> Attachment : </h4>
                  </Col>
            </Row>

            <Row>
                  <Col lg={4} className="mb-3">
                  <div className="form-group">
                                    <label  >Attachment File</label>
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input form-control" id="customFile" value="" />
                                       
                                         
                                       
                                    </div>
                                </div>
                  </Col>
                  <Col lg={4} className="mb-3">
                    <div className="d-flex gap-3">
                   <div className="flex-grow-1">
                                <label  >Notes</label>
                                <input type="text" placeholder="Notes"   name="group-c[0][attached_notes]" value="developer resume"  className="form-control" />
                                <div className="invalid-feedback">
                                    Please provide a Notes.
                                </div>
                    </div>
                      <div className="align-self-end">
                      <label  >&nbsp;</label>
                      <button type="button" data-repeater-delete="" data-toggle="tooltip" data-placement="top" title="" className="btn btn-sm btn_danger " data-original-title="Delete">
                                    <i className="bx bx-trash d-block font-size-16"></i>
                      </button>
                        </div>
                    </div>
                  </Col>
            </Row>
              <Row>
                <Col>
                <a className="btn btn_success btn-sm font-14 " href="#"><i className="mdi mdi-plus align-middle me-1"></i>Add New</a>
                </Col>

            </Row>
            
            
         </CardBody></Card>

         <Card><CardBody>
              
              <Row>
              <Col lg={12} className="mb-3">
                    
                     
                    <label className="form-label d-block">Ask for</label>
                    
                    <div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
  <label className="form-check-label"  >Resume / CV</label>
</div>
<div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
  <label className="form-check-label"  >Resume / CV + Cover Letter</label>
</div>
 
                     
              </Col>
            </Row>


              <Row>
                <Col className="col-lg-12 mb-3">
                <label className="form-label">Minimum Experience</label>
                <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Minimum Experience"
                            />
                </Col>
              </Row>
              
              <Row>
                <Col className="col-lg-12 mb-3">
                <label className="form-label">Pay Range</label>
                <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Pay Range"
                            />
                </Col>
            </Row>
            
            
         </CardBody></Card>

            
         <Card><CardBody>
              
              <Row>
              <Col lg={12} className="mb-3">
                    
              <div className="form-group">
              <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  <label className="form-check-label">
                    Default checkbox
                  </label>
                </div>
                </div>
                     
              </Col>
            </Row>


              <Row>
                <Col className="col-lg-2  col-md-12 col-12 mb-3">
                    <a href="#" className="btn btn_success me-2"> Save </a>
               
                    <a href="#" className="btn btn_danger"> Cancel </a>
                </Col>
                
                <Col className="col-lg-4 col-md-12 col-12 mb-3">
                  <div className="border border-2 p-3 rounded-3">
                    <p className="mb-1 fw-bold">Created by : NewsFirst Admin</p>
                    <p className="mb-1 fw-bold">Created date time : 21 January, 2022 01:26 PM</p>
                    <p className="mb-1 fw-bold">Modified by : NewsFirst Admin</p>
                    <p className="mb-1 fw-bold">Modified date time : 21 January, 2022 01:26 PM</p>
                    <p className="mb-1 fw-bold">Total Time Taken : 00:00:03</p>
                   </div>
                </Col>
              </Row>
              
              
            
            
         </CardBody></Card>






          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default UiTabsAccordions
