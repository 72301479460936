import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Label,
  Input,
} from "reactstrap";
import MetaTags from "react-meta-tags";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../../../assets/scss/datatables.scss"

// Table data
const products = [
  {
    Sr: 1,
    Bankname: "India",
    DeductionType: "Advance",
    StartMonthYear: "01/20",
    EndMonthYear: "06/20",
    NoofInstallments: "06",
    InstallmentAmount: "1000",
    TotalDeduction: "6000",
    action: (
      <>
        <i className="mdi mdi-pencil h5 me-3 text-success font-size-18 "></i>
        <i className="mdi mdi-delete h5 me-3 text-danger font-size-18"></i>
      </>
    ),
  },
  {
    Sr: 2,
    Bankname: "India",
    DeductionType: "Advance",
    StartMonthYear: "01/20",
    EndMonthYear: "06/20",
    NoofInstallments: "06",
    InstallmentAmount: "1000",
    TotalDeduction: "6000",
    action: (
      <>
        <i className="mdi mdi-pencil h5 me-3 text-success font-size-18 "></i>
        <i className="mdi mdi-delete h5 me-3 text-danger font-size-18"></i>
      </>
    ),
  },
  {
    Sr: 3,
    Bankname: "India",
    DeductionType: "Advance",
    StartMonthYear: "01/20",
    EndMonthYear: "06/20",
    NoofInstallments: "06",
    InstallmentAmount: "1000",
    TotalDeduction: "6000",
    action: (
      <>
        <i className="mdi mdi-pencil h5 me-3 text-success font-size-18 "></i>
        <i className="mdi mdi-delete h5 me-3 text-danger font-size-18"></i>
      </>
    ),
  },
  

];

class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products,
    };
  }

  render() {
    const columns = [
      {
        dataField: "Sr",

        text: "Sr",
        sort: true,
      },
      {
        dataField: "DeductionType",

        text: "Deduction Type",
        sort: true,
      },
      {
        dataField: "StartMonthYear",

        text: "Start Month/Year",
        sort: true,
      },
      {
        dataField: "EndMonthYear",

        text: "End Month/Year",
        sort: true,
      },
      {
        dataField: "NoofInstallments",

        text: "No of Installments",
        sort: true,
      },
      {
        dataField: "InstallmentAmount",

        text: "Installment Amount",
        sort: true,
      },
      {
        dataField: "TotalDeduction",

        text: "Total Deduction",
        sort: true,
      },
      {
        dataField: "action",

        text: "Action",
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    };

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "25", value: 25 },
      { text: "All", value: this.state.productData.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;

    return (
      <React.Fragment>



      
        <div className="page-content">
          <MetaTags>
          <title>Manage Salary Slip | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
<Col className="col-lg-8 col-md-6 col-6">
  <div className="breadcrum ps-0">
      <CardTitle className="h6 mb-2">Manage Salary Slip</CardTitle>
      <a href="#" className="me-2">Home</a> <span>/</span><span className="ms-2">Manage Salary Slip</span>
  </div>
</Col>
  <Col className="col-lg-4 co-md-6 col-6">
    <a className ="btn btn-primary btn-sm float-end font-14" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
  </Col>
</Row> 

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>


                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col className="col-lg-4 col-md-4 col-6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-lg-8 col-md-8 col-6">
                                  <div className="float-end">
                                   
                                  </div>
                                </Col>
                              </Row>
                              <CardTitle className="h6 mb-4">Pay Slip</CardTitle>
                     <Row className="">
                       <Col lg={2}>
                          <div className="pay-box p-3">
                              <p className="mb-2">KC0001 | 01 | 20 </p>
                              <p className="mb-2">Jan - 2020</p>
                              <p className="mb-0">Net Salary 15,500</p>
                          </div>
                          <div className="text-end">
                            <p><a href="#">Share | Download</a></p>
                          </div>
                       </Col>
                       <Col lg={2}>
                          <div className="pay-box p-3">
                              <p className="mb-2">KC0001 | 01 | 20 </p>
                              <p className="mb-2">Jan - 2020</p>
                              <p className="mb-0">Net Salary 15,500</p>
                          </div>
                          <div className="text-end">
                            <p><a href="#">Share | Download</a></p>
                          </div>
                       </Col>
                       <Col lg={2}>
                          <div className="pay-box p-3">
                              <p className="mb-2">KC0001 | 01 | 20 </p>
                              <p className="mb-2">Jan - 2020</p>
                              <p className="mb-0">Net Salary 15,500</p>
                          </div>
                          <div className="text-end">
                            <p><a href="#">Share | Download</a></p>
                          </div>
                       </Col>
                       <Col lg={2}>
                          <div className="pay-box p-3">
                              <p className="mb-2">KC0001 | 01 | 20 </p>
                              <p className="mb-2">Jan - 2020</p>
                              <p className="mb-0">Net Salary 15,500</p>
                          </div>
                          <div className="text-end">
                            <p><a href="#">Share | Download</a></p>
                          </div>
                       </Col>
                       <Col lg={2}>
                          <div className="pay-box p-3">
                              <p className="mb-2">KC0001 | 01 | 20 </p>
                              <p className="mb-2">Jan - 2020</p>
                              <p className="mb-0">Net Salary 15,500</p>
                          </div>
                          <div className="text-end">
                            <p><a href="#">Share | Download</a></p>
                          </div>
                       </Col>
                       <Col lg={2}>
                          <div className="pay-box p-3">
                              <p className="mb-2">KC0001 | 01 | 20 </p>
                              <p className="mb-2">Jan - 2020</p>
                              <p className="mb-0">Net Salary 15,500</p>
                          </div>
                          <div className="text-end">
                            <p><a href="#">Share | Download</a></p>
                          </div>
                       </Col>
                     </Row>


                     <Row className="border-bottom pb-3">
                     <CardTitle className="h6 mb-4 mt-4">Deduction</CardTitle>
                     <Col lg={3}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Deduction Type</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Start Month</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Start Year</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Monthly deduction amount"
                            />
                          </div>
                        </Col>
                        <Col lg={3}>
                        <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="No of Installment"
                            />
                          </div>
                        </Col>

                        <Col lg={3}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputZip"
                              placeholder="Total Deduction"
                            />
                          </div>
                          
                        </Col>
                        <Col lg={3}>
                <div className ="d-flex align-items-center
                 justify-content-between">
                   
                  <div className ="d-flex">
                  <div  className="form-check">
                                          <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                    </div>
                    <a className ="btn btn_success btn-sm ms-2" href="/">Save</a>
                    <a className ="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
                      </Row>

                              <Row className="pt-4 pb-4">
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light table-thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DatatableTables;
