import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody, Input, Button } from "reactstrap";
import ConvertLeadIndividualDetail from "./ConvertLeadIndividualDetail";
// Rating Plugin1 

const UiRating = () => {
  const history = useHistory();
  const EnrollLeadRecord = async () => {
    let VLeadId = localStorage.getItem("ConvertIndividualLeadId") == null ? "0" : localStorage.getItem("ConvertIndividualLeadId") == "" ? "0" : localStorage.getItem("ConvertIndividualLeadId");
    let AlertMessgae = "Are you sure you want to enroll this record?";
    if (confirm(AlertMessgae) == true) {
      var AUrl = global.APIURL + '/LeadEnrolled/' + VLeadId;
      axios.post(AUrl)
        .then(res => {
          alert(res.data[0].RecordStatus);
          history.push("/ConvertedInq");
        });
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Converted Lead Detail | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">


          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle>Converted Lead Detail</CardTitle>
                <a href="/ConvertedInq" className="me-2">
                  Converted Lead
                </a>{" "}
                <span>/</span>
                <span className="ms-2">Converted Lead Detail</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/ConvertedInq">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
              <a className="btn btn-primary btn-sm float-end font-14 me-2" href="#" onClick={EnrollLeadRecord}>
                Enroll
              </a>

            </Col>
          </Row>
          <ConvertLeadIndividualDetail />

        </div>
      </div>
    </React.Fragment>
  );

}
export default UiRating;
