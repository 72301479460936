import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';

import LinkApp from 'App';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';


const OnSort = () => {
  let columns = [
    {
      key: "GId",
      text: "#",
      sortable: true
    },
    {
      key: "percentage",
      text: "GST Percentage",
      sortable: true
    },
    {
        key: "Flag",
        text: "Status",
        cell: (record, index) => {
            return (
              <Fragment>
                {record["Flag"] === "A" ?                
                <span>Active</span>
                : <span>InActive</span>}
              </Fragment>
            );
          }
    
      },

    {
      d: "action",
      text: "Action",
      cell: (record, index) => {
        return (
          <Fragment>
            {RightsUpdate == "A" ? <a className="me-3 text-primary" onClick={(editRecord.bind(this, record))}><i className="mdi mdi-pencil font-size-18"></i></a> : ''}
            {RightsDelete == "A" ? <a className={IconrenderSwitchColor(record)} onClick={deleteRecord.bind(this, record)}><i className={IconrenderSwitch(record)}></i></a> : ''}
          </Fragment>
        );
      }
    }
  ];
  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
   
  }
  const [records, setRecords] = useState([])
  const [CategoryFlag, setuserFlag] = useState("");
  const [RightsAdd, setRightsAdd] = useState("D");
  const [RightsUpdate, setRightsUpdate] = useState("D");
  const [RightsDelete, setRightsDelete] = useState("D");


  let history = useHistory();

  function IconrenderSwitch(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'mdi mdi-close font-size-18';
      default:
        return 'mdi mdi-check-all font-size-18';
    }
  }

  function IconrenderSwitchColor(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }

  useEffect(() => {
    const f = {
      MenuId: "67",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
      //UserTypeId: "1"
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {
   
        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add') {
            setRightsAdd(MS);
          }
          else if (MA == 'Update') {
            setRightsUpdate(MS);
          }
          else if (MA == 'Delete') {
            setRightsDelete(MS);
          }
        }
      });

      axios.post(global.APIURL + '/GSTListAll')
      .then(res => {
        console.log("res",res);
        setRecords(res.data);      

      });

  }, []);
  console.log("records",records)

   const editRecord = (record) => {
    let id = record["GId"]
    let path = `/GST/EditGST/:${id}`;
    history.push(path);
  }

  const deleteRecord = (record) => {
    console.log("rr",record);
    let GSTId = record["GId"];
    let UTFlag = record["Flag"];
   // let de = UTFlag == "A" ? "D" : UTFlag == "D" ? "A" : UTFlag;
    const GSTflag = {
        GId:GSTId,
        Flag:UTFlag,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    }

    let AlertMessgae = UTFlag == "A" ? "Are you sure you want to inactive this record?" : "Are you sure you want to active this record?";
    if (confirm(AlertMessgae) == true) {
      axios.post(global.APIURL + '/GSTStatusUpdate', GSTflag)
        .then(res => {
            console.log("res",res);
          if (UTFlag == "A") {
            alert("Record Inactive Successfully");
            axios.post(global.APIURL + '/GSTListAll')
      .then(res => {
        console.log("res",res);
        setRecords(res.data);      

      });
          }
          else if (UTFlag == "D") {
            alert("Record Active Successfully");
            axios.post(global.APIURL + '/GSTListAll')
            .then(res => {
              console.log("res",res);
              setRecords(res.data);            
            });
          }
        })
    }
  }

  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }

  const exportPDF=()=>{

    const unit = "pt";
    const size = "A4"; 
    const orientation = "portrait"; 

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "GST List Report";
    const headers = [["#","GST Percentage"]];

    const data = records.map((item,index)=> [index+1,item.percentage]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("GSTReport.pdf")
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>GST | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">
        <Row className="mb-3">
          <Col className="col-lg-6 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">GST</CardTitle>
              <a href="/GeneralDashboard" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <span className="ms-2">GST</span>
            </div>
          </Col>
          <Col className="col-lg-6 co-md-6 col-6">
            <div className="d-flex align-items-center justify-content-end ">
             
              <div>
                <a className="btn btn-primary btn-sm float-end font-14" href="/GeneralDashboard">
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
                {RightsAdd == "A" ? <a className="btn btn-primary btn-sm float-end font-14 me-2" href="/GST/AddGST">
                  <i className="mdi mdi-plus align-middle me-1"></i>Add New
                </a> : ''}

                <ReactHtmlTableToExcel
              className="btn btn-primary btn-sm float-end font-14 me-2"
              table="emp"
              filename="GSTListReport"
              sheet="Sheet"
              buttonText="Export Excel" />
              <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
              </div>
            </div>
          </Col>

        </Row>

        <Row>
          <div>
            <table id="emp" className="table" hidden="hidden" >
              <thead>
                <tr>
                <th>#</th>
                <th>Percentage</th>
              
                </tr>
              </thead>
              <tbody>              {
                records.map((item, index) => {
                  return <tr key={index}>
                    <td> {index +1} </td>                   
                    <td>{item.percentage}</td>                                                                                                                
                  </tr>
                })
              }
              </tbody>
            </table>           
          </div>
        </Row>
        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default OnSort;