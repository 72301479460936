import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import $ from 'jquery';

const EditInvoice = () => {
    const [EditInvoiceId, setEditInvoiceId] = useState(0);
    const [InvoiceType, setInvoiceType] = useState('');
    const [InvoiceClientId, setInvoiceClientId] = useState(0);
    const [InvoiceName, setInvoiceName] = useState('');
    const [InvoiceMobileNo, setInvoiceMobileNo] = useState('');
    const [InvoiceEmailAddress, setInvoiceEmailAddress] = useState('');
    const [InvoiceGSTNumber, setGSTNumber] = useState('');
    const [InvoiceCompanyName, setInvoiceCompanyName] = useState('');
    const [InvoiceAddress, setInvoiceAddress] = useState('');
    const [InvoiceState, setInvoiceState] = useState('');
    const [BillDate, setBillDate] = useState('');
    const [DueDate, setDueDate] = useState('');
    const [Particular, setParticular] = useState('');
    const [Amount, setAmount] = useState(0);
    const [IsGstApply, setIsGstApply] = useState('No');
    const [GstId, setGstId] = useState(0);
    const [Description, setDescription] = useState('');
    const [GSTList, SetGSTList] = useState([]);

    const [InvoiceItemList, SetInvoiceItemList] = useState([]);

    useEffect(() => {
        getGSTList();
        getInvoiceDetail();
    }, []);

    const getGSTList = async () => {
        var ACUrl = global.APIURL + '/GSTList';
        axios.get(ACUrl)
            .then(Cires => {
                let CityL = Cires.data.map(kccity => {
                    return kccity;
                });
                SetGSTList(CityL);
            });
    };

    const RBInvoiceTypeChange = async InvoiceType => {
        if (InvoiceType == 'Retail') {
            $("#divRetail").removeClass("d-none");
            $("#divCorporate").removeClass("d-none");
            $("#divCorporate").addClass("d-none");
        }
        else if (InvoiceType == 'Corporate') {
            $("#divRetail").removeClass("d-none");
            $("#divCorporate").removeClass("d-none");
            $("#divRetail").addClass("d-none");
        }
        else {
            $("#divRetail").removeClass("d-none");
            $("#divCorporate").removeClass("d-none");
            $("#divRetail").addClass("d-none");
            $("#divCorporate").addClass("d-none");
        }
    };

    const RetailloadOptions = (inputValue, callback) => {
        var SearchValue = inputValue == null ? 'ALL' : inputValue == '' ? 'ALL' : inputValue;
        fetch(global.APIURL + "/RetailSearchList/" + SearchValue)
            .then((res) => res.json())
            .then((data) => {

                callback(
                    data.map((f) => {
                        return {
                            value: f.LeadId,
                            label: f.FullName
                        };
                    })
                );
            })
    };

    const CorporateloadOptions = (inputValue, callback) => {
        var SearchValue = inputValue == null ? 'ALL' : inputValue == '' ? 'ALL' : inputValue;
        fetch(global.APIURL + "/CorporateSearchList/" + SearchValue)
            .then((res) => res.json())
            .then((data) => {

                callback(
                    data.map((f) => {
                        return {
                            value: f.CorporateleadId,
                            label: f.Title
                        };
                    })
                );
            })
    };

    const getInvoiceDetail = async () => {
        var VEditInvoiceId = parseInt(localStorage.getItem("EditInvoiceId") == null ? "0" : localStorage.getItem("EditInvoiceId") == "" ? "0" : localStorage.getItem("EditInvoiceId"));
        const ItemArr = {
            Id: VEditInvoiceId
        }
        axios.post(global.APIURL + "/EditInvoiceDetail", ItemArr)
            .then(res => {
                var VInvoiceId = res.data.InvoiceDetail[0].Id == null ? 0 : res.data.InvoiceDetail[0].Id;
                var VInvoiceType = res.data.InvoiceDetail[0].InvoiceType == null ? '' : res.data.InvoiceDetail[0].InvoiceType;

                var VBillDate = res.data.InvoiceDetail[0].DBillDate == null ? '' : res.data.InvoiceDetail[0].DBillDate;
                var VDueDate = res.data.InvoiceDetail[0].DDueDate == null ? '' : res.data.InvoiceDetail[0].DDueDate;

                setEditInvoiceId(VInvoiceId);

                if (VInvoiceType == 'Retail') {
                    $("#rbRetail").prop("checked", true);
                    $("#rbCorporate").prop("checked", false);
                    setInvoiceType('Retail');
                    $("#divRetail").removeClass("d-none");
                    $("#divCorporate").removeClass("d-none");
                    $("#divCorporate").addClass("d-none");
                }
                else if (VInvoiceType == 'Corporate') {
                    $("#rbRetail").prop("checked", false);
                    $("#rbCorporate").prop("checked", true);
                    setInvoiceType('Corporate');
                    $("#divRetail").removeClass("d-none");
                    $("#divCorporate").removeClass("d-none");
                    $("#divRetail").addClass("d-none");
                }
                else {
                    $("#rbRetail").prop("checked", false);
                    $("#rbCorporate").prop("checked", false);
                    $("#divRetail").removeClass("d-none");
                    $("#divCorporate").removeClass("d-none");
                    $("#divRetail").addClass("d-none");
                    $("#divCorporate").addClass("d-none");
                    setInvoiceType('');
                }

                setInvoiceClientId(res.data.InvoiceDetail[0].InvoiceClientId == null ? 0 : res.data.InvoiceDetail[0].InvoiceClientId);
                setInvoiceName(res.data.InvoiceDetail[0].InvoiceName == null ? '' : res.data.InvoiceDetail[0].InvoiceName);
                setInvoiceMobileNo(res.data.InvoiceDetail[0].InvoiceMobileNo == null ? '' : res.data.InvoiceDetail[0].InvoiceMobileNo);
                setInvoiceEmailAddress(res.data.InvoiceDetail[0].InvoiceEmailAddress == null ? '' : res.data.InvoiceDetail[0].InvoiceEmailAddress);
                setGSTNumber(res.data.InvoiceDetail[0].InvoiceGSTNumber == null ? '' : res.data.InvoiceDetail[0].InvoiceGSTNumber);
                setInvoiceCompanyName(res.data.InvoiceDetail[0].InvoiceCompanyName == null ? '' : res.data.InvoiceDetail[0].InvoiceCompanyName);
                setInvoiceAddress(res.data.InvoiceDetail[0].InvoiceAddress == null ? '' : res.data.InvoiceDetail[0].InvoiceAddress);
                setInvoiceState(res.data.InvoiceDetail[0].InvoiceState == null ? '' : res.data.InvoiceDetail[0].InvoiceState);

                console.log(res.data.InvoiceItemDetail);
                SetInvoiceItemList(res.data.InvoiceItemDetail);

                $("#txtBillDate").val(VBillDate);
                $("#txtDueDate").val(VDueDate);
            });
    };

    const getInviceClientDetail = async ItemArr => {
        const FilterItemArr = {
            InvoiceClientId: ItemArr.value == null ? 0 : ItemArr.value == '' ? 0 : ItemArr.value,
            SpMode: InvoiceType == 'Retail' ? 'RetailSearchList' : InvoiceType == 'Corporate' ? 'CorporateSearchList' : ''
        }
        axios.post(global.APIURL + '/InvoiceClientDetail', FilterItemArr)
            .then(res => {
                if (res.data != '') {
                    setInvoiceClientId(res.data[0].InvoiceClientId == null ? 0 : res.data[0].InvoiceClientId);
                    setInvoiceName(res.data[0].FullName == null ? '' : res.data[0].FullName);
                    setInvoiceMobileNo(res.data[0].MobileNumber == null ? '' : res.data[0].MobileNumber);
                    setInvoiceEmailAddress(res.data[0].EmailAddress == null ? '' : res.data[0].EmailAddress);
                    setGSTNumber(res.data[0].GSTNo == null ? '' : res.data[0].GSTNo);
                    setInvoiceCompanyName(res.data[0].CompanyName == null ? '' : res.data[0].CompanyName);
                    setInvoiceAddress(res.data[0].Address == null ? '' : res.data[0].Address);
                    setInvoiceState(res.data[0].StateName == null ? '' : res.data[0].StateName);
                }
                else {
                    setInvoiceClientId(0);;
                    setInvoiceName('');
                    setInvoiceMobileNo('');
                    setInvoiceEmailAddress('');
                    setGSTNumber('');
                    setInvoiceCompanyName('');
                    setInvoiceAddress('');
                    setInvoiceState('');
                }
            });
    };

    const InvoiceItemAddRow = () => {
        var AddRow = 1;
        if (Particular == null) {
            alert('Please input Particular.');
            AddRow = 0;
        }

        if (Particular == '') {
            alert('Please input Particular.');
            AddRow = 0;
        }

        if (Amount == null) {
            alert('Please input Amount.');
            AddRow = 0;
        }

        if (Amount == '') {
            alert('Please input Amount.');
            AddRow = 0;
        }

        if (Amount == 0) {
            alert('Please input Amount.');
            AddRow = 0;
        }


        if (IsGstApply == null) {
            alert('Please select is gst apply.');
            AddRow = 0;
        }

        if (IsGstApply == '') {
            alert('Please select is gst apply.');
            AddRow = 0;
        }

        if (IsGstApply == 'Yes') {

            if (GstId == null) {
                alert('Please input Gst Per.');
                AddRow = 0;
            }

            if (GstId == '') {
                alert('Please input Gst Per.');
                AddRow = 0;
            }

            if (GstId == 0) {
                alert('Please input Gst Per.');
                AddRow = 0;
            }
        }

        if (AddRow == 1) {
            const InvoiceItemArr = {
                RowNo: InvoiceItemList.length + 1,
                InvoiceDetailId: 0,
                Particular: Particular,
                Amount: Amount,
                IsGstApply: IsGstApply,
                GSTPerId: $("#ddlGST option:selected").val(),
                GSTPer: $("#ddlGST option:selected").text(),
                Description: Description,
                EntryType: 'N'
            };
            InvoiceItemList.push(InvoiceItemArr);

            setParticular('');
            setAmount(0);
            setIsGstApply('No');
            setGstId(0);
            setDescription('');

            $("#txtParticular").val('');
            $("#txtAmount").val('');
            $("#ddlGST").val(0);
            $("#txtDescription").val('');
            $("input[name='rbIsGSTApply']").prop("checked", false);
        }
    };

    const InvoiceItemRemoveRow = (e, InvoiceItemId, EntryType, RowIndex) => {
        let AlertMessgae = "Are you sure you want to delete this record?";
        if (confirm(AlertMessgae) == true) {
            let VInvoiceItemId = InvoiceItemId;
            let VRowIndex = RowIndex;
            let VEntryType = EntryType;

            if (VEntryType === 'O') {
                const ItemArr = { Id: VInvoiceItemId }
                axios.post(global.APIURL + '/InvoiceItemDelete', ItemArr)
                    .then(res => {
                        SetInvoiceItemList(
                            InvoiceItemList.filter(item => item.RowNo !== VRowIndex)
                        );
                    })
            }
            else {
                SetInvoiceItemList(
                    InvoiceItemList.filter(item => item.RowNo !== VRowIndex)
                );
            }
        }
    };

    const SaveInvoice = async () => {
        var AddRow = 1;
        if (InvoiceType == null) {
            alert('Select Invoice Type.');
            AddRow = 0;
            return;
        }

        if (InvoiceType == '') {
            alert('Select Invoice Type.');
            AddRow = 0;
            return;
        }

        if ($("#txtName").val() == null) {
            alert('Please Select Invoice Client.');
            AddRow = 0;
            return;
        }

        if ($("#txtName").val() == '') {
            alert('Please Select Invoice Client.');
            AddRow = 0;
            return;
        }

        if ($("#txtMobileNo").val() == null) {
            alert('Please Input Mobile No..');
            AddRow = 0;
            return;
        }

        if ($("#txtMobileNo").val() == '') {
            alert('Please Input Mobile No..');
            AddRow = 0;
            return;
        }

        if ($("#txtEmailAddress").val() == null) {
            alert('Please Input Email Address.');
            AddRow = 0;
            return;
        }

        if ($("#txtEmailAddress").val() == '') {
            alert('Please Input Email Address.');
            AddRow = 0;
            return;
        }

        if ($("#txtBillDate").val() == null) {
            alert('Please Input Bill Date.');
            AddRow = 0;
            return;
        }

        if ($("#txtBillDate").val() == '') {
            alert('Please Input Bill Date.');
            AddRow = 0;
            return;
        }

        if ($("#txtDueDate").val() == null) {
            alert('Please Input Due Date.');
            AddRow = 0;
            return;
        }

        if ($("#txtDueDate").val() == '') {
            alert('Please Input Due Date.');
            AddRow = 0;
            return;
        }

        if (AddRow > 0) {
            var VSubTotal = 0;
            var VGSTAmount = 0;
            var VTotalAmount = 0;

            for (let ii = 0; ii < InvoiceItemList.length; ii++) {
                const InvoiceItemDetail = InvoiceItemList[ii];
                var EntryType = InvoiceItemDetail.EntryType == null ? 'N' : InvoiceItemDetail.EntryType == '' ? 'N' : InvoiceItemDetail.EntryType;
                if (EntryType == 'N') {
                    var VIsGstApply = InvoiceItemDetail.IsGstApply == null ? '' : InvoiceItemDetail.IsGstApply;
                    if (VIsGstApply == 'Yes') {
                        var VAmount = parseFloat(InvoiceItemDetail.Amount == null ? 0 : InvoiceItemDetail.Amount);
                        var VGSTPer = parseFloat(InvoiceItemDetail.GSTPer == null ? 0 : InvoiceItemDetail.GSTPer);
                        var VGSTAmount = parseFloat(VAmount) * parseFloat(VGSTPer) / 100;
                        var VTotalAmount = parseFloat(VAmount) + parseFloat(VGSTAmount);

                        VSubTotal = parseFloat(VSubTotal) + parseFloat(VAmount);
                        VGSTAmount = parseFloat(VGSTAmount) + parseFloat(VGSTAmount);
                        VTotalAmount = parseFloat(VTotalAmount) + parseFloat(VTotalAmount);
                    }
                    else {
                        var VAmount = parseFloat(InvoiceItemDetail.Amount == null ? 0 : InvoiceItemDetail.Amount);
                        VSubTotal = parseFloat(VSubTotal) + parseFloat(VAmount);
                        VGSTAmount = parseFloat(VGSTAmount) + 0;
                        VTotalAmount = parseFloat(VTotalAmount) + parseFloat(VAmount);
                    }
                }
            }

            const InvoiceArr = {
                InvoiceId: EditInvoiceId,
                InvoiceType: InvoiceType,
                InvoiceClientId: InvoiceClientId,
                InvoiceName: $("#txtName").val(),
                InvoiceMobileNo: $("#txtMobileNo").val(),
                InvoiceEmailAddress: $("#txtEmailAddress").val(),
                InvoiceGSTNumber: $("#txtGSTNumber").val(),
                InvoiceCompanyName: $("#txtCompanyName").val(),
                BillDate: $("#txtBillDate").val(),
                DueDate: $("#txtDueDate").val(),
                InvoiceAddress: $("#txtAddress").val(),
                SubTotal: VSubTotal,
                GSTAmount: VGSTAmount,
                TotalAmount: VTotalAmount,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                SpMode: 'InvoiceUpdate'
            };

            axios.post(global.APIURL + "/InvoiceSave", InvoiceArr)
                .then(res => {
                    var VRecordInsertId = res.data[0].RecordInsertId;
                    var VRowCount = InvoiceItemList.filter(x => x.EntryType == 'N').length;
                    var VInvoiceItemListFilter = InvoiceItemList.filter(x => x.EntryType == 'N');
                    if (VRowCount > 0) {
                        for (let ii = 0; ii < VInvoiceItemListFilter.length; ii++) {
                            const InvoiceItemDetail = VInvoiceItemListFilter[ii];
                            var EntryType = InvoiceItemDetail.EntryType == null ? 'N' : InvoiceItemDetail.EntryType == '' ? 'N' : InvoiceItemDetail.EntryType;
                            if (EntryType == 'N') {
                                var VSubGSTAmount = 0;
                                var VSubCGSTAmount = 0;
                                var VSubSGSTAmount = 0;
                                var VSubIGSTAmount = 0;
                                var VSubTotalAmount = 0;

                                var VAmount = parseFloat(InvoiceItemDetail.Amount == null ? 0 : InvoiceItemDetail.Amount);
                                var VGSTPer = parseFloat(InvoiceItemDetail.GSTPer == null ? 0 : InvoiceItemDetail.GSTPer);
                                var VIsGstApply = InvoiceItemDetail.IsGstApply == null ? '' : InvoiceItemDetail.IsGstApply;
                                if (VIsGstApply == 'Yes') {
                                    VSubGSTAmount = parseFloat(VAmount * VGSTPer / 100);
                                    VSubTotalAmount = parseFloat(VAmount + VSubGSTAmount);
                                    VSubCGSTAmount = InvoiceState === 'gujarat' ?parseFloat(((VAmount * (VGSTPer / 2)) / 100)):0;
                                    VSubSGSTAmount =InvoiceState === 'gujarat' ? parseFloat(((VAmount * (VGSTPer / 2)) / 100)):0;
                                    VSubIGSTAmount =InvoiceState != 'gujarat' ? parseFloat(VAmount * VGSTPer / 100):0;
                                }
                                else {
                                    VSubGSTAmount = 0;
                                    VSubCGSTAmount = 0;
                                    VSubSGSTAmount = 0;
                                    VSubIGSTAmount = 0;
                                    VSubTotalAmount = parseFloat(VAmount + VSubGSTAmount);
                                }

                                const InvoiceItemArr = {
                                    InvoiceId: VRecordInsertId,
                                    Particular: InvoiceItemDetail.Particular == null ? '' : InvoiceItemDetail.Particular,
                                    Amount: VAmount,
                                    IsGstApply: InvoiceItemDetail.IsGstApply == null ? 'No' : InvoiceItemDetail.IsGstApply,
                                    GSTPerId: InvoiceItemDetail.IsGstApply == 'No' ? 0 : InvoiceItemDetail.GSTPerId == null ? 0 : InvoiceItemDetail.GSTPerId,
                                    GSTPer: InvoiceItemDetail.IsGstApply == 'No' ? 0 : InvoiceItemDetail.GSTPer == null ? 0 : InvoiceItemDetail.GSTPer,
                                    CGSTAmount: VSubCGSTAmount,
                                    SGSTAmount: VSubSGSTAmount,
                                    IGSTAmount: VSubIGSTAmount,
                                    GSTAmount: VSubGSTAmount,
                                    TotalAmount: VSubTotalAmount,
                                    Description: InvoiceItemDetail.Description == null ? '' : InvoiceItemDetail.Description,
                                    CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                                    SpMode: 'InvoiceItemSave'
                                };
                                axios.post(global.APIURL + "/InvoiceItemSave", InvoiceItemArr)
                                    .then(res1 => {

                                        if (ii == (VRowCount - 1)) {
                                            const ItemArr = {
                                                Id: VRecordInsertId
                                            };
                                            axios.post(global.APIURL + "/InvoiceAmountUpdate", ItemArr)
                                                .then(res2 => {
                                                });

                                            alert('Invoice information update successfully.');
                                            window.location.href = "/InvoiceManage";
                                        }
                                    });
                            }

                        }
                    }
                    else {
                        const ItemArr = {
                            Id: VRecordInsertId
                        };
                        axios.post(global.APIURL + "/InvoiceAmountUpdate", ItemArr)
                            .then(res2 => {
                                alert('Invoice information update successfully.');
                                window.location.href = "/InvoiceManage";
                            });


                    }
                });
        }
    }

    return (
        <div className="page-content">

            <MetaTags>
                <title>AccountType | KC Admin Group</title>
            </MetaTags>

            <div className="container-fluid">

                <Row className="mb-3">
                    <Col className="col-lg-8 col-md-6 col-12 mb-2">
                        <div className="breadcrum ps-0">
                            <CardTitle className="h4">Edit Invoice</CardTitle>
                            <a href="/GeneralDashboard" className="me-2">Home</a>
                            <span>/</span>
                            <a href="/InvoiceManage" className="ms-2 me-2">Invoice Manage</a>
                            <span>/</span>
                            <span className="ms-2">Edit Invoice</span>
                        </div>
                    </Col>
                    <Col className="col-lg-4 co-md-6 col-12">
                        <a className="btn btn-primary btn-sm float-end font-14" href="/InvoiceManage">
                            <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>

                                <Row>
                                    <Col lg={12} className="d-flex mb-3 mt-3">
                                        <div className="form-check me-2">
                                            <input type="radio" id="rbRetail" className="form-check-input" name="rbinvoicetype" value="Retail" onChange={e => { setInvoiceType('Retail'); RBInvoiceTypeChange('Retail') }} />
                                            <label className="form-check-label" htmlFor="rbRetail">Retail</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="radio" id="rbCorporate" className="form-check-input" name="rbinvoicetype" value="Corporate" onChange={e => { setInvoiceType('Corporate'); RBInvoiceTypeChange('Corporate') }} />
                                            <label className="form-check-label" htmlFor="rbCorporate">Corporate</label>
                                        </div>
                                    </Col>
                                    <Col lg={4} className="d-none" id='divRetail'>
                                        <AsyncSelect cacheOptions loadOptions={RetailloadOptions} defaultOptions onChange={e => getInviceClientDetail(e)} />
                                    </Col>
                                    <Col lg={4} className="d-none" id='divCorporate'>
                                        <AsyncSelect cacheOptions loadOptions={CorporateloadOptions} defaultOptions onChange={e => getInviceClientDetail(e)} />
                                    </Col>
                                    {InvoiceType == "" ? <Col lg={12}></Col> : <Col lg={8}></Col>}

                                    <Col lg={4} className="mt-3">
                                        <label className="form-label">Name</label>
                                        <Input type="text" className="form-control" id="txtName" value={InvoiceName} />
                                    </Col>
                                    <Col lg={4} className="mt-3">
                                        <label className="form-label">Mobile No.</label>
                                        <Input type="text" className="form-control" id="txtMobileNo" value={InvoiceMobileNo} />
                                    </Col>
                                    <Col lg={4} className="mt-3">
                                        <label className="form-label">Email Address</label>
                                        <Input type="text" className="form-control" id="txtEmailAddress" value={InvoiceEmailAddress} />
                                    </Col>
                                    <Col lg={3} className="mt-3">
                                        <label className="form-label">GST Number</label>
                                        <Input type="text" className="form-control" id="txtGSTNumber" placeholder="Enter GST Number" onChange={(e) => setGSTNumber(e.target.value)} />
                                    </Col>
                                    <Col lg={5} className="mt-3">
                                        <label className="form-label">Company Name</label>
                                        <Input type="text" className="form-control" id="txtCompanyName" placeholder="Enter Company Name" value={InvoiceCompanyName} onChange={(e) => setInvoiceCompanyName(e.target.value)} />
                                    </Col>
                                    <Col lg={2} className="mt-3">
                                        <label className="form-label">Bill Date</label>
                                        <Input type="date" className="form-control" id="txtBillDate" onChange={(e) => setBillDate(e.target.value)} />
                                    </Col>
                                    <Col lg={2} className="mt-3">
                                        <label className="form-label">Due Date</label>
                                        <Input type="date" className="form-control" id="txtDueDate" onChange={(e) => setDueDate(e.target.value)} />
                                    </Col>
                                    <Col lg={12} className="mt-3">
                                        <label className="form-label">Address</label>
                                        <Input type="text" className="form-control" id="txtAddress" value={InvoiceAddress} />
                                    </Col>
                                    <Col lg={12} className="mt-3">
                                        <hr />
                                        <h4 className="mt-2">Invoice Items</h4>
                                        <hr />
                                    </Col>
                                    <Col lg={12} className="mt-3">
                                        <label className="form-label">Particular</label>
                                        <Input type="text" className="form-control" placeholder="Enter Particular" id="txtParticular" maxLength={100} onChange={e => setParticular(e.target.value)} />
                                    </Col>
                                    <Col lg={3} className="mt-3">
                                        <label className="form-label">Amount</label>
                                        <Input type="text" className="form-control" id="txtAmount" placeholder="Enter Amount" onChange={e => setAmount(e.target.value)} />
                                    </Col>
                                    <Col lg={3} className="d-flex mb-3 mt-3" >
                                        <label className="form-label">Is GST Apply</label>
                                        <br />
                                        <div className="form-check isgstapplyrbyes">
                                            <input type="radio" id="rbIsGSTApplyYes" className="form-check-input" name="rbIsGSTApply" value="Yes" onChange={e => setIsGstApply(e.target.value)} />
                                            <label className="form-check-label" htmlFor="rbIsGSTApplyYes">Yes</label>
                                        </div>
                                        <div className="form-check isgstapplyrbno">
                                            <input type="radio" id="rbIsGSTApplyNo" className="form-check-input" name="rbIsGSTApply" value="No" onChange={e => setIsGstApply(e.target.value)} />
                                            <label className="form-check-label" htmlFor="rbIsGSTApplyNo">No</label>
                                        </div>
                                    </Col>
                                    {IsGstApply === 'Yes' ?
                                        <Col lg={3} className="mt-3">
                                            <label className="form-label">GST</label>
                                            <select id="ddlGST" className="form-select" aria-label="Default select example" onChange={e => setGstId(e.target.value)}>
                                                <option selected value="0">Select GST</option>
                                                {GSTList.map(g => (
                                                    <option key={g.GId} value={g.GId}>
                                                        {" "}
                                                        {g.percentage}{" "}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                        : ''}
                                    <Col lg={12} className="mt-3">
                                        <label className="form-label">Description</label>
                                        <Input type="textarea" className="form-control" placeholder="Enter Description" id="txtDescription" onChange={e => setDescription(e.target.value)} maxLength={180} />
                                    </Col>
                                    <Col lg={2} className="mt-3">
                                        <a className="btn btn_success btn-sm" id="btnAdd" onClick={InvoiceItemAddRow}>Add</a>
                                        <a className="btn btn_danger btn-sm ms-2" id="btnClear" >Clear</a>
                                    </Col>
                                    <Col lg={12} className="mt-3">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="text-center">#</th>
                                                    <th scope="col" className="text-center">Particular</th>
                                                    <th scope="col" className="text-center">Amount</th>
                                                    <th scope="col" className="text-center">Is GST Apply</th>
                                                    <th scope="col" className="text-center">GST Per</th>
                                                    <th scope="col" className="text-center">Description</th>
                                                    <th scope="col" className="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {InvoiceItemList.map((item, idx) => (
                                                    <tr key={item.RowNo}>
                                                        <th scope="row" className="text-center">{idx + 1}</th>
                                                        <td className="text-center">{item.Particular}</td>
                                                        <td className="text-center">{item.Amount}</td>
                                                        <td className="text-center">{item.IsGstApply}</td>
                                                        <td className="text-center">{item.GSTPer}</td>
                                                        <td className="text-center">{item.Description}</td>
                                                        <td className="text-center">
                                                            <a className="documentdelete" onClick={e => InvoiceItemRemoveRow(e, item.InvoiceDetailId, item.EntryType, item.RowNo)} ><i className="fas fa-trash-alt"></i></a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col lg={12} className="mt-3">
                                        <button className="btn btn_success btn-sm" id="btnsave" onClick={SaveInvoice} >Save</button>
                                        <a className="btn btn_danger btn-sm ms-2" href="/AccountType">Clear</a>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default EditInvoice;