import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Input, Form, Button } from "reactstrap";
import { useHistory } from 'react-router-dom';
import { Search } from "react-bootstrap-table2-toolkit";
import "../../../assets/scss/datatables.scss";
import axios from "axios";
import PlacholderImgC from "assets/images/favicon.png";
import { Link } from "react-router-dom";


const { SearchBar } = Search;

const Bureauhelpdata = () => {
    const history = useHistory();

    const [helpdeskList, setHelpdeskList] = useState([]);
    const [BureauDataList, setBureauDataList] = useState([]);
    const [BureauDatacategoryList, setBureauDatacategoryList] = useState([]);
    const [BId, setBId] = useState("");
    const [BsubId, setBsubId] = useState("");
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;



    useEffect(() => {

        getBureauDataList();

    }, []);

    const getBureauDataList = async () => {
        var ACUrl = global.APIURL + '/BureauCategory';
        axios.get(ACUrl)
            .then(Cires => {
                let CityL = Cires.data.map(kccity => {
                    return kccity;
                });
                setBureauDataList(CityL);
            });
    };




    const getbureausubcategoryDataList = async (VBId) => {
        var ACUrl = global.APIURL + '/BureauSubCategory';
        var subcatgory = {
            BId: VBId
        }

        axios.post(ACUrl, subcatgory)
            .then(Cires => {
                let CityL = Cires.data.map(kccity => {
                    return kccity;
                });
                setBureauDatacategoryList(CityL);
            });
    };

    const getbureauhelpList = async (VId) => {
        var AUrl = global.APIURL + '/bureauolddata';
        var ca = {
            Id: VId
        }

        axios.post(AUrl, ca)
            .then(LCdres => {
                let LeadContactList = LCdres.data.map((LeadContact) => {
                    return LeadContact
                })
                setHelpdeskList(LeadContactList);

            });
    }



    const HelpDatahandleAddRow = e => {
        var LRNo = helpdeskList.length + 1;
        var VCommunicationLevel = document.getElementById('txtCommunicationLevel').value;
        var VName = document.getElementById('txtName').value;
        var VEmail = document.getElementById('txtEmail').value;
        var VMobileNo = document.getElementById('txtMobileNo').value;

        if (VName == "") {
            alert("Enter Name");
            return;
        }
        if (VCommunicationLevel == "") {
            alert("Enter Communication Level");
            return;
        }
        if (VEmail == "") {
            alert("Enter Email");
            return;
        }
        if (regex.test(VEmail) === false) {
            alert("Enter Valid Email Address");
            return;

        }
        if (VMobileNo == "") {
            alert("Enter Mobile Number");
            return;

        }
        if (VMobileNo.length < 10) {
            alert("Enter Valid Mobile Number");
            return;
        }

        const Citem = {
            bureauHelpId: 0,
            CLevel: VCommunicationLevel,
            Name: VName,
            EmailId: VEmail,
            MobileNo: VMobileNo,
            txtCommunicationLevelId: "txtCommunicationLevel" + LRNo,
            txtNameId: "txtName" + LRNo,
            txtEmailId: "txtEmail" + LRNo,
            txtMobileNoId: "txtMobileNo" + LRNo

        };

        setHelpdeskList([...helpdeskList, Citem]);
        document.getElementById('txtCommunicationLevel').value = '';
        document.getElementById('txtName').value = '';
        document.getElementById('txtEmail').value = '';
        document.getElementById('txtMobileNo').value = '';



    };


    const BureauhelpdatahandleRemoveRow = (e, bureauHelpId) => {
        let AlertMessgae = "Are you sure you want to delete this record?";
        if (confirm(AlertMessgae) == true) {
            const RId = bureauHelpId;
            setHelpdeskList(helpdeskList.filter(item => item.bureauHelpId !== RId));

            var VLeadContactId = parseInt(bureauHelpId == null ? '0' : bureauHelpId == '' ? '0' : bureauHelpId);
            if (VLeadContactId > 0) {
                const LeadContactItemArr = { BId: VLeadContactId }
                axios.post(global.APIURL + '/BureauhelpdataDelete', LeadContactItemArr).then(resem => { });
            }

            alert('delete successfully');
        }
    };


    const SaveLeadsDeails = async () => {
        var VCommunicationLevel = document.getElementById('txtCommunicationLevel').value;
        var VName = document.getElementById('txtName').value;
        var VEmail = document.getElementById('txtEmail').value;
        var VMobileNo = document.getElementById('txtMobileNo').value;

        if (helpdeskList.length == 0) {
            let IsDataInsert = 1;
            if (VCommunicationLevel == "") {
                IsDataInsert = 0;
            } else if (VName == "") {
                IsDataInsert = 0;
            } else if (VEmail == "") {
                IsDataInsert = 0;
            } else if (VMobileNo == "") { IsDataInsert = 0; }
            if (VEmail != "") {
                if (regex.test(VEmail) === false) {
                    alert("Enter Valid Email Address");
                    return;
                }
            }
            if (VMobileNo != "") {
                if (VMobileNo.length < 10) {
                    alert("Enter Valid Mobile Number");
                    return;
                }
            }
            if (IsDataInsert == 1) {
                const ContactItemArr = {
                    bureauHelpId: 0,
                    CLevel: VCommunicationLevel,
                    Name: VName,
                    EmailId: VEmail,
                    ContactNo: VMobileNo,
                    BId: BId,
                    BsubId: BsubId,
                    Flag: "A",
                    CreateUser: localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId"),

                };

                axios.post(global.APIURL + '/BureauhelpdataInsert', ContactItemArr).then((res) => {
                    alert(res.data[0].RecordStatus);
                    window.location.reload();
                });
            }

        }
        else {
            for (let con = 0; con < helpdeskList.length; con++) {
                const LeadContactDetail = helpdeskList[con];
                var vbureauHelpId = LeadContactDetail.bureauHelpId;
                var vTextBoxControlIdCommunicationLevel = LeadContactDetail.txtCommunicationLevelId;
                var vTextBoxControlIdName = LeadContactDetail.txtNameId;
                var vTextBoxControlIdEmail = LeadContactDetail.txtEmailId;
                var TextBoxControlIdMobileNo = LeadContactDetail.txtMobileNoId;

                const ContactItemArr = {
                    bureauHelpId: vbureauHelpId,
                    CLevel: document.getElementById(vTextBoxControlIdCommunicationLevel).value,
                    Name: document.getElementById(vTextBoxControlIdName).value,
                    EmailId: document.getElementById(vTextBoxControlIdEmail).value,
                    ContactNo: document.getElementById(TextBoxControlIdMobileNo).value,
                    BId: BId,
                    BsubId: BsubId,
                    Flag: "A",
                    CreateUser: localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId"),

                };

                axios.post(global.APIURL + '/BureauhelpdataInsert', ContactItemArr).then(() => {

                    let IsDataInsert = 1;
                    if (VCommunicationLevel == "") {
                        IsDataInsert = 0;
                    } else if (VName == "") {
                        IsDataInsert = 0;
                    } else if (VEmail == "") {
                        IsDataInsert = 0;
                    }
                    else if (VMobileNo == "") {
                        IsDataInsert = 0;
                    }

                    if (VEmail != "") {
                        if (regex.test(VEmail) === false) {
                            alert("Enter Valid Email Address");
                            IsDataInsert = 0;
                        }
                    }
                    if (VMobileNo != "") {
                        if (VMobileNo.length < 10) {
                            alert("Enter Valid Mobile Number");
                            IsDataInsert = 0;
                        }
                    }

                    if (IsDataInsert == 1) {
                        const ContactItemArr = {
                            bureauHelpId: 0,
                            CLevel: VCommunicationLevel,
                            Name: VName,
                            EmailId: VEmail,
                            ContactNo: VMobileNo,
                            BId: BId,
                            BsubId: BsubId,
                            Flag: "A",
                            CreateUser: localStorage.getItem("CorporateleadId") == null ? "0" : localStorage.getItem("CorporateleadId") == "" ? "0" : localStorage.getItem("CorporateleadId"),
                        };
                        axios.post(global.APIURL + '/BureauhelpdataInsert', ContactItemArr).then(() => {
                            alert("Save successfully.");
                            window.location.reload();
                        });
                    }
                    else {
                        alert("Save successfully.");
                        window.location.reload();
                    }

                });
            }
        }
    };

    const inputHandler = e => {
        const { value, maxLength } = e.target;
        if (String(value).length >= maxLength) {
            e.preventDefault();
            return;
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">

                    <Row className="mb-3">
                        <Col className="col-lg-8 col-md-6 col-6">
                            <div className="breadcrum ps-0">
                                <CardTitle className="h4">Add Bureau Help List</CardTitle>
                                <a href="/GeneralDashboard" className="me-2">Home</a>
                                <span>/</span>
                                <a href="/BureauHelpList"><span className="ms-2 me-2">Bureau Help List</span></a>
                                <span>/</span>
                                <span className="ms-2">Add Bureau Help List</span>
                            </div>
                        </Col>
                        {/* <Col className="col-lg-4 co-md-6 col-6">
                            <a className="btn btn-primary btn-sm float-end font-14" href="/Bureauhelpdata">
                                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                            </a>
                        </Col> */}
                    </Row>

                    <div className="ind-card" id="ind-card">
                        <Card>
                            <CardBody>


                                <Row>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="Bureau" className="form-label">
                                                Bureau
                                            </label>
                                            <select className="form-select" Id="ddlBureau" name="ddlBureau" onChange={e => { getbureausubcategoryDataList(e.target.value); setBId(e.target.value) }}>
                                                <option value={"0"}>Select Bureau</option>
                                                {BureauDataList.map(Bureau => (
                                                    <option key={Bureau.Id} value={Bureau.Id}>
                                                        {" "}
                                                        {Bureau.value}{" "}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="category" className="form-label">
                                                Bureau Help category
                                            </label>
                                            <select className="form-select" Id="ddlSubcategory" name="ddlSubcategory" onChange={e => { setBsubId(e.target.value); getbureauhelpList(e.target.value) }}>
                                                <option value={"0"}>Select Bureau Help category</option>
                                                {BureauDatacategoryList.map(bb => (
                                                    <option key={bb.Id} value={bb.Id}>
                                                        {" "}
                                                        {bb.value}{" "}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </Row>


                                <Col lg="12">
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            {helpdeskList.map((item, idx) => (
                                                <tr id={"addr" + idx} key={idx}>
                                                    <td>
                                                        <div data-repeater-list="group-a">
                                                            <Row data-repeater-item className="align-items-end mb-3">
                                                                <Col lg="2" className="">
                                                                    <Label htmlFor="name">
                                                                        Communication Level
                                                                    </Label>
                                                                    <Input type="text" id={item.txtCommunicationLevelId} className="form-control" placeholder="Enter Communication Level" defaultValue={item.CLevel} />
                                                                </Col>
                                                                <Col lg="3" className="">
                                                                    <Label htmlFor="name">
                                                                        Name
                                                                    </Label>
                                                                    <Input type="text" id={item.txtNameId} className="form-control" placeholder="Enter Name" defaultValue={item.Name} />
                                                                </Col>
                                                                <Col lg="3" className="">
                                                                    <Label htmlFor="name">
                                                                        Email
                                                                    </Label>
                                                                    <Input type="text" id={item.txtEmailId} className="form-control" placeholder="Enter Email" defaultValue={item.EmailId} />
                                                                </Col>
                                                                <Col lg="3" className="">
                                                                    <Label htmlFor="name">
                                                                        Mobile No
                                                                    </Label>
                                                                    <Input type="number" id={item.txtMobileNoId} className="form-control" placeholder="Enter Mobile No" onKeyPress={inputHandler} defaultValue={item.MobileNo} maxLength={16} />
                                                                </Col>
                                                                <Col lg="1 mobile-mt">
                                                                    <Button
                                                                        onClick={e =>
                                                                            BureauhelpdatahandleRemoveRow(e, item.bureauHelpId)
                                                                        }
                                                                        color="danger"
                                                                        className="btn-sm btn-width mb-1 mt-md-3 mt-lg-0"
                                                                    >
                                                                        <i className="fas fa-trash-alt"></i>
                                                                    </Button>
                                                                </Col>


                                                            </Row>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div data-repeater-list="group-a">
                                        <Row data-repeater-item className="align-items-end">
                                            <Col lg="2" className="">
                                                <Label htmlFor=" Communication Level">
                                                    Communication Level
                                                </Label>
                                                <Input type="text" id="txtCommunicationLevel" className="form-control" placeholder="Enter  Communication Level" />
                                            </Col>
                                            <Col lg="3" className="">
                                                <Label htmlFor="Name">
                                                    Name
                                                </Label>
                                                <Input type="text" id="txtName" className="form-control" placeholder="Enter Name" />
                                            </Col>
                                            <Col lg="3" className="">
                                                <Label htmlFor="Email">
                                                    Email
                                                </Label>
                                                <Input type="text" id="txtEmail" className="form-control" placeholder="Enter Email" />
                                            </Col>
                                            <Col lg="3" className="">
                                                <Label htmlFor="MobileNo">
                                                    Mobile No
                                                </Label>
                                                <Input type="number" id="txtMobileNo" className="form-control" placeholder="Enter Mobile No" onKeyPress={inputHandler} maxLength={16} />
                                            </Col>
                                            <Col lg="1" className="mt-3">
                                                <Button onClick={HelpDatahandleAddRow} color="primary" className="btn-sm mb-1"><i className="fas fa-plus me-2"></i>Add</Button>
                                            </Col>

                                        </Row>
                                    </div>
                                </Col>

                                <div className="co-md-12 mt-3"><a href="#" className="btn btn_success me-2" onClick={() => SaveLeadsDeails()}> Save </a>
                                    <a href="#" className="btn btn_danger me-2"> Cancel </a></div>


                            </CardBody>
                        </Card>

                    </div>

                </div>
            </div>
        </React.Fragment >
    );
};

export default Bureauhelpdata;

