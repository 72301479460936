import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label,Input,Form,Button } from "reactstrap";
import { Search } from "react-bootstrap-table2-toolkit";
import "../../../assets/scss/datatables.scss";
import bank from "assets/images/bank-nm.png";
import axios from "axios";
import { Tune } from "@mui/icons-material";
import AddNewInquiryIndividual from "../AddLead/AddNewInquiryIndividual";
import AddNewInquiryFirm from "../AddLead/AddNewInquiryFirm";

const { SearchBar } = Search;

const DatatableTables = () => {
  var [LeadType, setLeadType] = useState("Individual")

  function onChangeLeadTypeValue(event) {
    setLeadType(event.target.value)
  }  

  return (
    <React.Fragment>
      <div className="page-content">
          <div className="container-fluid">
           
           
            {/* BreadCumb And Back Button */}
            <Row className="mb-3">

              <Col className="col-lg-8 col-md-6 col-12">
                <div className="breadcrum ps-0">
                  <CardTitle className="h4">Add New Inquiry</CardTitle>
                  <a href="#" className="me-2">
                    Home
                  </a>{" "}
                  <span>/</span>
                  <span className="ms-2">Add New Inquiry</span>
                </div>
              </Col>

              <Col className="col-lg-4 co-md-6 col-12">
                <a
                  className="btn btn-primary btn-sm float-end font-14"
                  href="#"
                >
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
              </Col>

            </Row>


            {/* Navbar Content */}
            <Card>
              <CardBody>
                <Row className="">
                  <Col className="col-lg-4">
                    <ul className="nav border-0 nav-tabs" id="myTab" role="tablist" onChange={onChangeLeadTypeValue}>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link ps-0 border-0 active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                          <div className="form-check">
                            <input type="radio" id="1" name="radio1" className="form-check-input" value="success" defaultChecked />
                            <Label className="form-check-label" htmlFor="radio2" >Individual</Label>
                          </div>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link ps-0 border-0" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                          <div className="form-check">
                            <input type="radio" id="1" name="radio1" className="form-check-input" value="success" />
                            <Label className="form-check-label" htmlFor="radio2" >Firm/Company</Label>
                          </div>
                        </button>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          
            {/* Tab Content */}
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <AddNewInquiryIndividual />
              </div>
              <div className="tab-pane fade show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
              <AddNewInquiryFirm />
              </div>
            </div>
          </div>
       
      </div>
    </React.Fragment>
  );
}

export default DatatableTables;
