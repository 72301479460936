import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';

const AddServicePackage = () => {
  const history = useHistory();
  const [DocumentTypeList, setDocumentTypeList] = useState([]);
  const [DocumentList, setDocumentList] = useState([]);
  const [ServiceDocumentList, setServiceDocumentList] = useState([]);
  const [DocumentTypeId, setDocumentTypeId] = useState("0");
  const [DocumentType, setDocumentType] = useState("");
  const [DocumentId, setDocumentId] = useState("0");
  const [Document, setDocument] = useState("");

  const [ActivitiesList, setActivitiesList] = useState([]);
  const [ServiceActivitiesList, setServiceActivitiesList] = useState([]);
  const [ActivitiesId, setActivitiesId] = useState("0");
  const [ActivitiesTitle, setActivitiesTitle] = useState("");
  const [MilestoneAlias, setMilestoneAlias] = useState("");
  const [DueDays, setDueDays] = useState("");
  const [Amount, setAmount] = useState("");

  const [ServicePackageTitle, setServicePackageTitle] = useState("");
  const [ReportHeader, setReportHeader] = useState("");
  const [Alias, setAlias] = useState("");
  const [GSTPer, setGSTPer] = useState("");
  const [Description, setDescription] = useState("");
  const [AboutService, setAboutService] = useState("");


  useEffect(() => {
    getDocumentTypeList();
    getActivitiesList();
  }, []);

  const getDocumentTypeList = async () => {
    axios.get(global.APIURL + '/ActiveDocumentType')
      .then(DTres => {
        let DTList = DTres.data.map(documenytype => {
          return documenytype;
        });
        setDocumentTypeList(DTList);
      });
  };

  const getActivitiesList = async () => {
    axios.get(global.APIURL + '/ActivitiesActiveList')
      .then(Ares => {
        let AList = Ares.data.map(activities => {
          return activities;
        });
        setActivitiesList(AList);
      });
  };

  const getDocumentList = async (DocumentTypeId) => {
    var ADUrl = global.APIURL + '/DocumentTypeEdit/' + DocumentTypeId;
    axios.get(ADUrl)
      .then(DTres => {
        setDocumentTypeId(DTres.data[0].DocumentId);
        setDocumentType(DTres.data[0].DocumentType);
      });

    var AUrl = global.APIURL + '/DocumentActiveList/' + DocumentTypeId;
    axios.get(AUrl)
      .then(Dres => {
        let DList = Dres.data.map(document => {
          return document;
        });
        setDocumentList(DList);
      });
  };

  const getDocumentId = async (DocumentId) => {
    var AUrl = global.APIURL + '/DocumentEdit/' + DocumentId;
    axios.get(AUrl)
      .then(res => {
        setDocumentId(res.data[0].DocumentId);
        setDocument(res.data[0].Title);
      });
  };

  const getActivitiesId = async (ActivitiesId) => {
    var AUrl = global.APIURL + '/Activities/' + ActivitiesId;
    axios.get(AUrl)
      .then(res => {
        setActivitiesId(res.data[0].ActivitiesId);
        setActivitiesTitle(res.data[0].Title);
      });
  };

  const ActivitiesAddRow = e => {
    const ActivitiesItem = {
      RowId: (ServiceActivitiesList.length + 1),
      ActivitiesId: ActivitiesId,
      ActivitiesTitle: ActivitiesTitle,
      MilestoneAlias: MilestoneAlias,
      DueDays: 0,
      Amount: 0
    };
    setServiceActivitiesList([...ServiceActivitiesList, ActivitiesItem]);
  };

  const ActivitiesDeleteRow = (e, idx) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = idx;
      setServiceActivitiesList(
        ServiceActivitiesList.filter(item => item.RowId !== RId)
      );
    }
  };

  const DocumentAddRow = e => {
    const DocumentItem = {
      RowId: (ServiceDocumentList.length + 1),
      DocumentTypeId: DocumentTypeId,
      DocumentType: DocumentType,
      DocumentId: DocumentId,
      Document: Document,
    };
    setServiceDocumentList([...ServiceDocumentList, DocumentItem]);
  };

  const DocumentDeleteRow = (e, idx) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = idx;
      setServiceDocumentList(
        ServiceDocumentList.filter(item => item.RowId !== RId)
      );
    }
  };

  const ServicePackageSave = e => {
    var SPT = ServicePackageTitle == null ? '' : ServicePackageTitle;
    var RHT = ReportHeader == null ? '' : ReportHeader;
    var SPA = Amount == null ? '' : Amount;
    if (SPT != '') {
      if (RHT != '') {
        if (SPA != '') {
          const ServicePackageItem = {
            Title: ServicePackageTitle,
            ReportHeader: ReportHeader,
            Alias: Alias,
            DueDays: DueDays,
            Amount: Amount,
            GST: GSTPer,
            Description: Description,
            CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            AboutService:AboutService
          };
          axios.post(global.APIURL + "/ServicePackage", ServicePackageItem)
            .then(res => {
              if (res.data.length > 0) {
                var ServicePackageId = res.data[0].ServicePackageId;
                for (let act = 0; act < ServiceActivitiesList.length; act++) {
                  const ServiceActivitiesDetail = ServiceActivitiesList[act];
                  const ServiceActivitiesArr = {
                    ServicePackageDocumentId: 0,
                    ServicePackageId: ServicePackageId,
                    ActivitiesId: ServiceActivitiesDetail.ActivitiesId,
                    ActivitiesTitle: ServiceActivitiesDetail.ActivitiesTitle,
                    MilestoneAlias: ServiceActivitiesDetail.MilestoneAlias,
                    DueDays: 0,
                    Amount: 0,
                    CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                  };

                  axios.post(global.APIURL + "/ServicePackageActivitiesSave", ServiceActivitiesArr).then(res => { });
                }

                for (let sdl = 0; sdl < ServiceDocumentList.length; sdl++) {
                  const ServiceDocumentDetail = ServiceDocumentList[sdl];
                  const ServiceDocumentItemArr = {
                    ServicePackageDocumentId: 0,
                    ServicePackageId: ServicePackageId,
                    DocumentTypeId: ServiceDocumentDetail.DocumentTypeId,
                    DocumentType: ServiceDocumentDetail.DocumentType,
                    DocumentId: ServiceDocumentDetail.DocumentId,
                    Document: ServiceDocumentDetail.Document,
                    CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                  };

                  axios.post(global.APIURL + "/ServicePackageDocumentSave", ServiceDocumentItemArr).then(res => { });
                }

                alert(res.data[0].RecordStatus);
                history.push("/ServicePackage");
              }
            })
        }
        else {
          alert('Please Enter Service Package Amount.')
        }
      }
      else {
        alert('Please Enter Report Header Title.')
      }
    }
    else {
      alert('Please Enter Service Package Title.')
    }
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Service Package | KC Admin Group</title>
        </MetaTags>

        <div className="container-fluid">


          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h3">Add Service Packag</CardTitle>
                <a href="/GeneralDashboard" className="me-2">Home</a>{" "}
                <span>/</span>
                <a href="/ServicePackage" className="ms-2 me-2">Service Packag Matsere</a>{" "}
                <span>/</span>
                <span className="ms-2">Add  Service Package</span>
              </div>
            </Col>
            <Col className="col-lg-4 col-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/ServicePackage">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>


          <Card>
            <CardBody>
              <Row>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label> Title <span className="requiredvalue">*</span> </label>
                      <input type="text" className="form-control" placeholder="Enter Title" onChange={e => setServicePackageTitle(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label> Report Header <span className="requiredvalue">*</span> </label>
                      <input type="text" className="form-control" placeholder="Enter Header" onChange={e => setReportHeader(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="mb-3">
                      <label> Alias  </label>
                      <input type="text" className="form-control" placeholder="Enter Alias" onChange={e => setAlias(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="mb-3">
                      <label>Due Days</label>
                      <input type="text" className="form-control" placeholder="Enter Due Days" onChange={e => setDueDays(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="mb-3">
                      <label>Amount <span className="requiredvalue">*</span></label>
                      <input type="text" className="form-control" placeholder="Enter Amount" onChange={e => setAmount(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="mb-3">
                      <label> GST(%) </label>
                      <input type="text" className="form-control" placeholder="Enter GST" onChange={e => setGSTPer(e.target.value)} />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label>Description </label>
                      <textarea className="form-control" placeholder="Enter Description" onChange={e => setDescription(e.target.value)}></textarea>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label>About Service </label>
                      <textarea className="form-control" placeholder="Enter AboutService" onChange={e => setAboutService(e.target.value)}></textarea>
                    </div>
                  </div>
                </div>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <div className="row">
                  <Row className="align-items-center">
                    <CardTitle>Activities List</CardTitle>
                    <Col className="col-lg-3">
                      <div className="mb-3">
                        <label className="form-label">Select Activities</label>
                        <select id="ddlActivities" className="form-select" onChange={e => getActivitiesId(e.target.value)}>
                          <option value={"0"}>Select Activities</option>
                          {ActivitiesList.map(act => (
                            <option key={act.ActivitiesId} value={act.ActivitiesId}>{act.Title}</option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <div className="col-lg-3">
                      <div className="mb-3">
                        <label>Milestone Alias</label>
                        <input type="text" className="form-control" placeholder="Enter Milestone Alias" onChange={e => setMilestoneAlias(e.target.value)} />
                      </div>
                    </div>

                    <div className="col-lg-2">
                      <a type="button" className="btn btn-primary mt-2" onClick={ActivitiesAddRow}>
                        Add Activities
                      </a>
                    </div>
                    <div className="col-lg-12">
                      <div className="table-responsive">
                        <table className="table table-bordered align-middle">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Activities Name</th>
                              <th scope="col">Milestone Alias</th>
                              <th className="text-center" scope="col">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ServiceActivitiesList.map(item => (
                              <tr key={item.ActivitiesId}>
                                <th scope="row">{item.RowId}</th>
                                <td>{item.ActivitiesTitle}</td>
                                <td>{item.MilestoneAlias}</td>
                                <td className="text-center"><a className="text-danger" href="#" onClick={e => ActivitiesDeleteRow(e, item.RowId)}><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Row>
                </div>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <div className="row">
                  <Row className="align-items-center">
                    <CardTitle>Document List</CardTitle>
                    <Col className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Document Type</label>
                        <select id="ddlDocumentType" className="form-select" onChange={e => getDocumentList(e.target.value)}>
                          <option value={"0"}>Select Documet type</option>
                          {DocumentTypeList.map(doctype => (
                            <option key={doctype.DocumentId} value={doctype.DocumentId}>{doctype.DocumentType}</option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col className="col-lg-4">
                      <div className="mb-3">
                        <label className="form-label">Document</label>
                        <select id="ddlDocument" className="form-select" onChange={e => getDocumentId(e.target.value)}>
                          <option value={"0"}>Select Documet</option>
                          {DocumentList.map(doctype => (
                            <option key={doctype.DocumentId} value={doctype.DocumentId}>{doctype.Title}</option>
                          ))}
                        </select>
                      </div>
                    </Col>

                    <div className="col-lg-4">
                      <a type="button" className="btn btn-primary mt-2" onClick={DocumentAddRow}>
                        Add Document
                      </a>
                    </div>
                    <div className="col-lg-12">
                      <div className="table-responsive">
                        <table className="table table-bordered align-middle">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Document Type</th>
                              <th scope="col">Document Name</th>
                              <th className="text-center" scope="col">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ServiceDocumentList.map(item => (
                              <tr key={item.DocumentId}>
                                <th scope="row">{item.RowId}</th>
                                <td>{item.DocumentType}</td>
                                <td>{item.Document}</td>
                                <td className="text-center">
                                  <a className="text-danger" href="#" onClick={e => DocumentDeleteRow(e, item.RowId)}>
                                    <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
                                  </a>
                                </td>
                              </tr>
                            ))}

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-lg-6">
                      <button type="button" className="btn btn_success me-2" onClick={ServicePackageSave}>Save</button>
                      <a type="button" className="btn btn_danger" href="/ServicePackage/AddServicePackage">Cancel</a>
                    </div>
                  </Row>
                </div>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddServicePackage;
