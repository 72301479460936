import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";
import { MenuItem } from "@material-ui/core";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AllMenu: []
    };

  }




  componentDidMount() {
    const ol = localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId");

    fetch(global.APIURL + '/DesktopMenuAccessList/' + ol)
      .then((response) => response.json())
      .then(booksList => {
        this.setState({ AllMenu: booksList });
      });
   
    let matchingMenuItem = null;
    const ul = document.getElementById("navigation");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };

  render() {

    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">


            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >

                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none"
                      to="/dashboard"
                    >
                      <i className="bx bx-home-circle me-2" />
                      {this.props.t("Dashboard")} {this.props.menuOpen}

                    </Link>
                  </li>
                  {this.state.AllMenu.map((menuItem, i) => {
                    if (menuItem.ParentId == 0) {
                      return (
                        this.state.AllMenu.filter(x => x.ParentId == menuItem.MenuId).length== 0 ?
                        <li key={i.MenuId}>

                          <Link className="nav-link dropdown-toggle arrow-none"
                            to={menuItem.Link}
                          >
                            <i className={menuItem.Icon} />
                            {menuItem.Title} {this.props.menuOpen}
                            <div className="arrow-down"></div>
                          </Link>

                          <div className={classname("dropdown-menu", {
                            // show: this.state.isDashboard,
                          })}
                          >
                            {this.state.AllMenu.map((subMenu, i) => {
                              if (menuItem.MenuId == subMenu.ParentId) {
                                return <a className="dropdown-item" key={i} href={subMenu.Link}>{subMenu.Title}</a>
                              }

                            })}


                          </div>

                        </li>
                        :
                        <li className="nav-item dropdown" key={i.MenuId}>

                          <Link className="nav-link dropdown-toggle arrow-none"
                            to="/" onClick={ (event) => event.preventDefault() }
                          >
                            <i className={menuItem.Icon} />
                            {menuItem.Title} {this.props.menuOpen}
                            <div className="arrow-down"></div>
                          </Link>

                          <div className={classname("dropdown-menu", {
                            // show: this.state.isDashboard,
                          })}
                          >
                            {this.state.AllMenu.map((subMenu, i) => {
                              if (menuItem.MenuId == subMenu.ParentId) {
                                return <a className="dropdown-item" key={i} href={subMenu.Link}>{subMenu.Title}</a>
                              }

                            })}


                          </div>

                        </li>
                      )
                    } else {

                    }
                  })}
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Navbar));
