import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class UiVideo extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
       <div className="page-content">
          <MetaTags>
            <title>Task | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
              <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h3">Task</CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Task</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
             
            <Row>
              
              <Col className="col-12">
                <Card>
                  <CardBody>
                     {/* User list */}
                
                    
                       

                     <Row className="mt-1">
    <div className="col-lg-4 col-md-4 col-6 col">
        <div className="search-box me-2 mb-2 d-inline-block">
            <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                    <span id="search-bar-0-label" className="sr-only">Search this table</span><input id="search-bar-0" type="text" aria-labelledby="search-bar-0-label" className="form-control" placeholder="Search" value="" />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
            </div>
        </div>
    </div>
</Row>


                    <Row className="mt-3 pb-5">


                      <div className="col-md-12 position-relative"> 
                        
                          <a className="position-absolute" href="">
                            <div className="add_nw_task">
                            Add <br />
                            New
                            </div>
                          </a>
                        
                        <div className="d-flex flex-column flex-md-row ps_10 mt-2">
                          
                        <div className="mb-2 flex-md-grow-1 me-0  me-md-3 ">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Description"
                            />
                          </div>
                         
                
                         

                        
                            <div className=" me-0  me-md-3 mb-2" >
                              <select id="inputState" className="form-select">
                                <option selected>Assign To</option>
                                <option>...</option>
                              </select>
                            </div>
                           
                              <div className ="">
                                    <a className ="btn btn-primary btn-sm p-2" href="#">Save</a>
                              </div>

                        </div>

                           
                       
                         
                      </div>
                      </Row>
                     
                      <Row className="mt-3 pb-5">


                              <div className="col-md-12 position-relative">                                 
                                  <a className="position-absolute" href="">
                                    <div className="add_nw_task_light">
                                    16 <br />
                                    MAY
                                    </div>
                                  </a>
                                
                                <div className="d-flex flex-column flex-md-row ps_10 mt-2 border_left_task pb-2">                                 
                                <div className="mb-2 flex-md-grow-1 me-0  me-md-3 ">
                                    <p className="fw-bold">
                                    lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet
                                    </p>
                                  </div>                        

                                    <div className=" me-0  me-md-3 mb-2" >
                                       
                                    </div>
                                  
                                      <div className ="">
                                           
                                      </div>

                                </div>

                                  
                              
                                
                      </div>
                      

                      <div className="col-md-12 position-relative">                                 
                                  <a className="position-absolute" href="">
                                    <div className="add_nw_task_light">
                                    16 <br />
                                    30
                                    </div>
                                  </a>
                                
                                <div className="d-flex flex-column flex-md-row ps_10 mt-2 border_left_task pb-2">                                 
                                <div className="mb-2 flex-grow-1 me-0  me-md-3 ">
                                    <p className="">
                                    lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet
                                    </p>
                                  </div>                        

                                    <div className="me-0 flex-md-grow-0  me-md-3 mb-2   flex_basis300px" >
                            <i className="mdi mdi-face-woman fs-5 me-2"></i>
                            <i className="mdi mdi-face-woman fs-5 me-2"></i><i className="mdi mdi-face-woman fs-5 me-2"></i><i className="mdi mdi-face-woman fs-5 me-2"></i>
                                    </div>
                                  
                                      <div className ="flex_basis300px">
                                           <p className="text-start text-md-end mb-1 white_nowrap">
                                           Email |  4:40pm
                                           </p>
                                            <p className="text-start text-md-end mb-1 white_nowrap">
                                              By : Jhon Doe
                                            </p>
                                      </div>

                                </div>

                                  
                              
                                
                      </div>

                      <div className="col-md-12 position-relative">                                 
                                  <a className="position-absolute" href="">
                                    <div className="add_nw_task_light">
                                    12 <br />
                                    30
                                    </div>
                                  </a>
                                
                                <div className="d-flex flex-column flex-md-row ps_10 mt-2 border_left_task pb-2">                                 
                                <div className="mb-2 flex-grow-1 me-0  me-md-3 ">
                                    <p>
                                    lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet
                                    </p>
                                  </div>                        

                                    <div className=" me-0  me-md-3 mb-2  flex_basis300px" >
                            <i className="mdi mdi-face-woman fs-5 me-2"></i>
                            <i className="mdi mdi-face-woman fs-5 me-2"></i><i className="mdi mdi-face-woman fs-5 me-2"></i><i className="mdi mdi-face-woman fs-5 me-2"></i>
                                    </div>
                                  
                                      <div className ="flex_basis300px">
                                           <p className="text-start text-md-end mb-1 white_nowrap">
                                           Email |  4:40pm
                                           </p>
                                            <p className="text-start text-md-end mb-1 white_nowrap">
                                              By : Jhon Doe
                                            </p>
                                      </div>

                                </div>

                                  
                              
                                
                      </div>

                      
                      <div className="col-md-12 position-relative">                                 
                                  <a className="position-absolute" href="">
                                    <div className="add_nw_task_light">
                                    11 <br />
                                    00
                                    </div>
                                  </a>
                                
                                <div className="d-flex flex-column flex-md-row ps_10 mt-2 border_left_task pb-2">                                 
                                <div className="mb-2 flex-grow-1 me-0  me-md-3 ">
                                    <p>
                                    lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet
                                    </p>
                                  </div>                        

                                    <div className=" me-0  me-md-3 mb-2  flex_basis300px" >
                            <i className="mdi mdi-face-woman fs-5 me-2"></i>
                            <i className="mdi mdi-face-woman fs-5 me-2"></i><i className="mdi mdi-face-woman fs-5 me-2"></i><i className="mdi mdi-face-woman fs-5 me-2"></i>
                                    </div>
                                  
                                      <div className ="flex_basis300px">
                                           <p className="text-start text-md-end mb-1 white_nowrap">
                                           Email |  4:40pm
                                           </p>
                                            <p className="text-start text-md-end mb-1 white_nowrap">
                                              By : Jhon Doe
                                            </p>
                                      </div>

                                </div>
                      </div>

                      
                      <div className="col-md-12 position-relative">                                 
                                  <a className="position-absolute" href="">
                                    <div className="add_nw_task_light">
                                    10 <br />
                                    30
                                    </div>
                                  </a>
                                
                                <div className="d-flex flex-column flex-md-row ps_10 mt-2 border_left_task pb-2">                                 
                                <div className="mb-2 flex-grow-1 me-0  me-md-3 ">
                                    <p>
                                    lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet
                                    </p>
                                  </div>                        

                                    <div className=" me-0  me-md-3 mb-2  flex_basis300px" >
                            <i className="mdi mdi-face-woman fs-5 me-2"></i>
                            <i className="mdi mdi-face-woman fs-5 me-2"></i><i className="mdi mdi-face-woman fs-5 me-2"></i><i className="mdi mdi-face-woman fs-5 me-2"></i>
                                    </div>
                                  
                                      <div className ="flex_basis300px">
                                           <p className="text-start text-md-end mb-1 white_nowrap">
                                           Email |  4:40pm
                                           </p>
                                            <p className="text-start text-md-end mb-1 white_nowrap">
                                              By : Jhon Doe
                                            </p>
                                      </div>

                                </div>
                      </div>

                      <div className="col-md-12 position-relative">                                 
                                  <a className="position-absolute" href="">
                                    <div className="add_nw_task_light">
                                    8 <br />
                                    45
                                    </div>
                                  </a>
                                
                                <div className="d-flex flex-column flex-md-row ps_10 mt-2 border_left_task pb-2">                                 
                                <div className="mb-2 flex-grow-1 me-0  me-md-3 ">
                                    <p>
                                    lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit amet, lorem ipsum dolor sit amet, lorem ipsum dolor sit amet  lorem ipsum dolor sit amet lorem ipsum dolor sit amet
                                    </p>
                                  </div>                        

                                    <div className=" me-0  me-md-3 mb-2  flex_basis300px" >
                            <i className="mdi mdi-face-woman fs-5 me-2"></i>
                            <i className="mdi mdi-face-woman fs-5 me-2"></i><i className="mdi mdi-face-woman fs-5 me-2"></i><i className="mdi mdi-face-woman fs-5 me-2"></i>
                                    </div>
                                  
                                      <div className ="flex_basis300px">
                                           <p className="text-start text-md-end mb-1 white_nowrap">
                                           Email |  4:40pm
                                           </p>
                                            <p className="text-start text-md-end mb-1 white_nowrap">
                                              By : Jhon Doe
                                            </p>
                                      </div>

                                </div>     
                      </div>





                        </Row>




                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default UiVideo
