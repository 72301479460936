import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';

const EditCity = () => {
  const [CountryId, setCountryId] = useState('');
  const [CountryTitle, setCountryTitle] = useState('');
  const [StateId, setStateId] = useState('');
  const [StateTitle, setStateTitle] = useState('');
  const [DistrictId, setDistrictId] = useState('');
  const [DistrictTitle, setDistrictTitle] = useState('');
  const [TehsilId, setTehsilId] = useState('');
  const [TehsilTitle, setTehsilTitle] = useState('');
  const [CityId, setCityId] = useState('');
  const [Title, setTitle] = useState('');
  const history = useHistory();
  let { id } = useParams();

  useEffect(() => {
    if (id != null) {
      if (id != "0") {
        let newid = id.split(':');
        let VCountryId = localStorage.getItem("CountryId");
        let VCountryTitle = localStorage.getItem("CountryTitle");
        let VStateStateId = localStorage.getItem("DistrictStateId");
        let VStateTitle = localStorage.getItem("DistrictStateTitle");
        let VDistrictId = localStorage.getItem("TehsilDistrictId");
        let VDistrictTitle = localStorage.getItem("TehsilDistrictTitle");
        let VTehsilId = localStorage.getItem("CityTehsilId");
        let VTehsilTitle = localStorage.getItem("CityTehsilTitle");
        setCountryId(VCountryId);
        setCountryTitle(VCountryTitle);
        setStateId(VStateStateId);
        setStateTitle(VStateTitle);
        setDistrictId(VDistrictId);
        setDistrictTitle(VDistrictTitle);
        setTehsilId(VTehsilId);
        setTehsilTitle(VTehsilTitle);
        getStateById(newid[1]);
      }
      else {
        history.push("/State");
      }
    }
    else {
      history.push("/State");
    }
  }, []);

  const CityUpdate = async (e) => {
    e.preventDefault();
    let CityTitle = event.target["txtCity"].value;
    if (CityTitle != '') {
      const CityArr = {
        CountryId: CountryId,
        StateId: StateId,
        DistrictId: DistrictId,
        TehsilId: TehsilId,
        CityId: CityId,
        Title: CityTitle,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      }

      axios.post(global.APIURL + '/CityUpdate', CityArr)
        .then(res => {
          alert(res.data.StatusMessage);
          history.push("/City");
        });
    }
  }

  const getStateById = async (CId) => {
    setCityId(CId)
    var AUrl = global.APIURL + '/CityEdit/' + CId;
    axios.get(AUrl)
      .then(res => {
        setTitle(res.data[0].Title);
        setCityId(res.data[0].CityId)
      })
  }

  return (
    <div className="page-content">

      <MetaTags>
        <title>Edit City | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Edit City</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <a href="/Country" className="ms-2 me-2">{CountryTitle}</a>
              <span>/</span>
              <a href="/State" className="ms-2 me-2">{StateTitle}</a>
              <span>/</span>
              <a href="/District" className="ms-2 me-2">{DistrictTitle}</a>
              <span>/</span>
              <a href="/Tehsil" className="ms-2 me-2">{TehsilTitle}</a>
              <span>/</span>
              <a href="/City"><span className="ms-2 me-2">City List</span></a>
              <span>/</span>
              <span className="ms-2">Edit City</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-6">
            <a className="btn btn-primary btn-sm float-end font-14" href="/City">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <form onSubmit={CityUpdate}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">City</label>
                        <Input type="text" className="" id="txtCity" defaultValue={Title} placeholder="Enter City" />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <input type="hidden" id="hdfCityId" value={CityId} />
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/City">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  )
}

export default EditCity;