import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label,
  Input,
} from "reactstrap"

import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import classnames from "classnames"

class UiTabsAccordions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col5: true,
      col6: true,
      col7: true,
      col8: true,
      col9: true,
      col10: false,
      col11: false,
    }
    this.toggle = this.toggle.bind(this)
    this.toggle1 = this.toggle1.bind(this)

    this.t_col1 = this.t_col1.bind(this)
    this.t_col2 = this.t_col2.bind(this)
    this.t_col3 = this.t_col3.bind(this)
    this.t_col5 = this.t_col5.bind(this)
    this.t_col6 = this.t_col6.bind(this)
    this.t_col7 = this.t_col7.bind(this)
    this.t_col8 = this.t_col8.bind(this)
    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)

    this.toggle2 = this.toggle2.bind(this)
    this.toggle3 = this.toggle3.bind(this)

    this.toggleVertical = this.toggleVertical.bind(this)
    this.toggleVerticalIcon = this.toggleVerticalIcon.bind(this)
    this.toggleCustom = this.toggleCustom.bind(this)
    this.toggleIconCustom = this.toggleIconCustom.bind(this)
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false
    })
  }

  t_col2() {
    this.setState({
      col1: false,
      col2: !this.state.col2,
      col3: false
    })
  }

  t_col3() {
    this.setState({
      col1: false,
      col2: false,
      col3: !this.state.col3
    })
  }

  t_col5() {
    this.setState({ col5: !this.state.col5 })
  }

  t_col6() {
    this.setState({ col6: !this.state.col6 })
  }

  t_col7() {
    this.setState({ col7: !this.state.col7 })
  }

  t_col8() {
    this.setState({
      col6: !this.state.col6,
      col7: !this.state.col7
    })
  }

  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false
    })
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false
    })
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      })
    }
  }

  toggle2(tab) {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      })
    }
  }

  toggle3(tab) {
    if (this.state.activeTab3 !== tab) {
      this.setState({
        activeTab3: tab,
      })
    }
  }

  toggleVertical(tab) {
    if (this.state.verticalActiveTab !== tab) {
      this.setState({
        verticalActiveTab: tab,
      })
    }
  }

  toggleVerticalIcon(tab) {
    if (this.state.verticalActiveTabWithIcon !== tab) {
      this.setState({
        verticalActiveTabWithIcon: tab,
      })
    }
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      })
    }
  }

  toggleIconCustom(tab) {
    if (this.state.customIconActiveTab !== tab) {
      this.setState({
        customIconActiveTab: tab,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
          <title>View Client | KC Admin App</title>
          </MetaTags>
          <Container fluid={true}>
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h3"> View Client</CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2"> View Client</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>

            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <p className="card-title-desc">
                    <div className="row mb-4">
                        <Col className="col-lg-6">
                       <div className="d-flex">
                       <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-form-label "
                        >
                         Client ID#
                        </Label>
                          <p className="mt-2 ms-3">IDXXXXXXXXXXXX</p>
                       </div>
                          </Col>
                        {/* <Col sm={2} className="ms-auto">
                        <Input
                            type="date"
                            className="form-control "
                            id="horizontal-firstname-Input"
                            placeholder=""
                          />
                        </Col> */}
                      </div>
                    </p>

                    <Nav tabs className="nav-tabs-custom nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "1",
                          })}
                          onClick={() => {
                            this.toggleCustom("1")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                          <span className="d-none d-sm-block">Personal Information</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "2",
                          })}
                          onClick={() => {
                            this.toggleCustom("2")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="fas fa-address-book"></i></span>
                          <span className="d-none d-sm-block">Contact Information</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "3",
                          })}
                          onClick={() => {
                            this.toggleCustom("3")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="fas fa-folder"></i></span>
                          <span className="d-none d-sm-block">KYC</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "4",
                          })}
                          onClick={() => {
                            this.toggleCustom("4")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="mdi mdi-bank"></i></span>
                          <span className="d-none d-sm-block">Banking Details</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "5",
                          })}
                          onClick={() => {
                            this.toggleCustom("5")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="mdi mdi-calendar"></i></span>
                          <span className="d-none d-sm-block">Followups</span>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.customActiveTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                          <div className ="d-flex logo-main float-end mb-3">
                              <div className="logo-box">
                                  <p className="mb-0 text-center">User <br /> Photo</p>
                              </div>
                                
                        </div>
                          </Col>

                          <Col lg={3}>
                          <div className="mb-3 p-2 border border-1">
                              <small> First Name </small>
                              <p className="mb-1"> Heena  </p>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3 p-2 border border-1">
                              <small> Middle Name </small>
                              <p className="mb-1"> Bharat kumar  </p>
                          </div>
                        </Col>
                        <Col lg={3}>
                        <div className="mb-3 p-2 border border-1">
                              <small> Last Name </small>
                              <p className="mb-1"> Patel  </p>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3 p-2 border border-1">
                          <small> Gender </small>
                              <p className="mb-1"> Female  </p>
                          </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small> Material Status </small>
                              <p className="mb-1"> Married  </p>
                          </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small> Spouse Name </small>
                              <p className="mb-1"> Vivek Patel  </p>
                          </div>
                        </Col>
                          <Col lg={3}>
                          
                          <div className="mb-3 p-2 border border-1">
                        <small> Wife of </small>
                              <p className="mb-1"> Vivek Patel  </p>
                          </div>
                        </Col>
                        
                        <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small> Qualification </small>
                              <p className="mb-1"> B.com </p>
                          </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small> Category </small>
                              <p className="mb-1"> Category </p>
                          </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small> Religion </small>
                              <p className="mb-1"> Hindu </p>
                           
                          </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small> Income(Per Month) </small>
                              <p className="mb-1"> 1,23,976/- </p>
                           
                          </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small> Occupation </small>
                              <p className="mb-1"> Business </p>
                            
                          </div>
                        </Col>
                          <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small> CIR ID </small>
                              <p className="mb-1"> IDXXXXXXXX </p>
                            
                          </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small> CIR Password </small>
                              <p className="mb-1"> ******* </p>
                            
                          </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small> Source </small>
                              <p className="mb-1"> Source </p>
                          </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small>Sub Source</small>
                              <p className="mb-1"> Sub Source </p>
                          </div>
                        </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="4">
                            <p className="mt-2 fw-normal">Mobile No. </p>
                            <div className="table-responsive">
               <table className="table table-borderless">
                      {/* <thead>
                        <tr className="">
                          <th scope="col">Sr</th>
                          <th scope="col" className="">Deduction Type</th>
                          <th scope="col" className="">Start Month/Year</th>
                          <th scope="col" className="">End Month/Year</th>
                          <th scope="col" className="">No of Installments</th>
                          <th scope="col" className="">Installment Amount</th>
                          <th scope="col" className="">Total Deduction</th>
                          <th scope="col" className="">Action</th>
                        </tr>
                      </thead> */}
                      <tbody>
                        <tr>
                          <td className="ps-0">+91 9878543212</td>
                          <td>
                            
                          </td>
                        </tr>
                        
                      </tbody>
                  </table>
               </div>
                          </Col>
                          <Col sm="6" className="offset-lg-2">
                          
                            <div className="table-responsive">
               <table className="table table-borderless">
                      <thead>
                        <tr className="">
                          <th scope="col" className="fw-normal">Email Address  </th>
                          <th scope="col" className=""></th>
                          <th scope="col" className=""></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="">name@gmail.com</td>
                          <td className=""> </td>
                          <td>
                             
                          </td>
                        </tr>
                         
                      </tbody>
                  </table>
               </div>
                          </Col>
                        </Row>
                      </TabPane>


                      <TabPane tabId="3">
                        <Row>
                          <Col lg={12}>
                          <div className="mb-3 p-2 border border-1">
                            <small> Remarks </small>
                              <p className="mb-1"> lorem Ipsum dolor sit amet. lorem Ipsum dolor sit amet. lorem Ipsum dolor sit amet. lorem Ipsum dolor sit amet. lorem Ipsum dolor sit amet. lorem Ipsum dolor sit amet. lorem Ipsum dolor sit amet. lorem Ipsum dolor sit amet.  </p>
                          </div>                         
                          </Col>
               
                        </Row>
                        <Row>
                        <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small>Village/Town/City </small>
                              <p className="mb-1"> Village/Town/City</p>
                          </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small>Tehsil </small>
                              <p className="mb-1"> Tehsil</p>
                           
                          </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small>District </small>
                              <p className="mb-1"> Ahmedabad</p>
                            
                          </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small>State </small>
                              <p className="mb-1"> Gujarat</p>
                          </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small>Country </small>
                              <p className="mb-1"> India </p>
                           
                          </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mb-3 p-2 border border-1">
                            <small>Pincode</small>
                              <p className="mb-1"> 2356415</p>
                            
                          </div>
                        </Col>
                        </Row>
                        <Row>
                        <div className="h4 card-title mt-4">Documents</div>
                        <Col sm="12">
                            <div className="table-responsive">
                              <table className="table table-borderless">
                                      <thead className="">
                                        <tr className="lead-table-th">
                                          <th scope="col" className="">Type</th>
                                          <th scope="col" className="">No ( Uniq ID)</th>
                                          <th scope="col" className="">Upload</th>
                                           
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Passport</td>
                                          <td>L1L2356L8L9</td>
                                          <td>
                                            <div className="d-flex lead-box-1">
                                              <div className="ms-1 mt-1 mb-1">
                                              <div className="logo-box p-4"><p className="mb-0">Image</p></div>
                                              </div>
                                              <div className="ms-3">
                                                <p className="mb-1">lorem ipsum doler sit amet</p>
                                                <p className="mb-0">18KB</p>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                           
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><div className="mb-3 w-90">
                            <select id="inputState" className="form-select">
                              <option selected>Type</option>
                              <option>...</option>
                            </select>
                          </div></td>
                                          <td>UID</td>
                                          <td>
                                            <div className="lead-box-1 p-4">
                                              <div className="text-center">
                                                <p className="mb-1">Upload</p>
                                              </div>
                                            </div>
                                          </td>
                                           
                                        </tr>
                                      </tbody>
                                  </table>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row>
                          <Col sm="12">
                            <div className="table-responsive">
                              <table className="table table-borderless border-bottom">
                                      <thead>
                                        <tr className="">
                                          <th scope="col" className="fw-normal">Bank</th>
                                          <th scope="col" className="fw-normal">IFSC Code</th>
                                          <th scope="col" className="fw-normal">Branch</th>
                                          <th scope="col" className="fw-normal">A/c No</th>
                                           
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Bank Name</td>
                                          <td>894743217854326</td>
                                          <td>Branch Name</td>
                                          <td>123456987123</td>
                                           
                                        </tr>
                                      </tbody>
                                  </table>

                                  <table className="table table-borderless border-bottom">
                                      <thead>
                                        <tr className="">
                                          <th scope="col" className="fw-normal">Fees</th>
                                          <th scope="col" className="fw-normal">Pending Fees</th>
                                          <th scope="col" className="fw-normal">Received Fees</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>9,99,999</td>
                                          <td>5,00,000</td>
                                          <td>4,99,999</td>
                                        </tr>
                                      </tbody>
                                  </table>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="5">
                        <Row>
                          
                          <Col sm={12}>
                         
                            <div className="followup-box">
                              <div className="h5 mb-3">
                                Next Schedule Refollow-up
                              </div>
                              
                               
                            </div>
                            <Row className="justify-content-center align-items-center border-bottom pb-3">
                                <Col sm={1}>
                                <div className="date-box p-3 text-center bg-light fw-bold border">
                                      <p className="mb-0">30</p>
                                      <p className="mb-0">Apr</p>
                                  </div>
                                </Col>
                                <Col sm={8}>
                                <div className="ms-2">
                                    <p className="mb-0">Remark: Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                  </div>
                                </Col>
                                <Col sm={2} className="offset-lg-1">
                                <div className="text-end">
                                    <p className="mb-0">Email 4:40pm</p>
                                    <p className="mb-0">By:Jhon Doe</p>
                                </div>
                                </Col>
                              </Row>
                              <div className="followup-box pt-3">
                                <div className="h5 mb-3">
                                Lead Activity
                                </div>
                              
                                 
                              </div>
                              <Row className="justify-content-center align-items-center pb-3">
                                <Col sm={1}>
                                <div className="date-box p-3 text-center bg-light fw-bold border">
                                      <p className="mb-0">15</p>
                                      <p className="mb-0">Apr</p>
                                  </div>
                                </Col>
                                <Col sm={8}>
                                <div className="ms-2">
                                    <p className="mb-0">Remark: Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                  </div>
                                </Col>
                                <Col sm={2} className="offset-lg-1">
                                <div className="text-end">
                                    <p className="mb-0">Email 4:40pm</p>
                                    <p className="mb-0">By:Jhon Doe</p>
                                </div>
                                </Col>
                              </Row>
                              <Row className="justify-content-center align-items-center pb-3">
                                <Col sm={1}>
                                <div className="date-box p-3 text-center bg-light fw-bold border">
                                      <p className="mb-0">16</p>
                                      <p className="mb-0">Apr</p>
                                  </div>
                                </Col>
                                <Col sm={8}>
                                <div className="ms-2">
                                    <p className="mb-0">Remark: Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                  </div>
                                </Col>
                                <Col sm={2} className="offset-lg-1">
                                <div className="text-end">
                                    <p className="mb-0">Email 4:40pm</p>
                                    <p className="mb-0">By:Jhon Doe</p>
                                </div>
                                </Col>
                              </Row>
                              <Row className="justify-content-center align-items-center pb-3">
                                <Col sm={1}>
                                <div className="date-box p-3 text-center bg-light fw-bold border">
                                      <p className="mb-0">30</p>
                                      <p className="mb-0">Apr</p>
                                  </div>
                                </Col>
                                <Col sm={8}>
                                <div className="ms-2">
                                    <p className="mb-0">Remark: Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                  </div>
                                </Col>
                                <Col sm={2} className="offset-lg-1">
                                <div className="text-end">
                                    <p className="mb-0">Email 4:40pm</p>
                                    <p className="mb-0">By:Jhon Doe</p>
                                </div>
                                </Col>
                              </Row>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default UiTabsAccordions
