import React,{ useState, useEffect } from 'react'

import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory,useParams  } from 'react-router-dom';
import Cookies from 'universal-cookie';
import {Row,Col,Card,CardBody,CardTitle,Label,FormGroup,InputGroup} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
const cookies = new Cookies();
 
const EditShiftMaster = () => { 
      const [INTime, setIntime] = useState('');
      const [OUTTime, setOuttime] = useState('');
      //const [ProfessionalTaxAmout, setDescription] = useState('');
      const [SId, setId] = useState('');
      const history = useHistory();

      const updateProduct  = async (e) => {
        e.preventDefault();
        let ETINTime=event.target["txtInTime"].value; 
        let ETOUTTime=event.target["txtOutTime"].value; 
        let ETSId=event.target["hdfSId"].value; 
        if(ETINTime!='')
        {
            const Shiftmaster = {
                SId:ETSId==''?'0':ETSId,
                INTime: ETINTime,
                OUTTime: ETOUTTime,
                Flag:"A"       
            }

            axios.post(global.APIURL+'/ShiftUpdate', Shiftmaster)
            .then(res => 
            {
                    alert(res.data);
                    history.push("/Shift");
            });
        }
    }

    useEffect(() => {
        let UTId=cookies.get('SId')==null?"0":cookies.get('SId')==""?"0":cookies.get('SId'); 
        if(UTId!=null)
        {
          if(UTId!="0")
          {
            getUserTyprById();
          }
          else{
            history.push("/ShiftMaster");
          }
        }
        else{
          history.push("/ShiftMaster");
        }
    }, []);
 
    const getUserTyprById = async () => {
        let SId=cookies.get('SId')==null?"0":cookies.get('SId')==""?"0":cookies.get('SId'); 
        var AUrl=global.APIURL+'/Shift/'+SId;
        axios.get(AUrl)  
        .then(res => {
        
           setId(res.data[0].SId)
           setIntime(res.data[0].INTime);
           setOuttime(res.data[0].OUTTime);
        })
    }

    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Shift | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Edit Shift</CardTitle>
                 <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/ShiftMaster"><span className="ms-2 me-2">Shift</span></a>
                <span>/</span>
                <span className="ms-2">Edit Shift</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/ShiftMaster">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={updateProduct}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">In Time</label>
                        {/* <Input type="text" className="form-control" id="txtSalaryStartRange" placeholder="Enter Start Range"/> */
}
<FormGroup className="mb-3">
                        <Label>In Time</Label>

                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Select time"
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "H:i",
                               id:"txtInTime"
                            }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-clock-outline" />
                            </span>
                          </div>
                        </InputGroup>
                      </FormGroup>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Out Time</label>
                        {/* <Input type="text" className="form-control" id="txtSalaryEndRange" placeholder="Enter End Range"/> */}
                        <FormGroup className="mb-3">
                        <Label>Out Time</Label>

                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Select time"
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              id : "txtOutTime",
                              dateFormat: "H:i"
                            }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-clock-outline" />
                            </span>
                          </div>
                        </InputGroup>
                      </FormGroup>
                      </div>

                    </Col>
                   
                    <Col lg={12}>
                    <input type="hidden" id="hdfSId" value={SId} /> 
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/ShiftMaster">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
      </div>
    )
}

export default EditShiftMaster;