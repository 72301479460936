import React, { Component } from "react"
import MetaTags from 'react-meta-tags';

import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    Input,
    Label,
} from "reactstrap"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class UiBreadcrumb extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
          <MetaTags>
            <title>Company Leave Master | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-5">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Company Leave Master</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
          
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
              <Col className="col-12">
                <div className ="d-flex align-items-center justify-content-between">
                 <div className="d-flex">
                  <CardTitle className="h6 mb-2">Company Leave Master</CardTitle>
                 </div>
                </div>
              
              </Col>
            <Row>
              
              <Col className="col-12 mt-4">
                <Card>
                  <CardBody>
                     {/* User list */}
                     <Row>
                     <Col lg={4}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Company Name</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                     </Row>
                     <Row>
                       <Col lg={6}>
                         <p>Leave Credit Chart:</p>
                       </Col>
                     </Row>
                      <Row>
                        <Col className=" col-md-4 col-12 mt-3">
                <div className ="d-flex align-items-center
                 justify-content-between">
                   
                      
                  <div className ="d-flex">
                  <div  className="form-check">
                                          <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                    <a className ="btn btn-primary btn-sm" href="/">Save</a>
                    <a className ="btn btn-primary btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col lg={12}>
                        <div className="table-responsive">
               <table className="table table-striped">
                      <thead>
                        <tr className="">
                          <th scope="col">Sr</th>
                          <th scope="col" className="">Leave Type</th>
                          <th scope="col" className="">Credit Type</th>
                          <th scope="col" className="">Carry Forward</th>
                          <th scope="col" className="">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="">1</th>
                          <td>Casual Leave CL</td>
                          <td>Monthly</td>
                          <td>Yes</td>
                          <td className="width-9">
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil h4 me-3"></i></a>
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-check-box-outline h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="">1</th>
                          <td>Sick Leave SL</td>
                          <td>Yearly</td>
                          <td>No</td>
                          <td className="width-9">
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil h4 me-3"></i></a>
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-check-box-outline h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                  </table>
               </div>
                        </Col>
                      </Row>
                     
                               <nav className="mt-3" aria-label="Page navigation example">
  <ul className="pagination justify-content-center">
    <li className="page-item disabled">
      <a className="page-link" href="#" tabIndex ="-1" aria-disabled="true">Previous</a>
    </li>
    <li className="page-item"><a className="page-link" href="#">1</a></li>
    <li className="page-item"><a className="page-link" href="#">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item">
      <a className="page-link" href="#">Next</a>
    </li>
  </ul>
</nav>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
            </React.Fragment>
        )
    }
}

export default UiBreadcrumb
