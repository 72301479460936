import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
const cookies = new Cookies();

const EC = () => {
    const [CountryList, setCountryList] = useState([]);
    const [countryId, setcountryId] = useState("");
    const [StateList, setStateList] = useState([]);
    const [StateId, setStateId] = useState("");
    const [CityList, setCityList] = useState([]);
    const [CityId, setCityId] = useState("");
    const [CompanyName, setCompanyName] = useState("");
    const [Address, setAddress] = useState("");
    const [Contact, setContact] = useState("");
    const [AlternetContactNumber, setAlternetContactNumber] = useState("");
    const [EmailAddress, setEmailAddress] = useState("");
    const [Pincode, setPincode] = useState("");
    const [CompanyPANNo, setCompanyPANNo] = useState("");
    const [CompanyGSTNo, setCompanyGSTNo] = useState("");
    const [CompanyId, setCompanyId] = useState('');
    const history = useHistory();

    let { id } = useParams();

 

    useEffect(() => {



        if (id != null) {
            if (id != "0") {
                let newid = id.split(':');


                getCompanyById(newid[1]);
                getCountryList();
                getStateList('1');
                getCityList('1');
            }
            else {
                history.push("/Company");
            }
        }
        else {
            history.push("/Company");
        }
    }, []);

    const getCompanyById = async (UTId) => {
      var AUrl=global.APIURL+'/CompanyEdit/'+UTId;
        axios.get(AUrl)
            .then(res => {

                setCompanyName(res.data[0].CompanyName);
                setCompanyPANNo(res.data[0].CompanyPANNo);
                setCompanyGSTNo(res.data[0].CompanyGSTNo);
                setAddress(res.data[0].AddressLine1);
                setContact(res.data[0].ContactNumber);
                setAlternetContactNumber(res.data[0].AlternetContactNumber);
                setEmailAddress(res.data[0].EmailAddress);
                setcountryId(res.data[0].CountryId);
                setStateId(res.data[0].StateId);
                setCityId(res.data[0].CityId);
                setPincode(res.data[0].Pincode);
                setCompanyId(res.data[0].CompanyId)
            })
    }
    const getCountryList = async () => {

        axios.get(global.APIURL+'/CountryALL')
            .then(Tres => {
                let ActiveTag = Tres.data.map((kctag) => {
                    return kctag
                })
                setCountryList(ActiveTag);

            })
    }
    const getStateList = async (Id) => {
      var AUrl=global.APIURL+'/ActiveState/'+Id;
        axios.get(AUrl)
            .then(Tres => {
                let ActiveTag = Tres.data.map((kctag) => {
                    return kctag
                })
                setStateList(ActiveTag);

            })
    }
    const getCityList = async (StateId) => {
      var AUrl=global.APIURL+'/ActiveCity/'+StateId;
        axios.get(AUrl)
            .then(Tres => {
                let ActiveTag = Tres.data.map((kctag) => {
                    return kctag
                })
                setCityList(ActiveTag);

            })
    }
    function submitForms() {

        submitFormDetails();
    }
    function submitFormDetails() {

        var docdata = {
            "CompanyId": 0,
            "CompanyName": CompanyName,
            "CompanyImage": "",
            "CompanyPANNo": CompanyPANNo,
            "CompanyGSTNo": CompanyGSTNo,
            "AddressLine1": Address,
            "AddressLine2": "",
            "ContactNumber": Contact,
            "AlternetContactNumber": AlternetContactNumber,
            "EmailAddress": EmailAddress,
            "AlternetEmailAddress": "",
            "CountryId": countryId,
            "StateId": StateId,
            "CityId": CityId,
            "DistrictId": "0",
            "TehsilId": "0",
            "Pincode": Pincode,
            "IsExisitingSalaryScale": "No",
            "CreateUser": localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),

        }

        axios.post(global.APIURL+"/CompanyUpdate", docdata)
            .then(res => {
                    alert(res.data.StatusMessage);
                    history.push("/Company");
                
            });

    }


    return (
        <div className="page-content">

            <MetaTags>
                <title>Company | KC Admin Group</title>
            </MetaTags>

            <div className="container-fluid">

                <Row className="mb-3">
                    <Col className="col-lg-8 col-md-6 col-6">
                        <div className="breadcrum ps-0">
                            <CardTitle className="h4">Edit Company</CardTitle>
                            <a href="/" className="me-2">Home</a>
                            <span>/</span>
                            <a href="/Company"><span className="ms-2 me-2">Company</span></a>
                            <span>/</span>
                            <span className="ms-2">Edit Company</span>
                        </div>
                    </Col>
                    <Col className="col-lg-4 co-md-6 col-6">
                        <a className="btn btn-primary btn-sm float-end font-14" href="/Company">
                            <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                        </a>
                    </Col>
                </Row>
               
                <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Company Name</label>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Title"
                          id="txtCompanyName"
                          value={CompanyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </div>
                    </Col>
                   
                    <Col lg={4}>
                      <label className="form-label">Address</label>
                      <textarea
                        className="form-control"
                        placeholder="Address"
                        id="Remarks"
                        rows="3"
                        name="txtAddress"
                        value={Address}
                        onChange={(e) => setAddress(e.target.value)}
                      ></textarea>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Contact</label>
                        <Input type="text" className="form-control" id="txtContact"
                         value={Contact}
                         onChange={(e) => setContact(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Alternet Contact</label>
                        <Input type="text" className="form-control" id="txtAlternetContactNumber"
                         value={AlternetContactNumber}
                         onChange={(e) => setAlternetContactNumber(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Email</label>
                        <Input type="text" className="form-control" id="txtEmail"
                           value={EmailAddress}
                           onChange={(e) => setEmailAddress(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">CompanyPANNo</label>
                        <Input type="text" className="form-control" id="txtCompanyPANNosd"
                           value={CompanyPANNo}
                           onChange={(e) => setCompanyPANNo(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">CompanyGSTNo</label>
                        <Input type="text" className="form-control" id="txtCompanyGSTNosd"
                        value={CompanyGSTNo}
                        onChange={(e) => setCompanyGSTNo(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                    <label className="form-label">Country</label>
                    <select className="form-select" name="ddlTags"  onChange={(e) => setcountryId(e.target.value)}>
                       
                          {CountryList.map((item) => (
                            <option key={item.Title} value={item.CountryId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                    </Col>
                    <Col lg={4}>
                    <label className="form-label">State</label>
                    <select className="form-select" name="ddlTags"  onChange={(e) => setStateId(e.target.value)}>
                   
                          {StateList.map((item) => (
                            <option key={item.Title} value={item.StateId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                    </Col>
                    <Col lg={4}>
                    <label className="form-label">City</label>
                    <select className="form-select" name="ddlTags"  onChange={(e) => setCityId(e.target.value)}>
                 
                          {CityList.map((item) => (
                            <option key={item.Title} value={item.CityId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Pincode</label>
                        <Input type="text" className="form-control" id="txtPincode2"
                        value={Pincode}
                        onChange={(e) => setPincode(e.target.value)}
                        />
                      </div>
                    </Col>
                  
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" onClick={submitForms} >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                    </Col>
                  </Row>
            </div>
        </div>
    )
}

export default EC;