import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Label } from "reactstrap"
import MetaTags from 'react-meta-tags'
import { Link } from 'react-router-dom';
import axios from 'axios'


import ReactApexChart from "react-apexcharts"
const DashboardLoad = (props) => {


const data=props;


var Object1=new Object;
Object1.counter=data[0];
Object1.chart=data[1];
Object1.list=data[2];


 

return (
  
    <div></div>

      

   
  );

}
export default DashboardLoad;

 
