import React, { Fragment,useState, useEffect }  from "react"
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
 
const AddBureauSubcategory = () => { 
    const history = useHistory();
    const [bureauList, setbureauList] = useState([]);
    const [bureauId, setbureauId] = useState("0");

    useEffect(() => {
        getbureauList();
     
      });

      const getbureauList = async () => {
        axios.get(global.APIURL+'/BureauCategory')
          .then(DTres => {
            let DTList = DTres.data.map(documenytype => {
              return documenytype;
            });
            setbureauList(DTList);
          });      
      };
    


    const BureaucategorySave = async (e) => {
        e.preventDefault();
        let VTitle=event.target["txtTitle"].value; 
        let VDescription=event.target["txtDescription"].value;
       
            const Bureaucategory = {
                BsubId:'0',
                BId : bureauId,
                Title: VTitle,
                Description: VDescription,
                Flag:"A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
                    
            }
           
            axios.post(global.APIURL+'/bureauSubcategoryInsert', Bureaucategory)
            .then(res => 
            {
                    alert("Insert SuccessFully");
                    history.push("/BureauSubcategoryList");
            });
        
    }
    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Add Bureau subcategory | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-12">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Bureau subcategory </CardTitle>
                 <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/BureauSubcategoryList" className="ms-2 me-2"> Bureau subcategory </a>
                <span>/</span>
                <span className="ms-2">Add  Bureau subcategory </span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-12">
              <a className="btn btn-primary btn-sm float-end font-14" href="/BureauSubcategoryList">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={BureaucategorySave}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
                  <Row>
                  <Col lg={4}>
                          <div className="mb-3">
                                <label className="form-label">Bureau</label>
                                  <select id="ddlbureau" className="form-select" onChange={e => setbureauId(e.target.value)}>
                                    <option value={"0"}>Select Bureau</option>
                                    {bureauList.map(b => (
                                      <option key={b.Id} value={b.Id}>{b.value}</option>
                                    ))}
                                  </select>
                                </div>
                          </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Title</label>
                        <Input type="text" className="form-control" id="txtTitle" placeholder="Enter Title"/>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-2">
                        <label className="form-label">Description</label>
                        <Input type="textarea" className="form-control" id="txtDescription" placeholder="Enter Description"/>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/AddBureauSubcategory">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
       
      </div>
    )
}

export default AddBureauSubcategory;