import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle,Dropdown,DropdownItem,DropdownToggle,DropdownMenu, Modal, Label,
  Input, } from "reactstrap"
import MetaTags from 'react-meta-tags'


// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


import "../../../assets/scss/datatables.scss"

// Table data
const products = [

 
  {
    "sr": 1,
    "name": "Jay",
    "description": "Remark description details goes here is simply",
    "date": "06/01/2021",
    "followup by":  <><img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads rounded-circle" /><img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads rounded-circle" /><img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads rounded-circle" /></>,
    "action": <>  <a href="#" className="table-link-a" data-bs-toggle="modal" data-bs-target="#view_followup" >
                    <i className="mdi mdi-eye me-3 font-size-18" ></i>
                  </a>
                  <a href="#" className="table-link-a text-success" data-bs-toggle="modal" data-bs-target="#Edit_followup" > <i className="mdi mdi-pencil me-3 font-size-18"></i> </a>
                  <a href="#" className="table-link-a text-danger"> <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i> </a></>
  },
  {
    "sr": 2,
    "name": "Raj",
    "description": "Remark description details goes here is simply Remark description details goes here is simply",
    "date": "14/01/2021",
    "followup by":  <><img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads rounded-circle" /><img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads rounded-circle" /><img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads rounded-circle" /></>,
    "action": <>  <a href="#" className="table-link-a" data-bs-toggle="modal" data-bs-target="#view_followup" >
                    <i className="mdi mdi-eye me-3 font-size-18" ></i>
                  </a>
                  <a href="#" className="table-link-a text-success" data-bs-toggle="modal" data-bs-target="#Edit_followup" > <i className="mdi mdi-pencil me-3 font-size-18"></i> </a>
                  <a href="#" className="table-link-a text-danger"> <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i> </a></>
  },

];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    const columns = [{
      dataField: 'sr',
      text: 'Sr',
      sort: true,
    }, {
      dataField: 'name',
      text: 'Name',
      sort: true
    }, {
      dataField: 'description',
      text: 'Description',
      sort: true
    }, {
      dataField: 'date',
      text: 'Date',
      sort: true
    }, {
      dataField: 'followup by',
      text: 'Followup By',
      sort: true
    }, {
      dataField: 'action',
      text: 'Action',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

  
    // Select All Button operation
    const selectRow = {
      mode: 'checkbox'
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>

        <div className="page-content">
          <MetaTags>
          <title>To Do List | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
<Col className="col-lg-8 col-md-6 col-6">
  <div className="breadcrum ps-0">
      <CardTitle className="h6 mb-2">To Do List</CardTitle>
      <a href="#" className="me-2 font-12">Home</a> <span>/</span><span className="ms-2 font-12">To Do List</span>
  </div>
</Col>
  <Col className="col-lg-4 co-md-6 col-6">
    <a className ="btn btn-primary btn-sm float-end font-14 margin-20-back" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
  </Col>
</Row>




            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                  {/* <Col className="col-12 mb-4">
                <div className ="d-flex align-items-center justify-content-between">
                 <div className="d-flex">
                 
                 </div>
                  <div className ="d-flex">
                  <div className="text-end me-5">
                     
                    </div>
                    <a className ="btn btn-primary btn-sm" href="#" data-bs-toggle="modal" data-bs-target="#Edit_followup" >Add</a>
                  </div>
                </div>
              
              </Col> */}
             
                    
                  
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                                <Row className="mb-2">
                             
                                <Col className="col-lg-4 col-md-4 col-6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-lg-8 col-md-8 col-6">
              <div className="float-end">
              <a className ="btn btn-green btn-sm float-end font-14" href="#"><i className="mdi mdi-plus align-middle me-1"></i>Add</a>
                    </div>
              </Col>
                              </Row>



                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap todo-text"
                                      }
                                      headerWrapperClasses={"thead-light table-thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            
{/* <div className="modal fade" id="view_followup" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">View Followup</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
                    <Row>
                    <Col sm={12}>
                         
                         <div className="followup-box">
                           <div className="h5 mb-3">
                             Next Schedule Refollow-up
                           </div>
                           
                           <div className="h5 mb-3">
                             <button className="btn border p-2 rounded">Mark as completed</button>
                           </div>
                         </div>
                         <Row className="justify-content-center align-items-center border-bottom pb-3">
                             <Col sm={2}>
                             <div className="date-box p-3 text-center  border">
                                   <p className="mb-0">30</p>
                                   <p className="mb-0">Apr</p>
                               </div>
                             </Col>
                             <Col sm={7}>
                             <div className="ms-2">
                                 <p className="mb-0">Remark: Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                               </div>
                             </Col>
                             <Col sm={2} className="offset-lg-1">
                             <div className="text-end">
                                 <p className="mb-0">Email 4:40pm</p>
                                 <p className="mb-0">By:Jhon Doe</p>
                             </div>
                             </Col>
                           </Row>
                           <div className="followup-box pt-3">
                             <div className="h5 mb-3">
                             Lead Activity
                             </div>
                           
                             <div className="h5 mb-3">
                               <button className="btn border p-2 rounded">Re-open (if Already Closed)</button>
                             </div>
                           </div>
                           <Row className="justify-content-center align-items-center pb-3">
                             <Col sm={2}>
                             <div className="date-box p-3 text-center  border">
                                   <p className="mb-0">15</p>
                                   <p className="mb-0">Apr</p>
                               </div>
                             </Col>
                             <Col sm={7}>
                             <div className="ms-2">
                                 <p className="mb-0">Remark: Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                               </div>
                             </Col>
                             <Col sm={2} className="offset-lg-1">
                             <div className="text-end">
                                 <p className="mb-0">Email 4:40pm</p>
                                 <p className="mb-0">By:Jhon Doe</p>
                             </div>
                             </Col>
                           </Row>
                           <Row className="justify-content-center align-items-center pb-3">
                             <Col sm={2}>
                             <div className="date-box p-3 text-center  border">
                                   <p className="mb-0">16</p>
                                   <p className="mb-0">Apr</p>
                               </div>
                             </Col>
                             <Col sm={7}>
                             <div className="ms-2">
                                 <p className="mb-0">Remark: Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                               </div>
                             </Col>
                             <Col sm={2} className="offset-lg-1">
                             <div className="text-end">
                                 <p className="mb-0">Email 4:40pm</p>
                                 <p className="mb-0">By:Jhon Doe</p>
                             </div>
                             </Col>
                           </Row>
                           <Row className="justify-content-center align-items-center pb-3">
                             <Col sm={2}>
                             <div className="date-box p-3 text-center  border">
                                   <p className="mb-0">30</p>
                                   <p className="mb-0">Apr</p>
                               </div>
                             </Col>
                             <Col sm={7}>
                             <div className="ms-2">
                                 <p className="mb-0">Remark: Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                               </div>
                             </Col>
                             <Col sm={2} className="offset-lg-1">
                             <div className="text-end">
                                 <p className="mb-0">Email 4:40pm</p>
                                 <p className="mb-0">By:Jhon Doe</p>
                             </div>
                             </Col>
                           </Row>
                       </Col>
       </Row>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> */}





{/* <div className="modal fade" id="Edit_followup" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Add/edit Followup</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
                    <Row>
                    <Col sm={12} >
                          <div className="lead-follow border-bottom mt-1">
                            <div className="h6 mb-3">
                              Lead Date : 25/03/2021
                            </div>
                            <div className="h6 mb-3">
                              Status : Active
                            </div>
                            <div className="h6 mb-3">
                              CLose Date : 30/4/2021
                            </div>
                            
                          </div>
                          <div className="lead-follow-2 mt-3">
                          <div className="h5 mb-3">
                              Add Followup
                            </div>
                            <div className="h6 mb-3">
                              Select Mode
                            </div>
                            <div className="">
                              <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                  Call
                                </label>
                              </div>
                              <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                  Metting
                                </label>
                              </div>
                              <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                  Message
                                </label>
                              </div>
                              <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                  Email
                                </label>
                              </div>
                              <div className="mb-3">
                                <input id="formrow-InputCity" placeholder="Add Remarks" type="text" className="form-control pb-5 form-control" />
                                </div>
                                <div className="h5 mb-3">
                                   Schedule Refollow-up
                                  </div>
                              </div>
                              <div className="mb-3 d-flex">
                                <div className="col-md-4 col-6">
                                  <input className="form-control" type="week" id="example-week-input" value="date" />
                                </div>
                                <div className="col-md-4 col-6  ms-3">
                                  <input className="form-control" type="time" id="example-time-input" value="time" />
                                </div>
                              </div>
                              
                          </div>
                          </Col>
           </Row>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> */}






          </div>
        </div>




      </React.Fragment>
    )
  }
}

export default DatatableTables