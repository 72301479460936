import React, { Component} from "react"
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
 
const AddStatus = () => { 
    const history = useHistory();
    const saveProduct = async (e) => {
        e.preventDefault();
        let Code=e.target["txtCode"].value; 
        let Title=e.target["txtTitle"].value; 
        let Description=e.target["txtDescription"].value; 
        if(Code!='')
        {
            const Department = {
                StatusId:'0',
                code: Code,
                Title: Title,
                Description: Description,
                Flag:"A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
         
            }

            axios.post(global.APIURL+'/Status', Department)
            .then(res => 
            {
                    alert(res.data.StatusMessage);
                    history.push("/StatusMaster");
            });
        }
    }
  
    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Status | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Add Status</CardTitle>
                <a href="/" className="me-2">Home</a>
                <span>/</span>
                <a href="/StatusMaster" className="ms-2 me-2">Status</a>
                <span>/</span>
                <span className="ms-2">Add Status</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/StatusMaster">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={saveProduct}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                  
                  <Row>
                      <Col lg={4}>
                        <div className="mb-2">
                        <label className="form-label">Code</label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Code"
                            id="txtCode"  
                           
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-2">
                        <label className="form-label">Title</label>
                          <Input
                            type="text"
                            className="form-control"
                            id="txtTitle"  
                            placeholder="Title"
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-2">
                        <label className="form-label">Description</label>
                          <Input
                            type="text"
                            className="form-control pb-4"
                            id="txtDescription"  
                          
                            placeholder="Description"
                          />
                        </div>
                      </Col>
                      <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                    </Col>
                    </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
       
      </div>
    )
}

export default AddStatus;