import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import img2 from "assets/images/favicon.png";
import axios from 'axios'
import { Col, Row, CardTitle, Card, CardBody, Input, Label, Form, Button } from "reactstrap";
import CIBILDateofReport from "../ClientCredit/CIBILDateofReport";
import EqifaxDateofReport from "../ClientCredit/EqifaxDateofReport";
import ExperianDateofReport from "../ClientCredit/ExperianDateofReport";
import CRIFDateofReport from "../ClientCredit/CRIFDateofReport";
import ClientAccountIndividualDetail from "../LeadList/ClientAccountIndividualDetail";
import LeadIndividualCreditDetail from "./LeadIndividualCreditDetail";
// Rating Plugin1 

const UiRating = () => {
  const [LeadCode, setLeadCode] = useState('');
  const [VClientAccountDetail, setVClientAccountDetail] = useState(1);
  const [VClientCreditDetail, setVClientCreditDetail] = useState(0);
  useEffect(() => {
    let LeadId = localStorage.getItem("IndividualLeadId");
    if (LeadId != null) {
      if (LeadId != "0") {
        getLeadDetailById(LeadId);
      }
      else {
        history.push("/Retail/LeadList/AllInq");
      }
    }
    else {
      history.push("/Retail/LeadList/AllInq");
    }
  }, []);

  const getLeadDetailById = async (LId) => {
    var AUrl = global.APIURL + '/WebLeadEdit/' + LId;
    axios.get(AUrl)
      .then(res => {
        setLeadCode(res.data[0].LeadCode);
      })
  }

  const ClientDetailType = async (event, VLeadType) => {
    if (VLeadType == 'Client Account Detail') {
      setVClientAccountDetail(1);
      setVClientCreditDetail(0);
    }
    else if (VLeadType == 'Client Credit Detail') {
      setVClientAccountDetail(0);
      setVClientCreditDetail(1);
    }
    else {
      setVClientAccountDetail(1);
      setVClientCreditDetail(0);
    }
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Enroll Individual Client | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">


          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-12 mb-2">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Lead Detail</CardTitle>
                <a href="/" className="me-2">
                  Home
                </a>{" "}
                <span>/</span>
                <a href="/LeadList" className="me-2 ms-2">
                  Manage Lead
                </a>{" "}
                <span>/</span>
                <span className="ms-2">Lead Detail</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a
                className="btn btn-primary btn-sm float-end font-14"
                href="/LeadList"
              >
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>

            </Col>
          </Row>
          <Card>
            <CardBody>
              <Row className="">
                <Col className="col-lg-4">
                  <ul className="nav border-0 nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item mt-2 mb-2" role="presentation">
                      <div className="form-check">
                        <input type="radio" id="1" name="radio1" className="form-check-input" value="success" defaultChecked onChange={event => ClientDetailType(event, "Client Account Detail")} />
                        <Label className="form-check-label" htmlFor="radio2">
                          Client Account Detail
                        </Label>
                      </div>
                    </li>
                    <li className="nav-item mt-2 mb-2 ms-3" role="presentation">
                      <div className="form-check">
                        <input type="radio" id="2" name="radio1" className="form-check-input" value="success" onChange={event => ClientDetailType(event, "Client Credit Detail")} />
                        <Label className="form-check-label" htmlFor="radio2">
                          Client Credit Detail
                        </Label>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className="tab-content" id="myTabContent">
            {VClientAccountDetail > 0 ?
              <div className="ind-card" id="ind-card">
                <ClientAccountIndividualDetail />
              </div>
              : ''}
            {VClientCreditDetail > 0 ?
              <div className="company-card" id="company-card">
                <LeadIndividualCreditDetail />
              </div>
              : ''}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}

export default UiRating;
