import React, { Component, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom';
import { getSectionClassNames } from "@fullcalendar/core";

const ContactInformation = () => {
  const history = useHistory();
  const [CLogo, setCLogo] = useState("");
  const [CLogoFileName, setCLogoFileName] = useState();
  const [CLogoDisplay, setCLogoDisplay] = useState();
  const [CName, setCName] = useState("");
  const [EAddress, setEAddress] = useState("");
  const [CNumber, setCNumber] = useState("");
  const [CAddress, setCAddress] = useState("");
  const [facebookLink, setfacebookLink] = useState("");
  const [InstaLink, setInstaLink] = useState("");
  const [LinkedinLink, setLinkedinLink] = useState("");
  const [WhatsappLink, setWhatsappLink] = useState("");


  useEffect(() => {
    getContactInformation();
  })


  const getContactInformation = async () => {

    axios.post(global.APIURL + '/ContactInformationDetail').then(res => {
      if (res.data.length > 0) {
        setCName(res.data[0].CName);
        setEAddress(res.data[0].CEmailAddress);
        setCNumber(res.data[0].CContactNumber);
        setCAddress(res.data[0].CAddress);
        setfacebookLink(res.data[0].facebookLink);
        setInstaLink(res.data[0].InstagramLink);
        setLinkedinLink(res.data[0].LinkedinLink);
        setWhatsappLink(res.data[0].WhatsappLink);
      }
    });
  }


  const getContactLogo = async (e) => {

    var details = e.target.files[0];
    var details1 = "ContactLogo";
    const formData = new FormData();
    formData.append('Document', details);
    formData.append('FolderName', details1);
    axios.post(global.APIURL + '/uploadavatar', formData, {

      headers: { "Content-Type": details.type },

    }).then((res) => {
      setCLogo(res.data.IName);
      setCLogoFileName(res.data.FileName);
      if (e.target.files[0].name != null) {
        if (e.target.files[0].name != '') {
          setCLogoDisplay(1);
        }
        else {
          setCLogoDisplay(0);
        }
      }
    });
  }


  const saveContact = async (event) => {
    event.preventDefault();
    let CName = event.target["txtContactName"].value;
    let CEmailAddress = event.target["txtEmailAddress"].value;
    let CNumber = event.target["txtContactNumber"].value;
    let CAddress = event.target["txtContactAddress"].value;
    let CFacebookLink = event.target["txtFacebookLink"].value;
    let CInstaLink = event.target["txtInstaLink"].value;
    let CLinkedInLink = event.target["txtLinkedinLink"].value;
    let CWaLink = event.target["txtWaLink"].value;

    if (CName == "") {
      alert("Enter Contact Name");
      return;
    }
    if (CEmailAddress == "") {
      alert("Enter Contact Email Address");
      return;
    }
    if (CNumber == "") {
      alert("Enter Contact Number");
      return;
    }
    if (CAddress == "") {
      alert("Enter Contact Address");
      return;
    }


    const ContactData = {
      CName: CName,
      CLogo: CLogo,
      CEmailAddress: CEmailAddress,
      CContactNumber: CNumber,
      CAddress: CAddress,
      facebookLink: CFacebookLink,
      InstagramLink: CInstaLink,
      LinkedinLink: CLinkedInLink,
      WhatsappLink: CWaLink
    }

    axios.post(global.APIURL + '/ContactInformationSave', ContactData)
      .then(res => {
        alert(res.data[0].RecordStatus);
        window.location.reload();
      });
  }
  return (
    <div className="page-content">

      <MetaTags>
        <title>Category | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-12 mb-2">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Add Contact Information</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              {/* <a href="/Category" className="ms-2 me-2">Add Contact Information</a>
              <span>/</span> */}
              <span className="ms-2">Add Contact Information</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-12">
            <a className="btn btn-primary btn-sm float-end font-14" href="/GeneralDashboard">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <form onSubmit={saveContact}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Contact Name</label>
                        <Input type="text" className="form-control" id="txtContactName" placeholder="Enter Contact Name" defaultValue={CName} />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Contact Logo</label>
                        {CLogoDisplay > 0 ?
                          <span className="float-end text-primary font-12" id="spandoctitle">
                            <Link to={CLogoFileName} target="_blank" >{CLogoFileName}</Link></span> : ''}
                        <Input type="file" className="form-control" id="txtContactLogo" accept=".png,.jpg,.jpeg,.gif" onChange={(e) => getContactLogo(e)} />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Email Address</label>
                        <Input type="text" className="form-control" id="txtEmailAddress" placeholder="Enter Email Address" defaultValue={EAddress} />
                      </div>
                    </Col>

                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Contact Number</label>
                        <Input type="text" className="form-control" id="txtContactNumber" placeholder="Enter Contact Name" defaultValue={CNumber} />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Contact Adress</label>
                        <textarea
                          type="text"
                          className="form-control pb-4"
                          id="txtContactAddress"
                          rows="1"
                          placeholder="Enter Contact Address"
                          defaultValue={CAddress}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Facebook Link</label>
                        <Input type="text" className="form-control" id="txtFacebookLink" placeholder="Enter facebook Link" defaultValue={facebookLink} />
                      </div>
                    </Col>

                  </Row>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Instagram Link</label>
                        <Input type="text" className="form-control" id="txtInstaLink" placeholder="Enter Instagram Link" defaultValue={InstaLink} />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Linkedin Link</label>
                        <Input type="text" className="form-control" id="txtLinkedinLink" placeholder="Enter Linkedin Link" defaultValue={LinkedinLink} />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Whatsapp Link</label>
                        <Input type="text" className="form-control" id="txtWaLink" placeholder="Enter Whatsapp Link" defaultValue={WhatsappLink} />
                      </div>
                    </Col>

                  </Row>
                  <Row>

                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave">Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/Category">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </form>
      </div>

    </div>
  )
}

export default ContactInformation;