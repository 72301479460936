import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import axios from 'axios'
import { Col, Row, CardTitle, Card, CardBody, Input } from "reactstrap";

const UiRating = () => {
  const [CustomerCode, setCustomerCode] = useState('');
  const [FullName, setFullName] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [MiddleName, setMiddleName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Gender, setGender] = useState("");
  const [CIRID, setCIRID] = useState("");
  const [CIRPassword, setCIRPassword] = useState("");
  const [CustomerProfileImage, setCustomerProfileImage] = useState("");
  const [PMobileNumber, setPMobileNumber] = useState("");
  const [PEmailAddress, setPEmailAddress] = useState("");
  const [CustomerType, setCustomerType] = useState("");
  const [State, setState] = useState("");
  const [District, setDistrict] = useState("");
  const [City, setCity] = useState("");
  const [Tehsil, setTehsil] = useState("");
  const [PinCode, setPinCode] = useState("");

  const [SelectChannelDetail, setSelectChannelDetail] = useState([]);
  const [SelectChannelPartnerDetail, setSelectChannelPartnerDetail] = useState([]);
  const [ServicePackageList, setServicePackageList] = useState([]);
  const [ServicePackagePayment, setServicePackagePayment] = useState([]);

  useEffect(() => {
    let CustomerId = localStorage.getItem("CustomerIndividualId");
    let LeadId = localStorage.getItem("CustomerIndividualLeadId");
    if (CustomerId != null && LeadId != null) {
      if (CustomerId != "0") {
        getCustomerDetailById(CustomerId);
        getSelectChannelDetailById(LeadId);
        getSelectChannelPartnerDetailById(LeadId);
        getServicePackage(LeadId);
        getServicePackagePayment(LeadId);
      }
      else {
        history.push("/ConvertedInq");
      }
    }
    else {
      history.push("/ConvertedInq");
    }
  }, []);

  const getCustomerDetailById = async (CId) => {
    var AUrl = global.APIURL + '/WebCustomerEdit/' + CId;
    axios.get(AUrl)
      .then(res => {
        setCustomerCode(res.data[0].CustomerCode);
        setFirstName(res.data[0].FirstName);
        setMiddleName(res.data[0].MiddleName);
        setLastName(res.data[0].LastName);
        setFullName(res.data[0].FullName);
        setPMobileNumber(res.data[0].MobileNumber);
        setPEmailAddress(res.data[0].EmailAddress);
        setCustomerType(res.data[0].CustomerType);
        setCustomerProfileImage(res.data[0].CustomerProfileImage)
        setGender(res.data[0].Gender);
        setCIRID(res.data[0].CIRID);
        setCIRPassword(res.data[0].CIRPassword);
        setState(res.data[0].State);
        setDistrict(res.data[0].District);
        setCity(res.data[0].City);
        setTehsil(res.data[0].Tehsil)
        setPinCode(res.data[0].Pincode);
      })
  }

  const getSelectChannelDetailById = async LeadId => {
    var AUrl = global.APIURL + '/WebLeadChannelEdit/' + LeadId;
    axios.get(AUrl)
      .then(LCDres => {
        let LeadChannelL = LCDres.data.map(kcchannel => {
          return kcchannel;
        });
        setSelectChannelDetail(LeadChannelL);
      });
  };

  const getSelectChannelPartnerDetailById = async LeadId => {
    var AUrl = global.APIURL + '/WebLeadChannelPartnerEdit/' + LeadId;
    axios.get(AUrl)
      .then(LCPDres => {
        let LeadPChannelL = LCPDres.data.map(kcchannepl => {
          return kcchannepl;
        });
        setSelectChannelPartnerDetail(LeadPChannelL);
      });
  };

  const getServicePackage = async LeadId => {
    const CSerPackArr = {
      LeadId: LeadId
    };
    var AUrl = global.APIURL + '/ClientServicePackage';
    axios.post(AUrl, CSerPackArr)
      .then(CSPres => {
        let ServicePackageList = CSPres.data.map(ServicePackage => {
          return ServicePackage;
        });
        setServicePackageList(ServicePackageList);
      });
  };

  const getServicePackagePayment = async LeadId => {
    const CSerPackArr = {
      LeadId: LeadId
    };
    var AUrl = global.APIURL + '/ServicePackagePaymentList';
    axios.post(AUrl, CSerPackArr)
      .then(CSPres => {
        let ServicePackagePaymentList = CSPres.data.map(ServicePackagePayment => {
          return ServicePackagePayment;
        });
        setServicePackagePayment(ServicePackagePaymentList);
      });
  };

  const ServiceActivitiesDetail = (e, idx) => {
    const RId = idx;
    localStorage.setItem('IndividualServicePackageId', RId);
    window.location.href = "/ClientIndividualServiceActivities";
  };

  // const RedirectLeadDetail = (ServicePackageId) => {
  //     localStorage.setItem('IndividualServicePackageId', ServicePackageId);
  //     window.location.href = "/ClientIndividualServiceActivities";
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Client Individual Detail | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">


          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle>Client Individual Detail</CardTitle>
                <a href="/ClientLead" className="me-2">
                  Manage Client
                </a>{" "}
                <span>/</span>
                <span className="ms-2">Client Individual Detail</span>
              </div>

            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a
                className="btn btn-primary btn-sm float-end font-14"
                href="/ClientLead"
              >
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>

            </Col>
          </Row>

          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div className="ind-card" id="ind-card">
                <Row>
                  <Col className="col-lg-12 col-12">
                    <Card>
                      <CardBody>
                        <Row className="align-items-center">
                          <Col className="col-md-10 col-12">
                            <Row>
                              <Col className="col-12 d-none d-md-block">
                                <h5 className="fw-bolder mb-2">{FullName} <span className="text-primary">({CustomerCode})</span></h5>
                                <hr />
                              </Col>
                              <Col className="col-md-2 col-lg-2 col-12 text-center d-block d-md-none">
                                <div className="">
                                  <img
                                    className="rounded-circle clientactimg"
                                    src={CustomerProfileImage}
                                  ></img>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col className="col-md-3 col-12 mb-md-0 mb-3">
                                <p className="mb-2 fontWeight500"> Mobile Number </p>
                                <Input
                                  type="text"
                                  className=""
                                  readOnly
                                  id="txtMobileNumber"
                                  defaultValue={PMobileNumber}
                                  placeholder="Enter Mobile Number"
                                />
                              </Col>
                              <Col className="col-md-3 col-12 mb-md-0 mb-3">
                                <p className="mb-2 fontWeight500"> Email Address </p>
                                <Input
                                  type="text"
                                  className=""
                                  readOnly
                                  id="txtEmailAddress"
                                  defaultValue={PEmailAddress}
                                  placeholder="Enter Email Address"
                                />
                              </Col>
                              <Col className="col-lg-3">
                                <p className="mb-2 fontWeight500"> Gender </p>
                                <Input type="text" className="" id="txtGender" defaultValue={Gender} readOnly />
                              </Col>
                              <Col className="col-md-3 col-12 mb-md-0 mb-3">
                                <p className="mb-2 fontWeight500"> Type </p>
                                <Input
                                  type="text"
                                  readOnly
                                  className=""
                                  id="txtType"
                                  defaultValue={CustomerType}
                                  placeholder="Enter Type"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col className="col-md-2 col-lg-2 col-12 float-end d-none d-md-block">
                            <div className="">
                              <img
                                className="rounded-circle float-end clientactimg"
                                src={CustomerProfileImage}
                              ></img>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-lg-12 col-md-12 col-12">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col className="col-lg-12">
                            <CardTitle>Personal Details</CardTitle>
                            <hr />
                          </Col>

                          <Col className="col-lg-2">
                            <p className="mb-2 fontWeight500"> CIR ID </p>
                            <Input type="text" className="" id="txtCIRID" defaultValue={CIRID} readOnly />
                          </Col>
                          <Col className="col-lg-2">
                            <p className="mb-2 fontWeight500"> State </p>
                            <Input type="text" className="" id="txtState" readOnly defaultValue={State} />
                          </Col>
                          <Col className="col-lg-2">
                            <p className="mb-2 fontWeight500"> District </p>
                            <Input type="text" className="" id="txtDistrict" readOnly defaultValue={District} />
                          </Col>
                          <Col className="col-lg-2">
                            <p className="mb-2 fontWeight500"> City </p>
                            <Input type="text" className="" id="txtCity" readOnly defaultValue={City} />
                          </Col>
                          <Col className="col-lg-2">
                            <p className="mb-2 fontWeight500"> Tehsil </p>
                            <Input type="text" className="" id="txtTehsil" readOnly defaultValue={Tehsil} />
                          </Col>
                          <Col className="col-lg-2">
                            <p className="mb-2 fontWeight500"> PinCode </p>
                            <Input type="text" className="" id="txtPinCode" readOnly defaultValue={PinCode} />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <Row>
                          <div className="col-lg-7">
                            <CardTitle>
                              Banking Source
                            </CardTitle>
                            <hr />
                            {SelectChannelDetail.map(item => (
                              <div className="team-modal-box" key={item.ChannelId}>
                                <div className="d-md-flex d-block align-items-center justify-content-between">
                                  <div className="d-md-flex d-block align-items-center">

                                    <div className="flex-shrink-0">
                                      <div className="avatar-md">
                                        <img
                                          src={item.ChannelImage}
                                          alt=""
                                          className="img-thumbnail"
                                        />
                                      </div>
                                    </div>
                                    <div className="flex-grow-1 ms-md-3 ms-0 mb-md-0 mb-3">
                                      <div className="font-14 dark-font mb-1">
                                        {item.ChannelName}
                                      </div>
                                      <div className="mb-1">
                                        <a href="" className="table-link-a font-12">
                                          Branch : {item.BranchName}
                                        </a>
                                      </div>
                                      <div className="">
                                        <a href="" className="table-link-a font-12">
                                          IFSE Code : {item.BranchIFSCCOde}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="bankdiv">
                                    <div className="">
                                      <a href="" className="table-link-a">
                                        <p className="mb-2 text-success">
                                          <i className="fas fa-phone-alt me-1"></i>
                                          {item.BranchContactNumber}
                                        </p>
                                      </a>
                                      <a href="" className="table-link-a">
                                        <p className="mb-0 text-primary">
                                          <i className="fas fa-envelope me-1"></i>
                                          {item.BranchEmailAddress}
                                        </p>
                                      </a>
                                    </div>

                                  </div>
                                </div>
                                <div className="d-md-flex d-block justify-content-between align-items-center">
                                  <div className="mb-md-0 mb-3">
                                    <div className="">
                                      <span className="badge badge-soft-primary p-2 me-2">
                                        {item.BranchState}
                                      </span>
                                      <span className="badge badge-soft-primary p-2 me-2">
                                        {item.BranchDistrict}
                                      </span>
                                      <span className="badge badge-soft-primary p-2 me-2">
                                        {item.BranchCity}
                                      </span>
                                      <span className="badge badge-soft-primary p-2 me-2">
                                        {item.BranchTehsil}
                                      </span>
                                      <span className="badge badge-soft-primary p-2 me-2">
                                        {item.Pincode}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="bankdiv1">
                                    <p className="mb-0 font-12">Approved</p>
                                    <p className="mb-0 font-12">
                                      CID : {item.ChannelCode}
                                    </p>
                                  </div>

                                </div>


                              </div>
                            ))}
                          </div>
                          <div className="col-lg-5 mt-3 mt-md-3 mt-lg-0">
                            <CardTitle>
                              Channel Partner
                            </CardTitle>
                            <hr />
                            {SelectChannelPartnerDetail.map(item => (
                              <div
                                className="team-modal-box"
                                key={item.ChannelPartnerId}
                              >
                                <div className="d-flex align-items-center border-bottom cpbottom">
                                  <div className="flex-shrink-0">
                                    <div className="avatar-md">
                                      <img
                                        src={item.ChannelPartnerLogo}
                                        alt=""
                                        className="img-thumbnail"
                                      />
                                    </div>
                                  </div>
                                  <div className="flex-grow-1 ms-3">
                                    <div className="font-14 dark-font mb-1">
                                      {item.Name}
                                    </div>
                                    <div className="mb-1">
                                      <a href="" className="table-link-a font-12">
                                        {item.Designation}
                                      </a>
                                    </div>
                                    <div className="">
                                      <a href="" className="table-link-a font-12">
                                        {item.ChannelName}
                                      </a>
                                    </div>
                                  </div>
                                </div>

                                <div className="modal-sub-box pt-3 d-block d-md-flex">
                                  <div className="department-box mb-2 mb-md-0">
                                    <a className="mb-0 text-success">
                                      <i className="fas fa-phone-alt me-1"></i>
                                      {item.MobileNumber}
                                    </a>
                                  </div>
                                  <div className="emp-code">
                                    <a className="mb-0 text-primary">
                                      <i className="fas fa-envelope me-1"></i>
                                      {item.Email}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <ul className="nav tab_bottom_main" role="tablist">
                          <li className="nav-item">
                            <a
                              className="tab_bottom table-link-a active"
                              data-bs-toggle="tab"
                              href="#ServiceInformation"
                            >
                              Service Information
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="tab_bottom  table-link-a"
                              data-bs-toggle="tab"
                              href="#FeesInformation"
                            >
                              Fees Information
                            </a>
                          </li>
                        </ul>

                        <div className="tab-content">
                          <div id="ServiceInformation" className=" tab-pane active">
                            <br />
                            <div className="table-responsive text-center" id="style-4">
                              <table className="table table-sm table-bordered  ">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th className="textalignstart">Services</th>
                                    <th className="textalignend">Total Accrued Amount</th>
                                    <th className="textalignend">Total Paid</th>
                                    <th className="textalignend">Total Amount to Pay</th>
                                    <th>Activities</th>
                                    <th>Documents</th>
                                    <th>Status</th>
                                    <th>Service Progress</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ServicePackageList.map(item => (
                                    <tr key={item.RowId}>
                                      <th>{item.RowId}</th>
                                      <td className="textalignstart">{item.ServicePackage}</td>
                                      <td className="textalignend">{item.ServicePackageAmount}</td>
                                      <td className="textalignend">{item.TotalRecivedAmount}</td>
                                      <th className="textalignend">{item.TotalPendingAmount}</th>
                                      <td><a href="#" onClick={e => ServiceActivitiesDetail(e, item.ServicePackageId)} className="btn btn_success btn-sm edit_activity_btn text-light">
                                        <i className="fas fa-eye me-2"></i>
                                        Manage
                                      </a>{" "}
                                      </td>
                                      <td><a href="#" className="btn btn_success btn-sm edit_activity_btn text-light">
                                        <i className="fas fa-eye me-2"></i>
                                        Manage
                                      </a>{" "}
                                      </td>

                                      <td>{item.PaymentStaus}</td>
                                      <td>
                                        <div
                                          className="progress"
                                          style={{ height: "20px" }}
                                        >
                                          <div
                                            className="progress-bar"
                                            style={{ width: "50%" }}
                                            aria-valuenow="50"
                                          >
                                            50%
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div id="FeesInformation" className=" tab-pane fade">
                            <br />

                            <div className="table-responsive text-center" id="style-4">
                              <table className="table table-sm table-bordered  ">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>ReceiptNo.</th>
                                    <th>Services</th>
                                    <th>Recived Amount</th>
                                    <th>GST Amount</th>
                                    <th>Total Amount</th>
                                    <th>Payment Staus</th>
                                    <th>Payment Mode</th>
                                    <th>Transaction Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ServicePackagePayment.map(item => (
                                    <tr key={item.RowId}>
                                      <td>{item.RowId}</td>
                                      <td>{item.ReceiptCode}</td>
                                      <td>{item.ServicePackage}</td>
                                      <td>{item.RecivedAmount}</td>
                                      <td>{item.RecivedGSTAmount}</td>
                                      <td>{item.TotalAmount}</td>
                                      <td>{item.PaymentStaus}</td>
                                      <td>{item.PaymentMode}</td>
                                      <td>{item.TransactionDate}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}
export default UiRating;
