import React,{ useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory,useParams  } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
const cookies = new Cookies();
 
const EditProfessionalTax = () => { 
      const [SalaryStartRange, setTitle] = useState('');
      const [SalaryEndRange, setShortCode] = useState('');
      const [ProfessionalTaxAmout, setDescription] = useState('');
      const [ProfessionalTaxId, setId] = useState('');
      const history = useHistory();

      let { id } = useParams();

      const updateProduct  = async (e) => {
        e.preventDefault();
        let ETSalaryStartRange=event.target["txtSalaryStartRange"].value; 
        let ETSalaryEndRange=event.target["txtSalaryEndRange"].value; 
        let ETProfessionalTaxAmout=event.target["txtProfessionalTaxAmout"].value; 
        let ETProfessionalTaxId=event.target["hdfProfessionalTaxId"].value;  
      
        if(ETSalaryStartRange!='')
        {
          const ProfessionalTaxDetails = {
            ProfessionalTaxId:ETProfessionalTaxId==''?'0':ETProfessionalTaxId,
            SalaryStartRangeitle: ETSalaryStartRange,
            SalaryEndRange: ETSalaryEndRange,
            ProfessionalTaxAmout: ETProfessionalTaxAmout,
            Flag:"A"       
        }
            axios.post(global.APIURL+'/ProfessionalTaxUpdate', ProfessionalTaxDetails)
            .then(res => 
            {
                    alert(res.data);
                    history.push("/ProfessionalTax");
            });
        }
    }

    useEffect(() => {
        if(id!=null)
        {
          if(id!="0")
          {
            let newid = id.split(':'); 
            getUserTyprById(newid[1]);
          }
          else{
            history.push("/ProfessionalTax");
          }
        }
        else{
          history.push("/ProfessionalTax");
        }
    }, []);
 
    const getUserTyprById = async (UTId) => {
      var AUrl=global.APIURL+'/ProfessionalTax/'+UTId;
      axios.get(AUrl)  
      .then(res => {  
        setId(res.data[0].ProfessionalTaxId)
        setTitle(res.data[0].SalaryStartRange);
        setShortCode(res.data[0].SalaryEndRange);
        setDescription(res.data[0].ProfessionalTaxAmout);
      })
    }

    return (
      <div className="page-content">
  
        <MetaTags>
          <title>ProfessionalTax  | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Edit ProfessionalTax </CardTitle>
                <a Link to="/" className="me-2">Home</a>
                <span>/</span>
                <a Link to="/EmployeeType"><span className="ms-2 me-2">ProfessionalTax</span></a>
                <span>/</span>
                <span className="ms-2">Edit ProfessionalTax</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" Link to="/ProfessionalTax">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={updateProduct}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                <Row>
                  <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Start Range</label>
                        <Input type="text" className="form-control" id="txtSalaryStartRange" defaultValue={SalaryStartRange} placeholder="Enter Title"/>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">End Range</label>
                        <Input type="text" className="form-control" id="txtSalaryEndRange" defaultValue={SalaryEndRange} placeholder="Enter Short Code"/>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Professional TaxAmout</label>
                        <Input type="text" className="form-control" id="txtProfessionalTaxAmout" defaultValue={ProfessionalTaxAmout} placeholder="Enter Description"/>
                      </div>
                    </Col>
                    <Col lg={12}>
                        <input type="hidden" id="hdfProfessionalTaxId" value={ProfessionalTaxId} />    
                        <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                        <a className="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
      </div>
    )
}

export default  EditProfessionalTax
  