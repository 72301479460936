import React, { Component,useState} from "react"
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
 
const AddChannelType = () => { 
    const history = useHistory();
    const [ChannelType, setChannelType] = useState("");
    const ChannelTypeSave = async (e) => {
        e.preventDefault();
        let UTitle=ChannelType; 
        if(UTitle!='')
        {
            const ChannelType = {
                Id:'0',
                ChannelTypeName: UTitle,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),      
            }
            
            axios.post(global.APIURL+'/ChannelTypeSave', ChannelType)
            .then(res => 
            {
                    alert(res.data[0].RecordStatus);
                    history.push("/ChannelType");
            });
        }
    }
    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Channel Type | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-12 mb-2">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Add Channel Type</CardTitle>
                 <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/ChannelType" className="ms-2 me-2">Channel Type</a>
                <span>/</span>
                <span className="ms-2">Add Channel Type</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-12">
              <a className="btn btn-primary btn-sm float-end font-14" href="/ChannelType">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Channel Type</label>
                        <Input type="text" className="form-control" id="txtChannelType" placeholder="Enter Channel Type" onChange={e => setChannelType(e.target.value)}/>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" onClick={ChannelTypeSave} >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/AddChannelType">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    )
}

export default AddChannelType;