import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';

const OnSort = () => {
  let columns = [
    {
      key: "SR",
      text: "#",
      sortable: true
    },
    {
      key: "Name",
      text: "Name",
      sortable: true
    },
    {
      d: "Gender & Date of Birth",
      text: "Gender & Date of Birth",
      cell: (record, index) => {
        let VGender = record["Gender"];
        let VDateofBirth = record["DateofBirth"];
        return (
          <Fragment>
            <p>{VGender}</p>
            <p>{VDateofBirth}</p>
          </Fragment>
        );
      }
    },
    {
      d: "Email & Mobile",
      text: "Email & Mobile",
      cell: (record, index) => {
        let VEmail = record["Email"];
        let VMobileNumber = record["MobileNumber"];
        return (
          <Fragment>
            <p>{VEmail}</p>
            <p>{VMobileNumber}</p>
          </Fragment>
        );
      }
    },
    {
      key: "UserType",
      text: "User Type",
      sortable: true
    },
    {
      d: "Department & Designation",
      text: "Department & Designation",
      cell: (record, index) => {
        let VDepartmentName = record["DepartmentName"];
        let VDesignationName = record["DesignationName"];
        return (
          <Fragment>
            {VDepartmentName}
            <p>{VDesignationName}</p>
          </Fragment>
        );
      }
    },
    {
      d: "Address",
      text: "Address",
      cell: (record, index) => {
        let VAddress = record["Address"];
        let VStateName = record["StateName"];
        let VCityName = record["CityName"];
        let VCountryName = record["CountryName"];
        let VPincode = record["Pincode"];
        return (
          <Fragment>
            {VAddress}
            <p>{VCityName}, {VStateName}, {VCountryName} - {VPincode}</p>
          </Fragment>
        );
      }
    },
    {
      d: "action",
      text: "Action",
      cell: (record, index) => {
        return (
          <Fragment>
            {RightsUpdate == "A" ? <a className="me-3 text-primary" onClick={(editRecord.bind(this, record))}><i className="mdi mdi-pencil font-size-18"></i></a> : ''}
            {RightsDelete == "A" ? <a className={IconrenderSwitchColor(record)} onClick={deleteRecord.bind(this, record)}><i className={IconrenderSwitch(record)}></i></a> : ''}
            <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModalHistory" style={{ marginLeft: "16px" }} className="btn btn-sm btn-primary" onClick={getUserHistory.bind(this, record)}>History</button>
            <br/>
            <button type="button" className="btn btn-sm btn-primary mt-2" onClick={getSendLoginCredential.bind(this, record)}>Send Login Credential</button>
          </Fragment>
        );
      }
    }
  ];
  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
  }
  const [records, setRecords] = useState([])
  const [UserFlag, setuserFlag] = useState("");
  const [RightsAdd, setRightsAdd] = useState("D");
  const [RightsUpdate, setRightsUpdate] = useState("D");
  const [RightsDelete, setRightsDelete] = useState("D");
  const [UserVisitHistory, setUserVisitHistory] = useState([]);
  const [UserVisitImages, setUserVisitImages] = useState([]);
  let history = useHistory();

  function IconrenderSwitch(param) {
    let UFlag = param["Flag"];
    switch (UFlag) {
      case 'A':
        return 'mdi mdi-close font-size-18';
      default:
        return 'mdi mdi-check-all font-size-18';
    }
  }

  function IconrenderSwitchColor(param) {
    let UFlag = param["Flag"];
    switch (UFlag) {
      case 'A':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }

  useEffect(() => {



    const f = {
      MenuId: "16",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
      //UserTypeId: "1"
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {

        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add') {
            setRightsAdd(MS);
          }
          else if (MA == 'Update') {
            setRightsUpdate(MS);
          }
          else if (MA == 'Delete') {
            setRightsDelete(MS);
          }
        }
      });


    fetch(global.APIURL + "/UserList/A")
      .then((res) => res.json())
      .then((json) => {
        setRecords(json)
      })
  },

    []);

  const editRecord = (record) => {
    let id = record["UserId"]
    let path = `/User/EditUser/:${id}`;
    history.push(path);
  }
  function handleChange(event) {

    if (event.target.value == "A") {
      fetch(global.APIURL + "/UserList/A")
        .then((req) => req.json())
        .then((json) => {
          setRecords(json)
        })
    }
    else if (event.target.value == "ALL") {
      fetch(global.APIURL + "/UserList/ALL")
        .then((req) => req.json())
        .then((json) => {
          setRecords(json)
        })
    }
    else {
      fetch(global.APIURL + "/UserList/D")
        .then((req) => req.json())
        .then((json) => {
          setRecords(json)
        })
    }


  }
  const deleteRecord = (record) => {
    let UTId = record["UserId"];
    let UFlag = record["Flag"];
    let de = UFlag == "A" ? "D" : UFlag == "D" ? "A" : UFlag;
    const Userflag = {
      UserId: UTId,
      Flag: de
    }

    let AlertMessgae = UFlag == "A" ? "Are you sure you want to inactive this record?" : "Are you sure you want to active this record?";
    if (confirm(AlertMessgae) == true) {
      axios.post(global.APIURL + '/Userdelete', Userflag)
        .then(res => {
          if (UFlag == "A") {
            alert("Record Inactive Successfully");
            fetch(global.APIURL + "/UserList/A")
              .then((res) => res.json())
              .then((json) => {
                setRecords(json)
              })
          }
          else if (UFlag == "D") {
            alert("Record Active Successfully");
            fetch(global.APIURL + "/UserList/A")
              .then((res) => res.json())
              .then((json) => {
                setRecords(json)
                window.location.reload()
              })
          }
        })
    }
  }
  const getUserHistory = async (record) => {
    event.preventDefault();
    let UTId = record["UserId"];

    const Userdata = {
      UserId: UTId
    }
    axios.post(global.APIURL + '/UserVisitHistory', Userdata)
      .then(res => {
        setUserVisitHistory(res.data);

      });
  }

  const getSendLoginCredential = async (record) => {
    let UTId = record["UserId"];

    const Userdata = {
      UserId: UTId
    }
    axios.post(global.APIURL + '/UserSendLoginCredential', Userdata)
      .then(res => {
        alert(res.data.StatusMessage);
      });
  }

  const getUserVisitImages = async (UserVisitId) => {

    const VisitImagedata = {

      UserVisitLogId: UserVisitId

    }
    axios.post(global.APIURL + '/UserVisitHistoryImages', VisitImagedata)
      .then(res => {
        setUserVisitImages(res.data);
      });

  }

  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }

  const exportPDF = () => {

    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "User List Report";
    const headers = [["#", "Name", "Gender", "Dob", "Mobile Number", "Email", "User Type", "Department Name", "Designation", "Address", "State", "City", "Country", "Pincode"]];

    const data = records.map((item, index) => [index + 1, item.Name, item.Gender, item.DateofBirth, item.MobileNumber, item.Email, item.UserType, item.DepartmentName, item.DesignationName, item.Address, item.StateName, item.CityName, item.CountryName, item.Pincode]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("UserListReport.pdf")
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>User | KC Admin Group</title>
      </MetaTags>
      <div className="modal fade" id="exampleModalHistory" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">View User Visit History</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form>
              <div className="modal-body">
                <div className="table-responsive text-center" id="style-4">
                  <table className="table table-sm table-bordered  ">
                    <thead>
                      <tr>
                        <th>ChannelName.</th>
                        <th>ChannelPartner</th>
                        <th>Visit Agenda</th>
                        <th>Visit Date</th>
                        <th>Visit From Time</th>
                        <th>Visit Person Contact</th>
                        <th>Visit Person Email</th>
                        <th>Visit Person Name</th>
                        <th>Visit Place Location</th>
                        <th>Visit Remark</th>
                        <th>Visit To Time</th>
                        <th>Visit Type</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {UserVisitHistory.map(item => (
                        <tr key={item.UserVisitLogId}>
                          <td>{item.ChannelName}</td>
                          <td>{item.ChannelPartner}</td>
                          <td>{item.VisitAgenda}</td>
                          <td>{item.VisitDate}</td>
                          <td>{item.VisitFromTime}</td>
                          <td>{item.VisitPersonContact}</td>
                          <td>{item.VisitPersonEmail}</td>
                          <td>{item.VisitPersonName}</td>
                          <td>{item.VisitPlaceLocation}</td>
                          <td>{item.VisitRemark}</td>
                          <td>{item.VisitToTime}</td>
                          <td>{item.VisitType}</td>
                          <td><button type="button" data-bs-toggle="modal" data-bs-target="#exampleModalImages" style={{ marginLeft: "16px" }} className="btn btn-sm btn-primary" onClick={getUserVisitImages.bind(this, item.UserVisitLogId)}>View Images</button></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="modal fade visitImage" id="exampleModalImages" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">View Images</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form>
              <div className="modal-body">
                <Row>

                  {UserVisitImages.map(item => (<Col lg={6} key={item.UserVisitLogId}>
                    <div className="mb-2 vewsourcecode">
                      <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                      <a href={item.VisitPhotos1} target="_blank" rel="noreferrer" className="fadocumenttitleiconcolor" >{item.VisitPhotos1FileName}</a>
                    </div>
                  </Col>

                  ))}
                  {UserVisitImages.map(item => (<Col lg={6} key={item.UserVisitLogId}>
                    <div className="mb-2 vewsourcecode">
                      <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                      <a href={item.VisitPhotos2} target="_blank" rel="noreferrer" className="fadocumenttitleiconcolor" >{item.VisitPhotos2FileName}</a>
                    </div>
                  </Col>

                  ))}
                  {UserVisitImages.map(item => (<Col lg={6} key={item.UserVisitLogId}>
                    <div className="mb-2 vewsourcecode">
                      <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                      <a href={item.VisitPhotos3} target="_blank" rel="noreferrer" className="fadocumenttitleiconcolor" >{item.VisitPhotos3FileName}</a>
                    </div>
                  </Col>

                  ))}
                  {UserVisitImages.map(item => (<Col lg={6} key={item.UserVisitLogId}>
                    <div className="mb-2 vewsourcecode">
                      <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                      <a href={item.VisitPhotos4} target="_blank" rel="noreferrer" className="fadocumenttitleiconcolor" >{item.VisitPhotos4FileName}</a>
                    </div>
                  </Col>

                  ))}
                  {UserVisitImages.map(item => (<Col lg={6} key={item.UserVisitLogId}>
                    <div className="mb-2 vewsourcecode">
                      <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                      <a href={item.VisitPhotos5} target="_blank" rel="noreferrer" className="fadocumenttitleiconcolor" >{item.VisitPhotos5FileName}</a>
                    </div>
                  </Col>
                  ))}
                </Row>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <Row className="mb-3">
          <Col className="col-lg-6 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">User</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <span className="ms-2">User</span>
            </div>
          </Col>
          <Col className="col-lg-6 co-md-6 col-6">
            <div className="d-flex align-items-center justify-content-end ">
              <div className="me-2">
                <select id="ddlActive" className="form-select selectbtnedit" onChange={handleChange}>

                  <option value={"A"} selected={UserFlag === "A"}>Active Records</option>
                  <option value={"D"}>In-Active Records</option>
                  <option value={"ALL"}>ALL Records</option>

                </select>
              </div>
              <div>
                <a className="btn btn-primary btn-sm float-end font-14" href="/GeneralDashboard">
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
                {/* <a className="btn btn-primary btn-sm font-14 me-2 " data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"> 
              <i className="fas fa-filter"></i>
            </a>*/}
                {RightsAdd == "A" ? <a className="btn btn-primary btn-sm float-end font-14 me-2" href="/User/AddUser">
                  <i className="mdi mdi-plus align-middle me-1"></i>Add New
                </a> : ''}

                <ReactHtmlTableToExcel
                  className="btn btn-primary btn-sm float-end font-14 me-2"
                  table="emp"
                  filename="UserListReport"
                  sheet="Sheet"
                  buttonText="Export Excel" />
                <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <div>
            <table id="emp" className="table" hidden="hidden">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Gender</th>
                  <th>Dob</th>
                  <th>Mobile Number</th>
                  <th>Email</th>
                  <th>User Type</th>
                  <th>DepartmentName</th>
                  <th>Designation</th>
                  <th>Address</th>
                  <th>State</th>
                  <th>City</th>
                  <th>Country</th>
                  <th>Pincode</th>
                </tr>
              </thead>
              <tbody>              {
                records.map((item, index) => {
                  return <tr key={index}>
                    <td> {index + 1} </td>
                    <td >{item.Name}</td>
                    <td >{item.Gender}</td>
                    <td >{item.DateofBirth}</td>
                    <td >{item.MobileNumber}</td>
                    <td>{item.Email}</td>
                    <td>{item.UserType}</td>
                    <td>{item.DepartmentName}</td>
                    <td>{item.DesignationName}</td>
                    <td>{item.Address}</td>
                    <td>{item.StateName}</td>
                    <td>{item.CityName}</td>
                    <td>{item.CountryName}</td>
                    <td>{item.Pincode}</td>
                  </tr>
                })
              }
              </tbody>
            </table>
          </div>
        </Row>
        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>

    </div>
  );
}

export default OnSort;