import React, {   useState ,useEffect} from "react"
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
 
const AddDistrict = () => { 
    const history = useHistory();
    const [CountryId, setCountryId] = useState('');
    const [CountryTitle, setCountryTitle] = useState('');
    const [StateTitle, setStateTitle] = useState('');
    const [StateId, setStateId] = useState('');
    const saveDistrict = async (e) => {  
        e.preventDefault();
        let DistrictTitle=event.target["txtDistrict"].value; 
        if(DistrictTitle!='')
        {
            const DistrictArr = {
                DistrictId:'0',
                StateId:StateId,
                CountryId:CountryId,
                Title: DistrictTitle,
                CreateUser:localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
            }

            axios.post(global.APIURL+'/District', DistrictArr)
            .then(res => 
            {
                    alert(res.data.StatusMessage);
                    history.push("/District");
            });
        }
    }

    useEffect(() => {
        let StateId=localStorage.getItem("DistrictStateId");
        let StateTitle=localStorage.getItem("DistrictStateTitle");
        let CountryTitle=localStorage.getItem("CountryTitle");
        let CountryId=localStorage.getItem("CountryId");
        setCountryId(CountryId);
        setCountryTitle(CountryTitle);
        setStateId(StateId);
        setStateTitle(StateTitle);
    });

    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Add District | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Add District</CardTitle>
                 <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/Country" className="ms-2 me-2">{CountryTitle}</a>
                <span>/</span>
                <a href="/State" className="ms-2 me-2">{StateTitle}</a>
                <span>/</span>
                <a href="/District" className="me-2 ms-2">District List</a>
                <span>/</span>
                <span className="ms-2">Add District</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/District">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={saveDistrict}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">District</label>
                        <Input type="text" className="form-control" id="txtDistrict" placeholder="Enter District"/>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/District">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
       
      </div>
    )
}

export default AddDistrict;