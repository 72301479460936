import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';

const AddTags = () => {
  const history = useHistory();
  const saveEmployeeType = async (e) => {
    e.preventDefault();
    let ETag = event.target["txtTag"].value;
    let EStatus = event.target["txtStatus"].value;
    if (ETag != '') {
      const TagsFlag = {
        TagId: '0',
        Tag: ETag,
        Status: EStatus,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")

      }

      axios.post(global.APIURL + '/Tags', TagsFlag)
        .then(res => {
          alert(res.data.StatusMessage);
          history.push("/Tags");
        });
    }
  }
  return (
    <div className="page-content">

      <MetaTags>
        <title>Tags | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Add Tags</CardTitle>
              <a href="/" className="me-2">Home</a>
              <span>/</span>
              <a href="/Tags" className="me-2 ms-2">Tags</a>
              <span>/</span>
              <span className="ms-2">Add Tags</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-6">
            <a className="btn btn-primary btn-sm float-end font-14" href="/Tags">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <form onSubmit={saveEmployeeType}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Tag</label>
                        <Input type="text" className="form-control" id="txtTag" placeholder="Enter Tag" />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Status</label>
                        <Input type="text" className="form-control" id="txtStatus" placeholder="Enter Status" />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Color
                        </label>
                        <input
                          className="form-control form-control-color mw-100"
                          type="color"
                          defaultValue="#556ee6"
                          id="example-color-input"
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>

    </div>
  )
}

export default AddTags;