import React, {   useState ,useEffect} from "react"
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
 
const AddTehsil = () => { 
    const history = useHistory();
    const [CountryId, setCountryId] = useState(''); 
    const [CountryTitle, setCountryTitle] = useState('');
    const [StateId, setStateId] = useState(''); 
    const [StateTitle, setStateTitle] = useState('');
    const [DistrictId, setDistrictId] = useState(''); 
    const [DistrictTitle, setDistrictTitle] = useState('');
    const [Title, setTitle] = useState('');
    const saveTehsil = async (e) => {  
        e.preventDefault();
        if(Title!='')
        {
            const TehsilArr = {
              TehsilId:'0',
              CountryId:CountryId,
              StateId:StateId,
              DistrictId:DistrictId,
              Title: Title,
              CreateUser:"1"       
            }

            axios.post(global.APIURL+'/Tehsil', TehsilArr)
            .then(res => 
            {
                    alert(res.data);
                    history.push("/Tehsil");
            });
        }
    }

    useEffect(() => {
      let VCountryId=localStorage.getItem("CountryTitle");
      let VCountryTitle=localStorage.getItem("CountryTitle");
      let VStateId=localStorage.getItem("DistrictStateId");
      let VStateTitle=localStorage.getItem("DistrictStateTitle");
      let VDistrictId=localStorage.getItem("TehsilDistrictId");
      let VDistrictTitle=localStorage.getItem("TehsilDistrictTitle");
      setCountryId(VCountryId);
      setCountryTitle(VCountryTitle);
      setStateId(VStateId);
      setStateTitle(VStateTitle);
      setDistrictId(VDistrictId);
      setDistrictTitle(VDistrictTitle)
    });

    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Add Tehsil | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Add Tehsil</CardTitle>
                 <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/Country" className="ms-2 me-2">{CountryTitle}</a>
                <span>/</span>
                <a href="/State" className="ms-2 me-2">{StateTitle}</a>
                <span>/</span>
                <a href="/District" className="ms-2 me-2">{DistrictTitle}</a>
                <span>/</span>
                <a href="/Tehsil" className="me-2 ms-2">Tehsil List</a>
                <span>/</span>
                <span className="ms-2">Add Tehsil</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/Tehsil">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Tehsil</label>
                        <Input type="text" className="form-control" id="txtTehsil" placeholder="Enter Tehsil" onChange={(e) => setTitle(e.target.value)}/>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" onClick={saveTehsil} >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/AddTehsil">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
       
      </div>
    )
}

export default AddTehsil;