import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Label,
  Input,
} from "reactstrap";
import MetaTags from "react-meta-tags";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";

// Table data
const products = [
  {
    Sr: 1,
    title: "Full Time",
    shortcode: "FT",
    action: (
      <>
       <div className="d-flex gap-3"><a className="text-success" href="/"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="/"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
      </>
    ),
  },
  {
    Sr: 2,
    title: "Part Time",
    shortcode: "PT",
    action: (
      <>
       <div className="d-flex gap-3"><a className="text-success" href="/"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="/"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
      </>
    ),
  },
  {
    Sr: 3,
    title: "Trainee",
    shortcode: "TT",
    action: (
      <>
       <div className="d-flex gap-3"><a className="text-success" href="/"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="/"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
      </>
    ),
  },
  {
    Sr: 4,
    title: "Permanent",
    shortcode: "PP",
    action: (
      <>
       <div className="d-flex gap-3"><a className="text-success" href="/"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="/"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
      </>
    ),
  },
  {
    Sr: 5,
    title: "Freelancer",
    shortcode: "FL",
    action: (
      <>
       <div className="d-flex gap-3"><a className="text-success" href="/"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="/"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
      </>
    ),
  },
 
 
 

];

class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products,
    };
  }

  render() {
    const columns = [
      {
        dataField: "Sr",

        text: "Sr",
        sort: true,
      },
      {
        dataField: "title",

        text: "Title",
        sort: true,
      },
      {
        dataField: "shortcode",

        text: "Short Code",
        sort: true,
      },
      {
        dataField: "action",

        text: "Action",
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    };

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "25", value: 25 },
      { text: "All", value: this.state.productData.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;

    return (
      <React.Fragment>

       
        <div className="page-content">
          <MetaTags>
          <title>Employee Type | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h6">Employee Type </CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Employee Type</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                  <Row>
                     <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Employee Title</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Employee Title"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                          <label className="form-label">Short Code</label>

                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-InputCity"
                                placeholder="Short Code"
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                <div className="mb-3">
                <label className="form-label">Description</label>

                            <Input
                              type="text"
                              className="form-control pb-4"
                              id="formrow-InputCity"
                              placeholder="Description"
                            />
                          </div>
                </Col>
                     </Row>
                   
                    
                      <Row className="mb-4">
                     
                        <Col className="col-lg-12">
                <div className ="d-flex align-items-center
                 justify-content-between">
                   
                  <div className ="d-flex">
                  <div  className="form-check">
                                          <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                    <a className ="btn btn_success btn-sm" href="/">Save</a>
                    <a className ="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
                      </Row>


                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2 border-top pt-4">
                                <Col className="col-lg-4 col-md-4 col-6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-lg-8 col-md-8 col-6">
                                  <div className="float-end">
                                    
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light table-thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DatatableTables;
