import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import axios from 'axios'
import { Col, Row, CardTitle, Card, CardBody, Label } from "reactstrap";
import ConvertLeadIndividualDetail from "../LeadList/ConvertLeadIndividualDetail";
import ConvertLeadIndividualCreditDetail from "../LeadList/ConvertLeadIndividualCreditDetail";
// Rating Plugin1 

const UiRating = () => {

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Converted Lead Detail | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">


          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-12 mb-2">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Converted Lead Detail</CardTitle>
                <a href="/" className="me-2">
                  Home
                </a>{" "}
                <span>/</span>
                <a href="/ConvertedInq" className="me-2 ms-2">
                  Converted Lead
                </a>{" "}
                <span>/</span>
                <span className="ms-2">Converted Lead Detail</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a
                className="btn btn-primary btn-sm float-end font-14"
                href="/ConvertedInq"
              >
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>

            </Col>
          </Row>
          <Card>
            <CardBody>
              <Row className="">
                <Col className="col-lg-4">
                  <ul className="nav border-0 nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item mt-2 mb-2" role="presentation">
                      <div className="form-check">
                        <input type="radio" id="1" name="radio1" className="form-check-input" value="success" data-bs-toggle="tab" data-bs-target="#home" role="tab" aria-controls="home" aria-selected="true" defaultChecked />
                        <Label className="form-check-label" htmlFor="radio2">
                          Lead Account Detail
                        </Label>
                      </div>
                    </li>
                    <li className="nav-item mt-2 mb-2 ms-3" role="presentation">
                      <div className="form-check">
                        <input type="radio" id="2" name="radio1" className="form-check-input" value="success" data-bs-toggle="tab" data-bs-target="#profile" role="tab" aria-controls="profile" aria-selected="false" />
                        <Label className="form-check-label" htmlFor="radio2">
                          Lead Credit Detail
                        </Label>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div className="ind-card" id="ind-card">
                <ConvertLeadIndividualDetail />
              </div>
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab" >
              <div className="company-card" id="company-card">
                <ConvertLeadIndividualCreditDetail />
              </div>
            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  );

}
export default UiRating;
