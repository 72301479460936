import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import axios from 'axios'
import { Col, Row, CardTitle, Card, CardBody, Input } from "reactstrap";
import { useHistory } from 'react-router-dom';
import $ from 'jquery';


const UiRating = () => {
  const history = useHistory();
  const [LeadCode, setLeadCode] = useState('');
  const [FullName, setFullName] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [MiddleName, setMiddleName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Gender, setGender] = useState("");
  const [CIRID, setCIRID] = useState("");
  const [LeadProfileImage, setLeadProfileImage] = useState("");
  const [PMobileNumber, setPMobileNumber] = useState("");
  const [PEmailAddress, setPEmailAddress] = useState("");
  const [LeadType, setLeadType] = useState("");
  const [State, setState] = useState("");
  const [District, setDistrict] = useState("");
  const [City, setCity] = useState("");
  const [Tehsil, setTehsil] = useState("");
  const [PinCode, setPinCode] = useState("");

  const [CompanyList, setCompanyList] = useState([]);
  const [PaymentModeList, setPaymentModeList] = useState([]);

  const [SelectChannelDetail, setSelectChannelDetail] = useState([]);
  const [SelectChannelPartnerDetail, setSelectChannelPartnerDetail] = useState([]);

  const [ServiceWiseActivityList, setServiceWiseActivityList] = useState([]);

  const [PaymentModeType, setPaymentModeType] = useState(0);

  const [PaymentModeId, setPaymentModeId] = useState(0);
  const [PaymentModeTitle, setPaymentModeTitle] = useState('');
  const [CompanyId, setCompanyId] = useState(0);
  const [BankName, setBankName] = useState('');
  const [Branch, setBranch] = useState('');
  const [ChequeDDNo, setChequeDDNo] = useState('');
  const [ChequeDDDate, setChequeDDDate] = useState('');
  const [TransactionDate, setTransactionDate] = useState('');

  const [UTR, setUTR] = useState('');
  const [Settlement, setSettlement] = useState('');

  const [ShowTransactionNumber, setShowTransactionNumber] = useState(0);
  const [ShowBankName, setShowBankName] = useState(0);
  const [ShowBranch, setShowBranch] = useState(0);
  const [ShowChequeDDDate, setShowChequeDDDate] = useState(0);
  const [ShowUTR, setShowUTR] = useState(0);
  const [ShowSettlement, setShowSettlement] = useState(0);

  const [PayFinalAmount, setPayFinalAmount] = useState(0);

  //const [InsertRowCount, setInsertRowCount] = useState(0);

  useEffect(() => {
    let VInLeadId = localStorage.getItem("ClientIndividualLeadId") == null ? "0" : localStorage.getItem("ClientIndividualLeadId") == "" ? "0" : localStorage.getItem("ClientIndividualLeadId");
    let VFILeadId = localStorage.getItem("ClientFirmLeadId") == null ? "0" : localStorage.getItem("ClientFirmLeadId") == "" ? "0" : localStorage.getItem("ClientFirmLeadId");
    let VFinalLeadId = VInLeadId == '0' ? VFILeadId : VInLeadId;
    if (parseInt(VFinalLeadId) > 0) {
      getCompanyList();
      getPaymentModeList();
      getLeadDetailById(VFinalLeadId);
      getSelectChannelDetailById(VFinalLeadId);
      getSelectChannelPartnerDetailById(VFinalLeadId);
      getServiceWiseActivity(VFinalLeadId);
    }
    else {
      history.push("/ConvertedInq");
    }
  }, []);

  const getCompanyList = async () => {
    var AUrl = global.APIURL + '/CompanyActive';
    axios.get(AUrl).then(SPres => {
      let CompanyL = SPres.data.map((Company) => {
        return Company
      })
      setCompanyList(CompanyL);
    })
  }

  const getPaymentModeList = async () => {
    var AUrl = global.APIURL + '/PaymentModeActive';
    axios.get(AUrl).then(Pres => {
      let PaymentModeL = Pres.data.map((PaymentMode) => {
        return PaymentMode
      })
      setPaymentModeList(PaymentModeL);
    })
  }

  const getLeadDetailById = async (LId) => {
    var AUrl = global.APIURL + '/WebLeadEdit/' + LId;
    axios.get(AUrl)
      .then(res => {
        setLeadCode(res.data[0].LeadCode);
        setFirstName(res.data[0].FirstName);
        setMiddleName(res.data[0].MiddleName);
        setLastName(res.data[0].LastName);
        setFullName(res.data[0].FullName);
        setPMobileNumber(res.data[0].MobileNumber);
        setPEmailAddress(res.data[0].EmailAddress);
        setLeadType(res.data[0].LeadType);
        setLeadProfileImage(res.data[0].LeadProfileImage)
        setGender(res.data[0].Gender);
        setCIRID(res.data[0].CIRID);
        setState(res.data[0].State);
        setDistrict(res.data[0].District);
        setCity(res.data[0].City);
        setTehsil(res.data[0].Tehsil)
        setPinCode(res.data[0].Pincode);
      })
  }

  const getServiceWiseActivity = async (LId) => {
    var AccruedTotalAmount = 0;
    var TotalGSTAmount = 0;
    var TotalAccruedAmount = 0;
    var TotalAccruedDue = 0;
    var TotalPaidAmount = 0;
    var TotalAmountToPay = 0;
    var TotalGSTAmountToPay = 0;
    var ServiceGstPer = 0;
    const SerPackRegArr = {
      LeadId: LId
    };
    var AUrl = global.APIURL + '/LeadServicePackageRegister';

    axios.post(AUrl, SerPackRegArr).then(SPres => {
      let SerPackRegActList = SPres.data.map((ServicePackageAct) => {
        return ServicePackageAct
      })
      setServiceWiseActivityList(SerPackRegActList);

      for (let sa = 0; sa < SerPackRegActList.length; sa++) {
        var VRecordType = SerPackRegActList[sa].RecordType;
        if (VRecordType == "S") {
          AccruedTotalAmount = AccruedTotalAmount + parseFloat(SerPackRegActList[sa].PackageAmount);
          TotalGSTAmount = TotalGSTAmount + parseFloat(SerPackRegActList[sa].PackageGSTAmount);
          TotalAccruedAmount = TotalAccruedAmount + parseFloat(SerPackRegActList[sa].TotalAccruedAmount);
          TotalAccruedDue = TotalAccruedDue + parseFloat(SerPackRegActList[sa].AccruedDue);
          TotalPaidAmount = TotalPaidAmount + parseFloat(SerPackRegActList[sa].PaidAmount);
          TotalAmountToPay = TotalAmountToPay + parseFloat(SerPackRegActList[sa].AmounttoPay);
          var GSTPer = parseFloat(SerPackRegActList[sa].GSTPer == null ? '0' : SerPackRegActList[sa].GSTPer == '' ? '0' : SerPackRegActList[sa].GSTPer);
          TotalGSTAmountToPay = (TotalAmountToPay * GSTPer) / 100;
          $("#txtTotalPackageAmount").val(AccruedTotalAmount);
          $("#txtTotalGST").val(TotalGSTAmount);
          $("#txtTotalAccruedAmount").val(TotalAccruedAmount);
          $("#txtTotalAccruedDue").val(TotalAccruedDue);
          $("#txtTotalPaidAmount").val(TotalPaidAmount);
          $("#txtTotalAmountToPay").val(TotalAmountToPay);

          $("#txtFTotalAccruedAmount").val(AccruedTotalAmount);
          $("#txtFTotalPaidAmount").val(TotalPaidAmount);
          $("#txtFGrossAmountToPayNow").val(TotalAmountToPay);
          $("#txtFGSTAmount").val(TotalGSTAmountToPay);
          $("#txtFTotalAmountToPayNow").val(TotalAmountToPay + TotalGSTAmountToPay);
        }
      }
    })
  };

  const getSelectChannelDetailById = async LeadId => {
    var AUrl = global.APIURL + '/WebLeadChannelEdit/' + LeadId;
    axios.get(AUrl)
      .then(LCDres => {
        let LeadChannelL = LCDres.data.map(kcchannel => {
          return kcchannel;
        });
        setSelectChannelDetail(LeadChannelL);
      });
  };

  const getSelectChannelPartnerDetailById = async LeadId => {
    var AUrl = global.APIURL + '/WebLeadChannelPartnerEdit/' + LeadId;
    axios.get(AUrl)
      .then(LCPDres => {
        let LeadPChannelL = LCPDres.data.map(kcchannepl => {
          return kcchannepl;
        });
        setSelectChannelPartnerDetail(LeadPChannelL);
      });
  };

  const PaymentModeOnChange = e => {
    var VPaymentMode = e.nativeEvent.target.selectedIndex;
    var PaymentModeId = e.nativeEvent.target[VPaymentMode].value;
    var PaymentModeText = e.nativeEvent.target[VPaymentMode].text;
    setPaymentModeId(PaymentModeId);
    setPaymentModeTitle(PaymentModeText);
    if (PaymentModeText == 'Cash') {
      setShowTransactionNumber(0);
      setShowBankName(0);
      setShowBranch(0);
      setShowChequeDDDate(0);
      setShowUTR(0);
      setShowSettlement(0);
    }
    else if (PaymentModeText == 'Cheque') {
      setShowTransactionNumber(1);
      setShowBankName(1);
      setShowBranch(1);
      setShowChequeDDDate(1);
      setShowUTR(0);
      setShowSettlement(0);
    }
    else if (PaymentModeText == 'Credit Card / Debit Card') {
      setShowTransactionNumber(1);
      setShowBankName(1);
      setShowBranch(1);
      setShowChequeDDDate(1);
      setShowUTR(0);
      setShowSettlement(0);
    }
    else if (PaymentModeText == 'NEFT') {
      setShowTransactionNumber(1);
      setShowBankName(1);
      setShowBranch(1);
      setShowChequeDDDate(1);
      setShowUTR(0);
      setShowSettlement(0);
    }
    else if (PaymentModeText == 'Paytm') {
      setShowTransactionNumber(1);
      setShowBankName(0);
      setShowBranch(0);
      setShowChequeDDDate(0);
      setShowUTR(1);
      setShowSettlement(1);
    }
    else if (PaymentModeText == 'E-Wallets except Paytm') {
      setShowTransactionNumber(1);
      setShowBankName(0);
      setShowBranch(0);
      setShowChequeDDDate(0);
      setShowUTR(1);
      setShowSettlement(1);
    }
    else if (PaymentModeText == 'Free') {
      setShowTransactionNumber(1);
      setShowBankName(0);
      setShowBranch(0);
      setShowChequeDDDate(0);
      setShowUTR(0);
      setShowSettlement(0);
    }
    else if (PaymentModeText == 'CASH & CARD') {
      setShowTransactionNumber(1);
      setShowBankName(1);
      setShowBranch(1);
      setShowChequeDDDate(1);
      setShowUTR(0);
      setShowSettlement(0);
    }
   
    else if (PaymentModeText == 'Cash & Cheque') {
      setShowTransactionNumber(1);
      setShowBankName(1);
      setShowBranch(1);
      setShowChequeDDDate(1);
      setShowUTR(0);
      setShowSettlement(0);
    }
    else if (PaymentModeText == 'Phone pay') {
      setShowTransactionNumber(1);
      setShowBankName(0);
      setShowBranch(0);
      setShowChequeDDDate(0);
      setShowUTR(1);
      setShowSettlement(1);
    }
    else {
      setShowTransactionNumber(0);
      setShowBankName(0);
      setShowBranch(0);
      setShowChequeDDDate(0);
      setShowUTR(0);
      setShowSettlement(0);
    }
  };

  const AmounttoPayOnChange = async () => {
    var APTotalAmount = 0;
    var APTotalGSTAmount = 0;
    var SPTotalAccruedAmount = parseFloat( $("#txtFTotalAccruedAmount").val() == null ? '0' :  $("#txtFTotalAccruedAmount").val() == '' ? '0' :  $("#txtFTotalAccruedAmount").val());
    for (let sw = 0; sw < ServiceWiseActivityList.length; sw++) {
      var RCount = sw + 1;
      var VRecordType = ServiceWiseActivityList[sw].RecordType;

      var AmountToPayTextId = '#txtSPAAmount' + RCount;
      if (VRecordType == "S") {
        var SPAAmount = parseFloat( $(AmountToPayTextId).val() == null ? '0' : $(AmountToPayTextId).val() == '' ? '0' : $(AmountToPayTextId).val());
        var GSTPer = parseFloat(ServiceWiseActivityList[sw].GSTPer == null ? '0' : ServiceWiseActivityList[sw].GSTPer == '' ? '0' : ServiceWiseActivityList[sw].GSTPer);
        var VGSTAmount = (SPAAmount * GSTPer) / 100;
        APTotalAmount = APTotalAmount + SPAAmount;
        APTotalGSTAmount = APTotalGSTAmount + VGSTAmount;
      }
    }
    var VTotalDueAmount = parseFloat(SPTotalAccruedAmount) - parseFloat(APTotalAmount);
    var VFinalAmount = parseFloat(APTotalAmount) + parseFloat(APTotalGSTAmount);
    $("#txtTotalAmountToPay").val(APTotalAmount);
    $("#txtFGrossAmountToPayNow").val(APTotalAmount);
    $("#txtFTotalDueAmount").val(VTotalDueAmount);
    $("#txtFGSTAmount").val(APTotalGSTAmount);
    $("#txtFTotalAmountToPayNow").val(VFinalAmount);

    let FT = parseFloat($("#txtFTotalAmountToPayNow").val() == null ? '0' : $("#txtFTotalAmountToPayNow").val() == '' ? '0' : $("#txtFTotalAmountToPayNow").val());
    setPayFinalAmount(FT);
  };

  const ServicePackageRegister = async () => {
    let FT = parseFloat($("#txtFTotalAmountToPayNow").val() == null ? '0' : $("#txtFTotalAmountToPayNow").val() == '' ? '0' : $("#txtFTotalAmountToPayNow").val());
    if (FT > 0) {
      let IsInsert = 1;
      if (CompanyId == 0) {
        IsInsert = 0;
        alert("Please select company");
        return;
      }
      if (PaymentModeId == 0) {
        IsInsert = 0;
        alert("Please select payment mode");
        return;
      }
      if (TransactionDate == '') {
        IsInsert = 0;
        alert("Please select transaction date");
        return;
      }
      if (PaymentModeId != 1) {
        if (ChequeDDNo == '') {
          IsInsert = 0;
          alert("Please input transaction number");
          return;
        }
      }

      if (PaymentModeId == 5) {
        if (UTR == '') {
          IsInsert = 0;
          alert("Please input UTR");
          return;
        }
      }
      if (PaymentModeId == 12) {
        if (UTR == '') {
          IsInsert = 0;
          alert("Please input UTR");
          return;
        }
      }

      if (PaymentModeId == 5) {
        if (Settlement == '') {
          IsInsert = 0;
          alert("Please input Settlement");
          return;
        }
      }
      if (PaymentModeId == 12) {
        if (Settlement == '') {
          IsInsert = 0;
          alert("Please input Settlement");
          return;
        }
      }

      if (IsInsert == 1) {
        let VInLeadId = localStorage.getItem("ClientIndividualLeadId") == null ? "0" : localStorage.getItem("ClientIndividualLeadId") == "" ? "0" : localStorage.getItem("ClientIndividualLeadId");
        let newDate = new Date()
        let CDay = newDate.getDate();
        let CMonth = newDate.getMonth() + 1;
        let CYear = newDate.getFullYear();
        let CDate = CYear + '-' + CMonth + '-' + CDay;
        const SerPackArr = {
          LeadId: VInLeadId
        };

        var SAUrl = global.APIURL + '/LeadServicePackageActivitiesGroupBy';
        axios.post(SAUrl, SerPackArr).then(SPAres => {
          for (let spa = 0; spa < SPAres.data.length; spa++) {
            let VSPId = SPAres.data[spa].ServicePackageId;
            let VSPAId = SPAres.data[spa].ActivitiesId;
            const ServiceWiseActivityFilter = ServiceWiseActivityList.filter(x => x.ServicePackageId == VSPId && x.ActivitiesId == VSPAId && x.RecordType == 'S');
            for (let sp = 0; sp < ServiceWiseActivityFilter.length; sp++) {
              let VATP = parseFloat(ServiceWiseActivityFilter[sp].AmounttoPay == null ? '0' : ServiceWiseActivityFilter[sp].AmounttoPay == '' ? '0' : ServiceWiseActivityFilter[sp].AmounttoPay);
              if (VATP > 0) {
                let VAmountToPayTextId ='#'+ ServiceWiseActivityFilter[sp].AmountToPayTextId;
                let VAmountToPayTextValue =$(VAmountToPayTextId).val(); 
                let VPayAmount = VAmountToPayTextValue == null ? '0' : VAmountToPayTextValue == '' ? '0' : VAmountToPayTextValue;
                const SerPackRegActPaymentArr = {
                  LeadId: VInLeadId,
                  ServicePackageId: ServiceWiseActivityFilter[sp].ServicePackageId,
                  ActivitiesId: ServiceWiseActivityFilter[sp].ActivitiesId,
                  RecivedAmount: VPayAmount,
                  CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
                };
                axios.post(global.APIURL + "/ServicePackageActivitiesPayment", SerPackRegActPaymentArr).then(res => { });
              }
            }
          }
        });

        var AUrl = global.APIURL + '/LeadServicePackageGroupBy';
        axios.post(AUrl, SerPackArr).then(SPres => {
          let rowcount = 1;
          for (let spg = 0; spg < SPres.data.length; spg++) {
            let SPId = SPres.data[spg].ServicePackageId;
            let VGSTPer = parseFloat(SPres.data[spg].GSTPer == null ? "0" : SPres.data[spg].GSTPer == "" ? "0" : SPres.data[spg].GSTPer);
            const ServiceWiseAct = ServiceWiseActivityList.filter(x => x.ServicePackageId == SPId && x.RecordType == 'S');
            let VAccruedAmount = 0;
            let VAccruedGstAmount = 0;
            let VTotalAccruedAmount = 0;
            let VAmountToPayPayment = 0;
            let VAmountToPayGSTPayment = 0;
            let VTotalAmountToPayPayment = 0;
            let VTotalDuePayment = 0;

            for (let x = 0; x < ServiceWiseAct.length; x++) {
              let VSATP = parseFloat(ServiceWiseAct[x].AmounttoPay == null ? '0' : ServiceWiseAct[x].AmounttoPay == '' ? '0' : ServiceWiseAct[x].AmounttoPay);
              if (VSATP > 0) {
                let VAA = ServiceWiseAct[x].PackageAmount == null ? '0' : ServiceWiseAct[x].PackageAmount == '' ? '0' : ServiceWiseAct[x].PackageAmount;
                let VAGA = ServiceWiseAct[x].PackageGSTAmount == null ? '0' : ServiceWiseAct[x].PackageGSTAmount == '' ? '0' : ServiceWiseAct[x].PackageGSTAmount;
                let VTAA = ServiceWiseAct[x].TotalAccruedAmount == null ? '0' : ServiceWiseAct[x].TotalAccruedAmount == '' ? '0' : ServiceWiseAct[x].TotalAccruedAmount;
                let TId='#'+ServiceWiseAct[x].AmountToPayTextId;
                let VAmountToPayTextValue = $(TId).val();
                let VATP = VAmountToPayTextValue == null ? '0' : VAmountToPayTextValue == '' ? '0' : VAmountToPayTextValue;
                VAccruedAmount = VAccruedAmount + parseFloat(VAA);
                VAccruedGstAmount = VAccruedGstAmount + parseFloat(VAGA);
                VTotalAccruedAmount = VTotalAccruedAmount + parseFloat(VTAA);
                VAmountToPayPayment = VAmountToPayPayment + parseFloat(VATP);
              }
            }
            VAmountToPayGSTPayment = (VAmountToPayPayment * VGSTPer) / 100;
            VTotalAmountToPayPayment = VAmountToPayPayment + VAmountToPayGSTPayment;
            VTotalDuePayment = VTotalAccruedAmount - VTotalAmountToPayPayment;
            const SerPackRegPaymentArr = {
              LeadPaymentId: 0,
              ServicePackageId: SPId,
              LeadId: VInLeadId,
              PackageAmount: VAccruedAmount,
              PackageGSTAmount: VAccruedGstAmount,
              PackageTotalAmount: VTotalAccruedAmount,
              RecivedAmount: VAmountToPayPayment,
              RecivedGSTAmount: VAmountToPayGSTPayment,
              TotalAmount: VTotalAmountToPayPayment,
              PaymentStaus: VTotalDuePayment == 0 ? 'Paid' : VTotalDuePayment > 0 ? 'Partial Due' : VTotalDuePayment == VTotalAccruedAmount ? 'Due' : '',
              CompanyId: CompanyId == null ? '0' : CompanyId == '' ? '0' : CompanyId,
              PaymentModeId: PaymentModeId == null ? '0' : PaymentModeId == '' ? '0' : PaymentModeId,
              PaymentMode: PaymentModeTitle == null ? '' : PaymentModeTitle,
              BankName: BankName == null ? '' : BankName,
              Branch: Branch == null ? '' : Branch,
              ChequeDDNo: ChequeDDNo == null ? '' : ChequeDDNo,
              ChequeDDDate: ChequeDDDate == null ? CDate : ChequeDDDate == '' ? CDate : ChequeDDDate,
              TransactionDate: TransactionDate == null ? CDate : TransactionDate == '' ? CDate : TransactionDate,
              UTR: UTR == null ? '' : UTR,
              Settlement: Settlement == null ? '' : Settlement,
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
            };
            axios.post(global.APIURL + "/ServicePackagePayment", SerPackRegPaymentArr)
              .then(res => {
                if (spg == (SPres.data.length - 1)) {
                  alert('Lead service package payment successfully');
                  history.push("/ClientLead");
                }
              });
          }

        });

      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Services Package Payment | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">


          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle>Services Package Payment</CardTitle>
                <a href="/ConvertedInq" className="me-2">
                  Converted Lead
                </a>{" "}
                <span>/</span>
                <span className="ms-2">Services Package Payment</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/ConvertLeadIndividual">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>

            </Col>
          </Row>

          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div className="ind-card" id="ind-card">
                <Row>
                  <Col className="col-lg-12 col-12">
                    <Card>
                      <CardBody>
                        <Row className="align-items-center">
                          <Col className="col-md-10 col-12">
                            <Row>
                              <Col className="col-12 d-none d-md-block">
                                <h5 className="fw-bolder mb-2">{FullName} <span className="text-primary">({LeadCode})</span> </h5>
                                <hr />
                              </Col>
                              <Col className="col-md-2 col-lg-2 col-12 text-center d-block d-md-none">
                                <div className="">
                                  <img
                                    className="rounded-circle clientactimg"
                                    src={LeadProfileImage}
                                  ></img>
                                </div>
                              </Col>
                              <Col className="col-12 d-block d-md-none mt-3 text-center">
                                <h5 className="fw-bolder mb-2">{FullName} <span className="text-primary">({LeadCode})</span> </h5>
                                <hr />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="col-md-4 col-12 mb-md-0 mb-3">
                                <p className="mb-2 fontWeight500"> Mobile Number </p>
                                <Input
                                  type="text"
                                  className=""
                                  readOnly
                                  id="txtMobileNumber"
                                  defaultValue={PMobileNumber}
                                  placeholder="Enter Mobile Number"
                                />
                              </Col>
                              <Col className="col-md-4 col-12 mb-md-0 mb-3">
                                <p className="mb-2 fontWeight500"> Email Address </p>
                                <Input
                                  type="text"
                                  className=""
                                  readOnly
                                  id="txtEmailAddress"
                                  defaultValue={PEmailAddress}
                                  placeholder="Enter Email Address"
                                />
                              </Col>
                              <Col className="col-md-4 col-12 mb-md-0 mb-3">
                                <p className="mb-2 fontWeight500"> Type </p>
                                <Input
                                  type="text"
                                  readOnly
                                  className=""
                                  id="txtType"
                                  defaultValue={LeadType}
                                  placeholder="Enter Type"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col className="col-md-2 col-lg-2 col-12 float-end d-none d-md-block">
                            <div className="">
                              <img
                                className="rounded-circle float-end clientactimg"
                                src={LeadProfileImage}
                              ></img>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-lg-12 col-md-12 col-12">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col className="col-md-12 col-12">
                            <CardTitle>Personal Details</CardTitle>
                            <hr />
                          </Col>
                          <Col className="col-md-2 col-12 mb-3 mb-md-0">
                            <p className="mb-2 fontWeight500"> First Name </p>
                            <Input type="text" className="" id="txtFirstName" defaultValue={FirstName} readOnly />
                          </Col>
                          <Col className="col-md-2 col-12 mb-3 mb-md-0">
                            <p className="mb-2 fontWeight500"> Middle Name </p>
                            <Input type="text" className="" id="txtMiddleName" defaultValue={MiddleName} readOnly />
                          </Col>
                          <Col className="col-md-2 col-12 mb-3 mb-md-0">
                            <p className="mb-2 fontWeight500"> Last Name </p>
                            <Input type="text" className="" id="txtLastName" defaultValue={LastName} readOnly />
                          </Col>
                          <Col className="col-md-2 col-12 mb-3 mb-md-0">
                            <p className="mb-2 fontWeight500"> Gender </p>
                            <Input type="text" className="" id="txtGender" defaultValue={Gender} readOnly />
                          </Col>
                          <Col className="col-md-2 col-12 mb-3 mb-md-0">
                            <p className="mb-2 fontWeight500"> CIR ID </p>
                            <Input type="text" className="" id="txtCIRID" defaultValue={CIRID} readOnly />
                          </Col>
                          <Col className="col-md-2 col-12 mb-3 mb-md-0">
                            <p className="mb-2 fontWeight500"> State </p>
                            <Input type="text" className="" id="txtState" readOnly defaultValue={State} />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-md-3 col-12 mt-md-3 mt-0">
                            <p className="mb-2 fontWeight500"> District </p>
                            <Input type="text" className="" id="txtDistrict" readOnly defaultValue={District} />
                          </Col>

                          <Col className="col-md-3 col-12 mt-3">
                            <p className="mb-2 fontWeight500"> City </p>
                            <Input type="text" className="" id="txtCity" readOnly defaultValue={City} />
                          </Col>

                          <Col className="col-md-3 col-12 mt-3">
                            <p className="mb-2 fontWeight500"> Tehsil </p>
                            <Input type="text" className="" id="txtTehsil" readOnly defaultValue={Tehsil} />
                          </Col>
                          <Col className="col-md-3 col-12 mt-3">
                            <p className="mb-2 fontWeight500"> PinCode </p>
                            <Input type="text" className="" id="txtPinCode" readOnly defaultValue={PinCode} />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <Row>
                          <div className="col-lg-6">
                            <CardTitle>
                              Banking Source
                            </CardTitle>
                            <hr />
                            {SelectChannelDetail.map(item => (
                              <div className="team-modal-box" key={item.ChannelId}>
                                <div className="d-md-flex d-block align-items-center justify-content-between">
                                  <div className="d-md-flex d-block align-items-center">
                                    <div className="flex-shrink-0">
                                      <div className="avatar-md">
                                        <img
                                          src={item.ChannelImage}
                                          alt=""
                                          className="img-thumbnail"
                                        />
                                      </div>
                                    </div>
                                    <div className="flex-grow-1 ms-md-3 ms-0 mb-md-0 mb-3">
                                      <div className="font-14 dark-font mb-1">
                                        {item.ChannelName}
                                      </div>
                                      <div className="mb-1">
                                        <a href="" className="table-link-a font-12">
                                          Branch : {item.BranchName}
                                        </a>
                                      </div>
                                      <div className="">
                                        <a href="" className="table-link-a font-12">
                                          IFSE Code : {item.BranchIFSCCOde}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="bankdiv">
                                    <div className="">
                                      <a href="" className="table-link-a">
                                        <p className="mb-2 text-success">
                                          <i className="fas fa-phone-alt me-1"></i>
                                          {item.BranchContactNumber}
                                        </p>
                                      </a>
                                      <a href="" className="table-link-a">
                                        <p className="mb-0 text-primary">
                                          <i className="fas fa-envelope me-1"></i>
                                          {item.BranchEmailAddress}
                                        </p>
                                      </a>
                                    </div>

                                  </div>
                                </div>
                                <div className="d-md-flex d-block justify-content-between align-items-center">
                                  <div className="mb-md-0 mb-3">
                                    <span className="badge badge-soft-primary mt-2 p-2 me-2">
                                      {item.BranchState}
                                    </span>
                                    <span className="badge badge-soft-primary p-2 me-2">
                                      {item.BranchDistrict}
                                    </span>
                                    <span className="badge badge-soft-primary p-2 me-2">
                                      {item.BranchCity}
                                    </span>
                                    <span className="badge badge-soft-primary p-2 me-2">
                                      {item.BranchTehsil}
                                    </span>
                                    <span className="badge badge-soft-primary p-2 me-2">
                                      {item.Pincode}
                                    </span>
                                  </div>
                                  <div className="bankdiv1">
                                    <p className="mb-0 font-12">Approved</p>
                                    <p className="mb-0 font-12">
                                      CID : {item.ChannelCode}
                                    </p>
                                  </div>
                                </div>


                              </div>
                            ))}
                          </div>
                          <div className="col-lg-6 mt-3 mt-md-3 mt-lg-0">
                            <CardTitle>
                              Channel Partner
                            </CardTitle>
                            <hr />
                            {SelectChannelPartnerDetail.map(item => (
                              <div
                                className="team-modal-box"
                                key={item.ChannelPartnerId}
                              >
                                <div className="d-flex align-items-center border-bottom cpbottom">
                                  <div className="flex-shrink-0">
                                    <div className="avatar-md">
                                      <img
                                        src={item.ChannelPartnerLogo}
                                        alt=""
                                        className="img-thumbnail"
                                      />
                                    </div>
                                  </div>
                                  <div className="flex-grow-1 ms-3">
                                    <div className="font-14 dark-font mb-1">
                                      {item.Name}
                                    </div>
                                    <div className="mb-1">
                                      <a href="" className="table-link-a font-12">
                                        {item.Designation}
                                      </a>
                                    </div>
                                    <div className="">
                                      <a href="" className="table-link-a font-12">
                                        {item.ChannelName}
                                      </a>
                                    </div>
                                  </div>
                                </div>

                                <div className="modal-sub-box pt-3 d-block d-md-flex">
                                  <div className="department-box mb-2 mb-md-0">
                                    <a className="mb-0 text-success">
                                      <i className="fas fa-phone-alt me-1"></i>
                                      {item.MobileNumber}
                                    </a>
                                  </div>
                                  <div className="emp-code">
                                    <a className="mb-0 text-primary">
                                      <i className="fas fa-envelope me-1"></i>
                                      {item.Email}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col className="col-md-12 col-12">
                            <CardTitle>Service Package List</CardTitle>
                            <div className="table-responsive mb-3 mt-3">
                              <table className="table table-sm table-bordered align-middle">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Service Package / Activities</th>
                                    <th className="textalignend">Package Amount</th>
                                    <th className="textalignend">PackageGSTAmount</th>
                                    <th className="textalignend">Total Accrued Amount</th>
                                    <th className="textalignend">Total Due</th>
                                    <th className="textalignend">Paid Amount</th>
                                    <th className="textalignend">Amount to Pay</th>
                                    <th className="textalignend">Payment Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ServiceWiseActivityList.map(item => (
                                    <tr key={item.RowId}>
                                      <td>{item.SRNo}</td>
                                      <td>{item.ServicePackage}</td>
                                      <td className="textalignend">{item.PackageAmount}</td>
                                      <td className="textalignend">{item.PackageGSTAmount}</td>
                                      <td className="textalignend">{item.TotalAccruedAmount}</td>
                                      <td className="textalignend">{item.AccruedDue}</td>
                                      <td className="textalignend">{item.PaidAmount}</td>
                                      {item.AmounttoPayTextBoxFlag == 'A' ?
                                        <td className="textalignend">{item.AmounttoPay}</td>
                                        :
                                        <td className="textalignend">
                                          <input type="text" id={item.AmountToPayTextId} defaultValue={item.AmounttoPay} onChange={e => AmounttoPayOnChange(item.GSTPer)} className="form-control border_greyinput text-end" />
                                        </td>}
                                      <td className="textalignend">{item.PaymentStaus}</td>
                                    </tr>
                                  ))}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td> </td>
                                    <td className="text-end"> Total Amount :</td>
                                    <td className="textalignend"><input type="text" id="txtTotalPackageAmount" readOnly defaultValue={0} placeholder="" className="form-control border_greyinput text-end" /></td>
                                    <td className="textalignend"><input type="text" id="txtTotalGST" readOnly defaultValue={0} placeholder="" className="form-control border_greyinput text-end" /></td>
                                    <td className="textalignend"><input type="text" id="txtTotalAccruedAmount" readOnly defaultValue={0} placeholder="" className="form-control border_greyinput text-end" /></td>
                                    <td className="textalignend"><input type="text" id="txtTotalAccruedDue" readOnly defaultValue={0} placeholder="" className="form-control border_greyinput text-end" /></td>
                                    <td className="textalignend"><input type="text" id="txtTotalPaidAmount" readOnly defaultValue={0} placeholder="" className="form-control border_greyinput text-end" /></td>
                                    <td className="textalignend"><input type="text" id="txtTotalAmountToPay" readOnly defaultValue={0} placeholder="" className="form-control border_greyinput text-end" /></td>
                                    <td> </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-lg-2 col-md-4 col-12 mb-3 mb-md-3 mb-lg-0">
                            <p className="mb-2 fontWeight500">Total Accrued Amount </p>
                            <Input type="text" className="" id="txtFTotalAccruedAmount" defaultValue={0} readOnly />
                          </Col>
                          <Col className="col-lg-2 col-md-4 col-12 mb-3 mb-md-3 mb-lg-0">
                            <p className="mb-2 fontWeight500">Total Paid Amount </p>
                            <Input type="text" className="" id="txtFTotalPaidAmount" defaultValue={0} readOnly />
                          </Col>
                          <Col className="col-lg-2 col-md-4 col-12 mb-3 mb-md-3 mb-lg-0">
                            <p className="mb-2 fontWeight500"> Gross Amount to Pay Now </p>
                            <Input type="text" className="" id="txtFGrossAmountToPayNow" defaultValue={0} readOnly />
                          </Col>
                          <Col className="col-lg-2 col-md-4 col-12 mb-3 mb-md-0">
                            <p className="mb-2 fontWeight500"> Total Due Amount</p>
                            <Input type="text" className="" id="txtFTotalDueAmount" defaultValue={0} readOnly />
                          </Col>
                          <Col className="col-lg-2 col-md-4 col-12 mb-3 mb-md-0">
                            <p className="mb-2 fontWeight500"> GST Amount </p>
                            <Input type="text" className="" id="txtFGSTAmount" defaultValue={0} readOnly />
                          </Col>
                          <Col className="col-lg-2 col-md-4 col-12">
                            <p className="mb-2 fontWeight500"> Total Amount to Pay Now </p>
                            <Input type="text" className="" id="txtFTotalAmountToPayNow" readOnly defaultValue={0} />
                          </Col>
                          <Col className="col-lg-4 col-md-4 col-12 mt-3">
                            <p className="mb-2 fontWeight500"> Company </p>
                            <select id="ddlCompany" className="form-select" aria-label="Default select example" onChange={e => setCompanyId(e.target.value)}>
                              <option key="0" value="0">Select Company</option>
                              {CompanyList.map((item) => (
                                <option key={item.CompanyId} value={item.CompanyId}>{item.CompanyName}</option>
                              ))}
                            </select>
                          </Col>
                          <Col className="col-lg-4 col-md-4 col-12 mt-3">
                            <p className="mb-2 fontWeight500"> Payment Mode </p>
                            <select id="ddlPaymentMode" className="form-select" aria-label="Default select example" onChange={PaymentModeOnChange}>
                              <option key="0" value="0">Select Payment Mode</option>
                              {PaymentModeList.map((item) => (
                                <option key={item.PaymentModeId} value={item.PaymentModeId}>{item.PaymenMode}</option>
                              ))}
                            </select>
                          </Col>
                          {ShowTransactionNumber > 0 ?<Col className="col-lg-2 mt-3">
                            <p className="mb-2 fontWeight500"> Transaction Number</p>
                            <Input type="text" className="" id="txtChequeDDNo" placeholder="Enter Transaction No." onChange={e => setChequeDDNo(e.target.value)} />
                          </Col> : ''}
                          <Col className="col-lg-2 col-md-2 col-12 mt-3">
                            <p className="mb-2 fontWeight500"> Transaction Date</p>
                            <Input type="date" className="" id="txtTransactionDate" placeholder="Enter Transaction Date" onChange={e => setTransactionDate(e.target.value)} />
                          </Col>
                          {ShowBankName > 0 ? <Col className="col-lg-2 mt-3">
                            <p className="mb-2 fontWeight500"> Bank Name </p>
                            <Input type="text" className="" id="txtBank Name" placeholder="Enter Bank Name" onChange={e => setBankName(e.target.value)} />
                          </Col> : ''}
                          {ShowBranch > 0 ? <Col className="col-lg-2 mt-3">
                            <p className="mb-2 fontWeight500"> Branch</p>
                            <Input type="text" className="" id="txtBranch" placeholder="Enter Branch" onChange={e => setBranch(e.target.value)} />
                          </Col> : ''}

                          {ShowChequeDDDate > 0 ? <Col className="col-lg-2 mt-3" id="divChequeDDDate">
                            <p className="mb-2 fontWeight500"> Cheque/DD Date</p>
                            <Input type="date" className="" id="txtChequeDDDate" placeholder="Enter Cheque/DD Date" onChange={e => setChequeDDDate(e.target.value)} />
                          </Col> : ''}

                          {ShowUTR > 0 ? <Col className="col-lg-2 col-md-2 col-12 mt-3">
                            <p className="mb-2 fontWeight500"> UTR</p>
                            <Input type="text" className="" id="txtUTR" placeholder="Enter UTR" onChange={e => setUTR(e.target.value)} />
                          </Col> : ''}
                          {ShowSettlement > 0 ? <Col className="col-lg-2 col-md-2 col-12 mt-3">
                            <p className="mb-2 fontWeight500"> Settlement</p>
                            <Input type="text" className="" id="txtSettlement" placeholder="Enter Settlement" onChange={e => setSettlement(e.target.value)} />
                          </Col> : ''}
                        </Row>
                        <Row>
                          <div className="col-lg-12 mt-3">
                            <button type="button" className="btn btn_success me-2" onClick={e => ServicePackageRegister()}>Save</button>
                            <button type="button" className="btn btn_danger" data-bs-dismiss="modal">Close</button>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}
export default UiRating;