import React, {  Fragment, useState ,useEffect} from "react"
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory,useParams  } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
const cookies = new Cookies();
 
const EditServiceMaster = () => { 
  const [PurposeofVisit, setpurposeofvisit] = useState('');
  const [Title, setTitle] = useState('');
  const [Description, setDescription] = useState('');
  const [ServiceId, setId] = useState('');
  const [PurposeList, setPurposeList] = useState([]);
  const [PurposeId, setPurposeId] = useState("");
  const history = useHistory();


      let { id } = useParams();

      const updateProduct  = async (e) => {
        e.preventDefault();
       
        let ETTitle=event.target["txtTitle"].value; 
        let ETDescription=event.target["txtDescription"].value; 
        let ETId=event.target["hdfServiceId"].value;  
      
        if(ETTitle!='')
        {
            const Servicemaster = {
                ServiceId:ETId==''?'0':ETId,
                PurposeId : PurposeId,
                Title: ETTitle,
                Description: ETDescription,
                Flag:"A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
         
            }
            axios.post(global.APIURL+'/ServiceUpdate', Servicemaster)
            .then(res => 
            {
                    alert(res.data.StatusMessage);
                    history.push("/ServiceMaster");
            });
        }
    }

    useEffect(() => {

     
        
        if(id!=null)
        {
          if(id!="0")
          {
            let newid = id.split(':'); 
            
            
            getUserTyprById(newid[1]);
            getPurposeList();
          }
          else{
            history.push("/ServiceMaster");
          }
        }
        else{
          history.push("/ServiceMaster");
        }
    }, []);
 
    const getUserTyprById = async (UTId) => {
      var AUrl=global.APIURL+'/ServiceEdit/'+UTId;
        axios.get(AUrl)  
        .then(res => {  
         
           setId(res.data[0].ServiceId)
           setPurposeId(res.data[0].PurposeId);
           setTitle(res.data[0].Title);
           setDescription(res.data[0].Description);
        })
    }
    const getPurposeList = async () => {

      axios.get(global.APIURL+'/PurposeAll')
        .then(Tres => {
          let ActiveTag = Tres.data.map((kctag) => {
            return kctag
          })
          setPurposeList(ActiveTag);
        
        })
    }

    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Edit Service Master  | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Edit Service Master </CardTitle>
                <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/ServiceMaster"><span className="ms-2 me-2">Service Master</span></a>
                <span>/</span>
                <span className="ms-2">Edit Service Master</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/ServiceMaster">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={updateProduct}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
                <Row>
                <Col lg={4}>
                    <label className="form-label">Purpose</label>
                    <select className="form-select" name="ddlTags"  onChange={(e) => setPurposeId(e.target.value)}>
                      <option key="0" value="0">Select Purpose</option>
                          {PurposeList.map((item) => (
                            <option key={item.Id} value={item.Id} selected={PurposeId === item.Id}>
                              {item.Name}
                            </option>
                          ))}
                        </select>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Title</label>
                        <Input type="text" className="form-control" id="txtTitle" defaultValue={Title} placeholder="Enter Title"/>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-2">
                        <label className="form-label">Description</label>
                        <Input type="textarea" className="form-control" id="txtDescription" defaultValue={Description} placeholder="Enter Description"/>
                      </div>
                    </Col>
                    <Col lg={12}>
                        <input type="hidden" id="hdfServiceId" value={ServiceId} />    
                        <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                        <a className="btn btn_danger btn-sm ms-2" href="/StatusMaster">Clear</a>
                    </Col>
                  </Row>
               
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
      </div>
    )
}

export default  EditServiceMaster 