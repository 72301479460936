import React, { Component } from "react"
import MetaTags from 'react-meta-tags';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, CardBody, Col, Container, Row, Input , CardTitle, } from "reactstrap"

// Rating Plugin
import Rating from "react-rating"
import RatingTooltip from "react-rating-tooltip"

class UiRating extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tooltipContent: ["Rate 1", "Rate 2", "Rate 3", "Rate 4", "Rate 5"],
      tooltipContentMore: ["1", "2", "3", "4", "5", "6", "7", "8"],
      tooltipContentHalf: ["6", "7", "8", "9", "10"],
      tooltipContentMiddle: [
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      tooltipContentStep: ["2", "4", "6", "8", "10"],
      default: "",
      half: "",
      customize: "",
    }
  }

  render() {
    return (
      <React.Fragment>
       <div className="page-content">
          <MetaTags>
            <title>Employee Type | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          <Col className="col-12 mb-3">
                <div className="breadcrumb align-items-center justify-content-end">
                    <a href="/dashboard" className="me-2">Home</a> <span>/</span><span className="ms-2">Employee Type</span>
                </div>
              </Col>
          
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
              <Col className="col-12">
                <div className ="d-flex align-items-center justify-content-between">
                 <div className="d-flex">
                  <CardTitle className="h6 mb-2">Employee Type </CardTitle>
                 </div>
                </div>
              
              </Col>
            <Row>
              
              <Col className="col-12 mt-4">
                <Card>
                  <CardBody>
                     {/* User list */}
                     <Row>
                     <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Employee Type Title"
                            />
                          </div>
                        </Col>
                     </Row>
                     <Row>
                     <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Short Code"
                            />
                          </div>
                        </Col>
                     </Row>
                     <Row>
                     <Col lg={6}>
                <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control pb-4"
                              id="formrow-InputCity"
                              placeholder="Description"
                            />
                          </div>
                </Col>
                     </Row>
                    
                      <Row>
                        <Col lg={4}>
                        <div  className="form-check">
                                          <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                        </Col>
                        <Col className="col-12 mt-3">
                <div className ="d-flex align-items-center
                 justify-content-between">
                  <div className ="d-flex">
                    <a className ="btn btn-primary btn-sm" href="/">Save</a>
                    <a className ="btn btn-primary btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
                      </Row>

                      <Row className="mt-4">
                          <Col className="col-12">
                              <div className ="d-flex justify-content-end">
                                <div className="border-bottom ms-4">
                                        Search <i className="mdi mdi-filter h4 ms-3"></i>
                                </div>
                              </div>
                          </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col lg={12}>
                        <div className="table-responsive">
               <table className="table table-striped">
                      <thead>
                        <tr className="">
                          <th scope="col">Sr</th>
                          <th scope="col" className="">Title</th>
                          <th scope="col" className="">Short code</th>
                          <th scope="col" className="">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="">1</th>
                          <td>FullTime</td>
                          <td>FT</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil h4 me-3"></i></a>
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-check-box-outline h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="">2</th>
                          <td>PartTime</td>
                          <td>PT</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil h4 me-3"></i></a>
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-check-box-outline h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="">3</th>
                          <td>Trainee</td>
                          <td>TT</td>
                          <td className="width-9">
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil h4 me-3"></i></a>
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-check-box-outline h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="">3</th>
                          <td>Permanent</td>
                          <td>pp</td>
                          <td className="width-9">
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil h4 me-3"></i></a>
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-check-box-outline h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="">3</th>
                          <td>Freelancer</td>
                          <td>FL</td>
                          <td className="width-9">
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil h4 me-3"></i></a>
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-check-box-outline h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>
                       
                      </tbody>
                  </table>
               </div>
                        </Col>
                      </Row>
                     
                               <nav className="mt-3" aria-label="Page navigation example">
  <ul className="pagination justify-content-center">
    <li className="page-item disabled">
      <a className="page-link" href="#" tabIndex ="-1" aria-disabled="true">Previous</a>
    </li>
    <li className="page-item"><a className="page-link" href="#">1</a></li>
    <li className="page-item"><a className="page-link" href="#">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item">
      <a className="page-link" href="#">Next</a>
    </li>
  </ul>
</nav>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default UiRating
