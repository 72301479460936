import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import axios from 'axios'
import { Col, Row, CardTitle, Card, CardBody, Input, Button } from "reactstrap";
import ServicesPackageRegistration from "./ServicesPackageRegistration";
// Rating Plugin1 

const UiRating = () => {
  const [LeadCode, setLeadCode] = useState('');
  const [FullName, setFullName] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [MiddleName, setMiddleName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Gender, setGender] = useState("");
  const [CIRID, setCIRID] = useState("");
  const [CIRPassword, setCIRPassword] = useState("");
  const [LeadProfileImage, setLeadProfileImage] = useState("");
  const [PMobileNumber, setPMobileNumber] = useState("");
  const [PEmailAddress, setPEmailAddress] = useState("");
  const [LeadType, setLeadType] = useState("");
  const [State, setState] = useState("");
  const [District, setDistrict] = useState("");
  const [City, setCity] = useState("");
  const [Tehsil, setTehsil] = useState("");
  const [PinCode, setPinCode] = useState("");

  const [SCreateDate, setSCreateDate] = useState("");
  const [SConvertDate, setSConvertDate] = useState("");
  const [SRegisterDate, setSRegisterDate] = useState("");
  const [SCloseDate, setSCloseDate] = useState("");

  const [SelectChannelDetail, setSelectChannelDetail] = useState([]);
  const [SelectChannelPartnerDetail, setSelectChannelPartnerDetail] = useState([]);

  const [QualificationList, setQualificationList] = useState([]);
  const [CategoryList, setCategoryList] = useState([]);
  const [ReligionList, setReligionList] = useState([]);
  const [OccupationList, setOccupationList] = useState([]);

  const [AccountTypeList, setAccountTypeList] = useState([]);

  const [LeadFollowupList, setLeadFollowupList] = useState([]);



  useEffect(() => {
    let LeadId = localStorage.getItem("ConvertIndividualLeadId");
    if (LeadId != null) {
      if (LeadId != "0") {
        getLeadBankDetails();
        getLeadDetailById(LeadId);
        getSelectChannelDetailById(LeadId);
        getSelectChannelPartnerDetailById(LeadId);
        getQualificationList();
        getCategoryList();
        getReligionList();
        getOccupationList();
        getAccountTypeList();
        getLeadProfessionalDetail();
        getLeadFollowupList(LeadId);

      }
      else {
        history.push("/ConvertedInq");
      }
    }
    else {
      history.push("/ConvertedInq");
    }
  }, []);

  const getLeadFollowupList = async (LId) => {
    var AUrl = global.APIURL + '/WebFollowupListByLeadId/' + LId;
    fetch(AUrl)
      .then((res) => res.json())
      .then((json) => {
        setLeadFollowupList(json)
      })
  }

  const getQualificationList = async () => {
    var AUrl = global.APIURL + '/QualificationActive/';
    axios.get(AUrl)
      .then(QLres => {
        let VqualificationList = QLres.data.map(qualification => {
          return qualification;
        });
        setQualificationList(VqualificationList);
      });
  };

  const getCategoryList = async () => {
    var AUrl = global.APIURL + '/CategoryActive/';
    axios.get(AUrl)
      .then(CLres => {
        let VcategoryList = CLres.data.map(category => {
          return category;
        });
        setCategoryList(VcategoryList);
      });
  };

  const getReligionList = async () => {
    var AUrl = global.APIURL + '/ReligionActive/';
    axios.get(AUrl)
      .then(RLres => {
        let VreligionList = RLres.data.map(religion => {
          return religion;
        });
        setReligionList(VreligionList);
      });
  };

  const getOccupationList = async () => {
    var AUrl = global.APIURL + '/OccupationActive/';
    axios.get(AUrl)
      .then(RLres => {
        let VoccupationList = RLres.data.map(occupation => {
          return occupation;
        });
        setOccupationList(VoccupationList);
      });
  };

  const getAccountTypeList = async () => {
    var AUrl = global.APIURL + '/AccountTypeActiveList/';
    axios.get(AUrl)
      .then(RLres => {
        let AccountTypeList = RLres.data.map(AT => {
          return AT;
        });
        setAccountTypeList(AccountTypeList);
      });
  };

  const getLeadDetailById = async (LId) => {
    var AUrl = global.APIURL + '/WebLeadEdit/' + LId;
    axios.get(AUrl)
      .then(res => {
        setLeadCode(res.data[0].LeadCode);
        setFirstName(res.data[0].FirstName);
        setMiddleName(res.data[0].MiddleName);
        setLastName(res.data[0].LastName);
        setFullName(res.data[0].FullName);
        setPMobileNumber(res.data[0].MobileNumber);
        setPEmailAddress(res.data[0].EmailAddress);
        setLeadType(res.data[0].LeadType);
        setLeadProfileImage(res.data[0].LeadProfileImage)
        setGender(res.data[0].Gender);
        setCIRID(res.data[0].CIRID);
        setCIRPassword(res.data[0].CIRPassword);
        setState(res.data[0].State);
        setDistrict(res.data[0].District);
        setCity(res.data[0].City);
        setTehsil(res.data[0].Tehsil)
        setPinCode(res.data[0].Pincode);
        setSCreateDate(res.data[0].CreateDate);
        setSConvertDate(res.data[0].ConvertDate);
        setSRegisterDate(res.data[0].RegisterDate);
        setSCloseDate(res.data[0].CloseDate);
      })
  }

  const getSelectChannelDetailById = async LeadId => {
    var AUrl = global.APIURL + '/WebLeadChannelEdit/' + LeadId;
    axios.get(AUrl)
      .then(LCDres => {
        let LeadChannelL = LCDres.data.map(kcchannel => {
          return kcchannel;
        });
        setSelectChannelDetail(LeadChannelL);
      });
  };

  const getSelectChannelPartnerDetailById = async LeadId => {
    var AUrl = global.APIURL + '/WebLeadChannelPartnerEdit/' + LeadId;
    axios.get(AUrl)
      .then(LCPDres => {
        let LeadPChannelL = LCPDres.data.map(kcchannepl => {
          return kcchannepl;
        });
        setSelectChannelPartnerDetail(LeadPChannelL);
      });
  };

  const [LeadProfessionalDetailId, setLeadProfessionalDetailId] = useState(0);
  const [QualificationId, setQualificationId] = useState(0);
  const [CategoryId, setCategoryId] = useState(0);
  const [ReligionId, setReligionId] = useState(0);
  const [OccupationId, setOccupationId] = useState(0);
  //const [AccountTypeId, setAccountTypeId] = useState(0);
  const [Department, setDepartment] = useState('');
  const [Designation, setDesignation] = useState('');
  const [Income, setIncome] = useState('');


  const getLeadProfessionalDetail = async () => {
    let VLeadId = localStorage.getItem("ConvertIndividualLeadId");
    const LPitem = { LeadId: VLeadId };
    var AUrl = global.APIURL + '/LeadProfessionalDetailSelectAll';
    axios.post(AUrl, LPitem)
      .then(res => {
        if (res.data.length > 0) {
          setLeadProfessionalDetailId(res.data[0].LeadProfessionalDetailId)
          setQualificationId(res.data[0].QualificationId)
          setCategoryId(res.data[0].CategoryId);
          setReligionId(res.data[0].ReligionId);
          setOccupationId(res.data[0].OccupationId);
          setDepartment(res.data[0].Department);
          setDesignation(res.data[0].Designation);
          setIncome(res.data[0].Income);
        }
      });
  };

  const LeadProfessionalDetailsSave = () => {
    let VLeadId = localStorage.getItem("ConvertIndividualLeadId");
    const LPDitem = {
      LeadId: VLeadId,
      QualificationId: QualificationId,
      CategoryId: CategoryId,
      ReligionId: ReligionId,
      OccupationId: OccupationId,
      Department: Department,
      Designation: Designation,
      Income: Income,
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
    };

    axios.post(global.APIURL + "/LeadProfessionalDetailSave", LPDitem)
      .then(res => {
        alert(res.data.StatusMessage);
        getLeadProfessionalDetail();
      });
  };

  const [BankName, setBankName] = useState([]);
  const [Branch, setBranch] = useState([]);
  const [IFSECode, setIFSECode] = useState([]);
  const [AccountName, setAccountName] = useState([]);
  const [AccountNumber, setAccountNumber] = useState([]);
  const [AccountTypeId, setAccountTypeId] = useState([]);


  const LeadBankDetailSave = () => {
    let VLeadId = localStorage.getItem("ConvertIndividualLeadId");
    const LBankItems = {
      LeadId: VLeadId,
      BankName: BankName,
      Branch: Branch,
      IFSECode: IFSECode,
      AccountName: AccountName,
      AccountNumber: AccountNumber,
      AccountTypeId: AccountTypeId,
      AccountTypeTitle: "",
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
    }
    axios.post(global.APIURL + "/LeadBankAccountDetailSave", LBankItems)
      .then(res => {
        alert(res.data.StatusMessage);
        getLeadBankDetails();
      });
  }

  const getLeadBankDetails = () => {
    let VLeadId = localStorage.getItem("ConvertIndividualLeadId");
    const LBankDetailitem = { LeadId: VLeadId };
    var AUrl = global.APIURL + '/LeadBankAccountDetailAll';
    axios.post(AUrl, LBankDetailitem)
      .then(res => {
        if (res.data.length > 0) {
          setBankName(res.data[0].BankName);
          setBranch(res.data[0].Branch);
          setIFSECode(res.data[0].IFSECode);
          setAccountName(res.data[0].AccountName);
          setAccountNumber(res.data[0].AccountNumber);
          setAccountTypeId(res.data[0].AccountTypeId);
        }
        else {
          for (let ad = 0; ad < SelectChannelDetail.length; ad++) {
            const ChannelDetail = SelectChannelDetail[ad];
            var VBankName = ChannelDetail.ChannelName == null ? '' : ChannelDetail.ChannelName;
            var VBranch = ChannelDetail.BranchName == null ? '' : ChannelDetail.BranchName;
            var VIFSECode = ChannelDetail.BranchIFSCCOde == null ? '' : ChannelDetail.BranchIFSCCOde;
            setBankName(VBankName);
            setBranch(VBranch);
            setIFSECode(VIFSECode);
          }
        }
      });
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Converted Lead Detail | KC Admin App</title>
      </MetaTags>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <div className="ind-card" id="ind-card">
            <Row>
              <Col className="col-lg-12 col-12">
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col className="col-md-10 col-12">
                        <Row>
                          <Col className="col-12 d-none d-md-block">
                            <h5 className="fw-bolder mb-2">{FullName} <span className="text-primary">({LeadCode})</span></h5>
                            <hr />
                          </Col>
                          <Col className="col-md-2 col-lg-2 col-12 text-center d-block d-md-none">
                            <div className="">
                              <img
                                className="rounded-circle clientactimg"
                                src={LeadProfileImage}
                              ></img>
                            </div>
                          </Col>
                          <Col className="col-12 d-block d-md-none mt-3 text-center">
                            <h5 className="fw-bolder mb-2">{FullName} <span className="text-primary">({LeadCode})</span></h5>
                            <hr />
                          </Col>
                        </Row>
                        <Row className="mt-2 mb-2">
                          <Col className="col-md-4 col-12 mb-md-0 mb-3">
                            <p className="mb-2 fontWeight500"> Mobile Number </p>
                            <Input
                              type="text"
                              className=""
                              readOnly
                              id="txtMobileNumber"
                              defaultValue={PMobileNumber}
                              placeholder="Enter Mobile Number"
                            />
                          </Col>
                          <Col className="col-md-4 col-12 mb-md-0 mb-3">
                            <p className="mb-2 fontWeight500"> Email Address </p>
                            <Input
                              type="text"
                              className=""
                              readOnly
                              id="txtEmailAddress"
                              defaultValue={PEmailAddress}
                              placeholder="Enter Email Address"
                            />
                          </Col>
                          <Col className="col-md-4 col-12 mb-md-0 mb-3">
                            <p className="mb-2 fontWeight500"> Type </p>
                            <Input
                              type="text"
                              readOnly
                              className=""
                              id="txtType"
                              defaultValue={LeadType}
                              placeholder="Enter Type"
                            />
                          </Col>
                          <Col className="col-md-3 col-12 mobile-mt mt-3">
                            <p className="mb-2 fontWeight500"> Inquiry Date </p>
                            <Input
                              type="text"
                              readOnly
                              defaultValue={SCreateDate}
                              id="txtInquiryDate"
                            />
                          </Col>
                          <Col className="col-md-3 col-12 mobile-mt mt-3">
                            <p className="mb-2 fontWeight500"> Converted Date </p>
                            <Input
                              type="text"
                              readOnly
                              defaultValue={SConvertDate}
                              id="txtConvertDate"
                            />
                          </Col>
                          <Col className="col-md-3 col-12 mobile-mt mt-3">
                            <p className="mb-2 fontWeight500"> Registered Date </p>
                            <Input
                              type="text"
                              readOnly
                              defaultValue={SRegisterDate}
                              id="txtRegisteredDate"
                            />
                          </Col>

                          <Col className="col-md-3 col-12 mobile-mt mt-3">
                            <p className="mb-2 fontWeight500"> Closed Date </p>
                            <Input
                              type="text"
                              readOnly
                              defaultValue={SCloseDate}
                              id="txtClosedDate"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col className="col-md-2 col-lg-2 col-12 float-end d-none d-md-block">
                        <div className="">
                          <img
                            className="rounded-circle float-end clientactimg"
                            src={LeadProfileImage}
                          ></img>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-lg-12 col-md-12 col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-md-12 col-12">
                        <CardTitle>Personal Details</CardTitle>
                        <hr className="mb-2" />
                      </Col>
                      <Col className="col-md-2 col-12 mb-3 mb-md-0">
                        <p className="mb-2 fontWeight500"> First Name </p>
                        <Input type="text" className="" id="txtFirstName" defaultValue={FirstName} readOnly />
                      </Col>
                      <Col className="col-md-2 col-12 mb-3 mb-md-0">
                        <p className="mb-2 fontWeight500"> Middle Name </p>
                        <Input type="text" className="" id="txtMiddleName" defaultValue={MiddleName} readOnly />
                      </Col>
                      <Col className="col-md-2 col-12 mb-3 mb-md-0">
                        <p className="mb-2 fontWeight500"> Last Name </p>
                        <Input type="text" className="" id="txtLastName" defaultValue={LastName} readOnly />
                      </Col>
                      <Col className="col-md-2 col-12 mb-3 mb-md-0">
                        <p className="mb-2 fontWeight500"> Gender </p>
                        <Input type="text" className="" id="txtGender" defaultValue={Gender} readOnly />
                      </Col>
                      <Col className="col-md-2 col-12 mb-3 mb-md-0">
                        <p className="mb-2 fontWeight500"> CIR ID </p>
                        <Input type="text" className="" id="txtCIRID" defaultValue={CIRID} readOnly />
                      </Col>
                      <Col className="col-md-2 col-12 mb-3 mb-md-0">
                        <p className="mb-2 fontWeight500"> State </p>
                        <Input type="text" className="" id="txtState" readOnly defaultValue={State} />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col className="col-md-3 col-12 mt-md-3 mt-0">
                        <p className="mb-2 fontWeight500"> District </p>
                        <Input type="text" className="" id="txtDistrict" readOnly defaultValue={District} />
                      </Col>

                      <Col className="col-md-3 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> City </p>
                        <Input type="text" className="" id="txtCity" readOnly defaultValue={City} />
                      </Col>

                      <Col className="col-md-3 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Tehsil </p>
                        <Input type="text" className="" id="txtTehsil" readOnly defaultValue={Tehsil} />
                      </Col>
                      <Col className="col-md-3 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> PinCode </p>
                        <Input type="text" className="" id="txtPinCode" readOnly defaultValue={PinCode} />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <div className="col-lg-7">
                        <CardTitle>
                          Banking Source
                        </CardTitle>
                        <hr className="mb-2" />
                        {SelectChannelDetail.map(item => (
                          <div className="team-modal-box mb-1" key={item.ChannelId}>
                            <div className="d-md-flex d-block align-items-center justify-content-between">
                              <div className="d-md-flex d-block align-items-center">

                                <div className="flex-shrink-0">
                                  <div className="avatar-md">
                                    <img
                                      src={item.ChannelImage}
                                      alt=""
                                      className="img-thumbnail"
                                    />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-md-3 ms-0 mb-md-0 mb-3">
                                  <div className="font-14 dark-font mb-1">
                                    {item.ChannelName}
                                  </div>
                                  <div className="mb-1">
                                    <a href="" className="table-link-a font-12">
                                      Branch : {item.BranchName}
                                    </a>
                                  </div>
                                  <div className="">
                                    <a href="" className="table-link-a font-12">
                                      IFSE Code : {item.BranchIFSCCOde}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="bankdiv">
                                <div className="">
                                  <a href="" className="table-link-a">
                                    <p className="mb-2 text-success">
                                      <i className="fas fa-phone-alt me-1"></i>
                                      {item.BranchContactNumber}
                                    </p>
                                  </a>
                                  <a href="" className="table-link-a">
                                    <p className="mb-0 text-primary">
                                      <i className="fas fa-envelope me-1"></i>
                                      {item.BranchEmailAddress}
                                    </p>
                                  </a>
                                </div>

                              </div>
                            </div>
                            <div className="d-md-flex d-block justify-content-between align-items-center">
                              <div className="mb-md-0 mb-3">
                                <div className="">
                                  <span className="badge badge-soft-primary p-2 me-2">
                                    {item.BranchState}
                                  </span>
                                  <span className="badge badge-soft-primary p-2 me-2">
                                    {item.BranchDistrict}
                                  </span>
                                  <span className="badge badge-soft-primary p-2 me-2">
                                    {item.BranchCity}
                                  </span>
                                  <span className="badge badge-soft-primary p-2 me-2">
                                    {item.BranchTehsil}
                                  </span>
                                  <span className="badge badge-soft-primary p-2 me-2">
                                    {item.Pincode}
                                  </span>
                                </div>
                              </div>
                              <div className="bankdiv1">
                                <p className="mb-0 font-12">Approved</p>
                                <p className="mb-0 font-12">
                                  CID : {item.ChannelCode}
                                </p>
                              </div>

                            </div>


                          </div>
                        ))}
                      </div>
                      <div className="col-lg-5 mt-3 mt-md-3 mt-lg-0">
                        <CardTitle>
                          Channel Partner
                        </CardTitle>
                        <hr className="mb-2" />
                        {SelectChannelPartnerDetail.map(item => (
                          <div className="team-modal-box mb-1" key={item.ChannelPartnerId}>
                            <div className="d-flex align-items-center border-bottom cpbottom">
                              <div className="flex-shrink-0">
                                <div className="avatar-md">
                                  <img
                                    src={item.ChannelPartnerLogo}
                                    alt=""
                                    className="img-thumbnail"
                                  />
                                </div>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <div className="font-14 dark-font mb-1">
                                  {item.Name}
                                </div>
                                <div className="mb-1">
                                  <a href="" className="table-link-a font-12">
                                    {item.Designation}
                                  </a>
                                </div>
                                <div className="">
                                  <a href="" className="table-link-a font-12">
                                    {item.ChannelName}
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div className="modal-sub-box pt-3 d-block d-md-flex">
                              <div className="department-box mb-2 mb-md-0">
                                <a className="mb-0 text-success">
                                  <i className="fas fa-phone-alt me-1"></i>
                                  {item.MobileNumber}
                                </a>
                              </div>
                              <div className="emp-code">
                                <a className="mb-0 text-primary">
                                  <i className="fas fa-envelope me-1"></i>
                                  {item.Email}
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row data-repeater-item className="align-items-end mb-2">
                      <Col className="col-md-12 col-12">
                        <CardTitle>Professional Details</CardTitle>
                        <hr />
                      </Col>
                      <Col lg={3} className="mt-2">
                        <p className="mb-2 fontWeight500"> Qualification </p>
                        <select className="form-select" id="ddlQualification" name="ddlQualification" onChange={e => setQualificationId(e.target.value)}>
                          <option value={"0"}>Select Qualification</option>
                          {QualificationList.map(item => (
                            <option key={item.Id} value={item.Id} selected={item.Id === QualificationId}>{item.Title}</option>
                          ))}
                        </select>
                      </Col>
                      <Col lg={3} className="mt-2">
                        <p className="mb-2 fontWeight500"> Category </p>
                        <select className="form-select" id="ddlCategory" name="ddlCategory" onChange={e => setCategoryId(e.target.value)}>
                          <option value={"0"}>Select Category</option>
                          {CategoryList.map(item => (
                            <option key={item.Id} value={item.Id} selected={item.Id === CategoryId}>{item.Title}</option>
                          ))}
                        </select>
                      </Col>
                      <Col lg={3} className="mt-2">
                        <p className="mb-2 fontWeight500"> Religion </p>
                        <select className="form-select" id="ddlReligion" name="ddlReligion" onChange={e => setReligionId(e.target.value)}>
                          <option value={"0"}>Select Religion</option>
                          {ReligionList.map(item => (
                            <option key={item.Id} value={item.Id} selected={item.Id === ReligionId}>{item.Title}</option>
                          ))}
                        </select>
                      </Col>
                      <Col lg={3} className="mt-2">
                        <p className="mb-2 fontWeight500"> Occupation </p>
                        <select className="form-select" id="ddlOccupation" name="ddlOccupation" onChange={e => setOccupationId(e.target.value)}>
                          <option value={"0"}>Select Occupation</option>
                          {OccupationList.map(item => (
                            <option key={item.Id} value={item.Id} selected={item.Id === OccupationId}>{item.Title}</option>
                          ))}
                        </select>
                      </Col>
                      <Col lg={3} className="mt-2">
                        <p className="mb-2 fontWeight500"> Department </p>
                        <Input type="text" className="form-control" id="txtDepartment" placeholder="Enter Department" defaultValue={Department} onChange={(e) => setDepartment(e.target.value)} />
                      </Col>
                      <Col lg={3} className="mt-2">
                        <p className="mb-2 fontWeight500"> Designation </p>
                        <Input type="text" className="form-control" id="txtDesignation" placeholder="Enter Designation" defaultValue={Designation} onChange={(e) => setDesignation(e.target.value)} />
                      </Col>
                      <Col lg={3} className="mt-2">
                        <p className="mb-2 fontWeight500"> Income </p>
                        <Input type="text" className="form-control" id="txtIncome" placeholder="Enter Income" defaultValue={Income} onChange={(e) => setIncome(e.target.value)} />
                      </Col>
                      <Col lg={3} className="mt-2">
                      </Col>
                      <Col lg="3" className="mt-3">
                        <Button onClick={LeadProfessionalDetailsSave} color="primary" className="btn-sm mb-1">{LeadProfessionalDetailId == 0 ? 'Save' : 'Update'}</Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row data-repeater-item className="align-items-end mb-2">
                      <Col className="col-md-12 col-12">
                        <CardTitle>Account Details</CardTitle>
                        <hr />
                      </Col>
                      <Col lg={4} className="mt-2">
                        <p className="mb-2 fontWeight500"> Bank Name </p>
                        <Input type="text" className="form-control" id="txtBankName" placeholder="Enter Bank Name" defaultValue={BankName} onChange={(e) => setBankName(e.target.value)} />
                      </Col>
                      <Col lg={4} className="mt-2">
                        <p className="mb-2 fontWeight500"> Branch </p>
                        <Input type="text" className="form-control" id="txtBranch" placeholder="Enter Branch" defaultValue={Branch} onChange={(e) => setBranch(e.target.value)} />
                      </Col>
                      <Col lg={4} className="mt-2">
                        <p className="mb-2 fontWeight500"> IFSE Code </p>
                        <Input type="text" className="form-control" id="txtIFSECode" placeholder="Enter IFSE Code" defaultValue={IFSECode} onChange={(e) => setIFSECode(e.target.value)} />
                      </Col>

                      <Col lg={4} className="mt-2">
                        <p className="mb-2 fontWeight500"> Account Name </p>
                        <Input type="text" className="form-control" id="txtAccountName" placeholder="Enter Account Name" defaultValue={AccountName} onChange={(e) => setAccountName(e.target.value)} />
                      </Col>
                      <Col lg={4} className="mt-2">
                        <p className="mb-2 fontWeight500"> Account Number </p>
                        <Input type="text" className="form-control" id="txtAccountNo" placeholder="Enter Account Number" defaultValue={AccountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
                      </Col>
                      <Col lg={4} className="mt-2">
                        <p className="mb-2 fontWeight500"> Account Type </p>
                        <select className="form-select" id="ddlAccountType" name="ddlAccountType" onChange={e => setAccountTypeId(e.target.value)}>
                          <option value={"0"}>Select Account Type</option>
                          {AccountTypeList.map(item => (
                            <option key={item.AccountTypeId} value={item.AccountTypeId} selected={item.AccountTypeId === AccountTypeId}>{item.AccountTypeName}</option>
                          ))}
                        </select>
                      </Col>

                      <Col lg="3" className="mt-3">
                        <Button onClick={LeadBankDetailSave} color="primary" className="btn-sm mb-1">{LeadProfessionalDetailId == 0 ? 'Save' : 'Update'}</Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-12 col-md-12 mb-2">
                        <CardTitle>Followup List</CardTitle>
                        <hr />
                      </Col>
                      <Col className="col-lg-12 col-md-12 mt-3">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th className="text-center" scope="col">#</th>
                              <th className="text-center" scope="col">Date</th>
                              <th className="text-center" scope="col">Time</th>
                              <th className="text-center" scope="col">Mode</th>
                              <th scope="col">Notes for Next Follow-up</th>
                              <th scope="col">Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {LeadFollowupList.map((record, index) => {
                              return (
                                <tr key={record.RowNo}>
                                  <td className="text-center">{record.RowNo}</td>
                                  <td className="text-center">{record.ScheduledDate}</td>
                                  <td className="text-center">{record.ScheduledTime}</td>
                                  <td className="text-center">{record.Mode}</td>
                                  <td>{record.Agenda}</td>
                                  <td>{record.Remark}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <ServicesPackageRegistration />
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}
export default UiRating;
