import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import img2 from "assets/images/PAN.png";
import axios from "axios";
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody, Input, Label, Button } from "reactstrap";
import Commheader from "../../../components/HorizontalLayout/Header";
import PlacholderImgC from "assets/images/favicon.png";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';

const SerPackageDetail = () => {
  const history = useHistory();
  const [StateList, setStateList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);
  const [CompanyDistrictId, setCompanyDistrictId] = useState("");
  const [CompanyTehsilId, setCompanyTehsilId] = useState("");
  const [LeadPartnerList, setLeadPartnerList] = useState([]);
  const [AddTehsil, setAddTehsil] = useState('D');
  const [AddCity, setAddCity] = useState('D');
  const [CompanyCityId, setCompanyCityId] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Branchcode, setBranchcode] = useState("");
  const [CompanyList, setCompanyList] = useState([]);
  const [NetworkSource, setNetworkSource] = useState("");
  const [NetworkTypeList, setNetworkTypeList] = useState([]);
  const [NetworkType, setNetworkType] = useState("");
  const [DistrictName, setDistrictName] = useState([]);
  const [TehsilName, setTehsilName] = useState([]);
  const [CityName, setCityName] = useState([]);

  const [LeadContactList, setLeadContactList] = useState([]);
  const [Address, setAddress] = useState("");
  const [LeadFollowupList, setLeadFollowupList] = useState([]);
  const [ChannelTypeId, setChannelTypeId] = useState("");
  const [StateName, setStateName] = useState("");
  const [EditcorLeadId, setcorEditLeadId] = useState("");
  const [ServiceTitleList, setServiceTitleList] = useState([]);


  const [Email, setEmail] = useState([]);
  const [Name, setName] = useState([]);
  const [MobileNo, setMobileNo] = useState([]);
  const [Designation, setDesignation] = useState([]);

  const [MeetNote, setMeetNote] = useState([]);
  const [MeetSummary, setMeetSummary] = useState([]);

  const [EmailIdd, setmEmailId] = useState([]);
  const [SerPackageList, setSerPackageList] = useState([]);
  const [ClientList, setClientList] = useState([]);



  let CoLeadId = localStorage.getItem("CorporateleadId");
  let VSerPackageId = localStorage.getItem("DSerPackageId") == null ? '0' : localStorage.getItem("DSerPackageId") == '' ? '0' : localStorage.getItem("DSerPackageId");

  // let { id } = useParams();

  // let new_id = id.split(':');
  // const VSerPackageId = new_id[1];

  useEffect(() => {

    getServiceDetails();
    getCompanyList();
    getNetWorkList();
    getSerPackageDetails();


  }, []);

  const getServiceDetails = async () => {
    let VCorporateleadId = localStorage.getItem("CorporateleadId");

    const SData = {
      "Id": VCorporateleadId
    }

    axios.post(global.APIURL + '/CorporateleadclientDetails ', SData)
      .then(res => {
        setClientList(res.data.Result[0].ClientDetails);
        setNetworkSource(res.data.Result[0].ClientDetails[0].CompanyName);
        setNetworkType(res.data.Result[0].ClientDetails[0].ChannelTypeName);
        setAddress(res.data.Result[0].ClientDetails[0].Address);
        setStateName(res.data.Result[0].ClientDetails[0].StateName);
        setDistrictName(res.data.Result[0].ClientDetails[0].districtName);
        setTehsilName(res.data.Result[0].ClientDetails[0].TehsilName);
        setCityName(res.data.Result[0].ClientDetails[0].CityName);
        setPincode(res.data.Result[0].ClientDetails[0].Pincode);
        setBranchcode(res.data.Result[0].ClientDetails[0].Branchcode);
        setmEmailId(res.data.Result[0].ClientDetails[0].EmailId);
        setLeadContactList(res.data.Result[0].de);
      });
  }

  const getSerPackageDetails = () => {

    let VCorporateleadId = localStorage.getItem("CorporateleadId");

    const SData = {
      "Id": VCorporateleadId,
      "ServicesPackageId": VSerPackageId
    }

    axios.post(global.APIURL + '/CorporateLeadServicepaymentDetails ', SData)
      .then(res => {
        setSerPackageList(res.data.Result[0].ServiceDetails);
        setServiceTitleList(res.data.Result[0].Servicetitle);
      });
  }

  const getCompanyList = async () => {
    var ACUrl = global.APIURL + '/CompanyActive/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCompanyList(CityL);
      });
  };

  const getNetWorkList = async () => {
    var ACUrl = global.APIURL + '/ChannalTypeList/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setNetworkTypeList(CityL);
      });
  };
  const exportPDF = () => {

    const header = [

      "#", "Network Source", "Network Type", "Email", "Address", "State", "District", "Tehsil", "City", "Pincode", "Branch code"];
    const content = ClientList.map((item, index) => [index + 1, item.CompanyName, item.ChannelTypeName, item.EmailId, item.Address, item.StateName, item.districtName, item.TehsilName, item.CityName, item.Pincode, item.Branchcode]);


    const header1 = ["#", "Service Title", "Type", "Price", "Total Amount", "Recived Amount", "Due Amount"];
    const content1 = ServiceTitleList.map((item, index) => [index + 1, item.ServiceTitle, item.ServicesPackageType, item.PlanAmount, item.TotalAmount, item.TotalRecivedAmount, item.TotalDueAmount]);


    const header2 = ["#", "Service Title", "Type", "DueDate", "Amount", "Gst Amount", "TotalAmount", "PaymentStatus"];
    const content2 = SerPackageList.map((item, index) => [index + 1, item.ServiceTitle, item.ServicesPackageType, item.DueDate, item.Amount, item.GstAmount, item.TotalAmount, item.PaymentStatus]);



    var doc = new jsPDF('p', 'pt');
    // doc.text("Client Services");

      doc.autoTable(header, content, {
      showHeader: 'firstPage',
      styles: { fontSize: 10 },
      marginLeft: { right: 40 }
    });

    doc.autoTable(header1, content1, {
      showHeader: 'firstPage',
      styles: { fontSize: 10 },
      marginLeft: { right: 40 }
    });

    doc.autoTable(header2, content2, {
      showHeader: 'firstPage',

      styles: { fontSize: 10 },
      marginLeft: { right: 40 }
    });

    doc.save("ClientService.pdf")

  }

  return (
    <React.Fragment>
      <Commheader />

      <div className="page-content">
        <MetaTags>
          <title>Corporate Service Detail | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">
          <table id="emp" className="table" hidden="hidden">
            <thead>
              <tr>
                <th>#</th>
                <th>Network Source</th>
                <th>Network Type</th>
                <th>Email</th>
                <th>Address</th>
                <th>State</th>
                <th>District</th>
                <th>Tehsil</th>
                <th>City</th>
                <th>Pincode</th>
                <th>Branch code</th>
              </tr>
            </thead>
            <tbody>

              {
                ClientList.map((item, index) => {
                  return <tr key={index}>
                    <td> {index + 1} </td>
                    <td>{item.CompanyName}</td>
                    <td>{item.ChannelTypeName}</td>
                    <td>{item.EmailId}</td>
                    <td>{item.Address}</td>
                    <td>{item.StateName}</td>
                    <td>{item.districtName}</td>
                    <td>{item.TehsilName}</td>
                    <td>{item.CityName}</td>
                    <td>{item.Pincode}</td>
                    <td>{item.Branchcode}</td>


                  </tr>

                })
              }
              <tr><td></td></tr>
              <tr><td></td></tr>

              <tr><td></td>
                <td></td>
                <td></td>

                <td><h2>Service</h2></td>
                <td></td>
                <td></td>
              </tr>
              <tr><td></td></tr>
            </tbody>
            <thead>
              <tr>
                <th>#</th>
                <th>Service Title</th>
                <th>Type</th>
                <th>Price</th>
                <th>Total Amount</th>
                <th>Total Received Amount</th>
                <th>Total Due Amount</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                ServiceTitleList.map((item, index) => {
                  return <tr key={index}>
                    <td> {index + 1} </td>

                    <td>{item.ServiceTitle}</td>
                    <td>{item.ServicesPackageType}</td>
                    <td>{item.PlanAmount}</td>
                    <td>{item.TotalAmount}</td>
                    <td>{item.TotalRecivedAmount}</td>
                    <td>{item.TotalDueAmount}</td>


                  </tr>
                })
              }
              <tr><td></td></tr>
              <tr><td></td></tr>

              <tr><td></td>
                <td></td>
                <td></td>
                <td><h2>Payment</h2></td>
                <td></td>
                <td></td>
              </tr>
              <tr><td></td></tr>

            </tbody>
            <thead>
              <tr>
                <th>#</th>
                <th>Service Title</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Gst Amount</th>
                <th>Total Amount</th>
                <th>Due Date</th>
                <th>Payment Status</th>
              </tr>
            </thead>
            <tbody>



              {
                SerPackageList.map((item, index) => {
                  return <tr key={index}>
                    <td> {index + 1} </td>

                    <td>{item.ServiceTitle}</td>
                    <td>{item.ServicesPackageType}</td>
                    <td>{item.Amount}</td>
                    <td>{item.GstAmount}</td>
                    <td>{item.TotalAmount}</td>
                    <td>{item.DueDate}</td>
                    <td>{item.PaymentStatus}</td>


                  </tr>
                })
              }


            </tbody>

          </table>
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle> Service Detail</CardTitle>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a
                className="btn btn-primary btn-sm float-end font-14 btncolor "
                href="/EditRegisterClient"
              >
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
              <ReactHtmlTableToExcel
                className="btn btn-primary btn-sm float-end font-14 me-2 btncolor"
                table="emp"
                filename="ClientService"
                sheet="Sheet"
                buttonText="Export Excel" />

              {<button className="btn btn-primary btn-sm float-end font-14 me-2 btncolor" onClick={exportPDF}>Export PDF</button>}

            </Col>
          </Row>
          <div className="ind-card" id="ind-card">
            <Row>
              <Col className="col-lg-12 col-md-12 col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                      <Col className="col-lg-12 col-md-12 col-12 mb-2">
                        <CardTitle> Service Detail</CardTitle>
                        <hr />
                      </Col>

                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Network Type </p>
                        <Input
                          type="text"
                          placeholder="EmailId"
                          className="form-control"
                          id="txtMEmailId"
                          maxlength="50"
                          defaultValue={NetworkType}

                        />

                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Network Source </p>
                        <Input
                          type="text"
                          placeholder="EmailId"
                          className="form-control"
                          id="txtMEmailId"
                          maxlength="50"
                          defaultValue={NetworkSource}

                        />

                      </Col>
                      <Col>
                        <div className="col-lg-3 col-md-6 col-12 mt-3">
                          <label htmlFor="EmailId" className="form-label">
                            EmailId
                          </label>
                          <Input
                            type="text"
                            placeholder="EmailId"
                            className="form-control"
                            id="txtMEmailId"
                            maxlength="50"
                            defaultValue={EmailIdd}

                          />

                        </div>
                      </Col>
                      <Col className="col-lg-12 col-md-12 col-12 mt-3 mt-md-3 mt-lg-0">
                        <p className="mt-3 mb-2 fontWeight500"> Address </p>
                        <div className="input-group auth-pass-inputgroup">
                          <textarea className="form-control" placeholder="Address" id="txtIndAddress" rows="3" name="txtIndAddress" defaultValue={Address} onChange={e => setAddress(e.target.value)}></textarea>
                        </div>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> State </p>
                        <Input
                          type="text"
                          placeholder="EmailId"
                          className="form-control"
                          id="txtMEmailId"
                          maxlength="50"
                          defaultValue={StateName}

                        />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> District </p>
                        <Input
                          type="text"
                          placeholder="EmailId"
                          className="form-control"
                          id="txtMEmailId"
                          maxlength="50"
                          defaultValue={DistrictName}

                        />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Tehsil </p>
                        <Input
                          type="text"
                          placeholder="EmailId"
                          className="form-control"
                          id="txtMEmailId"
                          maxlength="50"
                          defaultValue={TehsilName}
                        />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> City/Village/Town </p>
                        <Input
                          type="text"
                          placeholder="EmailId"
                          className="form-control"
                          id="txtMEmailId"
                          maxlength="50"
                          defaultValue={CityName}
                        />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> PinCode </p>
                        <Input type="text" className="" id="txtPinCode" defaultValue={Pincode} onChange={e => setPincode(e.target.value)} placeholder="Enter PinCode" />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Branch Code </p>
                        <Input type="text" className="" id="txtBranchCode" defaultValue={Branchcode} onChange={e => setBranchCode(e.target.value)} placeholder="Enter Branch Code" />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="">
                      <Col className="col-lg-12 mb-2">
                        <CardTitle>Contact Information</CardTitle>
                        <hr />
                      </Col>
                      <Col lg="12">
                        <table style={{ width: "100%" }}>
                          <tbody>
                            {LeadContactList.map((item, idx) => (
                              <tr id={"addr" + idx} key={idx}>
                                <td>
                                  <div data-repeater-list="group-a">
                                    <Row data-repeater-item className="align-items-end mb-3">
                                      <Col lg="3" className="">
                                        <Label htmlFor="name">
                                          Name
                                        </Label>
                                        <Input type="text" id={item.TextBoxControlIdName} className="form-control" placeholder="Enter Name" defaultValue={item.Name} />
                                      </Col>
                                      <Col lg="3" className="">
                                        <Label htmlFor="name">
                                          Email
                                        </Label>
                                        <Input type="text" id={item.TextBoxControlIdEmail} className="form-control" placeholder="Enter Email" defaultValue={item.Email} />
                                      </Col>
                                      <Col lg="3" className="">
                                        <Label htmlFor="name">
                                          Mobile No
                                        </Label>
                                        <Input type="text" id={item.TextBoxControlId} className="form-control" maxLength={10} placeholder="Enter Mobile No" defaultValue={item.Mobilenumber} />
                                      </Col>
                                      <Col lg="2" className="">
                                        <Label htmlFor="name">
                                          Designation
                                        </Label>
                                        <Input type="text" id={item.TextBoxControlIdDesignation} className="form-control" placeholder="Enter Designation" defaultValue={item.Designation} />
                                      </Col>





                                    </Row>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                      </Col>

                    </Row>
                  </CardBody>
                </Card>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card p-0">
                      <div className="card-body">
                        <p className="maincommontitlefordahboard">Payments</p>

                        <div className='maindasboardtableeditnew'>
                          <table className="table mt-0 mb-0">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" className='text-center'>#</th>
                                <th scope="col">Services Package</th>
                                <th scope="col" className='text-center'>Type</th>
                                <th scope="col" className='text-center'>Due Date</th>
                                <th scope="col" className='text-center'>Amount</th>
                                <th scope="col" className='text-center'>Gst Amount</th>
                                <th scope="col" className='text-center'>Total Amount</th>
                                <th scope="col" className='text-center'>Payment Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {SerPackageList?.map((item, idx) => (
                                <tr key={idx}>
                                  <td className='text-center'>{idx + 1}</td>
                                  <td>{item.ServiceTitle}</td>
                                  <td className='text-center'>{item.ServicesPackageType}</td>
                                  <td className='text-center'>{item.DueDate}</td>
                                  <td className='text-center'>{item.Amount}</td>
                                  <td className='text-center'>{item.GstAmount}</td>
                                  <td className='text-center'>{item.TotalAmount}</td>
                                  <td className='text-center'>{item.PaymentStatus}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                      </div>

                    </div>
                  </div>

                </div>


              </Col>

            </Row>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

export default SerPackageDetail;
