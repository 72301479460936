import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Label,
  Input,
  FormGroup,
  InputGroup,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";

// Table data
const products = [
  {
    Sr: 1,
    HolidayTitle: "Diwali",
    HolidayDate: "6/11/2020 to 9/11/2020",
    action: (
      <>
        <i className="mdi mdi-pencil h5 me-3"></i>
      </>
    ),
  },
  {
    Sr: 2,
    HolidayTitle: "New Year",
    HolidayDate: "01/01/2021",
    action: (
      <>
        <i className="mdi mdi-pencil h5 me-3"></i>
      </>
    ),
  },
];

class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products,
    };
  }

  render() {
    const columns = [
      {
        dataField: "Sr",

        text: "Sr",
        sort: true,
      },
      {
        dataField: "HolidayTitle",

        text: "Holiday Title",
        sort: true,
      },
      {
        dataField: "HolidayDate",

        text: "	Holiday Date",
        sort: true,
      },

      {
        dataField: "action",

        text: "Action",
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    };

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "25", value: 25 },
      { text: "All", value: this.state.productData.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Holiday Master | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
            <Row className="mb-3">
              <Col className="col-lg-8 col-md-6 col-6">
                <div className="breadcrum ps-0">
                <CardTitle className="h4">Holiday Master</CardTitle>
                  <a href="/invoices-list" className="me-2">
                    Home
                  </a>{" "}
                  <span>/</span>
                  <span className="ms-2">Holiday Master</span>
                </div>
              </Col>
              <Col className="col-lg-4 co-md-6 col-6">
                <a
                  className="btn btn-primary btn-sm float-end font-14"
                  href="/"
                >
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                   
                    <Row className="mt-3">
                      <Col lg={12}>
                        <div className="table-responsive">
                          <table className="table table-borderless mb-0">
                            {/* <thead>
                        <tr className="">
                          <th scope="col">Sr</th>
                          <th scope="col" className="">Leave Type</th>
                          <th scope="col" className="">Short Name</th>
                          <th scope="col" className="">Credit Type</th>
                          <th scope="col" className="">Eligibility</th>
                          <th scope="col" className="">Carry Forward</th>
                          <th scope="col" className="">Action</th>
                        </tr>
                      </thead> */}
                            <tbody>
                              <tr>
                                <td>
                                  <div className="mb-3">
                                  <label className="form-label">Holiday Title</label>
                                    <select
                                      id="inputState"
                                      className="form-select"
                                    >
                                      <option selected>Holiday Title</option>
                                      <option>...</option>
                                    </select>
                                  </div>
                                </td>
                                <td className="">
                                <FormGroup className="mb-4">
                        <Label>Holiday Start Date</Label>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d"
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                                 </td>
                                
                                <td className="">
                                <FormGroup className="mb-4">
                        <Label>Holiday End Date</Label>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d"
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                                 </td>
                               
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Col>
                      <div className="col-lg-6 ">
                      <div
                                    className="d-flex align-items-center
                 justify-content-between mb-4"
                                  >
                                    <div className="d-flex">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="flexCheckDefault"
                                          checked
                                        />
                                      </div>
                                      <a
                                        className="btn btn-primary btn-sm"
                                        href="/"
                                      >
                                        Save
                                      </a>
                                      <a
                                        className="btn btn-primary btn-sm ms-2"
                                        href="/"
                                      >
                                        Clear
                                      </a>
                                    </div>
                                  </div>
                      </div>
                    </Row>

                    {/* <Col lg={12} className="mt-4">
                      <p className="text-center">
                        Leaders of the duration of 25/5/2019 to 25/8/2021
                      </p>
                    </Col> */}
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2 border-top pt-4">
                                <Col className="col-lg-4 col-md-4 col-6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-lg-8 col-md-8 col-6">
                                  <div className="float-end">
                                    <Dropdown
                                      isOpen={this.state.info_dropup111}
                                      direction="left"
                                      className="btn-group dropstart"
                                      toggle={() =>
                                        this.setState({
                                          info_dropup111:
                                            !this.state.info_dropup111,
                                        })
                                      }
                                    >
                                      <DropdownToggle className="btn btn-primary-drop">
                                        <i className="mdi mdi-filter"></i>{" "}
                                        Filter
                                      </DropdownToggle>
                                      <DropdownMenu data-popper-placement="left-start">
                                        <DropdownItem header>
                                          Header
                                        </DropdownItem>
                                        <DropdownItem disabled>
                                          Action
                                        </DropdownItem>
                                        <DropdownItem>
                                          Another Action
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>
                                          Another Action
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light table-thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DatatableTables;
