import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import img2 from "assets/images/PAN.png";
import axios from "axios";
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody, Input, Label, Button } from "reactstrap";
import Commheader from "../../../components/HorizontalLayout/Header";
import PlacholderImgC from "assets/images/favicon.png";


const ManageInfo = () => {
  const history = useHistory();
  const [StateList, setStateList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);
  const [CompanyDistrictId, setCompanyDistrictId] = useState("");
  const [CompanyTehsilId, setCompanyTehsilId] = useState("");
  const [AddTehsil, setAddTehsil] = useState('D');
  const [AddCity, setAddCity] = useState('D');
  const [Pincode, setPincode] = useState([]);
  const [PAN, setPAN] = useState([]);
  const [TAN, setTAN] = useState([]);
  const [GSTProvId,setGSTProvId]=useState([]);
  const [GstARN,setGstARN]=useState([]);

  const [CompanyList, setCompanyList] = useState([]); 
  const [NetworkTypeList, setNetworkTypeList] = useState([]);
  const [DistrictName,setDistrictName]=useState([]);
  const [TehsilName,setTehsilName]=useState([]);
  const [CityName,setCityName]=useState([]);
  const [StateId, setStateId] = useState([]);
  const [CityId,setCityId]=useState([]);
  const [DistrictId,setDistrictId]=useState([]);
  const [TehsilId,setTehsilId]=useState([]);

  

  
  
  const [CAddress, setCAddress] = useState("");
  const [StateName, setStateName] = useState("");
  const [EmailIdd, setmEmailId] = useState([]);
  const [ContactList, setContactList] = useState([]);
  const [AlterContactList, setAlterContactList] = useState([]);
  const [BillContactList, setBillContactList] = useState([]);
  const [FinanceInstName, setFinanceInstName] = useState([]);
  const [MemberCode, setMemberCode] = useState([]);
  const [CMail, setCMail] = useState([]);
  const [CPassword, setCPassword] = useState([]);
  

  let { id } = useParams();
 

  useEffect(() => {
    getCompanyList();
    getNetWorkList();
    getStateList("1");
   
  }, []);

  const getStateList = async CId => {
    var AUrl = global.APIURL + '/ActiveState/' + CId;
    axios.get(AUrl)
      .then(Sres => {
        let StateL = Sres.data.map(kcstate => {
          return kcstate;
        });
        setStateList(StateL);
      });
  };

  const getCompanyCityList = async TId => {
    var ACUrl = global.APIURL + '/ActiveCity/' + TId;
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCityList(CityL);
      });
  };

const inputHandler = e => {
  const { value, maxLength } = e.target;
  if (String(value).length >= maxLength) {
    e.preventDefault();
    return;
  }
};


  const getCompanyList = async () => {
    var ACUrl = global.APIURL + '/CompanyActive/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCompanyList(CityL);
      });
  };

  const getNetWorkList = async () => {
    var ACUrl = global.APIURL + '/ChannalTypeList/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setNetworkTypeList(CityL);
      });
  };


  const getCompanyTehsilList = async DId => {
    var AUrl = global.APIURL + '/ActiveTehsil/' + DId;
    axios.get(AUrl)
      .then(Dres => {
        let TehsilL = Dres.data.map(kctehsil => {
          return kctehsil;
        });
        setTehsilList(TehsilL);
      });
  };


  const getCompanyDistrictList = async SId => {
    var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
    axios.get(AUrl)
      .then(Dres => {
        let DistrictL = Dres.data.map(kcdistrict => {
          return kcdistrict;
        });
        setDistrictList(DistrictL);
      });

  };

 

 



  const saveManageInfo=()=>{

    let VContactName=document.getElementById("txtContactName").value;
    let VContactDesignation=document.getElementById("txtContactDesignation").value;
    let VContactMobile=document.getElementById("txtContactMobile").value;
    let VContactLandline=document.getElementById("txtContactLandline").value;
    let VContactEmail=document.getElementById("txtContactEmail").value;

    let VAlterContactName=document.getElementById("txtAlterContactName").value;
    let VAlterContactDesignation=document.getElementById("txtAlterContactDesignation").value;
    let VAlterContactMobile=document.getElementById("txtAlterContactMobile").value;
    let VAlterContactLandline=document.getElementById("txtAlterContactLandline").value;
    let VAlterContactEmail=document.getElementById("txtAlterContactEmail").value;

    
    let VBillContactName=document.getElementById("txtBillContactName").value;
    let VBillContactDesignation=document.getElementById("txtBillContactDesignation").value;
    let VBillContactMobile=document.getElementById("txtBillContactMobile").value;
    let VBillContactLandline=document.getElementById("txtBillContactLandline").value;
    let VBillContactEmail=document.getElementById("txtBillContactEmail").value;

    const ContItemArray=[];
    const AlterContItemArray=[];
    const BillContItemArray=[];
    
 
    let IsDataInsert = 1;
    if(VContactName==""){IsDataInsert = 0; return; }
    if(VContactDesignation==""){IsDataInsert = 0; return; }
    if(VContactMobile==""){IsDataInsert = 0; return; }
    if(VContactLandline==""){IsDataInsert = 0; return; }
    if(VContactEmail==""){IsDataInsert = 0; return; }

    if(IsDataInsert==1)
    {

      const CArray={
        NodelofficerName:VContactName,
        Designation:VContactDesignation,
        MobileNo:VContactMobile,
        LandLine:VContactLandline,
        EmailId:VContactEmail,
        CreateUser:localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      
      };
      ContItemArray.push(CArray);
      
  }
  
  
    if(VAlterContactName==""){IsDataInsert = 0; return; }
    if(VAlterContactDesignation==""){IsDataInsert = 0; return; }
    if(VAlterContactMobile==""){IsDataInsert = 0; return; }
    if(VAlterContactLandline==""){IsDataInsert = 0; return; }
    if(VAlterContactEmail==""){IsDataInsert = 0; return; }

    if(IsDataInsert==1)
    {
      const CAlterArray={
        AlternatecontactName:VAlterContactName,
        Designation:VAlterContactDesignation,
        MobileNo:VAlterContactMobile,
        LandLine:VAlterContactLandline,
        EmailId:VAlterContactEmail,
        CreateUser:localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")      
      };
      AlterContItemArray.push(CAlterArray);
    }
  
 
    if(VBillContactName==""){IsDataInsert = 0; return; }
    if(VBillContactDesignation==""){IsDataInsert = 0; return; }
    if(VBillContactMobile==""){IsDataInsert = 0; return; }
    if(VBillContactLandline==""){IsDataInsert = 0; return; }
    if(VBillContactEmail==""){IsDataInsert = 0; return; }
    if(IsDataInsert==1)
    {
      const BillContactArray={
        CBilling:VBillContactName,
        Designation:VBillContactDesignation,
        MobileNo:VBillContactMobile,
        LandLine:VBillContactLandline,
        EmailId:VBillContactEmail,
        CreateUser:localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")      
      };
      BillContItemArray.push(BillContactArray);
    }
   
    const CData={
      cminfoId:0,
      Nameofthefinancialinstitution:FinanceInstName,
      MemberCode:MemberCode,
      communicationEmail:CMail,
      communicationPassword:CPassword,
      communicationAddress:CAddress,
      stateId:StateId,
      cityId:CityId,
      DistrictId:DistrictId,
      TehsilId:TehsilId,
      pincode:Pincode,
      PAN:PAN,
      TAN:TAN,
      GSTProvisionalId:GSTProvId,
      GSTARN:GstARN,
      Flag:"A",
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
      Nodelofficer:ContItemArray,
      ManageInfocontact:AlterContItemArray,
      ManageInfocontactpersonbilling:BillContItemArray
    }
   

    
    axios.post(global.APIURL + '/CorporateLeadManageInfoInsert ', CData)
    .then(res => {
     

    });


  }

 
  return (
    <React.Fragment>
      <Commheader />
      <div className="page-content">
        <MetaTags>
          <title> Manage Information | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">

          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle>Manage Information </CardTitle>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a
                className="btn btn-primary btn-sm float-end font-14 btncolor "
                href="/CorLeads/ManageSerInfo"
              >
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <div className="ind-card" id="ind-card">
            <Row>
              <Col className="col-lg-12 col-md-12 col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                      <Col className="col-lg-12 col-md-12 col-12 mb-2">
                        <CardTitle>Manage Information </CardTitle>
                        <hr />
                      </Col>                   
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500">Financial Institution</p>
                        <Input
                            type="text"
                            placeholder="Financial Institution"
                            className="form-control"
                            id="txtFinInstName"                          
                            onChange={e => setFinanceInstName(e.target.value)}                                             
                          />
                  
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Member Code</p>
                        <Input
                            type="text"
                            placeholder=" Member Code"
                            className="form-control"
                            id="txtMemberCode"
                            onChange={e => setMemberCode(e.target.value)}                           
                          />
                      
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500">Communication Email</p>
                        <Input
                            type="text"
                            placeholder="Email"
                            className="form-control"
                            id="txtCEmail"
                            onChange={e => setCMail(e.target.value)} 
                           
                                                 
                          />
                  
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500">Communication Password</p>
                        <Input
                            type="text"
                            placeholder=" Password"
                            className="form-control"
                            id="txtCPassword"
                            onChange={e => setCPassword(e.target.value)}                         
                          />
                      
                      </Col>                  
                      <Col className="col-lg-12 col-md-12 col-12 mt-3 mt-md-3 mt-lg-0">
                        <p className="mt-3 mb-2 fontWeight500">Communication Address </p>
                        <div className="input-group auth-pass-inputgroup">
                          <textarea className="form-control" placeholder="Address" id="txtCAddress" rows="3" onChange={e => setCAddress(e.target.value)}></textarea>
                        </div>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                      <div className="mb-3">
                        <label htmlFor="State" className="form-label">
                          State
                        </label>
                        <select className="form-select" Id="ddlCompanyState" name="ddlCompanyState" onChange={e => { getCompanyDistrictList(e.target.value); setStateId(e.target.value) }}>
                          <option value={"0"}>Select State</option>
                          {StateList.map(state => (
                            <option key={state.StateId} value={state.StateId}>
                              {" "}
                              {state.Title}{" "}
                            </option>
                          ))}
                        </select>
                      </div>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                      <div className="mb-3">
                        <label htmlFor="District" className="form-label">
                          District
                        </label>
                        <select className="form-select" Id="ddlCompanyDistrict" name="ddlCompanyDistrict" onChange={e => { getCompanyTehsilList(e.target.value); setDistrictId(e.target.value) }}>
                          <option value={"0"}>Select District</option>
                          {DistrictList.map(district => (
                            <option key={district.DistrictId} value={district.DistrictId}>
                              {district.Title}
                            </option>
                          ))}
                        </select>
                      </div>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label htmlFor="formFileSm" className="form-label">Tehsil</label>
                          </div>
                          <div>
                            {AddTehsil == "A" ? parseInt(CompanyDistrictId) > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddFirmTehsil">+ Add New</a></span> : '' : ''}
                          </div>
                        </div>
                        <select className="form-select" Id="ddlCompanyTehsilId" name="ddlCompanyTehsilId" onChange={e => { getCompanyCityList(e.target.value); setTehsilId(e.target.value) }}>
                          <option value={"0"}>Select Tehsil</option>
                          {TehsilList.map(tehsil => (
                            <option key={tehsil.TehsilId} value={tehsil.TehsilId}>
                              {tehsil.Title}
                            </option>
                          ))}
                        </select>
                      </div>
                      </Col>                   
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label htmlFor="formFileSm" className="form-label">City/Village/Town</label>
                          </div>
                          <div>
                            {AddCity == "A" ? parseInt(CompanyTehsilId) > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddFirmCity">+ Add New</a></span> : '' : ''}
                          </div>
                        </div>
                        <select className="form-select" Id="ddlCompanyCity" name="ddlCompanyCity" onChange={e => setCityId(e.target.value)}>
                          <option value={"0"}>Select City/Village/Town</option>
                          {CityList.map(city => (
                            <option key={city.CityId} value={city.CityId}>
                              {city.Title}
                            </option>
                          ))}
                        </select>
                      </div>
                      </Col>                                 
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> PinCode </p>
                        <Input type="text" className="" id="txtPinCode" onChange={e => setPincode(e.target.value)} placeholder="Enter PinCode" />
                      </Col>      

                       <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> PAN</p>
                        <Input type="text" className="" id="txtPAN" onChange={e => setPAN(e.target.value)}  placeholder="PAN" />
                      </Col>

                       <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> TAN</p>
                        <Input type="text" className="" id="txtTAN" onChange={e => setTAN(e.target.value)} placeholder="TAN" />
                      </Col>

                       <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> GST Provisional ID </p>
                        <Input type="text" className="" id="txtGSTId" onChange={e => setGSTProvId(e.target.value)}  placeholder="GST Provisional ID " />
                      </Col>   

                       <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> GST ARN </p>
                        <Input type="text" className="" id="txtGstARN" onChange={e => setGstARN(e.target.value)}  placeholder="GST ARN " />
                      </Col>       
                    </Row>     
                  </CardBody>
                </Card>

                 <Card>
              <CardBody>
                <Row className="">
                  <Col className="col-lg-12 mb-2">
                    <CardTitle>Contact Information</CardTitle>
                    <hr />
                  </Col>
                  <Col lg="12">
                    <table style={{ width: "100%" }}>
                      <tbody>                     
                       
              

                          <tr id="addr" >
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">
                                  <Col lg="11" className="">
                                    <Label htmlFor="name">
                                    Nodal Officer Name
                                    </Label>
                                    <Input type="text" id="txtContactName" className="form-control" placeholder=" Nodal Officer Name" />
                                  </Col>                               
                                
                                </Row>
                              </div>
                            </td>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">
                                <Col lg="11" className="">
                                    <Label htmlFor="name">
                                      Designation
                                    </Label>
                                    <Input type="text" id="txtContactDesignation"  className="form-control" placeholder="Designation"  />
                                  </Col>                                                            
                                </Row>
                              </div>
                            </td>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">                                                                     
                                  <Col lg="11" className="">
                                    <Label htmlFor="name">
                                      Mobile No
                                    </Label>
                                    <Input type="number" id="txtContactMobile"    onKeyPress={inputHandler} maxLength={10}  className="form-control"  placeholder=" Mobile No" />
                                  </Col>                                                                                              
                                </Row>
                              </div>
                            </td>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">                                                                                                            
                                  <Col lg="11" className="">
                                    <Label htmlFor="name">
                                      Landline
                                    </Label>
                                    <Input type="text" id="txtContactLandline" onKeyPress={inputHandler} maxLength={16} className="form-control" placeholder="Landline No" />
                                  </Col>                                                                
                                </Row>
                              </div>
                            </td>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">
                                                                   
                                  <Col lg="11" className="">
                                    <Label htmlFor="name">
                                      Email
                                    </Label>
                                    <Input type="text" id="txtContactEmail"  className="form-control" placeholder=" Email ID"  />
                                  </Col>                                
                                </Row>
                              </div>
                            </td>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">                                                                   
                                  <Col lg="11" className="mt-4">                                
                                    {/* <Button color="primary" className="btn-sm mb-1 btncolor" onClick={ContacthandleAddRow}><i className="fas fa-plus me-2"></i>Add</Button>              
                                  */}
                                  </Col>                                
                                </Row>
                              </div>
                            </td>                 
                          </tr>                      
                      </tbody>
                    </table>                
                  </Col>
                  {/* <div className="co-md-12 mt-3"><a href="#" className="btn btn_success me-2" > Save </a><a href="#" className="btn btn_danger me-2"> Cancel </a></div> */}
                </Row>
              </CardBody>
            </Card>  

            <Card>
              <CardBody>
                <Row className="">
                  <Col className="col-lg-12 mb-2">
                    <CardTitle>Alternate Contact Information</CardTitle>
                    <hr />
                  </Col>
                  <Col lg="12">
                    <table style={{ width: "100%" }}>
                      <tbody>  

                    
                          <tr id="addr" >
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">
                                  <Col lg="11" className="">
                                    <Label htmlFor="name">
                                     Name
                                    </Label>
                                    <Input type="text" id="txtAlterContactName" className="form-control" placeholder=" Alternate Contact Name" />
                                  </Col>                                                              
                                </Row>
                              </div>
                            </td>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">
                               
                                  <Col lg="11" className="">
                                    <Label htmlFor="name">
                                      Designation
                                    </Label>
                                    <Input type="text" id="txtAlterContactDesignation" className="form-control" placeholder="Designation"  />
                                  </Col>                                                                             
                                </Row>
                              </div>
                            </td>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">                                     
                                  <Col lg="11" className="">
                                    <Label htmlFor="name">
                                      Mobile No
                                    </Label>
                                    <Input type="number" id="txtAlterContactMobile" onKeyPress={inputHandler} maxLength={10}className="form-control"  placeholder=" Mobile No" />
                                  </Col>                                                              
                                </Row>
                              </div>
                            </td>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">                                                                    
                                  <Col lg="11" className="">
                                    <Label htmlFor="name">
                                      Landline
                                    </Label>
                                    <Input type="text" id="txtAlterContactLandline" onKeyPress={inputHandler} maxLength={16} className="form-control" placeholder="Landline No" />
                                  </Col>                                                            
                                </Row>
                              </div>
                            </td>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">                                                                   
                                 <Col lg="11" className="">
                                    <Label htmlFor="name">
                                      Email
                                    </Label>
                                    <Input type="text" id="txtAlterContactEmail" className="form-control" placeholder=" Email ID"  />
                                  </Col>                               
                                </Row>
                              </div>
                            </td>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">                                                                  
                                  <Col lg="11" className="mt-4">                        
                                    {/* <Button color="primary" className="btn-sm mb-1 btncolor" onClick={AlternateContacthandleAddRow}><i className="fas fa-plus me-2"></i>Add</Button>               */}
                                  </Col>                               
                                </Row>
                              </div>
                            </td>
                          </tr>                       
                      </tbody>
                    </table>                
                  </Col>               
                </Row>
              </CardBody>
            </Card>  

            <Card>
              <CardBody>
                <Row className="">
                  <Col className="col-lg-12 mb-2">
                    <CardTitle>Billing Contact Information</CardTitle>
                    <hr />
                  </Col>
                  <Col lg="12">
                  <table style={{ width: "100%" }}>
                      <tbody>   

                        


                          <tr id="addr" >
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">
                                  <Col lg="11" className="">
                                    <Label htmlFor="name">
                                     Name
                                    </Label>
                                    <Input type="text" id="txtBillContactName" className="form-control" placeholder=" Billing Person Name" />
                                  </Col>                                                                
                                </Row>
                              </div>
                            </td>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">                              
                                  <Col lg="11" className="">
                                    <Label htmlFor="name">
                                      Designation
                                    </Label>
                                    <Input type="text" id="txtBillContactDesignation" className="form-control" placeholder="Designation"  />
                                  </Col>                                                                         
                                </Row>
                              </div>
                            </td>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">                                     
                                  <Col lg="11" className="">
                                    <Label htmlFor="name">
                                      Mobile No
                                    </Label>
                                    <Input type="number" id="txtBillContactMobile" onKeyPress={inputHandler} maxLength={10}  className="form-control"  placeholder=" Mobile No" />
                                  </Col>                                                               
                                </Row>
                              </div>
                            </td>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">                                                                  
                                  <Col lg="11" className="">
                                    <Label htmlFor="name">
                                      Landline
                                    </Label>
                                    <Input type="text" id="txtBillContactLandline" onKeyPress={inputHandler} maxLength={16} className="form-control" placeholder="Landline No" />
                                  </Col>                           
                                </Row>
                              </div>
                            </td>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">                                                                   
                                  <Col lg="11" className="">
                                    <Label htmlFor="name">
                                      Email
                                    </Label>
                                    <Input type="text" id="txtBillContactEmail"  className="form-control" placeholder=" Email ID"  />
                                  </Col>                                
                                </Row>
                              </div>
                            </td>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">                                                                  
                                  <Col lg="11" className="mt-4">                                
                                    {/* <Button color="primary" className="btn-sm mb-1 btncolor" onClick={BillingContacthandleAddRow}><i className="fas fa-plus me-2"></i>Add</Button>               */}
                                  </Col>                        
                                </Row>
                              </div>
                            </td>
                          </tr>                      
                      </tbody>
                    </table>                              
                  </Col>
                   <div className="co-md-12 mt-3"><a href="#" className="btn btn_success me-2 btncolor" onClick={saveManageInfo} > Save </a><a href="#" className="btn btn_danger me-2"> Cancel </a></div> 
                </Row>
              </CardBody>
            </Card>  

              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageInfo;
