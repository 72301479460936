import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle,Dropdown,DropdownItem,DropdownToggle,DropdownMenu, } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


import "../../../assets/scss/datatables.scss"

// Table data
const products = [
  {
    "id": 1,
    "name":   <p className="fw-bold mb-1">Smith Jain</p>,
    "last in": <><p className="fw-bold mb-1">10:00 AM</p><p>(20/06/2020)</p></> ,
    "last out":   <><p className="fw-bold mb-1">7:00 PM</p><p>(20/06/2020)</p></>,
    "action": <> <p className="text-decoration-underline mb-1"> <a href="#">Manage Attendance</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#download_master"> <a href="#">Download Master</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#new_request"> <a href="#">New Request</a> </p></>
  },
  {
    "id": 2,
    "name":   <p className="fw-bold mb-1">Smith Jain</p>,
    "last in": <><p className="fw-bold mb-1">10:00 AM</p><p>(20/06/2020)</p></> ,
    "last out":   <><p className="fw-bold mb-1">7:00 PM</p><p>(20/06/2020)</p></>,
    "action": <> <p className="text-decoration-underline mb-1"> <a href="#">Manage Attendance</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#download_master"> <a href="#">Download Master</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#new_request"> <a href="#">New Request</a> </p></>
  },
  {
    "id": 3,
    "name":   <p className="fw-bold mb-1">Smith Jain</p>,
    "last in": <><p className="fw-bold mb-1">10:00 AM</p><p>(20/06/2020)</p></> ,
    "last out":   <><p className="fw-bold mb-1">7:00 PM</p><p>(20/06/2020)</p></>,
    "action": <> <p className="text-decoration-underline mb-1"> <a href="#">Manage Attendance</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#download_master"> <a href="#">Download Master</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#new_request"> <a href="#">New Request</a> </p></>
  },
  {
    "id": 4,
    "name":   <p className="fw-bold mb-1">Smith Jain</p>,
    "last in": <><p className="fw-bold mb-1">10:00 AM</p><p>(20/06/2020)</p></> ,
    "last out":   <><p className="fw-bold mb-1">7:00 PM</p><p>(20/06/2020)</p></>,
    "action": <> <p className="text-decoration-underline mb-1"> <a href="#">Manage Attendance</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#download_master"> <a href="#">Download Master</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#new_request"> <a href="#">New Request</a> </p></>
  },
  {
    "id": 5,
    "name":   <p className="fw-bold mb-1">Smith Jain</p>,
    "last in": <><p className="fw-bold mb-1">10:00 AM</p><p>(20/06/2020)</p></> ,
    "last out":   <><p className="fw-bold mb-1">7:00 PM</p><p>(20/06/2020)</p></>,
    "action": <> <p className="text-decoration-underline mb-1"> <a href="#">Manage Attendance</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#download_master"> <a href="#">Download Master</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#new_request"> <a href="#">New Request</a> </p></>
  },
  {
    "id": 6,
    "name":   <p className="fw-bold mb-1">Smith Jain</p>,
    "last in": <><p className="fw-bold mb-1">10:00 AM</p><p>(20/06/2020)</p></> ,
    "last out":   <><p className="fw-bold mb-1">7:00 PM</p><p>(20/06/2020)</p></>,
    "action": <> <p className="text-decoration-underline mb-1"> <a href="#">Manage Attendance</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#download_master"> <a href="#">Download Master</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#new_request"> <a href="#">New Request</a> </p></>
  },
  {
    "id": 7,
    "name":   <p className="fw-bold mb-1">Smith Jain</p>,
    "last in": <><p className="fw-bold mb-1">10:00 AM</p><p>(20/06/2020)</p></> ,
    "last out":   <><p className="fw-bold mb-1">7:00 PM</p><p>(20/06/2020)</p></>,
    "action": <> <p className="text-decoration-underline mb-1"> <a href="#">Manage Attendance</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#download_master"> <a href="#">Download Master</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#new_request"> <a href="#">New Request</a> </p></>
  },
  {
    "id": 8,
    "name":   <p className="fw-bold mb-1">Smith Jain</p>,
    "last in": <><p className="fw-bold mb-1">10:00 AM</p><p>(20/06/2020)</p></> ,
    "last out":   <><p className="fw-bold mb-1">7:00 PM</p><p>(20/06/2020)</p></>,
    "action": <> <p className="text-decoration-underline mb-1"> <a href="#">Manage Attendance</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#download_master"> <a href="#">Download Master</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#new_request"> <a href="#">New Request</a> </p></>
  },
  {
    "id": 9,
    "name":   <p className="fw-bold mb-1">Smith Jain</p>,
    "last in": <><p className="fw-bold mb-1">10:00 AM</p><p>(20/06/2020)</p></> ,
    "last out":   <><p className="fw-bold mb-1">7:00 PM</p><p>(20/06/2020)</p></>,
    "action": <> <p className="text-decoration-underline mb-1"> <a href="#">Manage Attendance</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#download_master"> <a href="#">Download Master</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#new_request"> <a href="#">New Request</a> </p></>
  },
  {
    "id": 10,
    "name":   <p className="fw-bold mb-1">Smith Jain</p>,
    "last in": <><p className="fw-bold mb-1">10:00 AM</p><p>(20/06/2020)</p></> ,
    "last out":   <><p className="fw-bold mb-1">7:00 PM</p><p>(20/06/2020)</p></>,
    "action": <> <p className="text-decoration-underline mb-1"> <a href="#">Manage Attendance</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#download_master"> <a href="#">Download Master</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#new_request"> <a href="#">New Request</a> </p></>
  },
  {
    "id": 11,
    "name":   <p className="fw-bold mb-1">Smith Jain</p>,
    "last in": <><p className="fw-bold mb-1">10:00 AM</p><p>(20/06/2020)</p></> ,
    "last out":   <><p className="fw-bold mb-1">7:00 PM</p><p>(20/06/2020)</p></>,
    "action": <> <p className="text-decoration-underline mb-1"> <a href="#">Manage Attendance</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#download_master"> <a href="#">Download Master</a> </p>
      <p className=" text-decoration-underline mb-1" data-bs-toggle="modal" data-bs-target="#new_request"> <a href="#">New Request</a> </p></>
  },

  

];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    const columns = [{
      dataField: 'id',
      text: '#',
      sort: true,
    }, {
      dataField: 'name',
      text: 'Name',
      sort: true
    }, {
      dataField: 'last in',
      text: 'Last In',
      sort: true
    },  {
      dataField: 'last out',
      text: 'Last Out',
      sort: true
    },{
      dataField: 'action',
      text: 'Action',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

  
    // Select All Button operation
   

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title> Manage Attendance | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
            


            <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                    <CardTitle className="h3"> Manage Attendance</CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2"> Manage Attendance</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 col-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14 margin-20-back" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>

             

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                  <Col className="col-12">
               
              </Col>
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
             
                    
                  
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                                <Row className="mb-2">
                             
                                <Col className="col-lg-4 col-md-4 col-6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-lg-8 col-md-8 col-6">
                                <a
                  className="btn btn-green btn-sm float-end font-14"
                  href="#"
                >
                  <i className="mdi mdi-plus align-middle me-1"></i>Add
                </a>
              </Col>
                              </Row>



                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                     
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light table-thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>


        <div className="modal fade" id="download_master" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Download Master</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
                    <Row>
                    <Col sm={12} >
                           
                          <div className="lead-follow-2">
                           
                            <div className=" d-flex">
                              <div className="form-check mb-3 me-3">
                                <input className="form-check-input" type="radio" value="" id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                  Yearly
                                </label>
                              </div>
                              <div className="form-check mb-3">
                                <input className="form-check-input" type="radio" value="" id="flexCheckDefault" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                  Monthly
                                </label>
                              </div>
                              
                               
                              
                              </div>
                              
                      <div className="col-md-12 mb-3 ">
                                <label> Yearly </label>
                                  <input className="form-control" type="text" id="example-week-input" value="Yearly" />
                                </div>
                      <div className="col-md-12 mb-3">
                      <label> Start Month </label>
                                  <input className="form-control" type="text" id="example-time-input" value="Start Month" />
                      </div>
                      <div className="col-md-12 mb-3">
                      <label> End Month </label>
                                  <input className="form-control" type="text" id="example-time-input" value="End Month" />
                      </div>
                      
                                <div className="col-md-12 mb-3">
                      
                                <button type="button" className="btn btn_success text-start" data-bs-dismiss="modal">Download</button>
                                </div>      
                              
                          </div>
                          </Col>
           </Row>
      </div>
    </div>
  </div>
        </div>
        





        <div className="modal fade" id="new_request" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">New Request</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
                    <Row>
                  <Col sm={12} >
                    <h5>Shift Change</h5>
                    <div className="table-responsive">
                    <table className="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Start Date</th>
      <th scope="col">End Date</th>
      <th scope="col">Old Shift	</th>
      <th scope="col">New Shift	</th>
      <th scope="col">Action	</th>
    </tr>
  </thead>
  <tbody>
      
    <tr>
      <th>1</th>
      <td>20/06/2020</td>
                            <td>21/06/2020</td>
                            <td>20/06/2020</td>
                            <td>09:00 to 06:00	</td>
                            <td>10:00 to 07:00	</td>
                            <td>  
                            <div className="btn-group dropstart icon_none">
  <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
  <i className="fas fa-ellipsis-v px-2"></i>
  </button>
  <ul className="dropdown-menu p-2">
                                  <a className="d-block border-bottom p-1" href="#"> Link </a>
                                  <a  className="d-block p-1" href="#"> Link </a>
  </ul>
</div>
                            </td>
                            
    </tr>
     
  </tbody>
</table> 
                    </div>
 
                    </Col>
                </Row>
                
                <Row>
                  <Col sm={12} >
                    <h5>Time Change</h5>
                    <div className="table-responsive">
                    <table className="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Date</th>
      <th scope="col">Old In / Out Time</th>
      <th scope="col">New In / Out Time</th>
      <th scope="col">Remarks</th>
       
    </tr>
  </thead>
  <tbody>
      
    <tr>
      <th>1</th>
                            <td>21/06/2020</td>
                            <td>09:00 to 06:00</td>
                            <td>10:00 to 07:00</td>
                            <td><textarea></textarea>	</td>
                            
                            
    </tr>
     
  </tbody>
</table> 
                    </div>
 
                    </Col>
           </Row>
      </div>
    </div>
  </div>
</div>





      </React.Fragment>
      
    )
  }
}

export default DatatableTables