import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';


const EditBureaucategory = () => {

  const [BId, setBId] = useState(0);
  const [Title, setTitle] = useState('');
  const [Description, setDescription] = useState('');
  const history = useHistory();
  let { id } = useParams();
  const [StepList, setStepList] = useState([]);

  useEffect(() => {

    if (id != null) {
      if (id != "0") {
        let newid = id.split(':');
        setBId(newid[1]);
        getBureauCategoryTypeById(newid[1]);
        getBureauCategoryStepList(newid[1]);
      }
      else {
        history.push("/bureaucategoryList");
      }
    }
    else {
      history.push("/bureaucategoryList");
    }
  }, []);

  const getBureauCategoryTypeById = async (DId) => {
    var AUrl = global.APIURL + '/bureaucategoryEdit/' + DId;
    axios.get(AUrl)
      .then(res => {
        $('#hdfId').val(res.data[0].BId);
        $('#txtTitle').val(res.data[0].Title);
        $('#txtDescription').val(res.data[0].Description);
      })
  }

  const getBureauCategoryStepList = async (DId) => {
    const BureaucategoryItem = { BureauCategoryId: DId }
    var AUrl = global.APIURL + '/BureauCategoryStepList';
    axios.post(AUrl, BureaucategoryItem)
      .then(res => {
        setStepList(res.data);
      })
  }

  const BureauUpdate = async (e) => {
    e.preventDefault();
    let VBId = $('#hdfId').val();
    let VTitle = $('#txtTitle').val();
    let VDescription = $('#txtDescription').val();
    const VStepList = StepList.filter(x => x.Id == 0);
    const Bureaucategory = {
      BId: BId,
      Title: VTitle,
      Description: VDescription,
      StepList: VStepList,
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    }
    axios.post(global.APIURL + '/bureaucategoryUpdate', Bureaucategory)
      .then(res => {
        alert("Update Successfully");
        history.push("/bureaucategoryList");
      });

  }



  const StepAddRow = e => {
    const StepItem = {
      Id: 0,
      RowId: (StepList.length + 1),
      Title: $('#txtStepTitle').val(),
      Description: $('#txtStepDescription').val(),
    };
    setStepList([...StepList, StepItem]);
    $('#txtStepTitle').val('');
    $('#txtStepDescription').val('');
  };

  const StepClearRow = e => {
    $('#txtStepTitle').val('');
    $('#txtStepDescription').val('');
  };

  const StepDeleteRow = (e, idx, Id, BureauCategoryId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {

      let Vidx = parseInt(idx == null ? '0' : idx == '' ? '0' : idx);
      let VId = parseInt(Id == null ? '0' : Id == '' ? '0' : Id);
      let VBureauCategoryId = parseInt(BureauCategoryId == null ? '0' : BureauCategoryId == '' ? '0' : BureauCategoryId);
      if (VId > 0) {
        const BureaucategoryItem = {
          Id: VId,
          CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
        }
        axios.post(global.APIURL + '/BureauCategoryStepDelete', BureaucategoryItem)
          .then(res => {
            alert(res.data[0].RecordStatus);
            const RId = idx;
            setStepList(StepList.filter(item => item.RowId !== RId));
            getBureauCategoryTypeById(VBureauCategoryId);
          });
      }
      else {
        if (Vidx > 0) {
          const RId = idx;
          setStepList(StepList.filter(item => item.RowId !== RId));
        }
      }
    }
  };

  return (
    <div className="page-content">

      <MetaTags>
        <title>Edit Bureau Category | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Edit Bureau Category</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <a href="/bureaucategoryList"><span className="ms-2 me-2">Bureau Category</span></a>
              <span>/</span>
              <span className="ms-2">Edit Bureau Category</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-6">
            <a className="btn btn-primary btn-sm float-end font-14" href="/bureaucategoryList">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <form onSubmit={BureauUpdate}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Title</label>
                        <Input type="text" className="form-control" id="txtTitle" placeholder="Enter Title" />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-2">
                        <label className="form-label">Description</label>
                        <Input type="textarea" className="form-control" id="txtDescription" placeholder="Enter Description" />
                      </div>
                    </Col>

                  </Row>
                  <Row>
                    <Col lg={12} className="mb-2 mt-3">
                      <CardTitle>Manage Step</CardTitle>
                      <hr />
                    </Col>
                    <Col lg={4} className="col-md-6 col-lg-4 mb-md-3 mb-lg-0">
                      <label className="form-label">Title</label>
                      <Input type="text" className="form-control" placeholder="Title" name="txtStepTitle" id="txtStepTitle"></Input>
                    </Col>
                    <Col lg={12} className="col-md-6 col-lg-4 mb-md-3 mb-lg-0 mt-3">
                      <label className="form-label">Description</label>
                      <textarea type="text" className="form-control" placeholder="Description" name="txtStepDescription" id="txtStepDescription" />
                    </Col>
                    <Col lg={2} className="col-md-12 col-lg-2 mb-md-3">
                      <a type="button" className="btn btn_success btn-sm mt-2" onClick={StepAddRow}>Add</a>
                      <a className="btn btn_danger btn-sm mt-2 ms-2" onClick={StepClearRow}>Clear</a>
                    </Col>
                    <Col lg={12} className="col-md-6 col-lg-4 mb-md-3 mb-lg-0 mt-1">
                      <div className="table-responsive">
                        <table className="table table-bordered align-middle">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Title</th>
                              <th scope="col">Description</th>
                              <th className="text-center" scope="col">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {StepList.map(item => (
                              <tr key={item.RowId}>
                                <th scope="row">{item.RowId}</th>
                                <td>{item.Title}</td>
                                <td>{item.Description}</td>
                                <td className="text-center"><a className="text-danger" onClick={e => StepDeleteRow(e, item.RowId, item.Id, item.BureauCategoryId)}><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/bureaucategoryList">Clear</a>
                      <Input type="hidden" className="form-control" id="hdfId" value={0} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  )
}

export default EditBureaucategory;