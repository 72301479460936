import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
const cookies = new Cookies();

const EditUserType = () => {
  const [Title, setTitle] = useState('');
  const [Id, setId] = useState('');
  const history = useHistory();
  let { id } = useParams();
  const [MenuList, setMenuList] = useState([]);
  const [MenuRightsList, setMenuRightsList] = useState([]);

  useEffect(() => {
    if (id != null) {
      if (id != "0") {
        let newid = id.split(':');
        getUserTyprById(newid[1]);
        getMenuList();
        getMenuDetailList(newid[1]);

      }
      else {
        history.push("/UserType");
      }
    }
    else {
      history.push("/UserType");
    }
  }, []);

  const getMenuCheckboxChange = async () => {

  }

  const getUserTyprById = async (UTId) => {
    var AUrl = global.APIURL + '/UserTypeEdit/' + UTId;
    axios.get(AUrl)
      .then(res => {
        setTitle(res.data[0].Title);
        setId(res.data[0].Id)
      })
  }

  const getMenuList = async () => {
    var AUrl = global.APIURL + '/Menu/A';
    axios.get(AUrl)
      .then(MSres => {
        let VMenuList = MSres.data.map(ml => {
          return ml;
        });
        setMenuList(VMenuList);
      });
  }

  const getMenuDetailList = async (UserTypeId) => {
    const MenuDetailArr = { UserTypeId: UserTypeId }
    var AUrl = global.APIURL + '/MenuAccessList';
    axios.post(AUrl, MenuDetailArr)
      .then(MSres => {
        let MenuAccessL = MSres.data.map(VMaster => {
          return VMaster;
        });
        console.log(MenuAccessL);
        setMenuRightsList(MenuAccessL);
        for (let mr = 0; mr < MenuAccessL.length; mr++) {
          const MA = MenuAccessL[mr];
          var VCheeckStatus = MA.CheckStatus;
          var VCheckBoxControlId = MA.CheckBoxControlId;
          if (VCheeckStatus == 'A') {
            document.getElementById(VCheckBoxControlId).checked = true;
          }
        }
      });
  }

  const updateProduct = async () => {
    const FMenuRightsItemArr = [];
    if (Title != '') {
      const UserType = {
        Id: Id == '' ? '0' : Id,
        Title: Title,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
        MenuRightsItem: FMenuRightsItemArr
      }
      axios.post(global.APIURL + '/UserTypeUpdate', UserType)
        .then(res => {
          if (parseInt(Id) > 0) {
            const FinalMenuRightsItemArr = [];
            for (let con = 0; con < MenuList.length; con++) {
              const MenuDetail = MenuList[con];
              var VMenuRightsFilter = MenuRightsList.filter(x => x.MenuId == MenuDetail.MenuId);
              for (let cond = 0; cond < VMenuRightsFilter.length; cond++) {
                const MenuRightDetail = VMenuRightsFilter[cond];
                var VCheckBoxControlId = MenuRightDetail.CheckBoxControlId;
                var VCheckBoxValue = document.getElementById(VCheckBoxControlId).checked;
                const MenuRightsItemArr = {
                  RightsId: MenuRightDetail.UserTypeRightsId,
                  UserTypeId: Id,
                  MenuId: MenuRightDetail.MenuId,
                  MenuAction: MenuRightDetail.MenuAction,
                  Status: VCheckBoxValue == true ? 'A' : 'D',
                  CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                };
                FinalMenuRightsItemArr.push(MenuRightsItemArr);
              }
            }
            const AllMenuRightsItemArr = {
              MenuRightsItem: FinalMenuRightsItemArr
            };
            axios.post(global.APIURL + '/UsertypeRightsDetailsUpdate', AllMenuRightsItemArr).then(Ires => { })
          }
          alert(res.data.StatusMessage);
        });
      //history.push("/UserType");
    }
  }

  // const handleCheckboxChange = async (CheckBoxControlId, CheckStatus) => {
  //   //alert(e.target.checked);
  //   //alert(ChkId);
  //   if (CheckStatus == 'A') {
  //     var VIsCheckStatus = document.getElementById(ChkId).checked;
  //     if (VIsCheckStatus == true) {
  //       document.getElementById(ChkId).checked = false;
  //     }
  //     else {
  //       document.getElementById(ChkId).checked = true;
  //     }
  //   }
  // }


  return (
    <div className="page-content">

      <MetaTags>
        <title>User Type | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Edit User Type</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <a href="/UserType"><span className="ms-2 me-2">User Type</span></a>
              <span>/</span>
              <span className="ms-2">Edit User Type</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-6">
            <a className="btn btn-primary btn-sm float-end font-14" href="/UserType">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <form onSubmit={updateProduct}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">User Type</label>
                        <Input type="text" className="" id="txtUserType" defaultValue={Title} placeholder="Enter User Type" onChange={(e) => setTitle(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg={12}>


                      <label className="form-label">Rights</label>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th className="text-center" scope="col">#</th>
                              <th scope="col">Title</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {MenuList.map(record => (
                              <tr key={record.SR}>
                                <td className="text-center">{record.SR}</td>
                                <td >{record.Title}</td>
                                <td>

                                  <div >
                                    {MenuRightsList.filter(x => parseInt(x.MenuId) == parseInt(record.MenuId)).map(mrdrecord => (
                                      <span key={mrdrecord.RightsId}>
                                        <input type="checkbox" className="form-check-input ms-2" name={mrdrecord.CheckBoxControlId} id={mrdrecord.CheckBoxControlId} />
                                        {" "}{mrdrecord.MenuAction}
                                      </span>
                                    ))}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>

                        </table>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <input type="hidden" id="hdfUserTypeId" value={Id} />
                      <a className="btn btn_success btn-sm" id="btnsave" onClick={() => updateProduct()} >Save</a>
                      <a className="btn btn_danger btn-sm ms-2" href="/UserType">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  )
}

export default EditUserType;