import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import PlacholderImgC from "assets/images/favicon.png";


const OnSort = () => {
  let columns = [
    {
      d: "#",
      text: "#",
      cell: (record, index) => {
        let RowIndex = index + 1;
        return (
          <Fragment>
            {RowIndex}
          </Fragment>
        );
      }
    },
    {
      d: "FullName",
      text: "Full Name",
      cell: (record, index) => {
        let FullName = record["FullName"];
        let LeadCode = record["LeadCode"];
        let Tag = record["Tag"];

        let VLeadProfileImage = record["LeadProfileImage"];
        return (

          <a className="text-heading nowrap" onClick={ViewDetailRecord.bind(this, record)}>
            <div className="d-flex">
              <div className="flex-shrink-0">
                <img
                  src={VLeadProfileImage}
                  alt=""
                  className="img-thumbnail img-leads rounded-circle"
                />
              </div>
              <div className="flex-grow-1 ms-1">
                <p className="mb-1">{FullName}</p>
                <p className="text-muted mb-0">{LeadCode}</p>
                <span className="badge badge-soft-primary px-2 py-1">{Tag}</span>

              </div>
            </div>
          </a>

        );
      }
    },
    {
      d: "LeadType",
      text: "Type & Inq. Date ",
      cell: (record, index) => {
        let VLeadType = record["LeadType"];
        let CreateDate = record["CreateDate"];
        return (
          <Fragment>
            <p className="mb-1">{VLeadType}</p>
            <p className="text-muted mb-0">{CreateDate}</p>
          </Fragment>
        );
      }
    },
    {
      d: "Source",
      text: "Source ",
      cell: (record, index) => {
        let ChannelName = record["ChannelName"];
        let ChannelCode = record["ChannelCode"];
        let City = record["City"]
        return (
          <Fragment>
            {ChannelName}
            {ChannelCode == null ? '' : ChannelCode == '' ? '' : <p className="mb-1 text-success">ID : #{ChannelCode} </p>}
            {City}
          </Fragment>
        );
      }
    },
    {
      d: "SubSource",
      text: "Sub Source ",
      cell: (record, index) => {
        let ChannelPartnerName = record["ChannelPartnerName"];
        let Designation = record["Designation"];
        return (
          <Fragment>
            <p className="mb-1 text-wrap1">{ChannelPartnerName}</p>
            <p className="mb-1 text-wrap1">{Designation}</p>
          </Fragment>
        );
      }
    },
    {
      d: "Email & Contact Number",
      text: "Email & Contact Number ",
      cell: (record, index) => {
        let Email = record["EmailAddress"];
        let ContactNumber = record["MobileNo"];
        return (
          <Fragment>
            <p className="mb-1 text-wrap1">{Email}</p>
            <p className="mb-1 text-wrap1">{ContactNumber}</p>
          </Fragment>
        );
      }
    },
    {
      d: "Tags & Priority",
      text: "Tags & Priority",
      cell: (record, index) => {
        let Priority = record["Priority"];
        let Tags = record["Tags"];
        return (
          <Fragment>
            <span className="badge badge-soft-primary font-12">{Tags}</span>
            <br/><span className="badge bg-danger px-2 py-1">{Priority}</span>
          </Fragment>
        );
      }
    },
    {
      d: "Status",
      text: "Status",
      cell: (record, index) => {
        let LeadStatus = record["LeadStatus"];
        let LeadStatusClass = record["LeadStatusClass"];
        return (
          <Fragment>
            <span className={LeadStatusClass}>{LeadStatus}</span>
          </Fragment>
        );
      }
    },
    {
      d: "action",
      text: "Action",
      cell: (record, index) => {


        return (
          <Fragment>
            {RightsUpdate == "A" ? <a className="me-3 text-primary" onClick={(editRecord.bind(this, record))}><i className="mdi mdi-pencil font-size-18"></i></a> : ''}
            {RightsDelete == "A" ? <a className={IconrenderSwitchColor(record)} ><i className={IconrenderSwitch(record)}></i></a> : ''}
          </Fragment>
        );
      }
    }
  ];
  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
    button: {
      excel: true,
      print: true,
      csv: true
    }
  }
  const [records, setRecords] = useState([])
  const [RightsAdd, setRightsAdd] = useState("D");
  const [RightsUpdate, setRightsUpdate] = useState("D");
  const [RightsDelete, setRightsDelete] = useState("D");
  const [ChannelTypeId, setChannelTypeId] = useState(0);
  const [ChannelMasterList, setChannelMasterList] = useState([]);
  const [ChannelTypeList,setChannelTypeList]=useState([]);
  const [ChannelMasterId, setChannelMasterId] = useState(0);
  const [ChannelPartnerList, setChannelPartnerList] = useState([]);
  const [ChannelLogo, setChannelLogo] = useState(PlacholderImgC);
  const [ChannelPartnerId,setChannelPartnerId]=useState(0);
  

  const ViewDetailRecord = (record) => {
    let id = record["LeadId"]
    let LeadType = record["LeadType"]
    //alert(LeadType);

    if (LeadType == "Individual") {
      localStorage.setItem('IndividualLeadId', id);
      window.location.href = "/ClientDetailIndividual";
    }
    else if (LeadType == "Firm/Company") {
      localStorage.setItem('FirmCompanyLeadId', id);
      window.location.href = "/ClientDetailFirmCompany";
    }
  }

  const EditDetailRecord = (record) => {
    let id = record["LeadId"]
    let LeadType = record["LeadType"]
    //alert(LeadType);

    if (LeadType == "Individual") {
      localStorage.setItem('IndividualLeadId', id);
      window.location.href = "/ClientDetailIndividual";
    }
    else if (LeadType == "Firm/Company") {
      localStorage.setItem('FirmCompanyLeadId', id);
      window.location.href = "/ClientDetailFirmCompany";
    }
  }

  const editRecord = (record) => {
    let id = record["LeadId"]
    localStorage.setItem('EditLeadId', id);
    window.location.href = "/AddNewInquiry";
  }

  let history = useHistory();

  function IconrenderSwitch(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'mdi mdi-close font-size-18';
      default:
        return 'mdi mdi-check-all font-size-18';
    }
  }

  function IconrenderSwitchColor(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }

  function IconrenderSwitch(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'mdi mdi-close font-size-18';
      default:
        return 'mdi mdi-check-all font-size-18';
    }
  }

  function IconrenderSwitchColor(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }

  useEffect(() => {

    const TableFilter = document.getElementsByClassName('table_filter');
    for (const tf of TableFilter) {
      tf.style.display = 'none';
    }

    const f = {
      MenuId: "4",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
      //UserTypeId: "1"
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {
        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add') {
            setRightsAdd(MS);
          }
          else if (MA == 'Update') {
            setRightsUpdate(MS);
          }
          else if (MA == 'Delete') {
            setRightsDelete(MS);
          }
        }
      });
    getWebLeadList();
    getChannelTypeList();


    /* fetch(global.APIURL + "/WebLeadList")
       .then((res) => res.json())
       .then((json) => {
         setRecords(json)
       })*/
  }, []);

  const getWebLeadList = async () => {
    var QSearch = document.getElementById("txtQuickSearch").value;
    var LeadType = document.getElementById("txtLeadType").value;
    var StartDate = document.getElementById("txtStartDate").value;
    var EndDate = document.getElementById("txtEndDate").value;
    var LeadStatus = document.getElementById("txtLeadStatus").value;
    var LeadEnroll = document.getElementById("txtLeadEnroll").value;
    var RecordStatus = document.getElementById("txtRecord").value;

    const LeadData = {
      QuickSearch: QSearch,
      LeadType: LeadType,
      FromDate: StartDate,
      ToDate: EndDate,
      LeadStatus: LeadStatus,
      LeadEnrollStatus: LeadEnroll,
      RecordStatus: RecordStatus,
      SourceId:ChannelMasterId,
      SubSourceId:ChannelPartnerId
    }
    console.log("Lead Data",LeadData);

    axios.post(global.APIURL + "/WebLeadList", LeadData)
      .then(CLres => {
        console.log("xx",CLres);
        let VWebLeadList = CLres.data.map(vcl => {
          return vcl;
        });
        setRecords(VWebLeadList);
      });
  };

  console.log("ChannelPartnerId",ChannelPartnerId);
  
  const saveLead = async (e) => {
    getWebLeadList();
  }

  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }

  const exportPDF = () => {

    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "All Inquiry List Report";
    const headers = [["#", "FullName", "Lead Code", "Created Date", "Lead Type", "Source", "Source-City", "Sub-Source", "Sub-Source Designation", "Email", "Contact Number","Tags", "Priority", "Enroll Status", "Status"]];

    const data = records.map((item, index) => [index + 1, item.FullName, item.LeadCode, item.CreateDate, item.LeadType, item.ChannelName+` (#`+(item.ChannelCode)+`)`, item.City, item.ChannelPartnerName, item.Designation, item.EmailAddress, item.MobileNo,item.Tags,item.Priority, item.LeadEnrollTitle, item.LeadStatus]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("AllInquiryListReport.pdf")
  }

  const getChannelMasterList = async (CTId) => {
    var AUrl = global.APIURL + '/ChannelMasterActiveList/' + CTId;
    fetch(AUrl)
      .then((res) => res.json())
      .then((json) => {
        setChannelMasterList(json)
      })
  }

  
  const getChannelTypeList = async () => {
    fetch(global.APIURL + "/ChannalTypeList")
      .then((res) => res.json())
      .then((json) => {
        setChannelTypeList(json)
      })
  }

  const getChannelMasterDetail = async (CMId) => {
    var AUrl = global.APIURL + '/ChannelMasterEdit/' + CMId;
    axios.get(AUrl)
      .then(res => {
        setChannelLogo(res.data[0].ChannelLogo);
      })
  }

  const getModelLeadChannelPartnerList = async CHId => {
    const LeadChannelPartnerFilter = {
      ChannelId: CHId,
      FilterSearchByName: "",
    };
    axios.post(global.APIURL + "/ActiveChannelPartner", LeadChannelPartnerFilter)
      .then(CPLres => {
        let VChannelPartnerList = CPLres.data.map(vcpl => {
          return vcpl;
        });
        setChannelPartnerList(VChannelPartnerList);
      });
  };

  console.log("setChannelPartnerList",ChannelPartnerList)

  return (
    <div className="page-content">
      <MetaTags>
        <title>All Inquiry | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">
        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-12 mb-2">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">All Inquiry</CardTitle>
              <a href="/" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <span className="ms-2">All Inquiry</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-12 text-end">
            <a className="btn btn-primary btn-sm font-14 me-2 " data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
              <i className="fas fa-filter"></i>
            </a>
            {RightsAdd == "A" ? <a className="btn btn-primary btn-sm  font-14 me-2" href="/AddNewInquiry">
              <i className="mdi mdi-plus align-middle me-1"></i>Add New
            </a> : ''}
            <ReactHTMLTableToExcel
              className="btn btn-primary btn-sm float-end font-14 me-2"
              table="emp"
              filename="AllInqReport"
              sheet="Sheet"
              buttonText="Export Excel" />
            <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Row>
              <Col xl="12">
                <div className="collapse" id="collapseExample">
                  <div className="card card-body">
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label className="form-label"> Quick search</label>
                        <form className="app-search app-searchnew p-0 d-none d-lg-block">
                          <div className="position-relative"><input type="text" className="form-control" placeholder="Search..." id="txtQuickSearch" /><span className="bx bx-search-alt"></span>
                          </div>
                        </form>
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">Lead Type </label>
                        <select className="form-select" aria-label="Default select example" id="txtLeadType">
                          <option value=''>All</option>
                          <option value="Individual">Individual</option>
                          <option value="Firm/Company">Firm/Company</option>

                        </select>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">Start Date </label>
                          <input type="date" className="form-control" id="txtStartDate" placeholder="name@example.com" />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">End Date </label>
                          <input type="date" className="form-control" id="txtEndDate" placeholder="name@example.com" />
                        </div>
                      </div>


                      <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="" className="form-label">Lead Status </label>
                        <select className="form-select" aria-label="Default select example" id="txtLeadStatus">
                          <option value="">All</option>
                          <option value="N">New</option>
                          <option value="I">In process</option>
                          <option value="F">Follow Up</option>
                          <option value="C">Converted</option>
                          <option value="H">Hold</option>
                          <option value="R">Registered</option>
                          <option value="L">Closed</option>
                        </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="" className="form-label">Lead Enroll Status</label>
                        <select className="form-select" aria-label="Default select example" id="txtLeadEnroll">
                          <option value="">All</option>
                          <option value="P">Pending</option>
                          <option value="E">Enrolled</option>

                        </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                      <div className="mb-3">
                      <label htmlFor="" className="form-label">Record Status</label>
                        <select className="form-select" aria-label="Default select example" id="txtRecord">
                          <option value="">All</option>
                          <option value="A">Active</option>
                          <option value="D">In-Active</option>
                        </select>
                        </div>
                    
                      </div>

                      <div className="col-md-3">
                      <div className="mb-3">
                        </div>
                      </div>

                      <div className="col-md-3">
                      <label className="form-label">Channel Type</label>
                      <select className="form-select" Id="ddlChanneltype" name="ddlChanneltype" onChange={(e) => { setChannelTypeId(e.target.value); getChannelMasterList(e.target.value) }}>
                        <option value={"0"}>Select Channel Type</option>
                        {ChannelTypeList.map((ChannelType) => (
                          <option key={ChannelType.ChannelTypeId} value={ChannelType.ChannelTypeId}> {ChannelType.ChannelTypeName} </option>
                        ))}
                      </select>
                      </div>

                      <div className="col-md-3">
                      <label className="form-label">Channel Master</label>
                      <select className="form-select" Id="ddlChannelMaster" name="ddlChannelMaster" onChange={(e) => { getChannelMasterDetail(e.target.value); setChannelMasterId(e.target.value);getModelLeadChannelPartnerList(e.target.value) }}>
                        <option value={"0"}>Select Channel Master</option>
                        {ChannelMasterList.map((ChannelMaster) => (
                          <option key={ChannelMaster.ChannelMasterId} value={ChannelMaster.ChannelMasterId}> {ChannelMaster.Title} </option>
                        ))}
                      </select>
                      </div>

                      <div className="col-md-3">
                      <label className="form-label">Channel Partner</label>
                      <select className="form-select" Id="ddlChannelMaster" name="ddlChannelMaster" onChange={(e) =>  setChannelPartnerId(e.target.value)}>
                        <option value={"0"}>Select Channel Master</option>
                        {ChannelPartnerList.map((ChannelPartner) => (
                          <option key={ChannelPartner.ChannelPartnerId} value={ChannelPartner.ChannelPartnerId}> {ChannelPartner.Name} </option>
                        ))}
                      </select>
                      </div>



                      <div className="col-md-12 mt-3 mb-2">
                        <button type="button" className="btn btn-sm btn-success font-14" onClick={saveLead}>Go</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Row>
            <div>
              <table id="emp" className="table" hidden="hidden">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Full Name</th>
                    <th>Lead Code</th>
                    <th>Created date</th>
                    <th>Lead Type</th>
                    <th>Source</th>
                    <th>Source City</th>
                    <th>Sub-Source</th>
                    <th>Sub-Source designation</th>
                    <th>Email</th>
                    <th>ContactNumber</th>
                    <th>Tags</th>
                    <th>Priority</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>              {
                  records.map((item, index) => {
                    return <tr key={index}>
                      <td> {index + 1} </td>
                      <td >{item.FullName}</td>
                      <td>{item.LeadCode}</td>
                      <td>{item.CreateDate}</td>
                      <td>{item.LeadType}</td>
                      <td >{item.ChannelName}({item.ChannelCode})</td>
                      <td>{item.City}</td>
                      <td >{item.ChannelPartnerName}</td>
                      <td>{item.Designation}</td>
                      <td >{item.EmailAddress}</td>
                      <td>{item.MobileNo}</td>
                      <td>{item.Tags}</td>
                      <td >{item.Priority}</td>
                      <td >{item.LeadStatus}</td>
                    </tr>
                  })
                }
                </tbody>
              </table>
            </div>
          </Row>
        </Row>
        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12" className="customreactdatatable">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default OnSort;