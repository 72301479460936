import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import img2 from "assets/images/PAN.png";
import axios from "axios";
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody, Input, Label, Button } from "reactstrap";
import PlacholderImgC from "assets/images/favicon.png";
import { ResetTvRounded } from "@mui/icons-material";
import 'jspdf-autotable';
import LinkApp from 'App';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import 'jspdf-autotable';


const Registerclient = () => {
  const history = useHistory();
  const [CorporateLeadId, setCorporateLeadId] = useState(0);
  const [CompanyName, setCompanyName] = useState('');
  const [ChannelTypeName, setChannelTypeName] = useState('');
  const [Email, setEmail] = useState('');
  const [Address, setAddress] = useState('');
  const [State, setState] = useState('');
  const [District, setDistrict] = useState('');
  const [Tehsil, setTehsil] = useState('');
  const [PinCode, setPinCode] = useState('');
  const [BranchCode, setBranchCode] = useState('');

  const [CompanyList, setCompanyList] = useState([]);
  const [PaymentModeList, setPaymentModeList] = useState([]);
  const [EditcorLeadId, setcorEditLeadId] = useState("");

  const [ServiceList, setServiceList] = useState([]);
  const [ServiceId, setServiceId] = useState(0);
  const [PaymentModeType, setPaymentModeType] = useState(0);
  const [CompanyId, setCompanyId] = useState(0);
  const [PaymentModeId, setPaymentModeId] = useState(0);
  const [PaymentModeTitle, setPaymentModeTitle] = useState('');
  const [BankName, setBankName] = useState('');
  const [Branch, setBranch] = useState('');
  const [ChequeDDNo, setChequeDDNo] = useState('');
  const [ChequeDDDate, setChequeDDDate] = useState('');
  const [TransactionDate, setTransactionDate] = useState('');

  const [LeadContactList, setLeadContactList] = useState([]);

  const [MonthlyRate, setMonthlyRate] = useState(0);
  const [QuaterlyRate, setQuaterlyRate] = useState(0);
  const [HalfYearlyRate, setHalfYearlyRate] = useState(0);
  const [YearlyRate, setYearlyRate] = useState(0);

  const [MonthlyGSTRate, setMonthlyGSTRate] = useState(0);
  const [QuaterlyGSTRate, setQuaterlyGSTRate] = useState(0);
  const [HalfYearlyGSTRate, setHalfYearlyGSTRate] = useState(0);
  const [YearlyGSTRate, setYearlyGSTRate] = useState(0);

  const [MonthlyTotalRate, setMonthlyTotalRate] = useState(0);
  const [QuaterlyTotalRate, setQuaterlyTotalRate] = useState(0);
  const [HalfYearlyTotalRate, setHalfYearlyTotalRate] = useState(0);
  const [YearlyTotalRate, setYearlyTotalRate] = useState(0);

  const [GstPercentage, setGstPercentage] = useState(0);
  const [NoofDue, setNoofDue] = useState(0);

  const [PlanAmount, setPlanAmount] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [TotalGstAmount, setTotalGstAmount] = useState(0);
  const [ServicesPackageType, setServicesPackageType] = useState('');
  const [SaveButtonShow, setSaveButtonShow] = useState(1);



  let { id } = useParams();

  useEffect(() => {
    if (id != null) {
      if (id != "0") {
        let newid = id.split(':');
        getLeadContactList(newid[1]);
        getLeadDetailById(newid[1]);
        getCompanyList();
        getPaymentModeList();
        getservicelist();
      } else {
        history.push("CorporateLeads");
      }
    } else {
      history.push("CorporateLeads");
    }
  }, []);

  const getPaymentModeList = async () => {
    var AUrl = global.APIURL + '/PaymentModeActive';
    axios.get(AUrl).then(Pres => {
      let PaymentModeL = Pres.data.map((PaymentMode) => {
        return PaymentMode
      })
      setPaymentModeList(PaymentModeL);
    })
  }


  const getCompanyList = async () => {
    var ACUrl = global.APIURL + '/CompanyActive/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCompanyList(CityL);
      });
  };

  const getservicelist = async () => {
    var ACUrl = global.APIURL + '/CorporateServiceList';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setServiceList(CityL);
      });
  };

  const getLeadDetailById = async LId => {
    var AUrl = global.APIURL + '/CorporateleadEdit/' + LId;
    axios.get(AUrl)
      .then(res => {
        setCorporateLeadId(parseFloat(LId == null ? '0' : LId == "" ? '0' : LId));
        setCompanyName(res.data[0].CompanyName);
        setChannelTypeName(res.data[0].ChannelTypeName);
        setEmail(res.data[0].Email);
        setAddress(res.data[0].Address);
        setState(res.data[0].StateName);
        setDistrict(res.data[0].districtName);
        setTehsil(res.data[0].TehsilName);
        setPinCode(res.data[0].Pincode);
        setBranchCode(res.data[0].Branchcode);
      });
  };

  const getLeadContactList = async (LeadId) => {
    var AUrl = global.APIURL + '/CorporateleadContactDetailsEdit/' + LeadId;
    axios.get(AUrl)
      .then(LCdres => {
        let LeadContactList = LCdres.data.map((LeadContact) => {
          return LeadContact
        })
        setLeadContactList(LeadContactList);
      });
  }



  const getrateandgst = async ServiceId => {

    var AUrl = global.APIURL + '/CorporateServiceAllList';
    const rate = {
      Id: ServiceId,
    };

    axios.post(AUrl, rate)
      .then(res => {

        setGstPercentage(parseFloat(res.data[0].percentage == null ? '0' : res.data[0].percentage == '' ? '0' : res.data[0].percentage));

        setMonthlyRate(parseFloat(res.data[0].Monthlyrate == null ? '0' : res.data[0].Monthlyrate == '' ? '0' : res.data[0].Monthlyrate));
        setMonthlyGSTRate(parseFloat(res.data[0].MonthlyGSTRate == null ? '0' : res.data[0].MonthlyGSTRate == '' ? '0' : res.data[0].MonthlyGSTRate));
        setMonthlyTotalRate(parseFloat(res.data[0].MonthlyTotal == null ? '0' : res.data[0].MonthlyTotal == '' ? '0' : res.data[0].MonthlyTotal));

        setQuaterlyRate(parseFloat(res.data[0].QuaterlyRate == null ? '0' : res.data[0].QuaterlyRate == '' ? '0' : res.data[0].QuaterlyRate));
        setQuaterlyGSTRate(parseFloat(res.data[0].QuaterlyGSTRate == null ? '0' : res.data[0].QuaterlyGSTRate == '' ? '0' : res.data[0].QuaterlyGSTRate));
        setQuaterlyTotalRate(parseFloat(res.data[0].QuaterlyTotal == null ? '0' : res.data[0].QuaterlyTotal == '' ? '0' : res.data[0].QuaterlyTotal));

        setHalfYearlyRate(parseFloat(res.data[0].Halfyearly == null ? '0' : res.data[0].Halfyearly == '' ? '0' : res.data[0].Halfyearly));
        setHalfYearlyGSTRate(parseFloat(res.data[0].HalfyearlyGSTRate == null ? '0' : res.data[0].HalfyearlyGSTRate == '' ? '0' : res.data[0].HalfyearlyGSTRate));
        setHalfYearlyTotalRate(parseFloat(res.data[0].HalfYearlyTotal == null ? '0' : res.data[0].HalfYearlyTotal == '' ? '0' : res.data[0].HalfYearlyTotal));

        setYearlyRate(parseFloat(res.data[0].yearlyrate == null ? '0' : res.data[0].yearlyrate == '' ? '0' : res.data[0].yearlyrate));
        setYearlyGSTRate(parseFloat(res.data[0].YearlyGSTRate == null ? '0' : res.data[0].YearlyGSTRate == '' ? '0' : res.data[0].YearlyGSTRate));
        setYearlyTotalRate(parseFloat(res.data[0].YearlyTotal == null ? '0' : res.data[0].YearlyTotal == '' ? '0' : res.data[0].YearlyTotal));
      });

  }

  const SelectServicesPackageType = async PackageType => {
    var VGstPer = parseFloat(GstPercentage == null ? '0' : GstPercentage.toString() == '' ? '0' : GstPercentage.toString());
    var VNoOfDue = parseFloat(NoofDue == null ? '0' : NoofDue.toString() == '' ? '0' : NoofDue.toString());
    if (PackageType == 'Monthly') {
      var VTotalAmount = VNoOfDue == 0 ? MonthlyRate : MonthlyRate * VNoOfDue;
      var VTotalGstAmount = ((VTotalAmount * VGstPer) / 100);
      setPlanAmount(MonthlyRate);
      setTotalAmount(VTotalAmount);
      setTotalGstAmount(VTotalGstAmount);
    }
    else if (PackageType == 'Quaterly') {
      var VTotalAmount = VNoOfDue == 0 ? QuaterlyRate : QuaterlyRate * VNoOfDue;
      var VTotalGstAmount = ((VTotalAmount * VGstPer) / 100);
      setPlanAmount(QuaterlyRate);
      setTotalAmount(VTotalAmount);
      setTotalGstAmount(VTotalGstAmount);
    }
    else if (PackageType == 'Half Yearly') {
      var VTotalAmount = VNoOfDue == 0 ? HalfYearlyRate : HalfYearlyRate * VNoOfDue;
      var VTotalGstAmount = ((VTotalAmount * VGstPer) / 100);
      setPlanAmount(HalfYearlyRate);
      setTotalAmount(VTotalAmount);
      setTotalGstAmount(VTotalGstAmount);
    }
    else if (PackageType == 'Yearly') {
      var VTotalAmount = VNoOfDue == 0 ? YearlyRate : YearlyRate * VNoOfDue;
      var VTotalGstAmount = ((VTotalAmount * VGstPer) / 100);
      setPlanAmount(YearlyRate);
      setTotalAmount(VTotalAmount);
      setTotalGstAmount(VTotalGstAmount);
    }
  }

  const onChangeNoofDue = async (SNoofDue) => {
    var VGstPer = parseFloat(GstPercentage == null ? '0' : GstPercentage.toString() == '' ? '0' : GstPercentage.toString());
    var VNoOfDue = parseFloat(SNoofDue == null ? '0' : SNoofDue.toString() == '' ? '0' : SNoofDue.toString());
    if (ServicesPackageType == 'Monthly') {
      var VTotalAmount = VNoOfDue == 0 ? MonthlyRate : MonthlyRate * VNoOfDue;
      var VTotalGstAmount = ((VTotalAmount * VGstPer) / 100);
      setPlanAmount(MonthlyRate);
      setTotalAmount(VTotalAmount);
      setTotalGstAmount(VTotalGstAmount);
    }
    else if (ServicesPackageType == 'Quaterly') {
      var VTotalAmount = VNoOfDue == 0 ? QuaterlyRate : QuaterlyRate * VNoOfDue;
      var VTotalGstAmount = ((VTotalAmount * VGstPer) / 100);
      setPlanAmount(QuaterlyRate);
      setTotalAmount(VTotalAmount);
      setTotalGstAmount(VTotalGstAmount);
    }
    else if (ServicesPackageType == 'Half Yearly') {
      var VTotalAmount = VNoOfDue == 0 ? HalfYearlyRate : HalfYearlyRate * VNoOfDue;
      var VTotalGstAmount = ((VTotalAmount * VGstPer) / 100);
      setPlanAmount(HalfYearlyRate);
      setTotalAmount(VTotalAmount);
      setTotalGstAmount(VTotalGstAmount);
    }
    else if (ServicesPackageType == 'Yearly') {
      var VTotalAmount = VNoOfDue == 0 ? YearlyRate : YearlyRate * VNoOfDue;
      var VTotalGstAmount = ((VTotalAmount * VGstPer) / 100);
      setPlanAmount(YearlyRate);
      setTotalAmount(VTotalAmount);
      setTotalGstAmount(VTotalGstAmount);
    }
  }

  const RegisterClientDeails = async () => {
    setSaveButtonShow(0);
    const UserDetailArr = {
      CorporateleadId: parseInt(CorporateLeadId == null ? '0' : CorporateLeadId == '' ? '0' : CorporateLeadId),
      ServicePackageId: parseInt(ServiceId == null ? '0' : ServiceId == '' ? '0' : ServiceId),
      ServicesPackageType: ServicesPackageType,
      ServicesPackageNoOfDue: NoofDue,
      GstPer: GstPercentage,
      PlanAmount: PlanAmount,
      TotalAmount: TotalAmount,
      RecivedAmount: 0,
      PaymentStaus: 'Due',
      PaymentType: ServicesPackageType,
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    }

    axios.post(global.APIURL + '/CorporateLeadServicePackageRegister', UserDetailArr)
      .then(res => {
        for (let pd = 0; pd < NoofDue; pd++) {
          let VDueDay = 0;
          let DDay = pd + 1;
          if (ServicesPackageType == 'Monthly') {
            VDueDay = DDay;
          }
          else if (ServicesPackageType == 'Quaterly') {
            VDueDay = DDay * 3;
          }
          else if (ServicesPackageType == 'Half Yearly') {
            VDueDay = DDay * 6;
          }
          else if (ServicesPackageType == 'Yearly') {
            VDueDay = DDay * 12;
          }

          const DueDetailArr = {
            MasterId: parseInt(res.data[0].MasterId == null ? '0' : res.data[0].MasterId == '' ? '0' : res.data[0].MasterId),
            CorporateleadId: parseInt(CorporateLeadId == null ? '0' : CorporateLeadId == '' ? '0' : CorporateLeadId),
            ServicePackageId: parseInt(ServiceId == null ? '0' : ServiceId == '' ? '0' : ServiceId),
            ServicesPackageType: ServicesPackageType,
            NoOfDue: NoofDue,
            DueDay: VDueDay,
            GstPercentage: GstPercentage,
            Amount: PlanAmount,
            GstAmount: ((PlanAmount*GstPercentage)/100),
            TotalAmount: (PlanAmount + ((PlanAmount*GstPercentage)/100)),
            PaymentStaus: 'D',
            PaymentType: ServicesPackageType,
            CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
          }

          axios.post(global.APIURL + '/CorporateLeadServicePackagePaymentDue', DueDetailArr)
            .then(resdue => {
              if (pd == NoofDue - 1) {
                alert(res.data[0].RecordStatus);
                history.push("/CorConvertedList");
              }
            });
        }
      });
  };



  return (
    <React.Fragment>

      <div className="page-content">
        <MetaTags>
          <title>Register Corporate Client </title>
        </MetaTags>
        <div className="container-fluid">
          <Row className="mb-3">

            <Col className="col-lg-8 col-md-6 col-12">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Register Corporate Client</CardTitle>

              </div>
            </Col>

            <Col className="col-lg-4 co-md-6 col-12">
              <a
                className="btn btn-primary btn-sm float-end font-14"
                href="/CorConvertedList"
              >
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>

          </Row>

          <div className="ind-card" id="ind-card">
            <Row>
              <Col className="col-lg-12 col-md-12 col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                      <Col className="col-lg-12 col-md-12 col-12 mb-2">
                        <CardTitle>Client Detail</CardTitle>
                        <hr />
                      </Col>

                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Company </p>
                        <Input type="text" id="txtCompany" className="form-control" defaultValue={CompanyName} readOnly />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Network Type </p>
                        <Input type="text" id="txtChannelTypeName" className="form-control" defaultValue={ChannelTypeName} readOnly />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <label htmlFor="EmailId" className="form-label">EmailId</label>
                        <Input type="text" id="txtEmail" className="form-control" defaultValue={Email} readOnly />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Branch Code </p>
                        <Input type="text" id="txtBranchCode" className="form-control" defaultValue={BranchCode} readOnly />
                      </Col>
                      <Col className="col-lg-12 col-md-12 col-12 mt-3 mt-md-3 mt-lg-0">
                        <p className="mt-3 mb-2 fontWeight500"> Address </p>
                        <div className="input-group auth-pass-inputgroup">
                          <textarea className="form-control" id="txtIndAddress" rows="3" name="txtIndAddress" defaultValue={Address}></textarea>
                        </div>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> State </p>
                        <Input type="text" id="txtState" className="form-control" defaultValue={State} readOnly />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> District </p>
                        <Input type="text" id="txtDistrict" className="form-control" defaultValue={District} readOnly />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Tehsil </p>
                        <Input type="text" id="txtTehsil" className="form-control" defaultValue={Tehsil} readOnly />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> PinCode </p>
                        <Input type="text" id="txtPinCode" className="form-control" defaultValue={PinCode} readOnly />
                      </Col>

                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                      <Col className="col-lg-12 col-md-12 col-12">
                        <div className="card-title">Contact Information</div>
                        <hr />
                      </Col>
                      <Col className="col-lg-12 col-md-12 col-12">
                        <table style={{ width: "100%" }}>
                          <tbody>
                            {LeadContactList.map((item, idx) => (
                              <tr id={"addr" + idx} key={idx}>
                                <td>
                                  <div data-repeater-list="group-a" className="mt-2">
                                    <Row data-repeater-item className="align-items-end">
                                      <Col lg="3" className="">
                                        <Label htmlFor="name">
                                          Name
                                        </Label>
                                        <Input type="text" id="txtName" className="form-control" placeholder="Enter Name" defaultValue={item.Name} readOnly />
                                      </Col>
                                      <Col lg="3" className="">
                                        <Label htmlFor="name">
                                          Email
                                        </Label>
                                        <Input type="text" id="txtEmail" className="form-control" placeholder="Enter Email" defaultValue={item.Email} readOnly />
                                      </Col>
                                      <Col lg="3" className="">
                                        <Label htmlFor="name">
                                          Mobile No
                                        </Label>
                                        <Input type="text" id="txtMobilenumber" className="form-control" placeholder="Enter Mobile No" defaultValue={item.Mobilenumber} readOnly />
                                      </Col>
                                      <Col lg="2" className="">
                                        <Label htmlFor="name">
                                          Designation
                                        </Label>
                                        <Input type="text" id="txtDesignation" className="form-control" placeholder="Enter Designation" defaultValue={item.Designation} readOnly />
                                      </Col>





                                    </Row>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                      <Col className="col-lg-12 col-md-12 col-12 mb-2">
                        <CardTitle>Services Package Registration</CardTitle>
                        <hr />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12">
                        <p className="mb-2 fontWeight500"> Service </p>
                        <select className="form-select" id="ddlService" name="ddlService" onChange={e => { getrateandgst(e.target.value); setServiceId(e.target.value); }}>
                          <option key="0" value="0">Select Service</option>
                          {ServiceList.map(item => (
                            <option key={item.Id} value={item.Id}>
                              {item.value}
                            </option>
                          ))}
                        </select>
                      </Col>
                      {ServiceId > 0 ?
                        <Col className="col-lg-12 col-md-6 col-12 mt-3" id="divServicePrice">
                          <p className="mb-2 fontWeight500"> Services Package Price </p>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Monthly" onClick={e => { SelectServicesPackageType(e.target.value); setServicesPackageType(e.target.value) }} />
                            <label className="form-check-label" htmlFor="inlineRadio1">Monthly</label>

                            <div className="maingsttexteditbox">
                              <p>(<span className="maintexttexteditb"> {MonthlyRate} + {MonthlyGSTRate} =</span><span className="maincountgst">{MonthlyTotalRate}</span>)</p>
                            </div>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Quaterly" onClick={e => { SelectServicesPackageType(e.target.value); setServicesPackageType(e.target.value) }} />
                            <label className="form-check-label" htmlFor="inlineRadio2">Quaterly</label>
                            <div className="maingsttexteditbox">
                              <p>(<span className="maintexttexteditb"> {QuaterlyRate} + {QuaterlyGSTRate} =</span><span className="maincountgst">{QuaterlyTotalRate}</span>)</p>
                            </div>
                          </div>

                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="Half Yearly" onClick={e => { SelectServicesPackageType(e.target.value); setServicesPackageType(e.target.value) }} />
                            <label className="form-check-label" htmlFor="inlineRadio3">Half yearly</label>
                            <div className="maingsttexteditbox">
                              <p>(<span className="maintexttexteditb"> {HalfYearlyRate} + {HalfYearlyGSTRate} =</span><span className="maincountgst">{HalfYearlyTotalRate}</span>)</p>
                            </div>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="Yearly" onClick={e => { SelectServicesPackageType(e.target.value); setServicesPackageType(e.target.value) }} />
                            <label className="form-check-label" htmlFor="inlineRadio4">yearly</label>
                            <div className="maingsttexteditbox">
                              <p>(<span className="maintexttexteditb"> {YearlyRate} + {YearlyGSTRate} =</span><span className="maincountgst">{YearlyTotalRate}</span>)</p>
                            </div>
                          </div>
                        </Col>
                        : ''}
                    </Row>
                    {ServiceId > 0 ?
                      <Row className="mb-2">
                        <Col className="col-lg-3 col-md-4 col-12 mb-3 mb-md-3 mb-lg-0">
                          <p className="mb-2 fontWeight500">No. of Due</p>
                          <Input type="text" className="" id="txtNoofDue" placeholder="Enter No. of Due" onChange={(e) => { setNoofDue(e.target.value); onChangeNoofDue(e.target.value) }} />
                        </Col>
                        <Col className="col-lg-3 col-md-4 col-12 mb-3 mb-md-3 mb-lg-0">
                          <p className="mb-2 fontWeight500">Plan Amount </p>
                          <Input type="text" className="" id="txtPlanGstAmount" Value={PlanAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })} readOnly />
                        </Col>
                        <Col className="col-lg-3 col-md-4 col-12 mb-3 mb-md-3 mb-lg-0">
                          <p className="mb-2 fontWeight500">Total Amount </p>
                          <Input type="text" className="" id="txtTotalAmount" Value={TotalAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })} readOnly />
                        </Col>
                        <Col className="col-lg-3 col-md-4 col-12 mb-3 mb-md-3 mb-lg-0">
                          <p className="mb-2 fontWeight500">Total Gst Amount </p>
                          <Input type="text" className="" id="txtTotalGstAmount" Value={TotalGstAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })} readOnly />
                        </Col>
                      </Row>
                      : ''}
                    {ServiceId > 0 ?
                      <Row className="mb-2">
                        <div className="co-md-12 mt-3">
                          {SaveButtonShow == 1 ?
                            <a className="btn btn_success me-2" onClick={() => RegisterClientDeails()}> Register </a>
                            : ''}
                          <a href="/CorConvertedList" className="btn btn_danger me-2"> Cancel </a>
                        </div>
                      </Row>
                      : ''}
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Registerclient;
