import React, {useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import img2 from "assets/images/favicon.png";
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody,Input,Label,Form,Button } from "reactstrap";
import ClientAccountFirmDetail from "../LeadList/ClientAccountFirmDetail";



const UiRating =()=>{
  const history = useHistory();
  const EnrollLeadRecord = async () => {
    let VLeadId = localStorage.getItem("FirmCompanyLeadId")==null?"0":localStorage.getItem("FirmCompanyLeadId")==""?"0":localStorage.getItem("FirmCompanyLeadId");
    let AlertMessgae ="Are you sure you want to enroll this record?";
    if (confirm(AlertMessgae) == true) {
      var AUrl=global.APIURL+'/LeadEnrolled/'+VLeadId;
      axios.post(AUrl)  
      .then(res => 
        {
          alert(res.data[0].RecordStatus);
          history.push("/LeadList");
        });
    }      
  }


    return (

      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Lead Individual Detail | KC Admin App</title>
          </MetaTags>

          <div className="container-fluid"> 
          <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-12 mb-2">
            <div className="breadcrum ps-0">
            <CardTitle className="h4">Lead Detail</CardTitle>
              <a  href="/" className="me-2">
                  Home
                </a>{" "}
                <span>/</span>
                <a  href="/LeadList" className="me-2 ms-2">
                Manage Lead
                </a>{" "}
                <span>/</span>
                <span className="ms-2">Lead Detail</span>
              </div>
          </Col>
            <Col className="col-lg-4 co-md-6 col-12">
              <a className="btn btn-primary btn-sm float-end font-14" href="/LeadList">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
              <a className="btn btn-primary btn-sm float-end font-14 me-2" href="/AddLead/AddNewInquiry">
                <i className="mdi mdi-plus align-middle me-1"></i>Add New
              </a>
              <a className="btn btn-primary btn-sm float-end font-14 me-2" href="#" onClick={EnrollLeadRecord}>
                Enroll
              </a>
            </Col>
          </Row>
            <ClientAccountFirmDetail />  
          </div>
        </div>
      </React.Fragment>
    );
  }
export default UiRating;
