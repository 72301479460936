import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import axios from 'axios'
import { Col, Row, CardTitle, Card, CardBody, Input, Button } from "reactstrap";
import ServicesPackageRegistration from "./ServicesPackageRegistration";
// Rating Plugin1 

const UiRating = () => {
    const [LeadCode, setLeadCode] = useState('');
    const [FullName, setFullName] = useState("");
    const [FirstName, setFirstName] = useState("");
    const [MiddleName, setMiddleName] = useState("");
    const [LastName, setLastName] = useState("");
    const [Gender, setGender] = useState("");
    const [CIRID, setCIRID] = useState("");
    const [LeadProfileImage, setLeadProfileImage] = useState("");
    const [PMobileNumber, setPMobileNumber] = useState("");
    const [PEmailAddress, setPEmailAddress] = useState("");
    const [LeadType, setLeadType] = useState("");
    const [State, setState] = useState("");
    const [District, setDistrict] = useState("");
    const [City, setCity] = useState("");
    const [Tehsil, setTehsil] = useState("");
    const [PinCode, setPinCode] = useState("");

    const [SelectChannelDetail, setSelectChannelDetail] = useState([]);
    const [SelectChannelPartnerDetail, setSelectChannelPartnerDetail] = useState([]);

    const [ServicePackageList, setServicePackageList] = useState([]);
    const [ServiceWiseActivityList, setServiceWiseActivityList] = useState([]);
    const [ServiceActivitiesAdd, setServiceActivitiesAdd] = useState([]);
    const [PaymentType, setPaymentType] = useState('Full');
    const [ActionType, setActionType] = useState('Default');
    const [ButtonText, setButtonText] = useState('Next');

    useEffect(() => {
        let LeadId = localStorage.getItem("ClientIndividualLeadId");
        if (LeadId != null) {
            if (LeadId != "0") {
                getLeadDetailById(LeadId);
                getSelectChannelDetailById(LeadId);
                getSelectChannelPartnerDetailById(LeadId);
                getServicePackageList();
                getRegisterServicePackageList();
            }
            else {
                window.location.href = "/ClientLead";
            }
        }
        else {
            window.location.href = "/ClientLead";
        }
    }, []);    

    const getLeadDetailById = async (LId) => {
        var AUrl = global.APIURL + '/WebLeadEdit/' + LId;
        axios.get(AUrl)
            .then(res => {
                setLeadCode(res.data[0].LeadCode);
                setFirstName(res.data[0].FirstName);
                setMiddleName(res.data[0].MiddleName);
                setLastName(res.data[0].LastName);
                setFullName(res.data[0].FullName);
                setPMobileNumber(res.data[0].MobileNumber);
                setPEmailAddress(res.data[0].EmailAddress);
                setLeadType(res.data[0].LeadType);
                setLeadProfileImage(res.data[0].LeadProfileImage)
                setGender(res.data[0].Gender);
                setCIRID(res.data[0].CIRID);
                setState(res.data[0].State);
                setDistrict(res.data[0].District);
                setCity(res.data[0].City);
                setTehsil(res.data[0].Tehsil)
                setPinCode(res.data[0].Pincode);
            })
    }

    const getSelectChannelDetailById = async LeadId => {
        var AUrl = global.APIURL + '/WebLeadChannelEdit/' + LeadId;
        axios.get(AUrl)
            .then(LCDres => {
                let LeadChannelL = LCDres.data.map(kcchannel => {
                    return kcchannel;
                });
                setSelectChannelDetail(LeadChannelL);
            });
    };

    const getSelectChannelPartnerDetailById = async LeadId => {
        var AUrl = global.APIURL + '/WebLeadChannelPartnerEdit/' + LeadId;
        axios.get(AUrl)
            .then(LCPDres => {
                let LeadPChannelL = LCPDres.data.map(kcchannepl => {
                    return kcchannepl;
                });
                setSelectChannelPartnerDetail(LeadPChannelL);
            });
    };

    const getServicePackageList = async () => {
        var AUrl = global.APIURL + '/ServicePackageActive';
        axios.get(AUrl).then(SPres => {
            let ServicePackageL = SPres.data.map((ServicePackage) => {
                return ServicePackage
            })
            setServicePackageList(ServicePackageL);
        })
    }

    const getRegisterServicePackageList = async () => {
        let VInLeadId = localStorage.getItem("ClientIndividualLeadId") == null ? "0" : localStorage.getItem("ClientIndividualLeadId") == "" ? "0" : localStorage.getItem("ClientIndividualLeadId");
        const RegSerPackArr = {
            LeadId: VInLeadId
        };
        var RSAUrl = global.APIURL + '/LeadServicePackageEdit';
        axios.post(RSAUrl, RegSerPackArr).then(RSPPres => {
            let RegisterServicePackageList = RSPPres.data.map((RegisterServicePackage) => { return RegisterServicePackage })
            setServiceActivitiesAdd(RegisterServicePackageList);
            let SPTotalAmount = 0;
            let SPTotalRecivedAmount = 0;
            let SPTotalPendingAmount = 0;
            for (let sa = 0; sa < RSPPres.data.length; sa++) {
                let VServicePackageAmount = RSPPres.data[sa].ServicePackageAmount;
                let VServicePackagePaidAmount = RSPPres.data[sa].TotalRecivedAmount;
                let VServicePackageDueAmount = RSPPres.data[sa].TotalPendingAmount;
                SPTotalAmount = SPTotalAmount + VServicePackageAmount;
                SPTotalRecivedAmount = SPTotalRecivedAmount + VServicePackagePaidAmount;
                SPTotalPendingAmount = SPTotalPendingAmount + VServicePackageDueAmount;
            }
            document.getElementById("txtFinalAmount").value = SPTotalAmount;
            document.getElementById("txtTotalRecivedAmount").value = SPTotalRecivedAmount;
            document.getElementById("txtTotalPendingAmount").value = SPTotalPendingAmount;
        })
    }

    const getServiceWiseActivity = async () => {
        var SPId = document.getElementById("ddlServicePackage").value;
        var VPaymentType = document.getElementById("ddlPaymentType").value;
        var VActionType = document.getElementById("ddlActionType").value;
        setActionType(VActionType);
        setPaymentType(VPaymentType);
        getServicePackageDetail(SPId);
        var AUrl = global.APIURL + '/ServicePackageActivities/' + SPId;
        axios.get(AUrl).then(SPres => {
            let ServicePackAct = SPres.data.map((spa) => {
                return spa
            })
            setServiceWiseActivityList(ServicePackAct);
        })
    };


    const getServicePackageDetail = async (SPId) => {
        var VActionType = document.getElementById("ddlActionType").value;
        var AUrl = global.APIURL + '/ServicePackageEdit/' + SPId;
        axios.get(AUrl)
            .then(res => {
                var SPAmount = parseFloat(res.data[0].Amount == null ? '0' : res.data[0].Amount == '' ? '0' : res.data[0].Amount);
                if (VActionType == 'Default') {
                    document.getElementById("txtDefaultSWTotalAmount").value = SPAmount;
                }
                else if (VActionType == 'Discounted') {
                    document.getElementById("txtDiscountedSWTotalAmount").value = SPAmount;
                }
            })
    }

    const ServiceActivitiesAddRow = e => {
        var SPId = document.getElementById("ddlServicePackage").value;
        var VActionType = document.getElementById("ddlActionType").value;
        var ServicesPackageAmount = 0;

        if (VActionType == 'Default') {
            var SPA = document.getElementById("txtDefaultSWTotalAmount").value;
            ServicesPackageAmount = parseFloat(SPA == null ? '0' : SPA == '' ? '0' : SPA);
        }
        else if (VActionType == 'Discounted') {
            var SPA = document.getElementById("txtDiscountedSWTotalAmount").value;
            ServicesPackageAmount = parseFloat(SPA == null ? '0' : SPA == '' ? '0' : SPA);
        }

        var ServiceActivitiesFilter = ServiceActivitiesAdd.filter(x => x.ServicePackageId == SPId);
        if (ServiceActivitiesFilter.length == 0) {
            var AUrl = global.APIURL + '/ServicePackageEdit/' + SPId;
            axios.get(AUrl).then(res => {
                if (res.data.length > 0) {
                    const ServiceActivitiesItem = {
                        RowId: (ServiceActivitiesAdd.length + 1),
                        ServicePackageId: res.data[0].ServicePackageId,
                        ServicePackage: res.data[0].Title,
                        ServicePackageAmount: ServicesPackageAmount,
                        PaymentType: document.getElementById("ddlPaymentType").value,
                        ActionType: document.getElementById("ddlActionType").value,
                        EntryType: 'N',
                        TotalRecivedAmount: 0,
                        TotalPendingAmount: ServicesPackageAmount
                    };

                    setServiceActivitiesAdd([...ServiceActivitiesAdd, ServiceActivitiesItem]);
                    var STAmount = document.getElementById("txtFinalAmount").value;
                    var STDmount = document.getElementById("txtTotalPendingAmount").value;
                    var FinalAmount = parseFloat(STAmount) + parseFloat(ServicesPackageAmount);
                    var DueAmount = parseFloat(STDmount) + parseFloat(ServicesPackageAmount);
                    document.getElementById("txtFinalAmount").value = FinalAmount;
                    document.getElementById("txtTotalPendingAmount").value = DueAmount;
                    document.getElementById("ddlServicePackage").value = '0';
                    document.getElementById("ddlPaymentType").value = 'Full';
                    document.getElementById("ddlActionType").value = 'Default';
                    ServiceWiseActivityList.length = 0;
                }
            })
            if (VActionType == 'Default') {
                document.getElementById("txtDefaultSWTotalAmount").value = '0';
            }
            else if (VActionType == 'Discounted') {
                document.getElementById("txtDiscountedSWTotalAmount").value = '0';
            }
            setButtonText('Save & Next');
        }
        else {
            alert("Service package add already exists.");
        }
    };

    const ServicePackageRegister = async () => {
        let VInLeadId = localStorage.getItem("ClientIndividualLeadId") == null ? "0" : localStorage.getItem("ClientIndividualLeadId") == "" ? "0" : localStorage.getItem("ClientIndividualLeadId");
        var InsertRowCount = 0;

        for (let spa = 0; spa < ServiceActivitiesAdd.length; spa++) {
            var SPId = ServiceActivitiesAdd[spa].ServicePackageId;
            var VEntryType = ServiceActivitiesAdd[spa].EntryType;
            var PA = ServiceActivitiesAdd[spa].ServicePackageAmount
            if (VEntryType == "N") {
                var AUrl = global.APIURL + '/ServicePackageEdit/' + SPId;
                axios.get(AUrl).then(res => {
                    if (res.data.length > 0) {
                        //var PA = res.data[0].ServicePackageAmount
                        var GST = res.data[0].GST
                        var PAGSTAmount = (parseFloat(PA) * parseFloat(GST)) / 100;

                        const SerPackRegArr = {
                            LeadServicePackageId: 0,
                            ServicePackageId: ServiceActivitiesAdd[spa].ServicePackageId,
                            LeadId: VInLeadId,
                            PackageAmount: PA,
                            PackageGSTAmount: PAGSTAmount,
                            TotalAmount: PA,
                            TotalRecivedAmount: "0",
                            TotalPendingAmount: PA,
                            PaymentType: ServiceActivitiesAdd[spa].PaymentType,
                            ActionType: ServiceActivitiesAdd[spa].ActionType,
                            PaymentStaus: 'Due',
                            CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
                        };

                        axios.post(global.APIURL + "/ServicePackageRegister", SerPackRegArr).then(res => {
                            if (spa == (ServiceActivitiesAdd.length - 1)) {
                                alert('Lead service package register successfully');
                                window.location.href = "/ClientServicesPackagePayment";
                               
                                InsertRowCount++;
                            }
                            else {
                                alert('Opps! Something Went Wrong');
                            }
                        });
                    }
                })
            }
        }
    };




    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Client Services Package Registration | KC Admin App</title>
                </MetaTags>
                <div className="container-fluid">


                    <Row className="mb-3">
                        <Col className="col-lg-8 col-md-6 col-12 mb-2">
                            <div className="breadcrum ps-0">
                                <CardTitle className="h4">Client Services Package Registration</CardTitle>
                                <a href="/" className="me-2">
                                    Home
                                </a>{" "}
                                <span>/</span>
                                <a href="/ManageClient" className="me-2 ms-2">
                                    Manage Client
                                </a>{" "}
                                <span>/</span>
                                <span className="ms-2">Client Services Package Registration</span>
                            </div>
                        </Col>
                        <Col className="col-lg-4 co-md-6 col-6">
                            <a
                                className="btn btn-primary btn-sm float-end font-14"
                                href="/ManageClient"
                            >
                                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                            </a>

                        </Col>
                    </Row>

                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div className="ind-card" id="ind-card">
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div className="ind-card" id="ind-card">
                                            <Row>
                                                <Col className="col-lg-12 col-12">
                                                    <Card>
                                                        <CardBody>
                                                            <Row className="align-items-center">
                                                                <Col className="col-md-10 col-12">
                                                                    <Row>
                                                                        <Col className="col-12 d-none d-md-block">
                                                                            <h5 className="fw-bolder mb-2">{FullName} <span className="text-primary">({LeadCode})</span></h5>
                                                                            <hr />
                                                                        </Col>
                                                                        <Col className="col-md-2 col-lg-2 col-12 text-center d-block d-md-none">
                                                                            <div className="">
                                                                                <img
                                                                                    className="rounded-circle clientactimg"
                                                                                    src={LeadProfileImage}
                                                                                ></img>
                                                                            </div>
                                                                        </Col>
                                                                        <Col className="col-12 d-block d-md-none mt-3 text-center">
                                                                            <h5 className="fw-bolder mb-2">{FullName} <span className="text-primary">({LeadCode})</span></h5>
                                                                            <hr />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mt-2 mb-2">
                                                                        <Col className="col-md-4 col-12 mb-md-0 mb-3">
                                                                            <p className="mb-2 fontWeight500"> Mobile Number </p>
                                                                            <Input
                                                                                type="text"
                                                                                className=""
                                                                                readOnly
                                                                                id="txtMobileNumber"
                                                                                defaultValue={PMobileNumber}
                                                                                placeholder="Enter Mobile Number"
                                                                            />
                                                                        </Col>
                                                                        <Col className="col-md-4 col-12 mb-md-0 mb-3">
                                                                            <p className="mb-2 fontWeight500"> Email Address </p>
                                                                            <Input
                                                                                type="text"
                                                                                className=""
                                                                                readOnly
                                                                                id="txtEmailAddress"
                                                                                defaultValue={PEmailAddress}
                                                                                placeholder="Enter Email Address"
                                                                            />
                                                                        </Col>
                                                                        <Col className="col-md-4 col-12 mb-md-0 mb-3">
                                                                            <p className="mb-2 fontWeight500"> Type </p>
                                                                            <Input
                                                                                type="text"
                                                                                readOnly
                                                                                className=""
                                                                                id="txtType"
                                                                                defaultValue={LeadType}
                                                                                placeholder="Enter Type"
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col className="col-md-2 col-lg-2 col-12 float-end d-none d-md-block">
                                                                    <div className="">
                                                                        <img
                                                                            className="rounded-circle float-end clientactimg"
                                                                            src={LeadProfileImage}
                                                                        ></img>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-lg-12 col-md-12 col-12">
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <Col className="col-md-12 col-12">
                                                                    <CardTitle>Personal Details</CardTitle>
                                                                    <hr className="mb-2" />
                                                                </Col>
                                                                <Col className="col-md-2 col-12 mb-3 mb-md-0">
                                                                    <p className="mb-2 fontWeight500"> First Name </p>
                                                                    <Input type="text" className="" id="txtFirstName" defaultValue={FirstName} readOnly />
                                                                </Col>
                                                                <Col className="col-md-2 col-12 mb-3 mb-md-0">
                                                                    <p className="mb-2 fontWeight500"> Middle Name </p>
                                                                    <Input type="text" className="" id="txtMiddleName" defaultValue={MiddleName} readOnly />
                                                                </Col>
                                                                <Col className="col-md-2 col-12 mb-3 mb-md-0">
                                                                    <p className="mb-2 fontWeight500"> Last Name </p>
                                                                    <Input type="text" className="" id="txtLastName" defaultValue={LastName} readOnly />
                                                                </Col>
                                                                <Col className="col-md-2 col-12 mb-3 mb-md-0">
                                                                    <p className="mb-2 fontWeight500"> Gender </p>
                                                                    <Input type="text" className="" id="txtGender" defaultValue={Gender} readOnly />
                                                                </Col>
                                                                <Col className="col-md-2 col-12 mb-3 mb-md-0">
                                                                    <p className="mb-2 fontWeight500"> CIR ID </p>
                                                                    <Input type="text" className="" id="txtCIRID" defaultValue={CIRID} readOnly />
                                                                </Col>
                                                                <Col className="col-md-2 col-12 mb-3 mb-md-0">
                                                                    <p className="mb-2 fontWeight500"> State </p>
                                                                    <Input type="text" className="" id="txtState" readOnly defaultValue={State} />
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-2">
                                                                <Col className="col-md-3 col-12 mt-md-3 mt-0">
                                                                    <p className="mb-2 fontWeight500"> District </p>
                                                                    <Input type="text" className="" id="txtDistrict" readOnly defaultValue={District} />
                                                                </Col>

                                                                <Col className="col-md-3 col-12 mt-3">
                                                                    <p className="mb-2 fontWeight500"> City </p>
                                                                    <Input type="text" className="" id="txtCity" readOnly defaultValue={City} />
                                                                </Col>

                                                                <Col className="col-md-3 col-12 mt-3">
                                                                    <p className="mb-2 fontWeight500"> Tehsil </p>
                                                                    <Input type="text" className="" id="txtTehsil" readOnly defaultValue={Tehsil} />
                                                                </Col>
                                                                <Col className="col-md-3 col-12 mt-3">
                                                                    <p className="mb-2 fontWeight500"> PinCode </p>
                                                                    <Input type="text" className="" id="txtPinCode" readOnly defaultValue={PinCode} />
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                    <Card>
                                                        <CardBody>
                                                            <Row>
                                                                <div className="col-lg-7">
                                                                    <CardTitle>
                                                                        Banking Source
                                                                    </CardTitle>
                                                                    <hr className="mb-2" />
                                                                    {SelectChannelDetail.map(item => (
                                                                        <div className="team-modal-box mb-1" key={item.ChannelId}>
                                                                            <div className="d-md-flex d-block align-items-center justify-content-between">
                                                                                <div className="d-md-flex d-block align-items-center">

                                                                                    <div className="flex-shrink-0">
                                                                                        <div className="avatar-md">
                                                                                            <img
                                                                                                src={item.ChannelImage}
                                                                                                alt=""
                                                                                                className="img-thumbnail"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="flex-grow-1 ms-md-3 ms-0 mb-md-0 mb-3">
                                                                                        <div className="font-14 dark-font mb-1">
                                                                                            {item.ChannelName}
                                                                                        </div>
                                                                                        <div className="mb-1">
                                                                                            <a href="" className="table-link-a font-12">
                                                                                                Branch : {item.BranchName}
                                                                                            </a>
                                                                                        </div>
                                                                                        <div className="">
                                                                                            <a href="" className="table-link-a font-12">
                                                                                                IFSE Code : {item.BranchIFSCCOde}
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="bankdiv">
                                                                                    <div className="">
                                                                                        <a href="" className="table-link-a">
                                                                                            <p className="mb-2 text-success">
                                                                                                <i className="fas fa-phone-alt me-1"></i>
                                                                                                {item.BranchContactNumber}
                                                                                            </p>
                                                                                        </a>
                                                                                        <a href="" className="table-link-a">
                                                                                            <p className="mb-0 text-primary">
                                                                                                <i className="fas fa-envelope me-1"></i>
                                                                                                {item.BranchEmailAddress}
                                                                                            </p>
                                                                                        </a>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div className="d-md-flex d-block justify-content-between align-items-center">
                                                                                <div className="mb-md-0 mb-3">
                                                                                    <div className="">
                                                                                        <span className="badge badge-soft-primary p-2 me-2">
                                                                                            {item.BranchState}
                                                                                        </span>
                                                                                        <span className="badge badge-soft-primary p-2 me-2">
                                                                                            {item.BranchDistrict}
                                                                                        </span>
                                                                                        <span className="badge badge-soft-primary p-2 me-2">
                                                                                            {item.BranchCity}
                                                                                        </span>
                                                                                        <span className="badge badge-soft-primary p-2 me-2">
                                                                                            {item.BranchTehsil}
                                                                                        </span>
                                                                                        <span className="badge badge-soft-primary p-2 me-2">
                                                                                            {item.Pincode}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="bankdiv1">
                                                                                    <p className="mb-0 font-12">Approved</p>
                                                                                    <p className="mb-0 font-12">
                                                                                        CID : {item.ChannelCode}
                                                                                    </p>
                                                                                </div>

                                                                            </div>


                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="col-lg-5 mt-3 mt-md-3 mt-lg-0">
                                                                    <CardTitle>
                                                                        Channel Partner
                                                                    </CardTitle>
                                                                    <hr className="mb-2" />
                                                                    {SelectChannelPartnerDetail.map(item => (
                                                                        <div className="team-modal-box mb-1" key={item.ChannelPartnerId}>
                                                                            <div className="d-flex align-items-center border-bottom cpbottom">
                                                                                <div className="flex-shrink-0">
                                                                                    <div className="avatar-md">
                                                                                        <img
                                                                                            src={item.ChannelPartnerLogo}
                                                                                            alt=""
                                                                                            className="img-thumbnail"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex-grow-1 ms-3">
                                                                                    <div className="font-14 dark-font mb-1">
                                                                                        {item.Name}
                                                                                    </div>
                                                                                    <div className="mb-1">
                                                                                        <a href="" className="table-link-a font-12">
                                                                                            {item.Designation}
                                                                                        </a>
                                                                                    </div>
                                                                                    <div className="">
                                                                                        <a href="" className="table-link-a font-12">
                                                                                            {item.ChannelName}
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="modal-sub-box pt-3 d-block d-md-flex">
                                                                                <div className="department-box mb-2 mb-md-0">
                                                                                    <a className="mb-0 text-success">
                                                                                        <i className="fas fa-phone-alt me-1"></i>
                                                                                        {item.MobileNumber}
                                                                                    </a>
                                                                                </div>
                                                                                <div className="emp-code">
                                                                                    <a className="mb-0 text-primary">
                                                                                        <i className="fas fa-envelope me-1"></i>
                                                                                        {item.Email}
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            {/* <ServicesPackageRegistration /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col className="">
                                    <CardTitle>
                                        Services Package Registration
                                        <hr className="mt-1 mb-2" />
                                    </CardTitle>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-lg-12 col-md-12 col-12">
                                    <Row>
                                        <Col className="col-md-3 col-12">
                                            <div className="mb-3">
                                                <label> Service Package </label>
                                                <select id="ddlServicePackage" className="form-select" aria-label="Default select example" onChange={e => getServiceWiseActivity()}>
                                                    <option key="0" value="0">Select Service Package</option>
                                                    {ServicePackageList.map((item) => (
                                                        <option key={item.ServicePackageId} value={item.ServicePackageId}>{item.Title}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col className="col-md-3 col-12">
                                            <div className="mb-3">
                                                <label> Payment Type </label>
                                                <select id="ddlPaymentType" className="form-select" aria-label="Default select example" onChange={e => getServiceWiseActivity()}>
                                                    <option value="Full">Full</option>
                                                    <option value="Installment">Installment</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col className="col-md-3 col-12">
                                            <div className="mb-3">
                                                <label> Action Type </label>
                                                <select id="ddlActionType" className="form-select" aria-label="Default select example" onChange={e => getServiceWiseActivity()}>
                                                    <option value="Default">Default</option>
                                                    <option value="Discounted">Discounted</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col className="col-md-2 col-12">
                                            <div className="mb-3">
                                                <label> Services Package Amount </label>
                                                {ActionType == 'Discounted' ? <input type="text" id="txtDiscountedSWTotalAmount" placeholder="" className="form-control" /> : ''}
                                                {ActionType == 'Default' ? <input type="text" id="txtDefaultSWTotalAmount" readOnly placeholder="" className="form-control" /> : ''}
                                            </div>
                                        </Col>
                                        <Col className="col-md-1 col-12">
                                            <button onClick={ServiceActivitiesAddRow} className="btn btn_success servicespackagebtnsuccess">+{"    "}Add</button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-md-12 col-12">
                                    <Col className="col-lg-12 mb-2">
                                        <CardTitle>Service Wise Activity List</CardTitle>
                                        <hr />
                                    </Col>
                                    <div className="table-responsive mb-3 mt-3">
                                        <table className="table table-sm table-bordered align-middle">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Activities</th>
                                                    <th className="textaligncenter">Milestone</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ServiceWiseActivityList.map(item => (
                                                    <tr key={item.ActivitiesId}>
                                                        <td>{item.RowId}</td>
                                                        <td>{item.ActivitiesTitle}</td>
                                                        <td className="textaligncenter">{item.MilestoneAlias}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                </Col>

                                <Col className="col-md-12 col-12">
                                    <CardTitle>Service Package List</CardTitle>
                                    <div className="table-responsive mt-3 mb-3">
                                        <table className="table table-sm table-bordered align-middle">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Service Package</th>
                                                    <th className="text-end">Total Amount </th>
                                                    <th className="text-end">Paid Amount </th>
                                                    <th className="text-end">Due Amount </th>
                                                    <th className="textalignend">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ServiceActivitiesAdd.map(item => (
                                                    <tr key={item.RowId}>
                                                        <th>{item.RowId}</th>
                                                        <td>{item.ServicePackage}</td>
                                                        <td className="text-end"> {item.ServicePackageAmount}</td>
                                                        <td className="text-end"> {item.TotalRecivedAmount}</td>
                                                        <td className="text-end"> {item.TotalPendingAmount}</td>
                                                        <td className="textaligncenter">{item.EntryType == "N" ? <a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a> : ''}</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <th></th>
                                                    <td className="textalignend">Total Amount : </td>
                                                    <td className="text-end"><input type="text" id="txtFinalAmount" readOnly defaultValue={0} placeholder="" className="form-control border_greyinput text-end" /></td>
                                                    <td className="text-end"><input type="text" id="txtTotalRecivedAmount" readOnly defaultValue={0} placeholder="" className="form-control border_greyinput text-end" /></td>
                                                    <td className="text-end"><input type="text" id="txtTotalPendingAmount" readOnly defaultValue={0} placeholder="" className="form-control border_greyinput text-end" /></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                    <div className="mb-3 text-end">
                                        <button className="btn btn_success" onClick={e => ServicePackageRegister()}>{" "}{ButtonText}{" "}</button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
                </div>
            </div>

        </React.Fragment>
    );

}
export default UiRating;
