import React, { Fragment, useState, useEffect, useRef } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import html2canvas from "html2canvas";
import kcreceiptlogo from "assets/images/kcreceiptlogo.png";
import rsimage from "assets/images/rsimage.png";


const OnSort = () => {
  let columns = [
    {
      d: "#",
      text: "#",
      cell: (record, index) => {
        let RowIndex = index + 1;
        return (
          <Fragment>
            {RowIndex}
          </Fragment>
        );
      }
    },
    {
      d: "FullName",
      text: "Full Name",
      cell: (record, index) => {
        let FullName = record["FullName"];
        let LeadCode = record["LeadCode"];
        let Tag = record["Tag"];
        let CreateDate = record["CreateDate"];
        let LeadType = record["LeadType"];
        let VLeadProfileImage = record["LeadProfileImage"];
        return (

          <a onClick={RedirectLeadDetail.bind(this, record)} className="text-heading nowrap">
            <div className="d-flex">
              <div className="flex-shrink-0">
                <img
                  src={VLeadProfileImage}
                  alt=""
                  className="img-thumbnail img-leads rounded-circle"
                />
              </div>
              <div className="flex-grow-1 ms-1">
                <p className="mb-1">{FullName}</p>
                <p className="text-muted mb-0">{LeadCode}</p>
                <span className="badge badge-soft-primary px-2 py-1">{Tag}</span>
                <p className="text-muted mb-0">{CreateDate}</p>
                <p className="text-muted mb-0">{LeadType}</p>
              </div>
            </div>
          </a>

        );
      }
    },
    {
      key: "RegisterDate",
      text: "Register Date",
      sortable: true
    },
    {
      d: "Source",
      text: "Source ",
      cell: (record, index) => {
        let ChannelName = record["ChannelName"];
        let ChannelCode = record["ChannelCode"];
        let City = record["City"]
        return (
          <Fragment>
            {ChannelName}
            {ChannelCode == null ? '' : ChannelCode == '' ? '' : <p className="mb-1 text-success">ID : #{ChannelCode} </p>}
            {City}
          </Fragment>
        );
      }
    },
    {
      d: "SubSource",
      text: "Sub Source ",
      cell: (record, index) => {
        let ChannelPartnerName = record["ChannelPartnerName"];
        let Designation = record["Designation"];
        return (
          <Fragment>
            <p className="mb-1 text-wrap1">{ChannelPartnerName}</p>
            <p className="mb-1 text-wrap1">{Designation}</p>
          </Fragment>
        );
      }
    },
    {
      d: "Email & Contact Number",
      text: "Email & Contact Number ",
      cell: (record, index) => {
        let Email = record["EmailAddress"];
        let ContactNumber = record["MobileNo"];
        return (
          <Fragment>
            <p className="mb-1 text-wrap1">{Email}</p>
            <p className="mb-1 text-wrap1">{ContactNumber}</p>
          </Fragment>
        );
      }
    },
    {
      d: "Tags & Priority",
      text: "Tags & Priority",
      cell: (record, index) => {
        let Priority = record["Priority"];
        let Tags = record["Tags"];
        return (
          <Fragment>
            <span className="badge badge-soft-primary font-12">{Tags}</span>
            <br />
            <span className="badge bg-danger px-2 py-1">{Priority}</span>
          </Fragment>
        );
      }
    },
    {
      d: "action",
      text: "Action",
      cell: (record, index) => {
        return (
          <Fragment>
            <a className="me-3 text-primary" onClick={RedirectLeadDetail.bind(this, record)}><i className="mdi mdi-pencil font-size-18"></i></a>
            <a className={IconrenderSwitchColor(record)}><i className={IconrenderSwitch(record)}></i></a>
            <br />
            <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModalViewPay" className="btn btn-sm btn-primary btn-sm mt-1" onClick={getServicePackagePayment.bind(this, record)}>View Payment</button>
          </Fragment>
        );
      }
    }
  ];
  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
    button: {
      excel: true,
      print: true,
      csv: true
    }
  }
  const [records, setRecords] = useState([])
  let history = useHistory();
  const [ChannelTypeId, setChannelTypeId] = useState(0);
  const [ChannelMasterList, setChannelMasterList] = useState([]);
  const [ChannelTypeList, setChannelTypeList] = useState([]);
  const [ChannelMasterId, setChannelMasterId] = useState(0);
  const [ChannelPartnerList, setChannelPartnerList] = useState([]);
  const [ChannelPartnerId, setChannelPartnerId] = useState(0);
  const [ServicePackagePayment, setServicePackagePayment] = useState([]);

  const [ReceiptNumber, setReceiptNumber] = useState(0);
  const [ReceiptDate, setReceiptDate] = useState('');
  const [FullName, setFullName] = useState('');
  const [ReceiptTotalAmount, setReceiptTotalAmount] = useState(0);
  const [ReceiptTotalAmountInWord, setReceiptTotalAmountInWord] = useState('');
  const [PaymentMode, setPaymentMode] = useState('');
  const [ReceiptChequeDDNo, setReceiptChequeDDNo] = useState('');
  const a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
  const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  const inputRef = useRef(null);

  function IconrenderSwitch(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'mdi mdi-close font-size-18';
      default:
        return 'mdi mdi-check-all font-size-18';
    }
  }

  function IconrenderSwitchColor(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }

  function IconrenderSwitch(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'mdi mdi-close font-size-18';
      default:
        return 'mdi mdi-check-all font-size-18';
    }
  }

  function IconrenderSwitchColor(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }

  useEffect(() => {
    const TableFilter = document.getElementsByClassName('table_filter');
    for (const tf of TableFilter) {
      tf.style.display = 'none';
    }
    getWebLeadList();
    getChannelTypeList();
  }, []);

  const RedirectLeadDetail = (record) => {
    let id = record["LeadId"]
    let LeadType = record["LeadType"]
    if (LeadType == "Individual") {
      localStorage.setItem('RegisterIndividualLeadId', id);
      localStorage.setItem('RegisterFirmLeadId', '0');
      window.location.href = "/RegisteredIndividualDetail";
    }
    else if (LeadType == "Firm/Company") {
      localStorage.setItem('RegisterIndividualLeadId', '0');
      localStorage.setItem('RegisterFirmLeadId', id);
      window.location.href = "/RegisteredFirmDetail";
    }
  }
  const getWebLeadList = async () => {
    var QSearch = document.getElementById("txtQuickSearch").value;
    var LeadType = document.getElementById("txtLeadType").value;
    var StartDate = document.getElementById("txtStartDate").value;
    var EndDate = document.getElementById("txtEndDate").value;
    var LeadEnroll = document.getElementById("txtLeadEnroll").value;
    var RecordStatus = document.getElementById("txtRecord").value;

    const LeadData = {
      QuickSearch: QSearch,
      LeadType: LeadType,
      FromDate: StartDate,
      ToDate: EndDate,
      LeadStatus: '',
      LeadEnrollStatus: LeadEnroll,
      RecordStatus: RecordStatus,
      SourceId: ChannelMasterId,
      SubSourceId: ChannelPartnerId
    }

    axios.post(global.APIURL + "/WebRegisteredLeadList", LeadData)
      .then(CLres => {

        let VWebLeadList = CLres.data.map(vcl => {
          return vcl;
        });
        setRecords(VWebLeadList);
      });
  };

  const saveLead = async (e) => {
    getWebLeadList();
  }

  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }

  const exportPDF = () => {

    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Register List Report";
    const headers = [["#", "FullName", "Lead Code", "Created Date", "Lead Type", "Register Date", "Source", "Source-City", "Sub-Source", "Sub-Source Designation", "Email", "ContactNumber", "Tags", "Priority"]];

    const data = records.map((item, index) => [index + 1, item.FullName, item.LeadCode, item.CreateDate, item.LeadType, item.RegisterDate, item.ChannelName + ` (#` + (item.ChannelCode) + `)`, item.City, item.ChannelPartnerName, item.Designation, item.EmailAddress, item.MobileNo, item.Tags, item.Priority]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("RegisterListReport.pdf")
  }
  const getChannelMasterList = async (CTId) => {
    var AUrl = global.APIURL + '/ChannelMasterActiveList/' + CTId;
    fetch(AUrl)
      .then((res) => res.json())
      .then((json) => {
        setChannelMasterList(json)
      })
  }


  const getChannelTypeList = async () => {
    fetch(global.APIURL + "/ChannalTypeList")
      .then((res) => res.json())
      .then((json) => {
        setChannelTypeList(json)
      })
  }


  const getModelLeadChannelPartnerList = async CHId => {
    const LeadChannelPartnerFilter = {
      ChannelId: CHId,
      FilterSearchByName: "",
    };
    axios.post(global.APIURL + "/ActiveChannelPartner", LeadChannelPartnerFilter)
      .then(CPLres => {
        let VChannelPartnerList = CPLres.data.map(vcpl => {
          return vcpl;
        });
        setChannelPartnerList(VChannelPartnerList);
      });
  };

  const getServicePackagePayment = async (record) => {
    let VLeadId = record["LeadId"];
    const CSerPackArr = {
      LeadId: VLeadId
    };

    var AUrl = global.APIURL + '/ServicePackagePaymentList';
    axios.post(AUrl, CSerPackArr)
      .then(CSPres => {
        let ServicePackagePaymentList = CSPres.data.map(ServicePackagePayment => {
          return ServicePackagePayment;
        });
        setServicePackagePayment(ServicePackagePaymentList);
      });
  };

  const ServicePackagePaymentDetail = (item) => {
    console.log(item.LeadPaymentId);
    const ItemFilter = {
      Id: item.LeadPaymentId
    };
    axios.post(global.APIURL + "/LeadServicePackagePaymentDetail", ItemFilter)
      .then(CPLres => {
        let VServicePackagePaymentDetail = CPLres.data.map(vcpl => {
          return vcpl;
        });
        if (VServicePackagePaymentDetail.length > 0) {
          var VTotalAmount = VServicePackagePaymentDetail[0].TotalAmount == null ? '' : VServicePackagePaymentDetail[0].TotalAmount
          var VTotalAmountSplit = VTotalAmount.toString().split('.');
          var VAmountinWords = inWords(VTotalAmountSplit[0] == null ? '0' : VTotalAmountSplit[0] == '' ? '0' : VTotalAmountSplit[0])
          var VPointAmount = VTotalAmountSplit[1] == null ? '0' : VTotalAmountSplit[1] == '' ? '0' : VTotalAmountSplit[1].length == 1 ? VTotalAmountSplit[1] + '0' : VTotalAmountSplit[1];
          var VPointAmountinWords = VTotalAmountSplit.length > 1 ? inWords(VPointAmount) : '';

          var VFinalAmountinWords = '';

          if (VPointAmountinWords != '') {
            VFinalAmountinWords = VAmountinWords + ' rupees and ' + VPointAmountinWords + ' paisa';
          }
          else {
            VFinalAmountinWords = VAmountinWords + ' rupees';
          }

          setReceiptTotalAmountInWord(VFinalAmountinWords);
          setReceiptNumber(VServicePackagePaymentDetail[0].ReceiptNumber == null ? 0 : VServicePackagePaymentDetail[0].ReceiptNumber == '' ? 0 : VServicePackagePaymentDetail[0].ReceiptNumber);
          setReceiptDate(VServicePackagePaymentDetail[0].TransactionDate == null ? '' : VServicePackagePaymentDetail[0].TransactionDate);
          setFullName(VServicePackagePaymentDetail[0].FullName == null ? '' : VServicePackagePaymentDetail[0].FullName);
          setReceiptTotalAmount(VServicePackagePaymentDetail[0].TotalAmount == null ? '' : VServicePackagePaymentDetail[0].TotalAmount);
          setPaymentMode(VServicePackagePaymentDetail[0].PaymentMode == null ? '' : VServicePackagePaymentDetail[0].PaymentMode);
          setReceiptChequeDDNo(VServicePackagePaymentDetail[0].ChequeDDNo == null ? '' : VServicePackagePaymentDetail[0].ChequeDDNo);
        }
        else {
          setReceiptNumber(0);
          setReceiptDate('');
          setFullName('');
          setReceiptTotalAmount('');
          setPaymentMode('');
          setReceiptChequeDDNo('');
        }
      });

    $('#receiptModal').modal('show');
  }

  function inWords(num) {
    if ((num = num.toString()).length > 9) return 'overflow';
    var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? ' ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + ' ' : '';
    return str;
  }

  function printDocument() {
    html2canvas(document.getElementById('divprintreceiptmindiv'), { quality: 0.95 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("l", "mm", "a5");
      pdf.addImage(imgData, "JPEG", 0, 0, 210, 150);
      var Pdfname = ReceiptNumber + 'PaymentReceipt.pdf';
      pdf.save(Pdfname);
    });
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Registered Lead | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">
        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-12 mb-2">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Registered Lead</CardTitle>
              <a href="/" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <span className="ms-2">Registered Lead</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-12 text-end">
            <a className="btn btn-primary btn-sm font-14 me-2 " data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
              <i className="fas fa-filter"></i>
            </a>
            <ReactHtmlTableToExcel
              className="btn btn-primary btn-sm float-end font-14 me-2"
              table="emp"
              filename="RegisterListReport"
              sheet="Sheet"
              buttonText="Export Excel" />
            <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>

          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Row>
              <Col xl="12">
                <div className="collapse" id="collapseExample">
                  <div className="card card-body">
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label className="form-label"> Quick search</label>
                        <form className="app-search app-searchnew p-0 d-none d-lg-block">
                          <div className="position-relative"><input type="text" className="form-control" placeholder="Search..." id="txtQuickSearch" /><span className="bx bx-search-alt"></span>
                          </div>
                        </form>
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="" className="form-label">Lead Type </label>
                        <select className="form-select" aria-label="Default select example" id="txtLeadType">
                          <option value=''>All</option>
                          <option value="Individual">Individual</option>
                          <option value="Firm/Company">Firm/Company</option>

                        </select>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">Start Date </label>
                          <input type="date" className="form-control" id="txtStartDate" placeholder="name@example.com" />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="exampleFormControlInput1" className="form-label">End Date </label>
                          <input type="date" className="form-control" id="txtEndDate" placeholder="name@example.com" />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="" className="form-label">Lead Enroll Status</label>
                          <select className="form-select" aria-label="Default select example" id="txtLeadEnroll">
                            <option value="">All</option>
                            <option value="P">Pending</option>
                            <option value="E">Enrolled</option>

                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label htmlFor="" className="form-label">Record Status</label>
                          <select className="form-select" aria-label="Default select example" id="txtRecord">
                            <option value="">All</option>
                            <option value="A">Active</option>
                            <option value="D">In-Active</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="mb-3">
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                        </div>
                      </div>

                      <div className="col-md-3">
                        <label className="form-label">Channel Type</label>
                        <select className="form-select" Id="ddlChanneltype" name="ddlChanneltype" onChange={(e) => { setChannelTypeId(e.target.value); getChannelMasterList(e.target.value) }}>
                          <option value={"0"}>Select Channel Type</option>
                          {ChannelTypeList.map((ChannelType) => (
                            <option key={ChannelType.ChannelTypeId} value={ChannelType.ChannelTypeId}> {ChannelType.ChannelTypeName} </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-md-3">
                        <label className="form-label">Channel Master</label>
                        <select className="form-select" Id="ddlChannelMaster" name="ddlChannelMaster" onChange={(e) => { setChannelMasterId(e.target.value); getModelLeadChannelPartnerList(e.target.value) }}>
                          <option value={"0"}>Select Channel Master</option>
                          {ChannelMasterList.map((ChannelMaster) => (
                            <option key={ChannelMaster.ChannelMasterId} value={ChannelMaster.ChannelMasterId}> {ChannelMaster.Title} </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-3">
                        <label className="form-label">Channel Partner</label>
                        <select className="form-select" Id="ddlChannelMaster" name="ddlChannelMaster" onChange={(e) => setChannelPartnerId(e.target.value)}>
                          <option value={"0"}>Select Channel Master</option>
                          {ChannelPartnerList.map((ChannelPartner) => (
                            <option key={ChannelPartner.ChannelPartnerId} value={ChannelPartner.ChannelPartnerId}> {ChannelPartner.Name} </option>
                          ))}
                        </select>
                      </div>


                      <div className="col-md-12 mt-3 mb-2">
                        <button type="button" className="btn btn-sm btn-success font-14" onClick={saveLead}>Go</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <div>
            <table id="emp" className="table" hidden="hidden">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Full Name</th>
                  <th>Lead Code</th>
                  <th>Created date</th>
                  <th>Lead Type</th>
                  <th>Register Date</th>
                  <th>Source</th>
                  <th>Source City</th>
                  <th>Sub-Source</th>
                  <th>Sub-Source designation</th>
                  <th>Email</th>
                  <th>ContactNumber</th>
                  <th>Tags</th>
                  <th>Priority</th>
                </tr>
              </thead>
              <tbody>              {
                records.map((item, index) => {
                  return <tr key={index}>
                    <td> {index + 1} </td>
                    <td >{item.FullName}</td>
                    <td>{item.LeadCode}</td>
                    <td>{item.CreateDate}</td>
                    <td>{item.LeadType}</td>
                    <td>{item.RegisterDate}</td>
                    <td >{item.ChannelName}({item.ChannelCode})</td>
                    <td>{item.City}</td>
                    <td >{item.ChannelPartnerName}</td>
                    <td>{item.Designation}</td>
                    <td >{item.EmailAddress}</td>
                    <td>{item.MobileNo}</td>
                    <td>{item.Tags}</td>
                    <td >{item.Priority}</td>
                  </tr>
                })
              }
              </tbody>
            </table>
          </div>
        </Row>
        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div className="modal fade" id="exampleModalViewPay" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">View Payment</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form>
                  <div className="modal-body">


                    <div className="table-responsive text-center" id="style-4">
                      <table className="table table-sm table-bordered  ">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>ReceiptNo.</th>
                            <th>Services</th>
                            <th>Recived Amount</th>
                            <th>GST Amount</th>
                            <th>Total Amount</th>

                            <th>Payment Mode</th>
                            <th>Transaction Date</th>
                            <th>Download Receipt</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ServicePackagePayment.map(item => (
                            <tr key={item.RowId}>
                              <td>{item.RowId}</td>
                              <td>{item.ReceiptCode}</td>
                              <td>{item.ServicePackage}</td>
                              <td>{item.RecivedAmount}</td>
                              <td>{item.RecivedGSTAmount}</td>
                              <td>{item.TotalAmount}</td>

                              <td>{item.PaymentMode}</td>
                              <td>{item.TransactionDate}</td>
                              <td> <a className="btn-sm btn btn-outline-primary" onClick={e => ServicePackagePaymentDetail(item)} >Download</a> </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="modal fade" id="receiptModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Receipt</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="d-flex justify-content-center">
                    <div className="receiptmindiv" id="divprintreceiptmindiv" ref={inputRef}>
                      <div className="receiptmindivcontent">
                        <div className="d-flex align-items-center">
                          <div>
                            <img src={kcreceiptlogo} className="receiptlogo" alt="" />
                          </div>
                          <div>
                            <p className="receipttitle">KC FINANCIAL SOLUTIONS PVT.LTD.</p>
                          </div>
                        </div>
                        <div className="centertitle">
                          <div>
                            (Cibil & Loan Consultancy)
                          </div>
                        </div>

                        <p className="loactiondetailreceipt"><i className="fas fa-map-marker-alt"></i>H.O. : 3/139, Khandu
                          Colony,Banswara (Raj.), E-mail : Kcgroup2019@gmail.com</p>

                        <div className="d-flex justify-content-between align-items-center">
                          <div className="mainbiilno">
                            <span className="text">No.</span><span className="count">{ReceiptNumber}</span>
                          </div>
                          <div className="receiptbtn">
                            RECEIPT
                          </div>
                          <div className="d-flex">
                            <div className="inputtext">
                              Date :
                            </div>
                            <div className="receiptinput dateinput">
                              <span>{ReceiptDate}</span>
                            </div>
                          </div>

                        </div>
                        <div className="d-flex mt-3">
                          <div className="inputtext receivedwiththanksfortext">
                            Received with thanks from
                          </div>
                          <div className="receiptinput  receivedwiththanksfor">
                            <span> {FullName}</span>
                          </div>
                        </div>
                        <div className="d-flex mt-3">
                          <div className="inputtext">
                            the sum of Rs.
                          </div>
                          <div className="receiptinput  receivedwiththanksfor">
                            <span>{ReceiptTotalAmount}</span>
                          </div>
                        </div>
                        <div className="d-flex mt-3">
                          <div className="inputtext">
                            In words Rupees
                          </div>
                          <div className="receiptinput  receivedwiththanksfor">
                            <span>{ReceiptTotalAmountInWord} </span>
                          </div>
                        </div>
                        <div className="d-flex mt-3">
                          <div className="inputtext">
                            by {PaymentMode} No.
                          </div>
                          <div className="receiptinput  receivedwiththanksfor2">
                            <span> {ReceiptChequeDDNo}</span>
                          </div>
                        </div>

                        <div className="bottomreceiptdiv">
                          <div className="receiptamount">
                            <div className="amountboxlable">
                              <img src={rsimage} alt="" />
                            </div>
                            <div className="amountbox">
                              {ReceiptTotalAmount}
                            </div>

                          </div>
                          <div className="signature">
                            Auth. Singnature
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer justify-content-end">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary" onClick={printDocument}>Print</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OnSort;