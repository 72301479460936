import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label,
  Input,
} from "reactstrap"

import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import classnames from "classnames"

class UiTabsAccordions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col5: true,
      col6: true,
      col7: true,
      col8: true,
      col9: true,
      col10: false,
      col11: false,
    }
    this.toggle = this.toggle.bind(this)
    this.toggle1 = this.toggle1.bind(this)

    this.t_col1 = this.t_col1.bind(this)
    this.t_col2 = this.t_col2.bind(this)
    this.t_col3 = this.t_col3.bind(this)
    this.t_col5 = this.t_col5.bind(this)
    this.t_col6 = this.t_col6.bind(this)
    this.t_col7 = this.t_col7.bind(this)
    this.t_col8 = this.t_col8.bind(this)
    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)

    this.toggle2 = this.toggle2.bind(this)
    this.toggle3 = this.toggle3.bind(this)

    this.toggleVertical = this.toggleVertical.bind(this)
    this.toggleVerticalIcon = this.toggleVerticalIcon.bind(this)
    this.toggleCustom = this.toggleCustom.bind(this)
    this.toggleIconCustom = this.toggleIconCustom.bind(this)
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false
    })
  }

  t_col2() {
    this.setState({
      col1: false,
      col2: !this.state.col2,
      col3: false
    })
  }

  t_col3() {
    this.setState({
      col1: false,
      col2: false,
      col3: !this.state.col3
    })
  }

  t_col5() {
    this.setState({ col5: !this.state.col5 })
  }

  t_col6() {
    this.setState({ col6: !this.state.col6 })
  }

  t_col7() {
    this.setState({ col7: !this.state.col7 })
  }

  t_col8() {
    this.setState({
      col6: !this.state.col6,
      col7: !this.state.col7
    })
  }

  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false
    })
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false
    })
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      })
    }
  }

  toggle2(tab) {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      })
    }
  }

  toggle3(tab) {
    if (this.state.activeTab3 !== tab) {
      this.setState({
        activeTab3: tab,
      })
    }
  }

  toggleVertical(tab) {
    if (this.state.verticalActiveTab !== tab) {
      this.setState({
        verticalActiveTab: tab,
      })
    }
  }

  toggleVerticalIcon(tab) {
    if (this.state.verticalActiveTabWithIcon !== tab) {
      this.setState({
        verticalActiveTabWithIcon: tab,
      })
    }
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      })
    }
  }

  toggleIconCustom(tab) {
    if (this.state.customIconActiveTab !== tab) {
      this.setState({
        customIconActiveTab: tab,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Service Detail | KC Admin Group</title>
          </MetaTags>
          <Container fluid={true}>
          <div className="modal fade" id="team" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Team Detail</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <Row className="pb-4">
          <div className="col-lg-12">
          <div className="h5 mb-4">Employees :</div>
          </div>
          <Col className="col-lg-6">
         
            <div className="team-modal-box">
            <div className="d-flex align-items-center border-bottom pb-2">
                  <div className="flex-shrink-0">
                  <div className="avatar-md">
                    <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail rounded-circle" />
                  </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                  <div className="font-14 dark-font mb-1">Hardik Dudhrejiya</div>
                      <div className="mb-1"><a href="" className="table-link-a">+436-426-3677</a></div>
                      <div className="" ><a href="" className="table-link-a">Vincenzo.Runolfsson43@hotmail.com</a></div>
                  </div>
                </div>
          
            <div className="modal-sub-box pt-2">
                <div className="department-box">
                  <p className="mb-0">Department: <span className="text-success">Accounts</span></p>
                </div>
                <div className="emp-code">
                <p className="mb-0">Employee Code: <span className="text-orange">08462</span></p>
                </div>
            </div>
            </div>
          </Col>
          <Col className="col-lg-6">
          
            <div className="team-modal-box">
            <div className="d-flex align-items-center border-bottom pb-2">
                  <div className="flex-shrink-0">
                  <div className="avatar-md">
                    <img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail rounded-circle" />
                  </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                  <div className="font-14 dark-font mb-1">Yogesh Ramani</div>
                      <div className="mb-1"><a href="" className="table-link-a">+436-426-3677</a></div>
                      <div className="" ><a href="" className="table-link-a">Ralph_Witting@gmail.com</a></div>
                  </div>
                </div>
          
            <div className="modal-sub-box pt-2">
                <div className="department-box">
                  <p className="mb-0">Department: <span className="text-success">Accounts</span></p>
                </div>
                <div className="emp-code">
                <p className="mb-0">Employee Code: <span className="text-orange">08462</span></p>
                </div>
            </div>
            </div>
          </Col>
        </Row>

        <Row className="pt-4 border-top">
          <div className="col-lg-12">
          <div className="h5 mb-4">Channel Partner :</div>
          </div>
          <Col className="col-lg-6">
         
            <div className="team-modal-box">
            <div className="d-flex align-items-center border-bottom pb-2">
                  <div className="flex-shrink-0">
                  <div className="avatar-md">
                    <img src="/static/media/avatar-4.b23e41d9.jpg" alt="" className="img-thumbnail rounded-circle" />
                  </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                  <div className="font-14 dark-font mb-1">Hardik Dudhrejiya</div>
                      <div className="mb-1"><a href="" className="table-link-a">+436-426-3677</a></div>
                      <div className="" ><a href="" className="table-link-a">Vincenzo.Runolfsson43@hotmail.com</a></div>
                  </div>
                </div>
          
            <div className="modal-sub-box pt-2">
                <div className="department-box">
                  <p className="mb-0">Partrner ID: <span className="text-success">#CP202200015</span></p>
                </div>
                {/* <div className="emp-code">
                <p className="mb-0">Employee Code: <span className="text-orange">08462</span></p>
                </div> */}
            </div>
            </div>
          </Col>
          <Col className="col-lg-6">
          
            <div className="team-modal-box">
            <div className="d-flex align-items-center border-bottom pb-2">
                  <div className="flex-shrink-0">
                  <div className="avatar-md">
                    <img src="/static/media/avatar-6.dc44eabf.jpg" alt="" className="img-thumbnail rounded-circle" />
                  </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                  <div className="font-14 dark-font mb-1">Yogesh Ramani</div>
                      <div className="mb-1"><a href="" className="table-link-a">+436-426-3677</a></div>
                      <div className="" ><a href="" className="table-link-a">Ralph_Witting@gmail.com</a></div>
                  </div>
                </div>
          
            <div className="modal-sub-box pt-2">
                <div className="department-box">
                <p className="mb-0">Partrner ID: <span className="text-success">#CP202200015</span></p>
                </div>
                {/* <div className="emp-code">
                <p className="mb-0">Employee Code: <span className="text-orange">08462</span></p>
                </div> */}
            </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        {/* <button type="button" className="btn btn-primary">Save changes</button> */}
      </div>
    </div>
  </div>
</div>


              
                <div className="modal fade" id="checklist" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Checklist Notes</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
          <Row className="align-items-center">
          <Col className="col-lg-10">
          <div className="mb-3">
  <label htmlFor="exampleFormControlTextarea1" className="form-label">Add Notes</label>
  <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
</div>
          </Col>
          <Col className="col-lg-2">
          <a className ="btn btn-primary btn-sm" href="/">Save</a>
          </Col>
          </Row>
      <Row>
          
<Col className="col-12 mb-3 mt-3">
  <div className="pb-2">
    <div className="notes_box pb-3">
      Notes shared with <strong>Khushi Verma</strong>  on 1st Sep 21 <br />
      <div className="text-success">by Kautzer Yessenia</div>
      <div>Notes: Vitae perspiciatis velit similique autem. Ullam dolorem quaerat consequatur blanditiis. Incidunt occaecati modi numquam sapiente voluptatem molestiae. Sit omnis nostrum totam molestiae delectus.</div>
    </div>
    <div className="notes_box pb-3">
      Mark as re-open on 30th June 21 <br />
      <div className="text-success">by Kautzer Yessenia</div>
    </div>
    <div className="notes_box pb-3">
       Mark as complete on 30th June 21 <br />
       <div className="text-success">by Kautzer Yessenia</div>
    </div>
    <div className="notes_box pb-3">
      Created new note with attachment on 25th June 21 <br />
      <div className="text-success">by Kautzer Yessenia</div>
      <div>Notes: Autem voluptatum praesentium sed magni quasi eum provident impedit voluptatum. Quas consequatur non quia et nisi. Qui quia qui autem sit laboriosam nisi. </div>
    </div>
    <div className="notes_box pb-3">
       Created new note on 25th June 21 <br />
      <div className="text-success">by Kautzer Yessenia</div>
      <div>Notes: Autem voluptatum praesentium sed magni quasi eum provident impedit </div>
    </div>

    <div className="notes_box pb-3">
    Task Assign to Nikolaus Lavern on 17th June 21 <br />
      <div className="text-success">by Kautzer Yessenia</div>
    </div>

    <div className="notes_box pb-3">
      Open on 16th June 21  <br />
      <div className="text-success">by Kautzer Yessenia</div>
    </div>

  </div>
</Col>
</Row>  
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        {/* <button type="button" className="btn btn-primary">Save changes</button> */}
      </div>
    </div>
  </div>
</div>
<Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h4"> Service Detail </CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Service Detail</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                    <a className ="btn btn-primary btn-sm me-2 font-14 float-end" href="#">ADD</a>
                  </Col>
              </Row>
<Card>
              <CardBody>
                <Row >
                  <Col className="col-md-10 col-8">
                    <div className="d-flex logo-main mb-3 gap-4">
                      <div className="bg-light profile_pic">
                        <p className="mb-0 text-center">User <br /> Photo
                        </p>
                      </div>
                          
                        <div className="align-self-center"> 
                          <h3 className="text-dark"> Rajal Chauhan </h3>
                          <div className="text-secondary mb-2"> Reilly Y. , HDFC, Gurukul , Ahmedabad </div>
                          <div className="d-flex gap-3">
                            <div className="bg_blue p-1 px-2 rounded-3"> India </div>
                            <div className="bg_blue p-1 px-2 rounded-3"> Gujarat </div>
                            <div className="bg_blue p-1  px-2 rounded-3"> 380001 </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-md-2 col-6 align-self-center text-md-center text-start">
                   <div data-bs-toggle="modal" data-bs-target="#team">
                    <a className="btn btn-primary bg_darkblue py-3 fs-5 px-4">
                        <i className="fas fa-users text-light fs-3 "></i> <br />
                        Teams
                      </a>
                   </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          
             
            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <Nav tabs className="nav-tabs-custom nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "1",
                          })}
                          onClick={() => {
                            this.toggleCustom("1")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                          <span className="d-none d-sm-block">Details</span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "2",
                          })}
                          onClick={() => {
                            this.toggleCustom("2")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                          <span className="d-none d-sm-block">Checklist</span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "3",
                          })}
                          onClick={() => {
                            this.toggleCustom("3")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                          <span className="d-none d-sm-block">History</span>
                        </NavLink>
                      </NavItem>

                    </Nav>

                    <TabContent activeTab={this.state.customActiveTab} className="p-3 text-muted">

                <TabPane tabId="1">
                      <Row>
                          <Col className="col-lg-12">
                                <div className="h5">About Service</div>
                          </Col>
                          <Col className="col-12 mb-2">
                        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                        </Col>
                      </Row>
                        
                    <hr className="bg-bg-grey-900" />    
                    <Row>
                        <Col className="col-12 mb-3">
                        <Col className="col-lg-12">
                                <div className="h5">About Service</div>
                          </Col>
                            <ul>
                              <li className=" mb-2">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</li>
                              <li className=" mb-2">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</li>
                              <li className=" mb-2">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</li>
                              <li className=" mb-2">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</li>
                          </ul>
                        </Col>
                    </Row>
                </TabPane>
                      

                      <TabPane tabId="2">
                        <Row className="mb-3" data-bs-toggle="modal" data-bs-target="#checklist">
                            <Col className="col-1 text-center" >
                                <input
                                    type="checkbox"
                                    className="form-check-input input-mini "
                                    id="closeButton"
                                    value="checked" checked
                                />
                            </Col>
                            <Col className="col-11">
                           <a className="table-link-a">
                           Perspiciatis dolores et aperiam et et. Ut animi mollitia. Ratione adipisci est. Unde sit aut magnam nostrum repellendus et ut omnis ea. Vitae a et non quis voluptatum reprehenderit at consequuntur. Corrupti eum distinctio dolores velit voluptatibus adipisci id fuga.
                           </a>
                            </Col>  
                            </Row> 
                       

                            <Row className="mb-3" data-bs-toggle="modal" data-bs-target="#checklist">
                            <Col className="col-1 text-center">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-mini "
                                    id="closeButton"
                                    value="checked" checked
                                />
                            </Col>
                            <Col className="col-11">
                           <a className="table-link-a">
                           Nihil soluta ipsa in aliquam quis iusto sit. Ducimus perferendis suscipit voluptatem. Sit voluptas alias laborum dolores.
                           </a>
                            </Col>  
                            </Row> 
                            

                           
                            <Row className="mb-3" data-bs-toggle="modal" data-bs-target="#checklist">
                            <Col className="col-1 text-center">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-mini "
                                    id="closeButton"
                                    value="checked" checked
                                />
                            </Col>
                            <Col className="col-11">
                           <a className="table-link-a">
                           Voluptatum vel qui doloremque provident sit officiis. Repudiandae dicta doloremque ducimus. Ea pariatur pariatur deleniti molestias id et natus. Hic est ipsa qui ipsa similique et expedita ut. Inventore impedit consequatur pariatur aliquid.
                           </a>
                            </Col>  
                            </Row> 
                            
                           
                           <Row className="mb-3"  data-bs-toggle="modal" data-bs-target="#checklist">
                            <Col className="col-1 text-center">
                                <input
                                    type="checkbox"
                                    className="form-check-input input-mini "
                                    id="closeButton"
                                    value="checked" checked
                                />
                            </Col>
                            <Col className="col-11">
                            <a className="table-link-a">Est eum enim. Et veritatis ea. Eos qui nam in aperiam quia accusantium. Illo est ad quaerat.</a>
                            </Col>
                            </Row> 
                          
                      </TabPane>

                      <TabPane tabId="3">
                        <Row>
                          <Col className="col-12 mb-3">
                            <div className="pb-2">
                              <div className="notes_box pb-3">
                               <p className="mb-1"> Nihil consectetur sint eos vitae voluptate suscipit dolores nihil. Enim dolores qui quam. Modi quae dolorem exercitationem neque.</p>
                               <div className="modal-sub-box">
                                    <div className="department-box">
                                    <p className="mb-0">Status: <span className="text-orange">In Process</span></p>
                                    </div>
                                    <div className="emp-code">
                                    <p className="mb-0"><i className="fa fa-comments text-success"></i> <span className="">135 Replay</span></p>
                                    </div>
                                </div>
                              </div>
                              <div className="notes_box pb-3">
                              <p className="mb-1"> Saepe consequatur ut earum. Voluptate et qui nulla. Qui et dolor ab occaecati commodi magni nulla harum odio. Rerum nobis corporis harum recusandae sed facere.</p>
                               <div className="modal-sub-box">
                                    <div className="department-box">
                                    <p className="mb-0">Status: <span className="text-success">Complete</span></p>
                                    </div>
                                    <div className="emp-code">
                                    <p className="mb-0"><i className="fa fa-comments text-success"></i> <span className="">5 Replay</span></p>
                                    </div>
                                </div>
                              </div>
                              <div className="notes_box pb-3">
                              <p className="mb-1"> Saepe consequatur ut earum. Voluptate et qui nulla. Qui et dolor ab occaecati commodi magni nulla harum odio. Rerum nobis corporis harum recusandae sed facere.</p>
                               <div className="modal-sub-box">
                                    <div className="department-box">
                                    <p className="mb-0">Status: <span className="text-success">Complete</span></p>
                                    </div>
                                    <div className="emp-code">
                                    <p className="mb-0"><i className="fa fa-comments text-success"></i> <span className="">5 Replay</span></p>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>  
                      </TabPane>


                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            
          </Container>
          
        </div>
        
      </React.Fragment>
    )
  }
}

export default UiTabsAccordions
