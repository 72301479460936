import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';

import LinkApp from 'App';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';

const OnSort = () => {
  let columns = [
    {
      key: "SR",
      text: "#",
      sortable: true
    },
    {
      key: "EmailAddress",
      text: "Email",
      sortable: true
    },
    {
        key: "InquiryDate",
        text: "Inquiry Date",
        sortable: true
      },   
  
  ];
  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
  
  }
  const [records, setRecords] = useState([])
  const [CategoryFlag, setuserFlag] = useState("");
  const [RightsAdd, setRightsAdd] = useState("D");
  const [RightsUpdate, setRightsUpdate] = useState("D");
  const [RightsDelete, setRightsDelete] = useState("D");


  let history = useHistory();

  function IconrenderSwitch(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'mdi mdi-close font-size-18';
      default:
        return 'mdi mdi-check-all font-size-18';
    }
  }

  function IconrenderSwitchColor(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }

  useEffect(() => {
   
    const f = {
      MenuId: "22",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
      //UserTypeId: "1"
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {
   
        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add') {
            setRightsAdd(MS);
          }
          else if (MA == 'Update') {
            setRightsUpdate(MS);
          }
          else if (MA == 'Delete') {
            setRightsDelete(MS);
          }
        }
      });
    const TListData={
        "Flag":"A"
      }
      axios.post(global.APIURL + "/NewsletterInquiryList",TListData).then((res)=>{

        if(res.data.length>0)
        {
          setRecords(res.data);
        }
    
      });


  }, []);

  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }

  const exportPDF=()=>{

    const unit = "pt";
    const size = "A2"; 
    const orientation = "portrait"; 

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Newsletter Inquiry List Report";
    const headers = [["#","Email","Inquiry Date"]];

    const data = records.map((item,index)=> [index+1,item.EmailAddress,item.InquiryDate]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("NewsletterInquiryReport.pdf")
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Newsletter Inquiry | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">
        <Row className="mb-3">
          <Col className="col-lg-6 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Newsletter Inquiry</CardTitle>
              <a href="/GeneralDashboard" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <span className="ms-2">Newsletter Inquiry</span>
            </div>
          </Col>
          <Col className="col-lg-6 co-md-6 col-6">
            <div className="d-flex align-items-center justify-content-end ">
              {/* <div className="me-2">
                <select id="ddlActive" className="form-select selectbtnedit" >

                  <option value={"A"} selected={CategoryFlag === "A"}>Active Records</option>
                  <option value={"D"}>In-Active Records</option>
                  <option value={"ALL"}>ALL Records</option>

                </select>
              </div> */}
              <div>
                <a className="btn btn-primary btn-sm float-end font-14" href="/GeneralDashboard">
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
                {/* {RightsAdd == "A" ? <a className="btn btn-primary btn-sm float-end font-14 me-2" href="/Testimony/AddTestimonial">
                  <i className="mdi mdi-plus align-middle me-1"></i>Add New
                </a> : ''} */}

                <ReactHtmlTableToExcel
              className="btn btn-primary btn-sm float-end font-14 me-2"
              table="emp"
              filename="NewsletterInquiryListReport"
              sheet="Sheet"
              buttonText="Export Excel" />
              <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div>
            <table id="emp" className="table" hidden="hidden">
              <thead>
                <tr>
                <th>#</th>             
                <th>Email Address</th>
                <th>Inquiry Date</th>
                
                </tr>
              </thead>
              <tbody>              {
                records.map((item, index) => {
                  return <tr key={index}>
                    <td> {index +1} </td>                       
                    <td>{item.EmailAddress}</td> 
                    <td>{item.InquiryDate}</td> 
                                                                                                                                               
                  </tr>
                })
              }
              </tbody>
            </table>           
          </div>
        </Row>
        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default OnSort;