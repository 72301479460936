import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Media, Row, CardTitle, Input, Label, } from "reactstrap";
import MetaTags from 'react-meta-tags';

//Import Image
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import img3 from "../../assets/images/small/img-3.jpg";
import img4 from "../../assets/images/small/img-4.jpg";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";


class EmailRead extends Component {
  render() {
    return (
      <React.Fragment>
       <div className="page-content">
          <MetaTags>
            <title>Holiday Master | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-5">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Holiday Mater</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
              <Col className="col-12">
                <div className ="d-flex align-items-center justify-content-between">
                 <div className="d-flex">
                  <CardTitle className="h6 mb-2">Holiday Master</CardTitle>
                 </div>
                </div>
              
              </Col>
            <Row>
              
              <Col className="col-12 mt-4">
                <Card>
                  <CardBody>
                     {/* User list */}
                    
                     <Row className="mt-3">
                        <Col lg={12}>
                        <div className="table-responsive">
               <table className="table table-borderless">
                      {/* <thead>
                        <tr className="">
                          <th scope="col">Sr</th>
                          <th scope="col" className="">Leave Type</th>
                          <th scope="col" className="">Short Name</th>
                          <th scope="col" className="">Credit Type</th>
                          <th scope="col" className="">Eligibility</th>
                          <th scope="col" className="">Carry Forward</th>
                          <th scope="col" className="">Action</th>
                        </tr>
                      </thead> */}
                      <tbody>
                        <tr className="">
                          <td>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Holiday Title</option>
                              <option>...</option>
                            </select>
                          </div>
                          </td>
                          <td className="width-9">
                           <p>Holiday Date</p>
                           <div className="d-flex">
                          <div className="form-check mb-2">
                              <input
                                type="radio"
                                id="radio1"
                                name="toastType"
                                className="form-check-input"
                                value="success"
                                defaultChecked
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio1"
                              >
                                Single
                              </Label>
                            </div>

                            <div className="form-check mb-2 ms-2">
                              <input
                                type="radio"
                                id="radio2"
                                name="toastType"
                                className="form-check-input"
                                value="info"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio2"
                              >
                               Multiple
                              </Label>
                            </div>
                          </div>
                          <div className="d-flex">
                            <input className="form-control" type="date" id="example-date-input" value=""></input>
                            {/* <input className="form-control" type="date" id="example-date-input" value=""></input> */}
                          </div>
                          
                          </td>
                          <td className="">
                          <div className ="d-flex align-items-center
                 justify-content-between">
                   
                      
                  <div className ="d-flex">
                  <div  className="form-check">
                                          <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                    <a className ="btn btn-primary btn-sm" href="/">Save</a>
                    <a className ="btn btn-primary btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
                          </td>
                         
                        </tr>
                        <tr>
                          <td>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Holiday Title</option>
                              <option>...</option>
                            </select>
                          </div>
                          </td>
                          <td className="">
                           <p>Holiday Date</p>
                           <div className="d-flex">
                          <div className="form-check mb-2">
                              <input
                                type="radio"
                                id="radio1"
                                name="toastType"
                                className="form-check-input"
                                value="success"
                                defaultChecked
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio1"
                              >
                                Single
                              </Label>
                            </div>

                            <div className="form-check mb-2 ms-2">
                              <input
                                type="radio"
                                id="radio2"
                                name="toastType"
                                className="form-check-input"
                                value="info"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio2"
                              >
                               Multiple
                              </Label>
                            </div>
                          </div>
                          <div className="d-flex">
                            <input className="form-control" type="date" id="example-date-input" value=""></input>
                            <input className="form-control ms-3" type="date" id="example-date-input" value=""></input>
                          </div>
                          
                          </td>
                          <td>
                          <div className ="d-flex align-items-center
                 justify-content-between">
                   
                      
                  <div className ="d-flex">
                  <div  className="form-check">
                                          <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                    <a className ="btn btn-primary btn-sm" href="/">Save</a>
                    <a className ="btn btn-primary btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
                          </td>
                         
                        </tr>
                      </tbody>
                  </table>
               </div>
                        </Col>
                      </Row>

                      <Row className="mt-5">
                          <Col className="col-12">
                              <div className ="d-flex justify-content-end">
                                <div className="border-bottom ms-4">
                                        Search <i className="mdi mdi-filter h4 ms-3"></i>
                                </div>
                              </div>
                          </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col lg={12}>
                        <div className="table-responsive">
               <table className="table table-striped">
                      <thead>
                        <tr className="">
                          <th scope="col" className="width-9">Sr No.</th>
                          <th scope="col" className="w-20">Holiday Title</th>
                          <th scope="col" className="w-20">Holiday Date</th>
                          <th scope="col" className="">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="">1</th>
                          <td>Diwali</td>
                          <td>6/11/2020 to 9/11/2020</td>
                         
                          <td className="width-9">
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil h4 me-3"></i></a>
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-check-box-outline h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="">2</th>
                          <td>New Year</td>
                          <td>01/01/2021</td>
                         
                          <td className="width-9">
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil h4 me-3"></i></a>
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-check-box-outline h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>
                       
                       
                      </tbody>
                  </table>
               </div>
                        </Col>
                      </Row>
                     
                               <nav className="mt-3" aria-label="Page navigation example">
  <ul className="pagination justify-content-center">
    <li className="page-item disabled">
      <a className="page-link" href="#" tabIndex ="-1" aria-disabled="true">Previous</a>
    </li>
    <li className="page-item"><a className="page-link" href="#">1</a></li>
    <li className="page-item"><a className="page-link" href="#">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item">
      <a className="page-link" href="#">Next</a>
    </li>
  </ul>
</nav>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EmailRead;
