import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';

import LinkApp from 'App';



const OnSort = () => {
  let columns = [
    {
      key: "SR",
      text: "#",
      sortable: true
    },
    // {
    //   key: "Name",
    //   text: "Name",
    //   sortable: true
    // },
    // {
    //   key: "Email",
    //   text: "Email",
    //   sortable: true
    // },
    // {
    //   key: "Mobilenumber",
    //   text: "Mobilenumber",
    //   sortable: true
    // },
    {
      key: "ChannelTypeName",
      text: "Network Type",
      sortable: true
    },
    {
      key: "CompanyName",
      text: "Network Source",
      sortable: true
    },
    {
      key: "Branchcode",
      text: "Branchcode",
      sortable: true
    },
    {
      key: "CorporateEmail",
      text: "Email",
      sortable: true
    },
    {
      key: "Address",
      text: "Address",
      sortable: true
    },
    {
      d: "Status",
      text: "Status",
      cell: (record, index) => {
        let LeadStatus = record["LeadStatus"];
        let LeadStatusClass = record["LeadStatusClass"];
        return (
          <Fragment>
            <span className={LeadStatusClass}>{LeadStatus}</span>
          </Fragment>
        );
      }
    },
    {
      d: "action",
      text: "Action",
      cell: (record, index) => {


        return (
          <Fragment>
            {RightsUpdate == "A" ? <a className="me-3 text-primary" onClick={(editRecord.bind(this, record))}><i className="mdi mdi-pencil font-size-18"></i></a> : ''}
          </Fragment>
        );
      }
    }
  ];
  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
    // button: {
    //     excel: true,
    //     print: true,
    //     csv: true
    // }
  }
  const [records, setRecords] = useState([])
  const [CreditStatusFlag, setCreditStatusFlag] = useState("");
  const [RightsAdd, setRightsAdd] = useState("D");
  const [RightsUpdate, setRightsUpdate] = useState("D");
  const [RightsDelete, setRightsDelete] = useState("D");


  let history = useHistory();

  function IconrenderSwitch(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'mdi mdi-close font-size-18';
      default:
        return 'mdi mdi-check-all font-size-18';
    }
  }

  function IconrenderSwitchColor(param) {
    let UTFlag = param["Flag"];
    switch (UTFlag) {
      case 'A':
        return 'text-danger';
      default:
        return 'text-success';
    }
  }

  useEffect(() => {
    const f = {
      MenuId: "50",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {
        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add') {
            setRightsAdd(MS);
          }
          else if (MA == 'Update') {
            setRightsUpdate(MS);
          }
          else if (MA == 'Delete') {
            setRightsDelete(MS);
          }
        }
      });
    fetch(global.APIURL + "/corporateLeadListclose")
      .then((res) => res.json())
      .then((json) => {
        setRecords(json)
      })
  }, []);


  const editRecord = (record) => {
    let id = record["CorporateleadId"]
    let path = `CorLeads/EditCorCloseList/:${id}`;
    history.push(path);
  }

  const exportPDF = () => {

    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Corporate Closed List Report";
    const headers = [["#", "Network Type", "Network Source", "Branch Code", "Email", "Address", "Status"]];

    const data = records.map((item, index) => [index + 1, item.ChannelTypeName, item.CompanyName, item.Branchcode, item.CorporateEmail, item.Address, item.LeadStatus]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("CorporateClosedListReport.pdf")
  }


  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Corporate Lead Close | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">
        <Row className="mb-3">
          <Col className="col-lg-6 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Corporate Lead Close</CardTitle>
              <a href="/GeneralDashboard" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <span className="ms-2">Corporate Lead Close</span>
            </div>
          </Col>
          <Col className="col-lg-6 co-md-6 col-6">
            <div className="d-flex align-items-center justify-content-end ">
              {/* <div className="me-2">
                <select id="ddlActive" className="form-select selectbtnedit" onChange={handleChange}>

                  <option value={"A"} selected={CreditStatusFlag === "A"}>Active Records</option>
                  <option value={"D"}>In-Active Records</option>
                  <option value={"ALL"}>ALL Records</option>

                </select>
              </div> */}
              <div>
                {RightsAdd == "A" ? <a className="btn btn-primary btn-sm float-end font-14 me-2" href="/CorLeads/Addcorleads">
                  <i className="mdi mdi-plus align-middle me-1"></i>Add New
                </a> : ''}
                <ReactHtmlTableToExcel
                  className="btn btn-primary btn-sm float-end font-14 me-2"
                  table="emp"
                  filename="CorporateClosedListReport"
                  sheet="Sheet"
                  buttonText="Export Excel" />
                <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
              </div>

            </div>
          </Col>

        </Row>
        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>

        <table id="emp" className="table" hidden="hidden">
          <thead>
            <tr>
              <th>#</th>
              <th>Network Type</th>
              <th>Network Source</th>
              <th>Branch Code</th>
              <th>Email</th>
              <th>Address</th>
              <th>Status</th>

            </tr>
          </thead>
          <tbody>
            {
              records.map((item, index) => {
                return <tr key={index}>
                  <td> {index + 1} </td>
                  <td>{item.ChannelTypeName}</td>
                  <td>{item.CompanyName}</td>
                  <td>{item.Branchcode}</td>
                  <td>{item.CorporateEmail}</td>
                  <td>{item.Address}</td>
                  <td>{item.LeadStatus}</td>

                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OnSort;