import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import { Email } from '@mui/icons-material';


const AddActivities = () => {
  const history = useHistory();
  const [ActionTypeList, setActionTypeList] = useState([]);
  const [ActionId, setActionId] = useState("");
  const [PageList, setPageList] = useState([]);
  const [StatusList, setStatusList] = useState([]);
  const [PageId, setPageId] = useState("");
  const [StatusId, setStatusId] = useState(0);
  const [StatusTitle, setStatusTitle] = useState("");
  const [Title, setTitle] = useState("");
  const [ISVisible, setISVisible] = useState("");
  const [ActionTitle, setActionTitle] = useState("");
  const [ActivitiesId, setActivitiesId] = useState("");
  const [ServiceActivitiesList, setServiceActivitiesList] = useState([]);
  const [IsActive, setIsActive] = useState("");

  const StatusOnChange = e => {
    var VStatus = e.nativeEvent.target.selectedIndex;
    var VStatusId = e.nativeEvent.target[VStatus].value;
    var VStatusTitle = e.nativeEvent.target[VStatus].text;
    setStatusId(VStatusId);
    setStatusTitle(VStatusTitle);
  }

  const  ActivitiesAddRow = e => {
    const ActivitiesItem = {
      ActivitiesDetailId: 0,
      RowId: (ServiceActivitiesList.length + 1),
      ActionTitle: ActionTitle,
      ActionId: ActionId == "Select Action Type" ? "" : ActionId,
      PageId: PageId == "Select Master Name" ? "" : PageId,
      StatusId: StatusId,
      StatusTitle: StatusTitle == "" ? "Pending" : StatusTitle == "Select Status" ? "Pending" : StatusTitle,
    };

    setServiceActivitiesList([...ServiceActivitiesList, ActivitiesItem]);
    document.getElementById('txtActionTitle').value = '';
    document.getElementById("ddlActionType").value = 0;
    document.getElementById("ddlPageMaster").value = 0;
    document.getElementById("ddlStatus").value = 0;

    setActionTitle('');
    setActionId('');
    setPageId('');
    setStatusId(0);
    setStatusTitle('');
  };
  
  const ActivitiesDeleteRow = (e, idx) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = idx;
      setServiceActivitiesList(
        ServiceActivitiesList.filter(item => item.RowId !== RId)
      );
    }
  };
  useEffect(() => {

    getActionType();
    getpageList();
    getStatusList();



  }, []);

  const getActionType = async () => {

    axios.get(global.APIURL + '/ActiveALL')
      .then(Tres => {
        let ActiveTag = Tres.data.map((kctag) => {
          return kctag
        })
        setActionTypeList(ActiveTag);

      })
  }
  const getpageList = async () => {

    axios.get(global.APIURL + '/PageALL')
      .then(Tres => {
        let ActiveTag = Tres.data.map((kctag) => {
          return kctag
        })
        setPageList(ActiveTag);

      })
  }

  const getStatusList = async () => {

    axios.get(global.APIURL + '/StatusActiveList')
      .then(Tres => {
        let StatusList = Tres.data.map((statusal) => {
          return statusal
        })
        setStatusList(StatusList);

      })
  }


  function submitForms() {

    submitFormDetails();
  }
  function submitFormDetails() {
    var ActivitiesActionListArr=[];
    for (let con = 0; con < ServiceActivitiesList.length; con++) {
      const ServiceActivitiesListDetail = ServiceActivitiesList[con];
      const ContactItemArr = {
        ActivitiesDetailId: 0,
        ActionTitle: ServiceActivitiesListDetail.ActionTitle,
        ActionId: ServiceActivitiesListDetail.ActionId,
        PageId: ServiceActivitiesListDetail.PageId,
        StatusId: ServiceActivitiesListDetail.StatusId,
        StatusTitle: ServiceActivitiesListDetail.StatusTitle,
      };
      ActivitiesActionListArr.push(ContactItemArr);
    }
    var docdata = {
      "ActivitiesId": 0,
      "Title": Title,
      "ISVisible": IsActive == true ? "Yes" : "No",
      "Flag": "A",
      "CreateUser": localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
      "ActivitiesActionList":ActivitiesActionListArr
    }
    axios.post(global.APIURL + "/ActivityMaster", docdata)
      .then(res => {
        if (res.data.length > 0) {
          //setActivitiesId(res.data[0].ActivitiesId);
          //let AId = res.data[0].ActivitiesId;
          alert(res.data[0].RecordStatus);
          // alert("Activities save successfully.");
          history.push("/Activities");
        }
      });

  }
  return (
    <div className="page-content">

      <MetaTags>
        <title>Activities | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Add Activities</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <a href="/Activities" className="ms-2 me-2">Activities</a>
              <span>/</span>
              <span className="ms-2">Add Activities</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-6">
            <a className="btn btn-primary btn-sm float-end font-14" href="/Activities">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row>
                  <Col lg={6}>
                    <div className="mb-2">
                      <label className="form-label">Title
                        <input className="form-check-input  ms-2" type="checkbox" id="autoSizingCheck" onChange={e => setIsActive(e.target.checked)} />
                        <label className="form-check-label ms-2" htmlFor="autoSizingCheck">Is In App Visible ?</label>
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Title"
                        id="txtCompanyName"
                        value={Title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>

                  </Col>

                  <Col lg={4}>
                    <div className="col-lg-4 mt-3">

                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="mb-2">
                    <CardTitle>Action Type List</CardTitle>
                    <hr />
                  </Col>
                  <Col lg={4} className="col-md-6 col-lg-4 mb-md-3 mb-lg-0">
                    <label className="form-label">Title</label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      name="txtActionTitle"
                      id="txtActionTitle"
                      onChange={(e) => setActionTitle(e.target.value)}
                    ></Input>
                  </Col>
                  <Col lg={2} className="col-md-6 col-lg-2 mb-md-3 mb-lg-0">
                    <label className="form-label">Action Type</label>
                    <select className="form-select" name="ddlActionType" id="ddlActionType" onChange={(e) => setActionId(e.target.value)}>
                      <option selected value="0">Select Action Type </option>
                      {ActionTypeList.map((item) => (
                        <option key={item.Title} value={item.Title}>
                          {item.Title}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col lg={2} className="col-md-6 col-lg-2 mb-md-3 mb-lg-0">
                    <label className="form-label">Select Drop-down Master</label>
                    <select className="form-select" name="ddlPageMaster" id="ddlPageMaster" onChange={(e) => setPageId(e.target.value)}>
                      <option selected value="0">Select Master Name</option>
                      {PageList.map((item) => (
                        <option key={item.Title} value={item.Title}>
                          {item.Title}
                        </option>
                      ))}

                    </select>
                  </Col>
                  <Col lg={2} className="col-md-6 col-lg-2 mb-md-3 mb-lg-0">
                    <label className="form-label">Select Status</label>
                    <select className="form-select" name="ddlStatus" id="ddlStatus" onChange={StatusOnChange}>
                      <option selected value="0">Select Status</option>
                      {StatusList.map((item) => (
                        <option key={item.StatusId} value={item.StatusId}>
                          {item.StatusTitle}
                        </option>
                      ))}

                    </select>
                  </Col>
                  <Col lg={2} className="col-md-12 col-lg-2 mb-md-3 mb-lg-0 pt-1">
                    <button type="button" className="btn btn-primary mt-md-0 mt-lg-4" onClick={ActivitiesAddRow}>
                      Add Activities
                    </button>
                  </Col>
                </Row>

                <Row className='mt-4'>
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      <table className="table table-bordered align-middle">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Action</th>
                            <th scope="col">Action Type</th>
                            <th scope="col">Master Name</th>
                            <th scope="col">Status</th>
                            <th className="text-center" scope="col">Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ServiceActivitiesList.map(item => (
                            <tr key={item.ActionId}>
                              <th scope="row">{item.RowId}</th>
                              <td>{item.ActionTitle}</td>
                              <td>{item.ActionId}</td>
                              <td>{item.PageId}</td>
                              <td>{item.StatusTitle}</td>
                              <td className="text-center"><a className="text-danger" href="#" onClick={e => ActivitiesDeleteRow(e, item.RowId)}><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Col lg={12}>
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={submitForms} >Save</button>
                    <a className="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AddActivities;