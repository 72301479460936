import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label,
  Input,
} from "reactstrap"

import { Link } from "react-router-dom"



import classnames from "classnames"

class UiTabsAccordions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col5: true,
      col6: true,
      col7: true,
      col8: true,
      col9: true,
      col10: false,
      col11: false,
    }
    this.toggle = this.toggle.bind(this)
    this.toggle1 = this.toggle1.bind(this)

    this.t_col1 = this.t_col1.bind(this)
    this.t_col2 = this.t_col2.bind(this)
    this.t_col3 = this.t_col3.bind(this)
    this.t_col5 = this.t_col5.bind(this)
    this.t_col6 = this.t_col6.bind(this)
    this.t_col7 = this.t_col7.bind(this)
    this.t_col8 = this.t_col8.bind(this)
    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)

    this.toggle2 = this.toggle2.bind(this)
    this.toggle3 = this.toggle3.bind(this)

    this.toggleVertical = this.toggleVertical.bind(this)
    this.toggleVerticalIcon = this.toggleVerticalIcon.bind(this)
    this.toggleCustom = this.toggleCustom.bind(this)
    this.toggleIconCustom = this.toggleIconCustom.bind(this)
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false
    })
  }

  t_col2() {
    this.setState({
      col1: false,
      col2: !this.state.col2,
      col3: false
    })
  }

  t_col3() {
    this.setState({
      col1: false,
      col2: false,
      col3: !this.state.col3
    })
  }

  t_col5() {
    this.setState({ col5: !this.state.col5 })
  }

  t_col6() {
    this.setState({ col6: !this.state.col6 })
  }

  t_col7() {
    this.setState({ col7: !this.state.col7 })
  }

  t_col8() {
    this.setState({
      col6: !this.state.col6,
      col7: !this.state.col7
    })
  }

  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false
    })
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false
    })
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      })
    }
  }

  toggle2(tab) {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      })
    }
  }

  toggle3(tab) {
    if (this.state.activeTab3 !== tab) {
      this.setState({
        activeTab3: tab,
      })
    }
  }

  toggleVertical(tab) {
    if (this.state.verticalActiveTab !== tab) {
      this.setState({
        verticalActiveTab: tab,
      })
    }
  }

  toggleVerticalIcon(tab) {
    if (this.state.verticalActiveTabWithIcon !== tab) {
      this.setState({
        verticalActiveTabWithIcon: tab,
      })
    }
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      })
    }
  }

  toggleIconCustom(tab) {
    if (this.state.customIconActiveTab !== tab) {
      this.setState({
        customIconActiveTab: tab,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Service | KC Admin Group</title>
          </MetaTags>
          <Container fluid={true}>
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h4">Services</CardTitle>
                      <a href="/ClientList" className="me-2">Home</a> <span>/</span><span className="ms-2">Service List</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>

            <Row>
              <Col xl={12}>
                <Card>
                 <CardBody>
                    <div className="row align-items-center">
                    <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Lead ID</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Lead ID"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Customer ID</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Customer ID"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Service Status</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Service status"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Date of Lead</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="DOL"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-2">
                          <a className="btn btn-primary btn-sm font-14 me-3" href="#"> Gmail</a>
                            <a className="btn btn-warning btn-sm font-14" href="#">CBIL</a>
                          </div>
                        </Col>
                    </div>
                 </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
                <Col>
                <Card>
                    <CardBody>
                        <Row>
                        <Col className="col-lg-10">
                            <Row>
                            <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Name"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Date Of Birth</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="DOB"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Mobile No.</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Enter 10digit mobile no"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Adhar</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Adhar"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">PAN</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="PAN"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Email ID</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Email ID"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">CIR ID 1</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="CIR"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">CIR Password</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="CIR Password"
                            />
                          </div>
                        </Col>
                        
                       
                            </Row>
                          </Col>
                          <Col className="col-lg-2">
                          <label className="form-label d-block text-center">Upload Image</label>
                          <div className ="d-flex logo-main float-end">
                         
                              <div className="logo-box">
                                  <p className="mb-0">User</p>
                              </div>
                                <div className="icon-box">
                              <a href="">  <i className="mdi mdi-pencil"></i></a>
                                </div>
                        </div>
                          </Col>
                        </Row>
                    </CardBody>
                </Card>
                </Col>
            </Row>

            <Row>
              <Col xl={12}>
                <Card>
                 <CardBody>
                    <div className="row align-items-center">
                    <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Service1</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Service1"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Bank / FI Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Customer ID"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Account Type</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Account Type"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Status</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Status"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Current Balance</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Current Balance"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">OD Balance</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="OD Balance"
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                          <label className="form-label">Remarks</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Remarks" rows="3"></textarea>
                          </div>
                        </Col>
                        <Col lg={2}>
                        <button  type="button" className="btn btn-danger mb-2 deleteMultipleImages float-right">
                            <i className="bx bx-trash d-block font-size-16"></i>
                        </button>

                        </Col>
                        <Col lg={6}>
                        <a className ="btn btn_success btn-sm font-14" href="#"><i className="mdi mdi-plus align-middle me-1"></i>Add</a>
                        </Col>
                    </div>
                 </CardBody>
                </Card>
              </Col>
            </Row>
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default UiTabsAccordions
