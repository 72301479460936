import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { size } from "lodash";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Media,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
  CardTitle,
  CardBody,
  Label,
} from "reactstrap";
import classnames from "classnames";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Images
import images from "../../assets/images";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  addMessage,
  getChats,
  getContacts,
  getGroups,
  getMessages,
} from "../../store/actions";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRoomId: 1,
      currentUser: {
        name: "Henry Wells",
        isActive: true,
      },
      notification_Menu: false,
      search_Menu: false,
      settings_Menu: false,
      other_Menu: false,
      activeTab: "1",
      Chat_Box_Username: "Steven Franklin",
      Chat_Box_User_Status: "online",
      Chat_Box_User_isActive: false,
      curMessage: "",
    };
    this.messageBox = null;
  }

  componentDidMount() {
    const { onGetChats, onGetGroups, onGetContacts, onGetMessages } =
      this.props;
    const { currentRoomId } = this.state;
    onGetChats();
    onGetGroups();
    onGetContacts();
    onGetMessages(currentRoomId);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { messages } = this.props;
    if (size(messages) !== size(prevProps.messages)) {
      this.scrollToBottom();
    }
  }

  toggleNotification = () => {
    this.setState(prevState => ({
      notification_Menu: !prevState.notification_Menu,
    }));
  };

  //Toggle Chat Box Menus
  toggleSearch = () => {
    this.setState(prevState => ({
      search_Menu: !prevState.search_Menu,
    }));
  };

  toggleSettings = () => {
    this.setState(prevState => ({
      settings_Menu: !prevState.settings_Menu,
    }));
  };

  toggleOther = () => {
    this.setState(prevState => ({
      other_Menu: !prevState.other_Menu,
    }));
  };

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  //Use For Chat Box
  userChatOpen = (id, name, status, roomId) => {
    const { onGetMessages } = this.props;
    this.setState({ Chat_Box_Username: name, currentRoomId: roomId });
    onGetMessages(roomId);
  };

  addMessage = (roomId, sender) => {
    const { onAddMessage } = this.props;
    const message = {
      id: Math.floor(Math.random() * 100),
      roomId,
      sender,
      message: this.state.curMessage,
      createdAt: new Date(),
    };
    this.setState({ curMessage: "" });
    onAddMessage(message);
  };

  scrollToBottom = () => {
    if (this.messageBox) {
      this.messageBox.scrollTop = this.messageBox.scrollHeight + 1000;
    }
  };

  onKeyPress = e => {
    const { key, value } = e;
    const { currentRoomId, currentUser } = this.state;
    if (key === "Enter") {
      this.setState({ curMessage: value });
      this.addMessage(currentRoomId, currentUser.name);
    }
  };

  //serach recent user
  searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("search-user");
    filter = input.value.toUpperCase();
    ul = document.getElementById("recent-list");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };

  render() {
    const { chats, groups, contacts, messages } = this.props;
    const { currentRoomId, currentUser } = this.state;

    return (
      <React.Fragment>
          <div className="page-content">
          <MetaTags>
            <title>Salary Scale Master | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-5">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Salary Scale Master</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
          
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
              <Col className="col-12">
                <div className ="d-flex align-items-center justify-content-between">
                 <div className="d-flex">
                  <CardTitle className="h6 mb-2">Salary Scale Master</CardTitle>
                 </div>
                </div>
              
              </Col>
            <Row>
              
              <Col className="col-12 mt-4">
                <Card>
                  <CardBody>
                     {/* User list */}
                     <Row>
                     <Col lg={4}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Company</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                     <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Basic Salary (In %)"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Allowance</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                     </Row>
                     <Row>
                       <Col lg={6}>
                         <p className="mt-3 mb-3">For</p>
                          <div className="d-flex">
                          <div className="form-check mb-2">
                              <input
                                type="radio"
                                id="radio1"
                                name="toastType"
                                className="form-check-input"
                                value="success"
                                defaultChecked
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio1"
                              >
                                Employee Specific
                              </Label>
                            </div>

                            <div className="form-check mb-2 ms-2">
                              <input
                                type="radio"
                                id="radio2"
                                name="toastType"
                                className="form-check-input"
                                value="info"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio2"
                              >
                               Employer Specific
                              </Label>
                            </div>
                          </div>
                       </Col>
                     </Row>
                    
                      <Row>
                        <Col className=" col-md-4 col-12 mt-3">
                <div className ="d-flex align-items-center
                 justify-content-between">
                   
                      
                  <div className ="d-flex">
                    <a className ="btn btn-primary btn-sm ms-2" href="/">ADD</a>
                  </div>
                </div>
              
              </Col>
                      </Row>

                      <Row className="mt-5 mb-5">
                      <CardTitle className="h6 mb-2">Employee Specific</CardTitle>
                        <Col lg={12}>
                        <div className="table-responsive">
               <table className="table table-striped">
                      <thead>
                        <tr className="">
                          <th scope="col">Sequence</th>
                          <th scope="col" className="">Allowance Title</th>
                          <th scope="col" className="">Taxable</th>
                          <th scope="col" className="">Apply On</th>
                          <th scope="col" className="">Remaining of Gross</th>
                          <th scope="col" className="">Value In</th>
                          <th scope="col" className="">Value</th>
                          <th scope="col" className="">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="">1</th>
                          <td>PF (Deduction)</td>
                          <td>No</td>
                          <td>Gross</td>
                          <td className="text-center"><input
                                type="checkbox"
                                className="form-check-input input-mini"
                                id="closeButton"
                                value="checked"
                              /></td>
                          <td><select id="inputState" className="form-select">
                              <option selected>Percentage</option>
                              <option>...</option>
                            </select></td>
                            <td><div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder=""
                            />
                          </div></td>
                          <td className="width-9">
                            <div className="d-flex align-items-center">
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-delete h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row" className="">2</th>
                          <td>HRA (Earning)</td>
                          <td>Yes</td>
                          <td>Basic</td>
                          <td className="text-center"><input
                                type="checkbox"
                                className="form-check-input input-mini"
                                id="closeButton"
                                value="checked"
                              /></td>
                          <td><select id="inputState" className="form-select">
                              <option selected>Numeric</option>
                              <option>...</option>
                            </select></td>
                            <td><div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder=""
                            />
                          </div></td>
                          <td className="width-9">
                            <div className="d-flex align-items-center">
                             
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-delete h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>
                      
                      </tbody>
                  </table>
               </div>
                        </Col>
                      </Row>

                      <Row className="mt-5 mb-5">
                      <CardTitle className="h6 mb-2">Employer Specific</CardTitle>
                        <Col lg={12}>
                        <div className="table-responsive">
               <table className="table table-striped">
                      <thead>
                        <tr className="">
                          <th scope="col">Sequence</th>
                          <th scope="col" className="">Allowance Title</th>
                          <th scope="col" className="">Value Type</th>
                          <th scope="col" className="">Apply On</th>
                          <th scope="col" className="">Percentage Value</th>
                          <th scope="col" className="">Amount Value</th>
                          <th scope="col" className="">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="">1</th>
                          <td>PF</td>
                          <td><select id="inputState" className="form-select">
                              <option selected>Numeric</option>
                              <option>...</option>
                            </select></td>
                           
                          <td>Gross</td>
                          <td><div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder=""
                            />
                          </div></td>
                          <td><div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder=""
                            />
                          </div></td>
                          <td className="width-9">
                            <div className="d-flex align-items-center">
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-delete h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row" className="">2</th>
                          <td>PF</td>
                          <td><select id="inputState" className="form-select">
                              <option selected>Numeric</option>
                              <option>...</option>
                            </select></td>
                           
                          <td>Basic</td>
                          <td><div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder=""
                            />
                          </div></td>
                          <td><div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder=""
                            />
                          </div></td>
                          <td className="width-9">
                            <div className="d-flex align-items-center">
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-delete h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>

                     
                      
                      </tbody>
                  </table>
               </div>
                        </Col>
                       
                      </Row>
                      <Row>
                          <Col sm={12}>
                            <p className="mb-0">Do YouWant to update all exisiting salary scale with this?</p>
                            <Col lg={6} className="mt-3">
                          <div className="d-flex">
                          <div className="form-check mb-2">
                              <input
                                type="radio"
                                id="radio1"
                                name="toastType"
                                className="form-check-input"
                                value="success"
                                defaultChecked
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio1"
                              >
                                Yes
                              </Label>
                            </div>

                            <div className="form-check mb-2 ms-2">
                              <input
                                type="radio"
                                id="radio2"
                                name="toastType"
                                className="form-check-input"
                                value="info"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio2"
                              >
                               No
                              </Label>
                            </div>
                          </div>
                       </Col>
                          </Col>
                        </Row>
                        <Row>
                        <Col className=" col-md-4 col-12 mt-3">
                <div className ="d-flex align-items-center
                 justify-content-between">
                   
                      
                  <div className ="d-flex">
                  <a className ="btn btn-primary btn-sm" href="/">Save</a>
                    <a className ="btn btn-primary btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
                      </Row>
                     
                               <nav className="mt-3" aria-label="Page navigation example">
  <ul className="pagination justify-content-center">
    <li className="page-item disabled">
      <a className="page-link" href="#" tabIndex ="-1" aria-disabled="true">Previous</a>
    </li>
    <li className="page-item"><a className="page-link" href="#">1</a></li>
    <li className="page-item"><a className="page-link" href="#">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item">
      <a className="page-link" href="#">Next</a>
    </li>
  </ul>
</nav>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Chat.propTypes = {
  chats: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetContacts: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
};

const mapStateToProps = ({ chat }) => ({
  chats: chat.chats,
  groups: chat.groups,
  contacts: chat.contacts,
  messages: chat.messages,
});

const mapDispatchToProps = dispatch => ({
  onGetChats: () => dispatch(getChats()),
  onGetGroups: () => dispatch(getGroups()),
  onGetContacts: () => dispatch(getContacts()),
  onGetMessages: roomId => dispatch(getMessages(roomId)),
  onAddMessage: roomId => dispatch(addMessage(roomId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
