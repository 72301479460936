import React, { Component } from "react"
import
{ Row, Col, Card, CardBody, CardTitle,Label,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import "../../../assets/scss/datatables.scss"

// Table data
const products = [

    
  { "sr": 1, "allowance title": "PF (Deduction)", "taxable": "No", "apply on": "Gross" ,"remaining of gross": <div className=""><input
  type="checkbox"
  className="form-check-input input-mini"
  id="closeButton"
  value="checked"
/></div>, "value in": <select id="inputState" className="form-select">
                              <option selected>Percentage</option>
                              <option>...</option>
                            </select>,"value":  <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder=""
                            />, "action": <>
  <div className="d-flex gap-3"><a className="text-success" href="/"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="/"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
 </>, },

                            { "sr": 2, "allowance title": "HRA (Earnings)", "taxable": "Yes", "apply on": "Basic" ,"remaining of gross": <div className=""><input
                            type="checkbox"
                            className="form-check-input input-mini"
                            id="closeButton"
                            value="checked"
                          /></div>, "value in": <select id="inputState" className="form-select">
                                                        <option selected>Numeric</option>
                                                        <option>...</option>
                                                      </select>,"value":  <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="formrow-InputCity"
                                                        placeholder=""
                                                      />, "action": <>
  <div className="d-flex gap-3"><a className="text-success" href="/"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="/"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div>
 </>, },
];
   




class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    const columns = [{
      dataField: 'sr',
      text: 'Sr',
      sort: true,
    }, {
      dataField: 'allowance title',
      text: 'Allowance Title',
      sort: true
    }, {
      dataField: 'taxable',
      text: 'Taxable',
      sort: true
    },
    {
      dataField: 'apply on',
      text: 'Apply On',
      sort: true
    },
    {
      dataField: 'remaining of gross',
      text: 'Remaining of Gross',
      sort: true
    }, 
    {
      dataField: 'value in',
      text: 'Value In',
      sort: true
    },
    {
      dataField: 'value',
      text: 'Value',
      sort: true
    },{
      dataField: 'action',
      text: 'Action',
      sort: true
    }, ];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

  
    // Select All Button operation
    const selectRow = {
      mode: 'checkbox'
    }

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
          <title>Salary Scale Master | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h6">Salary Scale Master</CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Salary Scale Master</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
          <Card>
                  <CardBody>
          
             
              <Row>
                     <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Company Name</label>
                            <select id="inputState" className="form-select">
                              <option selected>Company</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                     <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Basic Salary</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Basic Salary (In %)"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Allowance</label>
                            <select id="inputState" className="form-select">
                              <option selected>Allowance</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                     </Row>
                     <Row className="mb-3">
                       <Col lg={6}>
                        
                          <div className="d-flex align-items-center">
                          <h6 className="me-3">For :</h6>
                          <div className="form-check mb-2">
                              <input
                                type="radio"
                                id="radio1"
                                name="toastType"
                                className="form-check-input"
                                value="success"
                                defaultChecked
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio1"
                              >
                                Employee Specific
                              </Label>
                            </div>

                            <div className="form-check mb-2 ms-2">
                              <input
                                type="radio"
                                id="radio2"
                                name="toastType"
                                className="form-check-input"
                                value="info"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio2"
                              >
                               Employer Specific
                              </Label>
                            </div>
                          </div>
                       </Col>
                     </Row>   
                     <Row className="mb-4">
                        <Col className=" col-md-4 col-12 mt-3">
                <div className ="d-flex align-items-center
                 justify-content-between">
                   
                      
                  <div className ="d-flex">
                  <div  className="form-check">
                                          <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                    </div>
                    <a className ="btn btn_success btn-sm" href="/">Save</a>
                    <a className ="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
                      </Row>        
            <Row className="">
              <Col className="col-12">
                
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2 border-top pt-4">
                             
                                <Col className="col-lg-4 col-md-4 col-6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-lg-8 col-md-8 col-6">
              <div className="float-end">
                    </div>
              </Col>
                              </Row>

                              <Row>
                              <CardTitle className="h6 mb-3">Employee Specific</CardTitle>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                                
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-4">
                          <Col className="col-lg-12 col-md-12 col-12">
                            <p className="mb-0 d-flex">
                              Do YouWant to update all exisiting salary scale with this?
                              <div className="form-check ms-3 mb-2">
                              <input
                                type="radio"
                                id="radio1"
                                name="toastType"
                                className="form-check-input"
                                value="success"
                                defaultChecked
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio1"
                              >
                                Yes
                              </Label>
                            </div>

                            <div className="form-check mb-2 ms-2">
                              <input
                                type="radio"
                                id="radio2"
                                name="toastType"
                                className="form-check-input"
                                value="info"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio2"
                              >
                               No
                              </Label>
                            </div>
                              </p>
                          </Col>
                        </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                 
              </Col>
            </Row>
            </CardBody>
                </Card>
          </div>
         
        </div>
      </React.Fragment>
    )
  }
}

export default DatatableTables