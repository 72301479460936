import React, { Component, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const AddFAQ = () => {


    const history = useHistory();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [Description, setDescription] = useState("");
    const [AchivementFilePath, setAchivementFilePath] = useState("");
    const [AchivementFileName, setAchivementFileName] = useState("");
    const [AchivementFileDisplay, setAchivementFileDisplay] = useState(0);


    const onEditorStateChange = editorState => {
        setEditorState(editorState)
        const convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }

    const saveFAQDetails = async (e) => {
        e.preventDefault();
        let VTitle = e.target["txtTitle"].value;

        if (VTitle == "") {
            alert("Enter FAQ Title");
            return;
        }
        if (Description == "") {
            alert("Enter FAQ Description");
            return;
        }

        if (VTitle != '') {
            const FAQData = {

                Title: VTitle,
                Description: Description,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            }

            axios.post(global.APIURL + '/FrequentlyAskedQuestionsSave', FAQData)
                .then(res => {
                    alert(res.data[0].RecordStatus);
                    history.push('/FAQ');
                });
        }
    }

    return (
        <div className="page-content">

            <MetaTags>
                <title>FAQ | KC Admin Group</title>
            </MetaTags>

            <div className="container-fluid">

                <Row className="mb-3">
                    <Col className="col-lg-8 col-md-6 col-12 mb-2">
                        <div className="breadcrum ps-0">
                            <CardTitle className="h4">Add FAQ</CardTitle>
                            <a href="/GeneralDashboard" className="me-2">Home</a>
                            <span>/</span>
                            <a href="/FAQ" className="ms-2 me-2">FAQ</a>
                            <span>/</span>
                            <span className="ms-2">Add FAQ</span>
                        </div>
                    </Col>
                    <Col className="col-lg-4 co-md-6 col-12">
                        <a className="btn btn-primary btn-sm float-end font-14" href="/FAQ">
                            <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                        </a>
                    </Col>
                </Row>
                <form onSubmit={saveFAQDetails}>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-2">
                                                <label className="form-label">Title</label>
                                                <Input type="text" className="form-control" id="txtTitle" placeholder="Enter FAQ Title" />
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>

                                        <Col lg={12}>
                                            <div className="mb-2 " >
                                                <label className="form-label">Description</label>

                                                <Editor
                                                    editorState={editorState}
                                                    wrapperClassName="demo-wrapper"
                                                    editorClassName="demo-editor"
                                                    onEditorStateChange={onEditorStateChange}
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={12}>
                                            <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                                            <a className="btn btn_danger btn-sm ms-2" href="/Category">Clear</a>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </form>
            </div>

        </div>
    )
}

export default AddFAQ;