import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle} from "reactstrap"
import { connect } from "react-redux"


import { getChartsData } from "../../../store/actions"

import { withTranslation } from "react-i18next"

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
        {
          title: "Revenue",
          iconClass: "bx-archive-in",
          description: "$35, 723",
        },
        {
          title: "Average Price",
          iconClass: "bx-purchase-tag-alt",
          description: "$16.2",
        },
      ],
      email: [
        { title: "Week", linkto: "#", isActive: false },
        { title: "Month", linkto: "#", isActive: false },
        { title: "Year", linkto: "#", isActive: true },
      ],
      modal: false,
      subscribemodal: false,
      chartSeries: [],
      periodType: "yearly"
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }

  componentDidMount() {
    const { onGetChartsData } = this.props;
    setTimeout(() => this.setState({ subscribemodal: true }), 2000);
    onGetChartsData("yearly");
  }


  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ ...this.state, chartSeries: this.props.chartsData })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>List of Attendance | KC Admin Group</title> 
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
          <Row>
          <Col className="col-lg-8 col-md-6 col-6">
  <div className="breadcrum ps-0">
      <CardTitle className="h6 mb-2">List of Attendance</CardTitle>
      <a href="#" className="me-2">Home</a> <span>/</span><span className="ms-2">List of Attendance</span>
  </div>
</Col>
<Col className="col-lg-4 co-md-6 col-6">
    <a className ="btn btn-primary btn-sm float-end font-14" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
  </Col>
          </Row>
          
           <Card className="mt-3">
            <CardBody>
            <Row>
              <div className="col-lg-12">
                <div className="todo-main-box d-flex align-items-center justify-content-between mb-3">
                    <div className="">
                    <h5 className="font-weight-medium">Attendance</h5>
                    </div>
                </div>
               
              </div>
             
              <Row>
              <div className="col-lg-6 mt-1">
                <div className="leave-sub-box">
                                <div className="box-2-text"><div className="text-box">
                                  <div className="h5 mb-0">19-08-2021 </div>
                                </div>
                                <div className="btn-box">
                                <a href="/" className=""><i className="bx bxs-map align-middle me-1"></i>21504 Abigale Trail Jacobibury </a>
                                
                                </div></div>
                            <div className="box-2"></div>
                            <table className="table table-borderless">
  <thead>
    <tr className="">
      <th scope="col">Check-in</th>
      <th scope="col">Break Start</th>
      <th scope="col">Break End</th>
      <th scope="col">Check out</th>
    </tr>
  </thead>
  <tbody>
    <tr className="">
      <th scope="row" className="text-success">09:11 AM</th>
      <td className="text-blue">01:00 PM</td>
      <td className="text-warning">1:30 PM</td>
      <td className="text-danger">08:00 PM</td>
    </tr>
  </tbody>
</table>
                              </div>
                             
                              
                </div>
                <div className="col-lg-6 mt-3">
                <div className="leave-sub-box">
                                <div className="box-2-text"><div className="text-box">
                                  <div className="h5 mb-0">19-08-2021 </div>
                                </div>
                                <div className="btn-box">
                                <a href="/" className=""><i className="bx bxs-map align-middle me-1"></i>21504 Abigale Trail Jacobibury </a>
                                
                                </div></div>
                            <div className="box-2"></div>
                            <table className="table table-borderless">
  <thead>
    <tr className="">
      <th scope="col">Check-in</th>
      <th scope="col">Break Start</th>
      <th scope="col">Break End</th>
      <th scope="col">Check out</th>
    </tr>
  </thead>
  <tbody>
  <tr className="">
      <th scope="row" className="text-success">09:11 AM</th>
      <td className="text-blue">01:00 PM</td>
      <td className="text-warning">1:30 PM</td>
      <td className="text-danger">08:00 PM</td>
    </tr>
  </tbody>
</table>
                              </div>
                             
                              
                </div>
                <div className="col-lg-6 mt-3">
                <div className="leave-sub-box">
                                <div className="box-2-text"><div className="text-box">
                                  <div className="h5 mb-0">19-08-2021 </div>
                                </div>
                                <div className="btn-box">
                                <a href="/" className=""><i className="bx bxs-map align-middle me-1"></i>21504 Abigale Trail Jacobibury </a>
                                
                                </div></div>
                            <div className="box-2"></div>
                            <table className="table table-borderless">
  <thead>
    <tr className="">
      <th scope="col">Check-in</th>
      <th scope="col">Break Start</th>
      <th scope="col">Break End</th>
      <th scope="col">Check out</th>
    </tr>
  </thead>
  <tbody>
  <tr className="">
      <th scope="row" className="text-success">09:11 AM</th>
      <td className="text-blue">01:00 PM</td>
      <td className="text-warning">1:30 PM</td>
      <td className="text-danger">08:00 PM</td>
    </tr>
  </tbody>
</table>
                              </div>
                             
                              
                </div>
                <div className="col-lg-6 mt-3">
                <div className="leave-sub-box">
                                <div className="box-2-text"><div className="text-box">
                                  <div className="h5 mb-0">19-08-2021 </div>
                                </div>
                                <div className="btn-box">
                                <a href="/" className=""><i className="bx bxs-map align-middle me-1"></i>21504 Abigale Trail Jacobibury </a>
                                
                                </div></div>
                            <div className="box-2"></div>
                            <table className="table table-borderless">
  <thead>
    <tr className="">
      <th scope="col">Check-in</th>
      <th scope="col">Break Start</th>
      <th scope="col">Break End</th>
      <th scope="col">Check out</th>
    </tr>
  </thead>
  <tbody>
  <tr className="">
      <th scope="row" className="text-success">09:11 AM</th>
      <td className="text-blue">01:00 PM</td>
      <td className="text-warning">1:30 PM</td>
      <td className="text-danger">08:00 PM</td>
    </tr>
  </tbody>
</table>
                              </div>
                             
                              
                </div>
                <div className="col-lg-6 mt-3">
                <div className="leave-sub-box">
                                <div className="box-2-text"><div className="text-box">
                                  <div className="h5 mb-0">19-08-2021 </div>
                                </div>
                                <div className="btn-box">
                                <a href="/" className=""><i className="bx bxs-map align-middle me-1"></i>21504 Abigale Trail Jacobibury </a>
                                
                                </div></div>
                            <div className="box-2"></div>
                            <table className="table table-borderless">
  <thead>
    <tr className="">
      <th scope="col">Check-in</th>
      <th scope="col">Break Start</th>
      <th scope="col">Break End</th>
      <th scope="col">Check out</th>
    </tr>
  </thead>
  <tbody>
  <tr className="">
      <th scope="row" className="text-success">09:11 AM</th>
      <td className="text-blue">01:00 PM</td>
      <td className="text-warning">1:30 PM</td>
      <td className="text-danger">08:00 PM</td>
    </tr>
  </tbody>
</table>
                              </div>
                             
                              
                </div>
                <div className="col-lg-6 mt-3">
                <div className="leave-sub-box">
                                <div className="box-2-text"><div className="text-box">
                                  <div className="h5 mb-0">19-08-2021 </div>
                                </div>
                                <div className="btn-box">
                                <a href="/" className=""><i className="bx bxs-map align-middle me-1"></i>21504 Abigale Trail Jacobibury </a>
                                
                                </div></div>
                            <div className="box-2"></div>
                            <table className="table table-borderless">
  <thead>
    <tr className="">
      <th scope="col">Check-in</th>
      <th scope="col">Break Start</th>
      <th scope="col">Break End</th>
      <th scope="col">Check out</th>
    </tr>
  </thead>
  <tbody>
  <tr className="">
      <th scope="row" className="text-success">09:11 AM</th>
      <td className="text-blue">01:00 PM</td>
      <td className="text-warning">1:30 PM</td>
      <td className="text-danger">08:00 PM</td>
    </tr>
  </tbody>
</table>
                              </div>
                             
                              
                </div>
              
              </Row>
              
         
            </Row>

            </CardBody>
            </Card>
           
          </Container>
        </div>
       
      </React.Fragment>
    )
  }
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func
}

const mapStateToProps = ({ Dashboard }) => ({
  chartsData: Dashboard.chartsData,
})

const mapDispatchToProps = dispatch => ({
  onGetChartsData: (periodType) => dispatch(getChartsData(periodType)),
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(withTranslation()(Dashboard))
