import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import { parseInt } from "lodash";

const AddUserType = () => {
  const history = useHistory();
  const [Title, setTitle] = useState();
  const [MenuList, setMenuList] = useState([]);
  const [MenuRightsList, setMenuRightsList] = useState([]);

  useEffect(() => {
    getMenuList();
    getMenuDetailList(0);
  }, []);


  const getMenuList = async () => {
    var AUrl = global.APIURL + '/Menu/A';
    axios.get(AUrl)
      .then(MSres => {
        let VMenuList = MSres.data.map(ml => {
          return ml;
        });
        setMenuList(VMenuList);
      });
  }

  const getMenuDetailList = async (UserTypeId) => {
    const MenuDetailArr = { UserTypeId: UserTypeId }
    var AUrl = global.APIURL + '/MenuAccessList';
    axios.post(AUrl, MenuDetailArr)
      .then(MSres => {
        let MenuAccessL = MSres.data.map(VMaster => {
          return VMaster;
        });
        setMenuRightsList(MenuAccessL);
      });
  }

  const UserDetailSave = async () => {
    let UTitle = Title
    if (UTitle != '') {
      const UserType = {
        Id: '0',
        Title: UTitle,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
      }

      axios.post(global.APIURL + '/UserType', UserType)
        .then(res => {
          let UId = res.data.UsertypeId;
         

          if (parseInt(UId) > 0) {
            const FinalMenuRightsItemArr = [];
            for (let con = 0; con < MenuList.length; con++) {
              const MenuDetail = MenuList[con];
              var VMenuRightsFilter = MenuRightsList.filter(x => x.MenuId == MenuDetail.MenuId);
              for (let cond = 0; cond < VMenuRightsFilter.length; cond++) {
                const MenuRightDetail = VMenuRightsFilter[cond];
                var VCheckBoxControlId = MenuRightDetail.CheckBoxControlId;
                var VCheckBoxValue = document.getElementById(VCheckBoxControlId).checked;
                const MenuRightsItemArr = {
                  RightsId: 0,
                  UserTypeId: UId,
                  MenuId: MenuRightDetail.MenuId,
                  MenuAction: MenuRightDetail.MenuAction,
                  Status: VCheckBoxValue == true ? 'A' : 'D',
                  CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                };
                FinalMenuRightsItemArr.push(MenuRightsItemArr);
              }
            }
            const AllMenuRightsItemArr = {
              MenuRightsItem: FinalMenuRightsItemArr
            };
            axios.post(global.APIURL + '/UsertypeRightsDetailsSave', AllMenuRightsItemArr).then(Ires => { })
          }
        });
    }
  }

  return (
    <div className="page-content">

      <MetaTags>
        <title>User Type | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-12 mb-2">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Add User Type</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <a href="/UserType" className="ms-2 me-2">User Type</a>
              <span>/</span>
              <span className="ms-2">Add User Type</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-12">
            <a className="btn btn-primary btn-sm float-end font-14" href="/UserType">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row>
                  <Col lg={4}>
                    <div className="mb-2">
                      <label className="form-label">User Type</label>
                      <Input type="text" className="form-control" id="txtUserType" defaultValue={Title} placeholder="Enter User Type" onChange={(e) => setTitle(e.target.value)} />
                    </div>
                  </Col>
                  <label></label>
                  <label></label>
                  <label className="form-label">Rights</label>
                  <Col lg={12}>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th className="text-center" scope="col">#</th>
                            <th scope="col">Title</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {MenuList.map(record => (
                            <tr key={record.SR}>
                              <td className="text-center">{record.SR}</td>
                              <td >{record.Title}</td>
                              <td>
                                <div >
                                  {MenuRightsList.filter(x => parseInt(x.MenuId) == parseInt(record.MenuId)).map(mrdrecord => (
                                    <span key={mrdrecord.RightsId}>
                                      <input type="checkbox" className="form-check-input ms-2" value="Add" name="languages" id={mrdrecord.CheckBoxControlId} />{" "}{mrdrecord.MenuAction}
                                    </span>
                                  ))}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>

                      </table>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={() => UserDetailSave()}>Save</button>
                    <a className="btn btn_danger btn-sm ms-2" href="/AddUserType">Clear</a>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AddUserType;