import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';

import ReactHtmlTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import 'jspdf-autotable';

import LinkApp from 'App';

const OnSort = () => {
    let columns = [
        {
            key: "Srno",
            text: "#",
            sortable: true
        },
        {
            key: "ServiceTitle",
            text: "Service",
            sortable: true
        },
        {
            key: "BureauTitle",
            text: "Bureau",
            sortable: true
        },
        {
            key: "BureauStep",
            text: "Bureau Step",
            sortable: true
        },
        {
            key: "Title",
            text: "Activities Title",
            sortable: true
        },
        {
            key: "Description",
            text: "Description",
            sortable: true
        },
        {
            d: "action",
            text: "Action",
            cell: (record, index) => {
                return (
                    <Fragment>
                        <a className="me-3 text-primary" onClick={(ManageBureauStep.bind(this, record))}> <i className="mdi mdi-pencil font-size-18"></i></a>
                    </Fragment>
                );
            }
        }
    ];

    let config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        show_filter: true,
        show_pagination: true,
        filename: "restaurents",
    }
    const [records, setRecords] = useState([])
    let history = useHistory();

    useEffect(() => {
        getCorporateServiceStepList();
    }, []);

    const getCorporateServiceStepList = async () => {
        let VActivitiesCorporateServiceId = parseInt(localStorage.getItem("ActivitiesCorporateServiceId") == null ? "0" : localStorage.getItem("ActivitiesCorporateServiceId") == "" ? "0" : localStorage.getItem("ActivitiesCorporateServiceId"));
        let VCorporateServiceIdBureauId = parseInt(localStorage.getItem("CorporateServiceBureauId") == null ? "0" : localStorage.getItem("CorporateServiceBureauId") == "" ? "0" : localStorage.getItem("CorporateServiceBureauId"));
        var CorporateServiceActivitiesItem = {
            ServiceId: VActivitiesCorporateServiceId,
            BureauId: VCorporateServiceIdBureauId
        }
        axios.post(global.APIURL + "/CorporateServiceBureauStepList", CorporateServiceActivitiesItem)
            .then(res => {
                setRecords(res.data);
            });
    };


    const ManageBureauStep = (record) => {
        let VServiceId = record["ServiceId"]
        let VBureauId = record["BureauId"]
        let VId = record["Id"]
        localStorage.setItem("CorporateServiceId", VServiceId);
        localStorage.setItem("CorporateServiceBureauId", VBureauId);
        localStorage.setItem("CorporateServiceIdBureauRowId", VId);
        window.location.href = "/CorporateServiceActivities";
    }

    const AddBureauStep = async (e) => {
        let VActivitiesCorporateServiceId = parseInt(localStorage.getItem("ActivitiesCorporateServiceId") == null ? "0" : localStorage.getItem("ActivitiesCorporateServiceId") == "" ? "0" : localStorage.getItem("ActivitiesCorporateServiceId"));
        let VCorporateServiceIdBureauId = parseInt(localStorage.getItem("CorporateServiceBureauId") == null ? "0" : localStorage.getItem("CorporateServiceBureauId") == "" ? "0" : localStorage.getItem("CorporateServiceBureauId"));
        localStorage.setItem("CorporateServiceId", VActivitiesCorporateServiceId);
        localStorage.setItem("CorporateServiceBureauId", VCorporateServiceIdBureauId);
        localStorage.setItem("CorporateServiceIdBureauRowId", 0);
        window.location.href = "/AddCorporateServiceActivities";
      }

    const onSort = (column, records, sortOrder) => {
        return orderBy(records, [column], [sortOrder]);
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>Corporate Service Plan</title>
            </MetaTags>

            <div className="container-fluid">
                <Row className="mb-3">
                    <Col className="col-lg-6 col-md-6 col-6">
                        <div className="breadcrum ps-0">
                            <CardTitle className="h4">Corporate Service Plan Bureau Step</CardTitle>
                            <a href="/GeneralDashboard" className="me-2">Home</a>{" "}
                            <span>/</span>
                            <a href="/CorporateServiceplanList" className="me-2"><span className="ms-2">Corporate Service Plan</span></a>{" "}
                            <span>/</span>
                            <span className="ms-2">Corporate Service Plan Bureau Step</span>
                        </div>
                    </Col>
                    <Col className="col-lg-6 co-md-6 col-6">
                        <div className="d-flex align-items-center justify-content-end ">
                            <div>

                                <a className="btn btn-primary btn-sm float-end font-14" href="/CorporateServiceplanList">
                                    <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                                </a>

                                <a className="btn btn-primary btn-sm float-end font-14 me-2" onClick={AddBureauStep}>
                                    <i className="mdi mdi-plus align-middle me-1"></i>Add
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <form >
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xl="12">
                                            <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </form>
                <table id="emp" className="table" hidden="hidden">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Create Date</th>
                            <th>Service Title</th>
                            <th>Monthly Rate</th>
                            <th>Quaterly Rate</th>
                            <th>Half Yearly Rate</th>
                            <th>Yearly Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            records.map((item, index) => {
                                return <tr key={index}>
                                    <td> {index + 1} </td>
                                    <td>{item.CreateDate}</td>
                                    <td>{item.ServiceTitle}</td>
                                    <td>{item.Monthlyrate}</td>
                                    <td>{item.QuaterlyRate}</td>
                                    <td>{item.Halfyearly}</td>
                                    <td>{item.yearlyrate}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default OnSort;