import React, { Component } from "react"
import MetaTags from 'react-meta-tags';

//Import Breadcrumb
import { Col, Row, CardTitle, } from "reactstrap"

// Rating Plugin

class UiRating extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tooltipContent: ["Rate 1", "Rate 2", "Rate 3", "Rate 4", "Rate 5"],
      tooltipContentMore: ["1", "2", "3", "4", "5", "6", "7", "8"],
      tooltipContentHalf: ["6", "7", "8", "9", "10"],
      tooltipContentMiddle: [
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      tooltipContentStep: ["2", "4", "6", "8", "10"],
      default: "",
      half: "",
      customize: "",
    }
  }

  render() {
    return (
      <React.Fragment>
       <div className="page-content">
          <MetaTags>
            <title>Corporate Client Support | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-5">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle>Corporate Client Support</CardTitle>
                      <a href="#" className="me-2">Home</a> <span>/</span><span className="ms-2">Corporate Client Support </span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
            
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default UiRating
