import React,{ useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory,useParams  } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
const cookies = new Cookies();
 
const EditPriority = () => { 
  const [Priority, setTitle] = useState('');
  const [Status, setShortCode] = useState('');
  const [PriorityId, setDId] = useState('');
  const history = useHistory();

      let { id } = useParams();

      const updateProduct  = async (e) => {
        e.preventDefault();
        let UPriority=e.target["txtPriority"].value; 
        let UStatus=e.target["txtStatus"].value; 
        let UPriorityId=e.target["hdfPriorityId"].value; 
      
        if(UPriority!='')
        {
            const PrirityDetails = {
                PriorityId:UPriorityId==''?'0':UPriorityId,
                Priority: UPriority,
                Status: UStatus,
                Flag:"A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
       
            }

            axios.post(global.APIURL+'/PriorityUpdate', PrirityDetails)
            .then(res => 
            {
                    alert(res.data.StatusMessage);
                    history.push("/Priority");
            });
        }
    }

    useEffect(() => {
        if(id!=null)
        {
          if(id!="0")
          {
            let newid = id.split(':'); 
            getUserTyprById(newid[1]);
          }
          else{
            history.push("/Priority");
          }
        }
        else{
          history.push("/Priority");
        }
    }, []);
 
    const getUserTyprById = async (UTId) => {
        var AUrl=global.APIURL+'/PriorityEdit/'+UTId;
        axios.get(AUrl)  
        .then(res => {  
          setTitle(res.data[0].Priority);
          setShortCode(res.data[0].Status);
          setDId(res.data[0].PriorityId)
        })
    }

    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Priority | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Edit Priority </CardTitle>
                <a Link to="/" className="me-2">Home</a>
                <span>/</span>
                <a Link to="/Priority"><span className="ms-2 me-2">Priority</span></a>
                <span>/</span>
                <span className="ms-2">Edit Priority</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" Link to="/Priority">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={updateProduct}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                <Row>
                  <Col lg={4}>
                        <div className="mb-2">
                        <label className="form-label">Priority</label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Priority"
                            id="txtPriority"  
                            defaultValue={Priority}
                           
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-2">
                        <label className="form-label">Status</label>
                          <Input
                            type="text"
                            className="form-control"
                            id="txtStatus"  
                            placeholder="Status"
                            defaultValue={Status}
                          />
                        </div>
                      </Col>
                      
                    <Col lg={12}>
                        <input type="hidden" id="hdfPriorityId" value={PriorityId} />    
                        {/* <input type="hidden" id="hdfUserTypeId" value={this.state.Id} /> */}
                        <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                        <a className="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
      </div>
    )
}

export default   EditPriority 