import React,{ useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory,useParams  } from 'react-router-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
 
const EditCountry = () => { 
      const [Title, setTitle] = useState('');
      const [Id, setId] = useState('');
      const history = useHistory();
      let { id } = useParams();
      
      const UpdateCountry  = async (e) => {
        e.preventDefault();
        let CountryTitle=event.target["txtCountry"].value; 
        let CountryId=event.target["hdfCountryId"].value; 
        if(CountryTitle!='')
        {
            const Country = {
                CountryId:CountryId==''?'0':CountryId,
                Title: CountryTitle,
                Flag:"A",
                CreateUser:"1"
    
            }

            axios.post(global.APIURL+'/CountryUpdate', Country)
            .then(res => 
            {
                    alert(res.data.StatusMessage);
                    history.push("/Country");
            });
        }
    }

    useEffect(() => {
      if(id!=null)
      {
        if(id!="0")
        {
          let newid = id.split(':'); 
          getCountryById(newid[1]);
        }
        else{
          history.push("/Country");
        }
      }
      else{
        history.push("/Country");
      }
    }, []);
 
    const getCountryById = async (CId) => {
        var AUrl=global.APIURL+'/CountryEdit/'+CId;
        axios.get(AUrl)  
        .then(res => {  
           setTitle(res.data[0].Title);
           setId(res.data[0].CountryId)
        })
    }

    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Country | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Edit Country</CardTitle>
                <a href="/GeneralDashboard" className="me-2">Home</a>
                <span>/</span>
                <a href="/Country"><span className="ms-2 me-2">Country</span></a>
                <span>/</span>
                <span className="ms-2">Edit Country</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/Country">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={UpdateCountry}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Country</label>
                        <Input type="text" className="" id="txtCountry" defaultValue={Title}  placeholder="Enter Country" />
                      </div>
                    </Col>
                    <Col lg={12}>
                        <input type="hidden" id="hdfCountryId" value={Id} />    
                        <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                        <a className="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
      </div>
    )
}

export default EditCountry;