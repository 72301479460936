import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle,Dropdown,DropdownItem,DropdownToggle,DropdownMenu, } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

// Table data
const products = [

 
  {
    "id": 1,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 2,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 3,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 4,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 5,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 6,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 7,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 8,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 9,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 10,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 11,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 12,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  

];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    const columns = [{
      dataField: 'id',
      text: '#',
      sort: true,
    }, {
      dataField: 'employee name',
      text:  <span>Employee Name/ <br />Employee Id </span> ,
      sort: true
    }, {
      dataField: 'td',
      text: 'TD',
      sort: true
    }, {
      dataField: 'wd',
      text: 'WD',
      sort: true
    },{
      dataField: 'ph',
      text: 'PH',
      sort: true
    },{
      dataField: 'wo',
      text: 'WO',
      sort: true
    },{
      dataField: 'pd',
      text: 'PD',
      sort: true
    },{
      dataField: 'ad',
      text: 'AD',
      sort: true
    },{
      dataField: 'pl',
      text: 'PL',
      sort: true
    }, {
      dataField: 'pd',
      text: 'PD',
      sort: true
    }, {
      dataField: 'lwp',
      text: 'LWP',
      sort: true
    }, {
      dataField: 'action',
      text: 'Action',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

  
    

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
          <title>Manage Attendance | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
            
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h3">Manage Attendance
</CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Manage Attendance
</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
             
              <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col className="col-md-3">
                   <label>Company</label>
                      <select className="form-select" id="floatingSelect" aria-label="Floating label select example">
                        <option selected="">Select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                     
                     
                  </Col> 
                  <Col className="col-md-3">
                   <label>User</label>
                      <select className="form-select" id="floatingSelect" aria-label="Floating label select example">
                        <option selected="">Select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                      
                     
                  </Col> 
                  <Col className="col-md-3">
                   <label>Month</label>
                      <select className="form-select" id="floatingSelect" aria-label="Floating label select example">
                        <option selected="">Select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                      
                     
                  </Col> 
                  <Col className="col-md-3">
                   <label>Year</label>
                      <select className="form-select" id="floatingSelect" aria-label="Floating label select example">
                        <option selected="">Select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                      
                     
                  </Col> 
                </Row>

                <Row>
                  <Col className="col-md-12">
                   
                                            <button className="btn btn_primary" type="">Search</button>
                                        
                </Col>
                </Row>
                

              </CardBody>
              </Card>










            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                 
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
              
                  
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                                <Row className="mb-2">
                             
                                <Col className="col-lg-4 col-md-4 col-6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-lg-8 col-md-8 col-6">
               
              </Col>
                              </Row>



                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light table-thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DatatableTables