import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import MetaTags from "react-meta-tags";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Reply_person from "../../../assets/images/users/avatar-2.jpg";

import "../../../assets/scss/datatables.scss";

// Table data
const products = [
  {
    id: 1,
    tid: (
     <><a href="/SupportTicketDetail">#KCSP2022000162</a></>
    ),
    issue: "Document Issue",
    staff: (
      <>
      <a href="#" className="text-heading nowrap">
      <div className="d-flex"><div className="flex-shrink-0"><img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/></div><div className="flex-grow-1 ms-1"><p className="mb-0">Hirva Suthar</p></div></div>
      </a>
      </>
    ),
    date: "5 Jan 2020 07:21AM",
    replay: "5 Jan 2020 07:21AM",
    priority: <div className="dropdown">
    <button className="btn border border-3 p-1 text-danger dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
      High
    </button>
    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li><a className="dropdown-item text-danger" href="#">High</a></li>
      <li><a className="dropdown-item text-warning" href="#">Medium</a></li>
      <li><a className="dropdown-item text-success" href="#">Low</a></li>
    </ul>
  </div>,
    status:<div className="dropdown">
    <button className="btn border border-3 p-1 dropdown-toggle text-info" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     New
    </button>
    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li><a className="dropdown-item text-info" href="#">Open</a></li>
      <li><a className="dropdown-item text-info" href="#">Reopened</a></li>
      <li><a className="dropdown-item text-danger" href="#">On Hold</a></li>
      <li><a className="dropdown-item text-success" href="#">Closed</a></li>
      <li><a className="dropdown-item text-success" href="#">In Progress</a></li>
    </ul>
  </div>,
    action: (
      <>
        <div className="d-flex gap-3">
          <a className="text-success" href="#">
            <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
          </a>
          <a className="text-danger" href="#">
            <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
          </a>
        </div>
      </>
    ),
  },
  {
    id: 2,
    tid: (
     <><a href="/SupportTicketDetail">#KCSP2022000162</a></>
    ),
    issue: "Document Issue",
    staff: (
      <>
      <a href="#" className="text-heading nowrap">
      <div className="d-flex"><div className="flex-shrink-0"><img src="/static/media/avatar-1.3921191a.jpg" alt="" className="img-thumbnail img-leads rounded-circle"/></div><div className="flex-grow-1 ms-1"><p className="mb-0">Hirva Suthar</p></div></div>
      </a>
      </>
    ),
    date: "5 Jan 2020 07:21AM",
    replay: "5 Jan 2020 07:21AM",
    priority: <div className="dropdown">
    <button className="btn border border-3 p-1 text-danger dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
      High
    </button>
    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li><a className="dropdown-item text-danger" href="#">High</a></li>
      <li><a className="dropdown-item text-warning" href="#">Medium</a></li>
      <li><a className="dropdown-item text-success" href="#">Low</a></li>
    </ul>
  </div>,
    status:<div className="dropdown">
    <button className="btn border border-3 p-1 dropdown-toggle text-info" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     New
    </button>
    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li><a className="dropdown-item text-info" href="#">Open</a></li>
      <li><a className="dropdown-item text-info" href="#">Reopened</a></li>
      <li><a className="dropdown-item text-danger" href="#">On Hold</a></li>
      <li><a className="dropdown-item text-success" href="#">Closed</a></li>
      <li><a className="dropdown-item text-success" href="#">In Progress</a></li>
    </ul>
  </div>,
    action: (
      <>
        <div className="d-flex gap-3">
          <a className="text-success" href="#">
            <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
          </a>
          <a className="text-danger" href="#">
            <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
          </a>
        </div>
      </>
    ),
  },
];

class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products,
    };
  }

  render() {
    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
      },
      {
        dataField: "tid",
        text: "Tickect Id",
        sort: true,
      },
      {
        dataField: "issue",
        text: "Ticket Issue ",
        sort: true,
      },
      {
        dataField: "staff",
        text: "Assigned Staff",
        sort: true,
      },
      {
        dataField: "date",
        text: "Created Date",
        sort: true,
      },
      {
        dataField: "replay",
        text: "Last Replay",
        sort: true,
      },
     
      {
        dataField: "priority",
        text: "Priority",
        sort: true,
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
      },
      {
        dataField: "action",
        text: "Action",
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    };

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "25", value: 25 },
      { text: "All", value: this.state.productData.length },
    ];

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Support Ticket | KC Admin Group </title>
          </MetaTags>
          <div className="container-fluid">
          <div
              className="modal fade"
              id="generateticket"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content ">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Generate Ticket
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <Row>
                      <Col className="col-12">
                        <div className="mb-3">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Select Category</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mb-3">
                          <label className="form-label">Subject</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Add"
                          />
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mb-3">
                          <label className="form-label">Description</label>
                          <textarea
                            type="text"
                            className="form-control"
                            placeholder="Add"
                          >
                            {" "}
                          </textarea>
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mb-3">
                          <a href="#" className="btn btn-primary d-block">
                            {" "}
                            Generate Ticket{" "}
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
            <Row className="mb-3">
              <Col className="col-lg-8 col-md-6 col-6">
                <div className="breadcrum ps-0">
                  <CardTitle className="h4">Support Ticket</CardTitle>
                  <a href="#" className="me-2">
                    Home
                  </a>{" "}
                  <span>/</span>
                  <span className="ms-2">Support Ticket</span>
                </div>
              </Col>
              <Col className="col-lg-4 co-md-6 col-6">
                <a
                  className="btn btn-primary btn-sm float-end font-14"
                  href="#"
                >
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
                <a
                  className="btn btn_success btn-sm float-end font-14 me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#generateticket"
                  href="#"
                >
                  <i className="fas fa-plus align-middle me-1"></i>Add New
                  Ticket
                </a>
              </Col>
            </Row>

            <Card>
              <CardBody>
               

               <Row>
                 <div className="col-lg-3">
                     <div className="support-ticket">
                     <div className="mb-3">
                          <h5>New Ticket<span className="float-end text-success">+10%</span></h5>
                      </div>
                      <h4 className="mb-3">112</h4>
                      <div className="progress">
                        <div className="progress-bar bg-primary pb-70" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                     </div>
                 </div>
                 <div className="col-lg-3">
                     <div className="support-ticket">
                     <div className="mb-3">
                          <h5>Solved Tickets<span className="float-end text-success">+12.5%</span></h5>
                      </div>
                      <h4 className="mb-3">70</h4>
                      <div className="progress">
                        <div className="progress-bar bg-primary pb-70" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                     </div>
                 </div>
                 <div className="col-lg-3">
                     <div className="support-ticket">
                     <div className="mb-3">
                          <h5>Open Tickets<span className="float-end text-danger">-2.8%</span></h5>
                      </div>
                      <h4 className="mb-3">100</h4>
                      <div className="progress">
                        <div className="progress-bar bg-primary pb-70" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                     </div>
                 </div>
                 <div className="col-lg-3">
                     <div className="support-ticket">
                     <div className="mb-3">
                          <h5>Pending Tickets<span className="float-end text-danger">-75%</span></h5>
                      </div>
                      <h4 className="mb-3">125</h4>
                      <div className="progress">
                        <div className="progress-bar bg-primary pb-70" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                     </div>
                 </div>
               </Row>
               
              
              </CardBody>
            </Card>
         

          
          <Card>
            <CardBody>
            <Row className="align-items-center">
                 <div className="col-lg-2">
                 <form>
                  <div className="">
                  <label htmlFor="exampleInputEmail1" className="form-label">Name</label>
                    <input type="email" placeholder="Employee Name" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                  </div>
                </form>
                 </div>
                 <div className="col-lg-2 ">
                 <label htmlFor="exampleInputEmail1" className="form-label">Status</label>
                 <select className="form-select" aria-label="Default select example">
                      <option selected>--select--</option>
                      <option value="1">Pending</option>
                      <option value="2">Approved</option>
                      <option value="3">Returned</option>
                    </select>
                 </div>
                 <div className="col-lg-2 ">
                 <label htmlFor="exampleInputEmail1" className="form-label">Priority</label>
                 <select className="form-select" aria-label="Default select example">
                      <option selected>--select--</option>
                      <option value="1">High</option>
                      <option value="2">Low</option>
                      <option value="3">Medium</option>
                    </select>
                 </div>
                 <div className="col-lg-2">
                 <form>
                  <div className="">
                  <label htmlFor="exampleInputEmail1" className="form-label">From</label>
                    <input type="date" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                  </div>
                </form>
                 </div>
                 <div className="col-lg-2">
                 <form>
                  <div className="">
                  <label htmlFor="exampleInputEmail1" className="form-label">To</label>
                    <input type="date" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                  </div>
                </form>
                 </div>
                 <div className="col-lg-2">
                 <a
                  className="btn btn_success btn-sm float-end font-14 w-100 mt-4"
                  href="#"
                >
                  Search
                </a>
                 </div>
               </Row>
               <hr />
            <Row>
              
            
           
              <Col className="col-12">
               
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2 justify-content-center">
                                <Col className="col-lg-4 col-md-4 col-4">
                                  <div className="dropdown">
                                    <button
                                      className="btn btn-border  dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      Export
                                    </button>
                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton1"
                                    >
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          Action
                                        </a>
                                      </li>
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          Another action
                                        </a>
                                      </li>
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          Something else here
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </Col>
                                <div className="col-lg-4 col-md- col-4 text-center col">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <label
                                        htmlFor="search-bar-0"
                                        className="search-label"
                                      >
                                        <span
                                          id="search-bar-0-label"
                                          className="sr-only"
                                        >
                                          Search this table
                                        </span>
                                        <input
                                          id="search-bar-0"
                                          type="text"
                                          aria-labelledby="search-bar-0-label"
                                          className="form-control "
                                          placeholder="Search"
                                          value=""
                                        />
                                      </label>
                                      <i className="bx bx-search-alt search-icon"></i>
                                    </div>
                                  </div>
                                </div>

                                <Col className="col-lg-4 col-md-4 col-4">
                                  <div className="d-inline float-end">
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-border dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        10
                                        <i className="fas fa-angle-down align-middle ms-2"></i>
                                      </button>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li>
                                          <a className="dropdown-item" href="#">
                                            25
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="#">
                                            30
                                          </a>
                                        </li>
                                        <li>
                                          <a className="dropdown-item" href="#">
                                            35
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      classes={
                                        "table align-top lead-table-retail"
                                      }
                                      headerWrapperClasses={
                                        "thead-light table-thead-light lead-table"
                                      }
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="text-md-right ms-auto">
                                    <div className="pagination pagination-rounded justify-content-end">
                                      <ul className="pagination react-bootstrap-table-page-btns-ul">
                                        <li
                                          className="page-item"
                                          title="previous page"
                                        >
                                          <a href="#" className="page-link">
                                            &lt;
                                          </a>
                                        </li>
                                        <li className="page-item" title="1">
                                          <a href="#" className="page-link">
                                            1
                                          </a>
                                        </li>
                                        <li
                                          className="active page-item"
                                          title="2"
                                        >
                                          <a href="#" className="page-link">
                                            2
                                          </a>
                                        </li>
                                        <li className="page-item" title="3">
                                          <a href="#" className="page-link">
                                            3
                                          </a>
                                        </li>
                                        <li
                                          className="page-item"
                                          title="next page"
                                        >
                                          <a href="#" className="page-link">
                                            &gt;
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                 
              </Col>
            </Row>
            </CardBody>
          </Card>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DatatableTables;
