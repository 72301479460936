import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import img1 from "assets/images/users/avatar-1.jpg";

//Import Breadcrumb
import { Col, Row, CardTitle, Card, CardBody } from "reactstrap";

// Rating Plugin

class UiRating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipContent: ["Rate 1", "Rate 2", "Rate 3", "Rate 4", "Rate 5"],
      tooltipContentMore: ["1", "2", "3", "4", "5", "6", "7", "8"],
      tooltipContentHalf: ["6", "7", "8", "9", "10"],
      tooltipContentMiddle: [
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      tooltipContentStep: ["2", "4", "6", "8", "10"],
      default: "",
      half: "",
      customize: "",
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Client Activities | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
            <Row className="mb-3">
              <Col className="col-lg-8 col-md-6 col-6">
                <div className="breadcrum ps-0">
                  <CardTitle>Client Activities</CardTitle>
                  <a href="#" className="me-2">
                    Lead List
                  </a>{" "}
                  <span>/</span>
                  <span className="ms-2">Client Activities</span>
                </div>
              </Col>
              <Col className="col-lg-4 co-md-6 col-6">
                <a
                  className="btn btn-primary btn-sm float-end font-14"
                  href="#"
                >
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
              </Col>
            </Row>

            <Row>
              <Col className="col-lg-12 col-12">
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col className="col-md-10 col-12">
                        <Row>
                          <Col className="col-12">
                            <h5 className="fw-bolder mb-2">
                              {" "}
                              Sheron Anilbhai Christian{" "}
                            </h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-lg-3">
                            <div className="border-box p-2 mb-2 ">
                              <p className="mb-1 text-muted"> Mobile Number </p>
                              <p className="mb-0 fontWeight500">
                                {" "}
                                +91 6598632545{" "}
                              </p>
                            </div>
                          </Col>
                          <Col className="col-lg-3">
                            <div className=" border-box p-2  mb-2 ">
                              <p className="mb-1 text-muted"> Email Address </p>
                              <p className="mb-0 fontWeight500">
                                {" "}
                                abc@gmail.com{" "}
                              </p>
                            </div>
                          </Col>

                          <Col className="col-lg-3">
                            <div className="border-box p-2  mb-2 ">
                              <p className="mb-1 text-muted">
                                Registration Date{" "}
                              </p>
                              <p className="mb-0 fontWeight500"> 20/01/2022 </p>
                            </div>
                          </Col>
                          <Col className="col-lg-3">
                            <div className=" border-box p-2 mb-2 ">
                              <p className="mb-1 text-muted">
                                {" "}
                                Registration Time
                              </p>
                              <p className="mb-0 fontWeight500"> 03:15AM </p>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="col-md-2 col-lg-2 col-12 float-end">
                        {/* <div className="bg-light rounded-2 height250px pt-3">
                                                <a className=" bg-dark text-light rounded-circle p-2 ms-2"> <i className="fas fa-pencil-alt"></i> </a>    
                                            </div> */}
                        <div className="">
                          <img
                            className="rounded-circle float-end clientactimg"
                            src={img1}
                          ></img>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col className="col-lg-12">
                <Card>
                  <CardBody>
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Follow ups{" "}
                            <span className="text-danger activity-status fw-bold">
                              Pending
                            </span>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <Row>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Person to be Connected
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Name"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Mobile No.1
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter mobile"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Mobile No.2
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Mobile"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Email Address of Official
                                    </label>
                                    <input
                                      type="email"
                                      placeholder="Enter Official Email"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Follow up Date 1
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Stage
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Stage"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Response
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Response"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Next Follow Up Due
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Active / Close
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Status"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4 co-md-6 col-6 col">
                                <a
                                  className="btn btn_success btn-sm font-14 me-2"
                                  href="#"
                                >
                                  Save
                                </a>
                                <a
                                  className="btn btn_danger btn-sm  font-14"
                                  href="#"
                                >
                                  Cancel
                                </a>
                              </div>
                            </Row>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Activity 1{" "}
                            <span className="text-danger activity-status fw-bold">
                              Pending
                            </span>
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <Row>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Service 1
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter service name"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Bank / FI Name
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Bank / FI Name"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Account Type
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Account Type"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      A/c No.
                                    </label>
                                    <input
                                      type="email"
                                      placeholder="Enter Account Number"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Status
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Add Status"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Current Balance
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Balance"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      OD Balance
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="OD Balance"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-6">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Remarks
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Remarks"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>

                              <CardTitle>Follow Up<span className="span-text float-end"><a href="#" className="text-success">+ Add</a></span></CardTitle>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Follow Up Date
                                    </label>
                                    <input
                                      type="date"
                                      placeholder="OD Balance"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Stage
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Stage"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Response
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Response"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Next Follow up due
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                               
                                </form>
                              </div>
                              <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Active/Close</label>
                            <select id="inputState" className="form-select">
                              <option selected>Active</option>
                              <option>Close</option>
                            </select>
                          </div>
                        </Col>
                              <div className="col-lg-12 co-md-6 col-6 col">
                                <a
                                  className="btn btn_success btn-sm font-14 me-2"
                                  href="#"
                                >
                                  Save
                                </a>
                                <a
                                  className="btn btn_danger btn-sm  font-14"
                                  href="#"
                                >
                                  Cancel
                                </a>
                              </div>
                            </Row>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                             Activity 2{" "}
                            <span className="activity-status text-danger fw-bold">
                              Pending
                            </span>
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <Row>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                       Activity 2
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter service name"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Bank / FI Name
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Bank / FI Name"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Account Type
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Account Type"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      A/c No.
                                    </label>
                                    <input
                                      type="email"
                                      placeholder="Enter Account Number"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Status
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Add Status"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Current Balance
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Balance"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      OD Balance
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="OD Balance"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-6">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Remarks
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Remarks"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              
                              <CardTitle>Follow Up<span className="span-text float-end"><a href="#" className="text-success">+ Add</a></span></CardTitle>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Follow Up Date
                                    </label>
                                    <input
                                      type="date"
                                      placeholder="OD Balance"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Stage
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Stage"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Response
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Response"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Next Follow up due
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                               
                                </form>
                              </div>
                              <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Active/Close</label>
                            <select id="inputState" className="form-select">
                              <option selected>Active</option>
                              <option>Close</option>
                            </select>
                          </div>
                        </Col>
                              <div className="col-lg-12 co-md-6 col-6 col">
                                <a
                                  className="btn btn_success btn-sm font-14 me-2"
                                  href="#"
                                >
                                  Save
                                </a>
                                <a
                                  className="btn btn_danger btn-sm  font-14"
                                  href="#"
                                >
                                  Cancel
                                </a>
                              </div>
                            </Row>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading4">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse4"
                            aria-expanded="false"
                            aria-controls="collapse4"
                          >
                             Activity 3{" "}
                            <span className="activity-status text-danger fw-bold">
                              Pending
                            </span>
                          </button>
                        </h2>
                        <div
                          id="collapse4"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading4"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <Row>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                       Activity 3
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter service name"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Bank / FI Name
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Bank / FI Name"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Account Type
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Account Type"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      A/c No.
                                    </label>
                                    <input
                                      type="email"
                                      placeholder="Enter Account Number"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Status
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Add Status"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Current Balance
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Balance"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      OD Balance
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="OD Balance"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-6">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Remarks
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Remarks"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              
                              <CardTitle>Follow Up<span className="span-text float-end"><a href="#" className="text-success">+ Add</a></span></CardTitle>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Follow Up Date
                                    </label>
                                    <input
                                      type="date"
                                      placeholder="OD Balance"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Stage
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Stage"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Response
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Response"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Next Follow up due
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                               
                                </form>
                              </div>
                              <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Active/Close</label>
                            <select id="inputState" className="form-select">
                              <option selected>Active</option>
                              <option>Close</option>
                            </select>
                          </div>
                        </Col>
                              <div className="col-lg-12 co-md-6 col-6 col">
                                <a
                                  className="btn btn_success btn-sm font-14 me-2"
                                  href="#"
                                >
                                  Save
                                </a>
                                <a
                                  className="btn btn_danger btn-sm  font-14"
                                  href="#"
                                >
                                  Cancel
                                </a>
                              </div>
                            </Row>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading5">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse5"
                            aria-expanded="false"
                            aria-controls="collapse5"
                          >
                            Activity 4{" "}
                            <span className="activity-status text-danger fw-bold">
                              Pending
                            </span>
                          </button>
                        </h2>
                        <div
                          id="collapse5"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading5"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <Row>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Activity 4
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter service name"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Bank / FI Name
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Bank / FI Name"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Account Type
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Account Type"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      A/c No.
                                    </label>
                                    <input
                                      type="email"
                                      placeholder="Enter Account Number"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Status
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Add Status"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Current Balance
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Balance"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      OD Balance
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="OD Balance"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-6">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Remarks
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Remarks"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              
                              <CardTitle>Follow Up<span className="span-text float-end"><a href="#" className="text-success">+ Add</a></span></CardTitle>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Follow Up Date
                                    </label>
                                    <input
                                      type="date"
                                      placeholder="OD Balance"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Stage
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Stage"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Response
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Response"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Next Follow up due
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                               
                                </form>
                              </div>
                              <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Active/Close</label>
                            <select id="inputState" className="form-select">
                              <option selected>Active</option>
                              <option>Close</option>
                            </select>
                          </div>
                        </Col>
                              <div className="col-lg-12 co-md-6 col-6 col">
                                <a
                                  className="btn btn_success btn-sm font-14 me-2"
                                  href="#"
                                >
                                  Save
                                </a>
                                <a
                                  className="btn btn_danger btn-sm  font-14"
                                  href="#"
                                >
                                  Cancel
                                </a>
                              </div>
                            </Row>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading6">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse6"
                            aria-expanded="false"
                            aria-controls="collapse6"
                          >
                             Activity 5{" "}
                            <span className="activity-status text-danger fw-bold">
                              Pending
                            </span>
                          </button>
                        </h2>
                        <div
                          id="collapse6"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading6"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <Row>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Service 6
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter service name"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Bank / FI Name
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Bank / FI Name"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Account Type
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Account Type"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      A/c No.
                                    </label>
                                    <input
                                      type="email"
                                      placeholder="Enter Account Number"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Status
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Add Status"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Current Balance
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Balance"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      OD Balance
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="OD Balance"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-6">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Remarks
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Remarks"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              
                              <CardTitle>Follow Up<span className="span-text float-end"><a href="#" className="text-success">+ Add</a></span></CardTitle>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Follow Up Date
                                    </label>
                                    <input
                                      type="date"
                                      placeholder="OD Balance"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Stage
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Stage"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Response
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Response"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                                </form>
                              </div>
                              <div className="col-lg-4">
                                <form>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Next Follow up due
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                    />
                                  </div>
                               
                                </form>
                              </div>
                              <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Active/Close</label>
                            <select id="inputState" className="form-select">
                              <option selected>Active</option>
                              <option>Close</option>
                            </select>
                          </div>
                        </Col>
                              <div className="col-lg-12 co-md-6 col-6 col">
                                <a
                                  className="btn btn_success btn-sm font-14 me-2"
                                  href="#"
                                >
                                  Save
                                </a>
                                <a
                                  className="btn btn_danger btn-sm  font-14"
                                  href="#"
                                >
                                  Cancel
                                </a>
                              </div>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UiRating;
