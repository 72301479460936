import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle, } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins

import { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import "../../../../assets/scss/datatables.scss"

// Table data
const products = [

 
  {
    "id": 1,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 2,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 3,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 4,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 5,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 6,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 7,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 8,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 9,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 10,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 11,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  {
    "id": 12,
    "employee name":  <><p className="fw-bold mb-0"> Yashi Joshi </p><p className="mb-0"> KC000023 </p></>,
    "td":  "2",
    "wd": "25",
    "ph":  "2",
    "wo": "1",
    "pd":  "1",
    "ad": "1",
    "pl":  "3",
    "pd": "1",
    "lwp":  "1",
    "action": <a href="#" className="btn btn_primary"> Manage </a>
  },

  

];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    const columns = [{
      dataField: 'id',
      text: '#',
      sort: true,
    }, {
      dataField: 'employee name',
      text:  <span>Employee Name/ <br />Employee Id </span> ,
      sort: true
    }, {
      dataField: 'td',
      text: 'TD',
      sort: true
    }, {
      dataField: 'wd',
      text: 'WD',
      sort: true
    },{
      dataField: 'ph',
      text: 'PH',
      sort: true
    },{
      dataField: 'wo',
      text: 'WO',
      sort: true
    },{
      dataField: 'pd',
      text: 'PD',
      sort: true
    },{
      dataField: 'ad',
      text: 'AD',
      sort: true
    },{
      dataField: 'pl',
      text: 'PL',
      sort: true
    }, {
      dataField: 'pd',
      text: 'PD',
      sort: true
    }, {
      dataField: 'lwp',
      text: 'LWP',
      sort: true
    }, {
      dataField: 'action',
      text: 'Action',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

  
    

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
          <title>Add Invoice Vendor | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
            
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h3">Invoice Vendor
</CardTitle>
                      <a href="/invoices-list" className="me-2">Invoice Vendor</a> <span>/</span><span className="ms-2">Add
</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
          </Row>
             
            <Card>
              <CardBody>
                <Row className="align-items-center">
                  <Col className="col-md-8 col-12">
                    <div className="fw-bold fs-5">New invoice No (<span className="text_dark_blue">INR/V/2022/00000003</span>)</div>
                  </Col>
                  <Col className="col-md-4 col-12 text-md-end text-start">
                    <a className="btn btn_danger me-2" href="#"> Save Draft </a>
                    <a className="btn btn_success" href="#"> Preview </a>
                  </Col>
                  </Row>
              </CardBody>
            </Card>

            <Row>
              <Col className="col-md-8 col-12">
                  <Card>
                  <CardBody>
                    <Row>
                        <Col className="col-md-8 col-12 mb-3">
                          <label className="fw-bold fs-6"> Vendor Type</label>
                          <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                <label className="form-check-label" >
                                Select Already Added Vendor
                                </label>
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                <label className="form-check-label" >
                                New Vendor
                                </label>
                            </div>
                        </Col>
                         <Col className="col-md-4 col-12 mb-3">
                    <div className="d-flex text-md-end text-left">
                                        
                                        <div className="flex-grow-1 form-group form-group-inline mb-0 me-2 mb-2">
                                            <select className="form-select select"  >
                                                <option value="">Choose Any One</option>
                                                            <option selected="" value="quantity">Quantity</option>
                                                <option value="duration">Duration</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                Please provide a Quantity / Duration.
                                            </div>
                                        </div>
                                        <div className="form-group form-group-inline mb-0 ">
                                            <select className="form-select select" >
                                                <option value="INR">INR</option>
                                                <option value="CAD">CAD</option>
                                                <option value="EUR">EUR</option>
                                                <option value="USD">USD</option>
                                            </select>
                                        </div>
                                    </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-md-12">
                        <ul className="list-unstyled">
                          <li className="position-relative mb-3">
                            <span className="position-absolute top-0 left-0"><i className="far fa-building fs-4"></i></span> 
                            <div className="ms-3 ps-3 fw-bold">
                            804, Elite Business Hub, Beside Mercedes Benz Showroom, opposite Shapath Hexa, Ahmedabad, Gujarat 380060
                            </div>
                          </li>
                          <li className="position-relative mb-3">
                          <span className="position-absolute top-0 left-0"><i className="far fa-envelope fs-4"></i></span>
                            <div className="ms-3 ps-3 fw-bold">
                            accounts@excelsiortenologies.com
                            </div>
                          </li>
                          <li className="position-relative mb-3">
                          <span className="position-absolute top-0 left-0"> <i className="fas fa-globe-americas fs-4"></i></span>
                            <div className="ms-3 ps-3 fw-bold">
                            www.excelsiortechnologies.com
                            </div>
                          </li>
                          </ul>
                      </Col>
                    </Row>

                    <Row className="p-2 bg-light m-1 pt-3 mb-3">
                      <Col className="col-md-8 col-12 mb-3">
                      <label className="form-label">Particular</label>
                      <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Particular" />
                      </Col>
                      <Col className="col-md-4  col-6 mb-3">
                      <label className="form-label">HSN Code</label>
                      <input type="text" className="form-control" id="formGroupExampleInput" placeholder="HSN Code" />
                      </Col>

                      <Col className="col-md-4  col-6 mb-3">
                      <label className="form-label">Quantity</label>
                      <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Quantity" />
                      </Col>
                      <Col className="col-md-4 col-6  mb-3">
                      <label className="form-label">Unit Price</label>
                      <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Unit Price" />
                      </Col>
                      <Col className="col-md-4 col-6  mb-3">
                      <label className="form-label">Amount</label>
                      <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Amount" readOnly />
                      </Col>

                      <Col className="col-md-4 col-6  mb-3">
                      <label className="form-label">Discount (%)</label>
                      <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Discount" />
                      </Col>
                      <Col className="col-md-4 col-6  mb-3">
                      <label className="form-label">Discount Amount</label>
                      <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Discount Amount" readOnly />
                      </Col>
                      <Col className="col-md-4  col-6 mb-3">
                      <label className="form-label">GST(%)</label>
                      <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Select GST(%)" />
                      </Col>

                      <Col className="col-md-4 col-6  mb-3">
                      <label className="form-label">SGST(%)</label>
                      <input type="text" className="form-control" id="formGroupExampleInput" placeholder="SGST" readOnly />
                      </Col>
                      <Col className="col-md-4  col-6 mb-3">
                      <label className="form-label">IGST(%)</label>
                      <input type="text" className="form-control" id="formGroupExampleInput" placeholder="IGST"  readOnly />
                      </Col>
                      <Col className="col-md-4 col-6  mb-3">
                      <label className="form-label">Total</label>
                      <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Total" readOnly />
                      </Col>
                    </Row>

                    <Row className=" m-1">
                      <Col className="col-md-12 col-12  mb-3">
                        <a href="#" className=" text-info"> <i className="fas fa-plus"></i> Add item or service </a>
                      </Col>
                      <Col className="col-md-12 col-12 mb-3">
                        <label>Message to vendor</label>
                        <textarea className="form-control"  placeholder="Message to vendor"></textarea>
                      </Col>
                      <Col className="col-md-12 col-12 mb-3">
                        <label>Message to Self</label>
                        <textarea className="form-control"  placeholder="Message to Self"></textarea>
                      </Col>
                      <Col className="col-md-12 col-12 mb-3">
                        <a href="#" className="mb-2 d-block  text-info"> <i className="fas fa-plus"></i> Add Terms And Condition </a>
                        <label>Terms And Condition</label>
                        <textarea className="form-control"  placeholder="Terms And Condition"></textarea>
                      </Col>
                    </Row>

                    <Row className=" m-1">
                      <Col className="col-md-12  col-12 mb-3">
                        <label> Invoice Scan Copy </label>
                      </Col>
                      <Col className="col-md-6 mb-3">
                        <div className="bg-light p-3 height500px position-relative"> ddzf </div>
                        <a href="#" className="bg-dark p-2 rounded-circle position-absolute top-0 end-0 m-3 me-4"> <i className="fas fa-pencil-alt fs-5 text-light "></i> </a>
                      </Col>
                      <Col className="col-md-12 col-12 mb-3">
                      <a className="btn btn_success me-2" href="#"> Save Publish </a>
                    <a className="btn btn_danger" href="#"> Cancel </a>
                      </Col>
                    </Row>
                     </CardBody>
                </Card>
              </Col>



              <Col className="col-md-4 col-12">
                  <Card>
                     <CardBody>
                        <Row className=" align-items-center">
                          <Col className="col-md-6 mb-3">
                          <div className="bg-light p-2 fw-bold text-center "> LOGO </div>
                           </Col>
                           <Col className="col-md-6 mb-3">
                           <select className="form-select select" >
                                                <option value="INR">Select Company</option>
                                                <option value="CAD">ABC</option>
                                                <option value="EUR">123</option>
                                                <option value="USD">XYZ</option>
                                            </select>
                           </Col>
                    </Row>
                    <Row>
                      <Col className="col-md-12">
                        <ul className="list-unstyled">
                          <li className="position-relative mb-3">
                            <span className="position-absolute top-0 left-0"><i className="far fa-building fs-4"></i></span> 
                            <div className="ms-3 ps-3 fw-bold">
                            804, Elite Business Hub, Beside Mercedes Benz Showroom, opposite Shapath Hexa, Ahmedabad, Gujarat 380060
                            </div>
                          </li>
                          <li className="position-relative mb-3">
                          <span className="position-absolute top-0 left-0"><i className="far fa-envelope fs-4"></i></span>
                            <div className="ms-3 ps-3 fw-bold">
                            accounts@excelsiortenologies.com
                            </div>
                          </li>
                          <li className="position-relative mb-3">
                          <span className="position-absolute top-0 left-0"> <i className="fas fa-globe-americas fs-4"></i></span>
                            <div className="ms-3 ps-3 fw-bold">
                            www.excelsiortechnologies.com
                            </div>
                          </li>
                          </ul>
                      </Col>
                    </Row>
                     </CardBody>
                </Card>


                <Card>
                     <CardBody>
                      
                    <Row>
                      <Col className="col-md-12  col-12">
                        <div className="fs-4 mb-2 fw-bold">Invoice No</div>
                        <div>INR/V/2022/00000003</div>
                        <hr className="bg_hrlight"></hr>
                      </Col>
                      <Col className="col-md-12 col-12 mb-3">
                      <label>Invoice Date</label>
                   <input type="date" className="form-control" placeholder="Default Input" />
                      </Col>
                      <Col className="col-md-12 col-12">
                      <label>Due Date</label>
                        <input type="date" className="form-control" placeholder="Default Input" />
                        <hr className="bg_hrlight"></hr>
                      </Col>
                      
                      
                      <Col className="col-md-12 mb-3">

                      <div className="table-responsive">
                        <table className="table mb-0">
                            <tbody>
                                <tr>
                                    <td className="table-font">Net Amount :</td>
                                    <th className="text-end" id="txtNetAmount1"><span className="text-muted">INR &nbsp;</span>
                                        0.00
                                    </th>
                                </tr>
                                <tr>
                                    <td className="table-font">Total Discount : </td>
                                    <th className="text-end" id="txtTotalDiscount1"><span className="text-muted">INR &nbsp;</span>
                                        0.00
                                    </th>
                                </tr>
                                <tr>
                                    <td className="table-font">Total GST :</td>
                                    <th className="text-end" id="txtTotalTax1"><span className="text-muted">INR &nbsp;</span>
                                        0.00
                                    </th>
                                </tr>
                                <tr>
                                    <td className="table-font">Total SGST :</td>
                                    <th className="text-end" id="txtTotalSgstTax1"><span className="text-muted">INR &nbsp;</span>
                                        0.00
                                        
                                </th></tr>
                                <tr>
                                    <td className="table-font">Total CGST :</td>
                                    <th className="text-end" id="txtTotalCgstTax1"><span className="text-muted">INR &nbsp;</span>
                                        0.00
                                        
                                </th></tr>
                                <tr>
                                    <td className="table-font">Total IGST :</td>
                                    <th className="text-end" id="txtTotalIgstTax1"><span className="text-muted">INR &nbsp;</span>
                                        0.00
                                        
                                </th></tr>
                                <tr>
                                    <td className="table-font">TDS :</td>
                                    <th className="text-end"><span> <button type="button" className="btn btn-sm btn-primary dropdown-toggle" data-bs-toggle="modal" data-bs-target=".tdsModal" aria-haspopup="true" aria-expanded="false">Add &nbsp;<i className="fas fa-plus ml-2"></i>
                                            </button></span>
                                </th></tr>
                                <tr>
                                    <td className="table-font">TCS : </td>
                                    <th className="text-end"><span> <button type="button" className="btn btn-sm btn-primary dropdown-toggle" data-bs-toggle="modal" data-bs-target=".tcsModal" aria-haspopup="true" aria-expanded="false">Add &nbsp;<i className="fas fa-plus ml-2"></i>
                                            </button></span>
                                </th></tr>
                                <tr>


                                    <th className="font-total-large fs-4">Total :
                                    </th><th className="text-end  fs-4 " id="txtPayable1"><span className="amountUnitSpan">INR</span>
                                        0.00
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                        
                      </Col>
                    </Row>
                     </CardBody>
                </Card>

              </Col>

              
            </Row>  








            










            
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DatatableTables