import React, { Fragment, useState, useEffect } from "react"
import { Col, Row, CardTitle, Card, CardBody, Input, Label, Button } from "reactstrap";
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import LinkApp from 'App';
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import 'jspdf-autotable';


const OnSort = () => {
  let columns = [
    {
      key: "Srno",
      text: "#",
      sortable: true
    },
    {
      key: "CorporateName",
      text: "Corporate Name",
      sortable: true
    },
    {
      d: "MeetingContactName",
      text: "Corporate Contact",
      cell: (record, index) => {
        let MeetingContactName = record["MeetingContactName"];
        let SplitArray = MeetingContactName.split('|');
        return (
          <Fragment>
            {<p className="concss">{SplitArray[0]}</p>}
            {<p className="concss">{SplitArray[1]}</p>}
            {<p>{SplitArray[2]}</p>}
          </Fragment>
        );
      }
    },
    {
      key: "MeetingDate",
      text: "MeetingDate",
      sortable: true
    },
    {
      key: "Meetingtime",
      text: "Meetingtime",
      sortable: true
    },
    {
      key: "Meetingheadnote",
      text: "Meetingheadnote",
      sortable: true
    },
    {
      key: "Meetingsummary",
      text: "Meetingsummary",
      sortable: true
    },

    {
      d: "action",
      text: "Action",
      cell: (record, index) => {


        return (
          <Fragment>
            {RightsUpdate == "A" ? <a className="btn btn-primary btn-sm float-end font-14" href="#" onClick={RedirectAddNewFollowup.bind(this, record)}><i className="mdi mdi-pencil align-middle me-1"></i> Edit of Meeting</a> : ''}
          </Fragment>
        );
      }
    }
  ];


  const RedirectAddNewFollowup = (record) => {
    let id = record["CorporateleadId"];
    localStorage.setItem("CorporateleadId", id);
    let path = `/ManageMOMd/:${id}`;
    localStorage.setItem('MId', record["MId"]);
    history.push(path);
  }

  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
  }

  const [records, setRecords] = useState([])
  const [CreditStatusFlag, setCreditStatusFlag] = useState("");
  const [RightsAdd, setRightsAdd] = useState("D");
  const [RightsUpdate, setRightsUpdate] = useState("D");
  const [RightsDelete, setRightsDelete] = useState("D");

  const [MeetNote, setMeetNote] = useState('');
  const [MeetSummary, setMeetSummary] = useState('');
  const [MeetPoint, setMeetPoint] = useState('');
  const [DisplayAttachFile, setDisplayAttachFile] = useState(0);
  const [AttachmentFilePath, setAttachmentFilePath] = useState('');
  const [AttachmentFile, setAttachmentFile] = useState('');
  const [MeetPointList, setMeetPointList] = useState([]);
  const [AttachmentList, setAttachmentList] = useState([]);
  const [MeetAgendaList, setMeetAgendaList] = useState([]);
  const [MeetDate, setMeetDate] = useState('');
  const [MeetTime, setMeetTime] = useState('');
  const [NextMeetDate, setNextMeetDate] = useState('');
  const [CorporateList, setCorporateList] = useState([]);
  const [CorporateId, setCorporateId] = useState(0);
  const [CorporateContactPersonList, setCorporateContactPersonList] = useState([]);



  let history = useHistory();

  useEffect(() => {
    const f = {
      MenuId: "52",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {
        console.log(res.data);
        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add') {
            setRightsAdd(MS);
          }
          else if (MA == 'Update') {
            setRightsUpdate(MS);
          }
          else if (MA == 'Delete') {
            setRightsDelete(MS);
          }
        }
      });

    getMeetingList();
    getCorporateList();


  }, []);


  const exportPDF = () => {

    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Manage of Meeting List Report";
    const headers = [["#", "Corporate Name", "Corporate Contact", "Meeting Date", "Meeting Time", "Meeting HeadNote", "Meeting Summary"]];

    const data = records.map((item, index) => [index + 1, item.CorporateName, item.MeetingContactName, item.MeetingDate, item.Meetingtime, item.Meetingheadnote, item.Meetingsummary]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("ManageOfMeetingListReport.pdf")
  }


  const getMeetingList = async () => {
    fetch(global.APIURL + "/WebCorporateLeadMomListweb")
      .then((res) => res.json())
      .then((json) => {
        setRecords(json)
      })
  }

  const getCorporateList = async () => {
    fetch(global.APIURL + "/MeetingCorporateClient")
      .then((res) => res.json())
      .then((json) => {
        setCorporateList(json)
      })
  }

  const getCorporateContactPersonList = async CompanyId => {
    const LeadInquiry = {
      CompanyId: CompanyId
    };
    setCorporateId(CompanyId);
    axios.post(global.APIURL + "/MeetingCorporateClientContact", LeadInquiry)
      .then(CorporateClientres => {
        let CorporateClientContact = CorporateClientres.data.map(contact => {
          return contact;
        });
        setCorporateContactPersonList(CorporateClientContact);
      });
  };


  const onSort = (column, records, sortOrder) => {
    return orderBy(records, [column], [sortOrder]);
  }

  const MeetPointhandleAddRow = e => {
    var LRNo = MeetPointList.length == 0 ? 1 : (MeetPointList[MeetPointList.length - 1].RowId + 1);

    var VMeetPoint = document.getElementById('txtMeetingPoint').value;

    if (VMeetPoint != '') {
      const Mitem = {
        MeetPointId: 0,
        RowId: LRNo,
        Point: VMeetPoint,
        MeetPointTextBoxControlId: 'txtMeetingPoint' + LRNo,
      };
      setMeetPointList([...MeetPointList, Mitem]);
      document.getElementById('txtMeetingPoint').value = '';
    }

  };

  function AttachmentfetchPost(e) {
    var details = e.target.files[0];
    var details1 = "MeetAttachment";
    const formData = new FormData();
    formData.append('Document', details);
    formData.append('FolderName', details1);
    var AUrl = global.APIURL + '/uploadavatar/';
    axios.post(AUrl, formData,
      {
        headers: { "Content-Type": details.type },
      })
      .then((res) => {

        setAttachmentFile(res.data.FileName);
        setAttachmentFilePath(res.data.IName);


        if (e.target.files[0].name != null) {
          if (e.target.files[0].name != '') {
            setDisplayAttachFile(1);
          }
          else {
            setDisplayAttachFile(0);
          }
        }
      });
  }

  const AttachmenthandleAddRow = e => {
    var LRNo = AttachmentList.length == 0 ? 1 : (AttachmentList[AttachmentList.length - 1].RowId + 1);
    var VAttachFile = AttachmentFile;
    var VAttachFilePath = AttachmentFilePath;
    if (VAttachFile != '') {
      const ALitem = {
        AttachId: 0,
        RowId: LRNo,
        AttachFile: VAttachFile,
        AttachFilePath: VAttachFilePath,
        UploadFileTextBoxControlId: "AttachfileId" + LRNo
      };
      setAttachmentList([...AttachmentList, ALitem]);
      document.getElementById("AttachfileId").value = "";
      setDisplayAttachFile(0);
      setAttachmentFile('');
      setAttachmentFilePath('');
    }
  };

  const MeetAgendahandleAddRow = e => {
    var LRNo = MeetAgendaList.length == 0 ? 1 : (MeetAgendaList[MeetAgendaList.length - 1].RowId + 1);

    var VAgenda = document.getElementById('txtMeetingAgenda').value;
    if (VAgenda != '') {
      const Aitem = {
        MeetAgendaId: 0,
        RowId: LRNo,
        Agenda: VAgenda,
        MeetAgendaTextBoxControlId: 'txtMeetingAgenda' + LRNo,

      };
      setMeetAgendaList([...MeetAgendaList, Aitem]);
      document.getElementById('txtMeetingAgenda').value = '';
    }
  };

  const MeetAgendahandleRemoveRow = (e, RowId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowId;
      setMeetAgendaList(MeetAgendaList.filter(item => item.RowId !== RId));
    }
  };

  const AttachmenthandleRemoveRow = (e, RowId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowId;
      setAttachmentList(AttachmentList.filter(item => item.RowId !== RId));
    }
  };

  const MeetPointhandleRemoveRow = (e, RowId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowId;
      setMeetPointList(MeetPointList.filter(item => item.RowId !== RId));
    }
  };


  const saveMeetDetails = async () => {


    let VMeetNote = document.getElementById("txtMeetNote").value;
    let VMeetSummary = document.getElementById("txtMeetSummary").value;
    let VMeetDate = document.getElementById("txtMeetDate").value;
    let VMeetTime = document.getElementById("txtMeetTime").value;
    let VNextMeetDate = document.getElementById("txtNextMeetDate").value;
    let VMeetingPoint = document.getElementById("txtMeetingPoint").value;
    let VMeetingAgenda = document.getElementById("txtMeetingAgenda").value;
    let VAttachFile = AttachmentFile;
    let VAttachFilePath = AttachmentFilePath;


    if (MeetPointList.length == 0) {


      let IsDataInsert = 1;
      if (VMeetNote == "") {
        IsDataInsert = 0;
      }
      else if (VMeetSummary == "") {
        IsDataInsert = 0;
      }
      else if (VMeetDate == "") {
        IsDataInsert = 0;
      } else if (VMeetTime == "") {
        IsDataInsert = 0;
      } else if (VNextMeetDate == "") {
        IsDataInsert = 0;
      }

      if (IsDataInsert == 1) {
        var VCorporateId = document.getElementById('ddlCorporate').value;
        const MeetData = {
          Meetingheadnote: MeetNote,
          Meetingsummary: MeetSummary,
          MeetingDate: MeetDate,
          Meetingtime: MeetTime,
          Nextmeetingdate: NextMeetDate,
          "Flag": "A",
          CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
          CorporateleadId: VCorporateId
        }
        axios.post(global.APIURL + '/WebCorporateLeadManageofmeetingAdd', MeetData).then(res => {
          var VMId = res.data[0].MId;

          for (let ccp = 0; ccp < CorporateContactPersonList.length; ccp++) {
            const CorporateContactPersonDetail = CorporateContactPersonList[ccp];
            var VChkId = CorporateContactPersonDetail.ChkId;
            var VCorporateleadId = CorporateContactPersonDetail.CorporateleadId;
            var VCorporateleadContactId = CorporateContactPersonDetail.CorporateleadContactId;
            var VIsChk = document.getElementById(VChkId).checked;
            if (VIsChk == true) {
              const MeetItemArr = {
                Id: 0,
                MId: VMId,
                CorporateId: VCorporateleadId == null ? '0' : VCorporateleadId == '' ? '0' : VCorporateleadId,
                ContactId: VCorporateleadContactId == null ? '0' : VCorporateleadContactId == '' ? '0' : VCorporateleadContactId,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              };

              axios.post(global.APIURL + '/MeetingContactInformationSave', MeetItemArr).then(res => { });
            }
          }

          if (VMeetingPoint != '') {

            const MeetItemArr = {
              MId: VMId,
              Point: VMeetingPoint,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: VCorporateId
            };

            axios.post(global.APIURL + '/WebCorporateLeadMompointAdd', MeetItemArr).then(res => { });
          }


          if (VMeetingAgenda != '') {
            const MeetAgItemArr = {
              MId: VMId,
              Agenda: VMeetingAgenda,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: VCorporateId
            };

            axios.post(global.APIURL + '/WebCorporateLeadMomagendaAdd', MeetAgItemArr).then(res => { });
          }

          if (VAttachFilePath != '') {
            const ALItemArr = {
              MId: VMId,
              Attachment: VAttachFilePath,
              mediafileName: VAttachFile,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: VCorporateId
            };

            axios.post(global.APIURL + '/WebCorporateLeadMomAttachmentAdd', ALItemArr).then(res => { });
          }

        });
        alert("Meeting save successfully.");
        window.location.reload();


      }
    }
    else {

      let IsDataInsert = 1;
      var VCorporateId = document.getElementById('ddlCorporate').value;
      if (MeetNote == "") { IsDataInsert = 0; alert("Enter Meeting Note") }
      else if (MeetSummary == "") { IsDataInsert = 0; alert("Enter Meeting Summary") }
      else if (MeetDate == "") { IsDataInsert = 0; alert("Enter Meeting Meeting Date") }
      else if (MeetTime == "") { IsDataInsert = 0; alert("Enter Meeting Time") }
      else if (NextMeetDate == "") { IsDataInsert = 0; alert("Enter Next Meeting Date") }
      else if (VMeetingPoint == "") { IsDataInsert = 0; alert("Enter Meeting Point") }
      else if (VMeetingAgenda == "") { IsDataInsert = 0; alert("Enter Meeting Agenda") }
      else if (VAttachFile == "") { IsDataInsert = 0; alert("Select Attachment") }

      if (IsDataInsert == 1) {

        const MeetData = {
          Meetingheadnote: MeetNote,
          Meetingsummary: MeetSummary,
          MeetingDate: MeetDate,
          Meetingtime: MeetTime,
          Nextmeetingdate: NextMeetDate,
          "Flag": "A",
          CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
          CorporateleadId: VCorporateId
        }
        axios.post(global.APIURL + '/WebCorporateLeadManageofmeetingAdd', MeetData).then(res => {
          var VMId = res.data[0].MId;

          if (VMeetingPoint != '') {
            const MeetItemArr = {
              MId: VMId,
              Point: VMeetingPoint,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: VCorporateId
            };

            axios.post(global.APIURL + '/WebCorporateLeadMompointAdd', MeetItemArr).then(res => { });
          }

          if (VMeetingAgenda != '') {
            const MeetAgItemArr = {
              MId: VMId,
              Agenda: VMeetingAgenda,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: VCorporateId
            };
            axios.post(global.APIURL + '/WebCorporateLeadMomagendaAdd', MeetAgItemArr).then(res => { });
          }

          if (VAttachFile != '') {
            const ALItemArr = {
              MId: VMId,
              Attachment: VAttachFilePath,
              mediafileName: VAttachFile,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: VCorporateId
            };

            axios.post(global.APIURL + '/WebCorporateLeadMomAttachmentAdd', ALItemArr).then(res => { });
          }

          for (let mpl = 0; mpl < MeetPointList.length; mpl++) {

            const MeetPointDetail = MeetPointList[mpl];
            var VMeetPointTextBoxControlId = MeetPointDetail.MeetPointTextBoxControlId;
            var VMeetPoint = document.getElementById(VMeetPointTextBoxControlId).value == null ? '' : document.getElementById(VMeetPointTextBoxControlId).value;

            if (VMeetPoint != '') {
              const MeetItemArr = {
                MId: VMId,
                Point: VMeetPoint,
                "Flag": "A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                CorporateleadId: VCorporateId
              };

              axios.post(global.APIURL + '/WebCorporateLeadMompointAdd', MeetItemArr).then(res => { });
            }
          }

          for (let mal = 0; mal < MeetAgendaList.length; mal++) {
            const MeetAgendaDetail = MeetAgendaList[mal];

            var VMeetAgendaTextBoxControlId = MeetAgendaDetail.MeetAgendaTextBoxControlId;
            var VMeetAgenda = document.getElementById(VMeetAgendaTextBoxControlId).value == null ? '' : document.getElementById(VMeetAgendaTextBoxControlId).value;

            if (VMeetAgenda != '') {
              const MeetAgItemArr = {
                MId: VMId,
                Agenda: VMeetAgenda,
                "Flag": "A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                CorporateleadId: VCorporateId
              };
              axios.post(global.APIURL + '/WebCorporateLeadMomagendaAdd', MeetAgItemArr).then(res => { });
            }
          }

          for (let al = 0; al < AttachmentList.length; al++) {
            const AttachmentListDetail = AttachmentList[al];

            var VAttachment = AttachmentListDetail.AttachFilePath;
            var VmediafileName = AttachmentListDetail.AttachFile;

            if (VAttachment != '') {
              const ALItemArr = {
                MId: VMId,
                Attachment: VAttachment,
                mediafileName: VmediafileName,
                "Flag": "A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                CorporateleadId: VCorporateId
              };

              axios.post(global.APIURL + '/WebCorporateLeadMomAttachmentAdd', ALItemArr).then(res => { });
            }
          }

          for (let ccp = 0; ccp < CorporateContactPersonList.length; ccp++) {

            const CorporateContactPersonDetail = CorporateContactPersonList[ccp];
            var VChkId = CorporateContactPersonDetail.ChkId;
            var VCorporateleadId = CorporateContactPersonDetail.CorporateleadId;
            var VCorporateleadContactId = CorporateContactPersonDetail.CorporateleadContactId;
            var VIsChk = document.getElementById(VChkId).checked;
            if (VIsChk == true) {
              const MeetItemArr = {
                Id: 0,
                MId: VMId,
                CorporateId: VCorporateleadId == null ? '0' : VCorporateleadId == '' ? '0' : VCorporateleadId,
                ContactId: VCorporateleadContactId == null ? '0' : VCorporateleadContactId == '' ? '0' : VCorporateleadContactId,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              };

              axios.post(global.APIURL + '/MeetingContactInformationSave', MeetItemArr).then(res => { });
            }
          }

        });


        alert("Insert Successfull");
        window.setTimeout(() => {
          window.location.reload();
        }, 2000);
        //  history.push("/MOMList");
      }
    }
  }

  const MeetingDetailClose = async (e) => {
    e.preventDefault();
    getCorporateList();
    getCorporateContactPersonList(0);
    document.getElementById('txtMeetNote').value = '';
    document.getElementById('txtMeetSummary').value = '';
    document.getElementById('txtMeetDate').value = '';
    document.getElementById('txtMeetTime').value = '';
    document.getElementById('txtNextMeetDate').value = '';
    document.getElementById('txtMeetingPoint').value = '';
    document.getElementById('txtMeetingAgenda').value = '';
    document.getElementById('AttachfileId').value = '';
    MeetPointList.length = 0;
    MeetAgendaList.length = 0;
    AttachmentList.length = 0;
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Manage Of Meeting | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">
        <div className="modal fade bd-example-modal-lg" id="AddNewMeeting" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add New Meeting</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={MeetingDetailClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row className="">
                  <Row className="align-items-center">

                    <Col lg="6" className="mb-3 col-md-6">
                      <Label htmlFor="name">Corporate</Label>
                      <select className="form-select" Id="ddlCorporate" name="ddlCorporate" onChange={e => getCorporateContactPersonList(e.target.value)}>
                        <option value={"0"}>Select Corporate</option>
                        {CorporateList.map((Corporate) => (
                          <option key={Corporate.CorporateId} value={Corporate.CorporateId}> {Corporate.CorporateName} </option>
                        ))}
                      </select>
                    </Col>

                  </Row>

                  <Row className="align-items-center">
                    <Label htmlFor="name">Corporate Contact Information</Label>

                    {CorporateContactPersonList.map((item, idx) => (
                      <Col lg="3" className="col-md-6" key={idx}>
                        <input className="form-check-input" type="checkbox" id={item.ChkId} value={item.CorporateleadContactId} />{" "}<label className="">{item.Name}</label>
                      </Col>
                    ))}
                  </Row>

                  <Row className="align-items-center">
                    <Col lg="6" className="mb-3 col-md-6">
                      <Label htmlFor="name">Meeting Headnote</Label>
                      <Input type="text" id="txtMeetNote" className="form-control" placeholder="Enter Meeting HeadNote" onChange={e => setMeetNote(e.target.value)} />
                    </Col>
                    <Col lg="6" className="mb-3 col-md-6">
                      <Label htmlFor="name">Meeting Summary</Label>
                      <div className="input-group auth-pass-inputgroup">
                        <Input type="text" id="txtMeetSummary" className="form-control" placeholder="Enter Meeting Summary" onChange={e => setMeetSummary(e.target.value)} /> </div>
                    </Col>
                  </Row>

                  <Row className="align-items-center">
                    <Col lg="4" className="mb-3 col-md-6">
                      <Label htmlFor="name">Meeting Date</Label>
                      <Input type="date" id="txtMeetDate" className="form-control" placeholder="Enter Meeting Date" min={new Date().toISOString().split('T')[0]} onChange={e => setMeetDate(e.target.value)} />
                    </Col>
                    <Col lg="4" className="mb-3 col-md-6">
                      <Label htmlFor="name">Meeting Time</Label>
                      <div className="input-group auth-pass-inputgroup">
                        <input id="txtMeetTime" type="time" className="form-control" placeholder="Enter Meeting Time" onChange={e => setMeetTime(e.target.value)} />
                      </div>
                    </Col>
                    <Col lg="4" className="mb-3 col-md-6">
                      <Label htmlFor="name">Next Meeting Date</Label>
                      <div className="input-group auth-pass-inputgroup">
                        <input id="txtNextMeetDate" type="date" className="form-control" placeholder="Enter Next Meeting Date" min={new Date().toISOString().split('T')[0]} onChange={e => setNextMeetDate(e.target.value)} />
                      </div>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col lg="12">
                      <Label htmlFor="name">Meeting Points</Label>
                    </Col>
                    <Col lg="12">
                      <table style={{ width: "100%" }}>
                        <tbody>
                          {MeetPointList?.map((item, idx) => (
                            <tr id={"addr" + idx} key={idx}>
                              <td>
                                <div data-repeater-list="group-a mb-3">
                                  <Row data-repeater-item className="align-items-center mobile-mt">
                                    <Col lg="10" className="mb-3 col-md-6">

                                      <Input type="text" id={item.MeetPointTextBoxControlId} className="form-control" placeholder="Enter Meeting Agenda" defaultValue={item.Point} />
                                    </Col>

                                    <Col lg="2" className="mb-3 col-md-6">
                                      <Button color="danger" className="btn-sm btn-width" onClick={e =>
                                        MeetPointhandleRemoveRow(e, item.RowId)
                                      }>
                                        <i className="fas fa-trash-alt"></i>
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div data-repeater-list="group-a">
                        <Row data-repeater-item className="align-items-center">
                          <Col lg="10" className="mb-3 col-md-6">
                            <Input type="text" id="txtMeetingPoint" className="form-control" placeholder="Enter Meeting Points" defaultValue={MeetPoint} />
                          </Col>
                          <Col lg="2" className="mb-3 col-md-6">
                            <Button color="primary" className="btn-sm" onClick={MeetPointhandleAddRow}>
                              <i className="fas fa-plus me-2"></i>Add
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg="12">
                      <Label htmlFor="name">Next Meeting Agenda</Label>
                    </Col>
                    <Col lg="12">
                      <table style={{ width: "100%" }}>
                        <tbody>
                          {MeetAgendaList?.map((item, idx) => (
                            <tr id={"addr" + idx} key={idx}>
                              <td>
                                <div data-repeater-list="group-a mb-3">
                                  <Row data-repeater-item className="align-items-center mobile-mt">
                                    <Col lg="10" className="mb-3 col-md-6">
                                      <Input type="text" id={item.MeetAgendaTextBoxControlId} className="form-control" placeholder="Enter Meeting Agenda" defaultValue={item.Agenda} />
                                    </Col>

                                    <Col lg="2" className="mb-3 col-md-6">
                                      <Button color="danger" className="btn-sm btn-width" onClick={e =>
                                        MeetAgendahandleRemoveRow(e, item.RowId)}>
                                        <i className="fas fa-trash-alt"></i>
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div data-repeater-list="group-a">
                        <Row data-repeater-item className="align-items-center">
                          <Col lg="10" className="mb-3 col-md-6">
                            <Input type="text" id="txtMeetingAgenda" className="form-control" placeholder="Enter Meeting Agenda" />
                          </Col>
                          <Col lg="2" className="mb-3 col-md-6">
                            <Button color="primary" className="btn-sm" onClick={MeetAgendahandleAddRow}>
                              <i className="fas fa-plus me-2"></i>Add
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg="12">
                      <Label htmlFor="name">Attachment</Label>
                    </Col>
                    <Col lg="12">
                      <table style={{ width: "100%" }}>
                        <tbody>
                          {AttachmentList?.map((item, idx) => (
                            <tr id={"addr" + idx} key={idx}>
                              <td>
                                <div data-repeater-list="group-a mb-3">
                                  <Row data-repeater-item className="align-items-center mobile-mt">
                                    <Col lg="10" className="mb-3 col-md-6">
                                      <span className="form-control form-control"><i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i><a href={item.AttachFilePath} target="_blank" rel="noopener noreferrer" >{item.AttachFile}</a></span>
                                      <input type="hidden" className="form-control form-control" id={item.UploadFileTextBoxControlId} defaultValue={item.AttachFilePath} />
                                    </Col>
                                    <Col lg="2" className="mb-3 col-md-6">
                                      <Button color="danger" className="btn-sm btn-width" onClick={e => AttachmenthandleRemoveRow(e, item.RowId)}>
                                        <i className="fas fa-trash-alt"></i>
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div data-repeater-list="group-a">
                        <Row data-repeater-item className="align-items-center">
                          <Col lg="10" className="mb-3 col-md-6">
                            {DisplayAttachFile > 0 ?
                              <span className="float-end text-primary font-12" id="spandoctitle">
                                <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                                <a href={AttachmentFilePath} target="_blank" rel="noopener noreferrer" >{AttachmentFile}</a></span> : ''}
                            <input type="file" className="form-control form-control" id="AttachfileId" onChange={AttachmentfetchPost} />
                          </Col>
                          <Col lg="2" className="mb-3 col-md-6">
                            <Button color="primary" className="btn-sm" onClick={AttachmenthandleAddRow}>
                              <i className="fas fa-plus me-2"></i>Add
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                  </Row>

                  <Row>
                    <div className="col-lg-12">
                      <button type="button" className="btn btn_success me-2" data-bs-dismiss="modal" aria-label="Close" onClick={e => saveMeetDetails()}>Save</button>
                      <button type="button" className="btn btn_danger me-2" data-bs-dismiss="modal" aria-label="Close" onClick={e => MeetingDetailClose()}>Close</button>

                    </div>
                  </Row>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <Row className="mb-3">
          <Col className="col-lg-6 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Manage Of Meeting</CardTitle>
              <a href="/GeneralDashboard" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <span className="ms-2">Manage Of Meeting</span>
            </div>
          </Col>
          <Col className="col-lg-6 co-md-6 col-6">
            <div className="d-flex align-items-center justify-content-end ">

              <div>

                {RightsAdd == "A" ? <a className="btn btn-primary btn-sm float-end font-14 me-2" data-bs-toggle="modal" data-bs-target="#AddNewMeeting">
                  <i className="mdi mdi-plus align-middle me-1"></i>Add New
                </a> : ''}
                <ReactHtmlTableToExcel
                  className="btn btn-primary btn-sm float-end font-14 me-2"
                  table="emp"
                  filename="ManageOfMeetingListReport"
                  sheet="Sheet"
                  buttonText="Export Excel" />
                <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
              </div>
            </div>
          </Col>

        </Row>
        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>

        <table id="emp" className="table" hidden="hidden">
          <thead>
            <tr>
              <th>#</th>
              <th>Corporate Name</th>
              <th>Meeting Contact Name</th>
              <th>Meeting Date</th>
              <th>Meeting Time</th>
              <th>Meeting Headnote</th>
              <th>Meeting Summary</th>

            </tr>
          </thead>
          <tbody>
            {
              records.map((item, index) => {
                return <tr key={index}>
                  <td> {index + 1} </td>
                  <td>{item.CorporateName}</td>
                  <td>{item.MeetingContactName}</td>
                  <td>{item.MeetingDate}</td>
                  <td>{item.Meetingtime}</td>
                  <td>{item.Meetingheadnote}</td>
                  <td>{item.Meetingsummary}</td>

                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OnSort;