import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
} from "reactstrap"


//Import Breadcrumb


class UiTabsAccordions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col5: true,
      col6: true,
      col7: true,
      col8: true,
      col9: true,
      col10: false,
      col11: false,
    }
    this.toggle = this.toggle.bind(this)
    this.toggle1 = this.toggle1.bind(this)

    this.t_col1 = this.t_col1.bind(this)
    this.t_col2 = this.t_col2.bind(this)
    this.t_col3 = this.t_col3.bind(this)
    this.t_col5 = this.t_col5.bind(this)
    this.t_col6 = this.t_col6.bind(this)
    this.t_col7 = this.t_col7.bind(this)
    this.t_col8 = this.t_col8.bind(this)
    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)

    this.toggle2 = this.toggle2.bind(this)
    this.toggle3 = this.toggle3.bind(this)

    this.toggleVertical = this.toggleVertical.bind(this)
    this.toggleVerticalIcon = this.toggleVerticalIcon.bind(this)
    this.toggleCustom = this.toggleCustom.bind(this)
    this.toggleIconCustom = this.toggleIconCustom.bind(this)
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false
    })
  }

  t_col2() {
    this.setState({
      col1: false,
      col2: !this.state.col2,
      col3: false
    })
  }

  t_col3() {
    this.setState({
      col1: false,
      col2: false,
      col3: !this.state.col3
    })
  }

  t_col5() {
    this.setState({ col5: !this.state.col5 })
  }

  t_col6() {
    this.setState({ col6: !this.state.col6 })
  }

  t_col7() {
    this.setState({ col7: !this.state.col7 })
  }

  t_col8() {
    this.setState({
      col6: !this.state.col6,
      col7: !this.state.col7
    })
  }

  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false
    })
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false
    })
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      })
    }
  }

  toggle2(tab) {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      })
    }
  }

  toggle3(tab) {
    if (this.state.activeTab3 !== tab) {
      this.setState({
        activeTab3: tab,
      })
    }
  }

  toggleVertical(tab) {
    if (this.state.verticalActiveTab !== tab) {
      this.setState({
        verticalActiveTab: tab,
      })
    }
  }

  toggleVerticalIcon(tab) {
    if (this.state.verticalActiveTabWithIcon !== tab) {
      this.setState({
        verticalActiveTabWithIcon: tab,
      })
    }
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      })
    }
  }

  toggleIconCustom(tab) {
    if (this.state.customIconActiveTab !== tab) {
      this.setState({
        customIconActiveTab: tab,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Edit Candidate | KC Admin Group</title>
          </MetaTags>
          <Container fluid={true}>
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h4">Edit Candidate
</CardTitle>
                      <a href="#" className="me-2">Candidate</a> <span>/</span><span className="ms-2">Edit Candidate</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>

            
              <Card>
                <CardBody>
                <Row>
                  <Col className="col-md-8 col-12">
                    <h5 className="fw-bolder"> Laravel Developer : </h5>
                  </Col>
                  <Col className="col-md-4 col-12 text-md-end text-start">
                    <p className="mb-0"> Total Applicants : 3 </p>
                  </Col>
                </Row>
                <hr className="bg_hrdark my-2" />
                <Row>
                  <Col className="col-md-3 col-6 mb-2">
                    <p className="mb-0 text-transform-uppercase"> Description  </p>
                     <p className="fw-bolder mb-0">Website Development</p>
                  </Col>
                  <Col className="col-md-3 col-6 mb-2">
                    <p className="mb-0 text-transform-uppercase "> Minimum Experience   </p>
                     <p className="fw-bolder mb-0">3 year</p>
                  </Col>
                  <Col className="col-md-3 col-6 mb-2">
                    <p className="mb-0 text-transform-uppercase "> Short Description </p>
                     <p className="fw-bolder mb-0">Development</p>
                  </Col>
                  <Col className="col-md-3 col-6 mb-2">
                    <p className="mb-0 text-transform-uppercase "> Pay Range   </p>
                     <p className="fw-bolder mb-0">10000</p>
                  </Col>
                </Row>
                <hr className="bg_hrlight my-2" />
                <Row>
                  <Col className="col-md-3 col-6 mb-2">
                    <p className="mb-0 text-transform-uppercase "> Walk-In Allowed  </p>
                     <p className="fw-bolder mb-0">Yes</p>
                  </Col>
                  <Col className="col-md-3 col-6 mb-2">
                    <p className="mb-0 text-transform-uppercase "> Job Post Start Date  </p>
                     <p className="fw-bolder mb-0">2021-10-08</p>
                  </Col>
                  <Col className="col-md-3 col-6  mb-2">
                    <p className="mb-0 text-transform-uppercase"> Job Post End Date  </p>
                     <p className="fw-bolder mb-0">2021-10-08</p>
                  </Col>

                  <Col className="col-md-3 col-6 mb-2">
                    <p className="mb-0 text-transform-uppercase "> Ask for   </p>
                     <p className="fw-bolder mb-0">Resume Plus Cover Letter</p>
                  </Col>
                  
                </Row>
                <hr className="bg_hrdark my-2" />
                </CardBody>
            </Card>


            
            <Card>
              <CardBody>

                <Row>
                  <Col className="col-lg-10  col-12">
                            <Row>
                            <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">First Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="First Name"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Middle Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Middle Name"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Last Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Last Name"
                            />
                          </div>
                      </Col>
                      <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Email</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Email"
                            />
                          </div>
                        </Col>
                        
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Country</label>
                            <select id="inputState" className="form-select">
                              <option selected>Select Country</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">State</label>
                            <select id="inputState" className="form-select">
                              <option selected> Select State</option>
                              <option>State</option>
                              <option>State</option>
                            </select>
                          </div>
                      </Col>
                      
                            </Row>
                            
                  </Col>
                  
                          <Col className="col-lg-2 col-12">
                          <label className="form-label d-block text-center">Upload Image</label>
                          <div className ="d-flex logo-main float-end">
                         
                              <div className="logo-box">
                                  <p className="mb-0">User</p>
                              </div>
                                <div className="icon-box">
                              <a href="">  <i className="mdi mdi-pencil"></i></a>
                                </div>
                        </div>
                          </Col>
                     
                </Row>
                
                <Row>
                <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">City</label>
                            <select id="inputState" className="form-select">
                              <option selected> Select City</option>
                              <option>City</option>
                              <option>City</option>
                            </select>
                          </div>
                      </Col>
                      <Col lg={4}>
                      <div className="mb-3">
                          <label className="form-label">Contact Number</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Contact Number"
                            />
                          </div>
                      </Col>
                      <Col lg={4}>
                      <div className="mb-3">
                          <label className="form-label">Highest Qualification</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Highest Qualification"
                            />
                          </div>
                      </Col>
                      <Col lg={4}>
                      <div className="mb-3">
                          <label className="form-label">Total Experience</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Total Experience"
                            />
                          </div>
                      </Col>
                      <Col lg={4}>
                      <div className="mb-3">
                          <label className="form-label">Position Applied</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Position Applied"
                            />
                          </div>
                  </Col>
                  <Col lg={4}>
                      <div className="mb-3">
                          <label className="form-label">Previous Designation</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Previous Designation"
                            />
                          </div>
                      </Col>
                </Row>

                <Row  className="mb-3">
                  <Col className="col-lg-9 col-12">
                    <Row>
                    <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Date</label>
                            <Input
                              type="date"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Select"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">File Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="File Name"
                            />
                          </div>
                      </Col>
                            <Col lg={4}>
                          <div className="mb-3">
                          <div className="mb-3">
  <label   className="form-label">CV / Resume</label>
  <input className="form-control" type="file" id="formFile" />
</div>
                          </div>
                            </Col>
                      </Row>
                        <Row>
                      <Col lg={12}>
                        <div className="mb-1">
                          <label>Status</label>
                      </div>
                          <div className="mb-3">
                          <div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
  <label className="form-check-label"  >Pending For Interview</label>
</div>
<div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
  <label className="form-check-label"  > Interview done </label>
                          </div>
                          <div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
  <label className="form-check-label"  > On hold </label>
                          </div>
                          <div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
  <label className="form-check-label"  > Selected </label>
                          </div>
                          <div className="form-check form-check-inline">
  <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
  <label className="form-check-label"  > Rejected </label>
</div>
                          </div>
                      </Col>
                      
                    </Row>
                    
                    <Row>
                      <Col lg={12} className="mb-3">
                      <label >Remarks</label>
                      <textarea className="form-control" placeholder="Leave a remark here"></textarea>
                      </Col>
                    </Row>




                  
                            
                  </Col>
                  
                          <Col className="col-lg-3 col-12">
                    <Row>
                      <Col className="col-12">
                        <label> Preview CV </label>
                          <div className ="d-flex logo-main">
                              
                              <div className="bg-light d-flex  flex-grow-1" style={{height:"230px"}}>
                                   <p className="align-items-center flex-grow-1 justify-content-center d-flex text-center">Document Preview <br /> Place Holder </p>
                              </div>
                                <div className="icon-box">
                              <a href="#">  <i className="mdi mdi-pencil"></i></a>
                                </div>
                        </div>
                      </Col>
                    </Row>
                          </Col>
                    
            </Row>

            <Row>
              <Col lg={12} className="mb-3">
                    
              <div className="form-group">
              <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  <label className="form-check-label">
                    Default checkbox
                  </label>
                </div>
                </div>
                     
              </Col>
            </Row>


              <Row>
                <Col className="col-lg-2  col-md-12 col-12 mb-3">
                    <a href="#" className="btn btn_success me-2"> Save </a>
               
                    <a href="#" className="btn btn_danger"> Cancel </a>
                </Col>
                
                <Col className="col-lg-4 col-md-12 col-12 mb-3">
                  <div className="border border-2 p-3 rounded-3">
                    <p className="mb-1 fw-bold">Created by : KC Admin</p>
                    <p className="mb-1 fw-bold">Created date time : 21 January, 2022 01:26 PM</p>
                    <p className="mb-1 fw-bold">Modified by : KC Admin</p>
                    <p className="mb-1 fw-bold">Modified date time : 21 January, 2022 01:26 PM</p>
                    <p className="mb-1 fw-bold">Total Time Taken : 00:00:03</p>
                   </div>
                </Col>
              </Row>

              </CardBody>
            </Card>












            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default UiTabsAccordions
