import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  Col,
  Label,
  Row,
  CardTitle,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";


//Import Breadcrumb

class UiNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",

      showDuration: 300,
      hideDuration: 1000,
      timeOut: 5000,
      extendedTimeOut: 1000,
    };
    this.showToast.bind(this);
    this.clearToast.bind(this);
  }

  showToast() {
    var ele = document.getElementsByName("toastType");
    var position = document.getElementsByName("positions");
    var toastType;
    var title = document.getElementById("title").value;
    var message = "Have fun storming the castle!";

    if (document.getElementById("message").value !== "")
      message = document.getElementById("message").value;

    //Close Button
    var closeButton = document.getElementById("closeButton").checked;

    //Add behavior on toast click Button
    var behaviorButton = document.getElementById("behaviorButton").checked;

    //Debug
    var debug = document.getElementById("debugInfo").checked;

    //Progressbar
    var progressBar = document.getElementById("progressBar").checked;

    //Duplicates
    var preventDuplicates = document.getElementById("preventDuplicates").checked;

    //Newest on Top
    var newestOnTop = document.getElementById("newestOnTop").checked;

    //position class
    var positionClass = "toast-top-right";

    //Fetch position
    for (var p = 0; p < position.length; p++) {
      if (position[p].checked) positionClass = position[p].value;
    }

    //Show Easing
    var showEasing = document.getElementById("showEasing").value;

    //Hide Easing
    var hideEasing = document.getElementById("hideEasing").value;

    //show method
    var showMethod = document.getElementById("showMethod").value;

    //Hide method
    var hideMethod = document.getElementById("hideMethod").value;

    //show duration
    var showDuration = document.getElementById("showDuration").value;

    //Hide duration
    var hideDuration = document.getElementById("hideDuration").value;

    //timeout
    var timeOut = document.getElementById("timeOut").value;

    //extended timeout
    var extendedTimeOut = document.getElementById("extendedTimeOut").value;

    //Fetch checked Type
    for (var i = 0; i < ele.length; i++) {
      if (ele[i].checked) toastType = ele[i].value;
    }

    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      behaviorButton: behaviorButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  clearToast() {
    toastr.clear();
  }

  render() {
    return (
      <React.Fragment>
          <div className="page-content">
          <MetaTags>
            <title>Pay Calculated On | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h6">Pay Calculated On</CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Pay Calculate on</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>  
            <Row>
              
              <Col className="col-12">
                <Card>
                  <CardBody>
                     {/* User list */}
                     <Row>
                     <Col lg={6}>
                          <div className="mb-3">
                            <label className="form-label">Company</label>
                            <select id="inputState" className="form-select">
                              <option selected>Comapany </option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                     </Row>
                     <Row>
                       <Col lg={12}>
                         <p>Calculation Criteria</p>
                          <div className="d-flex">
                            <div className="form-check mb-3 me-3">
                              <input
                                type="checkbox"
                                className="form-check-input input-mini"
                                id="closeButton"
                                value=""
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="closeButton"
                              >
                                Total Working
                              </Label>
                            </div>
                            <div className="form-check mb-3 me-3">
                              <input
                                type="checkbox"
                                className="form-check-input input-mini"
                                id="closeButton"
                                value="checked"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="closeButton"
                              >
                                Public Holiday
                              </Label>
                            </div>
                            <div className="form-check mb-3 me-3">
                              <input
                                type="checkbox"
                                className="form-check-input input-mini"
                                id="closeButton"
                                value="checked"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="closeButton"
                              >
                                Week Off
                              </Label>
                            </div>
                            <div className="form-check mb-3 me-3">
                              <input
                                type="checkbox"
                                className="form-check-input input-mini"
                                id="closeButton"
                                value="checked"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="closeButton"
                              >
                                Total Working Hours
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input input-mini"
                                id="closeButton"
                                value="checked"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="closeButton"
                              >
                                On to Stories Submitted
                              </Label>
                            </div>
                          </div>
                       </Col>
                     </Row>
                     <Row>
                        <Col className=" col-md-4 col-12 mt-3">
                <div className ="d-flex align-items-center
                 justify-content-between">
                   
                      
                  <div className ="d-flex">
                  <div  className="form-check">
                                          <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                    <a className ="btn btn_success btn-sm" href="/">Save</a>
                    <a className ="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
                      </Row>
                     
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UiNotifications;
