import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import img2 from "assets/images/PAN.png";
import axios from "axios";
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, CardTitle, Card, CardBody, Input, Label, Button } from "reactstrap";
import PlacholderImgC from "assets/images/favicon.png";
import { ResetTvRounded } from "@mui/icons-material";


const Editcorleads = () => {
  const history = useHistory();
  const [StateList, setStateList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);
  const [CompanyDistrictId, setCompanyDistrictId] = useState("");
  const [CompanyTehsilId, setCompanyTehsilId] = useState("");
  const [LeadPartnerList, setLeadPartnerList] = useState([]);
  const [AddTehsil, setAddTehsil] = useState('D');
  const [AddCity, setAddCity] = useState('D');
  const [CityId, setCityId] = useState("");
  const [Pincode, setPincode] = useState("");
  const [BranchCode, setBranchCode] = useState("");
  const [ComapanyList, setCompanyList] = useState([]);
  const [CompanyId, setCompanyId] = useState("");
  const [NetworkTypeList, setNetworkTypeList] = useState([]);
  const [NetworkTypeId, setNetworkTypeId] = useState("");
  const [LeadContactList, setLeadContactList] = useState([]);
  const [Address, setAddress] = useState("");
  const [LeadFollowupList, setLeadFollowupList] = useState([]);
  const [ChannelTypeId, setChannelTypeId] = useState("");
  const [StateId, setStateId] = useState("");
  const [EditcorLeadId, setcorEditLeadId] = useState("");
  const [NextFollowupDate, setNextFollowupDate] = useState('');
  const [NextFollowupTime, setNextFollowupTime] = useState('');
  const [NextFollowupMode, setNextFollowupMode] = useState('');
  const [NextFollowupNote, setNextFollowupNote] = useState('');
  const [MEmailId, setmEmailId] = useState("");
  const [ConvertDate, setConvertDate] = useState('');
  const [ConvertRemark, setConvertRemark] = useState('');

  const [CloseDate, setCloseDate] = useState('');
  const [CloseRemark, setCloseRemark] = useState('');

  const [LeadStatus, setLeadStatus] = useState('');


  const [MeetNote, setMeetNote] = useState([]);
  const [MeetSummary, setMeetSummary] = useState([]);
  const [MeetDate, setMeetDate] = useState([]);
  const [MeetTime, setMeetTime] = useState([]);
  const [NextMeetDate, setNextMeetDate] = useState([]);
  const [MeetPointList, setMeetPointList] = useState([]);
  const [MeetAgendaList, setMeetAgendaList] = useState([]);
  const [AttachmentList, setAttachmentList] = useState([]);
  const [AttachmentFilePath, setAttachmentFilePath] = useState([]);
  const [AttachmentFile, setAttachmentFile] = useState([]);
  const [MeetDetails, setMeetDetails] = useState([]);
  const [DisplayAttachFile, setDisplayAttachFile] = useState([]);
  const [DropdownChannelList, setDropdownChannelList] = useState([]);
  const [CorporateList, setCorporateList] = useState([]);

  const [MeetCorporateId, setMeetCorporateId] = useState(0);
  const [CorporateId, setCorporateId] = useState(0);
  const [MeetingId, setMeetingId] = useState(0);

  const [MeetPoint, setMeetPoint] = useState([]);
  const [CorporateContactPersonList, setCorporateContactPersonList] = useState([]);

  const [pointde, setpoint] = useState([]);
  const [agendade, setagenda] = useState([]);
  const [Idset, setId] = useState([]);
  const [attach, setattachment] = useState([]);
  const [mid, setmid] = useState([]);
  //let CoLeadId = localStorage.getItem("CorporateleadId");

  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


  let { id } = useParams();
  let newid1 = id.split(':');
  const CoLeadId = newid1[1];
  let MIdstorage = localStorage.getItem("MId");


  useEffect(() => {

    if (id != null) {
      if (id != "0") {
        let newid = id.split(':');

        getStateList("1");
        getLeadContactList(newid[1]);
        getLeadDetailById(newid[1]);
        getCompanyList();
        getNetWorkList();
        getLeadFollowupList(newid[1]);
        getMenuHideShow();
        getMeetDetailList(newid[1]);
        setId(newid[1]);
        getDropdownChannelMasterList();
        getCorporateList();

        // document.getElementById("CorporateListdiv").style.display = 'none';
        //getpointList(newid[1]);
        //getagendaList(newid[1]);
        //getattachmentList(newid[1]);
      } else {
        history.push("CorporateLeads");
      }
    } else {
      history.push("CorporateLeads");
    }
  }, []);

  const getCorporateList = async () => {
    fetch(global.APIURL + "/MeetingCorporateClient")
      .then((res) => res.json())
      .then((json) => {
        setCorporateList(json)
      })
  }

  const getCorporateContactPersonList = async CompanyId => {

    const LeadInquiry = {
      CompanyId: CompanyId
    };


    setCorporateId(CompanyId);
    axios.post(global.APIURL + "/MeetingCorporateClientContact", LeadInquiry)
      .then(CorporateClientres => {
        let CorporateClientContact = CorporateClientres.data.map(contact => {
          return contact;
        });
        setCorporateContactPersonList(CorporateClientContact);

      });
  };


  const getDropdownChannelMasterList = async () => {
    var VNetworkTypeId = document.getElementById("ddlNetworkType").value;
    if (parseInt(VNetworkTypeId) > 0) {
      const ChannelDropdownFilter = {
        ChannelTypeId: VNetworkTypeId,
        ChannelMasterId: 0,
        CityId: 0,
        SpMode: 'ChannelMasterSelectAll',
      };
      axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
        .then(CLres => {
          let VChannelMasterList = CLres.data.map(vcl => {
            return vcl;
          });
          setDropdownChannelList(VChannelMasterList);
        });
    }
  };

  const getEditDropdownChannelMasterList = async (NetworkTypeId) => {
    var VNetworkTypeId = NetworkTypeId
    if (parseInt(VNetworkTypeId) > 0) {
      const ChannelDropdownFilter = {
        ChannelTypeId: VNetworkTypeId,
        ChannelMasterId: 0,
        CityId: 0,
        SpMode: 'ChannelMasterSelectAll',
      };
      axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
        .then(CLres => {
          let VChannelMasterList = CLres.data.map(vcl => {
            return vcl;
          });
          setDropdownChannelList(VChannelMasterList);
        });
    }
  };

  const getMenuHideShow = async () => {
    const f = {
      MenuId: "48",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {
        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add Tehsil') {
            setAddTehsil(MS);
          }
          else if (MA == 'Add City') {
            setAddCity(MS);
          }
        }
      });
  };

  const getCompanyList = async () => {
    var ACUrl = global.APIURL + '/CompanyActive/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCompanyList(CityL);
      });
  };
  const getNetWorkList = async () => {
    var ACUrl = global.APIURL + '/ChannalTypeList/';
    axios.get(ACUrl)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setNetworkTypeList(CityL);
      });
  };

  const getStateList = async CId => {
    var AUrl = global.APIURL + '/ActiveState/' + CId;
    axios.get(AUrl)
      .then(Sres => {
        let StateL = Sres.data.map(kcstate => {
          return kcstate;
        });
        setStateList(StateL);
      });
  };

  const getDistrictList = async SId => {
    setStateId(SId);
    var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
    axios.get(AUrl)
      .then(Dres => {
        let DistrictL = Dres.data.map(kcdistrict => {
          return kcdistrict;
        });
        setDistrictList(DistrictL);
      });
  };

  const getCompanyTehsilList = async DId => {
    var AUrl = global.APIURL + '/ActiveTehsil/' + DId;
    axios.get(AUrl)
      .then(Dres => {
        let TehsilL = Dres.data.map(kctehsil => {
          return kctehsil;
        });
        setTehsilList(TehsilL);
      });
  };


  const getMeetDetailList = async (CID) => {
    const MData = {
      "CorporateleadId": CID
    }

    axios.post(global.APIURL + '/WebCorporateLeadMomEdit', MData)
      .then(res => {
        if (res.data != '') {

          setMeetDetails(res.data);
          setmid(res.data[0].MId);
        }

      });

  }

  const MeetPointhandleAddRow = e => {
    var LRNo = MeetPointList.length == 0 ? 1 : (MeetPointList[MeetPointList.length - 1].RowId + 1);

    var VMeetPoint = document.getElementById('txtMeetingPoint').value;
    if (VMeetPoint == "") {
      alert("Enter Meeting Points");
      return;
    }

    const Mitem = {
      MeetPointId: 0,
      RowId: LRNo,
      Point: VMeetPoint,
      MeetPointTextBoxControlId: 'txtMeetingPoint' + LRNo,

    };
    setMeetPointList([...MeetPointList, Mitem]);
    document.getElementById('txtMeetingPoint').value = '';

  };

  const MeetPointhandleRemoveRow = (e, RowId, MeetPointId) => {
    let AlertMessgae = "Are you sure you want to delete this re cord?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowId;
      setMeetPointList(MeetPointList.filter(item => item.RowId !== RId));

      var VMeetPointId = parseInt(MeetPointId == null ? '0' : MeetPointId == '' ? '0' : MeetPointId);
      if (VMeetPointId > 0) {
        const MPItemArr = { Id: VMeetPointId }
        axios.post(global.APIURL + '/WebMOMPointDelete', MPItemArr).then(resem => { });
      }
    }
  };

  const MeetAgendahandleAddRow = e => {
    var LRNo = MeetAgendaList.length == 0 ? 1 : (MeetAgendaList[MeetAgendaList.length - 1].RowId + 1);

    var VAgenda = document.getElementById('txtMeetingAgenda').value;
    if (VAgenda == "") {
      alert("Enter Meeting Agenda");
      return;
    }

    const Aitem = {
      MeetAgendaId: 0,
      RowId: LRNo,
      Agenda: VAgenda,
      MeetAgendaTextBoxControlId: VAgenda,

    };
    setMeetAgendaList([...MeetAgendaList, Aitem]);
    document.getElementById('txtMeetingAgenda').value = '';

  };

  const MeetAgendahandleRemoveRow = (e, RowId, MeetAgendaId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowId;
      setMeetAgendaList(MeetAgendaList.filter(item => item.RowId !== RId));

      var VMeetAgendaId = parseInt(MeetAgendaId == null ? '0' : MeetAgendaId == '' ? '0' : MeetAgendaId);
      if (VMeetAgendaId > 0) {
        const MAItemArr = { Id: VMeetAgendaId }
        axios.post(global.APIURL + '/WebMOMagendaDelete', MAItemArr).then(resem => { });
      }
    }
  };


  const AttachmenthandleAddRow = e => {
    var LRNo = AttachmentList.length == 0 ? 1 : (AttachmentList[AttachmentList.length - 1].RowId + 1);
    var VAttachFile = AttachmentFile;
    var VAttachFilePath = AttachmentFilePath;
    if (VAttachFile == "") {
      alert("Add Attachment");
      return;
    }
    const ALitem = {
      AttachId: 0,
      RowId: LRNo,
      AttachFile: VAttachFile,
      AttachFilePath: VAttachFilePath,
      UploadFileTextBoxControlId: VAttachFilePath

    };
    setAttachmentList([...AttachmentList, ALitem]);
    setAttachmentFile([]);
    setAttachmentFilePath([]);
    document.getElementById("AttachfileId").value = "";


  };

  const AttachmenthandleRemoveRow = (e, RowId, AttachId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowId;
      setAttachmentList(AttachmentList.filter(item => item.RowId !== RId));

      var VAttachId = parseInt(AttachId == null ? '0' : AttachId == '' ? '0' : AttachId);
      if (VAttachId > 0) {
        const AItemArr = { Id: VAttachId }
        axios.post(global.APIURL + '/WebMOMAttachDelete', AItemArr).then(resem => { });
      }
    }
  };

  function AttachmentfetchPost(e) {
    var details = e.target.files[0];
    var details1 = "MeetAttachment";
    const formData = new FormData();
    formData.append('Document', details);
    formData.append('FolderName', details1);
    var AUrl = global.APIURL + '/uploadavatar/';
    axios.post(AUrl, formData,
      {
        headers: { "Content-Type": details.type },
      })
      .then((res) => {

        setAttachmentFile(res.data.FileName);
        setAttachmentFilePath(res.data.IName);

        if (e.target.files[0].name != null) {
          if (e.target.files[0].name != '') {
            setDisplayAttachFile(1);
          }
          else {
            setDisplayAttachFile(0);
          }
        }
      });
  }

  //let CoLeadId=localStorage.getItem("CorporateleadId");

  const getLeadDetailById = async LId => {
    var AUrl = global.APIURL + '/CorporateleadEdit/' + LId;
    axios.get(AUrl)
      .then(res => {
        if (res.data.length > 0) {
          console.log(res.data[0]);
          setCompanyId(res.data[0].CompanyId);
          setNetworkTypeId(res.data[0].ChannelTypeId);
          setAddress(res.data[0].Address);
          setStateId(res.data[0].StateId);
          getDistrictList(res.data[0].StateId);
          setCompanyDistrictId(res.data[0].DistrictId);
          getCompanyTehsilList(res.data[0].DistrictId);
          setCityId(res.data[0].CityId);
          setCompanyTehsilId(res.data[0].TehsilId);
          getCityList(res.data[0].TehsilId)
          setPincode(res.data[0].Pincode);
          setBranchCode(res.data[0].Branchcode);
          setcorEditLeadId(res.data[0].CorporateleadId);
          setmEmailId(res.data[0].EmailId);
          getEditDropdownChannelMasterList(res.data[0].ChannelTypeId);
          setLeadStatus(res.data[0].Status)
        }
      });
  };

  const getLeadContactList = async (LeadId) => {
    var AUrl = global.APIURL + '/CorporateleadContactDetailsEdit/' + LeadId;
    axios.get(AUrl)
      .then(LCdres => {
        let LeadContactList = LCdres.data.map((LeadContact) => {
          return LeadContact
        })
        setLeadContactList(LeadContactList);
      });
  }

  const ContacthandleAddRow = e => {

    var LRNo = LeadContactList.length == 0 ? 1 : (LeadContactList[LeadContactList.length - 1].RowNo + 1);
    var VName = document.getElementById('txtName').value;
    var VEmail = document.getElementById('txtEmail').value;
    var VDesignation = document.getElementById('txtDesignation').value;
    var VMobileNo = document.getElementById('txtMobilenumber').value;


    let CId = id.split(':');
    let VCId = CId[1];

    if (VName == "") {
      alert("Enter Name");
      return;
    }
    if (VEmail == "") {
      alert("Enter Email ");
      return;
    }
    if (regex.test(VEmail) === false) {
      alert("Enter Valid Email ");
      return;

    }
    if (VDesignation == "") {
      alert("Enter Designation");
      return;
    }
    if (VMobileNo == "") {
      alert("Enter Mobile No ");
      return;
    }
    if (VMobileNo.length < 10) {
      alert("Enter Valid Mobile No ");
      return;
    }

    const Citem = {
      RowNo: LRNo,
      CorporateleadContactId: 0,
      CorporateleadId: VCId,
      Name: VName,
      Email: VEmail,
      Designation: VDesignation,
      Mobilenumber: VMobileNo,
      TextBoxControlIdName: "txtName" + LRNo,
      TextBoxControlIdEmail: "txtEmail" + LRNo,
      TextBoxControlIdDesignation: "txtDesignation" + LRNo,
      TextBoxControlId: "txtMobilenumber" + LRNo,
    };
    setLeadContactList([...LeadContactList, Citem]);
    document.getElementById('txtName').value = '';
    document.getElementById('txtEmail').value = '';
    document.getElementById('txtDesignation').value = '';
    document.getElementById('txtMobilenumber').value = '';
  };

  const ContacthandleRemoveRow = (e, RowNo, CorporateleadContactId) => {

    if (LeadContactList.length > 1) {
      let AlertMessgae = "Are you sure you want to delete this record?";
      if (confirm(AlertMessgae) == true) {
        const RId = parseInt(RowNo == null ? '0' : RowNo == '' ? '0' : RowNo);
        setLeadContactList(LeadContactList.filter(item => item.RowNo !== RId));
        var VLeadContactId = parseInt(CorporateleadContactId == null ? '0' : CorporateleadContactId == '' ? '0' : CorporateleadContactId);
        if (VLeadContactId > 0) {
          const LeadContactItemArr = { Id: VLeadContactId }
          axios.post(global.APIURL + '/WebCorporateLeadContactDelete', LeadContactItemArr).then(resem => { });
        }
        alert('contact delete successfully');
        window.location.reload();
      }
    }
  };

  function getRandomNumber() {
    let min = 100000;
    let max = 999999;
    return Math.round(Math.random() * (max - min) + min);
  }


  function getpassword() {
    let min = 100000;
    let max = 999999;
    return Math.round(Math.random() * (max - min) + min);
  }


  const UpdateLeadsDeails = async () => {

    let LeadContactListArr = [];

    for (let con = 0; con < LeadContactList.length; con++) {
      const LeadContactDetail = LeadContactList[con];
      var VCorporateleadContactId = parseInt(LeadContactDetail.CorporateleadContactId == null ? '0' : LeadContactDetail.CorporateleadContactId == '' ? '0' : LeadContactDetail.CorporateleadContactId);
      var vTextBoxControlIdName = "#" + LeadContactDetail.TextBoxControlIdName;
      var vTextBoxControlIdEmail = "#" + LeadContactDetail.TextBoxControlIdEmail;
      var vTextBoxControlIdMobileNo = "#" + LeadContactDetail.TextBoxControlId;
      var vTextBoxControlIdDesignation = "#" + LeadContactDetail.TextBoxControlIdDesignation;
      let VName = $(vTextBoxControlIdName).val();
      let VEmail = $(vTextBoxControlIdEmail).val();
      let VMobileNo = $(vTextBoxControlIdMobileNo).val();
      let VDesignation = $(vTextBoxControlIdDesignation).val();

      let ICCIns = 0;
      if (VName != null) {
        if (VName != null) {
          ICCIns++;
        }
      }
      if (VEmail != null) {
        if (VEmail != null) {
          ICCIns++;
        }
      }
      if (VMobileNo != null) {
        if (VMobileNo != null) {
          ICCIns++;
        }
      }
      if (VDesignation != null) {
        if (VDesignation != null) {
          ICCIns++;
        }
      }
      if (ICCIns > 0) {
        const ContactItemArr = {
          RowId: VCorporateleadContactId,
          Name: VName,
          Email: VEmail,
          Designation: VDesignation,
          Mobilenumber: VMobileNo,
        };
        LeadContactListArr.push(ContactItemArr);
      }
    }

    let VName = document.getElementById("txtName").value;
    let VEmail = document.getElementById("txtEmail").value;
    let VMobileNo = document.getElementById("txtMobilenumber").value;
    let VDesignation = document.getElementById("txtDesignation").value;
    let ICCIns = 1;
    if (VName == "") {
      ICCIns = 0;
      //  alert("Enter Contact Name");

    }
    if (VEmail == "") {
      ICCIns = 0;
      // alert("Enter Contact Email");

    }
    if (VEmail != "") {
      if (regex.test(VEmail) === false) {
        alert("Enter Valid Email ");
        ICCIns = 0;
        return;
      }
    }

    if (ICCIns == 1) {
      const ContactItemArr = {
        RowId: 0,
        Name: VName,
        Email: VEmail,
        Designation: VDesignation,
        Mobilenumber: VMobileNo
      };
      LeadContactListArr.push(ContactItemArr);
    }
    let IsInsert = 1;
    let NetworkTypeId = parseInt($('#ddlNetworkType option:selected').val() == null ? '0' : $('#ddlNetworkType option:selected').val() == '' ? '0' : $('#ddlNetworkType option:selected').val());
    let NetworkSourceId = parseInt($('#ddlNetworkSource option:selected').val() == null ? '0' : $('#ddlNetworkSource option:selected').val() == '' ? '0' : $('#ddlNetworkSource option:selected').val());
    let NetworkSource = $('#ddlNetworkSource option:selected').text() == null ? '' : $('#ddlNetworkSource option:selected').text();
    let VEmailAddress = $('#txtNetworkSourceEmailAddress').val() == null ? '' : $('#txtNetworkSourceEmailAddress').val() == '' ? '' : $('#txtNetworkSourceEmailAddress').val();
    let VAddress = $('#txtNetworkSourceAddress').val() == null ? '' : $('#txtNetworkSourceAddress').val() == '' ? '' : $('#txtNetworkSourceAddress').val();
    let StateId = parseInt($('#ddlNetworkSourceState option:selected').val() == null ? '0' : $('#ddlNetworkSourceState option:selected').val() == '' ? '0' : $('#ddlNetworkSourceState option:selected').val());
    let DistrictId = parseInt($('#ddlNetworkSourceDistrict option:selected').val() == null ? '0' : $('#ddlNetworkSourceDistrict option:selected').val() == '' ? '0' : $('#ddlNetworkSourceDistrict option:selected').val());
    let TehsilId = parseInt($('#ddlNetworkSourceTehsilId option:selected').val() == null ? '0' : $('#ddlNetworkSourceTehsilId option:selected').val() == '' ? '0' : $('#ddlNetworkSourceTehsilId option:selected').val());
    let CityId = parseInt($('#ddlNetworkSourceCity option:selected').val() == null ? '0' : $('#ddlNetworkSourceCity option:selected').val() == '' ? '0' : $('#ddlNetworkSourceCity option:selected').val());
    let VPincode = $('#txtNetworkSourcePincode').val() == null ? '' : $('#txtNetworkSourcePincode').val();
    let VBranchcode = $('#txtNetworkSourceBarcode').val() == null ? '' : $('#txtNetworkSourceBarcode').val();
    if (NetworkTypeId == 0) {
      alert("Please select network type.");
      IsInsert = 0;
      return;
    }
    if (NetworkSourceId == 0) {
      alert("Please select network source.");
      IsInsert = 0;
      return;
    }
    if (VEmailAddress == "") {
      alert("Enter Email Address");
      IsInsert = 0;
      return;
    }
    else {
      if (regex.test(VEmailAddress) === false) {
        alert("Enter Valid Email ");
        IsInsert = 0;
        return;
      }
    }
    if (VAddress == "") {
      alert("Enter Address");
      IsInsert = 0;
      return;
    }
    if (StateId == 0) {
      alert("Please select state.");
      IsInsert = 0;
      return;
    }
    if (DistrictId == 0) {
      alert("Please select district.");
      IsInsert = 0;
      return;
    }
    if (TehsilId == 0) {
      alert("Please select tehsil.");
      IsInsert = 0;
      return;
    }
    if (CityId == 0) {
      alert("Please select city.");
      IsInsert = 0;
      return;
    }
    if (VPincode == "") {
      alert("Enter pincode");
      IsInsert = 0;
      return;
    }
    if (VBranchcode == "") {
      alert("Enter branch code");
      IsInsert = 0;
      return;
    }
    if (IsInsert == 1) {
      const LeadInquiry = {
        CorporateleadId: EditcorLeadId == "" ? CoLeadId : EditcorLeadId,
        ChannelTypeId: NetworkTypeId,
        CompanyId: NetworkSourceId,
        CompanyName: NetworkSource,
        EmailId: VEmailAddress,
        Address: VAddress,
        CountryId: "1",
        StateId: StateId,
        DistrictId: DistrictId,
        TehsilId: TehsilId,
        CityId: CityId,
        Pincode: VPincode,
        Branchcode: VBranchcode,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
        ContactPersonList: LeadContactListArr,
      };
      axios.post(global.APIURL + "/CorporateleadUpdate", LeadInquiry)
        .then(res => {
          alert(res.data.StatusMessage);
          history.push("/CorporateLeads");
        });
    }
  }

  const saveMeetDetails = async () => {

    let VMeetingPoint = document.getElementById("txtMeetingPoint").value;
    let VMeetingAgenda = document.getElementById("txtMeetingAgenda").value;
    let VAttachFile = AttachmentFile;
    let VAttachFilePath = AttachmentFilePath;

    if (MeetPointList.length == 0 && MeetAgendaList.length == 0 && AttachmentList.length == 0) {

      let IsDataInsert = 1;
      if (MeetNote == "") { IsDataInsert = 0; }
      else if (MeetSummary == "") { IsDataInsert = 0; }
      else if (MeetDate == "") { IsDataInsert = 0; }
      else if (MeetTime == "") { IsDataInsert = 0; }
      else if (NextMeetDate == "") { IsDataInsert = 0; }
      else if (VMeetingPoint == "") { IsDataInsert = 0; }
      else if (VMeetingAgenda == "") { IsDataInsert = 0; }
      else if (VAttachFile == "") { IsDataInsert = 0 }

      if (IsDataInsert == 1) {
        const MeetData = {
          Meetingheadnote: MeetNote,
          Meetingsummary: MeetSummary,
          MeetingDate: MeetDate,
          Meetingtime: MeetTime,
          Nextmeetingdate: NextMeetDate,
          "Flag": "A",
          CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
          CorporateleadId: Idset
        }
        axios.post(global.APIURL + '/WebCorporateLeadManageofmeetingAdd', MeetData).then(res => {


          var VMId = res.data[0].MId;

          for (let ccp = 0; ccp < LeadContactList.length; ccp++) {
            const CorporateContactPersonDetail = LeadContactList[ccp];
            var VChkId = CorporateContactPersonDetail.ChkId;

            var VCorporateleadId = CorporateContactPersonDetail.CorporateleadId;
            var VCorporateleadContactId = CorporateContactPersonDetail.CorporateleadContactId;
            var VIsChk = document.getElementById(VChkId).checked;

            if (VIsChk == true) {
              const ContactItemArr = {
                Id: 0,
                MId: VMId,
                CorporateId: VCorporateleadId == null ? '0' : VCorporateleadId == '' ? '0' : VCorporateleadId,
                ContactId: VCorporateleadContactId == null ? '0' : VCorporateleadContactId == '' ? '0' : VCorporateleadContactId,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              };
              axios.post(global.APIURL + '/MeetingContactInformationSave', ContactItemArr).then(res => { });
            }
          }

          if (VMeetingPoint != '') {
            const MeetPointItemArr = {
              MId: VMId,
              Point: VMeetingPoint,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: Idset
            };
            axios.post(global.APIURL + '/WebCorporateLeadMompointAdd', MeetPointItemArr).then(res => { });
          }

          if (VMeetingAgenda != '') {
            const MeetAgItemArr = {
              MId: VMId,
              Agenda: VMeetingAgenda,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: Idset
            };
            axios.post(global.APIURL + '/WebCorporateLeadMomagendaAdd', MeetAgItemArr).then(res => { });
          }

          if (VAttachFile != '') {
            const ALItemArr = {
              MId: VMId,
              Attachment: VAttachFilePath,
              mediafileName: VAttachFile,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: Idset
            };
            axios.post(global.APIURL + '/WebCorporateLeadMomAttachmentAdd', ALItemArr).then(res => { });
          }

          alert("Insert Successfull");
          history.push("/MOMList");

        });
      }
    }

    else {

      let IsDataInsert = 1;
      if (MeetNote == "") { IsDataInsert = 0; alert("Enter Meeting Note") }
      else if (MeetSummary == "") { IsDataInsert = 0; alert("Enter Meeting Summary") }
      else if (MeetDate == "") { IsDataInsert = 0; alert("Enter Meeting Meeting Date") }
      else if (MeetTime == "") { IsDataInsert = 0; alert("Enter Meeting Time") }
      else if (NextMeetDate == "") { IsDataInsert = 0; alert("Enter Next Meeting Date") }
      else if (VMeetingPoint == "") { IsDataInsert = 0; alert("Enter Meeting Point") }
      else if (VMeetingAgenda == "") { IsDataInsert = 0; alert("Enter Meeting Agenda") }
      else if (VAttachFile == "") { IsDataInsert = 0; alert("Select Attachment") }

      if (IsDataInsert == 1) {

        const MeetData = {
          Meetingheadnote: MeetNote,
          Meetingsummary: MeetSummary,
          MeetingDate: MeetDate,
          Meetingtime: MeetTime,
          Nextmeetingdate: NextMeetDate,
          "Flag": "A",
          CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
          CorporateleadId: Idset
        }
        axios.post(global.APIURL + '/WebCorporateLeadManageofmeetingAdd', MeetData).then(res => {
          var VMId = res.data[0].MId;

          if (VMeetingPoint != '') {
            const MeetItemArr = {
              MId: VMId,
              Point: VMeetingPoint,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: Idset
            };
            axios.post(global.APIURL + '/WebCorporateLeadMompointAdd', MeetItemArr).then(res => { });
          }

          if (VMeetingAgenda != '') {
            const MeetAgItemArr = {
              MId: VMId,
              Agenda: VMeetingAgenda,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: Idset
            };
            axios.post(global.APIURL + '/WebCorporateLeadMomagendaAdd', MeetAgItemArr).then(res => { });
          }

          if (VAttachFile != '') {
            const ALItemArr = {
              MId: VMId,
              Attachment: VAttachFilePath,
              mediafileName: VAttachFile,
              "Flag": "A",
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              CorporateleadId: Idset
            };
            axios.post(global.APIURL + '/WebCorporateLeadMomAttachmentAdd', ALItemArr).then(res => { });
          }

          for (let mpl = 0; mpl < MeetPointList.length; mpl++) {

            const MeetPointDetail = MeetPointList[mpl];
            var VMeetPointTextBoxControlId = MeetPointDetail.MeetPointTextBoxControlId;
            var VMeetPoint = document.getElementById(VMeetPointTextBoxControlId).value == null ? '' : document.getElementById(VMeetPointTextBoxControlId).value;

            if (VMeetPoint != '') {
              const MeetItemArr = {
                MId: VMId,
                Point: VMeetPoint,
                "Flag": "A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                CorporateleadId: Idset
              };
              axios.post(global.APIURL + '/WebCorporateLeadMompointAdd', MeetItemArr).then(res => { });
            }
          }

          for (let mal = 0; mal < MeetAgendaList.length; mal++) {
            const MeetAgendaDetail = MeetAgendaList[mal];

            var VMeetAgendaTextBoxControlId = MeetAgendaDetail.MeetAgendaTextBoxControlId;
            var VMeetAgenda = document.getElementById(VMeetAgendaTextBoxControlId).value == null ? '' : document.getElementById(VMeetAgendaTextBoxControlId).value;

            if (VMeetAgenda != '') {
              const MeetAgItemArr = {
                MId: VMId,
                Agenda: VMeetAgenda,
                "Flag": "A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                CorporateleadId: Idset
              };
              axios.post(global.APIURL + '/WebCorporateLeadMomagendaAdd', MeetAgItemArr).then(res => { });
            }
          }

          for (let al = 0; al < AttachmentList.length; al++) {
            const AttachmentListDetail = AttachmentList[al];

            var VAttachment = AttachmentListDetail.AttachFilePath;
            var VmediafileName = AttachmentListDetail.AttachFile;

            if (VAttachment != '') {
              const ALItemArr = {
                MId: VMId,
                Attachment: VAttachment,
                mediafileName: VmediafileName,
                "Flag": "A",
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
                CorporateleadId: Idset
              };

              axios.post(global.APIURL + '/WebCorporateLeadMomAttachmentAdd', ALItemArr).then(res => { });
            }
          }

          for (let ccp = 0; ccp < LeadContactList.length; ccp++) {


            const CorporateContactPersonDetail = LeadContactList[ccp];
            var VCorporateleadId = CorporateContactPersonDetail.CorporateleadId;
            var VCorporateleadContactId = CorporateContactPersonDetail.CorporateleadContactId;
            var VChkId = CorporateContactPersonDetail.ChkId;
            var VIsChk = document.getElementById(VChkId).checked;

            if (VIsChk == true) {

              const MeetItemContactArr = {
                Id: 0,
                MId: VMId,
                CorporateId: VCorporateleadId == null ? '0' : VCorporateleadId == '' ? '0' : VCorporateleadId,
                ContactId: VCorporateleadContactId == null ? '0' : VCorporateleadContactId == '' ? '0' : VCorporateleadContactId,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              };

              axios.post(global.APIURL + '/MeetingContactInformationSave', MeetItemContactArr).then(res => { });
            }
          }

        });
        alert("Insert Successfull");
        window.setTimeout(() => {
          history.push("/MOMList");
        }, 2000);
      }
    }
  }

  const LeadFollowUpSave = async () => {
    const LeadFollowUpArr =
    {
      CorporateleadId: EditcorLeadId,
      ScheduledDate: NextFollowupDate,
      ScheduledTime: NextFollowupTime,
      Mode: NextFollowupMode,
      Agenda: NextFollowupNote,
      Remark: '',
      Status: "P",
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    }
    axios.post(global.APIURL + "/corLeadFollowUpAdd", LeadFollowUpArr)
      .then(res => {
        if (res.data.length > 0) {
          alert(res.data[0].RecordStatus);
          history.push("/CorporateLeads");
        }
      });
  };

  const LeadConvertSave = async () => {
    const LeadConvertArr =
    {
      CorporateleadId: EditcorLeadId,
      ConvertDate: ConvertDate,
      Remark: ConvertRemark,
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    }
    axios.post(global.APIURL + "/corporateLeadConvertUpdate", LeadConvertArr)
      .then(res => {
        if (res.data.length > 0) {
          alert(res.data[0].RecordStatus);
          history.push("/CorConvertedList");
        }
      });
  };

  const LeadCloseSave = async () => {
    const LeadCloseArr =
    {
      CorporateleadId: EditcorLeadId,
      CloseDate: CloseDate,
      Remark: CloseRemark,
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    }
    axios.post(global.APIURL + "/corporateLeadCloseUpdate", LeadCloseArr)
      .then(res => {
        if (res.data.length > 0) {
          alert(res.data[0].RecordStatus);
          history.push("/CorCloselead");
        }
      });
  };

  const getCityList = async TId => {
    var AUrlC = global.APIURL + '/ActiveCity/' + TId;
    axios.get(AUrlC)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setCityList(CityL);
      });
  };

  const getLeadFollowupList = async (LId) => {
    var AUrl = global.APIURL + '/WebcorFollowupListByLeadId/' + LId;
    fetch(AUrl)
      .then((res) => res.json())
      .then((json) => {
        setLeadFollowupList(json)
      })
  }

  const inputHandler = e => {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">

        <div className="container-fluid">

          <Row className="mb-3">
            <Col className="col-lg-6 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Edit Corporate Lead</CardTitle>
                <a href="/GeneralDashboard" className="me-2">Home</a>{" "}
                <span>/</span>
                <a href="/CorporateLeads" className="me-2 ms-2">Corporate Lead</a>{" "}
                <span>/</span>
                <span className="ms-2">Edit Corporate Lead </span>
              </div>
            </Col>
            <Col className="col-lg-6 co-md-6 col-6">
              <div className="d-flex align-items-center justify-content-end ">

                <div>
                  <a className="btn btn-primary btn-sm float-end font-14" href="/CorporateLeads">
                    <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                  </a>

                </div>
              </div>
            </Col>
          </Row>
          <div className="ind-card" id="ind-card">
            <Row>
              <Col className="col-lg-9 col-md-12 col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-2">
                      <Col className="col-lg-12 col-md-12 col-12 mb-2">
                        <CardTitle>Corporate Lead</CardTitle>
                        <hr />
                      </Col>
                      {/* <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Company </p>
                        <select className="form-select" id="ddlNetworkSource" name="ddlNetworkSource" onChange={e => { setCompanyId(e.target.value) }}>
                          <option key="0" value="0">Select Company</option>
                          {ComapanyList.map(item => (
                            <option key={item.CompanyId} value={item.CompanyId} selected={CompanyId === item.CompanyId}>
                              {item.CompanyName}
                            </option>
                          ))}
                        </select>
                      </Col> */}

                      <Col className="col-lg-4 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Network Type </p>
                        <select className="form-select" Id="ddlNetworkType" name="ddlNetworkType" onChange={e => { setNetworkTypeId(e.target.value); getDropdownChannelMasterList(); }}>
                          <option value={"0"}>Select Network Type</option>
                          {NetworkTypeList.map(state => (
                            <option key={state.ChannelTypeId} value={state.ChannelTypeId} selected={NetworkTypeId === state.ChannelTypeId}>
                              {" "}
                              {state.ChannelTypeName}{" "}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-4 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Network Source </p>
                        <select className="form-select" Id="ddlNetworkSource" name="ddlNetworkSource" onChange={e => { setCompanyId(e.target.value) }}>
                          <option value={"0"}>Select Source</option>
                          {DropdownChannelList.map(item => (
                            <option key={item.ChannelMasterId} value={item.ChannelMasterId} selected={CompanyId === item.ChannelMasterId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-4 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> EmailId </p>
                        <Input
                          type="text"
                          placeholder="EmailId"
                          className="form-control"
                          id="txtNetworkSourceEmailAddress"
                          maxlength="50"
                          defaultValue={MEmailId}
                          onChange={e => setmEmailId(e.target.value)}
                        />
                      </Col>
                      <Col className="col-lg-12 col-md-12 col-12 mt-3 mt-md-3 mt-lg-0">
                        <p className="mt-3 mb-2 fontWeight500"> Address </p>
                        <div className="input-group auth-pass-inputgroup">
                          <textarea className="form-control" placeholder="Address" id="txtNetworkSourceAddress" rows="3" name="txtNetworkSourceAddress" defaultValue={Address} onChange={e => setAddress(e.target.value)}></textarea>
                        </div>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> State </p>
                        <select className="form-select" id="ddlNetworkSourceState" name="ddlNetworkSourceState" onChange={e => { getDistrictList(e.target.value); setStateId(e.target.value) }}>
                          <option key="0" value="0">Select State</option>
                          {StateList.map(item => (
                            <option key={item.StateId} value={item.StateId} selected={StateId === item.StateId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> District </p>
                        <select className="form-select" Id="ddlNetworkSourceDistrict" name="ddlNetworkSourceDistrict" onChange={e => { getCompanyTehsilList(e.target.value); setCompanyDistrictId(e.target.value) }}>
                          <option key="0" value="0">Select District</option>
                          {DistrictList.map(item => (
                            <option key={item.DistrictId} value={item.DistrictId} selected={CompanyDistrictId === item.DistrictId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label htmlFor="formFileSm" className="form-label">Tehsil</label>
                          </div>
                          <div>
                            {AddTehsil == "A" ? CompanyDistrictId > 0 ? <span className="float-end text-primary font-12"><a data-bs-toggle="modal" data-bs-target="#AddPerTehsil">+ Add New</a></span> : '' : ''}
                          </div>
                        </div>
                        <select className="form-select" id="ddlNetworkSourceTehsilId" name="ddlNetworkSourceTehsilId" onChange={e => { getCityList(e.target.value); setCompanyTehsilId(e.target.value) }}>
                          <option key="0" value="0">Select Tehsil</option>
                          {TehsilList.map(item => (
                            <option key={item.TehsilId} value={item.TehsilId} selected={CompanyTehsilId === item.TehsilId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label htmlFor="formFileSm" className="form-label">City/Village/Town</label>
                          </div>
                          <div>
                            {AddCity == "A" ? TehsilId > 0 ? <span className="float-end text-primary font-12"><a data-bs-toggle="modal" data-bs-target="#AddPerCity">+ Add New</a></span> : '' : ''}
                          </div>
                        </div>
                        <select className="form-select" id="ddlNetworkSourceCity" name="ddlNetworkSourceCity" onChange={e => setCityId(e.target.value)}>
                          <option key="0" value="0">Select City/Village/Town</option>
                          {CityList.map(item => (
                            <option key={item.CityId} value={item.CityId} selected={CityId === item.CityId}>
                              {item.Title}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> PinCode </p>
                        <Input type="number" className="" id="txtNetworkSourcePincode" defaultValue={Pincode} onChange={e => setPincode(e.target.value)} placeholder="Enter PinCode" onKeyPress={inputHandler} maxLength={6} />
                      </Col>
                      <Col className="col-lg-3 col-md-6 col-12 mt-3">
                        <p className="mb-2 fontWeight500"> Branch Code </p>
                        <Input type="text" className="" id="txtNetworkSourceBarcode" defaultValue={BranchCode} onChange={e => setBranchCode(e.target.value)} placeholder="Enter Branch Code" />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="">
                      <Col className="col-lg-12 mb-2">
                        <CardTitle>Contact Information</CardTitle>
                        <hr />
                      </Col>
                      <Col lg="12">
                        <table style={{ width: "100%" }}>
                          <tbody>
                            {LeadContactList.map((item, idx) => (
                              <tr id={"addr" + idx} key={idx}>
                                <td>
                                  <div data-repeater-list="group-a">
                                    <Row data-repeater-item className="align-items-end mb-3">
                                      <Col lg="3" className="">
                                        <Label htmlFor="name">
                                          Name
                                        </Label>
                                        <Input type="text" id={item.TextBoxControlIdName} className="form-control" placeholder="Enter Name" defaultValue={item.Name} />
                                      </Col>
                                      <Col lg="3" className="">
                                        <Label htmlFor="name">
                                          Email
                                        </Label>
                                        <Input type="text" id={item.TextBoxControlIdEmail} className="form-control" placeholder="Enter Email" defaultValue={item.Email} />
                                      </Col>
                                      <Col lg="3" className="">
                                        <Label htmlFor="name">
                                          Mobile No
                                        </Label>
                                        <Input type="text" id={item.TextBoxControlId} className="form-control" maxLength={10} placeholder="Enter Mobile No" defaultValue={item.Mobilenumber} />
                                      </Col>
                                      <Col lg="2" className="">
                                        <Label htmlFor="name">
                                          Designation
                                        </Label>
                                        <Input type="text" id={item.TextBoxControlIdDesignation} className="form-control" placeholder="Enter Designation" defaultValue={item.Designation} />
                                      </Col>


                                      <Col lg="1 mobile-mt">
                                        <Button
                                          onClick={e =>
                                            ContacthandleRemoveRow(e, item.RowNo, item.CorporateleadContactId)
                                          }
                                          color="danger"
                                          className="btn-sm btn-width mb-1 mt-md-3 mt-lg-0"
                                        >
                                          <i className="fas fa-trash-alt"></i>
                                        </Button>
                                      </Col>


                                    </Row>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div data-repeater-list="group-a">
                          <Row data-repeater-item className="align-items-end">
                            <Col lg="3" className="">
                              <Label htmlFor="name">
                                Name
                              </Label>
                              <Input type="text" id="txtName" className="form-control" placeholder="Enter Name" />
                            </Col>
                            <Col lg="3" className="">
                              <Label htmlFor="name">
                                Email
                              </Label>
                              <Input type="text" id="txtEmail" className="form-control" placeholder="Enter Email" />
                            </Col>
                            <Col lg="3" className="">
                              <Label htmlFor="name">
                                Mobile No
                              </Label>
                              <Input type="text" id="txtMobilenumber" className="form-control" onKeyPress={inputHandler} placeholder="Enter Mobile No" maxLength={10} />
                            </Col>
                            <Col lg="2" className="">
                              <Label htmlFor="name">
                                Designation
                              </Label>
                              <Input type="text" id="txtDesignation" className="form-control" placeholder="Enter Designation" />
                            </Col>

                            <Col lg="1" className="mt-3">
                              <Button onClick={ContacthandleAddRow} color="primary" className="btn-sm mb-1"><i className="fas fa-plus me-2"></i>Add</Button>
                            </Col>

                          </Row>
                        </div>
                      </Col>
                      <div className="co-md-12 mt-3"><a className="btn btn_success me-2" onClick={() => UpdateLeadsDeails()}> Save </a><a className="btn btn_danger me-2"> Cancel </a></div>

                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <div className="col-lg-3 col-md-12 col-12">
                <Card>
                  <CardBody>
                    <div className="mb-2">
                      <CardTitle>Lead Followup</CardTitle>
                      <hr />
                    </div>
                    <Row>
                      <div className="col-md-12">
                        <ul className="nav client-detail-firm border-0 nav-tabs" id="myTab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button className="nav-link border-0 active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"> <div className="form-check me-1 form-check-inline mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="radio1"
                                id="1"
                                value="success"
                                checked
                              />
                              <label className="form-check-label" htmlFor="1">
                                {" "}
                                Followup
                              </label>
                            </div></button>
                          </li>
                          {LeadStatus == "R" ? '' : LeadStatus == "L" ? '' : LeadStatus == "C" ? '' :
                            <li className="nav-item" role="presentation">
                              <button className="nav-link border-0" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"> <div className="form-check me-1 form-check-inline  mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radio1"
                                  id="2"
                                  value="success"
                                />
                                <label className="form-check-label" htmlFor="2">
                                  {" "}
                                  Convert
                                </label>
                              </div></button>
                            </li>
                          }
                          <li className="nav-item" role="presentation">
                            <button className="nav-link border-0" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">  <div className="form-check me-1 form-check-inline  mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="radio1"
                                id="3"
                                value="success"
                              />
                              <label className="form-check-label" htmlFor="3"> Close</label>
                            </div></button>
                          </li>
                        </ul>

                      </div>
                      <div className="col-md-12">

                        <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <Row>
                              <Col>
                                {" "}
                                <CardTitle className="mt-2">Set Followup</CardTitle>
                                <hr />
                              </Col>
                            </Row>
                            <Row>
                              <div className="col-md-12 mb-2">
                                <label className=""> Next Follow up Date </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder=""
                                  onChange={e => setNextFollowupDate(e.target.value)}
                                />
                              </div>

                              <div className="col-md-12 mb-2 mt-2">
                                <label className=""> Next Follow up Time </label>
                                <input
                                  type="time"
                                  className="form-control"
                                  placeholder=""
                                  onChange={e => setNextFollowupTime(e.target.value)}
                                />
                              </div>
                              <div className="col-md-12 mb-2 mt-2">
                                <label className=""> Next Follow up Mode </label>
                                <select className="form-select" aria-label="Default select example" onChange={e => setNextFollowupMode(e.target.value)}>
                                  <option selected>Select Mode</option>
                                  <option value="Call">Call</option>
                                  <option value="Email">Email</option>
                                  <option value="Meeting">Meeting</option>
                                </select>
                              </div>
                            </Row>
                            <Row>
                              <div className="mb-3 mt-2">
                                <label className="">
                                  {" "}
                                  Notes for Next Follow-up{" "}
                                </label>
                                <textarea
                                  className="form-control"
                                  placeholder="Enter Notes for Next Follow-up"
                                  onChange={e => setNextFollowupNote(e.target.value)}
                                >
                                  {" "}
                                </textarea>
                              </div>
                            </Row>
                            <Row>
                              <div className="">
                                <a onClick={LeadFollowUpSave} className="btn btn_success me-2">
                                  {" "}
                                  Submit{" "}
                                </a>
                                <a className="btn btn_danger me-2">
                                  {" "}
                                  Cancel{" "}
                                </a>
                              </div>
                            </Row>
                          </div>
                          <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <Row>
                              <Col>
                                {" "}
                                <CardTitle className="mt-2">Lead to Convert</CardTitle>
                                <hr />
                              </Col>
                            </Row>
                            <Row>
                              <div className="col-md-12 mb-2">
                                <label className=""> Convert Date </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder=""
                                  onChange={e => setConvertDate(e.target.value)}
                                />
                              </div>
                            </Row>
                            <Row>
                              <div className="mb-3 mt-2">
                                <label className="">
                                  {" "}
                                  Remarks{" "}
                                </label>
                                <textarea
                                  className="form-control"
                                  placeholder="Enter Notes for Convert"
                                  onChange={e => setConvertRemark(e.target.value)}
                                >
                                  {" "}
                                </textarea>
                              </div>
                            </Row>
                            <Row>
                              <div className="">
                                <a onClick={LeadConvertSave} className="btn btn_success me-2">
                                  {" "}
                                  Submit{" "}
                                </a>
                                <a className="btn btn_danger me-2">
                                  {" "}
                                  Cancel{" "}
                                </a>
                              </div>
                            </Row>
                          </div>
                          <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            <Row>
                              <Col>
                                {" "}
                                <CardTitle className="mt-2">Lead to Close</CardTitle>
                                <hr />
                              </Col>
                            </Row>
                            <Row>
                              <div className="col-md-12 mb-2">
                                <label className=""> Closure Date </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder=""
                                  onChange={e => setCloseDate(e.target.value)}
                                />
                              </div>
                            </Row>
                            <Row>
                              <div className="mb-3 mt-2">
                                <label className="">
                                  {" "}
                                  Reason For Closure{" "}
                                </label>
                                <textarea
                                  className="form-control"
                                  placeholder="Enter Reason For Closure"
                                  onChange={e => setCloseRemark(e.target.value)}
                                >
                                  {" "}
                                </textarea>
                              </div>
                            </Row>
                            <Row>
                              <div className="">
                                <a onClick={LeadCloseSave} className="btn btn_success me-2">
                                  {" "}
                                  Submit{" "}
                                </a>
                                <a className="btn btn_danger me-2">
                                  {" "}
                                  Cancel{" "}
                                </a>
                              </div>
                            </Row>
                          </div>
                        </div>

                      </div>
                    </Row>

                  </CardBody>
                </Card>
              </div>
              <div className="col-lg-12 col-md-12 col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-12 col-md-12 mb-2">
                        <CardTitle>Followup List</CardTitle>
                        <hr />
                      </Col>
                      <Col className="col-lg-12 col-md-12 mt-3">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th className="text-center" scope="col">#</th>
                              <th className="text-center" scope="col">Date</th>
                              <th className="text-center" scope="col">Time</th>
                              <th className="text-center" scope="col">Mode</th>
                              <th scope="col">Notes for Next Follow-up</th>
                              <th scope="col">Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {LeadFollowupList.map((record, index) => {
                              return (
                                <tr key={record.RowNo}>
                                  <td className="text-center">{record.RowNo}</td>
                                  <td className="text-center">{record.ScheduledDate}</td>
                                  <td className="text-center">{record.ScheduledTime}</td>
                                  <td className="text-center">{record.Mode}</td>
                                  <td>{record.Agenda}</td>
                                  <td>{record.Remark}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
              <div className="col-lg-12 col-md-12 col-12">
                <Card>
                  <CardBody>
                    <Row className="">
                      <Col className="col-lg-12 mb-2">
                        <CardTitle>Meeting Information</CardTitle>
                        <hr />
                      </Col>
                      <Row className="align-items-center">
                        {/* <div id="CorporateListdiv" >
                        <Col lg="6" className="mb-3 col-md-6">
                          <Label htmlFor="name">Corporate</Label>
                          <select className="form-select" Id="ddlCorporate" name="ddlCorporate" onChange={e => getCorporateContactPersonList(e.target.value)}>
                            <option value={"0"}>Select Corporate</option>
                            {CorporateList.map((Corporate) => (
                              <option key={Corporate.CorporateId} value={Corporate.CorporateId} selected={Corporate.CorporateId == MeetCorporateId}> {Corporate.CorporateName} </option>
                            ))}
                          </select>   </Col>
                      </div> */}
                      </Row>
                      <Row className="align-items-center">

                        <Label htmlFor="name">Corporate Contact Information</Label>

                        {LeadContactList.map((item, idx) => (

                          <Col lg="3" className="col-md-6" key={idx}>
                            <input className="form-check-input" type="checkbox" id={item.ChkId} value={item.CorporateleadContactId} />{" "}<label className="">{item.Name}</label>
                          </Col>

                        ))}

                      </Row>
                      <Row className="align-items-center">
                        <Col lg="6" className="mb-3 col-md-6">
                          <Label htmlFor="name">Meeting Headnote</Label>
                          <Input type="text" id="txtMeetNote" className="form-control" placeholder="Enter Meeting HeadNote" onChange={e => setMeetNote(e.target.value)} />
                        </Col>
                        <Col lg="6" className="mb-3 col-md-6">
                          <Label htmlFor="name">Meeting Summary</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Input type="text" id="txtMeetSummary" className="form-control" placeholder="Enter Meeting Summary" onChange={e => setMeetSummary(e.target.value)} /> </div>
                        </Col>
                      </Row>
                      <Row className="">
                        <Col lg="4">
                          <table style={{ width: "100%" }}>
                            <tbody>
                              {MeetPointList?.map((item, idx) => (
                                <tr id={"addr" + idx} key={idx}>
                                  <td>
                                    <div data-repeater-list="group-a mb-3">
                                      <Row data-repeater-item className="align-items-center mobile-mt">
                                        <Col lg="9" className="mb-3 col-md-6">
                                          <Label htmlFor="name">Meeting Points</Label>
                                          <Input type="text" id={item.MeetPointTextBoxControlId} className="form-control" placeholder="Enter Meeting Agenda" defaultValue={item.Point} />
                                        </Col>

                                        <Col lg="2" className="col-5 col-md-2">
                                          <Button color="danger" className="mt-2 btn-sm btn-width" onClick={e =>
                                            MeetPointhandleRemoveRow(e, item.RowId, item.MeetPointId)
                                          }>
                                            <i className="fas fa-trash-alt"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item className="align-items-center">
                              <Col lg="9" className="mb-3 col-md-6">
                                <Label htmlFor="name">Meeting Points</Label>
                                <Input type="text" id="txtMeetingPoint" className="form-control" placeholder="Enter Meeting Points" defaultValue={MeetPoint} />
                              </Col>
                              <Col lg="3">
                                <Button color="primary" className="mt-2 btn-sm" onClick={MeetPointhandleAddRow}>
                                  <i className="fas fa-plus me-2"></i>Add
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col lg="4">
                          <table style={{ width: "100%" }}>
                            <tbody>
                              {AttachmentList?.map((item, idx) => (
                                <tr id={"addr" + idx} key={idx}>
                                  <td>
                                    <div data-repeater-list="group-a mb-3">
                                      <Row data-repeater-item className="align-items-center mobile-mt">
                                        <Col lg="9" className="mb-3 col-md-6">
                                          <Label htmlFor="name">Attachment</Label>
                                          <span className="form-control form-control"><i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i><a href={item.AttachFilePath} target="_blank" rel="noopener noreferrer" >{item.AttachFile}</a></span>
                                          <input type="hidden" className="form-control form-control" id={item.UploadFileTextBoxControlId} defaultValue={item.AttachFilePath} />
                                        </Col>
                                        <Col lg="2" className="col-5 col-md-2">
                                          <Button color="danger" className="mt-2 btn-sm btn-width" onClick={e =>
                                            AttachmenthandleRemoveRow(e, item.RowId, item.AttachId)
                                          }>
                                            <i className="fas fa-trash-alt"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item className="align-items-center">
                              <Col lg="9" className="mb-3 col-md-6">
                                <Label htmlFor="name">Attachment</Label>

                                {DisplayAttachFile > 0 ?
                                  <span className="float-end text-primary font-12" id="spandoctitle">
                                    <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                                    <a href={AttachmentFilePath} target="_blank" rel="noopener noreferrer" >{AttachmentFile}</a></span> : ''}
                                <input type="file" className="form-control form-control" id="AttachfileId" onChange={AttachmentfetchPost} />

                              </Col>

                              <Col lg="3">
                                <Button color="primary" className="mt-2 btn-sm" onClick={AttachmenthandleAddRow}>
                                  <i className="fas fa-plus me-2"></i>Add
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col lg="4">
                          <table style={{ width: "100%" }}>
                            <tbody>
                              {MeetAgendaList?.map((item, idx) => (
                                <tr id={"addr" + idx} key={idx}>
                                  <td>
                                    <div data-repeater-list="group-a mb-3">
                                      <Row data-repeater-item className="align-items-center mobile-mt">
                                        <Col lg="9" className="mb-3 col-md-6">
                                          <Label htmlFor="name">Next Meeting Agenda</Label>
                                          <Input type="text" id={item.MeetAgendaTextBoxControlId} className="form-control" placeholder="Enter Meeting Agenda" defaultValue={item.Agenda} />
                                        </Col>

                                        <Col lg="2" className="col-5 col-md-2">
                                          <Button color="danger" className="mt-2 btn-sm btn-width" onClick={e =>
                                            MeetAgendahandleRemoveRow(e, item.RowId, item.MeetAgendaId)}>
                                            <i className="fas fa-trash-alt"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div data-repeater-list="group-a">
                            <Row data-repeater-item className="align-items-center">
                              <Col lg="9" className="mb-3 col-md-6">
                                <Label htmlFor="name">Next Meeting Agenda</Label>
                                <Input type="text" id="txtMeetingAgenda" className="form-control" placeholder="Enter Meeting Agenda" />
                              </Col>
                              <Col lg="3">
                                <Button color="primary" className="mt-2 btn-sm" onClick={MeetAgendahandleAddRow}>
                                  <i className="fas fa-plus me-2"></i>Add
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col lg="4" className="mb-3 col-md-6">
                          <Label htmlFor="name">Meeting Date</Label>
                          <Input type="date" id="txtMeetDate" className="form-control" placeholder="Enter Meeting Date" onChange={e => setMeetDate(e.target.value)} min={new Date().toISOString().split('T')[0]} />
                        </Col>
                        <Col lg="4" className="mb-3 col-md-6">
                          <Label htmlFor="name">Meeting Time</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <input id="txtMeetTime" type="time" className="form-control" placeholder="Enter Meeting Time" onChange={e => setMeetTime(e.target.value)} />
                          </div>
                        </Col>
                        <Col lg="4" className="mb-3 col-md-6">
                          <Label htmlFor="name">Next Meeting Date</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <input id="txtNextMeetDate" type="date" className="form-control" placeholder="Enter Next Meeting Date" min={new Date().toISOString().split('T')[0]} onChange={e => setNextMeetDate(e.target.value)} />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <div className="col-lg-12">
                          <button
                            type="button"
                            className="btn btn_success me-2"
                            onClick={e => saveMeetDetails()}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn btn_danger"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </Row>
                    </Row>
                  </CardBody>
                </Card>
              </div>
              <div className="col-lg-12 col-md-12 col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-lg-12 col-md-12 mb-2">
                        <CardTitle>Meeting Details List</CardTitle>
                        <hr />
                      </Col>
                      <Col className="col-lg-12 col-md-12 mt-3">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th className="text-center" scope="col">#</th>
                              <th className="text-center" scope="col">Meeting headnote</th>
                              <th className="text-center" scope="col">Meeting summary</th>
                              <th className="text-center" scope="col">Meeting Date</th>
                              <th className="text-center" scope="col">Meeting Time</th>
                              <th className="text-center" scope="col">Next Meeting date</th>
                              <th className="text-center" scope="col">Point</th>
                              <th className="text-center" scope="col">Agenda</th>
                              <th className="text-center" scope="col">Attachment</th>

                            </tr>
                          </thead>
                          <tbody>
                            {MeetDetails.map((record, index) => {
                              return (
                                <tr key={record.RowNo}>
                                  <td className="text-center">{record.Srno}</td>
                                  <td className="text-center">{record.Meetingheadnote}</td>
                                  <td className="text-center">{record.Meetingsummary}</td>
                                  <td className="text-center">{record.MeetingDate}</td>
                                  <td className="text-center">{record.Meetingtime}</td>
                                  <td className="text-center">{record.Nextmeetingdate}</td>
                                  <td className="text-center">{record.Point}</td>
                                  <td className="text-center">{record.Agenda}</td>
                                  <td className="text-center">{record.MediaFileName}</td>
                                  {/* <td className="text-center position-relative"><p className="mb-0" >{record.Point}</p>
                                  <small className="text-primary dropdown-toggle " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">View More</small>

                                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            
                                    {pointde.map((re, index) => { return (<li  className="dropdown-item" key={re.mpoint}>{re.Point}</li>) })}
                                  </ul>

                                </td>
                                <td className="text-center position-relative"><p className="mb-0" >{record.Agenda}</p>
                                  <small className="text-primary dropdown-toggle " id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">View More</small>

                                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                   
                                    {agendade.map((re, index) => { return (<li  className="dropdown-item" key={re.MAId}>{re.Agenda}</li>) })}
                                  </ul>

                                </td>

                                <td><span className="float-end text-primary font-12" id="spandoctitle">
                                  <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                                  <a href={record.Attachment} target="_blank" rel="noopener noreferrer" >{record.MediaFileName}</a></span></td>
                                 */}
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>



            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Editcorleads;
