import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import jsPDF from "jspdf";
import 'jspdf-autotable';

import LinkApp from 'App';


const OnSort = () => {
  let columns = [
    {
      d: "#",
      text: "#",
      cell: (records, index) => {
        let RowIndex = index + 1;
        return (
          <Fragment>
            {RowIndex}
          </Fragment>
        );
      }
    },
    {
      d: "ChannelName",
      text: "Channel & Channel Partner",
      cell: (records, index) => {

        let VChannelName = records["ChannelName"];
        let VChannelPartner = records["ChannelPartner"];


        return (
          <Fragment>
            <p className="mb-1 m-with">{VChannelName}</p>
            <p className="mb-1 m-with">{VChannelPartner}</p>
          </Fragment>
        );
      }
    },
    {
      d: "VisitDate",
      text: "Visit Date & Time",
      cell: (records, index) => {
        let VisitDate = records["VisitDate"];
        let VisitFromTime = records["VisitFromTime"];
        let VisitToTime = records["VisitToTime"];
        return (
          <Fragment>
            <p className="mb-1 m-with-visit-date">{VisitDate}</p>
            <p className="mb-1">{VisitFromTime}</p>
            <p className="mb-1">{VisitToTime}</p>
          </Fragment>
        );
      }
    },
    {
      d: "Visit Person Detail",
      text: "Visit Person Detail",
      cell: (records, index) => {
        let VisitPersonName = records["VisitPersonName"];
        let VisitPersonContact = records["VisitPersonContact"];
        let VisitPersonEmail = records["VisitPersonEmail"];

        return (
          <Fragment>
            <p className="mb-1">{VisitPersonName}</p>
            <p className="mb-1">{VisitPersonEmail}</p>
            <p className="mb-1">{VisitPersonContact}</p>
          </Fragment>
        );
      }
    },
    {
      d: "VisitPlaceLocation",
      text: "Place Location",
      cell: (records, index) => {
        let VisitPlaceLocation = records["VisitPlaceLocation"];

        return (
          <Fragment>
            <p className="mb-1">{VisitPlaceLocation}</p>
          </Fragment>
        );
      }
    },
    {
      d: "VisitAgenda",
      text: "Agenda",
      cell: (records, index) => {
        let VisitAgenda = records["VisitAgenda"];

        return (
          <Fragment>
            <a className="visitagenda" data-bs-toggle="modal" data-bs-target="#ModalVisitAgenda" onClick={getUserVisitAgenda.bind(this, records)}><i className="fas fa-eye ms-2 fasfaeye"></i> View</a>
          </Fragment>
        );
      }
    },
    {
      d: "VisitRemark",
      text: "Remark",
      cell: (records, index) => {
        //let SVisitRemark = records["VisitRemark"];
        return (
          <Fragment>
            <a className="visitagenda" data-bs-toggle="modal" data-bs-target="#ModalVisitRemark" onClick={getUserVisitRemark.bind(this, records)}><i className="fas fa-eye ms-2 fasfaeye"></i> View</a>
          </Fragment>
        );
      }
    },
    {
      d: "Upload Files",
      text: "Upload Files",
      cell: (records, index) => {
        //let SVisitRemark = records["VisitRemark"];
        return (
          <Fragment>
            <a className="visitagenda" data-bs-toggle="modal" data-bs-target="#exampleModalVisitImages" onClick={getUserVisitImages.bind(this, records)}><i className="fas fa-eye ms-2 fasfaeye"></i> View</a>
          </Fragment>
        );
      }
    }


  ];
  let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
    button: {
      excel: true,
      print: true,
      csv: true
    }
  }
 

  const [ChannelSourceList, setChannelSourceList] = useState([]);
  const [ChannelSubSourceList, setChannelSubSourceList] = useState([]);
  const [ChannelId, setChannelId] = useState(0);
  const [ChannelSubSourceId, setChannelSubSourceId] = useState(0);
  const [UserList, setUserList] = useState([]);
  const [UserId, setUserId] = useState([]);
  const [records, setRecords] = useState([]);
  const [UserVisitImages, setUserVisitImages] = useState([]);
  const [VisitAgenda, setVisitAgenda] = useState('');
  const [VisitRemark, setVisitRemark] = useState('');

  const [VisitPhotos1, setVisitPhotos1] = useState('');
  const [VisitPhotos2, setVisitPhotos2] = useState('');
  const [VisitPhotos3, setVisitPhotos3] = useState('');
  const [VisitPhotos4, setVisitPhotos4] = useState('');
  const [VisitPhotos5, setVisitPhotos5] = useState('');

  const [VisitPhotos1FileName, setVisitPhotos1FileName] = useState('');
  const [VisitPhotos2FileName, setVisitPhotos2FileName] = useState('');
  const [VisitPhotos3FileName, setVisitPhotos3FileName] = useState('');
  const [VisitPhotos4FileName, setVisitPhotos4FileName] = useState('');
  const [VisitPhotos5FileName, setVisitPhotos5FileName] = useState('');


  let history = useHistory();

  const getChannelSourceList = async () => {
    var AUrl = global.APIURL + '/ChannelSourceList';
    axios.post(AUrl).then(res => {
      setChannelSourceList(res.data);
    })
  }

  const getChannelSubSourceList = async (VChannelId) => {

    var AUrl = global.APIURL + '/ChannelSubSourceList';
    var ChannelData = {
      ChannelId: VChannelId
    }
    axios.post(AUrl, ChannelData).then(res => {
      setChannelSubSourceList(res.data);
    })
  }

  useEffect(() => {
    getChannelSourceList();
    getActiveUserList();
    getUserVisitFilter();


  }, []);

  const getUserVisitFilter = async () => {

    let StartDate = document.getElementById("txtStartDate").value;
    let txtEndDate = document.getElementById("txtEndDate").value;

    const filterVal = {
      UserId: UserId == "" ? "0" : UserId,
      LoginUserId: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
      ChannelSourceId: ChannelId == "" ? "0" : ChannelId,
      ChannelSubSourceId: ChannelSubSourceId == "" ? "0" : ChannelSubSourceId,
      FromDate: StartDate == "" ? "" : StartDate,
      ToDate: txtEndDate == "" ? "" : txtEndDate
    }

    axios.post(global.APIURL + "/UserVisitFilter", filterVal)
      .then(CLres => {
        let VWebLeadList = CLres.data.map(vcl => {
          return vcl;
        });
        setRecords(VWebLeadList);
      });
  }

  const getActiveUserList = async (e) => {

    await axios.get(global.APIURL + '/ActiveUserList')
      .then((res) => {
        setUserList(res.data)
      });
  }

  const getUserVisitImages = async (record) => {
    setVisitPhotos1('');
    setVisitPhotos2('');
    setVisitPhotos3('');
    setVisitPhotos4('');
    setVisitPhotos5('');

    setVisitPhotos1FileName('');
    setVisitPhotos2FileName('');
    setVisitPhotos3FileName('');
    setVisitPhotos4FileName('');
    setVisitPhotos5FileName('');

    let VVisitPhotos1 = record["VisitPhotos1"];
    let VVisitPhotos2 = record["VisitPhotos2"];
    let VVisitPhotos3 = record["VisitPhotos3"];
    let VVisitPhotos4 = record["VisitPhotos4"];
    let VVisitPhotos5 = record["VisitPhotos5"];

    let VVisitPhotos1FileName = record["VisitPhotos1FileName"];
    let VVisitPhotos2FileName = record["VisitPhotos2FileName"];
    let VVisitPhotos3FileName = record["VisitPhotos3FileName"];
    let VVisitPhotos4FileName = record["VisitPhotos4FileName"];
    let VVisitPhotos5FileName = record["VisitPhotos5FileName"];

    setVisitPhotos1(VVisitPhotos1);
    setVisitPhotos2(VVisitPhotos2);
    setVisitPhotos3(VVisitPhotos3);
    setVisitPhotos4(VVisitPhotos4);
    setVisitPhotos5(VVisitPhotos5);

    setVisitPhotos1FileName(VVisitPhotos1FileName);
    setVisitPhotos2FileName(VVisitPhotos2FileName);
    setVisitPhotos3FileName(VVisitPhotos3FileName);
    setVisitPhotos4FileName(VVisitPhotos4FileName);
    setVisitPhotos5FileName(VVisitPhotos5FileName);

    // const VisitImagedata = {
    //   UserVisitLogId: UserVisitId
    // }

    // axios.post(global.APIURL + '/UserVisitHistoryImages', VisitImagedata)
    //   .then(res => {
    //     setUserVisitImages(res.data);
    //   });

  }

  const getUserVisitAgenda = async (VisitAgenda) => {
    setVisitAgenda('');
    let VVisitAgenda = VisitAgenda["VisitAgenda"];
    setVisitAgenda(VVisitAgenda);
  }

  const getUserVisitRemark = (record) => {
    setVisitRemark('');
    let VVisitRemark = record["VisitRemark"]
    setVisitRemark(VVisitRemark);
  }

  const onSort = (column, FilterUsers, sortOrder) => {
    return orderBy(FilterUsers, [column], [sortOrder]);
  }
  
  const exportPDF=()=>{

    const unit = "pt";
    const size = "A2"; 
    const orientation = "portrait"; 

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "User Visit Entry List Report";
    const headers = [["#","Source","Sub-Source","Visit Date","From Time","To Time","Name","Contact","Email","Location","Agenda","Remark"]];

    const data = records.map((item,index)=> [index+1,item.ChannelName,item.ChannelPartner,item.VisitDate,item.VisitFromTime,item.VisitToTime,item.VisitPersonName,item.VisitPersonContact,item.VisitPersonEmail,item.VisitPlaceLocation,item.VisitAgenda,item.VisitRemark]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("UserVisitEntryListReport.pdf")
  }


  return (
    <div className="page-content">
      <MetaTags>
        <title>User Visit Entry | KC Admin Group</title>
      </MetaTags>
      <div className="modal fade visitImage" id="exampleModalVisitImages" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">View Images</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form>
              <div className="modal-body">
                <Row>

                  {VisitPhotos1 != '' ?
                    <Col lg={12}>
                      <div className="mb-2 vewsourcecode">
                        <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                        <a href={VisitPhotos1} target="_blank" rel="noreferrer" className="fadocumenttitleiconcolor" >{VisitPhotos1FileName}</a>
                      </div>
                    </Col>
                    : ''}
                  {VisitPhotos2 != '' ?
                    <Col lg={12}>
                      <div className="mb-2 vewsourcecode">
                        <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                        <a href={VisitPhotos2} target="_blank" rel="noreferrer" className="fadocumenttitleiconcolor" >{VisitPhotos2FileName}</a>
                      </div>
                    </Col>
                    : ''}
                  {VisitPhotos3 != '' ?
                    <Col lg={12}>
                      <div className="mb-2 vewsourcecode">
                        <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                        <a href={VisitPhotos3} target="_blank" rel="noreferrer" className="fadocumenttitleiconcolor" >{VisitPhotos3FileName}</a>
                      </div>
                    </Col>
                    : ''}
                  {VisitPhotos4 != '' ?
                    <Col lg={12}>
                      <div className="mb-2 vewsourcecode">
                        <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                        <a href={VisitPhotos4} target="_blank" rel="noreferrer" className="fadocumenttitleiconcolor" >{VisitPhotos4FileName}</a>
                      </div>
                    </Col>
                    : ''}
                  {VisitPhotos5 != '' ?
                    <Col lg={12}>
                      <div className="mb-2 vewsourcecode">
                        <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                        <a href={VisitPhotos5} target="_blank" rel="noreferrer" className="fadocumenttitleiconcolor" >{VisitPhotos5FileName}</a>
                      </div>
                    </Col>
                    : ''}
                </Row>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="modal fade visitImage" id="ModalVisitAgenda" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Visit Agenda</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form>
              <div className="modal-body">
                <Row>
                  <Col lg={12}>
                    {VisitAgenda}
                  </Col>
                </Row>
              </div>

            </form>



          </div>
        </div>
      </div>

      <div className="modal fade visitImage" id="ModalVisitRemark" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Visit Remarks</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form>
              <div className="modal-body">
                <Row>
                  <Col lg={12}>
                    {VisitRemark}
                  </Col>
                </Row>
              </div>

            </form>



          </div>
        </div>
      </div>


      <div className="container-fluid">
        <Row className="mb-3">
          <Col className="col-lg-6 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">User Visit Entry</CardTitle>
              <a href="/GeneralDashboard" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <span className="ms-2">UserVisitEntry</span>
            </div>
          </Col>
          <Col className="col-lg-6 co-md-6 col-6">
            <div className="d-flex align-items-center justify-content-end ">

              <div>
                <a className="btn btn-primary btn-sm float-end font-14" href="/GeneralDashboard">
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
                <a className="btn btn-primary btn-sm font-14 me-2 " data-bs-toggle="collapse" href="#collapseExampleUserVisit" role="button" aria-expanded="false" aria-controls="collapseExample">
                  <i className="fas fa-filter"></i>
                </a>
                <a className="btn btn-primary btn-sm float-end font-14 me-2" href="/UserVisitEntry/AddUserVisitEntry">
                  <i className="mdi mdi-plus align-middle me-1"></i>Add new
                </a>
                <ReactHTMLTableToExcel
              className="btn btn-primary btn-sm float-end font-14 me-2"
              table="emp"
              filename="UserVisitReport"
              sheet="Sheet"
              buttonText="Export Excel" />
               <button className="btn btn-primary btn-sm float-end font-14 me-2" onClick={exportPDF}>Export PDF</button>
              </div>
            </div>
          </Col>

        </Row>
        <Col className="col-12">

          <Row>
            <Col xl="12">
              <div className="collapse" id="collapseExampleUserVisit">
                <div className="card card-body">
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label className="form-label">User</label>
                      <select className="form-select" Id="ddlUserlist" name="ddlUserlist" onChange={(e) => setUserId(e.target.value)}>
                        <option value={"0"}>Select User List</option>
                        {UserList.map((items) => (
                          <option key={items.UserId} value={items.UserId}> {items.Name} </option>
                        ))}
                      </select>

                    </div>
                    <div className="col-md-3">
                      <label className="form-label">Channel Source</label>
                      <select className="form-select" Id="ddlChannelSource" name="ddlChannelSource" onChange={e => { getChannelSubSourceList(e.target.value); setChannelId(e.target.value) }} >
                        <option value={"0"}>Select Channel Source List</option>
                        {ChannelSourceList.map((ChannelMaster) => (
                          <option key={ChannelMaster.ChannelId} value={ChannelMaster.ChannelId}> {ChannelMaster.ChannelName} </option>
                        ))}
                      </select>

                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label className="form-label">Channel Sub Source</label>
                        <select className="form-select" Id="ddlChannelSubSource" name="ddlChannelSubSource" onChange={(e) => setChannelSubSourceId(e.target.value)}>
                          <option value={"0"}>Select Channel Source List</option>
                          {ChannelSubSourceList.map((ChannelMaster) => (
                            <option key={ChannelMaster.ChannelPartnerId} value={ChannelMaster.ChannelPartnerId}> {ChannelMaster.Name} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label">Start Date </label>
                        <input type="date" className="form-control" id="txtStartDate" placeholder="name@example.com" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="exampleFormControlInput1" className="form-label">End Date </label>
                        <input type="date" className="form-control" id="txtEndDate" placeholder="name@example.com" />
                      </div>
                    </div>
                    <div className="col-md-12 mt-3 mb-2">
                      <button type="button" className="btn btn-sm btn-success font-14" onClick={getUserVisitFilter}>Go</button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

        </Col>
        <Row>
          <div>
            <table id="emp" className="table" hidden="hidden">
              <thead>
                <tr>
                <th>#</th>
                  <th>Source</th>
                  <th>Sub-Source</th>               
                  <th>Visit Date</th>
                  <th> From Time</th>
                  <th> To Time</th>
                  <th> Name</th>
                  <th> Contact</th>
                  <th> Email</th>
                  <th> Location</th>
                  <th> Agenda</th>
                  <th> Remark</th>                 
                </tr>
              </thead>
              <tbody>              {
                records.map((item, index) => {
                  return <tr key={index}>
                    <td> {index +1} </td>
                    <td >{item.ChannelName}</td>
                    <td>{item.ChannelPartner}</td>                                     
                    <td>{item.VisitDate}</td>
                    <td>{item.VisitFromTime}</td>
                    <td >{item.VisitToTime}</td>
                    <td>{item.VisitPersonName}</td>
                    <td >{item.VisitPersonContact}</td>
                    <td>{item.VisitPersonEmail}</td>
                    <td >{item.VisitPlaceLocation}</td>
                    <td>{item.VisitAgenda}</td>
                    <td>{item.VisitRemark}</td>
                    
                  </tr>
                })
              }
              </tbody>
            </table>           
          </div>
        </Row>

        <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort} />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default OnSort;