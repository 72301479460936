import React, { Component } from "react"
import MetaTags from 'react-meta-tags';

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
  Tooltip,
  Input,
} from "reactstrap"

import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class UiGeneral extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // Popover
      popovertop1: false,
      popoverleft: false,
      popoverright: false,
      popoverbottom: false,
    }
    this.toggletop1 = this.toggletop1.bind(this)
    this.toggleright = this.toggleright.bind(this)
    this.toggleleft = this.toggleleft.bind(this)
    this.togglebottom = this.togglebottom.bind(this)
    this.toggledismiss = this.toggledismiss.bind(this)
    this.toggledismissclose = this.toggledismissclose.bind(this)
  }

  toggletop1() {
    this.setState({ popovertop1: !this.state.popovertop1 })
  }

  toggleleft() {
    this.setState({ popoverleft: !this.state.popoverleft })
  }

  toggleright() {
    this.setState({ popoverright: !this.state.popoverright })
  }

  togglebottom() {
    this.setState({ popoverbottom: !this.state.popoverbottom })
  }

  toggledismiss() {
    this.setState({ popoverdismiss: !this.state.popoverdismiss })
  }

  toggledismissclose() {
    this.setState({ popoverdismiss: false })
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Designation | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
            
          
              {/* <Breadcrumbs title="Setting" breadcrumbItem="Country Name" /> */}
              <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h4">Add To Do’s </CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Add To Do’s</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
            <Row>
              
              <Col className="col-12">
                <Card>
                  <CardBody>
                     {/* User list */}
                    

                     

                      <Row className="">
                      <Col className="col-lg-12">
                      <label className="form-label">Add Details</label>
                      <textarea className="form-control" placeholder="Add To Do Details" Row="10" ></textarea>
                      </Col>

                      <Col className="col-4 mt-4">
                      <label className="form-label">File Upload</label>
                      <input className="form-control" type="file" id="formFile" />
                      </Col>


                    </Row>
                    
                    <Row className="mt-3">           
                      <Col className="col-xl-6 col-12">
                        <div className="assign_task_box">
                          <span className=" d-inline-block"> Assign Task To : </span>
                          <span className="assignee_task d-inline-block "> Viral Patel
                            <a href="">  <i className="fas fa-times  text-black ms-2"></i></a> </span>
                            <span className="assignee_task d-inline-block"> Viral Patel
                            <a href="">  <i className="fas fa-times  text-black ms-2"></i></a> </span>
                            <span className="assignee_task d-inline-block "> Viral Patel
                           <a href="">  <i className="fas fa-times text-black ms-2"></i></a> </span>
                        </div>
                      </Col>                      
                    </Row>
                    
                    <Row className="mt-3">           
                     
                      <Col className="col-6">
                      <label className="form-label">Due Date</label>
                      <input type="date" className="form-control" id="formControlInput" placeholder="Date" />
                      </Col>
                      <Col className="col-6">
                      <label className="form-label">Due Time</label>
                      <input type="time" className="form-control" id="formControlInput" placeholder="Time" />
                      </Col>
                      </Row>
                    
                    <Row className="mt-3">
                      <div className="d-flex">
                      <a className="btn btn-primary btn-sm d-inline-block" href="#">Save</a>
                        <a className="btn btn-primary btn-sm ms-2 d-inline-block" href="#">Clear</a>
                      </div>   
                    </Row>
                  
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default UiGeneral
