import React, { Component, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const AddTestimonial = () => {

    const history = useHistory();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [TestimonyMsg, setTestimonyMsg] = useState("");
    const [TestimonyFilePath, setTestimonyFilePath] = useState("");
    const [TestimonyFileName, setTestimonyFileName] = useState("");
    const [TestimonyFileDisplay, setTestimonyFileDisplay] = useState(0);


    const onEditorStateChange = editorState => {
        setEditorState(editorState)
        const convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setTestimonyMsg(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }


    const saveTestimonyDetails = async (e) => {
        e.preventDefault();
        let VTestimonyName = e.target["txtTestimonyName"].value;
        let VTestimonyDesignation = e.target["txtTestimonyDesignation"].value;

        if (VTestimonyName == "") {
            alert("Enter Testimony Name");
            return;
        }
        if (VTestimonyDesignation == "") {
            alert("Enter Testimony Designation");
            return;
        }
        if (TestimonyMsg == "") {
            alert("Enter Testimony Message");
            return;
        }


        if (VTestimonyName != '') {
            const AData = {
                TestimonialName: VTestimonyName,
                TestimonialDesignation: VTestimonyDesignation,
                TestimoniaPersonImage: TestimonyFilePath,
                TestimonialMessage: TestimonyMsg,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            }

            axios.post(global.APIURL + '/TestimonialSave', AData)
                .then(res => {
                    alert(res.data[0].RecordStatus);
                    history.push('/Testimonial');
                });
        }
    }

    const getTestimonyFile = (e) => {
        var details = e.target.files[0];
        var details1 = "Testimonial";
        const formData = new FormData();
        formData.append('Document', details);
        formData.append('FolderName', details1);


        axios.post(global.APIURL + '/uploadavatar', formData, {

            headers: { "Content-Type": details.type },

        }).then((res) => {
            setTestimonyFilePath(res.data.IName);
            setTestimonyFileName(res.data.FileName);
            if (e.target.files[0].name != null) {
                if (e.target.files[0].name != '') {
                    setTestimonyFileDisplay(1);
                }
                else {
                    setTestimonyFileDisplay(0);
                }
            }
        });
    }

    return (
        <div className="page-content">

            <MetaTags>
                <title>Testimonial | KC Admin Group</title>
            </MetaTags>

            <div className="container-fluid">

                <Row className="mb-3">
                    <Col className="col-lg-8 col-md-6 col-12 mb-2">
                        <div className="breadcrum ps-0">
                            <CardTitle className="h4">Add Testimonial</CardTitle>
                            <a href="/GeneralDashboard" className="me-2">Home</a>
                            <span>/</span>
                            <a href="/Testimonial" className="ms-2 me-2">Testimonial</a>
                            <span>/</span>
                            <span className="ms-2">Add Testimonial</span>
                        </div>
                    </Col>
                    <Col className="col-lg-4 co-md-6 col-12">
                        <a className="btn btn-primary btn-sm float-end font-14" href="/Testimonial">
                            <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                        </a>
                    </Col>
                </Row>
                <form onSubmit={saveTestimonyDetails}>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={4}>
                                            <div className="mb-2">
                                                <label className="form-label">Testimony Name</label>
                                                <Input type="text" className="form-control" id="txtTestimonyName" placeholder="Enter Testimony Name" />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-2">
                                                <label className="form-label">Testimony Designation</label>
                                                <Input type="text" className="form-control" id="txtTestimonyDesignation" placeholder="Enter Testimony Designation" />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-2">
                                                <label className="form-label">Testimony Image</label>
                                                {TestimonyFileDisplay > 0 ?
                                                    <span className="float-end text-primary font-12" id="spandoctitle">
                                                        <Link to={TestimonyFileName} target="_blank" >{TestimonyFileName}</Link></span> : ''}
                                                <Input type="file" className="form-control" id="txtTestimonyFilePath" accept=".png,.jpg,.jpeg,.gif" onChange={(e) => getTestimonyFile(e)} />
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>

                                        <Col lg={12}>
                                            <div className="mb-2">
                                                <label className="form-label">Testimonial Message</label>

                                                <Editor
                                                    editorState={editorState}
                                                    wrapperClassName="demo-wrapper"
                                                    editorClassName="demo-editor"
                                                    onEditorStateChange={onEditorStateChange}
                                                />
                                            </div>
                                        </Col>

                                        <Col lg={12}>
                                            <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                                            <a className="btn btn_danger btn-sm ms-2" href="/Category">Clear</a>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </form>
            </div>

        </div>
    )
}

export default AddTestimonial;