import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
const cookies = new Cookies();
import { EditorState, convertToRaw, ContentState, convertFromRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { stateFromHTML } from 'draft-js-import-html';

const EditAchievement = () => {
    const [Title, setTitle] = useState('');
    const [Id, setId] = useState('');
    const [editorState, seteditorState] = useState(EditorState.createEmpty());
    const [AchivementTitle, setAchivementTitle] = useState("");
    const [AchivementYear, setAchivementYear] = useState("");
    const [AchievementDesc, setAchievementDesc] = useState("");
    const [AchievementDetails, setAchievementDetails] = useState("");

    const [AchivementFilePath, setAchivementFilePath] = useState("");
    const [AchivementFileName, setAchivementFileName] = useState("");
    const [AchivementFileDisplay, setAchivementFileDisplay] = useState(1);
    const history = useHistory();
    let { id } = useParams();

    useEffect(() => {


        if (id != null) {
            if (id != "0") {
                let newid = id.split(':');
                const Eid = newid[1];
                getAchievementDetails(Eid);
            }
            else {
                history.push("/Achivement");
            }
        }
        else {
            history.push("/Achivement");
        }
    }, []);

    useEffect(() => {
        let contentState = stateFromHTML(AchievementDesc);
        seteditorState(EditorState.createWithContent(contentState));

    }, [AchievementDesc]);

    const onEditorStateChange = editorState => {

        seteditorState(editorState)


        setAchievementDetails(draftToHtml(convertToRaw(editorState.getCurrentContent())));

    }

    const getAchievementFile = (e) => {
        var details = e.target.files[0];
        var details1 = "Achievements";
        const formData = new FormData();
        formData.append('Document', details);
        formData.append('FolderName', details1);
        axios.post(global.APIURL + '/uploadavatar', formData, {
            headers: { "Content-Type": details.type },
        }).then((res) => {
            setAchivementFilePath(res.data.IName);
            setAchivementFileName(res.data.FileName);
            if (e.target.files[0].name != null) {
                if (e.target.files[0].name != '') {
                    setAchivementFileDisplay(1);
                }
                else {
                    setAchivementFileDisplay(0);
                }
            }

        });
    }

    const updateAchievement = async (e) => {
        e.preventDefault();
        let newid = id.split(':');
        const Eid = newid[1];
        let VAchivementTitle = e.target["txtAchivementTitle"].value;
        let VAchivementYear = e.target["txtAchivementYear"].value;

        if (VAchivementTitle == "") {
            alert("Enter Achivement Title");
            return;
        }
        if (VAchivementYear == "") {
            alert("Enter Achivement Year");
            return;
        }

        if (VAchivementTitle != '') {
            const AUpdateData = {
                Id: Eid,
                AchivementTitle: VAchivementTitle,
                AchivementYear: VAchivementYear,
                AchivementDescription: AchievementDetails == "" ? AchievementDesc : AchievementDetails,
                AchivementFilePath: AchivementFilePath,
                AchivementFileName: AchivementFileName,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
            }
            axios.post(global.APIURL + '/AchivementUpdate', AUpdateData)
                .then(res => {
                    alert(res.data[0].RecordStatus);
                    history.push('/Achivement')
                });
        }
    }


    const getAchievementDetails = async (UTId) => {

        const AData = {
            "Id": UTId
        }

        axios.post(global.APIURL + '/AchivementDetail', AData)
            .then(res => {
                setAchivementTitle(res.data[0].AchivementTitle);
                setAchivementYear(res.data[0].AchivementYear);
                setAchivementFileName(res.data[0].AchivementFileName);
                setAchievementDesc(res.data[0].AchivementDescription);
                setAchivementFilePath(res.data[0].AchivementFilePath);
            })
    }

    return (
        <div className="page-content">

            <MetaTags>
                <title>Achievement | KC Admin Group</title>
            </MetaTags>

            <div className="container-fluid">

                <Row className="mb-3">
                    <Col className="col-lg-8 col-md-6 col-6">
                        <div className="breadcrum ps-0">
                            <CardTitle className="h4">Edit Achievement</CardTitle>
                            <a href="/GeneralDashboard" className="me-2">Home</a>
                            <span>/</span>
                            <a href="/Achivement"><span className="ms-2 me-2">Achievement</span></a>
                            <span>/</span>
                            <span className="ms-2">Edit Achievement</span>
                        </div>
                    </Col>
                    <Col className="col-lg-4 co-md-6 col-6">
                        <a className="btn btn-primary btn-sm float-end font-14" href="/Achivement">
                            <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                        </a>
                    </Col>
                </Row>
                <form onSubmit={updateAchievement}>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col lg={4}>
                                            <div className="mb-2">
                                                <label className="form-label">Achievement Title</label>
                                                <Input type="text" className="form-control" id="txtAchivementTitle" placeholder="Enter Achievement Title" defaultValue={AchivementTitle} />
                                            </div>
                                        </Col>

                                        <Col lg={4}>
                                            <div className="mb-2">
                                                <label className="form-label">Achievement Year</label>
                                                <Input type="text" className="form-control" id="txtAchivementYear" placeholder="Enter  Achievement Year" defaultValue={AchivementYear} />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-2">
                                                <label className="form-label">Achievement File</label>
                                                {AchivementFileDisplay > 0 ?
                                                    <span className="float-end text-primary font-12" id="spandoctitle">
                                                        <Link to={AchivementFileName} target="_blank" >{AchivementFileName}</Link></span> : ''}
                                                <Input type="file" className="form-control" id="txtAchivementFilePath" accept=".png,.jpg,.jpeg,.gif" onChange={(e) => getAchievementFile(e)} />
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>

                                        <Col lg={12}>
                                            <div className="mb-2">
                                                <label className="form-label">Achievement Description</label>

                                                <Editor

                                                    editorState={editorState}
                                                    wrapperClassName="demo-wrapper"
                                                    editorClassName="demo-editor"
                                                    onEditorStateChange={onEditorStateChange}
                                                    id="AchEditor"
                                                    name="AchEditor"

                                                />
                                            </div>
                                        </Col>

                                        <Col lg={12}>
                                            <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                                            <a className="btn btn_danger btn-sm ms-2" href="/Category">Clear</a>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    )
}

export default EditAchievement;