import React, {  Fragment, useState ,useEffect} from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';






const OnSort =()=> {  
  let columns = [
    {
        key: "SR",
        text: "#",
        sortable: true
    },
    {
        key: "Title",
        text: "Title",
        sortable: true
    },
  
  {
    key: "Description",
    text: "Description",
    sortable: true
 },
    {
        d: "action",
        text: "Action",
        cell: (record, index) => {

        
            return (
                <Fragment>
                    <a className="me-3 text-primary" onClick={editRecord.bind(this, record)}  ><i className="mdi mdi-pencil font-size-18"></i></a>
                    <a className={IconrenderSwitchColor(record)} onClick={deleteRecord.bind(this, record)}><i className={IconrenderSwitch(record)}></i></a>
                </Fragment>
            );
        }
    }
];
let config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    filename: "restaurents",
    // button: {
    //     excel: true,
    //     print: true,
    //     csv: true
    // }
}
const [records ,setRecords] =useState([])
let history = useHistory();
   function IconrenderSwitch(param) {
      let UTFlag=param["Flag"];
      switch(UTFlag) {
        case 'A':
          return 'mdi mdi-close font-size-18';
        default:
          return 'mdi mdi-check-all font-size-18';
      }
    }

    function  IconrenderSwitchColor(param) {
      let UTFlag=param["Flag"];
      switch(UTFlag) {
        case 'A':
          return 'text-danger';
        default:
          return 'text-success';
      }
    }

    useEffect(() => {
      fetch(global.APIURL+"/Deduction")
      .then((res) => res.json())
      .then((json) => {
        setRecords(json)
      })
    });
   
    const editRecord = (record) => {
      let id = record["DeductionId"]
      let path = `/DeductionTime/EditDeductionTime/:${id}`;
      history.push(path);
    }

    const deleteRecord = (record) => {
        let UTId=record["DeductionId"];
        let UTFlag=record["Flag"];
        let de = UTFlag=="A"?"D":UTFlag=="D"?"A":UTFlag;
        const DeductionFlag = {
          DeductionId:UTId,
          Flag:de   
        }
        let AlertMessgae =UTFlag=="A"?"Are you sure you want to inactive this record?":"Are you sure you want to active this record?";
        if (confirm(AlertMessgae) == true) {
          axios.delete(global.APIURL+'/Deduction',{ data: DeductionFlag })  
            .then(res => { 
              if(UTFlag=="A")
              {
                alert("Record Inactive Successfully");
                fetch(global.APIURL+"/Deduction")
                .then((res) => res.json())
                .then((json) => {
                  setRecords(json)
                })
              }
              else if(UTFlag=="D")
              {
                alert("Record Active Successfully");
                fetch(global.APIURL+"/Deduction")
                .then((res) => res.json())
                .then((json) => {
                  setRecords(json)
                })
              }
           })
        }      
    }
   
    const onSort = (column, records, sortOrder) => {
      return orderBy(records, [column], [sortOrder]);
  }

    return (
      <div className="page-content">
        <MetaTags>
          <title>Deduction | KC Admin Group</title>
        </MetaTags>
     
        <div className="container-fluid">
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-12 mb-2">
              <div className="breadcrum ps-0">
              <CardTitle className="h4">Deduction</CardTitle>
                <a href="/" className="me-2">
                  Home
                </a>{" "}
                <span>/</span>
                <span className="ms-2">Deduction</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-12">
              <a className="btn btn-primary btn-sm float-end font-14" href="/">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
              <a className="btn btn-primary btn-sm float-end font-14 me-2" href="/DeductionTime/AddDeductionTime">
                <i className="mdi mdi-plus align-middle me-1"></i>Add New
              </a>
            </Col>
          </Row>
          <form >
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="12" className="master-table-1">
                      <ReactDatatable config={config} records={records} columns={columns} onSort={onSort}/>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
        </div>
      </div>
    );
}

export default OnSort;