import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle,Input, } from "reactstrap"
import MetaTags from 'react-meta-tags'
// import images



// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import "../../../assets/scss/datatables.scss"

// Table data
const products = [

 
  {
    "sr": 1,
    "datetime": <> <p className="mb-1">29/06/2020</p> <p className="mb-0"> 07:30 PM </p> </> ,
    "compname": <><a href="/ClientInformation" className="text-heading">Company Shine</a></>,
    "contactperson": "Smith Jain",
    "contactno": "9876543432",
    "email": "hiren@gmail.com",
    "rating": "New",
    "status": <><a href=""><span className="badge badge-soft-primary font-12">Active</span></a></> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a className="d-block border-bottom  p-1" href="#"> Delete Record </a>
                                    <a  className="d-block p-1" href="/InvoicePaymentHistory"> Invoice & Payment History </a>
    </ul>
  </div>
  },

  {
    "sr": 2,
    "datetime": <> <p className="mb-1">29/06/2020</p> <p className="mb-0"> 07:30 PM </p> </> ,
    "compname": <><a href="/ClientInformation" className="text-heading">Company Shine</a></>,
    "contactperson": "Smith Jain",
    "contactno": "9876543432",
    "email": "hiren@gmail.com",
    "rating": "New",
    "status": <><a href=""><span className="badge badge-soft-primary font-12">Active</span></a></> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a className="d-block border-bottom  p-1" href="#"> Delete Record </a>
                                    <a  className="d-block p-1" href="/InvoicePaymentHistory"> Invoice & Payment History </a>
    </ul>
  </div>
  },

  {
    "sr": 3,
    "datetime": <> <p className="mb-1">29/06/2020</p> <p className="mb-0"> 07:30 PM </p> </> ,
    "compname": <><a href="/ClientInformation" className="text-heading">Company Shine</a></>,
    "contactperson": "Smith Jain",
    "contactno": "9876543432",
    "email": "hiren@gmail.com",
    "rating": "New",
    "status": <><a href=""><span className="badge badge-soft-primary font-12">Active</span></a></> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a className="d-block border-bottom  p-1" href="#"> Delete Record </a>
                                    <a  className="d-block p-1" href="/InvoicePaymentHistory"> Invoice & Payment History </a>
    </ul>
  </div>
  },

  {
    "sr": 4,
    "datetime": <> <p className="mb-1">29/06/2020</p> <p className="mb-0"> 07:30 PM </p> </> ,
    "compname": <><a href="/ClientInformation" className="text-heading">Company Shine</a></>,
    "contactperson": "Smith Jain",
    "contactno": "9876543432",
    "email": "hiren@gmail.com",
    "rating": "New",
    "status": <><a href=""><span className="badge badge-soft-primary font-12">Active</span></a></> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a className="d-block border-bottom  p-1" href="#"> Delete Record </a>
                                    <a  className="d-block p-1" href="/InvoicePaymentHistory"> Invoice & Payment History </a>
    </ul>
  </div>
  },

  {
    "sr": 5,
    "datetime": <> <p className="mb-1">29/06/2020</p> <p className="mb-0"> 07:30 PM </p> </> ,
    "compname": <><a href="/ClientInformation" className="text-heading">Company Shine</a></>,
    "contactperson": "Smith Jain",
    "contactno": "9876543432",
    "email": "hiren@gmail.com",
    "rating": "New",
    "status": <><a href=""><span className="badge badge-soft-primary font-12">Active</span></a></> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a className="d-block border-bottom  p-1" href="#"> Delete Record </a>
                                    <a  className="d-block p-1" href="/InvoicePaymentHistory"> Invoice & Payment History </a>
    </ul>
  </div>
  },

  {
    "sr": 6,
    "datetime": <> <p className="mb-1">29/06/2020</p> <p className="mb-0"> 07:30 PM </p> </> ,
    "compname": <><a href="/ClientInformation" className="text-heading">Company Shine</a></>,
    "contactperson": "Smith Jain",
    "contactno": "9876543432",
    "email": "hiren@gmail.com",
    "rating": "New",
    "status": <><a href=""><span className="badge badge-soft-primary font-12">Active</span></a></> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a className="d-block border-bottom  p-1" href="#"> Delete Record </a>
                                    <a  className="d-block p-1" href="/InvoicePaymentHistory"> Invoice & Payment History </a>
    </ul>
  </div>
  },

  {
    "sr": 7,
    "datetime": <> <p className="mb-1">29/06/2020</p> <p className="mb-0"> 07:30 PM </p> </> ,
    "compname": <><a href="/ClientInformation" className="text-heading">Company Shine</a></>,
    "contactperson": "Smith Jain",
    "contactno": "9876543432",
    "email": "hiren@gmail.com",
    "rating": "New",
    "status": <><a href=""><span className="badge badge-soft-primary font-12">Active</span></a></> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a className="d-block border-bottom  p-1" href="#"> Delete Record </a>
                                    <a  className="d-block p-1" href="/InvoicePaymentHistory"> Invoice & Payment History </a>
    </ul>
  </div>
  },

  {
    "sr": 8,
    "datetime": <> <p className="mb-1">29/06/2020</p> <p className="mb-0"> 07:30 PM </p> </> ,
    "compname": <><a href="/ClientInformation" className="text-heading">Company Shine</a></>,
    "contactperson": "Smith Jain",
    "contactno": "9876543432",
    "email": "hiren@gmail.com",
    "rating": "New",
    "status": <><a href=""><span className="badge badge-soft-primary font-12">Active</span></a></> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a className="d-block border-bottom  p-1" href="#"> Delete Record </a>
                                    <a  className="d-block p-1" href="/InvoicePaymentHistory"> Invoice & Payment History </a>
    </ul>
  </div>
  },

  {
    "sr": 9,
    "datetime": <> <p className="mb-1">29/06/2020</p> <p className="mb-0"> 07:30 PM </p> </> ,
    "compname": <><a href="/ClientInformation" className="text-heading">Company Shine</a></>,
    "contactperson": "Smith Jain",
    "contactno": "9876543432",
    "email": "hiren@gmail.com",
    "rating": "New",
    "status": <><a href=""><span className="badge badge-soft-primary font-12">Active</span></a></> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a className="d-block border-bottom  p-1" href="#"> Delete Record </a>
                                    <a  className="d-block p-1" href="/InvoicePaymentHistory"> Invoice & Payment History </a>
    </ul>
  </div>
  },

  {
    "sr": 10,
    "datetime": <> <p className="mb-1">29/06/2020</p> <p className="mb-0"> 07:30 PM </p> </> ,
    "compname": <><a href="/ClientInformation" className="text-heading">Company Shine</a></>,
    "contactperson": "Smith Jain",
    "contactno": "9876543432",
    "email": "hiren@gmail.com",
    "rating": "New",
    "status": <><a href=""><span className="badge badge-soft-primary font-12">Active</span></a></> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a className="d-block border-bottom  p-1" href="#"> Delete Record </a>
                                    <a  className="d-block p-1" href="/InvoicePaymentHistory"> Invoice & Payment History </a>
    </ul>
  </div>
  },

  {
    "sr": 11,
    "datetime": <> <p className="mb-1">29/06/2020</p> <p className="mb-0"> 07:30 PM </p> </> ,
    "compname": <><a href="/ClientInformation" className="text-heading">Company Shine</a></>,
    "contactperson": "Smith Jain",
    "contactno": "9876543432",
    "email": "hiren@gmail.com",
    "rating": "New",
    "status": <><a href=""><span className="badge badge-soft-primary font-12">Active</span></a></> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a className="d-block border-bottom  p-1" href="#"> Delete Record </a>
                                    <a  className="d-block p-1" href="/InvoicePaymentHistory"> Invoice & Payment History </a>
    </ul>
  </div>
  },

  {
    "sr": 12,
    "datetime": <> <p className="mb-1">29/06/2020</p> <p className="mb-0"> 07:30 PM </p> </> ,
    "compname": <><a href="/ClientInformation" className="text-heading">Company Shine</a></>,
    "contactperson": "Smith Jain",
    "contactno": "9876543432",
    "email": "hiren@gmail.com",
    "rating": "New",
    "status": <><a href=""><span className="badge badge-soft-primary font-12">Active</span></a></> ,
    "action": <div className="btn-group dropstart icon_none">
    <button type="button" className=" dropdown-toggle border-0 bg-white" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fas fa-ellipsis-v px-2"></i>
    </button>
    <ul className="dropdown-menu p-2">
                                    <a className="d-block border-bottom p-1" href="#"> Edit Record </a>
                                    <a className="d-block border-bottom  p-1" href="#"> Delete Record </a>
                                    <a  className="d-block p-1" href="/InvoicePaymentHistory"> Invoice & Payment History </a>
    </ul>
  </div>
  }

];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    const columns = [{
      dataField: 'sr',
      text: 'Sr',
      sort: true,
    }, {
      dataField: 'datetime',
      text: 'Date & Time',
      sort: true
    }, {
      dataField: 'compname',
      text: 'Company Name',
      sort: true
    }, {
      dataField: 'contactperson',
      text: 'Contact Person Name',
      sort: true
    }, {
      dataField: 'contactno',
      text: 'Contact Number',
      sort: true
    }, {
      dataField: 'email',
      text: 'Email',
      sort: true
    }, {
      dataField: 'rating',
      text: 'Rating	',
      sort: true
    },{
      dataField: 'status',
      text: 'Status',
      sort: true
    }, {
      dataField: 'action',
      text: 'Action',
      sort: true
    }];

    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];

  
  

    const { SearchBar } = Search;

    return (
      <React.Fragment>

        <div className="page-content">
          <MetaTags>
          <title>Vendor List | KC Admin App</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                      <CardTitle className="h6 mb-2">Vendor List</CardTitle>
                      <a href="#" className="me-2">Home</a> <span>/</span><span className="ms-2">Vendor List</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                    <a className ="btn btn_success btn-sm float-end font-14 me-2" href="#"><i className="mdi mdi-plus align-middle me-1"></i>Add Invoice </a>
                  </Col>
              </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

<Row className=""> 
                <Col className="col-lg-4 col-md-4 col-4">
                               
                               <div className="dropdown">
                 <button className="btn btn-border  dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                   Export
                 </button>
                 <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                   <li><a className="dropdown-item" href="#">Action</a></li>
                   <li><a className="dropdown-item" href="#">Another action</a></li>
                   <li><a className="dropdown-item" href="#">Something else here</a></li>
                 </ul>
               </div>
                                               </Col>
                                               <div className="col-lg-4 col-md- col-4 text-center col">
                   <div className="search-box me-2 mb-2 d-inline-block">
                       <div className="position-relative">
                           <label htmlFor="search-bar-0" className="search-label">
                               <span id="search-bar-0-label" className="sr-only">Search this table</span>
                               <input id="search-bar-0" type="text" aria-labelledby="search-bar-0-label" className="form-control " placeholder="Search" value="" />
                           </label>
                           <i className="bx bx-search-alt search-icon"></i>
                       </div>
                   </div>
               </div>
                                               
                                               <Col className="col-lg-4 col-md-4 col-4">
                                               <div className="d-inline float-end">
                                               <div className="dropdown">
                 <button className="btn btn-border dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                   10<i className="fas fa-angle-down align-middle ms-2"></i>
                 </button>
                 <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                   <li><a className="dropdown-item" href="#">25</a></li>
                   <li><a className="dropdown-item" href="#">30</a></li>
                   <li><a className="dropdown-item" href="#">35</a></li>
                 </ul>
               </div>
                                                 </div>
                                               </Col>
        

</Row>



                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                     
                                      classes={
                                        "table align-middle table-nowrap todo-text"
                                      }
                                      headerWrapperClasses={"thead-light table-thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                
                                  <div className="text-md-right ms-auto">
                                  <div className="pagination pagination-rounded justify-content-end">
    <ul className="pagination react-bootstrap-table-page-btns-ul">
        <li className="page-item" title="previous page"><a href="#" className="page-link">&lt;</a></li>
        <li className="page-item" title="1"><a href="#" className="page-link">1</a></li>
        <li className="active page-item" title="2"><a href="#" className="page-link">2</a></li>
        <li className="page-item" title="3"><a href="#" className="page-link">3</a></li>
        <li className="page-item" title="next page"><a href="#" className="page-link">&gt;</a></li>
    </ul>
</div>
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DatatableTables