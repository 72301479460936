import React, { Component } from "react"
import { Row, Col, Card, CardBody, CardTitle,Dropdown,DropdownItem,DropdownToggle,DropdownMenu, } from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

// Table data
const products = [

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 1" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 2" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 3" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 4" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 5" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 6" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 7" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 8" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 9" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 10" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 11" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 12" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 27" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 28" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 29" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 30" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },

  {
    "id": <input type="text" className="form-control w_70px bg-light ms-0"  value="Day 31" disabled="" />,
    "day start time": <input type="time" className="form-control"  placeholder="Enter Start Time"  />,
    "day end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "break start time": <input type="time" className="form-control"  placeholder="Enter Start Time" value="" />,
    "break end time": <input type="time" className="form-control"  placeholder="Enter End Time" value="" />,
    "status":  <select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select>
  },
 
];

class DatatableTables extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products
    }

  }

  render() {

    // const columns = [{
    //   dataField: 'id',
    //   text: '#',
    //   sort: true,
    // }, {
    //   dataField: 'day start time',
    //   text: 'Day Start Time',
    //   sort: true
    // },   {
    //   dataField: 'day end time',
    //   text: 'Day End Time',
    //   sort: true
    // }, {
    //   dataField: 'break start time',
    //   text: 'Break Start Time',
    //   sort: true
    // }, {
    //   dataField: 'break end time',
    //   text: 'Break End Time',
    //   sort: true
    // },{
    //   dataField: 'status',
    //   text: 'Status',
    //   sort: true
    // }];

    // const defaultSorted = [{
    //   dataField: 'id',
    //   order: 'asc'
    // }];

    // const pageOptions = {
    //   sizePerPage: 10,
    //   totalSize: products.length, // replace later with size(customers),
    //   custom: true,
    // }

    // // Custom Pagination Toggle
    // const sizePerPageList = [
    //   { text: '5', value: 5 },
    //   { text: '10', value: 10 },
    //   { text: '15', value: 15 },
    //   { text: '20', value: 20 },
    //   { text: '25', value: 25 },
    //   { text: 'All', value: (this.state.productData).length }];

  
   
    // const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Manage (Employee Attendance) | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
            
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h3">Manage (Employee Attendance)</CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Manage (Employee Attendance)</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
            </Row>
            
            <Card>
                <CardBody>
                <Row>
                  <Col className="col-md-12">
                    <h5 className="fw-bolder"> Employee Details : </h5>
                    <hr className="bg_hrdark my-2" />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-3 col-6 mb-2">
                    <p className="mb-0"> EMPLOYEE NAME  </p>
                     <p className="fw-bolder mb-0">Yashi Joshi</p>
                  </Col>
                  <Col className="col-md-3 col-6  mb-2">
                    <p className="mb-0"> EMPLOYEE ID  </p>
                     <p className="fw-bolder mb-0">12</p>
                  </Col>
                  <Col className="col-md-3 col-6  mb-2">
                    <p className="mb-0"> MOBILE NUMBER </p>
                     <p className="fw-bolder mb-0">91(#)3253453453</p>
                  </Col>
                  <Col className="col-md-3 col-6  mb-2">
                    <p className="mb-0"> EMAIl  </p>
                     <p className="fw-bolder mb-0">yashi@gmail.com</p>
                  </Col>
                </Row>
                <hr className="bg_hrlight my-2" />
                <Row>
                  <Col className="col-md-3 col-6  mb-2">
                    <p className="mb-0"> DEPARTMENT  </p>
                     <p className="fw-bolder mb-0">abcd</p>
                  </Col>
                  <Col className="col-md-3 col-6  mb-2">
                    <p className="mb-0"> DESIGNATION  </p>
                     <p className="fw-bolder mb-0">Business Editor</p>
                  </Col>
                  <Col className="col-md-3 col-6  mb-2">
                    <p className="mb-0"> MANAGE GRADE  </p>
                     <p className="fw-bolder mb-0">Management 5</p>
                  </Col>
                  
                </Row>
                <hr className="bg_hrdark my-2" />
                </CardBody>
            </Card>

            <Card>
              <CardBody>
              <Row>
                                <Col className="col-md-12">
                                  <div className="table-responsive">
                                  <table className="table table-nowrap">
  <thead className="table-thead-light">
    <tr>
      <th scope="col" className="w_120px">#</th>
      <th scope="col">Day Start Time</th>
      <th scope="col">Day End Time</th>
        <th scope="col">Break Start Time</th>
          <th scope="col">Break End Time</th>
          <th scope="col">Status</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row"><input type="text" className="form-control w_100px bg-light ms-0"  value="Day 1" disabled="" /></th>
      <td><input type="time" className="form-control"  placeholder="Enter Start Time"  /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><input type="time" className="form-control" placeholder="Enter Start Time" value="" /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></td>
                                          
                          </tr>
                          <tr>
      <th scope="row"><input type="text" className="form-control w_100px bg-light ms-0"  value="Day 2" disabled="" /></th>
      <td><input type="time" className="form-control"  placeholder="Enter Start Time"  /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><input type="time" className="form-control" placeholder="Enter Start Time" value="" /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></td>
                                          
                          </tr>
                          <tr>
      <th scope="row"><input type="text" className="form-control w_100px bg-light ms-0"  value="Day 3" disabled="" /></th>
      <td><input type="time" className="form-control"  placeholder="Enter Start Time"  /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><input type="time" className="form-control" placeholder="Enter Start Time" value="" /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></td>
                                          
                          </tr>
                          <tr>
      <th scope="row"><input type="text" className="form-control w_100px bg-light ms-0"  value="Day 4" disabled="" /></th>
      <td><input type="time" className="form-control"  placeholder="Enter Start Time"  /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><input type="time" className="form-control" placeholder="Enter Start Time" value="" /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></td>
                                          
                          </tr>
                          <tr>
      <th scope="row"><input type="text" className="form-control w_100px bg-light ms-0"  value="Day 5" disabled="" /></th>
      <td><input type="time" className="form-control"  placeholder="Enter Start Time"  /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><input type="time" className="form-control" placeholder="Enter Start Time" value="" /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></td>
                                          
                          </tr>
                          <tr>
      <th scope="row"><input type="text" className="form-control w_100px bg-light ms-0"  value="Day 6" disabled="" /></th>
      <td><input type="time" className="form-control"  placeholder="Enter Start Time"  /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><input type="time" className="form-control" placeholder="Enter Start Time" value="" /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></td>
                                          
                          </tr>
                          <tr>
      <th scope="row"><input type="text" className="form-control w_100px bg-light ms-0"  value="Day 7" disabled="" /></th>
      <td><input type="time" className="form-control"  placeholder="Enter Start Time"  /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><input type="time" className="form-control" placeholder="Enter Start Time" value="" /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></td>
                                          
                          </tr>
                          <tr>
      <th scope="row"><input type="text" className="form-control w_100px bg-light ms-0"  value="Day 8" disabled="" /></th>
      <td><input type="time" className="form-control"  placeholder="Enter Start Time"  /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><input type="time" className="form-control" placeholder="Enter Start Time" value="" /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></td>
                                          
                          </tr>
                          <tr>
      <th scope="row"><input type="text" className="form-control w_100px bg-light ms-0"  value="Day 9" disabled="" /></th>
      <td><input type="time" className="form-control"  placeholder="Enter Start Time"  /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><input type="time" className="form-control" placeholder="Enter Start Time" value="" /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></td>
                                          
                          </tr>
                          <tr>
      <th scope="row"><input type="text" className="form-control w_100px bg-light ms-0"  value="Day 10" disabled="" /></th>
      <td><input type="time" className="form-control"  placeholder="Enter Start Time"  /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><input type="time" className="form-control" placeholder="Enter Start Time" value="" /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></td>
                                          
                          </tr>
                          <tr>
      <th scope="row"><input type="text" className="form-control w_100px bg-light ms-0"  value="Day 11" disabled="" /></th>
      <td><input type="time" className="form-control"  placeholder="Enter Start Time"  /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><input type="time" className="form-control" placeholder="Enter Start Time" value="" /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></td>
                                          
                          </tr>
                          <tr>
      <th scope="row"><input type="text" className="form-control w_100px bg-light ms-0"  value="Day 27" disabled="" /></th>
      <td><input type="time" className="form-control"  placeholder="Enter Start Time"  /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><input type="time" className="form-control" placeholder="Enter Start Time" value="" /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></td>
                                          
                          </tr>
                          <tr>
      <th scope="row"><input type="text" className="form-control w_100px bg-light ms-0"  value="Day 28" disabled="" /></th>
      <td><input type="time" className="form-control"  placeholder="Enter Start Time"  /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><input type="time" className="form-control" placeholder="Enter Start Time" value="" /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></td>
                                          
                          </tr>
                          <tr>
      <th scope="row"><input type="text" className="form-control w_100px bg-light ms-0"  value="Day 29" disabled="" /></th>
      <td><input type="time" className="form-control"  placeholder="Enter Start Time"  /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><input type="time" className="form-control" placeholder="Enter Start Time" value="" /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></td>
                                          
                          </tr>
                          <tr>
      <th scope="row"><input type="text" className="form-control w_100px bg-light ms-0"  value="Day 30" disabled="" /></th>
      <td><input type="time" className="form-control"  placeholder="Enter Start Time"  /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><input type="time" className="form-control" placeholder="Enter Start Time" value="" /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></td>
                                          
                          </tr>
                          <tr>
      <th scope="row"><input type="text" className="form-control w_100px bg-light ms-0"  value="Day 31" disabled="" /></th>
      <td><input type="time" className="form-control"  placeholder="Enter Start Time"  /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><input type="time" className="form-control" placeholder="Enter Start Time" value="" /></td>
      <td><input type="time" className="form-control"  placeholder="Enter End Time" value="" /></td>
      <td><select className="form-select" id="floatingSelect" aria-label="Floating label select example"><option>Select Status</option><option value="1">One</option><option value="2">Two</option><option value="3">Three</option></select></td>
                                          
    </tr>
  </tbody>
</table>
                                      </div>
                                </Col>
                </Row>
                <Row>
                                <Col className="col-md-12">
                                 <div className="">
                                    <a href="" className="btn btn_success me-2 ms-2"> Save </a>
                                    <a href="" className="btn btn_danger"> Cancel </a>
                                  </div>
                                </Col>
                              </Row>

              </CardBody>
            </Card>

           
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DatatableTables