import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import img1 from "assets/images/small/img-1.jpg"
import img2 from "assets/images/small/img-3.jpg"
import img3 from "assets/images/small/img-4.jpg"
import img4 from "assets/images/small/img-6.jpg"
import Reply_person from "../../../assets/images/users/avatar-2.jpg";
//Import Breadcrumb

import classnames from "classnames";

class UiTabsAccordions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col5: true,
      col6: true,
      col7: true,
      col8: true,
      col9: true,
      col10: false,
      col11: false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggle1 = this.toggle1.bind(this);

    this.t_col1 = this.t_col1.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.t_col3 = this.t_col3.bind(this);
    this.t_col5 = this.t_col5.bind(this);
    this.t_col6 = this.t_col6.bind(this);
    this.t_col7 = this.t_col7.bind(this);
    this.t_col8 = this.t_col8.bind(this);
    this.t_col9 = this.t_col9.bind(this);
    this.t_col10 = this.t_col10.bind(this);
    this.t_col11 = this.t_col11.bind(this);

    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);

    this.toggleVertical = this.toggleVertical.bind(this);
    this.toggleVerticalIcon = this.toggleVerticalIcon.bind(this);
    this.toggleCustom = this.toggleCustom.bind(this);
    this.toggleIconCustom = this.toggleIconCustom.bind(this);
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false,
    });
  }

  t_col2() {
    this.setState({
      col1: false,
      col2: !this.state.col2,
      col3: false,
    });
  }

  t_col3() {
    this.setState({
      col1: false,
      col2: false,
      col3: !this.state.col3,
    });
  }

  t_col5() {
    this.setState({ col5: !this.state.col5 });
  }

  t_col6() {
    this.setState({ col6: !this.state.col6 });
  }

  t_col7() {
    this.setState({ col7: !this.state.col7 });
  }

  t_col8() {
    this.setState({
      col6: !this.state.col6,
      col7: !this.state.col7,
    });
  }

  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false,
    });
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false,
    });
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false,
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      });
    }
  }

  toggle2(tab) {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      });
    }
  }

  toggle3(tab) {
    if (this.state.activeTab3 !== tab) {
      this.setState({
        activeTab3: tab,
      });
    }
  }

  toggleVertical(tab) {
    if (this.state.verticalActiveTab !== tab) {
      this.setState({
        verticalActiveTab: tab,
      });
    }
  }

  toggleVerticalIcon(tab) {
    if (this.state.verticalActiveTabWithIcon !== tab) {
      this.setState({
        verticalActiveTabWithIcon: tab,
      });
    }
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      });
    }
  }

  toggleIconCustom(tab) {
    if (this.state.customIconActiveTab !== tab) {
      this.setState({
        customIconActiveTab: tab,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title> Support Ticket Detail | KC Admin Group</title>
          </MetaTags>
          <Container fluid={true}>
         
            <Row className="mb-3">
              <Col className="col-lg-8 col-md-6 col-6">
                <div className="breadcrum ps-0">
                  <CardTitle className="h4">
                    {" "}
                    Support Ticket Detail
                  </CardTitle>
                  <a href="#" className="me-2">
                    Home
                  </a>{" "}
                  <span>/</span>
                  <span className="ms-2"> Support Ticket Detail</span>
                </div>
              </Col>
              <Col className="col-lg-4 co-md-6 col-6">
                <a
                  className="btn btn-primary btn-sm float-end font-14 margin-20-back"
                  href="#"
                >
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
              </Col>
            </Row>
            
            <Row>
              <Col className="col-lg-8">
                <Card>
                  <CardBody>
                      <Row>
                        <Col className="col-lg-2">
                          <p className="fw-bold mb-0">Status : <span className="badge bg-warning">New</span></p>
                        </Col>
                        <Col className="col-lg-3">
                          <p className="fw-bold mb-0">Client : <span className="text-primary">John Doe</span></p>
                        </Col>
                        <Col className="col-lg-4">
                          <p className="fw-bold mb-0">Created : <span className="text-secondary">5 Jan 2019 07:21 AM </span></p>
                        </Col>
                        <Col className="col-lg-3">
                          <p className="fw-bold mb-0">Created by : <span className="text-primary">John Doe</span></p>
                        </Col>
                      </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="">
                          <h5 className="mb-0">Document Issue</h5>
                        </div>
                        <div className="">
                        <div className="dropdown">
    <button className="btn border border-3 p-1 text-danger dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
      High
    </button>
    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li><a className="dropdown-item text-danger" href="#">High</a></li>
      <li><a className="dropdown-item text-warning" href="#">Medium</a></li>
      <li><a className="dropdown-item text-success" href="#">Low</a></li>
    </ul>
  </div>
                        </div>
                    </div>

                    <p className="mt-3 p-txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum sollicitudin libero vitae est consectetur, a molestie tortor consectetur. Aenean tincidunt interdum ipsum, id pellentesque diam suscipit ut. Vivamus massa mi, fermentum eget neque eget, imperdiet tristique lectus. Proin at purus ut sem pellentesque tempor sit amet ut lectus. Sed orci augue, placerat et pretium ac, hendrerit in felis. Integer scelerisque libero non metus commodo, et hendrerit diam aliquet. Proin tincidunt porttitor ligula, a tincidunt orci pellentesque nec. Ut ultricies maximus nulla id consequat. Fusce eu consequat mi, eu euismod ligula. Aliquam porttitor neque id massa porttitor, a pretium velit vehicula. Morbi volutpat tincidunt urna, vel ullamcorper ligula fermentum at.</p>

                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Row>
                      <CardTitle className="mb-3">Uploaded files</CardTitle>
                      
                      <div className="col-lg-3">
                          <img src={img1} className="w-100"></img>
                      </div>
                      <div className="col-lg-3">
                          <img className="w-100" src={img2}></img>
                      </div>
                      <div className="col-lg-3">
                          <img className="w-100" src={img3}></img>
                      </div>
                      <div className="col-lg-3">
                          <img className="w-100" src={img4}></img>
                      </div>
                      
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col className="col-lg-4">
                <Card className="c-height">
                  <CardBody>
                    <CardTitle>Replies<span className="float-end font-12"><a href="" className="table-link" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Updates</a></span></CardTitle>
                  <Row className="mb-3">
                          <Col className="col-12 mb-2">
                            <div className="d-flex gap-3">
                              <div>
                                {" "}
                                <img
                                  src={Reply_person}
                                  className="rounded-circle header-profile-user"
                                />{" "}
                              </div>
                              <div>
                                {" "}
                                <p className="mb-0">
                                  {" "}
                                  <a href="#"> Kartik Singh Mehta</a>{" "}
                                </p>{" "}
                                <p className="mb-0 text-muted">
                                  {" "}
                                  <a className=" text-muted" href="#">
                                    {" "}
                                    SmithJainabc@gmail.com{" "}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col className="col-12 ps-5">
                            <div className="ms-3 bg-light fontWeight500 p-3 rounded-2">
                              Iure magni fugiat inventore quisquam. Earum rerum
                              occaecati deleniti et dignissimos voluptatem
                              perspiciatis tenetur est. Iure magni fugiat
                              inventore quisquam. Earum rerum occaecati deleniti
                              et dignissimos voluptatem perspiciatis tenetur
                              est.
                            </div>
                          </Col>
                        </Row>
                        <hr className="bg-grey-900" />

                        <Row className="mb-3">
                          <Col className="col-12 mb-2">
                            <div className="d-flex gap-3">
                              <div>
                                {" "}
                                <img
                                  src={Reply_person}
                                  className="rounded-circle header-profile-user"
                                />{" "}
                              </div>
                              <div>
                                {" "}
                                <p className="mb-0">
                                  {" "}
                                  <a href="#"> Smith Jain</a>{" "}
                                </p>{" "}
                                <p className="mb-0">
                                  {" "}
                                  <a className=" text-muted" href="#">
                                    {" "}
                                    SmithJainabc@gmail.com{" "}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col className="col-12 ps-5">
                            <div className="ms-3 bg-light fontWeight500 p-3 rounded-2">
                              Iure magni fugiat inventore quisquam. Earum rerum
                              occaecati deleniti et dignissimos voluptatem
                              perspiciatis tenetur est. Iure magni fugiat
                              inventore quisquam. Earum rerum occaecati deleniti
                              et dignissimos voluptatem perspiciatis tenetur
                              est.
                            </div>
                          </Col>
                        </Row>
                        <hr className="bg-grey-900" />

                        <Row className="mb-3">
                          <Col className="col-12 mb-2">
                            <div className="d-flex gap-3">
                              <div>
                                {" "}
                                <img
                                  src={Reply_person}
                                  className="rounded-circle header-profile-user"
                                />{" "}
                              </div>
                              <div>
                                {" "}
                                <p className="mb-0">
                                  {" "}
                                  <a href="#"> Kartik Singh Mehta</a>{" "}
                                </p>{" "}
                                <p className="mb-0">
                                  {" "}
                                  <a className=" text-muted" href="#">
                                    {" "}
                                    SmithJainabc@gmail.com{" "}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col className="col-12 ps-5">
                            <div className="ms-3 bg-light fontWeight500 p-3 rounded-2">
                              Iure magni fugiat inventore quisquam. Earum rerum
                              occaecati deleniti et dignissimos voluptatem
                              perspiciatis tenetur est. Iure magni fugiat
                              inventore quisquam. Earum rerum occaecati deleniti
                              et dignissimos voluptatem perspiciatis tenetur
                              est.
                            </div>
                          </Col>
                        </Row>
                        <hr className="bg-grey-900" />

                        <Row className="mb-3">
                          <Col className="col-12 mb-2">
                            <div className="d-flex gap-3">
                              <div>
                                {" "}
                                <img
                                  src={Reply_person}
                                  className="rounded-circle header-profile-user"
                                />{" "}
                              </div>
                              <div>
                                {" "}
                                <p className="mb-0">
                                  {" "}
                                  <a href="#"> Smith Jain</a>{" "}
                                </p>{" "}
                                <p className="mb-0">
                                  {" "}
                                  <a className=" text-muted" href="#">
                                    {" "}
                                    SmithJainabc@gmail.com{" "}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col className="col-12 ps-5">
                            <div className="ms-3 bg-light fontWeight500 p-3 rounded-2">
                              Iure magni fugiat inventore quisquam. Earum rerum
                              occaecati deleniti et dignissimos voluptatem
                              perspiciatis tenetur est. Iure magni fugiat
                              inventore quisquam. Earum rerum occaecati deleniti
                              et dignissimos voluptatem perspiciatis tenetur
                              est.
                            </div>
                          </Col>
                        </Row>
                        <hr className="bg-grey-900" />

                        <Row className="mb-3">
                          <Col className="col-12 mb-2">
                            <div className="d-flex gap-3">
                              <div>
                                {" "}
                                <img
                                  src={Reply_person}
                                  className="rounded-circle header-profile-user"
                                />{" "}
                              </div>
                              <div>
                                {" "}
                                <p className="mb-0">
                                  {" "}
                                  <a href="#"> Smith Jain</a>{" "}
                                </p>{" "}
                                <p className="mb-0">
                                  {" "}
                                  <a className=" text-muted" href="#">
                                    {" "}
                                    SmithJainabc@gmail.com{" "}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col className="col-12 ps-5">
                            <div className="ms-3 bg-light fontWeight500 p-3 rounded-2">
                              Iure magni fugiat inventore quisquam. Earum rerum
                              occaecati deleniti et dignissimos voluptatem
                              perspiciatis tenetur est. Iure magni fugiat
                              inventore quisquam. Earum rerum occaecati deleniti
                              et dignissimos voluptatem perspiciatis tenetur
                              est.
                            </div>
                          </Col>
                        </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div className="offcanvas-header">
    <h5 id="offcanvasRightLabel">Updates</h5>
    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div className="offcanvas-body">
  <Row>
                          <Col className="col-12 mb-3">
                            <div className="pb-2">
                              <div className="notes_box notes_box_yellow pb-3 text-black">
                                Open on 16th June 21 <br />
                                <div className="text-muted">
                                  by Kartik Singh Mehta
                                </div>
                                <div>
                                  Vitae perspiciatis velit similique autem.
                                  Ullam dolorem quaerat consequatur blanditiis.
                                  Incidunt occaecati modi numquam sapiente
                                  voluptatem molestiae. Sit omnis nostrum totam
                                  molestiae delectus.
                                </div>
                              </div>
                              <div className="notes_box notes_box_yellow pb-3  text-black">
                                Task Assign to{" "}
                                <span className="text-info">Kartik Singh</span>{" "}
                                on 17th June 21 <br />
                                <div className="text-muted">
                                  by Kartik Singh Mehta
                                </div>
                              </div>
                              <div className="notes_box notes_box_yellow  pb-3  text-black">
                                Created new note on 25th June 21 <br />
                                <div className="text-muted">
                                  by Kartik Singh Mehta
                                </div>
                                <div className="bg-light p-3 rounded-2">
                                  {" "}
                                  Vitae perspiciatis velit similique autem.
                                  Ullam dolorem quaerat consequatur blanditiis.
                                  Incidunt occaecati modi numquam sapiente
                                  voluptatem molestiae. Sit omnis nostrum totam
                                  molestiae delectus.{" "}
                                </div>
                              </div>
                              <div className="notes_box notes_box_green pb-3 text-black">
                                Created new note with attachment on 25th June 21{" "}
                                <br />
                                <div className="text-muted">
                                  by Kartik Singh Mehta
                                </div>
                                <div className="bg-light p-3 rounded-2">
                                  Autem voluptatum praesentium sed magni quasi
                                  eum provident impedit voluptatum. Quas
                                  consequatur non quia et nisi. Qui quia qui
                                  autem sit laboriosam nisi.{" "}
                                </div>
                                <Row>
                                  <Col className="col-md-6 col-6 m-3 ms-0">
                                    <span className="d-flex border_dotted p-3 rounded-3 bg-light">
                                      <div>
                                        <i className="mdi mdi-attachment fs-3 me-3"></i>
                                      </div>
                                      <div className="">
                                        <p className="mb-0">todo.PDF</p>
                                        <p className="mb-0">18KB</p>
                                      </div>
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                              <div className="notes_box notes_box_green pb-3  text-black">
                                Mark as completed on 30th June 21 <br />
                                <div className="text-muted">
                                  by Kartik Singh Mehta
                                </div>
                              </div>

                              <div className="notes_box notes_box_blue pb-3  text-black">
                                Mark as re-open on 30th June 21 <br />
                                <div className="text-muted">
                                  by Kartik Singh Mehta
                                </div>
                              </div>

                              <div className="notes_box notes_box_lightblue pb-3  text-black">
                                Notes shared on 1st Sep 21 <br />
                                <div className="text-muted">
                                  by Kartik Singh Mehta
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
  </div>
</div>
        </div>
      </React.Fragment>
    );
  }
}

export default UiTabsAccordions;
