import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
  Container,
  CardTitle} from "reactstrap"
import { connect } from "react-redux"

//import Charts


//import action
import { getChartsData } from "../../../../store/actions"

// Pages Components
import logo1 from "../../../../assets/images/users/logo-1.png";
import barcode1 from "../../../../assets/images/users/barcode-1.png";
//Import Breadcrumb

//i18n
import { withTranslation } from "react-i18next"

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
        {
          title: "Revenue",
          iconClass: "bx-archive-in",
          description: "$35, 723",
        },
        {
          title: "Average Price",
          iconClass: "bx-purchase-tag-alt",
          description: "$16.2",
        },
      ],
      email: [
        { title: "Week", linkto: "#", isActive: false },
        { title: "Month", linkto: "#", isActive: false },
        { title: "Year", linkto: "#", isActive: true },
      ],
      modal: false,
      subscribemodal: false,
      chartSeries: [],
      periodType: "yearly"
    }

    this.togglemodal.bind(this)
    this.togglesubscribemodal.bind(this)
  }

  componentDidMount() {
    const { onGetChartsData } = this.props;
    setTimeout(() => this.setState({ subscribemodal: true }), 2000);
    onGetChartsData("yearly");
  }


  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  togglesubscribemodal = () => {
    this.setState(prevState => ({
      subscribemodal: !prevState.subscribemodal,
    }))
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ ...this.state, chartSeries: this.props.chartsData })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Vendor Invoice | KC Admin Group</title> 
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <CardTitle className="mb-3">Vendor Invoice </CardTitle>
            <div className="row justify-content-center mb-5">
    <div className="col-md-10">
        <div className="d-print-none mb-3">
            <div className="text-end">
                <a href="javascript:;" id="printPage" className="btn btn-secondary waves-effect waves-light me-1"><i className="fa fa-print me-2"></i>Print</a>
            </div>
        </div>
        <div className="mainpageinvoice p-0">
            <div className="row ms-0 me-0 align-items-center">
                <div className="col-6 pt-3 pb-3">
                    <h4 className="mainhedinvoice mb-0">TAX INVOICE</h4>
                </div>
                <div className="col-6 pt-3 pb-3 invoiceimgcompany text-end">
                    <img  src={logo1} />
                </div>
            </div>

            <hr className="hrforinvocenew" />

            <div className="row ms-0 me-0">
                <div className="col-lg-6">
                    <address className="mt-2  mb-2">
                        <strong>Invoice : </strong>
                        INR/2021/00000004
                    </address>
                </div>
                {/* <div className="col-md-4 d-flex justify-content-center">
                                 <div className="invoice-bar-code">
                                    <img src="../../../assets/images/bar-code-p.png"/>
                                 </div>
                             </div> */}
                <div className="col-lg-6 text-sm-end">
                    <address className="mt-2  mb-2">
                        <strong>Invoice Date:</strong>
                        01 December, 2021
                    </address>
                </div>
            </div>

            <hr className="hrforinvoce" />

            <div className="row ms-0 me-0">
                <div className="col-sm-6 afteraddressboxborder border-end mt-2">
                    <address className="addressdetailtextedit mb-2">
                        <strong className="billhededit">Bill To</strong><br />
                        <p className="companynameeditin">Css Tech</p>
                        <p></p>
                        <p>India, Gujarat, Ahmedabad 382120</p>

                        <div className="row">
                            <div className="col-md-12">
                                <p>GST No. : TRE7412</p>
                            </div>
                            <div className="col-md-12">
                                <p>PAN No. : 789654TYU</p>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-md-6">
                                <p>Mobile # : 7766554433</p>
                            </div>
                            <div className="col-md-6">
                                                                <p>Email : </p>
                            </div>

                        </div>
                    </address>
                </div>
                <div className="col-sm-6 mt-2">
                    <address className="addressdetailtextedit mb-2">
                        <strong className="billhededit">Bill From</strong><br />

                        <p className="companynameeditin">Sunshine Technology</p>

                        <p>
                            208, SIlver Peral, 208, SIlver Peral, Paldi,Paldi, India, Gujarat, Gandhinagar 380059 <br />
                            https://wr.testingbeta.in/login
                        </p>
                        <div className="row">
                            <div className="col-md-12">
                                <p>
                                    GST No. : 4125369854
                                </p>
                            </div>
                            <div className="col-md-12">
                                <p>
                                    PAN No. : 4125369854
                                </p>
                            </div>
                        </div>
                    </address>
                </div>
            </div>
            <hr className="hrforinvoce mt-2" />
            <div className="row mt-2 ms-0 me-0">
                <div className="col-md-12">
                    <strong>Invoice Summary</strong>
                </div>
            </div>

            <div className="row mt-3 ms-0 me-0">
                <div className="col-md-6">
                    <div className="tabledetailsright">
                        <p className="mb-0 hedfortheparticularbox">HSN Code</p>
                        <p className="detailparticularbox">hsnCode1</p>
                        <p className="mb-0 hedfortheparticularbox">Particular</p>
                        <p className="detailparticularbox">Service</p>

                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center mt-3">
                                <div className="mr-2 amountboxin">
                                    <p className="mb-0 hedfortheparticularbox">1000.00</p>
                                    <small>Amount</small>
                                </div>

                                <div className="mr-2 text-center">
                                    <p className="mb-0">*</p>
                                </div>
                                <div className="mr-2 amountboxin">
                                    <p className="mb-0 hedfortheparticularbox">10</p>
                                    <small>QTY</small>
                                </div>
                            </div>

                            <div className="amountboxin">
                                <p className="mb-0 hedfortheparticularbox">10000.00</p>
                                <small>Total</small>
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-2">
                            <div className="mr-4 amountboxin">
                                <p className="mb-0 hedfortheparticularbox">15.00%</p>
                                <small>Discount</small>
                            </div>

                            <div className="amountboxin">
                                <p className="mb-0 hedfortheparticularbox">1500.00</p>
                                <small></small>
                            </div>
                        </div>

                        <div className="row justify-content-end">
                            <div className="col-md-6">
                                <div className="d-flex justify-content-between bordertopedit">
                                    <div className="pb-2 pt-2">
                                        <p className="mb-0 hedfortheparticularbox">Net Amount</p>
                                    </div>

                                    <div className="pb-2 pt-2">
                                        <p className="hedfortheparticularbox">8500.00</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="tabledetailsright pb-0">
                        <div className="d-flex justify-content-between">
                            <div className="pb-2">
                                <p className="mb-0 hedfortheparticularbox">GST (14.00%)</p>
                            </div>
                        </div>

                        <div className="row forthebordergst">
                            <div className="col-md-4 amountboxin forthebordergstsub pe-0 pt-2 pb-2">
                                <p className="mb-0 hedfortheparticularbox">0.00</p>
                                <small>SGST</small>
                            </div>
                            <div className="col-md-4 amountboxin forthebordergstsub pt-2 pb-2">
                                <p className="mb-0 hedfortheparticularbox">0.00</p>
                                <small>CGST</small>
                            </div>

                            <div className="col-md-4 amountboxin pe-0 pt-2 pb-2">
                                <p className="mb-0 hedfortheparticularbox">1190.00</p>
                                <small>IGST (14.00%)</small>
                            </div>
                        </div>

                        <div className="row justify-content-end forthebordergst">
                            <div className="col-md-6">
                                <div className="d-flex justify-content-between borderbottomedit">
                                    <div className="pb-2 pt-2">
                                        <p className="mb-0 hedfortheparticularbox">Total GST</p>
                                    </div>

                                    <div className="pb-2 pt-2">
                                        <p className="detailparticularbox">1190.00 (14.00%)</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-end">
                            <div className="col-md-6">
                                <div className="d-flex justify-content-between borderbottomedit">
                                    <div className="pb-2 pt-2">
                                        <p className="mb-0 hedfortheparticularbox">Amount</p>
                                    </div>
                                    <div className="pb-2 pt-2">
                                        <p className="detailparticularbox">8500.00</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-end">
                            <div className="col-md-6">
                                <div className="d-flex justify-content-between">
                                    <div className="pt-2">
                                        <p className="mb-0 hedfortheparticularbox">Total Amount</p>
                                    </div>

                                    <div className="pt-2">
                                        <p className="hedfortheparticularbox">9690.00</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row justify-content-end ms-0 me-0">
                <div className="col-md-6">
                    <div className="tabledetailsrightnew pb-0">
                        <div className="row justify-content-end">
                            <div className="col-md-6">
                                <div className="d-flex justify-content-between borderbottomedit">
                                    <div className="pb-2 pt-2">
                                        <p className="mb-0 hedfortheparticularbox">Net Total</p>
                                    </div>

                                    <div className="pb-2 pt-2">
                                        <p className="detailparticularbox">10000.00</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-end">
                            <div className="col-md-6">
                                <div className="d-flex justify-content-between borderbottomedit">
                                    <div className="pb-2 pt-2">
                                        <p className="mb-0 hedfortheparticularbox">Total Discount</p>
                                    </div>

                                    <div className="pb-2 pt-2">
                                        <p className="detailparticularbox">1500.00</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-end">
                            <div className="col-md-6">
                                <div className="d-flex justify-content-between borderbottomedit">
                                    <div className="pb-2 pt-2">
                                        <p className="mb-0 hedfortheparticularbox">Total TAX</p>
                                    </div>

                                    <div className="pb-2 pt-2">
                                        <p className="detailparticularbox">1190.00</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-end">
                            <div className="col-md-6">
                                <div className="d-flex justify-content-between borderbottomedit">
                                    <div className="pb-2 pt-2">
                                        <p className="mb-0 hedfortheparticularbox">Total SGST</p>
                                    </div>

                                    <div className="pb-2 pt-2">
                                        <p className="detailparticularbox">0.00</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-end">
                            <div className="col-md-6">
                                <div className="d-flex justify-content-between borderbottomedit">
                                    <div className="pb-2 pt-2">
                                        <p className="mb-0 hedfortheparticularbox">Total CGST</p>
                                    </div>

                                    <div className="pb-2 pt-2">
                                        <p className="detailparticularbox">0.00</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-end">
                            <div className="col-md-6">
                                <div className="d-flex justify-content-between">
                                    <div className="pb-2 pt-2">
                                        <p className="mb-0 hedfortheparticularbox">Total IGST</p>
                                    </div>

                                    <div className="pb-2 pt-2">
                                        <p className="detailparticularbox">1190.00</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-end ms-0 me-0 bordertopedit footereditit">
                <div className="col-md-6">
                    <div className="tabledetailsrightnew ps-0">
                        <p className="mb-0 amountinword">
                            Amount Payable In Words : nine thousand six hundred and ninety only.
                        </p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="tabledetailsrightnew pb-0">
                        <div className="row justify-content-end">
                            <div className="col-md-6">
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <p className="mb-0 hedfortheparticularbox">Total Payable</p>
                                    </div>

                                    <div className="">
                                        <p className="hedfortheparticularbox">9690.00</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row signeboxs m-0">
                <div className="col-md-8 pt-2">
                    <address className="addressdetailtexteditnew mb-2">
                        <strong>Bank Details</strong><br />
                        <div className="row">
                            <div className="col-md-3">
                                <p>
                                    <span className="titalbankd">Bank Name</span> <br />
                                    <span className="detailbanki">Bank of baroda</span>
                                </p>
                            </div>
                            <div className="col-md-3">
                                <p>
                                    <span className="titalbankd">Account Number</span> <br />
                                    <span className="detailbanki">7896541235</span>
                                </p>
                            </div>

                            <div className="col-md-3">
                                <p>
                                    <span className="titalbankd">IFSC Code</span> <br />
                                    <span className="detailbanki">BARB0CORAHM</span>
                                </p>
                            </div>
                            <div className="col-md-3">
                                <p>
                                    <span className="titalbankd">Branch Name</span> <br />
                                    <span className="detailbanki">Paldi</span>
                                </p>
                            </div>
                        </div>
                    </address>
                    <strong>Terms and Conditions</strong>
                    <p className="termsandconditions mb-2">
                        These Terms of Use govern your use of the websites, content and other services offered through  (“Site”) You (the user) agree to access the site, subject to the terms and conditions of
                        use as set out here. We may add to or change or update these Terms of Use, from time to time entirely at its own discretion. You are responsible for checking these Terms of Use periodically to remain in
                        compliance with these terms. Your use of a Site after any amendment to the Terms of Use shall constitute your acceptance of these terms and you also agree to be bound by any such changes/revisions.
                    </p>
                </div>
                <div className="col-md-2 borderfootersign text-md-center mb-5 mb-md-0 pt-2">
                    <strong>QR Code</strong>

                    <div>
                        <img src={barcode1} width="100px" />
                    </div>
                </div>

                <div className="col-md-2 text-md-center mb-5 mb-md-0 pt-2">
                    <strong>Company Sign</strong>
                </div>
            </div>

            <div className="row ms-0 me-0 mt-2">
                <div className="col-md-12">
                    <strong>Excelsior technology</strong>
                    <p className="addresstext mb-1">
                        208, SIlver Peral, 208, SIlver Peral, Paldi,Paldi, India, Gujarat, Gandhinagar 380059. <span> <strong> Phone : </strong>+91-4234234234</span> <span className="breakline"> | </span>
                        <span> <strong> Email : </strong>deepak@gmail.com</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

             
          </Container>
        </div>
       
      </React.Fragment>
    )
  }
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func
}

const mapStateToProps = ({ Dashboard }) => ({
  chartsData: Dashboard.chartsData,
})

const mapDispatchToProps = dispatch => ({
  onGetChartsData: (periodType) => dispatch(getChartsData(periodType)),
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(withTranslation()(Dashboard))

