import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
const cookies = new Cookies();
import { EditorState, convertToRaw, ContentState, convertFromRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { stateFromHTML } from 'draft-js-import-html';

const EditWebServicesDetails = () => {

    const [Title, setTitle] = useState('');
    const [Id, setId] = useState('');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [Description, setDescription] = useState("");
    const [Desc, setDesc] = useState("");
    const [TestimonyDesignation, setTestimonyDesignation] = useState("");
    const [TestimonyMsg, setTestimonyMsg] = useState("");
    const [TestimonyDesc, setTestimonyDesc] = useState("");
    const [WsDetailFilePath, setWsDetailFilePath] = useState("");
    const [WsDetailFileName, setWsDetailFileName] = useState("");
    const [WsDetailFileDisplay, setWsDetailFileDisplay] = useState(1);
    const history = useHistory();
    let { id } = useParams();

    useEffect(() => {


        if (id != null) {
            if (id != "0") {
                let newid = id.split(':');
                const Eid = newid[1];
                getWebServiceDetails(Eid);
            }
            else {
                history.push("/WebServices");
            }
        }
        else {
            history.push("/WebServices");
        }
    }, []);


    useEffect(() => {


        let contentState = stateFromHTML(Desc);
        setEditorState(EditorState.createWithContent(contentState));

    }, [Desc]);


    const onEditorStateChange = editorState => {
        setEditorState(editorState)

        setDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())));


    }
    const getWsDetailFile = (e) => {
        var details = e.target.files[0];
        var details1 = "WebServicesDetail";

        const formData = new FormData();
        formData.append('Document', details);
        formData.append('FolderName', details1);

        axios.post(global.APIURL + '/uploadavatar', formData, {

            headers: { "Content-Type": details.type },

        }).then((res) => {
            setWsDetailFilePath(res.data.IName);
            setWsDetailFileName(res.data.FileName);

            if (e.target.files[0].name != null) {
                if (e.target.files[0].name != '') {
                    setWsDetailFileDisplay(1);
                }
                else {
                    setWsDetailFileDisplay(0);
                }
            }

        });
    }


    const updateWebServicesDetails = async (e) => {
        e.preventDefault();
        let newid = id.split(':');
        const Eid = newid[1];
        let VWebServicesDetailTitle = e.target["txtDetailTitle"].value;
        let Wid = localStorage.getItem("WebServiceId") == null ? "0" : localStorage.getItem("WebServiceId") == "" ? "0" : localStorage.getItem("WebServiceId");


        if (VWebServicesDetailTitle == "") {
            alert("Enter Web Service Detail Title");
            return;
        }

        if (VWebServicesDetailTitle != '') {
            const WUpdateData = {

                Id: Eid,
                WebServiceId: Wid,
                DetailTitle: VWebServicesDetailTitle,
                DetailImage: WsDetailFilePath,
                DetailDescription: Description == "" ? Desc : Description,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
            }
            axios.post(global.APIURL + '/WebServicesDetailUpdate', WUpdateData)
                .then(res => {
                    alert(res.data[0].RecordStatus);
                    history.push('/WebServices');
                });
        }
    }


    const getWebServiceDetails = async (UTId) => {

        const AData = {
            "Id": UTId
        }

        axios.post(global.APIURL + '/WebServicesDetailEdit', AData)
            .then(res => {
                setTitle(res.data[0].DetailTitle);
                setDesc(res.data[0].DetailDescription)
                let filearray = res.data[0].DetailImage.split('/');
                setWsDetailFileName(filearray[5]);
            })
    }

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Row className="mb-3">
                    <Col className="col-lg-8 col-md-6 col-6">
                        <div className="breadcrum ps-0">
                            <CardTitle className="h4">Edit Web Services Detail</CardTitle>
                            <a href="/GeneralDashboard" className="me-2">Home</a>
                            <span>/</span>
                            <a href="/WebServices"><span className="ms-2 me-2">Web Services Detail</span></a>
                            <span>/</span>
                            <span className="ms-2">Edit Web Services Detail</span>
                        </div>
                    </Col>
                    <Col className="col-lg-4 co-md-6 col-6">
                        <a className="btn btn-primary btn-sm float-end font-14" href="/WebServicesDetails">
                            <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                        </a>
                    </Col>
                </Row>
                <form onSubmit={updateWebServicesDetails}>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col lg={5}>
                                            <div className="mb-2">
                                                <label className="form-label">Title</label>
                                                <Input type="text" className="form-control" id="txtDetailTitle" placeholder="Enter Web Services Detail Title" defaultValue={Title} />
                                            </div>
                                        </Col>
                                        <Col lg={5}>
                                            <div className="mb-2">
                                                <label className="form-label">Image</label>
                                                {WsDetailFileDisplay > 0 ?
                                                    <span className="float-end text-primary font-12" id="spandoctitle">
                                                        <Link to={WsDetailFileName} target="_blank" >{WsDetailFileName}</Link></span> : ''}
                                                <Input type="file" className="form-control" id="txtWsFilePath" accept=".png,.jpg,.jpeg,.gif" onChange={(e) => getWsDetailFile(e)} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col lg={10}>


                                            <div className="mb-2">
                                                <label className="form-label">Description</label>

                                                <Editor
                                                    editorState={editorState}
                                                    wrapperClassName="demo-wrapper"
                                                    editorClassName="demo-editor"
                                                    onEditorStateChange={onEditorStateChange}
                                                />
                                            </div>

                                        </Col>

                                        <Col lg={12}>
                                            <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                                            <a className="btn btn_danger btn-sm ms-2" href="/Category">Clear</a>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    )
}

export default EditWebServicesDetails;