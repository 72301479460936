import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
const cookies = new Cookies();

const EditSuiteFileStatus = () => {
  const [Title, setTitle] = useState('');
  const [Id, setId] = useState('');
  const history = useHistory();
  let { id } = useParams();

  const updateProduct = async (e) => {
    e.preventDefault();
    let UTTitle = event.target["txtSuiteStatus"].value;
    let UTId = event.target["SuiteStatusId"].value;
    if (UTTitle != '') {
      const SuiteStatus = {
        Id: UTId == '' ? '0' : UTId,
        Title: UTTitle,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
      }

      axios.post(global.APIURL + '/SuiteStatusUpdate', SuiteStatus)
        .then(res => {
          alert(res.data.StatusMessage);
          history.push("/SuiteFileStatus");
        });
    }
  }

  useEffect(() => {
    if (id != null) {
      if (id != "0") {
        let newid = id.split(':');
        getUserTyprById(newid[1]);
      }
      else {
        history.push("/SuiteFileStatus");
      }
    }
    else {
      history.push("/SuiteFileStatus");
    }
  }, []);

  const getUserTyprById = async (UTId) => {
    var AUrl = global.APIURL + '/SuiteStatusEdit/' + UTId;
    axios.get(AUrl)
      .then(res => {
        setTitle(res.data[0].Title);
        setId(res.data[0].Id)
      })
  }

  return (
    <div className="page-content">

      <MetaTags>
        <title>Category | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Edit Category</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <a href="/Category"><span className="ms-2 me-2">Category</span></a>
              <span>/</span>
              <span className="ms-2">Edit Category</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-6">
            <a className="btn btn-primary btn-sm float-end font-14" href="/SuiteFileStatus">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <form onSubmit={updateProduct}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Category</label>
                        <Input type="text" className="" id="txtSuiteStatus" defaultValue={Title} placeholder="Enter Suite File Status" />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <input type="hidden" id="SuiteStatusId" value={Id} />
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/SuiteFileStatus">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  )
}

export default EditSuiteFileStatus;