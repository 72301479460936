import React,{ useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory,useParams  } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
const cookies = new Cookies();
 
const EditDeductionTime = () => { 
  const [Title, setTitle] = useState('');
  const [Description, setDescription] = useState('');
  const [DeductionId, setId] = useState('');
  const history = useHistory();

      let { id } = useParams();

      const updateProduct  = async (e) => {
        e.preventDefault();
        let ETTitle=event.target["txtTitle"].value; 
        let ETDescription=event.target["txtDescription"].value; 
        let ETId=event.target["hdfDeductionId"].value;  
      
        if(ETTitle!='')
        {
          const Deductiontime = {
            DeductionId:ETId==''?'0':ETId,
            Title: ETTitle,
            Description: ETDescription,
            Flag:"A"       
        }

            axios.post(global.APIURL+'/DeductionUpdate', Deductiontime)
            .then(res => 
            {
                    alert(res.data);
                    history.push("/DeductionTime");
            });
        }
    }

    useEffect(() => {
        if(id!=null)
        {
          if(id!="0")
          {
            let newid = id.split(':'); 
            getUserTyprById(newid[1]);
          }
          else{
            history.push("/DeductionTime");
          }
        }
        else{
          history.push("/DeductionTime");
        }
    }, []);
 
    const getUserTyprById = async (UTId) => {
        var AUrl=global.APIURL+'/Deduction/'+UTId;
        axios.get(AUrl)  
        .then(res => {  
          setId(res.data[0].DeductionId)
          setTitle(res.data[0].Title);
          setDescription(res.data[0].Description);
        })
    }

    return (
      <div className="page-content">
  
        <MetaTags>
          <title>Deduction | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Edit Deduction </CardTitle>
                <a Link to="/" className="me-2">Home</a>
                <span>/</span>
                <a Link to="/DeductionTime"><span className="ms-2 me-2">Deduction</span></a>
                <span>/</span>
                <span className="ms-2">Edit Deduction</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" Link to="/DeductionTime">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={updateProduct}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                <Row>
                  <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Title</label>
                        <Input type="text" className="form-control" id="txtTitle" defaultValue={Title} placeholder="Enter Title"/>
                      </div>
                    </Col>
                   
                    <Col lg={12}>
                      <div className="mb-2">
                        <label className="form-label">Description</label>
                        <Input type="textarea" className="form-control" id="txtDescription" defaultValue={Description} placeholder="Enter Description"/>
                      </div>
                    </Col>
                    <Col lg={12}>
                        <input type="hidden" id="hdfDeductionId" value={DeductionId} />    
                        <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                        <a className="btn btn_danger btn-sm ms-2" href="/">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
      </div>
    )
}

export default EditDeductionTime;