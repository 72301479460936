import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  CardTitle,
  Input,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class UiNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",

      showDuration: 300,
      hideDuration: 1000,
      timeOut: 5000,
      extendedTimeOut: 1000,
    };
    this.showToast.bind(this);
    this.clearToast.bind(this);
  }

  showToast() {
    var ele = document.getElementsByName("toastType");
    var position = document.getElementsByName("positions");
    var toastType;
    var title = document.getElementById("title").value;
    var message = "Have fun storming the castle!";

    if (document.getElementById("message").value !== "")
      message = document.getElementById("message").value;

    //Close Button
    var closeButton = document.getElementById("closeButton").checked;

    //Add behavior on toast click Button
    var behaviorButton = document.getElementById("behaviorButton").checked;

    //Debug
    var debug = document.getElementById("debugInfo").checked;

    //Progressbar
    var progressBar = document.getElementById("progressBar").checked;

    //Duplicates
    var preventDuplicates = document.getElementById("preventDuplicates").checked;

    //Newest on Top
    var newestOnTop = document.getElementById("newestOnTop").checked;

    //position class
    var positionClass = "toast-top-right";

    //Fetch position
    for (var p = 0; p < position.length; p++) {
      if (position[p].checked) positionClass = position[p].value;
    }

    //Show Easing
    var showEasing = document.getElementById("showEasing").value;

    //Hide Easing
    var hideEasing = document.getElementById("hideEasing").value;

    //show method
    var showMethod = document.getElementById("showMethod").value;

    //Hide method
    var hideMethod = document.getElementById("hideMethod").value;

    //show duration
    var showDuration = document.getElementById("showDuration").value;

    //Hide duration
    var hideDuration = document.getElementById("hideDuration").value;

    //timeout
    var timeOut = document.getElementById("timeOut").value;

    //extended timeout
    var extendedTimeOut = document.getElementById("extendedTimeOut").value;

    //Fetch checked Type
    for (var i = 0; i < ele.length; i++) {
      if (ele[i].checked) toastType = ele[i].value;
    }

    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      behaviorButton: behaviorButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  clearToast() {
    toastr.clear();
  }

  render() {
    return (
      <React.Fragment>
          <div className="page-content">
          <MetaTags>
            <title>Leave Type | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h6">Leave Type</CardTitle>
                      <a href="/invoices-list" className="me-2">Home</a> <span>/</span><span className="ms-2">Leave Type</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="/"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>
            <Row>
              
              <Col className="col-12">
                <Card>
                  <CardBody>
                     {/* User list */}
                     <Row>
                     <Col lg={4}>
                          <div className="mb-3">
                            <select id="inputState" className="form-select">
                              <option selected>Leave Type</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                     <Col lg={4}>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Short Name"
                            />
                          </div>
                        </Col>
                     </Row>
                     <Row>
                       <Col lg={6}>
                         <p>Eligibile Employee Type</p>
                          <div className="emp-radio-btn d-flex">
                            <div className="form-check form-1 mb-2">
                              <input
                                type="checkbox"
                                className="form-check-input input-mini"
                                id="closeButton"
                                value="checked"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="closeButton"
                              >
                                On Probation
                              </Label>
                            </div>
                            <div className="form-check mb-2 ms-2">
                              <input
                                type="checkbox"
                                className="form-check-input input-mini"
                                id="closeButton"
                                value="checked"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="closeButton"
                              >
                                Confirmed
                              </Label>
                            </div>
                            <div className="form-check mb-2 ms-2">
                              <input
                                type="checkbox"
                                className="form-check-input input-mini"
                                id="closeButton"
                                value="checked"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="closeButton"
                              >
                                Freelancer
                              </Label>
                            </div>
                            <div className="form-check mb-2 ms-2">
                              <input
                                type="checkbox"
                                className="form-check-input input-mini"
                                id="closeButton"
                                value="checked"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="closeButton"
                              >
                                All
                              </Label>
                            </div>
                          </div>
                       </Col>
                     </Row>
                     <Row>
                       <Col lg={6}>
                         <p className="mt-3 mb-3">Carry Forward</p>
                          <div className="d-flex">
                          <div className="form-check mb-2">
                              <input
                                type="radio"
                                id="radio1"
                                name="toastType"
                                className="form-check-input"
                                value="success"
                                defaultChecked
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio1"
                              >
                                Yes
                              </Label>
                            </div>

                            <div className="form-check mb-2 ms-2">
                              <input
                                type="radio"
                                id="radio2"
                                name="toastType"
                                className="form-check-input"
                                value="info"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio2"
                              >
                                No
                              </Label>
                            </div>
                          </div>
                       </Col>
                     </Row>
                     <Row>
                       <Col lg={6}>
                         <p className="mt-3 mb-3">Credit Type</p>
                          <div className="d-flex">
                          <div className="form-check mb-2">
                              <input
                                type="radio"
                                id="radio1"
                                name="toastType"
                                className="form-check-input"
                                value="success"
                                defaultChecked
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio1"
                              >
                                Yearly
                              </Label>
                            </div>

                            <div className="form-check mb-2 ms-2">
                              <input
                                type="radio"
                                id="radio2"
                                name="toastType"
                                className="form-check-input"
                                value="info"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio2"
                              >
                                Half Yearly
                              </Label>
                            </div>
                            <div className="form-check mb-2 ms-2">
                              <input
                                type="radio"
                                id="radio2"
                                name="toastType"
                                className="form-check-input"
                                value="info"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio2"
                              >
                                Quartely
                              </Label>
                            </div>
                            <div className="form-check mb-2 ms-2">
                              <input
                                type="radio"
                                id="radio2"
                                name="toastType"
                                className="form-check-input"
                                value="info"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="radio2"
                              >
                                Monthly
                              </Label>
                            </div>
                          </div>
                       </Col>
                     </Row>
                      <Row>
                        <Col className=" col-md-4 col-12 mt-3">
                <div className ="d-flex align-items-center
                 justify-content-between">
                   
                      
                  <div className ="d-flex">
                  <div  className="form-check">
                                          <input className ="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                    </div>
                    <a className ="btn btn-primary btn-sm" href="/">Save</a>
                    <a className ="btn btn-primary btn-sm ms-2" href="/">Clear</a>
                  </div>
                </div>
              
              </Col>
                      </Row>

                      <Row className="mt-4">
                          <Col className="col-12">
                              <div className ="d-flex justify-content-end">
                                <div className="border-bottom ms-4">
                                        Search <i className="mdi mdi-filter h4 ms-3"></i>
                                </div>
                              </div>
                          </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col lg={12}>
                        <div className="table-responsive">
               <table className="table table-striped">
                      <thead>
                        <tr className="">
                          <th scope="col">Sr</th>
                          <th scope="col" className="">Leave Type</th>
                          <th scope="col" className="">Short Name</th>
                          <th scope="col" className="">Credit Type</th>
                          <th scope="col" className="">Eligibility</th>
                          <th scope="col" className="">Carry Forward</th>
                          <th scope="col" className="">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" className="">1</th>
                          <td>Casual Leave</td>
                          <td>CL</td>
                          <td>Monthly</td>
                          <td>All</td>
                          <td>Yes</td>
                          <td className="width-9">
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil h4 me-3"></i></a>
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-check-box-outline h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="">2</th>
                          <td>Sick Leave</td>
                          <td>SL</td>
                          <td>Yearly</td>
                          <td>Only Confirmed</td>
                          <td>No</td>
                          <td className="width-9">
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-pencil h4 me-3"></i></a>
                              <div  className="form-check">
                              <a href=""><i className="mdi mdi-check-box-outline h4 me-3"></i></a>
                                    </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                  </table>
               </div>
                        </Col>
                      </Row>
                     
                               <nav className="mt-3" aria-label="Page navigation example">
  <ul className="pagination justify-content-center">
    <li className="page-item disabled">
      <a className="page-link" href="#" tabIndex ="-1" aria-disabled="true">Previous</a>
    </li>
    <li className="page-item"><a className="page-link" href="#">1</a></li>
    <li className="page-item"><a className="page-link" href="#">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item">
      <a className="page-link" href="#">Next</a>
    </li>
  </ul>
</nav>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UiNotifications;
