import React, { Fragment, useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input,Form } from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactDatatable from '@ashvin27/react-datatable';
import { orderBy } from 'lodash';
import axios from 'axios'
import { useHistory } from 'react-router-dom';

const OnSort = () => {
  const history = useHistory();

  const changePassword = async () => {
    let currentPwd = document.getElementById("txtCurrentPassword").value;
    let newPwd = document.getElementById("txtNewPassword").value;
    let confirmPwd = document.getElementById("txtConfirmPassword").value;

     
    if (newPwd == confirmPwd) {

      const ChangePwdData = {
        UserId: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
        CurrentPassword: currentPwd,
        NewPassword: newPwd
      }

      axios.post(global.APIURL + "/UserChangePassword", ChangePwdData)
        .then(res => {
          alert(res.data[0].RecordStatus);
          document.getElementById("txtCurrentPassword").value = "";
          document.getElementById("txtNewPassword").value = "";
          document.getElementById("txtConfirmPassword").value = "";
        });
    }
    else {
      alert("New Password and confirm Password did not match");
    }
  }

  const clear = () => {
    document.getElementById("txtCurrentPassword").value = "";
    document.getElementById("txtNewPassword").value = "";
    document.getElementById("txtConfirmPassword").value = "";
  }

  return (
    <div className="page-content">

      <MetaTags>
        <title>Change Password | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Change Password</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <span className="ms-2">Change Password</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
              <Form onSubmit={changePassword}>
                <Row>
                  <Col lg={4}>
                    <div className="mb-2">
                      <label className="form-label">Current Password</label>
                      <Input type="text" className="form-control" id="txtCurrentPassword" placeholder="Enter Current Password" required/>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-2">
                      <label className="form-label">New Password</label>
                      <Input type="text" className="form-control" id="txtNewPassword" placeholder="Enter New Password" required/>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-2">
                      <label className="form-label">Confirm Password</label>
                      <Input type="text" className="form-control" id="txtConfirmPassword" placeholder="Enter Confirm Password" required/>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <button className="btn btn_success btn-sm" id="btnsave">Save</button>
                    <a className="btn btn_danger btn-sm ms-2" target="_self" onClick={clear}>Clear</a>
                  </Col>
                </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    </div>
  )
}


export default OnSort;