import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import img1 from "assets/images/users/avatar-1.jpg";
import img2 from "assets/images/PAN.png";
import img3 from "assets/images/pdf.png";
import bank from "assets/images/bank-nm.png";
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import ReactDatatable from '@ashvin27/react-datatable';
//Import Breadcrumb
import { Col, Row, CardTitle, Card, CardBody, Input } from "reactstrap";


import Bank_source from "assets/images/bank-nm.png";

// Rating Plugin

const UiRating = () => {
  const history = useHistory();
  const [LeadFollowupList, setLeadFollowupList] = useState([]);
  const [FullName, setFullName] = useState('');
  const [LeadCode, setLeadCode] = useState('');
  const [LeadProfileImage, setLeadProfileImage] = useState('');  
  const [ClientBriefing, setClientBriefing] = useState('');
  const [NotesforNextFollowup, setNotesforNextFollowup] = useState('');
  const [FollowUpId, setFollowUpId] = useState('0');

  const [NextFollowupDate, setNextFollowupDate] = useState('');
  const [NextFollowupTime, setNextFollowupTime] = useState('');
  const [NextFollowupMode, setNextFollowupMode] = useState('');
  const [NextFollowupNote, setNextFollowupNote] = useState('');

  const [ConvertDate, setConvertDate] = useState('');
  const [ConvertRemark, setConvertRemark] = useState('');

  const [CloseDate, setCloseDate] = useState('');
  const [CloseRemark, setCloseRemark] = useState('');





  useEffect(() => {
    let VFollowupLeadId = localStorage.getItem("FollowupLeadId");
    let VFollowupId = localStorage.getItem("FollowUpId");
    if (VFollowupLeadId != null) {
      if (VFollowupLeadId != "0") {
        getLeadDetailById(VFollowupLeadId);
        getLeadFollowupList(VFollowupLeadId);
        getLeadFollowupDetail(VFollowupId);
      }
      else {
        history.push("/MyFollowUp");
      }
    }
    else {
      history.push("/MyFollowUp");
    }
  }, []);

  const getLeadDetailById = async (LId) => {
    var AUrl=global.APIURL+'/WebFollowupLeadDetail/'+LId;
    axios.get(AUrl)
      .then(res => {
        setFullName(res.data[0].FullName);
        setLeadCode(res.data[0].LeadCode);
        setLeadProfileImage(res.data[0].LeadProfileImage);
        setClientBriefing(res.data[0].ClientBriefing==null?'':res.data[0].ClientBriefing);
        document.getElementById("txtMobileNumber").value =res.data[0].LeadMobileNumber==null?'':res.data[0].LeadMobileNumber;
        document.getElementById("txtEmailAddress").value =res.data[0].LeadEmailAddress==null?'':res.data[0].LeadEmailAddress;
        document.getElementById("txtGender").value =res.data[0].Gender==null?'':res.data[0].Gender;
        document.getElementById("txtType").value =res.data[0].LeadType==null?'':res.data[0].LeadType;
        document.getElementById("txtCIRID").value =res.data[0].CIRID==null?'':res.data[0].CIRID;
        document.getElementById("txtState").value =res.data[0].StateName==null?'':res.data[0].StateName;
        document.getElementById("txtDistrict").value =res.data[0].DistrictName==null?'':res.data[0].DistrictName;
        document.getElementById("txtCity").value =res.data[0].CityName==null?'':res.data[0].CityName;
        document.getElementById("txtTehsil").value =res.data[0].TehsilName==null?'':res.data[0].TehsilName;
        document.getElementById("txtPinCode").value =res.data[0].PinCode==null?'':res.data[0].PinCode;
      })
  }

  const getLeadFollowupList = async (LId) => {
    var AUrl=global.APIURL+'/WebFollowupListByLeadId/'+LId;
    fetch(AUrl)
    .then((res) => res.json())
    .then((json) => {
      setLeadFollowupList(json)
    })
  }

  const getLeadFollowupDetail = async (FId) => {
    var AUrl=global.APIURL+'/WebFollowupEdit/'+FId;
    axios.get(AUrl)
      .then(res => {
        setNotesforNextFollowup(res.data[0].Agenda);
        var VPreviousFollowupId =res.data[0].FollowUpId==null?'0':res.data[0].FollowUpId==''?'0':res.data[0].FollowUpId;
        localStorage.setItem('PreviousFollowupId', VPreviousFollowupId);
      })
  }

  const LeadFollowUpSave = async () => {
    let VFollowupLeadId = localStorage.getItem("FollowupLeadId");
    let VPreviousFollowupId = localStorage.getItem("PreviousFollowupId");
    var VPreviousFollowupRemark=document.getElementById("txtPreviousFollowupRemark").value==null?'':document.getElementById("txtPreviousFollowupRemark").value==''?'':document.getElementById("txtPreviousFollowupRemark").value;
    if(VPreviousFollowupRemark!='')
    {
      const LeadPFollowUpArr =
      {
        FollowUpId: VPreviousFollowupId,
        Remark: VPreviousFollowupRemark,
        Status: "C",
        CreateUser: localStorage.getItem("LoginUserId")==null?"0":localStorage.getItem("LoginUserId")==""?"0":localStorage.getItem("LoginUserId")
      }
      axios.post(global.APIURL + "/LeadPreviousFollowupUpdate", LeadPFollowUpArr).then(res => 
      {
        if (res.data.length>0) 
        {
          const LeadFollowUpArr =
          {
            LeadId: VFollowupLeadId,
            ScheduledDate: NextFollowupDate,
            ScheduledTime: NextFollowupTime,
            Mode: NextFollowupMode,
            Agenda:NextFollowupNote,
            Remark: '',
            Status: "P",
            CreateUser: localStorage.getItem("LoginUserId")==null?"0":localStorage.getItem("LoginUserId")==""?"0":localStorage.getItem("LoginUserId")
          }
          axios.post(global.APIURL + "/LeadFollowUpAdd", LeadFollowUpArr).then(res => 
            {
              if (res.data.length>0) {
                history.push("/MyFollowUp");
              }
            }); 
        }
      });    
    }
    else
    {
      alert("Please write remark after add new follow-up.");
    }
  };

  const LeadConvertSave = async () => {
    let VFollowupLeadId = localStorage.getItem("FollowupLeadId");
    var VPreviousFollowupRemark=document.getElementById("txtPreviousFollowupRemark").value==null?'':document.getElementById("txtPreviousFollowupRemark").value==''?'':document.getElementById("txtPreviousFollowupRemark").value;
    if(VPreviousFollowupRemark!='')
    {
      const LeadPFollowUpArr =
      {
        FollowUpId: document.getElementById("txtPreviousFollowupId").value==null?'0':document.getElementById("txtPreviousFollowupId").value==''?'0':document.getElementById("txtPreviousFollowupId").value,
        Remark: VPreviousFollowupRemark,
        Status: "C",
        CreateUser: localStorage.getItem("LoginUserId")==null?"0":localStorage.getItem("LoginUserId")==""?"0":localStorage.getItem("LoginUserId")
      }

      axios.post(global.APIURL + "/LeadPreviousFollowupUpdate", LeadPFollowUpArr).then(res => 
      {
        if (res.data.length>0) 
        {
          const LeadConvertArr =
          {
            LeadId: VFollowupLeadId,
            ConvertDate: ConvertDate,
            Remark: ConvertRemark,
            CreateUser: localStorage.getItem("LoginUserId")==null?"0":localStorage.getItem("LoginUserId")==""?"0":localStorage.getItem("LoginUserId")
          }
          axios.post(global.APIURL + "/LeadConvertUpdate", LeadConvertArr)
            .then(res => {
              if (res.data.length>0) {
                alert(res.data[0].RecordStatus);
                history.push("/MyFollowUp");
              }
            }); 
        }
      });    
    }
    else
    {
      alert("Please write remark after convert.");
    } 
  };


  const LeadCloseSave = async () => {
    let VFollowupLeadId = localStorage.getItem("FollowupLeadId");
    var VPreviousFollowupRemark=document.getElementById("txtPreviousFollowupRemark").value==null?'':document.getElementById("txtPreviousFollowupRemark").value==''?'':document.getElementById("txtPreviousFollowupRemark").value;
    if(VPreviousFollowupRemark!='')
    {
      const LeadPFollowUpArr =
      {
        FollowUpId: document.getElementById("txtPreviousFollowupId").value==null?'0':document.getElementById("txtPreviousFollowupId").value==''?'0':document.getElementById("txtPreviousFollowupId").value,
        Remark: VPreviousFollowupRemark,
        Status: "C",
        CreateUser: localStorage.getItem("LoginUserId")==null?"0":localStorage.getItem("LoginUserId")==""?"0":localStorage.getItem("LoginUserId")
      }

      axios.post(global.APIURL + "/LeadPreviousFollowupUpdate", LeadPFollowUpArr).then(res => 
      {
        if (res.data.length>0) 
        {
          const LeadCloseArr =
          {
            LeadId: VFollowupLeadId,
            CloseDate: CloseDate,
            Remark: CloseRemark,
            CreateUser: localStorage.getItem("LoginUserId")==null?"0":localStorage.getItem("LoginUserId")==""?"0":localStorage.getItem("LoginUserId")
          }
          axios.post(global.APIURL + "/LeadCloseUpdate", LeadCloseArr)
            .then(res => {
              if (res.data.length>0) {
                alert(res.data[0].RecordStatus);
                history.push("/MyFollowUp");
              }
            });
        }
      });    
    }
    else
    {
      alert("Please write remark after close.");
    } 
    
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Client Follow-Up Information | KC Admin App</title>
        </MetaTags>
        <div className="container-fluid">
          <div className="modal fade" id="edit" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Remark</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <Row>
                      <Col className="col-lg-12">
                        <div className="mb-3">
                          <label htmlFor="exampleFormControlTextarea1" className="form-label">Remarks</label>
                          {/* <textarea className="form-control" id="txtRemarks"  rows="3"></textarea> */}
                          <Input type="text" className="" id="txtRemarkscheck" />
                    
                        </div>
                      </Col>
                      <Col className="text-center">
                        <button type="button" className="btn btn-primary">Submit</button>
                      </Col>
                    </Row>
                </div>

              </div>
            </div>
          </div>
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle>Client Follow-Up Information</CardTitle>
                <a href="#" className="me-2">
                  My Follow Up
                </a>{" "}
                <span>/</span>
                <span className="ms-2">Client Follow-Up Information</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a
                className="btn btn-primary btn-sm float-end font-14"
                href="/MyFollowUp"
              >
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>


          <Row>
            <Col className="col-lg-12 col-12">
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col className="col-md-10 col-12">
                      <Row>
                        <Col className="col-12 d-none d-md-block">
                          <h5 className="fw-bolder mb-2">{FullName} <span className="text-primary">({LeadCode})</span></h5>
                          <hr />
                        </Col>                        
                      </Row>
                      <Row>
                        <Col className="col-md-3 col-12 mb-md-0 mb-3">
                          <p className="mb-2 fontWeight500"> Mobile Number </p>
                          <Input type="text" className="" readOnly id="txtMobileNumber"/>
                        </Col>
                        <Col className="col-md-3 col-12 mb-md-0 mb-3">
                          <p className="mb-2 fontWeight500"> Email Address </p>
                          <Input type="text" className="" readOnly id="txtEmailAddress"/>
                        </Col>
                        <Col className="col-lg-3">
                          <p className="mb-2 fontWeight500"> Gender </p>
                          <Input type="text" className="" id="txtGender" readOnly/>
                        </Col>
                        <Col className="col-md-3 col-12 mb-md-0 mb-3">
                          <p className="mb-2 fontWeight500"> Type </p>
                          <Input type="text" readOnly className="" id="txtType"/>
                        </Col>
                      </Row>                       
                    </Col>
                    <Col className="col-md-2 col-lg-2 col-12 float-end d-none d-md-block">
                            <div className="">
                              <img
                                className="rounded-circle float-end clientactimg"
                                src={LeadProfileImage}
                              ></img>
                            </div>
                          </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-lg-12 col-md-12 col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-lg-12">
                      <CardTitle>Personal Details</CardTitle>
                      <hr />
                    </Col>                            
                    <Col className="col-lg-2">
                      <p className="mb-2 fontWeight500"> CIR ID </p>
                      <Input type="text" className="" id="txtCIRID" readOnly/>
                    </Col>
                    <Col className="col-lg-2">
                      <p className="mb-2 fontWeight500"> State </p>
                      <Input type="text" className="" id="txtState" readOnly/>
                    </Col>
                    <Col className="col-lg-2">
                      <p className="mb-2 fontWeight500"> District </p>
                      <Input type="text" className="" id="txtDistrict" readOnly/>
                    </Col>
                    <Col className="col-lg-2">
                      <p className="mb-2 fontWeight500"> City </p>
                      <Input type="text" className="" id="txtCity" readOnly/>
                    </Col>
                    <Col className="col-lg-2">
                      <p className="mb-2 fontWeight500"> Tehsil </p>
                      <Input type="text" className="" id="txtTehsil" readOnly/>
                    </Col>
                    <Col className="col-lg-2">
                      <p className="mb-2 fontWeight500"> PinCode </p>
                      <Input type="text" className="" id="txtPinCode" readOnly/>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-lg-6">
            <Card>
                      <CardBody>
                        <div className="">
                          <CardTitle>Lead Followup</CardTitle>
                          <hr />
                        </div>
                        <Row>
                          <ul className="nav client-detail-firm border-0 nav-tabs leadfollowuptype" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button className="nav-link border-0 active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"> <div className="form-check me-1 form-check-inline mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radio1"
                                  id="1"
                                  value="success"
                                  checked
                                />
                                <label className="form-check-label">
                                  Followup
                                </label>
                              </div></button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link border-0" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"> <div className="form-check me-1 form-check-inline  mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radio1"
                                  id="2"
                                  value="success"
                                />
                                <label className="form-check-label">
                                  Convert
                                </label>
                              </div></button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link border-0" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">  <div className="form-check me-1 form-check-inline  mb-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radio1"
                                  id="3"
                                  value="success"
                                />
                                <label className="form-check-label"> Close</label>
                              </div></button>
                            </li>
                          </ul>
                          <Col>

                            <div className="tab-content" id="myTabContent">
                              <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <Row>
                                  <Col>
                                    {" "}
                                    <CardTitle className="mt-2">Set Followup</CardTitle>
                                    <hr />
                                  </Col>
                                </Row>
                                <Row>
                                  <div className="col-md-12 mb-2">
                                    <label className=""> Next Follow up Date </label>
                                    <input type="date" className="form-control" placeholder="" onChange={e => setNextFollowupDate(e.target.value)}/>
                                  </div>

                                  <div className="col-md-12 mb-2 mt-2">
                                    <label className=""> Next Follow up Time </label>
                                    <input type="time" className="form-control" placeholder="" onChange={e => setNextFollowupTime(e.target.value)}/>
                                  </div>
                                  <div className="col-md-12 mb-2 mt-2">
                                    <label className=""> Next Follow up Mode </label>
                                    <select className="form-select" aria-label="Default select example" onChange={e => setNextFollowupMode(e.target.value)}>
                                      <option selected>Select Mode</option>
                                      <option value="Call">Call</option>
                                      <option value="Email">Email</option>
                                      <option value="Meeting">Meeting</option>
                                    </select>
                                  </div>
                                </Row>
                                <Row>
                                  <div className="mb-3 mt-2">
                                    <label className="">{" "}Notes for Next Follow-up{" "}</label>
                                    <textarea className="form-control" placeholder="Enter Notes for Next Follow-up" onChange={e => setNextFollowupNote(e.target.value)}>{" "}</textarea>
                                  </div>
                                </Row>
                                <Row>
                                  <div className="">
                                    <button className="btn btn_success me-2" onClick={LeadFollowUpSave}>{" "}Submit{" "}</button>
                                    <button className="btn btn_danger me-2">{" "}Cancel{" "}</button>
                                  </div>
                                </Row>
                              </div>
                              <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <Row>
                                  <Col>
                                    {" "}
                                    <CardTitle className="mt-2">Lead to Convert</CardTitle>
                                    <hr />
                                  </Col>
                                </Row>
                                <Row>
                                  <div className="col-md-12 mb-2">
                                    <label className=""> Convert Date </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      onChange={e => setConvertDate(e.target.value)}
                                    />
                                  </div>
                                </Row>
                                <Row>
                                  <div className="mb-3 mt-2">
                                    <label className="">
                                      Remarks
                                    </label>
                                    <textarea
                                      className="form-control"
                                      placeholder="Enter Notes for Next Follow-up"
                                      id="txtRemarks"
                                      onChange={e => setConvertRemark(e.target.value)}
                                    >
                                      {" "}
                                    </textarea>
                                  </div>
                                </Row>
                                <Row>
                                  <div className="">
                                    <button className="btn btn_success me-2" onClick={LeadConvertSave}>
                                      {" "}
                                      Submit{" "}
                                    </button>
                                    <button href="#" className="btn btn_danger me-2">
                                      {" "}
                                      Cancel{" "}
                                    </button>
                                  </div>
                                </Row>
                              </div>
                              <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                <Row>
                                  <Col>
                                    {" "}
                                    <CardTitle className="mt-2">Lead to Close</CardTitle>
                                    <hr />
                                  </Col>
                                </Row>
                                <Row>
                                  <div className="col-md-12 mb-2">
                                    <label className=""> Closure Date </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      onChange={e => setCloseDate(e.target.value)}
                                    />
                                  </div>
                                </Row>
                                <Row>
                                  <div className="mb-3 mt-2">
                                    <label className="">
                                      {" "}
                                      Reason For Closure{" "}
                                    </label>
                                    <textarea
                                      className="form-control"
                                      placeholder="Enter Notes for Next Follow-up"
                                      onChange={e => setCloseRemark(e.target.value)}
                                    >
                                      {" "}
                                    </textarea>
                                  </div>
                                </Row>
                                <Row>
                                  <div className="">
                                    <button className="btn btn_success me-2" onClick={LeadCloseSave}>
                                      {" "}
                                      Submit{" "}
                                    </button>
                                    <button href="#" className="btn btn_danger me-2">
                                      {" "}
                                      Cancel{" "}
                                    </button>
                                  </div>
                                </Row>
                              </div>
                            </div>

                          </Col>
                        </Row>

                      </CardBody>
                    </Card>
            </Col>
            <Col className="col-lg-6">
            <Card>
                      <CardBody>
                        <div className="">
                          <CardTitle>Client Briefing</CardTitle>
                          <hr />
                        </div>
                        <Row>
                          <Col className="col-lg-12">
                            <div className="mb-3">
                              {ClientBriefing}
                            </div>
                            <hr />
                          </Col>
                          
                        </Row>
                        <div className="">
                          <CardTitle>Notes for Next Follow-up</CardTitle>
                          <hr />
                        </div>
                        <Row>
                          <Col className="col-lg-12">
                            <div className="mb-3">
                              {NotesforNextFollowup}
                            </div>
                            <hr />
                          </Col>
                        </Row>
                        <div className="">
                          <CardTitle>Remarks</CardTitle>
                          <hr />
                        </div>
                        <Row>
                          <Col className="col-lg-12">
                            <div className="mb-3">
                              <textarea className="form-control" id="txtPreviousFollowupRemark" placeholder="Enter Remark"></textarea>
                              <input type="hidden" className="form-control" id="txtPreviousFollowupId" readOnly value="0"/>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
            </Col>
          </Row>


          <Row>

            <Card>
              <CardBody>
                <CardTitle>Followup List</CardTitle>
                <hr />

                <Col className="col-lg-12">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th className="text-center" scope="col">#</th>
                          <th className="text-center" scope="col">Date</th>
                          <th className="text-center" scope="col">Time</th>
                          <th className="text-center" scope="col">Mode</th>
                          <th scope="col">Notes for Next Follow-up</th>
                          <th scope="col">Remarks</th>
                          <th className="text-center" scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {LeadFollowupList.map((record, index) => {
                          return (
                            <tr key={record.RowNo}>
                              <td className="text-center">{record.RowNo}</td>
                              <td className="text-center">{record.ScheduledDate}</td>
                              <td className="text-center">{record.ScheduledTime}</td>
                              <td className="text-center">{record.Mode}</td>
                              <td>{record.Agenda}</td>
                              <td>{record.Remark}</td>
                              <td className="text-center"> <a
                                href="/EditFollowUp"
                                data-bs-toggle="modal"
                                data-bs-target="#edit"
                                className="btn btn_success me-2 btn-sm-height"
                               
                              >
                                {" "}
                                Edit{" "}
                              </a></td>
                            </tr>
                          )
                        })}
                      </tbody>

                    </table>
                  </div>

                </Col>
              </CardBody>
            </Card>
          </Row>


        </div>
      </div>
    </React.Fragment>
  );
}

export default UiRating;
