import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label,
  Input,
} from "reactstrap"

import { Link } from "react-router-dom"



import classnames from "classnames"

class UiTabsAccordions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col5: true,
      col6: true,
      col7: true,
      col8: true,
      col9: true,
      col10: false,
      col11: false,
    }
    this.toggle = this.toggle.bind(this)
    this.toggle1 = this.toggle1.bind(this)

    this.t_col1 = this.t_col1.bind(this)
    this.t_col2 = this.t_col2.bind(this)
    this.t_col3 = this.t_col3.bind(this)
    this.t_col5 = this.t_col5.bind(this)
    this.t_col6 = this.t_col6.bind(this)
    this.t_col7 = this.t_col7.bind(this)
    this.t_col8 = this.t_col8.bind(this)
    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)

    this.toggle2 = this.toggle2.bind(this)
    this.toggle3 = this.toggle3.bind(this)

    this.toggleVertical = this.toggleVertical.bind(this)
    this.toggleVerticalIcon = this.toggleVerticalIcon.bind(this)
    this.toggleCustom = this.toggleCustom.bind(this)
    this.toggleIconCustom = this.toggleIconCustom.bind(this)
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false
    })
  }

  t_col2() {
    this.setState({
      col1: false,
      col2: !this.state.col2,
      col3: false
    })
  }

  t_col3() {
    this.setState({
      col1: false,
      col2: false,
      col3: !this.state.col3
    })
  }

  t_col5() {
    this.setState({ col5: !this.state.col5 })
  }

  t_col6() {
    this.setState({ col6: !this.state.col6 })
  }

  t_col7() {
    this.setState({ col7: !this.state.col7 })
  }

  t_col8() {
    this.setState({
      col6: !this.state.col6,
      col7: !this.state.col7
    })
  }

  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false
    })
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false
    })
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      })
    }
  }

  toggle2(tab) {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      })
    }
  }

  toggle3(tab) {
    if (this.state.activeTab3 !== tab) {
      this.setState({
        activeTab3: tab,
      })
    }
  }

  toggleVertical(tab) {
    if (this.state.verticalActiveTab !== tab) {
      this.setState({
        verticalActiveTab: tab,
      })
    }
  }

  toggleVerticalIcon(tab) {
    if (this.state.verticalActiveTabWithIcon !== tab) {
      this.setState({
        verticalActiveTabWithIcon: tab,
      })
    }
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      })
    }
  }

  toggleIconCustom(tab) {
    if (this.state.customIconActiveTab !== tab) {
      this.setState({
        customIconActiveTab: tab,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Client List | KC Admin Group</title>
          </MetaTags>
          <Container fluid={true}>
          <Row className="mb-3">
                <Col className="col-lg-8 col-md-6 col-6">
                  <div className="breadcrum ps-0">
                  <CardTitle className="h4">Edit Client</CardTitle>
                      <a href="/ClientList" className="me-2">Client List</a> <span>/</span><span className="ms-2">Edit Client</span>
                  </div>
                </Col>
                  <Col className="col-lg-4 co-md-6 col-6">
                    <a className ="btn btn-primary btn-sm float-end font-14" href="#"><i className="mdi mdi-arrow-left align-middle me-1"></i>Back</a>
                  </Col>
              </Row>

            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    
                  <div className="col-lg-4 col-md-4 col-6 col">
                            <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                    <label htmlFor="search-bar-0" className="search-label">
                                        <span id="search-bar-0-label" className="sr-only">Search this table</span>
                                        <input id="search-bar-0" type="text" aria-labelledby="search-bar-0-label" className="form-control" placeholder="Search" value="" />
                                    </label>
                                    <i className="bx bx-search-alt search-icon"></i>
                                </div>
                            </div>
                        </div>
                       
                    <Nav tabs className="nav-tabs-custom nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "1",
                          })}
                          onClick={() => {
                            this.toggleCustom("1")
                          }}
                        >
                           <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                          <span className="d-none d-sm-block">Personal Information</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "2",
                          })}
                          onClick={() => {
                            this.toggleCustom("2")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                          <span className="d-none d-sm-block">Contact Information</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "3",
                          })}
                          onClick={() => {
                            this.toggleCustom("3")
                          }}
                        >
                          <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                          <span className="d-none d-sm-block">KYC</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.customActiveTab === "4",
                          })}
                          onClick={() => {
                            this.toggleCustom("4")
                          }}
                        >
                           <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                          <span className="d-none d-sm-block">Banking Details</span>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.customActiveTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row className="mt-3">
                          {/* <Col sm="12">
                          <div className ="d-flex logo-main float-end mb-3">
                              <div className="logo-box">
                                  <p className="mb-0">User</p>
                              </div>
                                <div className="icon-box">
                              <a href="">  <i className="mdi mdi-pencil"></i></a>
                                </div>
                        </div>
                          </Col> */}

                          <Col className="col-lg-10">
                            <Row>
                            <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">First Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="First Name"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Middle Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Middle Name"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Last Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Last Name"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Gender</label>
                            <select id="inputState" className="form-select">
                              <option selected>Gender</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Marital Status</label>
                            <select id="inputState" className="form-select">
                              <option selected>Marital Status</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Select Parent</label>
                            <select id="inputState" className="form-select">
                              <option selected>Son Of</option>
                              <option>Wife of</option>
                              <option>Daughter of</option>
                            </select>
                          </div>
                        </Col>
                            </Row>
                          </Col>
                          <Col className="col-lg-2">
                          <label className="form-label d-block text-center">Upload Image</label>
                          <div className ="d-flex logo-main float-end">
                         
                              <div className="logo-box">
                                  <p className="mb-0">User</p>
                              </div>
                                <div className="icon-box">
                              <a href="">  <i className="mdi mdi-pencil"></i></a>
                                </div>
                        </div>
                          </Col>
                        
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Date of Birth</label>
                            <select id="inputState" className="form-select">
                              <option selected>Date of Birth</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Qualification</label>
                            <select id="inputState" className="form-select">
                              <option selected>Qualification</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Category</label>
                            <select id="inputState" className="form-select">
                              <option selected>Category</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Religion</label>
                            <select id="inputState" className="form-select">
                              <option selected>Religion</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Income</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Income(Per Month)"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Occupation</label>
                            <select id="inputState" className="form-select">
                              <option selected>Occupation</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">CIR ID</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="CIR ID"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">CIR Password</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="CIR Password"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Source</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Source"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Sub Source</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Sub Source"
                            />
                          </div>
                        </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="4">
                            <p className="mt-2">Mobile No.<i className="mdi mdi-plus-circle text-success h4 align-middle ms-2"></i> </p>
                            <div className="table-responsive">
               <table className="table table-borderless">
                      {/* <thead>
                        <tr className="">
                          <th scope="col">Sr</th>
                          <th scope="col" className="">Deduction Type</th>
                          <th scope="col" className="">Start Month/Year</th>
                          <th scope="col" className="">End Month/Year</th>
                          <th scope="col" className="">No of Installments</th>
                          <th scope="col" className="">Installment Amount</th>
                          <th scope="col" className="">Total Deduction</th>
                          <th scope="col" className="">Action</th>
                        </tr>
                      </thead> */}
                      <tbody>
                        <tr>
                          <td className="ps-0">+91 9878543212</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-close-circle text-danger h4 me-3"></i></a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="ps-0">Mobile No.</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-plus-circle text-success h4 me-3"></i></a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                  </table>
               </div>
                          </Col>
                          <Col sm="6" className="offset-lg-2">
                          
                            <div className="table-responsive">
               <table className="table table-borderless">
                      <thead>
                        <tr className="">
                          <th scope="col" className="">Email Address<i className="mdi mdi-plus-circle text-success h4 align-middle ms-2"></i> </th>
                          <th scope="col" className="">Password</th>
                          <th scope="col" className=""></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="">name@gmail.com</td>
                          <td className="">*******</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-close-circle text-danger h4 me-3"></i></a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="">Mobile No.</td>
                          <td className="">Password</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a href=""><i className="mdi mdi-plus-circle text-success h4 me-3"></i></a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                  </table>
               </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                        <Col lg={8}>
                <div className="mb-3">
                <label className="form-label">Add Remarks</label>
                            <Input
                              type="text"
                              className="form-control pb-5"
                              id="formrow-InputCity"
                              placeholder="Remarks"
                            />
                          </div>
                </Col>
               
                        </Row>
                        <Row>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Village/Town/City</label>
                            <select id="inputState" className="form-select">
                              <option selected>Village/Town/City</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                        <div className="mb-3">
                        <label className="form-label">Tehsil</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Tehsil"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">District</label>
                            <select id="inputState" className="form-select">
                              <option selected>District</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">state</label>
                            <select id="inputState" className="form-select">
                              <option selected>State</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                          <label className="form-label">Country</label>
                            <select id="inputState" className="form-select">
                              <option selected>Country</option>
                              <option>...</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>
                        <div className="mb-3">
                        <label className="form-label">Pincode</label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-InputCity"
                              placeholder="Pincode"
                            />
                          </div>
                        </Col>
                        </Row>
                        <Row>
                        <div className="h4 card-title mt-4">Documents</div>
                        <Col sm="12">
                            <div className="table-responsive">
                              <table className="table table-borderless">
                                      <thead className="">
                                        <tr className="lead-table-th">
                                          <th scope="col" className="">Type</th>
                                          <th scope="col" className="">No ( Uniq ID)</th>
                                          <th scope="col" className="">Upload</th>
                                          <th scope="col" className="">Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Passport</td>
                                          <td>L1L2356L8L9</td>
                                          <td>
                                            <div className="d-flex lead-box-1">
                                              <div className="ms-1 mt-1 mb-1">
                                              <div className="logo-box p-4"><p className="mb-0">Image</p></div>
                                              </div>
                                              <div className="ms-3">
                                                <p className="mb-1">lorem ipsum doler sit amet</p>
                                                <p className="mb-0">18KB</p>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="d-flex align-items-center">
                                              <a href=""><i className="mdi mdi-plus-circle text-success h4 me-3"></i></a>
                                              <a href=""><i className="mdi mdi-close-circle text-danger h4 me-3"></i></a>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td><div className="mb-3 w-90">
                            <select id="inputState" className="form-select">
                              <option selected>Type</option>
                              <option>...</option>
                            </select>
                          </div></td>
                                          <td>UID</td>
                                          <td>
                                            <div className="lead-box-1 p-4">
                                              <div className="text-center">
                                                <p className="mb-1">Upload</p>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="d-flex align-items-center">
                                              <a href=""><i className="mdi mdi-plus-circle text-success h4 me-3"></i></a>
                                              <a href=""><i className="mdi mdi-close-circle text-danger h4 me-3"></i></a>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                  </table>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row>
                          <Col sm="12">
                            <div className="table-responsive">
                              <table className="table table-borderless border-bottom">
                                      <thead>
                                        <tr className="">
                                          <th scope="col" className="">Bank</th>
                                          <th scope="col" className="">IFSC Code</th>
                                          <th scope="col" className="">Branch</th>
                                          <th scope="col" className="">A/c No</th>
                                          <th scope="col" className="">Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Bank Name</td>
                                          <td>894743217854326</td>
                                          <td>Branch Name</td>
                                          <td>123456987123</td>
                                          <td>
                                            <div className="d-flex align-items-center">
                                              <a href=""><i className="mdi mdi-plus-circle text-success h4 me-3"></i></a>
                                              <a href=""><i className="mdi mdi-close-circle text-danger h4 me-3"></i></a>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                  </table>

                                  <table className="table table-borderless border-bottom">
                                      <thead>
                                        <tr className="">
                                          <th scope="col" className="">Fees</th>
                                          <th scope="col" className="">Pending Fees</th>
                                          <th scope="col" className="">Received Fees</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>9,99,999</td>
                                          <td>5,00,000</td>
                                          <td>4,99,999</td>
                                        </tr>
                                      </tbody>
                                  </table>
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default UiTabsAccordions
