import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import axios from 'axios'
import { Col, Row, CardTitle, Card, CardBody, Input, Label, Button } from "reactstrap";
import { Link, useHistory } from 'react-router-dom';
import PlacholderImgC from "assets/images/favicon.png";

const UiRating = () => {
  const history = useHistory();
  const [EditLeadId, setEditLeadId] = useState('');
  const [LeadProfileImage, setLeadProfileImage] = useState("");
  const [LeadType, setLeadType] = useState('');
  const [LeadCode, setLeadCode] = useState('');
  const [DateOfBirth, setDateOfBirth] = useState("");
  const [CompanyEmailAddress, setCompanyEmailAddress] = useState('');
  const [CompanyMobileNumber, setCompanyMobileNumber] = useState('');
  const [CompanyPan, setCompanyPan] = useState("");
  const [CompanyGSTNo, setCompanyGSTNo] = useState("");
  const [CompanyTINNo, setCompanyTINNo] = useState("");
  const [CompanyType, setCompanyType] = useState('');
  const [CompanyName, setCompanyName] = useState('');
  const [Address, setAddress] = useState("");
  const [CIRID, setCIRID] = useState('');
  const [CIRPassword, setCIRPassword] = useState('');

  const [SCreateDate, setSCreateDate] = useState("");
  const [SConvertDate, setSConvertDate] = useState("");
  const [SRegisterDate, setSRegisterDate] = useState("");
  const [SCloseDate, setSCloseDate] = useState("");

  const [StateId, setStateId] = useState('');
  const [DistrictId, setDistrictId] = useState('');
  const [CityId, setCityId] = useState('');
  const [TehsilId, setTehsilId] = useState('');
  const [PinCode, setPinCode] = useState('');

  const [Reference, setReference] = useState('');
  const [Remarks, setRemarks] = useState('');
  const [ClientBriefing, setClientBriefing] = useState('');
  const [PriorityId, setPriorityId] = useState('');
  const [SelectTags, setSelectTags] = useState('');
  const [StateList, setStateList] = useState([]);
  const [DistrictList, setDistrictList] = useState([]);
  const [CityList, setCityList] = useState([]);
  const [TehsilList, setTehsilList] = useState([]);

  const [SelectChannelDetail, setSelectChannelDetail] = useState([]);
  const [SelectChannelPartnerDetail, setSelectChannelPartnerDetail] = useState([]);

  const [DropdownChannelList, setDropdownChannelList] = useState([]);
  const [DropdownChannelTypeList, setDropdownChannelTypeList] = useState([]);
  const [DropdownCityList, setDropdownCityList] = useState([]);
  const [DropdownChannelBranchList, setDropdownChannelBranchList] = useState([]);
  const [ModelChannelList, setModelChannelList] = useState([]);
  const [ModelChannelPartnerList, setModelChannelPartnerList] = useState([]);

  const [PriorityList, setPriorityList] = useState([]);
  const [TagsList, setTagsList] = useState([]);

  const [ChannelId, setChannelId] = useState("0");
  const [ChannelPartnerId, setChannelPartnerId] = useState("0");

  const [DocumentList, setDocumentList] = useState([]);
  const [LeadContactList, setLeadContactList] = useState([]);
  const [LeadEmailList, setLeadEmailList] = useState([]);
  const [LeadPartnerList, setLeadPartnerList] = useState([]);
  const [LeadPartnerDetailList, setLeadPartnerDetailList] = useState([]);

  const [AddTehsil, setAddTehsil] = useState('D');
  const [AddCity, setAddCity] = useState('D');

  const [LeadFollowupList, setLeadFollowupList] = useState([]);

  function onChangeCompanyTypeValue(event) {
    setCompanyType(event.target.value)
  }

  useEffect(() => {
    let LeadId = localStorage.getItem("ClosedFirmLeadId");
    if (LeadId != null) {
      if (LeadId != "0") {
        getMenuHideShow();
        getDocumentTypeList();
        setEditLeadId(LeadId);
        getStateList('1');
        getPriorityList();
        getTagsList();
        getLeadDetailById(LeadId);
        getSelectChannelDetailById(LeadId);
        getSelectChannelPartnerDetailById(LeadId);
        getLeadPartnerList(LeadId);
        getLeadContactList(LeadId);
        getLeadEmailList(LeadId);
        getLeadDocumentById(LeadId);
        getCountryList();
        getChannelTypeList();
        getChannelSubSourceDepartmentList();
        getChannelSubSourceDesignation();
        getChannelSubSourceCountryList();
        getDropdownChannelTypeList();
        getLeadFollowupList(LeadId);
      }
      else {
        history.push("/Retail/LeadList/AllInq");
      }
    }
    else {
      history.push("/Retail/LeadList/AllInq");
    }
  }, []);

  const getLeadFollowupList = async (LId) => {
    var AUrl = global.APIURL + '/WebFollowupListByLeadId/' + LId;
    fetch(AUrl)
      .then((res) => res.json())
      .then((json) => {
        setLeadFollowupList(json)
      })
  }

  const getMenuHideShow = async () => {
    const f = {
      MenuId: "4",
      UserTypeId: localStorage.getItem("LoginUserTypeId") == null ? "0" : localStorage.getItem("LoginUserTypeId") == "" ? "0" : localStorage.getItem("LoginUserTypeId")
    }

    axios.post(global.APIURL + '/HideShowWeb', f)
      .then(res => {
        for (let con = 0; con < res.data.length; con++) {
          const hidef = res.data[con];
          var MA = hidef.MenuAction;
          var MS = hidef.Status;
          if (MA == 'Add Tehsil') {
            setAddTehsil(MS);
          }
          else if (MA == 'Add City') {
            setAddCity(MS);
          }
        }
      });
  };

  const getDropdownChannelTypeList = async () => {
    axios.get(global.APIURL + "/ChannalTypeList")
      .then(CLres => {
        let VChannelTypeList = CLres.data.map(vcl => {
          return vcl;
        });
        setDropdownChannelTypeList(VChannelTypeList);
      });
  };

  const getModelLeadChannelList = async () => {
    var VChannelMasterId = document.getElementById("ddlFChannel").value;
    var VChannelTypeId = document.getElementById("ddlFChannelType").value;
    var VBranchId = document.getElementById("ddlFBranch").value;
    var VCityId = document.getElementById("ddlFCity").value;

    const LeadChannelFilter = {
      ChannelTypeId: VChannelTypeId,
      ChannelMasterId: VChannelMasterId,
      BranchId: VBranchId,
      CityId: VCityId
      //FilterSearchByName: "",
    };

    axios.post(global.APIURL + "/ActiveChannelFilter", LeadChannelFilter)
      .then(CLres => {
        let VChannelList = CLres.data.map(vcl => {
          return vcl;
        });
        setModelChannelList(VChannelList);
      });
  };

  const getDropdownChannelMasterList = async () => {
    var VChannelTypeId = document.getElementById("ddlFChannelType").value;
    if (parseInt(VChannelTypeId) > 0) {
      const ChannelDropdownFilter = {
        ChannelTypeId: VChannelTypeId,
        ChannelMasterId: 0,
        CityId: 0,
        SpMode: 'ChannelMasterSelectAll',
      };
      axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
        .then(CLres => {
          let VChannelMasterList = CLres.data.map(vcl => {
            return vcl;
          });
          setDropdownChannelList(VChannelMasterList);
        });
    }
  };

  const getDropdownCityList = async () => {
    var VChannelTypeId = document.getElementById("ddlFChannelType").value;
    var VChannelMasterId = document.getElementById("ddlFChannel").value;

    if (parseInt(VChannelTypeId) > 0 && parseInt(VChannelMasterId) > 0) {
      const ChannelDropdownFilter = {
        ChannelTypeId: VChannelTypeId,
        ChannelMasterId: VChannelMasterId,
        CityId: 0,
        SpMode: 'ChannelCitySelectAll',
      };
      axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
        .then(CLres => {
          let VChannelMasterList = CLres.data.map(vcl => {
            return vcl;
          });
          setDropdownCityList(VChannelMasterList);
        });
    }
  };

  const getDropdownBranchList = async () => {
    var VChannelTypeId = document.getElementById("ddlFChannelType").value;
    var VChannelMasterId = document.getElementById("ddlFChannel").value;
    var VCityId = document.getElementById("ddlFCity").value;

    if (parseInt(VChannelTypeId) > 0 && parseInt(VChannelMasterId) > 0 && parseInt(VCityId) > 0) {
      const ChannelDropdownFilter = {
        ChannelTypeId: VChannelTypeId,
        ChannelMasterId: VChannelMasterId,
        CityId: VCityId,
        SpMode: 'ChannelBranchSelectAll',
      };
      axios.post(global.APIURL + "/ChannelDropdownList", ChannelDropdownFilter)
        .then(CLres => {
          let VChannelMasterList = CLres.data.map(vcl => {
            return vcl;
          });
          setDropdownChannelBranchList(VChannelMasterList);
        });
    }
  };

  const getLeadDocumentById = async LeadId => {
    var AUrl = global.APIURL + '/WebLeadDocumentEdit/' + LeadId;
    axios.get(AUrl)
      .then(LDres => {
        let LeadDocumentL = LDres.data.map(kcdocument => {
          return kcdocument;
        });
        setDocumentList(LeadDocumentL);
      });
  };

  const getStateList = async (CId) => {
    var AUrl = global.APIURL + '/ActiveState/' + CId;
    axios.get(AUrl)
      .then(Sres => {
        let StateL = Sres.data.map((kcstate) => {
          return kcstate
        })
        setStateList(StateL);
      })
  }

  const getDistrictList = async (SId) => {
    var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
    axios.get(AUrl)
      .then(Dres => {
        let DistrictL = Dres.data.map((kcdistrict) => {
          return kcdistrict
        })
        setDistrictList(DistrictL);
      })
  }

  const getTehsilList = async (DId) => {
    var AUrl = global.APIURL + '/ActiveTehsil/' + DId;
    axios.get(AUrl)
      .then(Dres => {
        let TehsilL = Dres.data.map((kctehsil) => {
          return kctehsil
        })
        setTehsilList(TehsilL);
      })
  }

  const getCityList = async (TId) => {
    var CAUrl = global.APIURL + '/ActiveCity/' + TId;
    axios.get(CAUrl)
      .then(Cires => {
        let CityL = Cires.data.map((kccity) => {
          return kccity
        })
        setCityList(CityL);
      })
  }

  const getPriorityList = async () => {

    axios.get(global.APIURL + '/ActivePriority')
      .then(Pres => {
        let Priority = Pres.data.map((priority) => {
          return priority
        })
        setPriorityList(Priority);
      })
  }

  const getTagsList = async () => {

    axios.get(global.APIURL + '/ActiveTag')
      .then(Tres => {
        let ActiveTag = Tres.data.map((kctag) => {
          return kctag
        })
        setTagsList(ActiveTag);
      })
  }

  const getLeadDetailById = async (LId) => {
    var AUrl = global.APIURL + '/WebLeadEdit/' + LId;
    axios.get(AUrl)
      .then(res => {
        setLeadProfileImage(res.data[0].LeadProfileImage)
        setLeadType(res.data[0].LeadType);
        setCompanyType(res.data[0].CompanyType);
        setCompanyName(res.data[0].CompanyName);
        setCompanyMobileNumber(res.data[0].CompanyMobileNumber);
        setCompanyEmailAddress(res.data[0].CompanyEmailAddress);
        setCIRID(res.data[0].CIRID);
        setCIRPassword(res.data[0].CIRPassword);
        setStateId(res.data[0].CompanyStateId);
        getDistrictList(res.data[0].CompanyStateId);
        setDistrictId(res.data[0].CompanyDistrictId);
        getTehsilList(res.data[0].CompanyDistrictId);
        setCityId(res.data[0].CompanyCityId);
        setTehsilId(res.data[0].CompanyTehsilId);
        setReference(res.data[0].Reference);
        setPinCode(res.data[0].CompanyPincode);
        setRemarks(res.data[0].Remarkdescription);
        setClientBriefing(res.data[0].ClientBriefing);
        setPriorityId(res.data[0].PriorityId);
        setSelectTags(res.data[0].Tags);
        setChannelId(res.data[0].ChannelId);
        setChannelPartnerId(res.data[0].ChannelPartnerId);
        setLeadCode(res.data[0].LeadCode);
        setCompanyPan(res.data[0].CompanyPan);
        setCompanyGSTNo(res.data[0].CompanyGSTNo);
        setCompanyTINNo(res.data[0].CompanyTINNo);
        setAddress(res.data[0].Address);
        setDateOfBirth(res.data[0].DateOfBirth);
        setSCreateDate(res.data[0].CreateDate);
        setSConvertDate(res.data[0].ConvertDate);
        setSRegisterDate(res.data[0].RegisterDate);
        setSCloseDate(res.data[0].CloseDate);
      })
  }

  const getLeadContactList = async (LeadId) => {
    var AUrl = global.APIURL + '/LeadContactDetailsList/' + LeadId;
    axios.get(AUrl)
      .then(LCdres => {
        let LeadContactList = LCdres.data.map((LeadContact) => {
          return LeadContact
        })
        setLeadContactList(LeadContactList);
      });
  }

  const getSelectChannelDetailById = async (LeadId) => {
    var AUrl = global.APIURL + '/WebLeadChannelEdit/' + LeadId;
    axios.get(AUrl).then(LCDres => {
      let LeadChannelL = LCDres.data.map((kcchannel) => {
        return kcchannel
      })
      setSelectChannelDetail(LeadChannelL);
    })
  }

  const getLeadPartnerList = async (LeadId) => {
    var AUrl = global.APIURL + '/LeadPartnerDetailsList/' + LeadId;
    axios.get(AUrl).then(LPres => {
      let LeadPartnerList = LPres.data.map((LeadPartner) => {
        return LeadPartner
      })
      setLeadPartnerList(LeadPartnerList);
    })
  }

  const getSelectChannelPartnerDetailById = async (LeadId) => {
    var AUrl = global.APIURL + '/WebLeadChannelPartnerEdit/' + LeadId;
    axios.get(AUrl)
      .then(LCPDres => {
        let LeadPChannelL = LCPDres.data.map((kcchannepl) => {
          return kcchannepl
        })
        setSelectChannelPartnerDetail(LeadPChannelL);
      })

  }

  const getLeadEmailList = async (LeadId) => {
    var AUrl = global.APIURL + '/LeadEmailDetailsList/' + LeadId;
    axios.get(AUrl)
      .then(LEdres => {
        let LeadEmailList = LEdres.data.map((LeadEmail) => {
          return LeadEmail
        })
        setLeadEmailList(LeadEmailList);
      });
  }

  const PartnerhandleAddRow = e => {
    var LRNo = LeadPartnerList.length == 0 ? 1 : (LeadPartnerList[LeadPartnerList.length - 1].PartnerId + 1);
    var VPartnerName = document.getElementById('txtPartnerName').value;
    var VPartnerPAN = document.getElementById('txtPartnerPAN').value;
    var VPartnerContactNo = document.getElementById('txtPartnerContact').value;
    var VPartnerEmailAddress = document.getElementById('txtPartnerEmail').value;
    var VPartnerPassword = document.getElementById('txtPartnerPassword').value;
    const Pitem = {
      PartnerId: LRNo,
      PartnerName: VPartnerName,
      PartnerPAN: VPartnerPAN,
      PartnerContactNo: VPartnerContactNo,
      PartnerEmailAddress: VPartnerEmailAddress,
      PartnerPassword: VPartnerPassword,
      PartnerNameTextBoxControlId: 'txtPartnerName' + LRNo,
      PartnerPANTextBoxControlId: 'txtPartnerPAN' + LRNo,
      PartnerContactNoTextBoxControlId: 'txtPartnerContact' + LRNo,
      PartnerEmailAddressTextBoxControlId: 'txtPartnerEmail' + LRNo,
      PartnerPasswordTextBoxControlId: 'txtPartnerPassword' + LRNo
    };
    setLeadPartnerList([...LeadPartnerList, Pitem]);
    document.getElementById('txtPartnerName').value = '';
    document.getElementById('txtPartnerPAN').value = '';
    document.getElementById('txtPartnerContact').value = '';
    document.getElementById('txtPartnerEmail').value = '';
    document.getElementById('txtPartnerPassword').value = '';
  };

  const PartnerhandleRemoveRow = (e, PartnerId, LeadPartnerId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = PartnerId;
      setLeadPartnerList(
        LeadPartnerList.filter(item => item.PartnerId !== RId)
      );

      var VLeadPartnerId = parseInt(LeadPartnerId == null ? '0' : LeadPartnerId == '' ? '0' : LeadPartnerId);
      if (VLeadPartnerId > 0) {
        const LeadPartnerItemArr = { Id: VLeadPartnerId }
        axios.post(global.APIURL + '/WebLeadPartnerDelete', LeadPartnerItemArr).then(resem => { });
      }

      alert('Partner detail delete successfully');
    }
  };

  const ContacthandleAddRow = e => {
    var LRNo = LeadContactList.length == 0 ? 1 : (LeadContactList[LeadContactList.length - 1].ContactId + 1);
    var VMobileNo = document.getElementById('txtFMobileNo').value;
    var VIsActiveWhatsApp = document.getElementById("ChkFActiveWhatsApp").checked;
    const Citem = {
      LeadContactId: 0,
      ContactId: LRNo,
      MobileNo: VMobileNo,
      TextBoxControlId: 'txtFMobileNo' + LRNo,
      CheckBoxControlId: 'ChkFActiveWhatsApp' + LRNo,
      IsActiveWhatsApp: VIsActiveWhatsApp,
    };
    setLeadContactList([...LeadContactList, Citem]);
    document.getElementById('txtFMobileNo').value = '';
    document.getElementById("ChkFActiveWhatsApp").checked = false;
  };

  const ContacthandleRemoveRow = (e, ContactId, LeadContactId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = ContactId;
      setLeadContactList(LeadContactList.filter(item => item.ContactId !== RId));

      var VLeadContactId = parseInt(LeadContactId == null ? '0' : LeadContactId == '' ? '0' : LeadContactId);
      if (VLeadContactId > 0) {
        const LeadContactItemArr = { Id: VLeadContactId }
        axios.post(global.APIURL + '/WebLeadContactDelete', LeadContactItemArr).then(resem => { });
      }

      alert('Partner contact delete successfully');
    }
  };

  const EmailhandleAddRow = e => {
    var LRNo = LeadEmailList.length == 0 ? 1 : (LeadEmailList[LeadEmailList.length - 1].RowId + 1);
    var VEmailAddress = document.getElementById('txtFEmailAddress').value;
    var VPassword = document.getElementById("txtFPassword").value;
    const Eitem = {
      LeadEmailId: 0,
      RowId: LRNo,
      EmailAddress: VEmailAddress,
      Password: VPassword,
      EmailTextBoxControlId: 'txtFEmailAddress' + LRNo,
      PasswordTextBoxControlId: 'txtFPassword' + LRNo,
    };
    setLeadEmailList([...LeadEmailList, Eitem]);
    document.getElementById('txtFEmailAddress').value = '';
    document.getElementById('txtFPassword').value = '';
  };

  const EmailhandleRemoveRow = (e, RowId, LeadEmailId) => {
    let AlertMessgae = "Are you sure you want to delete this record?";
    if (confirm(AlertMessgae) == true) {
      const RId = RowId;
      setLeadEmailList(LeadEmailList.filter(item => item.RowId !== RId));

      var VLeadEmailId = parseInt(LeadEmailId == null ? '0' : LeadEmailId == '' ? '0' : LeadEmailId);
      if (VLeadEmailId > 0) {
        const LeadEmailItemArr = { Id: VLeadEmailId }
        axios.post(global.APIURL + '/WebLeadEmaiDelete', LeadEmailItemArr).then(resem => { });
      }
    }
  };

  const GetSetChannelId = async (CId) => {
    setChannelId(CId);
    getChannelDetail(CId);
    getModelLeadChannelPartnerList();
  }

  const getChannelDetail = async (ChId) => {
    var AUrl = global.APIURL + '/WebChannelDetail/' + ChId;
    axios.get(AUrl)
      .then(CDres => {
        let VChannelDetail = CDres.data.map((CDres) => {
          return CDres
        })
        setSelectChannelDetail(VChannelDetail);
      })
  }

  const GetSetChannelPartnerId = async (CPId) => {
    setChannelPartnerId(CPId);
    getChannelPartnerDetail(CPId);
  }

  const getChannelPartnerDetail = async (ChpId) => {
    var AUrl = global.APIURL + '/WebChannelPartnerDetail/' + ChpId;
    axios.get(AUrl)
      .then(CPDres => {
        let VChannelPartnerDetail = CPDres.data.map((CPDres) => {
          return CPDres
        })
        setSelectChannelPartnerDetail(VChannelPartnerDetail);
      })
  }

  const getModelLeadChannelPartnerList = async () => {

    const LeadChannelPartnerFilter = {
      ChannelId: ChannelId,
      FilterSearchByName: ""
    }
    axios.post(global.APIURL + '/ActiveChannelPartner', LeadChannelPartnerFilter)
      .then(CPLres => {
        let VChannelPartnerList = CPLres.data.map((vcpl) => {
          return vcpl
        })
        setModelChannelPartnerList(VChannelPartnerList);
      })
  }

  const UpdateLeadInqiry = async () => {
    if (LeadType != '') {
      const LeadInquiry = {
        LeadId: EditLeadId,
        LeadType: LeadType,
        IsLeadInquiry: "Yes",
        ProfileImage: "",
        FirstName: '',
        MiddelName: '',
        LastName: '',
        Relationship: '',
        RelationshipName: '',
        DateOfBirth: DateOfBirth,
        MobileNumber: "",
        EmailAddress: "",
        Gender: '',
        Address: Address,
        CountryId: '0',
        StateId: '0',
        DistrictId: '0',
        CityId: '0',
        TehsilId: '0',
        Pincode: '0',
        CIRID: CIRID,
        CIRPassword: CIRPassword,
        ISCompanyNew: "Yes",
        CompanyId: "0",
        CompanyType: CompanyType,
        CompanyName: CompanyName,
        CompanyMobileNumber: CompanyMobileNumber,
        CompanyEmailAddress: CompanyEmailAddress,
        CompanyPan: CompanyPan,
        CompanyGSTNo: CompanyGSTNo,
        CompanyTINNo: CompanyTINNo,
        CompanyStateId: StateId,
        CompanyCityId: CityId,
        CompanyDistrictId: DistrictId,
        CompanyTehsilId: TehsilId,
        CompanyPincode: PinCode,
        ChannelId: ChannelId,
        ChannelPartnerId: ChannelPartnerId,
        Tags: SelectTags,
        PriorityId: PriorityId,
        Reference: Reference,
        Remarks: Remarks,
        ClientBriefing: ClientBriefing,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      }

      axios.post(global.APIURL + '/WebLeadUpdate', LeadInquiry)
        .then(res => {
          for (let par = 0; par < LeadPartnerList.length; par++) {
            const LeadPartnerDetail = LeadPartnerList[par];
            var VPartnerNameTextBoxControlId = LeadPartnerDetail.PartnerNameTextBoxControlId;
            var VPartnerPANTextBoxControlId = LeadPartnerDetail.PartnerPANTextBoxControlId;
            var VPartnerContactNoTextBoxControlId = LeadPartnerDetail.PartnerContactNoTextBoxControlId;
            var VPartnerEmailAddressTextBoxControlId = LeadPartnerDetail.PartnerEmailAddressTextBoxControlId;
            var VPartnerPasswordTextBoxControlId = LeadPartnerDetail.PartnerPasswordTextBoxControlId;
            var VPartnerName = document.getElementById(VPartnerNameTextBoxControlId).value == null ? '' : document.getElementById(VPartnerNameTextBoxControlId).value;
            var VPartnerPAN = document.getElementById(VPartnerPANTextBoxControlId).value == null ? '' : document.getElementById(VPartnerPANTextBoxControlId).value;
            var VPartnerContactNo = document.getElementById(VPartnerContactNoTextBoxControlId).value == null ? '' : document.getElementById(VPartnerContactNoTextBoxControlId).value;
            var VPartnerEmailAddress = document.getElementById(VPartnerEmailAddressTextBoxControlId).value == null ? '' : document.getElementById(VPartnerEmailAddressTextBoxControlId).value;
            var VPartnerPassword = document.getElementById(VPartnerPasswordTextBoxControlId).value == null ? '' : document.getElementById(VPartnerPasswordTextBoxControlId).value;
            const PartnerItemArr = {
              LeadPartnerId: LeadPartnerDetail.LeadPartnerId,
              LeadId: EditLeadId,
              PartnerName: VPartnerName,
              PartnerPAN: VPartnerPAN,
              PartnerContactNo: VPartnerContactNo,
              PartnerEmailAddress: VPartnerEmailAddress,
              PartnerPassword: VPartnerPassword,
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            };

            axios.post(global.APIURL + "/WebLeadPartnerAdd", PartnerItemArr).then(respar => { });
          }

          var OVPartnerName = document.getElementById('txtPartnerName').value == null ? '' : document.getElementById('txtPartnerName').value;
          var OVPartnerPAN = document.getElementById('txtPartnerPAN').value == null ? '' : document.getElementById('txtPartnerPAN').value;
          var OVPartnerContactNo = document.getElementById('txtPartnerContact').value == null ? '' : document.getElementById('txtPartnerContact').value;
          var OVPartnerEmailAddress = document.getElementById('txtPartnerEmail').value == null ? '' : document.getElementById('txtPartnerEmail').value;
          var OVPartnerPassword = document.getElementById('txtPartnerPassword').value == null ? '' : document.getElementById('txtPartnerPassword').value;

          if (OVPartnerName != '' && OVPartnerContactNo != '') {
            const OPartnerItemArr = {
              LeadPartnerId: 0,
              LeadId: EditLeadId,
              PartnerName: OVPartnerName,
              PartnerPAN: OVPartnerPAN,
              PartnerContactNo: OVPartnerContactNo,
              PartnerEmailAddress: OVPartnerEmailAddress,
              PartnerPassword: OVPartnerPassword,
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            };

            axios.post(global.APIURL + "/WebLeadPartnerAdd", OPartnerItemArr).then(respar => { });
          }

          for (let con = 0; con < LeadContactList.length; con++) {
            const LeadContactDetail = LeadContactList[con];
            var VTextBoxControlId = LeadContactDetail.TextBoxControlId;
            var VCheckBoxControlId = LeadContactDetail.CheckBoxControlId;
            var VIsActiveWhatsApp = document.getElementById(VCheckBoxControlId).checked;
            var MobileNo = document.getElementById(VTextBoxControlId).value == null ? '' : document.getElementById(VTextBoxControlId).value;
            if (MobileNo != '') {
              const ContactItemArr = {
                LeadContactId: LeadContactDetail.LeadContactId,
                LeadId: EditLeadId,
                MobileNo: MobileNo,
                IsActiveWhatsApp: VIsActiveWhatsApp,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              };

              axios.post(global.APIURL + '/WebLeadContactInformationAdd', ContactItemArr).then(rescon => { });
            }
          }

          var BVIsActiveWhatsApp = document.getElementById('ChkFActiveWhatsApp').checked;
          var BVMobileNo = document.getElementById('txtFMobileNo').value == null ? '' : document.getElementById('txtFMobileNo').value;

          if (BVMobileNo != '') {
            const ContactItemArr = {
              LeadContactId: 0,
              LeadId: EditLeadId,
              MobileNo: BVMobileNo,
              IsActiveWhatsApp: BVIsActiveWhatsApp,
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            };
            axios.post(global.APIURL + '/WebLeadContactInformationAdd', ContactItemArr).then(rescon => { });
          }

          for (let ema = 0; ema < LeadEmailList.length; ema++) {
            const LeadEmailDetail = LeadEmailList[ema];

            var VEmailTextBoxControlId = LeadEmailDetail.EmailTextBoxControlId;
            var VPasswordTextBoxControlId = LeadEmailDetail.PasswordTextBoxControlId;
            var VEmailAddress = document.getElementById(VEmailTextBoxControlId).value == null ? '' : document.getElementById(VEmailTextBoxControlId).value;
            var VPassword = document.getElementById(VPasswordTextBoxControlId).value == null ? '' : document.getElementById(VPasswordTextBoxControlId).value;

            if (VEmailAddress != '') {
              const EmailItemArr = {
                LeadEmailId: LeadEmailDetail.LeadEmailId,
                LeadId: EditLeadId,
                EmailId: VEmailAddress,
                Password: VPassword,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
              };

              axios.post(global.APIURL + '/WebLeadEmailInformationAdd', EmailItemArr).then(resem => { });
            }
          }

          var BVEmailAddress = document.getElementById('txtFEmailAddress').value == null ? '' : document.getElementById('txtFEmailAddress').value;
          var BVPassword = document.getElementById('txtFPassword').value == null ? '' : document.getElementById('txtFPassword').value;
          if (BVEmailAddress != '') {
            const BEmailItemArr = {
              LeadEmailId: 0,
              LeadId: EditLeadId,
              EmailId: BVEmailAddress,
              Password: BVPassword,
              CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
            };

            axios.post(global.APIURL + '/WebLeadEmailInformationAdd', BEmailItemArr).then(resem => { });
          }

          alert(res.data[0].RecordStatus);
          history.push("/RegisteredList");

        });
    }
  }

  const [ChannelTypeList, setChannelTypeList] = useState([]);
  const [ChannelCountryList, setChannelCountryList] = useState([]);
  const [ChannelStateList, setChannelStateList] = useState([]);
  const [ChannelDistrictList, setChannelDistrictList] = useState([]);
  const [ChannelCityList, setChannelCityList] = useState([]);
  const [ChannelTehsilList, setChannelTehsilList] = useState([]);
  const [ChannelMasterList, setChannelMasterList] = useState([]);

  const [ChannelLogo, setChannelLogo] = useState(PlacholderImgC);
  const [ChannelName, setChannelName] = useState("");
  const [ChannelTypeId, setChannelTypeId] = useState("");
  const [ChannelMasterId, setChannelMasterId] = useState("");
  const [BranchName, setBranchName] = useState("");
  const [BranchCode, setBranchCode] = useState("");
  const [IFSCCode, setIFSCCode] = useState("");
  const [BranchContactNo, setBranchContactNo] = useState("");
  const [BranchEmailAddress, setBranchEmailAddress] = useState("");
  const [BranchLocation, setBranchLocation] = useState("");
  const [BranchAddress, setBranchAddress] = useState("");
  const [BranchCountryId, setBranchCountryId] = useState("0");
  const [BranchStateId, setBranchStateId] = useState("0");
  const [BranchDistrictId, setBranchDistrictId] = useState("0");
  const [BranchTehsilId, setBranchTehsilId] = useState("0");
  const [BranchCityId, setBranchCityId] = useState("0");
  const [BranchPincode, setBranchPincode] = useState("");

  const getChannelTypeList = async () => {
    fetch(global.APIURL + "/ChannalTypeList")
      .then((res) => res.json())
      .then((json) => {
        setChannelTypeList(json)
      })
  }

  function ChannelLogofetchPost(e) {
    var details = e.target.files[0];
    let arr = details.name.toString().split(".");
    var FileEx = arr[1];
    var isUpload = 0;
    if (FileEx == 'jpg') {
      isUpload++;
    }
    else if (FileEx == 'JPG') {
      isUpload++;
    }
    else if (FileEx == 'jpeg') {
      isUpload++;
    }
    else if (FileEx == 'JPEG') {
      isUpload++;
    }
    else if (FileEx == 'png') {
      isUpload++;
    }
    else if (FileEx == 'PNG') {
      isUpload++;
    }
    else if (FileEx == 'gif') {
      isUpload++;
    }
    else if (FileEx == 'GIF') {
      isUpload++;
    }
    if (parseInt(isUpload) > 0) {
      var details1 = "ChannelSource";
      const formData = new FormData();
      formData.append('Document', details);
      formData.append('FolderName', details1);
      var AUrl = global.APIURL + '/uploadavatar/';
      axios.post(AUrl, formData,
        {
          headers: { "Content-Type": details.type },
        })
        .then((res) => {
          setChannelLogo(res.data.IName);
          document.getElementById("IGProfileImage").src = res.data.IName;
        });
    }
    else {
      document.getElementById("fuChannelLogo").value = '';
      alert("Please upload file .jpg or .jpeg or .png or .gif image!");
    }
  }

  const getCountryList = async () => {
    fetch(global.APIURL + "/CountryActiveList")
      .then((res) => res.json())
      .then((json) => {
        setChannelCountryList(json)
      })
  }

  const getChannelStateList = async CId => {
    var AUrl = global.APIURL + '/ActiveState/' + CId;
    axios.get(AUrl)
      .then(Sres => {
        let StateL = Sres.data.map(kcstate => {
          return kcstate;
        });
        setChannelStateList(StateL);
      });
  };

  const getChannelDistrictList = async SId => {
    var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
    axios.get(AUrl)
      .then(Dres => {
        let DistrictL = Dres.data.map(kcdistrict => {
          return kcdistrict;
        });
        setChannelDistrictList(DistrictL);
      });
  };

  const getChannelCityList = async TId => {
    var AUrlC = global.APIURL + '/ActiveCity/' + TId;
    axios.get(AUrlC)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setChannelCityList(CityL);
      });
  };

  const getChannelTehsilList = async DId => {
    var AUrl = global.APIURL + '/ActiveTehsil/' + DId;
    axios.get(AUrl)
      .then(Dres => {
        let TehsilL = Dres.data.map(kctehsil => {
          return kctehsil;
        });
        setChannelTehsilList(TehsilL);
      });
  };

  const getChannelMasterList = async (CTId) => {
    var AUrl = global.APIURL + '/ChannelMasterActiveList/' + CTId;
    fetch(AUrl)
      .then((res) => res.json())
      .then((json) => {
        setChannelMasterList(json)
      })
  }

  const getChannelMasterDetail = async (CMId) => {
    var AUrl = global.APIURL + '/ChannelMasterEdit/' + CMId;
    axios.get(AUrl)
      .then(res => {
        setChannelLogo(res.data[0].ChannelLogo);
      })
  }

  const ChannelDetailSave = async (e) => {
    e.preventDefault();

    const UserDetailArr = {
      action: "Add",
      ChannelId: "0",
      IsExistingBranch: "No",
      ChannelImage: ChannelLogo,
      ChannelTypeId: ChannelTypeId,
      ChannelMasterId: ChannelMasterId,
      IsNewChannel: "Yes",
      ExistingChannelId: "0",
      ExistingBranchId: "0",
      ChannelName: ChannelName,
      BranchName: BranchName,
      BranchCode: BranchCode,
      BranchIFSCCode: IFSCCode,
      BranchStateID: BranchStateId,
      BranchCityID: BranchCityId,
      BranchDistrictId: BranchDistrictId,
      BranchTehsilId: BranchTehsilId,
      BranchPincode: BranchPincode,
      BranchContactNumber: BranchContactNo,
      BranchEmailAddress: BranchEmailAddress,
      BranchAddress: BranchAddress,
      BranchLocation: BranchLocation,
      Flag: "A",
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
    }

    axios.post(global.APIURL + '/ChannelAddEditWeb', UserDetailArr)
      .then(res => {
        if (res.data.length > 0) {
          alert(res.data);
          var VChannelMasterId = document.getElementById("ddlFChannel").value;
          var VChannelTypeId = document.getElementById("ddlFChannelType").value;
          var VBranchId = document.getElementById("ddlFBranch").value;
          var VCityId = document.getElementById("ddlFCity").value;

          const LeadChannelFilter = {
            ChannelTypeId: VChannelTypeId,
            ChannelMasterId: VChannelMasterId,
            BranchId: VBranchId,
            CityId: VCityId
          };

          axios.post(global.APIURL + "/ActiveChannelFilter", LeadChannelFilter)
            .then(CLres => {
              let VChannelList = CLres.data.map(vcl => {
                return vcl;
              });
              setModelChannelList(VChannelList);
            });

          document.getElementById("fuChannelLogo").value = '';
          document.getElementById("ddlChanneltype").value = 0;
          document.getElementById("txtChannelName").value = '';
          document.getElementById("txtBranchName").value = '';
          document.getElementById("txtBranchCode").value = '';
          document.getElementById("txtIFSC").value = '';
          document.getElementById("txtContactNo").value = '';
          document.getElementById("txtEmail").value = '';
          document.getElementById("txtBranchLocation").value = '';
          document.getElementById("txtAddress").value = '';
          document.getElementById("ddlChannelCountry").value = 0;
          document.getElementById("ddlChannelState").value = 0;
          document.getElementById("ddlChannelDistrict").value = 0;
          document.getElementById("ddlChannelTehsil").value = 0;
          document.getElementById("ddlChannelCity").value = 0;
          document.getElementById("txtChannelPincode").value = '';
        }
      });
  }

  const ChannelDetailClose = async (e) => {
    e.preventDefault();
    document.getElementById("fuChannelLogo").value = '';
    document.getElementById("ddlChanneltype").value = 0;
    document.getElementById("txtChannelName").value = '';
    document.getElementById("txtBranchName").value = '';
    document.getElementById("txtBranchCode").value = '';
    document.getElementById("txtIFSC").value = '';
    document.getElementById("txtContactNo").value = '';
    document.getElementById("txtEmail").value = '';
    document.getElementById("txtBranchLocation").value = '';
    document.getElementById("txtAddress").value = '';
    document.getElementById("ddlChannelCountry").value = 0;
    document.getElementById("ddlChannelState").value = 0;
    document.getElementById("ddlChannelDistrict").value = 0;
    document.getElementById("ddlChannelTehsil").value = 0;
    document.getElementById("ddlChannelCity").value = 0;
    document.getElementById("txtChannelPincode").value = '';
  }

  const [ChannelSubSourceLog, setChannelSubSourceLog] = useState('');
  const [ChannelSubSourceName, setChannelSubSourceName] = useState("");
  const [ChannelSubSourceDesignationId, setChannelSubSourceDesignationId] = useState(0);
  const [ChannelSubSourceDepartmentId, setChannelSubSourceDepartmentId] = useState(0);
  const [ChannelSubSourceMobileNumber, setChannelSubSourceMobileNumber] = useState('');
  const [ChannelSubSourceAltMobileNumber, setChannelSubSourceAltMobileNumber] = useState('');
  const [ChannelSubSourceEmail, setChannelSubSourceEmail] = useState('');
  const [ChannelSubSourceAltEmail, setChannelSubSourceAltEmail] = useState('');
  const [ChannelSubSourceVisitingCard, setChannelSubSourceVisitingCard] = useState('');
  const [ChannelSubSourceDateOfBirth, setChannelSubSourceDateOfBirth] = useState('');
  const [ChannelSubSourceDateOfAnniversary, setChannelSubSourceDateOfAnniversary] = useState('');
  const [ChannelSubSourceFirstMeetingBrief, setChannelSubSourceFirstMeetingBrief] = useState('');
  const [ChannelSubSourceAddress, setChannelSubSourceAddress] = useState('');
  const [ChannelSubSourceCountryId, setChannelSubSourceCountryId] = useState("0");
  const [ChannelSubSourceStateId, setChannelSubSourceStateId] = useState("0");
  const [ChannelSubSourceDistrictId, setChannelSubSourceDistrictId] = useState("0");
  const [ChannelSubSourceTehsilId, setChannelSubSourceTehsilId] = useState("0");
  const [ChannelSubSourceCityId, setChannelSubSourceCityId] = useState("0");
  const [ChannelSubSourcePincode, setChannelSubSourcePincode] = useState("");

  const [ChannelSubSourceDepartmentList, setChannelSubSourceDepartmentList] = useState([]);
  const [ChannelSubSourceDesignationList, setChannelSubSourceDesignationList] = useState([]);
  const [ChannelSubSourceCountryList, setChannelSubSourceCountryList] = useState([]);
  const [ChannelSubSourceStateList, setChannelSubSourceStateList] = useState([]);
  const [ChannelSubSourceDistrictList, setChannelSubSourceDistrictList] = useState([]);
  const [ChannelSubSourceCityList, setChannelSubSourceCityList] = useState([]);
  const [ChannelSubSourceTehsilList, setChannelSubSourceTehsilList] = useState([]);

  const getChannelSubSourceDepartmentList = async () => {
    fetch(global.APIURL + "/DepartmentActiveList")
      .then((res) => res.json())
      .then((json) => {
        setChannelSubSourceDepartmentList(json)
      })
  }

  const getChannelSubSourceDesignation = async () => {
    fetch(global.APIURL + "/DesignationActiveList")
      .then((res) => res.json())
      .then((json) => {
        setChannelSubSourceDesignationList(json)

      })
  }

  const getChannelSubSourceCountryList = async () => {
    fetch(global.APIURL + "/CountryActiveList")
      .then((res) => res.json())
      .then((json) => {
        setChannelSubSourceCountryList(json)
      })
  }

  const getChannelSubSourceStateList = async CId => {
    var AUrl = global.APIURL + '/ActiveState/' + CId;
    axios.get(AUrl)
      .then(Sres => {
        let StateL = Sres.data.map(kcstate => {
          return kcstate;
        });
        setChannelSubSourceStateList(StateL);
      });
  };

  const getChannelSubSourceDistrictList = async SId => {
    var AUrl = global.APIURL + '/ActiveDistrict/' + SId;
    axios.get(AUrl)
      .then(Dres => {
        let DistrictL = Dres.data.map(kcdistrict => {
          return kcdistrict;
        });
        setChannelSubSourceDistrictList(DistrictL);
      });
  };

  const getChannelSubSourceTehsilList = async DId => {
    var AUrl = global.APIURL + '/ActiveTehsil/' + DId;
    axios.get(AUrl)
      .then(Dres => {
        let TehsilL = Dres.data.map(kctehsil => {
          return kctehsil;
        });
        setChannelSubSourceTehsilList(TehsilL);
      });
  };

  const getChannelSubSourceCityList = async TId => {
    var AUrlC = global.APIURL + '/ActiveCity/' + TId;
    axios.get(AUrlC)
      .then(Cires => {
        let CityL = Cires.data.map(kccity => {
          return kccity;
        });
        setChannelSubSourceCityList(CityL);
      });
  };

  function ChannelSubSourceVisitingCardfetchPost(e) {
    var details = e.target.files[0];
    var details1 = "ChannelSubSourceVisitingCard";
    const formData = new FormData();
    formData.append('Document', details);
    formData.append('FolderName', details1);
    var AUrl = global.APIURL + '/uploadavatar/';
    axios.post(AUrl, formData,
      {
        headers: { "Content-Type": details.type },
      })
      .then((res) => {
        setChannelSubSourceVisitingCard(res.data.IName);
      });
  }

  function ChannelSubSourceLogofetchPost(e) {
    var details = e.target.files[0];
    var details1 = "ChannelSubSourceLogo";
    const formData = new FormData();
    formData.append('Document', details);
    formData.append('FolderName', details1);
    var AUrl = global.APIURL + '/uploadavatar/';
    axios.post(AUrl, formData,
      {
        headers: { "Content-Type": details.type },
      })
      .then((res) => {
        setChannelSubSourceLog(res.data.IName);
      });
  }

  const ChannelSubSourceDetailSave = async (e) => {
    e.preventDefault();

    const ChannelSubSourceArr = {
      ChannelPartnerId: "0",
      ChannelId: ChannelId,
      Name: ChannelSubSourceName,
      ChannelSubSourceLogo: ChannelSubSourceLog,
      DesignationId: ChannelSubSourceDesignationId,
      DepartmentId: ChannelSubSourceDepartmentId,
      MobileNumber: ChannelSubSourceMobileNumber,
      AlternetMobileNumber: ChannelSubSourceAltMobileNumber,
      Email: ChannelSubSourceEmail,
      AlternetEmail: ChannelSubSourceAltEmail,
      VistingCard: ChannelSubSourceVisitingCard,
      DateOfBirth: ChannelSubSourceDateOfBirth,
      DateOfAnniversary: ChannelSubSourceDateOfAnniversary,
      FirstMeetingBrief: ChannelSubSourceFirstMeetingBrief,
      CurrentAddress: ChannelSubSourceAddress,
      CountryId: ChannelSubSourceCountryId,
      StateId: ChannelSubSourceStateId,
      CityId: ChannelSubSourceDistrictId,
      DistrictId: ChannelSubSourceTehsilId,
      TehsilId: ChannelSubSourceCityId,
      Pincode: ChannelSubSourcePincode,
      CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
    };
    axios.post(global.APIURL + "/ChannelSubSourceInsert", ChannelSubSourceArr)
      .then(res => {
        if (res.data.length > 0) {
          alert("Add new channel sub source save successfully.");
          getModelLeadChannelPartnerList(ChannelId);
          document.getElementById("FUCSSLogo").value = '';
          document.getElementById("txtCSSName").value = '';
          document.getElementById("ddlCSSDesignation").value = 0;
          document.getElementById("ddlCSSDepartment").value = 0;
          document.getElementById("txtCSSMobileNumber").value = '';
          document.getElementById("txtCSSAlternatemobileNumber").value = '';
          document.getElementById("txtCSSEmail").value = '';
          document.getElementById("txtCSSAlternateEmail").value = '';
          document.getElementById("FUCSSVisitingCard").value = '';
          document.getElementById("txtCSSBirth").value = '';
          document.getElementById("txtCSSAnniversary").value = '';
          document.getElementById("txtCSSFirst").value = '';
          document.getElementById("ddlCSSCountry").value = 0;
          document.getElementById("ddlCSSState").value = 0;
          document.getElementById("ddlCSSDistrict").value = 0;
          document.getElementById("ddlCSSTehsil").value = 0;
          document.getElementById("ddlCSSCity").value = 0;
          document.getElementById("txtCSSPincode").value = '';
        }
      });
  }

  const ChannelSubSourceDetailClose = async (e) => {
    e.preventDefault();
    document.getElementById("FUCSSLogo").value = '';
    document.getElementById("txtCSSName").value = '';
    document.getElementById("ddlCSSDesignation").value = 0;
    document.getElementById("ddlCSSDepartment").value = 0;
    document.getElementById("txtCSSMobileNumber").value = '';
    document.getElementById("txtCSSAlternatemobileNumber").value = '';
    document.getElementById("txtCSSEmail").value = '';
    document.getElementById("txtCSSAlternateEmail").value = '';
    document.getElementById("FUCSSVisitingCard").value = '';
    document.getElementById("txtCSSBirth").value = '';
    document.getElementById("txtCSSAnniversary").value = '';
    document.getElementById("txtCSSFirst").value = '';
    document.getElementById("ddlCSSCountry").value = 0;
    document.getElementById("ddlCSSState").value = 0;
    document.getElementById("ddlCSSDistrict").value = 0;
    document.getElementById("ddlCSSTehsil").value = 0;
    document.getElementById("ddlCSSCity").value = 0;
    document.getElementById("txtCSSPincode").value = '';
  }

  const [DocumentTypeId, setDocumentTypeId] = useState("");
  const [DocumentType, setDocumentType] = useState("");
  const [DocumentFilePath, setDocumentFilePath] = useState("");
  const [DocumentTypeList, setDocumentTypeList] = useState([]);

  const getDocumentTypeList = async () => {
    axios.get(global.APIURL + '/ActiveDocumentType')
      .then(DTres => {
        let DTList = DTres.data.map(documenytype => {
          return documenytype;
        });
        setDocumentTypeList(DTList);
      });
  };

  const DocumentTypeOnChange = e => {
    var VDocumentType = e.nativeEvent.target.selectedIndex;
    var DocumentTypeId = e.nativeEvent.target[VDocumentType].value;
    var DocumentTypeText = e.nativeEvent.target[VDocumentType].text;
    setDocumentTypeId(DocumentTypeId);
    setDocumentType(DocumentTypeText);
  };

  function DocumentfetchPost(e) {
    var details = e.target.files[0];
    var details1 = "LeadDocument";
    const formData = new FormData();
    formData.append('Document', details);
    formData.append('FolderName', details1);
    var AUrl = global.APIURL + '/uploadavatar/';
    axios.post(AUrl, formData,
      {
        headers: { "Content-Type": details.type },
      })
      .then((res) => {
        setDocumentFilePath(res.data.IName);
      });
  }

  const DocumenthandleAddRow = () => {
    let VLeadId = localStorage.getItem("ClosedFirmLeadId");
    var EVDocumentTypeId = parseInt(DocumentTypeId == null ? '0' : DocumentTypeId == '' ? '0' : DocumentTypeId);
    var EVDocumentType = DocumentType == null ? "" : DocumentType;
    if (EVDocumentTypeId > 0 && EVDocumentType != '') {
      var EVDocumentTypeId = DocumentTypeId == null ? '0' : DocumentTypeId == '' ? '0' : DocumentTypeId;
      var EVDocumentType = DocumentType == null ? "" : DocumentType;
      var EVUploadFile = DocumentFilePath == null ? "" : DocumentFilePath;

      const DocumentItemArr = {
        LeadId: VLeadId,
        DocumentTypeId: EVDocumentTypeId,
        DocumentFilePath: EVUploadFile,
        Remarks: document.getElementById("txtKYCDocNo").value,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId"),
      };

      axios.post(global.APIURL + '/WebLeadDocumentAdd', DocumentItemArr)
        .then(res => {
          alert(res.data[0].RecordStatus);
          getLeadDocumentById(VLeadId);
          document.getElementById("ddlDocumentTypeI").value = 0;
          document.getElementById("FUDocumentFilePathI").value = '';
          document.getElementById("txtKYCDocNo").value = '';
        })
    }
  };

  const DocumenthandleRemoveRow = (e, DocumentRowId) => {
    var VDocumentRowId = parseInt(DocumentRowId == null ? '0' : DocumentRowId == '' ? '0' : DocumentRowId);
    if (VDocumentRowId > 0) {
      const LeadDocumentItemArr = { Id: VDocumentRowId }
      axios.post(global.APIURL + '/WebLeadDocumentDelete', LeadDocumentItemArr)
        .then(res => {
          alert(res.data[0].RecordStatus);
          getLeadDocumentById(EditLeadId);
        })
    }

  };

  const PasswordInputChange = (e, VEmailPassword) => {
    var FPasswordType = document.getElementById(VEmailPassword).type;
    if (FPasswordType == 'password') {
      document.getElementById(VEmailPassword).type = "input";
    }
    else if (FPasswordType == 'input') {
      document.getElementById(VEmailPassword).type = "password";
    }
    else {
      document.getElementById(VEmailPassword).type = "password";
    }
  };

  const PerTehsilDetailSave = async (e) => {
    e.preventDefault();
    var VTehsilTitle = document.getElementById("txtPerTehsil").value;
    if (VTehsilTitle != '') {
      const TehsilArr = {
        TehsilId: '0',
        CountryId: 1,
        StateId: document.getElementById("ddlPerState").value,
        DistrictId: document.getElementById("ddlPerDistrictId").value,
        Title: VTehsilTitle,
        CreateUser: "1"
      }

      axios.post(global.APIURL + '/Tehsil', TehsilArr)
        .then(res => {
          alert(res.data);
          var DistrictId = document.getElementById("ddlPerDistrictId").value;
          getTehsilList(DistrictId);
        });
    }
    document.getElementById("txtPerTehsil").value = '';
  }

  const PerTehsilClose = async (e) => {
    e.preventDefault();
    document.getElementById("txtPerTehsil").value = '';
  }

  const PerCityDetailSave = async (e) => {
    e.preventDefault();
    var VCityTitle = document.getElementById("txtPerCity").value;
    if (VCityTitle != '') {
      const CityArr = {
        CountryId: 1,
        StateId: document.getElementById("ddlPerState").value,
        DistrictId: document.getElementById("ddlPerDistrictId").value,
        TehsilId: document.getElementById("ddlPerTehsil").value,
        CityId: '0',
        Title: VCityTitle,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      }
      axios.post(global.APIURL + '/City', CityArr)
        .then(res => {
          alert(res.data.StatusMessage);
          var VTehsilId = document.getElementById("ddlPerTehsil").value;
          getCityList(VTehsilId)
        });
    }
    document.getElementById("txtPerCity").value = '';
  }

  const PerCityClose = async (e) => {
    e.preventDefault();
    document.getElementById("txtPerCity").value = '';
  }

  const ChannelTehsilDetailSave = async (e) => {
    e.preventDefault();
    var VTehsilTitle = document.getElementById("txtChannelTehsil").value;
    if (VTehsilTitle != '') {
      const TehsilArr = {
        TehsilId: '0',
        CountryId: document.getElementById("ddlChannelCountry").value,
        StateId: document.getElementById("ddlChannelState").value,
        DistrictId: document.getElementById("ddlChannelDistrict").value,
        Title: VTehsilTitle,
        CreateUser: "1"
      }

      axios.post(global.APIURL + '/Tehsil', TehsilArr)
        .then(res => {
          alert(res.data);
          var VDistrictId = document.getElementById("ddlChannelDistrict").value;
          getChannelTehsilList(VDistrictId);
        });
    }
    document.getElementById("txtChannelTehsil").value = '';
  }

  const ChannelTehsilClose = async (e) => {
    e.preventDefault();
    document.getElementById("txtChannelTehsil").value = '';
  }

  const ChannelCityDetailSave = async (e) => {
    e.preventDefault();
    var VCityTitle = document.getElementById("txtChannelCity").value;
    if (VCityTitle != '') {
      const CityArr = {
        CountryId: document.getElementById("ddlChannelCountry").value,
        StateId: document.getElementById("ddlChannelState").value,
        DistrictId: document.getElementById("ddlChannelDistrict").value,
        TehsilId: document.getElementById("ddlChannelTehsil").value,
        CityId: '0',
        Title: VCityTitle,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      }
      axios.post(global.APIURL + '/City', CityArr)
        .then(res => {
          alert(res.data.StatusMessage);
          var VTehsilId = document.getElementById("ddlChannelTehsil").value;
          getChannelCityList(VTehsilId)
        });
    }
    document.getElementById("txtChannelCity").value = '';
  }

  const ChannelCityClose = async (e) => {
    e.preventDefault();
    document.getElementById("txtChannelCity").value = '';
  }

  const ChannelSubSourceTehsilDetailSave = async (e) => {
    e.preventDefault();
    var VTehsilTitle = document.getElementById("txtChannelSubSourceTehsil").value;
    if (VTehsilTitle != '') {
      const TehsilArr = {
        TehsilId: '0',
        CountryId: document.getElementById("ddlCSSCountry").value,
        StateId: document.getElementById("ddlCSSState").value,
        DistrictId: document.getElementById("ddlCSSDistrict").value,
        Title: VTehsilTitle,
        CreateUser: "1"
      }

      axios.post(global.APIURL + '/Tehsil', TehsilArr)
        .then(res => {
          alert(res.data);
          var VDistrictId = document.getElementById("ddlCSSDistrict").value;
          getChannelSubSourceTehsilList(VDistrictId);
        });
    }
    document.getElementById("txtChannelSubSourceTehsil").value = '';
  }

  const ChannelSubSourceTehsilClose = async (e) => {
    e.preventDefault();
    document.getElementById("txtChannelSubSourceTehsil").value = '';
  }

  const ChannelSubSourceCityDetailSave = async (e) => {
    e.preventDefault();
    var VCityTitle = document.getElementById("txtChannelSubSourceCity").value;
    if (VCityTitle != '') {
      const CityArr = {
        CountryId: document.getElementById("ddlCSSCountry").value,
        StateId: document.getElementById("ddlCSSState").value,
        DistrictId: document.getElementById("ddlCSSDistrict").value,
        TehsilId: document.getElementById("ddlCSSTehsil").value,
        CityId: '0',
        Title: VCityTitle,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      }
      axios.post(global.APIURL + '/City', CityArr)
        .then(res => {
          alert(res.data.StatusMessage);
          var VTehsilId = document.getElementById("ddlCSSTehsil").value;
          getChannelSubSourceCityList(VTehsilId)
        });
    }
    document.getElementById("txtChannelSubSourceCity").value = '';
  }

  const ChannelSubSourceCityClose = async (e) => {
    e.preventDefault();
    document.getElementById("txtChannelSubSourceCity").value = '';
  }

  const KYCDocOnChange = e => {

    var checkKYCDocNo = document.getElementById("txtKYCDocNo").value;
    if (DocumentTypeId == 10) {
      if (checkKYCDocNo.length > 10) {

        alert("Length should be 10 digit");
        document.getElementById("txtKYCDocNo").value = ''
      }
      else { KYCDocNo = checkKYCDocNo }
    }
    else if (DocumentTypeId == 17) {
      if (checkKYCDocNo.length > 12) {

        alert("Length should be 12 digit");
        document.getElementById("txtKYCDocNo").value = ''
      }
      else { KYCDocNo = checkKYCDocNo }
    }
  };

  return (

    <div className="page-content">
      <MetaTags>
        <title>Client Detail | KC Admin App</title>
      </MetaTags>
      <div className="container-fluid">
        <div className="modal fade" id="firmbanking" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Bank List</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <Row>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="formFileSm" className="form-label">Channel Type</label>
                      <select id="ddlFChannelType" className="form-select" aria-label="Default select example" onChange={e => { getModelLeadChannelList(); getDropdownChannelMasterList(); getDropdownCityList(); getDropdownBranchList() }}>
                        <option selected value="0">Select Channel Type</option>
                        {DropdownChannelTypeList.map(item => (
                          <option key={item.ChannelTypeId} value={item.ChannelTypeId}>
                            {item.ChannelTypeName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="formFileSm" className="form-label">Channel Master</label>
                      <select id="ddlFChannel" className="form-select" aria-label="Default select example" onChange={e => { getModelLeadChannelList(); getDropdownCityList(); getDropdownBranchList() }}>
                        <option selected value="0">Select Channel</option>
                        {DropdownChannelList.map(item => (
                          <option key={item.ChannelMasterId} value={item.ChannelMasterId}>
                            {item.Title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="formFileSm" className="form-label">City</label>
                      <select id="ddlFCity" className="form-select" aria-label="Default select example" onChange={e => { getModelLeadChannelList(); getDropdownBranchList() }}>
                        <option selected value="0">Select City</option>
                        {DropdownCityList.map(item => (
                          <option key={item.CityId} value={item.CityId}>
                            {item.Title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="formFileSm" className="form-label">Branch</label>
                      <select id="ddlFBranch" className="form-select" aria-label="Default select example" onChange={e => getModelLeadChannelList()}>
                        <option selected value="0">Select Branch</option>
                        {DropdownChannelBranchList.map(item => (
                          <option key={item.BranchId} value={item.BranchId}>
                            {item.BranchName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-12">

                    <div className="d-flex justify-content-between">
                      <div>
                        <label htmlFor="formFileSm" className="form-label">
                          Result
                        </label>
                      </div>
                      <div>
                        <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#FirmAddNewBank">+ Add New</a></span>
                      </div>
                    </div>
                    <hr />
                    {ModelChannelList.map(item => (
                      <div className="team-modal-box mb-2 mt-2" key={item.ChannelId}>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center border-bottom pb-2">
                            <div className="flex-shrink-0">
                              <div className="avatar-md">
                                <img
                                  src={item.ChannelImage}
                                  alt=""
                                  className="img-thumbnail"
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="font-14 dark-font mb-1">
                                {item.ChannelName}
                              </div>
                              <div className="mb-1">
                                <a href="" className="table-link-a font-12">
                                  Channel : {item.ChannelMaster}
                                </a>
                              </div>
                              <div className="mb-1">
                                <a href="" className="table-link-a font-12">
                                  Type : {item.ChannelTypeName}
                                </a>
                              </div>
                              <div className="mb-1">
                                <a href="" className="table-link-a font-12">
                                  Branch : {item.BranchName} |  IFSE Code : {item.BranchIFSCCOde}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              id="1"
                              className="form-check-input"
                              name="ChannelList"
                              value={item.ChannelId}
                              data-bs-dismiss="modal" aria-label="Close"
                              onChange={() => GetSetChannelId(parseInt(item.ChannelId == null ? 0 : item.ChannelId == '' ? 0 : item.ChannelId))}
                            />
                          </div>
                        </div>

                        <div className="pt-2">
                          <span className="badge badge-soft-primary p-2 me-2">
                            {item.BranchStateName}
                          </span>
                          <span className="badge badge-soft-primary p-2 me-2">
                            {item.BranchDistrictName}
                          </span>
                          <span className="badge badge-soft-primary p-2 me-2">
                            {item.BranchCityName}
                          </span>
                          <span className="badge badge-soft-primary p-2 me-2">
                            {item.BranchTehsilName}
                          </span>
                          <span className="badge badge-soft-primary p-2 me-2">
                            {item.BranchPincode}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="firmchannel" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Channel Partner List
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <Row>
                  <div className="d-flex justify-content-between">
                    <div>
                      <label htmlFor="formFileSm" className="form-label">
                        Result
                      </label>
                    </div>
                    <div>
                      {ChannelId > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#FirmAddNewChannelPartner">+ Add New</a></span> : ''}
                    </div>
                  </div>
                  {ModelChannelPartnerList.map(item => (
                    <div className="col-lg-12" key={item.ChannelPartnerId}>
                      <a href="">
                        <div className="team-modal-box">

                          <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center border-bottom pb-2">
                              <div className="flex-shrink-0">
                                <div className="avatar-md">
                                  <img
                                    src={item.ChannelPartnerLogo}
                                    alt=""
                                    className="img-thumbnail"
                                  />
                                </div>
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <div className="font-14 dark-font mb-1">
                                  {item.Name}
                                </div>
                                <div className="mb-1">
                                  <a href="" className="table-link-a font-12">
                                    {item.Designation}
                                  </a>
                                </div>
                                <div className="">
                                  <a href="" className="table-link-a font-12">
                                    {item.ChannelName}
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="form-check">
                              <input
                                type="radio"
                                id="1"
                                className="form-check-input"
                                name="ChannelPartnerList"
                                value={item.ChannelPartnerId}
                                data-bs-dismiss="modal" aria-label="Close"
                                onChange={e =>
                                  GetSetChannelPartnerId(item.ChannelPartnerId)
                                }
                              />
                            </div>

                          </div>
                          <div className="modal-sub-box pt-2">
                            <div className="department-box">
                              <a className="mb-0 text-success">
                                <i className="fas fa-phone-alt me-1"></i>
                                {item.MobileNumber}
                              </a>
                            </div>
                            <div className="emp-code">
                              <a className="mb-0 text-primary">
                                <i className="fas fa-envelope me-1"></i>
                                {item.Email}
                              </a>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </Row>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn_success">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn_danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-lg" id="partnerdetails" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Partner Details List</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col className="col-lg-12 col-md-12">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Company Name</th>
                          <th>Name</th>
                          <th>PAN No.</th>
                          <th>Contact No.</th>
                          <th>Email Address</th>
                        </tr>
                      </thead>
                      <tbody>

                        {LeadPartnerDetailList.map((item, idx) => (
                          <tr key={idx}>
                            <td>{item.CompanyName}</td>
                            <td>{item.PartnerName}</td>
                            <td>{item.PartnerPAN}</td>
                            <td>{item.PartnerContactNo}</td>
                            <td>{item.PartnerEmailAddress}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-lg" id="FirmAddNewBank" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add New Channel Source</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ChannelDetailClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>
                  <Col lg={4} className="mt-2"></Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-3 labeledit text-center">
                      <div>
                        <label htmlFor="uploadimage" className="form-label">
                          Upload Image
                        </label>
                      </div>
                      <img id="IGProfileImage" src={ChannelLogo} className="channellogoimage"></img>
                      <input type="file" id="fuChannelLogo" className="form-control form-control mt-3" onChange={ChannelLogofetchPost} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2"></Col>
                  <Col lg={6} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Channel Type</label>
                      <select className="form-select" Id="ddlChanneltype" name="ddlChanneltype" onChange={(e) => { setChannelTypeId(e.target.value); getChannelMasterList(e.target.value) }}>
                        <option value={"0"}>Select Channel Type</option>
                        {ChannelTypeList.map((ChannelType) => (
                          <option key={ChannelType.ChannelTypeId} value={ChannelType.ChannelTypeId}> {ChannelType.ChannelTypeName} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={6} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Channel Master</label>
                      <select className="form-select" Id="ddlChannelMaster" name="ddlChannelMaster" onChange={(e) => { getChannelMasterDetail(e.target.value); setChannelMasterId(e.target.value) }}>
                        <option value={"0"}>Select Channel Master</option>
                        {ChannelMasterList.map((ChannelMaster) => (
                          <option key={ChannelMaster.ChannelMasterId} value={ChannelMaster.ChannelMasterId}> {ChannelMaster.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Channel Source</label>
                      <Input type="text" className="form-control" id="txtChannelName" placeholder="Enter Channel Source" onChange={(e) => setChannelName(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Branch Name</label>
                      <Input type="text" className="form-control" id="txtBranchName" placeholder="Enter BranchName" onChange={(e) => setBranchName(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label"> Branch Code</label>
                      <Input type="text" className="form-control" id="txtBranchCode" placeholder="Enter BranchCode" onChange={(e) => setBranchCode(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label"> IFSC Code</label>
                      <Input type="text" className="form-control" id="txtIFSC" placeholder="Enter IFSC" onChange={(e) => setIFSCCode(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label"> Branch Contact No</label>
                      <Input type="text" className="form-control" id="txtContactNo" placeholder="Enter ContactNo" onChange={(e) => setBranchContactNo(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label"> Branch Email Address</label>
                      <Input type="text" className="form-control" id="txtEmail" placeholder="Enter Email" onChange={(e) => setBranchEmailAddress(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label"> Branch Location</label>
                      <Input type="text" className="form-control" id="txtBranchLocation" placeholder="Enter Branch Location" onChange={(e) => setBranchLocation(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-2">
                      <label className="form-label">Address</label>
                      <Input type="textarea" className="form-control" id="txtAddress" placeholder="Enter Address" onChange={(e) => setBranchAddress(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Country</label>
                      <select className="form-select" Id="ddlChannelCountry" onChange={(e) => { getChannelStateList(e.target.value); setBranchCountryId(e.target.value) }}>
                        <option value={"0"}>Select Country</option>
                        {ChannelCountryList.map((country) => (
                          <option key={country.CountryId} value={country.CountryId}> {country.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">State</label>
                      <select className="form-select" Id="ddlChannelState" onChange={(e) => { getChannelDistrictList(e.target.value); setBranchStateId(e.target.value) }}>
                        <option value={"0"}>Select State</option>
                        {ChannelStateList.map((state) => (
                          <option key={state.StateId} value={state.StateId}> {state.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">District</label>
                      <select className="form-select" Id="ddlChannelDistrict" onChange={(e) => { getChannelTehsilList(e.target.value); setBranchDistrictId(e.target.value) }}>
                        <option value={"0"}>Select District</option>
                        {ChannelDistrictList.map((district) => (
                          <option key={district.DistrictId} value={district.DistrictId}> {district.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label htmlFor="formFileSm" className="form-label">Tehsil</label>
                        </div>
                        <div>
                          {AddTehsil == 'A' ? parseInt(BranchDistrictId) > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddChannelTehsil">+ Add New</a></span> : '' : ''}
                        </div>
                      </div>
                      <select className="form-select" Id="ddlChannelTehsil" onChange={(e) => { getChannelCityList(e.target.value); setBranchTehsilId(e.target.value) }}>
                        <option value={"0"}>Select Tehsil</option>
                        {ChannelTehsilList.map((Tehsil) => (
                          <option key={Tehsil.TehsilId} value={Tehsil.TehsilId}> {Tehsil.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label htmlFor="formFileSm" className="form-label">City/Village/Town</label>
                        </div>
                        <div>
                          {AddCity == 'A' ? parseInt(BranchTehsilId) > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddChannelCity">+ Add New</a></span> : '' : ''}
                        </div>
                      </div>
                      <select className="form-select" Id="ddlChannelCity" onChange={(e) => setBranchCityId(e.target.value)}>
                        <option value={"0"}>Select City/Village/Town</option>
                        {ChannelCityList.map((city) => (
                          <option key={city.CityId} value={city.CityId}> {city.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Pincode</label>
                      <Input type="text" className="form-control" id="txtChannelPincode" placeholder="Enter Pincode" maxlength="6" onChange={(e) => setBranchPincode(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={ChannelDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={ChannelDetailClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-lg" id="FirmAddNewChannelPartner" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add New Channel Sub Source</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ChannelDetailClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Logo / Profile Image</label>
                      <input type="file" className="form-control form-control" id="FUCSSLogo" name="file" onChange={ChannelSubSourceLogofetchPost} />
                    </div>
                  </Col>
                  <Col lg={8} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Name</label>
                      <Input type="text" className="form-control" id="txtCSSName" placeholder="Enter Name" onChange={(e) => setChannelSubSourceName(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Designation</label>
                      <select className="form-select" Id="ddlCSSDesignation" name="ddlCSSDesignation" onChange={(e) => setChannelSubSourceDesignationId(e.target.value)}>
                        <option value={"0"}>Select Designation</option>
                        {ChannelSubSourceDesignationList.map((di) => (
                          <option key={di.Id} value={di.Id}> {di.Name} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Department</label>
                      <select className="form-select" Id="ddlCSSDepartment" name="ddlCSSDepartment" onChange={(e) => setChannelSubSourceDepartmentId(e.target.value)}>
                        <option value={"0"}>Select Department</option>
                        {ChannelSubSourceDepartmentList.map((d) => (
                          <option key={d.Id} value={d.Id}> {d.Name} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Mobile Number</label>
                      <Input type="text" className="form-control" id="txtCSSMobileNumber" placeholder="Enter Mobile Number" max={10} onChange={(e) => setChannelSubSourceMobileNumber(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Alternate Mobile Number</label>
                      <Input type="text" className="form-control" id="txtCSSAlternatemobileNumber" max={10} placeholder="Enter Alternate Mobile Number" onChange={(e) => setChannelSubSourceAltMobileNumber(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Email</label>
                      <Input type="text" className="form-control" id="txtCSSEmail" placeholder="Enter Email" onChange={(e) => setChannelSubSourceEmail(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label"> Alternate Email</label>
                      <Input type="text" className="form-control" id="txtCSSAlternateEmail" placeholder="Enter Alternate Email" onChange={(e) => setChannelSubSourceAltEmail(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-3 labeledit">
                      <label htmlFor="uploadimage" className="form-label">
                        Visiting Card
                      </label>
                      <input type="file" className="form-control form-control" id="FUCSSVisitingCard" name="file" onChange={ChannelSubSourceVisitingCardfetchPost} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label"> Date Of Birth</label>
                      <Input type="date" className="form-control" id="txtCSSBirth" onChange={(e) => setChannelSubSourceDateOfBirth(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label"> Date Of Anniversary</label>
                      <Input type="date" className="form-control" id="txtCSSAnniversary" onChange={(e) => setChannelSubSourceDateOfAnniversary(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={6} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">First Meeting Brief </label>
                      <textarea type="text" rows="1" className="form-control" placeholder="First Meeting Brief" id="txtCSSFirst" onChange={(e) => setChannelSubSourceFirstMeetingBrief(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={6} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Current Address</label>
                      <textarea type="text" rows="1" className="form-control" id="txtCSSAddress" placeholder="Enter CAddress" onChange={(e) => setChannelSubSourceAddress(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Country</label>
                      <select className="form-select" Id="ddlCSSCountry" name="ddlCSSCountry" onChange={(e) => { getChannelSubSourceStateList(e.target.value); setChannelSubSourceCountryId(e.target.value) }}>
                        <option value={"0"}>Select Country</option>
                        {ChannelSubSourceCountryList.map((country) => (
                          <option key={country.CountryId} value={country.CountryId}> {country.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">State</label>
                      <select className="form-select" Id="ddlCSSState" name="ddlCSSState" onChange={(e) => { getChannelSubSourceDistrictList(e.target.value); setChannelSubSourceStateId(e.target.value) }}>
                        <option value={"0"}>Select State</option>
                        {ChannelSubSourceStateList.map((state) => (
                          <option key={state.StateId} value={state.StateId}> {state.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">District</label>
                      <select className="form-select" Id="ddlCSSDistrict" name="ddlCSSDistrict" onChange={(e) => { getChannelSubSourceTehsilList(e.target.value); setChannelSubSourceDistrictId(e.target.value) }}>
                        <option value={"0"}>Select District</option>
                        {ChannelSubSourceDistrictList.map((district) => (
                          <option key={district.DistrictId} value={district.DistrictId}> {district.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label htmlFor="formFileSm" className="form-label">Tehsil</label>
                        </div>
                        <div>
                          {AddTehsil == 'A' ? parseInt(ChannelSubSourceDistrictId) > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddChannelSubSourceTehsil">+ Add New</a></span> : '' : ''}
                        </div>
                      </div>
                      <select className="form-select" Id="ddlCSSTehsil" name="ddlCSSTehsil" onChange={(e) => { getChannelSubSourceCityList(e.target.value); setChannelSubSourceTehsilId(e.target.value) }}>
                        <option value={"0"}>Select Tehsil</option>
                        {ChannelSubSourceTehsilList.map((Tehsil) => (
                          <option key={Tehsil.TehsilId} value={Tehsil.TehsilId}> {Tehsil.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label htmlFor="formFileSm" className="form-label">City/Village/Town</label>
                        </div>
                        <div>
                          {AddCity == 'A' ? parseInt(ChannelSubSourceTehsilId) > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddChannelSubSourceCity">+ Add New</a></span> : '' : ''}
                        </div>
                      </div>
                      <select className="form-select" Id="ddlCSSCity" name="ddlCSSCity" onChange={(e) => setChannelSubSourceCityId(e.target.value)}>
                        <option value={"0"}>Select City/Village/Town</option>
                        {ChannelSubSourceCityList.map((city) => (
                          <option key={city.CityId} value={city.CityId}> {city.Title} </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={4} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Pincode</label>
                      <Input type="text" className="form-control" id="txtCSSPincode" placeholder="Enter Pincode" maxlength="6" onChange={(e) => setChannelSubSourcePincode(e.target.value)} />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={ChannelSubSourceDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={ChannelSubSourceDetailClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-sm" id="AddFirmTehsil" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add Tehsil</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={PerTehsilClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>

                  <Col lg={12} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Tehsil</label>
                      <Input type="text" className="form-control" id="txtPerTehsil" placeholder="Enter Tehsil" />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={PerTehsilDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={PerTehsilClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-sm" id="AddFirmCity" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add City</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={PerCityClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>

                  <Col lg={12} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">City</label>
                      <Input type="text" className="form-control" id="txtPerCity" placeholder="Enter City" />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={PerCityDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={PerCityClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-sm" id="AddChannelTehsil" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add Tehsil</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ChannelTehsilClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>

                  <Col lg={12} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Tehsil</label>
                      <Input type="text" className="form-control" id="txtChannelTehsil" placeholder="Enter Tehsil" />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={ChannelTehsilDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={ChannelTehsilClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-sm" id="AddChannelCity" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add City</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ChannelCityClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>

                  <Col lg={12} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">City</label>
                      <Input type="text" className="form-control" id="txtChannelCity" placeholder="Enter City" />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={ChannelCityDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={ChannelCityClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-sm" id="AddChannelSubSourceTehsil" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add Tehsil</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ChannelSubSourceTehsilClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>

                  <Col lg={12} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">Tehsil</label>
                      <Input type="text" className="form-control" id="txtChannelSubSourceTehsil" placeholder="Enter Tehsil" />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={ChannelSubSourceTehsilDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={ChannelSubSourceTehsilClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade bd-example-modal-sm" id="AddChannelSubSourceCity" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title" id="exampleModalLabel">Add City</h5>
                </div>
                <div>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ChannelSubSourceCityClose}></button>
                </div>
              </div>
              <div className="modal-body">
                <Row>

                  <Col lg={12} className="mt-2">
                    <div className="mb-2">
                      <label className="form-label">City</label>
                      <Input type="text" className="form-control" id="txtChannelSubSourceCity" placeholder="Enter City" />
                    </div>
                  </Col>
                  <Col lg={12} className="mt-2">
                    <button className="btn btn_success btn-sm" id="btnsave" onClick={ChannelSubSourceCityDetailSave} data-bs-dismiss="modal" aria-label="Close">Save</button>
                    <button className="btn btn_danger btn-sm ms-2" id="btnClose" onClick={ChannelSubSourceCityClose} data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle>Closed Lead Detail</CardTitle>
              <a href="/" className="me-2">
                Home
              </a>{" "}
              <span>/</span>
              <a href="/ClosedList" className="me-2 ms-2">
                Closed Lead
              </a>{" "}
              <span>/</span>
              <span className="ms-2">Closed Lead Detail</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-12">
            <a
              className="btn btn-primary btn-sm float-end font-14"
              href="/ClosedList"
            >
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <Row>
          <Col className="col-lg-12 col-12">
            <Card>
              <CardBody>
                <Row className="align-items-center">
                  <Col className="col-md-10 col-12">
                    <Row className="mb-2">
                      <Col className="col-12 d-none d-md-block">
                        <h5 className="fw-bolder mb-2">{CompanyName} <span className="text-primary">({LeadCode})</span></h5>
                        <hr />
                      </Col>
                      <Col className="col-md-2 col-lg-2 col-12 text-center d-block d-md-none">
                        <div className="">
                          <img
                            className="rounded-circle clientactimg"
                            src={LeadProfileImage}
                          ></img>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col className="col-md-4 col-12 mb-md-0 mb-3">
                        <p className="mb-2 fontWeight500"> Mobile Number </p>
                        <Input
                          type="text"
                          className=""
                          readOnly
                          id="txtMobileNumber"
                          defaultValue={CompanyMobileNumber}
                          placeholder="Enter Mobile Number"
                        />
                      </Col>
                      <Col className="col-md-4 col-12 mb-md-0 mb-3">
                        <p className="mb-2 fontWeight500"> Email Address </p>
                        <Input
                          type="text"
                          className=""
                          readOnly
                          id="txtEmailAddress"
                          defaultValue={CompanyEmailAddress}
                          placeholder="Enter Email Address"
                        />
                      </Col>
                      <Col className="col-md-4 col-12 mb-md-0 mb-3">
                        <p className="mb-2 fontWeight500"> Type </p>
                        <Input
                          type="text"
                          readOnly
                          className=""
                          id="txtType"
                          defaultValue={LeadType}
                          placeholder="Enter Type"
                        />
                      </Col>
                      <Col className="col-md-3 col-12 mobile-mt mt-3">
                            <p className="mb-2 fontWeight500"> Inquiry Date </p>
                            <Input
                              type="text"
                              readOnly
                              defaultValue={SCreateDate}
                              id="txtInquiryDate"
                            />
                          </Col>
                          <Col className="col-md-3 col-12 mobile-mt mt-3">
                            <p className="mb-2 fontWeight500"> Converted Date </p>
                            <Input
                              type="text"
                              readOnly
                              defaultValue={SConvertDate}
                              id="txtConvertDate"
                            />
                          </Col>
                          <Col className="col-md-3 col-12 mobile-mt mt-3">
                            <p className="mb-2 fontWeight500"> Registered Date </p>
                            <Input
                              type="text"
                              readOnly
                              defaultValue={SRegisterDate}
                              id="txtRegisteredDate"
                            />
                          </Col>

                          <Col className="col-md-3 col-12 mobile-mt mt-3">
                            <p className="mb-2 fontWeight500"> Closed Date </p>
                            <Input
                              type="text"
                              readOnly
                              defaultValue={SCloseDate}
                              id="txtClosedDate"
                            />
                          </Col>
                    </Row>
                  </Col>
                  <Col className="col-md-2 col-lg-2 col-12 float-end d-none d-md-block">
                    <div className="">
                      <img
                        className="rounded-circle float-end clientactimg"
                        src={LeadProfileImage}
                      ></img>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="col-lg-12 col-md-12 col-12">
            <Card>
              <CardBody>
                <Row>
                  <Col className="col-lg-12 col-md-12 col-12 mb-2">
                    <CardTitle>Company Details</CardTitle>
                    <hr />
                  </Col>
                  <Col className="col-lg-12 col-md-6 col-12 mb-3">
                    <p className="mb-2 fontWeight500"> Company Type </p>
                    <div onChange={onChangeCompanyTypeValue} className="d-flex align-items-center">
                      <div className="form-check mb-2">
                        <input id="RBPartnership" className="form-check-input" name="RBCompanyType" value="Partnership" type="radio" checked={CompanyType === "Partnership"} />
                        <Label className="form-check-label" htmlFor="RBPartnership">
                          Partnership
                        </Label>
                      </div>
                      <div className="form-check mb-2 ms-2">
                        <input id="RBPrivateLimited" className="form-check-input" name="RBCompanyType" value="PrivateLimited" type="radio" checked={CompanyType === "PrivateLimited"} />
                        <Label className="form-check-label" htmlFor="RBPrivateLimited">
                          PrivateLimited
                        </Label>
                      </div>
                      <div className="form-check mb-2 ms-2">
                        <input id="RBPublic" className="form-check-input" name="RBCompanyType" value="Public" type="radio" checked={CompanyType === "Public"} />
                        <Label className="form-check-label" htmlFor="RBPublic" >
                          Public
                        </Label>
                      </div>
                      <div className="form-check mb-2 ms-2">
                        <input id="RBLLP" className="form-check-input" name="RBCompanyType" value="LLP" type="radio" checked={CompanyType === "LLP"} />
                        <Label className="form-check-label" htmlFor="RBLLP" >
                          LLP
                        </Label>
                      </div>
                      <div className="form-check mb-2 ms-2">
                        <input id="RBProprietorship" className="form-check-input" name="RBCompanyType" value="Proprietorship" type="radio" checked={CompanyType === "Proprietorship"} />
                        <Label className="form-check-label" htmlFor="RBProprietorship" >
                          Proprietorship
                        </Label>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-lg-4 col-md-6 col-12">
                    <p className="mb-2 fontWeight500"> Company Name </p>
                    <Input type="text" className="" id="txtCompanyName" defaultValue={CompanyName} onChange={(e) => setCompanyName(e.target.value)} />
                  </Col>
                  <Col className="col-lg-2 col-md-6 col-12">
                    <p className="mb-2 fontWeight500"> Mobile Number </p>
                    <Input type="text" className="" id="txtCompanyMobileNumber" defaultValue={CompanyMobileNumber} onChange={(e) => setCompanyMobileNumber(e.target.value)} />
                  </Col>
                  <Col className="col-lg-4 col-md-6 col-12">
                    <p className="mb-2 fontWeight500"> Email Address </p>
                    <Input type="text" className="" id="txtCompanyEmailAddress" defaultValue={CompanyEmailAddress} onChange={(e) => setCompanyEmailAddress(e.target.value)} />
                  </Col>
                  <Col className="col-lg-2 col-md-6 col-12">
                    <p className="mb-2 fontWeight500"> Date Of Registration </p>
                    <Input type="date" className="" id="txtDateofBirth" defaultValue={DateOfBirth} onChange={e => setDateOfBirth(e.target.value)} placeholder="Enter Date Of Registration" />
                  </Col>
                  <Col className="col-lg-2 col-md-6 col-12 mt-3">
                    <p className="mb-2 fontWeight500"> Pan No. </p>
                    <Input type="text" className="" id="txtCompanyPan" defaultValue={CompanyPan} onChange={(e) => setCompanyPan(e.target.value)} />
                  </Col>
                  <Col className="col-lg-2 col-md-6 col-12 mt-3">
                    <p className="mb-2 fontWeight500"> GST No. </p>
                    <Input type="text" className="" id="txtCompanyGSTNo" defaultValue={CompanyGSTNo} onChange={(e) => setCompanyGSTNo(e.target.value)} />
                  </Col>
                  <Col className="col-lg-2 col-md-6 col-12 mt-3">
                    <p className="mb-2 fontWeight500"> TIN No. </p>
                    <Input type="text" className="" id="txtCompanyGSTNo" defaultValue={CompanyTINNo} onChange={(e) => setCompanyTINNo(e.target.value)} />
                  </Col>
                  <Col className="col-lg-3 col-md-6 col-12 mt-3">
                    <p className="mb-2 fontWeight500"> CIR ID </p>
                    <Input type="text" className="" id="txtCIRID" defaultValue={CIRID} onChange={(e) => setCIRID(e.target.value)} />
                  </Col>
                  <Col className="col-lg-3 col-md-6 col-12 mt-3">
                    <p className="mb-2 fontWeight500"> CIR Password </p>
                    <div className="input-group auth-pass-inputgroup">
                      <input id="txtCIRPassword" type="password" className="form-control" placeholder="Enter CIR Password" aria-label="Password" aria-describedby="password-addon" onChange={e => setCIRPassword(e.target.value)} defaultValue={CIRPassword} />
                      <button className="btn btn-light " type="button" id="password-addon" onClick={e => PasswordInputChange(e, "txtCIRPassword")}><i id="iFPassword" className="mdi mdi-eye-outline"></i></button>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col className="col-lg-12 col-md-12 col-12 mt-3">
                    <label htmlFor="Address" className="form-label">Address</label>
                    <textarea className="form-control" placeholder="Address" id="Remarks" rows="3" defaultValue={Address} name="Address" onChange={e => setAddress(e.target.value)}></textarea>
                  </Col>
                  <Col className="col-lg-2 col-md-6 col-12 mt-3">
                    <p className="mb-2 fontWeight500"> State </p>
                    <select className="form-select" Id="ddlCompanyState" name="ddlCompanyState" onChange={(e) => { getDistrictList(e.target.value); setStateId(e.target.value) }}>
                      <option key="0" value="0">Select State</option>
                      {StateList.map((item) => (
                        <option key={item.StateId} value={item.StateId} selected={StateId === item.StateId}>
                          {item.Title}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col className="col-lg-2 col-md-6 col-12 mt-3">
                    <p className="mb-2 fontWeight500"> District </p>
                    <select className="form-select" Id="ddlCompanyDistrict" name="ddlCompanyDistrict" onChange={(e) => { getTehsilList(e.target.value); setDistrictId(e.target.value) }}>
                      <option key="0" value="0">Select District</option>
                      {DistrictList.map((item) => (
                        <option key={item.DistrictId} value={item.DistrictId} selected={DistrictId === item.DistrictId}>
                          {item.Title}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col className="col-lg-3 col-md-6 col-12 mt-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <label htmlFor="formFileSm" className="form-label">Tehsil</label>
                      </div>
                      <div>
                        {AddTehsil == 'A' ? parseInt(DistrictId) > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddFirmTehsil">+ Add New</a></span> : '' : ''}
                      </div>
                    </div>
                    <select className="form-select" Id="ddlCompanyTehsilId" name="ddlCompanyTehsilId" onChange={(e) => { getCityList(e.target.value); setTehsilId(e.target.value) }}>
                      <option key="0" value="0">Select Tehsil</option>
                      {TehsilList.map((item) => (
                        <option key={item.TehsilId} value={item.TehsilId} selected={TehsilId === item.TehsilId}>
                          {item.Title}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col className="col-lg-3 col-md-6 col-12 mt-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <label htmlFor="formFileSm" className="form-label">City/Village/Town</label>
                      </div>
                      <div>
                        {AddCity == 'A' ? parseInt(TehsilId) > 0 ? <span className="float-end text-primary font-12"><a href="#" data-bs-toggle="modal" data-bs-target="#AddFirmCity">+ Add New</a></span> : '' : ''}
                      </div>
                    </div>
                    <select className="form-select" Id="ddlCompanyCity" name="ddlCompanyCity" onChange={(e) => setCityId(e.target.value)}>
                      <option key="0" value="0">Select City/Village/Town</option>
                      {CityList.map((item) => (
                        <option key={item.CityId} value={item.CityId} selected={CityId === item.CityId}>
                          {item.Title}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col className="col-lg-2 col-md-6 col-12 mt-3">
                    <p className="mb-2 fontWeight500"> PinCode </p>
                    <Input type="text" className="" id="txtPinCode" defaultValue={PinCode} placeholder="Enter PinCode" onChange={(e) => setPinCode(e.target.value)} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Col className="col-lg-12 col-md-12 col-12 mb-2">
                  <CardTitle>Partner Details</CardTitle>
                  <hr />
                </Col>
                <table style={{ width: "100%" }}>
                  <tbody>
                    {LeadPartnerList.map((item, idx) => (
                      <tr key={idx}>
                        <td>
                          <div data-repeater-list="group-a mb-3">
                            <Row data-repeater-item className="align-items-center">
                              <Col lg="3" className="mb-3 col-md-6">
                                <Label htmlFor="name">Partner Name</Label>
                                <Input type="text" id={item.PartnerNameTextBoxControlId} className="form-control" defaultValue={item.PartnerName} />
                              </Col>
                              <Col lg="2" className="mb-3 col-md-6">
                                <Label htmlFor="name">Partner PAN</Label>
                                <Input type="text" id={item.PartnerPANTextBoxControlId} className="form-control" defaultValue={item.PartnerPAN} />
                              </Col>
                              <Col lg="2" className="mb-3 col-md-6">
                                <Label htmlFor="name">Partner Contact No.</Label>
                                <Input type="text" id={item.PartnerContactNoTextBoxControlId} className="form-control" defaultValue={item.PartnerContactNo} />
                              </Col>
                              <Col lg="2" className="mb-3 col-md-6">
                                <Label htmlFor="name">Partner Email Address</Label>
                                <Input type="text" id={item.PartnerEmailAddressTextBoxControlId} className="form-control" defaultValue={item.PartnerEmailAddress} />
                              </Col>
                              <Col lg="2" className="mb-3 col-md-6">
                                <Label htmlFor="name">Partner Password</Label>
                                <div className="input-group auth-pass-inputgroup">
                                  <input id={item.PartnerPasswordTextBoxControlId} type="password" className="form-control" placeholder="Password" aria-label="Password" aria-describedby="password-addon" defaultValue={item.PartnerPassword} />
                                  <button className="btn btn-light " type="button" id="password-addon" onClick={e => PasswordInputChange(e, item.PartnerPasswordTextBoxControlId)}><i id="iFPassword" className="mdi mdi-eye-outline"></i></button>
                                </div>
                              </Col>
                              <Col lg="1" className="mt-2 col-md-6">
                                <Button onClick={e => PartnerhandleRemoveRow(e, item.PartnerId)} color="danger" className="btn-sm btn-width">
                                  <i className="fas fa-trash-alt"></i>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div data-repeater-list="group-a">
                  <Row data-repeater-item className="align-items-center">
                    <Col lg="3" className="mb-3 col-md-6">
                      <Label htmlFor="name">Partner Name <a data-bs-toggle="modal" data-bs-target="#partnerdetails"><i className="fas fa-eye ms-2 fasfaeye"></i></a></Label>
                      <Input type="text" id="txtPartnerName" name="untyped-input" className="form-control" placeholder="Partner Name" onChange={e => getPartnerFilterList(e.target.value, 'LeadPartnerFilterByName')} />
                    </Col>
                    <Col lg="2" className="mb-3 col-md-6">
                      <Label htmlFor="name">Partner PAN <a data-bs-toggle="modal" data-bs-target="#partnerdetails"><i className="fas fa-eye ms-2 fasfaeye"></i></a></Label>
                      <Input type="text" id="txtPartnerPAN" name="untyped-input" className="form-control" placeholder="Partner PAN" onChange={e => getPartnerFilterList(e.target.value, 'LeadPartnerFilterByPan')} />
                    </Col>
                    <Col lg="2" className="mb-3 col-md-6">
                      <Label htmlFor="name">Partner Con. No. <a data-bs-toggle="modal" data-bs-target="#partnerdetails"><i className="fas fa-eye ms-2 fasfaeye"></i></a></Label>
                      <Input type="text" id="txtPartnerContact" name="untyped-input" className="form-control" placeholder="Partner Contact No." onChange={e => getPartnerFilterList(e.target.value, 'LeadPartnerFilterByContactNo')} />
                    </Col>
                    <Col lg="2" className="mb-3 col-md-6">
                      <Label htmlFor="name">Partner Email <a data-bs-toggle="modal" data-bs-target="#partnerdetails"><i className="fas fa-eye ms-2 fasfaeye"></i></a></Label>
                      <Input type="text" id="txtPartnerEmail" name="untyped-input" className="form-control" placeholder="Partner Email Address" onChange={e => getPartnerFilterList(e.target.value, 'LeadPartnerFilterByEmailAddress')} />
                    </Col>
                    <Col lg="2" className="mb-3 col-md-6">
                      <Label htmlFor="name">Partner Password</Label>
                      <div className="input-group auth-pass-inputgroup">
                        <input id="txtPartnerPassword" type="password" className="form-control" placeholder="Password" aria-label="Password" aria-describedby="password-addon" />
                        <button className="btn btn-light " type="button" id="password-addon" onClick={e => PasswordInputChange(e, "txtPartnerPassword")}><i id="iFPassword" className="mdi mdi-eye-outline"></i></button>
                      </div>
                    </Col>
                    <Col lg="1">
                      <Button onClick={PartnerhandleAddRow} color="primary" className="mt-2 btn-sm">
                        <i className="fas fa-plus me-2"></i>Add
                      </Button>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row className="">
                  <Col className="col-lg-12 mb-2">
                    <CardTitle>Contact Information</CardTitle>
                    <hr />
                  </Col>
                  <Col lg="4">
                    <table style={{ width: "100%" }}>
                      <tbody>
                        {LeadContactList.map((item, idx) => (
                          <tr id={"addr" + idx} key={idx}>
                            <td>
                              <div data-repeater-list="group-a">
                                <Row data-repeater-item className="align-items-end mb-3">
                                  <Col lg="10" className="">
                                    <Label htmlFor="name">
                                      Mobile No.{"       "}
                                      {item.IsActiveWhatsApp == true ? <input className="form-check-input ms-2" type="checkbox" id={item.CheckBoxControlId} checked /> : <input className="form-check-input ms-2" type="checkbox" id={item.CheckBoxControlId} />}{" "}Is Active WhatsApp?
                                    </Label>
                                    <Input type="text" id={item.TextBoxControlId} className="form-control" placeholder="Enter Mobile No" defaultValue={item.MobileNo} />
                                  </Col>
                                  <Col lg="2 mobile-mt">
                                    <Button
                                      onClick={e =>
                                        ContacthandleRemoveRow(e, item.ContactId, item.LeadContactId)
                                      }
                                      color="danger"
                                      className="btn-sm btn-width mb-1 mt-md-3 mt-lg-0"
                                    >
                                      <i className="fas fa-trash-alt"></i>
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div data-repeater-list="group-a" className="mb-2">
                      <Row data-repeater-item className="align-items-end">
                        <Col lg="9" className="">
                          <Label htmlFor="name">
                            Mobile No.{"       "}
                            <input className="form-check-input ms-2" type="checkbox" id="ChkFActiveWhatsApp" />{" "}Is Active WhatsApp?
                          </Label>
                          <Input type="text" id="txtFMobileNo" className="form-control" placeholder="Enter Mobile No" />
                        </Col>
                        <Col lg="3" className="mt-3">
                          <Button onClick={ContacthandleAddRow} color="primary" className="btn-sm mb-1"><i className="fas fa-plus me-2"></i>Add</Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg="7">
                    <table style={{ width: "100%" }}>
                      <tbody>
                        {LeadEmailList.map((item, idx) => (
                          <tr id={"addr" + idx} key={idx}>
                            <td>
                              <div data-repeater-list="group-a mb-3">
                                <Row data-repeater-item className="align-items-center mobile-mt">
                                  <Col lg="6" className="mb-3 col-md-6">
                                    <Label htmlFor="name">Email</Label>
                                    <Input type="text" id={item.EmailTextBoxControlId} className="form-control" placeholder="Enter Email" defaultValue={item.EmailAddress} />
                                  </Col>
                                  <Col lg="4" className="mb-3 col-md-6">
                                    <Label htmlFor="name">Password</Label>
                                    <div className="input-group auth-pass-inputgroup">
                                      <input id={item.PasswordTextBoxControlId} type="password" className="form-control" placeholder="Enter Password" aria-label="Password" aria-describedby="password-addon" defaultValue={item.Password} />
                                      <button className="btn btn-light " type="button" id="password-addon" onClick={e => PasswordInputChange(e, item.PasswordTextBoxControlId)}><i id="iFPassword" className="mdi mdi-eye-outline"></i></button>
                                    </div>
                                  </Col>
                                  <Col lg="2" className="col-5 col-md-2">
                                    <Button onClick={e => EmailhandleRemoveRow(e, item.RowId, item.LeadEmailId)} color="danger" className="mt-2 btn-sm btn-width">
                                      <i className="fas fa-trash-alt"></i>
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div data-repeater-list="group-a" className="mb-2">
                      <Row data-repeater-item className="align-items-center">
                        <Col lg="6" className="mb-3 col-md-6">
                          <Label htmlFor="name">Email</Label>
                          <Input type="text" id="txtFEmailAddress" className="form-control" placeholder="Enter Email" />
                        </Col>
                        <Col lg="4" className="mb-3 col-md-6">
                          <Label htmlFor="name">Password</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <input id="txtFPassword" type="password" className="form-control" placeholder="Enter Password" aria-label="Password" aria-describedby="password-addon" />
                            <button className="btn btn-light " type="button" id="password-addon" onClick={e => PasswordInputChange(e, "txtFPassword")}><i id="iFPassword" className="mdi mdi-eye-outline"></i></button>
                          </div>
                        </Col>
                        <Col lg="2">
                          <Button onClick={EmailhandleAddRow} color="primary" className="mt-2 btn-sm">
                            <i className="fas fa-plus me-2"></i>Add
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <Col className="col-lg-12 col-md-12 mb-2">
                    <CardTitle>Document Details</CardTitle>
                    <hr />
                  </Col>
                  <div className="col-lg-3 col-md-6">
                    <div className="mb-3">
                      <label htmlFor="DocumentType" className="form-label">Document Type</label>
                      <select className="form-select" id="ddlDocumentTypeI" onChange={DocumentTypeOnChange}>
                        <option value={"0"}>Select Documet type</option>
                        {DocumentTypeList.map(doctype => (
                          <option key={doctype.DocumentId} value={doctype.DocumentId}>{doctype.DocumentType}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="mb-3">
                      <label htmlFor="txtKYCDocNo" className="form-label">KYC Doc. No.</label>
                      <input type="text" className="form-control form-control" id="txtKYCDocNo" onChange={KYCDocOnChange} placeholder="KYC Doc. No." />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="mb-3">
                      <label htmlFor="DocumentType" className="form-label">Upload File</label>
                      <input type="file" className="form-control form-control" id="FUDocumentFilePathI" onChange={DocumentfetchPost} />
                    </div>
                  </div>
                  <Col className="col-lg-2 mt-2">
                    <Button onClick={DocumenthandleAddRow} color="primary" className="btn-sm mt-lg-4 mt-md-0 mb-md-3"><i className="fas fa-plus me-2"></i>Add</Button>
                  </Col>
                  <Col className="col-lg-12 col-md-12 mt-3">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" className="text-center">#</th>
                          <th scope="col" className="text-center">Type</th>
                          <th scope="col" className="text-center">KYC Doc. No.</th>
                          <th scope="col">Uploaded Image</th>
                          <th scope="col" className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {DocumentList.map(item => (
                          <tr key={item.LeadDocumentId}>
                            <th scope="row" className="text-center">{item.RowNo}</th>
                            <td className="text-center">{item.DocumentType}</td>
                            <td className="text-center">{item.Remarks}</td>
                            <td className="w-30">
                              {
                                item.DocumentFileEx == 'jpeg' ?
                                  ''
                                  :
                                  item.DocumentFileEx == 'jpg' ?
                                    ''
                                    :
                                    item.DocumentFileEx == 'png' ?
                                      ''
                                      :
                                      <i className="fa fa-file me-2 fadocumenticoncolor" aria-hidden="true"></i>
                              }
                              {
                                item.DocumentFileEx == 'jpeg' ?
                                <a href={item.DocumentFilePath} target="_blank" rel="noopener noreferrer" className="fadocumenttitleiconcolor" ><img src={item.DocumentFilePath} className="img-fluid"></img></a>
                                  :
                                  item.DocumentFileEx == 'jpg' ?
                                  <a href={item.DocumentFilePath} target="_blank" rel="noopener noreferrer" className="fadocumenttitleiconcolor" ><img src={item.DocumentFilePath} className="img-fluid"></img></a>
                                    :
                                    item.DocumentFileEx == 'png' ?
                                    <a href={item.DocumentFilePath} target="_blank" rel="noopener noreferrer" className="fadocumenttitleiconcolor" ><img src={item.DocumentFilePath} className="img-fluid"></img></a>
                                      :
                                      <a href={item.DocumentFilePath} target="_blank" rel="noopener noreferrer" className="fadocumenttitleiconcolor" >{item.DocumentFileName}</a>
                              }
                            </td>
                            <td className="text-center">
                              <a className="documentdelete" onClick={e => DocumenthandleRemoveRow(e, item.LeadDocumentId)} ><i className="fas fa-trash-alt"></i></a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <div className="col-lg-7">
                    <CardTitle>
                      Banking Source
                      <span className="float-end text-primary font-12">
                        <a href="#" data-bs-toggle="modal" data-bs-target="#firmbanking"> <i className="far fa-check-circle"> Select</i></a>
                      </span>
                    </CardTitle>
                    <hr />
                    {SelectChannelDetail.map(item => (
                      <div className="team-modal-box mt-3 mb-2" key={item.ChannelId}>
                        <div className="d-md-flex d-block align-items-center justify-content-between">
                          <div className="d-md-flex d-block align-items-center">

                            <div className="flex-shrink-0">
                              <div className="avatar-md">
                                <img
                                  src={item.ChannelImage}
                                  alt=""
                                  className="img-thumbnail"
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1 ms-md-3 ms-0 mb-md-0 mb-3">
                              <div className="font-14 dark-font mb-1">
                                {item.ChannelName}
                              </div>
                              <div className="mb-1">
                                <a href="" className="table-link-a font-12">
                                  Branch : {item.BranchName}
                                </a>
                              </div>
                              <div className="">
                                <a href="" className="table-link-a font-12">
                                  IFSE Code : {item.BranchIFSCCOde}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="bankdiv">
                            <div className="">
                              <a href="" className="table-link-a">
                                <p className="mb-2 text-success">
                                  <i className="fas fa-phone-alt me-1"></i>
                                  {item.BranchContactNumber}
                                </p>
                              </a>
                              <a href="" className="table-link-a">
                                <p className="mb-0 text-primary">
                                  <i className="fas fa-envelope me-1"></i>
                                  {item.BranchEmailAddress}
                                </p>
                              </a>
                            </div>

                          </div>
                        </div>
                        <div className="d-md-flex d-block justify-content-between align-items-center">
                          <div className="mb-md-0 mb-3">
                            <div className="">
                              <span className="badge badge-soft-primary p-2 me-2">
                                {item.BranchState}
                              </span>
                              <span className="badge badge-soft-primary p-2 me-2">
                                {item.BranchDistrict}
                              </span>
                              <span className="badge badge-soft-primary p-2 me-2">
                                {item.BranchCity}
                              </span>
                              <span className="badge badge-soft-primary p-2 me-2">
                                {item.BranchTehsil}
                              </span>
                              <span className="badge badge-soft-primary p-2 me-2">
                                {item.Pincode}
                              </span>
                            </div>
                          </div>
                          <div className="bankdiv1">
                            <p className="mb-0 font-12">Approved</p>
                            <p className="mb-0 font-12">
                              CID : {item.ChannelCode}
                            </p>
                          </div>

                        </div>


                      </div>
                    ))}
                  </div>
                  <div className="col-lg-5">
                    <CardTitle>
                      Channel Partner
                      <span className="float-end text-primary font-12">
                        <a href="#" data-bs-toggle="modal" data-bs-target="#firmchannel"> <i className="far fa-check-circle"> Select</i></a>
                      </span>
                    </CardTitle>
                    <hr />
                    {SelectChannelPartnerDetail.map(item => (
                      <div className="team-modal-box mt-3 mb-2" key={item.ChannelPartnerId}>
                        <div className="d-flex align-items-center border-bottom cpbottom">
                          <div className="flex-shrink-0">
                            <div className="avatar-md">
                              <img
                                src={item.ChannelPartnerLogo}
                                alt=""
                                className="img-thumbnail"
                              />
                            </div>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <div className="font-14 dark-font mb-1">
                              {item.Name}
                            </div>
                            <div className="mb-1">
                              <a href="" className="table-link-a font-12">
                                {item.Designation}
                              </a>
                            </div>
                            <div className="">
                              <a href="" className="table-link-a font-12">
                                {item.ChannelName}
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="modal-sub-box pt-3 d-block d-md-flex">
                          <div className="department-box mb-2 mb-md-0">
                            <a className="mb-0 text-success">
                              <i className="fas fa-phone-alt me-1"></i>
                              {item.MobileNumber}
                            </a>
                          </div>
                          <div className="emp-code">
                            <a className="mb-0 text-primary">
                              <i className="fas fa-envelope me-1"></i>
                              {item.Email}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <Col className="col-lg-12 col-md-12 mb-2">
                    <CardTitle>Other Details</CardTitle>
                    <hr />
                  </Col>
                  <Col className="col-lg-4 col-md-6 col-12 mb-2">
                    <p className="mb-2 fontWeight500"> Tags </p>
                    <select className="form-select" name="ddlTags" defaultValue={SelectTags} onChange={e => setSelectTags(e.target.value)}>
                    <option value="0">Select Tags</option>
                      {TagsList.map((item) => (
                        <option key={item.Tag} value={item.Tag} selected={SelectTags === item.Tag}>
                          {item.Tag}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col className="col-lg-4 col-md-6 col-12 mb-2">
                    <p className="mb-2 fontWeight500"> Priority </p>
                    <select className="form-select" name="ddlPriority" defaultValue={PriorityId} onChange={e => setPriorityId(e.target.value)}>
                    <option value="0">Select Priority</option>
                      {PriorityList.map((item) => (
                        <option key={item.PriorityId} value={item.PriorityId} selected={PriorityId === item.PriorityId}>
                          {item.Priority}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col className="col-lg-4 col-md-6 col-12 mb-2">
                    <p className="mb-2 fontWeight500"> Reference </p>
                    <input type="text" className="form-control" id="txtReference" defaultValue={Reference} placeholder="Enter Reference" />
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-12 mb-3">
                    <p className="mb-2 fontWeight500"> Remarks </p>
                    <textarea className="form-control" id="txtRemarks" defaultValue={Remarks} placeholder="Enter Remarks" />
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-12 mb-3">
                    <p className="mb-2 fontWeight500"> Lead Briefing </p>
                    <textarea className="form-control" id="txtLeadBriefing" defaultValue={ClientBriefing} placeholder="Enter Lead Briefing" />
                  </Col>
                </Row>
                <Row>
                  <div className="col-lg-12 mb-2">
                    <button type="button" className="btn btn_success me-2" onClick={(e) => UpdateLeadInqiry()} >
                      Save
                    </button>
                    <button type="button" className="btn btn_danger" data-bs-dismiss="modal">
                      Close
                    </button>
                  </div>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <Col className="col-lg-12 col-md-12 mb-2">
                    <CardTitle>Followup List</CardTitle>
                    <hr />
                  </Col>
                  <Col className="col-lg-12 col-md-12 mt-3">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th className="text-center" scope="col">#</th>
                          <th className="text-center" scope="col">Date</th>
                          <th className="text-center" scope="col">Time</th>
                          <th className="text-center" scope="col">Mode</th>
                          <th scope="col">Notes for Next Follow-up</th>
                          <th scope="col">Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {LeadFollowupList.map((record, index) => {
                          return (
                            <tr key={record.RowNo}>
                              <td className="text-center">{record.RowNo}</td>
                              <td className="text-center">{record.ScheduledDate}</td>
                              <td className="text-center">{record.ScheduledTime}</td>
                              <td className="text-center">{record.Mode}</td>
                              <td>{record.Agenda}</td>
                              <td>{record.Remark}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

        </Row>
      </div>
    </div>
  );
}

export default UiRating;
