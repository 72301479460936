import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';

const AddGST = () => {
  const history = useHistory();
  

  const saveGST = async (e) => {
    e.preventDefault();
    let GSTPercentage = event.target["txtPercentage"].value;
    if (GSTPercentage != '') {
      const GSTArr = {
        Percentage:GSTPercentage,
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
      }

      axios.post(global.APIURL + '/GSTInsert', GSTArr)
        .then(res => {
            console.log("res",res);
          alert(res.data[0].RecordStatus);
           history.push("/GST");
        });
    }
  }

  useEffect(() => {
   
});

  return (
    <div className="page-content">

      <MetaTags>
        <title>Add GST | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Add GST</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
             
              <a href="/GST" className="me-2 ms-2">GST List</a>
              <span>/</span>
              <span className="ms-2">Add GST</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-6">
            <a className="btn btn-primary btn-sm float-end font-14" href="/GST">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <form onSubmit={saveGST}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">GST Percentage</label>
                        <Input type="text" className="form-control" id="txtPercentage" placeholder="Enter GST Percentage" />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/GST">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>

    </div>
  )
}

export default AddGST;