import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label,
  Input,
} from "reactstrap"

import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import classnames from "classnames"

class UiTabsAccordions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      activeTab1: "5",
      activeTab2: "9",
      activeTab3: "13",
      verticalActiveTab: "1",
      verticalActiveTabWithIcon: "1",
      customActiveTab: "1",
      customIconActiveTab: "1",
      activeTabJustify: "5",
      col1: true,
      col2: false,
      col3: false,
      col5: true,
      col6: true,
      col7: true,
      col8: true,
      col9: true,
      col10: false,
      col11: false,
    }
    this.toggle = this.toggle.bind(this)
    this.toggle1 = this.toggle1.bind(this)

    this.t_col1 = this.t_col1.bind(this)
    this.t_col2 = this.t_col2.bind(this)
    this.t_col3 = this.t_col3.bind(this)
    this.t_col5 = this.t_col5.bind(this)
    this.t_col6 = this.t_col6.bind(this)
    this.t_col7 = this.t_col7.bind(this)
    this.t_col8 = this.t_col8.bind(this)
    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)

    this.toggle2 = this.toggle2.bind(this)
    this.toggle3 = this.toggle3.bind(this)

    this.toggleVertical = this.toggleVertical.bind(this)
    this.toggleVerticalIcon = this.toggleVerticalIcon.bind(this)
    this.toggleCustom = this.toggleCustom.bind(this)
    this.toggleIconCustom = this.toggleIconCustom.bind(this)
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false
    })
  }

  t_col2() {
    this.setState({
      col1: false,
      col2: !this.state.col2,
      col3: false
    })
  }

  t_col3() {
    this.setState({
      col1: false,
      col2: false,
      col3: !this.state.col3
    })
  }

  t_col5() {
    this.setState({ col5: !this.state.col5 })
  }

  t_col6() {
    this.setState({ col6: !this.state.col6 })
  }

  t_col7() {
    this.setState({ col7: !this.state.col7 })
  }

  t_col8() {
    this.setState({
      col6: !this.state.col6,
      col7: !this.state.col7
    })
  }

  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false
    })
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false
    })
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      })
    }
  }

  toggle2(tab) {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      })
    }
  }

  toggle3(tab) {
    if (this.state.activeTab3 !== tab) {
      this.setState({
        activeTab3: tab,
      })
    }
  }

  toggleVertical(tab) {
    if (this.state.verticalActiveTab !== tab) {
      this.setState({
        verticalActiveTab: tab,
      })
    }
  }

  toggleVerticalIcon(tab) {
    if (this.state.verticalActiveTabWithIcon !== tab) {
      this.setState({
        verticalActiveTabWithIcon: tab,
      })
    }
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      })
    }
  }

  toggleIconCustom(tab) {
    if (this.state.customIconActiveTab !== tab) {
      this.setState({
        customIconActiveTab: tab,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>List of Todo | KC Admin Group</title>
          </MetaTags>
          <Container fluid={true}>
  <Row className="mb-3">
              <Col className="col-lg-8 col-md-6 col-6">
                <div className="breadcrum ps-0">
                <CardTitle className="h4">List of Todo</CardTitle>
                  <a href="#" className="me-2">
                    Home
                  </a>{" "}
                  <span>/</span>
                  <span className="ms-2">List of Todo</span>
                </div>
              </Col>
              <Col className="col-lg-4 co-md-6 col-6">
                <a
                  className="btn btn-primary btn-sm float-end font-14 margin-20-back"
                  href="#"
                >
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                  <Row className="mt-3">
 
                          <div className="col-lg-6">
                             <a href="#" data-bs-toggle="modal" data-bs-target="#sd">
                             <div className="leave-sub-box mb-3">
                                    <h6>Voluptatem consequatur voluptatem exerctionem qui qui et expedita itaque esse.</h6>                      
                                    <p>Thu, 1st April 20210  | 9:00 AM - 12:00 PM  | 
                                      <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                    </p>
                              </div> 
                             </a>
                          </div>
                          <div className="col-lg-6">
                          <a href="#" data-bs-toggle="modal" data-bs-target="#sd">
                             <div className="leave-sub-box mb-3">
                                    <h6>Voluptatem consequatur voluptatem exerctionem qui qui et expedita itaque esse.</h6>                      
                                    <p>Thu, 1st April 20210  | 9:00 AM - 12:00 PM  | 
                                      <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                    </p>
                              </div> 
                             </a>
                          </div>
                          <div className="col-lg-6">
                          <a href="#" data-bs-toggle="modal" data-bs-target="#sd">
                             <div className="leave-sub-box mb-3">
                                    <h6>Voluptatem consequatur voluptatem exerctionem qui qui et expedita itaque esse.</h6>                      
                                    <p>Thu, 1st April 20210  | 9:00 AM - 12:00 PM  | 
                                      <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                    </p>
                              </div> 
                             </a>
                          </div>
                          <div className="col-lg-6">
                          <a href="#" data-bs-toggle="modal" data-bs-target="#sd">
                             <div className="leave-sub-box mb-3">
                                    <h6>Voluptatem consequatur voluptatem exerctionem qui qui et expedita itaque esse.</h6>                      
                                    <p>Thu, 1st April 20210  | 9:00 AM - 12:00 PM  | 
                                      <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                    </p>
                              </div> 
                             </a>
                          </div>
                          <div className="col-lg-6">
                          <a href="#" data-bs-toggle="modal" data-bs-target="#sd">
                             <div className="leave-sub-box mb-3">
                                    <h6>Voluptatem consequatur voluptatem exerctionem qui qui et expedita itaque esse.</h6>                      
                                    <p>Thu, 1st April 20210  | 9:00 AM - 12:00 PM  | 
                                      <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                    </p>
                              </div> 
                             </a>
                          </div>
                          <div className="col-lg-6">
                          <a href="#" data-bs-toggle="modal" data-bs-target="#sd">
                             <div className="leave-sub-box mb-3">
                                    <h6>Voluptatem consequatur voluptatem exerctionem qui qui et expedita itaque esse.</h6>                      
                                    <p>Thu, 1st April 20210  | 9:00 AM - 12:00 PM  | 
                                      <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-3.2cfd5ba6.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                      <img src="/static/media/avatar-2.feb0f89d.jpg" alt="" className="img-thumbnail modal-service-img rounded-circle"/>
                                    </p>
                              </div> 
                             </a>
                          </div>
                          </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default UiTabsAccordions
