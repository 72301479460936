import React, { useEffect,useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import PlacholderImg from "assets/images/favicon.png";

const AddChannelMaster = () => {
  const history = useHistory();
  const [ChannelTypeId, setChannelTypeId] = useState("");
  const [Title, setTitle] = useState("");
  const [ShortCode, setShortCode] = useState("");
  const [Description, setDescription] = useState("");
  const [ChannelLogo, setChannelLogo] = useState(PlacholderImg);
  const [ChannelTypeList, setChannelTypeList] = useState([]);

  useEffect(() => {
    getChannelTypeList();
  }, [])

  const getChannelTypeList = async () => {
    fetch(global.APIURL + "/ChannalTypeList")
      .then((res) => res.json())
      .then((json) => {
        setChannelTypeList(json)
      })
  }

  const saveProduct = async (e) => {
    e.preventDefault();
    
    if (Title != '') {
      const ChannelMaster = {
        ChannelTypeId:ChannelTypeId,
        Title: Title,
        ShortCode: ShortCode,
        ChannelLogoImage: ChannelLogo,
        Description: Description,
        Flag: "A",
        CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")

      }

      axios.post(global.APIURL + '/ChannelMaster', ChannelMaster)
        .then(res => {
        
          alert(res.data.StatusMessage);
          history.push("/ChannelMaster");
        });
    }
  }

  function ChannelLogofetchPost(e) {
    var details = e.target.files[0];
    let arr = details.name.toString().split(".");
    var FileEx = arr[1];
    var isUpload = 0;
    if (FileEx == 'jpg') {
      isUpload++;
    }
    else if (FileEx == 'JPG') {
      isUpload++;
    }
    else if (FileEx == 'jpeg') {
      isUpload++;
    }
    else if (FileEx == 'JPEG') {
      isUpload++;
    }
    else if (FileEx == 'png') {
      isUpload++;
    }
    else if (FileEx == 'PNG') {
      isUpload++;
    }
    else if (FileEx == 'gif') {
      isUpload++;
    }
    else if (FileEx == 'GIF') {
      isUpload++;
    }
    if (parseInt(isUpload) > 0) {
      var details1 = "ChannelSource";
      const formData = new FormData();
      formData.append('Document', details);
      formData.append('FolderName', details1);
      var AUrl = global.APIURL + '/uploadavatar/';
      axios.post(AUrl, formData,
        {
          headers: { "Content-Type": details.type },
        })
        .then((res) => {
          setChannelLogo(res.data.IName);
          document.getElementById("IGProfileImage").src = res.data.IName;
        });
    }
    else {
      document.getElementById("fuChannelLogo").value = '';
      alert("Please upload file .jpg or .jpeg or .png or .gif image!");
    }
  }

  return (
    <div className="page-content">

      <MetaTags>
        <title>Channel Master | KC Admin Group</title>
      </MetaTags>

      <div className="container-fluid">

        <Row className="mb-3">
          <Col className="col-lg-8 col-md-6 col-6">
            <div className="breadcrum ps-0">
              <CardTitle className="h4">Add Channel Master</CardTitle>
              <a href="/GeneralDashboard" className="me-2">Home</a>
              <span>/</span>
              <a href="/ChannelMaster" className="ms-2 me-2">Channel Master</a>
              <span>/</span>
              <span className="ms-2">Add Channel Master</span>
            </div>
          </Col>
          <Col className="col-lg-4 co-md-6 col-6">
            <a className="btn btn-primary btn-sm float-end font-14" href="/ChannelMaster">
              <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
            </a>
          </Col>
        </Row>
        <form onSubmit={saveProduct}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={2}>
                      <div className="mb-3 labeledit text-center">
                        <div>
                          <label htmlFor="uploadimage" className="form-label">
                            Upload Image
                          </label>
                        </div>
                        <img id="IGProfileImage" src={ChannelLogo} className="channellogoimage"></img>
                        <input type="file" id="fuChannelLogo" className="form-control form-control mt-3" onChange={ChannelLogofetchPost} />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                  <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Channel Type</label>
                        <select className="form-select" Id="ddlChanneltype" name="ddlChanneltype" onChange={(e) => setChannelTypeId(e.target.value)}>
                          <option value={"0"}>Select Channel Type</option>
                          {ChannelTypeList.map((ChannelType) => (
                            <option key={ChannelType.ChannelTypeId} value={ChannelType.ChannelTypeId}> {ChannelType.ChannelTypeName} </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label"> Title</label>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Title"
                          id="txtTitle"
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="mb-2">
                        <label className="form-label">Short Code</label>
                        <Input
                          type="text"
                          className="form-control"
                          id="txtShortcode"
                          placeholder="Short Code"
                          onChange={(e) => setShortCode(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-2">
                        <label className="form-label">Description</label>
                        <textarea
                          type="text"
                          className="form-control pb-4"
                          id="txtDescription"
                          rows="2"
                          placeholder="Description"
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                      <a className="btn btn_danger btn-sm ms-2" href="/AddChannelMaster">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </div>

    </div>
  )
}

export default AddChannelMaster;