import React,{ useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle,Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory,useParams  } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
const cookies = new Cookies();
 
const EditDepartment = () => { 
  const [Title, setTitle] = useState('');
  const [ShortCode, setShortCode] = useState('');
  const [Description, setDescription] = useState('');
  const [DId, setDId] = useState('');
  const history = useHistory();
      let { id } = useParams();
      const updateProduct  = async (e) => {
        e.preventDefault();
        let  UTitle=e.target["txtTitle"].value; 
        let UShortCode=e.target["txtShortCode"].value; 
        let UDescription=e.target["txtDescription"].value; 
        let UDId=e.target["hdfDId"].value; 
        if(UTitle!='')
        {
            const Department = {
                DId:UDId==''?'0':UDId,
                Title: UTitle,
                ShortCode: UShortCode,
                Description: UDescription,
                Flag:"A" ,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
        
            }

            axios.post(global.APIURL+'/DepartmentUpdate', Department)
            .then(res => 
            {
                    alert(res.data.StatusMessage);
                    history.push("/DepartmentMaster");
            });
        }
    }

    useEffect(() => { 
        if(id!=null)
        {
          if(id!="0")
          {
            let newid = id.split(':');            
            getUserTyprById(newid[1]);
          }
          else{
            history.push("/DepartmentMaster");
          }
        }
        else{
          history.push("/DepartmentMaster");
        }
    }, []);
 
    const getUserTyprById = async (UTId) => {
        var AUrl=global.APIURL+'/DepartmentEdit/'+UTId;
        axios.get(AUrl)  
        .then(res => {  
          setTitle(res.data[0].Title);
          setShortCode(res.data[0].ShortCode);
          setDescription(res.data[0].Description);
          setDId(res.data[0].DId)
        })
    }

    return (
      <div className="page-content">
  
        <MetaTags>
          <title>DepartMent | KC Admin Group</title>
        </MetaTags>
       
        <div className="container-fluid">
      
          <Row className="mb-3">
            <Col className="col-lg-8 col-md-6 col-6">
              <div className="breadcrum ps-0">
                <CardTitle className="h4">Edit DepartMent</CardTitle>
                <a href="/" className="me-2">Home</a>
                <span>/</span>
                <a href="/DepartmentMaster"><span className="ms-2 me-2">DepartMent</span></a>
                <span>/</span>
                <span className="ms-2">Edit DepartMent</span>
              </div>
            </Col>
            <Col className="col-lg-4 co-md-6 col-6">
              <a className="btn btn-primary btn-sm float-end font-14" href="/DepartmentMaster">
                <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
              </a>
            </Col>
          </Row>
          <form onSubmit={updateProduct}>
          <Row>         
            <Col className="col-12">
            <Card>
                <CardBody>
               
                <Row>
                  <Col lg={4}>
                        <div className="mb-2">
                        <label className="form-label">Department Title</label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Department Title"
                            id="txtTitle"  
                            defaultValue={Title}
                           
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-2">
                        <label className="form-label">Short Code</label>
                          <Input
                            type="text"
                            className="form-control"
                            id="txtShortCode"  
                            placeholder="Short Code"
                            defaultValue={ShortCode}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-2">
                        <label className="form-label">Description</label>
                          <Input
                            type="text"
                            className="form-control pb-4"
                            id="txtDescription"  
                          
                            placeholder="Description"
                            defaultValue={Description}
                          />
                        </div>
                      </Col>
                    <Col lg={12}>
                        <input type="hidden" id="hdfDId" value={DId} />    
                        {/* <input type="hidden" id="hdfUserTypeId" value={this.state.Id} /> */}
                        <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                        <a className="btn btn_danger btn-sm ms-2" href="/DepartmentMaster">Clear</a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
         </form>
        </div>
      </div>
    )
}

export default EditDepartment;