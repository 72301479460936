import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import MetaTags from "react-meta-tags";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "../../../assets/scss/datatables.scss";

// Table data
const products = [
  {
    id: 1,
    name: (
      <a className="text-heading" href="">
        Lorem Ipsum
      </a>
    ),
    channel:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
    Service: (
      <>
        <a href="">
          <span className="badge badge-soft-primary font-12">Active</span>
        </a>
      </>
    ),
    action: (
      <>
        <a
          href="#"
          className="btn btn_success btn-sm me-2 btn-sm-height"
          data-bs-toggle="modal"
          data-bs-target="#addactivities"
        >
          {" "}
          Edit{" "}
        </a>
        <a href="#" className="btn btn-warning btn-sm me-2 btn-sm-height">
          {" "}
          Inactive{" "}
        </a>
        <a href="#" className="btn btn_danger btn-sm btn-sm-height">
          {" "}
          Delete{" "}
        </a>
      </>
    ),
  },
  {
    id: 2,
    name: (
      <a className="text-heading" href="">
        Lorem Ipsum
      </a>
    ),
    channel: "Lorem Ipsum is simply dummy text",
    Service: (
      <>
        <a href="">
          <span className="badge badge-soft-primary font-12">Active</span>
        </a>
      </>
    ),
    action: (
      <>
        <a
          href="#"
          className="btn btn_success btn-sm me-2 btn-sm-height"
          data-bs-toggle="modal"
          data-bs-target="#addactivities"
        >
          {" "}
          Edit{" "}
        </a>
        <a href="#" className="btn btn-warning btn-sm me-2 btn-sm-height">
          {" "}
          Inactive{" "}
        </a>
        <a href="#" className="btn btn_danger btn-sm btn-sm-height">
          {" "}
          Delete{" "}
        </a>
      </>
    ),
  },
  {
    id: 3,
    name: (
      <a className="text-heading" href="">
        Lorem Ipsum
      </a>
    ),
    channel:
      "the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy",
    Service: (
      <>
        <a href="">
          <span className="badge badge-soft-primary font-12">Active</span>
        </a>
      </>
    ),
    action: (
      <>
        <a
          href="#"
          className="btn btn_success btn-sm me-2 btn-sm-height"
          data-bs-toggle="modal"
          data-bs-target="#addactivities"
        >
          {" "}
          Edit{" "}
        </a>
        <a href="#" className="btn btn-warning btn-sm me-2 btn-sm-height">
          {" "}
          Inactive{" "}
        </a>
        <a href="#" className="btn btn_danger btn-sm btn-sm-height">
          {" "}
          Delete{" "}
        </a>
      </>
    ),
  },
];

class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      productData: products,
    };
  }

  render() {
    const columns = [
      {
        dataField: "id",
        text: "Id",
        sort: true,
      },
      {
        dataField: "name",
        text: "Title",
        sort: true,
      },
      {
        dataField: "channel",
        text: "Action List",
        sort: true,
      },
      {
        dataField: "Service",
        text: "Status",
        sort: true,
      },
      {
        dataField: "action",
        text: "Action",
        sort: true,
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: products.length, // replace later with size(customers),
      custom: true,
    };

    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "20", value: 20 },
      { text: "25", value: 25 },
      { text: "All", value: this.state.productData.length },
    ];

    // Select All Button operation
    // const selectRow = {
    //   mode: 'checkbox'
    // }

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Activities | KC Admin Group</title>
          </MetaTags>
          <div className="container-fluid">
            <div
              className="modal fade"
              id="addactivities"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      {" "}
                      Add Activities{" "}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                
                  <div className="modal-footer">
                    <button type="button" className="btn btn_success">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn_danger"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Row className="mb-3">
              <Col className="col-lg-8 col-md-6 col-6">
                <div className="breadcrum ps-0">
                  <CardTitle className="h4">Add Activities</CardTitle>
                  <a href="#" className="me-2">
                    Activities
                  </a>{" "}
                  <span>/</span>
                  <span className="ms-2">Add Activities</span>
                </div>
              </Col>
              <Col className="col-lg-4 co-md-6 col-6">
                <a
                  className="btn btn-primary btn-sm float-end font-14 margin-20-back"
                  href="#"
                >
                  <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                </a>
                
              </Col>
            </Row>

            <Row>
            <Card>
                <CardBody>
                <div className="">
                  <Row className="align-items-center">
                  <div className="mb-3 col-lg-4">
                      <label> Title * </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title"
                      />
                    </div>
                    <div className="col-lg-4 mt-3">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="autoSizingCheck"/>
                        <label className="form-check-label" htmlFor="autoSizingCheck">
                        Is In App Visible ?
                        </label>
                      </div>
                    </div>
                  </Row>
                    <Row>
                      <CardTitle>Action Type List</CardTitle>
                    <div className="mb-3 col-lg-4">
                      <label> Title * </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title"
                      />
                    </div>
                    <div className="mb-3 col-lg-4">
                      <label> Action Type * </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title"
                      />
                    </div>
                    <div className="mb-3 col-lg-4">
                      <label>Select master Name </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Select Master Name</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="col-lg-12">
                     <div className="table-responsive">
                      <table className="table table-bordered align-middle">
                        <thead className="text-center">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Title</th>
                            <th scope="col">Action Type</th>
                            <th scope="col">Select Master</th>
                            <th scope="col">Status</th>
                            <th className="" scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          <tr>
                            <th scope="row">1</th>
                            <td>Lorem ipsum</td>
                            <td>Lorem ipsum dolor site amet</td>
                            <td>Service Master</td>
                            <td><a href="#"><span className="badge badge-soft-primary font-12">Follow Up</span></a></td>
                            <td className=""><div className="d-flex gap-3 justify-content-center"><a className="text-success" href="#"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div></td>
                          </tr>
                          <tr>
                            <th scope="row">2</th>
                            <td>Lorem ipsum</td>
                            <td>Lorem ipsum dolor site amet</td>
                            <td>Service Master</td>
                            <td><a href="#"><span className="badge badge-soft-primary font-12">Follow Up</span></a></td>
                            <td className=""><div className="d-flex gap-3 justify-content-center"><a className="text-success" href="#"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div></td>
                          </tr>
                          <tr>
                            <th scope="row">3</th>
                            <td>Lorem ipsum</td>
                            <td>Lorem ipsum dolor site amet</td>
                            <td>Service Master</td>
                            <td><a href="#"><span className="badge badge-soft-primary font-12">Follow Up</span></a></td>
                            <td className=""><div className="d-flex gap-3 justify-content-center"><a className="text-success" href="#"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div></td>
                          </tr>
                          <tr>
                            <th scope="row">4</th>
                            <td>Lorem ipsum</td>
                            <td>Lorem ipsum dolor site amet</td>
                            <td>Service Master</td>
                            <td><a href="#"><span className="badge badge-soft-primary font-12">Follow Up</span></a></td>
                            <td className=""><div className="d-flex gap-3 justify-content-center"><a className="text-success" href="#"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></a><a className="text-danger" href="#"><i className="mdi mdi-delete font-size-18" id="deletetooltip"></i></a></div></td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                     </div>
                    </Row>
                  </div>
                </CardBody>
            </Card>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DatatableTables;
