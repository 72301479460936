import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Input } from "reactstrap"
import MetaTags from 'react-meta-tags'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
const cookies = new Cookies();
import { EditorState, convertToRaw, ContentState, convertFromRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { stateFromHTML } from 'draft-js-import-html';

const EditTestimonial = () => {
    const [Title, setTitle] = useState('');
    const [Id, setId] = useState('');
    const [editorState, seteditorState] = useState(EditorState.createEmpty());

    const [TestimonyName, setTestimonyName] = useState("");
    const [TestimonyDesignation, setTestimonyDesignation] = useState("");
    const [TestimonyMsg, setTestimonyMsg] = useState("");
    const [TestimonyDesc, setTestimonyDesc] = useState("");
    const [TestimonyFilePath, setTestimonyFilePath] = useState("");
    const [TestimonyFileName, setTestimonyFileName] = useState("");
    const [TestimonyFileDisplay, setTestimonyFileDisplay] = useState(1);
    const history = useHistory();
    let { id } = useParams();

    useEffect(() => {


        if (id != null) {
            if (id != "0") {
                let newid = id.split(':');
                const Eid = newid[1];
                getTestimonyDetails(Eid);
            }
            else {
                history.push("/Testimonial");
            }
        }
        else {
            history.push("/Testimonial");
        }
    }, []);

    useEffect(() => {


        let contentState = stateFromHTML(TestimonyMsg);
        seteditorState(EditorState.createWithContent(contentState));


    }, [TestimonyMsg]);


    const onEditorStateChange = editorState => {
        seteditorState(EditorState.moveFocusToEnd(editorState));
        setTestimonyDesc(draftToHtml(convertToRaw(editorState.getCurrentContent())));

    }

    const getTestimonyFile = (e) => {
        var details = e.target.files[0];
        var details1 = "Testimonial";

        const formData = new FormData();

        formData.append('Document', details);
        formData.append('FolderName', details1);

        axios.post(global.APIURL + '/uploadavatar', formData, {
            headers: { "Content-Type": details.type },
        }).then((res) => {
            setTestimonyFilePath(res.data.IName);
            setTestimonyFileName(res.data.FileName);

            if (e.target.files[0].name != null) {
                if (e.target.files[0].name != '') {
                    setTestimonyFileDisplay(1);
                }
                else {
                    setTestimonyFileDisplay(0);
                }
            }

        });
    }

    const updateTestimony = async (e) => {
        e.preventDefault();
        let newid = id.split(':');
        const Eid = newid[1];
        let VTestimonyName = e.target["txtTestimonyName"].value;
        let VTestimonyDesignation = e.target["txtTestimonyDesignation"].value;

        if (VTestimonyName == "") {
            alert("Enter Testimony Name");
            return;
        }
        if (VTestimonyDesignation == "") {
            alert("Enter Testimony Designation");
            return;
        }


        if (VTestimonyName != '') {
            const TUpdateData = {
                Id: Eid,
                TestimonialName: VTestimonyName,
                TestimonialDesignation: VTestimonyDesignation,
                TestimoniaPersonImage: TestimonyFilePath,
                TestimonialMessage: TestimonyDesc == "" ? TestimonyMsg : TestimonyDesc,
                CreateUser: localStorage.getItem("LoginUserId") == null ? "0" : localStorage.getItem("LoginUserId") == "" ? "0" : localStorage.getItem("LoginUserId")
            }

            axios.post(global.APIURL + '/TestimonialUpdate', TUpdateData)
                .then(res => {
                    alert(res.data[0].RecordStatus);
                    history.push('/Testimonial')
                });
        }
    }

    const getTestimonyDetails = async (UTId) => {

        const AData = {
            "Id": UTId
        }

        axios.post(global.APIURL + '/TestimonialDetail', AData)
            .then(res => {
                setTestimonyName(res.data[0].TestimonialName);
                setTestimonyDesignation(res.data[0].TestimonialDesignation);
                setTestimonyFilePath(res.data[0].TestimoniaPersonImage);
                let filearray = res.data[0].TestimoniaPersonImage.split('/');
                setTestimonyFileName(filearray[5]);
                setTestimonyMsg(res.data[0].TestimonialMessage);
            })
    }

    return (
        <div className="page-content">

            <MetaTags>
                <title>Testimonial | KC Admin Group</title>
            </MetaTags>

            <div className="container-fluid">

                <Row className="mb-3">
                    <Col className="col-lg-8 col-md-6 col-6">
                        <div className="breadcrum ps-0">
                            <CardTitle className="h4">Edit Testimonial</CardTitle>
                            <a href="/GeneralDashboard" className="me-2">Home</a>
                            <span>/</span>
                            <a href="/Testimonial"><span className="ms-2 me-2">Testimonial</span></a>
                            <span>/</span>
                            <span className="ms-2">Edit Testimonial</span>
                        </div>
                    </Col>
                    <Col className="col-lg-4 co-md-6 col-6">
                        <a className="btn btn-primary btn-sm float-end font-14" href="/Testimonial">
                            <i className="mdi mdi-arrow-left align-middle me-1"></i>Back
                        </a>
                    </Col>
                </Row>
                <form onSubmit={updateTestimony}>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col lg={4}>
                                            <div className="mb-2">
                                                <label className="form-label">Testimony Name</label>
                                                <Input type="text" className="form-control" id="txtTestimonyName" placeholder="Enter Testimony Name" defaultValue={TestimonyName} />
                                            </div>
                                        </Col>

                                        <Col lg={4}>
                                            <div className="mb-2">
                                                <label className="form-label">Testimony Designation</label>
                                                <Input type="text" className="form-control" id="txtTestimonyDesignation" placeholder="Enter Testimony Designation" defaultValue={TestimonyDesignation} />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-2">
                                                <label className="form-label">Achievement File</label>
                                                {TestimonyFileDisplay > 0 ?
                                                    <span className="float-end text-primary font-12" id="spandoctitle">
                                                        <Link to={TestimonyFileName} target="_blank" >{TestimonyFileName}</Link></span> : ''}
                                                <Input type="file" className="form-control" id="txtTestimonyFilePath" accept=".png,.jpg,.jpeg,.gif" onChange={(e) => getTestimonyFile(e)} />
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="mb-2">
                                                <label className="form-label">Message</label>

                                                <Editor

                                                    editorState={editorState}
                                                    wrapperClassName="demo-wrapper"
                                                    editorClassName="demo-editor"
                                                    onEditorStateChange={onEditorStateChange}
                                                    id="AchEditor"
                                                    name="AchEditor"

                                                />
                                            </div>
                                        </Col>

                                        <Col lg={12}>
                                            <button className="btn btn_success btn-sm" id="btnsave" >Save</button>
                                            <a className="btn btn_danger btn-sm ms-2" href="/Category">Clear</a>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    )
}

export default EditTestimonial;